import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Typography } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { PercentageChange, SuccessDirection } from "../percentageChange";

import { LegendIndicator } from "./multiLineChartLegendGeneric";
import React from "react";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface TooltipData {
  current: number;
  prior: number;
  startTime?: number;
  endTime?: number;
}
interface MultiLineTooltipProps {
  active?: boolean;
  payload?: { payload: TooltipData }[];
  label?: number;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  currencyRates: any[];
  interval: INTERVAL;
  timezone: string;
  dataKeys: [string, string];
  colours: [string, string];
  notCurrency?: boolean;
  valueTooltipKey: string;
  valuePrefix?: string;
  valueSuffix?: string;
  toFixed?: number;
  displayPercentChange?: boolean;
  percentageChangeFormatting?: {
    isPptChange?: boolean;
    successDirection?: SuccessDirection;
    conditionalFormatting?: boolean;
    toFixedDigits?: number;
    noUnit?: boolean;
  };
}

export const MultiLineChartTooltipGeneric = ({
  active,
  payload,
  label,
  currentPeriod,
  currentCurrency,
  currencyRates,
  interval,
  timezone,
  dataKeys,
  colours,
  notCurrency,
  valueTooltipKey,
  valueSuffix,
  valuePrefix,
  toFixed,
  displayPercentChange,
  percentageChangeFormatting,
}: MultiLineTooltipProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  if (active) {
    const date = formatTooltipLabel(label, currentPeriod, interval, timezone);
    const tooltipData = payload?.length ? payload[0].payload : null;
    const { endTime } = tooltipData || {};
    const endDate = endTime
      ? formatTooltipLabel(endTime, currentPeriod, interval, timezone)
      : null;
    const LastWeekInfo = () => (
      <>
        <Box display="flex" mt={1} alignItems="center">
          <LegendIndicator
            {...{
              theme,
              fill: colours[1],
            }}
          />
          <Typography variant="body2">{dataKeys[1]}</Typography>
        </Box>
        <Box
          display="flex"
          mt={0.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2">{valueTooltipKey}:</Typography>
          <Typography variant="body2">
            {typeof tooltipData?.prior === "number"
              ? notCurrency
                ? `${valuePrefix ?? ""}${
                    Number.isFinite(toFixed)
                      ? numberWithCommas(tooltipData.prior.toFixed(toFixed))
                      : numberWithCommas(tooltipData.prior)
                  }${valueSuffix ?? ""}`
                : formatCurrencyRounded(
                    tooltipData.prior,
                    currencyRates,
                    currentCurrency,
                    currentCurrency
                  )
              : "-"}
          </Typography>
        </Box>
      </>
    );

    const ThisWeekInfo = () => (
      <>
        <Typography variant="body2">{date}</Typography>
        {endDate && <Typography variant="body2"> - {endDate}</Typography>}
        <Box display="flex" mt={0.5} alignItems="center">
          <LegendIndicator
            {...{
              theme,
              fill: colours[0],
            }}
          />
          <Typography variant="body2">{dataKeys[0]}</Typography>
        </Box>
        <Box
          display="flex"
          mt={0.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2">{valueTooltipKey}:</Typography>
          <Typography variant="body2">
            {typeof tooltipData?.current === "number"
              ? notCurrency
                ? `${valuePrefix ?? ""}${
                    Number.isFinite(toFixed)
                      ? numberWithCommas(tooltipData.current.toFixed(toFixed))
                      : numberWithCommas(tooltipData.current)
                  }${valueSuffix ?? ""}`
                : formatCurrencyRounded(
                    tooltipData.current,
                    currencyRates,
                    currentCurrency,
                    currentCurrency
                  )
              : "-"}
          </Typography>
        </Box>
      </>
    );

    const PercentChangeInfo = () => {
      return displayPercentChange ? (
        <>
          <Box
            display="flex"
            mt={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{t("chartKeys.change")}:</Typography>
            <PercentageChange
              {...{
                current: tooltipData?.current,
                prior: tooltipData?.prior,
                ...percentageChangeFormatting,
              }}
            />
          </Box>
        </>
      ) : null;
    };

    return (
      <BlackTooltip>
        <Box p={1}>
          <ThisWeekInfo />
          <LastWeekInfo />
          <PercentChangeInfo />
        </Box>
      </BlackTooltip>
    );
  }
  return null;
};
