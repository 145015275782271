import React, { useState } from "react";

import { AdvertisingProfile } from "~/store/mystore/marketing.redux";
import Box from "@material-ui/core/Box";
import { CountryLogo } from "../../img/flags/country_logos";
import { CountryLogoWrapper } from "./standaloneSelectProfile";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import Medium from "~/components/typography/medium";
import Radio from "@material-ui/core/Radio";
import RaisedButton from "~/components/buttons/raisedButton";
import { Store } from "~/typedef/store";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { updateAmazonAdvertisingProfile } from "../../store/connections/connectAmazon.redux";
import { useDispatch } from "react-redux";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
`;

interface AmazonSelectAdvertisingProfilePageProps {
  store: Store | null;
  onSuccess: () => void;
}

const AmazonSelectAdvertisingProfilePage: React.FC<
  AmazonSelectAdvertisingProfilePageProps
> = ({ store, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profiles = useTypedSelector((state) => state.marketing.profiles);

  const urlSearchParams = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] =
    useState<null | AdvertisingProfile>(null);
  const mid = store?.merchantId || urlSearchParams.get("store");
  const countryCode =
    store?.marketplaceCountry || urlSearchParams.get("countryCode");

  const redirectNextStep = async () => {
    onSuccess();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (selectedProfile && selectedProfile.profileId) {
        await dispatch(
          updateAmazonAdvertisingProfile(
            mid,
            countryCode,
            selectedProfile.profileId,
            undefined,
            "amazon_vendor",
            "amazon_vendor",
            false
          )
        );
        await redirectNextStep();
      } else {
        throw new Error(
          `Invalid advertising profile ${JSON.stringify(selectedProfile)}`
        );
      }
    } catch (err) {
      console.log(err);
      throw err;
    }

    setLoading(false);
  };

  const renderAvailableProfiles = (profiles: AdvertisingProfile[]) =>
    profiles.map((profile, index) => (
      <Grid
        key={index}
        container
        item
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Radio
          checked={`${selectedProfile?.profileId}` === `${profile.profileId}`}
          name={`${profile.profileId}`}
          color="primary"
          onChange={(e) => setSelectedProfile(profile)}
        />
        <Grid item>
          <CountryLogoWrapper>
            <CountryLogo code={profile.countryCode} />
          </CountryLogoWrapper>
        </Grid>
        <Grid item>
          <MarketplaceIcon market={"amazon"} />
        </Grid>
        <Grid item>
          <Typography variant="body2" noWrap>
            {profile.accountInfo.id} (&quot;{profile.accountInfo.name}
            &quot;)
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="amazon-selectadvertisingprofile-wrapper">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Medium>
                {t("connectWizard.amazonAdvertisingProfileTitle")}
              </Medium>
            </Grid>
            <Grid item xs={12}>
              {renderAvailableProfiles(profiles)}
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <Box pt={2}>
                  <RaisedButton
                    disabled={!selectedProfile?.profileId}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                  >
                    {t("generic.continueButton")}
                  </RaisedButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default AmazonSelectAdvertisingProfilePage;
