import { Box, Grid, useTheme } from "@material-ui/core";

import React from "react";
import styled from "styled-components";

interface LegendIndicatorProps {
  fill: string;
}

export const LegendIndicator = styled.div<LegendIndicatorProps>`
  height: 3px;
  width: 15px;
  margin-right: 10px;
  background-color: ${({ fill }: LegendIndicatorProps) => fill};
`;

const LegendWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

interface MultiLineChartLegendProps {
  dataKeys: string[];
  colours: string[];
}

const MultiLineChartLegendGeneric = ({
  dataKeys,
  colours,
}: MultiLineChartLegendProps) => {
  const theme = useTheme();
  return (
    <Grid container>
      {dataKeys.map((dataKey, i) => (
        <LegendWrapper key={`${dataKey}`}>
          <LegendIndicator fill={colours[i] ?? theme.palette.primary.main} />
          {dataKey}
        </LegendWrapper>
      ))}
    </Grid>
  );
};

export default MultiLineChartLegendGeneric;
