import React, { ChangeEvent, memo } from "react";
import lookup, { Country as CountryOption } from "country-code-lookup";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const countries = lookup.countries;

const CountrySelect = memo(
  (props: {
    value: CountryOption | null;
    label: string;
    onChange: (
      evt: ChangeEvent<{}>,
      value: CountryOption | null,
      reason: string
    ) => void;
  }) => {
    return (
      <Autocomplete
        onChange={props.onChange}
        options={countries}
        getOptionSelected={(opt: CountryOption, val: CountryOption) =>
          opt.iso3 === val.iso3
        }
        getOptionLabel={(val) => val.country}
        value={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            margin="normal"
            fullWidth
          />
        )}
      />
    );
  }
);

export default CountrySelect;
