import moment, { Moment } from "moment";

export const partitionRangeByCalendarMonth = (from: Moment, to: Moment) => {
  let workingFrom = moment(from);
  let workingTo = moment(from).endOf("month");
  const partitionedDates = [[moment(workingFrom), moment(workingTo)]];

  while (workingTo.isBefore(to)) {
    workingFrom = moment(workingTo).add(1, "day").startOf("month");
    workingTo = moment(workingFrom).endOf("month");
    if (workingTo.isAfter(to)) {
      workingTo = moment(to);
    }
    partitionedDates.push([moment(workingFrom), moment(workingTo)]);
  }

  return partitionedDates;
};
