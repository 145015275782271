import { Box, Link, withTheme } from "@material-ui/core";

import { LaunchRounded } from "@material-ui/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

interface SafeLinkProps {
  navigate?: () => any;
  component: any;
  innerRef: any;
}

const SafeLink = React.forwardRef((props: SafeLinkProps, ref: any) => {
  // eslint-disable-next-line no-unused-vars
  const { navigate, ...restOfProps } = props;
  return <Link {...restOfProps} innerRef={ref} />;
});

interface CombinedLinkProps {
  navigate?: () => any;
  children: any;
  to: any;
}
// Combine material UI Link (cosmetic) and react-router-dom Link (functionality)
export const CombinedLink = React.forwardRef(
  (props: CombinedLinkProps, ref) => (
    <SafeLink component={RouterLink} innerRef={ref} {...props} />
  )
);

interface LinkRefProps {
  to: any;
}
export const LinkRef = React.forwardRef((props: LinkRefProps, ref: any) => (
  <RouterLink {...props} innerRef={ref} />
));

const ExternalLinkText = withTheme(styled(SafeLink)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
  `}
`);

const ExternalLinkIcon = withTheme(styled(LaunchRounded)`
  font-size: 1rem;
  margin-left: 5px;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
  `}
`);

// Combine material UI Link (cosmetic) and react-router-dom Link (functionality)
export const ExternalLink = React.forwardRef(
  (props: CombinedLinkProps, ref) => (
    <Box display="flex" alignItems="center">
      <ExternalLinkText
        component={RouterLink}
        innerRef={ref}
        {...props}
        target="_blank"
      />
      <ExternalLinkIcon />
    </Box>
  )
);

export default CombinedLink;
