import {
  PaletteColor,
  PaletteOptions,
} from "@material-ui/core/styles/createPalette";

import { Typography } from "@material-ui/core";
import styled from "styled-components";

const StatusText = styled(Typography)<{
  status?: string;
  justify?: string;
}>`
  ${({ theme, status, justify }) => `
    display: flex;
    align-items: center;
    ${justify ? `justify-content: ${justify};` : ""}
    color: ${
      status
        ? status === "disabled" || status === "noData"
          ? theme.palette.disabled.light
          : status === "borderline" || status === "success"
          ? theme.palette.success.main
          : (theme.palette[status as keyof PaletteOptions] as PaletteColor)
              ?.main
        : theme.palette.text.secondary
    };
    ${
      status && status === "borderline"
        ? `border:1px ${theme.palette.error.main} solid`
        : ""
    }

  `}
`;

export default StatusText;
