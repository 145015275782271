import {
  SimplePaletteColorOptions,
  alpha,
  createTheme,
} from "@material-ui/core";
import { ThemeType, default as msTheme } from "@theming/muiTheme";
import { isEmpty, isUndefined } from "lodash";
import {
  palette as muiThemePalette,
  shape as muiThemeShape,
  typography as muiThemeTypography,
  shadows,
} from "../theming/muiTheme";

import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import WebFont from "webfontloader";
import { getPreferredColorScheme } from "~/utils/browserUtils";
import { useMemo } from "react";
import { useTypedSelector } from "~/hooks/useTypedSelector";

declare module "@material-ui/core/styles" {
  interface Theme {
    darkModeEnabled?: boolean;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    darkModeEnabled?: boolean;
  }
}

export const useCustomTheme = (
  forceColourScheme?: ReturnType<typeof getPreferredColorScheme>
) => {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  const userOverrides = customOverrides?.overrides;

  const colorScheme = useTypedSelector(
    (state) =>
      forceColourScheme ??
      (state.persistentAppSettings?.setting?.data?.colorScheme ||
        getPreferredColorScheme())
  );
  const darkModeEnabled = useMemo(
    () => !isEmpty(customOverrides) && colorScheme === "dark",
    [customOverrides, colorScheme]
  );

  if (userOverrides && !isEmpty(userOverrides)) {
    const palette: PaletteOptions = {
      ...muiThemePalette,
      ...userOverrides.palette,
    };

    const typography: TypographyOptions = {
      ...muiThemeTypography,
      ...userOverrides.typography,
    };

    if (typography.fontFamily && typeof typography.fontFamily === "string") {
      WebFont.load({
        google: {
          families: typography.fontFamily.split(","),
        },
      });
    }

    const shape = {
      ...muiThemeShape,
      ...userOverrides.shape,
    };

    const overrides = {
      MuiAppBar: {
        colorDefault: {
          backgroundColor: palette.common?.white,
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: palette.border.main,
          color: palette.common?.black,
        },
      },
      MuiBadge: {
        badge: {
          backgroundColor: (palette.warning as SimplePaletteColorOptions)?.main,
          color: (palette.warning as SimplePaletteColorOptions)?.contrastText,
          fontSize: "0.75rem",
          padding: "0 6px 0px 5px",
        },
      },
      MuiButton: {
        contained: {
          minHeight: "40px",
          minWidth: "285px",
        },
        outlined: {
          minHeight: "40px",
          minWidth: "285px",
        },
        outlinedSizeSmall: {
          minHeight: "40px",
          minWidth: "100px",
        },
        containedSizeSmall: {
          minHeight: "40px",
          minWidth: "100px",
        },
        root: {
          textTransform: "uppercase",
          borderRadius: "40px",
        },
        text: {
          textTransform: "none",
        },
        fullWidth: {
          minWidth: "fit-content",
        },
        // From https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
        // Used to add shadows to outlined buttons, since Material-UI doesn't
        // style them with shadows by default
        outlinedPrimary: {
          backgroundColor: palette.common?.white,
        },
        outlinedSecondary: {
          backgroundColor: palette.common?.white,
          boxShadow: shadows[1],
          "&:hover": {
            boxShadow: shadows[4],
            backgroundColor: palette.common?.white,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              boxShadow: shadows[1],
            },
          },
          "&$focusVisible": {
            boxShadow: shadows[6],
          },
          "&:active": {
            boxShadow: shadows[8],
          },
          "&$disabled": {
            boxShadow: shadows[0],
          },
        },
      },
      MuiButtonBase: {
        root: {
          borderRadius: shape.borderRadius,
        },
      },
      MuiChecked: {
        fill: (palette.primary as SimplePaletteColorOptions)?.main,
      },
      MuiChip: {
        sizeSmall: {
          fontSize: "0.75rem",
        },
      },
      MuiDivider: {
        middle: {
          marginTop: "16px",
        },
      },
      MuiDrawer: {
        paper: {
          position: "absolute",
          width: "inherit",
          color: (palette.secondary as SimplePaletteColorOptions)?.contrastText,
          background: (palette.secondary as SimplePaletteColorOptions)?.main,
        },
        paperAnchorDockedLeft: {
          borderRight: "none",
        },
        paperAnchorRight: {
          background: palette.common?.white,
        },
      },
      MuiExpansionPanel: {
        root: {
          "&:before": {
            backgroundColor: palette.border.main,
          },
        },
      },
      MuiInput: {
        root: {
          "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          minWidth: "fit-content",
        },
        label: {
          fontSize: "0.75rem",
          lineHeight: 1.5,
          fontWeight: 400,
        },
        labelPlacementStart: {
          width: "100%",
          marginLeft: 0,
          justifyContent: "space-between",
        },
      },
      MuiLinearProgress: {
        root: {
          height: 10,
        },
        colorSecondary: {
          backgroundColor: "#cfcfcf",
        },
        barColorSecondary: {
          backgroundColor: (palette.success as SimplePaletteColorOptions)?.main,
        },
      },
      MuiLink: {
        root: {
          cursor: "pointer",
        },
        button: {
          fontSize: "0.75rem",
          fontFamily: ["Marine", "sans-serif"].join(","),
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: `1px solid ${palette.border.main}`,
        },
      },
      MuiListItemIcon: {
        root: {
          color: "inherit",
          justifyContent: "center",
        },
      },
      MuiListItemText: {
        primary: {
          fontSize: "14px",
          fontWeight: 600,
        },
        secondary: {
          color: "inherit",
          fontWeight: 500,
        },
        inset: {
          paddingLeft: "70px",
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: `${shape.borderRadius}px`,
        },
        list: {
          padding: "0",
        },
      },
      MuiMenuItem: {
        root: {
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          background: "transparent",
          "&$selected": {
            backgroundColor: palette.action?.hover,
            "&:hover": {
              backgroundColor: palette.action?.hover,
            },
          },
          "&:hover": {
            backgroundColor: palette.action?.hover,
          },
        },
        dense: {
          paddingTop: "4px",
          paddingBottom: "4px",
        },
      },
      MuiSvgIcon: {
        root: {
          fill: palette.text?.primary,
        },
      },
      MuiTableCell: {
        head: {
          fontSize: "1rem",
          height: "38px",
          fontWeight: "bold",
        },
        body: {
          fontSize: "0.9rem",
        },
      },
      MuiTabs: {
        root: {
          width: "100%",
        },
      },
      MuiTab: {
        root: {
          display: "flex",
          minWidth: "0px",
          height: "64px",
          textTransform: "none",
          fontSize: "14px",
        },
        wrapper: {
          flexDirection: "row",
        },
        textColorInherit: {
          opacity: 1,
        },
      },
      MuiToggleButton: {
        root: {
          fontSize: "0.875rem",
          fontWeight: "400",
          "&:hover": {
            backgroundColor: alpha(
              (palette.info as SimplePaletteColorOptions)?.main,
              0.5
            ),
            color: (palette.info as SimplePaletteColorOptions)?.contrastText,
          },
          "&$selected": {
            backgroundColor: (palette.info as SimplePaletteColorOptions)?.main,
            color: (palette.info as SimplePaletteColorOptions)?.contrastText,
            "&:hover": {
              backgroundColor: (palette.info as SimplePaletteColorOptions)
                ?.main,
              color: (palette.info as SimplePaletteColorOptions)?.contrastText,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        groupedVertical: {
          "&:not(:last-child)": {
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          },
          "&:not(:first-child)": {
            borderTop: "1px solid ".concat(
              alpha(
                palette.common?.black ? palette.common.black : "#000000",
                0.12
              )
            ),
            marginTop: "1rem",
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
          },
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: "64px",
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: palette.common?.black,
        },
      },
    };

    if (palette) {
      overrides.MuiDrawer = {
        ...overrides.MuiDrawer,
        paper: {
          position: "absolute",
          width: "inherit",
          color: !isUndefined(palette.sideToolbar)
            ? palette.sideToolbar.contrastText
            : (palette?.secondary as SimplePaletteColorOptions)?.contrastText,
          background:
            !isUndefined(palette.sideToolbar) &&
            !isUndefined(palette.sideToolbar?.main)
              ? palette.sideToolbar.main
              : (palette.secondary as SimplePaletteColorOptions).main,
        },
        paperAnchorDockedLeft: {
          borderRight: "none",
        },
      };
    }

    const theme = createTheme({
      palette,
      typography,
      shadows,
      shape,
      spacing: 8,
      overrides: overrides as Overrides,
      darkModeEnabled,
    });
    return theme;
  }

  return msTheme as ThemeType;
};
