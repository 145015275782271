import {
  AppBar,
  Box,
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  Grid,
  Hidden,
  Toolbar,
} from "@material-ui/core";
import { LinkProps, Link as RouterLink } from "react-router-dom";
import React, { ReactChild, memo, useCallback } from "react";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import { AddOutlined } from "@material-ui/icons";
import NavTabs from "./navTabs";
import NotificationsTab from "./notificationsTab";
import ProfileTab from "./profileTab";
import Settings from "./settings";
import SideMenu from "../sideNavigation/sideMenu";
import SubscriptionAlert from "../../../modules/subscription/alerts/subscriptionAlert";
import get from "lodash/get";
import { isUndefined } from "lodash";
import { useCustomLogo } from "~/hooks/useCustomLogo";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledAppBar = styled(AppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.main
      : theme.palette.secondary.main};
`;

const ToolbarContent = styled(Grid)`
  max-width: 1600px;
  margin: auto;
  padding-left: 24px;
`;

const AddIcon = styled(AddOutlined)`
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

type ToolbarButtonProps = ExtendButtonBase<ButtonTypeMap<{}, "div">> &
  LinkProps &
  ThemeProps<DefaultTheme>;
const ToolbarButton = styled(Button)`
  min-width: auto;
  border-color: ${({ theme }: ToolbarButtonProps) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  color: ${({ theme }: ToolbarButtonProps) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  margin-right: 1rem;
`;

interface SmallLogoProps {
  gutter?: boolean;
}
const SmallLogo = styled.img`
  display: flex;
  width: 90%;
  height: auto;
  max-width: 250px;
  max-height: 62.5 px;
  margin-left: ${({ gutter }: SmallLogoProps) => (gutter ? "1rem" : "2rem")};
`;

interface StyledToolbarProps {
  $justify?: string;
}
const StyledToolbar = styled(Toolbar)`
  color: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.main
      : theme.palette.secondary.main};
  width: 100%;
  justify-content: ${({ $justify }: StyledToolbarProps) =>
    $justify || "stretch"};
`;

interface FullToolbarProps {
  isNewUser: boolean;
  displaySideNav?: boolean;
  logout: () => void;
  content: ReactChild;
}
export const FullToolbar = memo<FullToolbarProps>(function FullToolbar({
  isNewUser,
  displaySideNav,
  logout,
  content,
}) {
  const user = useTypedSelector((state) => get(state, "user"));

  const history = useHistory();

  const performLogout = useCallback(async () => {
    history.push("/");
    if (user?._id) {
      logout();
    }
  }, [user?._id]);

  return (
    <>
      <Hidden smDown>
        <DesktopToolbar logout={performLogout} isNewUser={isNewUser} />
      </Hidden>
      <Hidden mdUp>
        <MobileToolbar logout={performLogout} isNewUser={isNewUser} />
      </Hidden>
      <SubscriptionAlert />
      {displaySideNav && <SideMenu content={content} />}
    </>
  );
});

export default FullToolbar;

interface MobileToolbarProps {
  isNewUser: boolean;
  logout: () => void;
}
const MobileToolbar = memo<MobileToolbarProps>(function MobileToolbar({
  isNewUser,
  logout,
}) {
  const user = useTypedSelector((state) => get(state, "user"));
  const customLogo = useCustomLogo();
  const { t } = useTranslation();

  return (
    <StyledAppBar
      position="static"
      color="default"
      data-testid="mobile-toolbar"
      elevation={0}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={6}
            container
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <SmallLogo src={customLogo} gutter />
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            {!user?.role?.includes("external") && <NotificationsTab />}
            <Settings />
            <ProfileTab user={user} logout={logout} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <StyledToolbar variant="dense" $justify="center">
            <NavTabs isNewUser={isNewUser} />
            {!user?.roleSettings?.hideStoreConnectionWizard && (
              <Hidden xsDown>
                <ToolbarButton
                  name="connect-store"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  to={"/connectStore/select"}
                  component={RouterLink}
                >
                  {t("nav.connectStore")}
                </ToolbarButton>
              </Hidden>
            )}
          </StyledToolbar>
        </Grid>
        {!user?.roleSettings?.hideStoreConnectionWizard && (
          <Hidden smUp>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <StyledToolbar variant="dense">
                <ToolbarButton
                  name="connect-store"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  to={"/connectStore/select"}
                  component={RouterLink}
                >
                  {t("nav.connectStore")}
                </ToolbarButton>
              </StyledToolbar>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </StyledAppBar>
  );
});

interface DesktopToolbarProps {
  isNewUser: boolean;
  logout: () => void;
}
const DesktopToolbar = memo<DesktopToolbarProps>(function DesktopToolbar({
  isNewUser,
  logout,
}) {
  const user = useTypedSelector((state) => get(state, "user"));
  const customLogo = useCustomLogo();
  const { t } = useTranslation();

  return (
    <StyledAppBar
      position="static"
      color="secondary"
      data-testid="desktop-toolbar"
      elevation={0}
    >
      <StyledToolbar disableGutters>
        <SmallLogo src={customLogo} />
        <ToolbarContent
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item md={6}>
            <NavTabs isNewUser={isNewUser} />
          </Grid>
          <Grid item md={6}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {!user?.roleSettings?.hideStoreConnectionWizard && (
                <ToolbarButton
                  name="connect-store"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  to={"/connectStore/select"}
                  component={RouterLink}
                >
                  {t("nav.connectStore")}
                </ToolbarButton>
              )}
              {!user?.role?.includes("external") && <NotificationsTab />}
              <Settings />
              <ProfileTab user={user} logout={logout} />
            </Box>
          </Grid>
        </ToolbarContent>
      </StyledToolbar>
    </StyledAppBar>
  );
});
