import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { hasMultipleAmazonMarketplaces } from "~/utils/userUtils";
import { loadInfo } from "~/store/user.redux";
import { setAmazonStoreName } from "../../store/connections/connectAmazon.redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 325px;
  width: 100%;
`;

export const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const AmazonUpdateStoreNamePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const amazonStore = useSelector((state) => state.amazonNewStore);
  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const errMsg = useRef(t("connectWizard.amazonFriendlyNameEmptyError"));
  const [loading, setLoading] = useState(true);
  const [storeName, setStoreName] = useState("");
  const disableStoreUpdate = !amazonStore.isNewStore ? true : false;
  const marketplace = urlSearchParams.get("marketplace");
  const isAmazonVendor = marketplace === "amazonVendorUpdateStoreName";

  const hasMultipleMarketplaces = async (selectedCountryCode, sellerId) => {
    const hasMarketplaces = await hasMultipleAmazonMarketplaces(
      user._id,
      sellerId,
      selectedCountryCode
    );

    return hasMarketplaces;
  };

  const redirectNextStep = async () => {
    if (isAmazonVendor) {
      urlSearchParams.set("marketplace", "amazonVendorConnectAdvertising");
    } else if (
      await hasMultipleMarketplaces(
        urlSearchParams.get("selectedCountry"),
        amazonStore.sellerId
      )
    ) {
      urlSearchParams.set("marketplace", "amazonAddCountry");
    } else {
      urlSearchParams.delete("marketplace");
      urlSearchParams.delete("selectedCountry");
      urlSearchParams.delete("step");
    }

    //refresh user info before redirecting
    await dispatch(loadInfo());
    history.push(`/connectStore/select?${urlSearchParams.toString()}`);
  };

  useEffect(() => {
    if (!amazonStore.isNewStore) {
      redirectNextStep();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const tempStoreName = event.target.value;
    if (tempStoreName !== "") errMsg.current = "";
    else errMsg.current = t("connectWizard.amazonFriendlyNameEmptyError");

    const trimmedName = tempStoreName.trim();
    setStoreName(trimmedName !== "" ? tempStoreName : "");
  };

  const handleSubmit = async () => {
    if (storeName === "") {
      errMsg.current = t("connectWizard.amazonFriendlyNameEmptyError");
      return;
    }

    errMsg.current = "";
    setLoading(true);

    try {
      await dispatch(
        setAmazonStoreName(
          amazonStore.countryCode,
          amazonStore.newMid,
          storeName.trim(),
          isAmazonVendor ? "amazon_vendor" : "amazon"
        )
      );

      if (amazonStore.error)
        errMsg.current = t("connectWizard.amazonFriendlyNameSaveError");
      else await redirectNextStep();
    } catch (err) {
      errMsg.current = t("connectWizard.amazonFriendlyNameSaveError");
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="amazon-updatestorename-wrapper">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Medium>{t("connectWizard.amazonFriendlyNameTitle")}</Medium>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <Grid item xs={12}>
                <Medium>{t("connectWizard.amazonFriendlyNameLabel")}</Medium>
              </Grid>
              <Grid item xs={12}>
                <Box pt={1} pb={1} width={1}>
                  <TextField
                    value={storeName}
                    name="storeName"
                    margin="none"
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    disabled={disableStoreUpdate}
                  />
                </Box>
              </Grid>
              <>
                {errMsg && errMsg.current && (
                  <Grid container item xs={12}>
                    <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
                  </Grid>
                )}
              </>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={1}>
                <InlineIconButton />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="subtitle1" color="secondary">
                  {t("connectWizard.amazonFriendlyNameCanChangeMessage")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <RaisedButton
                  disabled={storeName === ""}
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  {t("generic.continueButton")}
                </RaisedButton>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default AmazonUpdateStoreNamePage;
