import { Box, Grid } from "@material-ui/core";
/* eslint-disable no-undefined */
import React, { memo, useCallback, useEffect } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import moment from "moment";
import { useShippedRevenueTotalsQuery } from "~/store/mystore/vendor.redux";
import { useTranslation } from "react-i18next";

interface ShippedRevenueTotalsProps {
  mid: string;
  currentRange: Range;
  currentCurrency: string;
  currentCompare: COMPARISON_PERIOD;
  view: "sourcing" | "manufacturing";
  conditionalFormatting?: boolean;
  report?: boolean;
}

const ShippedRevenueTotals = memo<ShippedRevenueTotalsProps>(
  function ShippedRevenueTotals({
    mid,
    currentRange,
    currentCurrency,
    currentCompare,
    view,
    conditionalFormatting,
    report,
  }) {
    const { t } = useTranslation();

    const {
      brandAnalyticsTotals,
      loading,
      lastReportDate,
      lastUpdatedAt,
      currency,
    } = useShippedRevenueTotalsQuery(
      {
        currentRange,
        mid,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            brandAnalyticsTotals: data,
            loading: isFetching,
            lastReportDate: data?.lastReportDate,
            lastUpdatedAt: data?.lastUpdatedAt,
            currency: data?.currency,
          };
        },
      }
    );

    const fromDisplayDate = moment
      .unix(currentRange.fromDate)
      .tz(currentRange.timezone)
      .format("D MMM YY");
    const toDisplayDate = moment
      .unix(currentRange.toDate)
      .tz(currentRange.timezone)
      .format("D MMM YY");

    return (
      <Panel
        id="widget-shipped-totals"
        title={
          report
            ? `${t(
                `myStoresWidget.shippedRevenueSummary`
              )}:  ${fromDisplayDate} - ${toDisplayDate}`
            : `${t(`myStoresWidget.shippedRevenueSummary`)}`
        }
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t(`retailAnalytics.${view}View`)} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t(`retailAnalytics.${view}View`)}`
        }
        tooltip={undefined}
        content={
          <Box p={2}>
            {loading || !brandAnalyticsTotals || !brandAnalyticsTotals[view] ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...brandAnalyticsTotals[view]?.shippedRevenue}
                    title={t(`brandAnalytics.shippedRevenue`)}
                    currency={currency}
                    currentCurrency={currentCurrency}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...brandAnalyticsTotals[view]?.shippedCogs}
                    currency={currency}
                    currentCurrency={currentCurrency}
                    title={t(`brandAnalytics.shippedCogs`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={brandAnalyticsTotals[view]?.shippedUnits.current}
                    comparison={{
                      prior: brandAnalyticsTotals[view]?.shippedUnits.prior,
                    }}
                    title={t(`brandAnalytics.shippedUnits`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={
                      brandAnalyticsTotals[view]?.netPureProductMargin?.current
                    }
                    comparison={{
                      prior:
                        brandAnalyticsTotals[view]?.netPureProductMargin?.prior,
                    }}
                    title={t(`brandAnalytics.netPureProductMargin`)}
                    tooltip={t(`brandAnalytics.netPureProductMarginTooltip`)}
                    condensedComparison={true}
                    condensed={true}
                    percentage={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...brandAnalyticsTotals[view]?.avgPrice}
                    currency={currency}
                    currentCurrency={currentCurrency}
                    title={t(`brandAnalytics.avgPrice`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...brandAnalyticsTotals[view]?.avgCogs}
                    currency={currency}
                    currentCurrency={currentCurrency}
                    title={t(`brandAnalytics.avgCogs`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default ShippedRevenueTotals;
