import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

const TooltipPaper = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: 2px 10px;
`;

export default TooltipPaper;
