import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";

import OverviewChartPanel from "~/modules/widgets/advertising/overviewChartPanel";
import React from "react";
import get from "lodash/get";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OverviewChartWrapperProps {
  countryCode: string;
  marketplaceType: string;
  mid: string;
  report?: boolean;
  condensed?: boolean;
}

const OverviewChartWrapper = memo<OverviewChartWrapperProps>(
  function OverviewChartWrapper({
    mid,
    countryCode,
    marketplaceType,
    report,
    condensed,
  }) {
    const currentStore = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    return (
      <OverviewChartPanel
        mid={mid}
        countryCode={countryCode}
        marketplaceType={marketplaceType}
        marketplaceSubtype={currentStore?.marketplaceSubtype || marketplaceType}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        currentFilter={currentFilter}
        report={report}
        timezone={selectedTimezone}
        condensed={condensed}
        includeTax={includeTax}
      />
    );
  }
);

export default OverviewChartWrapper;
