import _ from "lodash";
import { formatCurrency } from "~/utils/currencyUtils";

// eslint-disable-next-line no-undefined
export const percentageFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

// eslint-disable-next-line no-undefined
export const percentageFormatterRounded = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const getCategory = (label) => (acc, category) => {
  const match = category.children.find((child) => child.label === label);
  if (match) {
    return match;
  } else {
    return category.children.reduce(getCategory(label), acc);
  }
};

export const getEventKeys =
  (parentCategory, parentInvert) => (acc, category) => {
    const invert = parentCategory.type !== category.type;
    const invertXor = (!parentInvert && invert) || (parentInvert && !invert);

    if (_.isEmpty(category.children)) {
      if (invertXor) {
        acc.eventKeySubtract.push(category.label);
      } else {
        acc.eventKeyAdd.push(category.label);
      }
      return acc;
    } else {
      return category.children.reduce(getEventKeys(category, invertXor), acc);
    }
  };

export const computeTotalValue = (category, events, useAbsoluteValue) => {
  if (_.isEmpty(category)) {
    return 0;
  }

  const initValue = {
    eventKeyAdd: [],
    eventKeySubtract: [],
  };

  const eventKeys = category.children.reduce(
    getEventKeys(category, false),
    initValue
  );

  const totalValue = events.reduce((acc, event) => {
    if (useAbsoluteValue && eventKeys.eventKeySubtract.includes(event.label)) {
      return acc + event.value;
    }

    // the same key can appear in both eventKeyAdd and eventKeySubtract (e.g.,
    // the "Tax" event type) - when this happens, the key effective gets
    // cancelled out by being added and then subtracted from returnVal
    let returnVal = acc;

    if (eventKeys.eventKeyAdd.includes(event.label)) {
      returnVal += event.value;
    }

    if (eventKeys.eventKeySubtract.includes(event.label)) {
      returnVal -= event.value;
    }

    return returnVal;
  }, 0);

  return totalValue;
};

const revenueLabels = [
  "productSalesLabel",
  "productSalesTaxLabel",
  "refundedSalesLabel",
  "reimbursementsLabel",
  "promotionsLabel",
  "otherIncomeLabel",
];

const expensesLabels = [
  "advertisingLabel",
  "sellingFeesLabel",
  "fulfilmentAndShippingLabel",
  "refundsAndReturnsLabel",
  "costOfGoodsLabel",
  "otherExpensesLabel",
];

const lengthOfLabel = -5;
const getTitleFromLabel = (label) => {
  const result = label.replace(/([A-Z])/g, " $1");
  const titleCase = result.charAt(0).toUpperCase() + result.slice(1);
  return titleCase.slice(0, lengthOfLabel);
};

const formatCurrencyValue = (
  value,
  currencyRates,
  sourceCurrency,
  currentCurrency
) =>
  isNaN(value) || value === 0.0
    ? "-"
    : formatCurrency(value, currencyRates, sourceCurrency, currentCurrency);

const getCSVRow = (
  label,
  value,
  totalRevenue,
  data,
  currencyRates,
  sourceCurrency,
  currentCurrency
) => ({
  title: getTitleFromLabel(label),
  value: formatCurrencyValue(
    value,
    currencyRates,
    sourceCurrency,
    currentCurrency
  ),
  percentageIncome:
    value !== 0 && totalRevenue > 0
      ? `${percentageFormatter.format((100 * value) / totalRevenue)}%`
      : "-",
  valuePerUnit: formatCurrencyValue(
    value / data.meta.unitCount,
    currencyRates,
    sourceCurrency,
    currentCurrency
  ),
});

const generateCSVRowData = (
  categories,
  label,
  totalRevenue,
  data,
  currencyRates,
  sourceCurrency,
  currentCurrency
) => {
  const category = categories.reduce(getCategory(label), []);
  const value = computeTotalValue(category, data.events);
  const csvRow = getCSVRow(
    label,
    value,
    totalRevenue,
    data,
    currencyRates,
    sourceCurrency,
    currentCurrency
  );
  return csvRow;
};

export const generateCSV = (
  data,
  categories,
  currencyRates,
  sourceCurrency,
  currentCurrency
) => {
  if (!_.isEmpty(data) && !_.isEmpty(categories)) {
    const totalRevenue = computeTotalValue(categories[0], data.events);
    const totalExpenses = computeTotalValue(categories[1], data.events);
    const headerData = [
      {
        title: "Orders",
        value: data.meta.orderCount,
      },
      {
        title: "Units",
        value: data.meta.unitCount,
      },
      {
        title: "Units Refunded",
        value: data.meta.refundedUnitCount,
      },
    ];

    const revenueRows = revenueLabels.map((rowLabel) =>
      generateCSVRowData(
        categories,
        rowLabel,
        totalRevenue,
        data,
        currencyRates,
        sourceCurrency,
        currentCurrency
      )
    );

    const expensesRows = expensesLabels.map((rowLabel) =>
      generateCSVRowData(
        categories,
        rowLabel,
        totalRevenue,
        data,
        currencyRates,
        sourceCurrency,
        currentCurrency
      )
    );

    const totalRevenueRow = getCSVRow(
      "totalRevenueLabel",
      totalRevenue,
      totalRevenue,
      data,
      currencyRates,
      sourceCurrency,
      currentCurrency
    );

    const totalExpensesRow = getCSVRow(
      "totalExpensesLabel",
      totalExpenses,
      totalRevenue,
      data,
      currencyRates,
      sourceCurrency,
      currentCurrency
    );

    const profit = totalRevenue - totalExpenses;
    const cogs = _.get(
      data.events.find((e) => e.label === "COGS"),
      "value",
      0
    );
    const cogsRefunded = _.get(
      data.events.find((e) => e.label === "COGS_REFUNDED"),
      "value",
      0
    );
    const estimatedPayout = profit + cogs - cogsRefunded;

    const profitRow = getCSVRow(
      "profitLabel",
      profit,
      totalRevenue,
      data,
      currencyRates,
      sourceCurrency,
      currentCurrency
    );

    const estimatedPayoutRow = getCSVRow(
      "estimatedPayoutLabel",
      estimatedPayout,
      totalRevenue,
      data,
      currencyRates,
      sourceCurrency,
      currentCurrency
    );

    const advertisingCategory = categories.reduce(
      getCategory("advertisingLabel"),
      []
    );
    const sellingFeesCategory = categories.reduce(
      getCategory("sellingFeesLabel"),
      []
    );
    const fulfilmentAndShippingCategory = categories.reduce(
      getCategory("fulfilmentAndShippingLabel"),
      []
    );
    const advertisingFee = computeTotalValue(advertisingCategory, data.events);
    const totalFees =
      advertisingFee +
      computeTotalValue(sellingFeesCategory, data.events) +
      computeTotalValue(fulfilmentAndShippingCategory, data.events);
    const percentageRefund =
      (100 * data.meta.refundedUnitCount) / data.meta.unitCount;

    const metricData = [
      {
        title: "TACOS %",
        value:
          totalRevenue > 0 && advertisingFee > 0
            ? `${percentageFormatter.format(
                (100 * advertisingFee) / totalRevenue
              )}%`
            : "-",
      },
      {
        title: "Total fees %",
        value:
          totalRevenue > 0 && totalFees > 0
            ? `${percentageFormatter.format((100 * totalFees) / totalRevenue)}%`
            : "-",
      },
      {
        title: "Refunds %",
        value:
          isFinite(percentageRefund) && percentageRefund !== 0
            ? `${percentageFormatter.format(percentageRefund)}%`
            : "-",
      },
    ];

    return [
      ...headerData,
      ...revenueRows,
      totalRevenueRow,
      ...expensesRows,
      totalExpensesRow,
      profitRow,
      estimatedPayoutRow,
      ...metricData,
    ];
  }
  return [];
};

const profitable = 5;
const unprofitable = 0;

export const getStatusFromProfit = (profitMargin, conditionalFormatting) =>
  conditionalFormatting === false
    ? "disabled"
    : profitMargin
    ? profitMargin >= profitable
      ? "success"
      : profitMargin >= unprofitable
      ? "warning"
      : "error"
    : "disabled";
