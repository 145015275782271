import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  DeductionBasis,
  DeductionType,
  HistoricalDeduction,
  useDeleteVendorDeductionsMutation,
  useUpdateVendorDeductionsMutation,
  useVendorDeductionsQuery,
} from "~/store/mystore/vendorProfitability.redux";
import { Delete, Edit } from "@material-ui/icons";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import ActionCell from "~/components/table/cells/actionCell";
import AdTable from "@components/adTable/table";
import Bold from "~/components/typography/bold";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import CurrencyInput from "@components/inputs/currencyInput";
import { CurrentStore } from "~/typedef/store";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import { PaginationArgs } from "~/typedef/pagination";
import RaisedButton from "@components/buttons/raisedButton";
import { TableCellProp } from "~/components/table/cellProps";
import { TextCell } from "@components/table/cells/textCell";
import { ValueCell } from "@components/table/cells/valueCell";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { setError } from "~/store/globalToast.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TableWrapper = styled(Box)`
  max-height: 35rem;
  height: 35rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledDateInput = styled(Input)`
  ${({ theme }) => (theme.darkModeEnabled ? `color-scheme: dark;` : null)}
`;

const StyledPaper = styled(Paper)`
  width: 70%;
  max-width: 38rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  min-height: 40rem;
`;

const GreyDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.border.main};
`;

const iconStyles = css`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 0.8em;
  height: 0.8em;
`;
const StyledEditIcon = styled(Edit)`
  ${iconStyles}
`;
const StyledDeleteIcon = styled(Delete)`
  ${iconStyles}
`;

export type SingleProductCogs = {
  sku: string;
  currency: string;
  value: number;
};

interface SyncDialogParams {
  store: CurrentStore;
  storeCurrency: string;
  timezone: string;
  open: boolean;
  onClose: () => void;
}

const DEFAULT_SORT_COLUMN = "effectiveDateFrom";

type FormInputs = {
  deductionId: null | string;
  deductionType: DeductionType;
  deductionBasis: DeductionBasis;
  deductionValue: number;
  currency: string;
  vendorCode: string;
  fromDate: string;
  toDate: string;
};

const DeductionsSyncDialog: React.FC<SyncDialogParams> = ({
  store,
  open,
  onClose,
  storeCurrency,
  timezone,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deductionTypeOptions = useMemo(() => {
    return Object.values(DeductionType).map((type) => ({
      value: type,
      label: t(`vendorProfitability.deduction_${type}`),
    }));
  }, [t]);

  const DEFAULT_FORM_INPUTS = {
    deductionId: null,
    deductionType: deductionTypeOptions[0].value,
    deductionBasis: DeductionBasis.NET_RECEIPTS,
    deductionValue: 0,
    currency: storeCurrency,
    vendorCode: "",
    fromDate: "",
    toDate: "",
  };
  const [formInputs, setFormInputs] = useState<FormInputs>(DEFAULT_FORM_INPUTS);
  const [markedForDelete, setMarkedForDelete] = useState<string | null>(null);

  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize: 5,
    pageIndex: 0,
    sortKey: DEFAULT_SORT_COLUMN,
    sortOrder: "desc",
  });

  const [updateDeduction] = useUpdateVendorDeductionsMutation();
  const [deleteDeduction] = useDeleteVendorDeductionsMutation();

  const { isLoading, data, count } = useVendorDeductionsQuery(
    {
      mid: store.merchantId,
      marketplaceType: store.marketplace,
      marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
      paginationParams,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          // Intentionally using isLoading
          // to prevent the table height changing on form submit / deletion
          // otherwise it results in a glitchy behaviour
          isLoading,
          data: data?.deductions ?? [],
          count: data?.count ?? 0,
        };
      },
    }
  );

  const onPageChange = useCallback(({ pageSize, pageIndex, sortBy }) => {
    setPaginationParams({
      pageSize,
      pageIndex,
      sortKey: sortBy[0]?.id || DEFAULT_SORT_COLUMN,
      sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
    });
  }, []);

  const deductionBasisChangeHandler = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormInputs({
      ...formInputs,
      deductionBasis: checked
        ? DeductionBasis.FIXED_AMOUNT
        : DeductionBasis.NET_RECEIPTS,
    });
  };

  const deductionValueChangeHandler = (value: number) => {
    !isNaN(value) &&
      setFormInputs({
        ...formInputs,
        deductionValue: value,
      });
  };

  const currencyChangeHandler = (currency: string) => {
    setFormInputs({
      ...formInputs,
      currency,
    });
  };

  const deductionTypeChangeHandler = (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setFormInputs({
      ...formInputs,
      deductionType: event.target.value as DeductionType,
    });
  };

  const vendorCodeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFormInputs({
      ...formInputs,
      vendorCode: event.target.value.toUpperCase().slice(0, 5),
    });
  };

  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    if (["fromDate", "toDate"].includes(fieldName)) {
      setFormInputs({
        ...formInputs,
        [fieldName]: fieldValue,
      });
    }
  };

  const editSelectHandler = (deductionId: string) => {
    const deduction = data.find((record) => record.deductionId == deductionId);
    if (deduction) {
      setFormInputs({
        deductionId: deduction.deductionId,
        deductionType: deduction.deductionType,
        deductionBasis: deduction.deductionBasis,
        deductionValue: deduction.deductionValue,
        currency: deduction.currency,
        vendorCode: deduction.vendorCode ?? "",
        fromDate: moment(deduction.effectiveDateFrom).format("YYYY-MM-DD"),
        toDate: moment(deduction.effectiveDateTo).format("YYYY-MM-DD"),
      });
    }
  };

  const clearFormHandler = () => {
    setFormInputs(DEFAULT_FORM_INPUTS);
  };

  const deleteHandler = () => {
    if (markedForDelete) {
      deleteDeduction({
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
        deductionId: markedForDelete,
        successMessage: t("vendorProfitability.deductionDeleteSuccessMessage"),
      })
        .unwrap()
        .then(() => {
          setMarkedForDelete(null);
        })
        .catch((error) => {
          // Do nothing
          // The Toast will be displayed by the RTK query handler
        });
    }
  };

  const submitHandler = () => {
    if (
      !formInputs.deductionBasis ||
      !formInputs.deductionType ||
      !formInputs.deductionValue ||
      !formInputs.fromDate ||
      !formInputs.toDate
    ) {
      setError(
        dispatch,
        "Please fill in the required form fields.",
        "CUSTOM",
        "VendorDeductions"
      );
      return;
    }

    const momentFromDate = moment(formInputs.fromDate);
    const momentToDate = moment(formInputs.toDate);
    if (
      !momentFromDate.isValid() ||
      !momentToDate.isValid() ||
      momentFromDate.isSameOrAfter(momentToDate)
    ) {
      setError(
        dispatch,
        "Invalid date selection.",
        "CUSTOM",
        "VendorDeductions"
      );
      return;
    }

    updateDeduction({
      mid: store.merchantId,
      marketplaceType: store.marketplace,
      marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
      deduction: {
        ...formInputs,
        fromDate: moment(formInputs.fromDate)
          .tz(timezone, true)
          .startOf("day")
          .unix(),
        toDate: moment(formInputs.toDate)
          .tz(timezone, true)
          .endOf("day")
          .unix(),
      },
      successMessage: t("vendorProfitability.deductionUpdateSuccessMessage"),
    })
      .unwrap()
      .then(() => {
        // Reset form inputs
        clearFormHandler();
      })
      .catch((error) => {
        // Do nothing
        // The Toast will be displayed by the RTK query handler
      });
  };

  const columns = [
    {
      Header: t(`vendorProfitability.effectiveDateFromLabel`),
      id: "effectiveDateFrom",
      accessor: (row: HistoricalDeduction) =>
        row.effectiveDateFrom
          ? moment(row.effectiveDateFrom).tz(timezone).format("DD/MM/YYYY")
          : "-",
      Cell: TextCell,
      isVisible: true,
    },
    {
      Header: t(`vendorProfitability.effectiveDateToLabel`),
      id: "effectiveDateTo",
      accessor: (row: HistoricalDeduction) =>
        row.effectiveDateTo
          ? moment(row.effectiveDateTo).tz(timezone).format("DD/MM/YYYY")
          : "-",
      Cell: TextCell,
      isVisible: true,
    },
    {
      Header: t(`vendorProfitability.rebateLabel`),
      id: "deductionType",
      accessor: (row: HistoricalDeduction) =>
        t(`vendorProfitability.deduction_${row.deductionType}`),
      Cell: TextCell,
      isVisible: true,
    },
    {
      Header: t(`vendorProfitability.typeLabel`),
      id: "deductionBasis",
      accessor: (row: HistoricalDeduction) =>
        row.deductionBasis === DeductionBasis.FIXED_AMOUNT
          ? getCurrencySymbol?.[
              row.currency as keyof typeof getCurrencySymbol
            ] ?? row.currency
          : "%",
      Cell: TextCell,
      isVisible: true,
      align: "center",
      cellJustify: "center" as const,
    },
    {
      Header: t(`vendorProfitability.valueLabel`),
      id: "deductionValue",
      accessor: "deductionValue",
      Cell: ValueCell,
      isVisible: true,
      align: "center",
      cellJustify: "center" as const,
    },
    {
      Header: t(`vendorProfitability.vendorCodeLabel`),
      id: "vendorCode",
      accessor: (row: HistoricalDeduction) => row.vendorCode ?? "-",
      Cell: TextCell,
      isVisible: true,
      align: "center",
      cellJustify: "center" as const,
    },
    {
      Header: "",
      accessor: "deductionId",
      id: "actions",
      Cell: ({ value }: TableCellProp<string>) => {
        const actions = [
          {
            icon: StyledEditIcon,
            action: () => {
              editSelectHandler(value);
            },
          },
          {
            icon: StyledDeleteIcon,
            action: () => {
              setMarkedForDelete(value);
            },
          },
        ];
        return <ActionCell actions={actions} />;
      },
      isVisible: true,
      disableSortBy: true,
    },
  ];

  return (
    <BaseDialog open={open} onClose={onClose} PaperComponent={StyledPaper}>
      <BaseDialogTitle
        onClose={onClose}
        style={{ padding: "0 0 0 12px", flex: "none" }}
      >
        <b>{t("vendorProfitability.deductionsDialog")}</b>
      </BaseDialogTitle>
      <DialogContent
        style={{
          padding: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
          overflowY: "initial",
        }}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t("vendorProfitability.deductionType")}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <NativeSelect
                name={"deductionType"}
                value={formInputs["deductionType"]}
                options={deductionTypeOptions}
                onChange={deductionTypeChangeHandler}
                autoFocus={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t("vendorProfitability.deductionBasis")}:
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              alignItems="center"
              justifyContent="flex-start"
              wrap="nowrap"
            >
              <Typography variant="body1">
                {t(`vendorProfitability.deductionBasisPercentage`)}
              </Typography>
              <Switch
                color="secondary"
                checked={
                  formInputs.deductionBasis === DeductionBasis.FIXED_AMOUNT
                }
                onChange={deductionBasisChangeHandler}
              />
              <Typography variant="body1">
                {t(`vendorProfitability.deductionBasisFixed`)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">
                {formInputs.deductionBasis === DeductionBasis.FIXED_AMOUNT ? (
                  <>{t("vendorProfitability.value")}</>
                ) : (
                  <>{t("vendorProfitability.valuePercentage")}</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {formInputs.deductionBasis === DeductionBasis.FIXED_AMOUNT ? (
                <CurrencyInput
                  switchCurrency={currencyChangeHandler}
                  currentCurrency={formInputs.currency}
                  value={formInputs.deductionValue}
                  setValue={deductionValueChangeHandler}
                  fullWidth={true}
                />
              ) : (
                <Box display="flex" flexDirection={"row"} alignItems="center">
                  <Input
                    type="number"
                    inputProps={{
                      min: 0,
                      step: "any",
                    }}
                    value={formInputs.deductionValue.toString()}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      deductionValueChangeHandler(
                        parseFloat(event.target.value)
                      )
                    }
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    fullWidth={true}
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t("vendorProfitability.vendorCode")}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Input
                type="text"
                name="vendorCode"
                value={formInputs.vendorCode}
                onChange={vendorCodeChangeHandler}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">
                {t("vendorProfitability.dates")}:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledDateInput
                type="date"
                name="fromDate"
                value={formInputs.fromDate}
                onChange={dateChangeHandler}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledDateInput
                type="date"
                name="toDate"
                value={formInputs.toDate}
                onChange={dateChangeHandler}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box mb={1} width="100%">
          <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
            <Grid item xs={12} sm={4}>
              <RaisedButton
                fullWidth={true}
                onClick={clearFormHandler}
                variant="outlined"
              >
                {t("generic.clearButton")}
              </RaisedButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <RaisedButton
                fullWidth={true}
                disabled={
                  !formInputs.deductionType ||
                  !formInputs.deductionBasis ||
                  !formInputs.deductionValue ||
                  !formInputs.fromDate ||
                  !formInputs.toDate
                }
                onClick={submitHandler}
                variant="contained"
                color="primary"
              >
                {t("generic.saveButton")}
              </RaisedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
      <GreyDivider />
      <Box py={1} px={2}>
        <Bold variant="h6">
          {t("vendorProfitability.deductionsTableTitle")}
        </Bold>
      </Box>
      <GreyDivider />
      <TableWrapper>
        <AdTable
          columns={columns}
          data={data}
          fetchData={onPageChange}
          loading={isLoading}
          sorting={true}
          pagination={true}
          pageSize={paginationParams.pageSize}
          pageCount={Math.ceil(count / paginationParams.pageSize)}
          disableScroll={true}
        />
      </TableWrapper>
      <ConfirmDialog
        open={Boolean(markedForDelete)}
        title={t("generic.confirmTitle")}
        onClose={() => setMarkedForDelete(null)}
        onConfirm={deleteHandler}
        content={
          <>
            <Typography variant="body1">
              {t("vendorProfitability.deductionDeleteConfirm")}
            </Typography>
          </>
        }
      />
    </BaseDialog>
  );
};

export default DeductionsSyncDialog;
