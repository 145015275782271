import {
  CampaignsTotals,
  Filter,
  GlobalVar,
  OverviewMarketing,
  Range,
} from "@typedef/store";

import { Dispatch } from "redux";
import { PaginationParams } from "@typedef/pagination";
import { User } from "@typedef/user";
import { api } from "../apiSlice";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const CLEAR_MARKETING_OVERVIEW_EXPANDED =
  "CLEAR_MARKETING_OVERVIEW_EXPANDED";
export const FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST =
  "FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST";
export const FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS =
  "FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS";

const initState: OverviewMarketing = {
  summaryExpanded: {
    containsComparisons: true,
    loading: false,
    rows: [],
    params: null,
    count: 0,
  },
};

interface Action {
  type: string;
  payload: any;
}

export const marketing = (
  state = initState,
  action: Action
): OverviewMarketing => {
  switch (action.type) {
    case CLEAR_MARKETING_OVERVIEW_EXPANDED:
      return {
        ...state,
        summaryExpanded: {
          containsComparisons: true,
          loading: false,
          rows: [],
          params: null,
          count: 0,
        },
      };
    case FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST:
      return {
        ...state,
        summaryExpanded: {
          ...state.summaryExpanded,
          loading: true,
        },
      };
    case FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS:
      return processMarketingOverviewExpanded(state, action.payload);
    default:
      return state;
  }
};

interface MarketingOverviewExpandedParams {
  user: User;
  reportDateFrom: number;
  reportDateTo: number;
  pageIndex?: number;
  pageSize?: number;
  sortKey?: string;
  sortOrder?: string;
  searchText?: string;
  filter: GlobalVar["currentFilter"];
  format?: string;
  currentRange: Object;
  timezone: string;
  includeTax: boolean;
}

export const fetchMarketingOverviewExpanded =
  (params: MarketingOverviewExpandedParams) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST });
    const res = await axios
      .get(`${baseUrl}/api/overview/advertising/campaign/summaryExpanded`, {
        params: {
          customerId: params.user._id,
          timezone: params.timezone,
          reportDateFrom: params.reportDateFrom,
          reportDateTo: params.reportDateTo,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          sortKey: params.sortKey,
          sortOrder: params.sortOrder,
          searchText: params.searchText,
          filter: params.filter,
          format: params.format,
          includeTax: params.includeTax,
          ...params.currentRange,
        },
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(dispatch, msg || statusText, get(err, "status"));
      });
    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return dispatch({
          type: FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS,
          payload: data,
        });
      } else {
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    return setError(dispatch);
  };

const processMarketingOverviewExpanded = (
  state: OverviewMarketing,
  payload: any
): OverviewMarketing => {
  const existingParams = state.summaryExpanded.params;
  const { params, data, count, containsComparisons } = payload;
  const { pageIndex } = params;
  if (existingParams) {
    const { pageIndex: lastPageIndex } = existingParams;
    if (pageIndex === lastPageIndex + 1) {
      const existingRows = state.summaryExpanded.rows || [];
      const totalRows = [...existingRows, ...data];
      return {
        ...state,
        summaryExpanded: {
          containsComparisons,
          params,
          count,
          rows: totalRows,
          loading: false,
        },
      };
    }
    return state;
  } else {
    if (pageIndex === 0) {
      return {
        ...state,
        summaryExpanded: {
          containsComparisons,
          count,
          rows: data,
          params,
          loading: false,
        },
      };
    }
  }
  return state;
};

export const clearMarketingOverviewExpanded =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({ type: CLEAR_MARKETING_OVERVIEW_EXPANDED });
  };

interface MarketingCampaignSummaryArgs {
  reportDateFrom: number;
  reportDateTo: number;
  priorFromDate: number;
  priorToDate: number;
  searchText: string;
  paginationParams: PaginationParams;
  filter: Filter;
  timezone: string;
  includeTax: boolean;
}

type MarketingCampaignSummaryMetrics = {
  store_id: string;
  cost: number;
  sales: number;
  acos: number;
  tacos: number;
  roas: number;
  impressions: number;
  clicks: number;
  attributed_sales: number;
  attributed_sales_same_sku: number;
  attributed_conversions: number;
  attributed_conversions_same_sku: number;
  attributed_units_ordered: number;
  attributed_units_ordered_same_sku: number;
  attributed_orders_ntb: number;
  attributed_orders_ntb_percentage: number;
  attributed_units_ordered_ntb: number;
  attributed_units_ordered_ntb_percentage: number;
  attributed_sales_ntb: number;
  attributed_sales_ntb_percentage: number;
  attributed_detail_page_views_clicks: number;
  click_through_rate: number;
  cost_per_click: number;
  conversion_rate: number;
  mid: string;
  countryCode: string;
  shopName: string;
  marketplace: string;
  marketplaceSubtype: string;
};

type MarketingCampaignSummaryRow =
  | {
      current: MarketingCampaignSummaryMetrics;
      prior?: MarketingCampaignSummaryMetrics;
    }
  | {
      mid: string;
      countryCode: string;
      shopName: string;
      marketplace: string;
      marketplaceSubtype: string;
      isDisconnected: boolean;
    };

interface MarketingCampaignSummaryResponse {
  containsComparisons: boolean;
  data: MarketingCampaignSummaryRow[];
  count: number;
}

interface MarketingOverviewChartArgs {
  reportDateFrom: number;
  reportDateTo: number;
  interval: string;
  timezone: string;
  filter: Filter;
  targetCurrency: string;
}

interface MarketingOverviewChartEntry {
  report_date: string;
  cost: number;
  attributed_sales: number;
  acos: number;
  impressions: number;
  clicks: number;
  attributed_conversions: number;
  attributed_units_ordered: number;
  attributed_units_ordered_ntb: number;
}

interface MarketingOverviewTotalsArgs {
  currentRange: Range;
  filter: Filter;
  targetCurrency: string;
  timezone: string;
  includeTax: boolean;
}

interface MarketingOverviewTotalsResponse {
  summaryData: {
    current: CampaignsTotals;
    prior: CampaignsTotals;
  };
}

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    marketingCampaignSummary: build.query<
      MarketingCampaignSummaryResponse,
      MarketingCampaignSummaryArgs
    >({
      query: (params) => {
        const { paginationParams, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/overview/advertising/campaign/summary`,
          method: "GET",
          params: {
            ...paginationParams,
            ...otherParams,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    marketingOverviewChart: build.query<
      MarketingOverviewChartEntry[],
      MarketingOverviewChartArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/overview/advertising/campaign/chart`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    marketingOverviewTotals: build.query<
      MarketingOverviewTotalsResponse,
      MarketingOverviewTotalsArgs
    >({
      query: ({ currentRange, ...otherParams }) => {
        return {
          url: `${baseUrl}/api/overview/advertising/campaign/totals`,
          method: "GET",
          params: {
            ...currentRange,
            ...otherParams,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useMarketingCampaignSummaryQuery,
  useMarketingOverviewChartQuery,
  useMarketingOverviewTotalsQuery,
} = extendedApiSlice;
