import {
  FETCH_CANCELLED_ORDERS_LIST,
  FETCH_CANCELLED_ORDERS_LIST_FETCHING,
  cancelledOrders,
} from "../overview/cancelledOrders.redux";

import { SingleStore } from "~/typedef/store";
import axios from "axios";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";

export const FETCH_STORE_PERFORMANCE_YTD_FETCHING =
  "FETCH_STORE_PERFORMANCE_YTD_FETCHING";
export const FETCH_STORE_PERFORMANCE_YTD_SUCCESS =
  "FETCH_STORE_PERFORMANCE_YTD_SUCCESS";
export const FETCH_STORE_PERFORMANCE_YTD_ERROR =
  "FETCH_STORE_PERFORMANCE_YTD_ERROR";

export const FETCH_STORE_TOP_PRODUCTS_LIST = "FETCH_STORE_TOP_PRODUCTS_LIST";
export const FETCH_STORE_TOP_PRODUCTS_LIST_FETCHING =
  "FETCH_STORE_TOP_PRODUCTS_LIST_FETCHING";

export const FETCH_STORE_TOP_PRODUCTS_CHART = "FETCH_STORE_TOP_PRODUCTS_CHART";
export const FETCH_STORE_TOP_PRODUCTS_CHART_FETCHING =
  "FETCH_STORE_TOP_PRODUCTS_CHART_FETCHING";

export const FETCH_ACCOUNT_HEALTH = "FETCH_ACCOUNT_HEALTH";
export const FETCH_ACCOUNT_HEALTH_FETCHING = "FETCH_ACCOUNT_HEALTH";
export const FETCH_STORE_DISPATCH_STATUS = "FETCH_STORE_DISPATCH_STATUS";
export const FETCH_STORE_DISPATCH_STATUS_FETCHING =
  "FETCH_STORE_DISPATCH_STATUS_FETCHING";

/** Overdue Orders List */
export const FETCH_OVERDUE_ORDER_LIST = "FETCH_OVERDUE_ORDER_LIST";
export const FETCH_OVERDUE_ORDER_LIST_FETCHING =
  "FETCH_OVERDUE_ORDER_LIST_FETCHING";

/** Out of stock */
export const FETCH_OUT_OF_STOCK = "FETCH_OUT_OF_STOCK";
export const FETCH_OUT_OF_STOCK_FETCHING = "FETCH_OUT_OF_STOCK_FETCHING";
export const FETCH_OUT_OF_STOCK_ERROR = "FETCH_OUT_OF_STOCK_ERROR";

/** Sales by day */
export const FETCH_STORE_SALES_BY_DAY = "FETCH_STORE_SALES_BY_DAY";
export const FETCH_STORE_SALES_BY_DAY_FETCHING =
  "FETCH_STORE_SALES_BY_DAY_FETCHING";

/** Sales by brand */
export const FETCH_SALES_BY_BRAND = "FETCH_SALES_BY_BRAND";
export const FETCH_SALES_BY_BRAND_FETCHING = "FETCH_SALES_BY_BRAND_FETCHING";

/** Average by day */
export const FETCH_STORE_AVERAGE_SALES_BY_DAY =
  "FETCH_STORE_AVERAGE_SALES_BY_DAY";
export const FETCH_STORE_AVERAGE_SALES_BY_DAY_FETCHING =
  "FETCH_STORE_AVERAGE_SALES_BY_DAY_FETCHING";

export const FETCH_MIN_DATE_STORE = "FETCH_MIN_DATE_STORE";

/** Purchase orders over time */
export const FETCH_PO_PERFORMANCE = "FETCH_PO_PERFORMANCE ";
export const FETCH_PO_PERFORMANCE_FETCHING = "FETCH_PO_PERFORMANCE_FETCHING";
export const FETCH_PO_PERFORMANCE_ERROR = "FETCH_PO_PERFORMANCE_ERROR";

export const FETCH_PO_PERFORMANCE_SHIP_DATE = "FETCH_PO_PERFORMANCE_SHIP_DATE ";
export const FETCH_PO_PERFORMANCE_SHIP_DATE_FETCHING =
  "FETCH_PO_PERFORMANCE_SHIP_DATE_FETCHING";
export const FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR =
  "FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR";

/** Custom tags for a store/s */
export const FETCH_CUSTOM_TAGS_STORE = "FETCH_CUSTOM_TAGS_STORE";
export const ADD_CUSTOM_TAGS_STORE = "ADD_CUSTOM_TAGS_STORE";
export const FETCH_CUSTOM_TAGS_STORE_FETCHING =
  "FETCH_CUSTOM_TAGS_STORE_FETCHING";

export const FETCH_FILTERED_STORES = "FETCH_FILTERED_STORES";
export const FETCH_FILTERED_STORES_FETCHING = "FETCH_FILTERED_STORES_FETCHING";

export const FETCH_ALL_STORES = "FETCH_ALL_STORES";
export const FETCH_ALL_STORES_FETCHING = "FETCH_ALL_STORES_FETCHING";

axios.defaults.withCredentials = true;

const initState: SingleStore = {
  salesPerformance: {
    chartData: [],
    current: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    prior: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    currency: "",
    fetching: false,
  },
  salesPerformanceYTD: {
    chartData: [],
    current: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    prior: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    currency: "",
    fetching: false,
  },
  accountHealth: {
    fetching: false,
  },
  overdueOrdersList: {
    fetching: false,
  },
  salesByBrand: {
    fetching: false,
  },
  salesByDay: {
    fetching: false,
  },
  averageSalesByDay: {
    fetching: false,
  },
  outOfStockProducts: {
    fetching: false,
    error: null,
    filter: null,
    mid: null,
  },
  minDate: null,
  customTags: {
    fetching: false,
    tags: [],
  },
  filteredStores: {
    fetching: false,
    stores: [],
  },
  allStores: {
    fetching: false,
    stores: [],
  },
  topProductsList: {
    fetching: false,
    data: [],
  },
  purchaseOrdersPerformance: {
    fetching: false,
    chartData: [],
    current: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    prior: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    currency: "",
  },
  purchaseOrdersPerformanceShipDate: {
    fetching: false,
    chartData: [],
    current: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    prior: {
      sales: 0,
      orders: 0,
      unitsSold: 0,
      avgOrderValue: 0,
    },
    currency: "",
  },
};

export const mystore = (state = initState, action: any) => {
  switch (action.type) {
    /** Sales Performance YTD */
    case FETCH_STORE_PERFORMANCE_YTD_FETCHING:
      return {
        ...state,
        salesPerformanceYTD: {
          ...state.salesPerformanceYTD,
          fetching: true,
        },
      };
    case FETCH_STORE_PERFORMANCE_YTD_SUCCESS:
      return {
        ...state,
        salesPerformanceYTD: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_STORE_PERFORMANCE_YTD_ERROR:
      return {
        ...state,
        salesPerformanceYTD: {
          fetching: false,
          chartData: [],
          currentSales: 0,
          priorSales: 0,
        },
      };

    /** Account Health */
    case FETCH_ACCOUNT_HEALTH:
      const accountHealth = {
        fetching: false,
        ...action.payload,
      };
      return { ...state, accountHealth };
    case FETCH_ACCOUNT_HEALTH_FETCHING:
      return {
        ...state,
        accountHealth: {
          ...state.accountHealth,
          fetching: true,
        },
      };

    /** Dispatch Status */
    case FETCH_STORE_DISPATCH_STATUS:
      return {
        ...state,
        dispatchStatus: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_STORE_DISPATCH_STATUS_FETCHING:
      return {
        ...state,
        dispatchStatus: {
          ...state.dispatchStatus,
          fetching: true,
        },
      };

    /** Sales By Brand */
    case FETCH_SALES_BY_BRAND:
      return {
        ...state,
        salesByBrand: {
          data: action.payload,
          fetching: false,
        },
      };
    case FETCH_SALES_BY_BRAND_FETCHING:
      return {
        ...state,
        salesByBrand: {
          ...state.salesByBrand,
          fetching: true,
        },
      };

    /** Sales By Day */
    case FETCH_STORE_SALES_BY_DAY:
      return {
        ...state,
        salesByDay: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_STORE_SALES_BY_DAY_FETCHING:
      return {
        ...state,
        salesByDay: {
          ...state.salesByDay,
          fetching: true,
        },
      };

    /** Average Sales By Day */
    case FETCH_STORE_AVERAGE_SALES_BY_DAY:
      return {
        ...state,
        averageSalesByDay: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_STORE_AVERAGE_SALES_BY_DAY_FETCHING:
      return {
        ...state,
        averageSalesByDay: {
          ...state.averageSalesByDay,
          fetching: true,
        },
      };

    /** Overdue Orders */
    case FETCH_OVERDUE_ORDER_LIST:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_OVERDUE_ORDER_LIST_FETCHING:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: true,
        },
      };
    /** Cancelled orders list */
    case FETCH_CANCELLED_ORDERS_LIST_FETCHING:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: true,
        },
      };

    case FETCH_CANCELLED_ORDERS_LIST:
      const cancelledOrderData = cancelledOrders(
        get(state, "overdueOrdersList.cancelledOrders"),
        action
      );
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: get(cancelledOrderData, "fetching"),
          cancelledOrders: get(cancelledOrderData, "data"),
        },
      };
    case FETCH_OUT_OF_STOCK:
      if (action.payload.mid !== state.outOfStockProducts.mid) {
        return state;
      }
      return {
        ...state,
        outOfStockProducts: {
          ...action.payload.data,
          fetching: false,
          error: null,
          mid: action.payload.mid,
        },
      };
    case FETCH_OUT_OF_STOCK_FETCHING:
      return {
        ...state,
        outOfStockProducts: {
          ...state.outOfStockProducts,
          fetching: true,
          error: null,
          mid: action.payload.mid,
        },
      };
    case FETCH_OUT_OF_STOCK_ERROR:
      return {
        ...state,
        outOfStockProducts: {
          fetching: false,
          error: action.payload,
          mid: null,
        },
      };

    /** Purchase Orders Performance */
    case FETCH_PO_PERFORMANCE: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          fetching: false,
          ...action.payload,
        },
      };
    }

    case FETCH_PO_PERFORMANCE_FETCHING: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          ...state.purchaseOrdersPerformance,
          fetching: true,
        },
      };
    }
    case FETCH_PO_PERFORMANCE_ERROR: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          chartData: [],
          current: {
            sales: 0,
            orders: 0,
            unitsSold: 0,
            avgOrderValue: 0,
          },
          prior: {
            sales: 0,
            orders: 0,
            unitsSold: 0,
            avgOrderValue: 0,
          },
          currency: "",
          fetching: false,
        },
      };
    }

    case FETCH_PO_PERFORMANCE_SHIP_DATE: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          fetching: false,
          ...action.payload,
        },
      };
    }

    case FETCH_PO_PERFORMANCE_SHIP_DATE_FETCHING: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          ...state.purchaseOrdersPerformanceShipDate,
          fetching: true,
        },
      };
    }
    case FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          chartData: [],
          current: {
            sales: 0,
            orders: 0,
            unitsSold: 0,
            avgOrderValue: 0,
          },
          prior: {
            sales: 0,
            orders: 0,
            unitsSold: 0,
            avgOrderValue: 0,
          },
          currency: "",
          fetching: false,
        },
      };
    }

    /** Earliest date of orders */
    case FETCH_MIN_DATE_STORE:
      return {
        ...state,
        ...action.payload,
      };

    /** Custom tags by store*/
    case ADD_CUSTOM_TAGS_STORE:
      return {
        ...state,
        customTags: {
          tags: uniqBy([...action.payload, ...state.customTags.tags], "mid"),
          fetching: false,
        },
      };
    case FETCH_CUSTOM_TAGS_STORE:
      return {
        ...state,
        customTags: {
          tags: action.payload,
          fetching: false,
        },
      };
    case FETCH_CUSTOM_TAGS_STORE_FETCHING:
      return {
        ...state,
        customTags: {
          ...state.customTags,
          fetching: true,
        },
      };
    case FETCH_FILTERED_STORES:
      return {
        ...state,
        filteredStores: {
          stores: action.payload,
          fetching: false,
        },
      };
    case FETCH_FILTERED_STORES_FETCHING:
      return {
        ...state,
        filteredStores: {
          ...state.filteredStores,
          fetching: true,
        },
      };
    case FETCH_ALL_STORES:
      return {
        ...state,
        allStores: {
          stores: action.payload,
          fetching: false,
        },
      };
    case FETCH_ALL_STORES_FETCHING:
      return {
        ...state,
        allStores: {
          ...state.allStores,
          fetching: true,
        },
      };
    case FETCH_STORE_TOP_PRODUCTS_LIST:
      return {
        ...state,
        topProductsList: {
          data: action.payload,
          fetching: false,
        },
      };
    case FETCH_STORE_TOP_PRODUCTS_LIST_FETCHING:
      return {
        ...state,
        topProductsList: {
          ...state.topProductsList,
          fetching: true,
        },
      };
    case FETCH_STORE_TOP_PRODUCTS_CHART:
      return {
        ...state,
        productChart: {
          data: action.payload.data,
          currency: action.payload.currency,
          fetching: false,
        },
      };
    case FETCH_STORE_TOP_PRODUCTS_CHART_FETCHING:
      return {
        ...state,
        productChart: {
          ...state.topProductsList,
          fetching: true,
        },
      };

    default:
      return state;
  }
};
