import { Box, Grid, Theme, Typography, useTheme } from "@material-ui/core";
import {
  CurrentStore,
  NotificationPieChartData,
  NotificationsPieChart,
} from "~/typedef/store";
import {
  NotificationSettingOverride,
  settingsOverrides,
} from "~/pages/notifications/notificationSettings";
import { TFunction, useTranslation } from "react-i18next";

import NotificationsPieChartLegendItem from "./notificationsPieChartLegendItem";
import PieChart from "./pieChart";
import React, { memo, useEffect } from "react";
import {
  getNotificationsChartData,
  getNotificationsChartDataByStore,
} from "~/store/notifications.redux";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import Panel from "~/components/panel/panel";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import GenericTotal from "~/components/totals/genericTotal";
import NotificationsImage from "~/img/notifications.png";
import styled from "styled-components";
import usePrevious from "~/hooks/usePrevious";
import { isEqual } from "lodash";

const ImageWrapper = styled.img`
  width: 300px;
  display: flex;
`;

interface ChartDataEntry {
  name: string;
  value: number;
  percentage: number;
  color: string;
  tooltipContent?: string;
  legendContent?: JSX.Element;
}

export const getNotificationsPieChartData = ({
  pieChartRes,
  theme,
  t,
}: {
  pieChartRes: NotificationsPieChart | undefined;
  theme: Theme;
  t: TFunction<"translation">;
}): ChartDataEntry[] => {
  return (
    pieChartRes?.data
      ?.reduce(
        (
          chartRow: ChartDataEntry[],
          row: NotificationPieChartData,
          index: number
        ) => {
          const overrides: NotificationSettingOverride = settingsOverrides[
            row._id
          ] as NotificationSettingOverride;

          const foundIndex: number | undefined = chartRow.findIndex((r) =>
            overrides?.isConnectionIssue
              ? r.name === "CONNECTION_ISSUE"
              : r.name === row._id
          );

          if (foundIndex >= 0) {
            chartRow[foundIndex].value += Number(row.count) || 0;
            chartRow[foundIndex].percentage = Math.floor(
              pieChartRes?.total
                ? (chartRow[foundIndex].value / Number(pieChartRes.total)) * 100
                : 0
            );
          } else {
            const newChartRow = {
              name: row._id as string,
              value: Number(row.count),
              percentage: Math.floor(
                (Number(row.count) / Number(pieChartRes.total)) * 100
              ),
              color: Object.values(theme.palette.chart)[index],
            };

            if (overrides?.isConnectionIssue) {
              newChartRow.name = "CONNECTION_ISSUE";
            }

            chartRow.push(newChartRow);
          }

          return chartRow;
        },
        []
      )
      ?.map((r) => {
        const labelledRow: ChartDataEntry = {
          ...r,
          name:
            r.name === "CONNECTION_ISSUE"
              ? t(`notificationSettingRowTitle.connectionIssue`)
              : t(`notificationSettingRowTitle.${r.name}`),
        };

        return {
          ...labelledRow,
          tooltipContent: `${labelledRow.name}: ${labelledRow.percentage}%`,
          legendContent: (
            <NotificationsPieChartLegendItem
              name={labelledRow.name}
              value={labelledRow.value}
              piePercentage={labelledRow.percentage}
              noMargin
            />
          ),
        };
      }) || []
  );
};

const NotificationsPieChart = ({
  title,
  inPanel = true,
  flexDirection = "row",
  store,
}: {
  title?: string;
  inPanel?: boolean;
  flexDirection?: string;
  store?: CurrentStore;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const previousStore: CurrentStore | undefined = usePrevious(store);

  const user = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );
  const pieChartRes = useTypedSelector(
    (state) => state?.notifications?.pieChart
  );

  const pieChartResByStore = useTypedSelector(
    (state) => state?.notifications?.pieChartByStore
  );

  const chartData: ChartDataEntry[] = getNotificationsPieChartData({
    pieChartRes: store ? pieChartResByStore : pieChartRes,
    theme,
    t,
  });

  useEffect(() => {
    if (!store && filteredStores?.length) {
      dispatch(
        getNotificationsChartData(
          filteredStores.map((store) => store.merchantId)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (store?.merchantId && !isEqual(previousStore, store)) {
      dispatch(
        getNotificationsChartDataByStore({
          mid: store.merchantId,
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
          marketplaceCountry: store.marketplaceCountry,
        })
      );
    }
  }, [previousStore, store]);

  const footerLink = {
    url: "/notifications/list",
    label: t("generic.viewAllLink"),
  };

  const showChart =
    Boolean(chartData.length) && !user?.role?.includes("external");

  if (inPanel) {
    return (
      <Panel
        id="widget-mobile-vs-browser-totals"
        title={t("notificationsWidget.unresolvedNotifications")}
        tooltip={t("notificationsWidget.unresolvedNotifications")}
        footerLink={store && showChart ? footerLink : undefined}
        content={
          Boolean(
            store ? pieChartResByStore?.fetching : pieChartRes?.fetching
          ) ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : !showChart ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              p={1}
            >
              <ImageWrapper src={NotificationsImage} />
              <Box display="flex" width="250px">
                <Typography variant="body1" align="center">
                  {t("notifications.welcomeMessage")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Grid container item xs={12} alignItems="center">
              <Grid container item xs={12} alignItems="center">
                {" "}
                <Box pt={2} />
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <GenericTotal
                  title={t("notificationsTab.pieChartHeader")}
                  number={chartData.reduce(
                    (acc, datum) => acc + parseInt(`${datum.value}`),
                    0
                  )}
                  condensed={true}
                  textAlign="center"
                  customSize={40}
                  titleVariant="body1"
                  lockLayout={true}
                />
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <PieChart
                  {...{
                    data: chartData,
                    flexDirection,
                    condensed: Boolean(flexDirection === "column"),
                  }}
                />
              </Grid>
            </Grid>
          )
        }
      />
    );
  }

  return (store ? pieChartResByStore?.fetching : pieChartRes?.fetching) ? (
    <Box
      p={2}
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingIndicator />
    </Box>
  ) : !showChart ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      p={1}
    >
      <ImageWrapper src={NotificationsImage} />
      <Box display="flex" width="250px">
        <Typography variant="body1" align="center">
          {t("notifications.welcomeMessage")}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={12} alignItems="center">
        <GenericTotal
          title={t("notificationsTab.pieChartHeader")}
          number={chartData.reduce(
            (acc, datum) => acc + parseInt(`${datum.value}`),
            0
          )}
          condensed={true}
          textAlign="center"
          customSize={40}
          titleVariant="body1"
          lockLayout={true}
        />
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <PieChart
          {...{
            title,
            data: chartData,
            flexDirection,
            condensed: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default memo(NotificationsPieChart);
