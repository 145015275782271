import countryLookup from "country-code-lookup";
import { getCurrencyByCountryCode } from "mm-utils-frontend";

export interface CountryDetail {
  country: string;
  iso3: string;
}

export const getCountriesByCurrencyCode = (
  currency: string
): CountryDetail[] => {
  const countries = Object.keys(getCurrencyByCountryCode).filter(
    (countryCode) => getCurrencyByCountryCode[countryCode] === currency
  );

  return countries.map((iso3) => {
    const countryDetails = countryLookup.byIso(iso3);
    return { country: countryDetails?.country || iso3, iso3 };
  });
};
