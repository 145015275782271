import React, { useMemo, useState } from "react";
import { get, isEmpty } from "lodash";

import { Box } from "@material-ui/core";
import { CustomGroup } from "@typedef/customGroups";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ObjectFilterSelect from "~/components/select/objectFilterSelect";
import { Store } from "@typedef/store";
import { useCustomGroupsQuery } from "~/store/customGroups/reducer.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CustomGroupsSelectProps {
  setSelected: (selectedGroups: CustomGroup[]) => void;
  selected: CustomGroup[];
  store?: Store;
}

const CustomGroupsSelect: React.FC<CustomGroupsSelectProps> = ({
  setSelected,
  selected,
  store,
}) => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => get(state, "user"));

  const { customGroups, customGroupsFetching } = useCustomGroupsQuery(
    {
      mid: store?.merchantId || "",
      userId: user?._id,
    },
    {
      skip: !store?.merchantId || !user?._id,
      selectFromResult: ({ data, isFetching }) => ({
        customGroups: data?.groups || [],
        customGroupsFetching: isFetching,
      }),
    }
  );

  const [groupSearch, setGroupSearch] = useState("");
  const customGroupOptions = useMemo(() => {
    if (!isEmpty(groupSearch)) {
      return customGroups?.filter(
        (customGroup) =>
          !customGroup.groupName
            .toLowerCase()
            .indexOf(groupSearch.toLowerCase())
      );
    } else {
      return customGroups || [];
    }
  }, [customGroups, groupSearch]);

  const handleSelect = (
    values: { groupId: string | number }[],
    customGroups: { groupId: string | number }[]
  ) => {
    const newSelectedGroups = customGroups?.filter((customGroup) =>
      values.find((value) => value.groupId === customGroup.groupId)
    );
    setSelected((newSelectedGroups || []) as any);
  };

  return (
    <>
      {customGroupsFetching ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <ObjectFilterSelect
          {...{
            options: customGroupOptions,
            selectedOptions: selected || [],
            filterTitle: t("customGroupsChart.selectTitle"),
            handleSelect,
            groups: customGroups,
            isFullWidth: true,
            searchable: true,
            setSearchText: setGroupSearch,
            displayKey: "groupName",
            idKey: "groupId",
          }}
        />
      )}
    </>
  );
};

export default CustomGroupsSelect;
