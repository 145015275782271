import { CHART_TYPE, ChartOption } from "./salesByCustomerType";

import React from "react";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { useTranslation } from "react-i18next";

const RepeatPurchaseChartTypeSelect = ({
  setSelected,
  selected,
}: {
  setSelected: (value: any) => void;
  selected: any;
}) => {
  const { t } = useTranslation();
  const CHART_OPTIONS: ChartOption[] = [
    {
      value: CHART_TYPE.Customers,
      label: t("myStoresWidget.salesByCustomerType.customers"),
    },
    {
      value: CHART_TYPE.Revenue,
      label: t("myStoresWidget.salesByCustomerType.revenue"),
    },
    {
      value: CHART_TYPE.Orders,
      label: t("myStoresWidget.salesByCustomerType.orders"),
    },
  ];

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setSelected(option);
    }
  };

  return (
    <TableFilter
      currentValue={selected?.value}
      handleChange={switchChartType}
      options={CHART_OPTIONS}
      width="200px"
    />
  );
};

export default RepeatPurchaseChartTypeSelect;
