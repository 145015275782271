import { Box, IconButton, TextField } from "@material-ui/core";

import { ArrowBack } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Medium from "~/components/typography/medium";
import MercadoLibreAuthorise from "~/modules/authorise/mercadoLibreAuthorise";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { baseUrl } from "../../configs";
import { getCountryCodeISO3ToName } from "~/utils/countryUtils";
import { marketplaceConstants } from "mm-mercado-libre-common/dist/shared/marketplaceConstants";
import { strcmp } from "@merchantspring/common";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin-left: -1.5rem;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const BackButton = styled(IconButton)`
  margin: -15px 0 0 -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const topCountries = [
  {
    value: "USA",
    label: "Global Selling",
  },
];

const countries = Object.keys(marketplaceConstants)
  .filter((countryCode) => !topCountries.find((b) => b.value === countryCode))
  .map((countryCode) => ({
    value: countryCode,
    label: getCountryCodeISO3ToName(countryCode),
  }))
  .sort((a, b) => strcmp(a.label, b.label));

const MercadoLibreConnectPage = ({ onSuccess }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const marketplace = urlSearchParams.get("marketplace");

  const [shopName, setShopName] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const errMsg = React.useRef("");

  const onAuthError = () => {
    errMsg.current = t("connectWizard.connectionError");
  };

  return (
    <GridWrapper id="mercadolibre-connect-wrapper">
      <Grid container spacing={5}>
        <Grid item xs={2}>
          <BackButton onClick={history.goBack} id="back-button">
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item xs={10}>
          <TitleWrapper>
            <Medium>{t("connectWizard.mercadoLibreLinkTitle")}</Medium>
          </TitleWrapper>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/mercadolibre.jpg`} />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start">
          <Medium>{t("connectWizard.mercadoLibreCountryLabel")}</Medium>
          <Box pt={1} pb={1} width={1}>
            <NativeSelect
              name={"countryCode"}
              value={countryCode}
              topOptions={topCountries}
              options={countries}
              onChange={(e) => {
                const selectedCountry = topCountries
                  .concat(countries)
                  .find((country) => country.value === e.target.value);
                if (selectedCountry) {
                  setCountryCode(selectedCountry.value);
                }
              }}
              autoFocus={true}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start">
          <Typography variant="body2">
            {t("connectWizard.shopNameTitle")}
          </Typography>
          <AccountNameWrapper
            fullWidth
            label={t("connectWizard.mercadoLibreShopNameLabel")}
            margin="normal"
            required
            onChange={(e) => {
              setShopName(e.target.value);
            }}
          />
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <MercadoLibreAuthorise
              shopName={shopName}
              countryCode={countryCode}
              disabled={!countryCode || !shopName}
              onSuccess={onSuccess}
              onError={onAuthError}
            >
              {t("connectWizard.authorizeButton")}
            </MercadoLibreAuthorise>
          </Grid>
        </Grid>
        <>
          {errMsg && errMsg.current && (
            <Grid container item xs={12}>
              <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

MercadoLibreConnectPage.propTypes = {
  onSuccess: PropTypes.func,
};

export default MercadoLibreConnectPage;
