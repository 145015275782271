import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";

export const InlineIconButton = styled(InfoOutlined)`
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ColouredInlineIconButton = styled(InlineIconButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const LightInlineIconButton = styled(InfoOutlined)`
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
