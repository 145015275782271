import { Box, Paper } from "@material-ui/core";
import React, { useState } from "react";

import BillingHistoryPanel from "../../../modules/subscription/accountInfo/billingHistoryPanel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CombinedLink from "~/components/links/link";
import CurrentSubscription from "../../../modules/subscription/plan/currentSubscription";
import { Dispatch } from "redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import { LEGACY_PLAN_ID } from "~/modules/subscription/plan/plan";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PaymentMethodPanel from "../../../modules/subscription/accountInfo/paymentMethodPanel";
import SubscriptionPlanPanel from "../../../modules/subscription/subscriptionPlanPanel";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import { getDueDate } from "../../../modules/subscription/plan/plan";
import moment from "moment-timezone";
import styled from "styled-components";
import { updatePaymentMethod } from "~/store/subscriptions.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SuccessCheckCircle = styled(CheckCircle)`
  ${({ theme }) => `
    color: ${theme.palette.success.main};
    margin: 0 4px;
  `}
`;

const GridMargin = styled(Grid)`
  margin-bottom: 1rem;
`;

const StyledPaper = styled(Paper)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const handlePaymentMethodSubmit =
  (dispatch: Dispatch<any>, subscription: { subscriptionId: string }) =>
  (token: { nonce: number }) => {
    dispatch(updatePaymentMethod(subscription.subscriptionId, token.nonce));
  };

const BillingSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subscriptions = useTypedSelector((state) => state.subscriptions);
  const [expanded, setExpanded] = useState("");
  const { currentPlan, currentSubscription, loading } = subscriptions;

  const handlePanelChange =
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };
  const nextBillingDate = get(currentSubscription, "nextBillingDate");
  const status = get(currentSubscription, "status");

  if (!currentPlan || currentPlan.planId === LEGACY_PLAN_ID) {
    return (
      <StyledPaper>
        <Grid container>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Box p={2}>
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <SuccessCheckCircle />
                <Typography variant="body1" align="center" color="textPrimary">
                  {t("settings.billing.complimentaryMessage", {
                    date: moment(nextBillingDate).format("DD MMM YYYY"),
                  })}{" "}
                  <CombinedLink to="/subscription/plans">
                    {t("settings.billing.complimentaryExtendLink")}
                  </CombinedLink>
                </Typography>
              </Grid>
            </Box>
          )}
        </Grid>
      </StyledPaper>
    );
  }

  return (
    <Grid container>
      <GridMargin item xs={12}>
        <CurrentSubscription
          currentPlan={currentPlan}
          dueDate={getDueDate(
            get(currentPlan, "planId"),
            status,
            nextBillingDate
          )}
        />
      </GridMargin>
      <Grid item xs={12}>
        <ExpansionPanel
          expanded={expanded === "subscription"}
          onChange={handlePanelChange("subscription")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" color="textPrimary">
              {t("settings.billing.changeSubscriptionTitle")}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <SubscriptionPlanPanel
                subscription={currentSubscription}
                currentPlan={currentPlan}
              />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "payment"}
          onChange={handlePanelChange("payment")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" color="textPrimary">
              {t("settings.billing.paymentMethodTitle")}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <PaymentMethodPanel
                plan={currentPlan}
                subscription={currentSubscription}
                handleSubmit={handlePaymentMethodSubmit(
                  dispatch,
                  currentSubscription
                )}
              />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "history"}
          onChange={handlePanelChange("history")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" color="textPrimary">
              {t("settings.billing.billingHistoryTitle")}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <BillingHistoryPanel subscription={currentSubscription} />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
};

export default BillingSetting;
