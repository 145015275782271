import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "~/components/toolbars/breadcrumbs";
import CurrentSubscription from "~/modules/subscription/plan/currentSubscription";
import React from "react";
import { SubscriptionPageBlock } from "../../modules/subscription/subscriptionStyledComponents";
import SubscriptionPlans from "~/modules/subscription/plan/subscriptionPlans";
import { fetchPlans } from "~/store/subscriptions.redux";
import get from "lodash/get";
import { getDueDate } from "~/modules/subscription/plan/plan";
import size from "lodash/size";
import { usePlan } from "~/modules/subscription/plan/usePlan";
import { useTranslation } from "react-i18next";

const SubscriptionPricingPage = () => {
  const { t } = useTranslation();
  const loading = useSelector((state) => get(state, "subscriptions.loading"));
  const plans = useSelector((state) => get(state, "subscriptions.plans"));

  const currentPlan = usePlan();

  const nextBillingDate = useSelector((state) =>
    get(state, "subscriptions.currentSubscription.nextBillingDate")
  );

  const status = useSelector((state) =>
    get(state, "subscriptions.currentSubscription.status")
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchPlans());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading || size(plans) === 0 ? (
    <CircularProgress />
  ) : (
    <SubscriptionPageBlock>
      <Box pt={1} pb={2}>
        <Breadcrumbs
          selectedItem={{
            breadcrumb: [
              { text: t("profile.myAccount"), link: "/setting" },
              { text: t("subscription.plan.mainTitle") },
            ],
            currentPage: t("subscription.plan.mainTitle"),
          }}
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CurrentSubscription
            currentPlan={currentPlan}
            dueDate={getDueDate(
              get(currentPlan, "planId"),
              status,
              nextBillingDate
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SubscriptionPlans
            plans={plans}
            title={t("subscription.plan.changePlanTitle")}
          />
        </Grid>
      </Grid>
    </SubscriptionPageBlock>
  );
};

export default SubscriptionPricingPage;
