import React, { useEffect, useRef, useState } from "react";
import {
  addStores,
  removeTempIDs,
} from "../../store/connections/connectAmazon.redux";

import Checkbox from "@material-ui/core/Checkbox";
import { CountryLogo } from "../../img/flags/country_logos";
import Grid from "@material-ui/core/Grid";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { LinearProgress } from "@material-ui/core";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import Typography from "@material-ui/core/Typography";
import { getCountryCodeToName } from "~/utils/countryUtils";
import { getOpenAmazonMarketplaceList } from "../../store/user.redux";
import { loadInfo } from "~/store/user.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled(Grid)`
  max-width: ${({ maxWidth }: { maxWidth: number }) => `${maxWidth}px`};
`;

interface AmazonMarketplace {
  marketplaceId: string;
  countryCode: string;
}

const AmazonConnectCountryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const amazonStore = useTypedSelector((state) => state.amazonNewStore);
  const errMsg = useRef("");

  // eslint-disable-next-line no-magic-numbers
  const [loading, setLoading] = useState(false);
  const [availableMarketplaceIds, setAvailableMarketplaceIds] = useState<
    AmazonMarketplace[]
  >([]);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState<
    AmazonMarketplace[]
  >([]);

  const history = useHistory();
  const urlSearchParams = useQueryParams();

  const redirectNextStep = () => {
    urlSearchParams.delete("marketplace");
    urlSearchParams.delete("selectedCountry");
    urlSearchParams.delete("step");
    history.push(`/connectStore/select?${urlSearchParams.toString()}`);
  };

  const startRedirectProcess = async () => {
    //refresh user info before redirecting
    await dispatch(loadInfo());
    redirectNextStep();
  };

  const loadAvailableMarketplaces = async () => {
    const sellerId = amazonStore.sellerId;

    setLoading(true);
    try {
      await dispatch(
        getOpenAmazonMarketplaceList(
          user._id,
          sellerId,
          urlSearchParams.get("selectedCountry")
        )
      );

      if (user.openAmazonStores)
        setAvailableMarketplaceIds(user.openAmazonStores);
    } catch (err) {
      errMsg.current = t("connectWizard.amazonCountrySaveError");
    }

    setLoading(false);
  };

  const handleMarketplaceSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    marketplace: AmazonMarketplace
  ) => {
    let currentMarketplaces = selectedMarketplaces;

    if (event.target.checked) {
      currentMarketplaces = [...selectedMarketplaces, marketplace];
    } else {
      const filtered = currentMarketplaces.filter(
        (store) => store.marketplaceId !== marketplace.marketplaceId
      );
      currentMarketplaces = filtered;
    }

    setSelectedMarketplaces(currentMarketplaces);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    errMsg.current = "";
    setLoading(true);

    try {
      await dispatch(
        addStores(
          amazonStore.sellerId,
          urlSearchParams.get("selectedCountry"),
          selectedMarketplaces
        )
      );
      await dispatch(removeTempIDs());
    } catch (err) {
      errMsg.current = t("connectWizard.amazonCountrySaveError");
    }

    setLoading(false);
    startRedirectProcess();
  };

  const getCountryName = (countryCode: string) =>
    getCountryCodeToName(countryCode);

  useEffect(() => {
    loadAvailableMarketplaces();
  }, []);

  const renderAvailableMarketplaces = (marketplaceIds: AmazonMarketplace[]) =>
    marketplaceIds.map((marketplace: AmazonMarketplace, index: number) => (
      <Grid
        key={index}
        container
        item
        id="store-link"
        spacing={1}
        alignItems="center"
      >
        <Checkbox
          name={marketplace.marketplaceId}
          color="primary"
          onChange={(e) => handleMarketplaceSelect(e, marketplace)}
        />
        <FlexGrid item>
          <CountryLogo code={marketplace.countryCode} />
        </FlexGrid>
        <FlexGrid item>
          <MarketplaceIcon market={"amazon"} />
        </FlexGrid>
        <TextWrapper item maxWidth={200}>
          <Typography variant="body2" noWrap>
            Amazon {getCountryName(marketplace.countryCode)}
          </Typography>
        </TextWrapper>
      </Grid>
    ));

  return (
    <GridWrapper id="amazon-connectcountry-wrapper">
      <Form method="POST" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
            <Medium>{t("connectWizard.amazonCountrySelectTitle")}</Medium>
          </Grid>
          {loading && (
            <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
            {renderAvailableMarketplaces(availableMarketplaceIds)}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <InlineIconButton />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="subtitle1" color="secondary">
                  {t("connectWizard.amazonCountrySkipMessage")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ButtonWrapper>
              <RaisedButton
                disabled={selectedMarketplaces.length === 0}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t("generic.continueButton")}
              </RaisedButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <RaisedButton
                variant="outlined"
                color="secondary"
                onClick={() => startRedirectProcess()}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "auto 8px",
                  }}
                >
                  <Typography variant="button">
                    {t("generic.skipButton")}
                  </Typography>
                </div>
              </RaisedButton>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Form>
    </GridWrapper>
  );
};

export default AmazonConnectCountryPage;
