import PropTypes from "prop-types";
import moment from "moment-timezone";

// Reminder - if permanently removing basic/standard plans, also remove the line in planDisplay filterPlans()
export const offeredPlans = [
  "usd_basic_mth",
  "usd_standard_mth",
  "usd_professional_mth",
  "usd_vendor_mth",
  "usd_agency_global_mth",
  "usd_agency_sml_mth",
  "usd_agency_mth",
  "usd_agency1p_sml_mth",
  "usd_basic_yr",
  "usd_standard_yr",
  "usd_professional_yr",
  "usd_vendor_yr",
  "usd_agency_global_yr",
  "usd_agency_sml_yr",
  "usd_agency_yr",
  "usd_agency1p_sml_yr",
];

export const FEATURE_LIST = {
  salesTrends: "subscription.feature.salesTrends",
  operations: "subscription.feature.operations",
  health: "subscription.feature.health",
  listingQuality: "subscription.feature.listingQuality",
  advertising: "subscription.feature.advertising",
  profitability: "subscription.feature.profitability",
  reviewAutomation: "subscription.feature.reviewAutomation",
  vendorAnalytics: "subscription.feature.vendorAnalytics",
  clientReporting: "subscription.feature.clientReporting",
  customLogo: "subscription.feature.customLogo",
  inhouseBranding: "subscription.feature.inhouseBranding",
  customDomain: "subscription.feature.customDomain",
};

export const ALLOWED_BILLING_CYCLES = {
  monthly: {
    shortLabel: "subscription.plan.shortMonthLabel",
    period: moment.duration(1, "month"),
  },
  yearly: {
    shortLabel: "subscription.plan.shortYearLabel",
    period: moment.duration(1, "year"),
  },
};
export const YEARLY_DISCOUNT_PERCENTAGE = 20;

class Plan {
  public planId: string;
  public cost: number;
  public currency: string;
  public trialPeriod: number;
  public entitlements: Record<string, any>;
  public billingFrequencyMonths: number;
  public name: string;
  public description: string;

  /** Construct a new Plan.
   * @param {string} planId - a unique identifier for this plan.
   * See swagger documentation for mm-subscription-service for more details.
   * @param {number} cost - the cost of this plan per billing cycle.
   * @param {string} currency - an ISO4217 3-letter currency code that the cost of the plan is quoted in.
   * @param {int} trialPeriod - the trial period of this subscription in days.
   * @param {object} entitlements - the features that this plan entitles you to.
   * @param {int} billingFrequencyMonths - how many months each billing cycle represents e.g. 12 for a one-year billing cycle.
   */
  constructor(
    planId: string,
    cost: number,
    currency: string,
    trialPeriod: number,
    entitlements: Record<string, any>,
    billingFrequencyMonths = 1,
    name: string,
    description: string
  ) {
    this.planId = planId;
    this.cost = cost;
    this.currency = currency;
    this.trialPeriod = trialPeriod;
    this.entitlements = entitlements;
    this.billingFrequencyMonths = billingFrequencyMonths;
    this.name = name;
    this.description = description;
  }

  // Return Plan as a plain object - serializable
  toObject() {
    return {
      planId: this.planId,
      cost: this.cost,
      currency: this.currency,
      trialPeriod: this.trialPeriod,
      entitlements: this.entitlements,
      billingFrequencyMonths: this.billingFrequencyMonths,
      name: this.name,
      description: this.description,
    };
  }
}

export const LEGACY_PLAN_ID = "legacy_standard_mth";

/** Return a string representing a label for the given plan's billing period.
 * @param {object} plan - a Plan object
 * @param {boolean} short - whether to return the full label or a shortened
 * version.
 * @return {string} a label for this plan's billing period. */
export const formatBillingPeriod = (plan: Plan, short = true) => {
  const MONTHS_PER_YEAR = 12;
  if (plan.billingFrequencyMonths === MONTHS_PER_YEAR) {
    return short
      ? "subscription.plan.shortYearLabel"
      : "subscription.plan.longYearLabel";
  } else if (plan.billingFrequencyMonths === 1) {
    return short
      ? "subscription.plan.shortMonthLabel"
      : "subscription.plan.longMonthLabel";
  } else {
    return "";
  }
};

/** @param {object} a Plan object
 * @return {number} the monthly cost of the given plan */
export const getMonthlyCost = (plan: Plan) =>
  plan.cost / plan.billingFrequencyMonths;

/** Given a plan ID, return a human-readable label corresponding to that ID.
 * @param {string} planId - a plan ID from the subscription service.
 * @return {string} a human-readable name for the given plan ID */
export const getPlanName = (planId: string, name: string) => {
  if (planId === LEGACY_PLAN_ID) {
    return "subscription.plan.legacy";
  } else if (!offeredPlans.includes(planId)) {
    return name ? name : "subscription.plan.custom";
  } else if (planId.indexOf("basic") >= 0) {
    return "subscription.plan.basic";
  } else if (planId.indexOf("standard") >= 0) {
    return "subscription.plan.standard";
  } else if (planId.indexOf("professional") >= 0) {
    return "subscription.plan.professional";
  } else if (planId.indexOf("vendor") >= 0) {
    return "subscription.plan.vendor";
  } else if (
    planId === "usd_agency_sml_yr" ||
    planId === "usd_agency_sml_mth" ||
    planId === "usd_agency1p_sml_mth"
  ) {
    return "subscription.plan.smallAgency";
  } else if (planId.indexOf("agency") >= 0) {
    return "subscription.plan.agency";
  } else {
    return "subscription.plan.unknown";
  }
};

/** Given a plan ID, return a short piece of text advertising/describing it.
 * @param {string} planId - the plan ID
 * @return {string} a short advertisment for the plan.
 */
export const getPlanBlurb = (planId: string) => {
  if (planId.indexOf("basic") >= 0) {
    return "subscription.plan.basicBlurb";
  } else if (planId.indexOf("standard") >= 0) {
    return "subscription.plan.standardBlurb";
  } else if (planId.indexOf("professional") >= 0) {
    return "subscription.plan.professionalBlurb";
  } else if (
    planId === "usd_agency_sml_yr" ||
    planId === "usd_agency_sml_mth"
  ) {
    return "subscription.plan.smallAgencyBlurb";
  } else if (planId.indexOf("agency") >= 0) {
    return "subscription.plan.agencyBlurb";
  } else {
    return null;
  }
};

export const getDueDate = (
  planId: string,
  planStatus: string,
  nextBillingDate: string
) => {
  if (!planId) {
    return "N/A";
  } else if (planId === LEGACY_PLAN_ID) {
    return `N/A (Free trial expires ${moment(nextBillingDate).format(
      "D MMMM YYYY"
    )})`;
  } else if (planStatus === "Canceled" || planStatus === "Expired") {
    return `N/A (expires ${moment(nextBillingDate).format("D MMMM YYYY")})`;
  } else {
    return moment(nextBillingDate).format("D MMMM YYYY");
  }
};

export const getConvertedToPaid = (subscriptionStatus: string) =>
  subscriptionStatus === "INITIAL_PAYMENT_COMPLETED";

export default Plan;

export const PlanPropTypes = PropTypes.shape({
  planId: PropTypes.string,
  cost: PropTypes.number,
  currency: PropTypes.string,
  trialPeriod: PropTypes.number,
  entitlements: PropTypes.object,
});
