/* eslint-disable no-magic-numbers */
import {
  ALLOWED_BILLING_CYCLES,
  LEGACY_PLAN_ID,
  PlanPropTypes,
  getPlanName,
  offeredPlans,
} from "./plan";
import {
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import MessageDialog from "~/components/dialogs/messageDialog";
import PlanFeatures from "./planFeatures";
import PlanSummary from "./planSummary";
import PropTypes from "prop-types";
import React from "react";
import { SELECT_PLAN } from "~/store/subscriptions.redux";
import SwipeableTabs from "~/components/toolbars/swipeableTabs";
import get from "lodash/get";
import { loadInfo } from "~/store/user.redux";
import mapValues from "lodash/mapValues";
import { noPriorSubscription } from "../paywall";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { usePlan } from "./usePlan";
import { useTranslation } from "react-i18next";

const PlanPanel = styled(Paper)`
  height: 100%;
  border: ${({ theme }) => `1px ${theme.palette.grey[500]} solid`};
  ${({ theme }) => `${theme.breakpoints.down("sm")} {
    border: 1px black solid;
    border-radius: unset;
  }`}
`;

const ConfirmContent = ({ planName }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
  >
    <Typography
      gutterBottom
      variant="h2"
      component="p"
    >{`You have selected a ${planName} subscription`}</Typography>
    <Typography gutterBottom>Please confirm to continue to payment</Typography>
  </Grid>
);
ConfirmContent.propTypes = {
  planName: PropTypes.string.isRequired,
};

const GRID_MAX_COLUMNS = 12;

/** Given a list of subscription plans and a boolean indicating
 * if a user is new, filter out plans which aren't suitable for
 * new users (i.e. show new users a restricted list of plans.
 * @param {Object} plans - the full list of plans
 * @param {Plan[]} plans.monthly
 * @param {Plan[]} plans.yearly
 * @param {'monthly'|'yearly'} billingCycle - a string representing
 * the current selected billing cycle
 * @param {string} currency - an ISO4217 currency code */
const filterPlans = (plans, billingCycle, currency) =>
  plans[billingCycle]
    .filter(
      (p) => p.currency === currency && offeredPlans.includes(p.planId)
      // Filter out basic and standard plans
    )
    .filter(
      (p) =>
        !(
          p.planId &&
          typeof p.planId === "string" &&
          (p.planId.indexOf("basic") >= 0 ||
            p.planId.indexOf("standard") >= 0 ||
            p.planId.indexOf("agency1p") >= 0 ||
            p.planId.indexOf("agency_global") >= 0)
        )
    );

const PlanDisplay = ({ plans, billingCycle, currency }) => {
  const { t } = useTranslation();
  const currentPlan = usePlan();

  const loading = useSelector((state) => get(state, "subscriptions.loading"));
  const user = useSelector((state) => get(state, "user"));
  const currentSubscription = useSelector((state) =>
    get(state, "subscriptions.currentSubscription")
  );
  const status = get(currentSubscription, "status");
  const newUser = noPriorSubscription(currentSubscription);
  const dispatch = useDispatch();
  const history = useHistory();
  const [supportDialogOpen, setSupportDialogOpen] = React.useState(false);
  React.useEffect(() => {
    dispatch(loadInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading || !user) {
    return <CircularProgress />;
  }
  const filteredPlans = filterPlans(plans, billingCycle, currency);

  const onPlanSelect = (plan) => {
    if (!currentPlan || currentPlan.planId === LEGACY_PLAN_ID) {
      // new subscriber or previous subscriber
      dispatch({ type: SELECT_PLAN, payload: plan });
      history.push("/subscription/create");
    } else if (status === "Canceled" || status === "Expired") {
      // recently cancelled subscriber
      setSupportDialogOpen(true);
    } else {
      // active subscriber
      setSupportDialogOpen(true);
    }
  };

  const onSupportDialogClose = () => {
    setSupportDialogOpen(false);
    window.open(
      "https://merchantspring.zendesk.com/hc/en-us/requests/new",
      "_blank"
    );
  };

  // We want the tab to default to standard
  const currentPlanIndex = filteredPlans.findIndex(
    (p) => p.planId === get(currentPlan, "planId")
  );
  const standardPlanIndex = filteredPlans.findIndex((p) =>
    p.planId.includes("standard")
  );
  const defaultTabIndex =
    (currentPlanIndex === -1 ? 0 : currentPlanIndex) ||
    (standardPlanIndex === -1 ? 0 : standardPlanIndex);

  const renderPlans = () =>
    filteredPlans.map((plan) => {
      return (
        <Grid
          key={plan.planId}
          item
          xs={12}
          md={GRID_MAX_COLUMNS / (filteredPlans.length || 1)}
        >
          <PlanPanel>
            <PlanSummary
              billingCycle={billingCycle}
              onSelect={onPlanSelect}
              plan={plan}
              currentPlan={currentPlan}
              newSubscriber={newUser}
            />
            <PlanFeatures plan={plan} />
          </PlanPanel>
        </Grid>
      );
    });

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignContent="center"
        alignItems="stretch"
      >
        <Hidden smDown>{renderPlans()}</Hidden>
        <Hidden mdUp>
          <SwipeableTabs
            labels={filteredPlans.map((plan) => t(getPlanName(plan.planId)))}
            defaultIndex={defaultTabIndex}
          >
            {renderPlans()}
          </SwipeableTabs>
        </Hidden>
      </Grid>
      <MessageDialog
        title={t("subscription.plan.contactSupportTitle")}
        open={supportDialogOpen}
        onClose={onSupportDialogClose}
        messages={[
          t("subscription.plan.contactSupportMessageA"),
          t("subscription.plan.contactSupportMessageB"),
        ]}
      />
    </>
  );
};
PlanDisplay.propTypes = {
  plans: PropTypes.shape(
    mapValues(ALLOWED_BILLING_CYCLES, () => PropTypes.arrayOf(PlanPropTypes))
  ).isRequired,
  billingCycle: PropTypes.oneOf(Object.keys(ALLOWED_BILLING_CYCLES)).isRequired,
  currency: PropTypes.string.isRequired,
};
export default PlanDisplay;
