import { Box, Typography, useTheme, withTheme } from "@material-ui/core";
import React, { memo, useCallback, useEffect } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import Bold from "~/components/typography/bold";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import PieChart from "~/components/charts/pieChart/pieChart";
import { Range } from "~/typedef/store";
import { User } from "~/typedef/user";
import { fetchMarketingCampaignTypesChart } from "~/store/mystore/marketing.redux";
import { formatAdType } from "~/modules/marketing/commonColumns";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { formatNumber } from "~/utils/salesUtils";
import get from "lodash/get";
import { getStatusFromACOS } from "~/components/table/cells/acosCell";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledBox = styled(Box)`
  padding: 0.3em;
  row-gap: 0.2em;
`;

const TooltipBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0.3em;
  row-gap: 0.2em;
`;

const LegendRowBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

interface NoMarginProps {
  noMargin?: boolean;
}

const ContentWrapper = withTheme(styled.div<NoMarginProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ theme, noMargin }) => (noMargin ? 0 : theme.spacing(1))}px;
  flex-grow: 1;
`);

const LegendRowElement = styled.div`
  margin-right: 4px;
  align-items: center;
  display: flex;
`;

const StoreNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

interface WithStatusProps {
  status: "success" | "error" | "disabled" | "warning";
}
const AcosLabel = withTheme(styled(Typography)<WithStatusProps>`
  color: ${({ theme, status }) =>
    status ? theme.palette[status].main : theme.palette.text.secondary};
`);

interface TooltipContentProps {
  salesValue: number;
  acosValue: number;
}
const TooltipContentItem = ({ salesValue, acosValue }: TooltipContentProps) => {
  const { t } = useTranslation();
  const acosPercentageText =
    acosValue != 0 ? `${formatNumber(acosValue, 1)}%` : "0%";

  return (
    <TooltipBox>
      <LegendRowBox>
        <LegendRowElement>
          <Bold variant="body2">
            {t("chartLegends.sales")}: {salesValue}
          </Bold>
        </LegendRowElement>
      </LegendRowBox>
      <LegendRowBox>
        <LegendRowElement>
          <Bold variant="body2">{t("chartLegends.acos")}: </Bold>
        </LegendRowElement>
        <LegendRowElement>
          <AcosLabel variant="body2" $status={getStatusFromACOS(acosValue)}>
            {acosPercentageText}
          </AcosLabel>
        </LegendRowElement>
      </LegendRowBox>
    </TooltipBox>
  );
};

interface PieChartLegendItem {
  campaignName: string;
  salesValue: number;
  acosValue: number;
  piePercentage: number;
}

const PieChartLegendItem = ({
  campaignName,
  salesValue,
  acosValue,
  piePercentage,
}: PieChartLegendItem) => {
  const { t } = useTranslation();
  const acosPercentageText =
    acosValue != 0 ? `${formatNumber(acosValue, 1)}%` : "0%";

  return (
    <StyledBox display="flex" flexDirection="column" mb={1} mr={1} minWidth={0}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        overflow="hidden"
      >
        <StoreNameContainer>
          <Bold variant="body2" noWrap style={{ minWidth: 0 }}>
            {campaignName}
          </Bold>
        </StoreNameContainer>
      </Box>
      <LegendRowBox>
        <LegendRowElement>
          <Typography variant="body2">
            {t("chartLegends.sales")}:{salesValue}
          </Typography>
        </LegendRowElement>
        <LegendRowElement>
          <Typography variant="body2" color="secondary">
            ({piePercentage}%)
          </Typography>
        </LegendRowElement>
      </LegendRowBox>
      <LegendRowBox>
        <LegendRowElement>
          <Typography variant="body2">{t("chartLegends.acos")}: </Typography>
        </LegendRowElement>
        <LegendRowElement>
          <AcosLabel variant="body2" $status={getStatusFromACOS(acosValue)}>
            {acosPercentageText}
          </AcosLabel>
        </LegendRowElement>
      </LegendRowBox>
    </StyledBox>
  );
};

interface CampaignTypeProps {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  userInfo: User;
  currentCurrency: string;
  marketplaceSubtype?: string;
  currentRange: Range;
  report?: boolean;
}

interface CampaignTypeResponse {
  marketplaceId: string;
  adType: string;
  reportDate: string;
  attributedSales: number;
  percentage: number;
  costs: number;
  acos: number;
}

interface CampaignData {
  marketplaceId: string;
  adType: string;
  reportDate: string;
  attributedSales: number;
  percentage: number;
  costs: number;
  acos: number;
  convertedSalesDisplay: number;
  convertedAttributedSales: number;
}
const CampaignTypesWidget = memo<CampaignTypeProps>(
  function CampaignTypesWidget({
    mid,
    countryCode,
    marketplaceType,
    userInfo,
    currentCurrency,
    marketplaceSubtype,
    currentRange,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const marketing = useTypedSelector((state) => state.marketing);
    const isFetching = useTypedSelector((state) =>
      get(state, "marketing.campaignTypes.loading")
    );

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );

    const dispatch = useDispatch();

    const dispatchFetchCampaignTypes = useCallback(() => {
      dispatch(
        fetchMarketingCampaignTypesChart(
          userInfo._id,
          mid,
          countryCode,
          marketplaceType,
          marketplaceSubtype,
          currentRange.fromDate,
          currentRange.toDate,
          selectedTimezone
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      mid,
      countryCode,
      marketplaceType,
      currentRange.fromDate,
      currentRange.toDate,
      selectedTimezone,
    ]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchCampaignTypes();
      };
      fetchData();
    }, [dispatchFetchCampaignTypes, userInfo]);

    const emptyChart = [
      {
        name: t("generic.noDataAvailableMessage"),
        value: 100,
        color: "gray",
        tooltipContent: t("generic.noDataAvailableMessage"),
      },
    ];

    const campaignData =
      marketing &&
      marketing.campaignTypes &&
      marketing.campaignTypes.data &&
      marketing.campaignTypes.data.map((item: CampaignTypeResponse) => {
        const convertedSales = getConvertedValue(
          currencyRates,
          getCurrencyByCountryCode[countryCode],
          currentCurrency,
          item.attributedSales
        );
        const convertedSalesDisplay = formatCurrencyRounded(
          item.attributedSales,
          currencyRates,
          getCurrencyByCountryCode[countryCode],
          currentCurrency
        );
        return {
          ...item,
          convertedSalesDisplay,
          convertedAttributedSales: convertedSales,
        };
      });

    const rankedChartShades = [
      theme.palette.secondary.dark,
      theme.palette.secondary.main,
      theme.palette.secondary.light,
    ];

    const chartData =
      campaignData && campaignData.length > 0
        ? campaignData.map((item: CampaignData, index: number) => ({
            name: item.adType,
            value: item.convertedAttributedSales,
            percentage: item.percentage,
            color: rankedChartShades[index] || theme.palette.chart.gray,
            legendContent: (
              <PieChartLegendItem
                campaignName={formatAdType(item.adType, t)}
                salesValue={item.convertedSalesDisplay}
                acosValue={item.acos}
                piePercentage={item.percentage}
              />
            ),
            tooltipContent: (
              <TooltipContentItem
                salesValue={item.convertedSalesDisplay}
                acosValue={item.acos}
              />
            ),
          }))
        : emptyChart;

    return (
      <Panel
        id="widget-advertising-campaign"
        title={t("advertisingDashboardWidget.campaignTypes")}
        tooltip={t("advertisingDashboardWidget.campaignTypesTooltip")}
        content={
          isFetching ? (
            <PanelLoading />
          ) : (
            <ContentWrapper $noMargin>
              <PieChart
                {...{
                  data: chartData,
                }}
              />
            </ContentWrapper>
          )
        }
      />
    );
  }
);

export default CampaignTypesWidget;
