import Bold from "../typography/bold";
import { Moment } from "moment";
import React from "react";
import styled from "styled-components";

const MonthGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 1;
`;

export const MonthHeader = ({
  from,
  printMonth,
}: {
  from: Moment;
  printMonth: boolean;
}) => {
  return (
    <MonthGrid>
      {printMonth ? (
        <Bold variant="body1" color="textSecondary">
          {from.format("MMMM YYYY")}
        </Bold>
      ) : null}
    </MonthGrid>
  );
};
