import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useMemo, useState } from "react";

import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingButton from "~/components/buttons/loadingButton";
import ModalPanel from "~/components/panel/modalPanel";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export interface DaysCoverAttributionWindow {
  "7d": number;
  "30d": number;
  "60d": number;
  "90d": number;
}
interface ForecastWeightingSettingProps {
  openModal: boolean;
  inventoryReplenishmentSaving: boolean;
  setDaysCoverAttributionWindow: (input: DaysCoverAttributionWindow) => void;
  setOpenModal: (open: boolean) => void;
  saveSettings: () => void;
  daysCoverAttributionWindow: DaysCoverAttributionWindow;
}

const StyledTypography = styled(Typography)`
  margin: 8px 10px 8px 0;
`;

const SaveButton = styled(LoadingButton)`
  min-width: 100px;
`;

const StyledTextField = styled(TextField)`
  max-width: 100px;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const ForecastWeightingSetting = memo<ForecastWeightingSettingProps>(
  function ForecastWeightingSetting({
    inventoryReplenishmentSaving,
    daysCoverAttributionWindow,
    setDaysCoverAttributionWindow,
    openModal,
    setOpenModal,
    saveSettings,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [userUpdated, setUserUpdated] = useState(false);

    const textInputProps = useMemo<React.CSSProperties>(
      () => ({
        textAlign: "right",
        width: "75px",
        ...theme.typography.body2,
      }),
      [theme.typography]
    );

    const checkAttributionTotal = (
      daysCoverValue: DaysCoverAttributionWindow
    ) => {
      const total =
        daysCoverValue["7d"] + daysCoverValue["30d"] + daysCoverValue["60d"];
      if (total <= 100) {
        setUserUpdated(true);
        setDaysCoverAttributionWindow({
          ...daysCoverValue,
          "90d":
            100 -
            (daysCoverValue["7d"] +
              daysCoverValue["30d"] +
              daysCoverValue["60d"]),
        });
      }
    };

    return (
      <ModalPanel
        open={openModal}
        setOpen={setOpenModal}
        title={t(`editWeights.title`)}
        content={
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item container xs={12} justifyContent="space-between">
                <StyledBox p={0}>
                  <StyledTypography variant="body1" display="inline">
                    {t(`replenishmentSetting.Last7Days`)}
                  </StyledTypography>
                  <Tooltip title={t("replenishmentSetting.Last7DaysTooltip")}>
                    <InlineIconButton />
                  </Tooltip>
                </StyledBox>
                <StyledTextField
                  size="small"
                  autoFocus
                  type="number"
                  disabled={inventoryReplenishmentSaving}
                  value={daysCoverAttributionWindow["7d"]}
                  variant="outlined"
                  color="primary"
                  inputProps={{
                    style: textInputProps,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    checkAttributionTotal({
                      ...daysCoverAttributionWindow,
                      "7d": Number(e.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="space-between">
                <StyledBox p={0}>
                  <StyledTypography variant="body1" display="inline">
                    {t(`replenishmentSetting.Last30Days`)}
                  </StyledTypography>
                  <Tooltip title={t("replenishmentSetting.Last30DaysTooltip")}>
                    <InlineIconButton />
                  </Tooltip>
                </StyledBox>
                <StyledTextField
                  size="small"
                  type="number"
                  disabled={inventoryReplenishmentSaving}
                  value={daysCoverAttributionWindow["30d"]}
                  variant="outlined"
                  color="primary"
                  inputProps={{
                    style: textInputProps,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    checkAttributionTotal({
                      ...daysCoverAttributionWindow,
                      "30d": Number(e.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="space-between">
                <StyledBox p={0}>
                  <StyledTypography variant="body1" display="inline">
                    {t(`replenishmentSetting.Last60Days`)}
                  </StyledTypography>
                  <Tooltip title={t("replenishmentSetting.Last60DaysTooltip")}>
                    <InlineIconButton />
                  </Tooltip>
                </StyledBox>
                <StyledTextField
                  size="small"
                  type="number"
                  disabled={inventoryReplenishmentSaving}
                  value={daysCoverAttributionWindow["60d"]}
                  variant="outlined"
                  color="primary"
                  inputProps={{
                    style: textInputProps,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    checkAttributionTotal({
                      ...daysCoverAttributionWindow,
                      "60d": Number(e.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="space-between">
                <StyledBox p={0}>
                  <StyledTypography variant="body1" display="inline">
                    {t(`replenishmentSetting.Last90Days`)}
                  </StyledTypography>
                  <Tooltip title={t("replenishmentSetting.Last90DaysTooltip")}>
                    <InlineIconButton />
                  </Tooltip>
                </StyledBox>
                <StyledTextField
                  size="small"
                  type="number"
                  disabled={true}
                  value={daysCoverAttributionWindow["90d"]}
                  variant="outlined"
                  color="primary"
                  inputProps={{
                    style: textInputProps,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                <SaveButton
                  onClick={() => {
                    setUserUpdated(false);
                    saveSettings();
                  }}
                  data-testid="setting-save-button"
                  name="saveButton"
                  color="primary"
                  loading={inventoryReplenishmentSaving}
                  disabled={!userUpdated}
                >
                  {t("replenishmentSetting.save")}
                </SaveButton>
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default ForecastWeightingSetting;
