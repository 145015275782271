import {
  CommonColors,
  PaletteColor,
  PaletteOptions,
} from "@material-ui/core/styles/createPalette";
/* eslint-disable no-magic-numbers */
import { alpha, createTheme } from "@material-ui/core/styles";

import { Overrides } from "@material-ui/core/styles/overrides";
import { Shadows } from "@material-ui/core/styles/shadows";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

/**
 * Any palette properties that we want to use in our overrides must be extracted here.
 * We use PaletteColor(etc) (which is assignable to PaletteColorOptions) so typescript knows
 * the keys are defined when we use them in our overrides.
 */
const warning: PaletteColor = {
  light: "#FFA726",
  main: "#FB8C00",
  dark: "#EF6C00",
  contrastText: "#FFFFFF",
};
const secondary: PaletteColor = {
  light: "#8CB6F0",
  main: "#3C81E0",
  dark: "#094FB0",
  contrastText: "#FFFFFF",
};
const primary: PaletteColor = {
  light: "#FCE99C",
  main: "#FFD700",
  dark: "#F1B42F",
  contrastText: "#39393A",
};
const success: PaletteColor = {
  light: "#50C878",
  main: "#00A86B",
  dark: "#00945E",
  contrastText: "#FFFFFF",
};
const info: PaletteColor = {
  light: "#5AC4C4",
  main: "#3BA5A5",
  dark: "#369696",
  contrastText: "#FFFFFF",
};
const common: CommonColors = {
  black: "#000",
  white: "#fff",
};
/**
 * This is the application colour palette as determind by design
 * and marketing. The template is from the Material UI Theme.
 *
 * All colours used in the app should be referenced in the theme,
 * please try to use the theme where possible, instead of using
 * colour codes in components.
 *
 * When you want to add to or modify the palette, you must make three changes:
 *   1) change the Palette interface defined in ./palette.d.ts
 *   2) change the PaletteOptions interface defined in ./palette.d.ts
 *   3) change the value below
 */
export const palette: PaletteOptions = {
  type: "light",
  common,
  primary,
  secondary,
  error: {
    light: "#FF7F7F",
    main: "#CE2029",
    dark: "#8B0000",
    contrastText: "#FFFFFF",
  },
  warning: {
    light: "#FFA77A",
    main: "#F96E46",
    dark: "#C24919",
    contrastText: "#FFFFFF",
  },
  info,
  success,
  disabled: {
    light: "#F5F5F5",
    main: "#E5E4E2",
    dark: "#BEBFC5",
  },
  grey: {
    100: "#F3F3F3",
    200: "#ededf0",
    300: "#DBDBDB",
    400: "#CCCCCC",
    500: "#8E8E8E",
    600: "#757575",
  },
  text: {
    primary: "#111111",
    secondary: "#343434",
    disabled: "#BEBFC5",
    hint: "#BEBFC5",
  },
  divider: primary.main,
  border: {
    light: "#F5F5F5",
    main: "#E5E4E2",
    dark: "#BEBFC5",
  },
  table: {
    header: "#F0F8FF",
    text: {
      header: "#094FB0",
    },
  },
  link: {
    primary: "#3C81E0",
    secondary: "#3C81E0",
  },
  chart: {
    //Order of colours for multi line chart
    darkBlue: "#0277BD",
    brown: "#8D6E63",
    orange: "#FFA726",
    lightGreen: "#81C784",
    spaceGray: "#AAAAAA",
    deepOrange: "#FF7043",
    purple: "#8A43FF",
    pink: "#FF43D0",
    gray: "#78909C",
    lightGreen1: "#278882",
    lightBlue1: "#3C81E0",
    lightBrown: "#E18557",
    lightGray1: "#6A6A6A",
    indigo: "#3A015C",
    magenta: "#4F0147",
    green: "#43A047",
    cyan: "#B2EBF2",
    lightPink: "#FEC3E4",
    // Unordered
    lightBlue: "#81D4FA",
    progressBlue: "#0080DE",
    lightGray: "#C8C8C8",
    darkGray: "#6E7677",
    red: "#F16C6A",
  },
};
export const shape = {
  borderRadius: 14,
};

/**
 * This is the application typography styles as determind by design
 * and marketing. The template is from the Material UI Theme
 *
 * All font styles in the app should be referenced in the theme.
 * Please use the theme typography styles and not custom ones.
 *
 * When you want to add to or modify the typography, you must make three changes:
 *   1) change the Typography interface defined in ./typography.d.ts
 *   2) change the TypographyOptions interface defined in ./typography.d.ts
 *   3) change the value below
 */
export const typography: TypographyOptions = {
  fontFamily: ["Marine", "sans-serif"].join(","),
  fontSize: 16,
  h1: {
    //Sign in, Create your account, Page heading
    fontSize: "1.375rem",
    lineHeight: "1.75rem",
    fontWeight: 600,
  },
  h2: {
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  h3: {
    //Panel headings, "TOTAL" titles
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
    fontWeight: 600,
  },
  h4: {
    //Larger text, not bold, see PO Value side panel
    fontSize: "1rem",
    lineHeight: 1.2,
    fontWeight: 500,
  },
  h5: {
    fontSize: "1rem",
    lineHeight: 1.2,
    fontWeight: 400,
  },
  h6: {
    fontSize: "0.875rem",
    lineHeight: 1.2,
    fontWeight: 500,
  },
  body1: {
    //Table text, normal body font
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    fontWeight: 400,
  },
  button: {
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: "0.75rem",
    textTransform: "none",
  },
  subtitle1: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "0.625rem",
    fontWeight: 400,
  },
  caption: {
    fontSize: "0.625rem",
    fontWeight: 400,
  },
  overline: {
    fontSize: "0.875rem",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  chartSummary: {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  lightDetail: {
    fontSize: "0.75rem",
    fontWeight: 300,
  },
  faintDetail: {
    fontSize: "0.625rem",
    fontWeight: 300,
  },
  heavyDetail: {
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  secondaryData: {
    fontSize: "0.875rem",
    fontWeight: 300,
  },
  linkAndImageCell: {
    fontSize: "0.6875rem",
    fontWeight: 400,
  },
};

export const shadows: Shadows = [
  "none",
  "0px 1px 2px -1px rgba(0,0,0,0.5),0px 1px 1px 0px rgba(0,0,0,0.34),0px 1px 3px 0px rgba(0,0,0,0.22)",
  "0px 3px 2px -2px rgba(0,0,0,0.5),0px 2px 2px 0px rgba(0,0,0,0.34),0px 1px 5px 0px rgba(0,0,0,0.22)",
  "0px 3px 3px -2px rgba(0,0,0,0.5),0px 3px 4px 0px rgba(0,0,0,0.34),0px 1px 8px 0px rgba(0,0,0,0.22)",
  "0px 2px 4px -1px rgba(0,0,0,0.5),0px 4px 5px 0px rgba(0,0,0,0.34),0px 1px 10px 0px rgba(0,0,0,0.22)",
  "0px 3px 5px -1px rgba(0,0,0,0.5),0px 5px 8px 0px rgba(0,0,0,0.34),0px 1px 14px 0px rgba(0,0,0,0.22)",
  "0px 3px 5px -1px rgba(0,0,0,0.5),0px 6px 10px 0px rgba(0,0,0,0.34),0px 1px 18px 0px rgba(0,0,0,0.22)",
  "0px 4px 5px -2px rgba(0,0,0,0.5),0px 7px 10px 1px rgba(0,0,0,0.34),0px 2px 16px 1px rgba(0,0,0,0.22)",
  "0px 5px 5px -3px rgba(0,0,0,0.5),0px 8px 10px 1px rgba(0,0,0,0.34),0px 3px 14px 2px rgba(0,0,0,0.22)",
  "0px 5px 6px -3px rgba(0,0,0,0.5),0px 9px 12px 1px rgba(0,0,0,0.34),0px 3px 16px 2px rgba(0,0,0,0.22)",
  "0px 6px 6px -3px rgba(0,0,0,0.5),0px 10px 14px 1px rgba(0,0,0,0.34),0px 4px 18px 3px rgba(0,0,0,0.22)",
  "0px 6px 7px -4px rgba(0,0,0,0.5),0px 11px 15px 1px rgba(0,0,0,0.34),0px 4px 20px 3px rgba(0,0,0,0.22)",
  "0px 7px 8px -4px rgba(0,0,0,0.5),0px 12px 17px 2px rgba(0,0,0,0.34),0px 5px 22px 4px rgba(0,0,0,0.22)",
  "0px 7px 8px -4px rgba(0,0,0,0.5),0px 13px 19px 2px rgba(0,0,0,0.34),0px 5px 24px 4px rgba(0,0,0,0.22)",
  "0px 7px 9px -4px rgba(0,0,0,0.5),0px 14px 21px 2px rgba(0,0,0,0.34),0px 5px 26px 4px rgba(0,0,0,0.22)",
  "0px 8px 9px -5px rgba(0,0,0,0.5),0px 15px 22px 2px rgba(0,0,0,0.34),0px 6px 28px 5px rgba(0,0,0,0.22)",
  "0px 8px 10px -5px rgba(0,0,0,0.5),0px 16px 24px 2px rgba(0,0,0,0.34),0px 6px 30px 5px rgba(0,0,0,0.22)",
  "0px 8px 11px -5px rgba(0,0,0,0.5),0px 17px 26px 2px rgba(0,0,0,0.34),0px 6px 32px 5px rgba(0,0,0,0.22)",
  "0px 9px 11px -5px rgba(0,0,0,0.5),0px 18px 28px 2px rgba(0,0,0,0.34),0px 7px 34px 6px rgba(0,0,0,0.22)",
  "0px 9px 12px -6px rgba(0,0,0,0.5),0px 19px 29px 2px rgba(0,0,0,0.34),0px 7px 36px 6px rgba(0,0,0,0.22)",
  "0px 10px 13px -6px rgba(0,0,0,0.5),0px 20px 31px 3px rgba(0,0,0,0.34),0px 8px 38px 7px rgba(0,0,0,0.22)",
  "0px 10px 13px -6px rgba(0,0,0,0.5),0px 21px 33px 3px rgba(0,0,0,0.34),0px 8px 40px 7px rgba(0,0,0,0.22)",
  "0px 10px 14px -6px rgba(0,0,0,0.5),0px 22px 35px 3px rgba(0,0,0,0.34),0px 8px 42px 7px rgba(0,0,0,0.22)",
  "0px 11px 14px -7px rgba(0,0,0,0.5),0px 23px 36px 3px rgba(0,0,0,0.34),0px 9px 44px 8px rgba(0,0,0,0.22)",
  "0px 11px 15px -7px rgba(0,0,0,0.5),0px 24px 38px 3px rgba(0,0,0,0.34),0px 9px 46px 8px rgba(0,0,0,0.22)",
];

/**
 * Material UI Theme Overrides
 *
 * These are overrides for components that are style changes
 * that don't include colour or typography
 */
export const overrides: Overrides & {
  MuiToggleButton: any;
  MuiToggleButtonGroup: any;
} = {
  MuiAppBar: {
    colorDefault: {
      backgroundColor: palette.background?.default,
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: palette.background?.default,
      color: common.black,
    },
  },
  MuiBadge: {
    badge: {
      backgroundColor: warning.main,
      color: warning.contrastText,
      fontSize: "0.75rem",
      padding: "0 6px 0px 5px",
    },
  },
  MuiButton: {
    contained: {
      minHeight: "40px",
      minWidth: "285px",
    },
    outlined: {
      minHeight: "40px",
      minWidth: "285px",
    },
    outlinedSizeSmall: {
      minHeight: "40px",
      minWidth: "100px",
    },
    containedSizeSmall: {
      minHeight: "40px",
      minWidth: "100px",
    },
    root: {
      textTransform: "uppercase",
      borderRadius: "40px",
    },
    text: {
      textTransform: "none",
    },
    fullWidth: {
      minWidth: "fit-content",
    },
    // From https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
    // Used to add shadows to outlined buttons, since Material-UI doesn't
    // style them with shadows by default
    outlinedPrimary: {
      backgroundColor: palette.background?.default,
    },
    outlinedSecondary: {
      backgroundColor: palette.background?.default,
      boxShadow: shadows[1],
      "&:hover": {
        boxShadow: shadows[4],
        backgroundColor: palette.background?.default,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: shadows[1],
        },
      },
      "&$focusVisible": {
        boxShadow: shadows[6],
      },
      "&:active": {
        boxShadow: shadows[8],
      },
      "&$disabled": {
        boxShadow: shadows[0],
      },
    },
  },
  MuiButtonBase: {
    root: {
      borderRadius: 5,
    },
  },
  MuiChip: {
    sizeSmall: {
      fontSize: "0.75rem",
    },
  },
  MuiDivider: {
    middle: {
      marginTop: "16px",
    },
  },
  MuiDrawer: {
    paper: {
      position: "absolute",
      width: "inherit",
      color: secondary.contrastText,
      background: secondary.main,
    },
    paperAnchorDockedLeft: {
      borderRight: "none",
    },
    paperAnchorRight: {
      background: palette.background?.paper,
    },
  },
  MuiExpansionPanel: {
    root: {
      "&:before": {
        backgroundColor: palette.border.main,
      },
    },
  },
  MuiInput: {
    root: {
      "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      minWidth: "fit-content",
    },
    label: {
      fontSize: "0.75rem",
      lineHeight: 1.5,
      fontWeight: 400,
    },
    labelPlacementStart: {
      width: "100%",
      marginLeft: 0,
      justifyContent: "space-between",
    },
  },
  MuiLinearProgress: {
    root: {
      height: 10,
    },
    colorSecondary: {
      backgroundColor: "#cfcfcf",
    },
    barColorSecondary: {
      backgroundColor: success.main,
    },
  },
  MuiLink: {
    root: {
      cursor: "pointer",
    },
    button: {
      fontSize: "0.75rem",
      fontFamily: ["Marine", "sans-serif"].join(","),
    },
  },
  MuiListItem: {
    divider: {
      borderBottom: `1px solid ${palette.border.main}`,
    },
  },
  MuiListItemIcon: {
    root: {
      color: "inherit",
      justifyContent: "center",
    },
  },
  MuiListItemText: {
    primary: {
      fontSize: "14px",
      fontWeight: 600,
    },
    secondary: {
      color: "inherit",
      fontWeight: 500,
    },
    inset: {
      paddingLeft: "90px",
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: "5px",
    },
    list: {
      padding: "0",
    },
  },
  MuiMenuItem: {
    root: {
      paddingTop: "0.75rem",
      paddingBottom: "0.75rem",
      background: "transparent",
      "&$selected": {
        backgroundColor: "#FDF6E0",
        "&:hover": {
          backgroundColor: "#FDF6E0",
        },
      },
      "&:hover": {
        backgroundColor: "#FDF6E0",
      },
    },
    dense: {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
  MuiTableCell: {
    head: {
      fontSize: "1rem",
      height: "38px",
      fontWeight: "bold",
    },
    body: {
      fontSize: "0.9rem",
    },
  },
  MuiTabs: {
    root: {
      width: "100%",
    },
  },
  MuiTab: {
    root: {
      display: "flex",
      minWidth: "0px",
      height: "64px",
      textTransform: "none",
      fontSize: "14px",
    },
    wrapper: {
      flexDirection: "row",
    },
    textColorInherit: {
      opacity: 1,
    },
  },
  MuiToggleButton: {
    root: {
      fontSize: "0.875rem",
      fontWeight: "400",
      "&:hover": {
        backgroundColor: alpha(info.main, 0.5),
        color: info.contrastText,
      },
      "&$selected": {
        backgroundColor: info.main,
        color: info.contrastText,
        "&:hover": {
          backgroundColor: info.main,
          color: info.contrastText,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    groupedVertical: {
      "&:not(:last-child)": {
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "&:not(:first-child)": {
        borderTop: "1px solid ".concat(
          alpha(
            palette.common?.black ? palette.common.black : common.black,
            0.12
          )
        ),
        marginTop: "1rem",
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
      },
    },
  },
  MuiToolbar: {
    regular: {
      minHeight: "64px",
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: "black",
    },
  },
};

/** Material UI Theme */
const theme = createTheme({
  palette,
  typography,
  shadows,
  shape,
  spacing: 8,
  overrides,
});
export default theme;

// /*
// Exporting for use in styled.d.ts so we can get typed theme inside styled components ie:
//     font-size: ${({theme})=>theme.typography.body2.fontSize};
//     color: ${({theme})=>theme.palette.chart.darkGray};
// *
export type ThemeType = typeof theme;

// material-ui exports things like useTheme from multiple places, ie @material-ui/styles and @material-ui/core
// When you import useTheme from @material-ui/core, the return value of useTheme() is correctly typed
// however, if you happen to import useTheme from @material-ui/styles, the return value is not correctly typed
// The below module augmentation fixes that problem.
declare module "@material-ui/styles" {
  export interface DefaultTheme extends ThemeType {}
}
