import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CategoryCell } from "~/components/table/cells/categoryCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import PrimeIcon from "~/img/amazon_prime.png";
import { Range } from "~/typedef/store";
import { SmallIcon } from "../widgets/salesByProduct";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchSourcingShare } from "~/store/mystore/vendor.redux";
import { formatCurrency } from "~/utils/currencyUtils";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SourcingShareTableProps {
  user: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  searchText?: string;
  actions?: ReactChild;
  pageSize: number;
  condensed?: boolean;
  conditionalFormatting?: boolean;
  report?: boolean;
  country: string;
}

const SourcingShareTable = memo<SourcingShareTableProps>(
  function SourcingShareTable({
    user,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    searchText,
    actions,
    pageSize,
    condensed,
    conditionalFormatting,
    report,
    country,
  }) {
    const { t } = useTranslation();
    const sourcingShare = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.data || []
    );

    const sourcingShareCurrency = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.currency || ""
    );
    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.lastReportDate
    );
    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.lastUpdatedAt
    );
    const sourcingShareCount = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.count || 0
    );
    const sourcingShareFetching = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.fetching || false
    );
    const sourcingShareParams = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.products?.params || {}
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const dispatch = useDispatch();

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchSourcingShare(
            {
              user,
              mid,
              currentRange,
              searchText,
              sortKey: sortBy[0]?.id || "lost_cogs",
              sortOrder: sortBy[0]?.id
                ? sortBy[0]?.desc
                  ? "desc"
                  : "asc"
                : "desc",
              pageIndex,
              pageSize,
            },
            sourcingShareParams
          )
        );
      },
      [user, mid, currentRange, searchText]
    );

    useEffect(() => {
      if (!sourcingShareFetching) {
        fetchData({ pageSize, pageIndex: 0, sortBy: [] });
      }
    }, []);

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: `ASIN: ${row.asin} | VPI: ${row.vpi}`,
            image: row.imageUrl,
            link: row.url,
            target: "_blank",
            icon: row.isFulfilledByAmazon ? (
              <SmallIcon src={PrimeIcon} />
            ) : null,
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          isVisible: true,
          sticky: "left",
          colSpan: 2,
        },
        ...(condensed
          ? []
          : [
              {
                id: "brand",
                Header: t("myStoresWidget.salesByBrand.brandColumn"),
                accessor: "brand",
                Cell: TextCell,
                colSpan: 3,
              },
              {
                id: "category",
                Header: t("myStoresWidget.salesByCategory.categoryColumn"),
                accessor: (row: {
                  categoryLabel: string;
                  parentCategory: string;
                }) => ({
                  current: row.categoryLabel,
                  parent: row.parentCategory,
                }),
                Cell: (props: any) => <CategoryCell {...props} />,
                colSpan: 3,
                disableSortBy: true,
              },
            ]),
        {
          Header: t("sourcingShare.lostCogsIn", { country }),
          accessor: (row: { lostCogs: number; priorLostCogs: number }) => {
            return {
              value:
                row.lostCogs !== undefined && row.lostCogs !== 0
                  ? formatCurrency(
                      row.lostCogs,
                      currencyRates,
                      sourcingShareCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorLostCogs
                ? getPercentageDifference(row.lostCogs, row.priorLostCogs)
                : "-",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "lost_cogs",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },

        ...(condensed
          ? []
          : [
              {
                Header: t("sourcingShare.lostUnitsIn", { country }),
                id: "lost_units",
                accessor: (row: {
                  lostUnits: number;
                  priorLostUnits: number;
                }) => {
                  return {
                    value:
                      row.lostUnits !== undefined && row.lostUnits !== 0
                        ? numberWithCommas(row.lostUnits.toFixed(0))
                        : "-",

                    growth: row.priorLostUnits
                      ? getPercentageDifference(
                          row.lostUnits,
                          row.priorLostUnits
                        )
                      : "-",
                  };
                },
                Cell: ValueAndGrowthCell,
                isVisible: true,
                align: "right",
              },
            ]),
        {
          Header: t("vendor.sourcingShare", { country }),
          accessor: (row: {
            sourcingShare: number;
            priorSourcingShare: number;
          }) => {
            return {
              value:
                row.sourcingShare !== undefined && row.sourcingShare !== 0
                  ? row.sourcingShare.toFixed(1)
                  : "-",
              growth: row.priorSourcingShare
                ? (row.sourcingShare - row.priorSourcingShare).toFixed(1)
                : "-",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "sourcing_share",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("sourcingShare.exportedCogs"),
          accessor: (row: {
            exportedCogs: number;
            priorExportedCogs: number;
          }) => {
            return {
              value:
                row.exportedCogs !== undefined && row.exportedCogs !== 0
                  ? formatCurrency(
                      row.exportedCogs,
                      currencyRates,
                      sourcingShareCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.exportedCogs
                ? getPercentageDifference(row.exportedCogs, row.exportedCogs)
                : "-",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "exported_cogs",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("sourcingShare.exportedUnits"),
          id: "exported_units",
          accessor: (row: {
            exportedUnits: number;
            priorExportedUnits: number;
          }) => {
            return {
              value:
                row.exportedUnits !== undefined && row.exportedUnits !== 0
                  ? numberWithCommas(row.exportedUnits.toFixed(0))
                  : "-",
              growth: row.priorExportedUnits
                ? getPercentageDifference(
                    row.exportedUnits,
                    row.priorExportedUnits
                  )
                : "-",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("vendor.exportedShare"),
          accessor: (row: {
            exportedShare: number;
            priorExportedShare: number;
          }) => {
            return {
              value:
                row.exportedShare !== undefined && row.exportedShare !== 0
                  ? row.exportedShare.toFixed(1)
                  : "-",
              growth: row.priorExportedShare
                ? (row.exportedShare - row.priorExportedShare).toFixed(1)
                : "-",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "exported_share",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("vendor.unitCogs"),
                accessor: (row: { unitCogs: number }) => {
                  return row.unitCogs !== undefined && row.unitCogs !== 0
                    ? formatCurrency(
                        row.unitCogs,
                        currencyRates,
                        sourcingShareCurrency,
                        currentCurrency
                      )
                    : "-";
                },
                id: "unit_cogs",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("vendor.otherUnitCogs"),
                wrapHeaderText: true,
                accessor: (row: { otherUnitCogs: number }) => {
                  return row.otherUnitCogs !== undefined &&
                    row.otherUnitCogs !== 0
                    ? formatCurrency(
                        row.otherUnitCogs,
                        currencyRates,
                        sourcingShareCurrency,
                        currentCurrency
                      )
                    : "-";
                },
                id: "other_unit_cogs",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
            ]),
      ],
      [
        mid,
        currentRange,
        currentPeriod,
        currentCurrency,
        currencyRates,
        sourcingShareCurrency,
      ]
    );

    const [columnConfig, setColumnConfig] = useState<Column[]>(
      columns.map((c) => ({
        Header: c.Header,
        id: c.id,
        isVisible: c.isVisible,
      }))
    );

    return (
      <Panel
        id="sourcing-share-table"
        title={t("sourcingShare.mainTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.updated", {
                lastReportDate: moment(lastReportDate).format("ll"),
                lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
              })}`
            : ""
        }
        content={
          condensed ? (
            <NoScrollTable
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: sourcingShare,
                pageSize,
                loading: sourcingShareFetching,
                isReport: report,
              }}
            />
          ) : (
            <Table
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: sourcingShare,
                fetchData,
                loading: sourcingShareFetching,
                sorting: true,
                pagination: true,
                pageCount: Math.ceil((sourcingShareCount || 0) / pageSize),
                pageSize: pageSize,
              }}
            />
          )
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: columnConfig, setColumns: setColumnConfig }}
              />
            )}
          </>
        }
      />
    );
  }
);

export default SourcingShareTable;
