import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import WeChatIconRaw from "~/img/wechat.png";
import { loginWeChat } from "~/store/user.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useWeChatAuthcodeAuth from "~/hooks/useWeChatAuthcodeAuth";

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const WeChatIcon = class extends React.Component {
  render() {
    return (
      <ImageWrapper>
        <img src={WeChatIconRaw} style={{ height: "19px" }}></img>
      </ImageWrapper>
    );
  }
};

const WeChatLogin = (props) => {
  const { className, children, onSuccess, onError } = props;
  const dispatch = useDispatch();
  const onCode = React.useCallback(
    (code) => {
      dispatch(loginWeChat(code))
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          if (onError) {
            onError(e);
          } else {
            throw e;
          }
        });
    },
    [dispatch]
  );
  const triggerSignin = useWeChatAuthcodeAuth(onCode);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <Button
      className={className}
      variant="outlined"
      color="secondary"
      onClick={handleSignin}
    >
      <div style={{ width: "100%", display: "flex" }}>
        <WeChatIcon />
        <div style={{ margin: "auto" }}>{children}</div>
      </div>
    </Button>
  );
};

WeChatLogin.propTypes = {
  children: PropTypes.any,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  className: PropTypes.string,
};

export default WeChatLogin;
