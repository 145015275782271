import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import StatusText from "~/components/typography/status";

interface ValueLowWarningCellProps {
  cell: {
    value: {
      displayValue: number;
      conditionalFormatting?: boolean;
      compareValue?: number;
      lowThreshold?: number;
      highThreshold?: number;
    };
  };
}

/**
 * Table cell to show traffic lighted values based on supplied thresholds
 * Lower values are "good", higher values are "bad".
 */
export const ValueLowWarningCell = memo(
  ({ cell }: ValueLowWarningCellProps) => {
    const displayValue = cell.value.displayValue;
    const status =
      cell.value.conditionalFormatting === false
        ? "" // Falsy status value gives theme.palette.common.black
        : Number(cell.value.compareValue) > Number(cell.value.highThreshold)
        ? "error"
        : Number(cell.value.compareValue) > Number(cell.value.lowThreshold)
        ? "warning"
        : "";
    return (
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <StatusText variant="body2" status={status}>
            {displayValue}
          </StatusText>
        </Grid>
      </Grid>
    );
  }
);
