import {
  CLEAR_MARKETING_OVERVIEW_EXPANDED,
  FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST,
  FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS,
  FETCH_MARKETING_SUMMARY_ERROR,
  FETCH_MARKETING_SUMMARY_REQUEST,
  FETCH_MARKETING_SUMMARY_SUCCESS,
  marketing,
} from "./marketing.redux";
import {
  FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW,
  FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW,
  FETCH_CANCELLED_ORDERS_LIST_OVERVIEW,
  cancelledOrdersOverview,
} from "./cancelledOrders.redux";

import axios from "axios";
import get from "lodash/get";

/** Sales Performance YTD */
export const FETCH_PERFORMANCE_YTD_FETCHING = "FETCH_PERFORMANCE_YTD_FETCHING";
export const FETCH_PERFORMANCE_YTD_SUCCESS = "FETCH_PERFORMANCE_YTD_SUCCESS";
export const FETCH_PERFORMANCE_YTD_ERROR = "FETCH_PERFORMANCE_YTD_ERROR";
/** Marketplace Overview */
export const FETCH_MARKETPLACE_OVERVIEW_TOTALS =
  "FETCH_MARKETPLACE_OVERVIEW_TOTALS";
export const FETCH_MARKETPLACE_OVERVIEW_TOTALS_FETCHING =
  "FETCH_MARKETPLACE_OVERVIEW_TOTALS_FETCHING";
export const FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR =
  "FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR";
/** Sales Mix */
export const FETCH_SALES_MIX = "FETCH_SALES_MIX";
export const FETCH_SALES_MIX_FETCHING = "FETCH_SALES_MIX_FETCHING";
export const FETCH_SALES_MIX_ERROR = "FETCH_SALES_MIX_ERROR";
/** Dispatch Status */
export const FETCH_DISPATCH_STATUS_OVERVIEW = "FETCH_DISPATCH_STATUS_OVERVIEW";
export const FETCH_DISPATCH_STATUS_OVERVIEW_FETCHING =
  "FETCH_DISPATCH_STATUS_OVERVIEW_FETCHING";
export const FETCH_DISPATCH_STATUS_OVERVIEW_ERROR =
  "FETCH_DISPATCH_STATUS_OVERVIEW_ERROR";
/** Overdue Orders Summary */
export const FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW =
  "FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW";
export const FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_FETCHING =
  "FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_FETCHING";
export const FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR =
  "FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR";
/** Overdue Orders List */
export const FETCH_OVERDUE_ORDER_LIST_OVERVIEW =
  "FETCH_OVERDUE_ORDER_LIST_OVERVIEW";
export const FETCH_OVERDUE_ORDER_LIST_OVERVIEW_FETCHING =
  "FETCH_OVERDUE_ORDER_LIST_OVERVIEW_FETCHING";
export const FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR =
  "FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR";
/** Top Products */
export const FETCH_TOP_PRODUCTS_OVERVIEW = "FETCH_TOP_PRODUCTS_OVERVIEW";
export const FETCH_TOP_PRODUCTS_OVERVIEW_FETCHING =
  "FETCH_TOP_PRODUCTS_OVERVIEW_FETCHING";
export const FETCH_TOP_PRODUCTS_OVERVIEW_ERROR =
  "FETCH_TOP_PRODUCTS_OVERVIEW_ERROR";
/** Inventory Status */
export const FETCH_INVENTORY_OVERVIEW = "FETCH_INVENTORY_OVERVIEW";
export const FETCH_INVENTORY_OVERVIEW_FETCHING =
  "FETCH_INVENTORY_OVERVIEW_FETCHING";
export const FETCH_INVENTORY_OVERVIEW_ERROR = "FETCH_INVENTORY_OVERVIEW_ERROR";
/** Account Health */
export const FETCH_ACCOUNT_HEALTH_OVERVIEW = "FETCH_ACCOUNT_HEALTH_OVERVIEW";
export const FETCH_ACCOUNT_HEALTH_OVERVIEW_FETCHING =
  "FETCH_ACCOUNT_HEALTH_OVERVIEW_FETCHING";
export const FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR =
  "FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR";

/** Sales by day */
export const FETCH_OVERVIEW_SALES_BY_DAY = "FETCH_OVERVIEW_SALES_BY_DAY";
export const FETCH_OVERVIEW_SALES_BY_DAY_FETCHING =
  "FETCH_OVERVIEW_SALES_BY_DAY_FETCHING";
export const FETCH_OVERVIEW_SALES_BY_DAY_ERROR =
  "FETCH_OVERVIEW_SALES_BY_DAY_ERROR";

/** Average by day */
export const FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY =
  "FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY";
export const FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING =
  "FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING";
export const FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR =
  "FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR";

/** Average by day by market*/
export const FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY =
  "FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY";
export const FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING =
  "FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING";
export const FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR =
  "FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR";

/** Purchase orders performance overview (time-series chart) */
export const FETCH_OVERVIEW_PO_PERFORMANCE = "FETCH_OVERVIEW_PO_PERFORMANCE";
export const FETCH_OVERVIEW_PO_PERFORMANCE_FETCHING =
  "FETCH_OVERVIEW_PO_PERFORMANCE_FETCHING";
export const FETCH_OVERVIEW_PO_PERFORMANCE_ERROR =
  "FETCH_OVERVIEW_PO_PERFORMANCE_ERROR";
export const FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE =
  "FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE";
export const FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_FETCHING =
  "FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_FETCHING";
export const FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_ERROR =
  "FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_ERROR";

/** Vendor metrics overview (multi-store table) */
export const FETCH_VENDOR_OVERVIEW_TABLE = "FETCH_VENDOR_OVERVIEW_TABLE";
export const FETCH_VENDOR_OVERVIEW_TABLE_FETCHING =
  "FETCH_VENDOR_OVERVIEW_TABLE_FETCHING";
export const FETCH_VENDOR_OVERVIEW_TABLE_ERROR =
  "FETCH_VENDOR_OVERVIEW_TABLE_ERROR";

export const FETCH_MIN_DATE_OVERVIEW = "FETCH_MIN_DATE_OVERVIEW";

/** Custom tags for all markets */
export const FETCH_CUSTOM_TAGS_OVERVIEW = "FETCH_CUSTOM_TAGS_OVERVIEW";
export const FETCH_CUSTOM_TAGS_OVERVIEW_FETCHING =
  "FETCH_CUSTOM_TAGS_OVERVIEW_FETCHING";

axios.defaults.withCredentials = true;

const initState = {
  salesPerformanceYTD: {
    fetching: false,
    chartData: [],
    current: 0,
    prior: 0,
  },
  marketplaceOverviewTotals: {
    fetching: false,
  },
  vendorOverview: {
    fetching: false,
    count: 0,
    rows: [],
  },
  salesMix: {
    fetching: false,
  },
  dispatchStatus: {
    fetching: false,
  },
  overdueOrders: {
    fetching: false,
  },
  overdueOrdersList: {
    fetching: false,
  },
  topProducts: {
    fetching: false,
  },
  inventoryStatus: {
    fetching: false,
  },
  accountHealth: {
    fetching: false,
  },
  salesByDay: {
    fetching: false,
  },
  averageSalesByDay: {
    fetching: false,
  },
  averageSalesByDayByMarket: {
    fetching: false,
  },
  purchaseOrdersPerformance: {
    fetching: false,
    chartData: [],
    current: 0,
    prior: 0,
    currency: "",
  },
  purchaseOrdersPerformanceShipDate: {
    fetching: false,
    chartData: [],
    current: 0,
    prior: 0,
    currency: "",
  },
  minDate: null,
  customTags: {
    fetching: false,
    tags: [],
  },
};

export const overview = (state = initState, action) => {
  switch (action.type) {
    /** Sales Performance YTD */
    case FETCH_PERFORMANCE_YTD_FETCHING:
      return {
        ...state,
        salesPerformanceYTD: {
          ...state.salesPerformanceYTD,
          fetching: true,
        },
      };
    case FETCH_PERFORMANCE_YTD_SUCCESS:
      return {
        ...state,
        salesPerformanceYTD: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_PERFORMANCE_YTD_ERROR:
      return {
        ...state,
        salesPerformanceYTD: {
          fetching: false,
          chartData: [],
          current: 0,
          prior: 0,
        },
      };

    /** Marketplace Overview */
    case FETCH_MARKETPLACE_OVERVIEW_TOTALS:
      return {
        ...state,
        marketplaceOverviewTotals: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_MARKETPLACE_OVERVIEW_TOTALS_FETCHING:
      return {
        ...state,
        marketplaceOverviewTotals: {
          ...state.marketplaceOverviewTotals,
          fetching: true,
        },
      };
    case FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR:
      return {
        ...state,
        marketplaceOverviewTotals: {
          fetching: false,
        },
      };

    /** Sales Mix */
    case FETCH_SALES_MIX:
      return {
        ...state,
        salesMix: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_SALES_MIX_FETCHING:
      return {
        ...state,
        salesMix: {
          ...state.salesMix,
          fetching: true,
        },
      };
    case FETCH_SALES_MIX_ERROR:
      return {
        ...state,
        salesMix: {
          fetching: false,
        },
      };

    /** Dispatch Status */
    case FETCH_DISPATCH_STATUS_OVERVIEW:
      return {
        ...state,
        dispatchStatus: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_DISPATCH_STATUS_OVERVIEW_FETCHING:
      return {
        ...state,
        dispatchStatus: {
          ...state.dispatchStatus,
          fetching: true,
        },
      };
    case FETCH_DISPATCH_STATUS_OVERVIEW_ERROR:
      return {
        ...state,
        dispatchStatus: {
          fetching: false,
        },
      };

    /** Overdue Orders Summary */
    case FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW:
      const overdueOrders = {
        fetching: false,
        ...action.payload,
      };
      return { ...state, overdueOrders };
    case FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_FETCHING:
      return {
        ...state,
        overdueOrders: {
          ...state.overdueOrders,
          fetching: true,
        },
      };
    case FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR:
      return {
        ...state,
        overdueOrders: {
          fetching: false,
        },
      };

    /** Overdue Orders List */
    case FETCH_OVERDUE_ORDER_LIST_OVERVIEW:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_OVERDUE_ORDER_LIST_OVERVIEW_FETCHING:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: true,
        },
      };
    case FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR:
      return {
        ...state,
        overdueOrdersList: {
          fetching: false,
        },
      };

    /** Cancelled orders list */
    case FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW:
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: true,
        },
      };
    case FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW:
      return {
        ...state,
        overdueOrdersList: {
          fetching: false,
        },
      };

    case FETCH_CANCELLED_ORDERS_LIST_OVERVIEW:
      const cancelledOrderData = cancelledOrdersOverview(
        get(state, "overdueOrdersList.overdueOrdersList.cancelledOrders"),
        action
      );
      return {
        ...state,
        overdueOrdersList: {
          ...state.overdueOrdersList,
          fetching: get(cancelledOrderData, "fetchingOverview"),
          cancelledOrders: get(cancelledOrderData, "overviewData"),
        },
      };

    /** Top Products */
    case FETCH_TOP_PRODUCTS_OVERVIEW:
      return {
        ...state,
        topProducts: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_TOP_PRODUCTS_OVERVIEW_FETCHING:
      return {
        ...state,
        topProducts: {
          ...state.topProducts,
          fetching: true,
        },
      };
    case FETCH_TOP_PRODUCTS_OVERVIEW_ERROR:
      return {
        ...state,
        topProducts: {
          fetching: false,
        },
      };

    /** Inventory Status */
    case FETCH_INVENTORY_OVERVIEW:
      const inventoryStatus = {
        fetching: false,
        ...action.payload,
      };
      return { ...state, inventoryStatus };
    case FETCH_INVENTORY_OVERVIEW_FETCHING:
      return {
        ...state,
        inventoryStatus: {
          ...state.inventoryStatus,
          fetching: true,
        },
      };
    case FETCH_INVENTORY_OVERVIEW_ERROR:
      return {
        ...state,
        inventoryStatus: {
          fetching: false,
        },
      };

    /** Account Health */
    case FETCH_ACCOUNT_HEALTH_OVERVIEW:
      const accountHealth = {
        fetching: false,
        ...action.payload,
      };
      return { ...state, accountHealth };
    case FETCH_ACCOUNT_HEALTH_OVERVIEW_FETCHING:
      return {
        ...state,
        accountHealth: {
          ...state.accountHealth,
          fetching: true,
        },
      };
    case FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR:
      return {
        ...state,
        accountHealth: {
          fetching: false,
        },
      };

    /** Sales By Day */
    case FETCH_OVERVIEW_SALES_BY_DAY:
      return {
        ...state,
        salesByDay: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_OVERVIEW_SALES_BY_DAY_FETCHING:
      return {
        ...state,
        salesByDay: {
          ...state.salesByDay,
          fetching: true,
        },
      };
    case FETCH_OVERVIEW_SALES_BY_DAY_ERROR:
      return {
        ...state,
        salesByDay: {
          fetching: false,
        },
      };

    /** Average Sales By Day */
    case FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY:
      return {
        ...state,
        averageSalesByDay: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING:
      return {
        ...state,
        averageSalesByDay: {
          ...state.averageSalesByDay,
          fetching: true,
        },
      };
    case FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR:
      return {
        ...state,
        averageSalesByDay: {
          fetching: false,
        },
      };

    /** Average Sales By Day By Market*/
    case FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY:
      return {
        ...state,
        averageSalesByDayByMarket: {
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING:
      return {
        ...state,
        averageSalesByDayByMarket: {
          ...state.averageSalesByDayByMarket,
          fetching: true,
        },
      };
    case FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR:
      return {
        ...state,
        averageSalesByDayByMarket: {
          fetching: false,
        },
      };
    /** Purchase Orders Performance */
    case FETCH_OVERVIEW_PO_PERFORMANCE: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          fetching: false,
          ...action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_PO_PERFORMANCE_FETCHING: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          ...state.purchaseOrdersPerformance,
          fetching: true,
        },
      };
    }
    case FETCH_OVERVIEW_PO_PERFORMANCE_ERROR: {
      return {
        ...state,
        purchaseOrdersPerformance: {
          chartData: [],
          current: 0,
          prior: 0,
          currency: "",
          fetching: false,
        },
      };
    }

    case FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          fetching: false,
          ...action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_FETCHING: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          ...state.purchaseOrdersPerformanceShipDate,
          fetching: true,
        },
      };
    }

    case FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_ERROR: {
      return {
        ...state,
        purchaseOrdersPerformanceShipDate: {
          chartData: [],
          current: 0,
          prior: 0,
          currency: "",
          fetching: false,
        },
      };
    }

    /** Vendor overview (table) */
    case FETCH_VENDOR_OVERVIEW_TABLE: {
      return {
        ...state,
        vendorOverview: {
          fetching: false,
          ...action.payload,
        },
      };
    }

    case FETCH_VENDOR_OVERVIEW_TABLE_FETCHING: {
      return {
        ...state,
        vendorOverview: {
          ...state.vendorOverview,
          fetching: true,
        },
      };
    }
    case FETCH_VENDOR_OVERVIEW_TABLE_ERROR: {
      return {
        ...state,
        vendorOverview: {
          rows: [],
          count: 0,
          fetching: false,
        },
      };
    }

    /** Advertising overview **/
    case FETCH_MARKETING_SUMMARY_ERROR:
    case FETCH_MARKETING_SUMMARY_REQUEST:
    case FETCH_MARKETING_SUMMARY_SUCCESS:
    case CLEAR_MARKETING_OVERVIEW_EXPANDED:
    case FETCH_MARKETING_OVERVIEW_EXPANDED_REQUEST:
    case FETCH_MARKETING_OVERVIEW_EXPANDED_SUCCESS:
      return {
        ...state,
        marketing: marketing(state.marketing, action),
      };

    /** Earliest date of orders */
    case FETCH_MIN_DATE_OVERVIEW:
      return {
        ...state,
        ...action.payload,
      };

    /** Custom tags by market*/
    case FETCH_CUSTOM_TAGS_OVERVIEW:
      return {
        ...state,
        customTags: {
          tags: action.payload,
          fetching: false,
        },
      };
    case FETCH_CUSTOM_TAGS_OVERVIEW_FETCHING:
      return {
        ...state,
        customTags: {
          ...state.customTags,
          fetching: true,
        },
      };

    default:
      return state;
  }
};
