import CampaignPerformance from "~/modules/marketing/campaignPerformance";
import CampaignTypes from "~/modules/marketing/campaignTypePieChart";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import InventoryHealth from "~/pages/singleChannel/brandAnalytics/inventoryHealth";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import OrderedRevenue from "~/pages/singleChannel/brandAnalytics/orderedRevenue";
import OverviewChartWrapper from "~/modules/marketing/charts/overviewChartWrapper";
import OverviewTotalsWrapper from "~/modules/marketing/charts/overviewTotalsWrapper";
import { Panel } from "~/components/panel/panel";
import PurchaseOrderStatus from "~/modules/widgets/amazonVendor/purchaseOrderStatus";
import PurchaseOrdersPerformance from "~/modules/widgets/amazonVendor/purchaseOrdersPerformance";
import React from "react";
import SalesByBrand from "~/modules/overview/salesByBrand";
import SalesByCategory from "~/modules/overview/salesByCategory";
import SalesByProduct from "~/modules/overview/salesByProduct";
import ShippedRevenue from "~/pages/singleChannel/brandAnalytics/shippedRevenue";
import SourcingShare from "~/pages/singleChannel/brandAnalytics/sourcingShare";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface DefaultStoreDashboardProps {
  isAdvertisingAuthorised: boolean;
  isReconnectAdvertising: boolean;
}

const AmazonVendorDashboard = ({
  isAdvertisingAuthorised,
  isReconnectAdvertising,
}: DefaultStoreDashboardProps) => {
  const { t } = useTranslation();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return store ? (
    <>
      <GridWithErrorBoundary item xs={12} md={8}>
        <OrderedRevenue singleWidget="trend" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={4}>
        <OrderedRevenue singleWidget="summary" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={8}>
        <ShippedRevenue singleWidget="trend" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={4}>
        <ShippedRevenue singleWidget="summary" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={8}>
        <ShippedRevenue singleWidget="table" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={4}>
        <SourcingShare singleWidget="summary" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={8}>
        <InventoryHealth singleWidget="trend" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={4}>
        <InventoryHealth singleWidget="summary" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={12}>
        <InventoryHealth singleWidget="table" />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={8}>
        <PurchaseOrdersPerformance market={store.marketplace} store={store} />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={4}>
        <PurchaseOrderStatus />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={6}>
        <SalesByCategory
          market={store.marketplace}
          mid={store.merchantId}
          condensed
        />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={6}>
        <SalesByBrand
          market={store.marketplace}
          mid={store.merchantId}
          condensed
        />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={6}>
        <SalesByProduct
          mid={store.merchantId}
          marketplace={store.marketplace}
          marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
          countryCode={store.marketplaceCountry}
          sellerId={store.sourceSystemId}
          type="topSellers"
          condensed={true}
        />
      </GridWithErrorBoundary>
      <GridWithErrorBoundary item xs={12} md={6}>
        <SalesByProduct
          mid={store.merchantId}
          marketplace={store.marketplace}
          marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
          countryCode={store.marketplaceCountry}
          sellerId={store.sourceSystemId}
          type="worstSellers"
          condensed={true}
        />
      </GridWithErrorBoundary>
      {store.marketplace === "amazon_vendor" &&
        (isAdvertisingAuthorised ? (
          <>
            <GridWithErrorBoundary item xs={12} md={8}>
              <OverviewChartWrapper
                countryCode={store.marketplaceCountry}
                marketplaceType={store.marketplace}
                mid={store.merchantId}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <OverviewTotalsWrapper
                countryCode={store.marketplaceCountry}
                mid={store.merchantId}
                marketplaceType={store.marketplace}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <CampaignPerformance
                market={store.marketplace}
                mid={store.merchantId}
                countryCode={store.marketplaceCountry}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <CampaignTypes
                {...{
                  mid: store.merchantId,
                  countryCode: store.marketplaceCountry,
                  marketplaceType: store.marketplace,
                }}
              />
            </GridWithErrorBoundary>
          </>
        ) : (
          <GridWithErrorBoundary item xs={12} md={6}>
            <Panel
              id="widget-marketing-campaign-chart"
              title={t(
                "advertisingDashboardWidget.authorise.performanceTrendCampaignPerformance"
              )}
              content={
                <MarketingAuthoriseUser
                  condensed
                  isReconnect={isReconnectAdvertising}
                />
              }
            />
          </GridWithErrorBoundary>
        ))}
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default AmazonVendorDashboard;
