import {
  FETCH_VENDOR_OVERVIEW_TABLE,
  FETCH_VENDOR_OVERVIEW_TABLE_ERROR,
  FETCH_VENDOR_OVERVIEW_TABLE_FETCHING,
} from "./overview.redux";
import { Filter, Range } from "~/typedef/store";

import { Dispatch } from "redux";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

function setFetching(dispatch: Dispatch) {
  dispatch({
    type: FETCH_VENDOR_OVERVIEW_TABLE_FETCHING,
  });
}

interface FetchPurchaseOrderOverviewParams {
  currentRange: Range;
  filter?: Filter;
  includeTax: boolean;
  pageIndex: number;
  pageSize: number;
}

export const fetchVendorOverview =
  (
    params: FetchPurchaseOrderOverviewParams,
    oldParams?: FetchPurchaseOrderOverviewParams
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, pageIndex, pageSize, filter, includeTax } = params;
    if (!shouldUseCache(params, oldParams)) {
      setFetching(dispatch);
      const res = await axios
        .post(`${baseUrl}/api/generic-mws-service/api/vendor/overview`, {
          ...currentRange,
          pageIndex,
          pageSize,
          filter,
          includeTax,
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(dispatch, FETCH_VENDOR_OVERVIEW_TABLE_ERROR);
          return setError(dispatch, msg || statusText, get(err, "status"));
        });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          const data = res.data;
          return dispatch({
            type: FETCH_VENDOR_OVERVIEW_TABLE,
            payload: {
              rows: data.rows,
              count: data.count,
              params,
            },
          });
        } else {
          await dispatchError(dispatch, FETCH_VENDOR_OVERVIEW_TABLE_ERROR);
          return setError(dispatch, res.data.errMsg, res.status);
        }
      }
      return setError(dispatch);
    }
  };
