import { PaginationArgs } from "~/typedef/pagination";
import { Range } from "~/typedef/store";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface FetchSnSTrendArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubType: string;
  countryCode: string;
  currentRange: Range;
}

export interface FetchSnSTrendData {
  startDate: number;
  endDate: number;
  currency: string;
  current: {
    revenue: number;
    subscribers: number;
  };
  prior: {
    revenue: number;
    subscribers: number;
  };
}

export interface FetchSnSTrendDataResponse {
  chartData: FetchSnSTrendData[];
}

interface FetchSnSSummaryArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubType?: string;
  countryCode: string;
  currentRange: Range;
}

export interface FetchSnSSummaryResponse {
  snsRevenue: ComparisonData;
  snsSubscribers: ComparisonData;
  subscriberUnits: ComparisonData;
  shippedRevenue: ComparisonData;
  currency: string;
  startDate: number;
  endDate: number;
}

export interface FetchSnSProductPerformanceArgs extends PaginationArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentRange: Range;
  searchText?: string;
}

interface ComparisonData {
  current: number;
  prior: number;
}

export interface SnsProductPerformanceRow {
  asin: string;
  sku: string;
  title?: string;
  link?: string;
  imageUrl?: string;
  subscriberShippedRevenue: ComparisonData;
  subscribers: ComparisonData;
  revenuePerSubscriber: ComparisonData;
  revenuePenetration: ComparisonData;
  notDeliveredDueToOOSPercent: number;
  baseDiscount: number;
  tierDiscount: number;
  enrollment: string;
  eligibility: string;
}

export interface FetchSnSProductPerformanceResponse {
  productPerformance: SnsProductPerformanceRow[];
  currency?: string;
  startDate: number;
  endDate: number;
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    subscribeAndSaveTrend: build.query<
      FetchSnSTrendDataResponse,
      FetchSnSTrendArgs
    >({
      query: (params) => {
        const { currentRange, ...rest } = params;
        return {
          url: `${baseUrl}/api/amazon-mws-service/api/subscribeAndSave/chart`,
          method: "POST",
          data: {
            ...rest,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SubscribeAndSaveChart"),
    }),
    subscribeAndSaveSummary: build.query<
      FetchSnSSummaryResponse,
      FetchSnSSummaryArgs
    >({
      query: (params) => {
        const { currentRange, ...rest } = params;
        return {
          url: `${baseUrl}/api/amazon-mws-service/api/subscribeAndSave/summary`,
          method: "POST",
          data: {
            ...rest,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SubscriberAndSaveSummary"),
    }),
    subscribeAndProductPerformance: build.query<
      FetchSnSProductPerformanceResponse,
      FetchSnSProductPerformanceArgs
    >({
      query: (params) => {
        const { currentRange, ...rest } = params;
        return {
          url: `${baseUrl}/api/amazon-mws-service/api/subscribeAndSave/productPerformance`,
          method: "POST",
          data: {
            ...rest,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SubscriberAndSaveProductTable"),
    }),
  }),
});

export const {
  useSubscribeAndSaveTrendQuery,
  useSubscribeAndSaveSummaryQuery,
  useSubscribeAndProductPerformanceQuery,
} = extendedApiSlice;
