import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import Panel from "~/components/panel/panel";
import PerformanceSummary from "~/modules/widgets/advertising/performanceSummary";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useMarketingAllCampaignsTotalsQuery } from "~/store/mystore/marketing.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AllCampaignsTotalsPanelProps {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  groupId: string;
}

const AllCampaignsTotalsPanel = memo<AllCampaignsTotalsPanelProps>(
  function AllCampaignsTotalsPanel({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    groupId,
  }) {
    const { t } = useTranslation();
    const includeTax = useTypedSelector((state: StoreState) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const userInfo = useTypedSelector((state) => state.user);
    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const { data, loading } = useMarketingAllCampaignsTotalsQuery(
      {
        customerId: userInfo._id,
        mid,
        countryCode,
        marketplaceType,
        marketplaceSubtype,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        priorFromDate: currentRange.priorFromDate,
        priorToDate: currentRange.priorToDate,
        timezone: selectedTimezone,
        includeTax,
        groupId,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            data: data?.summaryData ? data?.summaryData : null,
            loading: isFetching,
          };
        },
      }
    );

    return (
      <Panel
        id="widget-marketing-campaign-totals"
        title={t("advertisingDashboardWidget.overallCampaignPerformanceTotals")}
        content={
          <Box p={2} height="100%" display="flex" alignItems="center">
            {loading || !data ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceSummary
                    data={data}
                    homeCurrency={homeCurrency}
                    currentCurrency={currentCurrency}
                    isOverview={true}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default AllCampaignsTotalsPanel;
