import React, { useCallback, useEffect, useState } from "react";
import { get, isEmpty } from "lodash";

import { Box } from "@material-ui/core";
import { CampaignCustomGroups } from "~/typedef/customGroups/advertising";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ObjectFilterSelect from "~/components/select/objectFilterSelect";
import { Store } from "@typedef/store";
import { fetchAllCampaignCustomGroups } from "~/store/customGroups/advertising.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CampaignCustomGroupsSelect = ({
  setSelected,
  selected,
  store,
}: {
  setSelected: (selectedGroups: CampaignCustomGroups[]) => void;
  selected: CampaignCustomGroups[];
  store: Store | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const campaignCustomGroups = useTypedSelector(
    (state) => state?.advertisingCustomGroups?.groups?.data
  );

  const campaignCustomGroupsFetching = useTypedSelector(
    (state) => state?.advertisingCustomGroups?.groups?.fetching
  );

  const [campaignCustomGroupOptions, setCampaignCustomGroupOptions] = useState(
    campaignCustomGroups || []
  );

  const triggerRefetch = useTypedSelector(
    (state) => state?.advertisingCustomGroups?.triggerRefetch
  );

  const [groupSearch, setGroupSearch] = useState("");

  const dispatchFetchCampaignCustomGroups = useCallback(
    (groupSearch: string) => {
      if (store) {
        dispatch(
          fetchAllCampaignCustomGroups({
            mid: store.merchantId,
            marketplaceType: store.marketplaceType,
            marketplaceSubtype: store.marketplaceSubtype,
            countryCode: store.marketplaceCountry,
            searchText: groupSearch,
          })
        );
      }
    },
    [store]
  );

  useEffect(() => {
    if (!campaignCustomGroupsFetching) {
      dispatchFetchCampaignCustomGroups(groupSearch);
    }
  }, [store?.merchantId]);

  useEffect(() => {
    if (triggerRefetch && !campaignCustomGroupsFetching) {
      dispatchFetchCampaignCustomGroups(groupSearch);
    }
  }, [triggerRefetch]);

  useEffect(() => {
    setCampaignCustomGroupOptions(campaignCustomGroups || []);
  }, [campaignCustomGroups]);

  useEffect(() => {
    if (!isEmpty(groupSearch)) {
      setCampaignCustomGroupOptions(
        campaignCustomGroupOptions?.filter(
          (customGroup) =>
            !customGroup.groupName
              .toLowerCase()
              .indexOf(groupSearch.toLowerCase())
        )
      );
    } else {
      setCampaignCustomGroupOptions(campaignCustomGroups || []);
    }
  }, [groupSearch]);

  const handleSelect = (
    values: CampaignCustomGroups[],
    customGroups: CampaignCustomGroups[]
  ) => {
    const newSelectedGroups = customGroups?.filter((customGroup) =>
      values.find((value) => value.groupId === customGroup.groupId)
    );
    setSelected(newSelectedGroups || []);
  };

  return (
    <>
      {campaignCustomGroupsFetching ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <ObjectFilterSelect
          {...{
            options: campaignCustomGroupOptions,
            selectedOptions: selected || [],
            filterTitle: t("customGroupsChart.selectTitle"),
            handleSelect,
            groups: campaignCustomGroups,
            isFullWidth: true,
            searchable: true,
            setSearchText: setGroupSearch,
            displayKey: "groupName",
            idKey: "groupId",
          }}
        />
      )}
    </>
  );
};

export default CampaignCustomGroupsSelect;
