import { Grid, Link } from "@material-ui/core";
import React, { memo } from "react";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const StyledAssignmentIcon = styled(AssignmentOutlinedIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 19px;
  height: 19px;
  padding-top: 3px;
`;

interface IconLinkProps {
  cell: any;
}

const IconLinkCell = memo(({ cell }: IconLinkProps) => {
  const value = cell.value;
  const link = value.link;
  return (
    <Grid container justifyContent="flex-end">
      <Link to={link} component={RouterLink} variant="body2">
        <StyledAssignmentIcon />
      </Link>
    </Grid>
  );
});

export default IconLinkCell;
