import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import { IconButton, Tooltip } from "@material-ui/core";
import { Range, StoreState } from "~/typedef/store";

import { DateRange } from "~/typedef/date";
import React from "react";
import { Sync } from "@material-ui/icons";
import { User } from "~/typedef/user";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Button = styled(IconButton)`
  padding: 6px;
`;

interface RefreshDateParams {
  currentPeriod: DATETIME_PERIODS;
  currentCompare: COMPARISON_PERIOD;
  selectedTimezone: string;
  currentRange: DateRange;
}

/**
 * Update the date range based on the current selected period so that
 * e.g. last 30 days is always 30 days before today instead of becoming
 * outdated due to persistent app settings; but don't override the
 * previously selected interval
 */
export const getNewRange = ({
  currentPeriod,
  currentCompare,
  selectedTimezone,
  currentRange,
}: RefreshDateParams): DateRange => {
  const newRange = getDatesFromPeriod(
    currentPeriod,
    currentCompare,
    selectedTimezone,
    currentRange.fromDate,
    currentRange.toDate,
    currentRange.priorFromDate,
    currentRange.priorToDate,
    currentRange.interval,
  );

  return newRange;
};

const SyncButton = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user: User = useTypedSelector((state: StoreState) => state.user);
  const currentPeriod: DATETIME_PERIODS = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone: string = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare: COMPARISON_PERIOD = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange: Range = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const refreshDates = () => {
    const newRange = getNewRange({
      currentPeriod,
      currentCompare,
      selectedTimezone,
      currentRange,
    });
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            currentPeriod,
            timezone: selectedTimezone,
            currentRange: newRange,
            currentCompare,
          },
        },
        user?.isDemoMode ? false : true
      )
    );
  };

  const lastUpdatedDisplay = currentRange.lastUpdated
    ? moment(currentRange.lastUpdated).fromNow()
    : moment().fromNow();

  return (
    <Tooltip
      title={
        t("syncButton.tooltip", { lastUpdated: lastUpdatedDisplay }) as string
      }
    >
      <Button aria-label="sync" onClick={refreshDates}>
        <Sync />
      </Button>
    </Tooltip>
  );
};

export default SyncButton;
