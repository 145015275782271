import {
  createIntercomTags,
  createIntercomUserEvents,
  getIntercomUserEvents,
  getMarketingContact,
  getMarketingContactId,
  updateMarketingContact,
} from "~/utils/intercomUtils";

import ReactGA from "react-ga";
import { fetchSubscription } from "../../store/subscriptions.redux";
import get from "lodash/get";
import moment from "moment-timezone";

/**Submit the Braintree checkout form to the backend.
 * @param {object} formState - the object representing the checkout form.
 * @param {boolean} newSubscriber - whether this user is subscribing for the first time
 * @param {Array} history - the react-router history object.
 * @param {object} user - an object representing the user
 * @param {function} dispatch - a Redux dispatch function created by useDispatch
 */
export const handleSubmit =
  (formState, newSubscriber, user, history, dispatch) =>
  async (token, promoCode, promoCodeDescription) => {
    const paymentMethodNonce = token.nonce;
    const {
      userId,
      planId,
      firstName,
      lastName,
      email,
      company,
      abn,
      streetAddress,
      extendedAddress,
      locality,
      region,
      postalCode,
      countryCode,
    } = formState;

    const shouldUpdateBillingAddress = Boolean(firstName || lastName);
    const body = {
      paymentMethodNonce,
      newSubscriber,
      planId,
      userId,
      customer: {
        firstName,
        lastName,
        email,
        company,
        abn,
      },
      promoCode,
    };

    if (shouldUpdateBillingAddress) {
      body.billingAddress = {
        firstName,
        lastName,
        company,
        streetAddress,
        extendedAddress,
        locality,
        region,
        postalCode,
        countryCode,
      };
    }

    const intercomCustomAttrib = {
      Plan_promo_code: promoCode,
      Plan_promo_description: promoCodeDescription,
    };

    const response = await fetch("/api/subscription-service/api/subscription", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw response;
    }

    ReactGA.event({
      category: "Subscription",
      action: "Payment Method - Completed",
    });

    const subscriptions = await dispatch(fetchSubscription());

    const contact = await getMarketingContact(userId);
    const contactId = getMarketingContactId(contact);
    if (contactId) {
      await updateMarketingContact(
        userId,
        contactId,
        intercomCustomAttrib,
        email
      );
      await createIntercomTags(userId, "Started Plan");
      const events = await getIntercomUserEvents(contactId);
      const allEvents = get(events, "data.events", []);
      const checkoutFinishEventLength = allEvents.filter(
        (event) => get(event, "event_name") === "checkout finish"
      ).length;
      const metadata = {
        Date: moment(Date.now()).format("D MMM YYYY"),
        Count: checkoutFinishEventLength + 1,
        Plan: planId,
        Price: subscriptions.payload.plan.cost,
        Currency: subscriptions.payload.plan.currency,
        "Promo code": promoCode,
        "Promo code description": promoCodeDescription,
      };

      await createIntercomUserEvents(
        "Checkout Finish",
        moment().seconds(0).milliseconds(0).unix(),
        userId,
        metadata
      );
    }

    if (newSubscriber && user.isNewUser) {
      history.push("/connect?subscriptionSuccess=true");
    } else {
      history.push("/overview?subscriptionSuccess=true");
    }
  };
