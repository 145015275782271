import { Box, Grid, Tooltip, Typography, useTheme } from "@material-ui/core";
import styled, { Keyframes, keyframes } from "styled-components";

import React from "react";

const Value = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.chartSummary.fontWeight};
  font-size: ${({ theme }) => theme.typography.chartSummary.fontSize};
`;

interface BarProps {
  barColor: string;
  percent: number;
  barHeight: number;
  animation: Keyframes;
}

const makeAnimation = (from: number, to: number) => {
  return keyframes`
  from {
    width: ${from}%;
  }
  to {
    width: ${to}%;
  }
  `;
};

const BarPart = styled.span<BarProps>`
  background-color: ${(props) => props.barColor};
  width: ${(props) => props.percent}%;
  box-sizing: border-box;
  height: ${(props) => props.barHeight}px;
  display: inline-block;
  animation-name: ${(props) => props.animation};
  animation-duration: 2s;
`;

export enum ProgressVariant {
  Percent = "PERCENT",
  Fraction = "FRACTION",
}

type ChartLayoutProps = {
  title: string;
  primaryColor: string;
  secondaryColor?: string;
  height?: number;
};

type PercentType = {
  // any other variant must have a discriminant called 'variant'
  variant: ProgressVariant.Percent;
  percent: number;
} & ChartLayoutProps;

type FractionType = {
  // any other variant must have a discriminant called 'variant'
  variant: ProgressVariant.Fraction;
  numerator: number;
  denominator: number;
} & ChartLayoutProps;

export type ProgressBarProps = PercentType | FractionType;

const isPercentType = (
  props: PercentType | FractionType
): props is PercentType => {
  return (props as PercentType).variant === ProgressVariant.Percent;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const theme = useTheme();
  const {
    title,
    secondaryColor = theme.palette.chart.lightGray,
    primaryColor,
    height = 6, // A fairly skinny bar, based on the design for walmart listing quality
  } = props;

  const percentage = isPercentType(props)
    ? props.percent
    : (props.numerator / props.denominator) * 100;
  const intPercent = Math.round(percentage);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="space-around"
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Value>
          {isPercentType(props)
            ? `${intPercent}%`
            : `${props.numerator}/${props.denominator}`}
        </Value>
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        {!isPercentType(props) ? (
          <>
            <Tooltip title={`${props.numerator}/${props.denominator}`}>
              <BarPart
                barHeight={height}
                barColor={primaryColor}
                percent={intPercent}
                animation={makeAnimation(0, intPercent)}
              />
            </Tooltip>
            <Tooltip
              title={`${props.denominator - props.numerator}/${
                props.denominator
              }`}
            >
              <BarPart
                barHeight={height}
                barColor={secondaryColor}
                percent={100 - intPercent}
                animation={makeAnimation(100, 100 - intPercent)}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <BarPart
              barHeight={height}
              barColor={primaryColor}
              percent={intPercent}
              animation={makeAnimation(0, intPercent)}
            />
            <BarPart
              barHeight={height}
              barColor={secondaryColor}
              percent={100 - intPercent}
              animation={makeAnimation(100, 100 - intPercent)}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(ProgressBar);
