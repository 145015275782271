import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import ErrorButton from "../../../components/buttons/errorButton";
import PropTypes from "prop-types";
import RaisedButton from "../../../components/buttons/raisedButton.tsx";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ErrorTitle = styled(DialogTitle)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const DialogBox = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    title,
    content,
    handleContinue,
    handleSaveSettings,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <ErrorTitle data-testid="away-dialog-title" id="form-dialog-title">
        {title}
      </ErrorTitle>
      <DialogContent>
        <DialogContentText data-testid="away-dialog-content">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} direction="row">
          <FlexGrid item xs={12} sm={6}>
            <ErrorButton
              data-testid="dialog-cancel-button"
              onClick={handleContinue}
              name="cancelButton"
            >
              {t("generic.leaveButton")}
            </ErrorButton>
          </FlexGrid>
          <FlexGrid item xs={12} sm={6}>
            <RaisedButton
              onClick={handleSaveSettings}
              data-testid="review-save-button"
              name="saveButton"
              color="info"
            >
              {t("reviewAutomation.saveAndApply")}
            </RaisedButton>
          </FlexGrid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
DialogBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleSaveSettings: PropTypes.func.isRequired,
};

export default DialogBox;
