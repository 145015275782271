import { Box, Grid } from "@material-ui/core";
import { Filter, Range } from "@typedef/store";
import React, { memo } from "react";

import Panel from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import PerformanceSummary from "../advertising/performanceSummary";
import { useMarketingOverviewTotalsQuery } from "~/store/overview/marketing.redux";
import { useTranslation } from "react-i18next";

interface MarketingOverviewTotalsParams {
  includeTax: boolean;
  currentFilter: Filter;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
  conditionalFormatting?: boolean;
}

const MarketingOverviewTotals = memo<MarketingOverviewTotalsParams>(
  function MarketingOverviewTotals({
    includeTax,
    currentFilter,
    currentRange,
    currentCurrency,
    timezone,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();

    const { data, loading } = useMarketingOverviewTotalsQuery(
      {
        filter: currentFilter,
        currentRange,
        targetCurrency: currentCurrency,
        includeTax,
        timezone,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            data: data?.summaryData,
            loading: isFetching,
          };
        },
      }
    );

    return (
      <Panel
        id="widget-marketing-overview-totals"
        title={t("advertisingDashboardWidget.performanceTotals.mainTitle")}
        tooltip={t("advertisingDashboardWidget.performanceTotals.mainTooltip")}
        content={
          <Box p={2} height="100%" display="flex" alignItems="center">
            {loading || !data ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceSummary
                    {...{
                      data,
                      homeCurrency: currentCurrency,
                      currentCurrency,
                      isOverview: true,
                      conditionalFormatting,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default MarketingOverviewTotals;
