import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CountryLogo } from "../../../img/flags/country_logos";
import LoadingIndicator from "../../loadingIndicator/loadingIndicator";
import { MarketplaceIcon } from "../../../img/marketplaces/icons/marketplaceIcon";
import Medium from "../../typography/medium";
import NoData from "../../loadingIndicator/noData";
import Pagination from "../../table/tablePagination";
import PropTypes from "prop-types";
import { fetchWeeklySalesHeatmap } from "../../../store/mystore/averageSalesByDay.redux";
import { formatCurrency } from "~/utils/currencyUtils";
import get from "lodash/get";
import { getShopName } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useLayoutProps } from "../chartUtils/chartComponents";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const getBackgroundColor = (percentage) => {
  const theme = useTheme();
  if (percentage < 10) {
    return theme.palette.secondary.light;
  }
  if (percentage < 25) {
    return theme.palette.secondary.main;
  }
  if (percentage >= 25) {
    return theme.palette.secondary.dark;
  }
  return "transparent";
};

const Heatbox = withTheme(styled(Box)`
  background-color: ${({ percentage }) => getBackgroundColor(percentage)};
  border-left: 1px solid white;
  color: white;
  width: 100%;
`);

const HeatmapTooltip = styled(Tooltip)`
  border-radius: 0;
`;

const HeatmapSquare = styled(Grid)`
  display: flex;
  flex: 1 0 10%;
  max-width: ${({ $isMobile }) => ($isMobile ? "14.2%" : "10%")};
`;

const PaginationWrapper = styled.div`
  padding-bottom: 3px;
`;

const getPageOptions = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Heatmap = ({ displayType }) => {
  const pageLimit = 5;
  const user = useSelector((state) => state.user);
  const filteredStores = useSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const currencyRates = useSelector((state) => state.globalVar.currencyRates);
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const selectedTimezone = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.timezone", moment.tz.guess())
  );
  const currentFilter = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const includeTax = useSelector((state) =>
    Boolean(get(state, "persistentAppSettings.setting.data.includeTax", true))
  );

  const [currentPage, setCurrentPage] = useState(0);

  const {
    fetching,
    rows: pageData,
    count,
    params,
  } = useSelector((state) => state.overview.averageSalesByDayByMarket);

  const dispatch = useDispatch();

  const dispatchFetchWeeklySalesHeatmap = useCallback(
    (userInfo, pageIndex, filter) => {
      dispatch(
        fetchWeeklySalesHeatmap(
          {
            user: { _id: userInfo._id },
            timezone: selectedTimezone,
            pageIndex,
            pageLimit,
            filter,
            filteredStores,
            includeTax,
          },
          params
        )
      );
    },
    [dispatch, includeTax, filteredStores, params, selectedTimezone]
  );

  useEffect(() => {
    dispatchFetchWeeklySalesHeatmap(user, currentPage, currentFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentFilter, filteredStores, includeTax, user]);

  if (fetching) {
    return <LoadingIndicator />;
  }

  if (!pageData || !pageData.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <NoData />
      </Box>
    );
  }

  return (
    <HeatmapChart
      pageData={pageData}
      count={count}
      filteredStores={filteredStores}
      displayType={displayType}
      currencyRates={currencyRates}
      currentCurrency={currentCurrency}
      pageLimit={pageLimit}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};

Heatmap.propTypes = {
  displayType: PropTypes.oneOf(["percentage", "sales"]),
};

export default Heatmap;

export const HeatmapChart = ({
  pageData,
  count,
  filteredStores,
  displayType,
  currencyRates,
  currentCurrency,
  pageLimit,
  currentPage,
  setCurrentPage,
}) => {
  const theme = useTheme();
  const { padding } = useLayoutProps("stepChart");
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [pageCount, setPageCount] = useState(Math.ceil(count / pageLimit));
  const [pageOptions, setPageOptions] = useState(getPageOptions(1, pageCount));

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(currentPage - 1);
  const gotoPage = (pageIndex) => setCurrentPage(pageIndex);

  useEffect(() => {
    setPageCount(Math.ceil(count / pageLimit));
  }, [count, pageLimit]);

  useEffect(() => {
    setPageOptions(getPageOptions(1, pageCount));
  }, [pageCount]);

  useEffect(() => {
    const canPrevious = () => currentPage > 0;
    const canNext = () => currentPage < pageCount - 1;
    setCanPreviousPage(canPrevious);
    setCanNextPage(canNext);
  }, [currentPage, pageCount]);

  return (
    <Box
      p={padding}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      pb={0}
    >
      <Grid container spacing={1}>
        {pageData.map((storeData, i) => {
          const salesData = storeData.data;
          const marketplace = storeData.marketplace;
          const mid = storeData.store;
          return (
            <Grid container item key={`heatmap-row-${i}`} wrap="nowrap">
              {salesData &&
                salesData.map((dayData, j) => (
                  <HeatmapSquare
                    item
                    xs={1}
                    key={`heatmap-square-${i}-${j}`}
                    $isMobile={isMobile}
                  >
                    <HeatmapTooltip
                      title={
                        displayType === "sales"
                          ? `${DAYS_OF_WEEK[j]} ${dayData.percentage || "0"}%`
                          : `${DAYS_OF_WEEK[j]} ${formatCurrency(
                              dayData.amount,
                              currencyRates,
                              storeData.currency,
                              currentCurrency
                            )}`
                      }
                    >
                      <Heatbox
                        p={1}
                        percentage={dayData.percentage}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {displayType === "sales" ? (
                          <Medium variant="body2">
                            {formatCurrency(
                              dayData.amount,
                              currencyRates,
                              storeData.currency,
                              currentCurrency
                            )}
                          </Medium>
                        ) : (
                          <Medium variant="body2">
                            {dayData.percentage || "0"}%
                          </Medium>
                        )}
                      </Heatbox>
                    </HeatmapTooltip>
                  </HeatmapSquare>
                ))}
              <Grid item>
                <Box display="flex" alignItems="center" height="100%" pl={1}>
                  <Box
                    pr={0.5}
                    display="flex"
                    alignItems="center"
                    height="100%"
                  >
                    <MarketplaceIcon
                      marketplaceSubtype={marketplace.marketplaceSubtype}
                      market={marketplace.market}
                      mid={mid}
                    />
                  </Box>
                  <CountryLogo code={marketplace.countryCode} />
                  <Box pl={1} display="flex" alignItems="center" height="100%">
                    <Typography variant="body2" color="textPrimary">
                      {getShopName(filteredStores, marketplace.market, mid)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
        <Grid container item xs={12} key={`heatmap-row-axis`}>
          {DAYS_OF_WEEK.map((dayOfWeek, j) => (
            <HeatmapSquare
              item
              xs={1}
              key={`heatmap-square-axis-${j}`}
              $isMobile={isMobile}
            >
              <Box
                pl={1}
                pr={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body1" color="textPrimary" align="center">
                  {dayOfWeek}
                </Typography>
              </Box>
            </HeatmapSquare>
          ))}
        </Grid>
      </Grid>
      <PaginationWrapper>
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={currentPage}
        />
      </PaginationWrapper>
    </Box>
  );
};

HeatmapChart.propTypes = {
  pageData: PropTypes.array,
  count: PropTypes.number,
  filteredStores: PropTypes.arrayOf(PropTypes.object),
  displayType: PropTypes.oneOf(["percentage", "sales"]),
  currencyRates: PropTypes.array,
  currentCurrency: PropTypes.string,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  pageLimit: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
