import { FETCH_MIN_DATE_OVERVIEW } from "./overview.redux";
import { FETCH_MIN_DATE_STORE } from "../mystore/mystore.redux";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const fetchMinDate = (user, mid, filter) => async (dispatch) => {
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/minDate`, {
      mid,
      filter,
    })
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(dispatch, msg || statusText, get(err, "status"));
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: FETCH_MIN_DATE_OVERVIEW,
        payload: data,
      });
    } else {
      return setError(dispatch, res.data.errMsg, res.status);
    }
  }
  return setError(dispatch);
};

export const fetchStoreMinDate = (user, mid, filter) => async (dispatch) => {
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/minDate`, {
      mid,
      filter,
    })
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(dispatch, msg || statusText, get(err, "status"));
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: FETCH_MIN_DATE_STORE,
        payload: data,
      });
    } else {
      return setError(dispatch, res.data.errMsg, res.status);
    }
  }
  return setError(dispatch);
};
