import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { ADVERTISING_BUTTONS } from "../connectWizard/connectPromptOptions";
import AmazonAuthoriseAdvertising from "~/modules/login/amazonAuthoriseAdvertising";
import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import { Store } from "~/typedef/store";
import { loadInfo } from "~/store/user.redux";
import { regionalCountryCodes } from "mm-amazon-common/dist/mwsConstants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;
const MarketplaceLogoIcon = styled.img`
  padding: 4px;
  max-height: 32px;
`;

const NORTH_AMERICA = "NA";
const EUROPE = "EU";
const ASIA_PACIFIC = "AP";

interface MarketingAuthoriseUserParams {
  store: Store;
  onSuccess: (goToNextStage?: boolean) => void;
  setIsSubmitting: (bool: boolean) => void;
}

const MarketingAuthoriseUser = ({
  store,
  onSuccess,
  setIsSubmitting,
}: MarketingAuthoriseUserParams) => {
  const [region, setRegion] = useState("");
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const countryCode = store.marketplaceCountry;
  const marketplace = store.marketplaceType;
  const errMsg = useRef("");
  const mid = store.merchantId;
  const { t } = useTranslation();

  // Amazon Vendor stores do not have Seller IDs, so passing null into
  // AmazonAuthoriseAdvertising is the expected behaviour
  const sellerId = marketplace === "amazon" ? store.sourceSystemId : null;

  const getRegion = (countryCode: AmazonCountry) => {
    if (regionalCountryCodes[NORTH_AMERICA].includes(countryCode)) {
      return NORTH_AMERICA;
    }

    if (regionalCountryCodes[EUROPE].includes(countryCode)) {
      return EUROPE;
    }
    if (regionalCountryCodes[ASIA_PACIFIC].includes(countryCode)) {
      return ASIA_PACIFIC;
    }

    return NORTH_AMERICA;
  };

  useEffect(() => {
    setRegion(getRegion(countryCode as AmazonCountry));
  }, [store]);

  const generateAuthorizationURL = (storeRegion: string) => {
    let host = "";

    switch (storeRegion) {
      case EUROPE:
        host = "https://eu.account.amazon.com/ap/oa";
        break;
      case ASIA_PACIFIC:
        host = "https://apac.account.amazon.com/ap/oa";
        break;
      default:
        host = "https://www.amazon.com/ap/oa";
    }

    const url = new URL(host);
    const urlParams = new URLSearchParams({
      // eslint-disable-next-line no-undef
      client_id: AMAZON_ADVERTISING_CLIENT_ID,
      scope: "advertising::campaign_management",
      response_type: "code",
      // eslint-disable-next-line no-undef
      redirect_uri: AMAZON_REDIRECT_URI,
      state: storeRegion,
    });
    url.search = urlParams.toString();
    return url.toString();
  };

  const onAuthSuccess = async () => {
    await dispatch(loadInfo()); //refresh user info
    if (marketplace === "amazon_vendor") {
      onSuccess(true); //true = goToNextStage
    } else {
      onSuccess();
    }
  };

  const onAuthError = () => {
    errMsg.current =
      "Error encountered when authorising access to Advertising data.";
  };
  setIsSubmitting(false);

  // if this widget is already rendered, and the user switches to a non-Amazon
  // store (e.g., one without a countryCode), amws.marketplace() may throw an
  // error. We need to make the component return null before this happens.
  if (marketplace !== "amazon" && marketplace !== "amazon_vendor") {
    return null;
  }

  return (
    <Grid item container alignItems="center" justifyContent="center">
      {user.isDemoMode ? (
        <DemoTooltip
          {...{
            arrow: true,
            placement: "bottom",
            open: true,
            title: t<string>("generic.notAvailableDemoMode"),
          }}
        >
          <span>
            <RaisedButton id="amazon-advertising-link">
              {t("advertisingConnectLabel.connectButton")}
            </RaisedButton>
          </span>
        </DemoTooltip>
      ) : (
        <Box pt={2}>
          <AmazonAuthoriseAdvertising
            id="amazon-advertising-link"
            url={generateAuthorizationURL(region || NORTH_AMERICA)}
            userId={user._id}
            shopName={store.storeName}
            mid={mid}
            countryCode={countryCode}
            sellerId={sellerId}
            localisation={t}
            marketplace={store.marketplaceType}
            onSuccess={onAuthSuccess}
            onError={onAuthError}
            variant="outlined"
            setIsSubmitting={setIsSubmitting}
          >
            <Grid
              container
              justifyContent="center"
              spacing={2}
              alignItems="center"
            >
              <Grid container item xs={5} justifyContent="flex-end">
                <MarketplaceLogoIcon
                  src={ADVERTISING_BUTTONS[marketplace].imgSrc}
                />
              </Grid>
              <Grid container item xs={7} justifyContent="flex-start">
                <Medium align="left">
                  {t(ADVERTISING_BUTTONS[marketplace].label)}
                </Medium>
              </Grid>
            </Grid>
          </AmazonAuthoriseAdvertising>
        </Box>
      )}
      {errMsg && errMsg.current && (
        <Grid container item xs={12}>
          <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
        </Grid>
      )}
    </Grid>
  );
};

export default MarketingAuthoriseUser;
