import { Box, Grid, useTheme } from "@material-ui/core";
import React, { ReactChild, memo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import {
  TrafficMixTrend,
  useTrafficMixTrendQuery,
} from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";

interface TrafficMixParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  actions?: ReactChild;
  report?: boolean;
  timezone: string;
}

const processTrafficMixData = (data: TrafficMixTrend[]) => {
  return data.map(({ startTime, browserSessions, mobileSessions }) => {
    const totalSessions = mobileSessions + browserSessions;
    // Subtract epsilon to make sure percentages add up to <100
    const browserPercent =
      (browserSessions / totalSessions - Number.EPSILON) * 100;
    const mobilePercent =
      (mobileSessions / totalSessions - Number.EPSILON) * 100;
    return {
      startTime,
      browserSessions: Number.isFinite(browserPercent) ? browserPercent : 0,
      mobileSessions: Number.isFinite(mobilePercent) ? mobilePercent : 0,
      totalSessions,
    };
  });
};

const TrafficMixTrend = memo<TrafficMixParams>(function TrafficMixTrend({
  mid,
  marketplaceType,
  marketplaceSubtype,
  currentPeriod,
  currentRange,
  currentCurrency,
  actions,
  report,
  timezone,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { trafficMixData, loading } = useTrafficMixTrendQuery(
    {
      mid,
      marketplaceFilters: {
        marketplaceType,
        marketplaceSubtype,
      },
      currentRange,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        trafficMixData: data?.data ? processTrafficMixData(data.data) : [],
        loading: isFetching,
      }),
    }
  );

  return (
    <Panel
      id="traffic-conversion-trend"
      title={t("chartTitle.trafficMixTrend")}
      tooltip={t("trafficMixTrend.mainTooltip")}
      actions={actions}
      content={
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              <StackedBarAndLineChart
                title={t(CHART_TITLES[currentPeriod])}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                interval={currentRange.interval}
                chartData={trafficMixData}
                isLoading={loading}
                topBar={{
                  colour: theme.palette.secondary.main,
                  key: "mobileSessions",
                  toFixed: 1,
                }}
                bottomBar={{
                  colour: theme.palette.primary.main,
                  key: "browserSessions",
                  toFixed: 1,
                }}
                xKey="startTime"
                report={report}
                view="units"
                timezone={timezone}
              />
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
});

export default TrafficMixTrend;
