import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

const generateAuthUrl: (state: string) => string = (state) => {
  const host = process.env.YAHOO_JAPAN_AUTH_ENDPOINT;
  const client_id = process.env.YAHOO_JAPAN_CLIENT_ID;
  const redirect_uri =
    process.env.YAHOO_JAPAN_REDIRECT_URL ||
    "https://mm.merchantspring.io/callback/yahoo";
  if (!host || !client_id) {
    throw new Error("Error authorising Yahoo Japan connection");
  }

  const url = new URL(host);
  const urlParams = new URLSearchParams({
    response_type: "code",
    client_id,
    redirect_uri,
    state,
    scope: "openid",
  });
  url.search = urlParams.toString();
  return url.toString();
};

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const state = process.env.CRITEO_STATE_KEY || "M3RCH4NTSPR1NG";
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "yahoo"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.error) {
          onError(params.error);
          return;
        }

        if (params.state) {
          if (params.state !== state) {
            onError("Invalid state response from Yahoo Japan");
            return;
          }
          if (params.code) {
            onCode(params.code);
            return;
          }
        }

        onError("Invalid response from Yahoo Japan");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateAuthUrl(state),
        "authPopup-mm-yahoojapan",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
