import { PaginationArgs } from "~/typedef/pagination";
import { Range } from "~/typedef/store";
import { api } from "../apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface SalesByCustomerTypeArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  includeTax: boolean;
}

interface SalesByCustomerTypeData {
  startTime: number;
  newCustomers: number;
  existingCustomers: number;
  totalCustomers: number;
  newCustomersRevenue: number;
  existingCustomersRevenue: number;
  totalCustomersRevenue: number;
  newCustomersOrders: number;
  existingCustomersOrders: number;
  totalCustomersOrders: number;
}

interface SalesByCustomerTypeResponse {
  chartData: SalesByCustomerTypeData[];
}

interface RepeatPurchaseSummaryArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  includeTax: boolean;
}

interface RepeatPurchaseSummaryData {
  totalCustomers: number;
  newCustomers: number;
  repeatCustomers: number;
  averageSpend: number | null;
  averageRepeatSpend: number | null;
}

interface RepeatPurchaseSummaryResponse {
  current: RepeatPurchaseSummaryData;
  prior: RepeatPurchaseSummaryData;
}

interface RepeatPurchaseByProductArgs extends PaginationArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  includeTax: boolean;
}

export interface RepeatPurchaseByProductRow {
  productSku: string;
  title: string;
  imageUrl?: string;
  linkUrl: string;
  totalSales: {
    current: number;
    prior: number;
  };
  repeatCustomerSales: {
    current: number;
    prior: number;
  };
  repeatSalesPercentage: {
    current: number;
    prior: number;
  };
  totalCustomers: {
    current: number;
    prior: number;
  };
  newCustomers: {
    current: number;
    prior: number;
  };
  repeatCustomers: {
    current: number;
    prior: number;
  };
  repeatCustomersPercentage: {
    current: number;
    prior: number;
  };
  salesPerCustomer: {
    current: number;
    prior: number;
  };
  salesPerRepeatCustomer: {
    current: number;
    prior: number;
  };
}

interface RepeatPurchaseByProductResponse {
  rows: RepeatPurchaseByProductRow[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesByCustomerType: build.query<
      SalesByCustomerTypeResponse,
      SalesByCustomerTypeArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/repeatPurchase/customerTypeTrend`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Get/SalesByCustomerType"),
    }),

    repeatPurchaseSummary: build.query<
      RepeatPurchaseSummaryResponse,
      RepeatPurchaseSummaryArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/repeatPurchase/summary`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Get/RepeatPurchaseSummary"),
    }),

    repeatPurchaseByProduct: build.query<
      RepeatPurchaseByProductResponse,
      RepeatPurchaseByProductArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/repeatPurchase/product`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Get/RepeatPurchaseByProduct"),
    }),
  }),
});

export const {
  useSalesByCustomerTypeQuery,
  useRepeatPurchaseSummaryQuery,
  useRepeatPurchaseByProductQuery,
} = extendedApiSlice;
