import { useDispatch, useSelector } from "react-redux";

import CombinedLink from "../../../components/links/link.tsx";
import FloatingAlertCard from "../../../components/alert/floatingAlertCard";
import React from "react";
import { Typography } from "@material-ui/core";
import get from "lodash/get";
import { hideTrialWarning } from "../../../store/subscriptions.redux";
import { isFrontPage } from "~/utils/navigationUtils";
import { isLegacySubscription } from "@merchantspring/common";
import moment from "moment-timezone";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  text-align: left;
`;

const LinkWrapper = styled.div`
  text-align: right;
`;
const TrialExpiryAlert = ({ currentSubscription }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const shouldHideAlert = useSelector((state) =>
    get(state, "subscriptions.hideTrialWarning", false)
  );

  const onClose = () => {
    dispatch(hideTrialWarning());
    setOpen(false);
  };
  const location = useLocation();

  const trialExpiry = moment(
    // default to some date far in the future if we don't have a current
    // subscription
    get(currentSubscription, "nextBillingDate", "9999-01-01"),
    "YYYY-MM-DD"
  );

  React.useEffect(() => {
    setOpen(
      !isFrontPage(location) &&
        !shouldHideAlert &&
        isLegacySubscription(currentSubscription) &&
        /* eslint-disable-next-line no-magic-numbers */
        moment().add(7, "days").isAfter(trialExpiry)
    );
  }, [location, shouldHideAlert, currentSubscription, trialExpiry]);

  return (
    <FloatingAlertCard
      data-testid="trial-expiry-alert"
      isOpen={open}
      onClose={onClose}
      type="warning"
    >
      <ContentWrapper>
        {get(currentSubscription, "status") !== "Expired" && (
          <Typography>
            {t("subscription.create.freeTrialExpiryWarning", {
              date: trialExpiry.format("D MMM YYYY"),
            })}
          </Typography>
        )}
        {get(currentSubscription, "status") === "Expired" && (
          <Typography>
            {t("subscription.create.freeTrialExpiredTitle")}
          </Typography>
        )}
        <Typography variant="subtitle1">
          {t("subscription.create.freeTrialExpiredMessage")}
        </Typography>
      </ContentWrapper>
      <LinkWrapper>
        <CombinedLink
          to="/subscription/plans"
          underline="always"
          variant="subtitle1"
        >
          {t("subscription.create.buyNowLink")}
        </CombinedLink>
      </LinkWrapper>
    </FloatingAlertCard>
  );
};

export default TrialExpiryAlert;
