import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import useTikTokAuthcodeAuth from "~/hooks/useTikTokAuthcodeAuth";
import { useTranslation } from "react-i18next";
import { useValidateTikTokAuthMutation } from "~/store/connections/connectTikTok.redux";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

interface TikTokAdvertisingConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const TikTokAdvertisingConnectPage: React.FC<
  TikTokAdvertisingConnectPageProps
> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  const [validateTiktokAuth] = useValidateTikTokAuthMutation({
    fixedCacheKey: "tiktokAuth",
  });

  const onAuthCode = useCallback(async (code: string) => {
    validateTiktokAuth({
      authCode: code,
    })
      .unwrap()
      .then(({ code, errMsg }) => {
        if (errMsg) {
          onAuthError(errMsg);
        } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
          onAuthError("connectWizard.connectionError");
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        const errorMessage = error.message || "connectWizard.connectionError";
        onAuthError(errorMessage);
      });
  }, []);

  const triggerSignin = useTikTokAuthcodeAuth(onAuthCode, onAuthError);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <GridWrapper id="tiktok-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Medium color="textSecondary">
            {t("connectWizard.tikTokAdvertisingLinkTitle")}
          </Medium>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <RaisedButton color="primary" onClick={handleSignin}>
              <StyledWrapper>
                <StyledChildren>
                  {t("connectWizard.connectButton")}
                </StyledChildren>
              </StyledWrapper>
            </RaisedButton>
          </Grid>
        </Grid>
        <>
          {error && (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <ErrorMessage color="error">{error}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default TikTokAdvertisingConnectPage;
