import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";

// TODO refactor the setState loading spinner in overview page to use
// redux states instead (?)
// export const LOAD_LISTING_QUALITY_REQUEST = 'LOAD_LISTING_QUALITY_REQUEST'
export const LOAD_LISTING_QUALITY_SUCCESS = "LOAD_LISTING_QUALITY_SUCCESS";
export const LOAD_LISTING_QUALITY_FAILURE = "LOAD_LISTING_QUALITY_FAILURE";

// reducer
export const listingQuality = (state = {}, action) => {
  switch (action.type) {
    //        case LOAD_LISTING_QUALITY_REQUEST:
    //            return []
    case LOAD_LISTING_QUALITY_SUCCESS:
      return action.payload;
    case LOAD_LISTING_QUALITY_FAILURE:
      return {};
    default:
      return state;
  }
};

// actions

// thunks
function receiveListingQualitySuccess(data) {
  return {
    type: LOAD_LISTING_QUALITY_SUCCESS,
    payload: data,
  };
}

function receiveListingQualityFailure() {
  return {
    type: LOAD_LISTING_QUALITY_FAILURE,
    payload: {},
  };
}

// the real work
export const loadListingQuality =
  (marketplaceId, countryCode) => async (dispatch) => {
    let response = null;
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/api/listingquality`,
        data: { mid: marketplaceId, countryCode },
      };
      response = await axios(options);
    } catch (e) {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(
        dispatch,
        msg || statusText,
        get(err, "status"),
        "listingquality"
      );
    }
    if (isHttpResponseValid(response.status) && response.data) {
      return Promise.resolve(
        dispatch(receiveListingQualitySuccess(response.data))
      );
    } else {
      setError(
        dispatch,
        response.data.errMsg,
        response.status,
        "listingquality"
      );
      dispatch(receiveListingQualityFailure());
      throw "Failed to retrieve listing quality data";
    }
  };
