import { Range } from "~/typedef/store";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface FetchCohortSummaryArgs {
  mid: string;
  currentRange: Range;
  marketplaceType: string;
  marketplaceSubtype: string;
}

interface DisplayCohortSummary {
  distinctCustomersCount: number;
  averageLtv: number | null;
  repeatCustomersCount: number;
  averageRepeatLtv: number | null;
  repeatCustomersPercentage: number | null;
  ltvCacRatio: number | null;
  totalRevenue: number;
}

interface FetchCohortSummaryResponse {
  current: DisplayCohortSummary;
  prior: DisplayCohortSummary;
}

interface FetchCohortTrendArgs {
  mid: string;
  currentRange: Range;
  sortKey: string;
  sortOrder: string;
  marketplaceType: string;
  marketplaceSubtype: string;
}

export interface FetchCohortTrendData {
  startTime: number;
  distinctNonRepeatCustomersCount: number;
  distinctRepeatCustomersCount: number;
  totalRevenueNotRepeatCustomers: number;
  totalRevenueRepeatCustomers: number;
  averageLTV: number;
  averageRepeatLTV: number;
  adSpend: number;
  distinctCustomersCount: number;
  totalRevenue: number;
  repeatPurchases: number;
  purchaseFrequency: number;
  ltvCacRatio: number;
  cac: number;
}

export interface FetchCohortTrendDataResponse {
  data: FetchCohortTrendData[];
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    cohortSummary: build.query<
      FetchCohortSummaryResponse,
      FetchCohortSummaryArgs
    >({
      query: (params) => {
        const { currentRange, mid, marketplaceSubtype, marketplaceType } =
          params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/clv/cohortSummary`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
            marketplaceSubtype,
            marketplaceType,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
    cohortTrend: build.query<
      FetchCohortTrendDataResponse,
      FetchCohortTrendArgs
    >({
      query: (params) => {
        const {
          currentRange,
          mid,
          sortKey,
          sortOrder,
          marketplaceSubtype,
          marketplaceType,
        } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/clv/cohortTrend`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
            sortKey,
            sortOrder,
            marketplaceSubtype,
            marketplaceType,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const { useCohortSummaryQuery, useCohortTrendQuery } = extendedApiSlice;
