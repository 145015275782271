import {
  CircularProgress,
  FormControl,
  Link,
  Typography,
} from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import TextField from "@material-ui/core/TextField";
import TrademeAuthorise from "~/modules/authorise/trademeAuthorise";
import { connectionStatus } from "~/utils/marketplaceUtils";
import { getCountryToISO3 } from "~/utils/countryUtils";
import { loadInfo } from "~/store/user.redux";
import { marketplaceOnMultipleRegions } from "../connectWizard/marketplaces";
import styled from "styled-components";
import { trimSpace } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const { CONNECTION_FAILED, CONNECTION_SUCCEED } = connectionStatus;

const SHOP_NAME_LIMIT = 30;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
  width: 80%;
  max-width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const LinkWrapper = styled(Typography)`
  margin-bottom: 20px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const isMarketplaceOnMultipleRegions = (marketplace) =>
  marketplaceOnMultipleRegions.includes(marketplace);

const appendCountryCode = (marketplace, countryToCheck) => {
  const countryCode = getCountryToISO3(countryToCheck);
  return `${marketplace}${countryCode}`;
};

const GenericConnectPageAuthForm = memo(function GenericConnectPageAuthForm({
  marketplaceConfig,
  input1,
  input2,
  input3,
  input4,
  shopName,
  setTransaction,
  setInput1,
  setInput2,
  setInput3,
  setInput4,
  setShopName,
  onSuccess,
  setIsSubmitting,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConnectBtn = async (e) => {
    setIsSubmitting && setIsSubmitting(true);
    e.preventDefault();

    if (marketplaceConfig.inputs[0] && marketplaceConfig.inputs[0].regex) {
      if (!marketplaceConfig.inputs[0].regex.test(trimSpace(input1))) {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.inputs[0].regexError,
        });
        return;
      }
    }

    if (marketplaceConfig.inputs[1] && marketplaceConfig.inputs[1].regex) {
      if (!marketplaceConfig.inputs[1].regex.test(trimSpace(input2))) {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.inputs[1].regexError,
        });
        return;
      }
    }

    if (marketplaceConfig.inputs[2] && marketplaceConfig.inputs[2].regex) {
      if (!marketplaceConfig.inputs[2].regex.test(trimSpace(input3))) {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.inputs[2].regexError,
        });
        return;
      }
    }

    if (marketplaceConfig.inputs[3] && marketplaceConfig.inputs[3].regex) {
      if (!marketplaceConfig.inputs[3].regex.test(trimSpace(input4))) {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.inputs[3].regexError,
        });
        return;
      }
    }

    try {
      const { marketplace, marketplaceSubtype, country } = marketplaceConfig;
      let subMarketplace = marketplaceSubtype;

      if (isMarketplaceOnMultipleRegions(marketplaceSubtype)) {
        subMarketplace = appendCountryCode(marketplaceSubtype, country);
      }

      // note use of eval here - all dynamic params specified in
      // submitActionParams must be present within this scope
      await dispatch(
        marketplaceConfig.submitAction(
          ...marketplaceConfig.submitActionParams.map((p) => trimSpace(eval(p)))
        )
      );

      setTransaction({
        status: CONNECTION_SUCCEED,
        errMsg: "",
      });

      await dispatch(loadInfo());

      if (!marketplaceConfig.facade && onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (err === "tokenInValid") {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.messages.connectionError,
        });
      } else if (err === "forbidden") {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: t("generic.notAvailableDemoMode"),
        });
      } else if (err?.message) {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: t(err.message),
        });
      } else {
        setTransaction({
          status: CONNECTION_FAILED,
          errMsg: marketplaceConfig.messages.connectionError,
        });
      }
    }
  };

  const handleAuthSuccess = async () => {
    setTransaction({
      status: CONNECTION_SUCCEED,
      errMsg: "",
    });

    await dispatch(loadInfo());
    onSuccess();
  };

  return !marketplaceConfig ? (
    <CircularProgress />
  ) : (
    <Form>
      {marketplaceConfig.inputs[0] && (
        <FormControl>
          <TextField
            fullWidth
            label={marketplaceConfig.inputs[0].label}
            type={marketplaceConfig.inputs[0].type}
            margin="normal"
            required
            value={input1}
            onChange={(e) => {
              setInput1(e.target.value);
            }}
          />
        </FormControl>
      )}
      {marketplaceConfig.inputs[1] && (
        <FormControl>
          <TextField
            fullWidth
            label={marketplaceConfig.inputs[1].label}
            type={marketplaceConfig.inputs[1].type}
            margin="normal"
            required
            value={input2}
            onChange={(e) => {
              setInput2(e.target.value);
            }}
          />
        </FormControl>
      )}
      {marketplaceConfig.inputs[2] && (
        <FormControl>
          <TextField
            fullWidth
            label={marketplaceConfig.inputs[2].label}
            type={marketplaceConfig.inputs[2].type}
            margin="normal"
            required
            value={input3}
            onChange={(e) => {
              setInput3(e.target.value);
            }}
          />
        </FormControl>
      )}
      {marketplaceConfig.inputs[3] && (
        <FormControl>
          <TextField
            fullWidth
            label={marketplaceConfig.inputs[3].label}
            type={marketplaceConfig.inputs[3].type}
            margin="normal"
            required
            value={input4}
            onChange={(e) => {
              setInput4(e.target.value);
            }}
          />
        </FormControl>
      )}
      <LinkWrapper align="right">
        <Link href={marketplaceConfig.messages.helpLinkUrl} target="_blank">
          {marketplaceConfig.messages.helpLink}
        </Link>
      </LinkWrapper>
      {marketplaceConfig.shopName && (
        <FormControl>
          <Typography variant="body2">
            {marketplaceConfig.messages.shopNameTitle}
          </Typography>
          <AccountNameWrapper
            fullWidth
            label={marketplaceConfig.messages.shopNameLabel}
            margin="normal"
            required
            value={shopName}
            onChange={(e) => {
              setShopName(e.target.value);
            }}
            inputProps={{
              maxLength: SHOP_NAME_LIMIT,
            }}
          />
        </FormControl>
      )}
      <ButtonWrapper>
        {marketplaceConfig.marketplace === "trademe" ? (
          <TrademeAuthorise onSuccess={handleAuthSuccess}>
            {t("connectWizard.connectButton")}
          </TrademeAuthorise>
        ) : (
          <RaisedButton
            disabled={
              !input1 ||
              (marketplaceConfig.inputs[1] && !input2) ||
              (marketplaceConfig.shopName && !shopName)
            }
            onClick={(event) => handleConnectBtn(event)}
            variant="contained"
            color="primary"
          >
            {t("connectWizard.connectButton")}
          </RaisedButton>
        )}
      </ButtonWrapper>
    </Form>
  );
});

GenericConnectPageAuthForm.propTypes = {
  marketplaceConfig: PropTypes.object.isRequired,
  input1: PropTypes.string.isRequired,
  input2: PropTypes.string.isRequired,
  input3: PropTypes.string.isRequired,
  input4: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  setTransaction: PropTypes.func.isRequired,
  setInput1: PropTypes.func.isRequired,
  setInput2: PropTypes.func.isRequired,
  setInput3: PropTypes.func.isRequired,
  setInput4: PropTypes.func.isRequired,
  setShopName: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  setIsSubmitting: PropTypes.func,
};

export default GenericConnectPageAuthForm;
