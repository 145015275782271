import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { clearStoreAction } from "@store/utils/globalActions";
import { extendedApiSlice as salesPerformanceApiSlice } from "@store/overview/salesPerformance.redux";

export type DashboardState = {
  isFetching: boolean;
};
const initialState: DashboardState = { isFetching: false };

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearStoreAction, () => {
        // Revert to initial state
        return initialState;
      })
      .addMatcher(
        isAnyOf(
          salesPerformanceApiSlice.endpoints.salesPerformance.matchPending
        ),
        (state) => {
          state.isFetching = true;
        }
      )
      .addMatcher(
        isAnyOf(
          salesPerformanceApiSlice.endpoints.salesPerformance.matchFulfilled,
          salesPerformanceApiSlice.endpoints.salesPerformance.matchRejected
        ),
        (state) => {
          state.isFetching = false;
        }
      );
  },
});

export default dashboardSlice.reducer;
