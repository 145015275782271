import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import { GridJustification, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import StatusText from "../../typography/status";
import { TableBaseProps } from "~/components/adTable/table";
import { getGrowthStatus } from "./percentageAndGrowthCell";
import styled from "styled-components";

export const Cell = styled.div<{ cellJustify?: GridJustification }>`
  display: flex;
  align-items: flex-end;
  justify-content: ${({ cellJustify }) =>
    cellJustify ? cellJustify : "flex-end"};
  width: 100%;
`;

export const GrowthBlock = withTheme(styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 7px;
`);

interface ValueAndGrowthCellProps {
  cell: {
    value: {
      value: number | string;
      growth: number | string;
      conditionalFormatting?: boolean;
      reverseFormatting?: boolean;
      customSuffix?: string;
      hideValue?: boolean;
    };
    column?: TableBaseProps["columns"][number];
  };
}

export const ValueAndGrowthCell = memo<ValueAndGrowthCellProps>(
  function ValueAndGrowthCell({ cell }) {
    const value: number | string = cell.value.value;
    const rawGrowth = cell.value.growth;
    const growth = Number(rawGrowth);
    const hideValue = cell.value.hideValue;
    const conditionalFormatting: boolean | undefined =
      cell.value.conditionalFormatting;
    const reverseFormatting: boolean | undefined = cell.value.reverseFormatting;
    const customSuffix: string | undefined = cell.value.customSuffix;
    const hasCustomSuffix: boolean = typeof customSuffix === "string";
    const status = getGrowthStatus(growth, reverseFormatting);

    const cellJustify = cell.column?.cellJustify;

    return (
      <Cell cellJustify={cellJustify}>
        {!hideValue && <Typography variant="body2">{value}</Typography>}
        {rawGrowth && (
          <GrowthBlock>
            <StatusText
              variant="body2"
              status={conditionalFormatting === false ? undefined : status}
              align="right"
              paragraph={false}
            >
              {growth > 0 && <UpArrow fontSize="inherit" status={status} />}
              {growth < 0 && <DownArrow fontSize="inherit" status={status} />}
              {isNaN(growth) ? rawGrowth : Math.abs(growth)}
              {!isNaN(growth) && (hasCustomSuffix ? customSuffix : "%")}
            </StatusText>
          </GrowthBlock>
        )}
      </Cell>
    );
  }
);
