import {
  CountryDetail,
  getCountriesByCurrencyCode,
} from "~/utils/getCountryByCurrencyCode";
import React, { ChangeEvent, useMemo } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface CountrySelectFromCurrencyCode {
  label: string;
  currencyCode: string;
  setError?: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: (evt: ChangeEvent<{}>, value: CountryDetail | null) => void;
}

const CountrySelectFromCurrencyCode: React.FC<
  CountrySelectFromCurrencyCode
> = ({ currencyCode, setError, label, onChange }) => {
  const { t } = useTranslation();

  const countries = useMemo(
    () => getCountriesByCurrencyCode(currencyCode),
    [currencyCode]
  );

  if (!countries.length) {
    setError && setError(t("connectWizard.invalidAccountCurrency"));
    return null;
  }

  return (
    <Autocomplete
      fullWidth
      onChange={onChange}
      options={countries}
      getOptionSelected={(opt: CountryDetail, val: CountryDetail) =>
        opt.iso3 === val.iso3
      }
      getOptionLabel={(val) => val.country}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" fullWidth />
      )}
    />
  );
};

export default CountrySelectFromCurrencyCode;
