import {
  Backdrop,
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import { CurrencyRate, Store } from "~/typedef/store";
import {
  MarketplaceOverviewExpandedEntry,
  useMarketplaceOverviewExpandedQuery,
} from "~/store/overview/marketplaceOverview.redux";
import React, { memo, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";
import { formatNumber, getPercentageDifference } from "~/utils/salesUtils";
import { get, isEmpty } from "lodash";

import Bold from "~/components/typography/bold";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MarketplaceAndCountryCell } from "~/components/table/cells/marketplaceAndCountryCell";
import { OutOfStockCell } from "~/components/table/cells/outOfStockCell";
import Panel from "~/components/panel/panel";
import StatusIndicator from "~/components/statusIndicator/statusIndicator";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import demoData from "./demoData";
import { getShopName } from "~/utils/marketplaceUtils";
import { getStatusTitle } from "~/utils/accountHealthUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import useQueryParams from "~/hooks/useQueryParams";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DEFAULT_PERIOD = DATETIME_PERIODS.LAST30;
const DEFAULT_RANGE = getDatesFromPeriod(
  DEFAULT_PERIOD,
  COMPARISON_PERIOD.THISYEAR,
  moment.tz.guess()
);
const PAGE_SIZE = 5;
const DEFAULT_SORT = "sales";
const DEFAULT_SORT_ORDER = "desc";

const RelativeBox = styled(Box)`
  position: relative;
`;

const LimitedBackdrop = styled(Backdrop)`
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ActivateText = styled(Typography)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const ActionButton = styled(Button)`
  margin: 16px;
  padding: 16px;
  width: 280px;
  background: linear-gradient(
    0deg,
    rgba(58, 158, 70, 1) 0%,
    rgba(80, 175, 95, 1) 100%
  );
  border-radius: 0;
  text-transform: uppercase;
`;

const FixedHeightTable = styled(Table)`
  height: 274px;
`;

const formatMarketplaceData = (
  filteredStores: Store[],
  marketplaceOverviewRows: MarketplaceOverviewExpandedEntry[],
  currencyRates: CurrencyRate[],
  currentCurrency: string,
  t: TFunction<"translation">
) => {
  if (!filteredStores || !marketplaceOverviewRows) {
    return [];
  }

  const marketplaceOverviewData = marketplaceOverviewRows.map((marketplace) => {
    // We still use conversion - API endpoint converts it all to a target currency (USD for now)
    const convertedSalesValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.value
    );
    const convertedPriorValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.priorPeriodValue
    );
    const convertedOrderValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.avOrderValue.value
    );
    const convertedOrderPriorValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.avOrderValue.priorPeriodValue
    );
    return {
      ...marketplace,
      convertedSalesValue,
      convertedPriorValue,
      convertedOrderValue,
      convertedOrderPriorValue,
    };
  });

  return marketplaceOverviewData.map((marketplace) => {
    const shopName = getShopName(
      filteredStores,
      marketplace.marketplace.market,
      marketplace.store
    );
    const orders = {
      value: marketplace.orders.value,
      growth: getPercentageDifference(
        marketplace.orders.value,
        marketplace.orders.priorPeriodValue
      ),
    };
    const sales = {
      sortValue: marketplace.convertedSalesValue,
      value: formatCurrencyRounded(
        marketplace.convertedSalesValue,
        currencyRates,
        currentCurrency,
        currentCurrency
      ),
      growth: getPercentageDifference(
        marketplace.convertedSalesValue,
        marketplace.convertedPriorValue
      ),
    };
    const avOrderValue = {
      value: formatCurrency(
        marketplace.convertedOrderValue,
        currencyRates,
        currentCurrency,
        currentCurrency
      ),
      growth: getPercentageDifference(
        marketplace.convertedOrderValue,
        marketplace.convertedOrderPriorValue
      ),
    };
    const avOrderSize = {
      value: formatNumber(marketplace.avOrderSize.value),
      growth: getPercentageDifference(
        marketplace.avOrderSize.value,
        marketplace.avOrderSize.priorPeriodValue
      ),
    };

    return {
      ...marketplace,
      shopName,
      orders,
      sales,
      avOrderValue,
      avOrderSize,
      healthStatus: {
        value: getStatusTitle(marketplace.healthStatus, t),
        status: marketplace.healthStatus,
      },
      outOfStockPercentage: {
        value: marketplace.outOfStockPercentage,
      },
    };
  });
};

const columns = (t: TFunction<"translation">) => [
  {
    id: "marketplace",
    Header: t("partnerWidget.marketplaceOverview.marketplaceColumn"),
    accessor: "marketplace",
    Cell: MarketplaceAndCountryCell,
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "shopName",
    Header: t("partnerWidget.marketplaceOverview.storeColumn"),
    accessor: "shopName",
    Cell: TextCell,
    isVisible: true,
    disableSortBy: true,
    colSpan: 2,
  },
  {
    id: "sales",
    Header: t("partnerWidget.marketplaceOverview.salesColumn"),
    accessor: "sales",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "orders",
    Header: t("partnerWidget.marketplaceOverview.ordersColumn"),
    accessor: "orders",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "healthStatus",
    Header: t("partnerWidget.accountHealth.healthColumn"),
    accessor: "healthStatus",
    Cell: ({ cell }: { cell: any }) => {
      const statusIndicatorProps = {
        size: "medium",
        statusText: cell.value.value,
        status: cell.value.status,
      };
      return <StatusIndicator {...statusIndicatorProps} />;
    },
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "outOfStockPercentage",
    Header: t("partnerWidget.inventoryStatus.outOfStockPercentageColumn"),
    accessor: (row: any) => {
      return {
        outOfStockPercentage: row.outOfStockPercentage.value,
        align: "center",
      };
    },
    Cell: OutOfStockCell,
    align: "center",
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "avOrderSize",
    Header: t("partnerWidget.marketplaceOverview.avgOrderSizeColumn"),
    accessor: "avOrderSize",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
  },
  {
    id: "avOrderValue",
    Header: t("partnerWidget.marketplaceOverview.avgOrderValueColumn"),
    accessor: "avOrderValue",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
  },
];

interface MarketplaceOverviewProps {
  currentCurrency: string;
  showingDemoData: boolean;
  partnerName: string;
}

// Because this widget is only used as an embedded widget, it doesn't get
// includeTax from global state the way normal widgets do.
const INCLUDE_TAX = true;

const MarketplaceOverview = memo<MarketplaceOverviewProps>(
  function MarketplaceOverview({
    currentCurrency,
    showingDemoData,
    partnerName,
  }) {
    const { t } = useTranslation();
    const queryParams = useQueryParams();
    const userInfo = useTypedSelector((state) => state.user);
    const filteredStores =
      useTypedSelector((state) => state?.mystore?.filteredStores?.stores) || [];
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const source = queryParams.get("source");
    const token = queryParams.get("token");
    const userCheck = userInfo && userInfo._id;

    const {
      marketplaceOverviewExpandedRows,
      marketplaceOverviewExpandedFetching,
    } = useMarketplaceOverviewExpandedQuery(
      {
        currentPeriod: DEFAULT_PERIOD,
        currentRange: DEFAULT_RANGE,
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        sortKey: DEFAULT_SORT,
        sortOrder: DEFAULT_SORT_ORDER,
        filter: {
          marketplaces: [],
          countries: [],
          tags: [],
        },
        includeTax: INCLUDE_TAX,
      },
      {
        skip: !userCheck || showingDemoData,
        selectFromResult: ({ data, isFetching }) => ({
          marketplaceOverviewExpandedRows: data?.rows || [],
          marketplaceOverviewExpandedFetching: isFetching,
        }),
      }
    );

    const data = useMemo(
      () =>
        formatMarketplaceData(
          showingDemoData ? [] : filteredStores,
          showingDemoData ? demoData.rows : marketplaceOverviewExpandedRows,
          currencyRates,
          currentCurrency,
          t
        ),
      [
        showingDemoData,
        marketplaceOverviewExpandedRows,
        currencyRates,
        currentCurrency,
        t,
      ]
    );

    const columnsMemo = useMemo(() => columns(t), [t]);

    const tableProps = {
      loading:
        !showingDemoData &&
        marketplaceOverviewExpandedFetching &&
        isEmpty(data),
      columns: columnsMemo,
      data,
    };

    return (
      <RelativeBox>
        {showingDemoData && (
          <LimitedBackdrop open>
            <Grid container>
              <Grid item xs={12}>
                <ActivateText align="center">
                  {t("partnerWidget.activateText", { partnerName })}
                </ActivateText>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Link
                  href={`/api/partner/omnivore/auth?source=${source}&token=${token}`}
                  target="_blank"
                  component={ActionButton}
                >
                  {t("partnerWidget.activateButton")}
                </Link>
              </Grid>
            </Grid>
          </LimitedBackdrop>
        )}
        <Panel
          id="widget-partner-marketplace-overview"
          title={t("partnerWidget.marketplaceOverview.mainTitle")}
          elevation={0}
          subtitle={t("partnerWidget.marketplaceOverview.subTitle", {
            currency: currentCurrency,
          })}
          tooltip={t("partnerWidget.marketplaceOverview.mainTooltip")}
          content={
            data ? (
              <FixedHeightTable {...tableProps} />
            ) : (
              <Box p={2}>
                <LoadingIndicator />
              </Box>
            )
          }
          footer={
            <Link href="/overview" target="_blank" variant="body2">
              <Bold>{t("generic.viewAllLink")}</Bold>
            </Link>
          }
        />
      </RelativeBox>
    );
  }
);

export default MarketplaceOverview;
