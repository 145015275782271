import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Tab, Tabs, alpha } from "@material-ui/core";

import BillingSetting from "./setting/billingSetting";
import Grid from "@material-ui/core/Grid";
import MarketplaceSetting from "./setting/marketplaceSetting";
import MediaUpload from "./setting/mediaUpload";
import OrganisationSeats from "~/modules/account/organisationSeats";
import PageBlock from "~/components/containers/pageBlock";
import PersonalSetting from "./setting/personalSetting";
import { SettingsToolbar } from "~/components/toolbars/settingsToolbar/settingsToolbar";
import styled from "styled-components";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const INITIAL_TAB = 0;

const SettingsWrapper = styled.div`
  padding-bottom: 2rem;
`;

interface TransparentProps {
  transparent: boolean;
}

const TabPanelContent = styled.div<TransparentProps>`
  box-shadow: ${({ theme, transparent }) =>
    transparent ? theme.shadows[0] : theme.shadows[1]};
  background-color: ${({ theme, transparent }) =>
    transparent ? "transparent" : theme.palette.background.paper};
  border-radius: 0 ${({ theme }) => theme.shape.borderRadius}px
    ${({ theme }) => theme.shape.borderRadius}px
    ${({ theme }) => theme.shape.borderRadius}px;
`;

const SettingsTabs = styled(Tabs)`
  background-color: transparent;
  width: fit-content;
`;

const SettingsTab = styled(Tab)`
  background-color: ${({ theme }) =>
    alpha(theme.palette.background.default, 0.85)};
  color: ${({ theme }) => theme.palette.common.black};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px
    ${({ theme }) => theme.shape.borderRadius}px 0px 0px;
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

const SettingsPage = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(INITIAL_TAB);
  const urlSearchParams = useQueryParams();
  const user = useTypedSelector((state) => state.user);
  const customOverrides = useTypedSelector((state) => state.customOverrides);

  const isTabValueToMarketplace =
    urlSearchParams.get("settingTab") === "marketplaces";
  const showDisconnected = urlSearchParams.get("showDisconnected") === "";
  const shouldDisplaySeats = ["owner"].includes(user?.role ?? "");
  const shouldDisplayMediaUpload =
    ["owner", "admin"].includes(user?.role ?? "") &&
    user?.mediaUploadOn &&
    customOverrides.agency &&
    user?.agency &&
    customOverrides.agency === user?.agency;
  const shouldDisplayBilling =
    !(user?.hideBilling || user?.roleSettings?.hideBilling) &&
    // hide billing page for omnivore users
    user?.source != "omnivore";

  const handleTabChange = (_e: React.ChangeEvent<{}>, value: number) => {
    setTabValue(value);
  };

  const TabPanel = ({
    value,
    children,
  }: {
    value: string | number;
    children: ReactElement;
  }) => {
    if (tabValue === value) {
      return (
        <Grid item xs={12}>
          <TabPanelContent transparent={value === 2}>
            {children}
          </TabPanelContent>
        </Grid>
      );
    }
    return null;
  };

  const settingTabs = useMemo(() => {
    return [
      {
        label: t("settings.accountTab"),
        component: <PersonalSetting />,
      },
      {
        label: t("settings.marketplacesTab"),
        component: <MarketplaceSetting showDisconnected={showDisconnected} />,
      },
      ...(shouldDisplayBilling
        ? [
            {
              label: t("settings.billingTab"),
              component: <BillingSetting />,
            },
          ]
        : []),
      ...(shouldDisplaySeats
        ? [
            {
              label: t("settings.seats"),
              component: <OrganisationSeats />,
            },
          ]
        : []),
      ...(shouldDisplayMediaUpload
        ? [
            {
              label: t("settings.mediaTab"),
              component: <MediaUpload />,
            },
          ]
        : []),
    ];
  }, [
    showDisconnected,
    shouldDisplayBilling,
    shouldDisplaySeats,
    shouldDisplayMediaUpload,
  ]);

  useEffect(() => {
    if (isTabValueToMarketplace || showDisconnected) {
      setTabValue(1);
    }
  }, [isTabValueToMarketplace, showDisconnected]);

  return (
    <PageBlock $transparent={true}>
      <SettingsToolbar
        {...{
          selectedItem: {
            breadcrumb: [
              { text: t("nav.mainPage"), link: "/overview" },
              { text: t("profile.myAccount") },
            ],
            currentPage: t("settings.settings"),
          },
        }}
      />
      <SettingsWrapper>
        <SettingsTabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          aria-label="simple tabs"
        >
          {settingTabs.map(({ label }, index) => (
            <SettingsTab key={index} label={label} />
          ))}
        </SettingsTabs>
        {settingTabs.map(({ component }, index) => (
          <TabPanel key={index} value={index}>
            {component}
          </TabPanel>
        ))}
      </SettingsWrapper>
    </PageBlock>
  );
};

export default SettingsPage;
