import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuList,
  MenuListProps,
  Tab,
  Typography,
} from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import { Notifications } from "@material-ui/icons";
import NotificationsPieChart from "~/components/charts/pieChart/notificationsPieChart";
import SmallButton from "~/components/buttons/smallButton";
import { fetchNewNotificationsCount } from "~/store/notifications.redux";
import { isUndefined } from "lodash";
import { useDispatch } from "react-redux";
import { useInterval } from "~/hooks/useInterval";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SmallTab = styled(Tab)`
  min-width: 0px;
  padding: 0; // IconButton has padding
`;

const NotificationsIcon = styled(Notifications)`
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

const CustomBadge = styled(Badge)<{ $selected?: boolean }>`
  & .MuiBadge-badge {
    background-color: ${({ theme }) =>
      theme.palette.topToolbar
        ? theme.palette.topToolbar.activeText
        : theme.palette.primary.main};
    color: ${({ theme }) =>
      theme.palette.topToolbar
        ? theme.palette.topToolbar.contrastText
        : theme.palette.primary.contrastText};
  }
  color: ${({ $selected, theme }) =>
    $selected
      ? theme.palette.topToolbar
        ? theme.palette.topToolbar.activeText
        : theme.palette.primary.main
      : theme.palette.topToolbar
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

type SubMenuProps = MenuListProps &
  ThemeProps<DefaultTheme> & { $divider?: boolean };
const SubMenu = styled(MenuList)`
  border-bottom: ${({ theme, $divider }: SubMenuProps) =>
    $divider ? `1px solid ${theme.palette.border.main}` : "none"};
  min-width: 300px;
`;

export const NotificationsBell = memo(function NotificationsBell() {
  const location = useLocation();
  const dispatch = useDispatch();
  const newNotificationsCount: number | undefined = useTypedSelector(
    (state) => state.notifications?.unresolvedCount
  );
  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );

  useEffect(() => {
    if (filteredStores?.length) {
      dispatch(
        fetchNewNotificationsCount(
          filteredStores.map((store) => store.merchantId)
        )
      );
    }
  }, [filteredStores]);

  useInterval(() => {
    if (filteredStores?.length) {
      dispatch(
        fetchNewNotificationsCount(
          filteredStores.map((store) => store.merchantId)
        )
      );
    }
  }, 60000);

  if (newNotificationsCount) {
    return (
      <IconButton component="div" aria-label="notifications" color="inherit">
        <CustomBadge
          overlap="circular"
          badgeContent={newNotificationsCount}
          $selected={location.pathname.includes("/notifications/")}
        >
          <NotificationsIcon fontSize="inherit" />
        </CustomBadge>
      </IconButton>
    );
  }

  return (
    <IconButton component="div" aria-label="notifications" color="inherit">
      <NotificationsIcon fontSize="inherit" />
    </IconButton>
  );
});

export const NotificationsTab = memo(function NotificationsTab() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const location = useLocation();

  const NotificationsMenu = () => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      disableScrollLock={true}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      autoFocus={false}
      marginThreshold={4}
    >
      <SubMenu $divider={true}>
        <Box p={1} pr={2} pl={2}>
          <Typography variant="h3">{t("nav.notifications")}</Typography>
        </Box>
      </SubMenu>
      <SubMenu $divider={true}>
        <NotificationsPieChart inPanel={false} flexDirection="column" />
      </SubMenu>
      <SubMenu>
        <Typography
          color="primary"
          variant="body1"
          align="center"
          id="panel-card-footer-link"
        >
          <SmallButton
            id={`notifications-link`}
            color="primary"
            component={RouterLink}
            to="/notifications/list"
          >
            {t("notifications.viewAlerts")}
          </SmallButton>
        </Typography>
      </SubMenu>
    </Menu>
  );

  return (
    <>
      <SmallTab
        value="notifications"
        id="notifications"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={<NotificationsBell />}
        selected={location.pathname.includes("/notifications/")}
      />
      <NotificationsMenu />
    </>
  );
});

export default NotificationsTab;
