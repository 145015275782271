import React, { memo } from "react";

import Bold from "../../typography/bold";
import { IconAndTextCell } from "../cells/iconAndTextCell";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SmallButton from "../../buttons/smallButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  width: ${({ width }) => width || null}!important;
  flex-grow: ${({ width }) => (width ? "0 !important" : "inherit")};
`;

const ButtonCell = styled.td`
  width: 130px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px !important;
  padding-right: 10px !important;
`;

export const StyledIcon = styled(KeyboardArrowRight)`
  margin-right: -10px;
`;

export const DisconnectedStoreRow = memo(function DisconnectedStoreRow({
  row,
  getCellProps,
}) {
  const { t } = useTranslation();
  const cells = row.allCells;
  const marketplaceInfo = cells.find(
    (cell) => cell.column.id === "market" || cell.column.id === "marketplace"
  );
  const store = cells.find(
    (cell) => cell.column.id === "shopName" || cell.column.id === "store"
  );

  return (
    <>
      <Cell
        width="90px"
        align={marketplaceInfo.column.align}
        {...marketplaceInfo.getCellProps(getCellProps(marketplaceInfo))}
        key={`${row.id} - reconnect market - ${marketplaceInfo}`}
      >
        {marketplaceInfo.render("Cell")}
      </Cell>
      <Cell
        align={store.column.align}
        {...store.getCellProps(getCellProps(store))}
        key={`${row.id} - reconnect store - ${store}`}
      >
        <IconAndTextCell cell={store} />
      </Cell>
      <ButtonCell colSpan={2}>
        <SmallButton
          component={Link}
          color="primary"
          to={`/connectStore/select`}
        >
          <Bold variant="body2">{t("generic.reconnectButton")}</Bold>
          <StyledIcon fontSize="small" />
        </SmallButton>
      </ButtonCell>
    </>
  );
});

DisconnectedStoreRow.propTypes = {
  row: PropTypes.any.isRequired,
  getCellProps: PropTypes.func.isRequired,
};
