import { Box, Grid, Typography } from "@material-ui/core";
import { GlobalVar, PurchaseOrder, UnitDetail } from "@typedef/store";
import React, { useMemo } from "react";

import Bold from "~/components/typography/bold";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { POStatus } from "~/components/table/cells/poStatusCell";
import PrimeIcon from "~/img/amazon_prime.png";
import SidePanel from "~/components/drawerPanel/sidePanel";
import Table from "~/components/table/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import { formatCurrency } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Divider = styled.div`
  height: 10px;
  width: 1px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.palette.text.secondary};
`;

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

interface PurchaseOrderDetailsProps {
  selectedPO: PurchaseOrder | null;
  currentCurrency: GlobalVar["currentCurrency"];
  currencyRates: GlobalVar["currencyRates"];
  setDrawerOpen: (open: boolean) => void;
}

const PurchaseOrderDetails = ({
  selectedPO,
  currentCurrency,
  currencyRates,
  setDrawerOpen,
}: PurchaseOrderDetailsProps) => {
  const { t } = useTranslation();

  /** These columns are used for the detailed pop-out when clicking on a
   * purchase order i.e. this displays data for a single purchase order. */
  const columns = useMemo(
    () => [
      {
        Header: t("purchaseOrder.uniqueSkus"),
        id: "title",
        colSpan: 4,
        accessor: (row: UnitDetail) => ({
          value: row.title,
          secondRowValue: `ASIN: ${row.asin} | VPI: ${row.vpi}`,
          image: row.imageUrl,
          link: row.linkUrl,
          target: "_blank",
          icon: row.isFulfilledByAmazon ? <SmallIcon src={PrimeIcon} /> : null,
        }),
        Cell: (props: any) => (
          <LinkAndImageCell {...props} colorVariant="external" />
        ),
      },
      {
        Header: t("purchaseOrder.submittedUnits"),
        id: "submittedUnits",
        accessor: "submittedUnits",
        align: "center",
      },
      {
        //accepted units
        Header: t("purchaseOrder.units"),
        id: "quantity",
        accessor: "quantity",
        align: "center",
      },
      {
        Header: t("purchaseOrder.cancelledUnits"),
        id: "cancelledUnits",
        accessor: "cancelledUnits",
        align: "center",
      },
      {
        Header: t("purchaseOrder.receivedUnits"),
        id: "receivedUnits",
        accessor: "receivedUnits",
        align: "center",
      },
      {
        Header: t("purchaseOrder.listPrice"),
        id: "listPrice",
        accessor: (row: UnitDetail) =>
          formatCurrency(
            row.listPrice,
            currencyRates,
            selectedPO?.currency,
            currentCurrency
          ),
        Cell: ValueCell,
        align: "right",
      },
      {
        Header: t("purchaseOrder.netCost"),
        id: "netCost",
        accessor: (row: UnitDetail) =>
          formatCurrency(
            row.netCost,
            currencyRates,
            selectedPO?.currency,
            currentCurrency
          ),
        Cell: ValueCell,
        align: "right",
      },
      {
        Header: t("purchaseOrder.poValue"),
        id: "poValue",
        accessor: (row: UnitDetail) =>
          formatCurrency(
            row.poValue,
            currencyRates,
            selectedPO?.currency,
            currentCurrency
          ),
        Cell: ValueCell,
        align: "right",
      },
    ],
    [selectedPO, currentCurrency, currencyRates]
  );

  return selectedPO ? (
    <SidePanel
      displayImage={false}
      headerComponent={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column" pl={2} pr={2}>
            <Bold>{selectedPO.purchaseOrder}</Bold>
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary">
                {t("purchaseOrder.dateReceived")}
                {": "}
                {moment(selectedPO.dateReceived).format("L")}
              </Typography>
              <Divider />
              <Typography color="textSecondary">
                {t("purchaseOrder.units")}
                {": "}
                {selectedPO.units}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pl={2}>
            <Box display="flex" alignItems="center" pr={2}>
              <POStatus value={selectedPO.status} />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h4">
                {t("purchaseOrder.poValue")}
                {": "}
                {formatCurrency(
                  selectedPO.poValue,
                  currencyRates,
                  selectedPO.currency,
                  currentCurrency
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      content={
        <Grid item>
          <Table
            {...{
              columns,
              data: selectedPO.unitDetails,
              pageSize: 20,
              gridLayoutColumns: 10,
            }}
          />
        </Grid>
      }
      handleClose={() => setDrawerOpen(false)}
    />
  ) : (
    <Grid />
  );
};

export default PurchaseOrderDetails;
