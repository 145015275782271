import { OverviewOverdueOrders, StoreState } from "~/typedef/store";
import React, { memo, useCallback, useMemo } from "react";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";
import { useDispatch, useSelector } from "react-redux";

import { LinkCell } from "~/components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "~/components/table/cells/marketplaceAndCountryCell";
import Table from "~/components/table/table";
import { TextAndIconCell } from "~/components/table/cells/textAndIconCell";
import { TextCell } from "~/components/table/cells/textCell";
import { get } from "lodash";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

interface ReducerEntry {
  store: any;
  shopName: any;
  overdueOrderCount: {
    value: number;
    icon: string;
  };
}

const formatMarketplaceData = (
  filteredStores: any[],
  overdueOrders: OverviewOverdueOrders | undefined,
  allowedLinks: string[] = []
) =>
  overdueOrders &&
  overdueOrders.rows &&
  overdueOrders.rows
    .reduce<ReducerEntry[]>((acc, storeOverdueOrders) => {
      if (storeOverdueOrders) {
        const shopName = {
          value: getShopName(
            filteredStores,
            storeOverdueOrders.marketplace.market,
            storeOverdueOrders.store
          ),
          link:
            storeOverdueOrders.marketplace.market === "amazon_vendor"
              ? marketplaceLink(
                  storeOverdueOrders.marketplace.market,
                  storeOverdueOrders.store,
                  "purchaseorders",
                  undefined,
                  allowedLinks
                )
              : marketplaceLink(
                  storeOverdueOrders.marketplace.market,
                  storeOverdueOrders.store,
                  "overdueorders",
                  undefined,
                  allowedLinks
                ),
        };
        const overdueOrderCount = {
          value: parseInt(storeOverdueOrders.overdueOrderCount),
          icon:
            storeOverdueOrders.overdueOrderCount === "0" ? "success" : "error",
        };

        acc.push({
          ...storeOverdueOrders,
          shopName,
          overdueOrderCount,
        });
      }
      return acc;
    }, [])
    .sort((a, b) =>
      a.overdueOrderCount.value > b.overdueOrderCount.value ? -1 : 1
    );

interface OverdueOrderSummaryContentsProps {
  overdueOrderSummary: OverviewOverdueOrders;
  fetchOverdueOrderSummary: Function;
  compact?: boolean;
}

const OverdueOrderSummaryContents = memo(
  ({
    overdueOrderSummary,
    fetchOverdueOrderSummary,
    compact,
  }: OverdueOrderSummaryContentsProps) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state: StoreState) => state.user);
    const filteredStores = useSelector((state) =>
      get(state, "mystore.filteredStores.stores", [])
    );
    const userCheck = userInfo && userInfo._id;
    const currentFilter = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const allowedLinks = useTypedSelector(
      (state) => state.customLayout?.layoutConfig?.sideNav?.pages
    );

    const records = overdueOrderSummary;
    const fetching = overdueOrderSummary.fetching;
    const dispatch = useDispatch();

    const dispatchFetchOverdueOrders = useCallback(
      ({ pageIndex, pageSize }) => {
        dispatch(
          fetchOverdueOrderSummary(
            {
              user: { _id: userInfo?._id },
              pageIndex,
              pageSize,
              filter: currentFilter,
              filteredStores,
              timezone: selectedTimezone,
            },
            overdueOrderSummary?.params
          )
        );
      },
      [currentFilter, filteredStores, userCheck, overdueOrderSummary?.params]
    );

    const data = useMemo(() => {
      const formattedData = formatMarketplaceData(
        filteredStores,
        records,
        allowedLinks
      );
      return formattedData ? formattedData : [];
    }, [records, userCheck, allowedLinks]);

    const columns = useMemo(
      () => [
        {
          id: "marketplace",
          Header: t("dashboardWidget.overdueOrders.marketplaceColumn"),
          accessor: "marketplace",
          Cell: MarketplaceAndCountryCell,
          alwaysSmall: true,
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.overdueOrders.storeColumn"),
          accessor: compact
            ? (row: any) => get(row, "shopName.value")
            : "shopName",
          Cell: compact ? TextCell : LinkCell,
          alwaysSmall: true,
        },
        {
          id: "overdueOrderCount",
          Header: t("dashboardWidget.overdueOrders.ordersDueColumn"),
          accessor: "overdueOrderCount",
          Cell: TextAndIconCell,
          alwaysSmall: true,
          align: "right",
        },
      ],
      []
    );

    const pageCount =
      records && records.count ? Math.ceil(records.count / PAGE_SIZE) : 1;
    return (
      <Table
        {...{
          columns,
          compact,
          data,
          loading: fetching,
          fetchData: dispatchFetchOverdueOrders,
          pageSize: PAGE_SIZE,
          pagination: !compact,
          pageCount,
          gridLayoutColumns: 4,
        }}
      />
    );
  }
);

export default OverdueOrderSummaryContents;
