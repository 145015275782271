import React, { memo, useCallback, useMemo } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";
import ACOSCell from "../../../components/table/cells/acosCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import StatusCell from "~/modules/marketing/statusCell";
import Table from "~/components/table/table";
import { User } from "~/typedef/user";
import { ValueCell } from "~/components/table/cells/valueCell";
import { WrappedTextCell } from "../../../components/table/cells/textCell";
import { fetchMarketingProductAds } from "~/store/mystore/marketing.redux";
import get from "lodash/get";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 5;

interface TopPerformingProductAdsProps {
  userInfo: User;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  report?: boolean;
  pageSize?: number;
  conditionalFormatting?: boolean;
  condensed?: boolean;
}

const TopPerformingProductAds = memo<TopPerformingProductAdsProps>(
  function TopPerformingProductAds({
    userInfo,
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentRange,
    currentCurrency,
    footerLink,
    report,
    pageSize,
    conditionalFormatting,
    condensed,
  }) {
    const { t } = useTranslation();
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currencyRates = useSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const marketing = useSelector((state) => get(state, "marketing"));
    const loading = useSelector((state) =>
      get(state, "marketing.productAds.loading")
    );
    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const dispatch = useDispatch();

    const columns = useMemo(
      () => [
        ...(!onMobile
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.campaignColumn"),
                id: "campaign_name",
                accessor: "campaign_name",
                isVisible: true,
                Cell: WrappedTextCell,
                colSpan: 2,
                customWidth: 150,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.adGroupColumn"),
                id: "ad_group_name",
                accessor: "ad_group_name",
                isVisible: true,
                Cell: WrappedTextCell,
                colSpan: 2,
                customWidth: 150,
              },
            ]
          : []),
        {
          Header: t("advertisingDashboardWidget.adTable.productColumn"),
          accessor: (row: any) => ({
            value: row.product_title,
            secondRowValue: `ASIN: ${row.asin}`,
            image: row.image_url,
            link: row.link_url,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          isVisible: true,
          colSpan: 6,
          customWidth: 350,
        },
        ...(!condensed
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.statusColumn"),
                id: "product_ad_status",
                accessor: (row: any) => ({
                  value: row.product_ad_status,
                }),
                Cell: (props: any) => <StatusCell {...props} />,
                isVisible: true,
                align: "center",
                customWidth: 100,
              },
            ]
          : []),

        {
          Header: t("advertisingDashboardWidget.adTable.salesColumn"),
          id: "attributed_sales",
          accessor: (row: any) =>
            row.attributed_sales
              ? formatCurrency(
                  row.attributed_sales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          customWidth: 100,
        },
        ...(!onMobile
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.spendColumn"),
                id: "cost",
                accessor: (row: any) =>
                  row.cost
                    ? formatCurrency(
                        row.cost,
                        currencyRates,
                        homeCurrency,
                        currentCurrency
                      )
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                customWidth: 100,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.acosColumn"),
                id: "acos",
                accessor: (row: any) => {
                  return {
                    value: row.acos ? `${row.acos.toFixed(1)}%` : "-",
                    conditionalFormatting: conditionalFormatting,
                  };
                },
                align: "right",
                Cell: ACOSCell,
                isVisible: true,
                sortDescFirst: true,
                customWidth: 100,
              },
            ]
          : []),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currencyRates, currentCurrency, homeCurrency, onMobile]
    );

    const EMPTY_SEARCH = "";
    const NO_ADGROUP_ID = null;

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchMarketingProductAds(
            userInfo._id,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            EMPTY_SEARCH,
            currentRange.fromDate,
            currentRange.toDate,
            NO_ADGROUP_ID,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                  sortKey: "attributed_sales",
                  sortDesc: true,
                }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [userInfo._id, mid, countryCode, marketplaceType, currentRange]
    );

    return (
      <Panel
        id="widget-marketing-keyword-summary"
        title={t("advertisingDashboardWidget.topPerformingProductAds")}
        tooltip={t("advertisingDashboardWidget.topPerformingProductAdsTooltip")}
        footerLink={footerLink}
        content={
          <Table
            tdHeight="unset"
            {...{
              columns,
              data: get(marketing, "productAds.data", []),
              fetchData,
              loading,
              pageSize: pageSize ?? PAGE_SIZE,
              gridLayoutColumns: 6,
              isReport: report,
            }}
          />
        }
      />
    );
  }
);

export default TopPerformingProductAds;
