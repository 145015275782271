import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SalesByBrandTable from "~/modules/overview/salesByBrand";
import get from "lodash/get";
import { useMarketplace } from "~/utils/navigationUtils";
import { useSelector } from "react-redux";

const SalesByBrand = memo(() => {
  const marketplace = useMarketplace();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SalesByBrandTable market={marketplace} mid={store.merchantId} />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default SalesByBrand;
