import { Box, Grid } from "@material-ui/core";
/* eslint-disable no-undefined */
import React, { memo, useCallback, useEffect } from "react";

import GenericTotal from "../../../components/totals/genericTotal";
import Panel from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import TotalOrders from "../../../components/totals/totalOrders";
import { User } from "~/typedef/user";
import { fetchReviewSummary } from "../../../store/mystore/review.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ReviewSummaryProps {
  userInfo?: User;
  mid?: string;
  currentPeriod: string;
  currentRange: Range;
}

const ReviewSummary = memo(
  ({ userInfo, mid, currentPeriod, currentRange }: ReviewSummaryProps) => {
    const { t } = useTranslation();

    const reviewSummary = useTypedSelector((state) => state.review.summary);
    const triggerRefetch = useTypedSelector(
      (state) => state.review.triggerRefetch
    );

    const dispatch = useDispatch();

    const dispatchFetchReviewSummary = useCallback(() => {
      dispatch(
        fetchReviewSummary({
          user: userInfo,
          currentRange,
          mid,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRange, mid, userInfo]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchReviewSummary();
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, currentRange, mid]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchReviewSummary();
      };
      if (triggerRefetch && !reviewSummary.fetching) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerRefetch]);

    return (
      <Panel
        id="widget-review-summary"
        title={t(`myStoresWidget.reviewStatus`)}
        tooltip={undefined}
        content={
          <Box p={2}>
            {reviewSummary.fetching ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TotalOrders
                    current={reviewSummary.totalOrders}
                    currentPeriod={currentPeriod}
                    omitGrowth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericTotal
                    number={reviewSummary.scheduledOrders}
                    title={t(`reviewManagement.scheduled`)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericTotal
                    number={reviewSummary.solicitedOrders}
                    title={t(`reviewManagement.solicited`)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericTotal
                    number={reviewSummary.excludedOrders}
                    title={t(`reviewManagement.excluded`)}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default ReviewSummary;
