import * as Sentry from "@sentry/browser";

import { AppSettings, GlobalVar } from "~/typedef/store";
import { Grid, GridProps } from "@material-ui/core";
import React, { Component, ErrorInfo, ReactNode } from "react";

import { DateRange } from "~/typedef/date";
import { ErroredWidgetContent } from "./erroredWidget";
import { Location } from "history";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ErrorBoundaryProps {
  user: User;
  globalVar: GlobalVar;
  appSettings?: AppSettings;
  location: Location;
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: null | ErrorInfo;
  eventId: null | string;
  error: null | Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
    errorInfo: null,
    eventId: null,
    error: null,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error: error, errorInfo: null, eventId: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      /** Specify user information */
      scope.setUser({
        id: get(this.props.user, "_id"),
        username:
          get(this.props.user, "firstName", "") +
          get(this.props.user, "lastName", ""),
        email: get(this.props.user, "email"),
        ip_address: "{{auto}}",
      });
      /** Specify extra tags used for Slack notification */
      scope.setTag("user.email", get(this.props.user, "email"));
      scope.setTag("path", get(this.props.location, "pathname"));
      /** Specify redux data to attach to the error */
      scope.setContext("Location", {
        ...this.props.location,
      });
      scope.setContext("Error Information", {
        ...errorInfo,
      });
      scope.setContext("User (Redux)", {
        id: get(this.props.user, "_id"),
        firstName: get(this.props.user, "firstName"),
        lastName: get(this.props.user, "lastName"),
        email: get(this.props.user, "email"),
        org: get(this.props.user, "org"),
      });
      const { ...globalVar } = this.props.globalVar;
      scope.setContext("Global Variables (Redux)", globalVar);
      scope.setContext("App Settings (Redux)", {
        ...this.props.appSettings,
      });

      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  public render() {
    if (this.state.hasError) {
      return <ErroredWidgetContent error={this.state.error} />;
    }
    return this.props.children;
  }
}

interface GridWithErrorBoundary extends GridProps {}

export const GridWithErrorBoundary: React.FC<GridWithErrorBoundary> = ({
  children,
  ...otherProps
}) => {
  const location = useLocation();

  const user = useTypedSelector((state) => state.user);
  const globalVar = useTypedSelector((state) => state.globalVar);
  const appSettings = useTypedSelector(
    (state) => state.persistentAppSettings.setting.data
  );

  return (
    <Grid {...otherProps}>
      <ErrorBoundary
        user={user}
        globalVar={globalVar}
        appSettings={appSettings}
        location={location}
      >
        {children}
      </ErrorBoundary>
    </Grid>
  );
};
