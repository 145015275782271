import {
  FETCH_DISCONNECTED_STORES,
  FETCH_DISCONNECTED_STORES_ERROR,
  FETCH_DISCONNECTED_STORES_FETCHING,
} from "./connections.redux";

import { Dispatch } from "redux";
import axios from "axios";
import { baseUrl } from "../../configs";

function setFetching(dispatch: Dispatch) {
  dispatch({
    type: FETCH_DISCONNECTED_STORES_FETCHING,
  });
}

export const fetchDisconnectedStores = () => async (dispatch: Dispatch) => {
  setFetching(dispatch);
  try {
    const { data } = await axios.get(
      `${baseUrl}/api/user-management-service/api/store/disconnected`
    );

    return dispatch({
      type: FETCH_DISCONNECTED_STORES,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: FETCH_DISCONNECTED_STORES_ERROR,
      payload: (e as Error).message,
    });
  }
};
