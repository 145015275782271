import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";

import AlertCard from "~/components/alert/alertCard";
import Bold from "~/components/typography/bold";
import ErrorButton from "~/components/buttons/errorButton";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { PriorityHigh } from "@material-ui/icons";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)`
  padding: 20px 20px;
  flex: 0;
`;

const StyledDialogTitle = styled(BaseDialogTitle)`
  padding: 0 20px;
  max-height: 55px;
`;

const AlertIcon = styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.light};
  margin-right: 1rem;
`;

interface CaseConfigUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (selectedFile: File) => void;
  loading?: boolean;
  warningLabel: string;
}

const CaseConfigUploadDialog: React.FC<CaseConfigUploadDialogProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
  warningLabel,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadAttempted, setUploadAttempted] = useState<boolean>(false);

  const selectFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event?.target?.files ? event?.target?.files[0] : null);
  };

  const startUpload = () => {
    if (!selectedFile) return;
    setUploadAttempted(true);
    onConfirm(selectedFile);
  };

  const cancel = () => {
    setSelectedFile(null);
    setUploadAttempted(false);
    onClose();
  };

  return (
    <BaseDialog maxWidth="md" open={open} onClose={cancel}>
      <StyledDialogTitle onClose={cancel}>
        {t("inventoryCaseConfig.uploadButtonMainLabel")}
      </StyledDialogTitle>
      <StyledDialogContent>
        {uploadAttempted ? (
          loading ? (
            <>
              <Grid container item xs={12} alignItems="center">
                <Typography variant="body1">
                  {t("inventoryCaseConfig.uploadInProgressLabel")}
                </Typography>
              </Grid>
              <PanelLoading />
            </>
          ) : (
            <Grid container item xs={12} alignItems="center">
              <Typography variant="body1">
                {t("inventoryCaseConfig.uploadFinishedLabel")}
              </Typography>
            </Grid>
          )
        ) : (
          <Box margin="1rem 2rem">
            <AlertCard isOpen={true} type="warning">
              <Box display="flex" alignItems="center" width="100%">
                <AlertIcon>
                  <PriorityHigh fontSize="small" />
                </AlertIcon>
                <Bold variant="body1">{warningLabel}</Bold>
              </Box>
            </AlertCard>
            <Box width="100%" mt="2rem">
              <Grid container>
                <Grid container item xs={6} justifyContent="center">
                  <Typography variant="body1">
                    {t("inventoryCaseConfig.uploadFileLabel")}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Input type="file" name="file" onChange={selectFileHandler} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Grid spacing={2} container item xs={12} direction="row-reverse">
          <Grid container justifyContent="center" item xs={12} sm={6}>
            {uploadAttempted ? (
              <RaisedButton
                disabled={loading}
                onClick={cancel}
                variant="contained"
                color="primary"
              >
                {t("generic.okButton")}
              </RaisedButton>
            ) : (
              <RaisedButton
                disabled={!selectedFile || loading}
                onClick={startUpload}
                variant="contained"
                color="primary"
              >
                {t("generic.confirmButton")}
              </RaisedButton>
            )}
          </Grid>
          <Grid container justifyContent="center" item xs={12} sm={6}>
            <ErrorButton disabled={loading} onClick={cancel} variant="outlined">
              {t("generic.cancelButton")}
            </ErrorButton>
          </Grid>
        </Grid>
        <Box mb={8} />
      </DialogActions>
    </BaseDialog>
  );
};

export default CaseConfigUploadDialog;
