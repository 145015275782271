import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect } from "react";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import Panel from "~/components/panel/panel";
import PerformanceSummary from "~/modules/widgets/advertising/performanceSummary";
import { StoreState } from "~/typedef/store";
import { fetchMarketingAdGroupTotals } from "~/store/mystore/marketing.redux";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AdGroupTotalsPanelProps {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  adGroupId: string;
}

const AdGroupTotalsPanel = memo(
  ({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    adGroupId,
  }: AdGroupTotalsPanelProps) => {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state: StoreState) => state.user);
    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const homeCurrency: string = getCurrencyByCountryCode[countryCode];
    const currentCurrency: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const loading = useTypedSelector((state: StoreState) =>
      get(state, "marketing.totals.loading", true)
    );
    const data = useTypedSelector((state: StoreState) =>
      get(state, "marketing.totals.data")
    );

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        fetchMarketingAdGroupTotals(
          userInfo._id,
          mid,
          countryCode,
          marketplaceType,
          marketplaceSubtype,
          currentRange.fromDate,
          currentRange.toDate,
          currentRange.priorFromDate,
          currentRange.priorToDate,
          adGroupId,
          selectedTimezone
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      userInfo._id,
      mid,
      countryCode,
      marketplaceType,
      currentRange,
      adGroupId,
      selectedTimezone,
    ]);

    return (
      <Panel
        id="widget-marketing-ad-group-chart"
        title={t("advertisingDashboardWidget.adGroupPerformanceTotals")}
        content={
          <Box p={2} height="100%" display="flex" alignItems="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceSummary
                    data={data}
                    homeCurrency={homeCurrency}
                    currentCurrency={currentCurrency}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default AdGroupTotalsPanel;
