import { User } from "~/typedef/user";

export const getFriendlyShortUserName = (user: User) => {
  return user.firstName
    ? `${user.firstName}`
    : user.email
    ? user.email.split("@")[0]
    : null;
};

export const getFriendlyLongUserName = (user: User) => {
  return user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.firstName
    ? `${user.firstName}`
    : user.email
    ? user.email.split("@")[0]
    : null;
};
