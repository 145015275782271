import React, { memo, useCallback } from "react";
import { flatMap, get, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import FilterTagSelect from "../../select/filterTagSelect";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { addStoreCustomTags } from "../../../store/overview/customTags.redux";
import { useTranslation } from "react-i18next";

export const FilterCell = memo(({ status, mid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterTagTitle = t("filters.addTags");
  const isDisabled = status === "Reconnect";
  const isTagsLoading = useSelector((state) =>
    get(state, "mystore.customTags.fetching")
  );
  const tags = useSelector((state) => get(state, "mystore.customTags.tags"));
  const options = uniq(flatMap(tags, (val) => val.tags));
  const tagsPerColumn = tags.find((tag) => tag.mid === mid);
  const selectedOptions = get(tagsPerColumn, "tags");

  const handleOptionChange = useCallback(
    (selectedTags) => {
      dispatch(addStoreCustomTags(mid, selectedTags));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mid]
  );

  return (
    <Grid container alignItems="flex-start">
      <FilterTagSelect
        maxWidth={500}
        minWidth={70}
        additionalMenuWidth={100}
        isDisabled={isDisabled}
        options={options}
        isWithSuggestion
        forSettingPage
        isLoading={isTagsLoading}
        handleOpenMenu={() => {}}
        handleOptionChange={handleOptionChange}
        filterTagTitle={filterTagTitle}
        selectedOptions={selectedOptions}
      />
    </Grid>
  );
});

FilterCell.propTypes = {
  status: PropTypes.oneOf(["Connected", "Reconnect"]),
  mid: PropTypes.string,
};
