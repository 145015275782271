import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import { Cell } from "./acosCell";
import { GrowthBlock } from "./valueAndGrowthCell";
import PropTypes from "prop-types";
import StatusText from "../../typography/status";
import { Typography } from "@material-ui/core";

export const getGrowthStatus = (growth, conditionalFormatting) => {
  if (
    growth === "N/A" ||
    growth === "-" ||
    conditionalFormatting === "disabled"
  ) {
    return undefined;
  }
  return growth > 0 ? "success" : "error";
};

const ProfitCell = memo(({ cell }) => {
  const growth = cell.value?.growth;
  const { value } = growth ? cell.value : cell;
  const conditionalFormatting = cell.value?.conditionalFormatting;
  const growthStatus = getGrowthStatus(growth, conditionalFormatting);
  return (
    <Cell>
      <Typography variant="body2">{value}</Typography>
      {growth && (
        <GrowthBlock>
          <StatusText
            variant="body2"
            status={conditionalFormatting === false ? undefined : growthStatus}
            align="right"
            paragraph={false}
          >
            {growthStatus === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {growthStatus === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth) ? growth : Math.abs(growth)}
            {!isNaN(growth) && "%"}
          </StatusText>
        </GrowthBlock>
      )}
    </Cell>
  );
});

ProfitCell.propTypes = {
  row: PropTypes.shape({ original: PropTypes.any }).isRequired,
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default ProfitCell;
