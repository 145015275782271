import React, { memo, useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import {
  useCustomGroupQuery,
  useCustomGroupsQuery,
} from "~/store/customGroups/reducer.redux";

import CustomGroupsFormBrand from "./customGroupsFormBrand";
import CustomGroupsFormProduct from "./customGroupsFormProduct";
import ModalPanel from "~/components/panel/modalPanel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CustomGroupsModalProps {
  mid: string;
  marketplace: string;
  openModal: boolean;
  groupId?: number;
  setOpenModal: (open: boolean) => void;
  onEdit?: () => void;
  setMarkedForEdit: (value: any) => void;
}

enum CustomGroupsTabs {
  BY_PRODUCT = "byProduct",
  BY_BRAND = "byBrand",
}

const INITIAL_TAB = CustomGroupsTabs.BY_PRODUCT;

const CustomGroupTabsWrapper = styled(Tabs)`
  background-color: transparent;
  width: fit-content;
`;

const CustomGroupTab = styled(Tab)`
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.common.black};
  border-radius: 0;
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

const CustomGroupsModal = memo<CustomGroupsModalProps>(
  function CustomGroupsModal({
    mid,
    marketplace,
    openModal,
    setOpenModal,
    groupId,
    onEdit,
    setMarkedForEdit,
  }) {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<CustomGroupsTabs>(INITIAL_TAB);

    const userInfo = useTypedSelector((state) => state.user);
    const { customGroups } = useCustomGroupsQuery(
      {
        mid,
        userId: userInfo?._id,
      },
      {
        skip: !userInfo?._id,
        selectFromResult: ({ data }) => ({
          customGroups: data?.groups || [],
        }),
      }
    );

    const handleTabChange = (
      _e: React.ChangeEvent<{}>,
      value: CustomGroupsTabs
    ) => {
      setCurrentTab(value);
    };

    const { currentGroup, currentGroupFetching, isCustomGroupByBrand } =
      useCustomGroupQuery(
        {
          mid,
          groupId: groupId || 0,
        },
        {
          // Note: Even if it skips making the query
          // it will return the previous value of currentGroup if an edit pop up was opened
          // that's why we have checks for the groupId in multiple places below
          // to avoid using the previous value of currentGroup
          skip: !groupId || !openModal,
          selectFromResult: ({ data, isFetching }) => ({
            currentGroup: data?.group,
            currentGroupFetching: isFetching,
            isCustomGroupByBrand: data?.group && data.group.brands.length > 0,
          }),
        }
      );

    useEffect(() => {
      if (groupId && !currentGroupFetching && currentGroup) {
        setCurrentTab(
          isCustomGroupByBrand
            ? CustomGroupsTabs.BY_BRAND
            : CustomGroupsTabs.BY_PRODUCT
        );
      }
    }, [groupId, currentGroup, currentGroupFetching, isCustomGroupByBrand]);

    const onClose = () => {
      setMarkedForEdit(null);
    };

    return (
      <ModalPanel
        open={openModal}
        setOpen={setOpenModal}
        title={
          groupId
            ? isCustomGroupByBrand
              ? t("customGroups.editGroupByBrand")
              : t("customGroups.editGroup")
            : t("customGroups.createGroup")
        }
        onClose={onClose}
        content={
          currentGroupFetching || !openModal ? (
            <PanelLoading />
          ) : (
            <>
              {groupId && currentGroup ? null : (
                <CustomGroupTabsWrapper
                  value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  aria-label="Simple Tabs"
                >
                  <CustomGroupTab
                    value={CustomGroupsTabs.BY_PRODUCT}
                    label={t("customGroups.byProductsTab")}
                  />
                  <CustomGroupTab
                    value={CustomGroupsTabs.BY_BRAND}
                    label={t("customGroups.byBrandsTab")}
                  />
                </CustomGroupTabsWrapper>
              )}
              {currentTab === CustomGroupsTabs.BY_BRAND ? (
                <CustomGroupsFormBrand
                  mid={mid}
                  marketplace={marketplace}
                  customGroups={customGroups}
                  currentGroup={groupId ? currentGroup : undefined}
                  setOpenModal={setOpenModal}
                  onEdit={onEdit}
                />
              ) : (
                <CustomGroupsFormProduct
                  mid={mid}
                  marketplace={marketplace}
                  customGroups={customGroups}
                  currentGroup={groupId ? currentGroup : undefined}
                  setOpenModal={setOpenModal}
                  onEdit={onEdit}
                />
              )}
            </>
          )
        }
      />
    );
  }
);

export default CustomGroupsModal;
