import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { TableBaseProps } from "~/components/adTable/table";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  word-break: break-all;
`;

interface TextCellProps {
  cell: {
    value: string;
    column?: TableBaseProps["columns"][number];
  };
}

export const TextCell = memo<TextCellProps>(function TextCell({ cell }) {
  const value = cell.value;
  const cellJustify = cell.column?.cellJustify;

  return (
    <Grid
      container
      alignItems="flex-start"
      {...(cellJustify
        ? {
            justifyContent: cellJustify,
          }
        : {})}
    >
      <Typography variant="body2" noWrap>
        {value}
      </Typography>
    </Grid>
  );
});

export const WrappedTextCell = memo<TextCellProps>(function TextCell({ cell }) {
  const value = cell.value;
  return (
    <Grid container alignItems="flex-start">
      <StyledTypography variant="body2">{value}</StyledTypography>
    </Grid>
  );
});
