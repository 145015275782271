import { ErrorRounded, LinkOff, SyncRounded } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";

const NoWrapGrid = styled(Grid)`
  overflow: hidden;
`;

const ReconnectIcon = styled(ErrorRounded)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const SyncIcon = styled(SyncRounded)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

const LinkIcon = styled(LinkOff)`
  color: ${({ theme }) => theme.palette.info.main};
`;

const getValue = (cell: CellProp) => {
  if (cell.value !== undefined && typeof cell.value !== "object") {
    return cell.value;
  }
  if (
    cell.value.originalFieldValue !== undefined &&
    typeof cell.value.originalFieldValue !== "object"
  ) {
    return cell.value.originalFieldValue;
  }
  if (cell.value.value !== undefined && typeof cell.value.value !== "object") {
    return cell.value.value;
  }
  return null;
};

type CellProp = {
  value:
    | {
        originalFieldValue: object | string;
        value: object | string;
      }
    | string;
};

interface IconAndTextCellProps {
  cell: CellProp;
  status?: "reconnect" | "sync" | "link";
}

export const IconAndTextCell = memo<IconAndTextCellProps>(
  function IconAndTextCell({ cell, status }) {
    const value = getValue(cell);

    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Grid item>
          {status === "sync" ? (
            <SyncIcon />
          ) : status === "link" ? (
            <LinkIcon />
          ) : (
            <ReconnectIcon />
          )}
        </Grid>
        <NoWrapGrid item>
          <Typography
            variant="body2"
            noWrap
            color={
              status === "sync" || status === "link" ? "textPrimary" : "error"
            }
          >
            {value}
          </Typography>
        </NoWrapGrid>
      </Grid>
    );
  }
);
