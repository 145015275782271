import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components";

interface EditCellProps {
  cell?: { value: { id: string; name: string } };
  onEdit: ({
    id,
    name,
    value,
  }: {
    id?: string;
    name?: string;
    value: any;
  }) => void;
  row: any;
}

const StyledEditIcon = styled(EditIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  fill: ${({ theme }) => theme.palette.grey["500"]};
`;

const EditCell: React.FC<EditCellProps> = ({ cell, onEdit, row }) => {
  const { id, name } = cell?.value ?? {};
  const value = row.original;

  return (
    <Tooltip title="Edit">
      <StyledEditIcon onClick={() => onEdit({ id, name, value })} />
    </Tooltip>
  );
};

export default EditCell;
