import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const FETCH_WALMART_LISTING_QUALITY_SUMMARY =
  "FETCH_WALMART_LISTING_QUALITY_SUMMARY";
export const FETCH_WALMART_LISTING_QUALITY_SUMMARY_FETCHING =
  "FETCH_WALMART_LISTING_QUALITY_SUMMARY_FETCHING";

export const FETCH_WALMART_LISTING_QUALITY_PRODUCTS =
  "FETCH_WALMART_LISTING_QUALITY_PRODUCTS";
export const FETCH_WALMART_LISTING_QUALITY_PRODUCTS_FETCHING =
  "FETCH_WALMART_LISTING_QUALITY_PRODUCTS_FETCHING";

const initState = {
  summary: {
    fetching: false,
  },
  products: {
    fetching: false,
  },
};
/*
  At this stage, only walmart is using this part of the redux store.
*/
export const listingQualityGeneric = (state = initState, action) => {
  switch (action.type) {
    case FETCH_WALMART_LISTING_QUALITY_SUMMARY_FETCHING:
      return {
        ...state,
        summary: {
          ...state.summary,
          fetching: true,
        },
      };

    case FETCH_WALMART_LISTING_QUALITY_SUMMARY:
      return {
        ...state, // TO NOT OVERIDE PRODUCTS
        summary: {
          ...action.payload,
          fetching: false,
        },
      };

    case FETCH_WALMART_LISTING_QUALITY_PRODUCTS_FETCHING:
      return {
        ...state,
        products: {
          ...state.products,
          fetching: true,
        },
      };

    case FETCH_WALMART_LISTING_QUALITY_PRODUCTS:
      return {
        ...state,
        products: {
          ...action.payload,
          fetching: false,
        },
      };

    default:
      return state;
  }
};

export const fetchListingQualitySummary =
  (mid, countryCode) => async (dispatch) => {
    dispatch({ type: FETCH_WALMART_LISTING_QUALITY_SUMMARY_FETCHING });

    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/walmart-service/api/listingQuality/summary`,
        {
          mid,
          countryCode,
        }
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: FETCH_WALMART_LISTING_QUALITY_SUMMARY,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const fetchListingQualityProducts =
  ({
    mid,
    countryCode,
    sortKey,
    sortOrder,
    pageIndex,
    pageSize,
    searchText, // can be empty string
    statusFilter, // can be null
  }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_WALMART_LISTING_QUALITY_PRODUCTS_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/walmart-service/api/listingQuality/products`,
        {
          mid,
          countryCode,
          sortKey,
          sortOrder,
          pageIndex,
          pageSize,
          searchText,
          statusFilter,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_WALMART_LISTING_QUALITY_PRODUCTS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };
