import { Grid, GridProps } from "@material-ui/core";
import { drawerWidthClosed, drawerWidthOpen } from "./sideNavConstants";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

type ContentWrapperProps = ThemeProps<DefaultTheme> &
  GridProps & { open?: boolean };
export const ContentWrapper = styled(Grid)`
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 120px);
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  align-self: flex-end;
  overflow-y: hidden;
  ${({ open, theme }: ContentWrapperProps) =>
    open
      ? {
          width: `calc(100% - ${drawerWidthOpen}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      : {
          width: `calc(100% - ${drawerWidthClosed}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.down("sm")]: {
            width: `100%`,
          },
        }};
`;
ContentWrapper.displayName = "ContentWrapper";
