import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import TopPerformingKeywordsWidget from "../widgets/advertising/topPerformingKeywords";
import { get } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TopPerformingKeywordsProps {
  market: string;
  mid: string;
  countryCode: string;
}

const TopPerformingKeywords = memo<TopPerformingKeywordsProps>(
  function TopPerformingKeywords({ market, mid, countryCode }) {
    const { t } = useTranslation();

    const userInfo = useTypedSelector((state) => state.user);

    const currentStore = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const footerLink = {
      url: marketplaceLink(market, mid, "marketingKeywords"),
      label: t("generic.viewAllLink"),
    };

    return (
      <TopPerformingKeywordsWidget
        userInfo={userInfo}
        mid={mid}
        countryCode={countryCode}
        marketplaceType={market}
        marketplaceSubtype={currentStore?.marketplaceSubtype || market}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        footerLink={footerLink}
      />
    );
  }
);

export default TopPerformingKeywords;
