import {
  ENTITY_TYPE,
  formatAdType,
  getPerformanceColumns,
} from "./commonColumns";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";
import { useReportDateFrom, useReportDateTo } from "./useReportDate";

import ColumnSelect from "~/components/adTable/columnSelect";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkCell } from "~/components/table/cells/linkCell";
import Panel from "~/components/panel/panel.tsx";
import PropTypes from "prop-types";
import SearchFilter from "~/components/adTable/searchFilter";
import StatusCell from "./statusCell";
import Table from "~/components/adTable/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchMarketingAdGroups } from "~/store/mystore/marketing.redux";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

const AdGroupSummaryPanel = memo(
  ({ marketplace, mid, countryCode, campaignId, campaignName }) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state.user);
    const store = useSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const marketplaceSubtype = get(store, "marketplaceSubtype");
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency")
    );
    const currencyRates = useSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[store.marketplaceCountry],
      currentCurrency
    );
    const marketing = useSelector((state) => get(state, "marketing"));
    const loading = useSelector((state) =>
      get(state, "marketing.adGroups.loading")
    );

    const reportDateFrom = useReportDateFrom();
    const reportDateTo = useReportDateTo();

    const dispatch = useDispatch();

    const columns = useMemo(
      () => [
        ...(!campaignId
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.campaignColumn"),
                id: "campaign_name",
                accessor: "campaign_name",
                isVisible: true,
              },
            ]
          : []),
        {
          Header: t("advertisingDashboardWidget.adTable.nameColumn"),
          id: "ad_group_name",
          accessor: (row) => ({
            value: row.ad_group_name,
            link: {
              pathname: campaignId
                ? `/mystores/${marketplace}/marketingCampaignAdGroupKeywords`
                : `/mystores/${marketplace}/marketingAdGroupKeywords`,
              search: `?store=${encodeURI(mid)}`,
              state: {
                campaignId: campaignId,
                campaignName: campaignName,
                adGroupId: row.ad_group_id,
                adGroupName: row.ad_group_name,
              },
            },
            subtitle: formatAdType(row.ad_type, t),
          }),
          Cell: (props) => <LinkCell {...props} />,
          isVisible: true,
          isLocked: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.statusColumn"),
          id: "ad_group_status",
          accessor: (row) => ({
            value: row.ad_group_status,
          }),
          Cell: (props) => <StatusCell {...props} />,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.defaultBidColumn"),
          id: "default_bid",
          accessor: (row) =>
            row.default_bid
              ? formatCurrency(
                  row.default_bid,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: false,
        },
        ...getPerformanceColumns(
          ENTITY_TYPE.adGroup,
          currencyRates,
          homeCurrency,
          currentCurrency,
          t
        ),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        currencyRates,
        homeCurrency,
        currentCurrency,
        campaignId,
        marketplace,
        mid,
        campaignName,
      ]
    );

    const [myColumns, setMyColumns] = useState(columns);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchMarketingAdGroups(
            userInfo._id,
            mid,
            countryCode,
            marketplace,
            marketplaceSubtype,
            searchText,
            reportDateFrom,
            reportDateTo,
            campaignId,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                  sortKey: "attributed_sales",
                  sortDesc: true,
                }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        userInfo._id,
        mid,
        countryCode,
        marketplace,
        marketplaceSubtype,
        searchText,
        reportDateFrom,
        reportDateTo,
        campaignId,
      ]
    );

    return (
      <Panel
        id="widget-marketing-adgroup-summary"
        title={t("advertisingDashboardWidget.adGroups.mainTitle")}
        content={
          <Table
            columns={myColumns}
            data={get(marketing, "adGroups.data", [])}
            fetchData={fetchData}
            loading={loading}
            sorting={true}
            pagination={true}
            pageCount={Math.ceil(
              get(marketing, "adGroups.count", 0) / PAGE_SIZE
            )}
            pageSize={PAGE_SIZE}
          />
        }
        actions={
          <>
            <SearchFilter setSearchText={setSearchText} />
            <ColumnSelect columns={myColumns} setColumns={setMyColumns} />
            <DownloadCsv
              mid={store.merchantId}
              reportType={"adGroup"}
              path={"/api/amazon/advertising/adGroup"}
              params={{
                campaignId,
                mid,
                countryCode,
                marketplaceType: store.marketplace,
                marketplaceSubtype: store.marketplaceSubtype,
                searchText,
                fromDate: reportDateFrom,
                toDate: reportDateTo,
                shopName: store.storeName,
                currentCurrency,
                exchangeRate,
              }}
            />
          </>
        }
      />
    );
  }
);

AdGroupSummaryPanel.propTypes = {
  marketplace: PropTypes.string.isRequired,
  mid: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  campaignId: PropTypes.string,
  campaignName: PropTypes.string,
};

export default AdGroupSummaryPanel;
