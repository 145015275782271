import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import InventoryStatusChart from "./inventoryStatusChart";
import { Panel } from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import has from "lodash/has";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useInventoryStatusQuery } from "~/store/mystore/inventoryReplenishment.redux";
import { useTranslation } from "react-i18next";

const ChartTitleTypography = styled(Typography)`
  display: inline-block;
  width: auto;
  min-width: 50%;
  text-align: center;
  margin-top: 10px;
`;

const StyledTypography = styled(Typography)`
  margin: 10px 15px;
`;

const ValueTypography = styled(Typography)`
  margin: 5px 15px 20px 15px;
  font-weight: bold;
`;

const NotAvailableTypography = styled(Typography)`
  margin-top: 20px;
`;

interface InventoryStatusProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
  report?: boolean;
}

const InventoryStatus = memo<InventoryStatusProps>(function InventoryStatus({
  mid,
  marketplaceCountry,
  marketplaceType,
  marketplaceSubtype,
  report,
}) {
  const { t } = useTranslation();
  const { inventoryStatus, isFetching } = useInventoryStatusQuery(
    {
      mid,
      marketplaceType,
      marketplaceCountry,
      marketplaceSubtype,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        inventoryStatus: data,
        isFetching,
      }),
    }
  );

  return (
    <Panel
      id="widget-inventory-status"
      title={
        report
          ? t("inventoryStatus.reportTitle")
          : t("inventoryStatus.panelTitle")
      }
      content={
        isFetching ? (
          <PanelLoading />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Box>
                <StyledTypography variant="body1">
                  {t(`inventoryStatus.daysCover`)}
                </StyledTypography>
                <ValueTypography variant="h4">
                  {inventoryStatus?.dailyUnits && inventoryStatus?.inventory
                    ? numberWithCommas(
                        Math.ceil(
                          inventoryStatus?.inventory /
                            inventoryStatus?.dailyUnits
                        )
                      )
                    : 0}
                </ValueTypography>
              </Box>
              <Box>
                <StyledTypography variant="body1">
                  {t(`inventoryStatus.inventoryUnits`)}
                </StyledTypography>
                <ValueTypography variant="h4">
                  {inventoryStatus?.inventory
                    ? numberWithCommas(inventoryStatus.inventory)
                    : "-"}
                </ValueTypography>
              </Box>
              <Box>
                <StyledTypography variant="body1">
                  {t(`inventoryStatus.productsOutOfStock`)}
                </StyledTypography>
                <ValueTypography variant="h4">
                  {inventoryStatus?.productsOutOfStock
                    ? numberWithCommas(inventoryStatus.productsOutOfStock)
                    : "-"}
                </ValueTypography>
              </Box>
              <Box>
                <StyledTypography variant="body1">
                  {t(`inventoryStatus.productsToReplen`)}
                </StyledTypography>
                <ValueTypography variant="h4">
                  {inventoryStatus?.productsToReplen
                    ? numberWithCommas(inventoryStatus.productsToReplen)
                    : "-"}
                </ValueTypography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ChartTitleTypography variant="h4">
                {t("inventoryStatusChart.mainTitle")}
              </ChartTitleTypography>
              {has(inventoryStatus, "inventoryAge90") ? (
                <InventoryStatusChart inventoryStatus={inventoryStatus} />
              ) : (
                <NotAvailableTypography variant="body1" align="center">
                  {t("inventoryStatus.unavailableText")}
                </NotAvailableTypography>
              )}
            </Grid>
          </Grid>
        )
      }
    />
  );
});

export default InventoryStatus;
