import * as Sentry from "@sentry/browser";

import axios from "axios";
import { baseUrl } from "~/configs/index";

export const hasMultipleAmazonMarketplaces = async (
  userId,
  sellerId,
  countryCode
) => {
  const inputParam = {
    amazonSellerId: sellerId,
    customerId: userId,
    countryCode: countryCode,
  };

  try {
    const options = {
      method: "GET",
      params: inputParam,
      url: `${baseUrl}/api/amazon-mws-service/api/sellerHealth/listOpenMarketplaces`,
    };
    const { data } = await axios(options);

    return data.length > 1;
  } catch (err) {
    Sentry.captureException(err);
  }

  return false;
};
