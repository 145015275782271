/* eslint-disable no-magic-numbers */
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";
import React, { memo } from "react";
import { getCurrencySymbol, intFormatterRounded } from "~/utils/currencyUtils";
import {
  getNearestHundredOrTen,
  getNearestHundredOrTenFloor,
} from "../chartUtils/chartUtils";

import BarChartNoData from "../../../components/placeholders/barChartNoData";
import ComboChart from "~/components/charts/comboChart/comboChart";
import ComboChartLegend from "~/components/charts/comboChart/ComboChartLegend";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

interface PlotProps {
  key: string;
  colour: string;
  toFixed?: number;
  notCurrency?: boolean;
  isInEndUnit?: boolean;
  type?: string;
}

export interface LineAndBarChartProps {
  title: string;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency?: string;
  chartData: any[];
  isLoading: boolean;
  line: PlotProps;
  line2?: PlotProps;
  bar: PlotProps;
  xKey: string;
  xKey2?: string;
  report?: boolean;
  lineCanContainNegativeValues?: boolean;
  lineUnit?: string;
  timezone: string;
  interval?: INTERVAL;
}

const Y_AXIS_WIDTH = 80;

const roundToTens = (num: number) => {
  return Math.sign(num) * (Math.ceil(Math.abs(num) / 10) * 10);
};

const LineAndBarChart = memo<LineAndBarChartProps>(function LineAndBarChart({
  title,
  currentPeriod,
  currentCurrency,
  chartData,
  isLoading,
  line,
  line2,
  bar,
  xKey,
  xKey2,
  report,
  lineCanContainNegativeValues,
  lineUnit,
  timezone,
  interval,
}) {
  const {
    key: lineKey,
    colour: lineColor,
    toFixed: lineToFixed,
    notCurrency: lineNotCurrency,
    isInEndUnit: lineIsInEndUnit,
    type: lineType,
  } = line;
  const {
    key: barKey,
    colour: barColor,
    toFixed: barToFixed,
    notCurrency: barNotCurrency,
  } = bar;
  const {
    key: line2Key,
    colour: line2Color,
    toFixed: line2ToFixed,
  } = line2 || {};
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const dataMin =
    chartData &&
    roundToTens(
      Math.min.apply(
        Math,
        chartData.map((o) => o[barKey]).concat(chartData.map((o) => o[lineKey]))
      )
    );
  const dataMaxBar =
    chartData &&
    getNearestHundredOrTen(
      Math.max.apply(
        Math,
        chartData.map((o) => o[barKey])
      )
    );
  const dataMaxLine =
    chartData && lineUnit === "%"
      ? 100
      : getNearestHundredOrTen(
          Math.max.apply(
            Math,
            chartData
              .map((o) => o[lineKey])
              .concat(line2Key ? chartData.map((o) => o[line2Key]) : [])
          )
        );
  const dataMinLine =
    lineUnit === "%"
      ? -100
      : lineCanContainNegativeValues
      ? chartData &&
        getNearestHundredOrTenFloor(
          Math.min.apply(
            Math,
            chartData
              .map((o) => o[lineKey])
              .concat(line2Key ? chartData.map((o) => o[line2Key]) : [])
          )
        )
      : 0;

  const dataMinTimestamp = chartData
    ? moment.min(chartData.map((o) => moment.unix(o[xKey])))
    : null;
  const dataMaxTimestamp = chartData
    ? moment.max(chartData.map((o) => moment.unix(o[xKey])))
    : null;
  const singleDayChart =
    dataMinTimestamp &&
    dataMaxTimestamp &&
    dataMinTimestamp.isSame(dataMaxTimestamp, "day");

  const getEvenTicks = (dataMin: number, dataMax: number, numTicks: number) => {
    const dataRange = dataMax - dataMin;
    const calcNumTicks =
      dataRange < 100 ? Math.ceil(dataRange / 10) + 1 : numTicks;
    const diff = dataRange < 100 ? 10 : roundToTens(dataRange / (numTicks - 1));
    const ticks = [dataMin];
    for (let i = 1; i < calcNumTicks; i++) {
      ticks.push(dataMin + diff * i);
    }
    return [...ticks];
  };

  const getScaledTicks = (ticks: number[]) => {
    const scale = Math.ceil(dataMaxBar / dataMaxLine);
    return ticks.map((tick) => tick * scale);
  };

  const rightTicks = lineCanContainNegativeValues
    ? getEvenTicks(dataMinLine, dataMaxLine, 5)
    : getEvenTicks(0, dataMaxLine, 5);
  const leftTicks = lineCanContainNegativeValues
    ? getScaledTicks(rightTicks)
    : getEvenTicks(0, dataMaxBar, 5);

  const getXAxisProps = () => {
    if (currentPeriod === DATETIME_PERIODS.WEEK) {
      const tickFormatter = (tick: number) =>
        moment.unix(tick).tz(timezone).format("ddd");
      return {
        dataKey: xKey,
        dy: 10,
        tickLine: false,
        tick: { fontSize: 14 },
        tickFormatter,
      };
    } else {
      const tickFormatter =
        interval === INTERVAL.MONTHS
          ? (tick: number) =>
              moment
                .unix(tick)
                .tz(timezone)
                .add(12, "h")
                .startOf("day")
                .format("MMM")
          : currentPeriod === DATETIME_PERIODS.DAY ||
            (interval === INTERVAL.HOURS && singleDayChart)
          ? (tick: number) => moment.unix(tick).tz(timezone).format("HH:mm")
          : interval === INTERVAL.HOURS
          ? (tick: number) =>
              moment.unix(tick).tz(timezone).format("DMMM HH:mm")
          : (tick: number) => moment.unix(tick).tz(timezone).format("D MMM");
      const tickCount = currentPeriod === DATETIME_PERIODS.DAY ? 13 : 3;
      return {
        dataKey: xKey,
        dy: 10,
        type: "category",
        interval: "preserveStartEnd",
        tickLine: false,
        tickCount: tickCount,
        domain: ["dataMin", "dataMax"],
        padding: "gap",
        tick: { fontSize: 14 },
        tickFormatter,
        minTickGap: 30,
      };
    }
  };

  const legendData = [
    {
      name: `chartKey.${barKey}`,
      fillColor: barColor,
      shape: "bar",
      unit:
        barKey.toLowerCase().includes("units") || barNotCurrency
          ? ""
          : currentCurrency
          ? getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol]
          : "",
      isInEndUnit: false,
      ...(barToFixed !== undefined ? { toFixed: barToFixed } : {}),
    },
    {
      name: `chartKey.${lineKey}`,
      fillColor: lineColor,
      shape: "line",
      unit:
        lineKey.toLowerCase().includes("units") || lineNotCurrency
          ? ""
          : currentCurrency
          ? getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol]
          : "",
      isInEndUnit: lineIsInEndUnit ?? true,
      ...(lineToFixed !== undefined ? { toFixed: lineToFixed } : {}),
    },
    ...(line2Key && line2Color
      ? [
          {
            name: `chartKey.${line2Key}`,
            fillColor: line2Color,
            shape: "line",
            unit: "",
            isInEndUnit: true,
            ...(line2ToFixed !== undefined ? { toFixed: line2ToFixed } : {}),
          },
        ]
      : []),
  ];

  const LegendComponent = () => <ComboChartLegend legendItems={legendData} />;

  const tooltipProps = {
    barTooltipProps: legendData[0],
    lineTooltipProps: legendData[1],
    ...(line2Key ? { line2TooltipProps: legendData[2] } : {}),
  };

  const yAxisLeftTickFormatter = (tick: number) => {
    if (barKey.toLowerCase().includes("units") || barNotCurrency) {
      return intFormatterRounded.format(tick);
    } else {
      return (
        (currentCurrency
          ? getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol]
          : "") + intFormatterRounded.format(tick)
      );
    }
  };

  const yAxisRightTickFormatter = (tick: number) => {
    if (lineKey.toLowerCase().includes("units") || lineNotCurrency) {
      return intFormatterRounded.format(tick) + (lineUnit ?? "");
    } else {
      return (
        (currentCurrency
          ? getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol]
          : "") + intFormatterRounded.format(tick)
      );
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <LoadingIndicator />
        ) : isEmpty(chartData) ? (
          <BarChartNoData {...{ currentPeriod }} />
        ) : (
          <ComboChart
            {...{
              chartData,
              interval,
              xAxisProps: getXAxisProps(),
              yAxisLeftProps: {
                tickFormatter: yAxisLeftTickFormatter,
                dataKey: barKey,
                tick: { fontSize: 14 },
                domain: [dataMin, dataMaxBar],
                ticks: leftTicks,
                width: Y_AXIS_WIDTH,
              },
              yAxisRightProps: {
                tickFormatter: yAxisRightTickFormatter,
                dataKey: lineKey,
                orientation: "right",
                domain: [
                  dataMin / Math.ceil(dataMaxBar / dataMaxLine),
                  dataMaxLine,
                ],
                tick: { fontSize: 14 },
                ticks: rightTicks,
              },
              barChartProps: {
                dataKey: barKey,
                fill: barColor,
                maxBarSize: 20,
              },
              lineChartProps: {
                type: lineType ?? "linear",
                dataKey: lineKey,
                stroke: lineColor,
                dot: false,
                strokeWidth: smDown ? 1 : 2,
              },
              lineChart2Props: {
                dataKey: line2Key,
                stroke: line2Color,
                dot: false,
                strokeWidth: smDown ? 1 : 2,
              },
              legendContent: LegendComponent,
              tooltipProps,
              currentPeriod,
              xKey,
              xKey2,
              barKey,
              lineKey,
              line2Key,
              report,
              timezone,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
});

export default LineAndBarChart;
