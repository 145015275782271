import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Prompt, PromptProps, useHistory } from "react-router-dom";
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  fetchReviewSettings,
  updateReviewSettings,
} from "../../../store/mystore/review.redux";

import Bold from "../../../components/typography/bold";
import DialogBox from "./reviewDialog";
import LoadingIndicator from "../../../components/loadingIndicator/loadingIndicator";
import Panel from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import SmallButton from "../../../components/buttons/smallButton";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MIN_DAYS = 5;
const MAX_DAYS = 30;

const DotPoint = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  height: 6px;
  width: 6px;
  margin-right: 6px;
  border-radius: 50%;
`;

const UppercaseLabel = styled(Typography)`
  text-transform: uppercase;
`;

const SelectLabel = styled(Typography)`
  margin-left: 10px;
`;

const CondensedGrid = styled(Grid)`
  margin-top: -12px;
  margin-bottom: -12px;
`;

interface ReviewAutomationProps {
  mid?: string;
}

const ReviewAutomation = memo<ReviewAutomationProps>(function ReviewAutomation({
  mid,
}) {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);
  const reviewSettings = useTypedSelector((state) => state.review.settings);

  const [isDirty, setIsDirty] = useState(false);
  const [validationError, setValidationError] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState(reviewSettings);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState({});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedOptions({
      ...updatedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { fetching, ...rest } = reviewSettings;
    const anyDirty = Object.keys(rest).some((settingsKey) => {
      const updatedOption = updatedOptions[settingsKey];
      const originalOption = reviewSettings[settingsKey];
      if (updatedOption === undefined) {
        return false;
      }
      if (updatedOption === originalOption) {
        return false;
      }
      return true;
    });
    setIsDirty(anyDirty);
    if (!anyDirty) {
      setIsUpdating(false);
    }
  }, [userInfo, mid, reviewSettings, updatedOptions]);

  const history = useHistory();
  const dispatch = useDispatch();

  const dispatchFetchReviewAutomationSettings = useCallback(() => {
    dispatch(fetchReviewSettings({ user: userInfo, mid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, userInfo]);

  const dispatchUpdateReviewAutomationSettings = useCallback(() => {
    // eslint-disable-next-line no-unused-vars
    const { fetching, ...settings } = updatedOptions;
    dispatch(updateReviewSettings({ user: userInfo, mid, settings, t }));
    setIsUpdating(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, updatedOptions, userInfo]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchReviewAutomationSettings();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, mid]);

  useEffect(() => {
    setUpdatedOptions(reviewSettings);
  }, [reviewSettings, reviewSettings.automationOn]);

  const handleBlockedNavigation: PromptProps["message"] = (_nextLocation) => {
    if (!dialogOpen) {
      setDialogOpen(true);
      setNextLocation(_nextLocation);
      return false;
    } else {
      return true;
    }
  };

  const saveDateRange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (value < MIN_DAYS) {
      return setValidationError("reviewAutomation.error.tooLow");
    }
    if (value > MAX_DAYS) {
      return setValidationError("reviewAutomation.error.tooHigh");
    } else {
      setValidationError("");
      return setUpdatedOptions({
        ...updatedOptions,
        minDays: value,
      });
    }
  };

  const renderOptions = () => {
    const menuItems = [];
    for (let i = MIN_DAYS; i < MAX_DAYS; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return menuItems;
  };

  return (
    <Panel
      id="widget-review-summary"
      title={t(`myStoresWidget.reviewAutomation`)}
      tooltip={undefined}
      footer={
        isDirty ? (
          <SmallButton
            id="save-review-settings"
            name="saveApplyReviewSettings"
            color="info"
            disabled={isUpdating}
            onClick={dispatchUpdateReviewAutomationSettings}
          >
            {isUpdating ? (
              <LoadingIndicator size={20} />
            ) : (
              t("reviewAutomation.saveAndApply")
            )}
          </SmallButton>
        ) : undefined
      }
      actions={
        updatedOptions.automationOn !== undefined &&
        !updatedOptions.fetching ? (
          <UppercaseLabel color="textSecondary">
            <CondensedGrid container alignItems="center" spacing={1}>
              <Grid item>{t(`switch.off`)}</Grid>
              <Grid item>
                <Switch
                  color="primary"
                  checked={updatedOptions.automationOn}
                  onChange={handleChange}
                  name="automationOn"
                />
              </Grid>
              <Grid item>{t(`switch.on`)}</Grid>
            </CondensedGrid>
          </UppercaseLabel>
        ) : undefined
      }
      content={
        <Box p={2}>
          {(updatedOptions.automationOn !== undefined &&
            reviewSettings.fetching) ||
          updatedOptions.fetching ? (
            <PanelLoading />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Box display="flex" pl={1} flexDirection="column">
                  <Bold variant="body2">
                    {t(`reviewAutomation.templateInfo`)}
                  </Bold>
                  <Box display="flex" pt={2} pl={1} alignItems="center">
                    <DotPoint />
                    <Typography variant="body2">
                      {t(`reviewAutomation.dotPoint1`)}
                    </Typography>
                  </Box>
                  <Box display="flex" pt={2} pl={1} alignItems="center">
                    <DotPoint />
                    <Typography variant="body2">
                      {t(`reviewAutomation.dotPoint2`)}
                    </Typography>
                  </Box>
                  <Box display="flex" pt={2} pb={2} pl={1} alignItems="center">
                    <DotPoint />
                    <Typography variant="body2">
                      {t(`reviewAutomation.dotPoint3`)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" pt={2} pl={1}>
                  <Bold variant="body2">
                    {t(`reviewAutomation.selectDateInfo`)}
                  </Bold>
                  <Box display="flex" pt={2} pl={1} alignItems="center">
                    <TextField
                      select
                      type="number"
                      value={updatedOptions.minDays}
                      onChange={saveDateRange}
                    >
                      {renderOptions()}
                    </TextField>
                    <SelectLabel variant="body2">
                      {t(`reviewAutomation.selectDateLabel`)}
                    </SelectLabel>
                  </Box>
                  {validationError && (
                    <Box display="flex" pt={1} pl={1} alignItems="center">
                      <Typography variant="body2" color="error">
                        {t(validationError)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="column">
                  <Bold variant="body2">
                    {t(`reviewAutomation.exclusionTitle`)}
                  </Bold>
                  {/* Hide the "Orders with refunds" toggle for now, until we
                      manage to set the "is_refunded" flag on Amazon orders.
                      This probably needs to be done as part of profitability
                      aggregation. */}
                  {/* <Box display="flex" pt={2} alignItems="flex-start">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={updatedOptions.excludeRefunds}
                          onChange={handleChange}
                          name="excludeRefunds"
                          disabled={!updatedOptions.automationOn}
                        />
                      }
                      label={t(`reviewAutomation.exclusion1`)}
                      labelPlacement="start"
                    />
                  </Box> */}
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={updatedOptions.excludeFBM}
                          onChange={handleChange}
                          name="excludeFBM"
                          disabled={!updatedOptions.automationOn}
                        />
                      }
                      label={t(`reviewAutomation.exclusion2`)}
                      labelPlacement="start"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={updatedOptions.excludeFBA}
                          onChange={handleChange}
                          name="excludeFBA"
                          disabled={!updatedOptions.automationOn}
                        />
                      }
                      label={t(`reviewAutomation.exclusion4`)}
                      labelPlacement="start"
                    />
                  </Box>
                  <Box display="flex" pb={1} alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={updatedOptions.excludeInternational}
                          onChange={handleChange}
                          name="excludeInternational"
                          disabled={!updatedOptions.automationOn}
                        />
                      }
                      label={t(`reviewAutomation.exclusion3`)}
                      labelPlacement="start"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          <Prompt when={isDirty} message={handleBlockedNavigation} />
          <DialogBox
            open={dialogOpen}
            title={t("generic.warningTitle")}
            content={t("myStoresWidget.reviewManagement.leavePageMessage")}
            onClose={() => {
              setDialogOpen(false);
            }}
            handleContinue={() => {
              setDialogOpen(false);
              history.push(nextLocation);
            }}
            handleSaveSettings={() => {
              setDialogOpen(false);
              dispatchUpdateReviewAutomationSettings();
              history.push(nextLocation);
            }}
          />
        </Box>
      }
    />
  );
});

export default ReviewAutomation;
