import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { IconButton, Paper, Typography } from "@material-ui/core";
import { Palette, PaletteColor } from "@material-ui/core/styles/createPalette";
import React, { FC } from "react";

import { Close } from "@material-ui/icons";
import { ItemTypes } from "./itemTypes";
import { Option } from "./dragAndDrop";
import styled from "styled-components";
import { useRef } from "react";

const DragCard = styled(Paper)<{ $borderColor: keyof Palette }>`
  padding: 0.5rem;
  border-left: 5px solid
    ${({ theme, $borderColor }) =>
      (theme.palette[$borderColor || "primary"] as PaletteColor).main};
  display: flex;
  align-self: stretch;
  margin-bottom: 0.5rem;
  cursor: grab;
  width: calc(90% - 1rem);
  align-items: center;
  justify-content: space-between;
`;

export interface CardProps extends Option {
  id: string;
  text: string;
  index: number;
  sortCard: (dragId: string, hoverId: string) => void;
  dropped?: boolean;
  color: keyof Palette;
  onRemove?: (id: string) => void;
  extraConfiguration?: FC;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
  dropped?: boolean;
  color: keyof Palette;
}

export const DraggableCard = ({
  id,
  text,
  index,
  dropped,
  color,
  extraConfiguration,
  sortCard,
  onRemove,
  ...otherOptionProps
}: CardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragId = item.id;
      const hoverId = id;

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      // Time to actually perform the action
      sortCard(dragId, hoverId);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {
        id,
        index,
        text,
        dropped,
        color,
        extraConfiguration,
        ...otherOptionProps,
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <DragCard
      elevation={1}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      $borderColor={color}
    >
      <Typography variant="body1">{text}</Typography>
      {onRemove && (
        <IconButton
          size="small"
          aria-label="remove item"
          onClick={() => onRemove(id)}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
    </DragCard>
  );
};
