import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { InlineIconButton } from "~/icons/inlineIconButton";
import PanelSelect from "~/components/select/panelSelect";
import { useTranslation } from "react-i18next";

interface PageSizeDropDownProps {
  setPageSize: (pageSize: number) => void;
  pageSize: number;
}

const PageSizeDropdown = memo(
  ({ setPageSize, pageSize }: PageSizeDropDownProps) => {
    const { t } = useTranslation();

    const PAGE_SIZE = [
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 25, label: "25" },
      { value: 50, label: "50" },
      { value: 100, label: "100" },
    ];

    const handleClick = (pageSize: number) => {
      setPageSize(pageSize);
    };

    return (
      <Box maxWidth="fit-content">
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {t("generic.tableSize")}
          </Typography>
          <Tooltip title={t("generic.tableSizeTooltip") as string}>
            <InlineIconButton />
          </Tooltip>
        </Box>
        <PanelSelect
          {...{
            width: "65px",
            height: "38px",
            currentValue: PAGE_SIZE.find((option) => option.value === pageSize)
              ?.label,
            handleChange: handleClick,
            options: PAGE_SIZE,
          }}
        />
      </Box>
    );
  }
);

export default PageSizeDropdown;
