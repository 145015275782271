import { Box, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import InventoryHealthTable, {
  InventoryOption,
  TABLE_TYPE,
} from "~/modules/vendor/inventoryHealthTable";
import React, { memo, useMemo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import InventoryHealthTotals from "~/modules/vendor/inventoryHealthSummary";
import InventoryHealthTrend from "~/modules/vendor/inventoryHealthTrend";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface InventoryHealthProps {
  singleWidget?: "table" | "summary" | "trend";
}

const InventoryHealth = memo(({ singleWidget }: InventoryHealthProps) => {
  const { t } = useTranslation();

  const userInfo = useTypedSelector((state) => state.user);
  const INVENTORY_OPTIONS: InventoryOption[] = useMemo(
    () => [
      {
        value: TABLE_TYPE.Units,
        label: t("inventoryHealth.unitsOption"),
      },
      {
        value: TABLE_TYPE.Cogs,
        label: t("inventoryHealth.cogsOption"),
      },
    ],
    []
  );

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const user = useTypedSelector((state) => state.user);

  const [chartType, setChartType] = useState<InventoryOption>(
    INVENTORY_OPTIONS[0]
  );
  const [searchText, setSearchText] = useState("");
  const [tableType, setTableType] = useState<InventoryOption>(
    INVENTORY_OPTIONS[0]
  );

  const switchTableType = (value: string) => {
    const option = INVENTORY_OPTIONS.find(
      (tableOption) => tableOption.value === value
    );
    if (option) {
      setTableType(option);
    }
  };

  const switchChartType = (value: string) => {
    const option = INVENTORY_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const chartActions = (
    <TableFilter
      {...{
        width: "165px",
        options: INVENTORY_OPTIONS,
        currentValue: chartType.label,
        handleChange: switchChartType,
      }}
    />
  );

  const tableFilter = (
    <TableFilter
      {...{
        width: "165px",
        options: INVENTORY_OPTIONS,
        currentValue: tableType.label,
        handleChange: switchTableType,
      }}
    />
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const tableActions = (
    <>
      <Box pl={2}>{tableFilter}</Box>
      <Box pl={2}>
        <SearchFilter setSearchText={setSearchText} />
      </Box>
      <DownloadCsv
        {...{
          reportType: "inventoryHealth",
          path: "/api/generic/vendor/inventoryHealth",
          mid: store.merchantId,
          params: {
            ...currentRange,
            sortKey: "sellable_inventory_units",
            sortOrder: "desc",
            searchText,
            currentCurrency,
            exchangeRate: getExchangeRate(
              currencyRates,
              getCurrencyByCountryCode[store.marketplaceCountry],
              currentCurrency
            ),
          },
        }}
      />
    </>
  );

  if (singleWidget) {
    switch (singleWidget) {
      case "trend":
        return (
          <InventoryHealthTrend
            userInfo={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            chartType={chartType}
            actions={chartActions}
            view={chartType.value as "units" | "cogs"}
            condensed={true}
            timezone={selectedTimezone}
          />
        );
      case "summary":
        return (
          <InventoryHealthTotals
            userInfo={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentCompare={currentCompare}
          />
        );
      case "table":
        return (
          <InventoryHealthTable
            user={user}
            mid={store.merchantId}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            searchText={searchText}
            tableType={tableType}
            actions={tableFilter}
            pageSize={5}
            condensed={true}
          />
        );
      default:
        return (
          <InventoryHealthTable
            user={user}
            mid={store.merchantId}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            searchText={searchText}
            tableType={tableType}
            actions={tableFilter}
            pageSize={5}
            condensed={true}
          />
        );
    }
  } else {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <InventoryHealthTrend
              userInfo={userInfo}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              chartType={chartType}
              actions={chartActions}
              view={chartType.value as "units" | "cogs"}
              timezone={selectedTimezone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InventoryHealthTotals
              userInfo={userInfo}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentCompare={currentCompare}
            />
          </Grid>
          <Grid item xs={12}>
            <InventoryHealthTable
              user={user}
              mid={store.merchantId}
              currentCurrency={currentCurrency}
              currentRange={currentRange}
              searchText={searchText}
              tableType={tableType}
              actions={tableActions}
              pageSize={PAGE_SIZE}
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
});

export default InventoryHealth;
