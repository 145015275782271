import _ from "lodash";
import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";

export const LOAD_ALL_RECOMMEDATIONS = "LOAD_ALL_RECOMMEDATIONS";
export const LOAD_CATEGORY_RECOMMEDATIONS = "LOAD_CATEGORY_RECOMMEDATIONS";
export const CREATE_IS_FILE = "CREATE_IS_FILE";
export const SYNC_ITEM_SPECIFICS = "SYNC_ITEM_SPECIFICS";
export const SYNC_ITEM_SPECIFICS_ERROR = "SYNC_ITEM_SPECIFICS_ERROR";

axios.defaults.withCredentials = true;
const initState = [];

export const recommendations = (state = initState, action) => {
  switch (action.type) {
    case LOAD_ALL_RECOMMEDATIONS:
      const newState = Object.assign([], action.payload);
      return newState;
    case LOAD_CATEGORY_RECOMMEDATIONS:
      const copyState = Object.assign([], state);
      const categoryRecommendations = action.payload;
      const updatedState = copyState.filter(
        (r) => r.categoryId !== categoryRecommendations.categoryId
      );
      updatedState.push(categoryRecommendations);
      return updatedState;
    case CREATE_IS_FILE:
      return state;
    default:
      return state;
  }
};

export const syncedItems = (
  state = { successfulIds: [], failedIds: [] },
  action
) => {
  switch (action.type) {
    case SYNC_ITEM_SPECIFICS:
      return _.cloneDeep(action.payload);

    case SYNC_ITEM_SPECIFICS_ERROR:
      return { state, error: action.payload };
    default:
      return state;
  }
};

export const loadRecommendations =
  (
    flag,
    mid,
    countryCode,
    categoryId,
    ebaySiteId,
    pageNum = 0,
    /* eslint-disable-next-line no-magic-numbers */
    pageSize = 10
  ) =>
  (dispatch) =>
    // eslint-disable-next-line consistent-return
    new Promise(async (resolve, reject) => {
      if (flag === "mid" && mid) {
        try {
          const options = {
            method: "POST",
            url: `${baseUrl}/api/recommendations`,
            data: { mid, countryCode, pageNum, pageSize },
          };
          const res = await axios(options);
          if (res) {
            if (isHttpResponseValid(res.status) && res.data) {
              dispatch({
                type: "LOAD_ALL_RECOMMEDATIONS",
                payload: res.data.data || [],
              });

              return resolve("success");
            } else {
              return setError(
                dispatch,
                res.data.errMsg,
                res.status,
                "loadRecommendations"
              );
            }
          }
          return setError(dispatch);
        } catch (e) {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "loadRecommendations"
          );
          return reject("error");
        }
      } else if (flag === "categoryId" && mid && categoryId && ebaySiteId) {
        try {
          const options = {
            method: "POST",
            url: `${baseUrl}/api/recommendations`,
            data: {
              mid,
              countryCode,
              categoryId,
              ebaySiteId,
              pageNum,
              pageSize,
            },
          };
          const res = await axios(options);
          if (res) {
            if (isHttpResponseValid(res.status) && res.data) {
              const [categoryRecommendations] = _.get(res, "data.data", [{}]);

              dispatch({
                type: "LOAD_CATEGORY_RECOMMEDATIONS",
                payload: categoryRecommendations,
              });
              return resolve("success");
            } else {
              return setError(
                dispatch,
                res.data.errMsg,
                res.status,
                "loadRecommendations"
              );
            }
          }
        } catch (e) {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          return setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "loadRecommendations"
          );
        }
      }
    });

export function createItemSpecificsFile(
  mid,
  countryCode,
  categoryInfo,
  modifiedItemSpecifics
) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/api/recommendation/createisfile`, {
          mid,
          countryCode,
          categoryInfo,
          modifiedItemSpecifics,
        })
        .then((res) => {
          if (res) {
            if (isHttpResponseValid(res.status) && res.data.fileName) {
              dispatch({ type: CREATE_IS_FILE, payload: res.data.fileName });
              resolve(res.data.fileName);
            } else {
              setError(dispatch, res.data.errMsg, res.status, "createisfile");
              reject(res.data.errMsg);
            }
          }
        })
        .catch((e) => {
          const msg = get(e, "data.error");
          const statusText = get(e, "statusText");
          return setError(
            dispatch,
            msg || statusText,
            get(e, "status"),
            "createisfile"
          );
        });
    });
}

export function syncItemSpecifics(userId, mid, countryCode, itemsData) {
  return async (dispatch) => {
    let res;

    try {
      res = await axios.post(`${baseUrl}/api/recommendation/syncitems`, {
        userId,
        mid,
        countryCode,
        itemsData,
      });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          const payload = _.get(res, "data.syncItems", null);

          if (!payload) {
            return dispatch({
              type: SYNC_ITEM_SPECIFICS_ERROR,
              payload: "Response did not contain synced item data",
            });
          }
          return dispatch({ type: SYNC_ITEM_SPECIFICS, payload: payload });
        } else {
          setError(dispatch, res.data.message, res.status, "syncitems");
          throw new Error();
        }
      }
      return setError(dispatch);
    } catch (err) {
      // unknown error on server-side, check server logs
      return dispatch({
        type: SYNC_ITEM_SPECIFICS_ERROR,
        payload: _.get(
          res,
          "err.data.errMsg",
          "Unknown server error while syncing items"
        ),
      });
    }
  };
}
