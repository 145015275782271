import { Grid, withTheme } from "@material-ui/core";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import BackgroundImage from "../../img/backgrounds/home-background.jpg";
import PageBlock from "./pageBlock";
import React from "react";
import { useTypedSelector } from "~/hooks/useTypedSelector";

type BackgroundImageContainerProps = ThemeProps<DefaultTheme> & {
  $backgroundImage: string;
  $useImage: boolean;
};

const BackgroundImageContainer = styled.div`
  ${({ theme }) =>
    theme?.palette?.backgroundColor &&
    `background-color: ${theme.palette.backgroundColor};`};
  ${({ $backgroundImage, $useImage }: BackgroundImageContainerProps) =>
    $useImage &&
    $backgroundImage &&
    `background-image: url(${$backgroundImage});`}
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Section = withTheme(styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
`);

const Padding = styled.div`
  margin-top: 64px;
`;

const AuthPageBlock = ({ children }: { children: any }) => {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  return (
    <BackgroundImageContainer
      $backgroundImage={BackgroundImage}
      $useImage={customOverrides?.agency === "ms"}
    >
      <PageBlock $transparent={true}>
        <Grid container justifyContent="center">
          <Grid container item xs={10} sm={6} md={5} lg={3} alignItems="center">
            {React.Children.map(children, (child) => (
              <Section>{child}</Section>
            ))}
          </Grid>
        </Grid>
        {/** padding because the intercom button might hide some elements on mobile */}
        <Padding />
      </PageBlock>
    </BackgroundImageContainer>
  );
};

export default AuthPageBlock;
