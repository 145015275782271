import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  CircularProgress,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  fetchJobStatus,
  fetchRecentJob,
  generateReport,
} from "~/store/reportDownload.redux";

import { DownloadCSVProps } from "./downloadCsv";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledBaseDialogTitle = styled(BaseDialogTitle)`
  flex-grow: 0;
`;

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const SecondaryLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
    `}
`;

type DownloadCSVDialogProps = DownloadCSVProps & {
  open: boolean;
  onClose: () => void;
};

const DownloadDialog = ({
  open,
  onClose,
  mid,
  reportType,
  path,
  params,
}: DownloadCSVDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recentJob = useTypedSelector((state) =>
    get(state.reportDownload, "recentJob")
  );
  const newJob = useTypedSelector((state) =>
    get(state.reportDownload, "newJob")
  );

  // states for this dialog: init -> loaded -> reportRequested -> reportGenerated
  const DialogState = {
    INIT: "init",
    LOADED: "loaded",
    REPORT_REQUESTED: "reportRequested",
    REPORT_GENERATED: "reportGenerated",
  };
  const [dialogState, setDialogState] = useState(DialogState.INIT);

  // refresh recent jobs when dialog is first opened
  useEffect(() => {
    if (open && dialogState === DialogState.INIT) {
      dispatch(fetchRecentJob(mid, reportType));
      setDialogState(DialogState.LOADED);
    }
  }, [open, dialogState]);

  // auto-generate report if there isn't any recent jobs
  useEffect(() => {
    if (open && dialogState === DialogState.LOADED) {
      if (!recentJob.fetching) {
        if (isEmpty(recentJob.data)) {
          requestReport();
        }
      }
    }
  }, [open, dialogState, recentJob.fetching]);

  useEffect(() => {
    if (open && dialogState === DialogState.REPORT_REQUESTED) {
      if (!newJob.fetching && newJob.data.jobId) {
        const timer = setInterval(() => {
          if (newJob.data.state === "done") {
            setDialogState(DialogState.REPORT_GENERATED);
          } else {
            dispatch(fetchJobStatus(mid, newJob.data.jobId));
          }
        }, 2000);
        return () => clearInterval(timer);
      }
    }
  }, [open, dialogState, newJob.fetching]);

  const requestReport = () => {
    dispatch(generateReport(mid, path, params));
    setDialogState(DialogState.REPORT_REQUESTED);
  };

  const handleClose = () => {
    setDialogState(DialogState.INIT);
    onClose();
  };

  return (
    <BaseDialog maxWidth="md" open={open} onClose={handleClose}>
      <StyledBaseDialogTitle onClose={handleClose}>
        {t("reportDownload.dialog.mainTitle")}
      </StyledBaseDialogTitle>
      <DialogContent>
        {/* if we are currently retrieving the most recent job */}
        {(dialogState === DialogState.INIT || recentJob.fetching) && (
          <CircularProgress />
        )}

        {/* if user has a usable recent job */}
        {dialogState === DialogState.LOADED && recentJob.data.downloadUrl && (
          <DialogContentGrid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                <SecondaryLink href={recentJob.data.downloadUrl}>
                  {t("reportDownload.dialog.downloadLink")}
                </SecondaryLink>{" "}
                {t("reportDownload.dialog.recentlyGeneratedReportText", {
                  time: moment(recentJob.data.finishDate).format("h:mm a"),
                })}
              </DialogContentText>
              <DialogContentText>{t("generic.orText")}</DialogContentText>
              <DialogContentText>
                <SecondaryLink onClick={requestReport}>
                  {t("reportDownload.dialog.generateLink")}
                </SecondaryLink>{" "}
                {t("reportDownload.dialog.newReportText")}
              </DialogContentText>
            </Grid>
          </DialogContentGrid>
        )}

        {/* if report is being generated */}
        {dialogState === DialogState.REPORT_REQUESTED && (
          <DialogContentGrid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                {t("reportDownload.dialog.generatingText")}
              </DialogContentText>
              <DialogContentText>
                {t("reportDownload.dialog.doNotCloseText")}
              </DialogContentText>
              <CircularProgress />
              {newJob.data && newJob.data.progress && (
                <DialogContentText>
                  {newJob.data.progress.completedCount}/
                  {newJob.data.progress.totalCount}
                </DialogContentText>
              )}
            </Grid>
          </DialogContentGrid>
        )}

        {/* if a new report has finished being generated */}
        {dialogState === DialogState.REPORT_GENERATED && (
          <DialogContentGrid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                {t("reportDownload.dialog.finishedText")}
              </DialogContentText>
              <DialogContentText>
                <SecondaryLink href={newJob.data.downloadUrl}>
                  {t("reportDownload.dialog.downloadLink")}
                </SecondaryLink>{" "}
              </DialogContentText>
            </Grid>
          </DialogContentGrid>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default DownloadDialog;
