/* eslint-disable no-use-before-define */
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FilterTagsParentContainer = styled(Box)`
  width: ${({ width }) => width}px;
  padding: 0 12px;
`;

const FilterTagsWrapper = styled.div`
  margin: 5px;
`;

const Tag = styled(Chip)`
  display: flex;
  align-items: center;
  height: 22px;
  margin: 2px;
  line-height: 22px;
  max-width: 100px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

const TagWrapper = styled(Grid)`
  max-height: 180px;
  max-width: 220px;
  overflow: auto;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: 0 5px;
  margin: 0;
  width: 15px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

const TagsTypography = styled(Typography)`
  margin: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.dark};
`;

const LinkWrapper = styled.div`
  margin: 5px 5px 10px;
`;

const TagsContainer = ({
  selectedOptions,
  options,
  noOptionsText,
  isWithSuggestion,
  handleOptionChange,
  isLoading,
  menuWidth,
  forSettingPage,
}) => {
  const listOfTags = isEmpty(options) ? selectedOptions : options;
  const updatedValue = useRef(listOfTags);
  const [currentValue, setCurrentValue] = useState(listOfTags);
  const { t } = useTranslation();
  const theme = useTheme();
  const placeholder =
    isWithSuggestion && !forSettingPage
      ? t("filters.searchForTags")
      : t("filters.typeTags");

  const generateTags =
    !isEmpty(currentValue) &&
    currentValue.map((option, index) => {
      // This is to handle new options added by the user (allowed by freeSolo prop).
      const label = option;

      const onDelete = () => {
        const uponDeletedValue = currentValue.filter(
          (entry) => entry !== option
        );

        setCurrentValue(uponDeletedValue);
        updatedValue.current = updatedValue.current.filter(
          (entry) => entry !== option
        );

        handleOptionChange(
          isWithSuggestion ? uponDeletedValue : updatedValue.current
        );
      };

      return (
        <Tag
          key={label + index}
          label={label}
          deleteIcon={<CloseIcon fontSize="small" />}
          onDelete={onDelete}
          theme={theme}
        />
      );
    });

  const updateCurrentValue = useCallback(
    (val) => {
      updatedValue.current = [...updatedValue.current, val];
      handleOptionChange(updatedValue.current);
    },
    [handleOptionChange]
  );

  const handleOnChange = (e, newValue) => {
    e.preventDefault();
    const value = e.target.value;
    setCurrentValue(newValue);
    if (isWithSuggestion) {
      handleOptionChange(newValue);
    } else {
      updateCurrentValue(value);
    }
  };

  const endAdornment = (params) => {
    if (isWithSuggestion) {
      return {
        ...params.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    setCurrentValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <FilterTagsParentContainer width={menuWidth}>
      <FilterTagsWrapper>
        <Autocomplete
          loading={isLoading}
          multiple
          id="custom-tags"
          data-cy="custom-tags"
          freeSolo={forSettingPage}
          filterSelectedOptions
          autoComplete={isWithSuggestion}
          options={listOfTags}
          onChange={(e, newValue) => handleOnChange(e, newValue)}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, val) => option === val}
          noOptionsText={noOptionsText}
          disableClearable
          renderTags={() => {}}
          value={currentValue}
          forcePopupIcon={false}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              margin="normal"
              fullWidth
              InputProps={endAdornment(params)}
            />
          )}
        />
        <TagWrapper container>
          {isLoading ? <CircularProgress size={30} /> : generateTags}
        </TagWrapper>
      </FilterTagsWrapper>
      {isWithSuggestion &&
        !forSettingPage &&
        !isLoading &&
        isEmpty(listOfTags) && (
          <TagsTypography theme={theme} variant="body2">
            {t("filters.noTagsAdded")}
          </TagsTypography>
        )}
      {isWithSuggestion && !forSettingPage && (
        <LinkWrapper>
          <Link
            component={RouterLink}
            to={{
              pathname: "/setting",
              search: "?settingTab=marketplaces",
            }}
            variant="body2"
            noWrap
          >
            {t("filters.addTags")}
          </Link>
        </LinkWrapper>
      )}
    </FilterTagsParentContainer>
  );
};

TagsContainer.defaultProps = {
  selectedOptions: [],
  options: [],
  noOptionsText: "",
  isWithSuggestion: false,
};

TagsContainer.propTypes = {
  selectedOptions: PropTypes.array,
  options: PropTypes.array,
  noOptionsText: PropTypes.node,
  isWithSuggestion: PropTypes.bool,
  disableClearable: PropTypes.bool,
  handleOptionChange: PropTypes.func,
  isLoading: PropTypes.bool,
  menuWidth: PropTypes.number,
};

export default TagsContainer;
