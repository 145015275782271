import {
  Box,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  MenuList,
  MenuListProps,
  Tab,
  Typography,
} from "@material-ui/core";
import React, { memo } from "react";
import { get, isUndefined } from "lodash";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Description from "@material-ui/icons/Description";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import { HelpRounded } from "@material-ui/icons";
import { LinkRef } from "../../links/link";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Money from "@material-ui/icons/MonetizationOnRounded";
import Person from "@material-ui/icons/PersonRounded";
import School from "@material-ui/icons/School";
import { User } from "~/typedef/user";
import { getFriendlyShortUserName } from "~/store/utils/displayUtils";
import { useAgencySpecificConfig } from "~/hooks/useAgencySpecificConfig";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const AccountImage = styled.img`
  display: flex;
  margin: 0 0.5rem 0 0;
  max-height: 35px;
  border-radius: 50%;
`;

const AccountIcon = styled(AccountCircle)`
  display: flex;
  margin: 0 0.5rem 0 0;
  max-height: 35px;
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

const ArrowIcon = styled(ArrowDropDown)`
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

type SubMenuProps = MenuListProps &
  ThemeProps<DefaultTheme> & { $divider?: boolean };
const SubMenu = styled(MenuList)`
  border-bottom: ${({ theme, $divider }: SubMenuProps) =>
    $divider ? `1px solid ${theme.palette.border.main}` : "none"};
`;

const SmallTab = styled(Tab)`
  min-width: 0px;
`;

interface ProfileTabProps {
  user: User;
  logout: () => void;
}

export const ProfileTab = memo<ProfileTabProps>(function ProfileTab({
  user,
  logout,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { hideHelpCenter } = useAgencySpecificConfig();
  const hideHelpCentreLink = user?.roleSettings?.hideHelpCenter; // Assuming the default for this is false (undefined)

  const { currentSubscription } = useTypedSelector(
    (state) => state.subscriptions || {}
  );

  const showHelpCentre = !hideHelpCenter && !hideHelpCentreLink;

  const isExternalUser = user?.role === "external";
  const isActiveSubscription = currentSubscription?.status === "Active";
  const isLegacySubscription =
    currentSubscription?.internalSubscriptionStatus === "LEGACY";

  const ProfileTabLabel = () => (
    <>
      <Hidden smDown>
        <Box mr={1}>
          <Typography variant="h6" display="inline">
            {getFriendlyShortUserName(user) ?? t("profile.myAccount")}
          </Typography>
        </Box>
      </Hidden>
      {user.imgUrl ? (
        <AccountImage src={user.imgUrl} id="avatar" />
      ) : (
        <AccountIcon id="avatar" />
      )}
      <ArrowIcon fontSize="small" />
    </>
  );

  const ProfileMenu = () => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      autoFocus={false}
      marginThreshold={1}
    >
      <SubMenu $divider={true}>
        <MenuItem>
          <StartIcon>
            <AccountCircle />
          </StartIcon>
          <Grid container direction="column">
            {user.firstName && (
              <Typography color="textSecondary" variant="h3" role="name">
                {user.firstName} {user.lastName}
              </Typography>
            )}
            <Typography color="textSecondary" variant="subtitle1" role="name">
              {user.email}
            </Typography>
          </Grid>
        </MenuItem>
      </SubMenu>
      {!user.isDemoMode && (
        <SubMenu $divider={true}>
          <MenuItem component={LinkRef} to={"/setting"}>
            <StartIcon>
              <Person fontSize="small" />
            </StartIcon>
            <Typography color="textPrimary" variant="button">
              {t("profile.myAccount")}
            </Typography>
          </MenuItem>
          {!get(user, "hideBilling") &&
            !get(user, "roleSettings.hideBilling") && (
              <MenuItem component={LinkRef} to={"/subscription/plans"}>
                <StartIcon>
                  <Money fontSize="small" />
                </StartIcon>
                <Typography color="textPrimary" variant="button">
                  {t("profile.subscription")}
                </Typography>
              </MenuItem>
            )}
        </SubMenu>
      )}
      {(showHelpCentre || !isExternalUser) && (
        <SubMenu $divider={true}>
          {showHelpCentre && (
            <MenuItem
              component="a"
              target="_blank"
              href="https://help.merchantspring.io/en"
            >
              <StartIcon>
                <HelpRounded fontSize="small" />
              </StartIcon>
              <Typography color="textPrimary" variant="button">
                {t("profile.helpCenter")}
              </Typography>
            </MenuItem>
          )}
          {!isExternalUser && [
            <MenuItem
              key="msAcademy"
              component="a"
              target="_blank"
              href="https://academy.merchantspring.io/"
            >
              <StartIcon>
                <School fontSize="small" />
              </StartIcon>
              <Typography color="textPrimary" variant="button">
                {t("profile.msAcademy")}
              </Typography>
            </MenuItem>,
            <MenuItem
              key="releaseNotes"
              component="a"
              target="_blank"
              href="https://merchantspring.io/release-notes/"
            >
              <StartIcon>
                <Description fontSize="small" />
              </StartIcon>
              <Typography color="textPrimary" variant="button">
                {t("profile.releaseNotes")}
              </Typography>
            </MenuItem>,
          ]}
        </SubMenu>
      )}
      {!isExternalUser && isActiveSubscription && !isLegacySubscription && (
        <SubMenu $divider={true}>
          <MenuItem
            component="a"
            target="_blank"
            href="https://merchantspring.firstpromoter.com/signup/8350"
          >
            <StartIcon>
              <MonetizationOn fontSize="small" />
            </StartIcon>
            <Typography color="textPrimary" variant="button">
              {t("profile.earnCashBack")}
            </Typography>
          </MenuItem>
        </SubMenu>
      )}
      <SubMenu>
        <MenuItem onClick={logout}>
          <StartIcon>
            <ExitToAppRounded fontSize="small" />
          </StartIcon>
          <Typography color="textPrimary" variant="button">
            {t("profile.logout")}
          </Typography>
        </MenuItem>
      </SubMenu>
    </Menu>
  );

  return (
    <>
      <SmallTab
        value="profile"
        id="account-menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={<ProfileTabLabel />}
      />
      <ProfileMenu />
    </>
  );
});

export default ProfileTab;
