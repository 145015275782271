import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { getCurrencySymbol } from "~/utils/currencyUtils.js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 16px 8px;
`;

const Tile = styled.div`
  flex: 1;
  flex-basis: 16%;
  padding-right: 8px;
`;

const formatPrice = (price) => {
  if (price && price.CurrencyCode && price.Amount) {
    return `${getCurrencySymbol[price.CurrencyCode]}${price.Amount}`;
  }
  return "-";
};

const SellerSummaryComponent = ({ data, marketplaceCountry }) => {
  const { t } = useTranslation();
  const buyBoxOffer = data.sellers.find((e) => e.IsBuyBoxWinner === "true");
  const amazonOffer = data.sellers.find((e) => e.isAmazon);

  // not necessarily the first entry in sellers array
  const lowestOffer = data.sellers.reduce((acc, curr) => {
    if (!acc) {
      return curr;
    }

    return parseFloat(curr.ListingPrice.Amount) <
      parseFloat(acc.ListingPrice.Amount)
      ? curr
      : acc;
  }, null);

  return (
    <TileContainer>
      <Tile>
        <Bold variant="body2" align="center">
          {t("myStoresWidget.buyBoxSellers.sellersLabel")}
        </Bold>
      </Tile>
      <Tile>
        <Bold variant="body2" align="right">
          {t("myStoresWidget.buyBoxSellers.buyBoxPriceLabel")}
        </Bold>
      </Tile>
      <Tile>
        <Bold variant="body2" align="right">
          {t("myStoresWidget.buyBoxSellers.amazonPriceLabel")}
        </Bold>
      </Tile>
      <Tile>
        <Bold variant="body2" align="right">
          {t("myStoresWidget.buyBoxSellers.lowestPriceLabel")}
        </Bold>
      </Tile>
      <Tile>
        <Bold variant="body2" align="right">
          {t("myStoresWidget.buyBoxSellers.suggestedPriceLabel")}
        </Bold>
      </Tile>
      <Tile>
        <Typography variant="body2" align="center">
          {data.sellers.length}
        </Typography>
      </Tile>
      <Tile>
        <Typography variant="body2" align="right">
          {formatPrice(buyBoxOffer?.ListingPrice)}
        </Typography>
      </Tile>
      <Tile>
        <Typography variant="body2" align="right">
          {formatPrice(amazonOffer?.ListingPrice)}
        </Typography>
      </Tile>
      <Tile>
        <Typography variant="body2" align="right">
          {
            // see: https://merchantspring.atlassian.net/browse/MM-3278 - we need to include 'points' as part of our data for the japanese market
            `${formatPrice(lowestOffer?.ListingPrice)}${
              marketplaceCountry === "JPN"
                ? ` + ${lowestOffer?.Points?.PointsNumber || "0"}pt`
                : ""
            }`
          }
        </Typography>
      </Tile>
      <Tile>
        <Typography variant="body2" align="right">
          {formatPrice(data.suggestedPrice)}
        </Typography>
      </Tile>
    </TileContainer>
  );
};

SellerSummaryComponent.propTypes = {
  data: PropTypes.object.isRequired,
  marketplaceCountry: PropTypes.string,
};

const SellerSummary = memo(SellerSummaryComponent);

export default SellerSummary;
