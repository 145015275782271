import {
  CampaignPaginationParams,
  useMarketingProductsQuery,
} from "../../store/mystore/marketing.redux";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { ENTITY_TYPE, getPerformanceColumns } from "./commonColumns";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import SearchFilter from "~/components/adTable/searchFilter";
import Table from "~/components/adTable/table";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const REPORT_COLUMN_IDS = [
  "product_title",
  "attributed_sales",
  "cost",
  "acos",
  "conversion_rate",
];

interface ProductSummaryPanel {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  userInfo: User;
  currentRange: Range;
  storeName: string;
  currentCurrency: string;
  pageSize: number;
  isReportView?: boolean;
}

const ProductSummaryPanel = memo<ProductSummaryPanel>(
  function ProductSummaryPanel({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    userInfo,
    currentRange,
    storeName,
    currentCurrency,
    pageSize,
    isReportView,
  }) {
    const { t } = useTranslation();

    const homeCurrency = getCurrencyByCountryCode[countryCode];

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[countryCode],
      currentCurrency
    );

    const columns = useMemo(() => {
      const columns = [
        {
          Header: t("advertisingDashboardWidget.adTable.productColumn"),
          id: "product_title",
          accessor: (row: any) => ({
            value: row.product_title,
            secondRowValue: `${
              marketplaceType?.startsWith("amazon") ? "ASIN" : "SKU"
            }: ${row.asin}`,
            image: row.image_url,
            link: row.link_url,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell
              {...props}
              colorVariant="external"
              maxWidth="25rem"
            />
          ),
          isVisible: true,
          disableSortBy: true,
        },
        ...getPerformanceColumns(
          ENTITY_TYPE.productAd,
          currencyRates,
          homeCurrency,
          currentCurrency,
          t,
          false
        ),
      ];
      const filteredColumns = isReportView
        ? columns.filter(
            (column) => column.id && REPORT_COLUMN_IDS.includes(column.id)
          )
        : columns;
      return filteredColumns;
    }, [currencyRates, currentCurrency, homeCurrency, isReportView]);

    const [myColumns, setMyColumns] = useState<Column[]>(columns);
    const [searchText, setSearchText] = useState("");

    const [paginationParams, setPaginationParams] =
      // Type CampaignPaginationParams can be reused here
      useState<CampaignPaginationParams>({
        pageSize,
        pageIndex: 0,
        sortKey: "attributed_sales",
        sortDesc: true,
      });

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy?.[0]?.id || "attributed_sales",
        sortDesc: sortBy?.[0]?.desc ?? true,
        pageIndex,
        pageSize,
      });
    }, []);

    const { adProducts, count, loading } = useMarketingProductsQuery(
      {
        mid: mid,
        countryCode: countryCode,
        marketplaceType: marketplaceType,
        marketplaceSubtype: marketplaceSubtype,
        searchText,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          adProducts: data?.data || [],
          count: data?.count || 0,
          loading: isFetching,
        }),
      }
    );

    return (
      <Panel
        id="widget-marketing-keyword-summary"
        title={t("advertisingDashboardWidget.product.mainTitle")}
        content={
          <Table
            columns={myColumns}
            data={adProducts}
            fetchData={fetchData}
            loading={loading}
            sorting={!isReportView}
            pagination={!isReportView}
            pageCount={Math.ceil(count / paginationParams.pageSize)}
            pageSize={paginationParams.pageSize}
            disableScroll={isReportView}
          />
        }
        actions={
          !isReportView ? (
            <>
              <SearchFilter setSearchText={setSearchText} />
              <ColumnSelect
                {...{ columns: myColumns, setColumns: setMyColumns }}
              />
              <DownloadCsv
                {...{
                  mid,
                  reportType: "advertisingProduct",
                  path: "/api/amazon/advertising/product",
                  params: {
                    mid,
                    countryCode,
                    marketplaceType,
                    marketplaceSubtype,
                    searchText: "",
                    fromDate: currentRange.fromDate,
                    toDate: currentRange.toDate,
                    shopName: storeName,
                    currentCurrency,
                    exchangeRate,
                  },
                }}
              />
            </>
          ) : undefined
        }
      />
    );
  }
);

export default ProductSummaryPanel;
