import { Box, Grid, useTheme } from "@material-ui/core";
import { LegendIndicator, LegendWrapper } from "../barChart/barChartLegend";

import React from "react";
import { useTranslation } from "react-i18next";

export const StepChartLegend = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const legendItems = [
    t("chartLegends.salesToDate"),
    t("chartLegends.expectedSales"),
  ];
  return (
    <Grid container>
      <Box ml={1} display="flex">
        {legendItems.map((item, i) => (
          <LegendWrapper item key={item}>
            <LegendIndicator
              theme={theme}
              fill={
                i === 0
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.light
              }
            />
            {item}
          </LegendWrapper>
        ))}
      </Box>
    </Grid>
  );
};
