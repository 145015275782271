import { Grid, Typography } from "@material-ui/core";
import React, { memo, useEffect, useRef, useState } from "react";

import styled from "styled-components";

const ICONS_WIDTH = 75;

const NoWrapGrid = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
`;

const TextWrapper = styled(Grid)<{ $maxWidth: number }>`
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
`;

interface CustomGroupLinkProps {
  groupId?: number;
  changeCustomGroup?: (groupId: number) => void;
  groupName?: string;
  fontSize?: string;
}
export const CustomGroupLink = memo<CustomGroupLinkProps>(
  function CustomGroupLink({
    groupId,
    changeCustomGroup,
    groupName,
    fontSize,
  }) {
    const defaultMaxWidth = 100;
    const [maxWidth, setMaxWidth] = useState(defaultMaxWidth);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (
        ref.current &&
        ref.current.offsetWidth > defaultMaxWidth + ICONS_WIDTH
      ) {
        setMaxWidth(ref.current.offsetWidth - ICONS_WIDTH);
      }
    }, [ref]);

    const selectCustomGroup = () => {
      if (changeCustomGroup && groupId) {
        changeCustomGroup(groupId);
      }
    };

    return (
      <NoWrapGrid
        container
        id="custom-group-link"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        onClick={selectCustomGroup}
        ref={ref}
      >
        <TextWrapper item $maxWidth={maxWidth}>
          <Typography
            variant={fontSize === "large" ? "h2" : "body2"}
            noWrap
            color="textPrimary"
          >
            {groupName}
          </Typography>
        </TextWrapper>
      </NoWrapGrid>
    );
  }
);

export default CustomGroupLink;
