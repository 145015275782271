import React from "react";
import { withStyles } from "@material-ui/core/styles";

const height = "14px";
const styles = (theme) => ({
  root: {
    height: 2 * height,
  },
  description: {
    display: "inline-block",
    textAlign: "center",
    fontSize: "10px",
    height: height,
    lineHeight: height,
    verticalAlign: "middle",
    fontWeight: "normal",
    // make text disappear smoothly as the percentage of a particular category
    // shrinks
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "break-spaces",
  },
  colorfulBar: {
    display: "inline-block",
    height: height,
  },
});

class PercentageBar extends React.Component {
  constructor(props) {
    super(props);
    this.precentageBox = React.createRef();
  }

  render() {
    const { data, totalLength, classes } = this.props;

    const blocks = data.map((item) => ({
      length: totalLength
        ? parseFloat(parseInt(totalLength) * parseFloat(item.percentage))
            .toFixed(0)
            .toString() + "px"
        : parseFloat(Math.floor(item.percentage * 10000) / 100.0)
            .toFixed(2)
            .toString() + "%",
      color: item.color,
      value: parseFloat(item.percentage * 100)
        .toFixed(0)
        .toString(),
      des: item.des,
    }));

    return (
      <div
        className={classes.root}
        style={{ width: "100%" }}
        ref={this.precentageBox}
      >
        {blocks.map((item, index) =>
          item.value < 10 ? null : (
            <div
              style={{ width: item.length }}
              key={index}
              className={classes.description}
            >
              <span>
                {`${item.value}% `}
                {item.des}
              </span>
            </div>
          )
        )}
        <div>
          {" "}
          {blocks.map((item, index) => (
            <div
              style={{ width: item.length, backgroundColor: item.color }}
              className={classes.colorfulBar}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PercentageBar);
