/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import { Box, Grid, Link, Typography, useTheme } from "@material-ui/core";
import {
  ebaySiteByCountryCode,
  getListingQualityMetrics,
  getListingQualitySummaryPercent,
} from "@merchantspring/common";

import { LinkAndImageComponent } from "~/components/table/cells/linkAndImageCell";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel.tsx";
import React from "react";
import ScoreDetails from "~/modules/widgets/listingQuality/scoreDetails";
import SmallButton from "~/components/buttons/smallButton";
import StatusIndicator from "~/components/statusIndicator/statusIndicator";
import _ from "lodash";
import get from "lodash/get";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const TopListingsRow = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  padding: 1rem;
`;

const ScoreCard = ({
  metrics,
  summaryPercent = 0,
  activeListings = 0,
  lastUpdateTime,
  renderLink,
  csvLink,
}) => {
  const { t } = useTranslation();
  const selectedTimezone = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const time = lastUpdateTime
    ? moment.tz(lastUpdateTime, selectedTimezone).calendar()
    : "Unknown";

  const summaryStatus =
    // eslint-disable-next-line no-magic-numbers
    summaryPercent > 80
      ? "success"
      : // eslint-disable-next-line no-magic-numbers
      summaryPercent > 50
      ? "warning"
      : summaryPercent > 0
      ? "error"
      : "disabled";
  return (
    <Panel
      id="widget-listing-quality-summary"
      title={t("myStoresWidget.listingQuality.mainTitle")}
      content={
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justifyContent="center">
              <StatusIndicator
                status={summaryStatus}
                statusScore={summaryPercent ? `${summaryPercent}%` : ""}
                title={t(
                  "myStoresWidget.listingQuality.statusIndicator.mainTitle"
                )}
                subtitle={t(
                  "myStoresWidget.listingQuality.statusIndicator.subTitle",
                  { time }
                )}
                footnote={t(
                  "myStoresWidget.listingQuality.statusIndicator.footnote",
                  { numListings: numberWithCommas(activeListings) }
                )}
              />
            </Grid>
            <Grid xs={12} item>
              <ScoreDetails metrics={metrics} />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <SmallButton
          component={renderLink}
          download
          href={csvLink}
          id="listing-quality-export"
          name="exportCSVBtn"
          color="info"
        >
          {t("myStoresWidget.listingQuality.exportButton")}
        </SmallButton>
      }
    />
  );
};

const TopListings = ({ listings, countryCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Panel
      id="widget-listing-quality-top-listings"
      title={t("myStoresWidget.listingQuality.topListings.mainTitle")}
      content={
        <Grid container direction="column">
          <TopListingsRow theme={theme} container item xs={12} align="center">
            <Typography variant="body2">
              {t("myStoresWidget.listingQuality.topListings.introText")}
            </Typography>
          </TopListingsRow>
          {listings.map((item) => {
            const ebayUrl = `${ebaySiteByCountryCode[countryCode]}itm/${item._id}`;
            const sku = _.get(item, "sku");
            return (
              <TopListingsRow
                key={item._id}
                theme={theme}
                container
                item
                xs={12}
                align="center"
              >
                <LinkAndImageComponent
                  image={_.get(item, "imageUrl[0]", "")}
                  value={item.title}
                  link={ebayUrl}
                  target={"_blank"}
                  colorVariant={"external"}
                  secondRowValue={sku ? `LISTING ID: ${sku}` : ""}
                />
              </TopListingsRow>
            );
          })}
        </Grid>
      }
    />
  );
};

const renderLink = React.memo(
  React.forwardRef((linkProps, ref) => (
    <Link color="primary" {...linkProps} innerRef={ref} />
  ))
);

const ListingSummary = ({ itemSpecifics, countryCode, listingQuality }) => {
  const scores = _.get(listingQuality, "scores", {});
  const badListings = _.get(listingQuality, "badListings", []);
  const itemInfo = _.get(listingQuality, "itemsInfo", []);
  // exit early if the required props haven't been loaded yet
  if (!(itemSpecifics && listingQuality)) {
    return <LoadingIndicator />;
  }

  const metrics = getListingQualityMetrics(
    scores,
    _.get(itemSpecifics, "categoryScores", [])
  );
  const listings = itemInfo.filter((e) => badListings.includes(e._id));
  const summaryPercent = getListingQualitySummaryPercent(metrics);
  const updateTime = _.min(
    itemInfo.map((item) => new Date(item.UpdateDate || item.CreateDate))
  );

  const mid = _.get(itemInfo[0], "mid");
  const urlParams = new URLSearchParams({
    mid,
    countryCode,
  });
  const csvLink = mid ? `/api/ebay/itemlistings?${urlParams.toString()}` : "#";

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} lg={8} item>
        <ScoreCard
          metrics={metrics}
          summaryPercent={summaryPercent}
          lastUpdateTime={updateTime}
          activeListings={itemInfo.length}
          renderLink={renderLink}
          csvLink={csvLink}
        />
      </Grid>
      <Grid xs={12} md={6} lg={4} item>
        <TopListings listings={listings} countryCode={countryCode} />
      </Grid>
    </Grid>
  );
};

export default withRouter(ListingSummary);
