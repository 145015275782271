import AllegroConnectPage from "../marketplaceConnectors/allegroConnectPage";
import AmazonConnectAdvertisingPage from "../marketplaceConnectors/amazonConnectAdvertisingPage";
import AmazonConnectCountryPage from "../marketplaceConnectors/amazonConnectCountryPage";
import AmazonConnectPage from "../marketplaceConnectors/amazonConnectPage";
import AmazonSelectAdvertisingProfilePage from "../marketplaceConnectors/amazonSelectAdvertisingProfilePage";
import AmazonUpdateStoreNamePage from "../marketplaceConnectors/amazonUpdateStoreNamePage";
import BrandFilterAdvertisingConnectPage from "../marketplaceConnectors/brandFilterAdvertisingConnectPage";
import BrandFilterConnectPage from "../marketplaceConnectors/brandFilterConnectPage";
import EbayConnectPage from "../marketplaceConnectors/ebayConnectPage";
import GenericAdvertisingConnectPage from "../marketplaceConnectors/genericAdvertisingConnectPage";
import GenericConnectPage from "../marketplaceConnectors/genericConnectPage";
import GoogleLogo from "~/img/marketplaces/icons/google.svg";
import MarketingAuthoriseUser from "../marketplaceConnectors/amazonAdvertisingConnectPageForWizard";
import MercadoLibreConnectPage from "../marketplaceConnectors/mercadoLibreConnectPage";
import OttoConnectPage from "../marketplaceConnectors/ottoConnectPage";
import WalmartConnectPage from "../marketplaceConnectors/walmartConnectPage";
import WoocommerceConnectPage from "../marketplaceConnectors/woocommerceConnectPage";
import YahooJapanConnectPage from "../marketplaceConnectors/yahooJapanConnectPage";
import ZalandoConnectPage from "../marketplaceConnectors/zalandoConnectPage";
import amazonDSPLogoRaw from "~/img/marketplaces/icons/dsp.jpg";
import amazonLogoRaw from "~/img/marketplaces/icons/amazon-icon.png";
import amazonVendorLogoRaw from "~/img/marketplaces/icons/amazon-vendor.png";
import criteoLogoRaw from "~/img/marketplaces/icons/criteo.jpg";
import ebayLogoRaw from "~/img/marketplaces/icons/ebay-favicon.jpg";
import instacartLogoRaw from "~/img/marketplaces/icons/instacart.jpg";
import shopifyLogoRaw from "~/img/marketplaces/icons/shopify-icon.jpg";
import tikTokLogoRaw from "~/img/marketplaces/icons/tiktok.jpg";
import walmartConnectLogoRaw from "~/img/marketplaces/icons/walmart-connect.png";
import walmartLogoRaw from "~/img/marketplaces/icons/walmart.jpg";

export interface PrimaryButtonsProps {
  [key: string]: {
    label: string;
    imgSrc: any;
  };
}

export interface AdvertisingButtonsProps {
  [key: string]: {
    label: string;
    imgSrc: any;
    featureFlagged?: string;
    isNew?: boolean;
  };
}

// A "library" of possible primary connection buttons
// to be picked from using custom layouts or a default layout
// The top-level key should match the marketplace names in the
// pageToRender map below
export const PRIMARY_BUTTONS: PrimaryButtonsProps = {
  amazon: {
    label: "Amazon Seller",
    imgSrc: amazonLogoRaw,
  },
  amazon_vendor: {
    label: "Amazon Vendor",
    imgSrc: amazonVendorLogoRaw,
  },
  ebay: {
    label: "eBay",
    imgSrc: ebayLogoRaw,
  },
  shopify: {
    label: "Shopify",
    imgSrc: shopifyLogoRaw,
  },
  walmart: {
    label: "Walmart",
    imgSrc: walmartLogoRaw,
  },
};

export const ADVERTISING_BUTTONS: AdvertisingButtonsProps = {
  amazon: {
    imgSrc: amazonLogoRaw,
    label: "advertisingConnectLabel.amazonAdvertising",
  },
  amazon_vendor: {
    imgSrc: amazonVendorLogoRaw,
    label: "advertisingConnectLabel.amazonVendorAdvertising",
  },
  dsp: {
    imgSrc: amazonDSPLogoRaw,
    label: "advertisingConnectLabel.amazonDSPAdvertising",
  },
  walmart: {
    imgSrc: walmartConnectLogoRaw,
    label: "advertisingConnectLabel.walmartAdvertising",
  },
  criteo: {
    imgSrc: criteoLogoRaw,
    label: "advertisingConnectLabel.criteoRetailMedia",
    isNew: true,
  },
  tiktok: {
    imgSrc: tikTokLogoRaw,
    label: "advertisingConnectLabel.tikTokAdvertising",
    isNew: true,
  },
  google: {
    imgSrc: GoogleLogo,
    label: "advertisingConnectLabel.googleAdvertising",
    isNew: true,
  },
  instacart: {
    imgSrc: instacartLogoRaw,
    label: "advertisingConnectLabel.instacartAdvertising",
    isNew: true,
    featureFlagged: "instacartConnectorOn",
  },
};

// a map from marketplace names (set in the URL) to connection pages
// to render for that marketplace
export const PAGE_TO_RENDER = {
  amazon: {
    component: AmazonConnectPage,
  },
  amazon_vendor: {
    component: AmazonConnectPage,
  },
  amazon_filtered: {
    component: BrandFilterConnectPage,
  },
  amazon_vendor_filtered: {
    component: BrandFilterConnectPage,
  },
  amazonUpdateStoreName: {
    component: AmazonUpdateStoreNamePage,
  },
  amazonVendorUpdateStoreName: {
    component: AmazonUpdateStoreNamePage,
  },
  amazonAddCountry: {
    component: AmazonConnectCountryPage,
  },
  amazonVendorConnectAdvertising: {
    component: AmazonConnectAdvertisingPage,
  },
  amazonVendorSelectAdvertisingProfile: {
    component: AmazonSelectAdvertisingProfilePage,
  },
  allegro: {
    component: AllegroConnectPage,
  },
  bol: {
    component: GenericConnectPage,
  },
  bol_filtered: {
    component: BrandFilterConnectPage,
  },
  brandFilterAdvertisingConnectPage: {
    component: BrandFilterAdvertisingConnectPage,
  },
  bigcommerce: {
    component: GenericConnectPage,
  },
  cdiscount: {
    component: GenericConnectPage,
  },
  ebay: {
    component: EbayConnectPage,
  },
  ebay_filtered: {
    component: BrandFilterConnectPage,
  },
  iconic: {
    component: GenericConnectPage,
  },
  kaufland: {
    component: GenericConnectPage,
  },
  kogan: {
    component: GenericConnectPage,
  },
  magento: {
    component: GenericConnectPage,
  },
  manomano: {
    component: GenericConnectPage,
  },
  mercadolibre: {
    component: MercadoLibreConnectPage,
  },
  mercadolibre_filtered: {
    component: BrandFilterConnectPage,
  },
  mirakl: {
    component: GenericConnectPage,
  },
  mirakl_filtered: {
    component: BrandFilterConnectPage,
  },
  mydeal: {
    component: GenericConnectPage,
  },
  otto: {
    component: OttoConnectPage,
  },
  pinkConnect: {
    component: GenericConnectPage,
  },
  sears: {
    component: GenericConnectPage,
  },
  shopify: {
    component: GenericConnectPage,
  },
  themarket: {
    component: GenericConnectPage,
  },
  trademe: {
    component: GenericConnectPage,
  },
  walmart: {
    component: WalmartConnectPage,
  },
  walmart_filtered: {
    component: BrandFilterConnectPage,
  },
  wayfair: {
    component: GenericConnectPage,
  },
  woocommerce: {
    component: WoocommerceConnectPage,
  },
  yahoojapan: {
    component: YahooJapanConnectPage,
  },
  zalando: {
    component: ZalandoConnectPage,
  },
  zalando_filtered: {
    component: BrandFilterConnectPage,
  },
  zalando_all_filtered: {
    component: BrandFilterConnectPage,
  },
};

export const advertisingPageToRender = () => {
  return {
    amazon: {
      component: MarketingAuthoriseUser,
    },
    amazon_vendor: {
      component: MarketingAuthoriseUser,
    },
    amazon_filtered: {
      component: BrandFilterAdvertisingConnectPage,
    },
    amazon_vendor_filtered: {
      component: BrandFilterAdvertisingConnectPage,
    },
    bol: {
      component: GenericAdvertisingConnectPage,
    },
    walmart: {
      component: GenericAdvertisingConnectPage,
    },
  };
};
