import {
  FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE,
  FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_ERROR,
  FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_FETCHING,
} from "../overview/overview.redux";
import {
  FETCH_PO_PERFORMANCE_SHIP_DATE,
  FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR,
  FETCH_PO_PERFORMANCE_SHIP_DATE_FETCHING,
} from "./mystore.redux";

import { Dispatch } from "redux";
import { FetchPoPerformanceParams } from "./purchaseOrderPerformance.redux";
import { QUERY_TYPE } from "~/modules/widgets/amazonVendor/purchaseOrdersPerformance";
import axios from "axios";
import { baseUrl } from "~/configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

export const fetchPurchaseOrdersPerformanceByShipDate =
  (params: FetchPoPerformanceParams, oldParams?: FetchPoPerformanceParams) =>
  async (dispatch: Dispatch) => {
    const { mid, currentPeriod, currentRange, filter, type } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({
        type: mid
          ? FETCH_PO_PERFORMANCE_SHIP_DATE_FETCHING
          : FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_FETCHING,
      });
      const poSales = await axios
        .post(
          `${baseUrl}/api/generic-mws-service/api/vendor/poSalesPerformance`,
          {
            currentPeriod,
            ...currentRange,
            mid,
            filter,
            queryBy: QUERY_TYPE.shippedDate,
          }
        )
        .catch((e: Error) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(dispatch, FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR);
          return setError(dispatch, msg || statusText, get(err, "status"));
        });

      if (poSales) {
        if (isHttpResponseValid(poSales.status)) {
          const salesData = poSales.data;
          dispatch({
            type: mid
              ? FETCH_PO_PERFORMANCE_SHIP_DATE
              : FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE,
            payload: {
              ...salesData,
              params,
              type,
            },
          });
        } else {
          await dispatchError(
            dispatch,
            mid
              ? FETCH_PO_PERFORMANCE_SHIP_DATE_ERROR
              : FETCH_OVERVIEW_PO_PERFORMANCE_SHIP_DATE_ERROR
          );
          return setError(dispatch, poSales.data.errMsg, poSales.status);
        }
      }
    }
    return;
  };
