/* eslint-disable no-magic-numbers */
import { PlanPropTypes, getPlanName } from "./plan";

import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function planButtonText(plan, currentPlan, newSubscriber, t) {
  const selected =
    currentPlan && getPlanName(plan.planId) === getPlanName(currentPlan.planId);

  if (selected) {
    return t("subscription.plan.currentPlanButton");
  } else if (newSubscriber) {
    return t("subscription.plan.tryFreeButton");
  } else {
    return t("subscription.plan.selectPlanButton");
  }
}

const PlanButtonElement = styled(RaisedButton)`
  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const PlanButton = ({ plan, currentPlan, onSelect, newSubscriber }) => {
  const { t } = useTranslation();
  const selected =
    currentPlan && getPlanName(plan.planId) === getPlanName(currentPlan.planId);
  const onClick = () => {
    onSelect(plan);
  };

  return (
    <>
      <PlanButtonElement
        color="primary"
        onClick={onClick}
        variant={newSubscriber || selected ? "contained" : "outlined"}
        disabled={selected}
        style={{ minWidth: "75%" }}
      >
        {planButtonText(plan, currentPlan, newSubscriber, t)}
      </PlanButtonElement>
    </>
  );
};
PlanButton.propTypes = {
  plan: PlanPropTypes.isRequired,
  currentPlan: PlanPropTypes,
  onSelect: PropTypes.func.isRequired,
  newSubscriber: PropTypes.bool.isRequired,
};

export default PlanButton;
