import {
  ButtonBase,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { memo, useMemo } from "react";

import { ArrowDropDown } from "@material-ui/icons";
import styled from "styled-components";

const PanelSelectBase = styled(ButtonBase)<{
  $width?: string;
  $height?: string;
}>`
  padding: 0 0.5rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const PanelSelectMenuItem = styled(MenuItem)<{ $width?: number | null }>`
  ${({ $width }) => ($width ? `width: ${$width}px;` : null)}
`;

type Option = {
  value: any;
  label: string;
};

interface PanelSelectProps {
  currentValue: any;
  handleChange: (value: any) => void;
  options: Option[];
  width?: string;
  height?: string;
  disabled?: boolean;
}

const PanelSelect = memo<PanelSelectProps>(
  ({ options, currentValue, handleChange, width, height, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState<
      (EventTarget & HTMLButtonElement) | null
    >(null);
    const [menuWidth, setMenuWidth] = React.useState(
      anchorEl ? anchorEl.offsetWidth : null
    );
    const currentValueLabel = useMemo(() => {
      const option = options.find((option) => option.value === currentValue);
      return option ? option.label : currentValue;
    }, [currentValue, options]);

    React.useEffect(() => {
      setMenuWidth(anchorEl ? anchorEl.offsetWidth : null);
    }, [anchorEl]);

    const openMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClick = (value: any) => {
      setAnchorEl(null);
      handleChange(value);
    };

    return (
      <Grid container item xs={12} direction="column">
        <PanelSelectBase
          id="panel-select"
          onClick={openMenu}
          $width={width}
          $height={height}
          disabled={disabled}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>{currentValueLabel}</Typography>
            </Grid>
            <Grid item>
              <ArrowDropDown />
            </Grid>
          </Grid>
        </PanelSelectBase>
        <Menu
          id="panel-select-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          autoFocus={false}
          marginThreshold={1}
          onClose={() => setAnchorEl(null)}
        >
          {options.map((option) => (
            <PanelSelectMenuItem
              key={option.value}
              $width={menuWidth}
              onClick={() => handleClick(option.value)}
            >
              {option.label}
            </PanelSelectMenuItem>
          ))}
        </Menu>
      </Grid>
    );
  }
);

export default PanelSelect;
