import {
  Account,
  setCriteoAccount,
} from "~/store/connections/connectCriteo.redux";
import React, { useEffect, useState } from "react";
import { allCurrencies, getCurrencyByCountryCode } from "mm-utils-frontend";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import Radio from "@material-ui/core/Radio";
import RaisedButton from "~/components/buttons/raisedButton";
import { StoreState } from "~/typedef/store";
import Typography from "@material-ui/core/Typography";
import { getCountryCodeISO2ToCountry } from "~/utils/countryUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CriteoAdvertisingProfilePageProps {
  selectProfile: (
    mid: string,
    profileId?: string,
    entityId?: string,
    countryCode?: string
  ) => void;
  onSuccess: () => void;
}

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
  padding-top: 2rem;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const SUPPORTED_COUNTRIES = getCurrencyByCountryCode;
const SUPPORTED_CURRENCIES = allCurrencies;

const CriteoAdvertisingProfilePage: React.FC<
  CriteoAdvertisingProfilePageProps
> = ({ selectProfile, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accounts = useTypedSelector(
    (state: StoreState) => state.connectCriteo.accounts
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
  };

  const handleSubmit = async () => {
    try {
      if (selectedAccount?.id && selectedCountry) {
        if (!SUPPORTED_COUNTRIES[selectedCountry]) {
          setError(t("connectWizard.invalidCountrySelected"));
          return;
        }

        const marketplaceId = `Criteo`;
        const mid = `${selectedAccount.id} @ ${marketplaceId}`;
        setLoading(true);
        await dispatch(
          setCriteoAccount(
            {
              mid,
              countryCode: selectedCountry,
              accountId: selectedAccount.id,
            },
            t
          )
        );
        setLoading(false);
        selectProfile(mid, selectedAccount.id, undefined, selectedCountry);
        onSuccess();
      } else {
        setError(t("connectWizard.invalidAccountSelected"));
        return;
      }
    } catch (error) {
      setError(
        t((error as Error).message || "advertisingConnectLabel.generalError")
      );
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      const accountCurrency = selectedAccount.attributes.currency;
      if (!SUPPORTED_CURRENCIES.includes(accountCurrency)) {
        // Error Case 1: The Account currency is currently not supported
        setError(t("connectWizard.invalidAccountCurrency"));
      } else {
        let currencyCountry: string | null = null;
        const accountCountries = selectedAccount?.attributes.countries;
        if (accountCountries) {
          // Iterate over account countries & find the first country that matches the account currency
          for (const countryCodeIso2 of accountCountries) {
            const country = getCountryCodeISO2ToCountry(countryCodeIso2);
            if (
              country &&
              SUPPORTED_COUNTRIES[country.iso3] === accountCurrency
            ) {
              currencyCountry = country.iso3;
              break;
            }
          }
        }
        if (!currencyCountry) {
          // Error Case 2: Currency is valid, but account has no countries
          // Error Case 3: Currency is valid, but no country matches the account currency
          // Auto-assign a country based on the accountCurrency
          for (const country in getCurrencyByCountryCode) {
            if (getCurrencyByCountryCode[country] === accountCurrency) {
              currencyCountry = country;
              break;
            }
          }
        }
        setSelectedCountry(currencyCountry);
      }
    } else {
      setSelectedCountry(null);
    }
  }, [selectedAccount]);

  const renderAvailableAccounts = (accounts: Account[]) =>
    accounts.map((account) => (
      <Grid
        key={account.id}
        container
        item
        spacing={1}
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
      >
        <Radio
          checked={selectedAccount?.id === account.id}
          name={`${account.attributes.name}`}
          color="primary"
          onChange={() => handleAccountSelect(account)}
        />
        <Grid item>
          <Typography variant="body2" color="textSecondary" noWrap>
            {account.attributes.name} ({account.attributes.parentAccountLabel})
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="criteo-selectaccount-wrapper">
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Medium color="textSecondary" align="center">
                {t("connectWizard.criteoAdvertisingAccountTitle")}
              </Medium>
            </Grid>
            <Grid item xs={12}>
              {accounts
                ? renderAvailableAccounts(accounts)
                : t("connectWizard.connectionError")}
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <Box pt={2}>
                  <RaisedButton
                    disabled={!selectedAccount?.id || !selectedCountry}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                  >
                    {t("generic.continueButton")}
                  </RaisedButton>
                </Box>
              </Grid>
            </Grid>
            <>
              {error && (
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <ErrorMessage color="error">{error}</ErrorMessage>
                </Grid>
              )}
            </>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default CriteoAdvertisingProfilePage;
