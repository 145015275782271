import { Grid, Tooltip } from "@material-ui/core";
import React, { memo } from "react";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AmazonIcon from "~/img/amazon_round.png";
import ErrorIcon from "@material-ui/icons/Error";
import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TrophyIcon from "~/img/trophy.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SmallIcon = styled.img`
  padding: 1px 0.25rem;
  height: 18px;
`;

const RedErrorIcon = styled(ErrorIcon)`
  fill: ${({ theme }) => theme.palette.error.dark};
  height: 20px;
`;

const QuestionIcon = styled(HelpIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const NoWinnerIcon = styled(RemoveCircleIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const SelfIcon = styled(AccountCircleIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const StatusCell = memo(({ cell }) => {
  const { t } = useTranslation();

  if (!cell.value) {
    return null;
  }

  const {
    isWinning,
    isLosingToAmazon,
    isLosingToSelf,
    isLosingToOthers,
    isLosingToUnknown,
    isNoWinner,
  } = cell.value;
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item>
        {isWinning && (
          <Tooltip title={t("myStoresWidget.buyBoxPerformance.winningTooltip")}>
            <SmallIcon src={TrophyIcon} />
          </Tooltip>
        )}
        {isLosingToAmazon && (
          <Tooltip
            title={t("myStoresWidget.buyBoxPerformance.losingToAmazonTooltip")}
          >
            <SmallIcon src={AmazonIcon} />
          </Tooltip>
        )}
        {isLosingToSelf && (
          <Tooltip
            title={t("myStoresWidget.buyBoxPerformance.losingToSelfTooltip")}
          >
            <SelfIcon />
          </Tooltip>
        )}
        {isLosingToOthers && (
          <Tooltip
            title={t("myStoresWidget.buyBoxPerformance.losingToOthersTooltip")}
          >
            <RedErrorIcon />
          </Tooltip>
        )}
        {isLosingToUnknown && (
          <Tooltip
            title={t("myStoresWidget.buyBoxPerformance.losingToUnknownTooltip")}
          >
            <QuestionIcon />
          </Tooltip>
        )}
        {isNoWinner && (
          <Tooltip
            title={t("myStoresWidget.buyBoxPerformance.noWinnerTooltip")}
          >
            <NoWinnerIcon />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
});

StatusCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default StatusCell;
