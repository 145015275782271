import React, { memo } from "react";

import { CROSS_COUNTRY_MARKETPLACES } from "~/utils/marketplaceUtils";
import { CountryLogo } from "../../../img/flags/country_logos";
import { Grid } from "@material-ui/core";
import { MarketplaceIcon } from "../../../img/marketplaces/icons/marketplaceIcon";

interface MarketplaceAndCountryCellProps {
  row: {
    original?: {
      store?: {
        value?: string;
      };
    };
  };
  value: {
    market: string;
    countryCode: string;
    marketplaceSubtype?: string;
    mid?: string;
  };
}

export const MarketplaceAndCountryCell = memo<MarketplaceAndCountryCellProps>(
  function MarketplaceAndCountryCell({ value, row }) {
    const mid = row?.original?.store
      ? row.original.store?.value
      : value.mid || "";
    return (
      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          <MarketplaceIcon
            marketplaceSubtype={value.marketplaceSubtype}
            market={value.market}
            mid={mid}
          />
        </Grid>
        <Grid item>
          {value.market in CROSS_COUNTRY_MARKETPLACES ? (
            <CountryLogo
              currencyCode={CROSS_COUNTRY_MARKETPLACES[value.market]}
            />
          ) : (
            <CountryLogo code={value.countryCode} />
          )}
        </Grid>
      </Grid>
    );
  }
);
