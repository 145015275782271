import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const FETCH_CANCELLED_ORDERS_LIST = "FETCH_CANCELLED_ORDERS_LIST";
export const FETCH_CANCELLED_ORDERS_LIST_FETCHING =
  "FETCH_CANCELLED_ORDERS_LIST_FETCHING";
export const FETCH_CANCELLED_ORDERS_LIST_OVERVIEW =
  "FETCH_CANCELLED_ORDERS_LIST_OVERVIEW";
export const FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW =
  "FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW";
export const FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW =
  "FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW";

const initState = {
  fetching: true,
};

export const cancelledOrdersOverview = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW:
      return {
        ...state,
        fetchingOverview: true,
      };
    case FETCH_CANCELLED_ORDERS_LIST_OVERVIEW:
      return {
        ...state,
        fetchingOverview: false,
        overviewData: action.payload,
      };
    case FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW:
      return {
        fetchingOverview: false,
      };
    default:
      return state;
  }
};

export const cancelledOrders = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CANCELLED_ORDERS_LIST_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_CANCELLED_ORDERS_LIST:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const fetchCancelledOrdersList =
  ({ mid, pageIndex, pageSize, filter, timezone }) =>
  async (dispatch) => {
    dispatch({
      // If mid is present, we're fetching for the mystores page,
      // else we're fetching for the overview page
      type: mid
        ? FETCH_CANCELLED_ORDERS_LIST_FETCHING
        : FETCH_CANCELLED_ORDERS_LIST_FETCHING_OVERVIEW,
    });
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/cancelledOrders`, {
        mid,
        timezone,
        pageIndex,
        pageSize,
        filter,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: mid
            ? FETCH_CANCELLED_ORDERS_LIST
            : FETCH_CANCELLED_ORDERS_LIST_OVERVIEW,
          payload: data,
        });
      } else {
        await dispatchError(
          dispatch,
          FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW
        );
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    await dispatchError(dispatch, FETCH_CANCELLED_ORDERS_LIST_ERROR_OVERVIEW);
    return setError(dispatch);
  };
