import { Box, Grid, Switch, Tooltip, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import { DAYS_COVER_UPPER_LIMIT, FrequencyValue } from "./notificationSettings";
import {
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { useEffect, useState } from "react";

import AdvancedConfigurationDaysCover from "./advancedConfigurationDaysCover";
import { FrequencyInterval } from "mm-utils-frontend/dist/typedef/userNotificationSetting";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { NotificationTypeIcon } from "~/components/table/cells/notificationTypeCell";
import PanelSelect from "~/components/select/panelSelect";
import ProductDropDown from "~/components/select/productDropDown";
import { Store } from "~/typedef/store";
import { fetchTopProductsList } from "~/store/mystore/salesByProduct.redux";
import { isEqual } from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SettingRow = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;

interface SwitchMenuItem {
  title: string;
  checked: boolean;
  setChecked: (
    checked: boolean,
    notificationType: StoreNotificationType | ProductNotificationType
  ) => void;
  tooltip: string;
  colour?: "default" | "primary" | "secondary" | undefined;
  notificationType: StoreNotificationType | ProductNotificationType;
}

export interface SettingsRowProps {
  store: Store;
  title: string;
  emailFrequency: FrequencyValue;
  setFrequency: (
    notificationType: StoreNotificationType | ProductNotificationType,
    emailFrequency: FrequencyValue
  ) => void;
  description: string;
  products?: string[];
  setProducts: (
    notificationType: StoreNotificationType | ProductNotificationType,
    products: string[]
  ) => void;
  toggle: SwitchMenuItem;
  daysCoverLimit?: number;
  setDaysCover?: (
    notificationType: StoreNotificationType | ProductNotificationType,
    daysCover: number
  ) => void;
  showAdvancedConfig?: boolean;
  showAlwaysOn?: boolean;
  noProducts?: boolean;
  notificationType:
    | StoreNotificationType
    | ProductNotificationType
    | "CONNECTION_ISSUE";
  setNotificationType: (
    notificationType: StoreNotificationType | ProductNotificationType
  ) => void;
}

const SettingsRow: React.FC<SettingsRowProps> = ({
  store,
  title,
  description,
  emailFrequency,
  setFrequency,
  products,
  setProducts,
  toggle,
  showAdvancedConfig,
  showAlwaysOn,
  noProducts,
  notificationType,
  setNotificationType,
  daysCoverLimit,
  setDaysCover,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useTypedSelector((state) => state.user);
  const topProducts: { data: { productSku: string }[]; fetching: boolean } =
    useTypedSelector((state) => state.mystore.topProductsList);
  const timezone = useTypedSelector(
    (state) =>
      state?.persistentAppSettings?.setting?.data?.timezone || moment.tz.guess()
  );
  const [requestedTopProducts, setRequestedTopProducts] = useState(false);

  const FREQUENCY_OPTIONS = [
    {
      value: { value: 0, interval: FrequencyInterval.IMMEDIATE },
      label: t("emailFrequency.immediate"),
    },
    {
      value: { value: 1, interval: FrequencyInterval.DAILY },
      label: t("emailFrequency.daily"),
    },
    {
      value: { value: 1, interval: FrequencyInterval.WEEKLY },
      label: t("emailFrequency.weekly"),
    },
    {
      value: { value: 0, interval: FrequencyInterval.NEVER },
      label: t("emailFrequency.never"),
    },
  ];

  const currentRange = getDatesFromPeriod(
    DATETIME_PERIODS.LAST30,
    COMPARISON_PERIOD.THISYEAR,
    timezone
  );

  const dispatchFetchTopProducts = () =>
    dispatch(
      fetchTopProductsList({
        t,
        mid: store.merchantId,
        currentPeriod: DATETIME_PERIODS.LAST30,
        currentRange,
        pageIndex: 0,
        pageSize: 5,
      })
    );

  useEffect(() => {
    if (
      requestedTopProducts &&
      topProducts?.data?.length &&
      !topProducts?.fetching
    ) {
      if (notificationType !== "CONNECTION_ISSUE") {
        setNotificationType(notificationType);
        setProducts(
          notificationType,
          topProducts.data.map(
            (product: { productSku: string }) => product.productSku
          )
        );
        setRequestedTopProducts(false);
      }
    } else if (
      requestedTopProducts &&
      !topProducts?.data?.length &&
      !topProducts?.fetching
    ) {
      setRequestedTopProducts(false);
    }
  }, [requestedTopProducts, topProducts?.data, topProducts?.fetching]);

  //SWITCH COMPONENT
  const switchComponent = (item: SwitchMenuItem) => {
    return (
      <>
        <Switch
          size="small"
          color={item.colour ?? "secondary"}
          checked={item.checked}
          onClick={() => {
            setNotificationType(item.notificationType);
            item.setChecked(!item.checked, item.notificationType);
          }}
        />
      </>
    );
  };

  const handleChangeFrequency = (frequency: FrequencyValue) => {
    if (notificationType !== "CONNECTION_ISSUE") {
      setFrequency(notificationType, frequency);
      setNotificationType(notificationType);
    }
  };

  return store ? (
    <SettingRow container direction="row" alignItems="center" spacing={0}>
      <Grid container item xs={12} md={noProducts ? 6 : 3}>
        <Box display="flex" alignItems="center" p={2}>
          <NotificationTypeIcon notificationType={notificationType} />
          <Box display="flex" flexDirection="column" pl={1}>
            <Typography variant="h3">{title}</Typography>
            <Box display="flex" pt="0.5rem">
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      {!noProducts && notificationType !== "CONNECTION_ISSUE" && (
        <Grid container item xs={5} md={3} direction="column">
          <Box display="flex" flexDirection="column" p={2}>
            <Box
              display="flex"
              pb="0.5rem"
              width="100%"
              justifyContent="space-between"
            >
              <Box display="flex" width="100%">
                <Typography variant="h3">{t("nav.vendorProduct")}</Typography>
                &nbsp;
                <Typography variant="subtitle2" color="textSecondary">
                  {t("notificationSetting.max5")}
                </Typography>
              </Box>
              <Box display="flex" width="100%" justifyContent="space-between">
                <StyledTypography
                  onClick={() => {
                    setNotificationType(notificationType);
                    setProducts(notificationType, []);
                  }}
                >
                  {t("notifications.clear")}
                </StyledTypography>
                <StyledTypography
                  onClick={() => {
                    dispatchFetchTopProducts();
                    setRequestedTopProducts(true);
                  }}
                >
                  {t("notifications.top5")}
                </StyledTypography>
                <StyledTypography
                  onClick={() => {
                    setNotificationType(notificationType);
                    setProducts(notificationType, ["ALL"]);
                  }}
                >
                  {t("notifications.all")}
                </StyledTypography>
              </Box>
            </Box>
            <ProductDropDown
              title={t("generic.selectProducts")}
              handleOptionChange={setProducts}
              mid={store?.merchantId}
              marketplace={store?.marketplaceType}
              userInfo={userInfo}
              initProducts={
                products
                  ? products.map((productSku: string) => ({
                      productSku,
                    }))
                  : []
              }
              notificationType={notificationType}
              setNotificationType={setNotificationType}
            />
          </Box>
        </Grid>
      )}
      <Grid
        container
        item
        xs={2}
        md={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {showAlwaysOn ? (
          <>
            <Typography variant="body2">{t("switch.on")}</Typography>
            <Tooltip title="tooltip">
              <InlineIconButton />
            </Tooltip>
          </>
        ) : (
          <>
            {switchComponent(toggle)}
            <Typography variant="body2">
              {toggle.checked ? t("switch.on") : t("switch.off")}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={5} md={2}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("notificationSetting.emailFrequency")}
        </Typography>
        <PanelSelect
          {...{
            width: "165px",
            currentValue: FREQUENCY_OPTIONS.find((option: any) =>
              isEqual(option?.value, emailFrequency)
            )?.label,
            handleChange: handleChangeFrequency,
            options: FREQUENCY_OPTIONS,
            disabled: showAlwaysOn || !toggle.checked,
          }}
        />
      </Grid>
      {showAdvancedConfig &&
        setDaysCover &&
        notificationType === ProductNotificationType.LOW_INVENTORY && (
          <Grid container item xs={12} md={2}>
            <AdvancedConfigurationDaysCover
              fieldValue={daysCoverLimit || DAYS_COVER_UPPER_LIMIT}
              setDaysCover={setDaysCover}
              notificationType={notificationType}
              setNotificationType={setNotificationType}
            />
          </Grid>
        )}
    </SettingRow>
  ) : (
    <></>
  );
};

export default SettingsRow;
