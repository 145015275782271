import React, { memo } from "react";

import Medium from "~/components/typography/medium";
import styled from "styled-components";

interface StyleProps {
  $status: string;
  theme: any;
}

function getCellColor(theme: any, value: string) {
  if (value) {
    if (value === "CLOSED") {
      return theme.palette.success.main;
    }
    if (value === "NEW") {
      return theme.palette.primary.main;
    }
    if (value === "ACKNOWLEDGED") {
      return theme.palette.chart.darkBlue;
    }
  }
  return theme.palette.disabled.main;
}

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  max-width: 100px;
  padding: 1px;
  border: 1.5px solid
    ${({ theme, $status }: StyleProps) => getCellColor(theme, $status)};
  color: ${({ theme, $status }: StyleProps) => getCellColor(theme, $status)};
  margin: auto;
  border-radius: 15px;
`;

interface POStatusProps {
  value: string;
}

export const POStatus = ({ value }: POStatusProps) => (
  <Cell $status={value}>
    <Medium variant="body2" noWrap color="inherit">
      {value}
    </Medium>
  </Cell>
);

interface POStatusCellProps {
  cell: POStatusProps;
}
const POStatusCell: React.FC<POStatusCellProps> = ({ cell: { value } }) => {
  return <POStatus value={value} />;
};

export default memo(POStatusCell);
