import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  withTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LargeNumber = withTheme(styled(Typography)`
  ${({ theme }) => `
    font-size: 34px;
    ${theme.breakpoints.down("lg")} {
      font-size: 28px;
    }
    ${theme.breakpoints.down("md")} {
      font-size: 28px;
    }
    ${theme.breakpoints.down("sm")} {
      font-size: 22px;
    }
  `}
`);

export const TotalStores = memo(({ salesPerformance }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [totalStores, setTotalStores] = useState("-");

  useEffect(() => {
    const stores = salesPerformance ? salesPerformance.totalStores : "-";

    setTotalStores(stores);
  }, [salesPerformance]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={smDown ? "center" : "flex-start"}
      spacing={2}
      id="total-sales-wrapper"
    >
      <Grid item md={12}>
        <Typography variant="h3">
          {t("dashboardWidget.salesPerformance.totalStores")}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <LargeNumber variant="h1">{totalStores}</LargeNumber>
      </Grid>
      <Grid item md={12}>
        <Box height={19} />
        {/** Placeholder for alignment */}
      </Grid>
    </Grid>
  );
});

TotalStores.propTypes = {
  salesPerformance: PropTypes.object.isRequired,
};

export default TotalStores;
