import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import { connectAmazonVendor } from "../../store/connections/connectAmazon.redux";
import useAmazonAuthcodeAuth from "../../hooks/useAmazonAuthcodeAuth";
import { useDispatch } from "react-redux";

const AmazonAuthoriseVendor = (props) => {
  const { children, onSuccess, onError, url } = props;
  const dispatch = useDispatch();
  const onCode = React.useCallback(
    (state, selling_partner_id, spapi_oauth_code) => {
      dispatch(connectAmazonVendor(state, selling_partner_id, spapi_oauth_code))
        .then(() => {
          if (onSuccess) {
            onSuccess(selling_partner_id, state);
          }
        })
        .catch((e) => {
          if (onError) {
            onError(e);
          } else {
            throw e;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const triggerSignin = useAmazonAuthcodeAuth(onCode, url);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <>
      <RaisedButton color="primary" onClick={handleSignin} {...props}>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ margin: "auto" }}>{children}</div>
        </div>
      </RaisedButton>
    </>
  );
};

AmazonAuthoriseVendor.propTypes = {
  children: PropTypes.any,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  url: PropTypes.string.isRequired,
};

export default AmazonAuthoriseVendor;
