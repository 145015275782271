import { Box, Grid, Switch, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  upsertPersistentAppSettings,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import Alert from "~/components/alert/alertCard";
import { CustomGroup } from "~/typedef/customGroups";
import CustomGroupsSelect from "~/modules/widgets/customGroups/customGroupsSelect";
import CustomVendorGroups from "../vendorGroup/customVendorGroup";
import { Link } from "react-router-dom";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { Store } from "~/typedef/store";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useCustomGroupsQuery } from "~/store/customGroups/reducer.redux";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const CHART_TYPE = {
  GlanceView: "glanceViews",
  OrderedRevenue: "revenue",
  UnitsSold: "units",
};

interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

const CustomLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
  `}
`;

const GroupRevenue = memo(function GroupRevenue() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useTypedSelector((state) => state.user);
  const marketplace = useMarketplace();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currentViewPreference = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.viewPreference") ||
      "sourcing"
  );
  const [isSourcingView, setIsSourcingView] = useState(
    currentViewPreference === "sourcing"
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.OrderedRevenue,
        label: t("customVendorGroupsChart.orderedRevenueOption"),
      },
      {
        value: CHART_TYPE.GlanceView,
        label: t("customVendorGroupsChart.glanceViewOption"),
      },
      {
        value: CHART_TYPE.UnitsSold,
        label: t("customVendorGroupsChart.unitsSoldOption"),
      },
    ],
    []
  );

  const { customGroups, customGroupsFetching } = useCustomGroupsQuery(
    {
      mid: store?.merchantId || "",
      userId: userInfo?._id,
    },
    {
      skip: !store?.merchantId || !userInfo?._id,
      selectFromResult: ({ data, isFetching }) => ({
        customGroups: data?.groups || [],
        customGroupsFetching: isFetching,
      }),
    }
  );

  /** Local State */
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [selectedGroups, setSelectedGroups] = useState<CustomGroup[]>([]);

  useEffect(() => {
    if (!customGroupsFetching && customGroups.length > 0) {
      setSelectedGroups(customGroups);
    }
  }, [customGroups]);

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const updateViewPreference = useCallback((isSourcingView: boolean) => {
    dispatch(
      upsertPersistentAppSettings({
        organisationId: userInfo.organisationId,
        userId: userInfo._id,
        settings: {
          viewPreference: isSourcingView ? "sourcing" : "manufacturing",
        },
      })
    );
  }, []);

  const switchType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );

  const actions = useMemo(() => {
    return (
      <>
        <Box>
          <Typography variant="h6" noWrap>
            {t("customVendorGroups.createGroupInfo")}
            <CustomLink
              to={
                marketplaceLink(
                  marketplace,
                  store.merchantId,
                  "salesByGroup"
                ) as string
              }
            >
              {t("customVendorGroups.createGroupButton")}
            </CustomLink>
          </Typography>
        </Box>
        <Box pl={2} width={200}>
          <CustomGroupsSelect
            setSelected={setSelectedGroups}
            selected={selectedGroups}
            store={filteredStores?.stores?.find(
              (filteredStore: Store) =>
                filteredStore.merchantId === store?.merchantId
            )}
          />
        </Box>
        <Box pl={2}>
          <TableFilter
            {...{
              width: "120px",
              options: CHART_OPTIONS,
              currentValue: chartType.label,
              handleChange: switchType,
            }}
          />
        </Box>
      </>
    );
  }, [selectedGroups, customGroups, chartType.label]);

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Alert isOpen={true} type="info">
              {t("customGroups.updateDelayMessage")}
            </Alert>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="subtitle1" color="textPrimary" noWrap>
              {isSourcingView
                ? t("retailAnalytics.sourcingView")
                : t("retailAnalytics.manufacturingView")}
            </Typography>
            <Box pr={1}>
              <Switch
                {...{
                  size: "small",
                  checked: isSourcingView,
                  onClick: () => {
                    updateViewPreference(!isSourcingView);
                    setIsSourcingView(!isSourcingView);
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CustomVendorGroups
            userInfo={userInfo}
            actions={actions}
            selectedGroups={selectedGroups}
            chartType={{
              value: chartType.value,
              label: chartType.label,
            }}
            mid={store.merchantId}
            currentCompare={currentCompare}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            countryCode={store.marketplaceCountry}
            marketplace={marketplace}
            shopName={store.storeName}
            currentCurrency={currentCurrency}
            timezone={selectedTimezone}
            includeTax={includeTax}
            isSourcingView={isSourcingView}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default GroupRevenue;
