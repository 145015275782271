import {
  InventoryReplenishment,
  InventoryReplenishmentProduct,
  InventoryReplenishmentState,
  InventoryStatus,
} from "~/typedef/store";
import { TAG_TYPES, api } from "@store/apiSlice";
import { setError, setInfoMessage } from "../globalToast.redux";

import { DaysCoverAttributionWindow } from "~/modules/widgets/inventoryManagement/forecastWeigtingSetting";
import { Dispatch } from "redux";
import { TFunction } from "react-i18next";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { get } from "lodash";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const FETCH_INVENTORY_REPLENISHMENT_REQUEST =
  "FETCH_INVENTORY_REPLENISHMENT_REQUEST";
export const FETCH_INVENTORY_REPLENISHMENT_SUCCESS =
  "FETCH_INVENTORY_REPLENISHMENT_SUCCESS";

export const FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_REQUEST =
  "FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_REQUEST";
export const FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_SUCCESS =
  "FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_SUCCESS";

export const UPLOADED_CASE_CONFIGURATIONS = "UPLOADED_CASE_CONFIGURATIONS";
export const UPLOADING_CASE_CONFIGURATIONS = "UPLOADING_CASE_CONFIGURATIONS";
export const UPLOAD_CASE_CONFIGURATIONS_FAILED =
  "UPLOAD_CASE_CONFIGURATIONS_FAILED";

enum InventorySettingsTypes {
  INVENTORY_REPLENISHMENT = "inventoryReplenishment",
}

export enum SalesDemandSignalLevel {
  SKU = "sku",
  ASIN = "asin",
}

interface InventoryReplenishmentSettings {
  warehouseLeadTime: number; // in days
  daysCoverTarget: number; // in days
  minRestockCases: number;
  daysCoverAttributionWindow: DaysCoverAttributionWindow;
  salesDemandSignalLevel: SalesDemandSignalLevel;
}

const initState: InventoryReplenishmentState = {
  table: {
    loading: false,
  },
  products: {
    loading: false,
  },
  caseConfigUpload: {
    uploading: false,
  },
};

type FetchInventoryReplenishmentRequestAction = {
  type: typeof FETCH_INVENTORY_REPLENISHMENT_REQUEST;
};
type FetchInventoryReplenishmentSuccessAction = {
  type: typeof FETCH_INVENTORY_REPLENISHMENT_SUCCESS;
  payload: { rows: InventoryReplenishment[] | null; count: number };
};

type FetchInventoryReplenishmentProductsRequestAction = {
  type: typeof FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_REQUEST;
};
type FetchInventoryReplenishmentProductsSuccessAction = {
  type: typeof FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_SUCCESS;
  payload: InventoryReplenishmentProduct[];
};

type UploadCaseConfigRequestAction = {
  type: typeof UPLOADING_CASE_CONFIGURATIONS;
};
type UploadedCaseConfigSuccessAction = {
  type: typeof UPLOADED_CASE_CONFIGURATIONS;
};
type UploadCaseConfigFailedAction = {
  type: typeof UPLOAD_CASE_CONFIGURATIONS_FAILED;
};

type InventoryReplenishmentAction =
  | FetchInventoryReplenishmentRequestAction
  | FetchInventoryReplenishmentSuccessAction
  | FetchInventoryReplenishmentProductsRequestAction
  | FetchInventoryReplenishmentProductsSuccessAction
  | UploadedCaseConfigSuccessAction
  | UploadCaseConfigRequestAction
  | UploadCaseConfigFailedAction;

export const inventoryReplenishment = (
  state: InventoryReplenishmentState = initState,
  action: InventoryReplenishmentAction
): InventoryReplenishmentState => {
  switch (action.type) {
    case FETCH_INVENTORY_REPLENISHMENT_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      };

    case FETCH_INVENTORY_REPLENISHMENT_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          data: action.payload,
        },
      };

    case FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      };

    case FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data: action.payload,
        },
      };

    case UPLOADING_CASE_CONFIGURATIONS:
      return {
        ...state,
        caseConfigUpload: {
          uploading: true,
        },
      };

    case UPLOADED_CASE_CONFIGURATIONS:
      return {
        ...state,
        caseConfigUpload: {
          uploading: false,
        },
      };

    case UPLOAD_CASE_CONFIGURATIONS_FAILED:
      return {
        ...state,
        caseConfigUpload: {
          uploading: false,
        },
      };
    default:
      return state;
  }
};

export interface FetchInventoryReplenishmentRecordsProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  searchText?: string;
  sortKey?: string;
  sortOrder?: string;
  pageIndex: number;
  pageSize: number;
  activeProducts: boolean;
}
export const fetchInventoryReplenishmentRecords =
  ({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    sortKey,
    sortOrder,
    pageIndex,
    pageSize,
    searchText,
    activeProducts,
  }: FetchInventoryReplenishmentRecordsProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INVENTORY_REPLENISHMENT_REQUEST });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/amazon-mws-service/api/inventory/fbaInventory`,
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          countryCode,
          sortKey,
          sortOrder,
          searchText,
          pageIndex,
          pageSize,
          activeProducts,
        }
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: FETCH_INVENTORY_REPLENISHMENT_SUCCESS,
          payload: data,
        });
      } else {
        return setError(
          dispatch,
          get(data, "errMsg") ||
            "There was an error encountered while loading the inventory replenishment data",
          status,
          "InventoryReplenishment/Records/Get"
        );
      }
    } catch (error) {
      return setError(
        dispatch,
        get(error, "response.data.errMsg") ||
          "There was an error encountered while loading the inventory replenishment data",
        get(error, "response.status"),
        "InventoryReplenishment/Records/Get"
      );
    }
  };

export interface FetchInventoryReplenishmentProductsProps {
  user: User;
  mid: string;
  skus: string[];
}

export const fetchInventoryReplenishmentProducts =
  ({ user, mid, skus }: FetchInventoryReplenishmentProductsProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_REQUEST });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/productInfo`,
        {
          user: {
            _id: user._id,
          },
          mid,
          identifier: "sellerSku",
          skus,
        }
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: FETCH_INVENTORY_REPLENISHMENT_PRODUCTS_SUCCESS,
          payload: data,
        });
      } else {
        return setError(
          dispatch,
          get(data, "errMsg") ||
            "There was an error encountered while loading the inventory replenishment products",
          status,
          "InventoryReplenishment/Products/Get"
        );
      }
    } catch (error) {
      return setError(
        dispatch,
        get(error, "response.data.errMsg") ||
          "There was an error encountered while loading the inventory replenishment products",
        get(error, "response.status"),
        "InventoryReplenishment/Products/Get"
      );
    }
  };

export const uploadCaseConfig =
  (mid: string, file: File, t: TFunction) => async (dispatch: Dispatch) => {
    dispatch({ type: UPLOADING_CASE_CONFIGURATIONS });

    try {
      const formData = new FormData();

      formData.append("mid", mid);
      formData.append("file", file);

      const { data, status } = await axios.post(
        `${baseUrl}/api/myStores/inventory/csv`,
        formData
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("inventoryCaseConfig.uploadSuccessMessage"));
        return dispatch({
          type: UPLOADED_CASE_CONFIGURATIONS,
        });
      } else {
        setError(dispatch, data.errMsg, status, "uploadCaseConfig");
        return dispatch({
          type: UPLOAD_CASE_CONFIGURATIONS_FAILED,
        });
      }
    } catch (err) {
      setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status"),
        "uploadCaseConfig"
      );
      return dispatch({
        type: UPLOAD_CASE_CONFIGURATIONS_FAILED,
      });
    }
  };

interface FetchInventoryStatusArgs {
  mid: string;
  marketplaceType: string;
  marketplaceCountry: string;
  marketplaceSubtype: string;
}

interface FetchInventoryReplenishmentSettingsArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
}

interface FetchInventoryReplenishmentSettingsResponse {
  settings: InventoryReplenishmentSettings;
  updatedAt: string;
}

interface SetInventoryReplenishmentSettingsArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
  settings: InventoryReplenishmentSettings;
  lastUpdatedAt?: string;
}

interface SetInventoryReplenishmentSettingsResponse {
  settings: { settings: InventoryReplenishmentSettings; updatedAt: string };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    inventoryStatus: build.query<InventoryStatus, FetchInventoryStatusArgs>({
      query: (params) => {
        const { marketplaceCountry, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/amazon-mws-service/api/inventory/fbaInventorySummary`,
          method: "POST",
          data: {
            ...otherParams,
            countryCode: marketplaceCountry,
          },
        };
      },
      providesTags: [TAG_TYPES.FbaInventorySettings],
      onQueryStarted: globalQueryErrorHandler("InventoryStatus/Get"),
    }),

    inventoryReplenishmentSettings: build.query<
      FetchInventoryReplenishmentSettingsResponse,
      FetchInventoryReplenishmentSettingsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/user-management-service/api/organisation/settings`,
          method: "GET",
          params: {
            ...params,
            type: InventorySettingsTypes.INVENTORY_REPLENISHMENT,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "InventoryReplenishment/Settings/Get"
      ),
    }),

    setInventoryReplenishmentSettings: build.mutation<
      SetInventoryReplenishmentSettingsResponse,
      SetInventoryReplenishmentSettingsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/user-management-service/api/organisation/settings`,
          method: "POST",
          data: {
            ...params,
            type: InventorySettingsTypes.INVENTORY_REPLENISHMENT,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.FbaInventorySettings],
      onQueryStarted: globalQueryErrorHandler(
        "InventoryReplenishment/Settings/Set",
        false,
        (data: SetInventoryReplenishmentSettingsResponse, dispatch, args) => {
          // Updating the query data for the inventoryReplenishmentSettings query
          // saves having to make another request to get the updated data
          const {
            mid,
            marketplaceType,
            marketplaceSubtype,
            marketplaceCountry,
          } = args;
          dispatch(
            extendedApiSlice.util.updateQueryData(
              "inventoryReplenishmentSettings",
              {
                mid,
                marketplaceType,
                marketplaceSubtype,
                marketplaceCountry,
              },
              (draft) => {
                // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
                if (data?.settings) {
                  draft.settings = data.settings.settings;
                  draft.updatedAt = data.settings.updatedAt;
                }
              }
            )
          );
        }
      ),
    }),
  }),
});

export const {
  useInventoryStatusQuery,
  useInventoryReplenishmentSettingsQuery,
  useSetInventoryReplenishmentSettingsMutation,
} = extendedApiSlice;
