import { Range } from "~/typedef/store";
import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface SalesByCategoryArgs {
  mid: string;
  currentPeriod: string;
  currentRange: Range;
  includeTax: boolean;
  timezone: string | undefined;
}

interface SalesByCategoryRecord {
  productId: string;
  totalSales: string;
  quantity: string;
  orderCount: number | null;
  categoryId: string;
  categoryLabel: string;
  parentCategoryLabel: string;
  parent2CategoryLabel: string;
  parent3CategoryLabel: string;
  parent4CategoryLabel: string;
  currency: string;
}

export interface SalesByCategoryRow {
  current: SalesByCategoryRecord;
  prior: SalesByCategoryRecord;
}

type SalesByCategoryResponse = SalesByCategoryRow[];

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesByCategory: build.query<SalesByCategoryResponse, SalesByCategoryArgs>({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/salesByCategory`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SalesByCategory"),
    }),
  }),
});

export const { useSalesByCategoryQuery } = extendedApiSlice;
