import { TAG_TYPES, api } from "../apiSlice";
import axios, { AxiosRequestConfig } from "axios";

import { AdvertisingPortfolio } from "~/typedef/brandFilterConnection";
import { Dispatch } from "redux";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { setInfoMessage } from "../globalToast.redux";

export const NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_LOADING =
  "NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_LOADING ";
export const NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE =
  "NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE ";
export const NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_LOADING =
  "NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_LOADING ";
export const NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE =
  "NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE ";

interface ParentStoreData {
  parentStore: {
    _id: string;
    ownerUserId: string;
    mid: string;
    marketplaceType: string;
    marketplaceSubtype: string;
    countryCode: string;
    shopName: string;
    isDemoMode: boolean;
  };
  // IDs of connected portfolios, if it exists
  // i.e. this is a re-connection/change
  currentConnectedPortfolios: number[];
}

export interface NewBrandFilterStoreState {
  loading: boolean;
  parentStoreLoading: boolean;
  portfolios: AdvertisingPortfolio[] | null;
  parentStore: ParentStoreData | null;
}

type NewBrandFilterStoreAction =
  | { type: typeof NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_LOADING }
  | {
      type: typeof NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE;
      payload: AdvertisingPortfolio[];
    }
  | {
      type: typeof NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_LOADING;
    }
  | {
      type: typeof NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE;
      payload: any;
    };

interface ParentStoreParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
}

const initState: NewBrandFilterStoreState = {
  loading: false,
  parentStoreLoading: false,
  portfolios: null,
  parentStore: null,
};

export const newBrandFilterStore = (
  state: NewBrandFilterStoreState = initState,
  action: NewBrandFilterStoreAction
): NewBrandFilterStoreState => {
  switch (action.type) {
    case NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_LOADING:
      return {
        ...state,
        portfolios: null,
        loading: true,
      };
    case NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE:
      return {
        ...state,
        portfolios: action.payload,
        loading: false,
      };
    case NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_LOADING:
      return { ...state, parentStore: null, parentStoreLoading: true };
    case NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE:
      return {
        ...state,
        parentStore: action.payload,
        parentStoreLoading: false,
      };
    default:
      return state;
  }
};

interface FetchStoreParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
}
export const fetchPortfolios =
  ({
    mid,
    marketplaceType,
    marketplaceSubtype,
    marketplaceCountry,
  }: FetchStoreParams) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        method: "GET",
        url: `${baseUrl}/auth/brandFilter/portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          mid,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry,
        },
      };
      const { status, data } = await axios(options);

      if (isHttpResponseValid(status)) {
        dispatch({
          type: NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE,
          payload: data,
        });
      }
    } catch (err) {
      dispatch({
        type: NEW_BRAND_FILTER_STORE_GET_PORTFOLIO_DONE,
      });
      return setError(dispatch, (err as Error).message);
    }
  };

export const fetchParentStore =
  ({
    mid,
    marketplaceType,
    marketplaceSubtype,
    marketplaceCountry,
  }: FetchStoreParams) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        method: "GET",
        url: `${baseUrl}/auth/brandFilter/parentStore`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          mid,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry,
        },
      };
      const { status, data } = await axios(options);

      if (isHttpResponseValid(status)) {
        dispatch({
          type: NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE,
          payload: data,
        });
      }
    } catch (err) {
      dispatch({
        type: NEW_BRAND_FILTER_STORE_GET_PARENT_STORE_DONE,
      });
      return setError(dispatch, (err as Error).message);
    }
  };

interface ConnectBrandFilterAdvertisingParams {
  portfolioIds: number[];
  storeParams: FetchStoreParams;
  parentStoreParams: ParentStoreParams;
}

interface AddBrandFilterStoreParams {
  groupId: number;
  parentStoreParams: ParentStoreParams;
  successMessage: string;
}

interface AddBrandFilterStoreRes {
  mid: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    connectBrandFilterAdvertising: build.mutation<
      { status: number },
      ConnectBrandFilterAdvertisingParams
    >({
      query: (params) => {
        return {
          method: "POST",
          url: `${baseUrl}/auth/brandFilter/connectAdvertising`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      invalidatesTags: [TAG_TYPES.MarketingCampaigns],
    }),
    connectBrandFilter: build.mutation<
      AddBrandFilterStoreRes,
      AddBrandFilterStoreParams
    >({
      query: (params) => ({
        url: `${baseUrl}/auth/brandFilter/add`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...params,
        },
      }),
      invalidatesTags: [TAG_TYPES.CustomGroups],
      onQueryStarted: globalQueryErrorHandler(
        "ConnectBrandFilter",
        false,
        (_data: string, dispatch, args: AddBrandFilterStoreParams) => {
          setInfoMessage(dispatch, args.successMessage);
        }
      ),
    }),
  }),
});

export const {
  useConnectBrandFilterAdvertisingMutation,
  useConnectBrandFilterMutation,
} = extendedApiSlice;
