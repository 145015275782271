import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import PropTypes from "prop-types";
import styled from "styled-components";

const MyOffer = styled(Bold)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const PriceCell = memo(({ cell }) => {
  const value = cell.value;
  const { isAmazon, isMyOffer } = cell.row.original;

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        {isAmazon ? (
          <Bold variant="body2">{value}</Bold>
        ) : isMyOffer ? (
          <MyOffer variant="body2">{value}</MyOffer>
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </Grid>
    </Grid>
  );
});

PriceCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    value: PropTypes.any,
  }).isRequired,
};

export default PriceCell;
