import { api } from "@store/apiSlice";
import { baseUrl } from "../configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";
import moment from "moment-timezone";

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    appStatus: build.query<{ down: boolean; lastFetched: string }, {}>({
      query: () => {
        return {
          url: `${baseUrl}/api/statuspage`,
          method: "GET",
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: (data: boolean) => ({
        down: data,
        lastFetched: moment().toISOString(),
      }),
    }),
  }),
});

export const { useAppStatusQuery } = extendedApiSlice;
