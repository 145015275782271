import {
  connectionStatus as CONNECTION_STATUS,
  getConnectionStatus,
} from "~/utils/marketplaceUtils";
import { FormControl, Typography } from "@material-ui/core";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Country } from "country-code-lookup";
import CountrySelect from "~/components/select/countrySelect";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import { StoreState } from "@typedef/store";
import TextField from "@material-ui/core/TextField";
import { baseUrl } from "../../configs";
import crossLogo from "../../img/cross_large.png";
import get from "lodash/get";
import { loadInfo } from "~/store/user.redux";
import moment from "moment-timezone";
import { setError } from "../../store/globalToast.redux";
import styled from "styled-components";
import tickLogo from "../../img/tick_large.png";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import useWoocommerceAuthcodeAuth from "../../hooks/useWoocommerceAuthcodeAuth";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
  width: 80%;
  max-width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const LinkWrapper = styled(Typography)`
  margin-bottom: 20px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

interface AuthUrlParams {
  storeUrl: string;
  userId: string;
  shopName: string;
  countryCode: string;
}

/** Given a user-provided WooCommerce storeUrl, which might be in the formats:
 * woocommerce-store.example.com
 * https://woocommerce-store.example.com
 * https://woocommerce-store.example.com/
 * etc,
 * strip it down and return a standardised mid in the form
 * woocommerce-store.example.com */
const normaliseMid = (storeUrl: string): string => {
  const stripSchemePrefix = (val: string) =>
    val.replace(/^http:\/\/|https:\/\//g, "");

  const stripTrailingSlash = (val: string) => val.replace(/\/$/, "");

  return stripTrailingSlash(stripSchemePrefix(storeUrl));
};

const buildAuthUrl = (params: AuthUrlParams): string => {
  const { storeUrl, userId, shopName, countryCode } = params;
  if (!storeUrl) return "";
  let url;
  // this will cover http and https
  if (storeUrl.startsWith("http")) {
    url = new URL(`${storeUrl}/wc-auth/v1/authorize`);
    url.protocol = "https";
  } else {
    url = new URL(`https://${storeUrl}/wc-auth/v1/authorize`);
  }
  const userPayload = Buffer.from(
    JSON.stringify({
      userId,
      shopName,
      countryCode,
      mid: storeUrl,
    })
  );

  const authUrlSearchParams = new URLSearchParams({
    app_name: "MerchantSpring Marketplace Manager",
    scope: "read",
    return_url: process.env.WOOCOMMERCE_RETURN_URL || "",
    callback_url: process.env.WOOCOMMERCE_CALLBACK_URL || "",
    user_id: userPayload.toString("base64"),
  });

  url.search = authUrlSearchParams.toString();
  return url.toString();
};

const WoocommerceConnectPage = (props: { onSuccess: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StoreState) => state.user);
  const disconnectedStores = useTypedSelector(
    (state) => state.connections?.disconnectedStores?.stores || []
  );
  const [message, setMessage] = useState("");
  const [connectionStatus, setConnectionStatus] = useState(
    CONNECTION_STATUS.CONNECTION_INIT
  );
  const [storeUrl, setStoreUrl] = useState("");
  const [shopName, setShopName] = useState("");
  const [country, setCountry] = useState<Country | null>(null);
  const [authUrl, setAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    storeUrl: "",
    shopName: "",
  });

  useEffect(() => {
    try {
      setErrors({
        ...errors,
        storeUrl: "",
      });
      setAuthUrl(
        buildAuthUrl({
          storeUrl,
          shopName,
          countryCode: country ? country.iso3 : "",
          userId: user._id,
        })
      );
    } catch (err) {
      setErrors({
        ...errors,
        storeUrl: t("connectWizard.woocommerceInvalidUrlError"),
      });
    }
  }, [storeUrl, shopName, country, user]);

  const onCallbackDone = (response: { success: string; user_id: string }) => {
    if (parseInt(get(response, "success", "0"))) {
      setConnectionStatus(CONNECTION_STATUS.CONNECTION_SUCCEED);
      setLoading(true);
    } else {
      setConnectionStatus(CONNECTION_STATUS.CONNECTION_FAILED);
      setMessage(t("connectWizard.woocommerceTryAgainError"));
    }
  };

  useEffect(() => {
    const dispatchFetchUser = () => dispatch(loadInfo());
    const fetchData = async () => {
      if (connectionStatus === CONNECTION_STATUS.CONNECTION_SUCCEED) {
        await dispatchFetchUser();
      }
    };

    const fetchUser = () => {
      fetchData().catch((err) =>
        setError(dispatch, err, err.status, "woofetchUser")
      );
    };
    fetchUser();
    // eslint-disable-next-line no-magic-numbers
    const interval = setInterval(
      fetchUser,
      moment.duration(10, "seconds").asMilliseconds()
    );
    return () => clearInterval(interval);
  }, [connectionStatus]);

  useEffect(() => {
    if (connectionStatus === CONNECTION_STATUS.CONNECTION_SUCCEED) {
      setLoading(false);
      const { shopStatus } = getConnectionStatus(
        disconnectedStores,
        "woocommerce",
        normaliseMid(storeUrl)
      );
      if (shopStatus) {
        props.onSuccess();
      } else {
        setConnectionStatus(CONNECTION_STATUS.CONNECTION_FAILED);
        setMessage(t("connectWizard.woocommerceTryAgainError"));
      }
    }
  }, [disconnectedStores, connectionStatus]);

  const onCountryChange = (
    _event: ChangeEvent<{}>,
    value: any,
    _reason: string
  ) => {
    setCountry(value);
  };

  const triggerWoocommerceSignin = useWoocommerceAuthcodeAuth(
    onCallbackDone,
    authUrl
  );

  const handleConnectBtn = (event: FormEvent) => {
    event.preventDefault();

    if (!shopName) {
      setErrors({
        ...errors,
        shopName: t("connectWizard.woocommerceEmptyShopNameError"),
      });
      return;
    }

    triggerWoocommerceSignin();
  };

  const renderConnectForm = () => (
    <Form method="POST" onSubmit={(event) => handleConnectBtn(event)}>
      <FormControl>
        <TextField
          error={errors.storeUrl.length > 0}
          helperText={errors.storeUrl}
          fullWidth
          label={t("connectWizard.woocommerceStoreUrlLabel")}
          placeholder={t("connectWizard.woocommerceStoreUrlPlaceholder")}
          margin="normal"
          required
          onChange={(e) => {
            setStoreUrl(e.target.value);
          }}
        />
      </FormControl>
      {/*  <LinkWrapper align="right">
        <Link href={t("connectWizard.woocommerceHelpLinkUrl")} target="_blank">
          {t("connectWizard.woocommerceHelpLink")}
        </Link>
      </LinkWrapper> */}
      <FormControl>
        <CountrySelect
          value={country}
          onChange={onCountryChange}
          label={t("connectWizard.woocommerceCountryCodeLabel")}
        />
      </FormControl>
      <FormControl>
        <Typography variant="body2">
          {t("connectWizard.shopNameTitle")}
        </Typography>
        <AccountNameWrapper
          error={errors.shopName.length > 0}
          helperText={errors.shopName}
          fullWidth
          label={t("connectWizard.woocommerceShopNameLabel")}
          margin="normal"
          required
          onChange={(e) => {
            setShopName(e.target.value);
          }}
        />
      </FormControl>
      <ButtonWrapper>
        <RaisedButton
          disabled={!shopName || !storeUrl || !country}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("connectWizard.connectButton")}
        </RaisedButton>
      </ButtonWrapper>
    </Form>
  );

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item>
            <LogoWrapper
              src={`${baseUrl}/static/icons/woocommerce_expanded.jpg`}
            />
          </Grid>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Grid container direction="column" alignItems="center">
              {connectionStatus === CONNECTION_STATUS.CONNECTION_INIT && (
                <Grid
                  container
                  item
                  xs={11}
                  sm={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" align="center">
                    {t("connectWizard.woocommerceLinkTitle")}
                  </Typography>
                </Grid>
              )}
              {message && (
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon>
                    <img
                      src={
                        connectionStatus ===
                        CONNECTION_STATUS.CONNECTION_SUCCEED
                          ? tickLogo
                          : crossLogo
                      }
                    />
                  </Icon>
                  <Typography variant="body1" align="center">
                    {message}
                  </Typography>
                </Grid>
              )}
              {connectionStatus !== CONNECTION_STATUS.CONNECTION_SUCCEED &&
                renderConnectForm()}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WoocommerceConnectPage;
