import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";

export const FETCH_REPORT_DOWNLOAD_RECENT_JOB =
  "FETCH_REPORT_DOWNLOAD_RECENT_JOB";
export const FETCH_REPORT_DOWNLOAD_RECENT_JOB_FETCHING =
  "FETCH_REPORT_DOWNLOAD_RECENT_JOB_FETCHING";

export const FETCH_REPORT_DOWNLOAD_JOB_STATUS =
  "FETCH_REPORT_DOWNLOAD_JOB_STATUS";
export const FETCH_REPORT_DOWNLOAD_JOB_STATUS_FETCHING =
  "FETCH_REPORT_DOWNLOAD_JOB_STATUS_FETCHING";

export const SUBMIT_REPORT_DOWNLOAD_GENERATE =
  "SUBMIT_REPORT_DOWNLOAD_GENERATE";
export const SUBMIT_REPORT_DOWNLOAD_GENERATE_FETCHING =
  "SUBMIT_REPORT_DOWNLOAD_GENERATE_FETCHING";

const initState = {
  recentJob: {
    data: {},
    fetching: false,
  },
  newJob: {
    data: {},
    fetching: false,
  },
};

export const reportDownload = (state = initState, action) => {
  switch (action.type) {
    case FETCH_REPORT_DOWNLOAD_RECENT_JOB:
      return {
        ...state,
        recentJob: {
          data: action.payload,
          fetching: false,
        },
      };

    case FETCH_REPORT_DOWNLOAD_JOB_STATUS:
    case SUBMIT_REPORT_DOWNLOAD_GENERATE:
      return {
        ...state,
        newJob: {
          data: action.payload,
          fetching: false,
        },
      };

    case FETCH_REPORT_DOWNLOAD_RECENT_JOB_FETCHING:
      return {
        ...state,
        recentJob: {
          data: {},
          fetching: true,
        },
      };

    case FETCH_REPORT_DOWNLOAD_JOB_STATUS_FETCHING:
    case SUBMIT_REPORT_DOWNLOAD_GENERATE_FETCHING:
      return {
        ...state,
        newJob: {
          data: {},
          fetching: true,
        },
      };

    default:
      return state;
  }
};

export const fetchRecentJob = (mid, reportType) => async (dispatch) => {
  dispatch({ type: FETCH_REPORT_DOWNLOAD_RECENT_JOB_FETCHING });

  try {
    const { data, status } = await axios.get(
      `${baseUrl}/api/reportDownload/recentJob`,
      {
        params: {
          mid,
          reportType,
        },
      }
    );

    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: FETCH_REPORT_DOWNLOAD_RECENT_JOB,
        payload: data,
      });
    } else {
      return setError(
        dispatch,
        data.errMsg,
        status,
        "reportDownload/recentJob"
      );
    }
  } catch (err) {
    return setError(
      dispatch,
      get(err, "response.data.errMsg"),
      get(err, "response.status"),
      "reportDownload/recentJob"
    );
  }
};

export const fetchJobStatus = (mid, jobId) => async (dispatch) => {
  dispatch({ type: FETCH_REPORT_DOWNLOAD_JOB_STATUS_FETCHING });

  try {
    const { data, status } = await axios.get(
      `${baseUrl}/api/reportDownload/jobStatus`,
      {
        params: {
          mid,
          jobId,
        },
      }
    );

    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: FETCH_REPORT_DOWNLOAD_JOB_STATUS,
        payload: data,
      });
    } else {
      return setError(
        dispatch,
        data.errMsg,
        status,
        "reportDownload/jobStatus"
      );
    }
  } catch (err) {
    return setError(
      dispatch,
      get(err, "response.data.errMsg"),
      get(err, "response.status"),
      "reportDownload/jobStatus"
    );
  }
};

export const generateReport = (mid, path, params) => async (dispatch) => {
  dispatch({ type: SUBMIT_REPORT_DOWNLOAD_GENERATE_FETCHING });

  try {
    const { data, status } = await axios.post(
      `${baseUrl}/api/reportDownload/generate`,
      {
        mid,
        path,
        params,
      }
    );

    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: SUBMIT_REPORT_DOWNLOAD_GENERATE,
        payload: data,
      });
    } else {
      return setError(dispatch, data.errMsg, status, "reportDownload/generate");
    }
  } catch (err) {
    return setError(
      dispatch,
      get(err, "response.data.errMsg"),
      get(err, "response.status"),
      "reportDownload/generate"
    );
  }
};
