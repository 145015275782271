import { Box, Grid } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import {
  getListingQualityMetrics,
  getListingQualitySummaryPercent,
} from "@merchantspring/common";
import { useDispatch, useSelector } from "react-redux";

import NoData from "~/components/loadingIndicator/noData";
import Panel from "~/components/panel/panel.tsx";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import PropTypes from "prop-types";
import ScoreDetails from "~/modules/widgets/listingQuality/scoreDetails";
import StatusIndicator from "../../../components/statusIndicator/statusIndicator";
import get from "lodash/get";
import { loadListingQuality } from "~/store/listingQuality.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import min from "lodash/min";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

const DISPLAY_ITEMS = 4;

const ListingQualityContent = memo(
  ({ scores, itemsInfo, itemSpecificScores }) => {
    const { t } = useTranslation();
    const selectedTimezone = useSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const metrics = getListingQualityMetrics(scores, itemSpecificScores);
    const summaryPercent = getListingQualitySummaryPercent(metrics);
    const updateTime = min(
      itemsInfo.map((item) => new Date(item.UpdateDate || item.CreateDate))
    );

    const time = updateTime
      ? moment.tz(updateTime, selectedTimezone).calendar()
      : "Unknown";

    const summaryStatus =
      summaryPercent > 80
        ? "success"
        : summaryPercent > 50
        ? "warning"
        : summaryPercent > 0
        ? "error"
        : "disabled";

    return (
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justifyContent="center">
            <StatusIndicator
              status={summaryStatus}
              statusScore={isFinite(summaryPercent) ? `${summaryPercent}%` : ""}
              title={t(
                "myStoresWidget.listingQuality.statusIndicator.mainTitle"
              )}
              subtitle={t(
                "myStoresWidget.listingQuality.statusIndicator.subTitle",
                { time }
              )}
              footnote={t(
                "myStoresWidget.listingQuality.statusIndicator.footnote",
                { numListings: numberWithCommas(itemsInfo.length) }
              )}
            />
          </Grid>
          <Grid xs={12} item>
            <ScoreDetails metrics={metrics.slice(0, DISPLAY_ITEMS)} />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

const ListingQuality = memo(({ market, mid, countryCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listingQuality = useSelector((state) => get(state, "listingQuality"));
  const itemSpecifics = useSelector((state) => get(state, "itemSpecifics"));

  const footerLink = {
    url: marketplaceLink(market, mid, "listingquality"),
    label: t("generic.viewAllLink"),
  };

  const scores = listingQuality.scores;
  const itemsInfo = listingQuality.itemsInfo;
  const itemSpecificScores = itemSpecifics.categoryScores;

  useEffect(() => {
    dispatch(loadListingQuality(mid, countryCode));
  }, [dispatch, mid, countryCode]);

  return (
    <Panel
      id="widget-listing-quality"
      title={t("myStoresWidget.listingQuality.mainTitle")}
      footerLink={footerLink}
      content={
        itemSpecifics?.loading || listingQuality?.loading ? (
          <PanelLoading />
        ) : !scores || !itemSpecificScores || !itemsInfo ? (
          <NoData />
        ) : (
          <ListingQualityContent
            {...{
              scores,
              itemsInfo,
              itemSpecificScores,
            }}
          />
        )
      }
    />
  );
});

ListingQuality.propTypes = {
  market: PropTypes.string.isRequired,
  mid: PropTypes.string.isRequired,
};

export default ListingQuality;
