import { Box, Grid } from "@material-ui/core";
import React, { ReactChild, memo } from "react";
import MultiBarChart from "../../components/charts/barChart/barChartMulti";
import MultiLineChartGeneric from "~/components/charts/multiLineChart/multiLineChartGeneric";
import BarChartNoData from "~/components/placeholders/barChartNoData";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import {
  CurrencyRate,
  Range,
  Filter,
  ShippedRevenueTrend,
  MarketplaceFilters,
} from "~/typedef/store";
import {
  useShippedTrendQuery,
  useShippedRevenueTotalsQuery,
} from "~/store/mystore/vendor.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import GenericTotal from "~/components/totals/genericTotal";
import TotalSales from "~/components/totals/totalSales";
import { getConvertedValue } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";

export interface ChartOption {
  value: string;
  label: string;
}

export const CHART_TYPE = {
  Revenue: "revenue",
  COGS: "cogs",
  NetPureProductMargin: "netPureProductMargin",
  Units: "units",
};

const CHART_TYPE_LEGENDS_MAPPING = {
  [CHART_TYPE.Revenue]: "chartLegends.revenue",
  [CHART_TYPE.COGS]: "chartLegends.cogs",
  [CHART_TYPE.NetPureProductMargin]: "chartLegends.netPureProductMargin",
  [CHART_TYPE.Units]: "chartLegends.units",
};

interface ShippedRevenueParams {
  mid?: string;
  marketplaceFilters?: MarketplaceFilters;
  displayTotals?: boolean;
  currentFilter?: Filter;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  chartType: ChartOption;
  actions?: ReactChild;
  view: "sourcing" | "manufacturing";
  condensed?: boolean;
  report?: boolean;
  timezone: string;
  isLineChart?: boolean;
}

const formatShippedRevenueData = (
  shippedRevenueData: ShippedRevenueTrend | undefined,
  currentCurrency: string,
  currency = DEFAULT_CURRENCY,
  currencyRates: CurrencyRate[],
  chartType: ChartOption
) => {
  if (!shippedRevenueData) {
    return {
      manufacturing: [],
      sourcing: [],
    };
  }

  const type = chartType?.value || CHART_TYPE.Revenue;
  const manufacturing = shippedRevenueData.manufacturing;
  const sourcing = shippedRevenueData.sourcing;

  const formattedManufacturing =
    manufacturing?.length > 0
      ? manufacturing.map((data) => {
          switch (type) {
            case CHART_TYPE.Revenue:
            case CHART_TYPE.COGS:
              return {
                startTime: data.startTime,
                endTime: data.endTime,
                current: data.current?.[type]
                  ? getConvertedValue(
                      currencyRates,
                      currency,
                      currentCurrency,
                      data.current[type]
                    )
                  : data.current?.[type],
                prior: data.prior?.[type]
                  ? getConvertedValue(
                      currencyRates,
                      currency,
                      currentCurrency,
                      data.prior[type]
                    )
                  : data.prior?.[type],
              };
            default:
              return {
                startTime: data.startTime,
                endTime: data.endTime,
                current: data.current?.[type],
                prior: data.prior?.[type],
              };
          }
        })
      : [];

  const formattedSourcing =
    sourcing?.length > 0
      ? sourcing.map((data) => {
          switch (type) {
            case CHART_TYPE.Revenue:
            case CHART_TYPE.COGS:
              return {
                startTime: data.startTime,
                endTime: data.endTime,
                current: data.current?.[type]
                  ? getConvertedValue(
                      currencyRates,
                      currency,
                      currentCurrency,
                      data.current[type]
                    )
                  : data.current?.[type],
                prior: data.prior?.[type]
                  ? getConvertedValue(
                      currencyRates,
                      currency,
                      currentCurrency,
                      data.prior[type]
                    )
                  : data.prior?.[type],
              };
            default:
              return {
                startTime: data.startTime,
                endTime: data.endTime,
                current: data.current?.[type],
                prior: data.prior?.[type],
              };
          }
        })
      : [];

  return {
    manufacturing: formattedManufacturing,
    sourcing: formattedSourcing,
  };
};

const ShippedRevenueTrend = memo<ShippedRevenueParams>(
  function ShippedRevenueTrend({
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    chartType,
    actions,
    view,
    condensed,
    report,
    timezone,
    isLineChart,
    currentFilter,
    marketplaceFilters,
    displayTotals = false,
  }) {
    const showSingleChannelTrend = !!mid;

    const { t } = useTranslation();
    const { fromDate, interval, toDate } = currentRange;

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const totals = useShippedRevenueTotalsQuery(
      {
        currentRange,
        currentFilter,
        marketplaceFilters,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            shippedRevenueTotals: data,
            isLoading: isFetching,
            lastReportDate: data?.lastReportDate,
            lastUpdatedAt: data?.lastUpdatedAt,
            currency: data?.currency,
          };
        },
        skip: !displayTotals,
      }
    );

    const { chartData, lastReportDate, lastUpdatedAt, isLoading } =
      useShippedTrendQuery(
        {
          mid,
          currentRange,
          currentFilter,
          marketplaceFilters,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            isLoading: isFetching,
            lastReportDate: data?.lastReportDate,
            lastUpdatedAt: data?.lastUpdatedAt,
            chartData: formatShippedRevenueData(
              data?.revenueChartData,
              currentCurrency,
              data?.currency,
              currencyRates,
              chartType
            ),
          }),
        }
      );

    const footerLink =
      showSingleChannelTrend && condensed && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "shippedrevenue"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    const getTotals = () => {
      if (!totals) return;

      const { currency, isLoading, shippedRevenueTotals } = totals;

      if (isLoading || !shippedRevenueTotals) return;

      const totalsByView = shippedRevenueTotals[view];

      switch (chartType.value) {
        case CHART_TYPE.Revenue:
          return (
            <TotalSales
              {...totalsByView.shippedRevenue}
              currentPeriod={currentPeriod}
              currency={currency}
              customTitle={t("shippedRevenueTrend.totalRevenueTitle")}
              currentCurrency={currentCurrency}
            />
          );

        case CHART_TYPE.COGS:
          return (
            <TotalSales
              {...totalsByView.shippedCogs}
              currency={currency}
              currentPeriod={currentPeriod}
              customTitle={t("shippedRevenueTrend.totalCoggsTitle")}
              currentCurrency={currentCurrency}
            />
          );

        case CHART_TYPE.Units:
          return (
            <GenericTotal
              number={totalsByView.shippedUnits.current}
              comparison={{
                prior: totalsByView.shippedUnits.prior,
              }}
              title={t(`shippedRevenueTrend.totalUnitsTitle`)}
            />
          );

        case CHART_TYPE.NetPureProductMargin:
          return (
            <GenericTotal
              number={totalsByView.netPureProductMargin.current}
              comparison={{
                prior: totalsByView.netPureProductMargin.prior,
              }}
              title={t(`shippedRevenueTrend.netPPMTitle`)}
              percentage
            />
          );
      }
    };

    return (
      <Panel
        id="widget-sales-performance"
        title={
          report
            ? `${t("chartTitle.ShippedRevenueTrend")} - ${chartType.label}`
            : t("chartTitle.ShippedRevenueTrend")
        }
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t(`retailAnalytics.${view}View`)} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t(`retailAnalytics.${view}View`)}`
        }
        tooltip={
          showSingleChannelTrend
            ? t("shippedRevenueTrend.mainTooltip")
            : t("shippedRevenueTrend.multiChannelMainTooltip")
        }
        actions={actions}
        footerLink={footerLink}
        content={
          totals?.isLoading || isLoading ? (
            <PanelLoading />
          ) : !chartData?.manufacturing.length ||
            !chartData?.sourcing.length ? (
            <BarChartNoData {...{ currentPeriod: currentPeriod }} />
          ) : (
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid
                  container
                  item
                  xs={12}
                  md={displayTotals ? 9 : undefined}
                  lg={displayTotals ? 10 : undefined}
                >
                  {!isLineChart ? (
                    <MultiBarChart
                      {...{
                        chartData: chartData[view],
                        currentPeriod,
                        currentCurrency,
                        fromDate,
                        toDate,
                        interval,
                        report,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "revenue"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.NetPureProductMargin,
                          CHART_TYPE.Units,
                        ].includes(chartType?.value),
                        valueSuffix:
                          chartType?.value === CHART_TYPE.NetPureProductMargin
                            ? "%"
                            : "",
                        ...(chartType?.value === CHART_TYPE.NetPureProductMargin
                          ? {
                              percentageChangeFormatting: { isPptChange: true },
                            }
                          : null),
                      }}
                    />
                  ) : (
                    <MultiLineChartGeneric
                      {...{
                        chartData: chartData[view],
                        currentPeriod,
                        currency: currentCurrency,
                        fromDate,
                        toDate,
                        interval,
                        report,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "revenue"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.NetPureProductMargin,
                          CHART_TYPE.Units,
                        ].includes(chartType?.value),
                        valueSuffix:
                          chartType?.value === CHART_TYPE.NetPureProductMargin
                            ? "%"
                            : "",
                        ...(chartType?.value === CHART_TYPE.NetPureProductMargin
                          ? {
                              percentageChangeFormatting: { isPptChange: true },
                            }
                          : null),
                      }}
                    />
                  )}
                </Grid>
                {!!totals && (
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    alignItems="center"
                  >
                    {getTotals()}
                  </Grid>
                )}
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default ShippedRevenueTrend;
