export const bolHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    items: [],
  },
  shippingPerformance: {
    colspan: 4,
    items: [
      {
        key: "onTimeDeliveryRate",
        title: "dashboardWidget.accountHealth.onTimeDeliveryRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "validTrackingRate",
        title: "dashboardWidget.accountHealth.bol.validTrackingRate",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.validTrackingRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "cancelRate",
        title: "dashboardWidget.accountHealth.cancelRate",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.cancelRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "returnRate",
        title: "dashboardWidget.accountHealth.returnRequests",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.returnRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
    ],
  },
  customerServicePerformance: {
    colspan: 4,
    items: [
      {
        key: "reviewScore",
        title: "dashboardWidget.accountHealth.reviewScore",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.reviewScore",
        units: "dashboardWidget.accountHealth.units.reviews",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "phoneAvailability",
        title: "dashboardWidget.accountHealth.phoneAvailability",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.phoneAvailability",
        suffix: "%",
        units: "dashboardWidget.accountHealth.units.calls",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "supportCaseRate",
        title: "dashboardWidget.accountHealth.supportCaseRate",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.supportCaseRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "responseTime",
        title: "dashboardWidget.accountHealth.bol.responseTime",
        tooltip: "dashboardWidget.accountHealth.tooltip.bol.responseTime",
        suffix: "%",
        units: "dashboardWidget.accountHealth.units.cases",
        displayTimePeriod: true,
        displayTotals: true,
      },
    ],
  },
};
