import { MenuItem, Typography } from "@material-ui/core";
import React, { memo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MenuItem as MenuItemType } from "../sideNavigation/sideNavConstants";
import { StoreSelector } from "./storeSelector";
import { StoreState } from "~/typedef/store";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { isRestricted } from "../sideNavigation/sideNavUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const StoreMenuItem = styled(MenuItem)`
  width: ${({ width }: { width: number | null | undefined }) => width}px;
`;

export const WrapText = styled(Typography)`
  margin-left: 8px;
  white-space: pre-wrap;
`;

export const ButtonMenuItem = styled(MenuItem)`
  width: ${({ width }: { width: number | null | undefined }) => width}px;
  display: flex;
  border-top: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
  align-items: flex-start;
`;

const MyStoresMenu = memo(function MyStoresMenu() {
  const dispatch = useDispatch();

  const user = useTypedSelector((state) => state.user);
  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );

  const location = useLocation();
  const history = useHistory();

  const clearFilters = () => {
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            currentFilter: {
              marketplaces: [],
              countries: [],
              tags: [],
            },
          },
        },
        user?.isDemoMode ? false : true
      )
    );
  };

  const changeStore = (
    market: string,
    mid: string,
    menuItems: MenuItemType[]
  ) => {
    // Our routes are set up so that the path segment corresponding to a
    // menu item in the sidebar is always in the same position in the URL
    // If you're at a path deeper than that, you get taken back to the
    // "main screen" for your particular menu item instead
    const MENU_ITEM_PATH_DEPTH = 3;
    const pathSegments = location.pathname.split("/");
    const page = pathSegments[MENU_ITEM_PATH_DEPTH];
    if (isRestricted(market, page, menuItems)) {
      // the "!" is here to convince TypeScript that the function always
      // returns a non-null value - this assertion should always be true
      // as long as the restrictLinks param is not passed to marketplaceLink
      history.push(marketplaceLink(market, mid)!);
    } else {
      history.push(marketplaceLink(market, mid, page)!);
    }
  };

  return (
    <StoreSelector
      changeStore={changeStore}
      currentStore={currentStore}
      currentFilter={currentFilter}
      clearFilters={clearFilters}
    />
  );
});

export default MyStoresMenu;
