import React, { memo } from "react";

import { Box } from "@material-ui/core";
import PanelSelect from "../../select/panelSelect";
import styled from "styled-components";

const SelectWrapper = styled(Box)`
  & button {
    text-transform: uppercase;
    margin: ${({ margin }) => margin};
  }
`;

interface TableFilterProps {
  currentValue: string;
  handleChange: (value: string) => void;
  options: {
    value: string | null;
    label: string;
  }[];
  width?: string;
  margin?: string;
}

export const TableFilter = memo(
  ({
    currentValue,
    handleChange,
    options,
    width,
    margin,
  }: TableFilterProps) => {
    return (
      <SelectWrapper maxWidth="250px" margin={margin}>
        <PanelSelect
          {...{
            width,
            currentValue,
            handleChange,
            options,
          }}
        />
      </SelectWrapper>
    );
  }
);

export default TableFilter;
