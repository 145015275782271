import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

const generateAuthUrl: () => string = () => {
  const authUrl =
    process.env.OTTO_AUTH_URL || "https://api.otto.market/oauth2/auth";
  const client_id = process.env.OTTO_CLIENT_ID;
  const redirect_uri =
    process.env.OTTO_REDIRECT_URI ||
    "https://mm.merchantspring.io/callback/otto";

  if (!client_id) {
    throw new Error("Error authorising Otto connection");
  }

  const url = new URL(authUrl);
  const urlParams = new URLSearchParams({
    response_type: "code",
    client_id,
    redirect_uri,
    scope: "installation partnerId",
  });
  url.search = urlParams.toString();
  return url.toString();
};

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "otto"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.error) {
          onError(params.error);
          return;
        }

        if (params.code) {
          onCode(params.code);
          return;
        }
        onError("Invalid response from Otto");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateAuthUrl(),
        "authPopup-mm-otto",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
