/* eslint-disable no-magic-numbers */
import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Typography } from "@material-ui/core";

import Bold from "~/components/typography/bold";
import { INTERVAL } from "~/store/utils/dateTimeUtils";
import { LegendIndicator } from "./ComboChartLegend";
import React from "react";
import { get } from "lodash";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

interface TooltipInfoProps {
  fillColor: string;
  shape: string;
  name: string;
  value: string | number | null;
  unit: string;
  isInEndUnit?: boolean;
  toFixed?: number;
}
export const TooltipInfo = ({
  fillColor,
  shape,
  name,
  value,
  unit,
  isInEndUnit,
  toFixed,
}: TooltipInfoProps) => {
  const { t } = useTranslation();
  const formattedValue =
    Number.isFinite(toFixed) && typeof value === "number"
      ? value.toFixed(toFixed)
      : value;
  return (
    <Box display="flex" mt={0.5} justifyContent="space-between">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <LegendIndicator shape={shape} fill={fillColor} />
        <Typography variant="body2">{t(name)}:&nbsp;</Typography>
      </Box>
      <Typography variant="body2">
        {isInEndUnit
          ? `${numberWithCommas(formattedValue)}${unit}`
          : `${unit}${numberWithCommas(formattedValue)}`}
      </Typography>
    </Box>
  );
};

interface ComboChartTooltipProps {
  lineTooltipProps?: Omit<TooltipInfoProps, "value">;
  line2TooltipProps?: Omit<TooltipInfoProps, "value">;
  barTooltipProps?: Omit<TooltipInfoProps, "value">;
  barTooltipProps2?: Omit<TooltipInfoProps, "value">;
  totalsTooltipProps?: Omit<TooltipInfoProps, "value">;
  payload?: any[];
  currentPeriod: string;
  headerKey?: string;
  headerKey2?: string;
  lineKey?: string;
  line2Key?: string;
  barKey?: string;
  barKey2?: string;
  totalsKey?: string;
  timezone: string;
  interval?: INTERVAL;
}

const ComboChartTooltip = ({
  payload,
  lineTooltipProps,
  line2TooltipProps,
  barTooltipProps,
  barTooltipProps2,
  totalsTooltipProps,
  currentPeriod,
  lineKey,
  line2Key,
  barKey,
  barKey2,
  headerKey,
  headerKey2 = "endTime",
  totalsKey,
  timezone,
  interval,
}: ComboChartTooltipProps) => {
  const tooltipData = get(payload, "0.payload", {});
  const headerValue = headerKey
    ? tooltipData[headerKey as keyof typeof tooltipData]
    : null;
  const headerValue2 = headerKey2
    ? tooltipData[headerKey2 as keyof typeof tooltipData]
    : null;
  const barChartVal = (
    barKey ? tooltipData[barKey as keyof typeof tooltipData] : null
  ) as string | number | null;
  const bar2ChartVal = barKey2
    ? tooltipData[barKey2 as keyof typeof tooltipData]
    : null;
  const lineChartVal = (
    lineKey ? tooltipData[lineKey as keyof typeof tooltipData] : null
  ) as string | number | null;
  const line2ChartVal = line2Key
    ? tooltipData[line2Key as keyof typeof tooltipData]
    : null;
  const totalVal = totalsKey
    ? tooltipData[totalsKey as keyof typeof tooltipData]
    : null;
  const date = headerValue
    ? formatTooltipLabel(headerValue, currentPeriod, interval, timezone)
    : undefined;
  const endDate = headerValue2
    ? formatTooltipLabel(headerValue2, currentPeriod, interval, timezone)
    : undefined;

  return (
    <BlackTooltip>
      <Box p={1} display="flex" flexDirection="column">
        <Bold variant="body2">{date}</Bold>
        {endDate && <Bold variant="body2">{endDate}</Bold>}
        {barTooltipProps && (
          <TooltipInfo value={barChartVal} {...barTooltipProps} />
        )}
        {barTooltipProps2 && (
          <TooltipInfo value={bar2ChartVal} {...barTooltipProps2} />
        )}
        {lineTooltipProps && (
          <TooltipInfo value={lineChartVal} {...lineTooltipProps} />
        )}
        {line2TooltipProps && (
          <TooltipInfo value={line2ChartVal} {...line2TooltipProps} />
        )}
        {totalsTooltipProps && totalVal && (
          <TooltipInfo value={totalVal} {...totalsTooltipProps} />
        )}
      </Box>
    </BlackTooltip>
  );
};

export default ComboChartTooltip;
