import { Box, Grid, Typography } from "@material-ui/core";
import { CancelRounded, CheckCircleRounded } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  PaletteColor,
  PaletteOptions,
} from "@material-ui/core/styles/createPalette";
import React, {
  FormEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createIntercomTags,
  getMarketingContact,
  getMarketingContactId,
  updateMarketingContact,
} from "~/utils/intercomUtils";
import {
  fetchAllStores,
  fetchStoreCustomTags,
} from "~/store/overview/customTags.redux";
import {
  hasFilteredSuffix,
  marketplaceLink,
  marketplaceTypesAndCountriesString,
} from "~/utils/marketplaceUtils";
import { removeMid, updateStoreName } from "~/store/user.redux";

import { Cell } from "react-table";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import EditableTextCell from "~/components/table/cells/editableTextCell";
import { FilterCell } from "~/components/table/cells/filterCell";
import { FilteredStore } from "~/typedef/store";
import { LinkCell } from "~/components/table/cells/linkCell";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MarketplaceAndCountryCell } from "~/components/table/cells/marketplaceAndCountryCell";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import SmallButton from "~/components/buttons/smallButton";
import StatusText from "~/components/typography/status";
import { TFunction } from "i18next";
import Table from "~/components/table/table";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { withTheme } from "@material-ui/core";

const CAPTURE_MARKETING_METRICS = process.env.TARGET_ENV !== "development";

interface SelectedStore {
  market: string;
  marketplaceSubtype: string;
  countryCode: string;
  mid: string;
  shopName: string;
  pageIndex: number;
}

interface SavedState {
  pageIndex: number;
  sortBy: { id: string }[];
}

interface StatusProps {
  $customColor: keyof PaletteOptions;
}

const StatusIcon = withTheme(styled(CheckCircleRounded)<StatusProps>`
  fill: ${({ theme, $customColor }) =>
    (theme.palette[$customColor] as PaletteColor).main};
`);

const ErrorStatusIcon = withTheme(styled(CancelRounded)<StatusProps>`
  fill: ${({ theme, $customColor }) =>
    (theme.palette[$customColor] as PaletteColor).main};
`);

const SmallerButton = withTheme(styled(SmallButton)`
  font-size: 12px;
`);

const ReconnectButton = styled(SmallerButton)`
  width: 50%;
  font-size: 12px;
`;

const RemoveButton = styled(SmallerButton)`
  width: 45%;
  max-width: fit-content;
  font-size: 12px;
`;

const ChangePortfolioButton = styled(SmallerButton)`
  width: 50%;
  font-size: 12px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

const isConnected = (store: FilteredStore) => {
  return (
    store.shopStatus ||
    (store.marketplaceSubtype === "advertising" && store.advertisingStatus) ||
    store.isDemoMode
  );
};

const StatusIndicator = ({ cell }: { cell: Cell }) => {
  const value = cell.value.value;
  const icon = cell.value.icon;
  return (
    <Grid container alignItems="center" spacing={2}>
      {icon === "success" ? (
        <Grid item>
          <StatusIcon $customColor={icon} fontSize="small" />
        </Grid>
      ) : (
        <Grid item>
          <ErrorStatusIcon $customColor={icon} fontSize="small" />
        </Grid>
      )}
      <Grid item>
        <Typography variant="body2" noWrap color="textPrimary">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const formatData = (
  filteredStores: FilteredStore[],
  t: TFunction,
  user: User
) =>
  filteredStores.map((store: FilteredStore) => {
    const connectionStatus = {
      value: isConnected(store)
        ? t("generic.connectedLabel")
        : t("generic.reconnectButton"),
      icon: isConnected(store) ? "success" : "error",
    };

    const market = {
      market: store.marketplaceType,
      marketplaceSubtype: store.marketplaceSubtype,
      countryCode: store.marketplaceCountry,
    };

    const shop = {
      value: store.storeName,
      link: marketplaceLink(store.marketplaceType, store.merchantId),
    };

    const shopName = user?.roleSettings?.blockStoreActions
      ? shop.value
      : {
          originalFieldValue: shop.value,
          originalComponent: <LinkCell {...{ cell: { value: shop } }} />,
          metadata: {
            marketplace: store.marketplaceType,
            marketplaceSubtype: store.marketplaceSubtype,
            countryCode: store.marketplaceCountry,
            mid: store.merchantId,
          },
        };

    return {
      connectionStatus,
      market,
      shopName,
      store,
      // marketAcc: m.marketAcc,
      mid: store.merchantId,
    };
  });

const MarketplaceSetting = memo(
  ({ showDisconnected }: { showDisconnected: boolean }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedStore, setSelectedStore] = useState<SelectedStore | null>(
      null
    );
    const [dialogOpen, setDialogOpen] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortBy, setSortBy] = useState([{ id: "shopName" }]);
    const [msg, setMsg] = useState<{
      status: string;
      msg: string;
    } | null>(null);

    const TABLE_TYPE = {
      All: "all",
      OnlyDisconnected: "onlyDisconnected",
    };
    const TABLE_OPTIONS = useMemo(
      () => [
        {
          value: TABLE_TYPE.All,
          label: t("settings.marketplace.allChannels"),
        },
        {
          value: TABLE_TYPE.OnlyDisconnected,
          label: t("settings.marketplace.disconnectedChannels"),
        },
      ],
      []
    );
    const [tableType, setTableType] = useState(
      showDisconnected ? TABLE_OPTIONS[1] : TABLE_OPTIONS[0]
    );
    const switchTableType = (value: string) => {
      const option = TABLE_OPTIONS.find(
        (tableOption) => tableOption.value === value
      );
      if (option) {
        setTableType(option);
      }
    };

    const user = useTypedSelector((state) => state.user);
    const allStores = useTypedSelector(
      (state) => state.mystore.allStores.stores
    );
    const allStoresFetching = useTypedSelector(
      (state) => state.mystore.allStores.fetching
    );
    const customTagsFetching = useTypedSelector((state) =>
      get(state, "mystore.customTags.fetching")
    );
    const allStoresWithoutDemo = allStores?.filter((s) => !s.isDemoMode);

    const dispatchRemoveMid = ({
      marketplaceType,
      marketplaceSubtype,
      countryCode,
      mid,
    }: {
      marketplaceType: string;
      marketplaceSubtype: string;
      countryCode: string;
      mid: string;
    }) => {
      dispatch(
        removeMid({ marketplaceType, marketplaceSubtype, countryCode, mid })
      );
    };

    const dispatchUpdateStoreName = async ({
      userId,
      marketplace,
      marketplaceSubtype,
      countryCode,
      mid,
      shopName,
      state,
    }: {
      userId: string;
      marketplace: string;
      marketplaceSubtype: string;
      countryCode: string;
      mid: string;
      shopName: string;
      state: SavedState;
    }) => {
      setPageIndex(state.pageIndex);
      setSortBy(state.sortBy);
      setSavingData(true);
      setMsg({
        status: "success",
        msg: t("settings.marketplace.savingMessage"),
      });
      await dispatch(
        updateStoreName({
          userId,
          marketplace,
          marketplaceSubtype,
          countryCode,
          mid,
          shopName,
        })
      );
      await dispatch(fetchAllStores());
      setSavingData(false);
      setMsg({
        status: "success",
        msg: t("settings.marketplace.renameSuccessMessage"),
      });
    };

    const intercomCustomAttrib = {
      Marketplaces: marketplaceTypesAndCountriesString(allStoresWithoutDemo),
      Marketplaces_connected: allStoresWithoutDemo.length,
    };

    useEffect(() => {
      if (!allStoresFetching) {
        dispatch(fetchAllStores());
      }
      if (!customTagsFetching) {
        dispatch(fetchStoreCustomTags());
      }
    }, []);

    const data = useMemo(
      () =>
        formatData(
          tableType.value === TABLE_TYPE.OnlyDisconnected
            ? allStores.filter((s) => !isConnected(s))
            : allStores,
          t,
          user
        ),
      [allStores, tableType, user]
    );

    const sortByField = useCallback(
      (fieldName) => (rowA: Object, rowB: Object) => {
        const a = get(rowA, fieldName, "");
        const b = get(rowB, fieldName, "");
        return a.localeCompare(b);
      },
      []
    );

    const columns = useMemo(
      () => [
        {
          id: "market",
          Header: t("settings.marketplace.marketplaceColumn"),
          accessor: "market",
          disableSortBy: true,
          Cell: MarketplaceAndCountryCell,
        },
        {
          id: "shopName",
          Header: t("settings.marketplace.storeColumn"),
          accessor: "shopName",
          sortType: sortByField("values.shopName.originalFieldValue"),
          Cell: user?.roleSettings?.blockStoreActions
            ? TextCell
            : EditableTextCell,
          onSave: (
            shopName: string,
            metadata: {
              marketplace: string;
              marketplaceSubtype: string;
              countryCode: string;
              mid: string;
            },
            state: SavedState
          ) =>
            dispatchUpdateStoreName({
              userId: user._id,
              marketplace: metadata.marketplace,
              marketplaceSubtype: metadata.marketplaceSubtype,
              countryCode: metadata.countryCode,
              mid: metadata.mid,
              shopName,
              state,
            }),
        },
        {
          id: "tags",
          Header: t("settings.marketplace.tagsColumn"),
          disableSortBy: true,
          Cell: ({ row }: any) => (
            <FilterCell
              {...{
                status: row.original.connectionStatus.value,
                mid: row.original.mid,
              }}
            />
          ),
        },
        {
          id: "connectionStatus",
          Header: t("settings.marketplace.connectionStatusColumn"),
          accessor: "connectionStatus",
          sortType: sortByField("values.connectionStatus.value"),
          Cell: StatusIndicator,
          hiddenDown: "xs",
          customWidth: 150,
        },
        {
          Header: t("settings.marketplace.actionsColumn"),
          disableSortBy: true,
          customWidth: 275,
          Cell: ({ row, state }: { row: any; state: SavedState }) => (
            <ActionWrapper>
              {selectedStore && selectedStore.mid === row.original.mid ? (
                <LoadingIndicator size={20} />
              ) : (
                <>
                  {!user?.roleSettings?.blockStoreActions && (
                    <RemoveButton
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setDialogOpen(true);
                        setSelectedStore({
                          market: row.original.market.market,
                          marketplaceSubtype:
                            row.original.market.marketplaceSubtype,
                          countryCode: row.original.market.countryCode,
                          mid: row.original.mid,
                          pageIndex: state.pageIndex,
                          shopName: row.original.store.storeName,
                        });
                      }}
                    >
                      {t("generic.removeButton")}
                    </RemoveButton>
                  )}
                  {row.original.connectionStatus.icon === "error" && (
                    <ReconnectButton
                      component={Link}
                      color="primary"
                      to={`/connectStore/select`}
                    >
                      {t("generic.reconnectButton")}
                    </ReconnectButton>
                  )}
                  {hasFilteredSuffix(row.original.market.market) && (
                    <ChangePortfolioButton
                      component={Link}
                      color="primary"
                      to={`/connectStore/select`}
                    >
                      {t("generic.changePortfolioButton")}
                    </ChangePortfolioButton>
                  )}
                </>
              )}
            </ActionWrapper>
          ),
        },
      ],
      [selectedStore]
    );

    const onCancel = () => {
      setDialogOpen(false);
      setSelectedStore(null);
    };

    const runIntercom = async () => {
      const contact = await getMarketingContact(user._id);
      const contactId = getMarketingContactId(contact);
      await createIntercomTags(user._id, "Deleted Store Data");
      if (contactId) {
        await updateMarketingContact(
          user._id,
          contactId,
          intercomCustomAttrib,
          user.email,
          user.phone,
          user.role
        );
      }
    };

    const onConfirm = async (e: FormEvent) => {
      e.preventDefault();
      if (!user || !user._id) {
        setMsg({
          status: "error",
          msg: t("generic.generalError"),
        });
        return;
      }
      setSavingData(true);
      try {
        if (selectedStore) {
          setPageIndex(selectedStore.pageIndex);
          await dispatchRemoveMid({
            marketplaceType: selectedStore.market,
            marketplaceSubtype: selectedStore.marketplaceSubtype,
            countryCode: selectedStore.countryCode,
            mid: selectedStore.mid,
          });
          setSavingData(false);
          setDialogOpen(false);
          setMsg({
            status: "success",
            msg: t("settings.marketplace.removeSuccessMessage"),
          });
          if (CAPTURE_MARKETING_METRICS) {
            if (window.Intercom) {
              window.Intercom("update", intercomCustomAttrib);
            }
            await runIntercom();
          }
        }
      } catch (err) {
        setSavingData(false);
        setSelectedStore(null);
        setMsg({
          status: "error",
          msg: err as string,
        });
      }
    };

    return (
      <>
        {!isEmpty(data) ? (
          allStoresFetching ? (
            <PanelLoading />
          ) : (
            <>
              <Box
                p={2}
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h3" color="textPrimary">
                  {t("settings.marketplace.marketplaceTitle")}
                </Typography>
                {savingData && <LoadingIndicator />}
                {msg && <StatusText status={msg.status}>{msg.msg}</StatusText>}
                <Box display="flex">
                  <TableFilter
                    {...{
                      width: "200px",
                      options: TABLE_OPTIONS,
                      currentValue: tableType.label,
                      handleChange: switchTableType,
                    }}
                  />
                </Box>
              </Box>
              <Table
                {...{
                  isMarketplaceSettingPage: true,
                  columns: columns,
                  data: data,
                  pageSize: 25,
                  pageIndex: pageIndex,
                  pagination: true,
                  sortBy: sortBy,
                  sorting: true,
                }}
              />
              <ConfirmDialog
                open={Boolean(dialogOpen)}
                title={t("generic.confirmTitle")}
                onClose={onCancel}
                onConfirm={onConfirm}
                content={
                  <>
                    <Typography variant="body1" color="textPrimary">
                      {t("settings.marketplace.removeConfirmMessage", {
                        storeName: selectedStore?.shopName || "",
                      })}
                    </Typography>
                  </>
                }
              />
            </>
          )
        ) : (
          <>
            <Box p={2} display="flex" alignItems="right" justifyContent="right">
              <TableFilter
                {...{
                  width: "200px",
                  options: TABLE_OPTIONS,
                  currentValue: tableType.label,
                  handleChange: switchTableType,
                }}
              />
            </Box>
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body2" color="textPrimary">
                {t("settings.marketplace.noInfoMessage")}
              </Typography>
            </Box>
          </>
        )}
        {!user?.roleSettings?.hideStoreConnectionWizard && (
          <Box
            p={2}
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <RaisedButton
              color="primary"
              variant="outlined"
              onClick={() => {
                history.push("/connectStore/select");
              }}
            >
              {t("settings.marketplace.addStoreButton")}
            </RaisedButton>
          </Box>
        )}
      </>
    );
  }
);

export default MarketplaceSetting;
