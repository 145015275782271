import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import styled from "styled-components";

export const StyledEditIcon = styled(EditIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const StyledDoneIcon = styled(DoneIcon)`
  fill: ${({ theme }) => theme.palette.success.main};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.palette.error.main};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

export const EditableTextField = memo(
  ({
    originalFieldValue,
    onSave,
    metadata,
    originalComponent,
    type,
    inputProps,
    condensed,
    state,
  }) => {
    const theme = useTheme();
    const [editMode, setEditMode] = useState(false);
    const [fieldValue, setFieldValue] = useState(originalFieldValue);

    const displayValue = `${originalFieldValue}`;

    return (
      <Box
        display="flex"
        width={condensed ? "auto" : "100%"}
        pl={condensed ? 1 : 0}
        alignItems="center"
        justifyContent="flex-end"
      >
        {editMode ? (
          <>
            <TextField
              autoFocus
              type={type || "text"}
              defaultValue={fieldValue}
              inputProps={{
                style: {
                  ...theme.typography.body2,
                  width: condensed ? "15px" : "auto",
                },
                ...inputProps,
              }}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(value);
              }}
            />
            <Tooltip title="Save">
              <IconButton
                size="small"
                aria-label="Save"
                onClick={() => {
                  setEditMode(false);
                  if (fieldValue !== originalFieldValue) {
                    onSave(fieldValue, metadata, state);
                  }
                }}
              >
                <StyledDoneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                size="small"
                aria-label="Cancel"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                <StyledCloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : originalComponent ? (
          originalComponent
        ) : (
          <>
            <Typography variant="body2">{displayValue}</Typography>
          </>
        )}
        {!editMode && (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              aria-label="Edit"
              onClick={() => {
                setFieldValue(originalFieldValue);
                setEditMode(true);
              }}
              style={{
                marginLeft: "2px",
              }}
            >
              <StyledEditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }
);

EditableTextField.propTypes = {
  originalFieldValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  originalComponent: PropTypes.node.isRequired,
  inputProps: PropTypes.object,
  type: PropTypes.string,
  metadata: PropTypes.object,
  condensed: PropTypes.bool,
  state: PropTypes.object,
};

const EditableTextCell = memo(({ cell, state }) => {
  const { onSave } = cell.column;

  const { originalFieldValue, originalComponent, metadata } = cell.value;

  return (
    <EditableTextField
      state={state}
      onSave={onSave}
      originalFieldValue={originalFieldValue}
      originalComponent={originalComponent}
      metadata={metadata}
    />
  );
});

EditableTextCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    column: PropTypes.any,
    value: PropTypes.any,
  }).isRequired,
  state: PropTypes.object.isRequired,
};

export default EditableTextCell;
