/* eslint-disable no-magic-numbers */
import {
  CircularProgress,
  FormControl,
  Link,
  Typography,
} from "@material-ui/core";
import React, { memo } from "react";

import { FormEvent } from "react";
import RaisedButton from "~/components/buttons/raisedButton";
import TextField from "@material-ui/core/TextField";
import { connectionStatus } from "~/utils/marketplaceUtils";
import { loadInfo } from "~/store/user.redux";
import styled from "styled-components";
import { trimSpace } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const { CONNECTION_FAILED, CONNECTION_SUCCEED, CONNECTION_INPROGRESS } =
  connectionStatus;
interface PageAuthPros {
  marketplaceConfig: any;
  input1: string;
  input2: string;
  setTransaction: ({
    status,
    errMsg,
    payload,
  }: {
    status: string;
    errMsg: string;
    // if the store connection redux action returns any data, carry it in
    // the "payload" field
    payload?: Record<string, any>;
  }) => void;
  setInput1: (input1: string) => void;
  setInput2: (input2: string) => void;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
  width: 80%;
  max-width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const LinkWrapper = styled(Typography)`
  margin-bottom: 20px;
`;

const GenericAdvertisingConnectPageAuthForm = memo(
  ({
    marketplaceConfig,
    input1,
    input2,
    setTransaction,
    setInput1,
    setInput2,
  }: PageAuthPros) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleConnectBtn = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (marketplaceConfig.inputs[0] && marketplaceConfig.inputs[0].regex) {
        if (!marketplaceConfig.inputs[0].regex.test(trimSpace(input1))) {
          setTransaction({
            status: CONNECTION_FAILED,
            errMsg: marketplaceConfig.inputs[0].regexError,
          });
          return;
        }
      }

      if (marketplaceConfig.inputs[1] && marketplaceConfig.inputs[1].regex) {
        if (!marketplaceConfig.inputs[1].regex.test(trimSpace(input2))) {
          setTransaction({
            status: CONNECTION_FAILED,
            errMsg: marketplaceConfig.inputs[1].regexError,
          });
          return;
        }
      }

      try {
        const { mid } = marketplaceConfig;
        setTransaction({
          status: CONNECTION_INPROGRESS,
          errMsg: "",
        });

        // note use of eval here - all dynamic params specified in
        // submitActionParams must be present within this scope
        const payload = await dispatch(
          marketplaceConfig.submitAction(
            ...marketplaceConfig.submitActionParams.map((p: string) =>
              trimSpace(eval(p))
            ),
            mid
          )
        );

        setTransaction({
          status: CONNECTION_SUCCEED,
          errMsg: "",
          payload,
        });

        dispatch(loadInfo());
      } catch (err) {
        if (err === "tokenInValid") {
          setTransaction({
            status: CONNECTION_FAILED,
            errMsg: marketplaceConfig.messages.connectionError,
          });
        } else if (err === "forbidden") {
          setTransaction({
            status: CONNECTION_FAILED,
            errMsg: t("generic.notAvailableDemoMode"),
          });
        } else {
          setTransaction({
            status: CONNECTION_FAILED,
            errMsg: marketplaceConfig.messages.connectionError,
          });
        }
      }
    };

    return !marketplaceConfig ? (
      <CircularProgress />
    ) : (
      <Form method="POST" onSubmit={(event) => handleConnectBtn(event)}>
        {marketplaceConfig.inputs[0] && (
          <FormControl>
            <TextField
              fullWidth
              label={marketplaceConfig.inputs[0].label}
              type={marketplaceConfig.inputs[0].type}
              margin="normal"
              required
              value={input1}
              onChange={(e) => {
                setInput1(e.target.value);
              }}
            />
          </FormControl>
        )}
        {marketplaceConfig.inputs[1] && (
          <FormControl>
            <TextField
              fullWidth
              label={marketplaceConfig.inputs[1].label}
              type={marketplaceConfig.inputs[1].type}
              margin="normal"
              required
              value={input2}
              onChange={(e) => {
                setInput2(e.target.value);
              }}
            />
          </FormControl>
        )}

        <LinkWrapper align="right">
          <Link href={marketplaceConfig.messages.helpLinkUrl} target="_blank">
            {marketplaceConfig.messages.helpLink}
          </Link>
        </LinkWrapper>

        <ButtonWrapper>
          <RaisedButton
            disabled={!input1 || (marketplaceConfig.inputs[1] && !input2)}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("connectWizard.connectButton")}
          </RaisedButton>
        </ButtonWrapper>
      </Form>
    );
  }
);

export default GenericAdvertisingConnectPageAuthForm;
