import { amazonHealthDataConfig } from "./amazonHealthUtils";
import { bolHealthDataConfig } from "./bolHealthUtils";
import { ebayHealthDataConfig } from "./ebayHealthUtils";
import { iconicHealthDataConfig } from "./iconicHealthUtils";
import { mercadoLibreHealthDataConfig } from "./mercadoLibreHealthDataConfig";
import { miraklHealthDataConfig } from "./miraklHealthUtils";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";

export const HealthStatus = {
  Good: "success",
  AtRisk: "warning",
  Poor: "error",
  Closed: "disabled",
  NoStatus: "disabled",
};

export const getTitle = (object, value) => {
  const keyName = Object.keys(object).find((key) => object[key] === value);
  return `healthStatus.${keyName}`;
};

export const getStatusTitle = (status, t) => t(getTitle(HealthStatus, status));

export const getAccountHealth = (market, storeData) => {
  if (storeData.accountHealth) {
    switch (stripFilteredSuffix(market)) {
      case "amazon":
        return storeData.accountHealth;
      case "ebay":
        return storeData.accountHealth;
      case "catch":
      case "mercadolibre":
        return storeData.accountHealth;
      case "mirakl":
        return storeData.accountHealth;
      case "iconic":
        return storeData.accountHealth;
      case "bol":
        return storeData.accountHealth;
      case "shopify":
      case "trademe":
      case "walmart":
      case "woocommerce":
        return {
          healthStatus: HealthStatus.Good,
          Details: {
            value: `No detailed information for ${market
              .charAt(0)
              .toUpperCase()}${market.slice(1)}`,
            status: HealthStatus.NoStatus,
          },
        };

      default:
        return null;
    }
  }
  return null;
};

export const healthDataConfig = {
  amazon: amazonHealthDataConfig,
  amazon_filtered: amazonHealthDataConfig,
  bol: bolHealthDataConfig,
  ebay: ebayHealthDataConfig,
  mercadolibre: mercadoLibreHealthDataConfig,
  mirakl: miraklHealthDataConfig,
  iconic: iconicHealthDataConfig,
};
