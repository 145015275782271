export const iconicHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    items: [
      {
        key: "returns",
        title: "dashboardWidget.accountHealth.returnRequests",
      },
      {
        key: "cancelRate",
        title: "dashboardWidget.accountHealth.cancelRate",
        suffix: "%",
      },
      {
        key: "shipment48",
        title: "dashboardWidget.accountHealth.shipment48hours",
        suffix: "%",
      },
      {
        key: "shipment24",
        title: "dashboardWidget.accountHealth.shipment24hours",
        suffix: "%",
      },
      {
        key: "soldout",
        title: "dashboardWidget.accountHealth.soldout",
        url: "https://sellercenter.theiconic.com.au/product/index/sold-out",
      },
      {
        key: "missingImage",
        title: "dashboardWidget.accountHealth.missingImages",
        url: "https://sellercenter.theiconic.com.au/product/index/disapproved",
      },
      {
        key: "poorQuality",
        title: "dashboardWidget.accountHealth.poorQualityRejections",
        url: "https://sellercenter.theiconic.com.au/product/index/disapproved",
      },
    ],
  },
};
