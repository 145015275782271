import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import React from "react";

export default function (onCode, authUrl) {
  const childWindow = React.useRef(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  React.useEffect(() => {
    const messageListener = (event) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "amazon"
      ) {
        const { params } = event.data;
        closeChildWindow();
        onCode(params.code, params.state, params.scope);
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode]);
  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        authUrl,
        "authPopup-mm-amazon",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=599,height=600,top=240,left=660.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
