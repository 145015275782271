import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import React from "react";
import { marketplaceConstants } from "mm-mercado-libre-common/dist/shared/marketplaceConstants";

const generateMercadoLibreAuthUrl = (countryCode) => {
  const isCbt = countryCode === "USA"; // CBT = Cross-Border Trade (Global Selling)
  const clientId = isCbt
    ? process.env["MERCADO_LIBRE_CBT_CLIENT_ID"]
    : process.env["MERCADO_LIBRE_CLIENT_ID"];

  const authUrlSearchParams = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: "https://mm.merchantspring.io/callback/mercadolibre",
  });

  return `https://${
    marketplaceConstants[countryCode].authEndpoint
  }/authorization?${authUrlSearchParams.toString()}`;
};

/**
 * @param {(onCode: Function, countryCode: string) => void} onCode
 */
export default function (onCode, countryCode) {
  const childWindow = React.useRef(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  React.useEffect(() => {
    const messageListener = (event) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "mercadolibre"
      ) {
        const { params } = event.data;
        closeChildWindow();
        onCode(params.code);
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateMercadoLibreAuthUrl(countryCode),
        "authPopup-mm-mercadolibre",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=599,height=600,top=240,left=660.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
