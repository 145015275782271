import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

import { AppDispatch } from "@store/configureStore";
import { QueryLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import get from "lodash/get";
import { isHttpResponseValid } from "./httpsResponseCodes";
import { setError } from "@store/globalToast.redux";

type QueryFulfilledHandler = (
  data: any,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  args: any
) => void;

export const globalQueryErrorHandler =
  (
    location: string = "",
    supressUnauthorisedError: boolean = false,
    queryFulfilledHandler?: QueryFulfilledHandler
  ) =>
  async (args: any, api: QueryLifecycleApi<any, any, any>) => {
    const { dispatch, queryFulfilled } = api;
    try {
      const { data, meta } = await queryFulfilled;
      // Assume 200 if meta is undefined
      const status = meta?.response?.status || 200;
      if (!isHttpResponseValid(status) && data) {
        setError(dispatch, data, status, location, supressUnauthorisedError);
      } else {
        queryFulfilledHandler && queryFulfilledHandler(data, dispatch, args);
      }
    } catch (err) {
      // `onError` side-effect
      setError(
        dispatch,
        get(err, "error.response.data.error") ||
          get(err, "error.response.data.message") ||
          get(err, "error.response.data.errMsg") ||
          get(err, "error.response.data") ||
          get(err, "error.data.error") ||
          get(err, "error.data.message") ||
          get(err, "error.data.errMsg.message") ||
          get(err, "error.data.errMsg") ||
          get(err, "error.data"),
        get(err, "error.response.status") || get(err, "error.status"),
        location,
        supressUnauthorisedError
      );
    }
  };

export interface AppThunkError {
  status?: any;
  validateHttpResponseStatus?: boolean;
  data?: any;
  error?: any;
  location?: string;
  supressUnauthorisedError?: boolean;
}
export const globalThunkErrorHandler = (
  dispatch: AppDispatch | ThunkDispatch<any, any, any>,
  {
    status,
    validateHttpResponseStatus = true,
    data,
    error,
    location = "",
    supressUnauthorisedError = false,
  }: AppThunkError
) => {
  if (status) {
    if (validateHttpResponseStatus) {
      if (!isHttpResponseValid(status) && data) {
        // Check HTTP Response & throw error only if invalid response
        setError(dispatch, data, status, location, supressUnauthorisedError);
        return true;
      }
      return false;
    }

    // Regarding of HTTP Response, throw error with given status & response
    // Thunk has it's own error checking mechanism. eg: fetchCustomLayoutConfig in customLayout.reducer.ts
    setError(
      dispatch,
      get(data, "errMsg") || get(data, "error") || "",
      status,
      location,
      supressUnauthorisedError
    );
    return false;
  } else {
    // The handler is called within the catch clause
    setError(
      dispatch,
      get(error, "response.data.errMsg") || get(error, "response.data.error"),
      get(error, "response.status"),
      location,
      supressUnauthorisedError
    );
    return false;
  }
};
