import { CustomGroup } from "@typedef/customGroups";
import CustomGroupLink from "./customGroupLink";
import React from "react";
import { StoreMenuItem } from "../../../components/toolbars/myStoresToolbar/myStoresDropdown";
import ToolbarSelect from "~/components/select/toolbarSelect";
import { useTranslation } from "react-i18next";

interface StoreSelectorProps {
  customGroups: CustomGroup[];
  changeCustomGroup: (groupId: number) => void;
  selectedGroup?: CustomGroup;
  maxWidth?: number;
  fullWidth?: boolean;
  rightAlign?: boolean;
}
export const CustomGroupSelector: React.FC<StoreSelectorProps> = ({
  customGroups,
  changeCustomGroup,
  selectedGroup,
  maxWidth,
  fullWidth = true,
  rightAlign = false,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const selectCustomGroup = (groupId: number) => {
    setAnchorEl(null);
    changeCustomGroup(groupId);
  };

  const renderOptions = () => {
    const options = customGroups
      ? [...customGroups]
          .sort((a: CustomGroup, b: CustomGroup): number =>
            a.groupName.localeCompare(b.groupName)
          )
          .map((customGroup: CustomGroup) => (
            <StoreMenuItem key={`${customGroup.groupId}`} width={menuWidth}>
              <CustomGroupLink
                {...{
                  activeLink: true,
                  changeCustomGroup: selectCustomGroup,
                  groupId: customGroup.groupId,
                  groupName: customGroup.groupName,
                }}
              />
            </StoreMenuItem>
          ))
      : [];

    return options;
  };

  return (
    <ToolbarSelect
      id="store-select"
      title={t("widget.selectCustomGroup")}
      maxWidth={maxWidth}
      setMenuWidth={setMenuWidth}
      renderOptions={renderOptions}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      fullWidth={fullWidth}
      alignItems={rightAlign ? "flex-end" : "flex-start"}
      displayComponent={
        <CustomGroupLink
          {...{
            activeLink: false,
            groupId: selectedGroup?.groupId,
            groupName: selectedGroup?.groupName,
          }}
        />
      }
    />
  );
};
