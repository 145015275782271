import { Box, Grid } from "@material-ui/core";
import React, { memo } from "react";

import DispatchStatusIndicator from "~/components/statusIndicator/dispatchStatus";
import { Overview } from "~/typedef/store";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useTranslation } from "react-i18next";

interface DispatchStatusContentsProps {
  market?: string;
  mid?: string;
  compact?: boolean;
  dispatchStatus: Overview["dispatchStatus"];
}

const DispatchStatusContents = memo<DispatchStatusContentsProps>(
  function DispatchStatusContents({ market, mid, compact, dispatchStatus }) {
    const { t } = useTranslation();

    const overdueOrdersLink =
      !mid || !market // isOverview
        ? "/overview/orderStatus?type=overdueOrders"
        : marketplaceLink(market, mid, "overdueOrders");

    const dueTodayLink =
      !mid || !market // isOverview
        ? "/overview/orderStatus?type=ordersDueToday"
        : marketplaceLink(market, mid, "overdueOrders", {
            type: "ordersDueToday",
          });

    const toSendLink =
      !mid || !market // isOverview
        ? "/overview/orderStatus?type=ordersToSend"
        : marketplaceLink(market, mid, "overdueOrders", {
            type: "ordersToSend",
          });

    return (
      <Box p={2} height={"100%"}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid container item xs={12}>
            <DispatchStatusIndicator
              status="success"
              count={parseInt(dispatchStatus.ordersToSend)}
              title={t("dashboardWidget.dispatchStatus.ordersToSendLabel")}
              link={toSendLink}
              compact={compact}
            />
          </Grid>
          <Grid container item xs={12}>
            <DispatchStatusIndicator
              status="warning"
              count={parseInt(dispatchStatus.ordersDueToday)}
              title={t("dashboardWidget.dispatchStatus.ordersDueTodayLabel")}
              link={dueTodayLink}
              compact={compact}
            />
          </Grid>
          <Grid container item xs={12}>
            <DispatchStatusIndicator
              status="error"
              count={parseInt(dispatchStatus.overdueOrders)}
              title={t("dashboardWidget.dispatchStatus.overdueOrdersLabel")}
              link={overdueOrdersLink}
              compact={compact}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default DispatchStatusContents;
