import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import Medium from "../../typography/medium";
import PropTypes from "prop-types";

export const StrongValueCell = memo(({ cell }) => {
  const value = cell.value;
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Medium variant="body2">{value}</Medium>
      </Grid>
    </Grid>
  );
});

StrongValueCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};
