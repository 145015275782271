import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useMemo } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
  roundFractionalDigits,
} from "~/utils/currencyUtils";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import Panel from "~/components/panel/panel";
import PerformanceChart from "../../widgets/advertising/performanceChart";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import moment from "moment-timezone";
import { useMarketingAllCampaignsChartQuery } from "~/store/mystore/marketing.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AllCampaignsChartPanelProps {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  groupId: string;
}

const AllCampaignsChartPanel = memo<AllCampaignsChartPanelProps>(
  function AllCampaignsChartPanel({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    groupId,
  }) {
    const { t } = useTranslation();
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const { data = [], isLoading: isLoadingMarketingAllCampaignsChart } =
      useMarketingAllCampaignsChartQuery({
        mid,
        countryCode,
        marketplaceType,
        marketplaceSubtype,
        interval: currentRange.interval,
        priorFromDate: currentRange.priorFromDate,
        priorToDate: currentRange.priorToDate,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        timezone: selectedTimezone,
        groupId,
      });

    const chartData = useMemo(
      () =>
        data.map((entry) => ({
          reportDate: moment(get(entry, "current.report_date")).unix(),
          sales: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.attributed_sales", 0)
          ),
          acos: roundFractionalDigits(get(entry, "current.acos") || 0),
          cost: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.cost", 0)
          ),
        })),
      [data, currentCurrency]
    );

    return (
      <Panel
        id="widget-marketing-campaign-chart"
        title={t("advertisingDashboardWidget.overallCampaignPerformance")}
        content={
          <Box p={2}>
            {isLoadingMarketingAllCampaignsChart ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceChart
                    title={t(CHART_TITLES[currentPeriod])}
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    chartData={chartData}
                    isLoading={isLoadingMarketingAllCampaignsChart}
                    timezone={selectedTimezone}
                    interval={currentRange.interval}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default AllCampaignsChartPanel;
