import { Grid, Tooltip, Typography, withTheme } from "@material-ui/core";

import { LinkCell } from "./linkCell";
import PropTypes from "prop-types";
import React from "react";
import SendIcon from "@material-ui/icons/Send";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ReviewContainer = styled(Grid)`
  min-width: 150px;
`;

const Divider = styled.div`
  height: 90%;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.border.dark};
`;

const SecondRowGridContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
`;

const StyledExpandIcon = withTheme(styled(VerticalSplitIcon)`
  height: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.secondary};
`);

const StyledSendIcon = withTheme(styled(SendIcon)`
  height: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`);

const DisabledSendIcon = withTheme(styled(SendIcon)`
  height: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.disabled.main};
`);

const FlexGridItem = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ReviewStatusCell = ({ cell, onSendReviewRequestClick, row }) => {
  const {
    orderId,
    orderCountry,
    sourceSystemId,
    orderCreatedAt,
    link,
    quantity,
    canSendReviewRequest,
  } = cell.value;
  const hasItems = parseInt(quantity) > 0;
  const { t } = useTranslation();

  return (
    <>
      <ReviewContainer container>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <LinkCell
              colorVariant="external"
              cell={{
                value: {
                  value: orderId,
                  link,
                },
              }}
            />
          </Grid>
          <SecondRowGridContainer item container xs={12}>
            <FlexGridItem item style={{ width: "4rem", paddingTop: "1px" }}>
              <Typography
                align="left"
                variant="subtitle1"
                color="textSecondary"
                noWrap
              >
                {`Items: ${quantity}`}
              </Typography>
            </FlexGridItem>
            <Divider />
            {hasItems && (
              <FlexGridItem item>
                {row && (
                  <>
                    <StyledExpandIcon {...row.getToggleRowExpandedProps()} />
                    <Divider />
                  </>
                )}
              </FlexGridItem>
            )}
            <FlexGridItem item>
              {canSendReviewRequest ? (
                <StyledSendIcon
                  onClick={() =>
                    onSendReviewRequestClick([
                      { orderId, sourceSystemId, orderCountry, orderCreatedAt },
                    ])
                  }
                />
              ) : (
                <Tooltip
                  title={t("reviewManagement.disabledReviewTooltip")}
                  id="panel-card-tooltip"
                >
                  <DisabledSendIcon />
                </Tooltip>
              )}
            </FlexGridItem>
          </SecondRowGridContainer>
        </Grid>
      </ReviewContainer>
    </>
  );
};

ReviewStatusCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
  onExpandDetailClick: PropTypes.func,
  onSendReviewRequestClick: PropTypes.func,
  row: PropTypes.any, // used for React table's expanding row feature
};

export default ReviewStatusCell;
