export { getCurrencyByCountryCode } from "mm-utils-frontend";

const SHOW_CENTS = 2;
const NO_CENTS = 0;

export const getConvertedValue = (
  rates,
  srcCurrency,
  desCurrency,
  value,
  decimalPlaces = SHOW_CENTS
) => {
  const covertedValue = 0.0;
  if (
    isNaN(value) ||
    !value ||
    !rates ||
    !rates.length ||
    !desCurrency ||
    !srcCurrency
  ) {
    return parseFloat(covertedValue.toFixed(decimalPlaces));
  }
  const numberValue = parseFloat(value);
  // if source and destination are the same, just return the unconverted value
  if (srcCurrency === desCurrency)
    return parseFloat(numberValue.toFixed(decimalPlaces));
  const matched = rates.find((i) => i._id === srcCurrency);
  if (matched) {
    return parseFloat(
      (matched.rates[desCurrency] * numberValue).toFixed(decimalPlaces)
    );
  }
  return parseFloat(covertedValue.toFixed(decimalPlaces));
};

export const getCurrencySymbol = {
  USD: "$",
  AUD: "$",
  GBP: "£",
  NZD: "$",
  HKD: "$",
  ISK: "kr",
  PHP: "₱",
  DKK: "Kr",
  HUF: "Ft",
  CZK: "Kč",
  RON: "lei",
  SEK: "kr",
  IDR: "Rp",
  INR: "₹",
  BRL: "R$",
  RUB: "₽",
  HRK: "kn",
  JPY: "¥‎",
  THB: "฿",
  EUR: "€",
  MYR: "RM",
  BGN: "Лв",
  TRY: "₺",
  CNY: "¥",
  NOK: "kr",
  ZAR: "R",
  MXN: "$",
  SGD: "S$",
  ILS: "₪",
  KRW: "₩",
  CHF: "Fr",
  PLN: "zł",
  CAD: "C$",
  AED: "د.إ",
  SAR: "ر.س",
  EGP: "E£",
  VND: "₫",
  ARS: "$",
  CLP: "$",
  COP: "$",
  UYU: "$",
};

export const distinctCurrenciesByCountryCode = {
  AUS: "AUD",
  USA: "USD",
  CAN: "CAD",
  GBR: "GBP",
  HKG: "HKD",
  ISL: "ISK",
  PHL: "PHP",
  DNK: "DKK",
  HUN: "HUF",
  CZE: "CZK",
  ROU: "RON",
  SWE: "SEK",
  IDN: "IDR",
  IND: "INR",
  BRA: "BRL",
  RUS: "RUB",
  HRV: "HRK",
  THA: "THB",
  JPN: "JPY",
  CHE: "CHF",
  EUR: "EUR",
  MYS: "MYR",
  BGR: "BGN",
  TUR: "TRY",
  CHN: "CNY",
  NOR: "NOK",
  NZL: "NZD",
  ZAF: "ZAR",
  MEX: "MXN",
  ISR: "ILS",
  KOR: "KRW",
  POL: "PLN",
  SGP: "SGD",
  ARE: "AED",
  SAU: "SAR",
  EGY: "EGP",
  VNM: "VND",
  ARG: "ARS",
  CHL: "CLP",
  COL: "COP",
  URY: "UYU",
};

// eslint-disable-next-line no-undefined
export const intFormatter = new Intl.NumberFormat(undefined, {
  useGrouping: true, // separating thousands etc
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

// eslint-disable-next-line no-undefined
export const intFormatterRounded = new Intl.NumberFormat(undefined, {
  useGrouping: true, // separating thousands etc
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/** Round a number to the given number of fractional digits.
 * e.g. roundFractionalDigits(6.05555, 2) == 6.06
 * Not guaranteed to handle floating point edge cases, but makes a
 * reasonable effort to do so. */
/* eslint-disable-next-line no-magic-numbers */
export const roundFractionalDigits = (number, fractionDigits = 2) => {
  if (typeof number !== "number") {
    return 0;
  }
  /* eslint-disable-next-line no-magic-numbers */
  const base = Math.pow(10, fractionDigits);
  return Math.round((number + Number.EPSILON) * base) / base;
};

export const formatCurrency = (
  number,
  currencyRates,
  sourceCurrency,
  currentCurrency
) => {
  const convertedNumber = getConvertedValue(
    currencyRates,
    sourceCurrency,
    currentCurrency,
    number
  );
  return (
    (number < 0 ? "-" : "") +
    getCurrencySymbol[currentCurrency] +
    intFormatter.format(Math.abs(convertedNumber))
  );
};

export const formatCurrencyRounded = (
  number,
  currencyRates,
  sourceCurrency = "AUD",
  currentCurrency = "AUD"
) => {
  const convertedNumber = getConvertedValue(
    currencyRates,
    sourceCurrency,
    currentCurrency,
    number,
    NO_CENTS
  );
  return (
    (number < 0 ? "-" : "") +
    getCurrencySymbol[currentCurrency] +
    intFormatterRounded.format(Math.abs(convertedNumber))
  );
};

export const getExchangeRate = (
  currencyRates,
  sourceCurrency,
  destCurrency
) => {
  if (sourceCurrency === destCurrency) {
    return 1.0;
  }
  const matched = currencyRates.find((i) => i._id === sourceCurrency);
  if (matched) {
    return parseFloat(matched.rates[destCurrency]);
  } else {
    return 1.0;
  }
};
