import { ArrowDropDown } from "@material-ui/icons";
import styled from "styled-components";

const StyledArrowDropDown = styled(ArrowDropDown)`
  width: 20px;
  margin-right: -0.25rem;
  margin-left: 0.25rem;
`;

export default StyledArrowDropDown;
