import {
  FETCH_CONNECTION_STATUS,
  FETCH_CONNECTION_STATUS_ERROR,
  FETCH_CONNECTION_STATUS_FETCHING,
  FETCH_CONNECTION_STATUS_SINGLE,
} from "./connections.redux";

import { Dispatch } from "redux";
import { StoreState } from "@typedef/store";
import axios from "axios";
import { baseUrl } from "../../configs";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

function setFetching(dispatch: Dispatch) {
  dispatch({
    type: FETCH_CONNECTION_STATUS_FETCHING,
  });
}

interface FetchConnectionStatusParams {
  user: StoreState["user"];
  mid?: string;
  sellerId?: string;
  market?: string;
}

export const fetchConnectionStatus =
  ({ user, mid, sellerId, market }: FetchConnectionStatusParams) =>
  async (dispatch: Dispatch) => {
    setFetching(dispatch);
    try {
      const res = await axios
        .post(`${baseUrl}/api/connection/status`, {
          user: { _id: user._id },
          mid,
          sellerId,
          market,
        })
        .catch((e) => {
          throw e;
        });

      if (res && isHttpResponseValid(res.status)) {
        const data = res.data;
        if (mid || sellerId) {
          return dispatch({
            type: FETCH_CONNECTION_STATUS_SINGLE,
            payload: {
              data,
              mid,
              sellerId,
            },
          });
        } else {
          return dispatch({
            type: FETCH_CONNECTION_STATUS,
            payload: data,
          });
        }
      } else {
        throw new Error(
          `${
            res.status
          } fetchConnectionStatus failed with error ${JSON.stringify(res.data)}`
        );
      }
    } catch (e) {
      return dispatch({
        type: FETCH_CONNECTION_STATUS_ERROR,
        payload: (e as Error).message,
      });
    }
  };
