import { Box, Grid, Typography } from "@material-ui/core";
import { Braintree, HostedField } from "react-braintree-fields";
import { PlanPropTypes, formatBillingPeriod } from "./plan/plan";
import {
  addDiscountOverride,
  removeDiscountOverride,
} from "../../store/subscriptions.redux";

import AlertCard from "~/components/alert/alertCard";
import CheckoutSummary from "./checkoutSummary";
import CreditCardBadge from "./assets/creditCardBadge";
import { FieldLabel } from "./subscriptionStyledComponents";
import PromoCodeField from "./promoCodeField";
import PropTypes from "prop-types";
import React from "react";
import ReactGA from "react-ga";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const StyledAlertCard = styled(AlertCard)`
  padding: 1rem 0;
`;

// Container for all the checkout fields
const FieldContainer = (props) => <Grid container spacing={2} {...props} />;

// A wrapper around individual fields
const FieldWrapper = styled(Grid)`
  ${({ theme }) => `
    padding-top: 24px;
    ${theme.breakpoints.up("md")} {
      padding-right: 50px;
    }
  `}
`;

const StyledHostedField = styled(HostedField)`
  border-bottom: ${({ theme, selected }) =>
    selected
      ? `1px ${theme.palette.primary.main} solid`
      : `1px ${theme.palette.grey["500"]} solid`};
  height: 30px;
  transition: border-bottom 300ms;
`;

const BraintreeCheckoutForm = ({
  displaySummary,
  summaryProps,
  promoCode,
  setPromoCode,
  error,
  setError,
  handleError,
  setTokenizeFunc,
  discount,
  discountAmount,
  setDiscountAmount,
  newUser,
  editMode,
}) => {
  const { t } = useTranslation();
  const [focusedFieldName, setFocusedField] = React.useState("");
  const [cardType, setCardType] = React.useState("");
  const numberField = React.useRef();
  const expiryField = React.useRef();
  const cvvField = React.useRef();
  const dispatch = useDispatch();

  const trialExpiry = moment()
    .startOf("day")
    .add(summaryProps.plan.trialPeriod, "days");
  const numTrialDays = discount ? discount.numTrialDays : 0;

  const today = moment();
  const promoStart = newUser && !numTrialDays ? trialExpiry : today;
  const billingCycle = numTrialDays
    ? "day"
    : t(formatBillingPeriod(summaryProps.plan, false));
  const discountCycles = numTrialDays ? numTrialDays : 0;
  const firstChargeDate = moment(promoStart)
    .add(discountCycles, billingCycle)
    .format("LL");

  React.useEffect(() => {
    ReactGA.event({
      category: "Subscription",
      action: "Payment Method - Started",
    });
  }, []);

  React.useEffect(() => {
    dispatch(removeDiscountOverride());
  }, [dispatch]);

  const onAuthorizationSuccess = () => {
    numberField.current.focus();
  };

  const onFieldFocus = (field, event) => {
    setFocusedField(event.emittedBy);
  };

  const onPromoCodeFocus = () => {
    // When the promo code field is focused, unfocus the
    // other fields
    setFocusedField("");
  };

  const onPromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const onPromoCodeSuccess = (amount, trialDays, numberOfBillingCycles) => {
    setError(null);
    setDiscountAmount(amount);
    dispatch(
      addDiscountOverride({
        amount,
        numTrialDays: trialDays,
        numberOfBillingCycles,
      })
    );
  };

  const onPromoCodeError = (errorMessage) => {
    setError(errorMessage);
  };

  const onCardTypeChange = (_field, event) => {
    // Only set the card type if we're sure what it is
    if (get(event, "cards.length") === 1) {
      setCardType(get(event, "cards[0].type"));
    } else {
      setCardType("");
    }
  };

  const BRAINTREE_TOKENIZATION_KEY = process.env.BRAINTREE_TOKENIZATION_KEY;

  return (
    <Box py={1}>
      {error && (
        <StyledAlertCard
          type={"error"}
          isOpen={true}
          onClose={() => {
            setError(null);
          }}
        >
          {error}
        </StyledAlertCard>
      )}

      <Braintree
        authorization={BRAINTREE_TOKENIZATION_KEY}
        onAuthorizationSuccess={onAuthorizationSuccess}
        onError={handleError}
        getTokenRef={(ref) => setTokenizeFunc(() => ref)}
        styles={{
          input: {
            "font-size": "16px",
            color: "#3a3a3a",
          },
          ":focus": {
            color: "black",
          },
        }}
      >
        <FieldContainer>
          <FieldWrapper item xs={12}>
            <FieldLabel htmlFor="frmCCNum">
              {t("settings.billing.cardNumberLabel")}
            </FieldLabel>
            <StyledHostedField
              type="number"
              selected={focusedFieldName === "number"}
              onFocus={onFieldFocus}
              onCardTypeChange={onCardTypeChange}
              ref={numberField}
              autocomplete="cc-number"
              name="cardnumber"
              id="frmCCNum"
            />
            <Box pb={1} mt={-4} style={{ float: "right" }}>
              <CreditCardBadge cardType={cardType} />
            </Box>
          </FieldWrapper>
          <FieldWrapper item xs={6}>
            <FieldLabel htmlFor="frmCCExp">
              {t("settings.billing.expirationDateLabel")}
            </FieldLabel>
            <StyledHostedField
              type="expirationDate"
              selected={focusedFieldName === "expirationDate"}
              onFocus={onFieldFocus}
              ref={expiryField}
              autocomplete="cc-exp"
              name="cc-exp"
              id="frmCCExp"
            />
          </FieldWrapper>
          <FieldWrapper item xs={6}>
            <FieldLabel htmlFor="frmCCCVC">
              {t("settings.billing.cvvLabel")}
            </FieldLabel>
            <StyledHostedField
              type="cvv"
              selected={focusedFieldName === "cvv"}
              onFocus={onFieldFocus}
              ref={cvvField}
              autocomplete="cc-csc"
              name="cvc"
              id="frmCCCVC"
            />
          </FieldWrapper>
          {summaryProps && !editMode && (
            <FieldWrapper item xs={12}>
              <FieldLabel>{t("settings.billing.promoCodeLabel")}</FieldLabel>
              <PromoCodeField
                value={promoCode}
                onChange={onPromoCodeChange}
                plan={summaryProps.plan}
                planId={summaryProps.plan.planId}
                inputProps={{ onFocus: onPromoCodeFocus }}
                onPromoCodeSuccess={onPromoCodeSuccess}
                onPromoCodeError={onPromoCodeError}
                newUser={newUser}
              />
            </FieldWrapper>
          )}
        </FieldContainer>
      </Braintree>
      <Box pt={2}>
        <Grid container spacing={1}>
          {displaySummary && summaryProps && (
            <CheckoutSummary
              {...summaryProps}
              discount={discount}
              discountAmount={discountAmount}
              newUser={newUser}
            />
          )}
        </Grid>
      </Box>
      {(newUser || Boolean(numTrialDays)) && (
        <Box pt={2}>
          <Typography variant="body2">
            {t("subscription.create.firstChargeMessage", { firstChargeDate })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

BraintreeCheckoutForm.defaultProps = {
  displaySummary: false,
  newUser: false,
};

BraintreeCheckoutForm.propTypes = {
  newUser: PropTypes.bool,
  editMode: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  setTokenizeFunc: PropTypes.func.isRequired,
  displaySummary: PropTypes.bool,
  summaryProps: PropTypes.shape({
    plan: PlanPropTypes,
  }),
  promoCode: PropTypes.string,
  setPromoCode: PropTypes.func,
  error: PropTypes.string,
  discountAmount: PropTypes.number,
  setDiscountAmount: PropTypes.func,
  discount: PropTypes.shape({
    amount: PropTypes.number,
    numTrialDays: PropTypes.number,
    numberOfBillingCycles: PropTypes.number,
  }),
};

export default BraintreeCheckoutForm;
