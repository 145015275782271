import { Grid, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "../typography/bold";
import Light from "../typography/light";
import PropTypes from "prop-types";
import StatusText from "../typography/status";
import styled from "styled-components";

const SizeVariant = {
  Small: "small",
  Med: "medium",
  Lge: "large",
};

const StatusAvatar = withTheme(styled.div`
  ${({ theme, status, size }) => `
      display: flex;
      background-color: ${
        status
          ? theme.palette[status]?.main ?? theme.palette.disabled.main
          : theme.palette.disabled.main
      };
      color: white;
      text-align: center;
      min-width: fit-content;
      width: ${
        size === SizeVariant.Small
          ? theme.spacing(2)
          : size === SizeVariant.Med
          ? theme.spacing(3)
          : theme.spacing(8)
      }px;
      height: ${
        size === SizeVariant.Small
          ? theme.spacing(2)
          : size === SizeVariant.Med
          ? theme.spacing(3)
          : theme.spacing(8)
      }px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    `}
`);

const VerticalGrid = styled(Grid)`
  flex-direction: column;
`;

const StatusIndicator = memo(function StatusIndicator(props) {
  const {
    status,
    size,
    statusText,
    title,
    statusScore,
    subtitle,
    footnote,
    conditionalFormatting,
  } = props;
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>
        <StatusAvatar
          status={conditionalFormatting === false ? "disabled" : status}
          size={size}
        >
          {statusScore && statusScore}
        </StatusAvatar>
      </Grid>
      <VerticalGrid item>
        {title && (
          <Bold variant="body1" color="textPrimary">
            {title}
          </Bold>
        )}
        {subtitle && (
          <Light variant="body2" color="textSecondary">
            {subtitle}
          </Light>
        )}
        {footnote && (
          <Typography variant="body2" color="textPrimary">
            {footnote}
          </Typography>
        )}
        <StatusText
          variant="body1"
          status={conditionalFormatting === false ? undefined : status}
          noWrap
        >
          {statusText}
        </StatusText>
      </VerticalGrid>
    </Grid>
  );
});

StatusIndicator.defaultProps = {
  status: "disabled",
  size: SizeVariant.Lge,
};

StatusIndicator.propTypes = {
  statusText: PropTypes.string.isRequired,
  title: PropTypes.string,
  status: PropTypes.oneOf([
    "success",
    "error",
    "info",
    "warning",
    "disabled",
    "noData",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  statusScore: PropTypes.string,
  subtitle: PropTypes.string,
  footnote: PropTypes.string,
};

export default StatusIndicator;
