import Bold from "../../typography/bold";
import { IconAndTextCell } from "../cells/iconAndTextCell";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import React from "react";
import SmallButton from "../../buttons/smallButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  width: ${({ width }) => width || null}!important;
  flex-grow: ${({ width }) => (width ? "0 !important" : "inherit")};
`;

const ButtonCell = styled.td`
  width: 155px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px !important;
  padding-right: 10px !important;
`;

const StyledIcon = styled(KeyboardArrowRight)`
  margin-right: -10px;
`;

interface ConnectAdvertisingRowProps {
  row: {
    id: string;
    original: {
      report?: boolean;
    };
    allCells: any[];
  };
  getCellProps: (store: string) => void;
}

export const ConnectAdvertisingRow: React.FC<ConnectAdvertisingRowProps> = ({
  row,
  getCellProps,
}) => {
  const { t } = useTranslation();
  const cells = row.allCells;
  const marketplaceInfo = cells.find(
    (cell) => cell.column.id === "market" || cell.column.id === "marketplace"
  );
  const store = cells.find(
    (cell) => cell.column.id === "shopName" || cell.column.id === "store"
  );
  const isReport = row.original.report;

  return (
    <>
      <Cell
        width="90px"
        align={marketplaceInfo.column.align}
        {...marketplaceInfo.getCellProps(getCellProps(marketplaceInfo))}
        key={`${row.id} - connect advertising - ${marketplaceInfo}`}
      >
        {marketplaceInfo.render("Cell")}
      </Cell>
      <Cell
        align={store.column.align}
        {...store.getCellProps(getCellProps(store))}
        key={`${row.id} - connect advertising store - ${store}`}
      >
        <IconAndTextCell cell={store} status="link" />
      </Cell>
      <ButtonCell colSpan={2}>
        {!isReport ? (
          <SmallButton
            {...{
              component: Link,
              color: "info",
              to: store.value.connectLink,
            }}
          >
            <Bold variant="body2">{t("generic.linkButton")}</Bold>
            <StyledIcon fontSize="small" />
          </SmallButton>
        ) : null}
      </ButtonCell>
    </>
  );
};
