import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
  intFormatterRounded,
} from "~/utils/currencyUtils";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";

import { FormatDataProps } from "./profitabilitySummaryExpanded";
import { getPercentageDifference } from "~/utils/salesUtils";
import { isEmpty } from "lodash";

export const formatData = ({
  profitability,
  categoriesData,
  filteredStores,
  currencyRates,
  currentCurrency,
  allowedLinks,
  conditionalFormatting,
}: FormatDataProps) => {
  if (isEmpty(profitability) || isEmpty(categoriesData)) {
    return [];
  }

  if (profitability[0] && !profitability[0].current) {
    return [];
  }
  const profitabilityData = profitability.map((profitabilityValue: any) => {
    const { prior, current } = profitabilityValue;
    const market = current.market;
    const countryCode = current.countryCode;
    const shop = filteredStores
      ? getShopName(filteredStores, market, current.store)
      : "";
    const storeName = {
      value: shop,
      link: marketplaceLink(
        market,
        current.store,
        "profitabilityStore",
        undefined,
        allowedLinks
      ),
    };
    const homeCurrency = getCurrencyByCountryCode[current.countryCode];

    return {
      storeName,
      totalSales: {
        value: current.totalSales
          ? formatCurrencyRounded(
              current.totalSales,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(current.totalSales, prior.totalSales)
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      totalSellingFees: {
        value: current.totalSellingFees
          ? formatCurrencyRounded(
              current.totalSellingFees,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalSellingFees,
              prior.totalSellingFees
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageSellingFees:
        current.totalRevenue > 0
          ? `${(
              (100 * current.totalSellingFees) /
              current.totalRevenue
            )?.toFixed(1)}%`
          : "-",

      totalRefunds: {
        value: current.totalRefunds
          ? formatCurrencyRounded(
              current.totalRefunds,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(current.totalRefunds, prior.totalRefunds)
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },
      totalReimbursements: {
        value: current.totalReimbursements
          ? formatCurrencyRounded(
              current.totalReimbursements,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalReimbursements,
              prior.totalReimbursements
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },
      totalAdvertising: {
        value: current.totalAdvertising
          ? formatCurrencyRounded(
              current.totalAdvertising,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalAdvertising,
              prior.totalAdvertising
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },
      percentageAdvertising:
        (100 * current.totalAdvertising) / current.totalRevenue
          ? `${(
              (100 * current.totalAdvertising) /
              current.totalRevenue
            ).toFixed(1)}%`
          : "-",
      totalPromotions: {
        value: current.totalPromotions
          ? formatCurrencyRounded(
              current.totalPromotions,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalPromotions,
              prior.totalPromotions
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentagePromotions:
        (100 * Math.abs(current.totalPromotions)) / current.totalRevenue
          ? `${(
              (100 * Math.abs(current.totalPromotions)) /
              current.totalRevenue
            ).toFixed(1)}%`
          : "-",

      totalOtherIncome: {
        value: current.totalOtherIncome
          ? formatCurrencyRounded(
              current.totalOtherIncome,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalOtherIncome,
              prior.totalOtherIncome
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      totalRefundsAndReturns: {
        value: current.totalRefundsAndReturns
          ? formatCurrencyRounded(
              current.totalRefundsAndReturns,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalRefundsAndReturns,
              prior.totalRefundsAndReturns
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageRefundsAndReturns:
        (100 * current.totalRefundsAndReturns) / current.totalRevenue
          ? `${(
              (100 * current.totalRefundsAndReturns) /
              current.totalRevenue
            ).toFixed(1)}%`
          : "-",

      totalCogs: {
        value: current.totalCogs
          ? formatCurrencyRounded(
              current.totalCogs,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(current.totalCogs, prior.totalCogs)
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageCogs:
        (100 * current.totalCogs) / current.totalRevenue
          ? `${((100 * current.totalCogs) / current.totalRevenue).toFixed(1)}%`
          : "-",

      totalOtherExpenses: {
        value: current.totalOtherExpenses
          ? formatCurrencyRounded(
              current.totalOtherExpenses,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalOtherExpenses,
              prior.totalOtherExpenses
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageOtherExpenses:
        (100 * current.totalOtherExpenses) / current.totalRevenue
          ? `${(
              (100 * current.totalOtherExpenses) /
              current.totalRevenue
            ).toFixed(1)}%`
          : "-",

      totalShippingFees: {
        value: current.totalShippingFees
          ? formatCurrencyRounded(
              current.totalShippingFees,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(
              current.totalShippingFees,
              prior.totalShippingFees
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageShipping:
        (100 * current.totalShippingFees) / current.totalRevenue
          ? `${(
              (100 * current.totalShippingFees) /
              current.totalRevenue
            ).toFixed(1)}%`
          : "-",

      totalProfit: {
        value: current.totalProfit
          ? formatCurrencyRounded(
              current.totalProfit,
              currencyRates,
              homeCurrency,
              currentCurrency
            )
          : "-",
        growth: prior
          ? getPercentageDifference(current.totalProfit, prior.totalProfit)
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageProfit:
        (100 * current.totalProfit) / current.totalRevenue
          ? `${((100 * current.totalProfit) / current.totalRevenue).toFixed(
              1
            )}%`
          : "-",

      grossMargin: (100 * current.totalProfit) / current.totalRevenue,

      unitCount: {
        value:
          current.unitCount > 0
            ? intFormatterRounded.format(current.unitCount)
            : "-",
        growth: prior
          ? getPercentageDifference(current.unitCount, prior.unitCount)
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      refundedUnitCount: {
        value:
          current.refundedUnitCount > 0
            ? intFormatterRounded.format(current.refundedUnitCount)
            : "-",
        growth: prior
          ? getPercentageDifference(
              current.refundedUnitCount,
              prior.refundedUnitCount
            )
          : "N/A",
        conditionalFormatting: conditionalFormatting,
      },

      percentageRefund: current.percentageRefund
        ? `${current.percentageRefund.toFixed(1)}%`
        : "-",

      shopName: shop,
      storeId: current.storeId,
      market,
      countryCode,
    };
  });

  return profitabilityData;
};
