import { Grid, useTheme } from "@material-ui/core";

import { CHART_KEYS } from "../chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const LegendIndicator = styled.div<{ fill: string }>`
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: ${({ fill }) => fill};
`;

export const LegendWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

interface BarChartLegendProps {
  currentPeriod: DATETIME_PERIODS;
}

export const BarChartLegend = ({ currentPeriod }: BarChartLegendProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const legendItems = Object.values(CHART_KEYS[currentPeriod]);
  return (
    <Grid container>
      {legendItems.map((item, i) => (
        <LegendWrapper item key={item}>
          <LegendIndicator
            theme={theme}
            fill={
              legendItems.length === 1
                ? theme.palette.primary.main
                : i === 0
                ? theme.palette.primary.light
                : theme.palette.primary.main
            }
          />
          {t(item)}
        </LegendWrapper>
      ))}
    </Grid>
  );
};
