import { CurrencyRate, Filter, Store } from "~/typedef/store";
import {
  MarketplaceOverviewRow,
  useMarketplaceOverviewQuery,
} from "../../../store/overview/marketplaceOverview.redux";
import React, { memo, useCallback, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";
import {
  formatNumber,
  getComparisonValue,
  getConvertedComparisonValue,
  getPercentageDifference,
} from "~/utils/salesUtils";
import {
  getShopName,
  marketplaceLink,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import { Box } from "@material-ui/core";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DateRange } from "~/typedef/date";
import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { PaginationArgs } from "~/typedef/pagination";
import { Panel } from "../../../components/panel/panel";
import Table from "../../../components/table/table";
import { ValueAndGrowthCell } from "../../../components/table/cells/valueAndGrowthCell";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

// all marketplaceOverviewRows have sales data in a target currency
// the targetCurrency is USD at the moment
const formatMarketplaceData = (
  filteredStores: Store[],
  marketplaceOverviewRows: MarketplaceOverviewRow[],
  currencyRates: CurrencyRate[],
  currentCurrency: string,
  conditionalFormatting?: boolean
) => {
  if (!marketplaceOverviewRows) {
    return [];
  }
  const marketplaceOverviewData = marketplaceOverviewRows.map((marketplace) => {
    const convertedSalesValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.value
    );
    const convertedPriorValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.priorPeriodValue
    );
    const convertedOrderValue =
      marketplace.avOrderValue?.value != null
        ? getConvertedValue(
            currencyRates,
            marketplace.currency,
            currentCurrency,
            marketplace.avOrderValue.value
          )
        : null;
    const convertedOrderPriorValue =
      marketplace.avOrderValue?.value != null
        ? getConvertedValue(
            currencyRates,
            marketplace.currency,
            currentCurrency,
            marketplace.avOrderValue.priorPeriodValue
          )
        : null;
    return {
      ...marketplace,
      convertedSalesValue,
      convertedPriorValue,
      convertedOrderValue,
      convertedOrderPriorValue,
    };
  });
  return marketplaceOverviewData.map((marketplace) => {
    const shopName = {
      value: getShopName(
        filteredStores,
        marketplace.marketplace.market,
        marketplace.store
      ),
      link: marketplaceLink(marketplace.marketplace.market, marketplace.store),
    };
    const orders = {
      value: marketplace.orders.value ?? "-",
      growth: getPercentageDifference(
        marketplace.orders.value,
        getComparisonValue(marketplace.orders)
      ),
      conditionalFormatting: conditionalFormatting,
    };
    const sales = {
      sortValue: marketplace.convertedSalesValue,
      value: formatCurrencyRounded(
        marketplace.convertedSalesValue,
        currencyRates,
        currentCurrency,
        currentCurrency
      ),
      growth: getPercentageDifference(
        marketplace.convertedSalesValue,
        getConvertedComparisonValue(marketplace)
      ),
      conditionalFormatting: conditionalFormatting,
    };

    const avgOrderValue = {
      value:
        marketplace.convertedOrderValue != null
          ? formatCurrency(
              marketplace.convertedOrderValue,
              currencyRates,
              currentCurrency,
              currentCurrency
            )
          : "-",
      growth: getPercentageDifference(
        marketplace.convertedOrderValue,
        getConvertedComparisonValue(marketplace, "orders")
      ),
      conditionalFormatting: conditionalFormatting,
    };

    const avgOrderSize = {
      value:
        marketplace.avOrderSize?.value != null
          ? formatNumber(marketplace.avOrderSize.value)
          : "-",
      growth: getPercentageDifference(
        marketplace.avOrderSize.value,
        getComparisonValue(marketplace.avOrderSize)
      ),
      conditionalFormatting: conditionalFormatting,
    };

    const salesSplit = Number.isFinite(marketplace.salesSplit)
      ? `${Math.round(marketplace.salesSplit)}%`
      : "-";

    return {
      ...marketplace,
      shopName,
      orders,
      sales,
      avgOrderValue,
      avgOrderSize,
      salesSplit,
    };
  });
};

export const columns = (
  t: TFunction,
  report: boolean | undefined,
  isVendorStore: boolean
) => [
  {
    id: "marketplace",
    Header: t("dashboardWidget.marketplaceOverview.marketplaceColumn"),
    accessor: "marketplace",
    Cell: MarketplaceAndCountryCell,
  },
  {
    id: "shopName",
    Header: t("dashboardWidget.marketplaceOverview.storeColumn"),
    accessor: "shopName",
    Cell: LinkCell,
  },
  {
    id: "sales",
    Header: isVendorStore
      ? t("vendor.orderedRevenue")
      : t("dashboardWidget.marketplaceOverview.salesColumn"),
    accessor: "sales",
    Cell: ValueAndGrowthCell,
    align: "right",
  },
  {
    id: "salesSplit",
    Header: t("dashboardWidget.marketplaceOverview.salesMixColumn"),
    accessor: "salesSplit",
    align: "center",
    hiddenDown: report ? "xl" : "sm",
  },
  ...(isVendorStore
    ? []
    : [
        {
          id: "orders",
          Header: t("dashboardWidget.marketplaceOverview.ordersColumn"),
          accessor: "orders",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "xl" : "sm",
        },
        {
          id: "avgOrderSize",
          Header: t("dashboardWidget.marketplaceOverview.avgOrderSizeColumn"),
          accessor: "avgOrderSize",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
        {
          id: "avgOrderValue",
          Header: t("dashboardWidget.marketplaceOverview.avgOrderValueColumn"),
          accessor: "avgOrderValue",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
      ]),
];

interface MarketplaceOverviewParams {
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: DateRange;
  pageSize: number;
  includeTax: boolean;
  currentFilter: Filter;
  filteredStores: Store[];
  report?: boolean;
  conditionalFormatting?: boolean;
}

const MarketplaceOverview = memo<MarketplaceOverviewParams>(
  function MarketplaceOverview({
    currentPeriod,
    currentRange,
    currentCurrency,
    report,
    pageSize,
    includeTax,
    currentFilter,
    filteredStores,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const isVendorStore = filteredStores.every(
      (store) => stripFilteredSuffix(store.marketplaceType) === "amazon_vendor"
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [paginationParams, setPaginationParams] = useState<
      Pick<PaginationArgs, "pageSize" | "pageIndex">
    >({
      pageIndex: 0,
      pageSize,
    });

    const {
      marketplaceOverviewRows,
      marketplaceOverviewCount,
      marketplaceOverviewFetching,
    } = useMarketplaceOverviewQuery(
      {
        currentPeriod,
        currentRange,
        filter: currentFilter,
        includeTax,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          marketplaceOverviewRows: data?.rows || [],
          marketplaceOverviewCount: data?.count || 0,
          marketplaceOverviewFetching: isFetching,
        }),
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
      setPaginationParams({
        pageIndex,
        pageSize,
      });
    }, []);

    const data = useMemo(
      () =>
        formatMarketplaceData(
          filteredStores,
          marketplaceOverviewRows,
          currencyRates,
          currentCurrency,
          conditionalFormatting
        ),
      [marketplaceOverviewRows, currencyRates, currentCurrency]
    );

    const columnsMemo = useMemo(
      () => columns(t, report, isVendorStore),
      [isVendorStore]
    );

    return (
      <Panel
        id="widget-marketplace-overview"
        title={t(
          `dashboardWidget.${
            isVendorStore ? "marketplaceOverviewVendor" : "marketplaceOverview"
          }.mainTitle`
        )}
        tooltip={t("dashboardWidget.marketplaceOverview.mainTooltip")}
        footerLink={
          report
            ? undefined
            : {
                url: "/overview/marketplaceOverview",
                label: t("generic.viewAllLink"),
              }
        }
        content={
          <Box
            mb={isMobile || report || data.length < PAGE_SIZE + 1 ? 0 : "-53px"}
          >
            <Table
              columns={columnsMemo}
              data={data}
              loading={marketplaceOverviewFetching}
              fetchData={fetchData}
              pageSize={pageSize}
              pagination={!report}
              pageCount={Math.ceil(marketplaceOverviewCount / PAGE_SIZE)}
              gridLayoutColumns={6}
            />
          </Box>
        }
      />
    );
  }
);

export default MarketplaceOverview;
