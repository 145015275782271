import React, { memo } from "react";

import { Typography } from "@material-ui/core";
import { percentColour } from "~/theming/utils";
import styled from "styled-components";

interface StyleProps {
  percent: number;
  fontWeight?: string | number;
  customcolor?: string;
}

const ColouredTypography = styled(Typography)<StyleProps>`
  color: ${(props) =>
    props.customcolor ?? percentColour(props.percent, props.theme)};
  font-weight: ${(props) =>
    props.fontWeight ?? props.theme.typography.heavyDetail.fontWeight};
  font-size: ${(props) => props.theme.typography.heavyDetail.fontSize};
`;

interface props {
  cell: {
    value: number;
  };
  fontWeight?: string | number;
  color?: string;
}
const ColouredPercentCell: React.FC<props> = ({
  cell: { value },
  fontWeight,
  color,
}) => {
  const percent = typeof value === "string" ? parseInt(value) : value;
  return (
    <ColouredTypography
      customcolor={color}
      fontWeight={fontWeight}
      percent={percent}
      noWrap
    >
      {`${Math.round(value)}%`}
    </ColouredTypography>
  );
};

export default memo(ColouredPercentCell);
