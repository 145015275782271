import { Box, IconButton, Link } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { marketplaceLink, stripFilteredSuffix } from "~/utils/marketplaceUtils";

import { ArrowBack } from "@material-ui/icons";
import ConnectAdvertisingInfo from "~/components/info/connectAdvertisingInfo";
import { CustomGroup } from "@typedef/customGroups";
import { CustomGroupSelector } from "~/modules/widgets/customGroups/customGroupSelector";
import GenericConnectButton from "~/modules/login/genericConnectButton";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import LongSyncInfo from "~/components/info/longSyncInfo";
import Medium from "~/components/typography/medium";
import { Store } from "@typedef/store";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { miraklMarketplaceArray } from "../connectWizard/marketplaces";
import styled from "styled-components";
import { useCustomGroupsQuery } from "~/store/customGroups/reducer.redux";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useConnectBrandFilterMutation } from "~/store/connections/connectBrandFilter.redux";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin-left: -1.5rem;
`;

const BackButton = styled(IconButton)`
  margin: -15px 0 0 -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

interface BrandFilterConnectPageProps {
  onSuccess: () => void;
}

const BrandFilterConnectPage: React.FC<BrandFilterConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const [connectBrandFilter, { isLoading }] = useConnectBrandFilterMutation();

  const marketplace: string | null = urlSearchParams.get("marketplace");
  const parentMarketplace: string | null = marketplace
    ? stripFilteredSuffix(marketplace)
    : null;
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings.setting.data?.currentStore
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );
  const userInfo = useTypedSelector((state) => state.user);
  const newBrandFilterStore = useTypedSelector((state) =>
    get(state, "newBrandFilterStore")
  );

  const [store, setStore] = useState<Store | undefined>(
    filteredStores?.stores.find(
      (store) => store.marketplaceType === parentMarketplace
    )
  );
  const [selectedGroup, setSelectedGroup] = useState<CustomGroup | undefined>();
  const [showAdvertisingPrompt, setShowAdvertisingPrompt] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const errMsg = React.useRef("");

  const { customGroups, customGroupsFetching } = useCustomGroupsQuery(
    {
      mid: store?.merchantId || "",
      userId: userInfo?._id,
    },
    {
      skip: !store?.merchantId || !userInfo?._id,
      selectFromResult: ({ data, isFetching }) => ({
        customGroups: data?.groups || [],
        customGroupsFetching: isFetching,
      }),
    }
  );

  const changeStore = useCallback(
    (_market: string, mid: string = currentStore?.merchantId || ""): void => {
      const store: Store | undefined = filteredStores?.stores?.find(
        (store: Store) => store.merchantId === mid
      );

      if (store) {
        setStore(store);
      }
    },
    [filteredStores]
  );

  const changeCustomGroup = useCallback(
    (groupId: number): void => {
      const foundGroup: CustomGroup | undefined = customGroups?.find(
        (customGroup: CustomGroup) =>
          customGroup.groupId.toString() === groupId.toString()
      );

      if (foundGroup) {
        setSelectedGroup(foundGroup);
      }
    },
    [customGroups]
  );

  const redirectNextStep = useCallback(
    (_newMid) => {
      setIsAdded(true);
      if (store?.advertisingStatus && store.marketplaceType !== "bol") {
        onSuccess();
        setShowAdvertisingPrompt(true);
      } else {
        onSuccess();
      }
    },
    [store]
  );

  const handleSuccess = async () => {
    if (!store || !selectedGroup) {
      return;
    }
    try {
      const {
        merchantId,
        marketplaceType,
        marketplaceSubtype,
        marketplaceCountry,
      } = store;
      const res = await connectBrandFilter({
        groupId: selectedGroup.groupId,
        successMessage: t("connectWizard.brandFilteredLinkSuccess"),
        parentStoreParams: {
          mid: merchantId,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry,
        },
      }).unwrap();

      redirectNextStep(res.mid);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <GridWrapper id="amazon-connect-wrapper">
      <Grid container spacing={5}>
        <Grid item xs={2}>
          <BackButton onClick={history.goBack} id="back-button">
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item xs={10}>
          <TitleWrapper>
            <Medium color="textPrimary">
              {t(
                marketplace?.includes("vendor")
                  ? "connectWizard.brandVendorFilteredLinkTitle"
                  : "connectWizard.brandFilteredLinkTitle"
              )}
            </Medium>
          </TitleWrapper>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LongSyncInfo />
        </Grid>
        {showAdvertisingPrompt ? (
          <Grid container item xs={12} justifyContent="center">
            <ConnectAdvertisingInfo />
          </Grid>
        ) : !isAdded ? (
          <>
            <Grid container item xs={12} justifyContent="flex-start">
              <Box pt={1} pb={1} width={1}>
                <StoreSelector
                  changeStore={changeStore}
                  currentStore={
                    store
                      ? {
                          marketplace: store?.marketplaceType,
                          merchantId: store?.merchantId,
                          sourceSystemId: store?.merchantId.split(" @")[0],
                          marketplaceCountry: store?.marketplaceCountry,
                          storeName: store?.storeName,
                        }
                      : undefined
                  }
                  currentFilter={
                    parentMarketplace && parentMarketplace === "mirakl"
                      ? {
                          marketplaces: miraklMarketplaceArray,
                          countries: [],
                          tags: [],
                        }
                      : parentMarketplace
                      ? {
                          marketplaces: [parentMarketplace],
                          countries: [],
                          tags: [],
                        }
                      : undefined
                  }
                  maxWidth={900}
                  fullWidth={true}
                  rightAlign={true}
                />
              </Box>
            </Grid>
            {store && (
              <Grid container item xs={12} justifyContent="flex-start">
                <Box pt={0} pb={1} width={1}>
                  {customGroupsFetching || filteredStores.fetching ? (
                    <LoadingIndicator size={30} />
                  ) : isEmpty(customGroups) ? (
                    <Box display="flex" flexDirection="column">
                      <Medium align="center" color="textPrimary">
                        {t("connectWizard.createCustomGroup")}
                      </Medium>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                      >
                        <Link
                          component={RouterLink}
                          to={marketplaceLink(
                            store?.marketplaceType,
                            store.merchantId,
                            "salesbygroup"
                          )}
                        >
                          {t("connectWizard.createGroup")}
                        </Link>
                      </Typography>
                    </Box>
                  ) : (
                    <CustomGroupSelector
                      customGroups={customGroups}
                      changeCustomGroup={changeCustomGroup}
                      selectedGroup={selectedGroup}
                      maxWidth={900}
                      fullWidth={true}
                      rightAlign={true}
                    />
                  )}
                </Box>
              </Grid>
            )}
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              item
              xs={12}
            >
              <Grid item>
                <GenericConnectButton
                  disabled={!!isEmpty(selectedGroup)}
                  onSuccess={handleSuccess}
                >
                  {newBrandFilterStore.loading ? (
                    <LoadingIndicator />
                  ) : (
                    t("connectWizard.createFilteredStore")
                  )}
                </GenericConnectButton>
              </Grid>
            </Grid>
          </>
        ) : null}
        <>
          {errMsg && errMsg.current && (
            <Grid container item xs={12}>
              <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default BrandFilterConnectPage;
