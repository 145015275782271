import { AgencyConfig, AGENCY_CONFIG } from "~/configs";

import { StoreState } from "~/typedef/store";
import cookies from "browser-cookies";
import get from "lodash/get";
import { useMemo } from "react";
import { useTypedSelector } from "./useTypedSelector";

export const useAgencySpecificConfig = (): AgencyConfig => {
  const user = useTypedSelector((state: StoreState) => state.user);

  const response: AgencyConfig = useMemo(() => {
    let agency: string = "";
    let originalDomain: string = "";
    let hideHelpCenter: boolean = false;
    let hideLoginProviders: boolean = false;
    let hideSignUp: boolean = false;
    let signupPath: string = "";

    for (const key of Object.keys(AGENCY_CONFIG)) {
      if (
        AGENCY_CONFIG[key] &&
        (get(user, "agency") === AGENCY_CONFIG[key].agency ||
          cookies.get("originalDomain") === AGENCY_CONFIG[key].originalDomain)
      ) {
        agency = AGENCY_CONFIG[key].agency;
        originalDomain = AGENCY_CONFIG[key].originalDomain;
        hideHelpCenter = AGENCY_CONFIG[key].hideHelpCenter;
        hideLoginProviders = AGENCY_CONFIG[key].hideLoginProviders;
        hideSignUp = AGENCY_CONFIG[key].hideSignUp;
        signupPath = AGENCY_CONFIG[key].signupPath;
        break;
      }
    }

    return {
      agency,
      originalDomain,
      hideHelpCenter,
      hideLoginProviders,
      hideSignUp,
      signupPath,
    };
  }, [user]);

  return response;
};
