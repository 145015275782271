import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";

const LegendRowElement = styled.div`
  margin-right: 4px;
  align-items: center;
  display: flex;
`;

const NameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

const intFormatter = new Intl.NumberFormat(void 0, {
  useGrouping: true, // separating thousands etc
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface NotificationsPieChartLegendItemProps {
  name: string;
  value: number;
  piePercentage: number;
  noMargin?: boolean;
  compact?: boolean;
}

const NotificationsPieChartLegendItem = memo(
  ({
    name,
    value,
    piePercentage,
    noMargin = false,
    compact = false,
  }: NotificationsPieChartLegendItemProps) => {
    const piePercentageText = Number.isNaN(piePercentage)
      ? "N/A"
      : `${piePercentage}%`;
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        // eslint-disable-next-line no-magic-numbers
        mb={noMargin ? 0 : compact ? 0.5 : 2}
        mt={compact ? "3px" : 0}
        minWidth={170}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          overflow="hidden"
        >
          <NameContainer>
            <Typography
              variant={compact ? "subtitle2" : "body1"}
              noWrap
              style={{ minWidth: 0 }}
            >
              {name}
            </Typography>
          </NameContainer>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
        >
          <LegendRowElement>
            <Typography
              variant={compact ? "subtitle2" : "body2"}
              style={{ fontWeight: compact ? 600 : 400 }}
            >
              {intFormatter.format(value)}
            </Typography>
          </LegendRowElement>
          <LegendRowElement>
            <Typography
              variant={compact ? "subtitle2" : "body2"}
              color="textSecondary"
              style={{ fontWeight: 300 }}
            >
              ({piePercentageText})
            </Typography>
          </LegendRowElement>
        </Box>
      </Box>
    );
  }
);

export default NotificationsPieChartLegendItem;
