import { Box, InputAdornment, TextField } from "@material-ui/core";
import { PlanPropTypes, formatBillingPeriod } from "./plan/plan";
import React, { useCallback, useEffect } from "react";

import ActionButton from "~/components/buttons/actionButton";
import { NOT_FOUND } from "http-status-codes";
import { PromoItem } from "./subscriptionStyledComponents";
import PropTypes from "prop-types";
import axios from "axios";
import { baseUrl } from "~/configs";
import get from "lodash/get";
import getSymbolFromCurrency from "currency-symbol-map";
import { isHttpResponseValid } from "../../store/utils/httpsResponseCodes";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledField = styled(TextField)`
  padding-bottom: 0.25rem;
  width: 100%;
`;

/** @typedef {Object} Discount
 *   @property {string} promoCode
 *   @property {string[]} planIds - the plan IDs that this discount is applicable to
 *   @property {number} amount - the numerical amount of the discount, in whatever
 *   currency is applicable to the plans listed in planIds
 *   (i.e. the discount amount is "dollar-for-dollar" and doesn't change depending on the currency)
 */

/** Given a promo code and plan ID, fetch the corresponding discount object
 * from the backend, or return null if there is no corresponding discount.
 * @param {string} promoCode
 * @param {string} planId
 * @return {Discount}
 */
export const getDiscount = async (promoCode, planId) => {
  try {
    const { status, data } = await axios.get(
      `${baseUrl}/api/subscription-service/api/subscription/discount/${planId}/${promoCode}`
    );

    if (isHttpResponseValid(status)) {
      return {
        ...data,
        promoCode: data.promoCode,
        planIds: data.planIds,
        amount: data.amount ? parseFloat(data.amount) : 0,
      };
    }
  } catch (err) {
    const status = get(err, "response.status");
    // Regard this as normal operation
    if (status === NOT_FOUND) {
      return null;
    }
  }
  throw new Error(
    `Unexpected error fetching discount for partner code ${promoCode} and plan ${planId}`
  );
};

const verifyPromoCode = async (promoCode, planId, onSuccess, onError, t) => {
  try {
    const discount = await getDiscount(promoCode, planId);
    if (discount) {
      onSuccess(discount.amount, discount);
      return;
    } else {
      if (onError) {
        onError(t("subscription.create.invalidPromoCodeError", { promoCode }));
      }
      return;
    }
  } catch (err) {
    if (onError) {
      onError(err.message);
    }
  }
};

const VerifyButton = styled(ActionButton)`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.disabled.light : theme.palette.info.main};
  color: ${({ theme }) => theme.palette.info.contrastText};
  border-radius: 0px;
  bottom: 0.25rem;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const VerifyAdornment = ({ value, onClick }) => {
  const { t } = useTranslation();
  return (
    <InputAdornment variant="filled" position="end">
      <VerifyButton
        disabled={!value}
        aria-label="Verify partner code"
        onClick={onClick}
      >
        {t("subscription.create.verifyButton")}
      </VerifyButton>
    </InputAdornment>
  );
};
VerifyAdornment.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const PromoCodeField = ({
  onPromoCodeSuccess,
  onPromoCodeError,
  plan,
  planId,
  value,
  newUser,
  ...props
}) => {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState(false);
  const [discountDetails, setDiscountDetails] = React.useState(null);

  const onSuccess = useCallback((amount, discount) => {
    setSuccess(true);
    onPromoCodeSuccess(
      amount,
      discount.trialDays,
      discount.numberOfBillingCycles
    );
    setDiscountDetails(discount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = useCallback((err) => {
    setSuccess(false);
    onPromoCodeError(err);
    setDiscountDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      verifyPromoCode(value, planId, onSuccess, onError, t);
    }
  }, [onError, onSuccess, planId, value]);

  const renderDiscountDetails = () => {
    if (success && discountDetails) {
      const numTrialDays = discountDetails.trialDays;
      const promoText =
        discountDetails && discountDetails.shortName
          ? `${discountDetails.shortName} (${discountDetails.description})`
          : "Partner code added.";

      const finalAmount = numTrialDays
        ? 0
        : plan.cost - (discountDetails.amount || 0);
      const paymentString = `${getSymbolFromCurrency(plan.currency)}${
        plan.cost
      } `;
      const paymentStringWithDiscount = `${getSymbolFromCurrency(
        plan.currency
      )}${finalAmount} ${plan.currency} /${t(formatBillingPeriod(plan))}`;

      const billingCycle = numTrialDays
        ? "day"
        : t(formatBillingPeriod(plan, false));
      const discountCycles = numTrialDays
        ? numTrialDays
        : discountDetails.numberOfBillingCycles;
      const trialExpiry = moment().startOf("day").add(plan.trialPeriod, "days");
      const today = moment();
      const promoStart = newUser && !numTrialDays ? trialExpiry : today;
      const startOfDiscount = moment(promoStart).format("LL");
      const endOfDiscount = moment(promoStart)
        .add(discountCycles, billingCycle)
        .format("LL");

      return (
        <Box mt="1rem" width="100%">
          <PromoItem
            leftText={promoText}
            leftSubtitle={`${startOfDiscount} - ${endOfDiscount}`}
            rightStrikethrough={paymentString}
            rightText={paymentStringWithDiscount}
            rightSubtitle={t("subscription.create.incSalesTaxLabel")}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      <StyledField
        {...props}
        value={value}
        InputProps={{
          endAdornment: (
            <VerifyAdornment
              value={value}
              onClick={() => verifyPromoCode(value, planId, onSuccess, onError)}
            />
          ),
        }}
      />
      {success && discountDetails && renderDiscountDetails()}
    </>
  );
};
PromoCodeField.propTypes = {
  onPromoCodeSuccess: PropTypes.func.isRequired,
  onPromoCodeError: PropTypes.func,
  plan: PlanPropTypes,
  planId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  newUser: PropTypes.bool.isRequired,
};
export default PromoCodeField;
