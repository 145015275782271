import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import React from "react";
import ReportSentTable from "~/modules/widgets/reportSentTable";
import { memo } from "react";

const ReportSentList = memo(() => {
  return (
    <PageBlock>
      <Grid container>
        <ReportSentTable />
      </Grid>
    </PageBlock>
  );
});

export default ReportSentList;
