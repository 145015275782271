import React, { ChangeEventHandler } from "react";
import { Select, SelectProps } from "@material-ui/core";

interface Option {
  label: string;
  value: string;
}

interface NativeSelectProps extends SelectProps {
  name: string;
  value: string;
  topOptions?: Option[];
  options: Option[];
  fullWidth?: boolean;
  placeholder?: string;
}

const NativeSelect = ({
  name,
  value = "placeholder",
  topOptions,
  options,
  onChange,
  fullWidth,
  placeholder,
  ...rest
}: NativeSelectProps) => (
  <Select
    name={name}
    native
    fullWidth={fullWidth}
    value={value}
    onChange={onChange}
    {...rest}
  >
    <option value="placeholder">{placeholder}</option>

    {topOptions &&
      topOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}

    {topOptions && <option disabled>─────────────────────────────</option>}

    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
);

export default NativeSelect;
