import lookup from "country-code-lookup";

const worldCountries = lookup.countries;

export const getCountryCode = [
  "AUS",
  "USA",
  "GBR",
  "HKG",
  "ISL",
  "PHL",
  "DNK",
  "HUN",
  "CZE",
  "ROU",
  "SWE",
  "IDN",
  "IND",
  "BRA",
  "RUS",
  "HRV",
  "THA",
  "JPN",
  "CHE",
  "AUT",
  "BEL",
  "DEU",
  "ESP",
  "FRA",
  "IRL",
  "ITA",
  "NLD",
  "SXM",
  "MYS",
  "BGR",
  "TUR",
  "CHN",
  "NOR",
  "NZL",
  "ZAF",
  "MEX",
  "ISR",
  "KOR",
  "POL",
  "SGP",
  "AED",
  "SAU",
  "EGY",
  "VNM",
];

// given a country code, return the iso2 country code of the country
export const getCountryCodeToISO2 = (countryCode: string) => {
  let countries = lookup.byIso(countryCode);
  // return countries && countries.iso2;
  if (countries) return countries.iso2;

  countries = lookup.byFips(countryCode);
  if (countries) return countries.iso2;

  return "";
};

export const getCountryToISO3 = (countryName: string) => {
  const country = lookup.byCountry(countryName);
  return country ? country.iso3 : "";
};

export const getCountryCodeToName = (countryCode: string) => {
  let countries = lookup.byIso(countryCode);
  if (countries) return countries.country;

  countries = lookup.byFips(countryCode);
  if (countries) return countries.country;

  return "";
};

// given a iso2 country code, return the common name of the country
// in English
export const getCountryCodeISO2ToName = (countryCode: string) =>
  worldCountries.find((country) => country.iso2 === countryCode)?.country || "";

// given a iso3 country code, return the common name of the country
// in English
export const getCountryCodeISO3ToName = (countryCode: string) =>
  worldCountries.find((country) => country.iso3 === countryCode)?.country || "";

export const getCountryCodeISO2ToCountry = (countryCode: string) =>
  worldCountries.find((country) => country.iso2 === countryCode);

export const getCountryCodeISO3ToCountry = (countryCode: string) =>
  worldCountries.find((country) => country.iso3 === countryCode);
