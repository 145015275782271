import React, { memo } from "react";

import { HealthStatus } from "~/utils/accountHealthUtils";
import { PropTypes } from "@material-ui/core";
import StatusText from "../../typography/status";

const percentageStatus = {
  Good: 10,
  AtRisk: 20,
};

export const calculateOutOfStockStatus = (percentage: number) => {
  if (percentage < percentageStatus.Good) {
    return HealthStatus.Good;
  }
  if (percentage < percentageStatus.AtRisk) {
    return HealthStatus.AtRisk;
  }
  if (percentage >= percentageStatus.AtRisk) {
    return HealthStatus.Poor;
  }
  return HealthStatus.NoStatus;
};

export interface OutOfStockCellProps {
  cell: {
    value: {
      outOfStockPercentage: string;
      conditionalFormatting?: boolean;
      align?: string;
    };
  };
}

export const OutOfStockCell = memo<OutOfStockCellProps>(
  function OutOfStockCell({ cell }) {
    const { outOfStockPercentage, conditionalFormatting, align } = cell.value;

    const outOfStockPercentageNumber = parseFloat(outOfStockPercentage);
    const value = isNaN(outOfStockPercentageNumber)
      ? "-"
      : outOfStockPercentageNumber.toFixed(1);
    const status = calculateOutOfStockStatus(outOfStockPercentageNumber);
    return (
      <StatusText
        align={align ? (align as PropTypes.Alignment) : "right"}
        justify={align ? align : "flex-end"}
        variant="body2"
        status={conditionalFormatting === false ? undefined : status}
      >
        {value === "-" ? value : `${value}%`}
      </StatusText>
    );
  }
);
