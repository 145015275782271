import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { FORBIDDEN } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../../configs";

axios.defaults.withCredentials = true;

export const connectBol = (clientId, clientSecret, shopName) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/bol/connect`,
        data: { clientId, clientSecret, shopName },
      };
      const res = await axios(options);

      if (
        res &&
        isHttpResponseValid(res.status) &&
        res.data &&
        res.data.code === RESPONSE_CODE.SUCCESS
      ) {
        resolve("succeed");
      } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
        reject("tokenInValid");
      } else {
        reject("failed");
      }
    } catch (err) {
      if (err.response && err.response.status === FORBIDDEN) {
        reject("forbidden");
      } else {
        reject("error");
      }
    }
  });

export const connectBolAdvertising = (clientId, clientSecret, mid) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/bol/connectAdvertising`,
        data: { clientId, clientSecret, mid },
      };
      const res = await axios(options);
      if (
        res &&
        isHttpResponseValid(res.status) &&
        res.data &&
        res.data.code === RESPONSE_CODE.SUCCESS
      ) {
        resolve("succeed");
      } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
        reject("tokenInValid");
      } else {
        reject("failed");
      }
    } catch (err) {
      if (err.response && err.response.status === FORBIDDEN) {
        reject("forbidden");
      } else {
        reject("error");
      }
    }
  });
