import { Box, InputAdornment, Paper, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import Medium from "../typography/medium";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import sortBy from "lodash/sortBy";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  padding-bottom: 0.75rem;
  width: 100%;
`;

const StyledAutoComplete = styled(Autocomplete)`
  margin-top: 0px;
  padding-left: 0.5rem;
`;

const StyledTextField = styled(TextField)`
  & .MuiAutocomplete-inputRoot {
    padding-right: 0px;
  }
  width: 98%;
`;

const AutoCompleteMarketplace = (props) => {
  const { instruction, options, onItemSelected } = props;

  const handleOnChange = (value) => {
    onItemSelected(value);
  };

  return (
    <StyledPaper elevation={3}>
      <StyledAutoComplete
        id="grouped-marketplace"
        clearOnBlur
        clearOnEscape
        handleHomeEndKeys
        openOnFocus
        options={options}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => handleOnChange(newValue)}
        forcePopupIcon={false}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={instruction}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(option) => (
          <>
            <MarketplaceIcon market={option.marketplace} />
            <Box pl={2}>
              <Medium>{option.name}</Medium>
            </Box>
          </>
        )}
      />
    </StyledPaper>
  );
};

AutoCompleteMarketplace.propTypes = {
  instruction: PropTypes.string,
  options: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

export default AutoCompleteMarketplace;
