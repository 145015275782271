import {
  ErrorFormButton,
  FieldValue,
  FormButton,
} from "./subscriptionStyledComponents";
import { Grid, Link } from "@material-ui/core";
import {
  formatBillingPeriod,
  getDueDate,
  getPlanName,
} from "~/modules/subscription/plan/plan";

import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OverridenFieldValue = styled(FieldValue)`
  text-decoration: line-through;
`;

const SubscriptionPlanPanel = ({
  subscription: { discounts, nextBillAmount, nextBillingDate, status },
  currentPlan,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const activeDiscounts = (discounts || []).filter(
    (discount) =>
      get(discount, "currentBillingCycle") <=
      get(discount, "numberOfBillingCycles")
  );
  const totalDiscount = activeDiscounts.reduce(
    (acc, discount) =>
      acc +
      parseFloat(get(discount, "amount", "0")) * get(discount, "quantity", 1),
    0
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FieldValue gutterBottom={true}>
          {t("settings.billing.currentPlanLabel")}:{" "}
          {t(getPlanName(currentPlan.planId))}
        </FieldValue>
        <FieldValue gutterBottom={true}>
          {t("settings.billing.priceLabel")}:{" "}
          {totalDiscount > 0 && (
            <OverridenFieldValue component="span">
              {getCurrencySymbol[currentPlan.currency]}
              {currentPlan.cost}
            </OverridenFieldValue>
          )}
          {getCurrencySymbol[currentPlan.currency]}
          {nextBillAmount} {currentPlan.currency}/
          {t(formatBillingPeriod(currentPlan))}
        </FieldValue>
        <FieldValue gutterBottom={true}>
          {t("settings.billing.discountLabel")}:{" "}
          {getCurrencySymbol[currentPlan.currency]}
          {totalDiscount.toFixed(2)} {currentPlan.currency}
        </FieldValue>
        <FieldValue gutterBottom={true}>
          {t("settings.billing.paymentDueLabel")}:{" "}
          {getDueDate(currentPlan.planId, status, nextBillingDate)}
        </FieldValue>
      </Grid>
      <Grid item xs={12}>
        <FormButton
          type="button"
          name="editBtn"
          color="primary"
          onClick={() => {
            history.push("/subscription/plans");
          }}
        >
          {t("settings.billing.changePlanButton")}
        </FormButton>
        {status !== "Canceled" && status !== "Expired" && (
          <ErrorFormButton
            type="button"
            name="cancelBtn"
            component={Link}
            href="mailto:billing@merchantspring.com.au"
          >
            {t("settings.billing.cancelPlanButton")}
          </ErrorFormButton>
        )}
      </Grid>
    </Grid>
  );
};

SubscriptionPlanPanel.propTypes = {
  subscription: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
};

export default SubscriptionPlanPanel;
