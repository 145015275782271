import { ParentProductMetric, ParentProductState } from "~/typedef/store";

import { Dispatch } from "redux";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "~/configs";
import { get } from "lodash";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";
import { shouldUseCache } from "./utils/shouldUseCache";

export const INITIAL_STATE = "INITIAL_STATE";

export const FETCH_PARENT_METRICS_SUCCESSFUL =
  "FETCH_PARENT_METRICS_SUCCESSFUL";
export const FETCH_PARENT_METRICS_IN_PROGRESS =
  "FETCH_PARENT_METRICS_IN_PROGRESS";

const initState: ParentProductState = {
  fetching: false,
};

type FetchParentMetricsInProgressAction = {
  type: typeof FETCH_PARENT_METRICS_IN_PROGRESS;
};

type FetchParentMetricsSuccessfulAction = {
  type: typeof FETCH_PARENT_METRICS_SUCCESSFUL;
  payload: {
    data: ParentProductMetric[];
    currency: string;
    count: number;
    params: FetchParentMetricsParamsType;
  };
};
type ParentMetricsAction =
  | FetchParentMetricsInProgressAction
  | FetchParentMetricsSuccessfulAction;

export const parentProducts = (
  state: ParentProductState = initState,
  action: ParentMetricsAction
): ParentProductState => {
  switch (action.type) {
    case FETCH_PARENT_METRICS_IN_PROGRESS:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_PARENT_METRICS_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    default:
      return state;
  }
};

interface FetchParentMetricsParamsType {
  user: User;
  mid: string;
  currentRange: any;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
  includeTax: boolean;
}

export const fetchParentMetrics =
  (
    params: FetchParentMetricsParamsType,
    oldParams?: FetchParentMetricsParamsType
  ) =>
  async (dispatch: Dispatch) => {
    const {
      user,
      mid,
      currentRange,
      searchText,
      sortKey = "createdAt",
      sortOrder = "desc",
      pageIndex,
      pageSize,
      includeTax,
    } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_PARENT_METRICS_IN_PROGRESS });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/parentMetrics/metrics`,
          {
            userId: user._id,
            mid,
            ...currentRange,
            searchText,
            sortKey,
            sortOrder,
            pageIndex,
            pageSize,
            includeTax,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_PARENT_METRICS_SUCCESSFUL,
            payload: {
              data: data.data,
              currency: data.currency,
              count: data.count,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status, "parentMetrics/metrics");
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status"),
          "parentMetrics/metrics"
        );
      }
    }
  };
