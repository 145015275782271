import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { FETCH_ADVERTISERS_SUCCESS } from "../mystore/marketing.redux";
import { FORBIDDEN } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../../configs";

export const CONNECT_NEW_STORE_REQUEST = "CONNECT_NEW_STORE_REQUEST";
export const CONNECT_NEW_STORE_SUCCESS = "CONNECT_NEW_STORE_SUCCESS";
export const CONNECT_NEW_STORE_ERROR = "CONNECT_NEW_STORE_ERROR";

export const SET_TEMPORARY_AMAZON_STORE_SELLER =
  "SET_TEMPORARY_AMAZON_STORE_SELLER";
export const REMOVE_TEMPORARY_NEW_AMAZON_STORE_IDS =
  "REMOVE_TEMPORARY_NEW_AMAZON_STORE_IDS";

export const STORE_NAME_UPDATE_REQUEST = "STORE_NAME_UPDATE_REQUEST";
export const STORE_NAME_UPDATE_SUCCESS = "STORE_NAME_UPDATE_SUCCESS";
export const STORE_NAME_UPDATE_ERROR = "STORE_NAME_UPDATE_ERROR";

export const ADD_NEW_STORES_REQUEST = "ADD_NEW_STORES_REQUEST";
export const ADD_NEW_STORES_SUCCESS = "ADD_NEW_STORES_SUCCESS";
export const ADD_NEW_STORES_ERROR = "ADD_NEW_STORES_ERROR";

export const ADVERTISING_PROFILE_UPDATE_REQUEST =
  "ADVERTISING_PROFILE_UPDATE_REQUEST";
export const ADVERTISING_PROFILE_UPDATE_SUCCESS =
  "ADVERTISING_PROFILE_UPDATE_SUCCESS";
export const ADVERTISING_PROFILE_UPDATE_ERROR =
  "ADVERTISING_PROFILE_UPDATE_ERROR";

axios.defaults.withCredentials = true;

const initState = {};

export const amazonNewStore = (state = initState, action) => {
  switch (action.type) {
    case CONNECT_NEW_STORE_REQUEST:
    case STORE_NAME_UPDATE_REQUEST:
    case ADD_NEW_STORES_REQUEST:
      return { ...state, loading: true, error: null };

    case CONNECT_NEW_STORE_ERROR:
    case STORE_NAME_UPDATE_ERROR:
    case ADVERTISING_PROFILE_UPDATE_ERROR:
    case ADD_NEW_STORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONNECT_NEW_STORE_SUCCESS:
      const payload = action.payload;
      return {
        ...state,
        loading: false,
        newMid: payload.mid,
        isNewStore: payload.isNewStore,
        shopName: payload.shopName,
        countryCode: payload.countryCode,
      };

    case STORE_NAME_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        shopName: action.payload,
      };

    case ADD_NEW_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        newAmazonStores: action.payload,
      };

    case SET_TEMPORARY_AMAZON_STORE_SELLER:
      return { ...state, sellerId: action.payload };

    case REMOVE_TEMPORARY_NEW_AMAZON_STORE_IDS:
      return {
        ...state,
        sellerId: null,
        newMid: null,
        isNewStore: null,
        shopName: null,
      };

    default:
      return state;
  }
};

export const connectAmazon =
  (state, sellerId, authCode) => async (dispatch) => {
    dispatch({ type: CONNECT_NEW_STORE_REQUEST });

    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/amazon/connect`,
        data: { state, sellerId, authCode },
      };
      const { data, status } = await axios(options);

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: CONNECT_NEW_STORE_SUCCESS,
          payload: {
            mid: data.mid,
            isNewStore: data.isNewStore,
            shopName: data.shopName,
            countryCode: data.countryCode,
          },
        });
      }

      return dispatch({
        type: CONNECT_NEW_STORE_ERROR,
        payload: "Amazon store connect failed",
      });
    } catch (err) {
      if (err.response && err.response.status === FORBIDDEN) {
        return dispatch({
          type: CONNECT_NEW_STORE_ERROR,
          payload: "Amazon store connect failed. Access denied.",
        });
      } else {
        return dispatch({
          type: CONNECT_NEW_STORE_ERROR,
        });
      }
    }
  };

export const connectAmazonVendor =
  (state, vendorGroupId, authCode) => async (dispatch) => {
    dispatch({ type: CONNECT_NEW_STORE_REQUEST });

    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/amazon/vendor/connect`,
        data: { state, vendorGroupId, authCode },
      };
      const { data, status } = await axios(options);

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: CONNECT_NEW_STORE_SUCCESS,
          payload: {
            mid: data.mid,
            isNewStore: data.isNewStore,
            shopName: data.shopName,
            countryCode: data.countryCode,
          },
        });
      }

      return dispatch({
        type: CONNECT_NEW_STORE_ERROR,
        payload: "Amazon store connect failed",
      });
    } catch (err) {
      if (err.response && err.response.status === FORBIDDEN) {
        return dispatch({
          type: CONNECT_NEW_STORE_ERROR,
          payload: "Amazon store connect failed. Access denied.",
        });
      } else {
        return dispatch({
          type: CONNECT_NEW_STORE_ERROR,
        });
      }
    }
  };

export const storeTempSellerId = (sellerId) => (dispatch) => {
  dispatch({
    type: SET_TEMPORARY_AMAZON_STORE_SELLER,
    payload: sellerId,
  });
};

export const removeTempIDs = () => (dispatch) => {
  dispatch({
    type: REMOVE_TEMPORARY_NEW_AMAZON_STORE_IDS,
  });
};

export const setAmazonStoreName =
  (countryCode, mid, shopName, marketplaceType, marketplaceSubtype) =>
  async (dispatch) => {
    dispatch({ type: STORE_NAME_UPDATE_REQUEST });
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/user/auth/updateStoreName`,
        data: {
          marketplaceType,
          countryCode,
          mid,
          shopName,
          marketplaceSubtype: marketplaceSubtype || marketplaceType,
        },
      };

      const { status } = await axios(options);

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: STORE_NAME_UPDATE_SUCCESS,
          payload: shopName,
        });
      }

      return dispatch({
        type: STORE_NAME_UPDATE_ERROR,
        payload: "Error encountered when saving store name.",
      });
    } catch (err) {
      return dispatch({
        type: STORE_NAME_UPDATE_ERROR,
        payload: { err },
      });
    }
  };

export const addStores =
  (sellerId, countryCode, marketplaces) => async (dispatch) => {
    dispatch({ type: ADD_NEW_STORES_REQUEST });

    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/amazon/addStores`,
        data: { sellerId, countryCode, marketplaces: marketplaces },
      };
      const res = await axios(options);

      if (res.data && res.data.code === RESPONSE_CODE.SUCCESS) {
        return dispatch({
          type: ADD_NEW_STORES_SUCCESS,
          payload: marketplaces,
        });
      }

      return dispatch({
        type: ADD_NEW_STORES_ERROR,
        payload: "Adding more Amazon stores failed",
      });
    } catch (err) {
      return dispatch({
        type: ADD_NEW_STORES_ERROR,
        payload: "Adding more Amazon stores failed",
      });
    }
  };

export const updateAmazonAdvertisingProfile =
  (
    mid,
    countryCode,
    profileId,
    entityId,
    marketplaceType,
    marketplaceSubtype,
    isStandalone
  ) =>
  async (dispatch) => {
    dispatch({ type: ADVERTISING_PROFILE_UPDATE_REQUEST });

    try {
      const options = {
        method: "POST",
        url:
          marketplaceType === "dsp"
            ? `${baseUrl}/auth/amazon/dsp/advertising/profile`
            : `${baseUrl}/auth/amazon/advertising/profile`,
        data: {
          mid,
          countryCode,
          profileId,
          entityId,
          marketplaceType,
          marketplaceSubtype,
          isStandalone,
        },
      };

      const { status, data } = await axios(options);

      if (isHttpResponseValid(status)) {
        if (marketplaceType === "dsp") {
          dispatch({
            type: FETCH_ADVERTISERS_SUCCESS,
            payload: data.advertisers,
          });
        }

        return dispatch({
          type: ADVERTISING_PROFILE_UPDATE_SUCCESS,
        });
      }

      return dispatch({
        type: ADVERTISING_PROFILE_UPDATE_ERROR,
        payload: "Error encountered when updating Advertising profile.",
      });
    } catch (err) {
      return dispatch({
        type: ADVERTISING_PROFILE_UPDATE_ERROR,
        payload: { err },
      });
    }
  };

export const updateAmazonAdvertisingAdvertiser =
  (
    mid,
    countryCode,
    advertiserId,
    profileId,
    entityId,
    marketplaceType,
    marketplaceSubtype,
    isStandalone
  ) =>
  async (dispatch) => {
    dispatch({ type: ADVERTISING_PROFILE_UPDATE_REQUEST });
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/amazon/dsp/advertising/advertiser`,
        data: {
          mid,
          countryCode,
          advertiserId,
          profileId,
          entityId,
          marketplaceType,
          marketplaceSubtype,
          isStandalone,
        },
      };

      const { status } = await axios(options);

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: ADVERTISING_PROFILE_UPDATE_SUCCESS,
        });
      }

      return dispatch({
        type: ADVERTISING_PROFILE_UPDATE_ERROR,
        payload: "Error encountered when updating Advertising profile.",
      });
    } catch (err) {
      return dispatch({
        type: ADVERTISING_PROFILE_UPDATE_ERROR,
        payload: { err },
      });
    }
  };
