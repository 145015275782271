import { CustomGroup, CustomGroupWithProducts } from "~/typedef/customGroups";
import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import SearchableMultiSelect, {
  Option,
} from "~/components/select/searchableMultiSelect";
import {
  useCreateOrEditGroupByBrandMutation,
  useSearchBrandsQuery,
} from "~/store/customGroups/reducer.redux";

import AlertCard from "~/components/alert/alertCard";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 100;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
`;

interface BrandOption extends Option {
  brand: string;
  value: string;
}

const mapBrandToOption = (brand: string): BrandOption => ({
  brand,
  value: brand,
});

interface CustomGroupsFormBrandProps {
  mid: string;
  marketplace: string;
  customGroups: CustomGroup[];
  currentGroup?: CustomGroupWithProducts;
  setOpenModal: (open: boolean) => void;
  onEdit?: () => void;
}

const CustomGroupsFormBrand = memo<CustomGroupsFormBrandProps>(
  function CustomGroupsFormBrand({
    mid,
    customGroups,
    currentGroup,
    setOpenModal,
    onEdit,
  }) {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const [groupName, setGroupName] = useState("");
    const [options, setOptions] = useState<BrandOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<BrandOption[]>([]);
    const [groupNameValidation, setGroupNameValidation] = useState("");

    const { brands, brandsFetching } = useSearchBrandsQuery(
      {
        mid,
        pageSize: PAGE_SIZE,
        searchText,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          brands: data?.brands || [],
          brandsFetching: isFetching,
        }),
      }
    );

    useEffect(() => {
      if (currentGroup) {
        setGroupName(currentGroup.groupName);
        setSelectedOptions(currentGroup.brands.map(mapBrandToOption));
      }
    }, [currentGroup]);

    const brandsList = useMemo(() => brands.map(mapBrandToOption), [brands]);

    useEffect(() => {
      if (brandsList.length) {
        const brandOptions = brandsList.map((brandsListItem) => {
          const isSelected = selectedOptions.find(
            (selectedOption) => selectedOption.brand === brandsListItem.brand
          );
          return {
            ...brandsListItem,
            selected: Boolean(isSelected),
          };
        });

        //Handling cases where a brand is not in the products table anymore
        // but is still in the custom group
        if (searchText === "") {
          selectedOptions.forEach((selectedOption) => {
            if (
              !brandOptions.find(
                (brand) => brand.brand === selectedOption.brand
              )
            ) {
              brandOptions.push({
                ...selectedOption,
                selected: true,
              });
            }
          });
        }

        setOptions(brandOptions);
      } else if (options.length) {
        setOptions([]);
      }
    }, [brandsList, selectedOptions]);

    const [createOrEditCustomGroup, { isLoading: customGroupSaving }] =
      useCreateOrEditGroupByBrandMutation();

    const dispatchCreateGroup = useCallback(async () => {
      try {
        await createOrEditCustomGroup({
          mid,
          groupName,
          brands: selectedOptions.map((option) => option.brand),
          groupId: currentGroup?.groupId,
          successMessage: currentGroup?.groupId
            ? t("customGroups.editSuccessMessage")
            : t("customGroups.createSuccessMessage"),
        }).unwrap();
        setOpenModal(false);
        setSearchText("");
        setGroupName("");
        setOptions([]);
        setSelectedOptions([]);
        if (currentGroup && onEdit) {
          onEdit();
        }
      } catch (error) {
        // Do nothing
        // The error is handled using the global error handler
        // in the mutation definition
      }
    }, [mid, currentGroup, groupName, selectedOptions, onEdit]);

    const changeSelectOption = (
      e: React.ChangeEvent<HTMLInputElement>,
      option: Option
    ) => {
      const checked = e.target.checked;
      if (checked) {
        setSelectedOptions([option as BrandOption, ...selectedOptions]);
      } else {
        setSelectedOptions(
          selectedOptions.filter(
            (selectedOption) => selectedOption.brand !== option.brand
          )
        );
      }
    };

    const selectAllOptions = (checked: boolean) => {
      if (checked) {
        setSelectedOptions(brandsList);
      } else {
        setSelectedOptions([]);
      }
    };

    const changeGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
      const groupName = e.target.value;
      if (e.target.value !== currentGroup?.groupName) {
        const matchingGroupName = customGroups?.find(
          (customGroup) =>
            customGroup.groupName.toLowerCase() === groupName.toLowerCase()
        );
        if (matchingGroupName) {
          setGroupNameValidation("customGroups.groupNameValidation");
        } else {
          setGroupNameValidation("");
        }
      }
      setGroupName(groupName);
    };

    return (
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AlertCard isOpen={true} type="error">
              {t("customGroups.groupByBrandSyncMessage")}
            </AlertCard>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                error={Boolean(groupNameValidation)}
                helperText={t(groupNameValidation)}
                label={t("customGroups.groupNameLabel")}
                required
                onChange={changeGroupName}
                value={groupName}
                disabled={customGroupSaving}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SearchableMultiSelect
                title={t("customGroups.searchBrands")}
                searchLimitText={t("customGroups.searchBrandsLimitMessage", {
                  pageSize: PAGE_SIZE,
                })}
                setSearchText={setSearchText}
                count={selectedOptions.length}
                options={options}
                searching={brandsFetching}
                optionComponent={({ value }) => (
                  <Typography variant="body2" noWrap>
                    {value}
                  </Typography>
                )}
                changeSelectOption={changeSelectOption}
                selectAllOptions={selectAllOptions}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RaisedButton
              type="submit"
              color="primary"
              onClick={dispatchCreateGroup}
              disabled={
                Boolean(groupNameValidation) ||
                customGroupSaving ||
                brandsFetching ||
                !selectedOptions.length
              }
            >
              {customGroupSaving ? (
                <LoadingIndicator size={20} />
              ) : (
                t("generic.save")
              )}
            </RaisedButton>
          </Grid>
        </Grid>
      </Form>
    );
  }
);

export default CustomGroupsFormBrand;
