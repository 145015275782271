import { Box, Grid, GridSize, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import Light from "~/components/typography/light";
import StatusText from "~/components/typography/status";

interface CategoryValueProps {
  level?: number;
  colSpan?: GridSize;
  value: string;
  isTotalRow?: Boolean;
  status?: string;
}

export const CategoryValue = memo<CategoryValueProps>(function CategoryValue({
  level = 0,
  colSpan = 1,
  value,
  isTotalRow,
  status,
}) {
  return (
    <Grid item xs={colSpan} justifyContent="flex-end">
      <Box p={1} pl={2} display="flex" justifyContent="flex-end">
        {isTotalRow ? (
          <Bold variant="body1" color="textSecondary">
            {value}
          </Bold>
        ) : status ? (
          <StatusText variant="body1" status={status}>
            {value}
          </StatusText>
        ) : level <= 1 ? (
          <Typography variant="body1" color="textSecondary">
            {value}
          </Typography>
        ) : (
          <Light variant="body2" color="textSecondary">
            {value}
          </Light>
        )}
      </Box>
    </Grid>
  );
});

export default CategoryValue;
