import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LargeNumber from "./largeNumber";
import { LightHeading } from "./styledTypography";
import StatusText from "../typography/status";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AvgOrderValueProps {
  current: number;
  prior?: number;
  currency?: string;
  title?: string;
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  comparisonSubtitle?: string;
  currentSubtitle?: string;
}

export const AvgOrderValue = memo<AvgOrderValueProps>(function AvgOrderValue({
  current,
  prior,
  currency = "AUD",
  title,
  currentCurrency,
  currentPeriod,
  comparisonSubtitle,
  currentSubtitle,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [avgOrderValue, setAvgOrderValue] = useState("-");
  const [lastPeriodAvgOrderValue, setLastPeriodAvgOrderValue] = useState("-");
  const [growth, setGrowth] = useState<string | number>("N/A");
  /** If totalOrders is a large number, our default font size can overflow the container.
    LargeNumber will recalculate the font size dynamically to prevent this from happening */
  const headingContainerRef = useRef<HTMLDivElement>(null);

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  useEffect(() => {
    const currentData = current || "-";

    const lastPeriodData = prior || "-";

    const totalValue = getConvertedValue(
      currencyRates,
      currency,
      currentCurrency,
      currentData
    );

    const lastPeriodValue = getConvertedValue(
      currencyRates,
      currency,
      currentCurrency,
      lastPeriodData
    );

    const convertedAvgOrderValue = formatCurrencyRounded(
      totalValue,
      currencyRates,
      currentCurrency,
      currentCurrency
    );

    const convertedLastPeriodAvgOrderValue = formatCurrencyRounded(
      lastPeriodValue,
      currencyRates,
      currentCurrency,
      currentCurrency
    );

    const growthValue = getPercentageDifference(totalValue, lastPeriodValue);

    setAvgOrderValue(convertedAvgOrderValue);
    setLastPeriodAvgOrderValue(convertedLastPeriodAvgOrderValue);
    setGrowth(growthValue);
  }, [currentCurrency, currencyRates, currentPeriod, current, prior, currency]);

  const status = isNaN(growth as number)
    ? "disabled"
    : (growth as number) < 0
    ? "error"
    : "success";

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={smDown ? "center" : "flex-start"}
      spacing={2}
      id="total-sales-wrapper"
    >
      <Grid item xs={6} md={12}>
        <Typography variant="h3">
          {title ?? t("dashboardWidget.salesPerformance.avgOrderValue")}
        </Typography>
        {currentSubtitle && (
          <Typography variant="body1" color="textSecondary">
            {currentSubtitle}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        wrap="wrap"
        alignItems="center"
        xs={6}
        md={12}
        ref={headingContainerRef}
      >
        <LargeNumber
          variant="h1"
          headingContainerRef={headingContainerRef}
          value={numberWithCommas(avgOrderValue)}
        />
        <LightHeading variant="h3" color="textSecondary">
          {currentCurrency}
        </LightHeading>
      </Grid>
      <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
        <Box>
          <Typography variant="h3" color="textSecondary">
            {t("dashboardWidget.salesPerformance.priorPeriod")}
          </Typography>
          {comparisonSubtitle && (
            <Typography variant="body1" color="textSecondary">
              {comparisonSubtitle}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
        <LargeNumber
          variant="h2"
          headingContainerRef={headingContainerRef}
          value={numberWithCommas(lastPeriodAvgOrderValue)}
        />
        <Tooltip title="Percentage change compared to the equivalent prior period">
          <StatusText variant="h5" status={status}>
            {status === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {status === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth as number) ? growth : Math.abs(growth as number)}
            {!isNaN(growth as number) && "%"}
          </StatusText>
        </Tooltip>
      </Grid>
    </Grid>
  );
});

export default AvgOrderValue;
