import { Box, CircularProgress } from "@material-ui/core";

import React from "react";

const PanelLoading = (props: any) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100%"
    boxSizing="border-box"
    pt={4}
    pb={4}
  >
    <CircularProgress {...props} />
  </Box>
);

export default PanelLoading;
