import React, { memo, useMemo } from "react";

import { CategoryCell } from "~/components/table/cells/categoryCell";
import { PercentageBarCell } from "~/components/table/cells/percentageBarCell";
import PropTypes from "prop-types";
import Table from "~/components/table/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

const ItemTable = memo(({ market, data }) => {
  const DISPLAY_ITEMS = 3;
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: "categoryName",
        Header: t("myStoresWidget.itemSpecifics.categoryColumn"),
        accessor: "category",
        Cell: (props) => <CategoryCell {...props} />,
        customWidth: 200,
        colSpan: 2,
      },
      {
        id: "itemCount",
        Header: t("myStoresWidget.itemSpecifics.listingsColumn"),
        accessor: "itemCount",
        Cell: ValueCell,
        hiddenDown: "md",
        align: "right",
      },
      {
        id: "completeness",
        Header: t("myStoresWidget.itemSpecifics.completenessColumn"),
        accessor: (row) => {
          const total = row.correct + row.invalid + row.missing || 1;

          return {
            correctPercentage: row.correct / total,
            invalidPercentage: row.invalid / total,
            missingPercentage: row.missing / total,
          };
        },
        Cell: PercentageBarCell,
        customWidth: 200,
        colSpan: 2,
      },
    ],
    []
  );

  return (
    <Container>
      <Table
        columns={columns}
        data={data.slice(0, DISPLAY_ITEMS)}
        gridLayoutColumns={8}
      />
    </Container>
  );
});

ItemTable.propTypes = {
  market: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default ItemTable;
