import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import { OverviewToolbar } from "~/components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "~/components/containers/pageBlock";
import ProfitAndLoss from "~/modules/widgets/profitability/profitAndLoss";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ProfitAndLossStatementProps {
  groupByMonth?: boolean;
  showComparison?: boolean;
  condensed?: boolean;
  report?: boolean;
}

const ProfitAndLossStatement = memo<ProfitAndLossStatementProps>(
  function ProfitAndLossStatement({
    groupByMonth = false,
    showComparison,
    condensed,
    report,
  }) {
    const { t } = useTranslation();
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentFilter = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );

    return condensed ? (
      <ProfitAndLoss
        title={t("dashboardWidget.profitAndLoss.mainTitle")}
        currentCurrency={currentCurrency}
        currentRange={currentRange}
        currentFilter={currentFilter}
        includeTax={includeTax}
        groupByMonth={groupByMonth}
        showComparison={showComparison}
        condensed={condensed}
        report={report}
        footerLink={
          condensed
            ? {
                url: "/overview/profitAndLoss",
                label: t("generic.viewAllLink"),
              }
            : undefined
        }
      />
    ) : (
      <PageBlock>
        <OverviewToolbar
          selectedItem={{
            currentPage: t("dashboardWidget.profitAndLoss.mainTitle"),
            breadcrumb: [],
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ProfitAndLoss
              title={t("dashboardWidget.profitAndLoss.mainTitle")}
              currentCurrency={currentCurrency}
              currentRange={currentRange}
              currentFilter={currentFilter}
              includeTax={includeTax}
              groupByMonth={groupByMonth}
              showComparison={showComparison}
              condensed={condensed}
              report={report}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfitAndLoss
              title={t("dashboardWidget.profitAndLoss.mainTitle")}
              currentCurrency={currentCurrency}
              currentRange={currentRange}
              currentFilter={currentFilter}
              includeTax={includeTax}
              groupByMonth={groupByMonth}
              showComparison={showComparison}
              condensed={condensed}
              report={report}
              isComparison={true}
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
);

export default ProfitAndLossStatement;
