import { Tooltip, Typography } from "@material-ui/core";

import Bold from "@components/typography/bold";
import Cell from "./cell";
import { LightInlineIconButton } from "~/icons/inlineIconButton";
import React from "react";
import SubcategoryCell from "./subcategoryCell";
import i18next from "i18next";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface ProfitAndLossCategoryLabelCellProps {
  cellKey: string;
  breakdown?: string[];
  bold?: boolean;
  shouldDisplayTooltip: boolean;
  empty?: boolean;
  expandRows?: boolean;
}

const StyledBold = styled(Bold)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;
const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;
const ProfitAndLossCategoryLabelCell = ({
  empty,
  shouldDisplayTooltip,
  cellKey,
  breakdown,
  expandRows,
  bold,
}: ProfitAndLossCategoryLabelCellProps) => {
  const { t } = useTranslation();
  const TextVariant = bold ? StyledBold : StyledTypography;
  return (
    <>
      <Cell key={cellKey} $startAlign={true}>
        {!empty && (
          <>
            {shouldDisplayTooltip && (
              <Tooltip title={t(`profitability.${cellKey}LabelTooltip`)}>
                <LightInlineIconButton />
              </Tooltip>
            )}
            <TextVariant variant="body1">
              {t(`profitability.${cellKey}Label`)}
            </TextVariant>
          </>
        )}
      </Cell>
      {breakdown?.length
        ? breakdown?.map((b, index) => {
            const translationKey = `profitability.eventLabel.${b}`;
            const hasTranslation = i18next.exists(translationKey);
            return (
              <SubcategoryCell
                key={`${cellKey}-${index}`}
                $visible={expandRows}
                $heavyBorder={index >= breakdown.length - 1}
              >
                {!empty && (
                  <Tooltip title={hasTranslation ? t(translationKey) : b}>
                    <TextVariant variant="body1">
                      {hasTranslation ? t(translationKey) : b}
                    </TextVariant>
                  </Tooltip>
                )}
              </SubcategoryCell>
            );
          })
        : null}
    </>
  );
};
export default ProfitAndLossCategoryLabelCell;
