import { Grid, Typography } from "@material-ui/core";
import React, { memo, useMemo } from "react";

import StatusText from "~/components/typography/status";

interface ThresholdCellProps {
  cell: {
    value: {
      displayValue: string;
      currentValue: number; // Keep display &
      thresholdValue?: number;
      conditionalFormatting?: boolean;
      reverseFormatting?: boolean;
      lowThreshold?: number; // Typically 0-100
      midThreshold?: number; // Typically 0-100
      highThreshold?: number; // Typically 0-100
    };
  };
}

export const ThresholdCell = memo<ThresholdCellProps>(({ cell }) => {
  const {
    displayValue,
    currentValue,
    thresholdValue,
    conditionalFormatting,
    reverseFormatting,
    lowThreshold = 25,
    midThreshold = 50,
    highThreshold = 75,
  } = cell.value;

  const percentageCompleted =
    thresholdValue && thresholdValue > 0
      ? Math.round((currentValue / thresholdValue) * 100)
      : null;

  const status = useMemo(() => {
    if (conditionalFormatting === false) return "";

    if (percentageCompleted === null) return "";

    if (!reverseFormatting) {
      if (percentageCompleted < lowThreshold) return "error";
      if (percentageCompleted < midThreshold) return "warning";
      if (percentageCompleted < highThreshold) return "success";
      if (percentageCompleted >= highThreshold) return "success";
    } else {
      if (percentageCompleted < lowThreshold) return "success";
      if (percentageCompleted < midThreshold) return "warning";
      if (percentageCompleted < highThreshold) return "error";
      if (percentageCompleted >= highThreshold) return "error";
    }
  }, [
    percentageCompleted,
    conditionalFormatting,
    reverseFormatting,
    lowThreshold,
    midThreshold,
    highThreshold,
  ]);

  return (
    <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
      <Grid item>
        <Typography variant="body2">{displayValue}</Typography>
      </Grid>
      {percentageCompleted !== null && (
        <Grid item>
          <StatusText variant="body2" status={status}>
            {percentageCompleted}%
          </StatusText>
        </Grid>
      )}
    </Grid>
  );
});
