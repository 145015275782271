import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "../../store/utils/dateTimeUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import ProfitabilitySummaryWidget from "../../modules/widgets/profitability/profitabilitySummary";
import React from "react";
import get from "lodash/get";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const ProfitabilitySummary = memo(function ProfitabilitySummary() {
  const PAGE_SIZE = 5;

  const currentCurrency = useTypedSelector((state) =>
    get(
      state,
      "persistentAppSettings.setting.data.currentCurrency",
      DEFAULT_CURRENCY
    )
  );

  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );

  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );

  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const userInfo = useTypedSelector((state) => state.user);
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  return (
    <ProfitabilitySummaryWidget
      {...{
        currentCurrency: currentCurrency,
        currentRange: currentRange,
        pageSize: PAGE_SIZE,
        includeTax: includeTax,
        userInfo: userInfo,
        currentFilter: currentFilter,
        filteredStores: filteredStores,
      }}
    />
  );
});

export default ProfitabilitySummary;
