import { Box, Grid } from "@material-ui/core";
import {
  NotificationStatus,
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { ReactChild, useMemo, useState } from "react";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { NotificationsList } from "./notificationsList";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { User } from "~/typedef/user";
import { memo } from "react";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 25;

interface TableFilterOption {
  value: string | null;
  label: string;
}

export interface NotificationsListProps {
  user: User;
  pageSize: number;
  status: string | null;
  notificationType: string | null;
  messageId: string | null;
  searchText?: string;
  actions?: ReactChild;
  autoResolve?: boolean;
}

const NotificationsOverview = memo(() => {
  const { t } = useTranslation();

  const TYPES: TableFilterOption[] = useMemo(
    () => [
      {
        value: null,
        label: t(`notificationType.ALL`),
      },
      ...Object.values(StoreNotificationType).map((type) => ({
        value: type,
        label: t(`notificationType.${type}`),
      })),
      ...Object.values(ProductNotificationType).map((type) => ({
        value: type,
        label: t(`notificationType.${type}`),
      })),
    ],
    []
  );

  const STATUSES: TableFilterOption[] = useMemo(
    () => [
      {
        value: null,
        label: t(`notificationStatus.all`),
      },
      ...Object.values(NotificationStatus).map((status) => ({
        value: status,
        label: t(`notificationStatus.${status}`),
      })),
    ],
    []
  );

  const user = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );

  const [searchText, setSearchText] = useState("");
  const [typeFilter, setTypeFilter] = useState<TableFilterOption>(TYPES[0]);
  const [statusFilter, setStatusFilter] = useState<TableFilterOption>(
    STATUSES[0]
  );
  const urlSearchParams = useQueryParams();
  const messageId = urlSearchParams.get("messageId");
  const autoResolve = urlSearchParams.get("resolve") === "true";

  const switchTypeFilter = (value: string) => {
    const option = TYPES.find((type) => type.value === value);
    if (option) {
      setTypeFilter(option);
    }
  };

  const switchStatusFilter = (value: string) => {
    const option = STATUSES.find((status) => status.value === value);
    if (option) {
      setStatusFilter(option);
    }
  };

  const actions = (
    <>
      <SearchFilter setSearchText={setSearchText} />
      <Box pl={2}>
        <TableFilter
          {...{
            width: "250px",
            options: TYPES,
            currentValue: typeFilter.label,
            handleChange: switchTypeFilter,
          }}
        />
      </Box>
      <Box pl={2}>
        <TableFilter
          {...{
            width: "165px",
            options: STATUSES,
            currentValue: statusFilter.label,
            handleChange: switchStatusFilter,
          }}
        />
      </Box>
      <Box pl={1}>
        <DownloadCsv
          {...{
            mid: "ALL",
            reportType: "notifications",
            path: "/api/notifications",
            params: {
              searchText,
              status: statusFilter.value,
              notificationType: typeFilter.value,
              messageIdFilter: messageId,
              mids: filteredStores.map((store) => store.merchantId),
            },
          }}
        />
      </Box>
    </>
  );
  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NotificationsList
            user={user}
            pageSize={PAGE_SIZE}
            actions={actions}
            searchText={searchText}
            notificationType={typeFilter.value}
            status={statusFilter.value}
            messageId={messageId}
            autoResolve={autoResolve}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default NotificationsOverview;
