import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";

import { ArrowBack } from "@material-ui/icons";
import Medium from "~/components/typography/medium";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import RaisedButton from "~/components/buttons/raisedButton";
import axios from "axios";
import { baseUrl } from "../../configs";
import { getCountryCodeISO3ToName } from "~/utils/countryUtils";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin-left: -1.5rem;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const BackButton = styled(IconButton)`
  margin: -15px 0 0 -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

// list of supported countries (from https://zalando.com/) in display order
const countries = [
  "AUT",
  "BEL",
  "HRV",
  "CZE",
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "HUN",
  "IRL",
  "ITA",
  "LVA",
  "LTU",
  "NLD",
  "NOR",
  "POL",
  "ROU",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
  "CHE",
  "GBR",
].map((countryCode) => ({
  value: countryCode,
  label: getCountryCodeISO3ToName(countryCode),
}));

const ZalandoConnectPage = (props: { onSuccess: () => void }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isAdded, setIsAdded] = useState(false);

  const usersChoice = useMemo(() => {
    return [
      { value: "all", label: t("connectWizard.zalandoAllChoiceLabel") },
      {
        value: "standard",
        label: t("connectWizard.zalandoStandardChoiceLabel"),
      },
    ];
  }, []);

  const [countryCode, setCountryCode] = useState("");
  const [businessPartnerId, setBusinessPartnerId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [shopName, setShopName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [choice, setChoice] = useState("");

  const handleConnectBtn = () => {
    axios
      .post(`${baseUrl}/auth/zalando/connect`, {
        countryCode,
        businessPartnerId,
        clientId,
        clientSecret,
        shopName,
      })
      .then(() => {
        props.onSuccess();
        setIsAdded(true);
      })
      .catch((err) => {
        console.log(err);
        setErrMsg(t("connectWizard.connectionError"));
      });
  };

  const handleChoiceChange = (value: string) => {
    setChoice(value);
    setCountryCode(""); // Reset countryCode when choice changes
  };

  return (
    <GridWrapper id="zalando-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={2}>
          <BackButton onClick={history.goBack} id="back-button">
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item xs={10}>
          <TitleWrapper>
            <Medium>{t("connectWizard.zalandoLinkTitle")}</Medium>
          </TitleWrapper>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/zalando.jpg`} />
        </Grid>

        {!isAdded && (
          <>
            <Grid container item xs={12} justifyContent="flex-start">
              <Medium>{t("connectWizard.zalandoChoiceLabel")}</Medium>
              <Box pt={1} pb={1} width={1}>
                <NativeSelect
                  name={"choice"}
                  value={choice}
                  options={usersChoice}
                  onChange={(e) => handleChoiceChange(e.target.value as string)}
                  autoFocus={true}
                  fullWidth
                />
              </Box>
              {(choice === "all" || choice === "standard") && (
                <>
                  {choice === "standard" && (
                    <>
                      <Box pt={3}>
                        <Medium>
                          {t("connectWizard.zalandoCountryLabel")}
                        </Medium>
                      </Box>
                      <Box pt={1} pb={1} width={1}>
                        <NativeSelect
                          name={"countryCode"}
                          value={countryCode}
                          options={countries}
                          onChange={(e) =>
                            setCountryCode(e.target.value as string)
                          }
                          autoFocus={true}
                          fullWidth
                        />
                      </Box>
                    </>
                  )}
                  <TextField
                    fullWidth
                    label={t("connectWizard.zalandoBusinessPartnerIdLabel")}
                    margin="normal"
                    required
                    onChange={(e) => setBusinessPartnerId(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label={t("connectWizard.zalandoClientIdLabel")}
                    margin="normal"
                    required
                    onChange={(e) => setClientId(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label={t("connectWizard.zalandoClientSecretLabel")}
                    type="password"
                    margin="normal"
                    required
                    onChange={(e) => setClientSecret(e.target.value)}
                  />
                  <Box pt={3}>
                    <Medium>{t("connectWizard.shopNameTitle")}</Medium>
                  </Box>
                  <AccountNameWrapper
                    fullWidth
                    label={t("connectWizard.zalandoShopNameLabel")}
                    margin="normal"
                    required
                    onChange={(e) => setShopName(e.target.value)}
                  />
                </>
              )}
            </Grid>

            {errMsg && (
              <Grid container item xs={12}>
                <ErrorMessage color="error">{errMsg}</ErrorMessage>
              </Grid>
            )}
            <Grid item>
              <ButtonWrapper>
                <RaisedButton
                  disabled={
                    !businessPartnerId ||
                    !clientId ||
                    !clientSecret ||
                    !shopName
                  }
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleConnectBtn}
                >
                  {t("connectWizard.connectButton")}
                </RaisedButton>
              </ButtonWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default ZalandoConnectPage;
