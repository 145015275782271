import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LargeNumber from "./largeNumber";
import { LightHeading } from "./styledTypography";
import StatusText from "../typography/status";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TotalSalesProps {
  current: number;
  prior?: number;
  currency?: string;
  isVendorStore?: boolean;
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  conditionalFormatting?: boolean;
  customTitle?: string;
  comparisonSubtitle?: string;
  currentSubtitle?: string;
}

export const TotalSales = memo<TotalSalesProps>(function TotalSales({
  current,
  prior,
  currency = "AUD",
  isVendorStore,
  currentCurrency,
  currentPeriod,
  conditionalFormatting,
  customTitle,
  comparisonSubtitle,
  currentSubtitle,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const currencyRates = useTypedSelector((state) =>
    state.globalVar ? state.globalVar.currencyRates : []
  );

  const [totalSales, setTotalSales] = useState("-");
  const [lastPeriodSales, setLastPeriodSales] = useState("-");
  const [growth, setGrowth] = useState<string | number>("N/A");
  /** If totalSales is a large number, our default font size can overflow the container.
    LargeNumber will recalculate the font size dynamically to prevent this from happening */
  const headingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sales = current || "-";

    const lastPeriodSales = prior || "-";

    const totalSalesValue = getConvertedValue(
      currencyRates,
      currency,
      currentCurrency,
      sales
    );

    const lastPeriodSalesValue = getConvertedValue(
      currencyRates,
      currency,
      currentCurrency,
      lastPeriodSales
    );

    const convertedTotalSales = formatCurrencyRounded(
      totalSalesValue,
      currencyRates,
      currentCurrency,
      currentCurrency
    );

    const convertedLastPeriodSales = formatCurrencyRounded(
      lastPeriodSalesValue,
      currencyRates,
      currentCurrency,
      currentCurrency
    );

    const growthValue = getPercentageDifference(
      totalSalesValue,
      lastPeriodSalesValue
    );

    setTotalSales(convertedTotalSales);
    setLastPeriodSales(convertedLastPeriodSales);
    setGrowth(growthValue);
  }, [currentCurrency, currencyRates, currentPeriod, current, prior, currency]);

  const status = isNaN(growth as number)
    ? "disabled"
    : (growth as number) < 0
    ? "error"
    : "success";

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={smDown ? "center" : "flex-start"}
      spacing={2}
      id="total-sales-wrapper"
    >
      <Grid item xs={6} md={12}>
        <Typography variant="h3">
          {t(
            customTitle?.length
              ? customTitle
              : isVendorStore
              ? "dashboardWidget.totalOrderedRevenue"
              : "dashboardWidget.salesPerformance.totalSales"
          )}
        </Typography>
        {currentSubtitle && (
          <Typography variant="body1" color="textSecondary">
            {currentSubtitle}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        wrap="wrap"
        alignItems="center"
        xs={6}
        md={12}
        ref={headingContainerRef}
      >
        <LargeNumber
          variant="h1"
          headingContainerRef={headingContainerRef}
          value={numberWithCommas(totalSales)}
        />
        <LightHeading variant="h3" color="textSecondary">
          {currentCurrency}
        </LightHeading>
      </Grid>
      <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
        <Box>
          <Typography variant="h3" color="textSecondary">
            {t("dashboardWidget.salesPerformance.priorPeriod")}
          </Typography>
          {comparisonSubtitle && (
            <Typography variant="body1" color="textSecondary">
              {comparisonSubtitle}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
        <LargeNumber
          variant="h2"
          headingContainerRef={headingContainerRef}
          value={numberWithCommas(lastPeriodSales)}
        />
        <Tooltip title="Percentage change compared to the equivalent prior period">
          <StatusText
            variant="h5"
            status={conditionalFormatting === false ? undefined : status}
          >
            {status === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {status === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth as number) ? growth : Math.abs(growth as number)}
            {!isNaN(growth as number) && "%"}
          </StatusText>
        </Tooltip>
      </Grid>
    </Grid>
  );
});

export default TotalSales;
