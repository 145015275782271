import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import {
  InlineBlockDiv,
  SmallIcon,
  StyledTableCell,
} from "../widgets/salesByProduct";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";

import { Box } from "@material-ui/core";
import { CategoryCell } from "~/components/table/cells/categoryCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import OrderedRevenueTableChart from "./orderedRevenueTableChart";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import PrimeIcon from "~/img/amazon_prime.png";
import { Range } from "~/typedef/store";
import { ShowChart } from "@material-ui/icons";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useOrderedRevenueQuery } from "~/store/mystore/vendor.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OrderedRevenueTableProps {
  user: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  searchText?: string;
  actions?: ReactChild;
  pageSize: number;
  condensed?: boolean;
  report?: boolean;
  conditionalFormatting?: boolean;
  timezone: string;
}

const OrderedRevenueTable = memo<OrderedRevenueTableProps>(
  function OrderedRevenueTable({
    user,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    searchText,
    actions,
    pageSize,
    condensed,
    report,
    conditionalFormatting,
    timezone,
  }) {
    const { t } = useTranslation();

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "ordered_revenue",
      sortOrder: "desc",
    });
    const {
      orderedRevenue,
      orderedRevenueCurrency,
      orderedRevenueCount,
      lastReportDate,
      lastUpdatedAt,
      orderedRevenueFetching,
    } = useOrderedRevenueQuery(
      {
        mid,
        currentRange,
        searchText,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          orderedRevenue: data?.data || [],
          orderedRevenueCurrency: data?.currency || "",
          orderedRevenueCount: data?.count || 0,
          lastReportDate: data?.lastReportDate,
          lastUpdatedAt: data?.lastUpdatedAt,
          orderedRevenueFetching: isFetching,
        }),
      }
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "ordered_revenue",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const contentRef = React.useRef<HTMLHeadingElement>(null);
    const renderOrderedRevenueSubComponent = ({ row }: any) => {
      const tableRenderedWidth = Number(contentRef.current?.clientWidth) - 20;
      return (
        <tr>
          <StyledTableCell
            style={{
              maxWidth: "0px",
              overflowX: "visible",
              position: "sticky",
              zIndex: 3,
              left: "0px",
            }}
          >
            <div
              style={{
                width: tableRenderedWidth,
              }}
            >
              <Box>
                <OrderedRevenueTableChart
                  userInfo={user}
                  mid={mid}
                  productId={row.original?.productId}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  timezone={timezone}
                />
              </Box>
            </div>
          </StyledTableCell>
        </tr>
      );
    };

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: `ASIN: ${row.asin} | VPI: ${row.vpi}`,
            image: row.imageUrl,
            link: row.url,
            target: "_blank",
            icon: row.isFulfilledByAmazon ? (
              <SmallIcon src={PrimeIcon} />
            ) : null,
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          isVisible: true,
          sticky: "left",
          colSpan: 2,
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("generic.chartActionColumn"),
                accessor: () => {},
                id: "actions",
                Cell: (cell: ReactTableCell) => {
                  const actions = [
                    {
                      icon: ShowChart,
                      text: cell.row.isExpanded
                        ? t("generic.hide")
                        : t("generic.show"),
                      action: () => {
                        const isExpanded = cell.row.isExpanded;
                        cell.toggleAllRowsExpanded(false);
                        const { onClick } =
                          cell.row.getToggleRowExpandedProps();
                        if (!isExpanded) onClick();
                      },
                    },
                  ];
                  return <ActionCell actions={actions} />;
                },
                isVisible: true,
                disableSortBy: true,
                sticky: "left",
              },
              {
                id: "brand",
                Header: t("myStoresWidget.salesByBrand.brandColumn"),
                accessor: "brand",
                Cell: TextCell,
                colSpan: 3,
              },
              {
                id: "category",
                Header: t("myStoresWidget.salesByCategory.categoryColumn"),
                accessor: (row: {
                  categoryLabel: string;
                  parentCategory: string;
                }) => ({
                  current: row.categoryLabel,
                  parent: row.parentCategory,
                }),
                Cell: (props: any) => <CategoryCell {...props} />,
                colSpan: 3,
                disableSortBy: true,
              },
              {
                Header: t("vendor.glanceViews"),
                accessor: (row: {
                  glanceViews: number;
                  priorGlanceViews: number;
                }) => {
                  return {
                    value:
                      row.glanceViews !== undefined
                        ? numberWithCommas(row.glanceViews.toFixed(0))
                        : "-",
                    growth: row.priorGlanceViews
                      ? getPercentageDifference(
                          row.glanceViews,
                          row.priorGlanceViews
                        )
                      : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  };
                },
                id: "glance_views",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]),
        {
          Header: t("vendor.orderedRevenue"),
          id: "ordered_revenue",
          accessor: (row: {
            orderedRevenue: number;
            priorOrderedRevenue: number;
          }) => {
            return {
              value:
                row.orderedRevenue !== undefined
                  ? formatCurrencyRounded(
                      row.orderedRevenue,
                      currencyRates,
                      orderedRevenueCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorOrderedRevenue
                ? getPercentageDifference(
                    row.orderedRevenue,
                    row.priorOrderedRevenue
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("vendor.orderedUnits"),
          accessor: (row: {
            orderedUnits: number;
            priorOrderedUnits: number;
          }) => {
            return {
              value:
                row.orderedUnits !== undefined
                  ? numberWithCommas(row.orderedUnits.toFixed(0))
                  : "-",
              growth: row.priorOrderedUnits
                ? getPercentageDifference(
                    row.orderedUnits,
                    row.priorOrderedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "ordered_units",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("brandAnalytics.avgPrice"),
                accessor: (row: { price: number; priorPrice: number }) => {
                  return {
                    value: row.price
                      ? formatCurrency(
                          row.price,
                          currencyRates,
                          orderedRevenueCurrency,
                          currentCurrency
                        )
                      : "-",
                    growth:
                      row.priorPrice !== undefined
                        ? getPercentageDifference(row.price, row.priorPrice)
                        : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  };
                },
                id: "price",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]),
        {
          Header: t("vendor.unitsPerView"),
          accessor: (row: {
            unitsPerView: number;
            priorUnitsPerView: number;
          }) => {
            return {
              value:
                row.unitsPerView !== undefined
                  ? row.unitsPerView.toFixed(1)
                  : "-",
              growth: row.priorUnitsPerView
                ? (row.unitsPerView - row.priorUnitsPerView).toFixed(1)
                : "N/A",
            };
          },
          id: "units_per_view",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
        },
      ],
      [
        mid,
        currentRange,
        currentPeriod,
        currentCurrency,
        currencyRates,
        orderedRevenueCurrency,
      ]
    );

    const [columnConfig, setColumnConfig] = useState<Column[]>(
      columns.map((c) => ({
        Header: c.Header,
        id: c.id,
        isVisible: c.isVisible,
      }))
    );

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "orderedrevenue"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="ordered-revenue-table"
        title={t("orderedRevenue.mainTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.manufacturingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.manufacturingView")}`
        }
        footerLink={footerLink}
        content={
          <>
            {/* Empty div to get the rendered width of the table by ref */}
            <InlineBlockDiv
              ref={contentRef}
              style={{ display: "inline-block" }}
            />
            {condensed ? (
              <NoScrollTable
                {...{
                  columns: columns.map((c) => ({
                    ...c,
                    isVisible: columnConfig.find((cc) => cc.id === c.id)
                      ?.isVisible,
                  })),
                  data: orderedRevenue,
                  pageSize,
                  loading: orderedRevenueFetching,
                  isReport: report,
                }}
              />
            ) : (
              <Table
                {...{
                  columns: columns.map((c) => ({
                    ...c,
                    isVisible: columnConfig.find((cc) => cc.id === c.id)
                      ?.isVisible,
                  })),
                  data: orderedRevenue,
                  fetchData,
                  loading: orderedRevenueFetching,
                  sorting: true,
                  pagination: true,
                  pageCount: Math.ceil((orderedRevenueCount || 0) / pageSize),
                  pageSize: pageSize,
                  renderRowSubComponent: renderOrderedRevenueSubComponent,
                }}
              />
            )}
          </>
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: columnConfig, setColumns: setColumnConfig }}
              />
            )}
          </>
        }
      />
    );
  }
);

export default OrderedRevenueTable;
