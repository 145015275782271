import React, { memo } from "react";

import CampaignNegativeKeywordSummaryPanel from "~/modules/marketing/campaignNegativeKeywords";
import { Grid } from "@material-ui/core";
import MarketingBreadcrumbs from "~/components/toolbars/marketingBreadcrumbs";
import MarketingSubNav from "~/components/toolbars/marketingSubNav";
import MarketingSubNavItem from "~/components/toolbars/marketingSubNavItem";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MarketingCampaignNegatives = memo(() => {
  const { t } = useTranslation();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const location = useLocation();

  const navigationState = get(location, "state", {});
  const campaignId = get(navigationState, "campaignId");
  const campaignName = get(navigationState, "campaignName");

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <MarketingBreadcrumbs
          marketplace={store.marketplace}
          mid={store.merchantId}
          parentPage={"marketingCampaigns"}
          parentTitle={t("advertisingDashboardWidget.subnav.allCampaigns")}
          title={campaignName}
          state={navigationState}
        />
        <MarketingSubNav>
          <MarketingSubNavItem
            marketplace={store.marketplace}
            mid={store.merchantId}
            page={"marketingCampaignAdGroups"}
            title={t("advertisingDashboardWidget.subnav.adGroups")}
            state={navigationState}
          />
          <MarketingSubNavItem
            title={t("advertisingDashboardWidget.subnav.negatives")}
            selected={true}
          />
        </MarketingSubNav>
        <Grid item xs={12}>
          <CampaignNegativeKeywordSummaryPanel
            mid={store.merchantId}
            countryCode={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            campaignId={campaignId}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default MarketingCampaignNegatives;
