import React, { useCallback, useMemo } from "react";

import { CurrencyRate } from "~/typedef/store";
import { IconButton } from "@material-ui/core";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { StyledEditIcon } from "~/modules/buybox/editablePriceCell";
import UploadIcon from "@material-ui/icons/CloudUploadSharp";
import { VendorChannelProfitAndLossResponse } from "~/store/mystore/vendorProfitability.redux";
import { formatCurrency } from "~/utils/currencyUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface MapToTableDataProps {
  data: Record<string, number>;
  total: number;
  units: number;
  currency: string;
  subCategory?: boolean;
  bold?: boolean;
  valueRow?: "percent" | "int";
  tooltip?: boolean | string;
  translate?: boolean;
  actions?: React.ReactNode;
}

interface UseFormattedTableDataProps {
  income?: VendorChannelProfitAndLossResponse["income"];
  expense?: VendorChannelProfitAndLossResponse["expense"];
  metrics?: VendorChannelProfitAndLossResponse["metrics"];
  snsFromDate?: VendorChannelProfitAndLossResponse["snsFromDate"];
  snsToDate?: VendorChannelProfitAndLossResponse["snsToDate"];
  showBreakdown: boolean;
  currencyRates: CurrencyRate[];
  sellType: SellType;
  currentCurrency: string;
  currency: string;
  setChargebackUploadDialogOpen?: (open: boolean) => void;
  setDeductionUpdateDialogOpen?: (open: boolean) => void;
  canUploadData: boolean;
}

const StyledIconButton = styled(IconButton)`
  margin-left: 0.5rem;
`;

const StyledUploadIcon = styled(UploadIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const UploadButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledIconButton size="small" onClick={onClick}>
      <StyledUploadIcon fontSize="small" />
    </StyledIconButton>
  );
};

const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledIconButton size="small" onClick={onClick}>
      <StyledEditIcon fontSize="small" />
    </StyledIconButton>
  );
};

export const useFormattedTableData = ({
  income,
  expense,
  metrics,
  snsFromDate,
  snsToDate,
  showBreakdown,
  currencyRates,
  sellType,
  currentCurrency,
  currency,
  setChargebackUploadDialogOpen,
  setDeductionUpdateDialogOpen,
  canUploadData,
}: UseFormattedTableDataProps) => {
  const { t } = useTranslation();

  const mapToTableData = useCallback(
    ({
      data,
      total,
      units,
      currency,
      // subCategory - used to identify subcategory rows
      // subCategory rows has label right aligned
      subCategory,
      // bold - used to toggle bold font weight
      bold,
      // valueRow - used to identify rows with only the value column
      // without the percent income or value per unit columns
      // valueRow can be "percent" or "int"
      valueRow,
      // tooltip - used to add tooltip to the row's label
      // could be a string or boolean
      tooltip,
      // translate - used to toggle translation the row's label
      translate = true,
      // actions - used to add actions to the label column
      actions,
    }: MapToTableDataProps) => {
      const commonMappings = {
        startAlign: !subCategory,
        bold,
        actions,
      };
      return Object.entries(data).map(([key, value]) => {
        if (valueRow) {
          return {
            key: translate ? t(`vendorProfitAndLoss.${key}Label`) : key,
            value:
              valueRow === "int"
                ? numberWithCommas(value)
                : total
                ? `${((value / total) * 100).toFixed(2)}%`
                : "-",
            incomePercent: "",
            valuePerUnit: "",
            tooltip:
              typeof tooltip === "string"
                ? tooltip
                : tooltip
                ? t(`vendorProfitAndLoss.${key}Tooltip`)
                : undefined,
            ...commonMappings,
          };
        }

        const absValue = Math.abs(value);
        return value
          ? {
              key: translate ? t(`vendorProfitAndLoss.${key}Label`) : key,
              value:
                value < 0
                  ? // if value is negative, show the value in brackets
                    `(${formatCurrency(
                      absValue,
                      currencyRates,
                      currency,
                      currentCurrency
                    )})`
                  : formatCurrency(
                      value,
                      currencyRates,
                      currency,
                      currentCurrency
                    ),
              incomePercent: total
                ? `${((absValue / total) * 100).toFixed(2)}%`
                : "-",
              valuePerUnit: units
                ? formatCurrency(
                    absValue / units,
                    currencyRates,
                    currency,
                    currentCurrency
                  )
                : "-",
              tooltip:
                typeof tooltip === "string"
                  ? tooltip
                  : tooltip
                  ? t(`vendorProfitAndLoss.${key}Tooltip`)
                  : undefined,
              ...commonMappings,
            }
          : {
              key: translate ? t(`vendorProfitAndLoss.${key}Label`) : key,
              value: "-",
              incomePercent: "-",
              valuePerUnit: "-",
              tooltip:
                typeof tooltip === "string"
                  ? tooltip
                  : tooltip
                  ? t(`vendorProfitAndLoss.${key}Tooltip`)
                  : undefined,
              ...commonMappings,
            };
      });
    },
    [currencyRates, currentCurrency]
  );

  const formattedTableData = useMemo(() => {
    if (!income || !expense || !metrics) {
      return {
        income: [],
        expense: [],
        profit: [],
        metrics: [],
      };
    }
    const netRevenue = income?.shippedCogs || 0;
    const netDeductions = Object.values(expense?.deduction || {}).reduce(
      (acc, val) => acc + val,
      0
    );
    const netChargebacks = Object.values(expense?.chargeback || {}).reduce(
      (acc, val) => acc + val,
      0
    );
    const adCost = expense?.adCost || 0;
    const cogs = expense?.cogs?.cogs || 0;
    const netExpense = adCost + cogs + netDeductions + netChargebacks;
    const unitsShipped = metrics?.shippedUnits || 0;
    const snsUnits = metrics?.snsUnits || 0;
    // Sell-in metrics
    const netReceipts = Number(income?.poValue || 0);
    const cancelledPO = Number(income?.cancelledValue || 0);
    const shortages = Number(income?.shortageValue || 0);
    const requestedPO = netReceipts + cancelledPO + shortages;
    const unitsReceived = metrics?.receivedUnits || 0;

    const isSellOut = sellType === SellType.SELL_OUT;

    // common metrics
    const units = isSellOut ? unitsShipped : unitsReceived;
    const netReceiptsOrRevenue = isSellOut ? netRevenue : netReceipts;

    const profit = netReceiptsOrRevenue - netExpense;
    return {
      income: [
        ...(isSellOut
          ? [
              ...mapToTableData({
                data: income,
                total: netRevenue,
                units: unitsShipped,
                currency,
                tooltip: true,
              }),
              ...mapToTableData({
                data: { netRevenue },
                total: netRevenue,
                units: unitsShipped,
                currency,
                bold: true,
              }),
            ]
          : [
              ...mapToTableData({
                data: {
                  requestedPO,
                  cancelledPO,
                  shortages,
                },
                total: netReceipts,
                // units as 0 to hide the value per unit column
                units: 0,
                currency,
                tooltip: true,
              }),
              ...mapToTableData({
                data: { netReceipts },
                total: netReceipts,
                units: unitsReceived,
                currency,
                bold: true,
              }),
            ]),
      ],
      expense: [
        ...mapToTableData({
          data: { adCost },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          tooltip: true,
        }),
        ...mapToTableData({
          data: { netDeductions },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          tooltip: true,
          actions: canUploadData ? (
            <EditButton
              onClick={() => {
                setDeductionUpdateDialogOpen &&
                  setDeductionUpdateDialogOpen(true);
              }}
            />
          ) : undefined,
        }),
        ...(showBreakdown
          ? mapToTableData({
              data: expense?.deduction || {},
              total: netReceiptsOrRevenue,
              units: units,
              currency,
              subCategory: true,
            })
          : []),
        ...mapToTableData({
          data: { cogs },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          tooltip: true,
        }),
        ...mapToTableData({
          data: { netChargebacks },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          tooltip: true,
          actions: canUploadData ? (
            <UploadButton
              onClick={() => {
                setChargebackUploadDialogOpen &&
                  setChargebackUploadDialogOpen(true);
              }}
            />
          ) : undefined,
        }),
        ...(showBreakdown
          ? mapToTableData({
              data: expense?.chargeback || {},
              total: netReceiptsOrRevenue,
              units: units,
              currency,
              subCategory: true,
              translate: false,
            })
          : []),
        ...mapToTableData({
          data: { netExpense },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          bold: true,
        }),
      ],
      profit: [
        ...mapToTableData({
          data: { profit },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          bold: true,
        }),
        ...mapToTableData({
          data: { profitPercent: profit },
          total: netReceiptsOrRevenue,
          units: units,
          currency,
          valueRow: "percent",
        }),
      ],
      metrics: [
        ...(isSellOut
          ? [
              ...mapToTableData({
                data: { tacos: adCost, totalFees: adCost + netDeductions },
                total: netRevenue,
                units: unitsShipped,
                currency,
                valueRow: "percent",
              }),
              ...mapToTableData({
                data: { unitsShipped },
                total: unitsShipped,
                units: unitsShipped,
                currency,
                valueRow: "int",
              }),
              ...mapToTableData({
                data: { snsUnits },
                total: unitsShipped,
                units: unitsShipped,
                currency,
                valueRow: "int",
                tooltip:
                  snsFromDate && snsToDate
                    ? t("vendorProfitAndLoss.snsUnitsTooltip", {
                        from: moment.unix(snsFromDate).format("DD MMMM YYYY"),
                        to: moment.unix(snsToDate).format("DD MMMM YYYY"),
                      })
                    : undefined,
              }),
              ...mapToTableData({
                data: { snsPen: snsUnits },
                total: unitsShipped,
                units: unitsShipped,
                currency,
                valueRow: "percent",
              }),
            ]
          : [
              ...mapToTableData({
                data: { tacos: adCost, totalFees: adCost + netDeductions },
                total: netReceipts,
                units: unitsReceived,
                currency,
                valueRow: "percent",
              }),
              ...mapToTableData({
                data: { unitsReceived },
                total: netReceipts,
                units: unitsReceived,
                currency,
                valueRow: "int",
              }),
              ...mapToTableData({
                data: { confirmationRate: requestedPO - cancelledPO },
                total: requestedPO,
                units: unitsReceived,
                currency,
                valueRow: "percent",
              }),
              ...mapToTableData({
                data: { deliveryRate: netReceipts },
                total: requestedPO - cancelledPO,
                units: unitsReceived,
                currency,
                valueRow: "percent",
              }),
            ]),
      ],
    };
  }, [
    income,
    expense,
    metrics,
    showBreakdown,
    currencyRates,
    sellType,
    currentCurrency,
  ]);

  return { formattedTableData };
};
