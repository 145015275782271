import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {
  createIntercomUserEvents,
  getIntercomUserEvents,
  getMarketingContact,
  getMarketingContactId,
} from "~/utils/intercomUtils";

import ConnectionSelector from "../pages/connectWizard/new/connectionSelector";
import LandingPage from "../pages/connectWizard/new/landingPage";
import { get } from "lodash";
import { marketplaceTypesAndCountriesString } from "~/utils/marketplaceUtils";
import moment from "moment";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const ConnectionRoutes = () => {
  const user = useTypedSelector((state) => state.user);
  const allStores = useTypedSelector((state) =>
    (state.mystore?.allStores?.stores || []).filter(
      (store) => !store.isDemoMode
    )
  );

  useEffect(() => {
    async function intercomEvents() {
      const contact = await getMarketingContact(user._id);
      const contactId = getMarketingContactId(contact);
      if (contactId) {
        const events = await getIntercomUserEvents(contactId);
        const allEvents = get(events, "data.events", []);
        const connectionStartEventLength = allEvents.filter(
          (event) => get(event, "event_name") === "connection start"
        ).length;
        const metadata = {
          Date: moment(Date.now()).format("D MMM YYYY"),
          Count: connectionStartEventLength + 1,
          Marketplaces: marketplaceTypesAndCountriesString(allStores),
          "Marketplaces Connected": allStores.length,
        };

        await createIntercomUserEvents(
          "Connection Start",
          moment().seconds(0).milliseconds(0).unix(),
          user._id,
          metadata
        );
      }
    }
    intercomEvents();
  }, []);

  return (
    <Switch>
      <Route exact path={`/connect`} component={LandingPage} />
      <Route exact path={`/connectStore`} component={LandingPage} />
      <Route exact path="/connect/select" component={ConnectionSelector} />
      <Route exact path="/connectStore/select" component={ConnectionSelector} />
    </Switch>
  );
};
