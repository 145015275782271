import {
  FETCH_SALES_MIX,
  FETCH_SALES_MIX_ERROR,
  FETCH_SALES_MIX_FETCHING,
} from "./overview.redux";
import { Filter, Range } from "~/typedef/store";

import { Dispatch } from "redux";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_SALES_MIX_FETCHING,
  });
}
interface FetchSalesMixParams {
  user: User;
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  filteredStores: any[];
  includeTax: boolean;
}

export const fetchSalesMix = (
  params: FetchSalesMixParams,
  oldParams?: FetchSalesMixParams
) => async (dispatch: Dispatch) => {
  const { currentPeriod, currentRange, filter, includeTax } = params;
  if (!shouldUseCache(params, oldParams)) {
    await setFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/salesMix`, {
        currentPeriod,
        ...currentRange,
        filter,
        includeTax,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_SALES_MIX_ERROR);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });
    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_SALES_MIX,
          payload: {
            data,
            params,
          },
        });
      } else {
        await dispatchError(dispatch, FETCH_SALES_MIX_ERROR);
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    return setError(dispatch);
  }
};
