import { MenuItem, SubMenuItem, getMenuItems } from "./sideNavConstants";

import { User } from "~/typedef/user";

/**
 * Recursive function - given a link, find its corresponding
 * menu item configuration, if it exists. Else, return null.
 *
 * @param {string} link - the relative path to a potentially restricted
 * page e.g. if the full path to a page is
 * /mystores/salesbybrand, link === "salesbybrand"
 * @param {MenuItem[]} menuItems - a list of menu item configurations
 * @return {(MenuItem | null)} */
export const findMenuItem = (
  link: string,
  menuItems?: MenuItem[] | SubMenuItem[]
): MenuItem | SubMenuItem | null => {
  if (!menuItems) return null;
  for (const menuItem of menuItems) {
    if (menuItem.link === link) {
      return menuItem;
    }
    const subResult = findMenuItem(link, menuItem?.subMenus);
    if (subResult) {
      return subResult;
    }
  }
  return null;
};

/**
 * @param {string} market - the marketplace that we're navigating to
 * e.g. ebay, amazon, shopify etc.
 *
 * @param {string} link - the relative path to a potentially restricted
 * page e.g. if the full path to a page is
 * /mystores/salesbybrand, link === "salesbybrand"
 *
 * @return {boolean} true iff this link isn't available for the given market
 * e.g. buybox is not available for ebay
 * */
export const isRestricted = (
  market: string,
  link: string,
  menuItems: MenuItem[]
): boolean | undefined => {
  const menuItemConfig = findMenuItem(link, menuItems);
  if (menuItemConfig) {
    return (
      menuItemConfig.restricted &&
      !menuItemConfig.restricted.markets.some(
        (allowedMarket) => allowedMarket === market
      )
    );
  }
  return true;
};

/**
 *
 * @param {string} link - the relative path to a potentially restricted
 * page e.g. if the full path to a page is
 * /mystores/salesbybrand, link === "salesbybrand"
 *
 * @return {boolean} true iff dates do not apply to this page
 * */
export const shouldDisableDateFilter = (
  link: string,
  user: User
): boolean | undefined => {
  const menuItemConfig = findMenuItem(link, getMenuItems());
  if (menuItemConfig) {
    return menuItemConfig.disableDateFilter;
  }
  return false;
};

export const shouldDisableTimezone = (
  link: string,
  user: User
): boolean | undefined => {
  const menuItemConfig = findMenuItem(link, getMenuItems());
  if (menuItemConfig) {
    return menuItemConfig.disableTimezoneFilter;
  }
  return false;
};
