import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";

const NO_FRACTION = 0;
const ONE_DECIMAL_POINT = 1;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

const POSTCODES = {
  NSW: {
    min: 1000,
    max: 2999,
  },
  ACT: {
    min: 200,
    max: 299,
  },
  VIC: {
    inner: { min: 3000, max: 3999 },
    outer: { min: 8000, max: 8999 },
  },
  QLD: {
    inner: { min: 4000, max: 4999 },
    outer: { min: 9000, max: 9999 },
  },
  SA: {
    min: 5000,
    max: 5999,
  },
  WA: {
    min: 6000,
    max: 6999,
  },
  TAS: {
    min: 7000,
    max: 7999,
  },
  NT: {
    min: 800,
    max: 999,
  },
};

export function isUserNameValid(userName) {
  const reg = /^[0-9a-zA-Z.\-$@*! ']{2,80}$/;
  return reg.test(userName);
}

export function isJobTitleValid(title) {
  const reg = /^[0-9a-zA-Z.\-$@*!\s]{0,200}$/;
  return reg.test(title);
}

export function isPhoneNumberValid(phone) {
  const reg = /^[0-9]{5,12}$/;
  return reg.test(phone);
}

export const removeStr = (theSeller) => {
  let result = "";
  if (theSeller && theSeller.length && theSeller.includes("Seller")) {
    const startingIndex = 0;
    const suffixPoint = 6;
    result = theSeller.substring(startingIndex, theSeller.length - suffixPoint);
  } else {
    return theSeller;
  }
  return result;
};

export const appendZero = (dateString) => {
  const dateInt = parseInt(dateString);
  let result;
  const singleDigitInt = 10;
  if (dateInt < singleDigitInt) {
    result = "0" + dateInt.toString();
    return result;
  } else {
    return dateString;
  }
};

export const kFormatter = (num) => {
  const thousandLimit = 1000;
  return Math.abs(num) >= thousandLimit
    ? Math.sign(num) *
        (Math.abs(num) / thousandLimit).toFixed(ONE_DECIMAL_POINT) +
        "k"
    : Math.sign(num) * Math.abs(num);
};

export const getGrowthPercent = (current, prior) => {
  const currentNum = parseFloat(current);
  const priorNum = parseFloat(prior);

  let growth;
  const noData = 0;
  const oneHundredPercent = 100.0;
  if (priorNum > noData) {
    growth =
      (((currentNum - priorNum) / priorNum) * oneHundredPercent).toFixed(
        NO_FRACTION
      ) + "%";
  } else {
    growth = "N/A";
  }

  return growth;
};

// flag:1, from currentCategory, flag: 2, from priorCategor
export const getFullCategoryList = (currentCategory, priorCategory) => {
  let fullList = [];

  if (!priorCategory && currentCategory) {
    return currentCategory;
  }
  if (!currentCategory && priorCategory) {
    return priorCategory;
  }
  if (!currentCategory && !priorCategory) {
    return fullList;
  }

  if (isEmpty(currentCategory) && isEmpty(priorCategory)) {
    return fullList;
  }

  fullList = currentCategory.map((item) => ({
    category: item.category,
    flag: 1,
  }));

  if (priorCategory && priorCategory.length) {
    priorCategory.forEach((item) => {
      if (!fullList.find((_item) => _item.category === item.category)) {
        fullList.push({ category: item.category, flag: 2 });
      }
    });
  }

  return fullList;
};

const periods = { TODAY: 0, WTD: 1, LAST30: 2 };

export const getIndexByPeriod = (period) => periods[period];

export const getDuration = (nowTime, compareTime) => {
  const duration = moment.duration(nowTime.diff(compareTime));

  const minutes = duration.asMinutes().toFixed(ONE_DECIMAL_POINT);
  const hours = duration.asHours().toFixed(ONE_DECIMAL_POINT);
  const days = duration.asDays().toFixed(ONE_DECIMAL_POINT);

  return { minutes, hours, days };
};

export const numberWithCommas = (x) => {
  // eslint-disable-next-line no-undefined
  if (x !== null && x !== undefined) {
    if (x < 1 || `${x}`.includes("%")) {
      return x;
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    return "";
  }
};

export const trimSpace = (stringValue) =>
  stringValue ? stringValue.replace(/^\s+|\s+$/g, "") : "";

export const convertMinutesToDays = (minutes) => {
  let hours;
  let days;
  let restMinutes;
  const onedayMinutes = HOURS_IN_DAY * MINUTES_IN_HOUR; //24*60

  if (minutes <= MINUTES_IN_HOUR) {
    return `${minutes} Minutes`;
  } else if (minutes > MINUTES_IN_HOUR && minutes < onedayMinutes) {
    hours = Math.floor(minutes / MINUTES_IN_HOUR);
    restMinutes = minutes % MINUTES_IN_HOUR;
    return `${hours} Hours`;
  } else {
    days = Math.floor(minutes / MINUTES_IN_HOUR / HOURS_IN_DAY);
    restMinutes = minutes % onedayMinutes;
    hours = Math.floor(restMinutes / MINUTES_IN_HOUR);
    restMinutes = restMinutes % MINUTES_IN_HOUR;
    return `${days} Days `;
  }
};

export const getStateByPostcode = (postcode) => {
  const iPostcode = parseInt(postcode);
  let state = "";

  if (iPostcode >= POSTCODES.NSW.min && iPostcode <= POSTCODES.NSW.max) {
    state = "NSW";
  } else if (iPostcode >= POSTCODES.ACT.min && iPostcode <= POSTCODES.ACT.max) {
    state = "ACT";
  } else if (
    (iPostcode >= POSTCODES.VIC.inner.min &&
      iPostcode <= POSTCODES.VIC.inner.max) ||
    (iPostcode >= POSTCODES.VIC.outer.min &&
      iPostcode <= POSTCODES.VIC.outer.max)
  ) {
    state = "VIC";
  } else if (
    (iPostcode >= POSTCODES.QLD.inner.min &&
      iPostcode <= POSTCODES.QLD.inner.max) ||
    (iPostcode >= POSTCODES.QLD.outer.min &&
      iPostcode <= POSTCODES.QLD.outer.max)
  ) {
    state = "QLD";
  } else if (iPostcode >= POSTCODES.SA.min && iPostcode <= POSTCODES.SA.max) {
    state = "SA";
  } else if (iPostcode >= POSTCODES.WA.min && iPostcode <= POSTCODES.WA.max) {
    state = "WA";
  } else if (iPostcode >= POSTCODES.TAS.min && iPostcode <= POSTCODES.TAS.max) {
    state = "TAS";
  } else if (iPostcode >= POSTCODES.NT.min && iPostcode <= POSTCODES.NT.max) {
    state = "NT";
  }
  return state;
};

export const allEbayCountryCodes = [
  "AUS",
  "GBR",
  "USA",
  "CAN",
  "AUT",
  "BEL",
  "FRA",
  "DEU",
  "ITA",
  "SXM",
  "NLD",
  "ESP",
  "CHE",
  "HKG",
  "IND",
  "IRL",
  "MYS",
  "PHL",
  "POL",
  "SGP",
];

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
