/* eslint-disable no-magic-numbers */
import {
  ALLOWED_BILLING_CYCLES,
  YEARLY_DISCOUNT_PERCENTAGE,
  offeredPlans,
} from "~/modules/subscription/plan/plan";
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";

import AlertCard from "~/components/alert/alertCard";
import { CurrencyDisplay } from "../../../components/toolbars/toolbarComponents/currencyDisplay";
import { InfoOutlined } from "@material-ui/icons";
import Light from "../../../components/typography/light";
import Panel from "../../../components/panel/panel.tsx";
import PlanDisplay from "~/modules/subscription/plan/planDisplay";
import { PlanPropTypes } from "./plan";
import PropTypes from "prop-types";
import mapValues from "lodash/mapValues";
import styled from "styled-components";
import { usePlan } from "./usePlan.ts";
import { useTranslation } from "react-i18next";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";

const AlertIcon = styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.warning.light};
  margin-right: 1rem;
`;

const ToggleLabel = styled(FormControlLabel)`
  ${({ theme }) => `
    margin-right: 0;
    ${theme.breakpoints.up("sm")} {
      margin-left: 10px;
    }`}
`;

const DiscountBox = styled.span`
  background-color: ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin: 1rem;
  padding: 0.25rem 0.5rem;
`;

const StyledCurrencyDisplay = styled(CurrencyDisplay)`
  padding-left: 0.25rem;
`;

export const SubscriptionPlans = ({ newUser, plans, title }) => {
  const { t } = useTranslation();
  const [yearly, setYearly] = React.useState(false);
  const [customPlan, setCustomPlan] = React.useState(null);
  const [billingCycle, setBillingCycle] = React.useState("monthly");
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const currency = DEFAULT_CURRENCY;
  const currencyCountryFlag = "USA";

  const currentPlan = usePlan();

  useEffect(() => {
    if (currentPlan) {
      if (!offeredPlans.includes(currentPlan.planId)) {
        setCustomPlan(currentPlan);
      }
    }
  }, [currentPlan]);

  function onToggleYearly(event) {
    setYearly(event.target.checked);
    setBillingCycle(event.target.checked ? "yearly" : "monthly");
  }

  function renderContent() {
    return (
      <Box p={2}>
        <Grid spacing={2} container>
          {customPlan && (
            <Grid container item xs={12}>
              <AlertCard isOpen={true} type="info">
                <AlertIcon theme={theme}>
                  <InfoOutlined fontSize="small" />
                </AlertIcon>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  flexDirection="column"
                >
                  <Typography variant="h6">
                    {t("subscription.plan.customPlanName")} {customPlan.name}.
                  </Typography>
                  <Typography variant="body1">
                    {customPlan.description}.
                  </Typography>
                </Box>
              </AlertCard>
            </Grid>
          )}
          <Grid container item xs={12} sm={9}>
            <ToggleLabel
              control={
                <Switch
                  color="primary"
                  id="yearly-toggle"
                  checked={yearly}
                  onChange={onToggleYearly}
                />
              }
              label={
                <>
                  <span>{t("subscription.plan.yearlyLabel")}</span>
                  <DiscountBox>
                    {t("subscription.plan.saveLabel", {
                      percentage: YEARLY_DISCOUNT_PERCENTAGE,
                    })}
                  </DiscountBox>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box pb={2}>
              <Light>{t("subscription.plan.currencyLabel")}</Light>
              <StyledCurrencyDisplay
                activeLink={false}
                currencyCode={currency}
                countryCode={currencyCountryFlag}
              />
            </Box>
          </Grid>
        </Grid>
        <PlanDisplay
          billingCycle={billingCycle}
          plans={plans}
          currency={currency}
        />
      </Box>
    );
  }

  if (onMobile && newUser) {
    return renderContent();
  }
  return <Panel id={title} title={title} content={renderContent()} />;
};

SubscriptionPlans.defaultProps = {
  newUser: false,
  title: "Change subscription plan",
};

SubscriptionPlans.propTypes = {
  newUser: PropTypes.bool,
  title: PropTypes.string.isRequired,
  plans: PropTypes.shape(
    mapValues(ALLOWED_BILLING_CYCLES, () => PropTypes.arrayOf(PlanPropTypes))
  ).isRequired,
};

export default SubscriptionPlans;
