import { useTypedSelector } from "~/hooks/useTypedSelector";

export const useTitleOverride = () => {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  const userOverrides = customOverrides?.overrides;
  const loadingComplete = customOverrides.loadingComplete ?? false;
  return (
    userOverrides?.appName ||
    (loadingComplete ? "MerchantSpring" : "Loading...")
  );
};
