import { Box, Grid } from "@material-ui/core";
import React, { memo, useMemo } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { useRepeatPurchaseSummaryQuery } from "~/store/mystore/repeatPurchases.redux";
import { useTranslation } from "react-i18next";

interface RepeatPurchaseSummaryProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: Range;
  currentCompare: COMPARISON_PERIOD;
  includeTax: boolean;
  conditionalFormatting?: boolean;
  report?: boolean;
}

const RepeatPurchaseSummary = memo<RepeatPurchaseSummaryProps>(
  function RepeatPurchaseSummary({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentRange,
    currentCompare,
    includeTax,
    conditionalFormatting,
    report,
  }) {
    const { t } = useTranslation();

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const { repeatPurchaseSummary, isLoading } = useRepeatPurchaseSummaryQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
        includeTax,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          isLoading: isFetching,
          repeatPurchaseSummary: data,
        }),
      }
    );

    return (
      <Panel
        id="widget-repeat-purchase-summary"
        title={t(`myStoresWidget.repeatPurchaseSummary`)}
        tooltip={t("myStoresWidget.repeatPurchaseSummaryTooltip")}
        content={
          <Box p={2}>
            {isLoading || !repeatPurchaseSummary ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={repeatPurchaseSummary.current.totalCustomers}
                    comparison={{
                      prior: repeatPurchaseSummary.prior.totalCustomers,
                    }}
                    title={t(`repeatPurchaseSummary.totalCustomers`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={repeatPurchaseSummary.current.averageSpend}
                    prior={repeatPurchaseSummary.prior.averageSpend}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`repeatPurchaseSummary.averageSpend`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={repeatPurchaseSummary.current.repeatCustomers}
                    comparison={{
                      prior: repeatPurchaseSummary.prior.repeatCustomers,
                    }}
                    title={t(`repeatPurchaseSummary.repeatCustomers`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={repeatPurchaseSummary.current.averageRepeatSpend}
                    prior={repeatPurchaseSummary.prior.averageRepeatSpend}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`repeatPurchaseSummary.averageRepeatSpend`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={repeatPurchaseSummary.current.newCustomers}
                    comparison={{
                      prior: repeatPurchaseSummary.prior.newCustomers,
                    }}
                    title={t(`repeatPurchaseSummary.newCustomers`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={
                      repeatPurchaseSummary.current.totalCustomers > 0
                        ? (repeatPurchaseSummary.current.repeatCustomers /
                            repeatPurchaseSummary.current.totalCustomers) *
                          100
                        : 0
                    }
                    comparison={{
                      prior:
                        repeatPurchaseSummary.prior.totalCustomers > 0
                          ? (repeatPurchaseSummary.prior.repeatCustomers /
                              repeatPurchaseSummary.prior.totalCustomers) *
                            100
                          : 0,
                    }}
                    title={t(`repeatPurchaseSummary.repeatCustomersPercentage`)}
                    percentage={true}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default RepeatPurchaseSummary;
