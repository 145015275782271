import { FORBIDDEN, UNAUTHORIZED } from "http-status-codes";
import { RESPONSE_CODE, isHttpResponseValid } from "./utils/httpsResponseCodes";
import { SET_ERROR, setError } from "./globalToast.redux";

import ReactGA from "react-ga";
import axios from "axios";
import { baseUrl } from "../configs";
import { clearStoreAction } from "@store/utils/globalActions";
import { fetchAllStoreDaysCoverLimit } from "./notifications.redux";
import { fetchAllStores } from "./overview/customTags.redux";
import { fetchConnectionStatus } from "./connections/connectionStatus.redux";
import { fetchDisconnectedStores } from "./connections/disconnectedStores.redux";
import get from "lodash/get";
import { getMarketplaceAccount } from "@merchantspring/common";
import { store } from "./configureStore";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const REMOVE_MID_SUCCESS = "REMOVE_MID_SUCCESS";
export const UPDATE_STORE_NAME_SUCCESS = "UPDATE_STORE_NAME_SUCCESS";
export const CLEAR_STORE = "CLEAR_STORE";
export const SET_IMPERSONATION = "SET_IMPERSONATION";
export const SET_AUTH_SOURCE = "SET_AUTH_SOURCE";
export const LOAD_OPEN_AMAZON_STORES_SUCCESS =
  "LOAD_OPEN_AMAZON_STORES_SUCCESS";

axios.defaults.withCredentials = true;

const initState = {};

export const partnerWidgetRegex = /\/partner\/.*\/(square|rectangular)Widget/;

export function recordRegisterEvent() {
  ReactGA.event({
    category: "Onboarding",
    action: "New User Registration",
  });
}

export const user = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, action.payload);
    case REGISTER_SUCCESS:
      return Object.assign({}, action.payload);
    case LOAD_USER_INFO:
      return Object.assign({}, action.payload);
    case ERROR_MESSAGE:
      return { ...state, error: { errMsg: action.payload.errMsg } };
    case UPDATE_SUCCESS:
      return Object.assign({}, action.payload);
    case REMOVE_MID_SUCCESS:
      let newState;
      let newAcc;
      const marketAcc = action.payload.marketAcc;
      if (state && state[marketAcc] && state[marketAcc].length) {
        newAcc = state[marketAcc].filter(
          (item) => item.mid !== action.payload.mid
        );
        newState = Object.assign(state, { [marketAcc]: newAcc });
      } else {
        newState = state;
      }
      return newState;
    case UPDATE_STORE_NAME_SUCCESS:
      const marketplace = action.payload.marketplace;
      const marketplaceAccount = getMarketplaceAccount(marketplace);
      if (
        state &&
        state[marketplaceAccount] &&
        state[marketplaceAccount].length
      ) {
        const otherAccounts = state[marketplaceAccount].filter(
          (item) => item.mid !== action.payload.mid
        );
        const changedAccount = state[marketplaceAccount].filter(
          (item) => item.mid === action.payload.mid
        );
        const updatedAccount = {
          ...changedAccount,
          shopName: action.payload.shopName,
        };
        const combinedAccounts = [...otherAccounts, updatedAccount];
        newState = Object.assign(state, {
          [marketplaceAccount]: combinedAccounts,
        });
      } else {
        newState = state;
      }
      return newState;
    case SET_IMPERSONATION:
      return {
        ...state,
        impersonation: action.payload,
      };
    case SET_AUTH_SOURCE:
      return {
        ...state,
        authSource: action.payload,
      };

    case LOAD_OPEN_AMAZON_STORES_SUCCESS:
      const stateWithOpenStores = Object.assign(state, {
        ["openAmazonStores"]: action.payload,
      });
      return stateWithOpenStores;

    default:
      return state;
  }
};

async function clearStoreOnNewUser(userData) {
  const existingUserState = store.getState().user;
  if (!existingUserState || existingUserState._id !== userData._id) {
    await store.dispatch({ type: CLEAR_STORE, payload: "login" });
    // For new RTK Slices
    await store.dispatch(clearStoreAction("login"));
  }
}

export function clearStore() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STORE,
      payload: "login",
    });
    // For new RTK Slices
    dispatch(clearStoreAction("login"));
  };
}

async function login(dispatch, success, data, errMsg) {
  if (success) {
    await clearStoreOnNewUser(data);
    await dispatch({ type: LOGIN_SUCCESS, payload: data });
  } else {
    // suppress red error bar for MFA-related error codes
    if (!["MFA_REQUIRED", "MFA_AUTH_CODE_ERROR"].includes(errMsg)) {
      // eslint-disable-next-line no-undefined
      await setError(dispatch, errMsg, undefined, "login");
      await dispatch({
        type: ERROR_MESSAGE,
        payload: { errMsg },
      });
    }

    throw errMsg;
  }
}

export function loginWithCredentials({ email, password, authCode }) {
  return async (dispatch) => {
    const res = await axios.post(`${baseUrl}/user/login`, {
      email,
      password,
      authCode,
    });
    const success =
      res &&
      isHttpResponseValid(res.status) &&
      res.data.code === RESPONSE_CODE.SUCCESS;
    return await login(
      dispatch,
      success,
      success ? res.data.data : [],
      success ? null : res.data.errMsg
    );
  };
}

export function loginDemoAccount() {
  return async (dispatch) => {
    const res = await axios.post(`${baseUrl}/user/login`, {
      email: "demo@mm.merchantspring.io",
      password: "demo",
    });
    const success =
      res &&
      isHttpResponseValid(res.status) &&
      res.data.code === RESPONSE_CODE.SUCCESS;
    return await login(
      dispatch,
      success,
      success ? res.data.data : [],
      success ? null : res.data.errMsg
    );
  };
}

function onLoginResponse(response) {
  return async (dispatch) => {
    const success = isHttpResponseValid(response.status);
    const error = success
      ? null
      : get(response, "response.data.errCode", response);
    const { user: userInfo, loginContext } = response.data;
    if (loginContext.isNewUser === true) {
      recordRegisterEvent();
    }
    const userPayload = {
      ...userInfo,
      isNewUser: loginContext.isNewUser,
    };
    return await login(
      dispatch,
      success,
      success ? userPayload : [],
      error
    ).catch((errMsg) => {
      throw errMsg;
    });
  };
}

export function loginGoogle(idToken) {
  return async (dispatch) => {
    const res = await axios.post(`${baseUrl}/user/login/google`, {
      idToken,
      referrer: document.referrer,
    });
    return dispatch(onLoginResponse(res));
  };
}

export function loginWeChat(authCode) {
  return async (dispatch) => {
    const res = await axios.post(`${baseUrl}/user/login/wechat`, {
      code: authCode,
      referrer: document.referrer,
    });
    return dispatch(onLoginResponse(res));
  };
}

export function updateRefreshToken() {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}/user/refreshToken`);
    } catch (e) {
      const msg = get(e, "message");
      const code = get(e, "response.status");
      await setError(dispatch, msg, code, "refreshToken");
      throw e;
    }
  };
}

export function setImpersonationFlag(isImpersonating) {
  return async (dispatch) =>
    await dispatch({
      type: SET_IMPERSONATION,
      payload: isImpersonating,
    });
}

export function setAuthSource(authSource) {
  return async (dispatch) =>
    await dispatch({
      type: SET_AUTH_SOURCE,
      payload: authSource,
    });
}

export function invalidateAndRegenRefreshToken() {
  return async () => {
    await axios.post(`${baseUrl}/user/invalidateAndRegenRefreshToken`);
  };
}

export function register({
  firstName,
  email,
  lastName,
  password,
  type,
  verified,
  title,
  org,
  captchaToken,
  originalDomain,
}) {
  return async (dispatch) => {
    const res = await axios
      .post(`${baseUrl}/user/register`, {
        firstName,
        email,
        lastName,
        password,
        type,
        verified,
        title,
        org,
        captchaToken,
        originalDomain,
        // Forward the Referer header to the backend
        referrer: document.referrer,
      })
      .catch((err) => {
        const errMsg = get(err, "response.data.errMsg");
        setError(dispatch, errMsg, get(err, "response.status"), "register");
        dispatch({ type: ERROR_MESSAGE, payload: { errMsg } });
        throw err;
      });
    if (res) {
      if (isHttpResponseValid(res.status)) {
        const registeredUser = res.data.user;
        recordRegisterEvent();
        return dispatch({ type: REGISTER_SUCCESS, payload: registeredUser });
      } else {
        await setError(
          dispatch,
          res.data.errMsg,
          get(res, "status"),
          "register"
        );
        return dispatch({
          type: ERROR_MESSAGE,
          payload: { errMsg: res.data.errMsg },
        });
      }
    }
    return setError(dispatch);
  };
}

export function updatePersonalInfo(userInfo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${baseUrl}/user/auth/updateinfo`, userInfo, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            if (
              isHttpResponseValid(res.status) &&
              res.data.code === RESPONSE_CODE.SUCCESS
            ) {
              const data = res.data.data;
              dispatch({ type: UPDATE_SUCCESS, payload: data });

              // invalidate other refresh tokens when either email or password is
              // updated
              if (
                get(userInfo, "userInfo.email") ||
                get(userInfo, "userInfo.newPassword")
              ) {
                dispatch(invalidateAndRegenRefreshToken());
              }

              resolve("succeed");
            } else {
              setError(
                dispatch,
                res.data.errMsg,
                get(res, "status"),
                "updateInfo"
              );
              reject(res.data.errMsg);
            }
          }
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          return setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "updateInfo"
          );
        });
    });
}

export function updateMarketingInfo(marketingInfo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${baseUrl}/user/auth/updateMarketingInfo`, marketingInfo, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            if (
              isHttpResponseValid(res.status) &&
              res.data.code === RESPONSE_CODE.SUCCESS
            ) {
              resolve("succeed");
            } else {
              reject(res.data.errMsg);
            }
          }
        })
        .catch((e) => {
          // If we get unauthorized while updating marketing info,
          // just skip - the user can't do anything about that anyway
          if (
            get(e, "response.status") === UNAUTHORIZED ||
            get(e, "response.status") === FORBIDDEN
          ) {
            return;
          }
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "updateMarketingInfo"
          );
        });
    });
}

// Given a token which can be used for resetting a user's password, send
// that token and a password reset request to the backend
export function resetPwd(userInfo) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const headers = { "Content-Type": "application/json" };
      axios
        .post(`${baseUrl}/user/resetpwd`, userInfo, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            if (
              isHttpResponseValid(res.status) &&
              res.data.code === RESPONSE_CODE.SUCCESS
            ) {
              dispatch({ type: UPDATE_SUCCESS, payload: res.data.data });
              resolve("succeed");
            } else {
              setError(dispatch, res.data.errMsg);
              reject(res.data.errMsg);
            }
          }
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          return setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "resetPwd"
          );
        });
    });
}

// Request the backend to send a password reset email
export function resetPwdRequest(email) {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/user/resetpwdreq/${email}`)
        .then((res) => {
          if (
            isHttpResponseValid(res.status) &&
            res.data.code === RESPONSE_CODE.SUCCESS
          ) {
            resolve("succeed");
          } else {
            reject("Something wrong when resetting password, please try again");
          }
        })
        .catch(() => {
          reject("Something wrong when resetting password, please try again");
        });
    });
}

export function logout() {
  return async (dispatch) => {
    let res;
    try {
      res = await axios.post(`${baseUrl}/user/logout`);
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
    }
    if (isHttpResponseValid(get(res, "status"))) {
      dispatch({ type: CLEAR_STORE, payload: "logout" });

      // For new RTK Slices
      dispatch(clearStoreAction("logout"));
    } else {
      setError(dispatch, "Logout failed.", get(res, "status"), "logout");
      throw new Error("Invalid response from axios.");
    }
  };
}

/**
 * @param {string} an optional pathname representing the current location in browser
 * @returns
 */
// eslint-disable-next-line no-undefined
export function loadInfo(pathname = "") {
  return async (dispatch) => {
    if (pathname === "/report") {
      dispatch({ type: CLEAR_STORE, payload: "login" });

      // For new RTK Slices
      dispatch(clearStoreAction("login"));
      return;
    }

    try {
      const res = await axios.get(`${baseUrl}/user/auth/info`);

      const userData = res.data;
      await clearStoreOnNewUser(userData);

      /** Fetch all connections in progress */
      await dispatch(fetchConnectionStatus({ user: userData }));
      await dispatch(fetchDisconnectedStores());
      await dispatch(fetchAllStores());
      await dispatch(fetchAllStoreDaysCoverLimit());
      await dispatch({ type: LOAD_USER_INFO, payload: userData });
    } catch (err) {
      // eslint-disable-next-line no-magic-numbers
      if (get(err, "response.status") === 401) {
        try {
          await dispatch(updateRefreshToken());
          // retry loadInfo only if updateRefreshToken succeeds
          await dispatch(loadInfo());
        } catch (e) {
          if (
            !pathname ||
            (!pathname.includes("/tokenLogin") &&
              !pathname.match(/\/partner\/.*\/(square|rectangular)Widget/))
          ) {
            await dispatch({
              type: SET_ERROR,
              payload: {
                showToast: true,
                status: "info",
                msg: "Your session has expired, please sign in again.",
                triggerLogout: true,
              },
            });
          }
          throw e;
        }
      } else {
        throw `loadInfo failed with error ${err}`;
      }
    }
  };
}

export function removeMid({
  marketplaceType,
  marketplaceSubtype,
  countryCode,
  mid,
}) {
  return async (dispatch) => {
    await axios
      .post(`${baseUrl}/user/auth/removemid`, {
        marketplaceType,
        marketplaceSubtype,
        countryCode,
        mid,
      })
      .then((res) => {
        if (
          isHttpResponseValid(res.status) &&
          res.data.code === RESPONSE_CODE.SUCCESS
        ) {
          dispatch({
            type: REMOVE_MID_SUCCESS,
            payload: { marketplaceType, marketplaceSubtype, countryCode, mid },
          });
          return dispatch(loadInfo());
        } else {
          setError(dispatch, res.data.errMsg, res.status, "removemid");
          throw new Error(res.data.errMsg);
        }
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "removemid"
        );
      });
  };
}

export function updateStoreName({
  userId,
  marketplace,
  marketplaceSubtype,
  countryCode,
  mid,
  shopName,
}) {
  return async (dispatch) => {
    await axios
      .post(`${baseUrl}/user/auth/updateStoreName`, {
        userId,
        marketplaceType: marketplace,
        marketplaceSubtype,
        countryCode,
        mid,
        shopName,
      })
      .then((res) => {
        if (
          isHttpResponseValid(res.status) &&
          res.data.code === RESPONSE_CODE.SUCCESS
        ) {
          dispatch({
            type: UPDATE_STORE_NAME_SUCCESS,
            payload: { marketplace, mid, shopName },
          });
        } else {
          setError(dispatch, res.data.errMsg, res.status, "updateStoreName");
          throw new Error(res.data.errMsg);
        }
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "updateStoreName"
        );
      });
  };
}

export const getOpenAmazonMarketplaceList =
  (userId, sellerId, countryCode) => async (dispatch) => {
    try {
      const inputParam = {
        amazonSellerId: sellerId,
        customerId: userId,
        countryCode: countryCode,
      };
      const options = {
        method: "GET",
        params: inputParam,
        url: `${baseUrl}/api/amazon-mws-service/api/sellerHealth/listOpenMarketplaces`,
      };
      const response = await axios(options);

      if (response.data && response.data.length > 1) {
        dispatch({
          type: LOAD_OPEN_AMAZON_STORES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      /* eslint-disable no-console */
      console.log(err);
      await dispatch({
        type: ERROR_MESSAGE,
        payload: { errMsg: "Error getting list of open Amazon marketplaces" },
      });
      throw new Error("Error getting list of open marketplaces.");
    }
  };
