import { TAG_TYPES, api } from "@store/apiSlice";

import { DateRange } from "~/typedef/date";
import { ForecastDataItem } from "~/components/forecasting/types";
import { User } from "~/typedef/user";
import _ from "lodash";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface SaveForecastAndCostParams {
  user: User;
  mid: string;
  currentRange: DateRange;
  value: number;
  type: string;
  level: string;
  currency: string;
}

interface FetchForecastValuesArgs {
  user: User;
  mid: string;
  currentRange: DateRange;
  includeInterval?: boolean;
  respectTimezone?: boolean;
}

interface FetchForecastValuesResponse {
  data: ForecastDataItem;
  dataTimezone: string;
}

interface SplitForecastValuesResponse {
  status: string;
}

interface FetchPerformanceAgainstForecastDataParams {
  user: User;
  currentRange: DateRange;
  mid: string;
}
interface PerformanceAgainstForecast {
  forecast: {
    budget: number | null;
    forecast: number | null;
  };
  adSpend: {
    target: number | null;
  };
  currency: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    forecastingValues: build.query<
      FetchForecastValuesResponse,
      FetchForecastValuesArgs
    >({
      query: (params) => {
        const { user, mid, currentRange, includeInterval, respectTimezone } =
          params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/forecasting/forecastingValue`,
          method: "POST",
          data: {
            user,
            mid,
            ...currentRange,
            ...(includeInterval ? {} : { interval: undefined }),
            respectTimezone,
          },
        };
      },
      providesTags: [TAG_TYPES.Forecast],
      onQueryStarted: globalQueryErrorHandler("forecastingValues"),
    }),
    splitValueEvenly: build.mutation<
      SplitForecastValuesResponse,
      SaveForecastAndCostParams
    >({
      query: (params) => {
        const { user, mid, currentRange, value, type, level, currency } =
          params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/forecasting/splitValue`,
          method: "POST",
          data: {
            user,
            mid,
            value,
            type,
            level,
            currency,
            ...currentRange,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.Forecast],
      onQueryStarted: globalQueryErrorHandler("splitForecasting"),
    }),

    fetchPerformanceAgainstForecastData: build.query<
      PerformanceAgainstForecast,
      FetchPerformanceAgainstForecastDataParams
    >({
      query: (params) => {
        const { currentRange, user, mid } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/forecasting/performanceAgainstForecast`,
          method: "POST",
          data: {
            ...currentRange,
            user,
            mid,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useForecastingValuesQuery,
  useSplitValueEvenlyMutation,
  useFetchPerformanceAgainstForecastDataQuery,
} = extendedApiSlice;
