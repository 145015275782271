import { FORBIDDEN, INTERNAL_SERVER_ERROR } from "http-status-codes";
import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { Dispatch } from "redux";
import { TFunction } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../configs";
import { setError } from "../globalToast.redux";

axios.defaults.withCredentials = true;

export const CRITEO_FETCH_PROFILES_START = "CRITEO_VALIDATE_AUTH_START";
export const CRITEO_FETCH_PROFILES_SUCCESS = "CRITEO_VALIDATE_AUTH_SUCCESS";

export interface Account {
  id: string;
  type: string;
  attributes: {
    name: string;
    type: "demand" | "supply";
    subtype: "brand" | "seller";
    countries: string[];
    currency: string;
    parentAccountLabel: string;
    timeZone: string;
  };
}

export interface ConnectCriteoStoreState {
  accounts: Account[] | null;
}

type ConnectCriteoStoreAction =
  | {
      type: typeof CRITEO_FETCH_PROFILES_START;
    }
  | {
      type: typeof CRITEO_FETCH_PROFILES_SUCCESS;
      payload: Account[];
    };

const initState: ConnectCriteoStoreState = {
  accounts: null,
};

export const connectCriteo = (
  state: ConnectCriteoStoreState = initState,
  action: ConnectCriteoStoreAction
): ConnectCriteoStoreState => {
  switch (action.type) {
    case CRITEO_FETCH_PROFILES_START:
      return {
        ...state,
        accounts: null,
      };
    case CRITEO_FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };
    default:
      return state;
  }
};

export const validateCriteoAuth =
  (authCode: string, t: TFunction<"translation">) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: CRITEO_FETCH_PROFILES_START });
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/criteo/connect`,
        data: { authCode },
      };
      const res = await axios(options);

      if (isHttpResponseValid(res.status)) {
        if (res.data && res.data.code === RESPONSE_CODE.SUCCESS) {
          dispatch({
            type: CRITEO_FETCH_PROFILES_SUCCESS,
            payload: res.data.accounts || [],
          });
          return "Success";
        } else if (res.data.errMsg) {
          setError(dispatch, t(res.data.errMsg), INTERNAL_SERVER_ERROR);
          throw new Error("Error authorising Criteo Advertising connection");
        } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
          throw new Error("TokenInValid");
        } else {
          throw new Error("Failed");
        }
      } else {
        setError(dispatch, t(res.data.errMsg), res.status);
        throw new Error("Failed");
      }
    } catch (err: any) {
      if (err.response && err.response.status === FORBIDDEN) {
        throw new Error("Forbidden");
      } else {
        setError(
          dispatch,
          t("advertisingConnectLabel.generalError"),
          INTERNAL_SERVER_ERROR
        );
        throw new Error("Error");
      }
    }
  };

type CriteoParams = {
  mid: string;
  countryCode: string;
  accountId: string;
};
export const setCriteoAccount =
  (params: CriteoParams, t: TFunction<"translation">) =>
  async (dispatch: Dispatch) => {
    try {
      const { mid, countryCode, accountId } = params;
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/criteo/connect/profile`,
        data: {
          mid,
          countryCode,
          accountId,
        },
      };

      const { status, data } = await axios(options);

      if (isHttpResponseValid(status)) {
        return "Success";
      }

      setError(dispatch, t(data.errMsg), status);
      throw new Error("Error encountered when updating Advertising profile.");
    } catch (err) {
      setError(
        dispatch,
        t("advertisingConnectLabel.generalError"),
        INTERNAL_SERVER_ERROR
      );
      throw new Error((err as Error).message);
    }
  };
