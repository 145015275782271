import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import StatusText from "~/components/typography/status";

interface ErrorCellProps {
  cell: {
    value: {
      displayValue: number;
      conditionalFormatting?: boolean;
      compareValue?: number;
      threshold?: number;
    };
  };
}

/**
 * Table cell to show traffic lighted values based on supplied thresholds
 * Higher values are "good", lower values are "bad".
 */
export const ErrorCell = memo(({ cell }: ErrorCellProps) => {
  const displayValue = cell.value.displayValue;
  const status =
    cell.value.conditionalFormatting === false
      ? "" // Falsy status value gives theme.palette.common.black
      : Number(cell.value.compareValue) > Number(cell.value.threshold)
      ? "error"
      : "";

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <StatusText variant="body2" status={status}>
          {displayValue}
        </StatusText>
      </Grid>
    </Grid>
  );
});
