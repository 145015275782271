import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  LEGACY_PLAN_ID,
  getPlanName,
} from "../../modules/subscription/plan/plan";
import {
  SELECT_PLAN,
  removeDiscountOverride,
} from "../../store/subscriptions.redux";
import {
  createIntercomUserEvents,
  getIntercomUserEvents,
  getMarketingContact,
  getMarketingContactId,
} from "~/utils/intercomUtils";
import { useDispatch, useSelector } from "react-redux";

import Bold from "~/components/typography/bold";
import Dashboard from "../multiChannel/overview";
import Italic from "~/components/typography/italic";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Medium from "~/components/typography/medium";
import PaymentDetails from "../../modules/subscription/paymentDetails";
import PaymentSubscriptionSummary from "../../modules/subscription/paymentSubscriptionSummary";
import PropTypes from "prop-types";
import React from "react";
import { SubscriptionPageBlock } from "../../modules/subscription/subscriptionStyledComponents";
import brita from "../../img/testimonials/brita_logo.png";
import customerImage from "../../img/testimonials/customer-image.png";
import { fetchPlans } from "~/store/subscriptions.redux";
import fila from "../../img/testimonials/Fila-Logo.png";
import get from "lodash/get";
import gshopper from "../../img/testimonials/gshopper.png";
import jeanswest from "../../img/testimonials/Jeanswest.png";
import logoAmazonPartner from "../../img/testimonials/amazon_partner.png";
import logoEbayPartner from "../../img/testimonials/ebay_partner.png";
import logoShopifyPartner from "../../img/testimonials/shopify_partner.png";
import mbeat from "../../img/testimonials/mbeat.png";
import moment from "moment-timezone";
import musashi from "../../img/testimonials/Musashi.png";
import { noPriorSubscription } from "../../modules/subscription/paywall";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core";

const PaywallDialogTitle = styled(DialogTitle)`
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
`;

const BackButton = styled(IconButton)`
  margin-left: -1rem;
`;

const CustomerImage = styled.img`
  width: 108px;
  height: 108px;
  border-radius: 50%;
`;

const LogoImage = styled.img`
  display: flex;
  max-height: 100%;
  max-width: 100%;
`;

const PartnerImage = styled.img`
  display: flex;
  max-height: 100px;
  height: 100px;
  margin: auto;
`;

const ImagesWrapper = styled(Grid)`
  padding-top: 2rem !important;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const LaunchIntercomLink = withTheme(styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    display: flex;
  `}
`);

const SubscriptionCreatePage = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => get(state, "user"));
  const currentSubscription = useSelector((state) =>
    get(state, "subscriptions.currentSubscription")
  );
  const newUser = noPriorSubscription(currentSubscription);
  const selectedPlan = useSelector((state) =>
    get(state, "subscriptions.selectedPlan")
  );
  const plans = useSelector((state) => get(state, "subscriptions.plans"));
  const discount = useSelector((state) => get(state, "subscriptions.discount"));
  const status = get(currentSubscription, "status");
  const currentPlanId = get(currentSubscription, "planId");
  const planId = get(selectedPlan, "planId");
  const subscriptionCurrency = get(selectedPlan, "currency");
  const subscriptionAmount = get(selectedPlan, "cost");
  const numTrialDays = get(selectedPlan, "trialPeriod");
  const history = useHistory();
  const params = useQueryParams();
  const dispatch = useDispatch();

  const urlPlanId = params.get("plan");
  const promoCode = params.get("promoCode");

  React.useEffect(() => {
    dispatch(fetchPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (urlPlanId && plans) {
      const billingPeriod = urlPlanId.includes("mth") ? "monthly" : "yearly";
      const plan = plans[billingPeriod].find((p) => p.planId === urlPlanId);
      if (plan) {
        dispatch({ type: SELECT_PLAN, payload: plan });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPlanId, plans]);

  const checkPreConditions = () => {
    if (
      typeof status !== "undefined" &&
      status !== "Canceled" &&
      status !== "Expired" &&
      currentPlanId !== LEGACY_PLAN_ID
    ) {
      // eslint-disable-next-line no-console
      console.log(`Subscription Create: status is ${status}`);
      return false;
    }

    if (
      typeof planId === "undefined" ||
      typeof subscriptionCurrency === "undefined" ||
      typeof subscriptionAmount === "undefined" ||
      typeof numTrialDays === "undefined"
    ) {
      // eslint-disable-next-line no-console
      console.error("Subscription Create: some Redux inputs are not defined");
      return false;
    }

    return true;
  };

  const preconditionsMet = checkPreConditions();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const onBack = () => {
    dispatch(removeDiscountOverride());
    history.goBack();
  };

  React.useEffect(() => {
    async function getEvents() {
      const contact = await getMarketingContact(user._id);
      const contactId = getMarketingContactId(contact);
      if (contactId) {
        const events = await getIntercomUserEvents(contactId);
        const allEvents = get(events, "data.events", []);
        const connectionFinishEventLength = allEvents.filter(
          (event) => get(event, "event_name") === "checkout start"
        ).length;
        const metadata = {
          Date: moment(Date.now()).format("D MMM YYYY"),
          Count: connectionFinishEventLength + 1,
          Plan: getPlanName(currentPlanId),
          Price: subscriptionAmount,
          Currency: subscriptionCurrency,
        };

        await createIntercomUserEvents(
          "Checkout Start",
          moment().seconds(0).milliseconds(0).unix(),
          user._id,
          metadata
        );
      }
    }
    getEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Dashboard />
      <Dialog open maxWidth="md">
        <SubscriptionPageBlock>
          <PaywallDialogTitle>
            <Box
              display="flex"
              alignItems={smDown ? "flex-start" : "center"}
              justifyContent="space-between"
              flexDirection={smDown ? "column" : "row"}
              width="100%"
            >
              <Box display="flex" alignItems="center">
                <BackButton aria-label="back" onClick={onBack}>
                  <KeyboardArrowLeft fontSize="large" />{" "}
                </BackButton>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  {newUser ? (
                    <>
                      <Medium variant="h4">
                        {t("subscription.create.newUserTitle")}
                      </Medium>
                      <Typography variant="body2" color="textSecondary">
                        {t("subscription.create.newUserMessage")}
                      </Typography>
                    </>
                  ) : (
                    <Medium variant="h4">
                      {t("subscription.create.mainTitle")}
                    </Medium>
                  )}
                </Box>
              </Box>
              <Box
                pl={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                alignSelf="stretch"
              >
                <LaunchIntercomLink id="launch-intercom">
                  {t("subscription.create.questionLink")}
                </LaunchIntercomLink>
              </Box>
            </Box>
          </PaywallDialogTitle>
          {preconditionsMet && (
            <Grid container spacing={2}>
              <PaymentSubscriptionSummary
                newUser={newUser}
                plan={selectedPlan}
                discount={discount}
              />
              <PaymentDetails
                promoCode={promoCode}
                newUser={newUser}
                plan={selectedPlan}
                discount={discount}
              />
            </Grid>
          )}
          <Box
            p={4}
            pl={smDown ? 1 : 6}
            pr={smDown ? 0 : 6}
            display="flex"
            flexDirection="column"
          >
            <Medium variant="h4">
              {t("subscription.create.customerTitle")}
            </Medium>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Box display="flex" pt={3}>
                  <CustomerImage src={customerImage} />
                  <Box
                    p={1}
                    pl={3}
                    pt={smDown ? 0 : 1}
                    display="flex"
                    flexDirection="column"
                  >
                    <Box pb={1}>
                      <Italic variant="body2">
                        &quot;Marketplace Manager is a must-have if selling
                        across multiple marketplaces, easy to see all your
                        information in one place.&quot;
                      </Italic>
                    </Box>
                    {!smDown && (
                      <>
                        <Bold variant="caption">Howard Chen,</Bold>
                        <Typography variant="caption">
                          Managing Director at Ultra Imagination Technology
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
                {smDown && (
                  <Box display="flex" flexDirection="column">
                    <Bold variant="caption">Howard Chen,</Bold>
                    <Typography variant="caption">
                      Managing Director at Ultra Imagination Technology
                    </Typography>
                  </Box>
                )}
              </Grid>
              <ImagesWrapper
                container
                item
                xs={12}
                md={5}
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={5}>
                  <LogoImage src={gshopper} />
                </Grid>
                <Grid item xs={7}>
                  <LogoImage src={jeanswest} />
                </Grid>
                <Grid item xs={2}>
                  <LogoImage src={mbeat} />
                </Grid>
                <Grid item xs={4}>
                  <LogoImage src={musashi} />
                </Grid>
                <Grid item xs={2}>
                  <LogoImage src={brita} />
                </Grid>
                <Grid item xs={4}>
                  <LogoImage src={fila} />
                </Grid>
              </ImagesWrapper>
            </Grid>
          </Box>
        </SubscriptionPageBlock>
        <Box
          maxWidth={600}
          p={2}
          maxHeight={100}
          display="flex"
          alignSelf="center"
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <PartnerImage src={logoAmazonPartner} />
            </Grid>
            <Grid item xs={4}>
              <PartnerImage src={logoEbayPartner} />
            </Grid>
            <Grid item xs={4}>
              <PartnerImage src={logoShopifyPartner} />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

SubscriptionCreatePage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SubscriptionCreatePage);
