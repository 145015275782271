import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "@store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import PurchaseOrderStatusPieChart from "./purchaseOrderStatusPieChart";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PurchaseOrderStatus = memo(function PurchaseOrderStatus() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  return store ? (
    <PurchaseOrderStatusPieChart
      mid={store.merchantId}
      currentRange={currentRange}
      currentCurrency={currentCurrency}
      includeTax={includeTax}
    />
  ) : null;
});

export default PurchaseOrderStatus;
