import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { memo } from "react";

import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import PropTypes from "prop-types";
import styled from "styled-components";

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PaginationInput = styled(TextField)`
  min-width: 25px;
  width: 35px;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  margin: 0 5px;
  input {
    padding: 5px;
    text-align: center;
  }
`;

const PaginationButton = styled(Button)`
  display: flex;
  align-items: center;
  min-width: fit-content;
`;

const Pagination = memo(function Pagination({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  autoPagination,
  currentPage,
}) {
  return autoPagination ? (
    currentPage < pageCount ? (
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        p={1}
      >
        <LoadingIndicator size={30} />
      </Box>
    ) : null
  ) : (
    <PaginationWrapper className="pagination">
      <PaginationButton
        variant="text"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {"<<"}
      </PaginationButton>
      <PaginationButton
        variant="text"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {"<"}
      </PaginationButton>{" "}
      <Typography variant="body2" color="textSecondary">
        Page
      </Typography>
      <PaginationInput
        type="number"
        size="small"
        variant="outlined"
        value={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
      />
      <Typography variant="body2" color="textSecondary">
        of {pageOptions.length}
      </Typography>
      <PaginationButton
        variant="text"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        {">"}
      </PaginationButton>
      <PaginationButton
        variant="text"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {">>"}
      </PaginationButton>
    </PaginationWrapper>
  );
});

Pagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  autoPagination: PropTypes.bool,
  currentPage: PropTypes.number,
};

export default Pagination;
