import axios, { AxiosRequestConfig } from "axios";

import { Contact } from "~/typedef/intercom";
import { User } from "~/typedef/user";
import { baseUrl } from "../configs";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const getMarketingContactId = (contact: { data: Contact } | null) =>
  get(contact?.data, "id");

export const createMarketingContact = async (userData: User) => {
  const { _id, email, firstName, lastName, imgUrl, phone, role } = userData;
  try {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `${baseUrl}/api/marketing/contact`,
      data: {
        userId: _id,
        email,
        firstName,
        lastName,
        imgUrl,
        phone,
        role,
      },
    };

    return await axios(config);
  } catch (error) {
    return error;
  }
};

export const updateMarketingContact = async (
  userId: string,
  contactId: string,
  customAttrib: Record<string, any>,
  email = "",
  phone = "",
  role?: string
) => {
  try {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `${baseUrl}/api/marketing/contact`,
      data: {
        userId,
        contactId,
        email,
        phone,
        role,
        customAttrib,
      },
    };

    return await axios(config);
  } catch (error) {
    return error;
  }
};

export const getMarketingContact = async (
  userId: string
): Promise<{ data: Contact } | null> => {
  try {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${baseUrl}/api/marketing/contact?userId=${userId}`,
    };

    return await axios(config);
  } catch (error) {
    return null;
  }
};

const getIntercomTagList = async (): Promise<{
  data: { data: { id: string; name: string }[] };
} | null> => {
  try {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${baseUrl}/api/marketing/tags`,
    };

    return await axios(config);
  } catch (error) {
    return null;
  }
};

const createIntercomTag = async (contactId: string, tagId: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `${baseUrl}/api/marketing/tags`,
      data: {
        contactId: contactId,
        tagId: tagId,
      },
    };

    return await axios(config);
  } catch (error) {
    return error;
  }
};

export const createIntercomTags = async (userId: string, trigger: string) => {
  let triggeredTagId = "";
  const tags = await getIntercomTagList();
  const contact = await getMarketingContact(userId);
  if (tags && contact) {
    const intercomTags = get(tags.data, "data");
    const contactId = getMarketingContactId(contact);

    if (contactId) {
      if (!isEmpty(intercomTags)) {
        const tagId = intercomTags.filter((tag) => tag.name === trigger);
        triggeredTagId = !tagId || isEmpty(tagId) ? "" : tagId[0].id;
      }

      return await createIntercomTag(contactId, triggeredTagId);
    }
  }
};

export const getIntercomUserEvents = async (contactId: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${baseUrl}/api/marketing/events?contactId=${contactId}`,
    };

    return await axios(config);
  } catch (error) {
    return error;
  }
};

export const createIntercomUserEvents = async (
  eventName: string,
  createdAt: number,
  userId: string,
  metadata: Record<string, any>
) => {
  const data = {
    userId: userId,
    createdAt: createdAt,
    eventName: eventName,
    metadata: metadata,
  };

  try {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `${baseUrl}/api/marketing/events`,
      data: data,
    };

    return await axios(config);
  } catch (error) {
    return error;
  }
};
