import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "@components/typography/bold";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { DateRange } from "~/typedef/date";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import { InfoOutlined } from "@material-ui/icons";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import Panel from "@components/panel/panel";
import PieChart from "@components/charts/pieChart/pieChart";
import { ReactChild } from "react";
import { numberWithCommas } from "@utils/utils";
import styled from "styled-components";
import { usePurchaseOrderStatusQuery } from "~/store/mystore/vendor.redux";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface IPieChartData {
  name: string;
  value: number;
  color: string;
  type: string;
  tooltipContent?: string;
  legendContent?: ReactChild;
}

interface IPOStatusLegendItem {
  name: string;
  value?: string;
  count: number;
  piePercentage?: number;
  tooltip?: string;
}

const InlineTooltip = styled(Tooltip)`
  vertical-align: middle;
`;

const InfoIcon = styled(InfoOutlined)`
  height: 12px;
  width: 12px;
  margin-left: 0.25rem;
`;

const POStatusLegendItem: React.FC<IPOStatusLegendItem> = ({
  name,
  value,
  count,
  piePercentage,
  tooltip,
}) => {
  const piePercentageText = Number.isNaN(piePercentage)
    ? "N/A"
    : `${piePercentage}%`;
  return (
    <Box display="flex" flexDirection="column" minWidth={0}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        overflow="hidden"
      >
        <Grid container item alignItems="center">
          <Grid item>
            <Typography variant="body1" noWrap>
              {name}
              {tooltip && (
                <InlineTooltip title={tooltip}>
                  <InfoIcon />
                </InlineTooltip>
              )}
              :
            </Typography>
          </Grid>
        </Grid>
        {value && (
          <Box display="flex" ml={1}>
            <Bold variant="body2">{numberWithCommas(value)}</Bold>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box display="flex">
          <Bold variant="body2">{count}</Bold>
        </Box>
        <Box display="flex" ml={1}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            ({piePercentageText})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface PurchaseOrderStatusPieChartProps {
  mid: string;
  currentRange: DateRange;
  currentCurrency: string;
  includeTax: boolean;
}

const PurchaseOrderStatusPieChart = memo<PurchaseOrderStatusPieChartProps>(
  function PurchaseOrderStatusPieChart({
    mid,
    currentRange,
    currentCurrency,
    includeTax,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const { chartData, chartDataLoading } = usePurchaseOrderStatusQuery(
      {
        mid,
        currentRange,
        includeTax,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          chartData: data || {
            acceptedUnits: 0,
            cancelledUnits: 0,
            submittedUnits: 0,
            receivedUnits: 0,
            rejectedUnits: 0,
            poCount: 0,
            poValue: 0,
            currency: DEFAULT_CURRENCY,
          },
          chartDataLoading: isFetching,
        }),
      }
    );

    const {
      acceptedUnits,
      cancelledUnits,
      receivedUnits,
      submittedUnits,
      poValue,
      currency,
    } = chartData;

    const acceptedUnitsToDisplay = acceptedUnits - receivedUnits;
    const submittedUnitsToDisplay =
      submittedUnits - acceptedUnitsToDisplay - receivedUnits;

    const pieChartTotal =
      cancelledUnits +
      receivedUnits +
      Math.max(acceptedUnitsToDisplay, 0) +
      Math.max(submittedUnitsToDisplay, 0);
    const pieChartTotalDisplay = pieChartTotal - cancelledUnits;

    const pieChartData: IPieChartData[] = [
      ...(submittedUnitsToDisplay > 0
        ? [
            {
              name: t("POStatus.submitted"),
              value: submittedUnitsToDisplay,
              color: theme.palette.chart.darkBlue,
              type: "submitted",
            },
          ]
        : []),
      ...(acceptedUnitsToDisplay > 0
        ? [
            {
              name: t("POStatus.accepted"),
              value: acceptedUnitsToDisplay,
              color: theme.palette.success.main,
              type: "accepted",
            },
          ]
        : []),
      {
        name: t("POStatus.received"),
        value: receivedUnits,
        color: theme.palette.primary.main,
        type: "received",
      },
      {
        name: t("POStatus.cancelled"),
        value: cancelledUnits,
        color: theme.palette.chart.red,
        type: "cancelled",
      },
    ];
    pieChartData.forEach((pieData) => {
      const piePercentage = Math.round((pieData.value / pieChartTotal) * 100);
      const pieValue = pieData.value;
      pieData.tooltipContent = `${pieData?.name}: ${numberWithCommas(
        pieValue
      )} (${piePercentage}%)`;
      pieData.legendContent = (
        <POStatusLegendItem
          name={pieData?.name}
          count={pieValue}
          piePercentage={piePercentage}
          tooltip={t(`POStatus.${pieData.type}Info`)}
        />
      );
    });

    return (
      <Panel
        id="purchase-order-status"
        title={t("vendor.purchaseOrderStatus.title")}
        tooltip={t("vendor.purchaseOrderStatus.tooltip")}
        content={
          chartDataLoading ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Grid container item xs={12} alignItems="center">
              <PieChart
                {...{
                  data: pieChartData,
                  legendHeader: (
                    <Box display="flex" flexDirection="column">
                      <GenericTotalMoney
                        current={poValue}
                        currency={currency}
                        currentCurrency={currentCurrency}
                        title={t(`vendor.totalPurchaseOrders`)}
                        condensed={true}
                        rounded
                      />
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography variant="body1">
                            {numberWithCommas(pieChartTotalDisplay)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip title={t(`vendor.totalPurchaseOrdersUnits`)}>
                            <InfoIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  ),
                }}
              />
            </Grid>
          )
        }
      />
    );
  }
);

export default PurchaseOrderStatusPieChart;
