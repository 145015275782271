import React, { memo } from "react";

import Medium from "../../typography/medium";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TableCellProp } from "../cellProps";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type CellStatus = "success" | "warning" | "error" | "disabled";
const Cell = styled.div<{
  $status: CellStatus;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  max-width: 100px;
  padding: 1px;
  border: 1.5px solid ${({ theme, $status }) => theme.palette[$status].main};
  color: ${({ theme, $status }) => theme.palette[$status].main};
  margin: auto;
`;

interface CellContentProps {
  getStatus: () => CellStatus;
  value: string;
}

const CellContent = ({ getStatus, value }: CellContentProps) => (
  <Cell $status={getStatus()}>
    <Medium variant="body2" noWrap color="inherit">
      {value ? value.toUpperCase() : "NOT SENT"}
    </Medium>
  </Cell>
);

interface RequestStatusCellProps {
  cell: TableCellProp<string>;
  row: {
    original: {
      isFBM: boolean;
      isFBA: boolean;
      isRefunded: boolean;
      shippingStatus: string;
    };
  };
  reviewSettings: {
    automationOn: boolean;
    excludeRefunds: boolean;
    excludeFBM: boolean;
    excludeFBA: boolean;
    excludeInternational: boolean;
  };
}

const RequestStatusCell = memo<RequestStatusCellProps>(
  function RequestStatusCell({ cell, row, reviewSettings }) {
    const { t } = useTranslation();

    const value = cell.value;
    const isFBM = Boolean(row.original.isFBM);
    const isFBA = Boolean(row.original.isFBA);
    const isRefunded = Boolean(row.original.isRefunded);
    const isInternational = Boolean(
      row.original.shippingStatus === "International"
    );

    function getStatus() {
      if (value) {
        if (value === "Excluded" || value === "Expired") {
          return "error";
        }
        if (value.includes("Sched")) {
          return "warning";
        }
        if (value === "Sent") {
          return "success";
        }
      }
      return "disabled";
    }

    function getTooltip() {
      if (value === "Excluded") {
        if (reviewSettings.excludeFBM && isFBM) {
          return t("excludedReason.FBM");
        }
        if (reviewSettings.excludeFBA && isFBA) {
          return t("excludedReason.FBA");
        }
        if (reviewSettings.excludeRefunds && isRefunded) {
          return t("excludedReason.refunded");
        }
        if (reviewSettings.excludeInternational && isInternational) {
          return t("excludedReason.international");
        }
      }
      return "";
    }

    return value === "Excluded" ? (
      <Tooltip title={getTooltip()} arrow placement="right-end">
        {/* div required for tooltip to work */}
        <div>
          <CellContent getStatus={getStatus} value={value} />
        </div>
      </Tooltip>
    ) : (
      <CellContent getStatus={getStatus} value={value} />
    );
  }
);

export default RequestStatusCell;
