import { PaginationArgs } from "~/typedef/pagination";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "./utils/errorHandlerUtils";

type FetchOrganisationSeatsArgs = Pick<
  PaginationArgs,
  "pageSize" | "pageIndex"
> & {
  search: string;
  roleFilter: string;
};

export type OrganisationSeat = {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  registrationTime: Date;
  imgUrl?: string;
  numberOfChannelsConnected?: number;
};

type FetchOrganisationSeatsResponse = {
  seats: OrganisationSeat[];
  total: number;
  pageSize: number;
  pageIndex: number;
};

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    organisationSeats: build.query<
      FetchOrganisationSeatsResponse,
      FetchOrganisationSeatsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/user-management-service/api/organisation/seats`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("OrganisatioSeats"),
    }),
  }),
});

export const { useOrganisationSeatsQuery } = extendedApiSlice;
