import { ArrowDropDown, Tune } from "@material-ui/icons";
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Grid,
  GridProps,
  Hidden,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useMemo, useState } from "react";

import TagsContainer from "../tags/tagsContainer";
import isEmpty from "lodash/isEmpty";
import { orderBy } from "lodash";
import styled from "styled-components";

interface FilterSelectBaseProps extends ButtonBaseProps {
  maxWidth?: number;
  height?: number;
}
export const FilterSelectBase = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !["maxWidth"].includes(prop),
})<FilterSelectBaseProps>`
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "none"};
  height: ${({ height }) => `${height}px` || "auto"};
  &:disabled {
    background-color: ${({ theme }) => theme.palette.disabled.light};
    box-shadow: ${({ theme }) => theme.shadows[0]};
    cursor: not-allowed;
  }
`;

export const OptionText = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  padding-left: 8px;
`;

interface RightAlignedGridProps extends GridProps {
  isFullWidth?: boolean;
}
const RightAlignedGrid = styled(Grid).withConfig({
  shouldForwardProp: (prop) => !["isFullWidth"].includes(prop),
})<RightAlignedGridProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "fit-content")};
`;

const TuneWrapper = styled(Tune)`
  font-size: 20px;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag = styled.div<{ isNarrow: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isNarrow }) => (isNarrow ? "50%" : "80%")};
  height: 22px;
  margin: 2px;
  line-height: 22px;
  max-width: 100px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

const StyledArrowDropDown = styled(ArrowDropDown)`
  width: 20px;
  margin-right: -0.25rem;
  margin-left: 0.25rem;
`;

interface FilterTagSelectProps {
  title: string;
  options?: string[];
  selectedOptions?: string[];
  filterTagTitle?: string;
  isWithSuggestion?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleOpenMenu?: () => void;
  handleOptionChange: (tags: string[]) => void;
  isFullWidth?: boolean;
  maxWidth?: number;
  additionalMenuWidth?: number;
  forSettingPage?: boolean;
}
const FilterTagSelect = memo<FilterTagSelectProps>(function FilterTagSelect({
  title,
  maxWidth,
  isFullWidth = false,
  filterTagTitle,
  options = [],
  selectedOptions = [],
  isWithSuggestion = false,
  handleOpenMenu,
  handleOptionChange,
  additionalMenuWidth = 0,
  isDisabled,
  isLoading,
  forSettingPage,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [tags, setTags] = useState(selectedOptions);
  const [menuWidth, setMenuWidth] = useState(
    anchorEl ? anchorEl.offsetWidth : null
  );
  const othersLength = selectedOptions.length - 1;

  const orderedOptions = useMemo(
    () =>
      orderBy(
        options,
        (option) =>
          typeof option === "string" ? option.toLowerCase() : option,
        "asc"
      ),
    [options]
  );

  const openMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
    if (!anchorEl && handleOpenMenu) {
      handleOpenMenu();
    }
  };

  const closeMenu = () => {
    tags && handleOptionChange(tags);
    setAnchorEl(null);
  };

  useEffect(() => {
    setMenuWidth(anchorEl ? anchorEl.offsetWidth + additionalMenuWidth : null);
  }, [setMenuWidth, anchorEl, additionalMenuWidth]);

  return (
    <RightAlignedGrid
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
      isFullWidth={isFullWidth}
    >
      <Hidden smDown>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {title}
          </Typography>
        </Box>
      </Hidden>
      <FilterSelectBase
        height={!isWithSuggestion ? 32 : undefined}
        maxWidth={maxWidth}
        onClick={openMenu}
        id="filter-tag-select"
        data-cy="filter-tag-select"
        theme={theme}
        disabled={isDisabled}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <TuneWrapper />
          <Grid container item xs alignItems="flex-end">
            {isEmpty(selectedOptions) ? (
              <OptionText theme={theme} variant="body2">
                {filterTagTitle}
              </OptionText>
            ) : (
              <>
                {
                  <Tag theme={theme} isNarrow={Boolean(othersLength)}>
                    <span>{selectedOptions[0]}</span>
                  </Tag>
                }
                {Boolean(othersLength) && (
                  <Typography variant="body2" noWrap>
                    {`, ${othersLength}+`}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <StyledArrowDropDown />
        </Grid>
      </FilterSelectBase>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        marginThreshold={1}
        onClose={closeMenu}
      >
        <TagsContainer
          menuWidth={menuWidth}
          isLoading={isLoading}
          options={orderedOptions}
          selectedOptions={selectedOptions}
          isWithSuggestion={isWithSuggestion}
          handleOptionChange={setTags}
          forSettingPage={forSettingPage}
        />
      </Popover>
    </RightAlignedGrid>
  );
});

export default FilterTagSelect;
