import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import styled, { useTheme } from "styled-components";

import React from "react";
import SmallButton from "../buttons/smallButton";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { useTranslation } from "react-i18next";

interface SplitInputProps {
  type: string;
  title: string;
  value: number;
  level: string;
  setStateValue: (value: number) => void;
  colorValue: string;
  disableButton: boolean;
  currentCurrency: string;
  splitValue: ({
    type,
    value,
    level,
  }: {
    type: string;
    value: number;
    level: string;
  }) => void;
}

const StyledTypography = styled(Typography)`
  margin-right: 20px;
  margin-top: 4px;
`;

const StyledGrid = styled(Grid)`
  margin: 8px 12px;
`;

const Indicator = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  height: 20px;
  width: 20px;
  margin: 1px;
  border-radius: 25px;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const SplitInputComponent: React.FC<SplitInputProps> = ({
  type,
  title,
  level,
  value,
  setStateValue,
  colorValue,
  currentCurrency,
  splitValue,
  disableButton,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledGrid
      container
      item
      direction="row"
      justifyContent="flex-start"
      data-testid="form-grid"
      alignItems="center"
      spacing={1}
      xs={12}
    >
      <Grid container item xs={4} direction="row" wrap="nowrap">
        <Indicator data-testid="form-indicator" $color={colorValue} />
        <StyledTypography variant="h3" data-testid="form-title">
          {title}
        </StyledTypography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          data-testid="form-text-field"
          size="small"
          autoFocus
          type="number"
          value={value || ""}
          variant="outlined"
          color="primary"
          inputProps={{
            "data-testid": "form-input",
            style: {
              textAlign: "right",
              ...theme.typography.body2,
              width: "100%",
              height: "3px",
              borderRadius: "12px",
              paddingLeft: "4px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {
                  getCurrencySymbol[
                    currentCurrency as keyof typeof getCurrencySymbol
                  ]
                }
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setStateValue(Number(e.target.value));
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SmallButton
          data-testid="form-button"
          variant="contained"
          disabled={disableButton}
          color="info"
          onClick={() => {
            splitValue({ type, value, level });
          }}
        >
          {t("forecasting.splitEvenly")}
        </SmallButton>
      </Grid>
    </StyledGrid>
  );
};
