import { CurrentStore, Store } from "~/typedef/store";
import { Icon, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import GenericAdvertisingConnectPageAuthForm from "./genericAdvertisingConnectPageAuthForm";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import { connectionStatus } from "~/utils/marketplaceUtils";
import crossLogo from "~/img/cross_large.png";
import { getAdvertisingMarketplaceConfig } from "./genericAdvertisingConnectPageConfig";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const {
  CONNECTION_INIT,
  CONNECTION_INPROGRESS,
  CONNECTION_FAILED,
  CONNECTION_SUCCEED,
} = connectionStatus;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

interface AdvertisingProps {
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
  onSuccess?: (...params: any) => void;
  setIsSubmitting?: (bool: boolean) => void;
}

const GenericStandaloneAdvertisingConnectPage = memo(
  ({
    marketplaceType,
    marketplaceCountry,
    marketplaceSubtype,
    onSuccess,
    setIsSubmitting,
  }: AdvertisingProps) => {
    const { t } = useTranslation();

    const [marketplaceConfig, setMarketplaceConfig] =
      useState<Record<string, any>>();
    const [input1, setInput1] = useState("");
    const [input2, setInput2] = useState("");

    const [transaction, setTransaction] = useState<{
      status: string;
      errMsg: string;
      payload?: Record<string, any>;
    }>({
      status: connectionStatus.CONNECTION_INIT,
      errMsg: "",
    });

    useEffect(() => {
      setMarketplaceConfig(
        getAdvertisingMarketplaceConfig(
          t,
          marketplaceType,
          // fill in dummy values here - we don't need these for
          // standalone at this stage
          "standaloneStoreName",
          null, //mid
          marketplaceCountry,
          marketplaceSubtype
        )
      );
    }, []);

    useEffect(() => {
      if (onSuccess && transaction.status === CONNECTION_SUCCEED) {
        onSuccess(
          undefined,
          transaction?.payload
            ? {
                ...(transaction.payload.mid
                  ? {
                      mid: transaction.payload?.mid,
                    }
                  : {}),
                ...(transaction.payload.countryCode
                  ? {
                      countryCode: transaction.payload?.countryCode,
                    }
                  : {}),
              }
            : undefined
        );
      }
    }, [transaction.status]);

    useEffect(() => {
      if (setIsSubmitting && transaction.status === CONNECTION_INPROGRESS) {
        setIsSubmitting(transaction.status === CONNECTION_INPROGRESS);
      }
    }, [transaction]);

    return !marketplaceConfig ||
      transaction.status === CONNECTION_INPROGRESS ? (
      <LoadingIndicator />
    ) : (
      <Grid container>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {marketplaceConfig.logoLinkUrl ? (
                <a
                  href={marketplaceConfig.logoLinkUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {marketplaceConfig.logo}
                </a>
              ) : (
                <>{marketplaceConfig.logo}</>
              )}
            </Grid>
            {transaction.status === CONNECTION_INIT && (
              <Grid container direction="column" alignItems="center">
                <Grid
                  container
                  item
                  xs={11}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={10}>
                    <Typography variant="body1" align="center">
                      {marketplaceConfig.messages.title}
                    </Typography>
                  </Grid>
                </Grid>
                <GenericAdvertisingConnectPageAuthForm
                  {...{
                    marketplaceConfig,
                    input1,
                    input2,
                    setTransaction,
                    setInput1,
                    setInput2,
                  }}
                />
              </Grid>
            )}
            {transaction.status === CONNECTION_FAILED && (
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <Icon>
                      <img src={crossLogo} />
                    </Icon>
                  </Grid>
                  <Grid item>
                    <Typography color="error">{transaction.errMsg}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography color="error" variant="body1" align="center">
                    {marketplaceConfig.messages.retryMessage}
                  </Typography>
                </Grid>
                <GenericAdvertisingConnectPageAuthForm
                  {...{
                    marketplaceConfig,
                    input1,
                    input2,
                    setTransaction,
                    setInput1,
                    setInput2,
                  }}
                />
              </Grid>
            )}
            {transaction.status === CONNECTION_SUCCEED && (
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Icon>
                      <img src={tickLogo} />
                    </Icon>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      {marketplaceConfig.messages.successMessage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default GenericStandaloneAdvertisingConnectPage;
