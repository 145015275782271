import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Cell, Row, StickyTable } from "react-sticky-table";
import React, { useCallback, useEffect, useState } from "react";
import {
  applyDirtyItems,
  createCSVData,
  currentValidationRules,
  downloadCSVData,
  getUnsyncedItemCount,
  getUpdatedItems,
  getUpdatedMatrix,
} from "~/utils/itemSpecificsUtils";
import {
  compareActualAndRecommended,
  itemSpecificTotals,
} from "@merchantspring/common";
import {
  createItemSpecificsFile,
  loadRecommendations,
  syncItemSpecifics,
} from "~/store/recommendations.redux";
import { useDispatch, useSelector } from "react-redux";

import ConfirmDialog from "~/components/dialogs/confirmDialog";
import DialogBox from "~/modules/itemSpecifics/itemSpecificDialog";
import Headings from "~/modules/itemSpecifics/specificsRecommendationsHeadings";
import LazyLoad from "react-lazy-load";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MessageDialog from "~/components/dialogs/messageDialog";
import Pagination from "~/components/table/tablePagination";
import { Prompt, useHistory } from "react-router-dom";
import SpecificsRecommendationsRow from "../../modules/itemSpecifics/specificsRecommendationsRow";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { switchLoadingData } from "~/store/globalVar.redux";
import unionBy from "lodash/unionBy";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/styles";

const Root = styled(Grid)`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 4rem;
`;

const TableWrapper = styled(Paper)`
  max-width: 100%;
  width: 100%;
`;

const TableFrame = styled(Grid)`
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  justify-content: left;
`;

const CutomStickyTable = styled(StickyTable)`
  min-width: 100%;
  height: 70vh;
  & > *:first-child: {
    min-width: 50%;
  }
`;

const HeaderCell = withTheme(styled(Cell)`
  background-color: ${({ theme }) => theme.palette.table.header};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  height: 45px;
  vertical-align: middle;
  color: ${({ theme }) => theme.palette.table.text.header};
  ${({ theme }) => `
        ${theme.breakpoints.down("xs")} {
          font-size: 0.75rem;
        }
     `}
`);

const StickyColumnCell = styled(HeaderCell)`
  display: table-cell;
  min-width: 300px;
  max-width: 300px;
  white-space: pre-wrap;
`;

const PAGE_SIZE = 30;

const RecommendationsPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => get(state, "user"));
  const recommendations = useSelector(
    (state) => get(state, "recommendations"),
    isEqual
  );
  const syncedItems = useSelector(
    (state) => get(state, "syncedItems"),
    isEqual
  );
  const syncedItemsError = useSelector((state) =>
    get(state, "syncedItems.error")
  );
  const globalLoadingData = useSelector((state) =>
    get(state, "globalVar.loadingData")
  );
  const query = new URLSearchParams(get(props, "location.search"));
  const categoryId = query.get("categoryId");
  const ebaySiteId = query.get("siteId");
  const mid = query.get("mid");
  const countryCode = query.get("countryCode");
  const requiredToggleInitVal = query.get("requiredToggle") === "true";

  const categoryRecommendations = (recommendations || []).find(
    (e) => e.categoryId === categoryId && e.siteId === ebaySiteId
  );
  const recommendedSpecifics = get(
    categoryRecommendations,
    "recommendedSpecifics"
  );
  const totalItemCount = get(categoryRecommendations, "totalItemCount", 0);

  const initValue = React.useMemo(
    () =>
      get(categoryRecommendations, "itemInfo", []).map((item) =>
        compareActualAndRecommended(item, recommendedSpecifics)
      ),
    [categoryRecommendations, recommendedSpecifics]
  );
  const categoryName = get(categoryRecommendations, "categoryName");
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const [compareResult, setCompareResult] = useState(initValue);
  const [requiredToggle, setRequiredToggle] = useState(requiredToggleInitVal);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmSync, setConfirmSync] = useState(false);
  const [nextLocation, setNextLocation] = useState({});
  const [displaySyncDialog, setDisplaySyncDialog] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [dirtyItems, setDirtyItems] = useState([]);
  const totalUnsyncedItems =
    dirtyItems.length + getUnsyncedItemCount(compareResult, initValue);

  const validationRules = currentValidationRules(requiredToggle);
  const correctPercentage = itemSpecificTotals(
    compareResult,
    validationRules
  ).correctPercentage;
  const invalidPercentage = itemSpecificTotals(
    compareResult,
    validationRules
  ).invalidPercentage;
  const missingPercentage = itemSpecificTotals(
    compareResult,
    validationRules
  ).missingPercentage;

  React.useEffect(() => {
    dispatch(
      loadRecommendations(
        "categoryId",
        mid,
        store.marketplaceCountry,
        categoryId,
        ebaySiteId,
        0,
        PAGE_SIZE
      )
    );
  }, []);

  React.useEffect(() => {
    setLoadingData(true);
    const { updatedItems, updatedDirtyItems } = applyDirtyItems(
      dirtyItems,
      initValue
    );
    setCompareResult(updatedItems);
    setDirtyItems(updatedDirtyItems);
    setLoadingData(false);
  }, [initValue]);

  const gotoPage = (pageNumArg) => {
    // this should get set to false once we setCompareResult in the useEffect
    // hook
    setLoadingData(true);
    setDirtyItems(
      unionBy(
        getUpdatedItems(compareResult, initValue),
        dirtyItems,
        (v) => v.itemId
      )
    );
    dispatch(
      loadRecommendations(
        "categoryId",
        mid,
        store.marketplaceCountry,
        categoryId,
        ebaySiteId,
        pageNumArg,
        PAGE_SIZE
      )
    );
    setPageNum(pageNumArg);
  };

  const previousPage = () => {
    if (pageNum > 0) {
      gotoPage(pageNum - 1);
    }
  };

  const nextPage = () => {
    if (pageNum < Math.ceil(totalItemCount / PAGE_SIZE) - 1) {
      gotoPage(pageNum + 1);
    }
  };

  const handleSyncToEbay = async (
    midSyncToEbay,
    categoryIdSyncToEbay,
    compareResultSyncToEbay
  ) => {
    if (
      !user ||
      !midSyncToEbay ||
      !categoryIdSyncToEbay ||
      !compareResultSyncToEbay instanceof Array
    ) {
      return;
    }

    // Only send updated items to eBay, not the entire list of items
    const items = createCSVData(
      unionBy(
        getUpdatedItems(compareResultSyncToEbay, initValue),
        dirtyItems,
        (v) => v.itemId
      )
    );
    const data = {
      categoryId: categoryIdSyncToEbay,
      items: items,
    };

    setLoadingData(true);
    setDialogOpen(false);

    try {
      dispatch(syncItemSpecifics(user._id, midSyncToEbay, countryCode, data));
      setErrMsg(syncedItemsError);
      setDisplaySyncDialog(true);
    } catch (e) {
      setErrMsg(e);
      setLoadingData(false);
      return;
    }

    // reload recommendations if all the items were successfully synced
    try {
      await dispatch(
        loadRecommendations(
          "categoryId",
          midSyncToEbay,
          store.marketplaceCountry,
          categoryIdSyncToEbay,
          ebaySiteId,
          pageNum,
          PAGE_SIZE
        )
      );
      setDirtyItems([]);

      setErrMsg("");
      setLoadingData(false);
    } catch (e) {
      setErrMsg(e);
      setLoadingData(false);
    }
  };

  const handleCreateCSVFile = useCallback(() => {
    const items = createCSVData(
      unionBy(
        getUpdatedItems(compareResult, initValue),
        dirtyItems,
        (v) => v.itemId
      )
    );

    dispatch(
      createItemSpecificsFile(
        mid,
        store.marketplaceCountry,
        {
          categoryId,
          ebaySiteId,
          categoryName,
        },
        items
      )
    ).then((_csvFileName) => {
      setDialogOpen(false);
      downloadCSVData(_csvFileName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareResult]);

  const handleBlockedNavigation = (_nextLocation) => {
    if (!dialogOpen) {
      setDialogOpen(true);
      setNextLocation(_nextLocation);
      return false;
    } else {
      return true;
    }
  };

  const handleSelectChange = useCallback(
    (e, itemId, name) => {
      const value = e.target.value;

      const updatedCompareResult = getUpdatedMatrix(
        compareResult,
        itemId,
        name,
        {
          selectedValue: value instanceof Array ? value : [value],
          flag: "correct",
        }
      );

      setCompareResult(updatedCompareResult);
    },
    [compareResult]
  );

  const headers = get(compareResult[0], "compareDetails", [])
    .filter((value) => validationRules.includes(value.validationRules))
    .map((v) => v.name);

  const eventHandler = useCallback(
    (ev) => {
      if (totalUnsyncedItems) {
        // this message is no longer displayed on most modern browsers
        // for security reasons, but left here as a fallback
        ev.returnValue = t("myStoresWidget.itemSpecifics.leavePageMessage", {
          count: totalUnsyncedItems,
        });

        return ev.returnValue;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalUnsyncedItems]
  );

  useEffect(() => {
    dispatch(switchLoadingData(false));
    window.addEventListener("beforeunload", eventHandler);
    return () => {
      window.removeEventListener("beforeunload", eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHandler]);

  if (loadingData || globalLoadingData) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Root container>
        <Grid item xs={12}>
          <Headings
            categoryName={categoryName}
            correctPercentage={correctPercentage}
            invalidPercentage={invalidPercentage}
            missingPercentage={missingPercentage}
            requiredToggle={requiredToggle}
            toggleSwitch={() => setRequiredToggle(!requiredToggle)}
            handleExportCSV={() =>
              handleCreateCSVFile(mid, categoryId, categoryName, compareResult)
            }
            syncToEbay={() => setConfirmSync(true)}
            isDemoMode={store.isDemoMode}
            totalUnsyncedItems={totalUnsyncedItems}
          />
        </Grid>

        <TableWrapper>
          <TableFrame item xs={12}>
            <LazyLoad width={"100%"}>
              <CutomStickyTable stickyHeaderCount={1}>
                <Row>
                  <StickyColumnCell>{`Items (${compareResult.length})`}</StickyColumnCell>
                  {headers.map((item, i) => (
                    <HeaderCell key={item + i}>{item}</HeaderCell>
                  ))}
                </Row>
                {compareResult.map((item, index) => {
                  const row = (
                    <SpecificsRecommendationsRow
                      index={index}
                      key={item.itemId}
                      item={item}
                      requiredToggle={requiredToggle}
                      countryCode={countryCode}
                      recommendedSpecifics={recommendedSpecifics}
                      onChange={handleSelectChange}
                    />
                  );
                  return row;
                })}
              </CutomStickyTable>
            </LazyLoad>
          </TableFrame>
          <Box p={1} borderTop={`2px solid #f5f5f5`}>
            <Pagination
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={pageNum > 0}
              canNextPage={pageNum < Math.ceil(totalItemCount / PAGE_SIZE) - 1}
              pageCount={Math.ceil(totalItemCount / PAGE_SIZE)}
              pageIndex={pageNum}
              pageOptions={{
                length: Math.ceil(totalItemCount / PAGE_SIZE),
              }}
            />
          </Box>
        </TableWrapper>

        <div>
          <Prompt
            when={totalUnsyncedItems > 0}
            message={handleBlockedNavigation}
          />
          <DialogBox
            open={dialogOpen}
            title={t("generic.warningTitle")}
            content={t("myStoresWidget.itemSpecifics.leavePageMessage", {
              count: totalUnsyncedItems,
            })}
            onClose={() => {
              setDialogOpen(false);
            }}
            handleContinue={() => {
              setDialogOpen(false);
              history.push(nextLocation);
            }}
            handleSyncEbay={() => {
              setConfirmSync(true);
              setDialogOpen(false);
            }}
            handleExportCSV={() =>
              handleCreateCSVFile(mid, categoryId, categoryName)
            }
          />
        </div>
      </Root>
      <div>
        <MessageDialog
          title={t("myStoresWidget.itemSpecifics.syncSuccessTitle")}
          open={displaySyncDialog}
          onClose={() => setDisplaySyncDialog(false)}
          messages={[
            t("myStoresWidget.itemSpecifics.syncSuccessMessage", {
              count: get(syncedItems, "successfulIds", []).length,
            }),
            t("myStoresWidget.itemSpecifics.syncFailedMessage", {
              count: get(syncedItems, "failedIds", []).length,
            }),
            ...get(syncedItems, "failedIds", []).map(
              (e) => `Item ID #${e.itemId}: ${errMsg}`
            ),
          ]}
        />
      </div>
      <ConfirmDialog
        title={t("generic.warningTitle")}
        open={confirmSync}
        onConfirm={() => {
          setConfirmSync(false);
          handleSyncToEbay(mid, categoryId, compareResult);
        }}
        onClose={() => setConfirmSync(false)}
        content={
          <Typography>
            {t("myStoresWidget.itemSpecifics.thirdPartyMessage")}
          </Typography>
        }
      />
    </>
  );
};

export default RecommendationsPage;
