import { GenericBuyboxState, GenericBuyboxTrend } from "~/typedef/store";

import { Dispatch } from "redux";
import { Range } from "~/typedef/store";
import axios from "axios";
import { baseUrl } from "~/configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

export const FETCH_GENERIC_BUYBOX_TREND = "FETCH_GENERIC_BUYBOX_TREND";
export const FETCH_GENERIC_BUYBOX_TREND_FETCHING =
  "FETCH_GENERIC_BUYBOX_TREND_FETCHING";

const initState: GenericBuyboxState = {
  history: {
    fetching: false,
  },
};

type FetchGenericBuyboxTrendFetchingAction = {
  type: typeof FETCH_GENERIC_BUYBOX_TREND_FETCHING;
};
type FetchGenericBuyboxTrendAction = {
  type: typeof FETCH_GENERIC_BUYBOX_TREND;
  payload: {
    data: GenericBuyboxTrend[];
    params: FetchGenericBuyboxTrendArgs;
  };
};

type GenericBuyboxAction =
  | FetchGenericBuyboxTrendFetchingAction
  | FetchGenericBuyboxTrendAction;

export const genericBuybox = (
  state: GenericBuyboxState = initState,
  action: GenericBuyboxAction
): GenericBuyboxState => {
  switch (action.type) {
    case FETCH_GENERIC_BUYBOX_TREND_FETCHING:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: true,
        },
      };
    case FETCH_GENERIC_BUYBOX_TREND:
      return {
        ...state,
        history: {
          ...action.payload,
          fetching: false,
        },
      };
    default:
      return state;
  }
};

export interface FetchGenericBuyboxTrendArgs {
  mid: string;
  currentRange: Range;
}

export const fetchGenericBuyboxTrend =
  (
    params: FetchGenericBuyboxTrendArgs,
    oldParams?: FetchGenericBuyboxTrendArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_GENERIC_BUYBOX_TREND_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/genericBuybox/genericBuyboxTrend`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_GENERIC_BUYBOX_TREND,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status, "genericBuyboxTrend");
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status"),
          "genericBuyboxTrend"
        );
      }
    }
  };
