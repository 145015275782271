import React, { useEffect, useRef, useState } from "react";
import {
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from "@material-ui/core";

import styled from "styled-components";

/** @returns the default font size of the total sales heading in pixels. */
export const defaultFontSizePixels = (
  theme: Theme,
  customSize?: number
): number => {
  if (customSize) {
    return customSize;
  }
  if (theme.breakpoints.down("lg")) {
    return 26;
  }
  if (theme.breakpoints.down("md")) {
    return 24;
  }
  if (theme.breakpoints.down("sm")) {
    return 22;
  }
  return 30;
};

interface LargeNumberTypographyProps {
  readonly fontSize: number;
}

const LargeNumberTypography = styled(Typography)<LargeNumberTypographyProps>`
  ${({ fontSize }) => `
      font-size: ${fontSize}px;
      line-height: 1;
    `}
`;

interface LargeNumberProps extends TypographyProps {
  value: any;
  headingContainerRef: React.RefObject<HTMLDivElement>;
  customSize?: number;
}

const LargeNumber: React.FC<LargeNumberProps> = ({
  value,
  headingContainerRef,
  customSize,
  variant,
  ...props
}) => {
  const theme = useTheme();
  /** If it is a large number, our default font size can
    overflow the container.
    Recalculate the font size dynamically to prevent this from happening */
  const [salesFontSize, setSalesFontSize] = useState<number>(
    defaultFontSizePixels(theme, customSize)
  );
  const headingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headingRef?.current && headingContainerRef?.current) {
      if (
        headingRef.current.offsetWidth > headingContainerRef.current.offsetWidth
      ) {
        setSalesFontSize(salesFontSize - 4);
      } else if (
        /* If the container is much larger than the number, e.g. due to
          a time period change or a screen resize, reset the font size
          to default */
        headingContainerRef.current.offsetWidth -
          headingRef.current.offsetWidth >
        50
      ) {
        setSalesFontSize(defaultFontSizePixels(theme));
      }
    }
  });

  return (
    <LargeNumberTypography
      {...props}
      ref={headingRef}
      variant={variant ?? "h1"}
      fontSize={salesFontSize}
    >
      {value}
    </LargeNumberTypography>
  );
};

export default LargeNumber;
