import { Cell, Pie, PieChart } from "recharts";
import React, { memo } from "react";

import { useTheme } from "styled-components";

const RADIAN = Math.PI / 180;
type ChartData = { value: number; name?: string; color: string };
interface NeedleProps {
  data: ChartData[];
  cx: number;
  cy: number;
  iR: number;
  oR: number;
  value: number;
  color: string;
}
const needle = ({ value, data, cx, cy, iR, oR, color }: NeedleProps) => {
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;
  return [
    <circle key="circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      key="path"
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
      data-testid="needle"
    />,
  ];
};
interface PieChartWithNeedleProps {
  data: ChartData[];
  circleX?: number;
  circleY?: number;
  innerRadius?: number;
  outerRadius?: number;
  value: number;
  needleColor?: string;
  width?: number;
  height?: number;
}
interface renderCustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
}
const PieChartWithNeedle = memo<PieChartWithNeedleProps>(
  function PieChartWithNeedle({
    value,
    data,
    circleX = 0,
    circleY = 0,
    innerRadius = 60,
    outerRadius = 100,
    needleColor = "#FFB446",
    width = 210,
    height = 175,
  }) {
    const theme = useTheme();
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      index,
    }: renderCustomizedLabelProps) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const getY = (index: number) => {
        if (index === 0) {
          return y + (180 - Math.abs(midAngle) + 25);
        } else if (index === data.length - 1) {
          return y + Math.abs(midAngle) + 25;
        }
        return y - 35;
      };
      return data[index].name ? (
        <text
          x={x}
          y={getY(index)}
          fill={theme.palette.text.primary}
          textAnchor={"middle"}
          dominantBaseline="central"
        >
          {data[index].name}
        </text>
      ) : null;
    };
    return (
      <PieChart width={width} height={height}>
        <Pie
          dataKey="value"
          data-testid="pie-chart-with-needle"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={circleX}
          cy={circleY}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} data-testid="cell" />
          ))}
        </Pie>
        {needle({
          value,
          data,
          cx: circleX,
          cy: circleY,
          iR: innerRadius,
          oR: outerRadius,
          color: needleColor,
        })}
      </PieChart>
    );
  }
);
export default PieChartWithNeedle;
