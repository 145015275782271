import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { FORBIDDEN } from "http-status-codes";
import { api } from "../apiSlice";
import axios from "axios";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

axios.defaults.withCredentials = true;

export const connectWalmart =
  (clientId: string, clientSecret: string, shopName: string) => () =>
    new Promise(async (resolve, reject) => {
      try {
        const options = {
          method: "POST",
          url: `${baseUrl}/auth/walmart/connect`,
          data: { clientId, clientSecret, shopName },
        };
        const res = await axios(options);

        if (
          res &&
          isHttpResponseValid(res.status) &&
          res.data &&
          res.data.code === RESPONSE_CODE.SUCCESS
        ) {
          resolve("succeed");
        } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
          reject("tokenInValid");
        } else {
          reject("failed");
        }
      } catch (err: any) {
        if (err.response && err.response.status === FORBIDDEN) {
          reject("forbidden");
        } else {
          reject("error");
        }
      }
    });

export const connectWalmartAdvertising =
  (advertiserId: string, mid: string) => () =>
    new Promise(async (resolve, reject) => {
      try {
        const options = {
          method: "POST",
          url: `${baseUrl}/auth/walmart/advertising/connect`,
          data: { advertiserId, mid },
        };
        const res = await axios(options);
        if (
          res &&
          isHttpResponseValid(res.status) &&
          res.data &&
          res.data.code === RESPONSE_CODE.SUCCESS
        ) {
          resolve({ ...res.data });
        } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
          reject("tokenInValid");
        } else {
          reject("failed");
        }
      } catch (err: any) {
        if (err.response && err.response.status === FORBIDDEN) {
          reject("forbidden");
        } else {
          reject("error");
        }
      }
    });

type ConnectWalmartRequestArgs = {
  clientId: string;
  clientSecret: string;
  shopName: string;
  countryCode: string;
};

type ConnectWalmartResponse = {
  status: string;
};

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    walmartConnect: build.mutation<
      ConnectWalmartResponse,
      ConnectWalmartRequestArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/walmart/connect`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("walmartConnect", true),
    }),
  }),
});

export const { useWalmartConnectMutation } = extendedApiSlice;
