import { CircularProgress, Typography } from "@material-ui/core";

import PropType from "prop-types";
import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";

export const EVENT_ORIGIN_PATTERN =
  process.env.TARGET_ENV === "production" ||
  process.env.TARGET_ENV === "staging"
    ? /mm.*\.merchantspring\.io/
    : /.*/;

const Container = styled.div`
  text-align: center;
`;

function AuthCallbackPage(props) {
  // eslint-disable-next-line no-magic-numbers
  const { communicationTimeout = 15000 } = props;
  const { authProvider } = useParams();
  const queryParams = useQueryParams();
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(
        {
          authProvider,
          params: Object.fromEntries(queryParams),
        },
        "*" // parent window can be from any of our custom domains
      );
      setTimeout(() => {
        setError("Unable to communicate with the original browser window.");
      }, communicationTimeout);
    } else {
      setError("The original browser window has been closed unexpectedly.");
    }
  }, []);
  return (
    <Container>
      {error ? (
        <>
          <Typography variant="h1">
            {
              "Sorry, something didn't work quite right. Please close this window and try again."
            }
          </Typography>
          <Typography color="error">{error}</Typography>
        </>
      ) : (
        <>
          <Typography variant="h1">
            {"We're processing your login. Hang tight!"}
          </Typography>
          <CircularProgress />
        </>
      )}
    </Container>
  );
}

AuthCallbackPage.propTypes = {
  communicationTimeout: PropType.number,
};

export default AuthCallbackPage;
