import axios, { AxiosError } from "axios";

import { ConnectionStatusState } from "~/typedef/store";
import { Dispatch } from "redux";
import { baseUrl } from "../../configs";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

const FETCH_CONNECTION_STATUS_SINGLE = "FETCH_CONNECTION_STATUS_SINGLE_V2";
const FETCH_CONNECTION_STATUS_SINGLE_FETCHING =
  "FETCH_CONNECTION_STATUS_SINGLE_FETCHING_V2";

const DELETE_CONNECTION_STATUS = "DELETE_CONNECTION_STATUS_V2";

type DeleteConnectionStatusAction = {
  type: typeof DELETE_CONNECTION_STATUS;
  payload: {
    marketplaceType: string;
    marketplaceSubtype: string;
    countryCode: string;
    mid: string;
  };
};

type FetchConnectionStatusSingleFetchingAction = {
  type: typeof FETCH_CONNECTION_STATUS_SINGLE_FETCHING;
};

type FetchConnectionStatusSingleAction = {
  type: typeof FETCH_CONNECTION_STATUS_SINGLE;
  payload: {
    marketplaceType: string;
    marketplaceSubtype: string;
    countryCode: string;
    mid: string;
    progress: number;
  };
};

type ConnectionStatusAction =
  | FetchConnectionStatusSingleFetchingAction
  | FetchConnectionStatusSingleAction
  | DeleteConnectionStatusAction;

const initState: ConnectionStatusState = {
  connectionStatuses: [],
  fetching: false,
};

export const connectionStatus = (
  state = initState,
  action: ConnectionStatusAction
): ConnectionStatusState => {
  switch (action.type) {
    case FETCH_CONNECTION_STATUS_SINGLE_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_CONNECTION_STATUS_SINGLE:
      if (!action.payload.mid) {
        // expected behaviour - no active sync job for the store
        return { ...state, fetching: false };
      }

      const idx = state.connectionStatuses.findIndex(
        (s) =>
          s.marketplaceType === action.payload.marketplaceType &&
          s.marketplaceSubtype === action.payload.marketplaceSubtype &&
          s.countryCode === action.payload.countryCode &&
          s.mid === action.payload.mid
      );

      const csToExtend = [...state.connectionStatuses];

      if (idx > -1) {
        csToExtend[idx].progress = action.payload.progress;
      } else {
        csToExtend.push(action.payload);
      }

      return {
        ...state,
        fetching: false,
        connectionStatuses: csToExtend,
      };
    case DELETE_CONNECTION_STATUS:
      const csToFilter = [...state.connectionStatuses];
      const filteredConnectionStatus = csToFilter.filter(
        (cs) =>
          !(
            cs.marketplaceType === action.payload.marketplaceType &&
            cs.marketplaceSubtype === action.payload.marketplaceSubtype &&
            cs.countryCode === action.payload.countryCode &&
            cs.mid === action.payload.mid
          )
      );
      return {
        ...state,
        connectionStatuses: filteredConnectionStatus,
      };

    default:
      return state;
  }
};

export const deleteConnectionStatus =
  (
    marketplaceType: string,
    marketplaceSubtype: string,
    countryCode: string,
    mid: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/connectionV2/deleteStatus`,
        {
          marketplaceType,
          marketplaceSubtype,
          countryCode,
          mid,
        }
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: DELETE_CONNECTION_STATUS,
          payload: {
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            mid,
          },
        });
      } else {
        return setError(dispatch, data, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        JSON.stringify((err as AxiosError).response?.data),
        (err as AxiosError).response?.status
      );
    }
  };

export const fetchConnectionStatusSingle =
  (
    marketplaceType: string,
    marketplaceSubtype: string,
    countryCode: string,
    mid: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CONNECTION_STATUS_SINGLE_FETCHING });
    try {
      const { status, data } = await axios.get(
        `${baseUrl}/api/connectionV2/status`,
        {
          params: {
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            mid,
          },
        }
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: FETCH_CONNECTION_STATUS_SINGLE,
          payload: {
            ...data,
          },
        });
      } else {
        return setError(dispatch, data, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        JSON.stringify((err as AxiosError).response?.data),
        (err as AxiosError).response?.status
      );
    }
  };
