import React, { memo } from "react";

import { HealthStatus } from "~/utils/accountHealthUtils";
import Medium from "../../typography/medium";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTheme } from "@material-ui/core";

const Cell = withTheme(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`);

const Box = withTheme(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  padding: 2px;
  border: ${({ theme, status }) => `1px solid ${theme.palette[status].main}`};
  color: ${({ theme, status }) => theme.palette[status].main};
`);

export const SimpleAvailabilityCell = memo(({ cell }) => {
  const value = cell.value;
  const noData = value === "-";

  function getStatus() {
    if (noData) {
      return HealthStatus.NoStatus;
    }
    const numberValue = parseInt(value);
    if (numberValue < 5) {
      return HealthStatus.Poor;
    }
    if (numberValue < 20) {
      return HealthStatus.AtRisk;
    }
    return HealthStatus.Good;
  }

  return (
    <Cell>
      <Box status={getStatus()}>
        <Medium variant="body2" noWrap color="inherit">
          {value}
        </Medium>
      </Box>
    </Cell>
  );
});

SimpleAvailabilityCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};
