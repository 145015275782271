import { Grid, Typography } from "@material-ui/core";

import { ChartDataDefinition } from "./multiComposedChart";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const LegendIndicator = styled.div<{
  shape: string;
  fill: string;
}>`
  height: ${({ shape }) => (shape === "line" ? "3px" : "15px")};
  width: 15px;
  margin-right: 10px;
  background-color: ${({ fill }) => fill};
`;

export const LegendWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-bottom: 10px;
`;

interface MutltiChartLegendProps {
  legendItems: ChartDataDefinition[];
}

const MutltiChartLegend: React.FC<MutltiChartLegendProps> = ({
  legendItems,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column">
      {legendItems.map((item, i) => (
        <LegendWrapper item key={`${item}-${i}`}>
          <LegendIndicator shape={item.type} fill={item.color} />
          <Typography variant="body2" color="textSecondary">
            {t(`chartLegend.${item.dataKey}`)}
          </Typography>
        </LegendWrapper>
      ))}
    </Grid>
  );
};

export default MutltiChartLegend;
