import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { CurrentStore } from "~/typedef/store";
import DownloadCsv from "../reportDownload/downloadCsv";
import SalesByIntervalWidget from "../widgets/salesByInterval";
import { get } from "lodash";
import moment from "moment";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByIntervalWidgetProps {
  store?: CurrentStore;
  condensed?: boolean;
}

const SalesByInterval = memo<SalesByIntervalWidgetProps>(
  function SalesByInterval({ store, condensed }) {
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      // In case of multi-channel, generic returns the data in AUD (DESTINATION_CURRENCY)
      store?.marketplaceCountry
        ? getCurrencyByCountryCode[store.marketplaceCountry]
        : "AUD",
      currentCurrency
    );

    const actions = (
      <DownloadCsv
        reportType="salesPerformance"
        path="/api/generic/salesPerformance"
        mid={store?.merchantId || "all"}
        params={{
          mid: store?.merchantId || "all",
          filter: currentFilter,
          currentRange,
          includeTax,
          currency: currentCurrency,
          exchangeRate,
          includeVendor: true,
        }}
      />
    );

    return (
      <SalesByIntervalWidget
        mid={store?.merchantId}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        currentFilter={currentFilter}
        condensed={condensed}
        timezone={selectedTimezone}
        includeTax={includeTax}
        actions={actions}
      />
    );
  }
);

export default SalesByInterval;
