/** A toast message which floats in the top right of the screen on desktop
 * and across the top of the screen on mobile */
import AlertCard from "./alertCard";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import PropTypes from "prop-types";
import React from "react";
import WarningIcon from "../../icons/warningIcon.tsx";
import styled from "styled-components";

const StyledAlertCard = styled(AlertCard)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  ${({ theme }) => `${theme.breakpoints.down("sm")} {
    width: calc(100% - 1rem);
    left: 0;
    padding: 0.5rem;
  }`}
  ${({ theme }) => `${theme.breakpoints.up("md")} {
    right: 2rem;
    padding-top: 1rem;
    max-width: 35%;
    min-width: 20%;
  }`}
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => `${theme.breakpoints.up("md")} {
    min-height: 4.5rem;
  }`}
`;

const CardContent = styled.div`
  padding: 0 1rem;
  text-align: left;
`;

const SuccessIcon = styled(CheckCircleRoundedIcon)`
  background-color: ${({ theme }) => theme.palette.success.main};
  height: 48px;
  width: 48px;
`;
const StyledWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.warning.light};
  height: 48px;
  width: 48px;
`;

const DefaultIcon = styled(WarningIcon)`
  height: 48px;
  width: 48px;
`;

const FloatingAlertCard = ({ isOpen, onClose, type, children, ...others }) => {
  let icon;
  switch (type) {
    case "success":
      icon = <SuccessIcon />;
      break;
    case "warning":
      icon = <StyledWarningIcon />;
      break;
    default:
      icon = <DefaultIcon />;
  }
  return (
    <StyledAlertCard isOpen={isOpen} onClose={onClose} type={type} {...others}>
      <CardContainer>
        {icon}
        <CardContent>{children}</CardContent>
      </CardContainer>
    </StyledAlertCard>
  );
};
FloatingAlertCard.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    "success",
    "info",
    "disabled",
    "noData",
    "warning",
    "error",
  ]),
};
export default FloatingAlertCard;
