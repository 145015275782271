import {
  AdvertisingType,
  SuccessDirection,
  getStatus,
} from "../advertising/performanceSummary";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  Cell,
  GrowthBlock,
  ValueAndGrowthCell,
} from "~/components/table/cells/valueAndGrowthCell";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { OverviewMarketing, Store } from "@typedef/store";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  clearMarketingOverviewExpanded,
  fetchMarketingOverviewExpanded,
} from "@store/overview/marketing.redux";
import {
  formatCurrency,
  formatCurrencyRounded,
  intFormatterRounded,
} from "@utils/currencyUtils";
import { get, isEmpty } from "lodash";
import { getShopName, marketplaceLink } from "@utils/marketplaceUtils";
import {
  useReportDateFrom,
  useReportDateTo,
} from "../../../modules/marketing/useReportDate";

import CampaignSummaryChart from "./campaignSummaryChart";
import CampaignSummaryTotals from "./campaignSummaryTotals";
import ColumnSelect from "../../../components/adTable/columnSelect";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkCell } from "@components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "@components/table/cells/marketplaceAndCountryCell";
import { OverviewToolbar } from "../../../components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "../../../components/containers/pageBlock";
import Panel from "../../../components/panel/panel";
import SearchFilter from "../../../components/adTable/searchFilter";
import StatusText from "~/components/typography/status";
import Table from "../../../components/adTable/table";
import { ValueCell } from "@components/table/cells/valueCell";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment-timezone";
import { useAdvertisingOnly } from "~/hooks/useAdvertisingOnly";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 10;
const DEFAULT_SORT = "sales";
type MarketingRow = OverviewMarketing["summaryExpanded"]["rows"][number];

const AdvertisingMarketplaces = [
  "amazon",
  "amazon_vendor",
  "mercadolibre",
  "advertising",
];

export const AcosRoasTacosTroasCell = memo((props: any) => {
  const { cell } = props;
  const value: string = cell.value.value;
  const growth: number = Number(cell.value.growth);
  const conditionalFormatting: boolean | undefined =
    cell.value.conditionalFormatting;

  const type: string | undefined = cell.value.type;
  const successDirection: SuccessDirection = cell.value.successDirection;

  let arrow: React.ReactElement, status: undefined | "success" | "error";
  if (growth >= 0) {
    status = getStatus(
      successDirection,
      SuccessDirection.ASCENDING,
      conditionalFormatting
    );
    arrow = <UpArrow fontSize="inherit" status={status} />;
  } else {
    status = getStatus(
      successDirection,
      SuccessDirection.DESCENDING,
      conditionalFormatting
    );
    arrow = <DownArrow fontSize="inherit" status={status} />;
  }

  return (
    <Cell>
      <Typography variant="body2">{value}</Typography>
      {!isNaN(growth) && (
        <GrowthBlock>
          <StatusText
            variant="body2"
            align="right"
            paragraph={false}
            status={status}
          >
            {arrow}
            {isNaN(growth) ? growth : Math.abs(growth).toFixed(1)}
            {!isNaN(growth) &&
              (type === AdvertisingType.ACOS ||
                type === AdvertisingType.TACOS) &&
              "ppt"}
          </StatusText>
        </GrowthBlock>
      )}
    </Cell>
  );
});

const MarketingOverview = memo(function MarketingOverview() {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);
  const filteredStores: Store[] = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );

  const marketingOverviewExpanded = useTypedSelector((state) =>
    get(state, "overview.marketing.summaryExpanded")
  );

  const allowedLinks = useTypedSelector(
    (state) => state.customLayout?.layoutConfig?.sideNav?.pages
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const isAdvertisingOnly = useAdvertisingOnly();

  const containsComparision = get(
    marketingOverviewExpanded,
    "campaigns.containsComparisons",
    false
  );

  const dispatch = useDispatch();
  const reportDateFrom = useReportDateFrom();
  const reportDateTo = useReportDateTo();

  const [searchText, setSearchText] = useState("");

  const dispatchFetchMarketingOverviewExpanded = useCallback(
    ({ pageIndex, pageSize, sortBy, format }) => {
      if (pageIndex === 0) {
        dispatch(clearMarketingOverviewExpanded());
      }
      dispatch(
        fetchMarketingOverviewExpanded({
          user: userInfo,
          reportDateFrom,
          reportDateTo,
          currentRange,
          pageIndex,
          pageSize,
          sortKey: sortBy && sortBy.length ? sortBy[0].id : DEFAULT_SORT,
          sortOrder:
            sortBy && sortBy.length
              ? sortBy[0].desc
                ? "desc"
                : "asc"
              : "desc",
          searchText,
          filter: currentFilter,
          format,
          timezone: selectedTimezone,
          includeTax,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userInfo._id,
      includeTax,
      searchText,
      currentFilter,
      reportDateFrom,
      reportDateTo,
    ]
  );

  useEffect(() => {
    if (marketingOverviewExpanded && !containsComparision) {
      const { count, loading, params } = marketingOverviewExpanded;
      if (params) {
        const { pageIndex, sortKey, sortOrder } = params;
        if (!loading) {
          if (count > PAGE_SIZE * pageIndex) {
            dispatchFetchMarketingOverviewExpanded({
              ...params,
              sortBy: [{ id: sortKey, desc: sortOrder === "desc" }],
              pageSize: PAGE_SIZE,
              pageIndex: pageIndex,
            });
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (marketingOverviewExpanded) {
      const { count, loading, params } = marketingOverviewExpanded;
      if (params) {
        const { pageIndex, sortKey, sortOrder } = params;
        if (!loading) {
          if (count > PAGE_SIZE * (pageIndex + 1)) {
            dispatchFetchMarketingOverviewExpanded({
              ...params,
              sortBy: [{ id: sortKey, desc: sortOrder === "desc" }],
              pageSize: PAGE_SIZE,
              pageIndex: pageIndex + 1,
            });
          }
        }
      }
    }
  }, [dispatchFetchMarketingOverviewExpanded, marketingOverviewExpanded, get(marketingOverviewExpanded, "loading")]);

  const data = useMemo(() => {
    return get(marketingOverviewExpanded, "rows", []).map((row: any) => {
      const { prior, current } = row;
      const valueToUse = current ? current : row;
      const market = valueToUse.marketplace;
      const marketplaceSubtype = valueToUse.marketplaceSubtype;
      const shopName = getShopName(filteredStores, market, valueToUse.mid);
      const homeCurrency = getCurrencyByCountryCode[valueToUse.countryCode];
      return {
        renderCustomRow: valueToUse.cost === undefined,
        marketplace: valueToUse.marketplace,
        marketplaceSubtype,
        seller_id: valueToUse.seller_id,
        countryCode: valueToUse.countryCode,
        shopName: shopName,
        mid: valueToUse.mid,
        sales: {
          value:
            current && current.sales
              ? formatCurrencyRounded(
                  current.sales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          growth: prior
            ? getPercentageDifference(current.sales, prior.sales)
            : "-",
        },
        cost: {
          value:
            current && current.cost
              ? formatCurrencyRounded(
                  current.cost,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          growth: prior
            ? getPercentageDifference(current.cost, prior.cost)
            : "-",
        },
        acos: {
          raw: current?.acos || "-",
          value: current?.acos ? `${current.acos.toFixed(1)}%` : "-",
          type: AdvertisingType.ACOS,
          growth: prior ? (current.acos - prior.acos).toFixed(1) : "-",
          successDirection: SuccessDirection.DESCENDING,
        },
        roas: {
          raw: current?.roas || "-",
          value: current?.roas ? `${current.roas.toFixed(1)}` : "-",
          type: AdvertisingType.ROAS,
          growth: prior ? (current.roas - prior.roas).toFixed(1) : "-",
          successDirection: SuccessDirection.ASCENDING,
        },
        tacos: {
          raw: current?.tacos || "-",
          value: current?.tacos ? `${current.tacos.toFixed(1)}%` : "-",
          type: AdvertisingType.TACOS,
          growth: prior?.tacos ? (current.tacos - prior.tacos).toFixed(1) : "-",
          successDirection: SuccessDirection.DESCENDING,
        },
        troas: {
          raw: current?.tacos ? (100 / current.tacos).toFixed(1) : "-",
          value: current?.tacos ? `${(100 / current.tacos).toFixed(1)}` : "-",
          type: AdvertisingType.TROAS,
          growth: prior?.tacos
            ? (100 / current.tacos - 100 / prior.tacos).toFixed(1)
            : "-",
          successDirection: SuccessDirection.ASCENDING,
        },
        impressions: current && current.impressions,
        clicks: current && current.clicks,
        click_through_rate: current && current.click_through_rate,
        cost_per_click: current && current.cost_per_click,
        conversion_rate: current && current.conversion_rate,
        attributed_conversions: current && current.attributed_conversions,
        attributed_units_ordered: current && current.attributed_units_ordered,
        attributed_sales_ntb: current && current.attributed_sales_ntb,
        attributed_orders_ntb: current && current.attributed_orders_ntb,
        attributed_units_ordered_ntb:
          current && current.attributed_units_ordered_ntb,
        attributed_orders_ntb_percentage:
          current && current.attributed_orders_ntb_percentage,
        attributed_sales_ntb_percentage:
          current && current.attributed_sales_ntb_percentage,
        attributed_units_ordered_ntb_percentage:
          current && current.attributed_units_ordered_ntb_percentage,
      };
    });
  }, [marketingOverviewExpanded, currencyRates, currentCurrency]);

  const [myColumns, setMyColumns] = useState([] as any[]);
  const columns = useMemo(
    () => [
      {
        Header: t("dashboardWidget.marketplaceColumn"),
        id: "market",
        accessor: (row: MarketingRow): any => ({
          market: row.marketplace,
          marketplaceSubtype: row.marketplaceSubtype,
          countryCode: row.countryCode,
        }),
        isVisible: myColumns.find((column) => column.id === "market") ?? true,
        Cell: MarketplaceAndCountryCell,
        disableSortBy: true,
        sticky: "left",
        // used by react-table-sticky; must be in sync with customWidth
        // if sticky and customWidth are set
        width: 90,
        customWidth: 90,
      },
      {
        id: "shopName",
        Header: t("dashboardWidget.storeColumn"),
        accessor: (row: MarketingRow): any => ({
          value: row.shopName,
          link: marketplaceLink(
            row.marketplace,
            row.mid,
            "marketingOverview",
            undefined,
            allowedLinks
          ),
        }),
        isVisible: myColumns.find((column) => column.id === "shopName") ?? true,
        Cell: LinkCell,
        disableSortBy: true,
        sticky: "left",
      },
      {
        Header: t("advertisingDashboardWidget.adTable.salesColumn"),
        id: "sales",
        accessor: "sales",
        align: "center",
        Cell: ValueAndGrowthCell,
        isVisible: myColumns.find((column) => column.id === "sales") ?? true,
        sortDescFirst: true,
      },
      ...(!onMobile
        ? [
            {
              Header: t("advertisingDashboardWidget.adTable.spendColumn"),
              id: "cost",
              accessor: "cost",
              align: "center",
              Cell: ValueAndGrowthCell,
              isVisible:
                myColumns.find((column) => column.id === "cost") ?? true,
              sortDescFirst: true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.acosColumn"),
              id: "acos",
              accessor: "acos",
              align: "center",
              Cell: AcosRoasTacosTroasCell,
              isVisible:
                myColumns.find((column) => column.id === "acos") ?? true,
              sortDescFirst: true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.roasColumn"),
              id: "roas",
              accessor: "roas",
              align: "center",
              Cell: AcosRoasTacosTroasCell,
              isVisible:
                myColumns.find((column) => column.id === "roas") ?? true,
              sortDescFirst: true,
            },
            ...(!isAdvertisingOnly
              ? [
                  {
                    Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
                    id: "tacos",
                    accessor: "tacos",
                    align: "center",
                    Cell: AcosRoasTacosTroasCell,
                    isVisible:
                      myColumns.find((column) => column.id === "tacos") ?? true,
                    disableSortBy: true,
                  },
                  {
                    Header: t("advertisingDashboardWidget.adTable.troasColumn"),
                    id: "troas",
                    accessor: "troas",
                    align: "center",
                    Cell: AcosRoasTacosTroasCell,
                    isVisible:
                      myColumns.find((column) => column.id === "troas") ?? true,
                    disableSortBy: true,
                  },
                ]
              : []),
            {
              Header: t("advertisingDashboardWidget.adTable.impressionsColumn"),
              id: "impressions",
              accessor: (row: MarketingRow) =>
                row.impressions
                  ? intFormatterRounded.format(row.impressions)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find((column) => column.id === "impressions") ?? true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.clicksColumn"),
              id: "clicks",
              accessor: (row: MarketingRow) =>
                row.clicks ? intFormatterRounded.format(row.clicks) : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find((column) => column.id === "clicks") ?? true,
            },
            {
              Header: t(
                "advertisingDashboardWidget.adTable.clickThroughRateColumn"
              ),
              id: "click_through_rate",
              accessor: (row: MarketingRow) =>
                row.click_through_rate
                  ? `${row.click_through_rate.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "click_through_rate"
                ) ?? true,
            },
            {
              Header: t(
                "advertisingDashboardWidget.adTable.costPerClickColumn"
              ),
              id: "cost_per_click",
              accessor: (row: MarketingRow) =>
                row.cost_per_click
                  ? formatCurrency(
                      row.cost_per_click,
                      currencyRates,
                      getCurrencyByCountryCode[row.countryCode],
                      currentCurrency
                    )
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find((column) => column.id === "cost_per_click") ??
                true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.conversionColumn"),
              id: "conversion_rate",
              accessor: (row: MarketingRow) =>
                row.conversion_rate
                  ? `${row.conversion_rate.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find((column) => column.id === "conversion_rate") ??
                true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.ordersColumn"),
              id: "attributed_conversions",
              accessor: (row: MarketingRow) =>
                row.attributed_conversions
                  ? intFormatterRounded.format(row.attributed_conversions)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_conversions"
                ) ?? true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.unitsColumn"),
              id: "attributed_units_ordered",
              accessor: (row: MarketingRow) =>
                row.attributed_units_ordered
                  ? intFormatterRounded.format(row.attributed_units_ordered)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_units_ordered"
                ) ?? false,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.ntbSalesColumn"),
              id: "attributed_sales_ntb",
              accessor: (row: MarketingRow) =>
                row.attributed_sales_ntb
                  ? formatCurrency(
                      row.attributed_sales_ntb,
                      currencyRates,
                      getCurrencyByCountryCode[row.countryCode],
                      currentCurrency
                    )
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_sales_ntb"
                ) ?? false,
            },
            {
              Header: t(
                "advertisingDashboardWidget.adTable.ntbSalesPercentageColumn"
              ),
              id: "attributed_sales_ntb_percentage",
              accessor: (row: MarketingRow) =>
                row.attributed_sales_ntb_percentage
                  ? `${row.attributed_sales_ntb_percentage.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_sales_ntb_percentage"
                ) ?? false,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.ntbOrdersColumn"),
              id: "attributed_orders_ntb",
              accessor: (row: MarketingRow) =>
                row.attributed_orders_ntb
                  ? intFormatterRounded.format(row.attributed_orders_ntb)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_orders_ntb"
                ) ?? false,
            },
            {
              Header: t(
                "advertisingDashboardWidget.adTable.ntbOrdersPercentageColumn"
              ),
              id: "attributed_orders_ntb_percentage",
              accessor: (row: MarketingRow) =>
                row.attributed_orders_ntb_percentage
                  ? `${row.attributed_orders_ntb_percentage.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_orders_ntb_percentage"
                ) ?? false,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.ntbUnitsColumn"),
              id: "attributed_units_ordered_ntb",
              accessor: (row: MarketingRow) =>
                row.attributed_units_ordered_ntb
                  ? intFormatterRounded.format(row.attributed_units_ordered_ntb)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) => column.id === "attributed_units_ordered_ntb"
                ) ?? false,
            },
            {
              Header: t(
                "advertisingDashboardWidget.adTable.ntbUnitsPercentageColumn"
              ),
              id: "attributed_units_ordered_ntb_percentage",
              accessor: (row: MarketingRow) =>
                row.attributed_units_ordered_ntb_percentage
                  ? `${row.attributed_units_ordered_ntb_percentage.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible:
                myColumns.find(
                  (column) =>
                    column.id === "attributed_units_ordered_ntb_percentage"
                ) ?? false,
            },
          ]
        : []),
    ],
    [currentCurrency, myColumns]
  );

  useEffect(() => setMyColumns(columns), [currentCurrency, isAdvertisingOnly]);

  /** Clear the redux store when navigating away so data is refreshed properly */
  useEffect(
    () => () => {
      dispatch(clearMarketingOverviewExpanded());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <PageBlock>
      <OverviewToolbar
        selectedItem={{
          currentPage: t("advertisingDashboardWidget.storePerformance"),
          breadcrumb: [],
        }}
      />{" "}
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} alignItems="center">
          <CampaignSummaryChart />
        </Grid>
        <Grid container item xs={12} md={4} alignItems="center">
          <CampaignSummaryTotals />
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Panel
            id="widget-marketing-overview-table"
            title={t("advertisingDashboardWidget.storePerformance")}
            tooltip={t(
              "advertisingDashboardWidget.campaignPerformanceExpandedTooltip"
            )}
            content={
              <>
                <Table
                  {...{
                    columns: myColumns,
                    data,
                    fetchData: dispatchFetchMarketingOverviewExpanded,
                    sorting: true,
                    loading:
                      get(marketingOverviewExpanded, "loading") &&
                      isEmpty(data),
                    pagination: true,
                    pageSize: PAGE_SIZE,
                    pageCount: Math.ceil(
                      get(marketingOverviewExpanded, "count") / PAGE_SIZE
                    ),
                    autoPagination: true,
                    currentPage:
                      (get(
                        marketingOverviewExpanded,
                        "params.pageIndex"
                      ) as number) + 1 || 1,
                  }}
                />
              </>
            }
            actions={
              <>
                <SearchFilter {...{ setSearchText }} />
                <ColumnSelect
                  {...{
                    columns: myColumns,
                    setColumns: setMyColumns,
                  }}
                />

                <DownloadCsv
                  {...{
                    reportType: "marketingOverview",
                    path: "/api/amazon/marketingOverview",
                    mid: "all",
                    params: {
                      reportDateFrom: reportDateFrom,
                      reportDateTo: reportDateTo,
                      ...currentRange,
                      sortKey: DEFAULT_SORT,
                      sortOrder: "desc",
                      searchText,
                      filter: currentFilter,
                      currentCurrency,
                      currencyRates,
                      stores: filteredStores
                        .filter((store) =>
                          AdvertisingMarketplaces.includes(
                            store.marketplaceSubtype
                          )
                        )
                        .map((store) => ({
                          storeId: store.storeId,
                          mid: store.merchantId,
                          countryCode: store.marketplaceCountry,
                          marketplaceType: store.marketplaceType,
                          marketplaceSubtype: store.marketplaceSubtype,
                          shopName: store.storeName,
                        })),
                      timezone: selectedTimezone,
                      includeTax,
                    },
                  }}
                />
              </>
            }
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default MarketingOverview;
