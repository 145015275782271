/* eslint-disable no-magic-numbers */
import {
  ALLOWED_BILLING_CYCLES,
  PlanPropTypes,
  getMonthlyCost,
  getPlanBlurb,
  getPlanName,
} from "./plan";
import { Grid, Typography } from "@material-ui/core";

import Bold from "~/components/typography/bold";
import Medium from "~/components/typography/medium";
import PlanButton from "./planButton";
import PropTypes from "prop-types";
import React from "react";
import { getCurrencySymbol } from "~/utils/currencyUtils.js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PlanSummaryContainer = styled(Grid).attrs(() => ({
  container: true,
  spacing: 2,
  direction: "column",
  alignItems: "center",
  justify: "center",
}))`
  // Material-UI's Grid implementation doesn't play well with borders
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  border-bottom: ${({ theme }) => `1px ${theme.palette.grey[200]} solid`};
  width: 100%;
  text-align: center;
`;

const PlanBlurbContainer = styled(Grid)`
  min-height: 4rem;
`;

const PlanSummary = (props) => {
  const { t } = useTranslation();
  const { plan, currentPlan, onSelect, billingCycle, newSubscriber } = props;
  // even if we're calculating cost by other cycles, present the result as monthly
  const presentationBillingCycle = "monthly";
  return (
    <PlanSummaryContainer>
      <Grid item xs={12}>
        <Bold id="summary-plan-name" variant="h3">
          {t(getPlanName(plan.planId))}
        </Bold>
      </Grid>
      {newSubscriber && getPlanBlurb(plan.planId) && (
        <PlanBlurbContainer item xs={12}>
          <Bold data-testid="summary-plan-blurb">
            {t(getPlanBlurb(plan.planId))}
          </Bold>
        </PlanBlurbContainer>
      )}
      <Grid item xs={12}>
        <Typography
          data-testid="summary-plan-cost"
          variant="h1"
          component="span"
        >
          {`${getCurrencySymbol[plan.currency]}${getMonthlyCost(plan).toFixed(
            0
          )}`}
        </Typography>
        <Bold component="span">
          {` /${t(
            ALLOWED_BILLING_CYCLES[presentationBillingCycle].shortLabel
          )}`}
        </Bold>
      </Grid>
      {billingCycle === "yearly" && (
        <Grid item xs={12}>
          <Typography>{t("subscription.plan.billedAnnuallyLabel")}</Typography>
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <PlanButton
          plan={plan}
          currentPlan={currentPlan}
          onSelect={onSelect}
          newSubscriber={newSubscriber}
        />
      </Grid>
    </PlanSummaryContainer>
  );
};
PlanSummary.propTypes = {
  plan: PlanPropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
  currentPlan: PlanPropTypes,
  billingCycle: PropTypes.oneOf(["monthly", "yearly"]).isRequired,
  newSubscriber: PropTypes.bool.isRequired,
};
export default PlanSummary;
