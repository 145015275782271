import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import HorizontalScoreBar from "~/components/horizontalBar";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ScoreDetails = memo(({ metrics }) => {
  const { t } = useTranslation();
  return (
    <ul>
      {metrics.map((e, index) => (
        <Grid container key={index} spacing={4} direction="column">
          <Grid container item xs={12} direction="column">
            <HorizontalScoreBar value={e.value || 0} maxValue={100} unit="%" />
            {e.link ? (
              <RouterLink color="primary" to={e.link} variant="body2">
                {t(e.description)}
              </RouterLink>
            ) : (
              <Typography variant="body2">{t(e.description)}</Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </ul>
  );
});

ScoreDetails.propTypes = {
  metrics: PropTypes.array.isRequired,
};

export default ScoreDetails;
