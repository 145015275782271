import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { useEffect, useState } from "react";
import {
  StyledCloseIcon,
  StyledDoneIcon,
  StyledEditIcon,
} from "~/modules/buybox/editablePriceCell";

import { useTranslation } from "react-i18next";

interface AdvancedConfigurationDaysCoverProps {
  fieldValue: number;
  setDaysCover: (
    notificationType: StoreNotificationType | ProductNotificationType,
    daysCover: number
  ) => void;
  notificationType: StoreNotificationType | ProductNotificationType;
  setNotificationType: (
    notificationType: StoreNotificationType | ProductNotificationType
  ) => void;
}

const AdvancedConfigurationDaysCover = ({
  fieldValue,
  setDaysCover,
  notificationType,
  setNotificationType,
}: AdvancedConfigurationDaysCoverProps) => {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(fieldValue);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      pl={1}
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography variant="h3">{t("notificationSetting.daysCover")}</Typography>

      {editMode && (
        <Box
          display="flex"
          flexDirection="row"
          width="80px"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            type="number"
            autoFocus
            defaultValue={value}
            inputProps={{
              step: "0.01",
              style: {
                ...theme.typography.body2,
                textAlign: "right",
                width: "42px",
              },
            }}
            onChange={(e) => {
              setValue(parseInt(e.target.value));
            }}
          />

          <Tooltip title="Save">
            <IconButton
              size="small"
              aria-label="Save"
              onClick={() => {
                setEditMode(false);
                if (value !== fieldValue) {
                  setNotificationType(notificationType);
                  setDaysCover(notificationType, value);
                }
              }}
            >
              <StyledDoneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              size="small"
              aria-label="Cancel"
              onClick={() => {
                setEditMode(false);
              }}
            >
              <StyledCloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {!editMode && (
        <Box
          display="flex"
          flexDirection="row"
          width="60px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2">{value}</Typography>
          <Tooltip title="Edit">
            <IconButton
              size="small"
              aria-label="Edit"
              onClick={() => {
                setValue(fieldValue);
                setEditMode(true);
              }}
            >
              <StyledEditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default AdvancedConfigurationDaysCover;
