import * as Sentry from "@sentry/browser";

import { Dispatch } from "redux";

export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const DISPLAY_INFO_MESSAGE = "DISPLAY_INFO_MESSAGE";

type GlobalToastState = {
  showToast: boolean;
  status: string;
  msg: string;
  triggerLogout: boolean;
  location?: string;
  sticky?: boolean;
};

const initialState: GlobalToastState = {
  showToast: false,
  status: "",
  msg: "",
  triggerLogout: false,
};

type DisplayInfoMessageAction = {
  type: typeof DISPLAY_INFO_MESSAGE;
  payload: GlobalToastState;
};

type SetErrorAction = {
  type: typeof SET_ERROR;
  payload: GlobalToastState;
};

type ClearErrorAction = {
  type: typeof CLEAR_ERROR;
  payload: GlobalToastState;
};

type GlobalToastAction =
  | DisplayInfoMessageAction
  | ClearErrorAction
  | SetErrorAction;

export const globalToast = (
  state = initialState,
  action: GlobalToastAction
) => {
  switch (action.type) {
    case DISPLAY_INFO_MESSAGE:
    case SET_ERROR:
      if (!state.showToast) {
        return { ...state, ...action.payload };
      } else {
        return state;
      }
    case CLEAR_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function clearError() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
      payload: {
        showToast: false,
        status: "",
        msg: "",
        triggerLogout: false,
      },
    });
  };
}

export const setError = async (
  dispatch: Dispatch,
  msg?: string,
  code?: number | string,
  location?: string,
  supressUnauthorisedError?: boolean
) => {
  if (`${code}` === "401" || `${msg}`.includes("401")) {
    if (`${msg}`.includes("Not logged in")) {
      await dispatch({
        type: SET_ERROR,
        payload: {
          showToast: false,
          status: "info",
          msg: "Not logged in.",
          location,
          triggerLogout: true,
        },
      });
    } else if (!supressUnauthorisedError) {
      await dispatch({
        type: SET_ERROR,
        payload: {
          showToast: true,
          status: "info",
          msg: "Your session has expired, please sign in again.",
          location,
          triggerLogout: true,
        },
      });
    }
  } else {
    if (code === "CUSTOM") {
      await dispatch({
        type: SET_ERROR,
        payload: {
          showToast: true,
          status: "error",
          msg,
          location,
          triggerLogout: false,
        },
      });
    }

    if (msg || location) {
      let finalMessage = "";
      if (msg) {
        Sentry.captureException(msg);
        finalMessage = `${msg}. If the issue persists please try logging out.`;
      } else {
        finalMessage = `There was an issue fetching some of your data, please refresh the browser. If the issue persists please try logging out.`;
      }
      // if the error comes from cloudfront, there's no point showing it to the user -
      // we already log this on the backend, and it's not giving them any useful
      // information
      const isCloudfrontError = `${msg}`.includes("DOCTYPE HTML PUBLIC");
      await dispatch({
        type: SET_ERROR,
        payload: {
          showToast: !isCloudfrontError,
          status: "error",
          msg: finalMessage,
          location,
          triggerLogout: false,
        },
      });
    }
  }
};

export const setInfoMessage = async (
  dispatch: Dispatch,
  msg: string,
  location?: string
) => {
  await dispatch({
    type: DISPLAY_INFO_MESSAGE,
    payload: {
      showToast: true,
      status: "info",
      msg,
      triggerLogout: false,
      ...(location ? { location } : {}),
    },
  });
};

export const setWarningMessage = (
  dispatch: Dispatch,
  msg: string,
  sticky?: boolean
) => {
  dispatch({
    type: DISPLAY_INFO_MESSAGE,
    payload: {
      showToast: true,
      status: "warning",
      msg: msg,
      sticky: Boolean(sticky),
      triggerLogout: false,
    },
  });
};
