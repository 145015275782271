import { Grid, IconButton, Paper } from "@material-ui/core";
import React, { ReactElement, memo, useCallback, useEffect } from "react";

import Bold from "../typography/bold";
import CloseIcon from "@material-ui/icons/Close";
import picMissing from "../../img/picMissing.jpg";
import styled from "styled-components";

const PanelHeader = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  min-height: 63px;
`;

const PanelWrapper = styled(Paper)`
  height: 100%;
`;
PanelWrapper.displayName = "PanelWrapper";

const Image = styled.img`
  display: flex;
  height: calc(45px - 1rem);
  width: calc(45px - 1rem);
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  object-fit: cover;
  margin: 0 8px;
`;

const Title = styled(Bold)`
  overflow: hidden;
  width: 80%;
  ${({ theme }) => `
    ${theme.breakpoints.down("xs")} {
      width: calc(100vw - 157px);
    }
  `};
`;

/**
 * Typically you would use title, with an optional image, xor just a header component.
 * A header component as well as title/image wouldn't work.
 */

interface SidePanelBasics {
  displayImage: boolean;
  content: ReactElement;
  handleClose: () => void;
}
interface SidePanelWithTitleProps extends SidePanelBasics {
  title: string;
  image?: string;
}
interface SidePanelWithHeaderProps extends SidePanelBasics {
  headerComponent: ReactElement;
}

type SidePanelProps = SidePanelWithTitleProps | SidePanelWithHeaderProps;

const SidePanelWithTitle = ({
  title,
  image,
  displayImage,
}: SidePanelWithTitleProps) => {
  return (
    <>
      {displayImage ? (
        image ? (
          <Image src={image} />
        ) : (
          <Image src={picMissing} />
        )
      ) : (
        <></>
      )}
      {title && (
        <Title variant="h5" title={title} noWrap>
          {title}
        </Title>
      )}
    </>
  );
};
const SidePanelWithHeader = ({ headerComponent }: SidePanelWithHeaderProps) => {
  return <>{headerComponent}</>;
};

const isSidePanelWithTitle = (
  props: SidePanelWithTitleProps | SidePanelWithHeaderProps
): props is SidePanelWithTitleProps => {
  return Boolean((props as SidePanelWithTitleProps).title);
};

const SidePanel = memo((props: SidePanelProps) => {
  const { content, handleClose } = props;
  const escFunction = useCallback((event) => {
    const ESCAPE = 27;
    if (event.keyCode === ESCAPE) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <PanelWrapper elevation={0} square id="panel-card">
      <PanelHeader
        container
        justifyContent="space-between"
        id="panel-card-header"
      >
        <Grid container item xs={12} alignItems="center">
          <IconButton
            size="small"
            aria-label="close drawer"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          {isSidePanelWithTitle(props) ? (
            <SidePanelWithTitle {...(props as SidePanelWithTitleProps)} />
          ) : (
            <SidePanelWithHeader {...(props as SidePanelWithHeaderProps)} />
          )}
        </Grid>
      </PanelHeader>
      {content}
    </PanelWrapper>
  );
});

export default SidePanel;
