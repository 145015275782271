import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface Account {
  advertiser_id: string;
  advertiser_name: string;
}

interface ValidateTikTokAuthArgs {
  authCode: string;
  errorHandler?: (error: Error) => void;
}

interface ValidateTikTokAuthResponse {
  errMsg?: string;
  code?: number;
  accounts: Account[] | null;
}

interface SetTikTokAccountArgs {
  advertiserId: string;
}

interface SetTikTokAccountResponse {
  errMsg?: string;
  code?: number;
  store?: {
    marketplaceType: string;
    marketplaceSubtype: string;
    mid: string;
    countryCode: string;
    shopName: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    validateTikTokAuth: build.mutation<
      ValidateTikTokAuthResponse,
      ValidateTikTokAuthArgs
    >({
      query: ({ authCode }) => {
        return {
          url: `${baseUrl}/auth/tiktok/connect`,
          method: "POST",
          data: { authCode },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Tiktok connect", true),
    }),

    setTikTokAccount: build.mutation<
      SetTikTokAccountResponse,
      SetTikTokAccountArgs
    >({
      query: ({ advertiserId }) => {
        return {
          url: `${baseUrl}/auth/tiktok/connect/profile`,
          method: "POST",
          data: { advertiserId },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Tiktok connect", true),
    }),
  }),
});

export const { useValidateTikTokAuthMutation, useSetTikTokAccountMutation } =
  extendedApiSlice;
