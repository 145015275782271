import React from "react";
import { useTheme } from "@material-ui/core";

interface BarChartLabelProps {
  x: number;
  y: number;
  width: number;
  value: number;
}

export const BarChartLabel = ({ x, y, width, value }: BarChartLabelProps) => {
  const theme = useTheme();
  if (value === 0) {
    return null;
  }
  return (
    <g>
      <text
        x={x + width / 2}
        y={y - 10}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={theme.palette.text.secondary}
        fontSize={12}
        fontFamily={theme.typography.fontFamily}
      >
        {value}
      </text>
    </g>
  );
};
