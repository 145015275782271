import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { CurrencyRate, Range } from "~/typedef/store";
import React, { useMemo } from "react";

import CombinedLink from "~/components/links/link";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import PieChartWithNeedle from "~/components/charts/pieChartWithNeedle/pieChartWithNeedle";
import RaisedButton from "~/components/buttons/raisedButton";
import SmallButton from "~/components/buttons/smallButton";
import { User } from "~/typedef/user";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { memo } from "react";
import styled from "styled-components";
import { useFetchPerformanceAgainstForecastDataQuery } from "~/store/mystore/forecasting.redux";
import { useMarketingAllCampaignsTotalsQuery } from "~/store/mystore/marketing.redux";
import { useTranslation } from "react-i18next";

const ForecastBox = styled(Box)`
  min-width: 80%;
  max-width: 100%;
`;

export const EnterBudgetBox = styled(Box)`
  height: 100%;
`;

const TitleTypography = styled(Typography)`
  margin: 0.5rem 0;
`;

const MetricGrid = styled(Grid)`
  padding: 5px 0;
`;

type MetricTypographyProps = {
  diff?: number;
};

const AdSpendDiffTypography = styled(Typography)<MetricTypographyProps>`
  color: ${({ diff, theme }) => {
    return isNaN(diff as number)
      ? theme.palette.text.primary
      : diff && diff >= 0
      ? theme.palette.chart.red
      : theme.palette.chart.green;
  }} !important;
`;

interface AdSpendInvestmentVsBudgetParams {
  mid: string;
  currentRange: Range;
  currentCurrency: string;
  includeTax: boolean;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype?: string;
  userInfo: User;
  currencyRates: CurrencyRate[];
  report?: boolean;
}

const AdSpendInvestmentVsBudgetWidget = memo<AdSpendInvestmentVsBudgetParams>(
  function AdSpendInvestmentVsBudgetWidget({
    mid,
    currentCurrency,
    currentRange,
    includeTax,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    userInfo,
    currencyRates,
    report,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const { advertisingData, adDataLoading } =
      useMarketingAllCampaignsTotalsQuery(
        {
          customerId: userInfo._id,
          mid,
          countryCode,
          marketplaceType,
          marketplaceSubtype: marketplaceSubtype ?? "",
          reportDateFrom: currentRange.fromDate,
          reportDateTo: currentRange.toDate,
          priorFromDate: currentRange.priorFromDate,
          priorToDate: currentRange.priorToDate,
          timezone: currentRange.timezone,
          includeTax,
        },
        {
          selectFromResult: ({ data, isFetching }) => {
            return {
              advertisingData: data?.summaryData ? data.summaryData : null,
              adDataLoading: isFetching,
            };
          },
        }
      );

    const { performanceData, performanceDataFetching, forecastCurrency } =
      useFetchPerformanceAgainstForecastDataQuery(
        {
          user: userInfo,
          mid,
          currentRange,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            performanceData: data,
            performanceDataFetching: isFetching,
            forecastCurrency: data?.currency,
          }),
        }
      );

    const result = useMemo(() => {
      if (performanceData && !performanceDataFetching && !adDataLoading) {
        const adSpendActual = advertisingData?.current.totalCost ?? 0;
        const target = performanceData.adSpend.target ?? 0;

        const adSpendLimitValueMax = Math.max(target, adSpendActual);
        const adSpendPieChartMax = adSpendLimitValueMax * 2;

        const adSpendDiff = adSpendActual - target;

        const adSpendData = {
          chartData: [
            ...(target > 0
              ? [
                  {
                    name: "$0",
                    value: target * 0.75,
                    color: theme.palette.chart.green,
                  },
                  {
                    value: target * 0.25,
                    color: theme.palette.chart.orange,
                  },
                  {
                    name: formatCurrencyRounded(
                      target,
                      currencyRates,
                      forecastCurrency,
                      currentCurrency
                    ),
                    value: 1,
                    color: theme.palette.chart.lightBlue,
                  },
                  {
                    name: formatCurrencyRounded(
                      adSpendPieChartMax,
                      currencyRates,
                      forecastCurrency,
                      currentCurrency
                    ),
                    value: adSpendPieChartMax - target,
                    color: theme.palette.chart.red,
                  },
                ]
              : [
                  {
                    value: adSpendPieChartMax - target,
                    color: theme.palette.chart.red,
                  },
                ]),
          ],
          value: target === 0 ? 0 : ((adSpendActual / target) * 100).toFixed(2),
        };
        return {
          adSpendData,
          adSpendDiff,
        };
      }
      return {
        adSpendData: { chartData: [], value: 0 },
        adSpendDiff: 0,
      };
    }, [performanceData, advertisingData]);

    const adSpendActual = advertisingData?.current.totalCost ?? 0;
    const target = performanceData?.adSpend.target ?? 0;

    return (
      <Panel
        id="widget-performance-against-forecast"
        title={t("dashboardWidget.adSpendInvestmentVsBudget.mainTitle")}
        content={
          performanceDataFetching || adDataLoading || !result ? (
            <Box p={2}>
              <LoadingIndicator />
            </Box>
          ) : adSpendActual === 0 && target === 0 ? (
            <EnterBudgetBox
              p={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <RaisedButton variant="contained" color="info">
                <CombinedLink
                  {...{
                    to: marketplaceLink(
                      marketplaceType,
                      mid,
                      "forecastsandcosts"
                    ),
                    color: "inherit",
                    variant: "body1",
                    underline: "none",
                  }}
                >
                  {t("forecasting.enterBudget")}
                </CombinedLink>
              </RaisedButton>
            </EnterBudgetBox>
          ) : (
            <Box p={3}>
              <Grid container spacing={1}>
                <Grid
                  item
                  container
                  xs={8}
                  md={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mt="25px" maxWidth={250}>
                    <PieChartWithNeedle
                      data={result?.adSpendData.chartData}
                      circleX={125}
                      circleY={125}
                      innerRadius={65}
                      outerRadius={100}
                      value={
                        performanceData?.adSpend.target &&
                        advertisingData?.current.totalCost
                          ? advertisingData?.current.totalCost
                          : 0
                      }
                      needleColor={theme.palette.text.primary}
                      width={250}
                      height={175}
                    />
                    <Typography variant="h2" align="center">
                      {`${result?.adSpendData.value}%`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={4}
                  md={6}
                  alignItems="center"
                  justifyContent={report ? "flex-start" : "flex-end"}
                >
                  <ForecastBox>
                    <TitleTypography variant="h3">
                      {t(
                        "dashboardWidget.performanceAgainstForecast.adSpendTitle"
                      )}
                    </TitleTypography>
                    <MetricGrid container justifyContent="space-between">
                      <Typography variant="h6">
                        {t("dashboardWidget.performanceAgainstForecast.target")}
                        :
                      </Typography>
                      <Typography variant="h6">
                        {performanceData?.adSpend?.target
                          ? formatCurrencyRounded(
                              performanceData?.adSpend?.target,
                              currencyRates,
                              forecastCurrency,
                              currentCurrency
                            )
                          : "N/A"}
                      </Typography>
                    </MetricGrid>
                    <MetricGrid container justifyContent="space-between">
                      <Typography variant="h6">
                        {t("dashboardWidget.performanceAgainstForecast.actual")}
                        :
                      </Typography>
                      <Typography variant="h6">
                        {advertisingData?.current.totalCost
                          ? formatCurrencyRounded(
                              advertisingData?.current.totalCost,
                              currencyRates,
                              forecastCurrency,
                              currentCurrency
                            )
                          : "N/A"}
                      </Typography>
                    </MetricGrid>
                    <MetricGrid container justifyContent="space-between">
                      <Typography variant="h6">
                        {t("dashboardWidget.performanceAgainstForecast.diff")}:
                      </Typography>
                      <AdSpendDiffTypography
                        variant="h6"
                        diff={result.adSpendDiff}
                      >
                        {result.adSpendDiff
                          ? formatCurrencyRounded(
                              result.adSpendDiff,
                              currencyRates,
                              forecastCurrency,
                              currentCurrency
                            )
                          : "N/A"}
                      </AdSpendDiffTypography>
                    </MetricGrid>
                  </ForecastBox>
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default AdSpendInvestmentVsBudgetWidget;
