import axios from "axios";

export const FETCH_CONNECTION_STATUS = "FETCH_CONNECTION_STATUS";
export const FETCH_CONNECTION_STATUS_FETCHING =
  "FETCH_CONNECTION_STATUS_FETCHING";
export const FETCH_CONNECTION_STATUS_ERROR = "FETCH_CONNECTION_STATUS_ERROR";
export const FETCH_CONNECTION_STATUS_SINGLE = "FETCH_CONNECTION_STATUS_SINGLE";
export const DELETE_CONNECTION_STATUS = "DELETE_CONNECTION_STATUS";
export const DELETE_CONNECTION_STATUS_ERROR = "DELETE_CONNECTION_STATUS_ERROR";

export const FETCH_DISCONNECTED_STORES = "FETCH_DISCONNECTED_STORES";
export const FETCH_DISCONNECTED_STORES_ERROR =
  "FETCH_DISCONNECTED_STORES_ERROR";
export const FETCH_DISCONNECTED_STORES_FETCHING =
  "FETCH_DISCONNECTED_STORES_FETCHING";

axios.defaults.withCredentials = true;

const initState = {
  connectionStatus: {
    fetching: false,
    error: null,
  },
  disconnectedStores: {
    fetching: false,
    error: null,
  },
};

export const connections = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: {
          fetching: false,
          error: null,
          connections: action.payload,
        },
      };
    case FETCH_CONNECTION_STATUS_SINGLE:
      const { data, mid, sellerId } = action.payload;
      const newConnection = data[0];
      const updatedConnections = state.connectionStatus.connections
        .map((connection) => {
          if (mid) {
            if (connection.mid === mid) {
              return newConnection;
            }
          }
          if (sellerId) {
            if (connection.sellerId === sellerId) {
              return newConnection;
            }
          }
          return connection;
        })
        .filter((connection) => connection != null);
      return {
        ...state,
        connectionStatus: {
          fetching: false,
          error: null,
          connections: updatedConnections,
        },
      };
    case FETCH_CONNECTION_STATUS_FETCHING:
      return {
        ...state,
        connectionStatus: {
          ...state.connectionStatus,
          fetching: true,
          error: null,
        },
      };
    case FETCH_CONNECTION_STATUS_ERROR:
      return {
        ...state,
        connectionStatus: {
          fetching: false,
          error: action.payload,
        },
      };
    case FETCH_DISCONNECTED_STORES:
      return {
        ...state,
        disconnectedStores: {
          fetching: false,
          error: null,
          stores: action.payload,
        },
      };
    case FETCH_DISCONNECTED_STORES_FETCHING:
      return {
        ...state,
        disconnectedStores: {
          ...state.disconnectedStores,
          fetching: true,
          error: null,
        },
      };
    case FETCH_DISCONNECTED_STORES_ERROR:
      return {
        ...state,
        disconnectedStores: {
          fetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
