import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";

import { ClientReportFormButton } from "./reportConfigForm";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import ReportEmailRecipients from "./reportEmailField";
import { editScheduledReportRecipients } from "~/store/mystore/clientReport.redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface EditReportRecipientsProps {
  reportId: string;
  initEmailToBeSent: string[];
}

const EditReportRecipients = ({
  reportId,
  initEmailToBeSent,
}: EditReportRecipientsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientReport = useTypedSelector((state) => get(state, "clientReport"));
  const [emailToBeSent, setEmailToBeSent] = useState(initEmailToBeSent);
  const [emailError, setEmailError] = useState(false);

  const dispatchEditRecipients = () => {
    dispatch(
      editScheduledReportRecipients({
        t,
        reportId,
        reportRecipients: emailToBeSent,
      })
    );
  };

  return (
    <Grid item xs={12}>
      <Panel
        id="edit-report-recipients"
        title={t("createReport.editReportRecipients")}
        content={
          <Box p={2}>
            <ReportEmailRecipients
              {...{
                emailToBeSent,
                setEmailToBeSent,
                emailError,
                setEmailError,
              }}
            />
          </Box>
        }
        footer={
          <Box display="flex" width="100%" justifyContent="space-between">
            {
              <ClientReportFormButton
                disabled={
                  emailError ||
                  emailToBeSent.length <= 0 ||
                  clientReport.sending
                }
                type="submit"
                variant="contained"
                color="primary"
                onClick={dispatchEditRecipients}
              >
                {clientReport.sending ? (
                  <LoadingIndicator size={20} />
                ) : (
                  t("createReport.saveReportRecipients")
                )}
              </ClientReportFormButton>
            }
          </Box>
        }
      />
    </Grid>
  );
};

export default EditReportRecipients;
