import React, { memo, useMemo } from "react";
import { getCurrencySymbol, intFormatter } from "~/utils/currencyUtils";

import EditablePriceCell from "./editablePriceCell";
import IndexCell from "./indexCell";
import PriceCell from "./priceCell";
import PrimeCell from "./primeCell";
import PropTypes from "prop-types";
import SellerCell from "./sellerCell";
import { StarRatingCell } from "~/components/table/cells/starRatingCell";
import Table from "~/components/table/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

const isTrue = (bool) => bool === "true" || bool === true;

const SellerTableComponent = ({
  listing,
  data,
  sellerId,
  shopName,
  marketplaceCountry,
}) => {
  const { t } = useTranslation();

  const formatSeller = (
    { SellerId, IsBuyBoxWinner, linkUrl },
    mySellerId,
    myShopName
  ) => ({
    sellerId: SellerId,
    title: SellerId
      ? SellerId === mySellerId
        ? myShopName
        : SellerId
      : t("myStoresWidget.buyBoxSellers.unknownSellerLabel"),
    isWinner: isTrue(IsBuyBoxWinner),
    linkUrl,
  });

  const columns = useMemo(() => [
    {
      Header: "#",
      accessor: "index",
      Cell: IndexCell,
      alwaysSmall: true,
    },
    {
      Header: t("myStoresWidget.buyBoxSellers.sellerColumn"),
      id: "seller",
      accessor: (row) => formatSeller(row, sellerId, shopName),
      Cell: SellerCell,
      colSpan: 2,
      customWidth: 150,
    },
    {
      Header: t("myStoresWidget.buyBoxSellers.priceColumn"),
      accessor: "ListingPrice.Amount",
      Cell: EditablePriceCell,
      align: "right",
      colSpan: 2,
      customWidth: 100,
      getProps: (row) => ({
        currencyCode: row.ListingPrice.CurrencyCode,
        sellerSku: listing.sellerSku,
        title: listing.title,
        imageUrl: listing.imageUrl,
        isFulfilledByAmazon: listing.status.isFulfilledByAmazon,
        originalPrice: listing.yourPrice ? listing.yourPrice.Amount : 0,
      }),
    },
    // see: https://merchantspring.atlassian.net/browse/MM-3278 - we need to include 'points' as part of our data for the japanese market
    ...(marketplaceCountry === "JPN"
      ? [
          {
            Header: t("myStoresWidget.buyBoxSellers.pointsColumn"),
            accessor: "Points.PointsNumber",
            Cell: PriceCell,
            align: "right",
            colSpan: 2,
            customWidth: 100,
          },
        ]
      : []),
    {
      Header: t("myStoresWidget.buyBoxSellers.shippingPriceColumn"),
      accessor: (row) => {
        const shippingPrice = parseFloat(row.Shipping.Amount);
        const currencyCode = row.Shipping.CurrencyCode;
        if (isNaN(shippingPrice) || shippingPrice === 0.0 || !currencyCode) {
          return "-";
        } else {
          return `${getCurrencySymbol[currencyCode]}${intFormatter.format(
            shippingPrice
          )}`;
        }
      },
      Cell: ValueCell,
      align: "right",
    },
    {
      Header: t("myStoresWidget.buyBoxSellers.primeColumn"),
      accessor: (row) =>
        isNil(row.PrimeInformation?.IsPrime)
          ? row.PrimeInformation?.IsOfferPrime
          : row.PrimeInformation?.IsPrime,
      Cell: PrimeCell,
      alwaysSmall: true,
      customWidth: 100,
      hiddenDown: "xs",
    },
    {
      Header: t("myStoresWidget.buyBoxSellers.sellerRatingColumn"),
      accessor: "SellerFeedbackRating.SellerPositiveFeedbackRating",
      Cell: StarRatingCell,
      align: "center",
      hiddenDown: "xs",
    },
  ]);

  const mappedData = data.map((e, i) => ({ ...e, index: i }));
  const myOffer = mappedData.find((e) => e.isMyOffer);
  const buyBoxOffer = mappedData.find((e) => isTrue(e.IsBuyBoxWinner));

  let sortedData = mappedData;

  const TOP_3 = 3;

  if (buyBoxOffer && myOffer && myOffer.index >= TOP_3) {
    sortedData = [
      mappedData[buyBoxOffer.index],
      mappedData[myOffer.index - 1],
      mappedData[myOffer.index],
      ...mappedData.slice(0, buyBoxOffer.index),
      ...mappedData.slice(buyBoxOffer.index + 1, myOffer.index - 1),
      ...mappedData.slice(myOffer.index + 1),
    ];
  } else if (buyBoxOffer) {
    sortedData = [
      mappedData[buyBoxOffer.index],
      ...mappedData.slice(0, buyBoxOffer.index),
      ...mappedData.slice(buyBoxOffer.index + 1),
    ];
  }

  return (
    <Table
      columns={columns}
      data={sortedData}
      pageSize={20}
      getRowProps={(row) => {
        if (row.original.isMyOffer) {
          return {
            style: {
              borderBottomWidth: "4px",
            },
          };
        }

        return {};
      }}
    />
  );
};

SellerTableComponent.propTypes = {
  listing: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  sellerId: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  marketplaceCountry: PropTypes.string,
};

const SellerTable = memo(SellerTableComponent);

export default SellerTable;
