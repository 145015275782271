import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

const clientId = process.env.TIKTOK_CLIENT_ID;
const redirectUri =
  process.env.TIKTOK_REDIRECT_URI ||
  "https://mm.merchantspring.io/callback/tiktok";
const authUrl = `https://business-api.tiktok.com/portal/auth?app_id=${clientId}&redirect_uri=${encodeURIComponent(
  redirectUri
)}`;

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "tiktok"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.auth_code) {
          onCode(params.auth_code);
          return;
        }

        onError("Invalid response from TikTok");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        authUrl,
        "authPopup-mm-tiktok",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
