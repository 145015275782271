import React, { memo } from "react";

import CampaignPerformance from "~/modules/marketing/campaignPerformance";
import CampaignTypes from "./campaignTypePieChart";
import { Grid } from "@material-ui/core";
import TopPerformingKeywords from "~/modules/marketing/topPerformingKeywords";
import TopPerformingProductAds from "~/modules/marketing/topPerformingProductAds";
import { getAdvertisingWidgets } from "~/components/toolbars/sideNavigation/sideNavConstants";

const isItemBlocked = (
  name: string,
  marketplaceType: string,
  countryCode: string
) => {
  const widget = getAdvertisingWidgets().subMenus?.find(
    (menuItem) => menuItem.link === name
  );

  if (widget) {
    if (widget.disabledFor?.markets.includes(marketplaceType as Marketplace)) {
      const disabledForCountries =
        widget.disabledFor?.countries?.[marketplaceType as Marketplace] ?? [];
      if (
        disabledForCountries.length === 0 ||
        disabledForCountries.includes(countryCode)
      ) {
        return true;
      }
    }
  }
  return false;
};

interface MarketingOverviewProps {
  marketplace: string;
  mid: string;
  countryCode: string;
}

const MarketingOverviewPanel = memo<MarketingOverviewProps>(
  function MarketingOverviewPanel({ marketplace, mid, countryCode }) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CampaignPerformance
            market={marketplace}
            mid={mid}
            countryCode={countryCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CampaignTypes
            {...{
              mid,
              countryCode,
              marketplaceType: marketplace,
            }}
          />
        </Grid>
        {!isItemBlocked("marketingKeywords", marketplace, countryCode) && (
          <Grid item xs={12}>
            <TopPerformingKeywords
              market={marketplace}
              mid={mid}
              countryCode={countryCode}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TopPerformingProductAds
            market={marketplace}
            mid={mid}
            countryCode={countryCode}
          />
        </Grid>
      </Grid>
    );
  }
);

export default MarketingOverviewPanel;
