import {
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import BuyBoxPieChart from "~/components/charts/pieChart/buyboxPieChart";
import Panel from "~/components/panel/panel";
import SummaryBox from "~/modules/buybox/summaryBox";
import get from "lodash/get";
import { loadBuyBoxSnapshot } from "~/store/buyBox.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { Store } from "~/typedef/store";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
`;

type BuyBoxSummaryProps = {
  store: Partial<Store>;
  showFooterLink?: boolean;
};

const BuyBoxSummary = memo(({ store, showFooterLink }: BuyBoxSummaryProps) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const userInfo = useTypedSelector((state) => state.user);

  const snapshotLoading = useTypedSelector((state) =>
    get(state, "buyBox.snapshotLoading")
  );
  const snapshot = useTypedSelector((state) => get(state, "buyBox.snapshot"));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadBuyBoxSnapshot(
        userInfo._id,
        store.sourceSystemId,
        store.marketplaceCountry
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sourceSystemId, store.marketplaceCountry, userInfo._id]);

  if (snapshotLoading) {
    return (
      <Panel
        id="widget-buy-box-summary"
        title={t("myStoresWidget.buyBoxSummary.mainTitle")}
        content={
          <ContentWrapper>
            <Grid container item xs={12} justifyContent="center">
              <CircularProgress />
            </Grid>
          </ContentWrapper>
        }
      />
    );
  }

  const footerLink = {
    url: marketplaceLink(
      store?.marketplaceType || "amazon",
      store?.merchantId || "",
      "buyBoxPerformance"
    ),
    external: false,
    label: t("generic.viewAllLink"),
  };

  return (
    <Panel
      id="widget-buy-box-summary"
      title={t("myStoresWidget.buyBoxSummary.mainTitle")}
      tooltip={t("myStoresWidget.buyBoxSummary.tooltip")}
      footerLink={showFooterLink ? footerLink : undefined}
      content={
        <ContentWrapper>
          {snapshotLoading ? (
            <Grid container item xs={12} justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid container item sm={3} xs={12}>
                <SummaryBox
                  winnerCount={get(snapshot, "winnerCount")}
                  losingCount={get(snapshot, "losingCount")}
                  isMobileView={isMobileView}
                />
              </Grid>
              <Grid item sm={9} xs={12}>
                <BuyBoxPieChart
                  data={{
                    numberOfProductsWinning: get(snapshot, "winnerCount"),
                    numberOfNoWinners: get(snapshot, "noWinnerCount"),
                    numberOfProductsLosingToAmazon: get(
                      snapshot,
                      "losingToAmazonCount"
                    ),
                    numberOfProductsLosing: get(snapshot, "losingCount"),
                    losingToSelfCount: get(snapshot, "losingToSelfCount"),
                    unknownCount: get(snapshot, "unknownCount"),
                    losingToUnknownCount: get(snapshot, "losingToUnknownCount"),
                  }}
                />
              </Grid>
            </Grid>
          )}
        </ContentWrapper>
      }
    />
  );
});

export default BuyBoxSummary;
