export const miraklHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    items: [
      {
        key: "accountStatus",
        title: "dashboardWidget.accountHealth.accountStatus",
      },
      {
        key: "accountGrade",
        title: "dashboardWidget.accountHealth.accountGrade",
        suffix: "dashboardWidget.accountHealth.starsSuffix",
      },
      {
        key: "silverSeller",
        title: "dashboardWidget.accountHealth.customerFeedbackScore",
        suffix: "%",
      },
      {
        key: "newMessages",
        title: "dashboardWidget.accountHealth.newMessages",
      },
      {
        key: "approvalRate",
        title: "dashboardWidget.accountHealth.approvalRate",
        suffix: "%",
      },
      { key: "shopName", title: "dashboardWidget.accountHealth.shopName" },
      {
        key: "avgResponseTime",
        title: "dashboardWidget.accountHealth.avgResponseTime",
        suffix: "dashboardWidget.accountHealth.daysSuffix",
      },
    ],
  },
};
