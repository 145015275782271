import { Grid, Input, Link, Typography } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";

import ErrorButton from "~/components/buttons/errorButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ModalPanel from "~/components/panel/modalPanel";
import RaisedButton from "~/components/buttons/raisedButton";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useUploadCampaignFileMutation } from "~/store/mystore/uploadCampaign.redux";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
`;

const LinkWrapper = styled(Typography)`
  margin-bottom: 20px;
`;

interface CampaignUploadDialogProps {
  mid: string;
  marketplaceType?: string;
  marketplaceSubtype?: string;
  countryCode: string;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

function CampaignUploadDialog({
  mid,
  marketplaceType,
  marketplaceSubtype,
  countryCode,
  openModal,
  setOpenModal,
}: CampaignUploadDialogProps) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();

  const selectFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target?.files?.[0]);
  };

  const successMessage = (cutoffDate: number | null) => {
    return t("marketingCampaigns.uploadSuccessMessage", {
      cutoffDate: cutoffDate
        ? moment.unix(cutoffDate).format("ll")
        : moment().format("ll"),
    });
  };

  const [
    uploadCampaignsFile,
    {
      isLoading: uploadCampaignsFileLoading,
      isSuccess: uploadCampaignsFileDone,
    },
  ] = useUploadCampaignFileMutation();

  const uploadFile = () => {
    if (selectedFile && mid && marketplaceType) {
      uploadCampaignsFile({
        mid,
        marketplaceType,
        marketplaceSubtype: marketplaceSubtype ?? marketplaceType,
        countryCode,
        file: selectedFile,
        successMessage,
      });
    }
  };

  const close = () => {
    setSelectedFile(undefined);
    setOpenModal(false);
  };

  useEffect(() => {
    if (uploadCampaignsFileDone) {
      close();
    }
  }, [uploadCampaignsFileDone]);

  return (
    <ModalPanel
      open={openModal}
      setOpen={setOpenModal}
      title={t("marketingCampaigns.uploadPanel")}
      content={
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("marketingCampaigns.campaignFileLabel")}
              </Typography>
              <LinkWrapper align="left">
                <Link
                  href={t("marketingCampaigns.uploadHelpLinkUrl")}
                  target="_blank"
                >
                  {t("marketingCampaigns.uploadHelpLink")}
                </Link>
              </LinkWrapper>
            </Grid>
            <Grid item xs={6}>
              <Input type="file" name="file" onChange={selectFileHandler} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              alignItems="center"
              direction="row"
            >
              <Grid item container xs={12} sm={6} justifyContent="center">
                <ErrorButton
                  disabled={uploadCampaignsFileLoading}
                  onClick={close}
                  variant="outlined"
                >
                  {t("generic.cancelButton")}
                </ErrorButton>
              </Grid>
              <Grid item container xs={12} sm={6} justifyContent="center">
                <RaisedButton
                  type="submit"
                  color="primary"
                  onClick={uploadFile}
                  disabled={uploadCampaignsFileLoading || !selectedFile}
                >
                  {uploadCampaignsFileLoading ? (
                    <LoadingIndicator size={20}></LoadingIndicator>
                  ) : (
                    t("generic.save")
                  )}
                </RaisedButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      }
    ></ModalPanel>
  );
}

export default CampaignUploadDialog;
