import {
  ADVERTISING_BUTTONS,
  advertisingPageToRender,
} from "../connectPromptOptions";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import AlertCard from "~/components/alert/alertCard";
import AmazonSelectAdvertisingProfilePage from "~/pages/marketplaceConnectors/amazonSelectAdvertisingProfilePage";
import { CustomBadge } from "~/components/toolbars/sideNavigation/drawerMenuItem";
import DataShareInfo from "~/components/info/dataShareInfo";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import SelectAdvertiser from "~/pages/marketplaceConnectors/dspSelectAdvertiser";
import StandaloneAdvertisingConnectPage from "./standaloneAdvertisingConnectPage";
import StandaloneAdvertisingProfilePage from "./standaloneAdvertisingProfilePage";
import StandaloneStoreName from "~/pages/marketplaceConnectors/standaloneStoreName";
import { Store } from "~/typedef/store";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import { User } from "~/typedef/user";
import { fetchCustomLayoutConfig } from "~/store/customLayout.redux";
import get from "lodash/get";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

enum STAGE {
  SELECT_STORE,
  CONNECT,
  SELECT_PROFILE,
  SELECT_ADVERTISER,
  RENAME,
  DONE,
}

const LinearProgressBar = styled(LinearProgress)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const MarketplaceLogoIcon = styled.img`
  padding: 4px;
  max-height: 32px;
`;

const ConnectButton = styled(Button)`
  margin-top: 16px;
`;

const CenteredFormControlLabel = styled(FormControlLabel)`
  margin: auto;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Divider = styled.div`
  height: 1px;
  width: 45%;
  background-color: ${({ theme }) => theme.palette.border.dark};
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperGrid = styled(Grid)`
  max-width: 300px;
`;

const NoMarginGrid = styled(Grid)`
  margin: 0;
`;

type PageToRenderProps = {
  immediateAuth: boolean;
  onSuccess: (nextStepParams: any) => void;
  store: Store;
  setIsSubmitting: (bool: boolean) => void;
};
const getPageToRender = ({
  selectedMarketplace,
}: {
  selectedMarketplace: string | null;
}): ((props: PageToRenderProps) => JSX.Element) | null => {
  return get(
    advertisingPageToRender(),
    `[${selectedMarketplace}].component`,
    null
  );
};

const PageToRender = ({
  selectedStore,
  onSuccess,
  setIsSubmitting,
}: {
  selectedStore: Store;
  onSuccess: (params?: any) => void;
  setIsSubmitting: (bool: boolean) => void;
}) => {
  const Component = getPageToRender({
    selectedMarketplace: selectedStore.marketplaceType,
  });

  return Component ? (
    <Component
      immediateAuth // flag to indicate OAuth/external flows that the auth flow should be initiated immediately
      onSuccess={onSuccess} // callback for when auth succeeds
      store={selectedStore}
      setIsSubmitting={setIsSubmitting}
    />
  ) : null;
};

export interface ConnectionProps {
  onSuccess: () => void;
  onError?: (params: any) => void;
  defaultStore?: Store["merchantId"] | null;
}

const AdvertisingConnectionPrompt: React.FC<ConnectionProps> = ({
  onSuccess,
  defaultStore,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores
  );

  const [store, setStore] = useState<Store | null>(
    defaultStore
      ? allStores?.find((s) => s.merchantId === defaultStore) || null
      : null
  );
  const [standaloneStore, setStandaloneStore] =
    useState<Partial<Store> | null>();
  const [dspAdditions, setDSPAdditions] = useState<{
    profileId?: string;
    entityId?: string;
    advertiserId?: string;
  }>({});
  const [isStandalone, setIsStandalone] = useState<boolean>(false);
  const [stage, setStage] = useState(STAGE.SELECT_STORE);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useTypedSelector((state) => state.user);
  const isLoading: boolean =
    useTypedSelector((state) => state.customLayout?.fetching) || false;

  useEffect(() => {
    dispatch(fetchCustomLayoutConfig());
  }, []);

  const changeStore = (market: string, mid: string): void => {
    const store = allStores?.find(
      (store: Store) =>
        store.marketplaceType === market && store.merchantId === mid
    );
    if (store) {
      setStore(store);
    }
  };

  const selectMarketplace = useCallback(
    (marketplace: string): void => {
      setStandaloneStore({
        marketplaceType: marketplace,
        marketplaceSubtype: "advertising",
      });
    },
    [standaloneStore]
  );

  const selectCountry = useCallback(
    (countryCode: string): void => {
      setStandaloneStore({
        ...standaloneStore,
        marketplaceCountry: countryCode,
      });
    },
    [standaloneStore]
  );

  const selectProfile = useCallback(
    (
      mid: string,
      profileId?: string,
      entityId?: string,
      countryCode?: string
    ): void => {
      setStandaloneStore({
        ...standaloneStore,
        merchantId: mid,
        ...(countryCode ? { marketplaceCountry: countryCode } : {}),
      });
      setDSPAdditions({ profileId, entityId });
    },
    [standaloneStore]
  );

  const selectAdvertiser = useCallback(
    (mid: string, advertiserId?: string): void => {
      setStandaloneStore({
        ...standaloneStore,
        merchantId: mid,
      });
      setDSPAdditions({ ...dspAdditions, advertiserId });
    },
    [standaloneStore]
  );

  const selectIsStandalone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsStandalone(isChecked);
    if (isChecked) {
      setStore(null);
    }
  };

  const setWholeFlowDone = () => {
    setShowSuccess(true);
    setStage(STAGE.DONE);
    onSuccess();
  };

  const advertisingOnSuccess = useCallback(
    (
      goToNextStage?: boolean,
      payload?: Record<string, any>,
      finalStep?: boolean
    ) => {
      setIsSubmitting(false);
      if (payload?.mid) {
        setStandaloneStore({
          merchantId: payload.mid,
          marketplaceCountry: payload.countryCode,
          ...standaloneStore,
        });
      }
      if (finalStep) {
        setWholeFlowDone();
      } else if (isStandalone || goToNextStage) {
        setStage(STAGE.SELECT_PROFILE);
      } else {
        setWholeFlowDone();
      }
    },
    [isStandalone, standaloneStore]
  );

  const profileOnSuccess = useCallback(() => {
    if (isStandalone) {
      if (standaloneStore?.marketplaceType === "dsp") {
        setStage(STAGE.SELECT_ADVERTISER);
      } else {
        setStage(STAGE.RENAME);
      }
    }
  }, [standaloneStore]);

  return (
    <WrapperGrid container alignItems="center">
      <NoMarginGrid
        container
        item
        alignItems="center"
        justifyContent="center"
        xs={12}
        spacing={2}
      >
        {isSubmitting && <LinearProgress />}
        {showSuccess ? (
          <Grid item xs={12}>
            <AlertCard isOpen={true} type={"success"}>
              <Medium variant="body1">
                {t("connectWizard.congratulationsBanner")}
              </Medium>
            </AlertCard>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="textSecondary"
              noWrap
              align="center"
            >
              {t("connect.selectAdvertisingChannelPrompt")}
            </Typography>
          </Grid>
        )}

        {user.role === "external" && (
          <Grid item>
            <Box width="100%" mb={4}>
              <DataShareInfo />
            </Box>
          </Grid>
        )}

        {isLoading ? (
          <LinearProgressBar />
        ) : (
          stage === STAGE.SELECT_STORE && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {t("connect.selectTypeOfAdvertisingConnection")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StoreSelector
                  changeStore={changeStore}
                  currentStore={
                    store
                      ? {
                          marketplace: store?.marketplaceType,
                          merchantId: store?.merchantId,
                          sourceSystemId: store?.merchantId.split(" @")[0],
                          marketplaceCountry: store?.marketplaceCountry,
                          storeName: store?.storeName,
                        }
                      : undefined
                  }
                  currentFilter={{
                    marketplaces: Object.keys(advertisingPageToRender()),
                    countries: [],
                    tags: [],
                  }}
                  maxWidth={350}
                  fullWidth={true}
                  hideClearFilters={true}
                  disabled={isStandalone}
                  centerAlign={true}
                />
              </Grid>
              <FlexGrid item xs={12}>
                <Divider />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {t("generic.or")}
                </Typography>
                <Divider />
              </FlexGrid>
              <Grid item xs={12}>
                <CenteredFormControlLabel
                  control={
                    <Checkbox
                      checked={isStandalone}
                      size="small"
                      color="primary"
                      onChange={(e) => selectIsStandalone(e)}
                    />
                  }
                  label={t("connect.createStandaloneAdsChannel")}
                />
              </Grid>
              {(isStandalone || store) && (
                <Grid item>
                  <RaisedButton
                    color="primary"
                    onClick={() => setStage(STAGE.CONNECT)}
                  >
                    {t("generic.next")}
                  </RaisedButton>
                </Grid>
              )}
            </>
          )
        )}
      </NoMarginGrid>
      <Grid container item xs={12} alignItems="center">
        {store && stage === STAGE.CONNECT && (
          <PageToRender
            selectedStore={store}
            onSuccess={advertisingOnSuccess}
            setIsSubmitting={setIsSubmitting}
          />
        )}
        {isStandalone && stage === STAGE.CONNECT ? (
          !standaloneStore?.marketplaceType ? (
            Object.keys(ADVERTISING_BUTTONS).map((marketplace) => {
              const { imgSrc, label, featureFlagged, isNew } =
                ADVERTISING_BUTTONS[marketplace];
              if (featureFlagged && !user[featureFlagged as keyof User]) {
                return null;
              }

              return (
                <ConnectButton
                  key={marketplace}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => selectMarketplace(marketplace)}
                  aria-label={`connect to ${t(label)}`}
                >
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid container item xs={3} justifyContent="flex-end">
                      <MarketplaceLogoIcon src={imgSrc} />
                    </Grid>
                    <Grid container item xs={9} justifyContent="flex-start">
                      <Medium align="left">{t(label)}</Medium>
                      {isNew && (
                        <Box>
                          <CustomBadge>{t("generic.new")}</CustomBadge>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </ConnectButton>
              );
            })
          ) : (
            <StandaloneAdvertisingConnectPage
              marketplace={standaloneStore.marketplaceType}
              onSuccess={advertisingOnSuccess}
              setIsSubmitting={setIsSubmitting}
              selectCountry={selectCountry}
            />
          )
        ) : stage === STAGE.SELECT_PROFILE ? (
          !standaloneStore ||
          !standaloneStore.marketplaceType ||
          (["amazon", "amazon_vendor", "dsp"].includes(
            standaloneStore.marketplaceType
          ) &&
            !standaloneStore.marketplaceCountry) ? (
            <AmazonSelectAdvertisingProfilePage
              store={store}
              onSuccess={advertisingOnSuccess}
            />
          ) : (
            <StandaloneAdvertisingProfilePage
              {...{
                marketplace: standaloneStore.marketplaceType,
                countryCode: standaloneStore.marketplaceCountry || undefined,
                selectProfile,
                onSuccess: profileOnSuccess,
              }}
            />
          )
        ) : stage === STAGE.SELECT_ADVERTISER &&
          standaloneStore &&
          standaloneStore.merchantId &&
          standaloneStore.marketplaceType &&
          standaloneStore.marketplaceCountry &&
          dspAdditions.profileId &&
          dspAdditions.entityId ? (
          <SelectAdvertiser
            mid={standaloneStore.merchantId}
            marketplaceType={standaloneStore.marketplaceType}
            countryCode={standaloneStore.marketplaceCountry}
            profileId={dspAdditions.profileId}
            entityId={dspAdditions.entityId}
            selectAdvertiser={selectAdvertiser}
            onSuccess={() => setStage(STAGE.RENAME)}
          />
        ) : (
          stage === STAGE.RENAME &&
          standaloneStore &&
          standaloneStore.marketplaceType &&
          standaloneStore.marketplaceCountry &&
          standaloneStore.merchantId && (
            <StandaloneStoreName
              marketplaceType={standaloneStore.marketplaceType}
              marketplaceSubtype={
                standaloneStore.marketplaceSubtype || "advertising"
              }
              countryCode={standaloneStore.marketplaceCountry}
              mid={standaloneStore.merchantId}
              onSuccess={setWholeFlowDone}
            />
          )
        )}
      </Grid>
    </WrapperGrid>
  );
};

export default AdvertisingConnectionPrompt;
