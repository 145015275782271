import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import { connectYahooJapan } from "~/store/connections/connectYahooJapan.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useYahooJapanAuthcodeAuth from "~/hooks/useYahooJapanAuthcodeAuth";
import { validateCriteoAuth } from "~/store/connections/connectCriteo.redux";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

interface YahooJapanConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const YahooJapanConnectPage: React.FC<YahooJapanConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);

  const onAuthError = React.useCallback((error: string) => {
    setError(t("connectWizard.connectionError"));
    console.error(error);
  }, []);

  const onAuthCode = React.useCallback(
    async (code: string) => {
      try {
        await connectYahooJapan(code);
        onSuccess();
      } catch (error) {
        const errorMessage = (error as Error).message || "Unknown Error";
        onAuthError(errorMessage);
      }
    },
    [dispatch]
  );

  const triggerSignin = useYahooJapanAuthcodeAuth(onAuthCode, onAuthError);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <GridWrapper id="yahoo-japan-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Medium color="textSecondary">
            {t("connectWizard.yahooJapanLinkTitle")}
          </Medium>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <RaisedButton color="primary" onClick={handleSignin}>
              <StyledWrapper>
                <StyledChildren>
                  {t("connectWizard.connectButton")}
                </StyledChildren>
              </StyledWrapper>
            </RaisedButton>
          </Grid>
        </Grid>
        <>
          {error && (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <ErrorMessage color="error">{error}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default YahooJapanConnectPage;
