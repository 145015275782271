export const RESPONSE_CODE = {
  SUCCESS: 0,
  REFRESH_CONNECTION_ERROR: -1,
  INVALID_TOKEN: -2,
  GENERAL_ERROR: -3,
};

export const isHttpResponseValid = (statusCode) =>
  // eslint-disable-next-line no-magic-numbers
  statusCode >= 200 && statusCode < 400;
