import { Box, Typography, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import ConnectStoresImg from "../../../img/connect_stores.jpg";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import PieChart from "../../../components/charts/pieChart/pieChart";
import RaisedButton from "../../../components/buttons/raisedButton";
import SalesMixPieChartLegendItem from "./salesMixPieChartLegendItem";
import { StringMappingType } from "typescript";
import { get } from "lodash";
import { getConvertedValue } from "~/utils/currencyUtils";
import { getShopName } from "~/utils/marketplaceUtils";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MAX_STORES_DISPLAYED = 3;

interface NoMarginProps {
  noMargin?: boolean;
}

const ContentWrapper = styled.div<NoMarginProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ theme, noMargin }) => (noMargin ? 0 : theme.spacing(1))}px;
  flex-grow: 1;
`;

const StyledImg = styled.img`
  max-height: 80%;
  max-width: 80%;
  margin: auto;
  flex-grow: 0;
`;

export interface SalesMixEntry {
  currency: string;
  marketplace: {
    market: string;
    countryCode: string;
  };
  percentage: number;
  sales: StringMappingType;
  store: string;
}

interface ChartDataEntry {
  name: string;
  value: number;
  percentage: number;
  color: string;
  store?: SalesMixEntry;
  tooltipContent?: string;
  legendContent?: JSX.Element;
}

interface SalesMixPieChartContents {
  salesMix: {
    data: SalesMixEntry[];
  };
  compact?: boolean;
}

const SalesMixPieChartContents = memo<SalesMixPieChartContents>(
  function SalesMixPieChartContents({ salesMix, compact }) {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();

    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const currentFilter = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );
    const userInfo = useTypedSelector((state) => state.user);
    const filteredStores = useTypedSelector((state) =>
      get(state, "mystore.filteredStores.stores", [])
    );

    const salesMixData =
      salesMix &&
      salesMix.data &&
      salesMix.data
        .map((store) => {
          const totalSalesValue = getConvertedValue(
            currencyRates,
            store.currency,
            currentCurrency,
            store.sales
          );
          return {
            ...store,
            convertedSalesValue: totalSalesValue,
          };
        })
        .sort((a, b) => b.convertedSalesValue - a.convertedSalesValue);

    const rankedChartShades = [
      theme.palette.secondary.dark,
      theme.palette.secondary.main,
      theme.palette.secondary.light,
    ];

    const chartData: ChartDataEntry[] = salesMixData
      ? salesMixData.slice(0, MAX_STORES_DISPLAYED).map((data, index) => ({
          name: data.store,
          value: data.convertedSalesValue,
          percentage: data.percentage,
          color: rankedChartShades[index] || theme.palette.chart.gray,
          store: data,
        }))
      : [];
    const otherStores = salesMixData
      ? salesMixData.slice(MAX_STORES_DISPLAYED)
      : [];
    const otherStoresSalesValue = otherStores.reduce(
      (acc, curr) => acc + curr.convertedSalesValue,
      0
    );
    const otherStoresSalesPercentage = otherStores.reduce(
      (acc, curr) => acc + curr.percentage,
      0
    );
    if (otherStores.length > 0) {
      chartData.push({
        name: t("dashboardWidget.salesMix.otherStoresLabel"),
        value: otherStoresSalesValue,
        percentage: otherStoresSalesPercentage,
        color: theme.palette.chart.gray,
      });
    }

    const isCurrentFilterNotEmpty =
      !isEmpty(get(currentFilter, "tags")) ||
      !isEmpty(get(currentFilter, "countries")) ||
      !isEmpty(get(currentFilter, "marketplaces"));

    chartData.forEach((data) => {
      const store = data.store;
      if (userInfo && filteredStores && store) {
        data.tooltipContent = `${getShopName(
          filteredStores,
          store.marketplace.market,
          store.store
        )}: ${data.percentage}%`;
        data.legendContent = (
          <SalesMixPieChartLegendItem
            storeName={
              getShopName(
                filteredStores,
                store.marketplace.market,
                store.store
              ) || store.store
            }
            countryCode={store.marketplace.countryCode}
            market={store.marketplace.market}
            salesValue={data.value}
            piePercentage={data.percentage}
            currentCurrency={currentCurrency}
            compact={compact}
          />
        );
      } else {
        data.tooltipContent = `${data.name}: ${data.percentage}%`;
        data.legendContent = (
          <SalesMixPieChartLegendItem
            storeName={data.name}
            salesValue={data.value}
            piePercentage={data.percentage}
            currentCurrency={currentCurrency}
            noMargin
            compact={compact}
          />
        );
      }
    });

    return !(chartData.length > 1) &&
      !isCurrentFilterNotEmpty &&
      !userInfo?.roleSettings?.hideStoreConnectionWizard ? (
      <ContentWrapper>
        <Box mb={1} display="flex" alignItems="center">
          <StyledImg src={ConnectStoresImg} />
        </Box>
        <Box mb={1} ml={1} mr={1}>
          <Typography align="center" gutterBottom>
            Chart is not available. Connect{" "}
            {chartData.length === 0 ? "a" : "another"} marketplace to view this
            chart.
          </Typography>
        </Box>
        <Box mb={1}>
          <RaisedButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push("/connectStore/select")}
          >
            CONNECT NOW
          </RaisedButton>
        </Box>
      </ContentWrapper>
    ) : !(chartData.length > 1) && isCurrentFilterNotEmpty ? (
      <ContentWrapper>
        <Box mb={1} display="flex" alignItems="center">
          <StyledImg src={ConnectStoresImg} />
        </Box>
        <Box mb={1} ml={1} mr={1}>
          <Typography align="center" gutterBottom>
            {t("generic.noDataAvailableMessage")}
          </Typography>
        </Box>
      </ContentWrapper>
    ) : (
      <ContentWrapper noMargin>
        <PieChart {...{ data: chartData }} />
      </ContentWrapper>
    );
  }
);

export default SalesMixPieChartContents;
