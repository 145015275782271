import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";

import AdSpendInvestmentVsBudgetWidget from "../widgets/forecasting/adSpendInvestmentVsBudget";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import React from "react";
import { get } from "lodash";
import { memo } from "react";
import moment from "moment";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AdSpendInvestmentVsBudgetParams {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype?: string;
}

const AdSpendInvestmentVsBudget = memo<AdSpendInvestmentVsBudgetParams>(
  function AdSpendInvestmentVsBudget({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
  }) {
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const currentCurrency = useTypedSelector((state) =>
      get(
        state,
        "persistentAppSettings.setting.data.currentCurrency",
        DEFAULT_CURRENCY
      )
    );
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const userInfo = useTypedSelector((state) => state.user);

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    return (
      <AdSpendInvestmentVsBudgetWidget
        currentCurrency={currentCurrency}
        includeTax={includeTax}
        mid={mid}
        currentRange={currentRange}
        countryCode={countryCode}
        marketplaceSubtype={marketplaceSubtype}
        marketplaceType={marketplaceType}
        userInfo={userInfo}
        currencyRates={currencyRates}
      />
    );
  }
);

export default AdSpendInvestmentVsBudget;
