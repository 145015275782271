import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo, useRef } from "react";

import { AttainmentOrLimit } from "../charts/attainmentOrLimit";
import { InfoOutlined } from "@material-ui/icons";
import LargeNumber from "./largeNumber";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const InlineIconButton = styled(InfoOutlined)`
  cursor: pointer;
  font-size: 1rem;
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const LightHeading = styled(Typography)`
  margin: 5px 0 5px 5px;
  font-weight: 300;
`;

interface AchievementTextProps {
  title: string;
  tooltip?: string;
  currentCurrency: string;
  currency: string;
  lockLayout?: boolean;
  currentValue: number;
  thresholdValue: number;
  lowThreshold?: number;
}

export const AchievementText = memo<AchievementTextProps>(
  function AchievementText({
    title,
    tooltip,
    currentCurrency,
    lockLayout,
    currentValue,
    thresholdValue,
    lowThreshold,
    currency,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const headingContainerRef = useRef<HTMLDivElement>(null);
    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    return (
      <Grid
        container
        alignItems="center"
        justifyContent={smDown ? "center" : "flex-start"}
        spacing={2}
        id="total-generic-money-wrapper"
      >
        <Grid
          container
          item
          wrap="wrap"
          alignItems="center"
          xs={lockLayout ? 12 : 6}
          md={12}
        >
          <Box mr="5px">
            <Typography variant="h3">{title}</Typography>
          </Box>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InlineIconButton aria-label="info" />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={lockLayout ? 12 : 6} md={12}>
          <Grid container alignItems="center">
            <LargeNumber
              variant="h2"
              headingContainerRef={headingContainerRef}
              value={formatCurrencyRounded(
                thresholdValue,
                currencyRates,
                currency,
                currentCurrency
              )}
            />
            <LightHeading variant="h3" color="textSecondary">
              {currentCurrency}
            </LightHeading>
          </Grid>
          <Tooltip title={t("tooltip.forecastAttainment")}>
            <Grid container alignItems="center">
              <AttainmentOrLimit
                {...{
                  currentValue,
                  thresholdValue,
                  lowThreshold,
                  variant: "h5",
                }}
              />
              <LightHeading variant="h3" color="textSecondary">
                {t("achievementText.attained")}
              </LightHeading>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default AchievementText;
