import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export const DemoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  arrow: {
    color: theme.palette.warning.main,
  },
}))(Tooltip);
