import "draft-js/dist/Draft.css";

import { Box, Theme } from "@material-ui/core";
import {
  ContentBlock,
  Editor,
  EditorCommand,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import styled, { StyledComponentProps } from "styled-components";

import BlockStyleControls from "./blockStyleControls";
import InlineStyleControls from "./inlineStyleControls";
import React from "react";

export const EditorControls = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  padding: 1rem 0.5rem;
  user-select: none;
  background-color: ${({ theme }) => theme.palette.border.main};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

interface CommentsProps {
  editMode?: boolean;
  includeControls?: boolean;
}

const CommentsEditor = styled(Box)`
  & .DraftEditor-root {
    display: flex;
    font-family: "marine";
    font-size: 0.875rem;
    min-height: ${({ includeControls }: CommentsProps) =>
      includeControls ? "300px" : "none"};
    color: ${({
      editMode,
      includeControls,
      theme,
    }: CommentsProps & { theme: Theme }) =>
      includeControls && !editMode
        ? theme.palette.text.secondary
        : theme.palette.text.primary};
    background-color: ${({
      editMode,
      includeControls,
      theme,
    }: CommentsProps & { theme: Theme }) =>
      includeControls && !editMode ? theme.palette.disabled.light : "none"};
    padding: 2rem;
  }
  & .DraftEditor-editorContainer {
    width: 100%;
    display: flex;
  }
  & .public-DraftEditor-content {
    width: 100%;
  }
  & * {
    box-sizing: border-box;
  }
  & .public-DraftEditorPlaceholder-root {
    width: auto;
  }
`;

interface RichTextEditorProps {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  editMode: boolean;
  editor?: React.MutableRefObject<Editor | null>;
  includeControls?: boolean;
}

const RichTextEditor = ({
  editorState,
  setEditorState,
  editMode,
  editor,
  includeControls,
}: RichTextEditorProps) => {
  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const toggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const getBlockStyle = (block: ContentBlock) => {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      default:
        return "";
    }
  };

  const mapKeyToEditorCommand = (e: React.KeyboardEvent<{}>) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  const focusEditor = () => {
    if (editMode) {
      editor?.current?.focus();
    }
  };

  return (
    <CommentsEditor
      display="flex"
      flexDirection="column"
      includeControls={includeControls}
      editMode={editMode}
      onClick={focusEditor}
    >
      {includeControls && (
        <EditorControls>
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
            editMode={editMode}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
            editMode={editMode}
          />
        </EditorControls>
      )}
      <Editor
        ref={editor}
        blockStyleFn={getBlockStyle}
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={mapKeyToEditorCommand}
        readOnly={!editMode}
        placeholder="No saved notes."
        spellCheck={true}
      />
    </CommentsEditor>
  );
};

export default RichTextEditor;
