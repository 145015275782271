import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  ToolbarProps,
} from "@material-ui/core";
import {
  DEFAULT_CURRENCY,
  upsertPersistentAppSettings,
} from "~/store/persistentAppSettings.redux";
import React, { memo } from "react";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import Breadcrumbs from "../breadcrumbs";
import CurrencyDropdown from "../toolbarComponents/currencyDropdown";
import FilterToolbar from "../filterToolbar/filterToolbar";
import { MenuRounded } from "@material-ui/icons";
import MyStoresDropdown from "./myStoresDropdown";
import { SelectedItem } from "../sideNavigation/sideMenu";
import { StoreState } from "~/typedef/store";
import SyncButton from "~/components/buttons/syncButton";
import TimePeriodDropdown from "../toolbarComponents/timePeriodDropdown";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const MenuIcon = styled(IconButton)`
  align-self: flex-start;
`;

type StyledToolbarProps = ToolbarProps &
  ThemeProps<DefaultTheme> & { open: boolean };
const StyledToolbar = styled(Toolbar)<StyledToolbarProps>`
  justify-content: center;
  padding-top: 0.5rem;
  align-self: flex-end;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  background-color: transparent;
  ${({ open, theme }) =>
    open
      ? {
          width: `calc(100% - ${theme.spacing(6)}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      : {
          width: `calc(100% - ${theme.spacing(6)}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.down("sm")]: {
            width: `calc(100% - ${theme.spacing(6)}px)`,
          },
          [theme.breakpoints.down("xs")]: {
            width: `calc(100% - ${theme.spacing(4)}px)`,
          },
        }}
`;

const FlexItem = styled(Grid)`
  display: flex;
  align-items: center;
`;

interface SecondaryToolbarProps {
  selectedItem: SelectedItem;
  toggleDrawer: () => void;
  open: boolean;
  hideDateSelector?: boolean;
  hideStoreSelector?: boolean;
  hideCurrencySelector?: boolean;
  includeFilters?: boolean;
  marginLeftPx: string;
  marginRightPx: string;
}

const DesktopToolbar = memo<SecondaryToolbarProps>(function DesktopToolbar({
  selectedItem,
  hideDateSelector,
  hideStoreSelector,
  hideCurrencySelector,
  includeFilters,
}) {
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const dispatchSwitchCurrency = (currency: string) =>
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            currentCurrency: currency,
          },
        },
        user?.isDemoMode ? false : true
      )
    );

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid container item xs={12} md={4} lg={3} xl={6}>
        <Breadcrumbs selectedItem={selectedItem} />
      </Grid>
      {includeFilters && (
        <Grid
          container
          item
          xs={12}
          md={8}
          lg={8}
          xl={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <FilterToolbar
            {...{
              containerWidth: 700,
              filterWidth: 220,
              isDemoMode: user.isDemoMode,
              rightAlign: true,
            }}
          />
        </Grid>
      )}
      {(!hideDateSelector || !hideCurrencySelector) && (
        <Grid
          container
          item
          xs={12}
          md={8}
          lg={hideStoreSelector ? 9 : 5}
          xl={hideStoreSelector ? 6 : 3}
          justifyContent="flex-end"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {!hideDateSelector && (
              <>
                <Box display="flex" alignItems="flex-end" height="100%">
                  <SyncButton />
                </Box>
                <TimePeriodDropdown />
              </>
            )}
            {!hideCurrencySelector && (
              <Box pl={2}>
                <CurrencyDropdown
                  currentCurrency={currentCurrency}
                  switchCurrency={dispatchSwitchCurrency}
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {!hideStoreSelector && (
        <Grid item xs={12} lg={4} xl={3}>
          <MyStoresDropdown />
        </Grid>
      )}
    </Grid>
  );
});

const MobileToolbar = memo<SecondaryToolbarProps>(function MobileToolbar({
  selectedItem,
  toggleDrawer,
  open,
  hideDateSelector,
  hideStoreSelector,
  hideCurrencySelector,
  includeFilters,
}) {
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const dispatchSwitchCurrency = (currency: string) =>
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            currentCurrency: currency,
          },
        },
        user?.isDemoMode ? false : true
      )
    );

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      spacing={1}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <FlexItem item>
          {!open && (
            <MenuIcon
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuRounded />
            </MenuIcon>
          )}
          <Breadcrumbs selectedItem={selectedItem} />
        </FlexItem>
      </Grid>
      {includeFilters && (
        <Grid container item xs={12}>
          <FilterToolbar
            {...{
              containerWidth: 700,
              filterWidth: 220,
              isDemoMode: user.isDemoMode,
            }}
          />
        </Grid>
      )}
      {!hideDateSelector && (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          wrap="wrap"
        >
          <SyncButton />
          <Grid item xs>
            <TimePeriodDropdown minWidth={300} fullWidth />
          </Grid>
        </Grid>
      )}
      {!hideCurrencySelector && (
        <Grid item xs={4}>
          <CurrencyDropdown
            fullWidth
            currentCurrency={currentCurrency}
            switchCurrency={dispatchSwitchCurrency}
          />
        </Grid>
      )}
      {!hideStoreSelector && (
        <Grid item xs={8}>
          <MyStoresDropdown />
        </Grid>
      )}
    </Grid>
  );
});

export const SecondaryToolbar = memo<SecondaryToolbarProps>(
  // The purpose of defining it as a named function is to be able
  // to view the component name in the React DevTools as opposed to "Anonymous"
  function SecondaryToolbar(props) {
    const { marginLeftPx, marginRightPx } = props;
    return (
      <StyledToolbar open={props.open} id="side-nav-toolbar">
        <Box width="100%" mr={marginRightPx} ml={marginLeftPx}>
          <Hidden smDown>
            <DesktopToolbar {...props} />
          </Hidden>
          <Hidden mdUp>
            <MobileToolbar {...props} />
          </Hidden>
        </Box>
      </StyledToolbar>
    );
  }
);
