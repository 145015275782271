/* eslint-disable no-empty-function */
import { Grid, MenuItem, Typography, useTheme } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import { INTERVAL } from "~/store/utils/dateTimeUtils";
import { OptionText } from "~/components/select/filterSelect";
import SearchFilter from "../../adTable/searchFilter";
import { ToolbarSelect } from "~/components/select/toolbarSelect";
import moment from "moment-timezone";
import { shouldLimitTimezones } from "~/components/dateRangePicker/fullDateTimePicker";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PRIORITY_TIMEZONES = [
  "America/Los_Angeles",
  "Europe/London",
  "Europe/Berlin",
  "Asia/Riyadh",
  "Asia/Tokyo",
  "Australia/Sydney",
];

interface StyleProps {
  $width?: number | null;
}
const FilterMenuItem = styled(MenuItem)`
  width: ${({ $width }: StyleProps) => $width}px;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

interface TimezoneSelectProps {
  selectedTimezone: string;
  handleSelect: (value: string) => void;
  interval?: INTERVAL;
  // if provided, limits the timezones displayed in this selector
  // to values for which filterTimezones returns true.
  filterTimezones?: (timezone: string, interval?: INTERVAL) => boolean;
  tooltip?: string;
}
const TimezoneSelect = memo(
  ({
    handleSelect,
    selectedTimezone,
    interval,
    filterTimezones = () => true,
    tooltip,
  }: TimezoneSelectProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [searchText, setSearchText] = useState("");
    const [filteredTimezones, setFilteredTimezones] = useState<string[]>([]);
    const [menuWidth, setMenuWidth] = useState(
      Boolean(anchorEl) ? anchorEl?.offsetWidth : null
    );

    useEffect(() => {
      const timezoneOptions = moment.tz
        .names()
        .filter((tz) => filterTimezones(tz, interval))
        .sort((a, b) =>
          PRIORITY_TIMEZONES.includes(a)
            ? PRIORITY_TIMEZONES.includes(b)
              ? 0
              : -1
            : 1
        );
      const filteredOptions = timezoneOptions.filter((zone) =>
        zone.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTimezones(filteredOptions || []);
    }, [searchText, interval]);

    useEffect(() => {
      if (
        shouldLimitTimezones(interval) &&
        filteredTimezones?.length &&
        !filteredTimezones.includes(selectedTimezone)
      ) {
        handleSelect(filteredTimezones[0]);
      }
    }, [interval, filteredTimezones]);

    useEffect(() => {
      setMenuWidth(Boolean(anchorEl) ? anchorEl?.offsetWidth : null);
    }, [setMenuWidth, anchorEl]);

    const renderAvailableOptions = () => {
      return filteredTimezones.map((option, index) => {
        return (
          <FilterMenuItem
            key={option + index}
            $width={menuWidth}
            onClick={() => {
              handleSelect(option);
            }}
          >
            <Typography variant="body2">
              {option.toUpperCase() === "GMT" ? t("timezone.GMT") : option}
            </Typography>
          </FilterMenuItem>
        );
      });
    };

    const renderOptions = () => {
      return [
        <FilterMenuItem key="search" $width={menuWidth}>
          <SearchFilter setSearchText={setSearchText} />
        </FilterMenuItem>,
        ...renderAvailableOptions(),
      ];
    };
    return (
      <ToolbarSelect
        id="report-timezone-select"
        title={t("createReport.selectTimeZone")}
        tooltip={tooltip}
        renderOptions={renderOptions}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setMenuWidth={setMenuWidth}
        fullWidth
        rightAlign={true}
        displayComponent={
          <FlexGrid item>
            <OptionText variant="body2" noWrap $condensed>
              {selectedTimezone?.toUpperCase() === "GMT"
                ? t("timezone.GMT")
                : selectedTimezone}
            </OptionText>
          </FlexGrid>
        }
      />
    );
  }
);

export default TimezoneSelect;
