import cookies from "browser-cookies";
import { fetchPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { fetchSubscription } from "~/store/subscriptions.redux";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";

export async function handleLoginSucceed(
  user,
  history,
  destination,
  dispatch,
  fromRegistration = false
) {
  const isNewUser = user.isNewUser;
  if (isNewUser && window.$FPROM) {
    window.$FPROM.trackSignup(
      {
        email: user.email,
      },
      () => {
        // eslint-disable-next-line no-console
        console.log("FirstPromoter callback received");
      }
    );
  }

  const accessToken = cookies.get("accessToken");

  if (accessToken) {
    jwtInterceptor(null, location.pathname);
  }

  await dispatch(fetchSubscription());
  await dispatch(fetchPersistentAppSettings({ user }));

  const bypassConnect = destination.includes("direct=true");

  if (user.email && user.email.endsWith("@wechat")) {
    // redirect to WeChat email entry page if user hasn't set a proper email yet
    history.push("/wechat");
  } else if ((!isNewUser || bypassConnect) && destination !== null) {
    if (fromRegistration) {
      history.push(destination, { from: "registrationPage" });
    } else {
      history.push(destination);
    }
  } else {
    history.push("/overview");
  }
}
