import { FORECAST_TYPE, ForecastValueItem } from "./types";
import moment, { Moment } from "moment";

import { DayCell } from "./dayCell";
import { DayHeader } from "./dayHeader";
import { INTERVAL } from "~/store/utils/dateTimeUtils";
import MessageDialog from "../dialogs/messageDialog";
import { MonthHeader } from "./monthHeader";
import { Prompt } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export const getTimestampForCalendar = (date: Moment, timezone: string) => {
  const timestamp = moment(date).tz(timezone).startOf("d").unix();
  return timestamp;
};

interface CalendarProps {
  from: Moment;
  to: Moment;
  labels: "day" | "month";
  data: ForecastValueItem;
  dataTimezone: string;
  onEdit?: (
    item: {
      type: string;
      amount: number;
      referenceLevel: string;
    },
    date: Moment
  ) => void;
  colors: Record<FORECAST_TYPE, string>;
}

export const Calendar: React.FC<CalendarProps> = ({
  from,
  to,
  dataTimezone,
  labels,
  data,
  onEdit,
  colors,
}) => {
  const [editable, setEditable] = React.useState(false);
  const [supportDialogOpen, setSupportDialogOpen] = React.useState(false);

  const { t } = useTranslation();

  const onSupportDialogClose = () => {
    setSupportDialogOpen(false);
  };

  const isoWeekdays = moment.weekdaysShort();
  //To make it ISO, put first element last
  isoWeekdays.push(isoWeekdays.splice(0, 1)[0]);
  // Create labels
  const cells = isoWeekdays.map((isoWeekday, i) =>
    labels === "month" ? (
      <MonthHeader key={isoWeekday} from={from} printMonth={i === 0} />
    ) : (
      <DayHeader key={isoWeekday} isoWeekday={isoWeekday} />
    )
  );
  const handleBlockedNavigation = (_nextLocation: object) => {
    if (!supportDialogOpen && editable) {
      setSupportDialogOpen(true);
      return false;
    } else {
      return true;
    }
  };

  // Pre fill blank parts of calendar
  const startOfMonth =
    labels === "month" ? moment(from).startOf("month") : moment(from);
  let dayOfWeek = startOfMonth.isoWeekday();
  let isFirst = true;

  if (dayOfWeek > 0) {
    for (let i = 1; i < dayOfWeek; i++) {
      cells.push(
        <DayCell
          key={`disabled-day-placeholder-${i}`}
          setEditable={setEditable}
          setSupportDialogOpen={setSupportDialogOpen}
          date={moment(startOfMonth)}
          editable={editable}
          printDay={false}
          printMonth={false}
          editView={labels === "day"}
          disabled={true}
          colors={colors}
        />
      );
    }
  }

  // Fill in dates in month prior to selection
  while (startOfMonth.isBefore(from)) {
    cells.push(
      <DayCell
        setSupportDialogOpen={setSupportDialogOpen}
        date={moment(startOfMonth)}
        setEditable={setEditable}
        key={`disabled-day-placeholder-${startOfMonth.format("YYYYMMDD")}`}
        editable={editable}
        printDay={true}
        printMonth={false}
        editView={labels === "day"}
        disabled={true}
        colors={colors}
      />
    );
    startOfMonth.add(1, "day");
  }

  const workingFrom = moment(from);
  const workingTo = moment(to);

  // Fill the actual date selected
  while (workingFrom.isSameOrBefore(workingTo)) {
    const printMonth =
      (isFirst || workingFrom.date() === 1) && labels === "day";
    dayOfWeek = workingFrom.isoWeekday();
    cells.push(
      <DayCell
        setSupportDialogOpen={setSupportDialogOpen}
        date={moment(workingFrom)}
        printDay={true}
        printMonth={printMonth}
        editable={editable}
        setEditable={setEditable}
        data={data[getTimestampForCalendar(workingFrom, dataTimezone)]}
        key={moment(workingFrom).format("YYYYMMDD")}
        onEdit={onEdit}
        editView={labels === "day"}
        colors={colors}
      />
    );
    workingFrom.add(1, INTERVAL.DAYS);
    isFirst = false;
  }

  // Fill the blank parts in the end range
  const endOfRange =
    labels === "month" ? moment(to).add(1, "day") : moment(to).endOf("month");
  // Fill in dates in month after selection
  while (endOfRange.isBefore(moment(to).endOf("month"))) {
    cells.push(
      <DayCell
        setSupportDialogOpen={setSupportDialogOpen}
        setEditable={setEditable}
        key={`disabled-day-placeholder-${endOfRange.format("YYYYMMDD")}`}
        editable={editable}
        date={moment(endOfRange)}
        printDay={true}
        onEdit={onEdit}
        printMonth={false}
        editView={labels === "day"}
        disabled={true}
        colors={colors}
      />
    );
    endOfRange.add(1, "day");
  }

  const lastDay =
    labels === "month"
      ? endOfRange.subtract(1, "day").isoWeekday()
      : moment(to).isoWeekday();

  for (let i = lastDay; i <= 6; i++) {
    cells.push(
      <DayCell
        setSupportDialogOpen={setSupportDialogOpen}
        date={moment(startOfMonth)}
        setEditable={setEditable}
        editable={editable}
        key={`disabled-day-placeholder-end-${i}`}
        printDay={false}
        onEdit={onEdit}
        printMonth={false}
        editView={labels === "day"}
        disabled={true}
        colors={colors}
      />
    );
  }

  return (
    <>
      {cells}
      <Prompt message={handleBlockedNavigation} />
      <MessageDialog
        title={t("forecasting.unsavedChanges")}
        open={supportDialogOpen}
        onClose={onSupportDialogClose}
        messages={[t("forecasting.supportMessage")]}
      />
    </>
  );
};
