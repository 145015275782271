/* eslint-disable no-magic-numbers */
import { Container } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/core";

const PageBlockBase = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  max-width: none;
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      margin-top: 0px;
    }`}
`;

const PageBlock = (props) => (
  <PageBlockBase {...props}>{props.children}</PageBlockBase>
);

export default withTheme(PageBlock);
