import { Box, Grid, useTheme } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import LineChart from "~/components/charts/lineChart/lineChart";
import NeedToReconnectBanner from "~/components/alert/needToReconnect";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import { fetchGenericBuyboxTrend } from "~/store/mystore/genericBuybox.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface BuyBoxHistoryProps {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  marketplace: string;
  report?: boolean;
  timezone: string;
  condensed?: boolean;
}

const GenericBuyboxTrend = memo(
  ({
    mid,
    currentPeriod,
    currentRange,
    marketplace,
    report,
    timezone,
    condensed,
  }: BuyBoxHistoryProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [canShowData, setCanShowData] = useState(true);

    const data = useTypedSelector(
      (state) => state?.genericBuybox?.history?.data || []
    );

    const params = useTypedSelector(
      (state) => state?.genericBuybox?.history?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.genericBuybox?.history?.fetching || false
    );
    const issues = useTypedSelector((state) => state?.issues?.data);

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        await dispatch(
          fetchGenericBuyboxTrend(
            {
              currentRange: {
                ...currentRange,
                interval: INTERVAL.DAYS,
              },
              mid,
            },
            params
          )
        );
      };
      fetchData();
    }, [currentRange, mid]);

    useEffect(() => {
      if (issues && issues.length && issues.includes("noRetailAnalytics")) {
        setCanShowData(false);
      }
    }, [issues]);

    const footerLink = {
      url: marketplaceLink(marketplace, mid, "buyBoxPerformance"),
      label: t("generic.viewAllLink"),
    };

    return (
      <Panel
        id="widget-buy-box-trend"
        title={t("myStoresWidget.buyBoxTrend.mainTitle")}
        tooltip={t("myStoresWidget.buyBoxTrend.tooltip")}
        footerLink={condensed ? footerLink : undefined}
        content={
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid container item xs={12} justifyContent="center">
                {canShowData ? (
                  <LineChart
                    title={t(CHART_TITLES[currentPeriod])}
                    currentPeriod={currentPeriod}
                    chartData={data}
                    isLoading={loading}
                    line={{
                      key: "buyboxWinPercentage",
                      colour: theme.palette.primary.main,
                    }}
                    xKey={"startTime"}
                    report={report}
                    timezone={timezone}
                  />
                ) : (
                  <NeedToReconnectBanner
                    marketplace={marketplace}
                    flat={true}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default GenericBuyboxTrend;
