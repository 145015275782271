import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { ColouredInlineIconButton } from "~/icons/inlineIconButton";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const GOOGLE_STRATEGY = {
  COMMISSION: {
    name: "advertisingDashboardWidget.biddingStrategy.commission.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.commission.tooltip",
  },
  ENHANCED_CPC: {
    name: "advertisingDashboardWidget.biddingStrategy.enhancedCpc.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.enhancedCpc.tooltip",
  },
  MANUAL_CPA: {
    name: "advertisingDashboardWidget.biddingStrategy.manualCpa.title",
    description: "advertisingDashboardWidget.biddingStrategy.manualCpa.tooltip",
  },
  MANUAL_CPC: {
    name: "advertisingDashboardWidget.biddingStrategy.manualCpc.title",
    description: "advertisingDashboardWidget.biddingStrategy.manualCpc.tooltip",
  },
  MANUAL_CPM: {
    name: "advertisingDashboardWidget.biddingStrategy.manualCpm.title",
    description: "advertisingDashboardWidget.biddingStrategy.manualCpm.tooltip",
  },
  MANUAL_CPV: {
    name: "advertisingDashboardWidget.biddingStrategy.manualCpv.title",
    description: "advertisingDashboardWidget.biddingStrategy.manualCpv.tooltip",
  },
  MAXIMIZE_CONVERSIONS: {
    name: "advertisingDashboardWidget.biddingStrategy.maximizeConversions.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.maximizeConversions.tooltip",
  },
  MAXIMIZE_CONVERSION_VALUE: {
    name: "advertisingDashboardWidget.biddingStrategy.maximizeConversionValue.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.maximizeConversionValue.tooltip",
  },
  PAGE_ONE_PROMOTED: {
    name: "advertisingDashboardWidget.biddingStrategy.pageOnePromoted.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.pageOnePromoted.tooltip",
  },
  PERCENT_CPC: {
    name: "advertisingDashboardWidget.biddingStrategy.percentCpc.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.percentCpc.tooltip",
  },
  TARGET_CPA: {
    name: "advertisingDashboardWidget.biddingStrategy.targetCpa.title",
    description: "advertisingDashboardWidget.biddingStrategy.targetCpa.tooltip",
  },
  TARGET_CPM: {
    name: "advertisingDashboardWidget.biddingStrategy.targetCpm.title",
    description: "advertisingDashboardWidget.biddingStrategy.targetCpm.tooltip",
  },
  TARGET_IMPRESSION_SHARE: {
    name: "advertisingDashboardWidget.biddingStrategy.targetImpressionShare.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.targetImpressionShare.tooltip",
  },
  TARGET_OUTRANK_SHARE: {
    name: "advertisingDashboardWidget.biddingStrategy.targetOutrankShare.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.targetOutrankShare.tooltip",
  },
  TARGET_ROAS: {
    name: "advertisingDashboardWidget.biddingStrategy.targetRoas.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.targetRoas.tooltip",
  },
  TARGET_SPEND: {
    name: "advertisingDashboardWidget.biddingStrategy.targetSpend.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.targetSpend.tooltip",
  },
  UNKNOWN: {
    name: "advertisingDashboardWidget.biddingStrategy.unknown.title",
    description: "advertisingDashboardWidget.biddingStrategy.unknown.tooltip",
  },
  UNSPECIFIED: {
    name: "advertisingDashboardWidget.biddingStrategy.unspecified.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.unspecified.tooltip",
  },
};

const STRATEGY = {
  legacyForSales: {
    name: "advertisingDashboardWidget.biddingStrategy.legacyForSales.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.legacyForSales.tooltip",
  },
  autoForSales: {
    name: "advertisingDashboardWidget.biddingStrategy.autoForSales.title",
    description:
      "advertisingDashboardWidget.biddingStrategy.autoForSales.tooltip",
  },
  manual: {
    name: "advertisingDashboardWidget.biddingStrategy.manual.title",
    description: "advertisingDashboardWidget.biddingStrategy.manual.tooltip",
  },
  ...GOOGLE_STRATEGY,
};

const TooltipContainer = styled.div`
  display: flex;
`;

export const BiddingStrategyCell = memo(({ cell }) => {
  const { t } = useTranslation();
  const { value } = cell.value;

  return (
    <Grid container spacing={1} justifyContent="flex-start">
      <Grid item>
        <TooltipContainer>
          <Typography variant="body2">
            {STRATEGY[value] ? t(STRATEGY[value].name) : "-"}
          </Typography>
          {STRATEGY[value] && (
            <Tooltip title={t(STRATEGY[value].description)}>
              <ColouredInlineIconButton />
            </Tooltip>
          )}
        </TooltipContainer>
      </Grid>
    </Grid>
  );
});

BiddingStrategyCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};
