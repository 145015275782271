import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CurrentStore, PurchaseOrder } from "~/typedef/store";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { formatCurrency, roundFractionalDigits } from "~/utils/currencyUtils";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import POStatusCell from "~/components/table/cells/poStatusCell";
import Panel from "~/components/panel/panel";
import PurchaseOrderDetails from "./purchaseOrderDetails";
import PurchaseOrderTable from "./purchaseOrdersTable";
import SearchFilter from "~/components/adTable/searchFilter";
import SellerLinkCell from "~/modules/buybox/sellerLinkCell";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import get from "lodash/get";
import i18next from "i18next";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface PurchaseOrdersProps {
  marketplace: string;
  store: CurrentStore;
}

const PurchaseOrders = memo<PurchaseOrdersProps>(function PurchaseOrders({
  marketplace,
  store,
}) {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.user);
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedPO, setSelectedPO] = useState<PurchaseOrder | null>(null);

  const handleListingSelect = useCallback(
    (po) => {
      setSelectedPO(po);
      setDrawerOpen(true);
    },
    [setDrawerOpen]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("purchaseOrder.dateReceived"),
        id: "dateReceived",
        accessor: (row: { dateReceived: string }) =>
          moment(row.dateReceived).locale(i18next.language).format("L"),
        Cell: TextCell,
        isVisible: true,
      },
      {
        Header: t("purchaseOrder.shipBy"),
        id: "shipBy",
        accessor: (row: { shipBy: string }) =>
          row.shipBy
            ? moment(row.shipBy).locale(i18next.language).format("L")
            : "-",
        Cell: TextCell,
        isVisible: true,
      },
      {
        Header: t("purchaseOrder.deliverBy"),
        id: "deliverBy",
        accessor: (row: { deliverBy: string }) =>
          row.deliverBy
            ? moment(row.deliverBy).locale(i18next.language).format("L")
            : "-",
        Cell: TextCell,
        isVisible: true,
      },
      {
        Header: t("purchaseOrder.purchaseOrder"),
        id: "purchaseOrder",
        accessor: "purchaseOrder",
        isVisible: true,
      },
      {
        Header: t("purchaseOrder.submittedUnits"),
        id: "submittedUnits",
        accessor: "submittedUnits",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("purchaseOrder.units"),
        id: "units",
        accessor: "units",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("purchaseOrder.cancelledUnits"),
        tooltip: t("purchaseOrder.cancelledUnitsTooltip"),
        id: "cancelledUnits",
        accessor: "cancelledUnits",
        isVisible: false,
        align: "center",
      },
      {
        Header: t("purchaseOrder.rejectedUnits"),
        tooltip: t("purchaseOrder.rejectedUnitsTooltip"),
        id: "rejectedUnits",
        accessor: "rejectedUnits",
        isVisible: false,
        align: "center",
      },
      {
        Header: t("purchaseOrder.receivedUnits"),
        id: "receivedUnits",
        accessor: "receivedUnits",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("purchaseOrder.confirmationRate"),
        id: "confirmationRate",
        accessor: (row: { confirmationRate: number }) => {
          return `${roundFractionalDigits(row.confirmationRate, 1)}%`;
        },
        isVisible: true,
        align: "center",
      },
      {
        Header: t("purchaseOrder.receiptRate"),
        id: "receiptRate",
        accessor: (row: { receiptRate: number }) => {
          return `${roundFractionalDigits(row.receiptRate, 1)}%`;
        },
        isVisible: true,
        align: "center",
      },
      {
        Header: t("purchaseOrder.uniqueSkus"),
        id: "uniqueSkus",
        accessor: "uniqueSkus",
        Cell: SellerLinkCell,
        isVisible: true,
        getProps: () => ({ handleListingSelect, justify: "center" }),
        align: "center",
      },
      {
        Header: t("purchaseOrder.poValue"),
        id: "poValue",
        accessor: (row: { poValue: number; currency: number }) =>
          formatCurrency(
            row.poValue,
            currencyRates,
            row.currency,
            currentCurrency
          ),
        Cell: ValueCell,
        isVisible: true,
        align: "right",
      },
      {
        Header: t("purchaseOrder.status"),
        id: "status",
        accessor: "status",
        Cell: POStatusCell,
        isVisible: true,
        align: "center",
      },
    ],
    [store?.merchantId, currentRange, currentPeriod, currentCurrency]
  );
  const [myColumns, setMyColumns] = useState<Column[]>(columns);

  useEffect(() => {
    setMyColumns(columns);
  }, [columns]);

  return (
    <DrawerPanel
      containerId="suppressed-drawer-container"
      drawerWidth="900px"
      open={drawerOpen}
      setOpen={setDrawerOpen}
      sidePanel={
        <PurchaseOrderDetails
          selectedPO={selectedPO}
          currentCurrency={currentCurrency}
          currencyRates={currencyRates}
          setDrawerOpen={setDrawerOpen}
        />
      }
      mainPanel={
        <Panel
          id="purchase-orders-table"
          title={t("vendor.purchaseOrders.mainTitle")}
          content={
            <PurchaseOrderTable
              mid={store?.merchantId}
              marketplaceType={store?.marketplace}
              columns={myColumns}
              countryCode={store?.marketplaceCountry}
              currentRange={currentRange}
              searchText={searchText}
            />
          }
          actions={
            <>
              <SearchFilter {...{ setSearchText }} />
              <ColumnSelect
                {...{ columns: myColumns, setColumns: setMyColumns }}
              />
              <DownloadCsv
                {...{
                  mid: store.merchantId,
                  reportType: "purchaseOrders",
                  path: "/api/amazon/vendor/purchaseOrders",
                  params: {
                    marketplace,
                    shopName: store.storeName,
                    mid: store.merchantId,
                    countryCode: store.marketplaceCountry,
                    ...currentRange,
                    searchText,
                    currentCurrency,
                    exchangeRates: currencyRates?.find(
                      (i) => i._id === currentCurrency
                    )?.rates,
                  },
                }}
              />
            </>
          }
        />
      }
    />
  );
});

export default PurchaseOrders;
