import { Box, Grid, useTheme } from "@material-ui/core";
import { CurrencyRate, OrderedRevenueTrend, Range } from "~/typedef/store";
import React, { memo, useCallback, useEffect, useMemo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LineAndBarChart from "~/components/charts/comboChart/lineAndBarChart";
import { User } from "~/typedef/user";
import { fetchOrderedProductChart } from "~/store/mystore/vendor.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OrderedRevenueTableChartParams {
  userInfo: User;
  mid: string;
  productId: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
}

const formatOrderedRevenueData = (
  orderedRevenueData: OrderedRevenueTrend[] | undefined,
  currentCurrency: string,
  currency = "AUD",
  currencyRates: CurrencyRate[]
) => {
  if (!orderedRevenueData) {
    return [];
  }
  return orderedRevenueData.map((data: OrderedRevenueTrend) => {
    return {
      startTime: data.startTime,
      endTime: data.endTime,
      revenue: Number.isFinite(data.revenue)
        ? getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            data.revenue
          )
        : data.revenue,
      units: data.units,
      glanceViews: data.glanceViews,
      unitsPerView: data.unitsPerView,
    };
  });
};

const OrderedRevenueTableChart = memo(
  ({
    userInfo,
    mid,
    productId,
    currentPeriod,
    currentRange,
    currentCurrency,
    timezone,
  }: OrderedRevenueTableChartParams) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const loading = useTypedSelector(
      (state) => state?.vendor?.productOrderedRevenue?.chart?.fetching || false
    );
    const orderedRevenueTrend = useTypedSelector(
      (state) => state?.vendor?.productOrderedRevenue?.chart.data
    );
    const currency = useTypedSelector(
      (state) => state?.vendor?.productOrderedRevenue?.chart.currency
    );

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchOrderedRevenueProductChart();
      };
      fetchData();
    }, [currentRange, mid, productId, userInfo]);

    const dispatchFetchOrderedRevenueProductChart = useCallback(() => {
      dispatch(
        fetchOrderedProductChart({
          user: userInfo,
          currentRange,
          mid,
          productId,
        })
      );
    }, [currentRange, mid, productId, userInfo]);

    const data = useMemo(() => {
      return formatOrderedRevenueData(
        orderedRevenueTrend,
        currentCurrency,
        currency,
        currencyRates
      );
    }, [
      currentRange,
      mid,
      productId,
      userInfo,
      orderedRevenueTrend,
      currentCurrency,
    ]);

    return (
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <LineAndBarChart
              title={t(CHART_TITLES[currentPeriod])}
              currentPeriod={currentPeriod}
              currentCurrency={currentCurrency}
              chartData={data}
              isLoading={loading}
              line={{
                key: "unitsPerView",
                toFixed: 1,
                colour: theme.palette.secondary.main,
              }}
              bar={{
                key: "glanceViews",
                notCurrency: true,
                colour: theme.palette.primary.main,
              }}
              xKey="startTime"
              xKey2="endTime"
              timezone={timezone}
              interval={currentRange?.interval}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default OrderedRevenueTableChart;
