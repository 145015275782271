import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";

import MarketingCampaignSummaryChartAndTotals from "../widgets/overview/marketingCampaignSummaryChartAndTotals";
import React from "react";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OverviewChartTotalWrapperProps {
  showChart?: boolean;
  showTotals?: boolean;
}

const OverviewChartTotalWrapper = memo<OverviewChartTotalWrapperProps>(
  function OverviewChartTotalWrapper({ showChart, showTotals }) {
    const currentFilter = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentFilter || {
          marketplaces: [],
          countries: [],
          tags: [],
        }
    );
    const currentPeriod = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentPeriod ||
        DATETIME_PERIODS.LAST30
    );
    const timezone = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.timezone ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentCompare ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentRange ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          timezone
        )
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentCurrency || ""
    );

    return (
      <MarketingCampaignSummaryChartAndTotals
        {...{
          currentFilter,
          currentPeriod,
          includeTax,
          currentCurrency,
          currentRange,
          showTotals,
          showChart,
          report: false,
          timezone,
        }}
      />
    );
  }
);

export default OverviewChartTotalWrapper;
