import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

const authUrl =
  process.env.INSTACART_AUTH_URL || "https://ads.instacart.com/login";

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "instacart"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.code) {
          onCode(params.code);
          return;
        }

        onError("Invalid response from Instacart");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        authUrl,
        "authPopup-mm-instacart",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
