import { Box, Grid, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
} from "~/store/utils/dateTimeUtils";
import {
  CustomGroupsChartData,
  useVendorCustomGroupChartQuery,
} from "~/store/customGroups/reducer.redux";
import React, { ReactChild, memo, useMemo } from "react";

import { CustomGroup } from "@typedef/customGroups";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import MultiLineChart from "~/components/charts/multiLineChart/multiLineChart";
import NoData from "~/components/loadingIndicator/noData";
import NoGroupsImg from "../../../img/no-custom-groups.png";
import { Panel } from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "@typedef/store";
import { getConvertedValue } from "~/utils/currencyUtils";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const CHART_TYPE_VENDOR = {
  GlanceView: "glanceViews",
  OrderedRevenue: "revenue",
  UnitsSold: "units",
};

interface CustomGroupsChartProps {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentCompare: COMPARISON_PERIOD;
  currentRange: Range;
  currentCurrency: string;
  customGroups?: CustomGroup[];
  selectedGroups: CustomGroup[];
  actions?: ReactChild;
  chartType?: ChartOption;
  report?: boolean;
  conditionalFormatting?: boolean;
  timezone: string;
  includeTax: boolean;
}

const StyledImg = styled.img`
  max-height: 250px;
  margin: auto;
  flex-grow: 0;
`;

export interface ChartOption {
  value: (typeof CHART_TYPE_VENDOR)[keyof typeof CHART_TYPE_VENDOR];
  label: string;
}

const CustomVendorGroupsChart = memo<CustomGroupsChartProps>(
  function CustomVendorGroupsChart({
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    customGroups,

    selectedGroups,
    actions,
    chartType,
    report,
    conditionalFormatting,
    timezone,
    currentCompare,
    includeTax,
  }) {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const { fromDate, interval, toDate } = currentRange;

    const { chartData, currency, totals, chartDataLoading } =
      useVendorCustomGroupChartQuery(
        {
          mid,
          currentPeriod,
          currentRange,
          includeTax,
          type: chartType?.value || "sales",
          selectedGroups,
        },
        {
          skip: isEmpty(selectedGroups),
          selectFromResult: ({ data, isFetching }) => ({
            chartData: data?.chart.data,
            currency: data?.chart.currency,
            totals: data?.totals,
            chartDataLoading: isFetching,
          }),
        }
      );

    /** Converting currency for sales */
    const data = useMemo(() => {
      const type = chartType?.value || CHART_TYPE_VENDOR.GlanceView;
      return chartData?.map((unitData: CustomGroupsChartData) => {
        return {
          ...unitData,
          groups: unitData?.groups?.map((group) => {
            const { name, value } = group;
            return {
              name,
              value: [CHART_TYPE_VENDOR.OrderedRevenue].includes(type)
                ? getConvertedValue(
                    currencyRates,
                    currency,
                    currentCurrency,
                    value
                  )
                : parseInt(value as unknown as string),
            };
          }),
        };
      });
    }, [currencyRates, currentCurrency, chartData, currency]);

    const groups = report ? selectedGroups : customGroups;
    return (
      <Panel
        id="widget-custom-groups-chart"
        title={t("dashboardWidget.customGroupsChart.mainTitle")}
        tooltip={
          report
            ? undefined
            : t("dashboardWidget.customGroupsChart.mainTooltip")
        }
        actions={actions}
        content={
          chartDataLoading ? (
            <PanelLoading />
          ) : isEmpty(groups) ? (
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box mb={1} display="flex" alignItems="center">
                <StyledImg src={NoGroupsImg} />
              </Box>
              <Box mb={1} ml={1} mr={1} maxWidth={300}>
                <Typography align="center" gutterBottom>
                  {t("noGroupsMessage")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box p={2}>
              {!isEmpty(chartData) && !isEmpty(selectedGroups) ? (
                <Grid container spacing={1}>
                  <Grid container item xs={12} md={9} lg={10}>
                    <MultiLineChart
                      chartData={data ?? []}
                      chartType={
                        chartType?.value || CHART_TYPE_VENDOR.GlanceView
                      }
                      currentPeriod={currentPeriod}
                      currency={
                        getCurrencySymbol[
                          currentCurrency as keyof typeof getCurrencySymbol
                        ]
                      }
                      fromDate={fromDate}
                      toDate={toDate}
                      interval={interval}
                      report={report}
                      timezone={timezone}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    alignItems="center"
                  >
                    {chartType?.value === CHART_TYPE_VENDOR.OrderedRevenue ? (
                      <GenericTotalMoney
                        current={totals?.current || 0}
                        prior={totals?.prior}
                        currency={currency}
                        title={t(`genericTotal.revenue`)}
                        currentCurrency={currentCurrency}
                        conditionalFormatting={conditionalFormatting}
                        comparisonPeriod={currentCompare}
                        condensedComparison={true}
                        condensed={true}
                        rounded
                      />
                    ) : (
                      <GenericTotal
                        number={Number(totals?.current) || 0}
                        comparison={{
                          prior: Number(totals?.prior) as number,
                        }}
                        title={t(`genericTotal.${chartType?.value}`)}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : (
                <NoData />
              )}
            </Box>
          )
        }
      />
    );
  }
);

export default CustomVendorGroupsChart;
