import { Cell, Row } from "react-sticky-table";

import PropTypes from "prop-types";
import React from "react";
import SpecificsRecommendationsCell from "~/modules/itemSpecifics/specificsRecommendationsCell";
import StickyContent from "~/modules/itemSpecifics/specificsRecommendationsContent";
import { currentValidationRules } from "~/utils/itemSpecificsUtils.js";
import get from "lodash/get";
import { getCountryCode } from "~/utils/countryUtils";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";

const ItemDisplayCell = styled(Cell)`
  color: #0080de;
  font-weight: 400;
  display: table-cell;
  min-width: 300px;
  max-width: 300px;
  white-space: pre-wrap;
  border-bottom: 2px solid #f5f5f5;
`;

const StyledRow = styled(Row)`
  margin-bottom: 10px;
`;

const getColor = (compareDetails, theme) =>
  compareDetails.find((i) => i.flag === "invalid")
    ? get(theme, "palette.error.main")
    : compareDetails.find((i) => i.flag === "missing")
    ? get(theme, "palette.disabled.main")
    : get(theme, "palette.success.main");

const SpecificsRecommendationsRow = (props) => {
  const {
    index,
    item,
    requiredToggle,
    countryCode,
    recommendedSpecifics,
    onChange,
  } = props;

  const theme = useTheme();

  const validationRules = currentValidationRules(requiredToggle);
  const itemComparables = (itemComp) => {
    const filteredItemComparables = itemComp.filter((validItem) =>
      validationRules.includes(validItem && validItem.validationRules)
    );
    return filteredItemComparables;
  };
  const itemCompared = itemComparables(item.compareDetails);
  return (
    <StyledRow>
      <ItemDisplayCell>
        <StickyContent
          index={index} //For intercom ids
          title={item.itemTitle}
          image={item.itemImg}
          dotColor={getColor(item.compareDetails, theme)}
          countryCode={countryCode}
          itemId={item.itemId}
        />
      </ItemDisplayCell>
      {itemCompared.map((cellInfo, idx) => (
        <SpecificsRecommendationsCell
          index={idx}
          key={item.itemId + cellInfo.name}
          cellInfo={cellInfo}
          recommendedSpecifics={recommendedSpecifics}
          itemId={item.itemId}
          onChange={onChange}
        />
      ))}
    </StyledRow>
  );
};

SpecificsRecommendationsRow.propTypes = {
  item: PropTypes.object.isRequired,
  requiredToggle: PropTypes.bool.isRequired,
  countryCode: PropTypes.oneOf(getCountryCode).isRequired,
  recommendedSpecifics: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default SpecificsRecommendationsRow;
