import { Box, Grid, Tooltip } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import { InlineIconButton } from "~/icons/inlineIconButton";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const HeadingGrid = styled(Grid)`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.palette.table.header};
  color: ${({ theme }) => theme.palette.table.text.header};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 1rem;
`;

export const CategoryHeading = styled(Bold)`
  text-transform: uppercase;
`;

const ColumnHeadings = memo(({ currencySymbol, category, report }) => {
  const { t } = useTranslation();

  return (
    <HeadingGrid container item xs={12} className="profit-headings">
      <Grid item xs={5}>
        <CategoryHeading variant="body1">
          {t(`profitability.${category.label}`)}
        </CategoryHeading>
      </Grid>
      {currencySymbol && (
        <>
          <Grid container item xs={2}>
            <Box display="flex" justifyContent="flex-end" width="100%" pr={1}>
              <Bold variant="body1">{currencySymbol}</Bold>
            </Box>
          </Grid>
          <Grid container item xs={2}>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Bold variant="body1">
                {t("profitability.percentIncomeLabel")}
              </Bold>
              {!report && (
                <Tooltip title={t("profitability.percentIncomeTooltip")}>
                  <InlineIconButton />
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid container item xs={3}>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Bold variant="body1">
                {t("profitability.perUnitLabel", {
                  currencySymbol,
                })}
              </Bold>
              {!report && (
                <Tooltip title={t("profitability.perUnitTooltip")}>
                  <InlineIconButton />
                </Tooltip>
              )}
            </Box>
          </Grid>
        </>
      )}
    </HeadingGrid>
  );
});

ColumnHeadings.propTypes = {
  currencySymbol: PropTypes.string,
  category: PropTypes.object,
  report: PropTypes.bool,
};

export default ColumnHeadings;
