import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import ErrorButton from "~/components/buttons/errorButton";
import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ErrorTitle = styled(DialogTitle)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const DialogBox = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    title,
    content,
    handleContinue,
    handleSyncEbay,
    handleExportCSV,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <ErrorTitle data-testid="away-dialog-title" id="form-dialog-title">
        {title}
      </ErrorTitle>
      <DialogContent>
        <DialogContentText data-testid="away-dialog-content">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={4}>
            <RaisedButton
              onClick={handleSyncEbay}
              data-testid="ebay-sync-button"
              name="syncButton"
              color="primary"
            >
              {t("myStoresWidget.itemSpecifics.syncButton")}
            </RaisedButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={handleExportCSV}
              data-testid="ebay-export-button"
              name="exportButton"
              variant="outlined"
              color="secondary"
            >
              {t("generic.exportCsvButton")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ErrorButton
              data-testid="dialog-cancel-button"
              onClick={handleContinue}
              name="cancelButton"
            >
              {t("generic.leaveButton")}
            </ErrorButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
DialogBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleSyncEbay: PropTypes.func.isRequired,
  handleExportCSV: PropTypes.func.isRequired,
};

export default DialogBox;
