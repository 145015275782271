import React, { ReactNode } from "react";

import { Theme } from "@material-ui/core";
import styled from "styled-components";

interface ButtonProps {
  active: boolean;
  disabled: boolean;
}

const Button = styled.span`
  color: ${({ active, theme }: ButtonProps & { theme: Theme }) =>
    active ? theme.palette.primary.main : theme.palette.text.secondary};
  cursor: ${({ disabled }: ButtonProps) =>
    disabled ? "not-allowed" : "pointer"};
  margin-right: 16px;
  padding: 2px 0;
  display: flex;
`;

interface StyleButtonProps {
  onToggle: (style: string) => void;
  label: string;
  icon?: ReactNode;
  active: boolean;
  style: string;
  editMode: boolean;
}

const StyleButton = ({
  onToggle,
  label,
  icon,
  active,
  style,
  editMode,
}: StyleButtonProps) => {
  const toggle = () => {
    if (editMode) {
      onToggle(style);
    }
  };

  return (
    <Button active={active} disabled={!editMode} onMouseDown={toggle}>
      {icon ? icon : label}
    </Button>
  );
};

export default StyleButton;
