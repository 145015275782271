import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const NoDataRow = styled.tr`
  display: flex;
  height: 100%;
  width: 100%;
`;

const NoDataCell = styled.td`
  min-height: 50px;
  height: calc(100% - 2rem) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const NoDataContainer = styled.div`
  min-height: 50px;
  height: calc(100% - 2rem) !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function NoData({ componentToRender }) {
  const { t } = useTranslation();

  return (
    <table width="100%" border="0">
      <tbody>
        <NoDataRow>
          <NoDataCell>
            <NoDataContainer p={2}>
              {componentToRender ? (
                componentToRender
              ) : (
                <Typography align="center">
                  {t("generic.noDataAvailableMessage")}
                </Typography>
              )}
            </NoDataContainer>
          </NoDataCell>
        </NoDataRow>
      </tbody>
    </table>
  );
}

NoData.propTypes = {
  componentToRender: PropTypes.node,
};
