import {
  FETCH_STORE_TOP_PRODUCTS_CHART,
  FETCH_STORE_TOP_PRODUCTS_CHART_FETCHING,
  FETCH_STORE_TOP_PRODUCTS_LIST,
  FETCH_STORE_TOP_PRODUCTS_LIST_FETCHING,
} from "./mystore.redux";
import { setError, setInfoMessage } from "../globalToast.redux";

import { Dispatch } from "redux";
import { Range } from "~/typedef/store";
import { TFunction } from "react-i18next";
import { TYPE } from "~/modules/overview/salesByProduct";
import { api } from "../apiSlice";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const fetchTopProductsList =
  ({
    t,
    mid,
    currentPeriod,
    currentRange,
    pageIndex,
    pageSize,
  }: {
    t: TFunction<"translation">;
    mid: string;
    currentPeriod: string;
    currentRange: Range;
    pageIndex: number;
    pageSize: number;
  }) =>
  async (dispatch: Dispatch) => {
    await dispatch({
      type: FETCH_STORE_TOP_PRODUCTS_LIST_FETCHING,
    });
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/topProducts`, {
        currentPeriod,
        ...currentRange,
        mid,
        pageIndex,
        pageSize,
        byAsin: true,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(dispatch, msg || statusText, get(err, "status"));
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        if (!data.length) {
          setInfoMessage(dispatch, t("notifications.topProductsNotFound"));
        }
        return await dispatch({
          type: FETCH_STORE_TOP_PRODUCTS_LIST,
          payload: data,
        });
      } else {
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    return setError(dispatch);
  };

export interface FetchSalesByProductTrendArgs {
  mid: string;
  currentRange: Range;
  productId: number;
}

export const fetchSalesByProductTrend =
  (params: FetchSalesByProductTrendArgs) => async (dispatch: Dispatch) => {
    const { mid, currentRange, productId } = params;
    dispatch({ type: FETCH_STORE_TOP_PRODUCTS_CHART_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/salesByProduct/productChart`,
        {
          mid,
          ...currentRange,
          productId,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_STORE_TOP_PRODUCTS_CHART,
          payload: {
            ...data,
            params,
          },
        });
      } else {
        return setError(dispatch, data, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

interface FetchSalesByProductArgs {
  type: keyof typeof TYPE;
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId?: string;
  countryCode: string;
  currentRange: Range;
  pageIndex: number;
  pageSize: number;
  sortKey: string;
  sortOrder: string;
  searchText?: string;
  includeNoSales: boolean;
  includeNoInventory: boolean;
  includeTax: boolean;
  groupId?: string | null;
}

export interface SalesByProduct {
  storeId: string;
  productId: string;
  id: string;
  imageUrl: string;
  title: string;
  brand: string;
  fulfillmentChannel: string;
  sku: string;
  productSku: string;
  quantity: number;
  linkUrl: string;
  lastSold: string | null;
  noQuantitySinceTime: string | null;
  price: string;
  totalSales: string;
  unitsSold: number;
  daysCover: number | null;
  salesRank: number | null;
  shippingPrice: string;
  categoryLabel: string | null;
  parentCategoryLabel: string | null;
  adSpend: number;
  adSales: number;
  acos: number;
  tacos: number;
  asin: number;
  status: {
    isFulfilledByAmazon: boolean;
    isNotParticipating: boolean;
    isNoOffers: boolean;
    isWinning: boolean;
    isLosingToAmazon: boolean;
    isLosingToSelf: boolean;
    isLosingToOthers: boolean;
    isLosingToUnknown: boolean;
    isNoWinner: boolean;
    isPriceDirty: boolean;
  };
  pageImpressions?: number;
  clickThroughRate?: number;
  pageViews?: number;
}

interface FetchSalesByProductResponse {
  rows: {
    current: SalesByProduct;
    prior: Pick<
      SalesByProduct,
      "productId" | "totalSales" | "unitsSold" | "salesRank"
    >;
  }[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesByProduct: build.query<
      FetchSalesByProductResponse,
      FetchSalesByProductArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/myStores/salesByProduct`,
          method: "GET",
          params: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SalesByProduct"),
    }),
  }),
});

export const { useSalesByProductQuery } = extendedApiSlice;
