import React from "react";
import TooltipPaper from "../tooltipPaper";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import get from "lodash/get";

interface PieChartTooltipProps {
  active?: boolean;
  payload?: {
    value?: ValueType;
    payload?: {
      tooltipContent?: React.ReactNode;
    };
  }[];
}

const PieChartTooltip: React.FC<PieChartTooltipProps> = ({
  active,
  payload,
}) => {
  if (active && payload) {
    const data = payload[0];
    const tooltipContent = get(
      data,
      "payload.tooltipContent",
      get(data, "value", "")
    );
    return <TooltipPaper>{tooltipContent}</TooltipPaper>;
  }

  return null;
};

export default PieChartTooltip;
