import {
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
} from "@material-ui/icons";

import { EditorState } from "draft-js";
import React from "react";
import StyleButton from "./styleButton";
import styled from "styled-components";

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  {
    label: "Blockquote",
    style: "blockquote",
    icon: <FormatQuote fontSize="small" />,
  },
  {
    label: "UL",
    style: "unordered-list-item",
    icon: <FormatListBulleted fontSize="small" />,
  },
  {
    label: "OL",
    style: "ordered-list-item",
    icon: <FormatListNumbered fontSize="small" />,
  },
];

interface BlockStyleControlsProps {
  editorState: EditorState;
  onToggle: (style: string) => void;
  editMode: boolean;
}

const BlockStyleControls = ({
  editorState,
  onToggle,
  editMode,
}: BlockStyleControlsProps) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
          editMode={editMode}
        />
      ))}
    </>
  );
};

export default BlockStyleControls;
