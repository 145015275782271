import { Tab, Tabs, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { withStyles } from "@material-ui/styles";

const StyledTab = withStyles((theme) => ({
  root: {
    height: "auto",
    border: "1px black solid",
  },
  selected: {
    color: theme.palette.background.paper,
    backgroundColor: "black",
  },
}))(Tab);

const SwipeableTabs = ({ labels, children, defaultIndex }) => {
  const [tabValue, setTabValue] = React.useState(defaultIndex);

  const onChange = (newValue) => {
    setTabValue(newValue);
  };

  const onTabChange = (_event, newValue) => {
    onChange(newValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={onTabChange}
        variant="fullWidth"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {labels.map((label) => (
          <StyledTab
            key={label}
            label={<Typography variant="subtitle1">{label}</Typography>}
          />
        ))}
      </Tabs>
      <SwipeableViews index={tabValue} onChangeIndex={onChange}>
        {children}
      </SwipeableViews>
    </>
  );
};
SwipeableTabs.defaultProps = {
  defaultIndex: 0,
};

SwipeableTabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  /** The index to select when this component renders **/
  defaultIndex: PropTypes.number,
};

export default SwipeableTabs;
