import React from "react";
import { SvgIcon } from "@material-ui/core";

const SalesInsightsIcon = (props: any) => (
  <SvgIcon {...props}>
    <g transform="matrix(0.3,0,0,0.3,-15.348284,-27.959504)">
      <path
        fill="currentColor"
        d="m 53.254233,160.56142 c -1.99332,-1.01641 -1.99466,-1.02278 -2.07493,-9.80805 -0.0961,-10.51763 -0.01,-10.69317 5.34811,-10.85098 2.9906,-0.0881 2.9906,-0.0881 5.86857,2.79693 1.63328,1.63727 3.78812,3.47763 4.98239,4.25527 2.10442,1.37026 2.10442,1.37026 -2.3151,1.37026 -5.09913,0 -5.47785,0.14635 -5.47785,2.11667 0,2.31346 -2.22978,2.13421 25.5577,2.05457 27.614977,-0.0791 25.242287,0.11873 25.242287,-2.10514 0,-1.91267 -0.40694,-2.0661 -5.48024,-2.0661 -4.42192,0 -4.42192,0 -2.31511,-1.38486 1.15874,-0.76168 3.39744,-2.67666 4.97487,-4.25552 2.86805,-2.87065 2.86805,-2.87065 5.86857,-2.78232 5.36532,0.15794 5.45423,0.33722 5.35803,10.80395 -0.0805,8.75711 -0.1162,8.92082 -2.15704,9.88926 -1.44467,0.68554 -62.032907,0.6531 -63.380257,-0.0339 z m 26.83639,-12.5288 c -22.05913,-4.38375 -30.33762,-30.91568 -14.56704,-46.68626 15.12448,-15.124472 40.563417,-8.201042 46.232737,12.58266 5.14584,18.86461 -12.533997,37.90559 -31.665697,34.1036 z m 6.44392,-8.55146 c 0.9104,-0.47079 1.36129,-1.43154 1.36129,-2.90061 0,-1.13607 0,-1.13607 1.42619,-1.45524 6.04808,-1.3535 8.25138,-9.03652 3.90357,-13.61193 -1.33411,-1.40396 -2.34884,-1.86915 -7.40084,-3.39283 -5.75159,-1.73468 -6.17784,-2.05167 -5.75923,-4.28309 0.38077,-2.02965 5.18796,-2.83174 9.0911,-1.51687 2.05314,0.69166 1.70902,0.77412 3.38526,-0.8112 2.55948,-2.42065 1.26665,-4.11041 -3.91844,-5.12149 -0.66249,-0.12918 -0.72761,-0.24644 -0.72761,-1.31023 0,-4.3053 -5.45642,-4.73878 -5.76606,-0.45809 -0.11277,1.55903 -0.11277,1.55903 -1.46248,1.87058 -5.94369,1.37197 -8.30068,8.73277 -4.24185,13.24716 1.57494,1.75171 2.40237,2.15452 7.72064,3.75851 5.80666,1.75128 6.56662,2.39149 5.60234,4.71947 -0.69486,1.67754 -6.92254,2.31158 -9.47258,0.96439 -1.14421,-0.60448 -1.58025,-0.43164 -3.18102,1.26094 -2.18935,2.31491 -0.97022,3.68452 4.25257,4.77746 0.66902,0.14 0.72792,0.25305 0.73151,1.40399 0.008,2.70771 2.12366,4.06499 4.45564,2.85908 z"
      />
    </g>
  </SvgIcon>
);

export default SalesInsightsIcon;
