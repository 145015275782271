import {
  Grid,
  IconButton,
  PropTypes,
  SvgIconTypeMap,
  Typography,
} from "@material-ui/core";
import React, { memo } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import SmallButton from "~/components/buttons/smallButton";
import { Variant } from "@material-ui/core/styles/createTypography";

// Partial typedef for a react-table cell - cell.row.original contains the data values
export interface ReactTableCell {
  row: {
    original: any[];
    getToggleRowExpandedProps: () => {
      onClick: () => void; // toggles single row expansion
    };
    isExpanded?: boolean;
  };
  toggleAllRowsExpanded: (a: boolean) => void; // toggles all rows expansion
  value: any; // Value passed by accessor
}

type MuiIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">>;

export interface ActionCellProps {
  actions: {
    icon?: MuiIcon;
    text?: string;
    textVariant?: "inherit" | Variant;
    buttonVariant?: "text" | "outlined" | "contained";
    color?: PropTypes.Color;
    action?: () => void; // pass the onClick function
  }[];
}

const ActionCell = memo<ActionCellProps>(function ActionCell({ actions }) {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      wrap="nowrap"
      justifyContent={actions?.length > 1 ? "space-between" : "center"}
    >
      {actions?.map(
        ({ icon, action, text, textVariant, buttonVariant, color }, i) => {
          const displayIcon =
            icon && React.createElement(icon, { fontSize: "inherit" });

          return (
            <Grid item key={`${text ?? ""}-${i}`}>
              {text ? (
                <SmallButton
                  size="small"
                  onClick={action}
                  startIcon={displayIcon}
                  variant={buttonVariant || "contained"}
                  color={color || "primary"}
                >
                  <Typography noWrap variant={textVariant || "caption"}>
                    {text}
                  </Typography>
                </SmallButton>
              ) : (
                <IconButton size="small" onClick={action}>
                  {displayIcon}
                </IconButton>
              )}
            </Grid>
          );
        }
      )}
    </Grid>
  );
});

export default ActionCell;
