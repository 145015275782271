import {
  FETCH_TOP_PRODUCTS_OVERVIEW,
  FETCH_TOP_PRODUCTS_OVERVIEW_ERROR,
  FETCH_TOP_PRODUCTS_OVERVIEW_FETCHING,
} from "./overview.redux";
import { Filter, Range } from "~/typedef/store";

import { Dispatch } from "redux";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_TOP_PRODUCTS_OVERVIEW_FETCHING,
  });
}

export interface TopProduct {
  mid: string;
  productId: string;
  title: string;
  price: number;
  marketplace: string;
  marketplaceSubtype: string;
  sellerSku: string;
  productSku: string;
  countryCode: string;
  totalSales: string;
  totalQuantity: string;
  url: string;
  imageUrl: string;
  fulfillmentChannel: string;
  available: number;
  daysCover: number | null;
}

export interface TopProductsParams {
  pageIndex: number;
  pageSize: number;
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  includeTax: boolean;
}

// Looks like this is used only in client reports for a cross marketplace report widget
export const fetchTopProducts =
  (params: TopProductsParams, oldParams?: TopProductsParams) =>
  async (dispatch: Dispatch) => {
    const {
      pageIndex,
      pageSize,
      currentPeriod,
      currentRange,
      filter,
      includeTax,
    } = params;
    if (!shouldUseCache(params, oldParams)) {
      await setFetching(dispatch);
      const res = await axios
        .post(`${baseUrl}/api/generic-mws-service/api/topProducts`, {
          pageIndex,
          pageSize,
          currentPeriod,
          ...currentRange,
          filter,
          includeTax,
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(dispatch, FETCH_TOP_PRODUCTS_OVERVIEW_ERROR);
          return setError(dispatch, msg || statusText, get(err, "status"));
        });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          const topProducts = res.data;

          return await dispatch({
            type: FETCH_TOP_PRODUCTS_OVERVIEW,
            payload: { data: topProducts, params },
          });
        } else {
          await dispatchError(dispatch, FETCH_TOP_PRODUCTS_OVERVIEW_ERROR);
          return setError(dispatch, res.data.errMsg, res.status);
        }
      }
      return setError(dispatch);
    }
  };
