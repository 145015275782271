import {
  ArrowDropDown,
  CheckRounded,
  CreditCardRounded,
} from "@material-ui/icons";
import {
  ButtonBase,
  Grid,
  Menu,
  MenuItem,
  Typography,
  withTheme,
} from "@material-ui/core";
import React, { memo } from "react";

import PayPalIcon from "../../img/creditCard/paypal.png";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// option values are aligned with paymentMethodType returned from subscription service
export const PAYMENT_OPTIONS = [
  { value: "CreditCard", label: "settings.billing.creditCardLabel" },
  { value: "PayPalAccount", label: "settings.billing.paypalLabel" },
];

const PaymentSelectBase = withTheme(styled(ButtonBase)`
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
`);

const OptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const SmallIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-left: -5px;
  margin-right: 15px;
  padding-top: 2px;
`;

const InlineCreditCardIcon = styled(CreditCardRounded)`
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: -5px;
  margin-right: 15px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const InlineCheckIcon = styled(CheckRounded)`
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 3px;
  color: ${({ theme }) => theme.palette.success.main};
`;

const ValueGrid = styled(Grid)`
  border-left: 4px solid transparent;
`;

const PaymentSelectMenuItem = styled(MenuItem)`
  border-left: ${({ theme, selected }) =>
    `4px solid ${selected ? theme.palette.primary.main : "transparent"}`};
  width: ${({ width }) => width}px;
`;

const getOptionIcon = (value) => {
  switch (value) {
    case "CreditCard":
      return <InlineCreditCardIcon />;
    case "PayPalAccount":
      return <SmallIcon src={PayPalIcon} />;
    default:
      return null;
  }
};

const PaymentSelect = memo(({ currentValue, handleChange, width }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl.offsetWidth : null
  );

  React.useEffect(() => {
    setMenuWidth(Boolean(anchorEl) ? anchorEl.offsetWidth : null);
  }, [anchorEl]);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClick = (value) => {
    setAnchorEl(null);
    handleChange(value);
  };

  const selectedOption = PAYMENT_OPTIONS.find(
    (option) => option.value === currentValue
  );

  return (
    <Grid container item xs={12} direction="column">
      <PaymentSelectBase
        variant="contained"
        onClick={openMenu}
        id="panel-select"
        width={width}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ValueGrid container>
              {getOptionIcon(selectedOption.value)}
              <OptionText>
                {selectedOption ? t(selectedOption.label) : ""}
              </OptionText>
            </ValueGrid>
          </Grid>
          <Grid item>
            <ArrowDropDown />
          </Grid>
        </Grid>
      </PaymentSelectBase>
      <Menu
        id="panel-select-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={() => setAnchorEl(null)}
      >
        {PAYMENT_OPTIONS.map((option) => (
          <PaymentSelectMenuItem
            key={option.value}
            width={menuWidth}
            onClick={() => handleClick(option.value)}
            selected={option.value === currentValue}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  {getOptionIcon(option.value)}
                  <OptionText>{t(option.label)}</OptionText>
                </Grid>
              </Grid>
              <Grid item>
                {option.value === currentValue && <InlineCheckIcon />}
              </Grid>
            </Grid>
          </PaymentSelectMenuItem>
        ))}
      </Menu>
    </Grid>
  );
});

PaymentSelect.propTypes = {
  currentValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default PaymentSelect;
