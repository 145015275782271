import React, { useMemo } from "react";

import { CHART_TYPE } from "./shippedRevenueTrend";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { useTranslation } from "react-i18next";

const ShippedRevenueMetricSelect = ({
  setSelected,
  selected,
  width = "200px",
}: {
  setSelected: (value: any) => void;
  selected: any;
  width?: string;
}) => {
  const { t } = useTranslation();

  const metricOptions = useMemo(
    () => [
      {
        value: CHART_TYPE.Revenue,
        label: t("shippedRevenue.revenueOption"),
      },
      {
        value: CHART_TYPE.COGS,
        label: t("shippedRevenue.cogsOption"),
      },
      {
        value: CHART_TYPE.Units,
        label: t("shippedRevenue.unitsOption"),
      },
      {
        value: CHART_TYPE.NetPureProductMargin,
        label: t("shippedRevenue.netPureProductMarginOption"),
      },
    ],
    []
  );

  const switchChartType = (value: string) => {
    const option = metricOptions.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setSelected(option);
    }
  };

  return (
    <TableFilter
      currentValue={selected?.value}
      handleChange={switchChartType}
      options={metricOptions}
      width={width}
    />
  );
};

export default ShippedRevenueMetricSelect;
