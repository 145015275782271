import { Range, StoreState } from "~/typedef/store";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { formatNumber, getPercentageDifference } from "~/utils/salesUtils";

import { Box } from "@material-ui/core";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchSalesByBrand } from "../../store/mystore/salesByBrand.redux";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import get from "lodash/get";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

interface SalesByBrandProps {
  userInfo: User;
  mid: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  pageSize?: number;
  timezone?: string;
  conditionalFormatting?: boolean;
  includeTax: boolean;
}

const SalesByBrandTable = memo(
  ({
    mid,
    condensed,
    userInfo,
    currentPeriod,
    currentRange,
    currentCurrency,
    footerLink,
    actions,
    report,
    pageSize,
    timezone,
    conditionalFormatting,
    includeTax,
  }: SalesByBrandProps) => {
    const CONDENSED_ROWS = 5;
    const { t } = useTranslation();
    const isVendorStore = useVendorStore(mid);
    const dispatch = useDispatch();

    const salesByBrand = useTypedSelector(
      (state) => state.mystore.salesByBrand
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const dispatchFetchSalesByBrand = useCallback(
      (user, store) => {
        dispatch(
          fetchSalesByBrand({
            mid: store,
            currentPeriod,
            currentRange,
            includeTax,
            timezone,
          })
        );
      },
      [currentPeriod, currentRange, includeTax, timezone]
    );

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchSalesByBrand(userInfo, mid);
      };
      if (mid) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mid, userInfo._id, dispatchFetchSalesByBrand]);

    const data = useMemo(() => {
      if (!salesByBrand || !salesByBrand.data) {
        return [];
      }
      const salesByBrandData = salesByBrand.data.map((brand: any) => {
        const { current, prior } = brand;
        return {
          brand: current.brand || t("generic.notSpecified"),
          priorPeriod: prior
            ? formatCurrencyRounded(
                prior.totalSales,
                currencyRates,
                prior.currency,
                currentCurrency
              )
            : "N/A",
          thisPeriod: {
            value: formatCurrencyRounded(
              current.totalSales,
              currencyRates,
              current.currency,
              currentCurrency
            ),
            growth: prior
              ? getPercentageDifference(current.totalSales, prior.totalSales)
              : "N/A",
            conditionalFormatting: conditionalFormatting,
          },
          units: {
            value: numberWithCommas(current.quantity),
            growth: prior
              ? getPercentageDifference(current.quantity, prior.quantity)
              : "N/A",
            conditionalFormatting: conditionalFormatting,
          },
          avgUnit: {
            value: formatCurrencyRounded(
              current.totalSales / current.quantity,
              currencyRates,
              current.currency,
              currentCurrency
            ),
            growth: prior
              ? getPercentageDifference(
                  current.totalSales / current.quantity,
                  prior.totalSales / prior.quantity
                )
              : "N/A",
            conditionalFormatting: conditionalFormatting,
          },
          avgOrder: {
            value: current.orderCount
              ? numberWithCommas(
                  formatNumber(current.quantity / current.orderCount)
                )
              : "N/A",
            growth:
              prior && current.orderCount
                ? getPercentageDifference(
                    current.quantity / current.orderCount,
                    prior.quantity / prior.orderCount
                  )
                : "N/A",
            conditionalFormatting: conditionalFormatting,
          },
        };
      });
      return salesByBrandData;
    }, [currencyRates, currentCurrency, salesByBrand, includeTax]);

    const columns = useMemo(
      () => [
        {
          id: "brand",
          Header: t("myStoresWidget.salesByBrand.brandColumn"),
          accessor: "brand",
          Cell: TextCell,
          colSpan: 3,
        },
        {
          id: "priorPeriod",
          Header: t("myStoresWidget.salesByBrand.priorPeriodColumn"),
          accessor: "priorPeriod",
          Cell: ValueCell,
          hiddenDown: report ? "" : "sm",
          align: "right",
        },
        {
          id: "thisPeriod",
          Header: t("myStoresWidget.salesByBrand.thisPeriodColumn"),
          accessor: "thisPeriod",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "units",
          Header: t("myStoresWidget.salesByBrand.unitsColumn"),
          accessor: "units",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "avgUnit",
          Header: t("myStoresWidget.salesByBrand.avgUnitColumn"),
          accessor: "avgUnit",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "" : "sm",
        },
        {
          id: "avgOrder",
          Header: t("myStoresWidget.salesByBrand.avgOrderColumn"),
          accessor: "avgOrder",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "" : "sm",
        },
      ],
      []
    );

    const condensedColumns = useMemo(
      () => [
        {
          id: "brand",
          Header: t("myStoresWidget.salesByBrand.brandColumn"),
          accessor: "brand",
          Cell: TextCell,
          colSpan: 3,
        },
        {
          id: "thisPeriod",
          Header: t(
            `myStoresWidget.${
              isVendorStore ? "poBrand" : "salesByBrand"
            }.salesColumn`
          ),
          accessor: "thisPeriod",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "units",
          Header: t("myStoresWidget.salesByBrand.unitsColumn"),
          accessor: "units",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "avgUnit",
          Header: t("myStoresWidget.salesByBrand.avgUnitColumn"),
          accessor: "avgUnit",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
        {
          id: "avgOrder",
          Header: t("myStoresWidget.salesByBrand.avgOrderColumn"),
          accessor: "avgOrder",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
      ],
      []
    );

    return (
      <Panel
        id="widget-sales-by-brand"
        title={t(
          `myStoresWidget.${
            isVendorStore ? "poBrand" : "salesByBrand"
          }.mainTitle`
        )}
        footerLink={footerLink}
        tooltip={undefined}
        actions={actions}
        content={
          salesByBrand?.fetching || !mid ? (
            <PanelLoading />
          ) : condensed ? (
            <NoScrollTable
              {...{
                columns: condensedColumns,
                data: data.slice(0, CONDENSED_ROWS),
                pageSize,
                loading: salesByBrand?.fetching,
                isReport: report,
              }}
            />
          ) : (
            <Box>
              <Table
                {...{
                  columns,
                  data,
                  gridLayoutColumns: 12,
                  pageSize: pageSize ?? 10,
                  pagination: !condensed,
                  isReport: report,
                }}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default SalesByBrandTable;
