import { CircularProgress, Grid } from "@material-ui/core";
import React, { memo } from "react";
import { computeTotalValue, generateCSV } from "./categoryUtils";
import {
  getCurrencyByCountryCode,
  getCurrencySymbol,
} from "~/utils/currencyUtils";

import CategoryLabel from "./categoryLabel";
import ColumnHeadings from "./columnHeadings";
import { FinancialCategory } from "~/store/mystore/profitability.redux";
import MetaTable from "./metaTable";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { ProductEventRowData } from "../profitLossProductTable/productTable";
import { Range } from "~/typedef/store";
import Totals from "./totals";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TableProps {
  countryCode: string;
  currentRange: Range;
  currentCurrency: string;
  categories: FinancialCategory[];
  data: ProductEventRowData;
  report: boolean;
  isProductReport: boolean;
  shopName: string;
  marketplaceName: string;
}

const Table = memo<TableProps>(function Table({
  countryCode,
  currentRange,
  currentCurrency,
  categories,
  data,
  report,
  isProductReport,
  shopName,
  marketplaceName,
}) {
  const { t } = useTranslation();

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const sourceCurrency = getCurrencyByCountryCode[countryCode] || "AUD";

  if (!countryCode || !currentRange || !categories || !data) {
    return (
      <PageBlock>
        <CircularProgress />
      </PageBlock>
    );
  }

  const revenueCategories = categories.filter(
    (category) => category.type === "revenue"
  );

  const expensesCategories = categories.filter(
    (category) => category.type === "expense"
  );

  const totalRevenue = computeTotalValue(
    {
      type: "revenue",
      label: "incomeLabel",
      children: revenueCategories.reduce(
        (acc: FinancialCategory[], category) => {
          return [...acc, ...category.children];
        },
        []
      ),
    },
    data.events
  );
  const totalExpenses = computeTotalValue(
    {
      type: "expense",
      label: "expenseLabel",
      children: expensesCategories.reduce(
        (acc: FinancialCategory[], category) => {
          return [...acc, ...category.children];
        },
        []
      ),
    },
    data.events
  );

  const csvData = generateCSV(
    data,
    categories,
    currencyRates,
    sourceCurrency,
    currentCurrency
  );

  return (
    <Grid container>
      <MetaTable
        data={data}
        currentRange={currentRange}
        csvData={csvData}
        report={report}
        isProductReport={isProductReport}
        countryCode={countryCode}
        shopName={shopName}
        marketplaceName={marketplaceName}
        currentCurrency={currentCurrency}
      />
      <ColumnHeadings
        {...{
          currencySymbol:
            getCurrencySymbol[
              currentCurrency as keyof typeof getCurrencySymbol
            ],
          category: categories[0],
          report: report,
        }}
      />
      <CategoryLabel
        {...{
          countryCode: countryCode,
          category: categories[0],
          data: data,
          level: 0,
          totalLabel: t("profitability.totalRevenueLabel"),
          totalValue: totalRevenue,
          totalRevenue: totalRevenue,
          currentCurrency: currentCurrency,
          report: report,
        }}
      />
      <ColumnHeadings
        {...{
          currencySymbol:
            getCurrencySymbol[
              currentCurrency as keyof typeof getCurrencySymbol
            ],
          category: categories[1],
          report: report,
        }}
      />
      <CategoryLabel
        {...{
          countryCode: countryCode,
          category: categories[1],
          data: data,
          level: 0,
          totalLabel: t("profitability.totalExpensesLabel"),
          totalValue: totalExpenses,
          totalRevenue: totalRevenue,
          currentCurrency: currentCurrency,
          report: report,
        }}
      />
      <Totals
        {...{
          countryCode: countryCode,
          categories: categories,
          data: data,
          totalRevenue: totalRevenue,
          totalExpenses: totalExpenses,
          currentCurrency: currentCurrency,
        }}
      />
    </Grid>
  );
});

export default Table;
