import { TextField, useTheme } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import { Cell } from "react-sticky-table";
import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import styled from "styled-components";

const calculateInputStyle = ({ theme, flag }) => {
  switch (flag) {
    case "correct":
      return `border-bottom: 2px solid ${theme.palette.success.main};
        &:after,&:before {
          border-bottom: 2px solid ${theme.palette.success.main};
        };
        & svg {
          fill: ${theme.palette.success.main};
        };`;
    case "invalid":
      return `border-bottom: 2px solid ${theme.palette.warning.main};
        &:after,&:before {
          border-bottom: 2px solid ${theme.palette.warning.main};
        };
        & svg {
          fill: ${theme.palette.warning.main};
        };`;
    case "missing":
      return `border-bottom: 2px solid ${theme.palette.error.main};
        &:after,&:before {
          border-bottom: 2px solid ${theme.palette.error.main};
        };
        & svg {
          fill: ${theme.palette.error.main};
        };`;
    default:
      return "";
  }
};

const StyledInput = styled(TextField)`
  min-width: 100px;
  min-height: 28px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  ${({ theme, flag }) => calculateInputStyle({ theme, flag })}
  & .MuiInput-root {
    font-size: 0.75rem;
    max-height: 28px;
    overflow: hidden;
    &:hover,
    :active,
    :focus,
    :focus-within {
      overflow: visible;
      max-height: fit-content;
    }
  }
`;

const TextInput = styled(StyledInput)`
  width: 100%;
`;

const InputCell = styled(Cell)`
  min-width: 100px;
  min-height: 28px;
  width: 100%;
  display: table-cell;
  border-bottom: 2px solid #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
`;

const AutocompleteMulti = styled(Autocomplete)`
  width: ${({ width }) => `${width}px`};
  min-height: 28px;
  & svg.MuiChip-deletable {
    display: none;
  }
`;

const AutocompleteDynamic = styled(Autocomplete)`
  width: ${({ width }) => `${width}px`};
  min-height: 28px;
`;

const SpecificsRecommendationsCell = React.memo((props) => {
  const { cellInfo, recommendedSpecifics, itemId, onChange } = props;

  const theme = useTheme();
  const inputType = get(cellInfo, "inputType", "text");
  const selectedValue = get(cellInfo, "selectedValue", "");
  const name = get(cellInfo, "name", "");
  const flag = get(cellInfo, "flag", "correct");

  const matchedRecommended = recommendedSpecifics.find((r) => r.name === name);
  const recommendedValues =
    get(matchedRecommended, "valueRecommendation", []) || [];
  const recommendedValusAutocompleteFormat = recommendedValues.map((rVal) => ({
    item: rVal,
  }));
  const flatProps = {
    options: recommendedValusAutocompleteFormat.map((option) => option.item),
  };

  const letterSpacing = 9;
  const cellLength = Math.max(
    ...recommendedValusAutocompleteFormat.map(
      (option) => (`${option.item}` || "").length * letterSpacing
    )
  );

  let input = null;
  switch (inputType) {
    case "text":
      input = (
        <TextInput
          fullWidth
          flag={flag}
          value={selectedValue}
          theme={theme}
          onChange={(e) => onChange(e, itemId, name)}
          InputProps={{ disableUnderline: true }}
        />
      );
      break;
    case "select":
      input = (
        <AutocompleteDynamic
          {...flatProps}
          fullWidth
          disableClearable
          freeSolo={true}
          clearOnBlur={true}
          size="small"
          getOptionSelected={(option, val) => option.item === val.item}
          onChange={(evt, value) => {
            onChange({ target: { value } }, itemId, name);
          }}
          value={
            selectedValue instanceof Array ? selectedValue[0] : selectedValue
          }
          width={cellLength}
          renderInput={({ InputProps, ...params }) => (
            <StyledInput
              flag={flag}
              fullWidth
              {...params}
              InputProps={{ ...InputProps, disableUnderline: true }}
            />
          )}
        />
      );
      break;
    case "mSelect":
      input = (
        <AutocompleteMulti
          {...flatProps}
          multiple
          fullWidth
          disableCloseOnSelect
          freeSolo={true}
          clearOnBlur={true}
          disableClearable
          onChange={(evt, value) => {
            onChange({ target: { value } }, itemId, name);
          }}
          size="small"
          width={cellLength * 2}
          value={selectedValue}
          renderInput={({ InputProps, ...params }) => (
            <StyledInput
              flag={flag}
              fullWidth
              {...params}
              InputProps={{ ...InputProps, disableUnderline: true }}
            />
          )}
        />
      );
      break;
    default:
      input = <></>;
  }
  return <InputCell>{input}</InputCell>;
});

SpecificsRecommendationsCell.propTypes = {
  cellInfo: PropTypes.object,
  recommendedSpecifics: PropTypes.array,
  itemId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default SpecificsRecommendationsCell;
