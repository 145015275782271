import {
  IconicLogo,
  KoganLogo,
  ShopifyLogo,
  TrademeLogo,
  WalmartLogo,
} from "~/img/marketplaces/logos/logos";

import React from "react";
import { baseUrl } from "../../configs";
import { connectBol } from "~/store/connections/connectBol.redux";
import { connectCDiscount } from "~/store/connections/connectCDiscount.redux";
import { connectFacade } from "~/store/connections/connectFacade.redux";
import { connectIconic } from "~/store/connections/connectIconic.redux";
import { connectKaufland } from "~/store/connections/connectKaufland.redux";
import { connectKogan } from "~/store/connections/connectKogan.redux";
import { connectManoMano } from "~/store/connections/connectManoMano.redux";
import { connectMirakl } from "~/store/connections/connectMirakl.redux";
import { connectMyDeal } from "~/store/connections/connectMyDeal.redux";
import { connectPinkConnect } from "~/store/connections/connectPinkConnect.redux";
import { connectShopify } from "~/store/connections/connectShopify.redux";
import { connectWalmart } from "~/store/connections/connectWalmart.redux";
import { connectWayfair } from "~/store/connections/connectWayfair.redux";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import styled from "styled-components";

const LogoWrappper = styled.img`
  width: 128px;
  height: 128px;
`;

const SUPPORTED_COUNTRIES = Object.keys(getCurrencyByCountryCode);
const supportedCountryRegex = new RegExp(
  `^(${SUPPORTED_COUNTRIES.join("|")})$`
);

export const getMarketplaceConfig = (
  marketplace,
  marketplaceSubtype,
  marketplaceName,
  country,
  t
) => {
  const configMap = {
    mirakl: {
      inputs: [
        {
          label: t("connectWizard.miraklAuthTokenLabel", { marketplaceName }),
          regex:
            /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
          regexError: t("connectWizard.miraklTokenFormatError", {
            marketplaceName,
          }),
        },
      ],
      shopName: false,
      logo: (
        <LogoWrappper
          src={`${baseUrl}/static/icons/${marketplaceSubtype}.jpg`}
        />
      ),
      messages: {
        title: t("connectWizard.miraklLinkTitle", {
          marketplaceName,
        }),
        helpLinkUrl: t("connectWizard.miraklHelpLinkUrl"),
        helpLink: t("connectWizard.miraklHelpLink"),
        successMessage: t("connectWizard.miraklSuccessMessage", {
          marketplaceName,
        }),
        retryMessage: t("connectWizard.miraklRetryTokenMessage"),
        connectionError: t("connectWizard.miraklConnectionError", {
          marketplaceName,
        }),
      },
      submitAction: connectMirakl,
      submitActionParams: ["input1", "subMarketplace"],
    },
    iconic: {
      inputs: [
        {
          label: t("connectWizard.iconicSellerIdLabel"),
        },
        {
          label: t("connectWizard.iconicApiKeyLabel"),
        },
      ],
      shopName: true,
      logo: <IconicLogo height={70} />,
      messages: {
        title: t("connectWizard.iconicLinkTitle"),
        helpLinkUrl: t("connectWizard.iconicHelpLinkUrl"),
        helpLink: t("connectWizard.iconicHelpLink"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.iconicShopNameLabel"),
        successMessage: t("connectWizard.iconicSuccessMessage"),
        retryMessage: t("connectWizard.iconicRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectIconic,
      submitActionParams: ["input1", "input2", "shopName"],
    },
    shopify: {
      inputs: [
        {
          label: t("connectWizard.shopifyUrlLabel"),
          regex: /^.*.myshopify.com\/?$/,
          regexError: t("connectWizard.shopifyWrongUrlError"),
        },
        {
          label: t("connectWizard.shopifyPasswordLabel"),
          type: "password",
        },
        {
          label: t("connectWizard.shopifyCountryLabel"),
          regex: supportedCountryRegex,
          regexError: t("connectWizard.shopifyCountryUnavailableError"),
        },
      ],
      shopName: false,
      logoLinkUrl: "https://www.shopify.com",
      logo: <ShopifyLogo height={70} />,
      messages: {
        title: t("connectWizard.shopifyLinkTitle"),
        helpLinkUrl: t("connectWizard.shopifyHelpLinkUrl"),
        helpLink: t("connectWizard.shopifyHelpLink"),
        successMessage: t("connectWizard.shopifySuccessMessage"),
        retryMessage: t("connectWizard.shopifyRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectShopify,
      submitActionParams: ["input1", "input2", "input3"],
    },
    walmart: {
      inputs: [
        {
          label: t("connectWizard.walmartClientIdLabel"),
        },
        {
          label: t("connectWizard.walmartClientSecretLabel"),
        },
      ],
      shopName: true,
      logoLinkUrl: "https://www.walmart.com",
      logo: <WalmartLogo height={70} />,
      messages: {
        title: t("connectWizard.walmartLinkTitle"),
        helpLinkUrl: t("connectWizard.walmartHelpLinkUrl"),
        helpLink: t("connectWizard.walmartHelpLink"),
        shopNameTitle: t("connectWizard.walmartAccountNameTitle"),
        shopNameLabel: t("connectWizard.walmartAccountNameLabel"),
        successMessage: t("connectWizard.walmartSuccessMessage"),
        retryMessage: t("connectWizard.walmartRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectWalmart,
      submitActionParams: ["input1", "input2", "shopName"],
    },
    kogan: {
      inputs: [
        {
          label: t("connectWizard.koganSellerIdLabel"),
        },
        {
          label: t("connectWizard.koganSellerTokenLabel"),
        },
      ],
      shopName: true,
      logoLinkUrl: "https://www.kogan.com",
      logo: <KoganLogo height={70} />,
      messages: {
        title: t("connectWizard.koganLinkTitle"),
        helpLinkUrl: t("connectWizard.koganHelpLinkUrl"),
        helpLink: t("connectWizard.koganHelpLink"),
        shopNameTitle: t("connectWizard.koganAccountNameTitle"),
        shopNameLabel: t("connectWizard.koganAccountNameLabel"),
        successMessage: t("connectWizard.koganSuccessMessage"),
        retryMessage: t("connectWizard.koganRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectKogan,
      submitActionParams: ["input1", "input2", "shopName"],
    },
    cdiscount: {
      inputs: [
        {
          label: t("connectWizard.cdiscountSellerIdLabel"),
        },
      ],
      logo: <LogoWrappper src={`${baseUrl}/static/icons/cdiscount.jpg`} />,
      messages: {
        title: t("connectWizard.cdiscountLinkTitle"),
        helpLinkUrl: t("connectWizard.cdiscountHelpLinkUrl"),
        helpLink: t("connectWizard.cdiscountHelpLink"),
        shopNameTitle: t("connectWizard.cdiscountAccountNameTitle"),
        shopNameLabel: t("connectWizard.cdiscountAccountNameLabel"),
        successMessage: t("connectWizard.cdiscountRequestSuccessMessage"),
        retryMessage: t("connectWizard.cdiscountRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectCDiscount,
      submitActionParams: ["input1"],
    },
    bol: {
      inputs: [
        {
          label: t("connectWizard.bolClientIdLabel"),
        },
        {
          label: t("connectWizard.bolClientSecretLabel"),
        },
      ],
      shopName: true,
      logoLinkUrl: "https://www.bol.com",
      logo: <LogoWrappper src={`${baseUrl}/static/icons/bol.jpg`} />,
      messages: {
        title: t("connectWizard.bolLinkTitle"),
        helpLinkUrl: t("connectWizard.bolHelpLinkUrl"),
        helpLink: t("connectWizard.bolHelpLink"),
        shopNameTitle: t("connectWizard.bolAccountNameTitle"),
        shopNameLabel: t("connectWizard.bolAccountNameLabel"),
        successMessage: t("connectWizard.bolSuccessMessage"),
        retryMessage: t("connectWizard.bolRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectBol,
      submitActionParams: ["input1", "input2", "shopName"],
    },
    trademe: {
      inputs: [],
      shopName: false,
      logoLinkUrl: "https://www.trademe.co.nz",
      logo: <TrademeLogo height={70} />,
      messages: {
        title: t("connectWizard.trademeLinkTitle"),
        successMessage: t("connectWizard.trademeSuccessMessage"),
        retryMessage: t("connectWizard.trademeRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitActionParams: [],
    },
    mydeal: {
      inputs: [
        {
          label: t("connectWizard.myDealSellerIdLabel"),
        },
        {
          label: t("connectWizard.myDealSellerTokenLabel"),
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/mydeal.jpg`} />,
      messages: {
        title: t("connectWizard.myDealLinkTitle"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: "Store Name",
        successMessage: t("connectWizard.myDealSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectMyDeal,
      submitActionParams: ["input1", "input2", "shopName"],
    },
    manomano: {
      inputs: [
        {
          label: t("connectWizard.manomanoCountryLabel"),
          regex: /^(DEU|ESP|FRA|GBR|ITA)$/,
          regexError: t("connectWizard.manomanoCountryError"),
        },
        {
          label: t("connectWizard.manomanoSellerIdLabel"),
        },
        {
          label: t("connectWizard.manomanoApiKeyLabel"),
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/manomano.jpg`} />,
      messages: {
        title: t("connectWizard.manomanoLinkTitle"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.manomanoSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectManoMano,
      submitActionParams: ["input1", "input2", "input3", "shopName"],
    },
    kaufland: {
      inputs: [
        {
          label: t("connectWizard.kauflandCountryLabel"),
          regex: /^(DEU|CZE|SVK)$/,
          regexError: t("connectWizard.kauflandCountryError"),
        },
        {
          label: t("connectWizard.kauflandClientKeyLabel"),
        },
        {
          label: t("connectWizard.kauflandSecretKeyLabel"),
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/kaufland.jpg`} />,
      messages: {
        title: t("connectWizard.kauflandLinkTitle"),
        helpLinkUrl: t("connectWizard.kauflandHelpLinkUrl"),
        helpLink: t("connectWizard.kauflandHelpLink"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.kauflandSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectKaufland,
      submitActionParams: ["input1", "input2", "input3", "shopName"],
    },
    wayfair: {
      inputs: [
        {
          label: t("connectWizard.wayfairCountryLabel"),
          regex: /^(USA|DEU|GBR|CAN)$/,
          regexError: t("connectWizard.wayfairCountryError"),
        },
        {
          label: t("connectWizard.wayfairClientIdLabel"),
        },
        {
          label: t("connectWizard.wayfairClientSecretLabel"),
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/wayfair.jpg`} />,
      messages: {
        title: t("connectWizard.wayfairLinkTitle"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.wayfairSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectWayfair,
      submitActionParams: ["input1", "input2", "input3", "shopName"],
    },
    pinkConnect: {
      inputs: [
        {
          label: t("connectWizard.pinkConnectShopId"),
        },
        {
          label: t("connectWizard.pinkConnectSellerId"),
        },
        {
          label: t("connectWizard.pinkConnectAPIToken"),
        },
      ],
      shopName: false,
      logo: (
        <LogoWrappper
          src={`${baseUrl}/static/icons/${marketplaceSubtype}.jpg`}
        />
      ),
      messages: {
        title: t("connectWizard.pinkConnectTitle", {
          marketplaceName,
        }),
        helpLinkUrl: t("connectWizard.pinkConnectHelpLinkUrl"),
        helpLink: t("connectWizard.pinkConnectHelpLink"),
        successMessage: t("connectWizard.pinkConnectSuccessMessage", {
          marketplaceName,
        }),
        retryMessage: t("connectWizard.pinkConnectRetryMessage"),
        connectionError: t("connectWizard.pinkConnectConnectionError", {
          marketplaceName,
        }),
      },
      submitAction: connectPinkConnect,
      submitActionParams: ["input1", "input2", "input3", "subMarketplace"],
    },
    /* ---------------------------------------- */
    /* Dummy facade connectors below this point */
    /* ---------------------------------------- */
    sears: {
      facade: true,
      inputs: [
        {
          label: "Seller ID",
        },
        {
          label: "Email Address",
        },
        {
          label: "Secret Key",
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/sears.jpg`} />,
      messages: {
        title: t("connectWizard.earlyAccessTitle", {
          marketplaceName: "Sears Marketplace",
        }),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.earlyAccessSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectFacade,
      submitActionParams: [
        "marketplace",
        "input1",
        "input2",
        "input3",
        "shopName",
      ],
    },
    themarket: {
      facade: true,
      inputs: [
        {
          label: "Merchant ID",
        },
        {
          label: "Username",
        },
        {
          label: "Password",
          type: "password",
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/themarket.jpg`} />,
      messages: {
        title: t("connectWizard.earlyAccessTitle", {
          marketplaceName: "TheMarket",
        }),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.earlyAccessSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectFacade,
      submitActionParams: [
        "marketplace",
        "input1",
        "input2",
        "input3",
        "shopName",
      ],
    },
    magento: {
      facade: true,
      inputs: [
        {
          label: "Consumer Key",
        },
        {
          label: "Consumer Secret",
        },
        {
          label: "Store Base URL",
        },
        {
          label: "OAuth Verifier",
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/magento.jpg`} />,
      messages: {
        title: t("connectWizard.earlyAccessTitle", {
          marketplaceName: "Magento",
        }),
        helpLinkUrl: t("connectWizard.magentoHelpLinkUrl"),
        helpLink: t("connectWizard.magentoHelpLink"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.earlyAccessSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectFacade,
      submitActionParams: [
        "marketplace",
        "input1",
        "input2",
        "input3",
        "input4",
        "shopName",
      ],
    },
    bigcommerce: {
      facade: true,
      inputs: [
        {
          label: "Client ID",
        },
        {
          label: "Client Secret",
        },
        {
          label: "Access Token",
        },
        {
          label: "Store Hash",
        },
      ],
      shopName: true,
      logo: <LogoWrappper src={`${baseUrl}/static/icons/bigcommerce.jpg`} />,
      messages: {
        title: t("connectWizard.earlyAccessTitle", {
          marketplaceName: "BigCommerce",
        }),
        helpLinkUrl: t("connectWizard.bigcommerceHelpLinkUrl"),
        helpLink: t("connectWizard.bigcommerceHelpLink"),
        shopNameTitle: t("connectWizard.shopNameTitle"),
        shopNameLabel: t("connectWizard.shopNameLabel"),
        successMessage: t("connectWizard.earlyAccessSuccessMessage"),
        retryMessage: t("connectWizard.connectionError"),
      },
      submitAction: connectFacade,
      submitActionParams: [
        "marketplace",
        "input1",
        "input2",
        "input3",
        "input4",
        "shopName",
      ],
    },
  };

  return {
    marketplace,
    marketplaceSubtype,
    marketplaceName,
    country,
    ...configMap[marketplace],
  };
};
