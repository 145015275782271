import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import { Box, DialogContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const OrderedList = styled.ol`
  margin: revert;
  padding: revert;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 5px;
`;

const StyledOrderedList = styled(OrderedList)`
  margin: 0;
`;

interface CaseConfigTemplateDialogProps {
  mid: string;
  open?: boolean;
  onClose: () => void;
}
const CaseConfigTemplateDialog = ({
  mid,
  open = false,
  onClose,
}: CaseConfigTemplateDialogProps) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setButtonDisabled(false);
  }, [open]);

  const paramString = new URLSearchParams({
    mid,
  });

  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={onClose} variant="h2">
        {t("inventoryCaseConfig.mainTemplateTitle")}
      </BaseDialogTitle>
      <DialogContent>
        <DialogContentGrid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={2}>
              <StyledTypography variant="h2">
                {t("inventoryCaseConfig.templateInstructionsTitle")}
              </StyledTypography>
              <Typography variant="body1">
                {t("inventoryCaseConfig.templateInstructionsIntroText")}
              </Typography>
            </Box>
            <Box mb={2}>
              <StyledTypography variant="h2">
                {t("inventoryCaseConfig.preserveColumnHeadersTitle")}
              </StyledTypography>
              <Typography variant="body1">
                {t("inventoryCaseConfig.preserveColumnHeadersText")}
              </Typography>
              <Typography variant="body1">
                <StyledOrderedList>
                  <li>
                    {t("inventoryCaseConfig.templateInstructionsBullet1")}
                  </li>
                  <li>
                    {t("inventoryCaseConfig.templateInstructionsBullet2")}
                  </li>
                  <li>
                    {t("inventoryCaseConfig.templateInstructionsBullet3")}
                  </li>
                </StyledOrderedList>
              </Typography>
            </Box>
            <Box mb={2}>
              <StyledTypography variant="h2">
                {t("inventoryCaseConfig.reviewSkuDiscrepanciesTitle")}
              </StyledTypography>
              <Typography variant="body1">
                {t("inventoryCaseConfig.reviewSkuDiscrepanciesText")}
              </Typography>
            </Box>
            <Box mb={2}>
              <StyledTypography variant="h2">
                {t("inventoryCaseConfig.updateExistingConfigurationsTitle")}
              </StyledTypography>
              <Typography variant="body1">
                {t("inventoryCaseConfig.updateExistingConfigurationsText")}
              </Typography>
            </Box>
          </Grid>
        </DialogContentGrid>
        <DialogContentGrid container justifyContent="center" item xs={12}>
          <RaisedButton
            disabled={buttonDisabled}
            href={`/api/myStores/inventory/csv?${paramString.toString()}`}
            variant="outlined"
            color="secondary"
            onClick={() => setButtonDisabled(true)}
          >
            {t("inventoryCaseConfig.downloadByProductSkuButtonLabel")}
          </RaisedButton>
        </DialogContentGrid>
      </DialogContent>
    </BaseDialog>
  );
};

export default CaseConfigTemplateDialog;
