import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import { DialogContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const OrderedList = styled.ol`
  margin: revert;
  padding: revert;
`;

interface TemplateDialogProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  open?: boolean;
  onClose: () => void;
}
const TemplateDialog = ({
  mid,
  marketplaceType,
  marketplaceSubtype,
  open = false,
  onClose,
}: TemplateDialogProps) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setButtonDisabled(false);
  }, [open]);

  const paramString = new URLSearchParams({
    mid,
    marketplaceType,
    marketplaceSubtype,
  });

  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={onClose}>
        {t("vendorCogs.templateInstructionsTitle")}
      </BaseDialogTitle>
      <DialogContent>
        <DialogContentGrid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t("vendorCogs.templateInstructionsIntroText")}
            </Typography>
            <Typography variant="body2">
              <OrderedList>
                <li>{t("vendorCogs.templateInstructionsBullet1")}</li>
                <li>{t("vendorCogs.templateInstructionsBullet2")}</li>
                <li>{t("vendorCogs.templateInstructionsBullet3")}</li>
              </OrderedList>
            </Typography>
          </Grid>
        </DialogContentGrid>
        <Grid container justifyContent="center" item xs={12}>
          <Grid container justifyContent="center" item xs={6}>
            <RaisedButton
              disabled={buttonDisabled}
              href={`/api/myStores/vendorProfitability/directCosts?${paramString.toString()}&identifier=sellerSku`}
              variant="outlined"
              color="secondary"
              onClick={() => setButtonDisabled(true)}
            >
              {t("profitability.downloadButtonLabel")}
            </RaisedButton>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default TemplateDialog;
