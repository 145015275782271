import { Box, Grid, useTheme } from "@material-ui/core";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import {
  CurrencyRate,
  Range,
  InventoryHealthTrend,
  InventoryTrend,
} from "~/typedef/store";
import { User } from "~/typedef/user";
import { fetchInventoryTrend } from "~/store/mystore/vendor.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import {
  roundFractionalDigits,
  getConvertedValue,
} from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import moment from "moment-timezone";

export interface ChartOption {
  value: string;
  label: string;
}

export interface ChartDataRevenue {
  revenue: number;
  startTime: number; //unix timestamp
  cogs: number;
  units: number;
  [x: string]: number;
}

export type ChartDataForInventoryHealth = ChartDataRevenue[];

interface InventoryHealthParams {
  userInfo: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  chartType: ChartOption;
  actions?: ReactChild;
  view: "cogs" | "units";
  condensed?: boolean;
  report?: boolean;
  timezone: string;
}

const formatInventoryHealthData = (
  inventoryHealthData: InventoryHealthTrend | undefined,
  currentCurrency: string,
  currency = "AUD",
  currencyRates: CurrencyRate[]
) => {
  if (!inventoryHealthData) {
    return {
      cogs: [],
      units: [],
    };
  }

  return {
    units:
      inventoryHealthData.units?.length > 0
        ? inventoryHealthData.units.map((data: InventoryTrend) => {
            return {
              startTime: data.startTime,
              sellableInventory: data.sellableInventory,
              unhealthyInventory: data.unhealthyInventory,
              daysCover:
                data.daysCover === null
                  ? null
                  : roundFractionalDigits(data.daysCover),
            };
          })
        : [],
    cogs:
      inventoryHealthData.cogs?.length > 0
        ? inventoryHealthData.cogs.map((data: InventoryTrend) => {
            return {
              startTime: data.startTime,
              sellableInventory: getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.sellableInventory
              ),
              unhealthyInventory: getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.unhealthyInventory
              ),
              daysCover:
                data.daysCover === null
                  ? null
                  : roundFractionalDigits(data.daysCover),
            };
          })
        : [],
  };
};

const InventoryHealthTrend = memo<InventoryHealthParams>(
  function InventoryHealthTrend({
    userInfo,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    chartType,
    actions,
    view,
    condensed,
    report,
    timezone,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const inventoryHealthTrend = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.data
    );

    const currency = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.currency
    );

    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.lastReportDate
    );

    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.lastUpdatedAt
    );

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.chart?.fetching || false
    );

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchInventoryTrend();
      };
      fetchData();
    }, [currentRange, mid, userInfo]);

    const dispatchFetchInventoryTrend = useCallback(() => {
      dispatch(
        fetchInventoryTrend(
          {
            user: userInfo,
            currentRange,
            mid,
          },
          params
        )
      );
    }, [currentRange, mid, userInfo, currentCurrency]);

    const data = useMemo(() => {
      return formatInventoryHealthData(
        inventoryHealthTrend,
        currentCurrency,
        currency,
        currencyRates
      );
    }, [
      currentRange,
      mid,
      userInfo,
      inventoryHealthTrend,
      currentCurrency,
      chartType,
      view,
    ]);

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "inventoryhealth"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="widget-sales-performance"
        title={t("inventoryHealthTrend.mainTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.sourcingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.sourcingView")}`
        }
        tooltip={t("inventoryHealthTrend.mainTooltip")}
        actions={actions}
        footerLink={footerLink}
        content={
          loading ? (
            <PanelLoading />
          ) : (
            <Box p={2}>
              <Grid container item xs={12}>
                <StackedBarAndLineChart
                  title={t("inventoryHealth.weeklySnapShotTrend")}
                  // inventory data is only available at weekly granularity
                  interval={INTERVAL.WEEKS}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  chartData={data[view]}
                  view={view}
                  isLoading={loading}
                  line={{
                    key: "daysCover",
                    colour: theme.palette.primary.main,
                  }}
                  topBar={{
                    key: "unhealthyInventory",
                    colour: theme.palette.disabled.main,
                  }}
                  bottomBar={{
                    key: "sellableInventory",
                    colour: theme.palette.secondary.main,
                  }}
                  xKey="startTime"
                  report={report}
                  timezone={timezone}
                />
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default InventoryHealthTrend;
