import { Box, Grid, useTheme } from "@material-ui/core";
import React, { ReactChild, memo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import MultiBarLineChart from "~/components/charts/multiBarLineChart/multiBarLineChart";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import { get } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useTrafficAndConversionTrendQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TrafficAndConversionParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  actions?: ReactChild;
  report?: boolean;
  condensed?: boolean;
  timezone: string;
}

const TrafficAndConversionTrend = memo<TrafficAndConversionParams>(
  function TrafficAndConversionTrend({
    mid,
    marketplaceType,
    marketplaceSubtype,
    currentPeriod,
    currentRange,
    currentCurrency,
    actions,
    report,
    condensed,
    timezone,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const { data, loading } = useTrafficAndConversionTrendQuery(
      {
        mid,
        marketplaceFilters: {
          marketplaceType,
          marketplaceSubtype,
        },
        currentRange,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          data: data?.data || [],
          loading: isFetching,
        }),
      }
    );

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink(marketplaceType, mid, "trafficandconversion"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="traffic-conversion-trend"
        title={t("chartTitle.trafficAndConversionTrend")}
        tooltip={
          marketplaceType === "walmart"
            ? t("trafficAndConversion.walmartTooltip")
            : undefined
        }
        actions={actions}
        footerLink={footerLink}
        content={
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <MultiBarLineChart
                  title={t(CHART_TITLES[currentPeriod])}
                  currentPeriod={currentPeriod}
                  currencyRates={currencyRates}
                  currentCurrency={currentCurrency}
                  chartData={data}
                  isLoading={loading}
                  bars={[
                    {
                      key: "pageViews",
                      colour: theme.palette.primary.main,
                      axis: "1",
                    },
                  ]}
                  lines={[
                    {
                      key: "conversionRate",
                      colour: theme.palette.secondary.main,
                      axis: "2",
                      toFixed: 1,
                    },
                    {
                      key: "sessionConversionRate",
                      colour: theme.palette.success.dark,
                      axis: "2",
                      toFixed: 1,
                    },
                  ]}
                  xKey="startTime"
                  report={report}
                  timezone={currentRange.timezone}
                  interval={currentRange.interval}
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default TrafficAndConversionTrend;
