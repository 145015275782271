export const amazonHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    url: "<amazonSellerUrl>/performance/dashboard",
    items: [
      {
        key: "inactiveListings",
        title: "dashboardWidget.accountHealth.inactiveListings",
      },
      {
        key: "defectData",
        title: "dashboardWidget.accountHealth.listingEnhancements",
      },
      {
        key: "returnRequests",
        title: "dashboardWidget.accountHealth.returnRequests",
        tooltip: "",
        url: "<amazonSellerUrl>/gp/returns/list/ref=xx_myr_dnav_xx",
      },
      {
        key: "suppressedListings",
        title: "dashboardWidget.accountHealth.suppressedListings",
        route: "/mystores/amazon/suppressedproducts",
      },
    ],
  },
  shippingPerformance: {
    colspan: 4,
    url: "<amazonSellerUrl>/performance/detail/shipping?ref=sp_st_dash_cs_vm",
    items: [
      {
        key: "lateShipment",
        title: "dashboardWidget.accountHealth.lateShipment",
        tooltip: "dashboardWidget.accountHealth.tooltip.amazon.lateShipment",
        suffix: "%",
        displayTimePeriod: true,
        /* Should this item also display absolute values as well as
         * proportions/percentages? */
        displayTotals: true,
      },
      {
        key: "invoiceDefectRate",
        title: "dashboardWidget.accountHealth.invoiceDefectRate",
        //          tooltip: "dashboardWidget.accountHealth.tooltip.amazon.invoiceDefectRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "onTimeDeliveryRate",
        title: "dashboardWidget.accountHealth.onTimeDeliveryRate",
        //          tooltip: "dashboardWidget.accountHealth.tooltip.amazon.onTimeDeliveryRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "validTrackingRate",
        title: "dashboardWidget.accountHealth.validTrackingRate",
        //          tooltip: "dashboardWidget.accountHealth.tooltip.amazon.validTrackingRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "cancelRate",
        title: "dashboardWidget.accountHealth.cancelRate",
        tooltip: "dashboardWidget.accountHealth.tooltip.amazon.cancelRate",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
    ],
  },
  customerServicePerformanceFbm: {
    colspan: 4,
    url: "<amazonSellerUrl>/performance/detail/customer-service?ref=sp_st_dash_cs_vm",
    items: [
      {
        key: "odr",
        title: "dashboardWidget.accountHealth.odr",
        tooltip: "dashboardWidget.accountHealth.tooltip.amazon.odr",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "negativeFeedback",
        title: "dashboardWidget.accountHealth.negativeFeedback",
        displayTotals: true,
      },
      {
        key: "chargebacks",
        title: "dashboardWidget.accountHealth.chargebacks",
        displayTotals: true,
      },
      {
        key: "azGuaranteeClaims",
        title: "dashboardWidget.accountHealth.azGuaranteeClaims",
        displayTotals: true,
      },
    ],
  },
  policyCompliance: {
    colspan: 8,
    url: "<amazonSellerUrl>/performance/account/health/product-policies",
    items: [
      {
        key: "ipComplaints",
        title: "dashboardWidget.accountHealth.ipComplaints", //Received intellectual property complaints",
      },
      {
        key: "suspectedIntellectualPropertyViolations",
        title: "dashboardWidget.accountHealth.ipViolations",
      },
      {
        key: "listingPolicyViolations",
        title: "dashboardWidget.accountHealth.listingPolicyViolations", //"Listing policy violations",
      },
      {
        key: "authenticityComplaints",
        title: "dashboardWidget.accountHealth.authenticityComplaints", //"Product authenticity customer complaints",
      },
      {
        key: "productSafetyComplaints",
        title: "dashboardWidget.accountHealth.productSafetyComplaints",
      },
      {
        key: "foodAndProductSafetyIssues",
        title: "dashboardWidget.accountHealth.foodAndProductSafetyIssues",
      },
      {
        key: "restrictedProductPolicyViolations",
        title: "dashboardWidget.accountHealth.restrictedPolicyViolations", //"Restricted product policy violations",
      },
      {
        key: "otherPolicyViolations",
        title: "dashboardWidget.accountHealth.otherPolicyViolations",
      },
    ],
  },
  customerServicePerformanceFba: {
    colspan: 4,
    url: "<amazonSellerUrl>/performance/detail/customer-service?ref=sp_st_dash_cs_vm",
    items: [
      {
        key: "odrFba",
        title: "dashboardWidget.accountHealth.odr",
        tooltip: "dashboardWidget.accountHealth.tooltip.amazon.odr",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "negativeFeedbackFba",
        title: "dashboardWidget.accountHealth.negativeFeedback",
        displayTotals: true,
      },
      {
        key: "chargebacksFba",
        title: "dashboardWidget.accountHealth.chargebacks",
        displayTotals: true,
      },
      {
        key: "azGuaranteeClaimsFba",
        title: "dashboardWidget.accountHealth.azGuaranteeClaims",
        displayTotals: true,
      },
    ],
  },
};
