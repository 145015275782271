import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Typography } from "@material-ui/core";

import Bold from "~/components/typography/bold";
import { ChartDataDefinition } from "./multiComposedChart";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LegendIndicator } from "./multiChartLegend";
import React from "react";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

interface TooltipInfoProps {
  fillColor: string;
  shape: string;
  name: string | number;
  value: string | number;
  unit: string;
}

const TooltipInfo: React.FC<TooltipInfoProps> = ({
  fillColor,
  shape,
  name,
  value,
  unit,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      mt={0.5}
      alignItems="center"
      width="100%"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        mr={1}
      >
        <LegendIndicator shape={shape} fill={fillColor} />
        <Typography variant="body2">{t(`chartLegend.${name}`)}:</Typography>
      </Box>
      <Typography variant="body2">{`${unit}${value}`}</Typography>
    </Box>
  );
};

interface TooltipData {
  [key: string]: number;
}

interface MultiChartTooltipProps {
  active?: boolean;
  label?: number;
  items: ChartDataDefinition[];
  payload?: { payload: TooltipData }[];
  currentPeriod: DATETIME_PERIODS;
  currency: string;
  timezone: string;
}

const MultiChartTooltip: React.FC<MultiChartTooltipProps> = ({
  payload,
  label,
  currentPeriod,
  currency,
  items,
  timezone,
}) => {
  const date = formatTooltipLabel(label, currentPeriod, undefined, timezone);
  const tooltipData = payload?.length ? payload[0].payload : null;
  const { endTime } = tooltipData || {};
  const endDate = endTime
    ? formatTooltipLabel(endTime, currentPeriod, undefined, timezone)
    : null;

  return (
    <BlackTooltip>
      <Box p={1} display="flex" flexDirection="column">
        <Bold variant="body2">{date}</Bold>
        {endDate && <Bold variant="body2">- {endDate}</Bold>}
        {items.map((item) => {
          if (
            tooltipData &&
            (typeof item.dataKey === "string" ||
              typeof item.dataKey === "number") &&
            typeof tooltipData[item.dataKey] === "number"
          ) {
            const rawValue = tooltipData[item.dataKey];
            const value = rawValue ? numberWithCommas(rawValue) : "0.00";
            return (
              <TooltipInfo
                key={item.dataKey}
                name={item.dataKey}
                value={value}
                fillColor={item.color}
                shape={item.type}
                unit={currency}
              />
            );
          } else {
            return null;
          }
        })}
      </Box>
    </BlackTooltip>
  );
};

export default MultiChartTooltip;
