import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import BannerWithLink from "~/components/alert/bannerWithLink";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ProfitAndLoss from "~/modules/widgets/profitability/profitAndLoss";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityMonthly = memo(() => {
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const { t } = useTranslation();

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const { isAdvertisingAuthorised } = useAdvertisingConnected(currentStore);

  return (
    <PageBlock>
      {currentStore ? (
        <>
          <Grid container spacing={4}>
            {currentStore.marketplace === "amazon" &&
              !isAdvertisingAuthorised && (
                <Grid container item xs={12}>
                  <BannerWithLink
                    type="info"
                    isOpen={true}
                    link={"/connectStore/select"}
                    linkText={t("generic.linkButton")}
                    message={t("profitability.linkAdvertisingMessage")}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <ProfitAndLoss
                title={t("profitability.monthlyProfitTitle")}
                currentCurrency={currentCurrency}
                store={currentStore}
                currentRange={currentRange}
                includeTax={includeTax}
                groupByMonth={true}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ProfitabilityMonthly;
