import { Grid, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import SubscriptionAlert from "@modules/subscription/alerts/subscriptionAlert";
import styled from "styled-components";
import { useCustomLogo } from "~/hooks/useCustomLogo";

interface LogoImgProps {
  $fullWidth?: boolean;
}

const LogoImg = styled.img`
  ${({ $fullWidth }: LogoImgProps) => {
    if ($fullWidth) {
      return `
      max-width: 80%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      `;
    } else {
      return "height: 3rem;";
    }
  }}
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

interface SimpleToolbarProps {
  fullWidth?: boolean;
  reportFormat?: boolean;
}

const SimpleToolbar = memo(
  ({ fullWidth, reportFormat }: SimpleToolbarProps) => {
    const theme = useTheme();

    const logoImgProps = {
      $fullWidth: fullWidth,
      src: useCustomLogo(!theme.darkModeEnabled),
      alt: "MerchantSpring Logo",
    };

    return (
      <Grid
        id="simple-toolbar"
        container
        item
        xs={12}
        sm={12}
        direction="column"
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems={reportFormat ? "flex-start" : "center"}
        >
          <LogoImg {...logoImgProps} className="report-logo" />
        </Grid>
        <SubscriptionAlert />
      </Grid>
    );
  }
);

export default SimpleToolbar;
