import React, { memo } from "react";

import AdGroupChartPanel from "~/modules/marketing/charts/adGroup.tsx";
import AdGroupTotalsPanel from "~/modules/marketing/charts/adGroupTotals.tsx";
import { Grid } from "@material-ui/core";
import MarketingBreadcrumbs from "~/components/toolbars/marketingBreadcrumbs";
import MarketingSubNav from "~/components/toolbars/marketingSubNav";
import MarketingSubNavItem from "~/components/toolbars/marketingSubNavItem";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ProductAdSummaryPanel from "~/modules/marketing/productAds";
import get from "lodash/get";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MarketingCampaignAdGroupProductAds = memo(() => {
  const { t } = useTranslation();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const location = useLocation();

  const navigationState = get(location, "state", {});
  const adGroupId = get(navigationState, "adGroupId");
  const adGroupName = get(navigationState, "adGroupName");

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <MarketingBreadcrumbs
          marketplace={store.marketplace}
          mid={store.merchantId}
          parentPage={"marketingCampaignAdGroups"}
          parentTitle={t("advertisingDashboardWidget.subnav.campaign")}
          title={adGroupName}
          state={navigationState}
        />
        <MarketingSubNav>
          <MarketingSubNavItem
            marketplace={store.marketplace}
            mid={store.merchantId}
            page={"marketingCampaignAdGroupKeywords"}
            title={t("advertisingDashboardWidget.subnav.keywords")}
            state={navigationState}
          />
          <MarketingSubNavItem
            title={t("advertisingDashboardWidget.subnav.ads")}
            selected={true}
          />
          <MarketingSubNavItem
            marketplace={store.marketplace}
            mid={store.merchantId}
            page={"marketingCampaignAdGroupNegatives"}
            title={t("advertisingDashboardWidget.subnav.negatives")}
            state={navigationState}
          />
        </MarketingSubNav>
        <Grid item xs={8}>
          <AdGroupChartPanel
            mid={store.merchantId}
            countryCode={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype}
            adGroupId={adGroupId}
          />
        </Grid>
        <Grid item xs={4}>
          <AdGroupTotalsPanel
            mid={store.merchantId}
            countryCode={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype}
            adGroupId={adGroupId}
          />
        </Grid>
        <Grid item xs={12}>
          <ProductAdSummaryPanel
            mid={store.merchantId}
            countryCode={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            adGroupId={adGroupId}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default MarketingCampaignAdGroupProductAds;
