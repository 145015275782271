import { Box, Grid, Link } from "@material-ui/core";
import { useDispatch } from "react-redux";

import AlertCard from "./alertCard";
import Medium from "../typography/medium";
import { PriorityHigh } from "@material-ui/icons";
import React from "react";
import SmallButton from "../buttons/smallButton";

import cookies from "browser-cookies";
import { setDemoBannerVisible } from "../../store/globalVar.redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core/styles";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { baseUrl } from "~/configs";
import CombinedLink from "../links/link";

const AVASK_LINK =
  "https://avask.merchantspring.io/signup?utm_source=avask_website&utm_medium=avask_landingpage&utm_campaign=avask_partnership&utm_id=avask&utm_content=new_user";

const AlertIcon = withTheme(styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.light};
  margin-right: 1rem;
`);

const DemoUserAlert = () => {
  const demoBannerVisible = useTypedSelector(
    (state) => state.globalVar.demoBannerVisible
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const originalDomain = cookies.get("originalDomain");

  const handleClose = () => {
    dispatch(setDemoBannerVisible(false));
  };

  const getLink = () => {
    if (originalDomain === "avask.merchantspring.io") {
      return AVASK_LINK;
    } else {
      return `https://${originalDomain}/signup`;
    }
  };

  const getLinkComponent = () => {
    if (originalDomain) {
      return (
        <SmallButton
          component={Link}
          color="info"
          target="_blank"
          href={getLink()}
        >
          {t("demo.bannerActionButton")}
        </SmallButton>
      );
    }
    return (
      <SmallButton
        component={CombinedLink}
        color="info"
        target="_blank"
        to="/signup"
      >
        {t("demo.bannerActionButton")}
      </SmallButton>
    );
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="stretch"
      flexDirection="column"
      mb={demoBannerVisible ? 2 : 0}
    >
      <AlertCard
        isOpen={demoBannerVisible}
        onClose={handleClose}
        type="warning"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center" width="100%">
              <AlertIcon>
                <PriorityHigh fontSize="small" />
              </AlertIcon>
              <Medium variant="body1">{t("demo.bannerMessage")}</Medium>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              height="100%"
              justifyContent="flex-end"
            >
              {getLinkComponent()}
            </Box>
          </Grid>
        </Grid>
      </AlertCard>
    </Box>
  );
};

export default DemoUserAlert;
