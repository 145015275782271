import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  deleteClientReportConfig,
  scheduleClientReport,
  sendClientReportViaEmail,
  setInitialState,
} from "~/store/mystore/clientReport.redux";
import { getReportDayAndTime, getScheduledDescription } from "./reportUtils";

import { ClientReportFormButton } from "./reportConfigForm";
import { CloseOutlined } from "@material-ui/icons";
import { ExternalLink } from "~/components/links/link";
import Icon from "@material-ui/core/Icon";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import ReportEmailField from "./reportEmailField";
import { ReportType } from "./createReport";
import StatusText from "~/components/typography/status";
import { Store } from "~/typedef/store";
import { baseUrl } from "~/configs";
import cookies from "browser-cookies";
import get from "lodash/get";
import i18next from "i18next";
import { isValidHttpUrl } from "~/utils/utils";
import moment from "moment-timezone";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const FlexIcon = styled(Icon)`
  align-items: center;
  display: flex;
`;

interface ReportPreviewProps {
  frequency: string | number;
  reportingDelay?: number;
  name: string;
  fromDate: number;
  toDate: number;
  period: string;
  stores: Store[];
  reportType: ReportType;
  selectedTags: string[];
  emailToBeSent: string[];
  setEmailToBeSent: (emailToBeSent: string[]) => void;
}

const ReportPreview = ({
  frequency,
  reportingDelay,
  name,
  fromDate,
  toDate,
  period,
  stores,
  reportType,
  selectedTags,
  emailToBeSent,
  setEmailToBeSent,
}: ReportPreviewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientReport = useTypedSelector((state) => get(state, "clientReport"));
  const timezone = get(clientReport, "reportConfig.timezone");
  const user = useTypedSelector((state) => get(state, "user"));
  const [emailError, setEmailError] = useState(false);

  const originalDomain = cookies.get("originalDomain");

  const resetEmailSent = () => {
    dispatch(setInitialState());
  };

  const onClickOfSendReport = () => {
    const reportDomain: string = originalDomain
      ? `https://${originalDomain}`
      : baseUrl;
    const validDomain: string = isValidHttpUrl(reportDomain)
      ? reportDomain
      : "https://" + reportDomain;
    if (frequency === 1) {
      dispatch(
        sendClientReportViaEmail({
          userId: user?._id,
          stores,
          payload: {
            reportRecipients: emailToBeSent,
            reportId: clientReport._id,
            reportName: name,
            type: "manual",
            reportPeriod: `${moment
              .unix(fromDate)
              .format("DD MMM YYYY")} - ${moment
              .unix(toDate)
              .format("DD MMM YYYY")}`,
            reportUrl: `${validDomain}/report?id=${clientReport._id}`,
            reportType,
            selectedTags: selectedTags.toString(),
          },
        })
      );
    } else {
      const { reportDay } = getReportDayAndTime(
        frequency,
        timezone,
        reportingDelay
      );
      dispatch(
        scheduleClientReport({
          userId: user?._id,
          stores,
          payload: {
            reportRecipients: emailToBeSent,
            reportId: clientReport._id,
            reportName: name,
            type: "scheduled",
            period,
            frequency,
            reportingDelay,
            timezone,
            reportDay,
            reportUrl: `${validDomain}/report?id=${clientReport._id}`,
            reportType,
          },
        })
      );
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteClientReportConfig({
        userId: user?._id,
        reportId: clientReport._id,
        t,
      })
    );
  };

  return (
    <Grid item xs={12}>
      <Panel
        id="preview-report"
        title={
          clientReport.emailSent
            ? frequency === 1
              ? `${t("createReport.reportSent")}!`
              : `${t("createReport.reportScheduled")}!`
            : t("panel.previewReport")
        }
        actions={
          clientReport.emailSent ? (
            <CloseOutlined
              onClick={() => resetEmailSent()}
              style={{ fontSize: "medium", color: "textSecondary" }}
            />
          ) : undefined
        }
        content={
          <Box p={2}>
            <Grid container spacing={2}>
              {clientReport.emailSent && (
                <Grid item xs={12}>
                  <StatusText variant="body2" status="info" noWrap>
                    {getScheduledDescription(
                      frequency,
                      t,
                      timezone,
                      reportingDelay
                    )}
                  </StatusText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  {clientReport.emailSent && (
                    <FlexIcon fontSize="large">
                      <img src={tickLogo} />
                    </FlexIcon>
                  )}
                  <Typography variant="body1">
                    {t("createReport.viewReport")}:
                  </Typography>
                  <Box pl={1} />
                  <ExternalLink
                    {...{
                      to: `/report?id=${clientReport._id}`,
                      color: "textSecondary",
                      variant: "body1",
                      underline: "always",
                    }}
                  >
                    {clientReport.reportConfig.name} -{" "}
                    {clientReport.marketplaceSubtype ||
                      clientReport.marketplace}{" "}
                    - {clientReport.shopName}
                  </ExternalLink>
                </Box>
              </Grid>
              {clientReport.emailSent ? null : (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      {t("createReport.generatedOn")}:{" "}
                      {moment(clientReport.createdAt)
                        .locale(i18next.language)
                        .format("ll LT ")}{" "}
                      ({get(clientReport, "reportConfig.timezone")})
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ReportEmailField
                      {...{
                        emailToBeSent,
                        setEmailToBeSent,
                        emailError,
                        setEmailError,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StatusText variant="body2" status="info" noWrap>
                      {getScheduledDescription(
                        frequency,
                        t,
                        timezone,
                        reportingDelay
                      )}
                    </StatusText>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        }
        footer={
          clientReport.emailSent ? undefined : (
            <Box display="flex" width="100%" justifyContent="space-between">
              <ClientReportFormButton
                disabled={
                  !name ||
                  clientReport.sending ||
                  clientReport.fetching ||
                  emailError ||
                  emailToBeSent.length <= 0
                }
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => onClickOfSendReport()}
              >
                {clientReport.sending ? (
                  <LoadingIndicator size={20} />
                ) : frequency === 1 ? (
                  t("createReport.sendReport")
                ) : (
                  t("createReport.scheduleReport")
                )}
              </ClientReportFormButton>
              {!(clientReport.isSent || clientReport.isScheduled) && (
                <ClientReportFormButton
                  disabled={clientReport.fetching || clientReport.deleting}
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete()}
                >
                  {clientReport.deleting ? (
                    <LoadingIndicator size={20} />
                  ) : (
                    t("createReport.deleteButton")
                  )}
                </ClientReportFormButton>
              )}
            </Box>
          )
        }
        footerLink={
          clientReport.emailSent
            ? {
                url:
                  frequency === 1
                    ? "/reports/reportsSent"
                    : "/reports/scheduledReports",
                label:
                  frequency === 1
                    ? t("createReport.viewSentReports")
                    : t("createReport.viewScheduledReports"),
              }
            : undefined
        }
      />
    </Grid>
  );
};

export default ReportPreview;
