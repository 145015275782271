import {
  FETCH_OUT_OF_STOCK,
  FETCH_OUT_OF_STOCK_ERROR,
  FETCH_OUT_OF_STOCK_FETCHING,
} from "../mystore/mystore.redux";

import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const fetchOutOfStockProducts =
  ({
    mid,
    pageSize,
    pageIndex,
    sortKey,
    sortOrder,
    daysCoverFilter,
    includeTax,
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_OUT_OF_STOCK_FETCHING,
      payload: { mid },
    });
    try {
      const res = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/outOfStock`,
        {
          mid,
          pageSize,
          pageIndex,
          sortKey,
          sortOrder,
          daysCoverFilter,
          includeTax,
        }
      );
      if (res) {
        if (isHttpResponseValid(res.status)) {
          const data = res.data;
          return dispatch({
            type: FETCH_OUT_OF_STOCK,
            payload: { data, mid },
          });
        } else {
          return setError(dispatch, res.data.errMsg, res.status);
        }
      }
      return setError(dispatch);
    } catch (e) {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      setError(dispatch, msg || statusText, get(err, "status"));
      return dispatch({
        type: FETCH_OUT_OF_STOCK_ERROR,
        payload: e,
      });
    }
  };
