import {
  Box,
  CircularProgress,
  FormControl,
  Link,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FormEvent, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import RaisedButton from "~/components/buttons/raisedButton";
import TextField from "@material-ui/core/TextField";
import { WalmartLogo } from "../../img/marketplaces/logos/logos";
import { connectionStatus } from "~/utils/marketplaceUtils";
import crossLogo from "~/img/cross_large.png";
import { loadInfo } from "~/store/user.redux";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useWalmartConnectMutation } from "~/store/connections/connectWalmart.redux";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
  width: 80%;
  max-width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const StyledWalmartLogo = styled(WalmartLogo)`
  height: 70px;
`;

const LinkWrapper = styled(Typography)`
  margin-bottom: 20px;
`;

const AccontNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const ConnectionStationIcon = styled(Icon)`
  margin-right: 5px;
`;

type TransactionType = {
  status: string;
  errMsg?: string;
};

const countries = [
  {
    value: "USA",
    label: "United States",
  },
  {
    value: "MEX",
    label: "Mexico",
  },
  {
    value: "CAN",
    label: "Canada",
  },
];

const WalmartConnectPage = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [shopName, setShopName] = useState("");
  const [transaction, setTransaction] = useState<TransactionType>({
    status: connectionStatus.CONNECTION_INIT,
    errMsg: "",
  });
  const [countryCode, setCountryCode] = useState("USA");
  const SHOP_NAME_LIMIT = 30;

  const [connectWalmart, { isError, isLoading, isUninitialized, isSuccess }] =
    useWalmartConnectMutation();

  const hasError =
    isError || transaction.status === connectionStatus.CONNECTION_FAILED;

  const handleConnectBtn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!shopName) {
      setTransaction({
        status: connectionStatus.CONNECTION_FAILED,
        errMsg: t("connectWizard.walmartEmptyAccountNameError"),
      });
      return;
    }
    if (shopName.length > SHOP_NAME_LIMIT) {
      setTransaction({
        status: connectionStatus.CONNECTION_FAILED,
        errMsg: t("connectWizard.walmartAccountNameLengthError"),
      });
      return;
    }

    try {
      await connectWalmart({
        clientId,
        clientSecret,
        shopName,
        countryCode,
      });
      await dispatch(loadInfo());
      if (onSuccess) {
        await onSuccess();
      }
    } catch (err) {
      setTransaction({
        status: connectionStatus.CONNECTION_FAILED,
      });
    }
  };

  const renderConnectForm = () => (
    <>
      <Grid
        container
        item
        xs={11}
        sm={6}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" color="textPrimary" align="center">
          {t("connectWizard.walmartLinkTitle")}
        </Typography>
      </Grid>
      <Form method="POST" onSubmit={(event) => handleConnectBtn(event)}>
        <FormControl>
          <TextField
            fullWidth
            label={t("connectWizard.walmartClientIdLabel")}
            margin="normal"
            required
            onChange={(e) => {
              setClientId(e.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label={t("connectWizard.walmartClientSecretLabel")}
            margin="normal"
            required
            onChange={(e) => {
              setClientSecret(e.target.value);
            }}
          />
        </FormControl>
        <LinkWrapper align="right">
          <Link href={t("connectWizard.walmartHelpLinkUrl")} target="_blank">
            {t("connectWizard.walmartHelpLink")}
          </Link>
        </LinkWrapper>
        {user.walmartMexAndCanOn && (
          <FormControl>
            <Box pt={1} pb={1}>
              <Typography variant="body2" color="textPrimary">
                {t("connectWizard.walmartCountryLabel")}
              </Typography>
            </Box>
            <Box pt={1} pb={4} width={1}>
              <NativeSelect
                name={"countryCode"}
                value={countryCode}
                options={countries}
                onChange={(
                  e: ChangeEvent<{ name?: string; value: unknown }>
                ) => {
                  const selectedCountry = countries.find(
                    (country) => country.value === e.target.value
                  );
                  if (selectedCountry) {
                    setCountryCode(selectedCountry.value);
                  }
                }}
                autoFocus={true}
                fullWidth
              />
            </Box>
          </FormControl>
        )}
        <FormControl>
          <Typography variant="body2" color="textPrimary">
            {t("connectWizard.walmartAccountNameTitle")}
          </Typography>
          <AccontNameWrapper
            fullWidth
            label={t("connectWizard.walmartAccountNameLabel")}
            margin="normal"
            required
            onChange={(e) => {
              setShopName(e.target.value);
            }}
          />
        </FormControl>
        <ButtonWrapper>
          <RaisedButton
            disabled={!clientId || !clientSecret || !shopName}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("connectWizard.connectButton")}
          </RaisedButton>
        </ButtonWrapper>
      </Form>
    </>
  );

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item>
            <a
              href="https://www.walmart.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledWalmartLogo />
            </a>
          </Grid>
          <Grid container direction="column" alignItems="center">
            {isLoading && <CircularProgress />}
            {isUninitialized && renderConnectForm()}
            {(isSuccess || hasError) && (
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <ConnectionStationIcon>
                  <img src={isSuccess ? tickLogo : crossLogo} alt="logo" />
                </ConnectionStationIcon>
                <Typography variant="body1" color="textPrimary" align="center">
                  {isSuccess
                    ? t("connectWizard.walmartSuccessMessage")
                    : transaction.errMsg || t("generic.generalError")}
                </Typography>
              </Grid>
            )}
          </Grid>
          {isSuccess && (
            <ButtonWrapper>
              <RaisedButton
                variant="outlined"
                color="primary"
                onClick={() => history.push("/overview")}
              >
                {t("nav.dashboard")}
              </RaisedButton>
            </ButtonWrapper>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WalmartConnectPage;
