import { Box, Grid, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import AlertCard from "./alertCard";
import Bold from "../typography/bold.tsx";
import CombinedLink from "../links/link.tsx";
import { CountryLogo } from "~/img/flags/country_logos";
import { MarketplaceIcon } from "~/img/marketplaces/icons/marketplaceIcon";
import { PriorityHighRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ErrorIcon = withTheme(styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error.light};
  margin-right: 1rem;
`);

const Spacer = styled.div`
  display: flex;
  width: 3px;
`;

export const FloatRight = styled.div`
  display: flex;
  float: right;
  margin-left: auto;
`;

const DisconnectedStoresAlert = memo(function DisconnectedStoresAlert({
  store,
  ...props
}) {
  const { t } = useTranslation();
  const mid = get(store, "mid");
  const marketplace = get(store, "market");
  const marketplaceSubtype = get(store, "marketplaceSubtype");
  const countryCode =
    get(store, "countryCode") || // from /api/store/disconnected
    get(store, "marketplaceCountry"); // from persistentAppSettings.settings.currentStore
  const shopName = get(store, "shopName");

  return (
    <Box mb={2}>
      <AlertCard isOpen={true} type="error" onClose={props.onClose}>
        <ErrorIcon>
          <PriorityHighRounded fontSize="small" />
        </ErrorIcon>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <MarketplaceIcon
            market={marketplace}
            marketplaceSubtype={marketplaceSubtype}
          />
          <Spacer />
          <CountryLogo code={countryCode} />
          <Spacer />
          <Bold variant="body1">{shopName || mid}</Bold>
          <Typography variant="body1">
            &nbsp;{t("generic.storeDisconnectedMessage")}
          </Typography>
        </Box>
        <FloatRight>
          <Grid container direction="row" spacing={2} padding={0}>
            <Grid item>
              <CombinedLink
                to={{
                  pathname: "/setting",
                  search: "?showDisconnected",
                }}
                color="inherit"
                variant="body1"
                underline="always"
              >
                {t("generic.manageDisconnectedLink")}
              </CombinedLink>
            </Grid>
            <Grid item>
              <CombinedLink
                to={`/connectStore?step=1`}
                color="inherit"
                variant="body1"
                underline="always"
              >
                {t("generic.reconnectNowLink")}
              </CombinedLink>
            </Grid>
          </Grid>
        </FloatRight>
      </AlertCard>
    </Box>
  );
});

DisconnectedStoresAlert.propTypes = {
  store: PropTypes.shape({
    mid: PropTypes.string,
    countryCode: PropTypes.string,
    marketplaceSubtype: PropTypes.string,
    shopName: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default DisconnectedStoresAlert;
