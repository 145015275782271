import { ProfitAndLossTable } from "./profitAndLossTable";
import React from "react";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { VendorChannelProfitAndLossResponse } from "~/store/mystore/vendorProfitability.redux";
import { useFormattedTableData } from "./hooks/useFormattedTableData";
import { useProfitAndLossColumns } from "./hooks/useProfitAndLossColumns";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ProfitAndLossPopOutProps {
  data:
    | Omit<
        VendorChannelProfitAndLossResponse,
        "snsFromDate" | "snsToDate" | "currency"
      >
    | undefined;
  currentCurrency: string;
  currency: string;
  sellType: SellType;
}

export const ProfitAndLossPopOut = ({
  currentCurrency,
  data,
  sellType,
  currency,
}: ProfitAndLossPopOutProps) => {
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  if (!data) return null;

  const { income, expense, metrics } = data;

  const { formattedTableData } = useFormattedTableData({
    income,
    expense,
    metrics,
    showBreakdown: false,
    currencyRates,
    sellType,
    currentCurrency,
    currency,
    canUploadData: false,
  });

  const columns = useProfitAndLossColumns({
    currentCurrency,
  });

  return <ProfitAndLossTable {...formattedTableData} columns={columns} />;
};
