import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const WidePaper = styled(Paper)`
  min-width: 40%;
  min-height: 16rem;
  max-height: 20rem;
  overflow-x: hidden;
`;

type BaseDialogProps = DialogProps & {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
};

export const BaseDialog = ({
  children,
  open,
  onClose,
  ...props
}: BaseDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperComponent={WidePaper}
      {...props}
    >
      {children}
    </Dialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  flex-grow: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const RightIconButton = styled(IconButton)`
  margin-left: auto;
`;

type BaseDialogTitleProps = DialogTitleProps & {
  children: React.ReactNode;
  variant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
  onClose?: () => void;
};

export const BaseDialogTitle = ({
  children,
  onClose,
  variant = "h6",
  ...props
}: BaseDialogTitleProps) => (
  <StyledDialogTitle disableTypography {...props}>
    <Grid container alignItems="center">
      <Typography variant={variant}>{children}</Typography>
      {onClose && (
        <RightIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </RightIconButton>
      )}
    </Grid>
  </StyledDialogTitle>
);
