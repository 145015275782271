import { Grid, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";
import { CheckCircleRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import styled from "styled-components";

const NoWrapGrid = styled(Grid)`
  overflow: hidden;
`;

const StatusIcon = withTheme(styled(CheckCircleRounded)`
  color: ${({ theme, $customColor }) => theme.palette[$customColor].main};
`);

export const TextAndIconCell = memo(({ cell }) => {
  const value = cell.value.value;
  const icon = cell.value.icon;
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
      wrap="nowrap"
    >
      <NoWrapGrid item>
        <Typography variant="body2" noWrap>
          {value}
        </Typography>
      </NoWrapGrid>
      {icon === "success" && (
        <Grid item>
          <StatusIcon $customColor={icon} fontSize="small" />
        </Grid>
      )}
    </Grid>
  );
});

TextAndIconCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};
