import { getCountryCode, getCountryCodeToISO2 } from "~/utils/countryUtils";

import Flag from "react-flagkit";
import React from "react";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";

const currencyByCountryCode = Object.values(getCurrencyByCountryCode);
const iso2CountryCodes = getCountryCode.map((code) =>
  getCountryCodeToISO2(code)
);
const codes = [...getCountryCode, ...iso2CountryCodes];

interface CountryLogoProps {
  currencyCode?: typeof currencyByCountryCode[number];
  flagHeight?: string;
  code?: typeof codes[number];
}
export const CountryLogo = ({
  currencyCode,
  flagHeight = "15px",
  code,
  ...props
}: CountryLogoProps) => {
  if (currencyCode && currencyCode === "EUR") {
    return <Flag country={"EU"} height={flagHeight} {...props} />;
  }
  if (!code) {
    return <Flag country={"AU"} height={flagHeight} {...props} />;
  }

  return (
    <Flag country={getCountryCodeToISO2(code)} height={flagHeight} {...props} />
  );
};
