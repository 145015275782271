import { FieldValue, StyledTableCell } from "../subscriptionStyledComponents";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const BillingHistoryPanel = ({ subscription: { transactions } }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {transactions && transactions.length > 0 ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {t("settings.billing.dateLabel")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t("settings.billing.amountLabel")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.createdAt}>
                    <StyledTableCell component="th" scope="row">
                      {moment(transaction.createdAt).format("D MMMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {transaction.amount}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <FieldValue>{t("settings.billing.noActivitiesMessage")}</FieldValue>
        )}
      </Grid>
    </Grid>
  );
};

BillingHistoryPanel.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default BillingHistoryPanel;
