import React, { useRef, useState } from "react";

import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import { Store } from "~/typedef/store";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { setAmazonStoreName } from "../../store/connections/connectAmazon.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 325px;
  width: 100%;
  padding-top: 2rem;
`;

export const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StandaloneStoreName = ({
  marketplaceType,
  marketplaceSubtype,
  countryCode,
  mid,
  onSuccess,
}: {
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  mid: string;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errMsg = useRef(t("connectWizard.amazonFriendlyNameEmptyError"));
  const [loading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [done, setDone] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tempStoreName = event.target.value;
    if (tempStoreName !== "") errMsg.current = "";
    else errMsg.current = t("connectWizard.amazonFriendlyNameEmptyError");

    const trimmedName = tempStoreName.trim();
    setStoreName(trimmedName !== "" ? tempStoreName : "");
  };

  const handleSubmit = async () => {
    if (storeName === "") {
      errMsg.current = t("connectWizard.amazonFriendlyNameEmptyError");
      return;
    }

    errMsg.current = "";
    setLoading(true);

    try {
      await dispatch(
        setAmazonStoreName(
          countryCode,
          mid,
          storeName.trim(),
          marketplaceType,
          marketplaceSubtype
        )
      );

      setDone(true);
      onSuccess();
    } catch (err) {
      errMsg.current = t("connectWizard.amazonFriendlyNameSaveError");
    }

    setLoading(false);
  };

  return (
    <>
      {done ? null : loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="amazon-updatestorename-wrapper">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Medium color="textSecondary">
                {t("connectWizard.amazonFriendlyNameTitle")}
              </Medium>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <Grid item xs={12}>
                <Medium color="textSecondary">
                  {t("connectWizard.amazonFriendlyNameLabel")}
                </Medium>
              </Grid>
              <Grid item xs={12}>
                <Box pt={1} pb={1} width={1}>
                  <TextField
                    value={storeName}
                    name="storeName"
                    margin="none"
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Box>
              </Grid>
              <>
                {errMsg && errMsg.current && (
                  <Grid container item xs={12}>
                    <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
                  </Grid>
                )}
              </>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={1}>
                <InlineIconButton />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("connectWizard.amazonFriendlyNameCanChangeMessage")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <RaisedButton
                  disabled={storeName === ""}
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  {t("generic.doneButton")}
                </RaisedButton>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default StandaloneStoreName;
