import {
  CurrentStore,
  FilteredStore,
  Store,
  StoreState,
} from "~/typedef/store";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import AccountHealth from "~/pages/singleChannel/operations/accountHealth";
import { Box } from "@material-ui/core";
import BuyBoxPerformance from "~/pages/singleChannel/pricing/buyBoxPerformance";
import CampaignsByGroupPage from "~/pages/singleChannel/marketing/campaignsByGroup";
import CustomerLTV from "~/pages/singleChannel/customer/customerLtv";
import ForecastsAndCosts from "~/pages/singleChannel/forecasts/forecastsAndCosts";
import GroupRevenue from "~/pages/singleChannel/brandAnalytics/groupRevenue";
import InventoryHealth from "~/pages/singleChannel/brandAnalytics/inventoryHealth";
import InventoryReplenishment from "~/pages/singleChannel/inventoryManagement/inventoryReplenishment";
import ItemSpecifics from "~/pages/singleChannel/marketplaceListings/itemSpecifics";
import ListingQuality from "~/pages/singleChannel/marketplaceListings/listingQuality";
import LoadingIndicator from "../components/loadingIndicator/loadingIndicator";
import MarketingAdGroupKeywords from "~/pages/singleChannel/marketing/marketingAdGroupKeywords";
import MarketingAdGroupNegatives from "~/pages/singleChannel/marketing/marketingAdGroupNegatives";
import MarketingAdGroupProductAds from "~/pages/singleChannel/marketing/marketingAdGroupProductAds";
import MarketingAdGroups from "~/pages/singleChannel/marketing/marketingAdGroups";
import MarketingCampaignAdGroupKeywords from "~/pages/singleChannel/marketing/marketingCampaignAdGroupKeywords";
import MarketingCampaignAdGroupNegatives from "~/pages/singleChannel/marketing/marketingCampaignAdGroupNegatives";
import MarketingCampaignAdGroupProductAds from "~/pages/singleChannel/marketing/marketingCampaignAdGroupProductAds";
import MarketingCampaignAdGroups from "~/pages/singleChannel/marketing/marketingCampaignAdGroups";
import MarketingCampaignNegatives from "~/pages/singleChannel/marketing/marketingCampaignNegatives";
import MarketingCampaigns from "~/pages/singleChannel/marketing/marketingCampaigns";
import MarketingKeywords from "~/pages/singleChannel/marketing/marketingKeywords";
import MarketingOverview from "~/pages/singleChannel/marketing/marketingOverview";
import MarketingProductAds from "~/pages/singleChannel/marketing/marketingProductAds";
import MarketingProducts from "~/pages/singleChannel/marketing/marketingProducts";
import OrderedRevenue from "~/pages/singleChannel/brandAnalytics/orderedRevenue";
import OutOfStock from "../pages/singleChannel/operations/outOfStock";
import OverdueOrders from "~/pages/singleChannel/operations/overdueOrders";
import ProfitabilityMonthly from "~/pages/singleChannel/profitability/profitabilityMonthly";
import ProfitabilityProduct from "~/pages/singleChannel/profitability/profitabilityProduct";
import ProfitabilityStore from "~/pages/singleChannel/profitability/profitabilityStore";
import ProfitabilityVendorByVendorCodes from "~/pages/singleChannel/profitability/vendor/profitabilityByVendorCodes";
import ProfitabilityVendorMonthly from "~/pages/singleChannel/profitability/vendor/profitabilityMonthly";
import ProfitabilityVendorProduct from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import ProfitabilityVendorStore from "~/pages/singleChannel/profitability/vendor/profitabilityStore";
import PurchaseOrders from "~/pages/singleChannel/salesInsights/purchaseOrders";
import RefundAnalytics from "~/pages/singleChannel/customer/refundAnalytics";
import RepeatPurchases from "~/pages/singleChannel/customer/repeatPurchases";
import Reviews from "~/pages/singleChannel/operations/reviewManagement";
import SalesByBrand from "~/pages/singleChannel/salesInsights/salesByBrand";
import SalesByCategory from "~/pages/singleChannel/salesInsights/salesByCategory";
import SalesByCountry from "~/pages/singleChannel/salesInsights/salesByCountry";
import SalesByGroup from "~/pages/singleChannel/salesInsights/salesByGroup";
import SalesByInterval from "~/pages/singleChannel/salesInsights/salesByInterval";
import SalesByParent from "~/pages/singleChannel/salesInsights/salesByParent";
import SalesByProduct from "~/pages/singleChannel/salesInsights/salesByProduct";
import ShippedRevenue from "~/pages/singleChannel/brandAnalytics/shippedRevenue";
import SourcingShare from "~/pages/singleChannel/brandAnalytics/sourcingShare";
import StoreDashboard from "~/pages/singleChannel/storeDashboards/storeDashboard";
import SubscriberAndSave from "~/pages/singleChannel/customer/subscribeAndSave";
import SuppressedProducts from "~/pages/singleChannel/marketplaceListings/suppressedProducts";
import TopProductsByBSR from "~/pages/singleChannel/salesInsights/salesByBSR";
import TopSellers from "~/pages/singleChannel/salesInsights/topSellers";
import TrafficAndConversion from "~/pages/singleChannel/trafficAndConversion";
import { User } from "~/typedef/user";
import { WithLazyLoad } from "..";
import WorstSellers from "~/pages/singleChannel/salesInsights/worstSellers";
import get from "lodash/get";
import { marketplaceLink } from "../utils/marketplaceUtils";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import usePrevious from "~/hooks/usePrevious";
import { useStore } from "../utils/navigationUtils";
import { useStoreConnection } from "~/hooks/useStoreConnection";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SpecificsRecommendationsPage = React.lazy(
  () => import("../pages/marketplaceConnectors/specificsRecommendationsPage")
);

const CircularProgress = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <LoadingIndicator />
  </Box>
);

const MyStoresRoutes = () => {
  const currentStore: CurrentStore | undefined = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const user: User = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();
  const [noStoreData, setNoStoreData] = useState(false);
  const location = useLocation();

  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );
  const store: FilteredStore | undefined = useStore();
  const previousStore: FilteredStore | undefined = usePrevious(store);

  const [storeHasChanged, setStoreHasChanged] = useState(
    Boolean(
      get(store, "merchantId") !== get(previousStore, "merchantId") ||
        get(store, "merchantId") !== get(currentStore, "merchantId")
    )
  );

  const connections = useTypedSelector(
    (state) => state.connections.connectionStatus
  );

  const { connection, firstConnectionIncomplete, showingDemoData } =
    useStoreConnection(connections, currentStore, filteredStores.stores);

  useEffect(() => {
    const noData = Boolean(
      (!currentStore ||
        !currentStore.marketplace ||
        !currentStore.merchantId) &&
        user
    );
    setNoStoreData(noData);
  }, [currentStore, user]);

  useEffect(() => {
    const hasChanged = Boolean(
      get(store, "merchantId") !== get(previousStore, "merchantId") ||
        get(store, "merchantId") !== get(currentStore, "merchantId")
    );

    if (hasChanged) {
      const selectedStore = {
        marketplace: get(store, "marketplaceType", ""),
        marketplaceSubtype: get(store, "marketplaceSubtype", ""),
        marketplaceCountry: get(store, "marketplaceCountry", ""),
        merchantId: get(store, "merchantId", ""),
        sourceSystemId: get(store, "sourceSystemId", ""),
        storeName: get(store, "storeName", ""),
        isDemoMode: get(store, "isDemoMode", false),
      };

      dispatch(
        upsertPersistentAppSettings({
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            currentStore: selectedStore,
          },
        })
      );
    }

    setStoreHasChanged(hasChanged);
  }, [previousStore, noStoreData, store, location, user]);

  if (location.pathname === "/mystores") {
    // No store has been selected yet, default to saved store from persistentAppSettings or
    // in redux or first in filteredStores
    if (filteredStores && filteredStores.stores) {
      if (filteredStores.stores.length && !filteredStores.fetching) {
        const foundDefaultStore = filteredStores.stores.find(
          (store: Store) => store.merchantId === currentStore?.merchantId
        );
        const defaultStore = foundDefaultStore || filteredStores.stores[0];
        const selectedStore = {
          marketplace:
            defaultStore.marketplaceSubtype || defaultStore.marketplaceType,
          marketplaceSubtype: defaultStore.marketplaceSubtype,
          marketplaceCountry: defaultStore.marketplaceCountry,
          merchantId: defaultStore.merchantId,
          sourceSystemId: defaultStore.sourceSystemId,
          storeName: defaultStore.storeName,
          isDemoMode: defaultStore.isDemoMode,
        };

        return (
          <Redirect
            to={marketplaceLink(
              selectedStore.marketplace,
              selectedStore.merchantId
            )}
          />
        );
      }
    }
    return <CircularProgress />;
  }

  if (connection && firstConnectionIncomplete && !showingDemoData)
    return <CircularProgress />;

  if (
    !store ||
    get(filteredStores, "fetching") ||
    filteredStores?.stores?.length == 0 ||
    storeHasChanged
  ) {
    return <CircularProgress />;
  }

  return (
    <Switch>
      <Route
        path={`/mystores/:marketplace/storeoverview`}
        component={StoreDashboard}
      />
      <Route
        path="/mystores/:marketplace/purchaseorders"
        component={PurchaseOrders}
      />
      <Route
        path="/mystores/:marketplace/salesbycategory"
        component={SalesByCategory}
      />
      <Route
        path="/mystores/:marketplace/salesbycountry"
        component={SalesByCountry}
      />
      <Route
        path="/mystores/:marketplace/salesbyinterval"
        component={SalesByInterval}
      />
      <Route
        path="/mystores/:marketplace/salesbybrand"
        component={SalesByBrand}
      />
      <Route
        path="/mystores/:marketplace/salesbygroup"
        component={SalesByGroup}
      />
      <Route
        path="/mystores/:marketplace/salesbyproduct"
        component={SalesByProduct}
      />
      <Route path="/mystores/:marketplace/topsellers" component={TopSellers} />
      <Route
        path="/mystores/:marketplace/topbsr"
        component={TopProductsByBSR}
      />
      <Route
        path="/mystores/:marketplace/worstsellers"
        component={WorstSellers}
      />
      <Route path="/mystores/:marketplace/outofstock" component={OutOfStock} />
      <Route
        path="/mystores/:marketplace/shippedrevenue"
        component={ShippedRevenue}
      />
      <Route
        path="/mystores/:marketplace/listingquality"
        component={ListingQuality}
      />
      <Route
        path="/mystores/:marketplace/suppressedproducts"
        component={SuppressedProducts}
      />
      <Route
        path="/mystores/:marketplace/itemspecifics/detail"
        component={WithLazyLoad(SpecificsRecommendationsPage)}
      />
      <Route
        path="/mystores/:marketplace/itemspecifics"
        component={ItemSpecifics}
      />
      <Route
        path="/mystores/:marketplace/accounthealth"
        component={AccountHealth}
      />
      <Route
        path="/mystores/:marketplace/overdueorders"
        component={OverdueOrders}
      />
      <Route path="/mystores/:marketplace/reviews" component={Reviews} />
      <Route
        path="/mystores/:marketplace/buyBoxPerformance"
        component={BuyBoxPerformance}
      />
      <Route
        path="/mystores/:marketplace/marketingOverview"
        component={MarketingOverview}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaigns"
        component={MarketingCampaigns}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaignAdGroups"
        component={MarketingCampaignAdGroups}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaignAdGroupKeywords"
        component={MarketingCampaignAdGroupKeywords}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaignAdGroupNegatives"
        component={MarketingCampaignAdGroupNegatives}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaignAdGroupProductAds"
        component={MarketingCampaignAdGroupProductAds}
      />
      <Route
        path="/mystores/:marketplace/marketingCampaignNegatives"
        component={MarketingCampaignNegatives}
      />
      <Route
        path="/mystores/:marketplace/marketingAdGroups"
        component={MarketingAdGroups}
      />
      <Route
        path="/mystores/:marketplace/marketingAdGroupKeywords"
        component={MarketingAdGroupKeywords}
      />
      <Route
        path="/mystores/:marketplace/marketingAdGroupNegatives"
        component={MarketingAdGroupNegatives}
      />
      <Route
        path="/mystores/:marketplace/marketingAdGroupProductAds"
        component={MarketingAdGroupProductAds}
      />
      <Route
        path="/mystores/:marketplace/marketingProducts"
        component={MarketingProducts}
      />
      <Route
        path="/mystores/:marketplace/marketingProductAds"
        component={MarketingProductAds}
      />
      <Route
        path="/mystores/:marketplace/marketingKeywords"
        component={MarketingKeywords}
      />
      <Route
        path="/mystores/:marketplace/campaignGroups"
        component={CampaignsByGroupPage}
      />
      <Route
        path="/mystores/:marketplace/profitabilityStore"
        component={ProfitabilityStore}
      />
      <Route
        path="/mystores/:marketplace/profitabilityMonthly"
        component={ProfitabilityMonthly}
      />
      <Route
        path="/mystores/:marketplace/profitabilityProduct"
        component={ProfitabilityProduct}
      />
      <Route
        path="/mystores/:marketplace/shippedRevenue"
        component={ShippedRevenue}
      />
      <Route
        path="/mystores/:marketplace/orderedRevenue"
        component={OrderedRevenue}
      />
      <Route
        path="/mystores/:marketplace/groupRevenue"
        component={GroupRevenue}
      />
      <Route
        path="/mystores/:marketplace/sourcingShare"
        component={SourcingShare}
      />
      <Route
        path="/mystores/:marketplace/inventoryHealth"
        component={InventoryHealth}
      />
      <Route
        path="/mystores/:marketplace/trafficandconversion"
        component={TrafficAndConversion}
      />
      <Route
        path="/mystores/:marketplace/trafficandconversionwalmart"
        component={TrafficAndConversion}
      />
      <Route
        path="/mystores/:marketplace/salesByParent"
        component={SalesByParent}
      />
      <Route
        path="/mystores/:marketplace/forecastsAndCosts"
        component={ForecastsAndCosts}
      />
      <Route
        path="/mystores/:marketplace/inventoryreplenishment"
        component={InventoryReplenishment}
      />
      <Route
        path="/mystores/:marketplace/customerltv"
        component={CustomerLTV}
      />
      <Route
        path="/mystores/:marketplace/repeatpurchases"
        component={RepeatPurchases}
      />
      <Route
        path="/mystores/:marketplace/subscribeandsave"
        component={SubscriberAndSave}
      />
      <Route
        path="/mystores/:marketplace/vendorprofitabilitystore"
        component={ProfitabilityVendorStore}
      />
      <Route
        path="/mystores/:marketplace/vendorprofitabilityproduct"
        component={ProfitabilityVendorProduct}
      />
      <Route
        path="/mystores/:marketplace/vendorprofitabilitymonthly"
        component={ProfitabilityVendorMonthly}
      />
      <Route
        path="/mystores/:marketplace/vendorProfitabilityByVendorCodes"
        component={ProfitabilityVendorByVendorCodes}
      />
      <Route
        path="/mystores/:marketplace/refundanalytics"
        component={RefundAnalytics}
      />
    </Switch>
  );
};

export default MyStoresRoutes;
