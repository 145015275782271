import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import ListingSummary from "~/modules/marketplaceListings/listingQuality/listingSummary";
import { StoreState } from "~/typedef/store";
import { fetchCategoryScores } from "../../../store/itemSpecifics.redux";
import get from "lodash/get";
import { loadListingQuality } from "../../../store/listingQuality.redux";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";

export interface EbayListingQualityProps {}

const EbayListingQuality: React.FC<EbayListingQualityProps> = () => {
  const listingQuality = useTypedSelector((state) => state.listingQuality);
  const itemSpecifics = useTypedSelector((state) => state.itemSpecifics);
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (store) {
      dispatch(fetchCategoryScores(store.merchantId, store.marketplaceCountry));
      dispatch(loadListingQuality(store.merchantId, store.marketplaceCountry));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.merchantId]);
  return store ? (
    <Grid item xs={12}>
      <ListingSummary
        {...{
          countryCode: store.marketplaceCountry,
          itemSpecifics: itemSpecifics,
          listingQuality: listingQuality,
        }}
      />
    </Grid>
  ) : (
    <LoadingIndicator />
  );
};

export default memo(EbayListingQuality);
