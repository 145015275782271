import { Avatar, Box, Link, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "../components/typography/bold";
import { BuildRounded } from "@material-ui/icons";
import RaisedButton from "../components/buttons/raisedButton";
import SimpleToolbar from "~/components/toolbars/simpleToolbar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Background = styled(Box)`
  background-color: white;
`;

const IconWrapper = withTheme(styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.info.dark};
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
`);

const MaintenancePage = memo(() => {
  const { t } = useTranslation();

  return (
    <Background
      display="flex"
      flexDirection="column"
      height="75%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <SimpleToolbar />
      <Box p={4} pb={10} maxWidth={500}>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml="-45px"
        >
          <IconWrapper>
            <BuildRounded color="primary" fontSize="small" />
          </IconWrapper>
          <Bold variant="h1">{t("maintenancePage.mainTitle")}</Bold>
        </Box>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h2" align="center">
            {t("maintenancePage.subtitle")}
          </Typography>
        </Box>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" align="center">
            {t("maintenancePage.message")}
          </Typography>
        </Box>

        <Box
          p={2}
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box p={1}>
            <RaisedButton
              component={Link}
              href="https://status.merchantspring.io/"
              color="primary"
            >
              {t("maintenancePage.viewStatusPage")}
            </RaisedButton>
          </Box>
        </Box>
      </Box>
    </Background>
  );
});

export default MaintenancePage;
