import { Box, Grid } from "@material-ui/core";
import React, { ReactChild, memo, useMemo } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import { useSalesByCustomerTypeQuery } from "~/store/mystore/repeatPurchases.redux";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const CHART_TYPE = {
  Customers: "customers",
  Revenue: "revenue",
  Orders: "orders",
};

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

interface SalesByCustomerTypeProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  timezone: string;
  includeTax: boolean;
  chartType: ChartOption;
  actions?: ReactChild;
}

const SalesByCustomerType = memo<SalesByCustomerTypeProps>(
  function SalesByCustomerType({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentPeriod,
    currentRange,
    timezone,
    includeTax,
    chartType,
    actions,
  }) {
    const { t } = useTranslation();

    const theme = useTheme();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const homeCurrency = getCurrencyByCountryCode[countryCode];

    const topBar = {
      key: "newToBrand",
      colour: theme.palette.secondary.main,
      isFixed: 0,
    };
    const bottomBar = {
      key: "existing",
      colour: theme.palette.primary.main,
      isFixed: 0,
    };

    const { isLoading, chartData } = useSalesByCustomerTypeQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
        includeTax,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isLoading: isFetching,
            chartData: data?.chartData || [],
          };
        },
      }
    );

    const convertedChartData = useMemo(() => {
      return chartData.map((data) => {
        const dataTimestamps = {
          startTime: data.startTime,
        };
        switch (chartType.value) {
          case CHART_TYPE.Customers:
            return {
              ...dataTimestamps,
              newToBrand: data.newCustomers,
              existing: data.existingCustomers,
              total: data.totalCustomers,
            };
          case CHART_TYPE.Revenue:
            return {
              ...dataTimestamps,
              newToBrand: getConvertedValue(
                currencyRates,
                homeCurrency,
                currentCurrency,
                data.newCustomersRevenue,
                0
              ),
              existing: getConvertedValue(
                currencyRates,
                homeCurrency,
                currentCurrency,
                data.existingCustomersRevenue,
                0
              ),
              total: getConvertedValue(
                currencyRates,
                homeCurrency,
                currentCurrency,
                data.totalCustomersRevenue,
                0
              ),
            };
          case CHART_TYPE.Orders:
            return {
              ...dataTimestamps,
              newToBrand: data.newCustomersOrders,
              existing: data.existingCustomersOrders,
              total: data.totalCustomersOrders,
            };
          default:
            return {
              ...dataTimestamps,
              newToBrand: data.newCustomers,
              existing: data.existingCustomers,
              total: data.totalCustomers,
            };
        }
      });
    }, [currentCurrency, chartData, currencyRates, chartType]);

    return (
      <Panel
        id="widget-sales-customer-type-trend"
        title={t("chartTitle.salesByCustomerType")}
        tooltip={t("myStoresWidget.salesByCustomerTypeTooltip")}
        actions={actions}
        content={
          <Box p={3}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                {chartData.length > 0 && bottomBar && (
                  <StackedBarAndLineChart
                    title={t(CHART_TITLES[currentPeriod])}
                    isLoading={isLoading}
                    chartData={convertedChartData}
                    xKey="startTime"
                    bottomBar={bottomBar}
                    topBar={topBar}
                    view={
                      chartType.value === CHART_TYPE.Revenue
                        ? "currency"
                        : "units"
                    }
                    currentPeriod={currentPeriod}
                    timezone={timezone}
                    currentCurrency={currentCurrency}
                    interval={currentRange.interval}
                    totalsKey="total"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default SalesByCustomerType;
