import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Flag from "react-flagkit";
import { getCountryCodeISO3ToCountry } from "~/utils/countryUtils";

interface CountryCellProps {
  cell: {
    value: {
      value: string;
      flagHeight?: string;
    };
  };
}

const CountryCell = memo<CountryCellProps>(function CountryCell({ cell }) {
  const { value, flagHeight = "15px" } = cell.value;
  const country = getCountryCodeISO3ToCountry(value);

  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      {country && (
        <Grid item>
          <Flag country={country?.iso2} height={flagHeight} />
        </Grid>
      )}
      <Grid item>
        <Typography variant="body2" noWrap>
          {country ? country.country : value}
        </Typography>
      </Grid>
    </Grid>
  );
});

export default CountryCell;
