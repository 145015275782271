import PropTypes from "prop-types";
import React from "react";
import amazonInactiveLogo from "./amazon_logo_disabled.png";
import amazonLogoRaw from "../icons/amazon-icon.png";
import catchLogoRaw from "../icons/catch.jpg";
import ebayLogoRaw from "../icons/ebay-favicon.jpg";
import iconicLogoRaw from "../icons/iconicicon.png";
import koganLogoRaw from "../icons/kogan.png";
import missingLogoRaw from "~/img/picMissing.jpg";
import shopifyLogoRaw from "../icons/shopify-icon.jpg";
import trademeLogoRaw from "../icons/trademe.png";
import walmartLogoRaw from "../icons/walmart.jpg";

//TODO: Mark - Make these logos more dynamic

export const EbayLogo = React.forwardRef((props, ref) => {
  const { active, noLink, ...otherProps } = props;
  let Logo = (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={ebayLogoRaw}
    />
  );
  if (!noLink) {
    Logo = (
      <a href="http://www.ebay.com" rel="noopener noreferrer" target="_blank">
        {Logo}
      </a>
    );
  }
  return Logo;
});
EbayLogo.defaultProps = {
  active: true,
};

export const AmazonLogo = React.forwardRef((props, ref) => {
  const { active, ...otherProps } = props;

  return (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={active ? amazonLogoRaw : amazonInactiveLogo}
    />
  );
});
AmazonLogo.defaultProps = {
  active: true,
};

export const CatchLogo = React.forwardRef((props, ref) => {
  const { active, ...otherProps } = props;
  return (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={catchLogoRaw}
    />
  );
});
CatchLogo.defaultProps = {
  active: true,
};

export const IconicLogo = React.forwardRef((props, ref) => {
  const { active, ...otherProps } = props;
  return (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={iconicLogoRaw}
    />
  );
});
IconicLogo.defaultProps = {
  active: true,
};

export const MissingLogo = React.forwardRef((props, ref) => (
  <img
    style={{ height: props.height }}
    {...props}
    ref={ref}
    src={missingLogoRaw}
  />
));

export const ShopifyLogo = React.forwardRef((props, ref) => {
  const { active, ...otherProps } = props;
  return (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={shopifyLogoRaw}
    />
  );
});
ShopifyLogo.defaultProps = {
  active: true,
};

export const WalmartLogo = React.forwardRef((props, ref) => {
  const { active, ...otherProps } = props;
  return (
    <img
      style={{ height: props.height }}
      {...otherProps}
      ref={ref}
      src={walmartLogoRaw}
    />
  );
});
WalmartLogo.defaultProps = {
  active: true,
};

export const KoganLogo = React.forwardRef((props, ref) => (
  <img
    style={{ height: props.height }}
    {...props}
    ref={ref}
    src={koganLogoRaw}
  />
));
KoganLogo.propTypes = {
  height: PropTypes.number,
};

export const TrademeLogo = React.forwardRef((props, ref) => (
  <img
    style={{ height: props.height }}
    {...props}
    ref={ref}
    src={trademeLogoRaw}
  />
));
TrademeLogo.propTypes = {
  height: PropTypes.number,
};

export const MarketplaceLogo = React.forwardRef((props, ref) => {
  const { market, noLink, ...restOfProps } = props;

  switch (market) {
    case "ebay":
      return <EbayLogo ref={ref} noLink={noLink} {...restOfProps} />;
    case "amazon":
      return <AmazonLogo ref={ref} {...restOfProps} />;
    case "amazon_vendor":
      return <AmazonLogo ref={ref} {...restOfProps} />;
    case "catch":
      return <CatchLogo ref={ref} {...restOfProps} />;
    case "iconic":
      return <IconicLogo ref={ref} {...restOfProps} />;
    case "shopify":
      return <ShopifyLogo ref={ref} {...restOfProps} />;
    case "walmart":
      return <WalmartLogo ref={ref} {...restOfProps} />;
    case "kogan":
      return <KoganLogo ref={ref} {...restOfProps} />;
    case "trademe":
      return <TrademeLogo ref={ref} {...restOfProps} />;
    default:
      console.error(`Cannot find logo for ${market}!`);
      return <MissingLogo ref={ref} {...restOfProps} />;
  }
});

MarketplaceLogo.propTypes = {
  market: PropTypes.string.isRequired,
};
