import {
  AdvertisingType,
  SuccessDirection,
} from "../advertising/performanceSummary";
import CustomGroupCell, {
  CustomGroupCellProps,
} from "~/components/table/cells/customGroupCell";
import CustomGroupsChart, {
  ChartOption,
} from "~/modules/widgets/customGroups/customGroupsChart";
import {
  CustomGroupsMetrics,
  useCustomGroupMetricsQuery,
  useCustomGroupsQuery,
  useUngroupedProductCountQuery,
} from "~/store/customGroups/reducer.redux";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import { formatCurrency, intFormatterRounded } from "~/utils/currencyUtils";

import { AcosRoasTacosTroasCell } from "../overview/marketingCampaignExpanded";
import Alert from "~/components/alert/alertCard";
import { CustomGroup } from "@typedef/customGroups";
import CustomGroupsTable from "~/modules/widgets/customGroups/customGroupsTable";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { Grid } from "@material-ui/core";
import { OutOfStockCell } from "~/components/table/cells/outOfStockCell";
import { PaginationArgs } from "~/typedef/pagination";
import { Range } from "@typedef/store";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { isEmpty } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

export const CUSTOM_GROUP_TABLE_PAGE_SIZE = 10;

interface CustomGroupsProps {
  userInfo: User;
  selectedGroups: CustomGroup[];
  actions?: ReactChild;
  chartType: ChartOption;
  mid: string;
  countryCode: string;
  marketplace: string;
  shopName: string;
  report?: boolean;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  conditionalFormatting?: boolean;
  timezone: string;
  includeTax: boolean;
  pageSize?: number;
}

const CustomGroups = memo<CustomGroupsProps>(function CustomGroups({
  userInfo,
  actions,
  selectedGroups,
  chartType,
  mid,
  countryCode,
  marketplace,
  shopName,
  report,
  currentPeriod,
  currentRange,
  currentCurrency,
  conditionalFormatting,
  timezone,
  includeTax,
  pageSize = CUSTOM_GROUP_TABLE_PAGE_SIZE,
}) {
  const { t } = useTranslation();

  /** Selectors */
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const isVendorStore = useVendorStore(mid);

  const [searchText, setSearchText] = useState("");
  const [markedForDelete, setMarkedForDelete] = useState<{
    groupId: number;
    groupName: string;
  } | null>(null);
  const [markedForEdit, setMarkedForEdit] = useState<{
    groupId: number;
    groupName: string;
  } | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const [paginationParams, setPaginationParams] = useState<
    Omit<PaginationArgs, "sortKey" | "sortOrder">
  >({
    pageSize,
    pageIndex: 0,
  });

  const { customGroups } = useCustomGroupsQuery(
    {
      mid,
      userId: userInfo?._id,
    },
    {
      skip: !userInfo?._id,
      selectFromResult: ({ data }) => ({
        customGroups: data?.groups || [],
      }),
    }
  );

  const {
    customGroupsMetrics,
    customGroupsMetricsRowCount,
    customGroupsMetricsFetching,
    paginatedGroups,
  } = useCustomGroupMetricsQuery(
    {
      mid,
      currentPeriod,
      currentRange,
      includeTax,
      selectedGroups,
      ...paginationParams,
      searchText,
    },
    {
      skip: isEmpty(selectedGroups),
      selectFromResult: ({ data, isFetching }) => {
        const customGroupsMetrics = data?.data || [];
        const customGroupsMetricsRowCount = data?.count || 0;
        const customGroupsMetricsFetching = isFetching;
        const paginatedGroups =
          selectedGroups?.filter((selectedGroup) =>
            customGroupsMetrics?.find(
              (customGroupLine) =>
                customGroupLine.groupId === selectedGroup.groupId
            )
          ) || [];
        return {
          customGroupsMetrics,
          customGroupsMetricsRowCount,
          customGroupsMetricsFetching,
          paginatedGroups,
        };
      },
    }
  );

  const { ungroupedProductCount } = useUngroupedProductCountQuery(
    {
      mid,
      userId: userInfo?._id,
    },
    {
      skip: !userInfo?._id,
      selectFromResult: ({ data }) => ({
        ungroupedProductCount: data?.count,
      }),
    }
  );

  /** Actions */
  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setPaginationParams({
        pageIndex,
        pageSize,
      });
    },
    []
  );

  const onMarkDeleted = ({
    groupId,
    groupName,
  }: {
    groupId: number;
    groupName: string;
  }) => {
    setMarkedForDelete({ groupId, groupName });
  };

  const onMarkEdit = ({
    groupId,
    groupName,
  }: {
    groupId: number;
    groupName: string;
  }) => {
    setMarkedForEdit({ groupId, groupName });
    setOpenModal(true);
  };

  const generateColumns = () => {
    return [
      {
        Header: t("customGroups.productGroupColumn"),
        accessor: (row: {
          groupId: number;
          groupName: number;
          productCount: number;
          filteredStores?: string[];
        }) => ({
          groupId: row.groupId,
          groupName: row.groupName,
          groupSize: row.productCount,
          groupLink: marketplaceLink(marketplace, mid, "salesbyproduct", {
            groupId: row.groupId,
          }),
          groupType: t("customGroups.products"),
          filteredStoresCount: row.filteredStores?.length,
        }),
        Cell: (props: CustomGroupCellProps) => (
          <CustomGroupCell
            {...props}
            report={report}
            onDelete={onMarkDeleted}
            onEdit={onMarkEdit}
          />
        ),
        isVisible: true,
        sticky: "left",
        customWidth: 200,
      },
      {
        Header: t(
          `customGroups.${isVendorStore ? "poValueColumn" : "salesColumn"}`
        ),
        accessor: (row: {
          totalSales: number;
          priorSales: number;
          currency: string;
        }) => {
          return {
            value:
              row.totalSales !== undefined
                ? formatCurrency(
                    row.totalSales,
                    currencyRates,
                    row.currency,
                    currentCurrency
                  )
                : "-",
            growth: row.priorSales
              ? getPercentageDifference(row.totalSales, row.priorSales)
              : "N/A",
            conditionalFormatting,
          };
        },
        id: "totalSales",
        align: "right",
        Cell: ValueAndGrowthCell,
        isVisible: true,
      },
      {
        Header: isVendorStore
          ? t("purchaseOrder.unitsColumn")
          : t("customGroups.unitsSoldColumn"),
        accessor: (row: { unitsSold: number; priorUnits: number }) => {
          return {
            value: row.unitsSold !== undefined ? row.unitsSold : "-",
            growth: row.priorUnits
              ? getPercentageDifference(row.unitsSold, row.priorUnits)
              : "N/A",
            conditionalFormatting,
          };
        },
        id: "unitsSold",
        Cell: ValueAndGrowthCell,
        align: "right",
        isVisible: true,
      },
      {
        Header: t("customGroups.avgPriceColumn"),
        accessor: (row: {
          avPrice: number;
          priorPrice: number;
          currency: string;
        }) => {
          return {
            value:
              row.avPrice !== undefined
                ? formatCurrency(
                    row.avPrice,
                    currencyRates,
                    row.currency,
                    currentCurrency
                  )
                : "-",
            growth: row.priorPrice
              ? getPercentageDifference(row.avPrice, row.priorPrice)
              : "N/A",
            conditionalFormatting,
          };
        },
        id: "avPrice",
        Cell: ValueAndGrowthCell,
        align: "right",
        isVisible: true,
      },
      ...(marketplace.includes("amazon") && !report
        ? [
            {
              Header: t("customGroups.adSpendColumn"),
              id: "adSpend",
              accessor: (row: {
                adSpend: number;
                countryCode: string;
                currency: string;
              }) => {
                return row.adSpend
                  ? formatCurrency(
                      row.adSpend,
                      currencyRates,
                      row.currency,
                      currentCurrency
                    )
                  : "-";
              },
              align: "right",
              Cell: ValueCell,
              isVisible: true,
            },
            {
              Header: t("customGroups.impressionsColumn"),
              id: "impressions",
              accessor: (row: { impressions: number }) =>
                row.impressions
                  ? intFormatterRounded.format(row.impressions)
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible: true,
            },

            {
              Header: t("customGroups.clickThroughRateColumn"),
              id: "clickThroughRate",
              accessor: (row: { clickThroughRate: number }) =>
                row.clickThroughRate
                  ? `${row.clickThroughRate.toFixed(2)}%`
                  : "-",
              align: "right",
              Cell: ValueCell,
              isVisible: true,
            },
            {
              Header: t("customGroups.conversionRateColumn"),
              id: "conversionRate",
              accessor: (row: { conversionRate: number }) =>
                row.conversionRate ? `${row.conversionRate.toFixed(2)}%` : "-",
              align: "right",
              Cell: ValueCell,
              isVisible: true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.clicksColumn"),
              id: "clicks",
              accessor: (row: CustomGroupsMetrics) =>
                row.clicks ? intFormatterRounded.format(row.clicks) : "-",
              align: "right",
              Cell: ValueCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.salesColumn"),
              id: "adSales",
              accessor: (row: CustomGroupsMetrics) =>
                row.adSales !== undefined
                  ? formatCurrency(
                      row.adSales,
                      currencyRates,
                      row.currency,
                      currentCurrency
                    )
                  : "-",
              align: "right",
              Cell: ValueCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.acosColumn"),
              id: "acos",
              accessor: (row: CustomGroupsMetrics) => {
                return {
                  value: row.acos >= 0.1 ? `${row.acos.toFixed(1)}%` : "-",
                  conditionalFormatting,
                  type: AdvertisingType.ACOS,
                  successDirection: SuccessDirection.DESCENDING,
                };
              },
              align: "right",
              Cell: AcosRoasTacosTroasCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.roasColumn"),
              id: "roas",
              accessor: (row: CustomGroupsMetrics) => {
                return {
                  value:
                    row.acos >= 0.1 ? `${(100 / row.acos).toFixed(1)}` : "-",
                  conditionalFormatting,
                  type: AdvertisingType.ROAS,
                  successDirection: SuccessDirection.ASCENDING,
                };
              },
              align: "right",
              Cell: AcosRoasTacosTroasCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
              id: "tacos",
              disableSortBy: true,
              accessor: (row: CustomGroupsMetrics) => {
                return {
                  value: row.tacos >= 0.1 ? `${row.tacos.toFixed(1)}%` : "-",
                  conditionalFormatting,
                  type: AdvertisingType.TACOS,
                  successDirection: SuccessDirection.DESCENDING,
                };
              },
              align: "right",
              Cell: AcosRoasTacosTroasCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.troasColumn"),
              id: "troas",
              disableSortBy: true,
              accessor: (row: CustomGroupsMetrics) => {
                return {
                  value:
                    row.tacos >= 0.1 ? `${(100 / row.tacos).toFixed(1)}` : "-",
                  conditionalFormatting,
                  type: AdvertisingType.TROAS,
                  successDirection: SuccessDirection.ASCENDING,
                };
              },
              align: "right",
              Cell: AcosRoasTacosTroasCell,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.adUnitsColumn"),
              id: "adUnits",
              accessor: (row: CustomGroupsMetrics) =>
                row.adUnits ? intFormatterRounded.format(row.adUnits) : "-",
              align: "right",
              Cell: ValueCell,
            },
          ]
        : []),
      ...(marketplace !== "amazon_vendor"
        ? [
            {
              id: "outOfStockPercentage",
              Header: t("customGroups.outOfStockPercentageColumn"),
              accessor: (row: any) => {
                return {
                  outOfStockPercentage: row.outOfStockPercentage,
                  conditionalFormatting,
                  align: "center",
                };
              },
              Cell: OutOfStockCell,
              align: "center",
              isVisible: true,
            },
          ]
        : []),
    ];
  };

  const columns = useMemo(
    () => generateColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mid, currentRange, currentPeriod, currentCurrency]
  );

  return (
    <Grid container spacing={2}>
      {!report && (
        <Grid item xs={12}>
          <Alert isOpen={true} type="info">
            {t("customGroups.updateDelayMessage")}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomGroupsChart
          mid={mid}
          currentPeriod={currentPeriod}
          currentRange={currentRange}
          currentCurrency={currentCurrency}
          customGroups={customGroups}
          selectedGroups={
            selectedGroups.length > paginatedGroups.length
              ? paginatedGroups
              : selectedGroups
          }
          actions={actions}
          chartType={chartType}
          conditionalFormatting={conditionalFormatting}
          timezone={timezone}
          includeTax={includeTax}
          report={report}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomGroupsTable
          marketplace={marketplace}
          mid={mid}
          countryCode={countryCode}
          currentPeriod={currentPeriod}
          currentRange={currentRange}
          currentCurrency={currentCurrency}
          selectedGroups={selectedGroups}
          customGroupsMetrics={customGroupsMetrics}
          customGroupsMetricsRowCount={customGroupsMetricsRowCount}
          customGroupsMetricsFetching={customGroupsMetricsFetching}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchData={fetchData}
          report={report}
          shopName={shopName}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setMarkedForDelete={setMarkedForDelete}
          setMarkedForEdit={setMarkedForEdit}
          markedForEdit={markedForEdit}
          markedForDelete={markedForDelete}
          tableColumns={columns}
          ungroupedProductCount={ungroupedProductCount}
          pageSize={pageSize}
        />
      </Grid>
    </Grid>
  );
});

export default CustomGroups;
