import {
  hasOnlyVendorStores,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { useTypedSelector } from "./useTypedSelector";

export const useVendorStore = (mid?: string) => {
  const currentStore = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentFilter = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores")
  );
  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores
  );

  if (mid) {
    return (
      stripFilteredSuffix(String(currentStore?.marketplace)) === "amazon_vendor"
    );
  }

  return (
    Boolean(
      currentFilter?.marketplaces?.length === 1 &&
        stripFilteredSuffix(String(currentFilter?.marketplaces[0])) ===
          "amazon_vendor"
    ) ||
    hasOnlyVendorStores(filteredStores) ||
    hasOnlyVendorStores(allStores)
  );
};
