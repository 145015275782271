import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";
import {
  computeTotalValue,
  getCategory,
  getStatusFromProfit,
  percentageFormatter,
} from "./categoryUtils";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";

import Bold from "~/components/typography/bold";
import CategoryValue from "./categoryValue";
import ColumnHeadings from "./columnHeadings";
import { InlineIconButton } from "~/icons/inlineIconButton";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BoldCategoryHeading = styled(Bold)`
  text-transform: uppercase;
`;

const TotalsGrid = styled(Grid)`
  width: 100%;
  border-top: 1px ${({ theme }) => theme.palette.secondary.main} solid;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
`;

const Totals = memo(
  ({
    countryCode,
    categories,
    data,
    totalRevenue,
    totalExpenses,
    currentCurrency,
  }) => {
    const { t } = useTranslation();

    const currencyRates = useSelector((state) => state.globalVar.currencyRates);
    const homeCurrency = getCurrencyByCountryCode[countryCode] || "AUD";

    const advertisingCategory = categories.reduce(
      getCategory("advertisingLabel"),
      []
    );
    const sellingFeesCategory = categories.reduce(
      getCategory("sellingFeesLabel"),
      []
    );
    const fulfilmentAndShippingCategory = categories.reduce(
      getCategory("fulfilmentAndShippingLabel"),
      []
    );

    const productSalesCategory = categories.reduce(
      getCategory("productSalesLabel"),
      []
    );

    const profit = totalRevenue - totalExpenses;
    const grossMargin = (100 * profit) / totalRevenue;
    const cogs = get(
      data.events.find((e) => e.label === "COGS"),
      "value",
      0
    );
    const cogsRefunded = _.get(
      data.events.find((e) => e.label === "COGS_REFUNDED"),
      "value",
      0
    );
    const estimatedPayout = profit + cogs - cogsRefunded;
    const advertisingFee = computeTotalValue(advertisingCategory, data.events);
    const totalFees =
      advertisingFee +
      computeTotalValue(sellingFeesCategory, data.events) +
      computeTotalValue(fulfilmentAndShippingCategory, data.events);
    const percentageRefund =
      (100 * data.meta.refundedUnitCount) / data.meta.unitCount;
    const productSales = computeTotalValue(productSalesCategory, data.events);

    const currencyFormatter = (value) =>
      isNaN(value) || value === 0.0
        ? "-"
        : isEmpty(currencyRates)
        ? `$${intFormatter.format(value)}` // only used in storybook
        : formatCurrency(value, currencyRates, homeCurrency, currentCurrency);

    return (
      <>
        <TotalsGrid container>
          <Grid item xs={5}>
            <Box p={1} display="flex" alignItems="center">
              <BoldCategoryHeading variant="body1" color="textSecondary">
                {t(`profitability.profitLabel`)}
              </BoldCategoryHeading>
              <Tooltip title={t(`profitability.profitTooltip`)}>
                <InlineIconButton />
              </Tooltip>
            </Box>
          </Grid>
          <CategoryValue
            value={currencyFormatter(profit)}
            colSpan={2}
            isTotalRow={true}
          />
          <CategoryValue
            value={
              totalRevenue > 0
                ? `${percentageFormatter.format(
                    (100 * profit) / totalRevenue
                  )}%`
                : "-"
            }
            colSpan={2}
            isTotalRow={true}
            status={getStatusFromProfit(grossMargin)}
          />
          <CategoryValue
            value={currencyFormatter(profit / data.meta.unitCount)}
            colSpan={3}
            isTotalRow={true}
          />
          <Grid item xs={5}>
            <Box p={1} display="flex" alignItems="center">
              <Typography variant="body1" color="textSecondary">
                {t(`profitability.grossMarginLabel`)}
              </Typography>
              <Tooltip title={t(`profitability.grossMarginTooltip`)}>
                <InlineIconButton />
              </Tooltip>
            </Box>
          </Grid>
          <CategoryValue
            value={
              totalRevenue > 0
                ? `${percentageFormatter.format(grossMargin)}%`
                : "-"
            }
            status={getStatusFromProfit(grossMargin)}
            colSpan={2}
          />
          <Grid item xs={2}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={5}>
            <Box p={1} display="flex" alignItems="center">
              <Typography variant="body1" color="textSecondary">
                {t(`profitability.estimatedPayoutLabel`)}
              </Typography>
              <Tooltip title={t(`profitability.estimatedPayoutTooltip`)}>
                <InlineIconButton />
              </Tooltip>
            </Box>
          </Grid>
          <CategoryValue
            value={currencyFormatter(estimatedPayout, true)}
            colSpan={2}
          />
          <Grid item xs={2}></Grid>
          <CategoryValue
            value={currencyFormatter(estimatedPayout / data.meta.unitCount)}
            colSpan={3}
          />
        </TotalsGrid>
        <ColumnHeadings category={{ label: "metricsLabel" }} />
        <Grid item xs={5}>
          <Box p={1} pl={2} display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {t(`profitability.tacosLabel`)}
            </Typography>
            <Tooltip title={t(`profitability.tacosTooltip`)}>
              <InlineIconButton />
            </Tooltip>
          </Box>
        </Grid>
        <CategoryValue
          value={
            productSales > 0 && advertisingFee > 0
              ? `${percentageFormatter.format(
                  (100 * advertisingFee) / productSales
                )}%`
              : "-"
          }
          colSpan={2}
        />
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          <Box p={1} pl={2} display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {t(`profitability.totalFeesLabel`)}
            </Typography>
            <Tooltip title={t(`profitability.totalFeesTooltip`)}>
              <InlineIconButton />
            </Tooltip>
          </Box>
        </Grid>
        <CategoryValue
          value={
            totalRevenue > 0 && totalFees > 0
              ? `${percentageFormatter.format(
                  (100 * totalFees) / totalRevenue
                )}%`
              : "-"
          }
          colSpan={2}
        />
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          <Box p={1} pl={2} display="flex" alignItems="center">
            <Typography variant="body1" color="textSecondary">
              {t(`profitability.refundsLabel`)}
            </Typography>
            <Tooltip title={t(`profitability.refundsTooltip`)}>
              <InlineIconButton />
            </Tooltip>
          </Box>
        </Grid>
        <CategoryValue
          value={
            isFinite(percentageRefund) && percentageRefund !== 0
              ? `${percentageFormatter.format(percentageRefund)}%`
              : "-"
          }
          colSpan={2}
        />
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>
      </>
    );
  }
);

Totals.propTypes = {
  countryCode: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.shape({
    meta: PropTypes.shape({
      orderCount: PropTypes.number,
      unitCount: PropTypes.number,
      refundedUnitCount: PropTypes.number,
    }),
    events: PropTypes.array,
  }),
  totalRevenue: PropTypes.number,
  totalExpenses: PropTypes.number,
  currentCurrency: PropTypes.string,
};

export default Totals;
