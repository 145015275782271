import { Grid } from "@material-ui/core";
import OutOfStockTable from "~/modules/widgets/outOfStock";
import PageBlock from "~/components/containers/sideNavPageBlock";
import React from "react";
import get from "lodash/get";
import { useMarketplace } from "~/utils/navigationUtils";
import { useSelector } from "react-redux";

const OutOfStock = () => {
  const marketplace = useMarketplace();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  return (
    <PageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <OutOfStockTable market={marketplace} store={store} />
        </Grid>
      </Grid>
    </PageBlock>
  );
};

export default OutOfStock;
