import { recommendations, syncedItems } from "./recommendations.redux";

import { CLEAR_STORE } from "./user.redux";
import { advertisingCustomGroups } from "./customGroups/advertising.redux";
import { amazonNewStore } from "./connections/connectAmazon.redux";
import { api } from "./apiSlice";
import { buyBox } from "./buyBox.redux";
import { clientReport } from "./mystore/clientReport.redux";
import { combineReducers } from "@reduxjs/toolkit";
import { connectCriteo } from "./connections/connectCriteo.redux";
import { connectionStatus } from "./connections/connectionStatusV2.redux";
import { connections } from "./connections/connections.redux";
import { customGroups } from "./customGroups/reducer.redux";
import customLayout from "./customLayout.redux";
import customOverrides from "./customOverrides.redux";
import dashboard from "./dashboard.redux";
import { genericBuybox } from "./mystore/genericBuybox.redux";
import { globalToast } from "./globalToast.redux";
import { globalVar } from "./globalVar.redux";
import { inventoryReplenishment } from "./mystore/inventoryReplenishment.redux";
import { issues } from "./mystore/issues.redux";
import { itemSpecifics } from "./itemSpecifics.redux";
import { listingQuality } from "./listingQuality.redux"; // Legecy Ebay
import { listingQualityGeneric } from "./mystore/listingQuality.redux";
import { marketing } from "./mystore/marketing.redux";
import { mystore } from "./mystore/mystore.redux";
import { newBrandFilterStore } from "./connections/connectBrandFilter.redux";
import { notifications } from "./notifications.redux";
import { overview } from "./overview/overview.redux";
import { parentProducts } from "./parentMetrics.redux";
import { password } from "./password.redux";
import { persistentAppSettings } from "./persistentAppSettings.redux";
import { profitability } from "./mystore/profitability.redux";
import { reportDownload } from "./reportDownload.redux";
import { review } from "./mystore/review.redux";
import { subscriptions } from "./subscriptions.redux";
import { suppressedProducts } from "./mystore/suppressedProducts.redux";
import { user } from "./user.redux";
import { vendor } from "./mystore/vendor.redux";

const reducers = {
  globalVar,
  user,
  password,
  globalToast,
  overview,
  amazonNewStore,
  mystore,
  buyBox,
  listingQuality,
  listingQualityGeneric,
  marketing,
  recommendations,
  subscriptions,
  connections,
  connectionStatus,
  syncedItems,
  itemSpecifics,
  profitability,
  review,
  reportDownload,
  suppressedProducts,
  customGroups,
  advertisingCustomGroups,
  clientReport,
  vendor,
  parentProducts,
  issues,
  inventoryReplenishment,
  genericBuybox,
  notifications,
  persistentAppSettings,
  newBrandFilterStore,
  connectCriteo,
};
const appReducer = combineReducers({
  // Using this pattern here as we want to have the new reducer slices included here directly
  // this allows us to keep track of what has been migrated to RTK and what has not
  ...reducers,
  dashboard,
  customLayout,
  customOverrides,
  [api.reducerPath]: api.reducer,
});

// TODO: Remove this once we have migrated all slices to RTK
// each slice will have their all revertAll handler
const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_STORE) {
    if (action.payload === "login" || action.payload === "logout") {
      return {
        globalVar: {
          ...state.globalVar,
          demoBannerVisible: true,
          currentFilter: {
            marketplaces: [],
            countries: [],
            tags: [],
          },
          currentStore: {
            marketplace: "",
            mid: "",
            sellerId: "",
            countryCode: "",
            shopName: "",
            isDemoMode: false,
          },
        },
        customOverrides: {
          ...state.customOverrides,
        },
        subscriptions: {},
      };
    } else {
      return {};
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
