import React, { memo, useEffect } from "react";
import {
  fetchDispatchStatus,
  fetchStoreDispatchStatus,
} from "~/store/overview/dispatchStatus.redux";

import { DEFAULT_FILTER } from "~/store/persistentAppSettings.redux";
import DispatchNoData from "~/components/placeholders/dispatchNoData";
import DispatchStatusContents from "./dispatchStatusContents";
import { Panel } from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface DispatchStatusProps {
  market?: string;
  mid?: string;
}

const DispatchStatus = memo<DispatchStatusProps>(function DispatchStatus({
  market,
  mid,
}) {
  const isOverview = !mid;
  const userInfo = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const dispatchStatus = useTypedSelector((state) =>
    isOverview ? state.overview.dispatchStatus : state.mystore.dispatchStatus
  );
  const { t } = useTranslation();
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        isOverview
          ? fetchDispatchStatus(
              {
                user: {
                  _id: userInfo._id,
                  organisationId: userInfo.organisationId,
                },
                filter: currentFilter,
                filteredStores,
                timezone: selectedTimezone,
              },
              dispatchStatus?.params
            )
          : fetchStoreDispatchStatus(
              userInfo,
              selectedTimezone,
              mid,
              currentFilter
            )
      );
    };
    fetchData();
  }, [
    userInfo._id,
    mid,
    isOverview,
    currentFilter,
    filteredStores,
    dispatchStatus?.params,
  ]);

  return (
    <Panel
      id="widget-dispatch-status"
      title={t("dashboardWidget.dispatchStatus.mainTitle")}
      tooltip={t("dashboardWidget.dispatchStatus.mainTooltip")}
      content={
        get(dispatchStatus, "fetching") ? (
          <PanelLoading />
        ) : get(dispatchStatus, "ordersToSend") !== undefined ? (
          <DispatchStatusContents
            market={market}
            mid={mid}
            dispatchStatus={dispatchStatus}
          />
        ) : (
          <DispatchNoData />
        )
      }
    />
  );
});

export default DispatchStatus;
