import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import { connectMercadoLibre } from "~/store/connections/connectMercadoLibre.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useMercadoLibreAuthcodeAuth from "~/hooks/useMercadoLibreAuthcodeAuth";

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

const MercadoLibreAuthorise = (props) => {
  const { children, onSuccess, onError, shopName, countryCode } = props;
  const dispatch = useDispatch();
  const onCode = React.useCallback(
    (code) => {
      dispatch(connectMercadoLibre(code, shopName, countryCode))
        .then(() => {
          onSuccess();
        })
        .catch((e) => {
          if (onError) {
            onError(e);
          } else {
            throw e;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, shopName, countryCode]
  );
  const triggerSignin = useMercadoLibreAuthcodeAuth(onCode, countryCode);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <RaisedButton color="primary" onClick={handleSignin} {...props}>
      <StyledWrapper>
        <StyledChildren>{children}</StyledChildren>
      </StyledWrapper>
    </RaisedButton>
  );
};

MercadoLibreAuthorise.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.any,
  shopName: PropTypes.string,
  countryCode: PropTypes.string,
};

export default MercadoLibreAuthorise;
