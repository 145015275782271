import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { CheckCircleOutline } from "@material-ui/icons";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import { Link } from "react-router-dom";
import RaisedButton from "~/components/buttons/raisedButton";
import React from "react";
import SiteBackground from "~/img/background_sites.png";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useMarketplace } from "~/utils/navigationUtils";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PaperWrapper = styled(Paper)`
  padding-bottom: 1rem;
`;

const HeaderWrapper = styled.div`
  padding-left: 1rem;
`;

interface HasMobile {
  isMobile: boolean;
}

const ContentWrapper = styled.div<HasMobile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ isMobile }) => (isMobile ? "1rem" : "none")};
  height: 100%;
`;

const FooterWrapper = styled.div<HasMobile>`
  text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
  padding-left: 1rem;
`;

const IncludedIcon = styled(CheckCircleOutline)`
  color: ${({ theme }) => theme.palette.success.main};
`;

const SiteImage = styled.img`
  width: 40vw;
`;

interface BannerProps {
  condensed?: boolean;
}

const BrandFilterAdConnectBanner = ({ condensed }: BannerProps) => {
  const user = useTypedSelector((state) => state.user);
  const marketplace = useMarketplace();
  const theme = useTheme();
  const onMobile = condensed || useMediaQuery(theme.breakpoints.down("xs"));
  const urlSearchParams = useQueryParams();
  const mid = urlSearchParams.get("store");
  const { t } = useTranslation();

  // if this widget is already rendered, and the user switches to a non-Amazon
  // store (e.g., one without a countryCode), amws.marketplace() may throw an
  // error. We need to make the component return null before this happens.
  if (
    stripFilteredSuffix(marketplace) !== "amazon" &&
    stripFilteredSuffix(marketplace) !== "amazon_vendor"
  ) {
    return null;
  }

  return (
    <>
      <PaperWrapper {...{ xs: 12 }}>
        <Grid {...{ container: true, display: "flex", spacing: 0 }}>
          {!onMobile && (
            <Grid item xs={onMobile ? 12 : 6}>
              <SiteImage src={SiteBackground} />
            </Grid>
          )}

          <Grid item xs={onMobile ? 12 : 6}>
            <ContentWrapper isMobile={onMobile}>
              <>
                <HeaderWrapper>
                  <Typography variant="h3">
                    {t("advertisingConnectLabel.header")}
                  </Typography>
                </HeaderWrapper>
                <List>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <Box display="flex" alignContent="center">
                        <IncludedIcon />
                      </Box>
                    </ListItemIcon>
                    <Typography variant="h6">
                      {t("advertisingConnectLabel.bulletA")}
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <Box display="flex" alignContent="center">
                        <IncludedIcon />
                      </Box>
                    </ListItemIcon>
                    <Typography variant="h6">
                      {t("advertisingConnectLabel.bulletB")}
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <Box display="flex" alignContent="center">
                        <IncludedIcon />
                      </Box>
                    </ListItemIcon>
                    <Typography variant="h6">
                      {t("advertisingConnectLabel.bulletC")}
                    </Typography>
                  </ListItem>
                </List>
              </>
              <FooterWrapper isMobile={onMobile}>
                {user.isDemoMode ? (
                  <DemoTooltip
                    {...{
                      arrow: true,
                      placement: "bottom",
                      open: true,
                      title: t<string>("generic.notAvailableDemoMode"),
                    }}
                  >
                    <span>
                      <RaisedButton id="amazon-advertising-link">
                        {t("advertisingConnectLabel.connectButton")}
                      </RaisedButton>
                    </span>
                  </DemoTooltip>
                ) : (
                  <span>
                    <RaisedButton
                      color="primary"
                      component={Link}
                      to={`/connectStore/select`}
                      id="amazon-advertising-link"
                    >
                      {t("advertisingConnectLabel.connectButton")}
                    </RaisedButton>
                  </span>
                )}
              </FooterWrapper>
            </ContentWrapper>
          </Grid>
        </Grid>
      </PaperWrapper>
    </>
  );
};

export default BrandFilterAdConnectBanner;
