import { Grid, Typography, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import { InventoryStatus } from "~/typedef/store";
import PieChart from "../../../components/charts/pieChart/pieChart";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LegendTypography = styled(Typography)`
  margin-top: 5px;
`;

interface ChartDataEntry {
  name: string;
  color: string;
  value: number;
  percentage?: number;
  tooltipContent?: string;
  legendContent?: JSX.Element;
}

interface InventoryStatusChartProps {
  inventoryStatus?: InventoryStatus;
}

const InventoryStatusChart = memo<InventoryStatusChartProps>(
  function InventoryStatusChart({ inventoryStatus }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const data: ChartDataEntry[] = [
      {
        name: t("inventoryStatusChart.fromToDays", { from: 0, to: 90 }),
        value: inventoryStatus?.inventoryAge90 ?? 0,
        color: theme.palette.chart.green,
      },
      {
        name: t("inventoryStatusChart.fromToDays", { from: 90, to: 180 }),
        value: inventoryStatus?.inventoryAge180 ?? 0,
        color: theme.palette.chart.lightGreen,
      },
      {
        name: t("inventoryStatusChart.fromToDays", { from: 180, to: 270 }),
        value: inventoryStatus?.inventoryAge270 ?? 0,
        color: theme.palette.chart.orange,
      },
      {
        name: t("inventoryStatusChart.fromToDays", { from: 270, to: 365 }),
        value: inventoryStatus?.inventoryAge365 ?? 0,
        color: theme.palette.chart.red,
      },
      {
        name: t("inventoryStatusChart.plusDays", { from: 365 }),
        value: inventoryStatus?.inventoryAge365Plus ?? 0,
        color: theme.palette.chart.gray,
      },
      {
        name: t("inventoryStatusChart.inboundInventory"),
        value: inventoryStatus?.inboundInventory ?? 0,
        color: theme.palette.chart.purple,
      },
      {
        name: t("inventoryStatusChart.reservedInventory"),
        value: inventoryStatus?.reservedInventory ?? 0,
        color: theme.palette.chart.lightBlue,
      },
    ].filter((e) => e.value && e.value > 0);
    data.forEach((pieData) => {
      const formattedValue = numberWithCommas(pieData.value);
      pieData.tooltipContent = `${pieData.name} (${formattedValue} units)`;
      pieData.legendContent = (
        <LegendTypography variant="body2">
          {pieData.name} ({formattedValue} units)
        </LegendTypography>
      );
    });

    return (
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <PieChart {...{ data }} />
      </Grid>
    );
  }
);

export default InventoryStatusChart;
