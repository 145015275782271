import {
  AdvertisingType,
  SuccessDirection,
} from "../advertising/performanceSummary";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Filter, Range, Store } from "~/typedef/store";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";

import { AcosRoasTacosTroasCell } from "./marketingCampaignExpanded";
import { ConnectAdvertisingRow } from "@components/table/rows/connectAdvertisingRow";
import { LinkCell } from "~/components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { PaginationParams } from "~/typedef/pagination";
import { Panel } from "~/components/panel/panel";
import Table from "~/components/table/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import get from "lodash/get";
import { getPercentageDifference } from "~/utils/salesUtils";
import { useAdvertisingOnly } from "~/hooks/useAdvertisingOnly";
import { useMarketingCampaignSummaryQuery } from "~/store/overview/marketing.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CampaignSummaryProps {
  pageSize: number;
  filteredStores: Store[];
  currentCurrency: string;
  currentRange: Range;
  report?: boolean;
  currentFilter: Filter;
  conditionalFormatting?: boolean;
  timezone: string;
  includeTax: boolean;
}

const CampaignSummary = memo<CampaignSummaryProps>(function CampaignSummary({
  pageSize,
  filteredStores,
  currentCurrency,
  currentRange,
  report,
  currentFilter,
  conditionalFormatting,
  timezone,
  includeTax,
}) {
  const { t } = useTranslation();
  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );
  const allowedLinks = useTypedSelector((state) =>
    get(state, "customLayout.layoutConfig.sideNav.pages")
  );
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isAdvertisingOnly = useAdvertisingOnly();

  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageSize,
    pageIndex: 0,
    sortKey: "attributed_sales",
    sortDesc: "true",
  });

  const { campaignsData, rowCount, loading } = useMarketingCampaignSummaryQuery(
    {
      searchText: "",
      reportDateFrom: currentRange.fromDate,
      reportDateTo: currentRange.toDate,
      priorFromDate: currentRange.priorFromDate,
      priorToDate: currentRange.priorToDate,
      filter: currentFilter,
      timezone,
      includeTax,
      paginationParams,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        campaignsData: data?.data || [],
        rowCount: data?.count || 0,
        loading: isFetching,
      }),
    }
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    setPaginationParams({
      sortKey: sortBy[0]?.id || "attributed_sales",
      sortDesc: sortBy[0]?.desc ?? "true",
      pageIndex,
      pageSize,
    });
  }, []);

  const data = useMemo(() => {
    return campaignsData.map((row) => {
      const dataAvailable = "current" in row;
      let valueToUse, prior, current, isDisconnected;
      if (dataAvailable) {
        valueToUse = row.current;
        prior = row.prior;
        current = row.current;
        isDisconnected = false;
      } else {
        valueToUse = row;
        isDisconnected = valueToUse.isDisconnected;
      }
      const market = valueToUse.marketplace;
      const marketplaceSubtype = valueToUse.marketplaceSubtype;
      const shopName = getShopName(filteredStores, market, valueToUse.mid);
      const homeCurrency = getCurrencyByCountryCode[valueToUse.countryCode];
      return {
        report,
        renderCustomRow: isDisconnected,
        marketplace: valueToUse.marketplace,
        countryCode: valueToUse.countryCode,
        marketplaceSubtype,
        shop: {
          value: shopName,
          link: marketplaceLink(
            valueToUse.marketplace,
            valueToUse.mid,
            "marketingOverview",
            undefined,
            allowedLinks
          ),
          connectLink: "/connectStore/select",
        },
        sales: {
          value:
            current && current.sales
              ? formatCurrencyRounded(
                  current.sales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          growth:
            current && prior
              ? getPercentageDifference(current.sales, prior.sales)
              : "-",
          conditionalFormatting: conditionalFormatting,
        },
        cost: {
          value:
            current && current.cost
              ? formatCurrencyRounded(
                  current.cost,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          growth:
            current && prior
              ? getPercentageDifference(current.cost, prior.cost)
              : "-",
          conditionalFormatting: conditionalFormatting,
        },
        acos: {
          raw: current && current.acos ? current.acos : "-",
          value:
            current && current.acos
              ? current.acos
                ? `${current.acos.toFixed(0)}%`
                : "-"
              : "-",
          growth:
            current && current.acos && prior && prior.acos
              ? (current.acos - prior.acos).toFixed(0)
              : "-",
          conditionalFormatting: conditionalFormatting,
          type: AdvertisingType.ACOS,
          successDirection: SuccessDirection.DESCENDING,
        },
        tacos: {
          raw: current && current.tacos ? current.tacos : "-",
          value:
            current && current.tacos
              ? current.tacos
                ? `${current.tacos.toFixed(0)}%`
                : "-"
              : "-",
          growth:
            current && current.tacos && prior && prior.tacos
              ? (current.tacos - prior.tacos).toFixed(0)
              : "-",
          conditionalFormatting: conditionalFormatting,
          type: AdvertisingType.TACOS,
          successDirection: SuccessDirection.DESCENDING,
        },
      };
    });
  }, [campaignsData, filteredStores]);

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: t("dashboardWidget.marketplaceColumn"),
        accessor: (row: any) => ({
          market: row.marketplace,
          marketplaceSubtype: row.marketplaceSubtype,
          countryCode: row.countryCode,
        }),
        Cell: MarketplaceAndCountryCell,
        colSpan: 0.5,
      },
      {
        id: "shopName",
        Header: t("dashboardWidget.storeColumn"),
        accessor: "shop",
        Cell: LinkCell,
      },
      {
        Header: t("advertisingDashboardWidget.adTable.salesColumn"),
        id: "sales",
        accessor: "sales",
        align: "center",
        Cell: ValueAndGrowthCell,
        isVisible: true,
      },
      ...(!onMobile
        ? [
            {
              Header: t("advertisingDashboardWidget.adTable.spendColumn"),
              id: "cost",
              accessor: "cost",
              align: "center",
              Cell: ValueAndGrowthCell,
              isVisible: true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.acosColumn"),
              id: "acos",
              accessor: "acos",
              align: "center",
              Cell: AcosRoasTacosTroasCell,
              isVisible: true,
              sortDescFirst: true,
            },
            ...(!isAdvertisingOnly
              ? [
                  {
                    Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
                    id: "tacos",
                    accessor: "tacos",
                    align: "center",
                    Cell: AcosRoasTacosTroasCell,
                    isVisible: true,
                    sortDescFirst: true,
                  },
                ]
              : []),
          ]
        : []),
    ],
    [currencyRates, currentCurrency, onMobile, t, isAdvertisingOnly]
  );

  return (
    <Panel
      id="widget-marketing-campaign-summary"
      title={t("advertisingDashboardWidget.storePerformance")}
      tooltip={t("advertisingDashboardWidget.storePerformanceTooltip")}
      footerLink={
        report
          ? undefined
          : {
              url: "/overview/marketingOverview",
              label: t("generic.viewAllLink"),
            }
      }
      content={
        <Box>
          <Table
            CustomRow={ConnectAdvertisingRow}
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pagination={!report}
            pageCount={data ? Math.ceil(rowCount / pageSize) : 1}
            pageSize={pageSize}
            gridLayoutColumns={onMobile ? 12 : 8}
          />
        </Box>
      }
    />
  );
});

export default CampaignSummary;
