import { CircularProgress, Grid } from "@material-ui/core";

import React from "react";

const LoadingIndicator = (props) => (
  <Grid container alignItems="center" justifyContent="center">
    <CircularProgress {...props} />
  </Grid>
);

export default LoadingIndicator;
