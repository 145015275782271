import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { PAGE_TO_RENDER, PRIMARY_BUTTONS } from "../connectPromptOptions";
import React, { useEffect, useState } from "react";

import AlertCard from "~/components/alert/alertCard";
import AutoCompleteMarketplace from "~/components/autocomplete/autoCompleteMarketplace";
import DataShareInfo from "~/components/info/dataShareInfo";
import { MARKETPLACES_WITH_SUBTYPES } from "~/configs";
import { MarketplaceIcon } from "~/img/marketplaces/icons/marketplaceIcon";
import Medium from "~/components/typography/medium";
import { User } from "~/typedef/user";
import cookies from "browser-cookies";
import { fetchCustomLayoutConfig } from "~/store/customLayout.redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { otherMarketplaces } from "../marketplaces";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface MarketplaceConnectButtonBasics {
  onClick: () => void;
  label: string;
}
interface MarketplaceConnectButtonPrimary
  extends MarketplaceConnectButtonBasics {
  imgSrc: any;
  marketplace?: never;
}
interface MarketplaceConnectButtonSeconadary
  extends MarketplaceConnectButtonBasics {
  imgSrc?: never;
  marketplace: string;
}
type MarketplaceConnectButtonProps =
  | MarketplaceConnectButtonPrimary
  | MarketplaceConnectButtonSeconadary;

const MarketplaceLogoIcon = styled.img`
  padding: 4px;
  max-height: 32px;
`;

const ConnectButton = styled(Button)`
  margin-bottom: 16px;
`;

const LinearProgressBar = styled(LinearProgress)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const WrapperGrid = styled(Grid)`
  max-width: 300px;
`;

// Order of buttons will be according to order here or in custom layout
const DEFAULT_PRIMARY_OPTIONS: string[] = [
  "amazon",
  "amazon_vendor",
  "ebay",
  "shopify",
  "walmart",
];

const MarketplaceConnectButton = (props: MarketplaceConnectButtonProps) => {
  const { onClick, imgSrc, label, marketplace } = props;
  return (
    <ConnectButton
      fullWidth
      variant="outlined"
      color="secondary"
      onClick={onClick}
      aria-label={`connect to ${label}`}
    >
      <Grid container justifyContent="center" spacing={1} alignItems="center">
        <Grid container item xs={5} justifyContent="flex-end">
          {imgSrc && <MarketplaceLogoIcon src={imgSrc} />}
          {marketplace && (
            <MarketplaceIcon market={marketplace} largeSize={true} />
          )}
        </Grid>
        <Grid container item xs={7} justifyContent="flex-start">
          <Medium align="left">{label}</Medium>
        </Grid>
      </Grid>
    </ConnectButton>
  );
};

type PageToRenderProps = {
  immediateAuth: boolean;
  onSuccess: (nextStepParams: any) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

const getPageToRender = ({
  selectedMarketplace,
}: {
  selectedMarketplace: string | null;
}): ((props: PageToRenderProps) => JSX.Element) | null => {
  return get(PAGE_TO_RENDER, `[${selectedMarketplace}].component`, null);
};

export interface ConnectionProps {
  onSuccess: (params?: any) => void;
  onError?: (params: any) => void;
  setIsSubmitting?: (submitting: boolean) => void;
}

const SHOPIFY_DISABLED_AUTH = ["omnivore", "sweetanalytics"];

const StoreConnectionPrompt = ({ onSuccess }: ConnectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authSource = cookies.get("authSource");
  const isShopifyDisabled = authSource
    ? SHOPIFY_DISABLED_AUTH.includes(authSource)
    : false;
  const user = useTypedSelector((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const isLoading =
    useTypedSelector((state) => state.customLayout?.fetching) || false;
  const customLayoutEnabled =
    useTypedSelector((state) => state.customLayout?.layoutConfig?.enabled) ??
    false;
  const customPrimaryOptions =
    useTypedSelector(
      (state) =>
        state.customLayout?.layoutConfig?.storeConnections
          ?.displayedMarketplaces
    ) || [];
  const shouldHideSearch =
    useTypedSelector(
      (state) => state.customLayout?.layoutConfig?.storeConnections?.hideSearch
    ) ?? false;

  useEffect(() => {
    dispatch(fetchCustomLayoutConfig());
  }, []);

  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const selectedMarketplace: string | null = urlSearchParams.get("marketplace");

  const marketplaceConnectorOptions = otherMarketplaces.filter((option) => {
    if (isShopifyDisabled && option.name === "Shopify") {
      // Disable the shopify connector
      return false;
    }

    if (!option.featureFlag) {
      return true;
    } else {
      if (user[option.featureFlag as keyof User]) {
        return true;
      } else {
        return false;
      }
    }
  });

  const setOptionKey = (key: string) => {
    urlSearchParams.delete("state");
    urlSearchParams.set("marketplace", key);
    history.push({
      search: "?" + urlSearchParams.toString(),
    });
  };

  const setMultiSubTypeOptionKey = (
    parentMarketplace: string,
    key: string,
    name: string,
    country: string
  ) => {
    urlSearchParams.delete("state");
    urlSearchParams.set("marketplace", parentMarketplace);
    urlSearchParams.set("submarketplace", key);
    urlSearchParams.set("name", name);
    urlSearchParams.set("country", country);
    history.push({
      search: "?" + urlSearchParams.toString(),
    });
  };

  const primaryOptions: string[] =
    customLayoutEnabled && !isEmpty(customPrimaryOptions)
      ? customPrimaryOptions
      : DEFAULT_PRIMARY_OPTIONS;
  // Disable the shopify connector
  const filteredPrimaryOptions = isShopifyDisabled
    ? primaryOptions.filter((option) => option !== "shopify")
    : primaryOptions;

  const PageToRender = getPageToRender({ selectedMarketplace });

  const onAuthSuccess = (_props: any) => {
    setIsSubmitting(false);
    setShowSuccess(true);
    onSuccess();
  };

  return (
    <WrapperGrid container alignItems="center">
      {isSubmitting && <LinearProgressBar />}
      {!PageToRender ? (
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          xs={12}
          spacing={2}
        >
          {showSuccess ? (
            <Grid item xs={12}>
              <AlertCard isOpen={true} type={"success"}>
                <Medium variant="body1">
                  {t("connectWizard.congratulationsBanner")}
                </Medium>
              </AlertCard>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="textSecondary"
                noWrap
                align="center"
              >
                {t("connect.selectChannelPrompt")}
              </Typography>
            </Grid>
          )}

          <Grid item>
            {user.role === "external" && (
              <Box width="100%" mb={4}>
                <DataShareInfo />
              </Box>
            )}
          </Grid>
          {isLoading ? (
            <LinearProgressBar />
          ) : (
            filteredPrimaryOptions.map((option: string) => {
              const primaryParams = PRIMARY_BUTTONS[option];
              if (primaryParams) {
                return (
                  <MarketplaceConnectButton
                    onClick={() => setOptionKey(option)}
                    imgSrc={primaryParams.imgSrc}
                    label={primaryParams.label}
                    key={option}
                  />
                );
              } else {
                const secondaryParams = marketplaceConnectorOptions.find(
                  (marketplace) => marketplace.marketplace === option
                );
                if (secondaryParams) {
                  return (
                    <MarketplaceConnectButton
                      onClick={() => setOptionKey(option)}
                      marketplace={secondaryParams.marketplace}
                      label={secondaryParams.name}
                      key={option}
                    />
                  );
                }
              }
            })
          )}
          {!customLayoutEnabled || !shouldHideSearch ? (
            <AutoCompleteMarketplace
              instruction={t("connectWizard.searchMarketplaces")}
              options={marketplaceConnectorOptions}
              onItemSelected={({
                parentMarketplace,
                marketplace,
                name,
                country,
              }) =>
                MARKETPLACES_WITH_SUBTYPES.includes(parentMarketplace)
                  ? setMultiSubTypeOptionKey(
                      parentMarketplace,
                      marketplace,
                      name,
                      country
                    )
                  : setOptionKey(marketplace)
              }
            />
          ) : null}
        </Grid>
      ) : (
        <PageToRender
          immediateAuth // flag to indicate OAuth/external flows that the auth flow should be initiated immediately
          onSuccess={onAuthSuccess} // callback for when auth succeeds
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </WrapperGrid>
  );
};

export default StoreConnectionPrompt;
