import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import NoReportImage from "~/img/noData/noReport.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Background = styled(Box)`
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 50%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.main} 100%)`};
`;

const Divider = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.border.main};
  height: 2px;
  width: 50%;
  margin-top: 1rem;
`;

const ImageWrapper = styled.img`
  width: 500px;
  display: flex;
`;

const NoReportPage = memo(function NoReportPage() {
  const { t } = useTranslation();

  return (
    <Background
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        p={4}
        maxWidth={500}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h1" color="textPrimary" align="center">
          {t("report.notAvailableTitle")}
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
          {t("report.notAvailableMessage")}
        </Typography>
        <Divider />
        <ImageWrapper src={NoReportImage} />
      </Box>
    </Background>
  );
});

export default NoReportPage;
