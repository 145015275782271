import React, { memo, useCallback, useState } from "react";

import { Column } from "~/components/adTable/columnSelect";
import { PaginationArgs } from "~/typedef/pagination";
import { Range } from "~/typedef/store";
import Table from "~/components/adTable/table";
import { usePurchaseOrdersQuery } from "~/store/mystore/vendor.redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface PurchaseOrdersTableProps {
  mid: string;
  marketplaceType: string;
  columns: Column[];
  countryCode: string;
  currentRange: Range;
  searchText: string;
}

const PurchaseOrdersTable = memo<PurchaseOrdersTableProps>(
  function PurchaseOrdersTable({
    mid,
    marketplaceType,
    columns,
    countryCode,
    currentRange,
    searchText,
  }) {
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: PAGE_SIZE,
      pageIndex: 0,
      sortKey: "page_views",
      sortOrder: "desc",
    });

    const { purchaseOrders, purchaseOrdersRowCount, purchaseOrdersFetching } =
      usePurchaseOrdersQuery(
        {
          mid,
          marketplaceType,
          countryCode,
          currentRange,
          includeTax,
          searchText,
          ...paginationParams,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            purchaseOrders: data?.rows ?? [],
            purchaseOrdersRowCount: data?.count ?? 0,
            purchaseOrdersFetching: isFetching,
          }),
        }
      );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "dateReceived",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    return (
      <Table
        {...{
          columns,
          data: purchaseOrders,
          fetchData,
          loading: purchaseOrdersFetching,
          sorting: true,
          pagination: true,
          pageCount: Math.ceil((purchaseOrdersRowCount || 0) / PAGE_SIZE),
          pageSize: PAGE_SIZE,
        }}
      />
    );
  }
);

export default PurchaseOrdersTable;
