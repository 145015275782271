import { Box, Grid } from "@material-ui/core";
/* eslint-disable no-undefined */
import React, { memo, useCallback, useEffect } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { User } from "~/typedef/user";
import { fetchInventoryHealthTotals } from "~/store/mystore/vendor.redux";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface InventoryHealthTotalsProps {
  userInfo: User;
  mid: string;
  currentRange: Range;
  currentCurrency: string;
  currentCompare: COMPARISON_PERIOD;
  conditionalFormatting?: boolean;
}

const InventoryHealthTotals = memo(
  ({
    userInfo,
    mid,
    currentRange,
    currentCurrency,
    currentCompare,
    conditionalFormatting,
  }: InventoryHealthTotalsProps) => {
    const { t } = useTranslation();

    const inventoryHealthTotals = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.data
    );
    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.data?.lastReportDate
    );
    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.data?.lastUpdatedAt
    );
    const homeCurrency = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.data?.currency
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.totals?.fetching || false
    );

    const dispatch = useDispatch();

    const dispatchFetchTotals = useCallback(() => {
      dispatch(
        fetchInventoryHealthTotals(
          {
            user: userInfo,
            currentRange,
            mid,
          },
          params
        )
      );
    }, [currentRange, mid, userInfo]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchTotals();
      };
      fetchData();
    }, [userInfo, currentRange, mid]);

    return (
      <Panel
        id="widget-ordered-totals"
        title={t(`myStoresWidget.inventoryHealthSummary`)}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.sourcingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.sourcingView")}`
        }
        tooltip={undefined}
        content={
          <Box p={2}>
            {loading || !inventoryHealthTotals ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <GenericTotalMoney
                    {...inventoryHealthTotals?.sellableCost}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`vendor.sellableInventory`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GenericTotal
                    number={inventoryHealthTotals?.sellableUnits.current}
                    comparison={{
                      prior: inventoryHealthTotals?.sellableUnits.prior,
                    }}
                    title={t(`vendor.sellableInventory`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GenericTotal
                    number={inventoryHealthTotals?.unhealthyUnits.current}
                    secondNumber={`(${(
                      (inventoryHealthTotals?.unhealthyUnits.current /
                        inventoryHealthTotals?.sellableUnits.current) *
                      100
                    ).toFixed(0)}%)`}
                    comparison={{
                      prior: inventoryHealthTotals?.unhealthyUnits.prior,
                    }}
                    title={t(`vendor.unhealthyUnits`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GenericTotal
                    number={inventoryHealthTotals?.aged90Days.current}
                    secondNumber={`(${(
                      (inventoryHealthTotals?.aged90Days.current /
                        inventoryHealthTotals?.sellableUnits.current) *
                      100
                    ).toFixed(0)}%)`}
                    comparison={{
                      prior: inventoryHealthTotals?.aged90Days.prior,
                    }}
                    title={t(`vendor.aged90Days`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default InventoryHealthTotals;
