import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { get, isEmpty } from "lodash";
import { isEmailValid, isPasswordValid } from "@merchantspring/common";
import { useDispatch, useSelector } from "react-redux";

import { CombinedLink } from "~/components/links/link.tsx";
import { Helmet } from "react-helmet";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PasswordHint from "~/components/passwordHint/passwordHint";
import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import ReCAPTCHA from "react-google-recaptcha";
import cookies from "browser-cookies";
import { passwordStrengthValidation } from "../../store/password.redux";
import { register } from "~/store/user.redux";
import styled from "styled-components";
import { useCustomFavicon } from "~/hooks/useCustomLogo";
import useDebounce from "../../hooks/useDebounce";
import { useTitleOverride } from "~/hooks/useTitleOverride";
import { useTranslation } from "react-i18next";

const FullWidthForm = styled.form`
  width: 100%;
  padding-top: 2rem;
`;

const PasswordHintContainer = styled.div`
  width: 100%;
`;

const FormField = styled(TextField)`
  padding-bottom: 1rem;
`;

const OPACITY = 0.3;

const MessageBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  padding-right: 0;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  background-color: ${({ theme }) =>
    alpha(theme.palette.secondary.light, OPACITY)};
`;

const Register = ({ onSuccess, onError, emailInitValue, errMsgInitValue }) => {
  const { t } = useTranslation();
  const passwordStore = useSelector((state) => state.password);
  const validationData = get(passwordStore, "validationData");
  const isPassValidated =
    !isEmpty(validationData) && validationData.every((val) => val[1] === true);
  const [email, setEmail] = React.useState(
    emailInitValue ? emailInitValue.toLowerCase() : ""
  );
  const [password, setPassword] = React.useState("");
  const [org, setOrg] = React.useState("");
  const [confirmCheckbox, setConfirmCheckbox] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(errMsgInitValue || "");
  const [loading, setLoading] = React.useState(false);
  const [formComplete, setFormComplete] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const DEBOUNCE_DELAY_MSEC = 1000;
  const debouncedSetPassword = useDebounce(password, DEBOUNCE_DELAY_MSEC);

  const recaptchaRef = React.useRef();
  const dispatch = useDispatch();

  const isAvaskUser =
    cookies.get("originalDomain") === "avask.merchantspring.io";
  const isDolmanBatemanUser =
    cookies.get("originalDomain") === "dolmanbateman.merchantspring.io";
  const isM2aSolutionsUser =
    cookies.get("originalDomain") === "app.m2asolutions.com";
  const isSitrunaUser =
    cookies.get("originalDomain") === "sitruna.merchantspring.io";

  const agency = isDolmanBatemanUser
    ? "Dolman Bateman"
    : isM2aSolutionsUser
    ? "M2A Solutions"
    : isSitrunaUser
    ? "Sitruna"
    : cookies.get("originalDomain");

  const isExternalUser =
    isAvaskUser || isDolmanBatemanUser || isM2aSolutionsUser || isSitrunaUser;

  // load reCAPTCHA script from www.recaptcha.net instead of www.google.com
  // see: https://developers.google.com/recaptcha/docs/faq#can-i-use-recaptcha-globally
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };

  // Handle successful completion of the recaptcha. The Google script handles
  // failure, so we don't need to
  const handleCaptchaSuccess = (responseToken) => {
    // Automatically sign the user up if captcha succeeds
    dispatch(
      register({
        email,
        password,
        org,
        captchaToken: responseToken,
        originalDomain: cookies.get("originalDomain"),
      })
    )
      .then(() => {
        setLoading(true);
        onSuccess();
      })
      .catch((err) => {
        if (recaptchaRef.current && recaptchaRef.current.reset) {
          recaptchaRef.current.reset();
        }
        setLoading(false);
        setFormComplete(false);
        onError(err);
      });
  };

  const handleSubmitSignup = (e) => {
    e.preventDefault();

    if (!isEmailValid(email)) {
      setErrMsg(t("register.invalidEmailError"));
      return;
    }

    if (!isPasswordValid(password)) {
      setErrMsg(t("register.invalidPasswordError"));
      return;
    }

    setFormComplete(true);
  };

  useEffect(() => {
    if (debouncedSetPassword) {
      dispatch(passwordStrengthValidation(password));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSetPassword, password]);

  const PasswordToggle = () => (
    <InputAdornment variant="filled" position="end">
      {!isEmpty(validationData) && (
        <Tooltip title={t("register.passwordTooltip")} id="panel-card-tooltip">
          <InlineIconButton />
        </Tooltip>
      )}
      <IconButton
        aria-label="Toggle password visibility"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const TermsAndConditions = () => (
    <Typography paragraph variant="body1" color="textPrimary">
      {t("register.agreeMerchantSpringText")}
      {t("generic.space")}
      <Link align="center" href={t("generic.termsConditionsLinkUrl")}>
        {t("generic.termsConditionsLink")}
      </Link>
      {t("generic.space")}
      {t("generic.andText")}
      {t("generic.space")}
      <Link align="center" href={t("generic.policyLinkUrl")}>
        {t("generic.policyLink")}
      </Link>
      {t("generic.periodText")}
    </Typography>
  );

  return (
    <>
      <Helmet>
        <title>Sign Up | {useTitleOverride()} </title>
        <meta
          name="description"
          content={
            "Sign up to Marketplace Manager " +
            "and connect your Amazon, eBay and " +
            "other marketplace accounts in minutes."
          }
        />
        <link rel="shortcut icon" type="image/png" href={useCustomFavicon()} />
      </Helmet>
      <FullWidthForm
        id="signupForm"
        method="POST"
        onSubmit={handleSubmitSignup}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <FormField
            fullWidth
            name="email"
            label={t("register.emailLabel")}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            value={email}
          />
          {isAvaskUser && (
            <FormField
              fullWidth
              name="org"
              label={t("register.orgLabel")}
              onChange={(e) => setOrg(e.target.value)}
              value={org}
            />
          )}
          <FormField
            fullWidth
            name="password"
            type={showPassword ? "text" : "password"}
            label={t("register.passwordLabel")}
            onChange={(e) => setPassword(e.target.value)}
            onClick={() => dispatch(passwordStrengthValidation(password))}
            InputProps={{
              endAdornment: <PasswordToggle />,
            }}
            value={password}
          />
          {!isEmpty(validationData) && (
            <PasswordHintContainer>
              {validationData.map((message, index) =>
                !isEmpty(message) ? (
                  <PasswordHint message={message} key={index} />
                ) : null
              )}
            </PasswordHintContainer>
          )}
          {errMsg && (
            <Grid item>
              <Typography color="error">{errMsg}</Typography>
            </Grid>
          )}
          {formComplete && !loading && (
            <div id="g-recaptcha" style={{ width: "90%", margin: "16px auto" }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={
                  window.Cypress || process.env.TARGET_ENV === "development"
                    ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // test key from https://developers.google.com/recaptcha/docs/faq
                    : process.env.CAPTCHA_SITE_KEY
                }
                onChange={handleCaptchaSuccess}
              />
            </div>
          )}
          {isExternalUser && (
            <MessageBox>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={confirmCheckbox}
                    color="primary"
                    onChange={(event) => {
                      setConfirmCheckbox(event.target.checked);
                    }}
                    name={
                      isAvaskUser
                        ? t("register.avaskDataShareCheckbox")
                        : t("register.genericDataShareCheckbox", { agency })
                    }
                  />
                }
                label={
                  isAvaskUser
                    ? t("register.avaskDataShareCheckbox")
                    : t("register.genericDataShareCheckbox", { agency })
                }
              />
            </MessageBox>
          )}
          {!formComplete && (
            <Box pt={2} pb={2}>
              <RaisedButton
                disabled={
                  // all users must meet password strength criteria
                  !isPassValidated ||
                  // in addition to the above, all external users must also
                  // check the data sharing checkbox
                  (isExternalUser && !confirmCheckbox) ||
                  // in addition to the above, all Avask users must also fill
                  // in the "organisation" text field
                  (isAvaskUser && !org)
                }
                type="submit"
                variant="contained"
                color="primary"
              >
                {t("register.signupButton")}
                <KeyboardArrowRight fontSize="small" />
              </RaisedButton>
            </Box>
          )}
          {loading && (
            <Box p={1}>
              <LoadingIndicator />
            </Box>
          )}
        </Box>
      </FullWidthForm>
      <Box pt={2} pb={2}>
        <TermsAndConditions />
      </Box>
      <Typography paragraph variant="body1" color="textPrimary">
        {t("register.alreadyHaveAccountText")}
        {t("generic.space")}
        <CombinedLink
          to={{
            pathname: "/signin",
            search: window.location.search,
          }}
        >
          {t("register.loginLink")}
        </CombinedLink>
      </Typography>
    </>
  );
};

Register.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  emailInitValue: PropTypes.string,
  errMsgInitValue: PropTypes.string,
};

export default Register;
