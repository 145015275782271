import { MarketplaceFilters } from "~/typedef/store";
import { PaginationArgs } from "~/typedef/pagination";
import { Range } from "~/typedef/store";
import { TrafficAndConversionTableView } from "~/modules/trafficAndConversion/trafficAndConversionTable";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface TrafficAndConversionArgs {
  mid: string;
  marketplaceFilters: MarketplaceFilters;
  currentRange: Range;
}

interface TrafficAndConversionTrend {
  startTime: number; //unix timestamp
  pageViews: number;
  totalSessions: number;
  conversionRate: number;
  sessionConversionRate: number;
}

interface TrafficAndConversionTrendResponse {
  data: TrafficAndConversionTrend[];
  currency: string;
}

interface TrafficAndConversionTotals {
  pageViews: {
    current: number;
    prior: number;
  };
  totalSessions: {
    current: number;
    prior: number;
  };
  orderedUnits: {
    current: number;
    prior: number;
  };
  orderedRevenue: {
    current: number;
    prior: number;
  };
  sessionsMobile: {
    current: number;
    prior: number;
  };
  sessionsBrowser: {
    current: number;
    prior: number;
  };
  pageViewsMobile: {
    current: number;
    prior: number;
  };
  pageViewsBrowser: {
    current: number;
    prior: number;
  };
}

interface TrafficAndConversionTotalsResponse {
  data: TrafficAndConversionTotals;
  currency: string;
}

interface TrafficAndConversionTableArgs extends TrafficAndConversionArgs {
  paginationParams: PaginationArgs;
  searchText?: string;
  view: TrafficAndConversionTableView;
}

export interface TrafficAndConversion {
  productId: number;
  title: string;
  parentCategory: string;
  categoryLabel: string;
  productSku: string;
  sku: string;
  imageUrl: string;
  url: string;
  isFulfilledByAmazon: boolean;
  brand: string;
  pageViews: number;
  priorPageViews: number;
  orderedRevenue: number;
  priorOrderedRevenue: number;
  orderedUnits: number;
  priorOrderedUnits: number;
  price: number;
  unitsPerView: number;
  priorUnitsPerView: number;
  sessions: number;
  priorSessions: number;
  unitsPerSession: number;
  priorUnitsPerSession: number;
  mobileSessionsPercentage: number;
  priorMobileSessionsPercentage: number;
  buyboxWinPercentage: number;
  priorBuyboxWinPercentage: number;
}

interface TrafficAndConversionView {
  data: TrafficAndConversion[];
  count: number;
}

interface TrafficAndConversionParentsView {
  data: Omit<
    TrafficAndConversion,
    "brand" | "isFulfilledByAmazon" | "price" | "sku"
  >[];
  count: number;
}

interface TrafficAndConversionTableResponse {
  parents: TrafficAndConversionParentsView;
  skus: TrafficAndConversionView;
  currency: string;
}

export type TrafficAndConversionProductTrend = {
  startTime: number; // unix timestamp
  pageViews: number;
  unitsPerView: number;
  totalSessions: number;
  unitsPerSession: number;
  buyboxWinPercentage: number;
};

interface TrafficAndConversionProductTrendArgs
  extends TrafficAndConversionArgs {
  view: TrafficAndConversionTableView;
  productId: number;
}

interface TrafficAndConversionProductTrendResponse {
  data: TrafficAndConversionProductTrend[];
  currency: string;
}

export interface TrafficMixTrend {
  startTime: number; //unix timestamp
  mobileSessions: number;
  browserSessions: number;
}

interface TrafficMixTrendResponse {
  data: TrafficMixTrend[];
  currency: string;
}

interface TrafficAndConversionNormalVsB2BTotals {
  orderedUnits: number;
  sales: number;
  otherOrderedUnits: number;
  otherSales: number;
  orderedUnitsTotal: number;
  salesTotal: number;
}

interface TrafficAndConversionNormalVsB2BSummaryResponse {
  data: TrafficAndConversionNormalVsB2BTotals;
  currency: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    trafficAndConversionTrend: build.query<
      TrafficAndConversionTrendResponse,
      TrafficAndConversionArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionTrend`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    trafficAndConversionSummary: build.query<
      TrafficAndConversionTotalsResponse,
      TrafficAndConversionArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionSummary`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    trafficAndConversion: build.query<
      TrafficAndConversionTableResponse,
      TrafficAndConversionTableArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    trafficAndConversionProductTrend: build.query<
      TrafficAndConversionProductTrendResponse,
      TrafficAndConversionProductTrendArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionProductTrend`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    trafficMixTrend: build.query<
      TrafficMixTrendResponse,
      TrafficAndConversionArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionMixTrend`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    trafficAndConversionNormalVsB2BSummary: build.query<
      TrafficAndConversionNormalVsB2BSummaryResponse,
      TrafficAndConversionArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionNormalVsB2BSummary`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useTrafficAndConversionTrendQuery,
  useTrafficAndConversionSummaryQuery,
  useTrafficAndConversionQuery,
  useTrafficAndConversionProductTrendQuery,
  useTrafficMixTrendQuery,
  useTrafficAndConversionNormalVsB2BSummaryQuery,
} = extendedApiSlice;
