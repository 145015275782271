import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PropTypes from "prop-types";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import WarningIcon from "@material-ui/icons/Warning";
import styled from "styled-components";

const GreenSuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.palette.success.main};
  height: 20px;
`;

const RedErrorIcon = styled(ErrorIcon)`
  fill: ${({ theme }) => theme.palette.error.main};
  height: 20px;
`;

const YellowWarningIcon = styled(WarningIcon)`
  fill: ${({ theme }) => theme.palette.warning.main};
  height: 20px;
`;

const ProcessingIcon = styled(QueryBuilderIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const IconGrid = styled(Grid)`
  width: auto;
`;

const SyncStatusCell = memo(({ cell }) => {
  if (!cell.value) {
    return (
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid container item alignItems="center">
          <Tooltip title="Processing">
            <ProcessingIcon />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const { MessagesSuccessful, MessagesWithError, MessagesWithWarning } =
    cell.value;

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
      {MessagesSuccessful !== "0" && (
        <IconGrid container item alignItems="center">
          <Tooltip title="Success">
            <GreenSuccessIcon />
          </Tooltip>
          <Typography variant="body2">{MessagesSuccessful}</Typography>
        </IconGrid>
      )}
      {MessagesWithWarning !== "0" && (
        <IconGrid container item alignItems="center">
          <Tooltip title="Warnings">
            <YellowWarningIcon />
          </Tooltip>
          <Typography variant="body2">{MessagesWithWarning}</Typography>
        </IconGrid>
      )}
      {MessagesWithError !== "0" && (
        <IconGrid container item alignItems="center">
          <Tooltip title="Errors">
            <RedErrorIcon />
          </Tooltip>
          <Typography variant="body2">{MessagesWithError}</Typography>
        </IconGrid>
      )}
    </Grid>
  );
});

SyncStatusCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default SyncStatusCell;
