import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, memo, useState } from "react";

import PanelLoading from "../loadingIndicator/panelLoadingIndicator";
import SearchFilter from "../adTable/searchFilter";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ScrollGrid = styled(Grid)`
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.border.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export interface Option {
  selected?: boolean;
  [x: string]: any;
}

interface SearchableMultiSelectProps {
  title: string;
  searchLimitText?: string;
  count: number;
  options: Option[];
  optionComponent: (props: any) => ReactElement;
  selectAllOptions: (checked: boolean) => void;
  changeSelectOption: (
    e: React.ChangeEvent<HTMLInputElement>,
    option: Option
  ) => void;
  setSearchText: (searchText: string) => void;
  searching?: boolean;
}

const SearchableMultiSelect = memo<SearchableMultiSelectProps>(
  function SearchableMultiSelect({
    title,
    searchLimitText,
    count,
    options,
    optionComponent,
    selectAllOptions,
    changeSelectOption,
    setSearchText,
    searching,
  }) {
    const { t } = useTranslation();
    const [selectAll, setSelectAll] = useState(false);

    const changeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setSelectAll(isChecked);
      selectAllOptions(isChecked);
    };

    const OptionComponent = optionComponent;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchFilter setSearchText={setSearchText} />
        </Grid>
        {searchLimitText && (
          <Grid item xs={12}>
            <Typography variant="body2">{searchLimitText}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                size="small"
                color="primary"
                onChange={(e) => changeSelectAll(e)}
              />
            }
            label={t("generic.selectAll", { count })}
          />
        </Grid>
        <ScrollGrid item xs={12}>
          {searching ? (
            <PanelLoading />
          ) : isEmpty(options) ? (
            <Typography align="center">
              {t("generic.noDataAvailableMessage")}
            </Typography>
          ) : (
            options?.map((option, index) => (
              <Box display="flex" alignItems="center" p={1} key={index}>
                <Checkbox
                  checked={option.selected}
                  size="small"
                  color="primary"
                  onChange={(e) => changeSelectOption(e, option)}
                />
                <OptionComponent {...option} />
              </Box>
            ))
          )}
        </ScrollGrid>
      </Grid>
    );
  }
);

export default SearchableMultiSelect;
