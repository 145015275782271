import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Typography, useTheme } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";

import Bold from "~/components/typography/bold";
import { CHART_TYPE } from "~/modules/widgets/salesPerformanceLineChart";
import { LegendIndicator } from "./multiLineChartLegend";
import React from "react";
import { TooltipProps } from "recharts";
import isEmpty from "lodash/isEmpty";
import { numberWithCommas } from "~/utils/utils";

interface TooltipInfoProps {
  fillColor: string;
  name: string;
  value: string;
  unit?: string;
}

const TooltipInfo = ({ fillColor, name, value, unit }: TooltipInfoProps) => {
  return (
    <Box
      display="flex"
      mt={0.5}
      alignItems="center"
      width="100%"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        mr={1}
      >
        <LegendIndicator fill={fillColor} />
        <Typography variant="body2">{name}:</Typography>
      </Box>
      <Typography variant="body2">{`${unit || ""}${value}`}</Typography>
    </Box>
  );
};

interface MultiLineChartTooltipProps extends TooltipProps<string, number> {
  currentPeriod: DATETIME_PERIODS;
  currency?: string;
  dataKeys: string[];
  interval: INTERVAL;
  chartType: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  timezone: string;
}
const MultiLineChartTooltip = ({
  payload,
  currentPeriod,
  currency,
  dataKeys,
  interval,
  chartType,
  timezone,
}: MultiLineChartTooltipProps) => {
  const tooltipData = !payload || isEmpty(payload) ? {} : payload[0].payload;
  const headerValue = Object.values(tooltipData)[0];
  const date = formatTooltipLabel(
    headerValue,
    currentPeriod,
    interval,
    timezone
  );
  const { endTime } = tooltipData || {};
  const endDate =
    interval === INTERVAL.WEEKS && endTime
      ? formatTooltipLabel(endTime, currentPeriod, interval, timezone)
      : null;

  const theme = useTheme();

  return (
    <BlackTooltip>
      <Box p={1} display="flex" flexDirection="column">
        <Bold variant="body2">{date}</Bold>
        {endDate && <Bold variant="body2">- {endDate}</Bold>}
        {dataKeys.map((dataKey, i) => {
          const rawValue = tooltipData[dataKey];
          const value = rawValue
            ? numberWithCommas(rawValue)
            : chartType === CHART_TYPE.UnitsSold
            ? "0"
            : "0.00";
          return (
            <TooltipInfo
              key={dataKey}
              name={dataKey}
              value={value}
              fillColor={Object.values(theme.palette.chart)[i] as string}
              unit={currency}
            />
          );
        })}
      </Box>
    </BlackTooltip>
  );
};

export default MultiLineChartTooltip;
