import { Avatar, Box, Grid } from "@material-ui/core";
import React, { memo } from "react";

import { NewReleasesRounded } from "@material-ui/icons";
import RaisedButton from "../buttons/raisedButton";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const DispatchNoData = memo(function DispatchNoData() {
  const history = useHistory();

  return (
    <Box p={2}>
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid item>
          <Avatar>
            <NewReleasesRounded />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h2" align="center">
            What’s this?
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" align="center">
            Information about the status of your orders will be availble here
            when you connect marketplaces that have orders.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <RaisedButton
            variant="text"
            onClick={() => history.push("/connectStore/select")}
            color="primary"
          >
            Add a Store now
          </RaisedButton>
        </Grid>
      </Grid>
    </Box>
  );
});

export default DispatchNoData;
