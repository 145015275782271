import React, { memo } from "react";

import HelpIcon from "@material-ui/icons/Help";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components";

const PauseIcon = styled(PauseCircleFilledIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const PlayIcon = styled(PlayCircleFilledIcon)`
  fill: ${({ theme }) => theme.palette.success.main};
  height: 20px;
`;

const UnknownIcon = styled(HelpIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  height: 20px;
`;

const enabledValues = ["enabled", "DELIVERING", "ENABLED", "ENABLE"];
const pausedValues = ["paused", "ENDED", "ARCHIVED", "DISABLE"];

const StatusCell = memo(({ cell }) => {
  const { value } = cell.value;
  return (
    <Tooltip title={value}>
      {enabledValues.includes(value) ? (
        <PlayIcon />
      ) : pausedValues.includes(value) ? (
        <PauseIcon />
      ) : (
        <UnknownIcon />
      )}
    </Tooltip>
  );
});

StatusCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default StatusCell;
