import { ArrowBack } from "@material-ui/icons";
import { ConnectionProps } from "../connectWizard/new/storeConnectionPrompt";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { connectEbay } from "../../store/connections/connectEbay.redux";
import { loadInfo } from "~/store/user.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useEbayAuthcodeAuth from "~/hooks/useEbayAuthcodeAuth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const BackButton = styled(IconButton)`
  margin: -15px 0 0 -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const EbayConnectPage = ({ onSuccess }: ConnectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errMsg, setErrMsg] = React.useState("");

  const onCode = React.useCallback(
    (code) => {
      connectEbay(code)
        .then(async () => {
          setErrMsg("");
          await dispatch(loadInfo());
          onSuccess({ marketplace: null });
        })
        .catch((e) => {
          console.error(e);
          setErrMsg(t("connectWizard.connectionError"));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return (
    <GridWrapper>
      <Grid container spacing={5}>
        <Grid item xs={2}>
          <BackButton onClick={history.goBack} id="back-button">
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item xs={10}>
          <Medium color="textPrimary">
            {t("connectWizard.ebayLinkTitle")}
          </Medium>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <Typography color="error">{errMsg}</Typography>
          </Grid>
          <Grid item>
            <RaisedButton
              onClick={useEbayAuthcodeAuth(onCode)}
              variant="contained"
              color="primary"
            >
              {t("connectWizard.authorizeButton")}
            </RaisedButton>
          </Grid>
        </Grid>
      </Grid>
    </GridWrapper>
  );
};

export default EbayConnectPage;
