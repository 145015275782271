import { Box, Grid, Hidden, Link, Typography } from "@material-ui/core";

import { KeyboardArrowRightRounded } from "@material-ui/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { SelectedItem } from "./sideNavigation/sideMenu";
import styled from "styled-components";

const StyledBox = styled(Box)<{ $textColorPalette?: string }>`
  color: ${({ $textColorPalette, theme }) => {
    if ($textColorPalette === "secondaryContrast")
      return theme.palette.secondary.contrastText;
    return theme.palette.text.primary;
  }};

  & svg {
    fill: ${({ $textColorPalette, theme }) => {
      if ($textColorPalette === "secondaryContrast")
        return theme.palette.secondary.contrastText;
      return theme.palette.text.primary;
    }};
  }
`;

interface BreadcrumbsProbs {
  selectedItem: SelectedItem;
  isOverviewToolbarPage?: boolean;
  textColorPalette?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProbs> = ({
  selectedItem,
  isOverviewToolbarPage,
  textColorPalette,
}) => (
  <StyledBox $textColorPalette={textColorPalette}>
    <Hidden smDown>
      <Grid container item xs={12} alignItems="center">
        {selectedItem.breadcrumb.map((crumb, i) => (
          <React.Fragment key={crumb.text}>
            {i > 0 && (
              <KeyboardArrowRightRounded fontSize="small" color="inherit" />
            )}
            <Grid item>
              {!crumb.link && (
                <Grid item>
                  <Typography variant="body2" color="inherit">
                    {crumb.text}
                  </Typography>
                </Grid>
              )}
              {crumb.link && (
                <Grid item>
                  <Link
                    to={crumb.link}
                    component={RouterLink}
                    underline={crumb.link ? "always" : "none"}
                    variant="body2"
                    color="inherit"
                    display="block"
                  >
                    {crumb.text}
                  </Link>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {!isOverviewToolbarPage && (
        <Grid item container alignItems="center">
          <Box mt={1} width="100%">
            <Typography variant="h1" color="inherit">
              {selectedItem.currentPage}
            </Typography>
          </Box>
        </Grid>
      )}
    </Hidden>
    <Hidden mdUp>
      <Typography variant="h6" color="inherit">
        {selectedItem.currentPage}
      </Typography>
    </Hidden>
  </StyledBox>
);

export default Breadcrumbs;
