import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SalesByParentWidget from "~/modules/widgets/salesByParent";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import moment from "moment-timezone";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SalesByParent = memo(() => {
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const userInfo = useTypedSelector((state) => state.user);
  const currentPeriod: DATETIME_PERIODS = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone: string = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare: COMPARISON_PERIOD = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const PAGE_SIZE = 25;

  const includeTax: boolean = useTypedSelector((state: StoreState) =>
    Boolean(state?.persistentAppSettings?.setting?.data?.includeTax)
  );

  const marketplace = useMarketplace();

  return (
    <PageBlock>
      {store ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SalesByParentWidget
              mid={store.merchantId}
              shopName={store.storeName}
              countryCode={store.marketplaceCountry}
              userInfo={userInfo}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              currentRange={currentRange}
              includeTax={includeTax}
              pageSize={PAGE_SIZE}
              marketplace={marketplace}
            />
          </Grid>
        </Grid>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default SalesByParent;
