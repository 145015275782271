import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import { Cell } from "./acosCell";
import { GrowthBlock } from "./valueAndGrowthCell";
import StatusText from "../../typography/status";

export const getStatusFromROAS = (
  roas: number | "-",
  conditionalFormatting?: boolean
) =>
  conditionalFormatting === false
    ? "disabled"
    : roas
    ? roas === "-"
      ? "disabled"
      : roas >= 6.66 //converting acos to roas equivalent
      ? "success"
      : roas >= 4
      ? "warning"
      : "error"
    : "disabled";

const getGrowthStatus = (growth: string | number) => {
  if (growth === "N/A" || growth === "-") {
    return "disabled";
  }
  return Number(growth) > 0 ? "error" : "success";
};

interface ROASCellProps {
  row: { original: { roas: number | "-" } };
  cell: {
    value: {
      value: string;
      raw: number;
      growth?: number;
      conditionalFormatting?: boolean;
    };
  };
}

const ROASCell = memo<ROASCellProps>(function ROASCell({ cell, row }) {
  const growth = cell.value.growth;
  const { value } = cell.value;
  const conditionalFormatting = cell.value.conditionalFormatting;
  const roas = growth ? cell.value.raw : row.original.roas;
  const status = getStatusFromROAS(roas, conditionalFormatting);
  const growthStatus = getGrowthStatus(growth ?? "-");
  return (
    <Cell>
      <StatusText justify="flex-end" variant="body2" status={status}>
        {value}
      </StatusText>
      {growth && (
        <GrowthBlock>
          <StatusText variant="body2" align="right" paragraph={false}>
            {growthStatus === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {growthStatus === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth) ? growth : Math.abs(growth)}
            {!isNaN(growth) && "ppt"}
          </StatusText>
        </GrowthBlock>
      )}
    </Cell>
  );
});

export default ROASCell;
