import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import KeywordSummaryPanel from "~/modules/marketing/keywords";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useSelector } from "react-redux";

const MarketingKeywords = memo(() => {
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const { isAdvertisingAuthorised, isReconnectAdvertising } =
    useAdvertisingConnected(store);

  return (
    <PageBlock>
      {!isAdvertisingAuthorised ? (
        <MarketingAuthoriseUser isReconnect={isReconnectAdvertising} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <KeywordSummaryPanel
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              marketplaceType={store.marketplace}
            />
          </Grid>
        </Grid>
      )}
    </PageBlock>
  );
});

export default MarketingKeywords;
