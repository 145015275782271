import {
  Bar,
  LabelList,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import {
  CHART_TITLES,
  getNearestHundredOrTen,
  getNearestPowerOfTenFloor,
  removeZeroValues,
} from "../chartUtils/chartUtils";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import React, { ReactSVGElement, memo, useMemo } from "react";
import {
  getEvenTicksFromZero,
  useLayoutProps,
} from "../chartUtils/chartComponents";

import { BarChartLabel } from "./barChartLabel";
import { BarChartLegendNew } from "./barChartLegendNew";
import { BarChartMultiTooltip } from "./barChartMultiTooltip";
import { SuccessDirection } from "../percentageChange";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Y_AXIS_WIDTH = 80;

const getXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  interval: INTERVAL,
  isCustomPeriodSameDay = false,
  timezone: string
): XAxisProps => {
  let tickFormatter;
  if (interval === INTERVAL.MONTHS) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).add(12, "h").startOf("day").format("MMM");
  } else if (currentPeriod === DATETIME_PERIODS.DAY || isCustomPeriodSameDay) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("HH");
  } else if (
    currentPeriod === DATETIME_PERIODS.WEEK ||
    currentPeriod === DATETIME_PERIODS.WEEKMTS
  ) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("ddd");
  } else {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("D MMM");
  }

  return {
    dataKey: "startTime",
    type: "category",
    interval: "preserveStartEnd",
    domain: ["dataMin", "dataMax"],
    tickLine: false,
    tick: { fontSize: 13 },
    minTickGap: 25,
    tickFormatter,
  };
};

interface BarChartProps {
  chartData: {
    startTime?: number;
    endTime?: number;
    current?: number | null;
    prior?: number | null;
  }[];
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  report?: boolean;
  isCustomPeriodSameDay?: boolean;
  fromDate: number;
  toDate: number;
  interval: INTERVAL;
  timezone: string;
  keys?: [string, string];
  keysForLegend?: [string, string];
  colours?: [string, string];
  notCurrency?: boolean;
  valueTooltipKey: string;
  valuePrefix?: string;
  valueSuffix?: string;
  toFixed?: number;
  displayLabelOnBar?: boolean;
  displayPercentChange?: boolean;
  percentageChangeFormatting?: {
    isPptChange?: boolean;
    successDirection?: SuccessDirection;
    conditionalFormatting?: boolean;
    toFixedDigits?: number;
    noUnit?: boolean;
  };
}

export const BarChartMulti = memo<BarChartProps>(function BarChartMulti({
  chartData,
  currentPeriod,
  currentCurrency,
  report,
  fromDate,
  toDate,
  interval,
  timezone,
  keys,
  keysForLegend,
  colours,
  notCurrency,
  valueTooltipKey,
  valueSuffix,
  valuePrefix,
  toFixed,
  displayLabelOnBar,
  displayPercentChange = true,
  percentageChangeFormatting,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const data = useMemo(() => chartData, [chartData]);

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const isCustomPeriodSameDay =
    Boolean(fromDate) &&
    Boolean(toDate) &&
    moment
      .unix(fromDate)
      .tz(timezone)
      .isSame(moment.unix(toDate).tz(timezone), "day");

  const XAxisProps =
    data &&
    getXAxisProps(currentPeriod, interval, isCustomPeriodSameDay, timezone);

  const { height, margin, onMobile } = useLayoutProps(undefined, report);

  const dataMaxThisWeek =
    chartData &&
    Math.max.apply(
      Math,
      chartData.map((o) => o.current ?? 0)
    );

  const dataMaxLastWeek =
    chartData &&
    Math.max.apply(
      Math,
      chartData.map((o) => o.prior ?? 0)
    );

  const dataMax = Math.max(dataMaxThisWeek, dataMaxLastWeek);

  const dataKeys = keys ?? ["prior", "current"];
  const dataKeysForLegend = keysForLegend ?? [
    t("chartKeys.past"),
    t("chartKeys.current"),
  ];
  const dataColours = colours ?? [
    theme.palette.secondary.main,
    theme.palette.primary.main,
  ];

  /* Calculate a 'rounding ceiling' - the minimum tick size for the Y axis 
    The one exception is if the dataMax is less than 10, in which case we round to 1, so that the Y axis
    ticks look better for small numbers.
  */
  const roundingCeiling = dataMax < 10 ? 1 : valueSuffix === "%" ? 10 : 100;

  const yAxisTicks = getEvenTicksFromZero(
    getNearestHundredOrTen(dataMax),
    5,
    // take the closest hundred, or thousand, etc, to 1% of the overall data value
    // Used to make the rounding on the tick marks more intuitive
    // for small currency denominations like JPY or DKK
    Math.max(roundingCeiling, getNearestPowerOfTenFloor(dataMax / 100))
  );

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h6">{t(CHART_TITLES[currentPeriod])}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width={"100%"} height={height}>
          <RechartsBarChart data={data} margin={margin}>
            <XAxis {...XAxisProps} />
            <YAxis
              type="number"
              ticks={yAxisTicks}
              domain={[0, "dataMax"]}
              allowDataOverflow
              tick={{ fontSize: 13 }}
              tickFormatter={(tick) =>
                notCurrency
                  ? `${valuePrefix ?? ""}${numberWithCommas(parseInt(tick))}${
                      valueSuffix ?? ""
                    }`
                  : formatCurrencyRounded(
                      tick,
                      currencyRates,
                      currentCurrency,
                      currentCurrency
                    )
              }
              width={Y_AXIS_WIDTH}
            />
            <Tooltip
              content={
                <BarChartMultiTooltip
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  currencyRates={currencyRates}
                  interval={interval}
                  timezone={timezone}
                  dataKeys={dataKeysForLegend}
                  colours={dataColours}
                  notCurrency={notCurrency}
                  valueTooltipKey={valueTooltipKey}
                  valuePrefix={valuePrefix}
                  valueSuffix={valueSuffix}
                  toFixed={toFixed}
                  displayPercentChange={displayPercentChange}
                  percentageChangeFormatting={percentageChangeFormatting}
                />
              }
            />
            <Legend
              verticalAlign="top"
              align="left"
              wrapperStyle={{ top: -16, fontSize: "12px" }}
              content={
                <BarChartLegendNew
                  dataKeys={dataKeysForLegend}
                  colours={dataColours}
                />
              }
            />

            {dataKeys.map((dataKey, i) => {
              return (
                <Bar
                  key={`${dataKey}-${i}`}
                  dataKey={dataKey}
                  fill={dataColours[i] ?? theme.palette.primary.main}
                  barSize={
                    currentPeriod === DATETIME_PERIODS.WEEK ||
                    currentPeriod === DATETIME_PERIODS.WEEKMTS
                      ? 25
                      : 20
                  }
                >
                  {!onMobile && displayLabelOnBar && (
                    <LabelList
                      content={BarChartLabel as unknown as ReactSVGElement}
                      formatter={removeZeroValues}
                      dataKey={dataKey}
                      position="top"
                    />
                  )}
                </Bar>
              );
            })}
          </RechartsBarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
});

export default BarChartMulti;
