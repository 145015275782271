import { Typography, alpha } from "@material-ui/core";

import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import cookies from "browser-cookies";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const InfoIcon = styled(InfoOutlined)`
  height: 24px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const MessageBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  background-color: ${({ theme }) =>
    alpha(theme.palette.secondary.light, OPACITY)};
`;

const OPACITY = 0.3;

const DataShareInfo = () => {
  const { t } = useTranslation();
  const isAvaskUser =
    cookies.get("originalDomain") === "avask.merchantspring.io";
  const isDolmanBatemanUser =
    cookies.get("originalDomain") === "dolmanbateman.merchantspring.io";
  const isM2aSolutionsUser =
    cookies.get("originalDomain") === "app.m2asolutions.com";
  const isSitrunaUser =
    cookies.get("originalDomain") === "sitruna.merchantspring.io";

  const agency = isDolmanBatemanUser
    ? "Dolman Bateman"
    : isM2aSolutionsUser
    ? "M2A Solutions"
    : isSitrunaUser
    ? "Sitruna"
    : cookies.get("originalDomain");

  return (
    <MessageBox>
      <InfoIcon />
      <Typography variant="body2">
        {isAvaskUser
          ? t("register.avaskDataShareMessage")
          : t("register.genericDataShareMessage", { agency })}
      </Typography>
    </MessageBox>
  );
};

export default DataShareInfo;
