import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Grid, Typography, useTheme } from "@material-ui/core";
import React, { useMemo, useState } from "react";

import { AxisDomain } from "recharts/types/util/types";
import { intFormatterRounded } from "~/utils/currencyUtils";
import { useLayoutProps } from "../chartUtils/chartComponents";

type WithPercent<T> = T & { percent: number; fullPercent: number };
function withPercent<T>(o: T): o is WithPercent<T> {
  const percent = (o as WithPercent<T>).percent;
  return percent !== undefined && percent !== null;
}

const LABEL_OFFSET = 5; // this has a default value of 5 in recharts, making it a constant for inclusion in formulae
const Y_AXIS_WIDTH = 80; // as above

const CustomizedLabel: React.FC<LabelProps> = (props) => {
  const { x, y, width, height, offset } = props;
  // LabelProps expects value to be a string or number, but we want an object for our use case. so used JSON.stringify
  const value = JSON.parse(props.value as string) as
    | BarData
    | WithPercent<BarData>; // this is what we pass in with dataKey={v=>v}

  const theme = useTheme();
  const fontNormal = theme.typography.body2;
  const fontHeavy = theme.typography.heavyDetail;
  return (
    <>
      <text
        x={(x as number) + (width as number) + (offset as number)}
        y={(y as number) + (height as number) / 2 + (offset as number)}
        fontSize={fontHeavy.fontSize}
        fontFamily={theme.typography.fontFamily}
        textAnchor="start"
      >
        <tspan
          fontWeight={fontHeavy.fontWeight}
          fill={theme.palette.text?.primary}
        >
          {intFormatterRounded.format(value.value)}
        </tspan>
        {withPercent(value) ? (
          <tspan
            fontWeight={fontNormal?.fontWeight}
            fill={theme.palette.text.secondary}
            dx={offset}
          >
            {`(${value.percent}%)`}
          </tspan>
        ) : undefined}
      </text>
    </>
  );
};

interface BarData {
  title: string;
  value: number;
  color: string;
}

export interface HorizontalBarChartProps {
  showPercent?: boolean;
  chartTitle: string;
  bars: BarData[];
}

/**
 * We need to flexibly calculate the right margin for larger datamaxes, see the story
 * BigNumber in horizontalBarChart.stories.tsx for an example.
 *
 */
function calcRightMargin(data: BarData[], showPercent?: boolean): number {
  // (Math.trunc(Math.log10(dataMax)) + 1) * 7 is a (very rough) approximation for how much space the value itself needs
  // 42 is an approximation for how much space ([X]XX%) needs, + LABEL_OFFSET which is the dx for that tspan
  const PERCENT_SPACE = 42 + LABEL_OFFSET;
  const dataMax = data.reduce(
    (acc, cur) => (cur.value > acc ? cur.value : acc),
    0
  );
  return (
    (dataMax >= 10 ? Math.trunc(Math.log10(dataMax)) + 1 : 1) * 7 +
    (showPercent ? PERCENT_SPACE : 0) +
    LABEL_OFFSET
  );
}

/**
 * Horizontal tick labels are clipped on vertical chart.
 * https://github.com/recharts/recharts/issues/1480
 *
 * Because we can't know in advance a) how long the bar titles different users of this component
 * will have and b) how long the names are even for supressed listings when translated to any given language
 * we need to calculate this to be safe.
 *
 */
function useCalcLeftMargin(bars: Array<BarData>): number {
  const titleWords: string[] = bars.reduce((acc, cur) => {
    // recharts seems to split the title up into tspans, one tspan for each word in the bar title
    acc.push(...cur.title.split(" "));
    return acc;
  }, [] as string[]);

  const theme = useTheme();

  const [ctx] = useState<CanvasRenderingContext2D>(
    document.createElement("canvas").getContext("2d")!
  );
  ctx.font = `${theme.typography.lightDetail.fontWeight} ${theme.typography.lightDetail.fontSize} ${theme.typography.fontFamily}`;

  const longestTitleWordLength = titleWords.reduce((acc, cur) => {
    const width = useMemo(() => ctx.measureText(cur).width, [cur]);
    return width > acc ? width : acc;
  }, 0);

  return Math.max(0, longestTitleWordLength - Y_AXIS_WIDTH + 10); // 10 is an approximation for the constant gap to the right of the words.
}

/**
 * Note - if you are using this with a dynamic number of bars (above 4), you may need to
 * enhance this code so that the height of the component is sufficient for the bars, in particular their Y axis tick labels.
 *
 */
const HorizontalBarChart: React.FC<HorizontalBarChartProps> = (props) => {
  const { showPercent, chartTitle, bars } = props;
  const { height } = useLayoutProps("horizontalChart");

  let data: Array<BarData | WithPercent<BarData>>;
  if (showPercent) {
    const totalIssues = bars.reduce((acc, cur) => acc + cur.value, 0);
    data = bars.map((b) => {
      let fullPercent: number;
      if (totalIssues === 0) {
        fullPercent = 0;
      } else {
        fullPercent = (b.value / totalIssues) * 100;
      }
      return { ...b, percent: Math.round(fullPercent), fullPercent };
    });
  } else {
    data = bars;
  }

  const theme = useTheme();
  const dataKey: keyof BarData = "value";
  const yDataKey: keyof BarData = "title";
  const commonTickProperties = {
    ...theme.typography.lightDetail,
    fontFamily: theme.typography.fontFamily,
    fill: theme.palette.text?.primary,
  };
  const leftMargin = useCalcLeftMargin(data);
  const rightMargin = calcRightMargin(data, showPercent);

  const dataMax = data.reduce(
    (acc, cur) => (cur.value > acc ? cur.value : acc),
    0
  );
  // recharts fails to intelligently set auto when dataMax is 0
  const domain: AxisDomain = dataMax > 0 ? [0, "auto"] : [0, 10];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom align="center" variant="body2">
          {chartTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={height}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{
              left: leftMargin,
              right: rightMargin,
            }}
          >
            <XAxis
              type="number"
              tickLine={false}
              tickCount={1}
              domain={domain}
              tickFormatter={(value: number) =>
                intFormatterRounded.format(value)
              }
              tick={commonTickProperties}
            />
            <YAxis
              width={Y_AXIS_WIDTH}
              tickLine={false}
              tick={commonTickProperties}
              dataKey={yDataKey}
              type="category"
            />
            <Bar barSize={15} dataKey={dataKey} layout="vertical">
              {data.map((bar, i) => (
                <Cell key={`Cell-${i}`} fill={bar.color}></Cell>
              ))}
              <LabelList
                offset={LABEL_OFFSET}
                position="right"
                // dataKey function is meant to map to a string or number, but we want
                // the whole object (due to our design requirements)
                // therefor JSON.stringifying to suppress a proptypes console error
                dataKey={(v) => JSON.stringify(v)}
                content={CustomizedLabel}
              ></LabelList>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default React.memo(HorizontalBarChart);
