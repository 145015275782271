import React, { memo } from "react";

import AllCampaignsChartPanel from "~/modules/marketing/charts/campaigns";
import AllCampaignsTotalsPanel from "~/modules/marketing/charts/campaignsTotals";
import BannerWithLink from "~/components/alert/bannerWithLink";
import { Box } from "@material-ui/core";
import CampaignSummaryPanel from "~/modules/marketing/campaigns";
import { Grid } from "@material-ui/core";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MarketingCampaigns = memo(() => {
  const { t } = useTranslation();
  const store = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const { isAdvertisingAuthorised, isReconnectAdvertising } =
    useAdvertisingConnected(store);
  const urlSearchParams = useQueryParams();
  const groupId = urlSearchParams.get("groupId");
  const groupName = urlSearchParams.get("groupName");

  return (
    <PageBlock>
      {!isAdvertisingAuthorised ? (
        <MarketingAuthoriseUser isReconnect={isReconnectAdvertising} />
      ) : (
        <>
          {groupId && (
            <Box pb={2}>
              <BannerWithLink
                type="info"
                isOpen={true}
                message={t("marketingCampaigns.customGroupFilter", {
                  groupName,
                })}
                link={marketplaceLink(
                  store?.marketplace ?? "",
                  store?.merchantId ?? "",
                  "marketingCampaigns"
                )}
                linkText={t("generic.viewAllLink")}
              />
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <AllCampaignsChartPanel
                marketplaceType={store?.marketplace ?? ""}
                marketplaceSubtype={store?.marketplaceSubtype ?? ""}
                mid={store?.merchantId ?? ""}
                countryCode={store?.marketplaceCountry ?? ""}
                groupId={groupId ?? ""}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AllCampaignsTotalsPanel
                marketplaceType={store?.marketplace ?? ""}
                marketplaceSubtype={store?.marketplaceSubtype ?? ""}
                mid={store?.merchantId ?? ""}
                countryCode={store?.marketplaceCountry ?? ""}
                groupId={groupId ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <CampaignSummaryPanel
                marketplace={store?.marketplace ?? ""}
                mid={store?.merchantId ?? ""}
                countryCode={store?.marketplaceCountry ?? ""}
                groupId={groupId}
              />
            </Grid>
          </Grid>
        </>
      )}
    </PageBlock>
  );
});

export default MarketingCampaigns;
