import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import {
  clearPasswordStrengthValidation,
  passwordStrengthValidation,
} from "~/store/password.redux";
import { get, isEmpty } from "lodash";
import { isEmailValid, isPasswordValid } from "@merchantspring/common";
import { isJobTitleValid, isUserNameValid } from "~/utils/utils";
import { useDispatch, useSelector } from "react-redux";

import { FormButton } from "~/modules/subscription/subscriptionStyledComponents";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { LEGACY_PLAN_ID } from "~/modules/subscription/plan/plan";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PasswordHint from "~/components/passwordHint/passwordHint";
import StatusText from "~/components/typography/status";
import { distinctCurrenciesByCountryCode } from "~/utils/currencyUtils";
import styled from "styled-components";
import { updateCustomer } from "~/store/subscriptions.redux";
import { updatePersonalInfo } from "~/store/user.redux";
import useDebounce from "~/hooks/useDebounce";
import { useTranslation } from "react-i18next";

export const CardDivider = styled(Divider)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey["200"]};
  `}
`;

const CustomForm = styled.form`
  & .MuiInput-underline.Mui-disabled:before {
    border-bottom: none;
  }
`;

const PasswordHintContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem;
  align-items: center;
`;

const GridWithLeftMargin = styled(Grid)`
  margin-left: 8px;
`;

const GridWithMargin = styled(Grid)`
  margin-bottom: 24px;
  margin-left: 8px;
`;

const setInitialState = (user) => ({
  firstName: {
    value: user && user.firstName ? user.firstName : "",
    edited: false,
  },
  lastName: {
    value: user && user.lastName ? user.lastName : "",
    edited: false,
  },
  email: {
    value: user && user.email ? user.email : "",
    edited: false,
  },
  mfaRequired: {
    value: user && user.mfaRequired ? user.mfaRequired : false,
    edited: false,
  },
  agreeReceiveNews: {
    value: user && user.agreeReceiveNews ? user.agreeReceiveNews : false,
    edited: false,
  },
  org: {
    value: user && user.org ? user.org : "",
    edited: false,
  },
  currency: {
    value: user && user.currency ? user.currency : "",
    edited: false,
  },
  password: {
    value: "",
    edited: false,
  },
  newPassword: {
    value: "",
    edited: false,
  },
});

const PersonalSetting = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const passwordStore = useSelector((state) => state.password);
  const subscriptions = useSelector((state) => state.subscriptions);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [msg, setMsg] = useState(null);
  const [savingData, setSavingData] = useState(false);
  const [formState, setFormState] = useState(setInitialState(user));
  const DEBOUNCE_DELAY_MSEC = 1000;
  const debouncedNewPassword = useDebounce(
    formState.newPassword.value,
    DEBOUNCE_DELAY_MSEC
  );
  const validationData = get(passwordStore, "validationData");
  const isDemoMode = get(user, "isDemoMode");
  const { currentSubscription } = subscriptions;

  useEffect(() => {
    if (debouncedNewPassword) {
      dispatch(passwordStrengthValidation(debouncedNewPassword));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNewPassword]);

  const handleChange = (event, accessor) => {
    event.preventDefault();
    setFormState({
      ...formState,
      [accessor]: {
        value:
          accessor === "email"
            ? event.target.value.toLowerCase()
            : event.target.value,
        edited: true,
      },
    });
    setChanges(true);
  };

  const handleCheckboxChange = (event, accessor) => {
    setFormState({
      ...formState,
      [accessor]: {
        value: event.target.checked,
        edited: true,
      },
    });
    setChanges(true);
  };

  const validate = (field, value) => {
    if (field === "firstName" || field === "lastName") {
      if (!isUserNameValid(value)) {
        setMsg({
          status: "error",
          msg: t(
            field === "firstName"
              ? "userSetting.invalidFirstNameError"
              : "userSetting.invalidLastNameError"
          ),
        });
        return true;
      }
    }

    if (field === "email") {
      if (!isEmailValid(value)) {
        setMsg({
          status: "error",
          msg: t("register.invalidEmailError"),
        });
        return true;
      }
    }

    if (field === "org") {
      if (!isJobTitleValid(value)) {
        setMsg({
          status: "error",
          msg: t("userSetting.invalidOrgNameError"),
        });
        return true;
      }
    }

    if (field === "password") {
      if (value === "") {
        setMsg({
          status: "error",
          msg: t("userSetting.emptyPasswordError"),
        });
        return true;
      }
    }

    if (field === "newPassword") {
      if (!isPasswordValid(value)) {
        setMsg({
          status: "error",
          msg: t("register.invalidPasswordError"),
        });
        return true;
      }
    }
    return false;
  };

  const PasswordToggle = () => (
    <InputAdornment variant="filled" position="end">
      {!isEmpty(validationData) && (
        <Tooltip title={t("register.passwordTooltip")} id="panel-card-tooltip">
          <InlineIconButton />
        </Tooltip>
      )}
    </InputAdornment>
  );

  const handleSavePersonalInfo = async (e) => {
    e.preventDefault();
    const updatedInfo = Object.keys(formState)
      .filter((field) => formState[field].edited)
      .reduce((res, field) => ((res[field] = formState[field].value), res), {});

    if (user && user._id) {
      updatedInfo.userId = user._id;
    } else {
      return setMsg({
        status: "error",
        msg: t("userSetting.needLoginError"),
      });
    }

    const hasErrors = Object.keys(updatedInfo)
      .map((field) => validate(field, updatedInfo[field]))
      .some((hasError) => hasError);

    if (updatedInfo !== {} && !hasErrors) {
      try {
        setSavingData(true);
        if (!isDemoMode) {
          await dispatch(updatePersonalInfo({ userInfo: updatedInfo }));
          if (
            user.role === "owner" &&
            currentSubscription &&
            currentSubscription.planId !== LEGACY_PLAN_ID
          ) {
            await dispatch(
              updateCustomer(
                currentSubscription.subscriptionId,
                get(formState, "email.value", user.email.toLowerCase()),
                get(formState, "firstName.value", user.firstName),
                get(formState, "lastName.value", user.lastName),
                get(formState, "org.value", user.org)
              )
            );
          }
        }
        setMsg({
          status: "success",
          msg: t("settings.account.updateSuccessMessage"),
        });
        setSavingData(false);
        setEditMode(false);
      } catch (err) {
        setSavingData(false);
        setMsg({
          status: "error",
          msg: err,
        });
        setEditMode(false);
        setFormState(setInitialState(user));
      }
    }
  };

  const isExternalUser = user.role === "external";

  return (
    <CustomForm method="POST" onSubmit={handleSavePersonalInfo}>
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" color="textPrimary">
          {t("settings.account.personalDetailsTitle")}
        </Typography>
        {savingData && <LoadingIndicator />}
        {msg && <StatusText status={msg.status}>{msg.msg}</StatusText>}
      </Box>
      <CardDivider />
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="first-name"
              label={t("settings.account.firstNameLabel")}
              value={formState.firstName.value}
              disabled={!editMode}
              onChange={(e) => handleChange(e, "firstName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="last-name"
              label={t("settings.account.lastNameLabel")}
              value={formState.lastName.value}
              disabled={!editMode}
              onChange={(e) => handleChange(e, "lastName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="org"
              label={t("settings.account.companyNameLabel")}
              value={formState.org.value}
              disabled={!editMode}
              onChange={(e) => handleChange(e, "org")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="currency-label">
                {t("settings.account.currencyLabel")}
              </InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label={t("settings.account.currencyLabel")}
                value={formState.currency.value}
                disabled={!editMode}
                onChange={(e) => handleChange(e, "currency")}
              >
                {Object.values(distinctCurrenciesByCountryCode).map(
                  (currency, i) => (
                    <MenuItem key={"currencyItem" + i} value={currency}>
                      {currency}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              label={t("settings.account.emailLabel")}
              autoComplete="off"
              value={formState.email.value}
              disabled={!editMode || user.lockedEmail}
              onChange={(e) => handleChange(e, "email")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.agreeReceiveNews.value}
                  onChange={(e) => handleCheckboxChange(e, "agreeReceiveNews")}
                  name="agreeReceiveNews"
                  color="primary"
                  disabled={!editMode}
                />
              }
              label={t("settings.account.newsUpdateLabel")}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6" color="textPrimary">
          {t("settings.account.passwordTitle")}
        </Typography>
      </Box>
      <CardDivider />
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="password"
              autoComplete="new-password"
              label={t("settings.account.existingPasswordLabel")}
              type="password"
              value={formState.password.value}
              disabled={!editMode}
              onChange={(e) => handleChange(e, "password")}
              fullWidth
            />
          </Grid>
          {editMode && (
            <Grid item xs={12} sm={6}>
              <TextField
                id="new-password"
                autoComplete="off"
                label={t("settings.account.newPasswordLabel")}
                type="password"
                value={formState.newPassword.value}
                disabled={!editMode}
                onChange={(e) => handleChange(e, "newPassword")}
                InputProps={{
                  endAdornment: <PasswordToggle />,
                }}
                fullWidth
                onClick={() =>
                  dispatch(passwordStrengthValidation(debouncedNewPassword))
                }
              />
            </Grid>
          )}
          {editMode && !isEmpty(validationData) && (
            <PasswordHintContainer>
              {validationData.map((message, index) =>
                !isEmpty(message) ? (
                  <PasswordHint message={message} key={index} />
                ) : null
              )}
            </PasswordHintContainer>
          )}
        </Grid>
      </Box>
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.mfaRequired.value}
                onChange={(e) => handleCheckboxChange(e, "mfaRequired")}
                name="mfaRequired"
                color="primary"
                disabled={!editMode}
              />
            }
            label={t("settings.account.enableMFAToggleLabel")}
          />
        </Grid>
      </Box>
      <Box p={2} flexGrow={1} display="flex" alignItems="center">
        {!editMode ? (
          <FormButton
            color="primary"
            onClick={() => {
              dispatch(clearPasswordStrengthValidation());
              setEditMode(true);
            }}
          >
            {t("generic.editButton")}
          </FormButton>
        ) : (
          <>
            <FormButton
              color="secondary"
              onClick={() => {
                setEditMode(false);
                setFormState(setInitialState(user));
              }}
            >
              {t("generic.cancelButton")}
            </FormButton>
            <FormButton
              type="submit"
              color="primary"
              disabled={!editMode || !changes}
            >
              {t("generic.saveButton")}
            </FormButton>
          </>
        )}
      </Box>
      {isExternalUser && (
        <GridWithMargin
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              <Link
                color="textSecondary"
                href={t("generic.termsConditionsLinkUrl")}
                target="_blank"
              >
                {t("generic.termsConditionsLink")}
              </Link>
            </Typography>
          </Grid>
          <GridWithLeftMargin item>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              <Link
                color="textSecondary"
                href={t("generic.policyLinkUrl")}
                target="_blank"
              >
                {t("generic.policyLink")}
              </Link>
            </Typography>
          </GridWithLeftMargin>
        </GridWithMargin>
      )}
    </CustomForm>
  );
});

export default PersonalSetting;
