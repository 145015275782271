import React, { useEffect, useMemo } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const state = useMemo(() => generateRandomState(), []);

  const childWindow = React.useRef<Window | null>(null);

  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "google"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.error) {
          onError(params.error);
          return;
        }

        if (params.state) {
          if (params.state !== state) {
            onError("Invalid state response from Google");
            return;
          }

          if (params.code) {
            onCode(params.code);
            return;
          }
        }

        onError("Invalid response from Google");
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError, state]);

  const triggerSignIn = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateAuthUrl(state),
        "authPopup-mm-google",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }

    if (childWindow.current) {
      childWindow.current.focus();
    }
  };

  return triggerSignIn;
}

const generateRandomState = (): string => crypto.randomUUID();

const generateAuthUrl: (state: string) => string = (state) => {
  const host =
    process.env.GOOGLE_ADS_OAUTH_ENDPOINT ||
    "https://accounts.google.com/o/oauth2/v2/auth";

  const clientId = process.env.GOOGLE_ADS_OAUTH_CLIENT_ID;

  const redirectUri =
    process.env.GOOGLE_ADS_OAUTH_REDIRECT_URI ||
    "https://mm.merchantspring.io/callback/google";

  if (!clientId) {
    throw new Error("Error authorising google connection");
  }

  const url = new URL(host);

  const urlParams = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirectUri,
    state,
    scope: "https://www.googleapis.com/auth/adwords",
    access_type: "offline",
    prompt: "consent",
  });

  url.search = urlParams.toString();
  return url.toString();
};
