import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import { Cell } from "./acosCell";
import { GrowthBlock } from "./valueAndGrowthCell";
import PropTypes from "prop-types";
import StatusText from "../../typography/status";

export const getStatusFromTACOS = (tacos, conditionalFormatting) =>
  conditionalFormatting === false
    ? "disabled"
    : tacos
    ? tacos === "-"
      ? "disabled"
      : tacos <= 10
      ? "success"
      : tacos <= 15
      ? "warning"
      : "error"
    : "disabled";

const getGrowthStatus = (growth) => {
  if (growth === "N/A" || growth === "-") {
    return "disabled";
  }
  return growth > 0 ? "error" : "success";
};

const TACOSCell = memo(({ cell, row }) => {
  const growth = cell.value.growth;
  const { value } = cell.value;
  const conditionalFormatting = cell.value.conditionalFormatting;
  const tacos = growth ? cell.value.raw : row.original.tacos;
  const status = getStatusFromTACOS(tacos, conditionalFormatting);
  const growthStatus = getGrowthStatus(growth);
  return (
    <Cell>
      <StatusText
        textAlign="right"
        justifyContent="flex-end"
        variant="body2"
        status={status}
      >
        {value}
      </StatusText>
      {growth && (
        <GrowthBlock>
          <StatusText variant="body2" align="right" paragraph={false}>
            {growthStatus === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {growthStatus === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth) ? growth : Math.abs(growth)}
            {!isNaN(growth) && "ppt"}
          </StatusText>
        </GrowthBlock>
      )}
    </Cell>
  );
});

TACOSCell.propTypes = {
  row: PropTypes.shape({ original: PropTypes.any }).isRequired,
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default TACOSCell;
