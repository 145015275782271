import React, { useCallback } from "react";
import { flatMap, get, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import FilterTagSelect from "../../select/filterTagSelect";
import PropTypes from "prop-types";
import { fetchCustomTags } from "../../../store/overview/customTags.redux";
import { useTranslation } from "react-i18next";

const FilterTagDropdown = ({
  selectedFilters,
  setSelectedFilters,
  filterWidth,
  fullWidth,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => get(state, "user"));
  const isTagsLoading = useSelector((state) =>
    get(state, "overview.customTags.fetching")
  );
  const customTags = useSelector((state) =>
    get(state, "overview.customTags.tags")
  );
  const selectedTags = get(selectedFilters, "tags");
  const filterTagTitle = t("filters.allCustomTags");
  const tags = uniq(flatMap(customTags, (val) => val.tags));

  const handleOpenMenu = useCallback(() => {
    dispatch(fetchCustomTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  const handleOptionChange = (tag) => {
    setSelectedFilters({ ...selectedFilters, tags: tag });
  };

  return (
    <FilterTagSelect
      title={t(`filterTitle.tags`)}
      isFullWidth={fullWidth}
      maxWidth={filterWidth}
      minWidth={120}
      additionalMenuWidth={50}
      isLoading={isTagsLoading}
      isWithSuggestion
      options={tags}
      selectedOptions={selectedTags}
      handleOpenMenu={handleOpenMenu}
      filterTagTitle={filterTagTitle}
      handleOptionChange={handleOptionChange}
      isDisabled={disabled}
    />
  );
};

FilterTagDropdown.propTypes = {
  filterWidth: PropTypes.number,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilterTagDropdown;
