import React, { memo, useMemo } from "react";

import StatusText from "~/components/typography/status";

interface AttainmentOrLimitProps {
  currentValue: number;
  thresholdValue: number;
  conditionalFormatting?: boolean;
  reverseFormatting?: boolean;
  lowThreshold?: number; // Typically 0-100
  midThreshold?: number; // Typically 0-100
  highThreshold?: number; // Typically 0-100
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
}

export const AttainmentOrLimit = memo<AttainmentOrLimitProps>(
  ({
    currentValue,
    thresholdValue,
    conditionalFormatting,
    reverseFormatting,
    lowThreshold = 25,
    midThreshold = 50,
    highThreshold = 75,
    variant,
  }) => {
    const percentageCompleted =
      thresholdValue && thresholdValue > 0
        ? Math.round((currentValue / thresholdValue) * 100)
        : null;

    const status = useMemo(() => {
      if (conditionalFormatting === false) return "";

      if (percentageCompleted === null) return "";

      if (!reverseFormatting) {
        if (percentageCompleted < lowThreshold) return "error";
        if (percentageCompleted < midThreshold) return "warning";
        if (percentageCompleted < highThreshold) return "success";
        if (percentageCompleted >= highThreshold) return "success";
      } else {
        if (percentageCompleted < lowThreshold) return "success";
        if (percentageCompleted < midThreshold) return "warning";
        if (percentageCompleted < highThreshold) return "error";
        if (percentageCompleted >= highThreshold) return "error";
      }
    }, [
      percentageCompleted,
      conditionalFormatting,
      reverseFormatting,
      lowThreshold,
      midThreshold,
      highThreshold,
    ]);

    return percentageCompleted !== null ? (
      <StatusText
        variant={variant ?? "body2"}
        align="right"
        paragraph={false}
        status={status}
      >
        {percentageCompleted}%
      </StatusText>
    ) : (
      <StatusText variant={variant ?? "body2"} align="right" paragraph={false}>
        -
      </StatusText>
    );
  }
);
