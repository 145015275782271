import React, { ReactChild, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./footer/footer";
import { Grid } from "@material-ui/core";
import SimpleToolbar from "./toolbars/simpleToolbar";
import Toolbar from "@components/toolbars/fullToolbar/fullToolbar";
import { hasOnlyDemoStores } from "~/utils/marketplaceUtils";
import { logout } from "@store/user.redux";
import { pathNavMap } from "@utils/navigationUtils";
import { setDestinationRoute } from "../store/globalVar.redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const RootContainer = styled.div`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: ${({ theme }) =>
    theme?.palette?.appBackgroundColor
      ? theme?.palette?.appBackgroundColor
      : theme?.palette?.backgroundColor
      ? theme.palette.backgroundColor
      : theme.palette.background.default};
  & .Mui-checked svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface AppFrameProps {
  footerHeight?: string;
}

const AppFrame = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: calc(
    100vh - ${({ footerHeight }: AppFrameProps) => footerHeight}px
  );
`;

const AppContent = styled(Grid)`
  position: relative;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`;

interface WrapperProps {
  fullNav?: boolean;
  children: ReactChild;
}

const Wrapper = memo(({ fullNav, children }: WrapperProps) => {
  const user = useTypedSelector((state) => state.user);
  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores
  );
  const location = useLocation();

  const dispatch = useDispatch();

  const logoutAndClearDestination = () => {
    dispatch(setDestinationRoute("/"));
    dispatch(logout());
  };

  const footer = document.getElementById("app-footer");
  const footerHeight = Boolean(footer) ? footer?.offsetHeight : "51";

  if (!user) {
    dispatch(logout());
    return null;
  }
  const displaySideNav = ["marketplaces", "reports", "notifications"].includes(
    pathNavMap(location.pathname)
  );

  const toolbarProps = {
    displaySideNav,
    isNewUser: hasOnlyDemoStores(allStores) && !user?.isDemoMode,
    content: children,
    logout: logoutAndClearDestination,
  };

  return (
    <RootContainer>
      <AppFrame footerHeight={`${footerHeight}`}>
        {fullNav ? <Toolbar {...toolbarProps} /> : <SimpleToolbar />}
        {!displaySideNav && (
          <AppContent
            container
            item
            alignItems="flex-start"
            justifyContent="center"
          >
            {children}
          </AppContent>
        )}
      </AppFrame>
      <Footer user={user} />
    </RootContainer>
  );
});

export default Wrapper;
