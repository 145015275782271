import { Grid, makeStyles } from "@material-ui/core";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import GoogleSignInButton from "~/modules/login/googleSignInButton";
import PropTypes from "prop-types";
import React from "react";
import WeChatLogin from "~/modules/login/wechatLogin";
import { default as classNames } from "clsx";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  noTextTransform: {
    textTransform: "none",
  },
}));

// A panel containing multiple 3rd party authentication providers e.g.
// Google, eBay, QQ etc.
export function LoginProviders(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { onSuccess, variant = "signin", onError } = props;
  const url = new URL(location.href);
  const domain = url.hostname;

  return (
    <>
      <Grid container spacing={2}>
        {
          // "Sign-in with Google" button should only be displayed on
          // mm.merchantspring.io (and not on custom domains)
          EVENT_ORIGIN_PATTERN.test(domain) && (
            <Grid item xs={12}>
              <GoogleSignInButton
                onSuccess={onSuccess}
                onError={onError}
              ></GoogleSignInButton>
            </Grid>
          )
        }
        {i18n.language && i18n.language.startsWith("zh") && (
          <Grid item xs={12}>
            <WeChatLogin
              className={classNames(classes.button, classes.noTextTransform)}
              onSuccess={() => onSuccess()}
              onError={() => onError()}
            >
              {t(`${variant}.withWeChat`)}
            </WeChatLogin>
          </Grid>
        )}
      </Grid>
    </>
  );
}

LoginProviders.defaultProps = {
  variant: "signin",
};

LoginProviders.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["signin", "signup"]),
};
