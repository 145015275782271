import React from "react";
import { SvgIcon } from "@material-ui/core";

const DownloadCsvIcon = (props: any) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <rect
      width="24"
      height="24"
      stroke="none"
      fill="currentColor"
      opacity="0"
    />
    <g transform="matrix(0.91 0 0 0.91 12 12)">
      <path
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinecap="round"
        strokeDashoffset={0}
        strokeLinejoin="miter"
        strokeMiterlimit={4}
        fill="currentColor"
        fillRule="nonzero"
        opacity={1}
        transform=" translate(-13, -13)"
        d="M 7 2 C 5.895 2 5 2.895 5 4 L 5 9 L 4 9 C 2.895 9 2 9.895 2 11 L 2 16 C 2 17.105 2.895 18 4 18 L 5 18 L 5 20 C 5 21.105 5.895 22 7 22 L 15.171875 22 L 13.171875 20 L 7 20 L 7 18 L 17 18 L 17 16 C 17 14.895 17.895 14 19 14 L 21 14 L 21 7 L 16 2 L 7 2 z M 7 4 L 15 4 L 15 8 L 19 8 L 19 9 L 7 9 L 7 4 z M 6 11 C 7.105 11 8 11.895 8 13 L 7 13 C 7 12.449 6.551 12 6 12 C 5.449 12 5 12.449 5 13 L 5 14 C 5 14.551 5.449 15 6 15 C 6.551 15 7 14.551 7 14 L 8 14 C 8 15.105 7.105 16 6 16 C 4.895 16 4 15.105 4 14 L 4 13 C 4 11.895 4.895 11 6 11 z M 10.644531 11 C 12.067531 11.041 12.154297 12.282906 12.154297 12.503906 L 11.1875 12.503906 C 11.1875 12.400906 11.204906 11.806641 10.628906 11.806641 C 10.453906 11.806641 10.059844 11.884188 10.089844 12.367188 C 10.118844 12.810188 10.703547 13.019406 10.810547 13.066406 C 11.034547 13.148406 12.141391 13.642391 12.150391 14.650391 C 12.152391 14.864391 12.097062 15.985 10.664062 16 C 9.1050625 16.017 9 14.675438 9 14.398438 L 9.9746094 14.398438 C 9.9746094 14.545438 9.9870625 15.256172 10.664062 15.201172 C 11.071063 15.167172 11.159828 14.87425 11.173828 14.65625 C 11.196828 14.28925 10.846563 14.068625 10.476562 13.890625 C 9.9565625 13.640625 9.1341406 13.333375 9.1191406 12.359375 C 9.1061406 11.482375 9.7505312 10.975 10.644531 11 z M 13 11 L 14.052734 11 L 14.992188 14.646484 L 15.9375 11 L 17 11 L 15.646484 16 L 14.345703 16 L 13 11 z M 19 16 L 19 20 L 16 20 L 20 24 L 24 20 L 21 20 L 21 16 L 19 16 z"
      />
    </g>
  </SvgIcon>
);

export default DownloadCsvIcon;
