import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";

import React from "react";
import VendorOverview from "~/modules/widgets/overview/vendorOverview";
import get from "lodash/get";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const VendorOverviewPage = memo(function VendorOverviewPage() {
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );

  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );

  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const PAGE_SIZE = 5;

  return (
    <VendorOverview
      {...{
        currentCurrency: currentCurrency,
        currentPeriod: currentPeriod,
        currentRange: currentRange,
        pageSize: PAGE_SIZE,
        includeTax: includeTax,
        currentFilter: currentFilter,
        filteredStores: filteredStores,
        conditionalFormatting: true,
        report: false,
      }}
    />
  );
});

export default VendorOverviewPage;
