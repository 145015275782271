import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

// The URL is based on the country code, so we need to create URL according to the specified country.
// These URLs are also hardcoded in server/src/routes/auth/storeConnectionRouters/allegroAuthRouter.ts
const createUrlForCountry = (countryCode: string): string => {
  switch (countryCode) {
    case "POL":
      return "https://allegro.pl";
    case "SVK":
      return "https://allegro.sk";
    case "CZE":
      return "https://allegro.cz";
    default:
      throw new Error("Invalid country code");
  }
};

const generateAuthUrl = (countryCode: string): string => {
  const getUrl = createUrlForCountry(countryCode);
  const authUrl = `${getUrl}/auth/oauth/authorize`;
  const client_id = process.env.ALLEGRO_CLIENT_ID;
  const redirect_uri =
    process.env.ALLEGRO_REDIRECT_URI ||
    "https://mm.merchantspring.io/callback/allegro";

  if (!client_id) {
    throw new Error("Error authorising Allegro connection");
  }

  const url = new URL(authUrl);
  const urlParams = new URLSearchParams({
    response_type: "code",
    client_id,
    redirect_uri,
    scope:
      "allegro:api:profile:read allegro:api:orders:read allegro:api:sale:offers:read allegro:api:fulfillment:read",
  });
  url.search = urlParams.toString();
  return url.toString();
};

export default function (
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "allegro"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.error) {
          onError(params.error);
          return;
        }

        if (params.code) {
          onCode(params.code);
          return;
        }
        onError("Invalid response from Allegro");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = (countryCode: string) => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateAuthUrl(countryCode),
        "authPopup-mm-allegro",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
