const _ = require("lodash");
const { inBrowser } = require("../../utils");

const requiredEnvVars = [
  "AMAZON_CLIENT_ID",
  "AMAZON_CLIENT_SECRET",
  "AMAZON_REDIRECT_URI",
  "AMAZON_APP_ID",
  "AMAZON_ADVERTISING_CLIENT_ID",
];

// Only check for environment variables if we're not running in the browser
if (!inBrowser()) {
  for (let e of requiredEnvVars) {
    if (!process.env[e])
      console.log(`Warning - missing required environment variable ${e}`);
  }
}

// A map of ISO 3166-alpha3 country codes to Amazon MWS endpoints and marketplace
// IDs
const amazonSites = {
  BRA: {
    retailHost: "amazon.com.br",
    endpoint: "mws.amazonservices.com",
    marketplaceId: "A2Q3Y263D00KWC",
  },
  CAN: {
    retailHost: "amazon.ca",
    endpoint: "mws.amazonservices.ca",
    marketplaceId: "A2EUQ1WTGCTBG2",
  },
  MEX: {
    retailHost: "amazon.com.mx",
    endpoint: "mws.amazonservices.com.mx",
    marketplaceId: "A1AM78C64UM0Y8",
  },
  USA: {
    retailHost: "amazon.com",
    endpoint: "mws.amazonservices.com",
    marketplaceId: "ATVPDKIKX0DER",
  },
  ARE: {
    retailHost: "amazon.ae",
    endpoint: "mws.amazonservices.ae",
    marketplaceId: "A2VIGQ35RCS4UG",
  },
  DEU: {
    retailHost: "amazon.de",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A1PA6795UKMFR9",
  },
  // default to amazon.com for countries that don't appear to have their own site
  EGY: {
    retailHost: "amazon.com",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "ARBP9OOSHTCHU",
  },
  ESP: {
    retailHost: "amazon.es",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A1RKKUPIHCS9HS",
  },
  FRA: {
    retailHost: "amazon.fr",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A13V1IB3VIYZZH",
  },
  GBR: {
    retailHost: "amazon.co.uk",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A1F83G8C2ARO7P",
  },
  IND: {
    retailHost: "amazon.in",
    endpoint: "mws.amazonservices.in",
    marketplaceId: "A21TJRUUN4KGV",
  },
  ITA: {
    retailHost: "amazon.it",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "APJ6JRA9NG5V4",
  },
  NLD: {
    retailHost: "amazon.nl",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A1805IZSGTT6HS",
  },
  POL: {
    retailHost: "amazon.pl",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A1C3SOZRARQ6R3",
    amazonSellerId: "A2R2221NX79QZP",
  },
  SAU: {
    retailHost: "amazon.sa",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A17E79C6D8DWNP",
  },
  SWE: {
    retailHost: "amazon.se",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A2NODRKZP88ZB9",
    amazonSellerId: "ANU9KP01APNAG",
  },
  TUR: {
    retailHost: "amazon.com.tr",
    endpoint: "mws-eu.amazonservices.com",
    marketplaceId: "A33AVAJ2PDY3EV",
  },
  SGP: {
    retailHost: "amazon.sg",
    endpoint: "mws-fe.amazonservices.com",
    marketplaceId: "A19VAU5U5O7RUS",
  },
  AUS: {
    retailHost: "amazon.com.au",
    endpoint: "mws.amazonservices.com.au",
    marketplaceId: "A39IBJ37TRP1C6",
  },
  JPN: {
    retailHost: "amazon.co.jp",
    endpoint: "mws.amazonservices.jp",
    marketplaceId: "A1VC38T7YXB528",
  },
};

// A map of Amazon regions to country codes
const regionalCountryCodes = {
  // North America
  NA: ["BRA", "CAN", "MEX", "USA"],
  // Europe
  EU: [
    "ARE",
    "DEU",
    "EGY",
    "ESP",
    "FRA",
    "GBR",
    "IND",
    "ITA",
    "NLD",
    "POL",
    "SAU",
    "SWE",
    "TUR",
  ],
  // Asia Pacific, or "Far East"
  AP: ["SGP", "AUS", "JPN"],
};

const amazonKey = {
  clientId: process.env["AMAZON_CLIENT_ID"],
  clientSecret: process.env["AMAZON_CLIENT_SECRET"],
  redirectUri: process.env["AMAZON_REDIRECT_URI"],
  appId: process.env["AMAZON_APP_ID"],
  advertisingClientId: process.env["AMAZON_ADVERTISING_CLIENT_ID"],
  advertisingClientSecret: process.env["AMAZON_ADVERTISING_CLIENT_SECRET"],
};

class amws {
  //Given an ISO 3166-alpha3 country code, return the Amazon Marketplace Web
  //Service endpoint and marketplace ID corresponding to that country
  static marketplace(countryCode) {
    const site = amazonSites[countryCode];
    if (site == null)
      throw new Error(`No Amazon site corresponding to country ${countryCode}`);
    return site;
  }

  // Given a marketplace ID, return the ISO 3166-alpha3 country code and
  // Amazon endpoints associated with that marketplace
  static marketplaceById(marketplaceId) {
    // use findKey instead of find so we can return the country code too
    const countryCode = _.findKey(
      amazonSites,
      (value) => value.marketplaceId === marketplaceId
    );
    const site = amazonSites[countryCode];
    if (countryCode == null) {
      throw new Error(
        `No Amazon site corresponding to marketplace ID ${marketplaceId}`
      );
    }
    return {
      country: countryCode,
      ...site,
    };
  }

  // Given a country code, return the set of developer credentials associated with
  // that country in the form
  // {
  //      accessKeyId: <string>,
  //      secretAccessKey: <string>
  // }
  //
  // Necessary because Amazon has regions for its marketplaces, and requires a
  // separate developer account for each region
  static credentials(countryCode) {
    throw new Error(
      `No Amazon marketplace region corresponding to country ${countryCode}`
    );
  }
}

module.exports = {
  amazonKey,
  amws,
  regionalCountryCodes,
};
