import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";

export const FETCH_PASSWORD_STRENGTH_VALIDATION_REQUEST =
  "FETCH_PASSWORD_STRENGTH_VALIDATION_REQUEST";
export const FETCH_PASSWORD_STRENGTH_VALIDATION_SUCCESS =
  "FETCH_PASSWORD_STRENGTH_VALIDATION_SUCCESS";
export const FETCH_PASSWORD_STRENGTH_VALIDATION_ERROR =
  "FETCH_PASSWORD_STRENGTH_VALIDATION_ERROR";
export const CLEAR_PASSWORD_STRENGTH_VALIDATION =
  "CLEAR_PASSWORD_STRENGTH_VALIDATION";

axios.defaults.withCredentials = true;

const initState = {};

export const password = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PASSWORD_STRENGTH_VALIDATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case FETCH_PASSWORD_STRENGTH_VALIDATION_SUCCESS:
    case FETCH_PASSWORD_STRENGTH_VALIDATION_ERROR:
      return {
        ...state,
        isLoading: false,
        validationData: action.payload.validations,
        message: action.payload.message,
      };
    case CLEAR_PASSWORD_STRENGTH_VALIDATION:
      return {
        ...state,
        isLoading: false,
        validationData: [],
        message: null,
      };
    default:
      return state;
  }
};

export const passwordStrengthValidation = (newPwd) => async (dispatch) => {
  dispatch({ type: FETCH_PASSWORD_STRENGTH_VALIDATION_REQUEST });

  try {
    const { data, status } = await axios.post(`${baseUrl}/user/validate`, {
      password: newPwd,
    });

    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: FETCH_PASSWORD_STRENGTH_VALIDATION_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    return dispatch({
      type: FETCH_PASSWORD_STRENGTH_VALIDATION_ERROR,
      payload: get(err, "response.data"),
    });
  }

  return dispatch({
    type: FETCH_PASSWORD_STRENGTH_VALIDATION_ERROR,
  });
};

export const clearPasswordStrengthValidation = () => (dispatch) =>
  dispatch({
    type: CLEAR_PASSWORD_STRENGTH_VALIDATION,
  });
