import { ProfitAndLossColumn } from "../profitAndLossTable";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseProfitAndLossColumnsProps {
  isComparison?: boolean;
  currentCurrency: string;
}

export const useProfitAndLossColumns = ({
  isComparison = false,
  currentCurrency,
}: UseProfitAndLossColumnsProps): ProfitAndLossColumn[] => {
  const { t } = useTranslation();

  const columns = useMemo((): ProfitAndLossColumn[] => {
    return [
      {
        header: isComparison
          ? t(`profitability.comparisonPeriodLabel`)
          : t(`profitability.currentPeriodLabel`),
        key: "key",
        width: "35%",
        startAlign: true,
        bold: true,
        uppercase: true,
      },
      {
        header:
          getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol],
        key: "value",
        width: "25%",
        uppercase: true,
      },
      {
        header: t(`profitability.percentIncomeLabel`),
        key: "incomePercent",
        uppercase: true,
        tooltip: t(`profitability.percentIncomeTooltip`),
        width: "20%",
      },
      {
        header: t("profitability.perUnitLabel", {
          currencySymbol:
            getCurrencySymbol[
              currentCurrency as keyof typeof getCurrencySymbol
            ],
        }),
        key: "valuePerUnit",
        uppercase: true,
        tooltip: t(`profitability.perUnitTooltip`),
        width: "20%",
      },
    ];
  }, [isComparison, currentCurrency, t]);

  return columns;
};
