import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { FEATURE_LIST, PlanPropTypes } from "./plan";

import { CheckCircle } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const IncludedIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.palette.success.main};
  font-size: 1rem;
`;

const FeatureIcon = styled(ListItemIcon)`
  min-width: 32px;
  display: flex;
  justify-content: flex-start;
`;

const FeatureItem = (props) => {
  const { text, on } = props;
  if (on) {
    return (
      <ListItem dense disableGutters alignItems="flex-start">
        <FeatureIcon>
          <IncludedIcon fontSize="small" />
        </FeatureIcon>
        <ListItemText disableTypography>
          <Typography variant="body1">{text}</Typography>
        </ListItemText>
      </ListItem>
    );
  }
  return null;
};
FeatureItem.defaultProps = {
  on: true,
};
FeatureItem.propTypes = {
  text: PropTypes.string.isRequired,
  on: PropTypes.bool,
};

const PlanFeatures = (props) => {
  const { t } = useTranslation();
  const { plan } = props;
  return (
    <Box pt={0.5}>
      <List>
        <FeatureItem
          on={true}
          text={t("subscription.feature.marketplaceConnections", {
            numConnections: plan.entitlements.marketplaces,
          })}
        />
        {Object.entries(FEATURE_LIST).map(([featureKey, featureText]) => (
          <FeatureItem
            key={featureKey}
            featureKey={featureKey}
            text={t(featureText)}
            on={plan.entitlements.features.includes(featureKey)}
          />
        ))}
      </List>
    </Box>
  );
};
PlanFeatures.propTypes = {
  plan: PlanPropTypes.isRequired,
};

export default PlanFeatures;
