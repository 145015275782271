import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import ProfitAndLoss from "~/modules/widgets/profitability/profitAndLoss";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitAndLossStatement = memo(
  ({
    title,
    groupByMonth = false,
    showComparison,
    isComparison,
    condensed,
    report,
  }: {
    title: string;
    groupByMonth?: boolean;
    showComparison?: boolean;
    isComparison?: boolean;
    condensed?: boolean;
    report?: boolean;
  }) => {
    const { t } = useTranslation();
    const currentStore = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const marketplace = useMarketplace();
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    return (
      <ProfitAndLoss
        title={title}
        currentCurrency={currentCurrency}
        store={currentStore}
        currentRange={currentRange}
        includeTax={includeTax}
        groupByMonth={groupByMonth}
        showComparison={showComparison}
        isComparison={isComparison}
        condensed={condensed}
        report={report}
        footerLink={
          condensed && currentStore
            ? {
                url: marketplaceLink(
                  marketplace,
                  currentStore.merchantId,
                  "profitabilityStore"
                ),
                label: t("generic.viewAllLink"),
              }
            : undefined
        }
      />
    );
  }
);

export default ProfitAndLossStatement;
