import React, { memo } from "react";

import { Box } from "@material-ui/core";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import barMonth from "../../img/noData/bar-28.png";
import barToday from "../../img/noData/bar-24h.png";
import barWTD from "../../img/noData/bar-7.png";
import styled from "styled-components";

const getImageForPeriod = (currentPeriod: DATETIME_PERIODS) => {
  switch (currentPeriod) {
    case DATETIME_PERIODS.DAY:
      return barToday;
    case DATETIME_PERIODS.WEEK:
      return barWTD;
    case DATETIME_PERIODS.LAST30 || DATETIME_PERIODS.YEAR:
      return barMonth;
    default:
      return barToday;
  }
};

const Image = styled.img`
  width: 100%;
`;

const BarChartNoData = memo<{ currentPeriod: DATETIME_PERIODS }>(
  function BarChartNoData({ currentPeriod }) {
    return (
      <Box p={2} display="flex" alignItems="center" justifyContent="center">
        <Image src={getImageForPeriod(currentPeriod)} />
      </Box>
    );
  }
);

export default BarChartNoData;
