/* eslint-disable no-magic-numbers */
import {
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import PasswordHint from "~/components/passwordHint/passwordHint";
import { isPasswordValid } from "@merchantspring/common";
import { passwordStrengthValidation } from "../../store/password.redux";
import { resetPwd } from "~/store/user.redux";
import styled from "styled-components";
import useDebounce from "../../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import SimpleToolbar from "~/components/toolbars/simpleToolbar";

const Root = styled(Grid)`
  width: 100%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
`;

const Frame = styled(Grid)`
  margin-top: 150px;
`;

const IconFrame = styled.div`
  width: 300px;
  height: 45px;
  background-image: ${({ icon }) => `url(${icon})`};
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const TabsFrame = styled.div`
  width: 350px;
  height: 35rem;
  margin: 0 auto;
  background-color: transparent;
`;

const ActiveTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.divider};
  font-size: 16px;
  font-weight: 500;
  border-bottom: ${({ theme }) => `5px solid ${theme.palette.divider}`};
  text-transform: none;
`;

const DividerWrapper = styled(Divider)`
  height: 1px;
  margin-top: -2px;
  margin-bottom: -2px;
  background-color: ${({ theme }) => theme.palette.border.main};
`;

const LoginFrame = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const TextFrame = styled(TextField)`
  margin-top: 25px;
  width: 21rem;
`;

const BtnFrame = styled.div`
  height: 2.95rem;
  margin-top: 25px;
`;

const Btn = styled(Button)`
  width: 50%;
  color: ${({ theme }) => theme.palette.secondary.main};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  font-weight: 500;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`;

const NormalMessage = styled.div`
  color: ${({ theme }) => theme.palette.success.main};
`;

const PasswordHintContainer = styled.div`
  margin-top: 25px;
  width: 100%;
`;

const ResetPwdPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const passwordStore = useSelector((state) => state.password);
  const validationData = get(passwordStore, "validationData");
  const isPassValidated =
    !isEmpty(validationData) && validationData.every((val) => val[1] === true);
  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [normalMsg, setNormalMsg] = useState("");
  const [isReseted, setIsReseted] = useState(false);
  const DEBOUNCE_DELAY_MSEC = 1000;
  const debouncedSetPassword = useDebounce(password, DEBOUNCE_DELAY_MSEC);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password || !isPasswordValid(password)) {
      setErrMsg(t("register.invalidPasswordError"));
      return;
    }

    if (password !== passwordRetype) {
      setErrMsg(t("resetPassword.mismatchedPasswordError"));
      return;
    }

    if (!props.match.params.token) {
      setErrMsg(t("resetPassword.invalidTokenError"));
      return;
    }

    const infoNeedToUpdate = {};
    infoNeedToUpdate.password = password;
    infoNeedToUpdate.token = props.match.params.token;
    dispatch(resetPwd({ userInfo: infoNeedToUpdate }))
      .then((res) => {
        setErrMsg("");
        setNormalMsg(t("resetPassword.successMessage"));
        setIsReseted(true);
      })
      .catch((error) => {
        setErrMsg(t("resetPassword.generalError"));
      });
  };

  useEffect(() => {
    if (debouncedSetPassword) {
      dispatch(passwordStrengthValidation(password));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSetPassword, password]);

  return (
    <Root theme={theme} container alignItems="center" justifyContent="center">
      <Frame item xs={12}>
        <TabsFrame>
          <SimpleToolbar fullWidth />
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <ActiveTab
              theme={theme}
              label={t("resetPassword.resetPasswordTitle")}
            />
          </Tabs>
          <DividerWrapper theme={theme} fullWidth />
          <LoginFrame theme={theme}>
            <form method="POST" onSubmit={(e) => handleSubmit(e)}>
              <TextFrame
                theme={theme}
                name="password"
                type="password"
                label={t("resetPassword.newPasswordLabel")}
                onChange={(e) => setPassword(e.target.value)}
                onClick={() => dispatch(passwordStrengthValidation(password))}
              />
              <br />
              <TextFrame
                theme={theme}
                name="passwordRetype"
                type="password"
                label={t("resetPassword.retypePasswordLabel")}
                onChange={(e) => setPasswordRetype(e.target.value)}
              />

              {!isEmpty(validationData) && (
                <PasswordHintContainer>
                  {validationData.map((message, index) =>
                    !isEmpty(message) ? (
                      <PasswordHint message={message} key={index} />
                    ) : null
                  )}
                </PasswordHintContainer>
              )}

              <br />
              {isReseted ? (
                <BtnFrame>
                  <Btn
                    theme={theme}
                    variant="outlined"
                    color="primary"
                    onClick={() => props.history.push("/")}
                  >
                    {t("resetPassword.loginButton")}
                  </Btn>
                </BtnFrame>
              ) : (
                <BtnFrame>
                  <Btn
                    theme={theme}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!isPassValidated}
                  >
                    {t("resetPassword.submitButton")}
                  </Btn>
                </BtnFrame>
              )}
              <br />
              {errMsg && <ErrorMessage theme={theme}>{errMsg}</ErrorMessage>}
              {normalMsg && (
                <NormalMessage theme={theme}>{normalMsg}</NormalMessage>
              )}
            </form>
          </LoginFrame>
        </TabsFrame>
      </Frame>
    </Root>
  );
};

export default ResetPwdPage;
