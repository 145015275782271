import { Connections, CurrentStore, Store } from "~/typedef/store";
import {
  marketplaceOnMultipleRegions,
  otherMarketplaces,
} from "../pages/connectWizard/marketplaces";

import { getCountryCodeISO3ToName } from "./countryUtils";

const FILTERED_SUFFIX = "_filtered";

export const stripFilteredSuffix = (
  marketplaceType: string | null
): string | null => marketplaceType?.replace(FILTERED_SUFFIX, "") ?? null;

export const hasFilteredSuffix = (marketplaceType: string | null): boolean =>
  marketplaceType?.endsWith(FILTERED_SUFFIX) ?? false;

export const getParentMarketplace = (marketplace: string): string | null => {
  if (!marketplace) {
    return null;
  }

  const unfilteredMarketplaceType = stripFilteredSuffix(marketplace);

  if (
    // Keep this updated with new Marketplaces as we add them.
    // Type of this list exists in client/src/typedef/marketplace.d.ts
    [
      "amazon",
      "advertising",
      "amazon_vendor",
      "bol",
      "cdiscount",
      "criteo",
      "dsp",
      "ebay",
      "google",
      "iconic",
      "kaufland",
      "kogan",
      "manomano",
      "mercadolibre",
      "mirakl",
      "mydeal",
      "otto",
      "pinkConnect",
      "shopify",
      "trademe",
      "walmart",
      "woocommerce",
      "zalando",
      "zalando_all",
      "tiktok",
    ].includes(unfilteredMarketplaceType as string)
  ) {
    return marketplace;
  }

  const supportedMarketplace = otherMarketplaces.find(
    (o) => o.marketplace === marketplace
  );
  if (supportedMarketplace) {
    return supportedMarketplace.parentMarketplace;
  }

  // Some marketplaces span multiple countries - marketplaces of this type
  // have the form <marketplace name><ISO3166-alpha3> e.g. carrefourTWN
  // for a Carrefour marketplace in Taiwan.
  const multiCountryMarketplace = marketplaceOnMultipleRegions.some((r) =>
    marketplace.startsWith(r)
  );
  if (multiCountryMarketplace) {
    // Find the version of the marketplace for the particular country we're interested in
    const localMarketplace = otherMarketplaces.find(
      (o) =>
        o.country ===
        getCountryCodeISO3ToName(
          marketplace.slice(marketplace.length - 3, marketplace.length)
        )
    );
    if (localMarketplace) {
      return localMarketplace.parentMarketplace;
    }
  }
  return null;
};

export const hasDemoStore = (filteredStores: Store[]): boolean => {
  if (filteredStores) {
    return filteredStores.some((store) => store.isDemoMode);
  }
  return false;
};

/** Iff the user has no stores connected, or only demo stores connected, return true.
 */
export const hasOnlyDemoStores = (stores: Store[]): boolean => {
  if (stores && stores.length) {
    return stores.every((store) => store.isDemoMode);
  }
  return true;
};

/** If the user has any advertising stores in this array, return true.
 */
export const hasAnyAdvertisingStores = (
  stores: Store[] | CurrentStore[]
): boolean => {
  if (stores && stores.length) {
    return stores.some((store) => {
      const marketplaceType =
        "marketplaceType" in store ? store.marketplaceType : store.marketplace;
      return (
        stripFilteredSuffix(marketplaceType) === "amazon" ||
        marketplaceType === "walmart" ||
        stripFilteredSuffix(marketplaceType) === "amazon_vendor" ||
        stripFilteredSuffix(marketplaceType) === "mercadolibre" ||
        stripFilteredSuffix(marketplaceType) === "bol" ||
        stripFilteredSuffix(store.marketplaceSubtype ?? null) === "advertising"
      );
    });
  }
  return false;
};

/** If the user has any amazon stores in this array, return true.
 */
export const hasAnyAmazonStores = (stores: Store[]): boolean => {
  if (stores && stores.length) {
    return stores.some(
      (store) =>
        stripFilteredSuffix(store.marketplaceType) === "amazon" &&
        store.marketplaceSubtype !== "advertising"
    );
  }
  return false;
};

/** If the user has any walmart stores in this array, return true.
 */
export const hasAnyWalmartStores = (stores: Store[]): boolean => {
  if (stores && stores.length) {
    return stores.some(
      (store) =>
        stripFilteredSuffix(store.marketplaceType) === "walmart" &&
        store.marketplaceSubtype !== "advertising"
    );
  }
  return false;
};

/** If the user has any amazon vendor stores in this array, return true.
 */
export const hasAnyAmazonVendorStores = (stores: Store[]): boolean => {
  if (stores && stores.length) {
    return stores.some(
      (store) =>
        stripFilteredSuffix(store.marketplaceType) === "amazon_vendor" &&
        store.marketplaceSubtype !== "advertising"
    );
  }
  return false;
};

/** If the user has only vendor stores in this array, return true.
 */
export const hasOnlyVendorStores = (stores: Store[]): boolean => {
  if (stores && stores.length) {
    return stores.every(
      (store) => stripFilteredSuffix(store.marketplaceType) === "amazon_vendor"
    );
  }
  return false;
};

export const marketplaceLink = (
  market: string,
  mid: string,
  page = "storeoverview",
  params = {},
  // if present, the user has been restricted to a simplified navigation.
  // this means that we should disable certain drilldowns
  allowedLinks: string[] = []
): { pathname: string; search: string } | string => {
  if (allowedLinks.length && !allowedLinks.includes(page)) {
    return "";
  }
  return {
    pathname: `/mystores/${getParentMarketplace(market)}/${page}`,
    search: "?" + new URLSearchParams({ store: mid, ...params }).toString(),
  };
};

export const getMatchingStore = (
  filteredStores: Store[],
  market: string,
  mid: string
): Store | undefined => {
  if (!filteredStores || !Array.isArray(filteredStores)) {
    return undefined;
  }

  return filteredStores.find(
    (s) =>
      (s.marketplaceSubtype === market || s.marketplaceType === market) &&
      s.merchantId === mid
  );
};

export const getShopName = (
  filteredStores: Store[],
  market: string,
  mid: string
): string => {
  const matchingStore = getMatchingStore(filteredStores, market, mid);

  if (!matchingStore) {
    return mid;
  }

  return matchingStore.storeName;
};

export const connectionStatus = {
  CONNECTION_INIT: "CONNECTION_INIT",
  CONNECTION_INPROGRESS: "CONNECTION_INPROGRESS",
  CONNECTION_FAILED: "CONNECTION_FAILED",
  CONNECTION_SUCCEED: "CONNECTION_SUCCEED",
  LOADINFO_FAILED: "LOADINFO_FAILED",
};

export const getConnectionStatus = (
  disconnectedStores: Connections["disconnectedStores"]["stores"],
  marketplace: string,
  mid: string
): { shopStatus: boolean; expiry: "N/A" } => {
  const matchingStore = disconnectedStores?.find(
    (s) =>
      (s.marketplaceType === marketplace ||
        s.marketplaceSubtype === marketplace) &&
      s.mid === mid &&
      Array.isArray(s.credentials) &&
      s.credentials.some((c) =>
        marketplace === "advertising"
          ? c.type === "advertising"
          : c.type === "primary"
      )
  );

  return {
    shopStatus: !Boolean(matchingStore),
    expiry: "N/A",
  };
};

export const getSyncStatus = (
  connections: Connections["connectionStatus"]["connections"],
  mid: string
): Object | false | undefined => {
  if (connections && mid) {
    return connections.find(
      (storeConnection) =>
        storeConnection.mid === mid && storeConnection.percentage < 100
    );
  }
  return false;
};

/**
 * Converts filteredStores to a string of marketplace types and countries
 * to be sent to Intercom
 * @param {Store[]} filteredStores - Should be filteredStores from redux
 * @returns {string} - String consisting of marketplaceType_marketplaceCountry
 * separated by commas e.g. "ebay_AUS, amazon_USA, ..."
 */
export const marketplaceTypesAndCountriesString = (
  filteredStores: Store[]
): string => {
  const marketplaceTypesAndCountriesList = filteredStores.map(
    (fs) => `${fs.marketplaceType}_${fs.marketplaceCountry}`
  );
  const unique = Array.from(new Set(marketplaceTypesAndCountriesList).values());
  return unique.join(", ");
};

export const CROSS_COUNTRY_MARKETPLACES: Record<string, string> = {
  zalando_all: "EUR",
};

export const isFilteredStoreSupported = (marketplace: string): boolean => {
  const supportedMarketplaces = [
    "amazon",
    "amazon_vendor",
    "bol",
    "ebay",
    "mercadolibre",
    "mirakl",
    "walmart",
    "zalando",
  ];
  return marketplace ? supportedMarketplaces.includes(marketplace) : false;
};
