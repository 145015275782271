import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../configs";
import { clearStoreAction } from "@store/utils/globalActions";
import { globalThunkErrorHandler } from "@store/utils/errorHandlerUtils";
import { isHttpResponseValid } from "@store/utils/httpsResponseCodes";

export enum WidgetId {
  ACCOUNT_HEALTH = "accountHealth",
  ADVERTISING_PERFORMANCE = "advertisingPerformance",
  AD_SPEND_AGAINST_BUDGET = "adSpendAgainstBudget",
  BUYBOX_PERFORMANCE = "buyboxPerformance",
  STORE_PROFITABILITY = "profitabilityStore",
  DISPATCH_STATUS = "dispatchStatus",
  ITEM_SPECIFICS = "itemSpecifics",
  SALES_AGAINST_BUDGET = "salesAgainstBudget",
  SALES_AGAINST_FORECAST = "salesAgainstForecast",
  SALES_BY_BRAND = "salesByBrand",
  SALES_BY_CATEGORY = "salesByCategory",
  SALES_BY_DAY = "salesByDay",
  SALES_PERFORMANCE = "salesPerformance",
  TOP_SELLERS = "topSellers",
  TOP_SELLERS_BSR = "topSellersBsr",
  TRAFFIC_AND_CONVERSION = "trafficandconversion",
  UNRESOLVED_NOTIFICATIONS = "unresolvedNotifications",
  WORST_SELLERS = "worstSellers",
}

export interface WidgetOption {
  position: number;
  id: WidgetId;
}

export interface CustomLayoutConfig {
  enabled: boolean;
  sideNav?: {
    // Which pages to include on the side nav
    // based on the page URL i.e. the 'link' attribute on menuItems in
    // marketoverview/client/src/components/toolbars/sideNavigation/sideNavConstants.tsx
    // Order doesn't matter
    pages: string[];
  };
  dashboard?: {
    // Which widgets to include on the store dashboard
    // based on the widget ID i.e. the 'id' attribute on widgets in
    // marketoverview/client/src/pages/multiChannel/overview.tsx
    // Order matters
    widgets: string[];
  };
  myStores?: {
    widgets: WidgetOption[];
  };
  storeConnections?: {
    // which marketplaces to display on the store connection screen
    displayedMarketplaces: string[]; // e.g. ['amazon', 'shopify', 'ebay'] order matters
    // whether to hide the search box for our extended list of marketplaces
    hideSearch: boolean;
  };
  advertisingConnections?: {
    // which marketplaces to display on the store connection screen
    displayedMarketplaces: string[]; // e.g. ['amazon', 'dsp', 'bol'] order matters
    // whether to hide the search box for our extended list of marketplaces
    hideSearch: boolean;
  };
}

export interface CustomLayoutState {
  fetching: boolean;
  layoutConfig?: CustomLayoutConfig;
}

export const fetchCustomLayoutConfig = createAsyncThunk(
  "customLayout/fetchCustomLayoutConfig",
  async (_, { dispatch }) => {
    try {
      const { status, data } = await axios.get(
        `${baseUrl}/api/user-management-service/api/customLayout`
      );

      const validHttpResponse = isHttpResponseValid(status);
      if (validHttpResponse && data) {
        return data as CustomLayoutConfig;
      } else if (validHttpResponse && data === null) {
        return { enabled: false } as CustomLayoutConfig;
      } else {
        globalThunkErrorHandler(dispatch, {
          status,
          validateHttpResponseStatus: false, // since we have already validated the status here
          data,
          location: "customLayout",
        });
        throw new Error(data.errMsg);
      }
    } catch (error) {
      globalThunkErrorHandler(dispatch, {
        error,
        location: "customLayout",
      });
      throw error;
    }
  }
);

const initialState: CustomLayoutState = {
  fetching: false,
  layoutConfig: { enabled: false },
};

export const customLayoutSlice = createSlice({
  name: "customLayout",
  initialState,
  reducers: {
    // any additional "normal" case reducers here.
    // these will generate new action creators
  },
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // outside of the slice, such as thunks or in other slices
    builder
      .addCase(clearStoreAction, () => {
        // Revert to initial state
        return initialState;
      })
      .addCase(fetchCustomLayoutConfig.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchCustomLayoutConfig.fulfilled, (state, action) => {
        state.fetching = false;
        state.layoutConfig = action.payload;
      })
      .addCase(fetchCustomLayoutConfig.rejected, (_state, action) => {
        // If we want to have the error stored in the slice state as well
        // state.error = action.error;
      });
  },
});

export default customLayoutSlice.reducer;
