import {
  CircularProgress,
  Grid,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";

import AdTable from "~/components/adTable/table";
import { AvailabilityCell } from "~/components/table/cells/availabilityCell";
import { CurrentStore } from "~/typedef/store";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import EditCell from "~/components/table/cells/editCell";
import { FinancialCategory } from "~/store/mystore/profitability.redux";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import PrimeIcon from "../../img/amazon_prime.png";
import ProductLinkCell from "./productLinkCell";
import ProductTableChart from "./productTableChart";
import { ReactTableColumn } from "~/components/table/column";
import StatusText from "~/components/typography/status";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueCell } from "~/components/table/cells/valueCell";
import get from "lodash/get";
import { getStatusFromProfit } from "../profitLossTable/categoryUtils";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export interface ProductEventResponse {
  rows: ProductEventRow[];
  count: number;
}

export interface ProductEventRow {
  productId: string;
  sellerSku: string;
  productSku: string;
  title: string;
  imageUrl: string;
  linkUrl: string;
  availableQuantity: number;
  daysCover: number;
  fulfilledBy: string;
  totalSales: number;
  totalRefunds: number;
  totalReimbursements: number;
  totalOtherIncome: number;
  totalPromotions: number;
  totalSellingFees: number;
  totalShippingFees: number;
  totalRefundsAndReturns: number;
  totalCogs: number;
  totalOtherExpenses: number;
  totalRevenue: number;
  totalExpenses: number;
  totalAdvertising: number;
  data: ProductEventRowData;
}

interface Meta {
  sellerSku: string;
  orderCount: number;
  unitCount: number;
  refundedUnitCount: number;
}

interface Event {
  label: string;
  value: number;
}

export interface ProductEventRowData {
  meta: Meta;
  events: Event[];
}

type HandleListingSelectFunc = (listing: any) => void;

interface ProductTableProps {
  store: CurrentStore;
  report?: boolean;
  categories: FinancialCategory[];
  loading: boolean;
  currency: string;
  data: ProductEventResponse;
  fetchData: (params: {
    pageIndex: number;
    pageSize: number;
    sortBy: { id: string; desc: boolean }[];
    dataVersion?: number;
  }) => void;
  pageSize: number;
  columns: ReactTableColumn<ProductEventRow>[];
  setColumns: (columns: any[]) => void;
  handleListingSelect: HandleListingSelectFunc;
  handleCogsEdit?: (product: ProductEventRow) => void;
}

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

export const generateColumns = (
  t: TFunction<"translation">,
  currencyFormatter: (value: number) => string,
  handleListingSelect: HandleListingSelectFunc,
  handleCogsEdit?: (product: ProductEventRow) => void,
  daysCoverLimit?: number,
  report?: boolean
) => [
  {
    Header: t(`profitability.column.product`),
    id: "title",
    accessor: (row: ProductEventRow) => ({
      value: row.title,
      secondRowValue: `ASIN: ${row.productSku} | SKU: ${row.sellerSku}`,
      image: row.imageUrl,
      link: row.linkUrl,
      target: "_blank",
      icon:
        row.fulfilledBy.toUpperCase() !== "MERCHANT" ? (
          <Tooltip title={t("generic.fulfilledByAmazon") as string}>
            <SmallIcon src={PrimeIcon} />
          </Tooltip>
        ) : null,
    }),
    Cell: (props: any) => (
      <LinkAndImageCell
        {...props}
        colorVariant="external"
        maxWidth={report ? "15rem" : "25rem"}
        expandable={!report}
      />
    ),
    isLocked: !report,
    isVisible: true,
    sticky: report ? "unset" : "left",
  },
  {
    Header: t("profitability.productSalesLabel"),
    id: "totalSales",
    accessor: ({ totalSales }: ProductEventRow) =>
      currencyFormatter(totalSales),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.refundedSalesLabel"),
    id: "totalRefunds",
    accessor: ({ totalRefunds }: ProductEventRow) =>
      currencyFormatter(totalRefunds),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.reimbursementsLabel"),
    id: "totalReimbursements",
    accessor: ({ totalReimbursements }: ProductEventRow) =>
      currencyFormatter(totalReimbursements),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.promotionsLabel"),
    id: "totalPromotions",
    accessor: ({ totalPromotions }: ProductEventRow) =>
      currencyFormatter(totalPromotions),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.otherIncomeLabel"),
    id: "totalOtherIncome",
    accessor: ({ totalOtherIncome }: ProductEventRow) =>
      currencyFormatter(totalOtherIncome),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  ...(report
    ? []
    : [
        {
          Header: t("profitability.advertisingLabel"),
          id: "totalAdvertising",
          accessor: ({ totalAdvertising }: ProductEventRow) =>
            currencyFormatter(totalAdvertising),
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          disableSortBy: true,
        },
      ]),
  {
    Header: t("profitability.sellingFeesLabel"),
    id: "totalSellingFees",
    accessor: ({ totalSellingFees }: ProductEventRow) =>
      currencyFormatter(totalSellingFees),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.fulfilmentAndShippingLabel"),
    id: "totalShippingFees",
    accessor: ({ totalShippingFees }: ProductEventRow) =>
      currencyFormatter(totalShippingFees),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.refundsAndReturnsLabel"),
    id: "totalRefundsAndReturns",
    accessor: ({ totalRefundsAndReturns }: ProductEventRow) =>
      currencyFormatter(totalRefundsAndReturns),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.costOfGoodsLabel"),
    id: "totalCogs",
    accessor: ({ totalCogs }: ProductEventRow) => currencyFormatter(totalCogs),
    align: "right",
    Cell: (props: any) => {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <ValueCell {...props} />
          </Grid>
          {handleCogsEdit && (
            <EditCell
              {...props}
              onEdit={({ value }) => handleCogsEdit(value)}
            />
          )}
        </Grid>
      );
    },
    isVisible: !report,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.otherExpensesLabel"),
    id: "totalOtherExpenses",
    accessor: ({ totalOtherExpenses }: ProductEventRow) =>
      currencyFormatter(totalOtherExpenses),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t(`profitability.profitLabel`),
    id: "totalProfit",
    accessor: (row: ProductEventRow) => {
      const totalProfit =
        row.totalRevenue - row.totalExpenses - row.totalAdvertising;
      const grossMargin = (100 * totalProfit) / row.totalRevenue;
      return {
        value: currencyFormatter(totalProfit),
        status: getStatusFromProfit(grossMargin),
      };
    },
    Cell: ({
      cell: {
        value: { value, status },
      },
    }: TableCellProp<{ value: string; status: string }>) => (
      <StatusText
        variant="body2"
        status={status}
        align="right"
        justify="flex-end"
      >
        {value === "-" ? value : `${value}`}
      </StatusText>
    ),
    align: "right",
    isVisible: true,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.availableQuantityLabel`),
    id: "availableQuantity",
    accessor: (row: ProductEventRow) => {
      return {
        availableValue: row.availableQuantity,
        compareValue: row.daysCover,
        threshold: daysCoverLimit,
      };
    },
    Cell: AvailabilityCell,
    align: "right",
    isVisible: !report,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitCostOfGoodsLabel`),
    id: "unitCogs",
    accessor: (row: ProductEventRow) => {
      const unitCogs = row.totalCogs / row.data.meta.unitCount;
      return currencyFormatter(unitCogs);
    },
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.inventoryValueLabel`),
    id: "inventoryValue",
    accessor: (row: ProductEventRow) => {
      const inventoryValue =
        (row.availableQuantity * row.totalCogs) / row.data.meta.unitCount;
      return currencyFormatter(inventoryValue);
    },
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.ordersLabel`),
    id: "orderCount",
    accessor: (row: ProductEventRow) => row.data.meta.orderCount,
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitsLabel`),
    id: "unitCount",
    accessor: (row: ProductEventRow) => row.data.meta.unitCount,
    Cell: ValueCell,
    align: "right",
    isVisible: report,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitsRefundedLabel`),
    id: "refundedUnitCount",
    accessor: (row: ProductEventRow) => row.data.meta.refundedUnitCount,
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  ...(report
    ? []
    : [
        {
          Header: t(`profitability.column.action`),
          id: "action",
          accessor: "productSku",
          Cell: ProductLinkCell,
          align: "right",
          getProps: () => ({ handleListingSelect }),
          isLocked: true,
          isVisible: true,
          disableSortBy: true,
        },
      ]),
];

const ProductTable = memo<ProductTableProps>(function ProductTable({
  store,
  categories,
  loading,
  data,
  fetchData,
  report = false,
  pageSize,
  columns,
  setColumns,
  handleListingSelect,
  handleCogsEdit,
  currency,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const homeCurrency = getCurrencyByCountryCode[store.marketplaceCountry];
  const currentCurrency = currency;

  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );
  const daysCoverLimit = useTypedSelector(
    (state) => state.notifications.daysCoverLimits.data
  )?.find((d) => d.mid === store.merchantId)?.daysCoverLimit;

  const currencyFormatter = (value: number) =>
    isNaN(value) || value === 0.0
      ? "-"
      : isEmpty(currencyRates)
      ? `$${intFormatter.format(value)}` // only used in storybook
      : formatCurrency(value, currencyRates, homeCurrency, currentCurrency);

  const newColumns = useMemo(
    () =>
      categories
        ? generateColumns(
            t,
            currencyFormatter,
            handleListingSelect,
            handleCogsEdit,
            daysCoverLimit,
            report
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, currentCurrency, daysCoverLimit]
  );

  useEffect(() => {
    setColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newColumns]);

  const renderProfitabilitySubComponent = ({
    row: {
      original: { sellerSku },
    },
  }: {
    row: { original: { sellerSku: string } };
  }) => (
    // eslint-disable-next-line no-magic-numbers
    <td colSpan={isMobile ? 4 : isSmall ? 6 : 9}>
      <ProductTableChart {...{ sellerSku }} />
    </td>
  );

  return (
    <>
      {!store || !data || !fetchData || isEmpty(columns) ? (
        <CircularProgress />
      ) : (
        <AdTable
          {...{
            columns,
            data: data.rows,
            fetchData,
            loading,
            sorting: true,
            pagination: !report, // disable pagination when this table is shown as a client report
            pageSize,
            pageCount: Math.ceil(data.count / pageSize),
            renderRowSubComponent: report
              ? undefined
              : renderProfitabilitySubComponent,
          }}
        />
      )}
    </>
  );
});

export default ProductTable;
