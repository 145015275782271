import { ProfitAndLossColumn, ProfitAndLossData } from "./profitAndLossTable";

import { Box } from "@material-ui/core";
import { Cell } from "./cell";
import React from "react";
import styled from "styled-components";

interface TableDataProps {
  width?: string;
  data: ProfitAndLossData[];
  columns: ProfitAndLossColumn[];
}

export const Row = styled(Box)<{
  $width?: string;
  $isSectionHeader?: boolean;
}>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: ${({ $width }) => $width || "100%"};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  ${({ $isSectionHeader, theme }) =>
    $isSectionHeader
      ? `
    background-color: ${theme.palette.table.header};
    `
      : `
    background-color: ${theme.palette.background.paper};
    `}
`;

export const TableData = ({ width, data, columns }: TableDataProps) => {
  return (
    <>
      {data.map(
        ({ bold, startAlign = true, tooltip, actions, colorCode, ...rest }) => {
          return (
            <Row key={rest["key"]} $width={width}>
              {columns.map(
                ({ key: columnKey, width, divider, stickyLeft }, index) => {
                  return (
                    <Cell
                      key={columnKey}
                      value={rest[columnKey as keyof typeof rest] as string}
                      width={width}
                      bold={bold}
                      stickyLeft={stickyLeft}
                      divider={divider}
                      // colorCode only the data cells, not the label
                      colorCode={index === 0 ? undefined : colorCode}
                      // startAlign, tooltip and actions are only used in the first column
                      startAlign={index === 0 ? startAlign : undefined}
                      tooltip={index === 0 ? tooltip : undefined}
                      actions={index === 0 ? actions : undefined}
                    />
                  );
                }
              )}
            </Row>
          );
        }
      )}
    </>
  );
};
