import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ThemeOverrides } from "~/typedef/store";
import axios from "axios";
import { baseUrl } from "../configs";
import { clearStoreAction } from "@store/utils/globalActions";
import { isHttpResponseValid } from "@store/utils/httpsResponseCodes";

type MongoCustomerOverride = {
  _id: string;
  agency: string;
  originalDomain: string;
  overrides: ThemeOverrides;
};

export type CustomOverridesState = Partial<
  MongoCustomerOverride & { loadingComplete: boolean }
>;
const initialState: CustomOverridesState = {
  loadingComplete: false,
};

export const fetchCustomOverridesConfig = createAsyncThunk<
  MongoCustomerOverride,
  {
    originalDomain: string | null;
    authSource: string | null;
    colorScheme: "light" | "dark";
  }
>(
  "customOverrides/fetchCustomOverridesConfig",
  async ({ originalDomain, authSource, colorScheme }, { rejectWithValue }) => {
    try {
      const params = authSource
        ? { authSource, colorScheme }
        : { originalDomain, colorScheme };
      const { status, data } = await axios.get(
        `${baseUrl}/api/user-management-service-reports/api/customOverrides`,
        {
          params,
        }
      );

      const validHttpResponse = isHttpResponseValid(status);
      if (validHttpResponse && data) {
        return data as MongoCustomerOverride;
      } else {
        return rejectWithValue("No custom overrides found for this domain");
      }
    } catch (error) {
      //do nothing
      return rejectWithValue("Unable to fetch custom overrides");
    }
  }
);

export const customOverridesSlice = createSlice({
  name: "customOverrides",
  initialState,
  reducers: {
    clearCustomOverridesConfig() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearStoreAction, (state) => {
        // We do not want to revert to initial state here as we want to keep the custom overrides
        return state;
      })
      .addCase(fetchCustomOverridesConfig.fulfilled, (_, action) => {
        return {
          loadingComplete: true,
          ...action.payload,
        };
      });
  },
});

export const { clearCustomOverridesConfig } = customOverridesSlice.actions;
export default customOverridesSlice.reducer;
