import {
  AdvertisingCustomGroupsState,
  CampaignCustomGroupMetrics,
  CampaignCustomGroups,
} from "@typedef/customGroups/advertising";
import { setError, setInfoMessage } from "../globalToast.redux";

import { DateRange } from "~/typedef/date";
import { Dispatch } from "redux";
import { TFunction } from "i18next";
import _ from "lodash";
import axios from "axios";
import { baseUrl } from "~/configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

const FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS =
  "FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS";
const FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_FETCHING =
  "FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_FETCHING";
const FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR =
  "FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR";

const FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID = "FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID";
const FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_FETCHING =
  "FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_FETCHING";
const FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_ERROR =
  "FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_ERROR";

const FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS_FETCHING =
  "FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS_FETCHING";
const FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS = "FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS";

const CREATE_CAMPAIGN_CUSTOM_GROUP_WORKING =
  "CREATE_CAMPAIGN_CUSTOM_GROUP_WORKING";
const CREATE_CAMPAIGN_CUSTOM_GROUP = "CREATE_CAMPAIGN_CUSTOM_GROUP";
const CREATE_CAMPAIGN_CUSTOM_GROUP_ERROR = "CREATE_CAMPAIGN_CUSTOM_GROUP_ERROR";

const UPLOAD_CAMPAIGN_CUSTOM_GROUPS_FETCHING =
  "UPLOAD_CAMPAIGN_CUSTOM_GROUPS_FETCHING";
const UPLOAD_CAMPAIGN_CUSTOM_GROUPS = "UPLOAD_CAMPAIGN_CUSTOM_GROUPS";
const DELETE_CAMPAIGN_GROUP_WORKING = "DELETE_CAMPAIGN_GROUP_WORKING";
const DELETE_CAMPAIGN_GROUP = "DELETE_CAMPAIGN_GROUP";

export const initAdvertisingCustomGroupsState: AdvertisingCustomGroupsState = {
  metrics: {
    fetching: false,
  },
  groups: {
    fetching: false,
  },
  currentGroup: {
    fetching: false,
  },
  upload: { fetching: false },
};

type FetchCampaignCustomGroupsFetchingAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_FETCHING;
};

type FetchCampaignCustomGroupsErrorAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR;
};

type FetchCampaignCustomGroupsAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS;
  payload: {
    rows: CampaignCustomGroupMetrics[];
    count: number;
  };
};

type FetchCampaignCustomGroupByIdFetchingAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_FETCHING;
};

type FetchCampaignCustomGroupByIdErrorAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_ERROR;
};

type FetchCampaignCustomGroupByIdAction = {
  type: typeof FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID;
  payload: {
    rows: CampaignCustomGroups[];
  };
};

type FetchAllCampaignCustomGroupsFetchingAction = {
  type: typeof FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS_FETCHING;
};

type FetchAllCampaignCustomGroupsAction = {
  type: typeof FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS;
  payload: {
    rows: CampaignCustomGroups[];
  };
};

type CreateCampaignCustomGroupWorking = {
  type: typeof CREATE_CAMPAIGN_CUSTOM_GROUP_WORKING;
};
type CreateCampaignCustomGroup = {
  type: typeof CREATE_CAMPAIGN_CUSTOM_GROUP;
};
type CreateCampaignCustomGroupError = {
  type: typeof CREATE_CAMPAIGN_CUSTOM_GROUP_ERROR;
};

type UploadCampaignGroupSendingAction = {
  type: typeof UPLOAD_CAMPAIGN_CUSTOM_GROUPS_FETCHING;
};
type UploadCampaignGroup = {
  type: typeof UPLOAD_CAMPAIGN_CUSTOM_GROUPS;
  payload: { data: { campaignsNotFound: string[] } };
};

type DeleteCampaignGroupWorking = {
  type: typeof DELETE_CAMPAIGN_GROUP_WORKING;
};

type DeleteCampaignGroup = {
  type: typeof DELETE_CAMPAIGN_GROUP;
};

interface FetchCampaignCustomGroupsParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentRange: DateRange;
  pageIndex: number;
  pageSize: number;
  sortKey: string;
  sortDesc: boolean;
  searchText?: string;
  campaignGroups?: CampaignCustomGroups[];
}

interface FetchAllCampaignCustomGroupsParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  searchText?: string;
  groupId?: number;
}

interface CreateCampaignCustomGroupParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  groupId?: number;
  groupName: string;
  campaignNames: string[];
}

type AdvertisingCustomGroupsAction =
  | FetchCampaignCustomGroupsAction
  | FetchCampaignCustomGroupsErrorAction
  | FetchCampaignCustomGroupsFetchingAction
  | FetchCampaignCustomGroupByIdAction
  | FetchCampaignCustomGroupByIdErrorAction
  | FetchCampaignCustomGroupByIdFetchingAction
  | FetchAllCampaignCustomGroupsFetchingAction
  | FetchAllCampaignCustomGroupsAction
  | CreateCampaignCustomGroupWorking
  | CreateCampaignCustomGroup
  | CreateCampaignCustomGroupError
  | UploadCampaignGroupSendingAction
  | UploadCampaignGroup
  | DeleteCampaignGroupWorking
  | DeleteCampaignGroup;

export const advertisingCustomGroups = (
  state: AdvertisingCustomGroupsState = initAdvertisingCustomGroupsState,
  action: AdvertisingCustomGroupsAction
): AdvertisingCustomGroupsState => {
  switch (action.type) {
    case FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_FETCHING:
      return {
        ...state,
        triggerRefetch: false,
        metrics: {
          ...state.metrics,
          fetching: true,
        },
      };

    case FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR:
      return {
        ...state,
        triggerRefetch: false,
        metrics: {
          ...state.metrics,
          fetching: false,
        },
      };

    case FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS:
      return {
        ...state,
        triggerRefetch: false,
        metrics: {
          data: action.payload.rows,
          count: action.payload.count,
          fetching: false,
        },
      };

    case FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS_FETCHING:
      return {
        ...state,
        groups: {
          ...state.groups,
          fetching: true,
        },
      };

    case FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID:
      return {
        ...state,
        currentGroup: {
          group: action.payload.rows,
          fetching: false,
        },
      };

    case FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_FETCHING:
      return {
        ...state,
        currentGroup: {
          fetching: true,
        },
      };

    case FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS:
      return {
        ...state,
        triggerRefetch: false,
        groups: {
          data: action.payload.rows,
          fetching: false,
        },
      };

    case UPLOAD_CAMPAIGN_CUSTOM_GROUPS_FETCHING:
      return {
        ...state,
        triggerRefetch: false,
        upload: {
          ...state.upload,
          fetching: true,
        },
      };

    case UPLOAD_CAMPAIGN_CUSTOM_GROUPS:
      return {
        ...state,
        triggerRefetch: true,
        upload: {
          ...state.upload,
          ...action.payload,
          fetching: false,
        },
      };

    case DELETE_CAMPAIGN_GROUP_WORKING:
      return {
        ...state,
        triggerRefetch: false,
      };

    case DELETE_CAMPAIGN_GROUP:
      return {
        ...state,
        triggerRefetch: true,
      };

    default:
      return state;
  }
};

export const fetchCustomGroupsMetrics =
  (params: FetchCampaignCustomGroupsParams) => async (dispatch: Dispatch) => {
    const {
      mid,
      marketplaceType,
      marketplaceSubtype,
      countryCode,
      pageIndex,
      pageSize,
      currentRange,
      sortKey,
      sortDesc,
      campaignGroups,
    } = params;
    dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/customGroups/getCampaigns`,
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          countryCode,
          pageIndex,
          pageSize,
          sortKey,
          sortDesc,
          campaignGroups,
          ...currentRange,
        }
      );

      if (data) {
        return dispatch({
          type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS,
          payload: { rows: data.rows, count: data.count },
        });
      } else {
        dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR });
        return setError(dispatch, data, status, "campaignCustomGroupMetrics");
      }
    } catch (err) {
      dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR });
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "campaignCustomGroupMetrics"
      );
    }
  };

export const fetchAllCampaignCustomGroups =
  (params: FetchAllCampaignCustomGroupsParams) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/customGroups/getAllCampaigns`,
        params
      );

      if (data) {
        return dispatch({
          type: FETCH_ALL_CAMPAIGN_CUSTOM_GROUPS,
          payload: { rows: data.rows, count: data.count },
        });
      } else {
        dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR });
        return setError(dispatch, data, status, "campaignCustomGroupMetrics");
      }
    } catch (err) {
      dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUPS_METRICS_ERROR });
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "campaignCustomGroupMetrics"
      );
    }
  };

export const fetchCampaignGroupById =
  (params: FetchAllCampaignCustomGroupsParams) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/customGroups/getCampaignGroupById`,
        params
      );

      if (data) {
        return dispatch({
          type: FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID,
          payload: { rows: data.rows },
        });
      } else {
        dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_ERROR });
        return setError(dispatch, data, status, "campaignCustomGroupByID");
      }
    } catch (err) {
      dispatch({ type: FETCH_CAMPAIGN_CUSTOM_GROUP_BY_ID_ERROR });
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "campaignCustomGroupByID"
      );
    }
  };

export const createCampaignCustomGroup =
  (params: CreateCampaignCustomGroupParams) => async (dispatch: Dispatch) => {
    const {
      mid,
      marketplaceType,
      marketplaceSubtype,
      countryCode,
      groupId,
      groupName,
      campaignNames,
    } = params;
    dispatch({ type: CREATE_CAMPAIGN_CUSTOM_GROUP_WORKING });
    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/customGroups/create`,
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          countryCode,
          groupId,
          groups: [
            {
              groupName,
              campaignNames,
            },
          ],
        }
      );

      if (data) {
        return dispatch({
          type: CREATE_CAMPAIGN_CUSTOM_GROUP,
        });
      } else {
        dispatch({ type: CREATE_CAMPAIGN_CUSTOM_GROUP_ERROR });
        return setError(dispatch, data, status, "createCampaignCustomGroup");
      }
    } catch (err) {
      dispatch({ type: CREATE_CAMPAIGN_CUSTOM_GROUP_ERROR });
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "createCampaignCustomGroup"
      );
    }
  };

interface UploadCampaignGroupParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  file: any;
  t: TFunction;
}
export const uploadCampaignCustomGroups =
  ({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    file,
    t,
  }: UploadCampaignGroupParams) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_CAMPAIGN_CUSTOM_GROUPS_FETCHING });

    try {
      const formData = new FormData();

      formData.append("mid", mid);
      formData.append("marketplaceType", marketplaceType);
      formData.append("marketplaceSubtype", marketplaceSubtype);
      formData.append("countryCode", countryCode);
      formData.append("file", file);

      const { data, status } = await axios.post(
        `${baseUrl}/api/myStores/customGroups/advertising/csv`,
        formData
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("customGroups.uploadSuccessMessage"));
        return dispatch({
          type: UPLOAD_CAMPAIGN_CUSTOM_GROUPS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status, "uploadCampaignGroups");
      }
    } catch (err) {
      const status = get(err, "response.status");

      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        status,
        "uploadCampaignGroups"
      );
    }
  };

export interface DeleteGroupArgs {
  t: TFunction;
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  groupId: number;
}

export const deleteCampaignGroup =
  ({
    t,
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    groupId,
  }: DeleteGroupArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_CAMPAIGN_GROUP_WORKING });
    try {
      const { status, data } = await axios.delete(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/customGroups/delete`,
        {
          data: {
            mid,
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            groupId,
          },
        }
      );

      if (isHttpResponseValid(status)) {
        setInfoMessage(dispatch, t("customGroups.deleteSuccessMessage"));
        return dispatch({
          type: DELETE_CAMPAIGN_GROUP,
        });
      } else {
        return setError(dispatch, data.error, status, "deleteCampaignGroup");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "deleteCampaignGroup"
      );
    }
  };
