import { Box, Drawer } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";
import { useCustomTheme } from "~/hooks/useCustomTheme";

const Backdrop = styled(Box)`
  overflow: visible !important; /* for box shadow around backdrop */
  padding-right: 0 !important; /* prevent shifting of main table content */
`;
Backdrop.displayName = "Backdrop";

interface DrawerPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  containerId: string;
  drawerWidth?: string;
  sidePanel: React.ReactElement;
  mainPanel: React.ReactElement;
}

const DrawerPanel: React.FC<DrawerPanelProps> = ({
  open,
  setOpen,
  containerId,
  sidePanel,
  mainPanel,
  drawerWidth = "600px",
}) => {
  const theme = useCustomTheme();
  return (
    <Backdrop width="100%" id={containerId} position="relative">
      <Drawer
        elevation={3}
        open={open}
        PaperProps={{
          style: {
            position: "absolute",
            maxWidth: drawerWidth,
            height: "101%",
            backgroundColor: theme.palette.background.paper,
          },
        }}
        BackdropProps={{
          style: {
            position: "absolute",
            opacity: "0.3",
            overflow: "visible",
          },
        }}
        ModalProps={{
          container: document.getElementById(containerId),
          style: { position: "absolute" },
          onClose: (_event, _reason) => {
            setOpen(false);
          },
        }}
        SlideProps={{
          onExiting: (node) => {
            node.style.webkitTransform = "scaleX(0)";
            node.style.transform = "scaleX(0)";
            node.style.transformOrigin = "top right";
          },
          style: { position: "absolute" },
        }}
        transitionDuration={{
          enter: 0,
          exit: 300,
        }}
        anchor="right"
      >
        {sidePanel}
      </Drawer>
      {mainPanel}
    </Backdrop>
  );
};
export default memo(DrawerPanel);
