import { get, sortedUniq } from "lodash";

import FilterSelect from "../../select/filterSelect";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CountryDropdown = ({
  selectedFilters,
  setSelectedFilters,
  filterWidth,
  fullWidth,
  disabled,
}) => {
  const { t } = useTranslation();
  const allStores = useSelector((state) =>
    get(state, "mystore.allStores.stores", [])
  );
  const flattenedCountries = sortedUniq(
    allStores.map((s) => s.marketplaceCountry).sort()
  );
  const selectedCountries = get(selectedFilters, "countries");

  const handleOptionChange = (countries) => {
    setSelectedFilters({ ...selectedFilters, countries: countries });
  };

  return (
    <FilterSelect
      title={t(`filterTitle.country`)}
      isFullWidth={fullWidth}
      maxWidth={filterWidth}
      options={flattenedCountries}
      selectedOptions={selectedCountries}
      handleSelect={handleOptionChange}
      isCountry
      isDisplayIcons
      filterTitle={t("filters.allCountries")}
      canSelectAll
      disabled={disabled}
    />
  );
};

CountryDropdown.propTypes = {
  filterWidth: PropTypes.number,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CountryDropdown;
