import React from "react";
import { TFunction } from "react-i18next";
import { baseUrl } from "../../configs";
import { connectBolAdvertising } from "~/store/connections/connectBol.redux";
import { connectWalmartAdvertising } from "~/store/connections/connectWalmart.redux";
import styled from "styled-components";

const LogoWrapper = styled.img`
  height: 98px;
`;

export const getAdvertisingMarketplaceConfig = (
  t: TFunction<"translation">,
  marketplace: string,
  marketplaceName: string,
  mid: string | null,
  country: string,
  marketplaceSubtype?: string
) => {
  const configMap = {
    bol: {
      inputs: [
        {
          label: t("connectWizard.bolClientIdLabel"),
        },
        {
          label: t("connectWizard.bolClientSecretLabel"),
        },
      ],
      shopName: true,
      logoLinkUrl: "https://www.bol.com",
      logo: <LogoWrapper src={`${baseUrl}/static/icons/bol.jpg`} />,
      messages: {
        title: t("connectWizard.bolAdvertisingLinkTitle"),
        helpLinkUrl: t("connectWizard.bolHelpLinkUrl"),
        helpLink: t("connectWizard.bolHelpLink"),
        shopNameTitle: t("connectWizard.bolAccountNameTitle"),
        shopNameLabel: t("connectWizard.bolAccountNameLabel"),
        successMessage: t("connectWizard.bolSuccessMessage"),
        retryMessage: t("connectWizard.bolRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectBolAdvertising,
      submitActionParams: ["input1", "input2"],
    },
    walmart: {
      inputs: [
        {
          label: t("connectWizard.walmartAdsAdvertiserIdLabel"),
        },
      ],
      logoLinkUrl: "https://www.walmart.com",
      logo: <LogoWrapper src={`${baseUrl}/static/icons/walmart-connect.jpg`} />,
      messages: {
        title: t("connectWizard.walmartAdvertisingLinkTitle"),
        helpLinkUrl: t("connectWizard.walmartAdvertisingHelpLinkUrl"),
        helpLink: t("connectWizard.walmartAdvertisingHelpLink"),
        shopNameTitle: t("connectWizard.walmartAccountNameTitle"),
        shopNameLabel: t("connectWizard.walmartAccountNameLabel"),
        successMessage: t("connectWizard.walmartSuccessMessage"),
        retryMessage: t("connectWizard.walmartAdvertisingRetryTokenMessage"),
        connectionError: t("connectWizard.connectionError"),
      },
      submitAction: connectWalmartAdvertising,
      submitActionParams: ["input1"],
    },
  };

  return {
    marketplace,
    marketplaceSubtype,
    marketplaceName,
    mid,
    country,
    ...configMap[marketplace as keyof typeof configMap],
  };
};
