import { Grid, Toolbar } from "@material-ui/core";
import React, { memo } from "react";

import Breadcrumbs from "../breadcrumbs";
import styled from "styled-components";

const StyledToolbar = styled(Toolbar)`
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  align-self: flex-end;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  background-color: transparent;
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      padding-bottom: 0.5rem;
    }`}
`;

interface SettingsToolbarProps {
  selectedItem: {
    currentPage: string;
    breadcrumb: {
      text: string;
      link?: string;
    }[];
  };
}

export const SettingsToolbar = memo<SettingsToolbarProps>(
  function SettingsToolbar({ selectedItem }) {
    return (
      <StyledToolbar id="overview-toolbar">
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Breadcrumbs
            selectedItem={selectedItem}
            textColorPalette="secondaryContrast"
          />
        </Grid>
      </StyledToolbar>
    );
  }
);
