import {
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

/* eslint-disable no-empty-function */
import { ArrowDropDown } from "@material-ui/icons";
import SearchFilter from "../adTable/searchFilter";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface FilterMenuItemProps {
  width?: number | null;
}
const FilterMenuItem = styled(MenuItem)`
  width: ${({ width }: FilterMenuItemProps) => width}px;
`;

export const FilterSelectBase = styled(ButtonBase)`
  width: 100%;
  padding: 0 0.5rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

export const OptionText = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  padding-left: 0px;
  text-transform: uppercase;
`;

const FlexGrid = styled(Grid)`
  padding: 4px 4px 0;
  display: flex;
  align-items: center;
`;

interface RightAlignedGridProps {
  $isFullWidth: boolean;
}
const RightAlignedGrid = styled(Grid)<RightAlignedGridProps>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "fit-content")};
`;

const ControlLabel = styled(FormControlLabel)`
  width: 100%;
  padding-left: 1rem;
`;

type EntityType = {
  groupId: number;
  groupName: string;
  [key: string]: any;
};

interface ObjectFilterSelectProps {
  options: EntityType[];
  selectedOptions: EntityType[];
  filterTitle: string;
  handleSelect: (options: EntityType[], customGroups: EntityType[]) => void;
  groups: EntityType[] | undefined;
  isFullWidth: boolean;
  disabled?: boolean;
  setSearchText?: (searchText: string) => void;
  displayKey: keyof EntityType;
  idKey: keyof EntityType;
  isRadio?: boolean;
}
const ObjectFilterSelect = ({
  options,
  selectedOptions,
  filterTitle,
  handleSelect,
  groups,
  isFullWidth,
  disabled,
  setSearchText,
  displayKey,
  idKey,
  isRadio,
}: ObjectFilterSelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [values, setValues] = useState(selectedOptions || []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );
  const [selectAll, setSelectAll] = useState(false);
  const othersLength = values.length - 1;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: EntityType
  ) => {
    const checked = e.target.checked;
    if (checked) {
      setValues([option, ...values]);
    } else {
      setValues(values.filter((value) => value[idKey] !== option[idKey]));
    }
  };

  const handleRadioOptionChange = (option: EntityType) => {
    setValues([option]);
  };

  const changeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setValues(options);
    } else {
      setValues([]);
    }
  };

  const openMenu = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget as HTMLElement);
  };

  const onClose = () => {
    handleSelect(values, groups || []);
    setAnchorEl(null);
  };

  useEffect(() => {
    setValues(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setMenuWidth(Boolean(anchorEl) ? anchorEl?.offsetWidth : null);
  }, [setMenuWidth, anchorEl]);

  const selectedOption = (option: EntityType) => (
    <FlexGrid item>
      <OptionText variant="body2" noWrap>
        {option[displayKey]}
      </OptionText>
    </FlexGrid>
  );

  const renderAvailableOptions = (currentOptions: EntityType[]) =>
    !isEmpty(currentOptions) &&
    currentOptions.map((option) => {
      const isSelected = values.find(
        (value) => value.groupId === option.groupId
      );

      return (
        <FilterMenuItem
          key={option[idKey]}
          width={menuWidth}
          dense={!isRadio}
          onClick={isRadio ? () => handleRadioOptionChange(option) : undefined}
        >
          {isRadio ? (
            <span>{option[displayKey]}</span>
          ) : (
            <ControlLabel
              control={
                <Checkbox
                  checked={Boolean(isSelected)}
                  name={option[displayKey] as string}
                  size="small"
                  color="primary"
                  onChange={(e) => handleChange(e, option)}
                />
              }
              label={selectedOption(option)}
            />
          )}
        </FilterMenuItem>
      );
    });

  return (
    <RightAlignedGrid
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
      $isFullWidth={isFullWidth}
    >
      <FilterSelectBase
        onClick={openMenu}
        id="filter-select"
        data-cy="filter-select"
        theme={theme}
        disabled={disabled}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid container alignItems="flex-end" item xs={10}>
            {isEmpty(values) ? (
              <OptionText theme={theme} variant="body2">
                {filterTitle}
              </OptionText>
            ) : (
              <>
                <FlexGrid item>
                  <OptionText variant="body2" noWrap>
                    {`${values
                      .slice(0, 3)
                      .map((value) => value[displayKey])
                      .join(", ")}${
                      values.length > 3 ? ` (+ ${values.length - 3})` : ""
                    }`}
                  </OptionText>
                </FlexGrid>
                {Boolean(othersLength) && (
                  <Typography variant="body2" noWrap>
                    {`, ${othersLength}+`}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            <ArrowDropDown />
          </Grid>
        </Grid>
      </FilterSelectBase>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={onClose}
      >
        {setSearchText && (
          <FilterMenuItem key="search" width={menuWidth} dense={true}>
            <SearchFilter setSearchText={setSearchText} />
          </FilterMenuItem>
        )}
        {!isRadio && (
          <FilterMenuItem key="select-all" width={menuWidth} dense={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  size="small"
                  color="primary"
                  onChange={(e) => changeSelectAll(e)}
                />
              }
              label={t("generic.selectAll", { count: options.length })}
            />
          </FilterMenuItem>
        )}
        {renderAvailableOptions(options)}
      </Menu>
    </RightAlignedGrid>
  );
};

export default memo(ObjectFilterSelect);
