import {
  HealthStatus,
  getAccountHealth,
  getStatusTitle,
} from "~/utils/accountHealthUtils";
import {
  OverviewAccountHealth,
  OverviewAccountHealthEntry,
} from "~/typedef/store";
import React, { memo, useCallback, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  getParentMarketplace,
  getShopName,
  marketplaceLink,
} from "~/utils/marketplaceUtils";

import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import NoData from "../../../components/loadingIndicator/noData";
import StatusIndicator from "../../../components/statusIndicator/statusIndicator";
import Table from "../../../components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

interface AccountHealthStatus {
  market: any;
  shopName: any;
  healthStatus: {
    value: string;
    status: string;
  };
}

const sortByHealth = (
  a: AccountHealthStatus,
  b: AccountHealthStatus
): number => {
  if (
    a.healthStatus.status === HealthStatus.Poor ||
    (a.healthStatus.status === HealthStatus.AtRisk &&
      b.healthStatus.status === HealthStatus.Good) ||
    b.healthStatus.status === HealthStatus.NoStatus ||
    b.healthStatus.status === HealthStatus.Closed
  ) {
    return -1;
  }

  if (
    a.healthStatus.status === HealthStatus.NoStatus ||
    a.healthStatus.status === HealthStatus.Closed ||
    (a.healthStatus.status === HealthStatus.AtRisk &&
      b.healthStatus.status === HealthStatus.Poor)
  ) {
    return 1;
  }

  return 0;
};

const formatMarketplaceData = (
  filteredStores: any[],
  accountHealth: OverviewAccountHealthEntry[],
  t: TFunction<"translation">,
  allowedLinks: string[] = []
) => {
  const defaultHealthData = {
    healthStatus: HealthStatus.NoStatus,
  };
  return (
    accountHealth &&
    accountHealth
      .reduce<AccountHealthStatus[]>((acc, storeAccountHealth) => {
        if (storeAccountHealth) {
          const market = storeAccountHealth.marketplace;
          const shopName = {
            value: getShopName(
              filteredStores,
              storeAccountHealth.marketplace.market,
              storeAccountHealth.store
            ),
            link: marketplaceLink(
              storeAccountHealth.marketplace.market,
              storeAccountHealth.store,
              "accounthealth",
              undefined,
              allowedLinks
            ),
          };
          const healthData =
            getAccountHealth(
              getParentMarketplace(market.market),
              storeAccountHealth
            ) || defaultHealthData;

          const { healthStatus } = healthData;
          acc.push({
            market,
            shopName,
            healthStatus: {
              value: getStatusTitle(healthStatus, t),
              status: healthStatus,
            },
          });
        }
        return acc;
      }, [])
      .sort(sortByHealth)
  );
};

interface AccountHealthSummaryContentsProps {
  accountHealth: OverviewAccountHealth;
  fetchAccountHealth: Function;
  compact?: boolean;
}

const AccountHealthSummaryContents = memo<AccountHealthSummaryContentsProps>(
  function AccountHealthSummaryContents({
    accountHealth,
    fetchAccountHealth,
    compact,
  }) {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);
    const filteredStores = useTypedSelector((state) =>
      get(state, "mystore.filteredStores.stores", [])
    );
    const userCheck = userInfo && userInfo._id;
    const currentFilter = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );
    const allowedLinks = useTypedSelector(
      (state) => state.customLayout?.layoutConfig?.sideNav?.pages
    );
    const records = accountHealth.accountHealth || [];
    const recordCount = accountHealth.count || 0;
    const fetching = accountHealth.fetching;
    const dispatch = useDispatch();

    const dispatchFetchAccountHealth = useCallback(
      ({ pageIndex, pageSize }) => {
        dispatch(
          fetchAccountHealth(
            {
              user: { _id: userInfo?._id },
              pageNum: pageIndex,
              pageSize,
              filter: currentFilter,
            },
            accountHealth?.params
          )
        );
      },
      [currentFilter, userCheck, accountHealth?.params]
    );

    const data = useMemo(
      () => formatMarketplaceData(filteredStores, records, t, allowedLinks),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [records, userCheck, allowedLinks]
    );

    const columns = useMemo(
      () => [
        {
          id: "market",
          Header: t("dashboardWidget.accountHealth.marketplaceColumn"),
          accessor: "market",
          Cell: MarketplaceAndCountryCell,
          alwaysSmall: true,
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.accountHealth.storeColumn"),
          accessor: compact
            ? (row: any) => get(row, "shopName.value")
            : "shopName",
          Cell: compact ? TextCell : LinkCell,
          alwaysSmall: true,
        },
        {
          id: "healthStatus",
          Header: t("dashboardWidget.accountHealth.healthColumn"),
          accessor: "healthStatus",
          alwaysSmall: true,
          Cell: ({ cell }: { cell: any }) => (
            <StatusIndicator
              {...{
                size: compact ? "small" : "medium",
                statusText: cell.value.value,
                status: cell.value.status,
              }}
            />
          ),
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    const isCurrentFilterNotEmpty =
      !isEmpty(get(currentFilter, "tags")) ||
      !isEmpty(get(currentFilter, "countries")) ||
      !isEmpty(get(currentFilter, "marketplaces"));

    return isEmpty(data) && isCurrentFilterNotEmpty && !fetching ? (
      <NoData />
    ) : (
      <Table
        {...{
          columns,
          compact,
          data,
          loading: fetching,
          fetchData: dispatchFetchAccountHealth,
          pageSize: PAGE_SIZE,
          pagination: !compact,
          pageCount: Math.ceil(recordCount / PAGE_SIZE),
        }}
      />
    );
  }
);

export default AccountHealthSummaryContents;
