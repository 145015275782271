import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import Medium from "~/components/typography/medium";
import { loadInfo } from "~/store/user.redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
`;

const AmazonConnectAdvertisingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const amazonStore = useSelector((state) => state.amazonNewStore);
  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const [loading, setLoading] = useState(true);
  const selectedCountry = urlSearchParams.get("selectedCountry");

  const redirectNextStep = async () => {
    urlSearchParams.delete("marketplace");
    urlSearchParams.set("step", "2");

    //refresh user info before redirecting
    await dispatch(loadInfo());
    history.push(`/connectStore?${urlSearchParams.toString()}`);
  };

  useEffect(() => {
    if (!amazonStore.isNewStore) {
      redirectNextStep();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="amazon-connectadvertising-wrapper">
          <GridWrapper container spacing={5}>
            <Grid item xs={12}>
              <Box pb={4}>
                <Medium>{t("connectWizard.amazonAdvertisingTitle")}</Medium>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MarketingAuthoriseUser
                isVendorNewConnection
                selectedCountry={selectedCountry}
              />
            </Grid>
          </GridWrapper>
        </GridWrapper>
      )}
    </>
  );
};

export default AmazonConnectAdvertisingPage;
