import "draft-js/dist/Draft.css";

import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { Edit, Save } from "@material-ui/icons";
import {
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import React, { useEffect } from "react";
import {
  ReportingCommentEditIcon,
  ReportingCommentHeader,
  ReportingCommentSaveIcon,
} from "./reportingCommentsDrawer";
import { flatMap, uniq } from "lodash";
import styled, { css } from "styled-components";
import {
  useFetchNotesQuery,
  useSaveNotesMutation,
} from "~/store/mystore/notes.redux";

import ObjectFilterSelect from "~/components/select/objectFilterSelect";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import RichTextEditor from "~/components/richTextEditor/richTextEditor";
import { fetchCustomTags } from "~/store/overview/customTags.redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MultiChannelReportingComments = ({
  setShouldShowBadge,
}: {
  setShouldShowBadge: (shouldShow: boolean) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const customTags = useTypedSelector((state) =>
    get(state, "overview.customTags.tags")
  );

  const tags = uniq(
    flatMap(customTags, (val: { tags: string[] }) => val.tags)
  ) as string[];

  const [editorState, setEditorState] = React.useState<
    Record<string, EditorState>
  >(
    tags.reduce((acc, filter) => {
      acc[filter] = EditorState.createEmpty();
      return acc;
    }, {} as Record<string, EditorState>)
  );

  const [editMode, setEditMode] = React.useState<Record<string, boolean>>(
    tags.reduce((acc, filter) => {
      acc[filter] = false;
      return acc;
    }, {} as Record<string, boolean>)
  );
  const [internalDirty, setInternalDirty] = React.useState<
    Record<string, boolean>
  >(
    tags.reduce((acc, filter) => {
      acc[filter] = false;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const [selectedTags, setSelectedTags] = React.useState<
    {
      groupId: number;
      groupName: string;
    }[]
  >(
    tags.map((filter, index) => ({
      groupId: index,
      groupName: filter,
    }))
  );

  const user = useTypedSelector((state) => get(state, "user"));
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const { isFetching, data: notes } = useFetchNotesQuery(
    {
      tags: tags,
    },
    {
      skip: !user || tags.length === 0,
    }
  );

  const [saveNote] = useSaveNotesMutation();

  useEffect(() => {
    if (notes && notes.notes.length > 0 && !isFetching) {
      const noteMap = notes.notes.reduce((acc, note) => {
        if ("tag" in note) {
          acc[note.tag] = note.note;
        }
        return acc;
      }, {} as Record<string, RawDraftContentState>);
      const newEditorState = tags.reduce((acc, tag) => {
        acc[tag] = noteMap[tag]
          ? EditorState.createWithContent(convertFromRaw(noteMap[tag]))
          : EditorState.createEmpty();
        return acc;
      }, {} as Record<string, EditorState>);
      setEditorState(newEditorState);
    } else if (!isFetching) {
      /* If we dont have notes after loading, reset the editor state */
      const newEditorState = tags.reduce((acc, tag) => {
        acc[tag] = EditorState.createEmpty();
        return acc;
      }, {} as Record<string, EditorState>);
      setEditorState(newEditorState);
    }
  }, [notes]);

  useEffect(() => {
    dispatch(fetchCustomTags());
  }, []);

  const editors = React.useRef<Record<string, React.RefObject<any>>>(
    tags.reduce((acc, tag) => {
      acc[tag] = React.createRef();
      return acc;
    }, {} as Record<string, React.RefObject<any>>)
  );

  const focusEditor = (tag: string) => {
    const editor = editors.current[tag];
    if (editor && editor?.current) {
      editor?.current?.focus();
    }
  };

  const handleSaveInternal = async (tag: string) => {
    if (store) {
      const contentState = editorState[tag].getCurrentContent();

      await saveNote({
        note: convertToRaw(contentState),
        tag,
      });

      setInternalDirty({
        ...internalDirty,
        [tag]: false,
      });

      setEditMode({
        ...editMode,
        [tag]: false,
      });
    }
  };

  const editComment = (tag: string, newState: EditorState) => {
    setInternalDirty({
      ...internalDirty,
      [tag]: true,
    });
    setEditorState({
      ...editorState,
      [tag]: newState,
    });
  };

  const handleButtonClick = async (tag: string) => {
    if (!editMode[tag]) {
      focusEditor(tag);
      setEditMode({
        ...editMode,
        [tag]: true,
      });
    } else {
      await handleSaveInternal(tag);
      setEditMode({
        ...editMode,
        [tag]: false,
      });
    }
  };

  useEffect(() => {
    const isDirty = Object.values(internalDirty).some((dirty) => dirty);
    setShouldShowBadge(isDirty);
  }, [Object.values(internalDirty)]);

  const selectedTagsGroupNames = selectedTags.map((tag) => tag.groupName);

  return (
    <>
      <ReportingCommentHeader container>
        <Typography variant="h3" color="textPrimary">
          {t("reportComments.reportCommentaryTitle")}
        </Typography>
        <Box
          display="flex"
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          marginTop={3}
          boxSizing={"border-box"}
        >
          <Box marginRight={3}>
            <Typography variant="h3" color="textPrimary">
              {t("reportComments.tagsTitle")}
            </Typography>
          </Box>
          <ObjectFilterSelect
            filterTitle="Show All"
            displayKey={"groupName"}
            groups={tags.map((filter, index) => ({
              groupId: index,
              groupName: filter,
            }))}
            handleSelect={(options) => {
              setSelectedTags(options.map((option) => option));
            }}
            idKey={"groupName"}
            isFullWidth={false}
            options={tags.map((filter, index) => ({
              groupId: index,
              groupName: filter,
            }))}
            selectedOptions={selectedTags.map((filter, index) => ({
              groupId: filter.groupId,
              groupName: filter.groupName,
            }))}
          />
        </Box>
      </ReportingCommentHeader>
      <Box>
        {isFetching ? (
          <PanelLoading />
        ) : (
          <Box>
            {tags
              .filter((tag) => {
                if (selectedTags.length === 0) {
                  return true;
                }

                return selectedTagsGroupNames.includes(tag);
              })
              .map((tag, index) => (
                <Box
                  key={tag}
                  borderBottom={
                    index < tags.length - 1
                      ? `1px solid ${theme.palette.border.main}`
                      : 0
                  }
                >
                  <Box padding={3}>
                    <Typography variant="h3" color="textPrimary">
                      {tag.toUpperCase()}
                    </Typography>
                  </Box>
                  {editorState[tag] && (
                    <Box>
                      <RichTextEditor
                        editorState={editorState[tag]}
                        setEditorState={(state) => {
                          editComment(tag, state);
                        }}
                        editMode={editMode[tag]}
                        editor={editors.current[tag]}
                        includeControls={true}
                      />
                      <Box position={"relative"}>
                        {editMode[tag] ? (
                          <ReportingCommentSaveIcon
                            onClick={() => handleButtonClick(tag)}
                          />
                        ) : (
                          <ReportingCommentEditIcon
                            onClick={() => handleButtonClick(tag)}
                          />
                        )}
                      </Box>
                    </Box>
                  )}

                  {internalDirty[tag] && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      height="70px"
                      p={3}
                    >
                      <Typography variant="body1" color="error">
                        {t("reportComments.unsavedComments")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default MultiChannelReportingComments;
