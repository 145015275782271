/* eslint-disable no-magic-numbers */
import { Grid, Typography } from "@material-ui/core";

import Panel from "../../../components/panel/panel.tsx";
import { PlanPropTypes } from "./plan";
import PropTypes from "prop-types";
import React from "react";
import SubscriptionDetails from "./subscriptionDetails";
import { getPlanName } from "~/modules/subscription/plan/plan";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PanelContentWrapper = styled(Grid).attrs(() => ({
  container: true,
}))`
  padding: 1rem;
`;

export const CurrentSubscription = ({ currentPlan, dueDate }) => {
  const { t } = useTranslation();

  if (!currentPlan) {
    return (
      <Panel
        id="subscription-details"
        title={t("settings.billing.subscriptionTitle")}
        content={
          <PanelContentWrapper>
            <Grid item xs={12} sm={6}>
              <Typography>
                {t("settings.billing.noActivePlanMessage")}
              </Typography>
            </Grid>
          </PanelContentWrapper>
        }
      />
    );
  }

  return (
    <Panel
      id="subscription-details"
      title={t("settings.billing.subscriptionTitle")}
      content={
        <PanelContentWrapper>
          <Grid item xs={12} sm={6}>
            <SubscriptionDetails
              id="current-subscription-name"
              description={t("settings.billing.currentPlanLabel")}
              value={t(getPlanName(currentPlan.planId, currentPlan.name))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubscriptionDetails
              id="current-subscription-due-date"
              description={t("settings.billing.paymentDueLabel")}
              value={dueDate}
            />
          </Grid>
        </PanelContentWrapper>
      }
    />
  );
};

CurrentSubscription.propTypes = {
  currentPlan: PlanPropTypes.isRequired,
  dueDate: PropTypes.string.isRequired,
};

export default CurrentSubscription;
