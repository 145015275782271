import { Box, Paper, Typography } from "@material-ui/core";
import React, { useMemo } from "react";

import AuthPageBlock from "~/components/containers/authPageBlock";
import Footer from "~/components/footer/footer";
import LoginForm from "@modules/login/signin";
import { LoginProviders } from "@modules/login/loginProviders";
import SimpleToolbar from "@components/toolbars/simpleToolbar";
import { StoreState } from "@typedef/store";
import get from "lodash/get";
import { getHomePath } from "@merchantspring/common";
import { handleLoginSucceed } from "@modules/login/login";
import styled from "styled-components";
import { updateMarketingInfo } from "@store/user.redux";
import { useAgencySpecificConfig } from "~/hooks/useAgencySpecificConfig";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import useQueryParams from "../../hooks/useQueryParams";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const SignInPaper = styled(Paper)`
  border-top: ${({ theme }) => `3px solid ${theme.palette.primary.main}`};
  max-width: calc(285px + 8rem);
`;

const SigninPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [hasErrored, setHasErrored] = React.useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = React.useState(false);
  const destinationRoute = useTypedSelector((state: StoreState) => {
    if (state.globalVar?.destinationRoute) {
      if (
        !["/", "/signin", "/signup"].includes(state.globalVar.destinationRoute)
      ) {
        return state.globalVar.destinationRoute;
      } else {
        return getHomePath(state.user);
      }
    }
    return getHomePath(state.user);
  });
  const user = useTypedSelector((state: StoreState) => state.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { hideLoginProviders, hideSignUp } = useAgencySpecificConfig();

  const marketingInfo = {
    marketingFunnel: document.referrer,
    utmSource: queryParams.get("utm_source"),
    utmCampaign: queryParams.get("utm_campaign"),
    utmContent: queryParams.get("utm_content"),
    utmMedium: queryParams.get("utm_medium"),
    utmTerm: queryParams.get("utm_term"),
    gclid: queryParams.get("gclid"),
    partnerCode: queryParams.get("partner_code"),
  };

  React.useEffect(() => {
    if (isLoginSuccessful && user && user._id) {
      handleLoginSucceed(user, history, destinationRoute, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccessful, user, history, destinationRoute]);

  const onLoginSuccess = () => {
    setHasErrored(false);
    dispatch(updateMarketingInfo(marketingInfo));
    setIsLoginSuccessful(true);
  };

  return (
    <>
      <AuthPageBlock>
        <SimpleToolbar {...{ fullWidth: true }} />
        <Box
          minHeight="calc(100vh - 300px)"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <SignInPaper elevation={2}>
            <Box
              pt={2}
              pl={isMobile ? 2 : 8}
              pb={8}
              pr={isMobile ? 2 : 8}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box pt={8} pb={2}>
                <Typography
                  variant="h1"
                  display="inline"
                  color="textSecondary"
                  noWrap
                >
                  {t("signin.signinTitle")}
                </Typography>
              </Box>
              {!hideLoginProviders && (
                <LoginProviders
                  onSuccess={onLoginSuccess}
                  onError={() => setHasErrored(true)}
                />
              )}
              <LoginForm
                onSuccess={onLoginSuccess}
                onError={() => setHasErrored(true)}
                hideSignup={hideSignUp}
              />
              {hasErrored && (
                <Typography color="error">
                  {get(user, "error.errMsg", t("generic.generalError"))}
                </Typography>
              )}
            </Box>
          </SignInPaper>
        </Box>
      </AuthPageBlock>
      <Footer />
    </>
  );
};

export default SigninPage;
