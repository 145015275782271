import cloneDeep from "lodash/cloneDeep";

export const currentValidationRules = (requiredToggle) =>
  requiredToggle ? ["Required"] : ["Required", "Optional", "Recommended"];

const baseUrl = "";

// Map compareResult to the rows with {name:selectedValue} format.
// Used to create CSV file and sync to ebay
// Like [{ itemId: '', Brand:'Gioia', Type:'Scrunchie', Material:'100 Silk'}, ...]
export const createCSVData = (compareResult) => {
  if (!(compareResult instanceof Array)) {
    return [];
  }
  const items = [];
  compareResult.forEach((item) => {
    const row = {};
    row.itemId = item.itemId;
    item.compareDetails.forEach((f) => {
      row[f.name] = f.selectedValue;
    });
    items.push(row);
  });
  return items;
};

export const downloadCSVData = (filename) => {
  const link = document.createElement("a");
  link.href = `${baseUrl}/api/file/${filename}`;
  // download instead of navigating to the link - this prevents
  // the window.beforeunload event firing
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDifferentOfTwoArr = (arr1, arr2) => {
  let notMatched = 0;
  arr1.forEach((obj1) => {
    const obj2 = arr2.find((i) => i.name === obj1.name);

    // If the selectedvalue is array, need to compare the values in array
    if (
      obj1.selectedValue instanceof Array &&
      obj2.selectedValue instanceof Array
    ) {
      if (
        JSON.stringify(obj1.selectedValue) !==
        JSON.stringify(obj2.selectedValue)
      ) {
        notMatched++;
      }
    } else {
      if (obj1.selectedValue !== obj2.selectedValue) {
        notMatched++;
      }
    }
  });
  return notMatched;
};

/** Given 2 arrays of items: one representing an updated list of items (and
 * item specifics for each item), and
 * the other representing the original state of the items,
 * return a list of the items that have changed between the updated list and
 * the initial list. */
export const getUpdatedItems = (current, initial) => {
  if (
    !(current instanceof Array && initial instanceof Array) ||
    current.length !== initial.length
  ) {
    throw new Error(
      "Current and initial item specifics must have the same dimensions"
    );
  }

  const updatedItems = current.filter((item) => {
    // Match up items, to make sure that we're comparing items with the same
    // item ID
    const initialItem = initial.find((i) => i.itemId === item.itemId);

    // getDifferentOfTwoArr returns the number of fields that are different
    // between the 2 items. If that number is greater than 0, then the
    // item must have been modified.
    return (
      getDifferentOfTwoArr(item.compareDetails, initialItem.compareDetails) > 0
    );
  });
  return updatedItems;
};

// Update single field or couple of fields in compareDetails
// The matrix should be like
/*      [
             {itemId: "333045793942", compareDetails: [{ name: "Brand", selectedValue: 'Gioia casa', flag: 'correct'...}]}
        ] */
export const getUpdatedMatrix = (matrix, itemId, name, fieldsToUpdate) => {
  const index = matrix.findIndex((i) => i.itemId === itemId);
  const matchedItem = cloneDeep(matrix[index]);

  const matchedItemAndName = matchedItem.compareDetails.find(
    (i) => i.name === name
  );

  for (const key in fieldsToUpdate) {
    matchedItemAndName[key] = fieldsToUpdate[key];
  }
  // Recompute the percentage
  matchedItem.correct = matchedItem.compareDetails.filter(
    (i) => i.flag === "correct"
  ).length;
  matchedItem.invalid = matchedItem.compareDetails.filter(
    (i) => i.flag === "invalid"
  ).length;
  matchedItem.missing = matchedItem.compareDetails.filter(
    (i) => i.flag === "missing"
  ).length;

  const updatedMatrix = Object.assign([], matrix, { [index]: matchedItem });

  return updatedMatrix;
};

export const getUnsyncedItemCount = (updated, initial) => {
  if (
    !(updated instanceof Array && initial instanceof Array) ||
    updated.length !== initial.length
  ) {
    return 0;
  }

  let allNotMatched = 0;
  updated.forEach((item) => {
    const _item = initial.find((i) => i.itemId === item.itemId);
    if (!_item) return;
    const notMatched = getDifferentOfTwoArr(
      item.compareDetails,
      _item.compareDetails
    );

    if (notMatched > 0) {
      allNotMatched++;
    }
  });

  return allNotMatched;
};

/** @typedef {object} DirtyResult
 * @property {object[]} updatedDirtyItems
 *  - the list of dirtied items, with any items which have been applied
 *  removed from the list
 *  @property {object[]} updatedItems
 *  - the initial list of items with changes from the dirty item list applied
 **/

/** Given a "clean" list of item specifics from the backend,
 * check if any of the items are in our list of dirty items.
 * For any such item, apply the changes from the dirty state.
 * This is used to restore changes upon paginating back to a
 * page that we've already made unsaved changes on before.
 *
 * @return {DirtyResult} */
export const applyDirtyItems = (dirtyItems, initialItems) => {
  const NOT_FOUND = -1;
  let updatedDirtyItems = dirtyItems;
  const updatedItems = [];
  for (const item of initialItems) {
    const dirtyItemIndex = dirtyItems.findIndex(
      (d) => d.itemId === item.itemId
    );

    if (dirtyItemIndex !== NOT_FOUND) {
      // drop the element from the dirty item list
      updatedDirtyItems = updatedDirtyItems
        .slice(0, dirtyItemIndex)
        .concat(dirtyItems.slice(dirtyItemIndex + 1));
      updatedItems.push(dirtyItems[dirtyItemIndex]);
    } else {
      updatedItems.push(item);
    }
  }
  return {
    updatedDirtyItems,
    updatedItems,
  };
};
