import { useMediaQuery, useTheme } from "@material-ui/core";

import DesktopBackground from "./error.jpg";
import MobileBackground from "./error-mobile.jpg";

export const ErrorBoundaryBackground = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  return isMobileView ? MobileBackground : DesktopBackground;
};
