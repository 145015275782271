import { Box, TextField } from "@material-ui/core";
import {
  OrganisationSeat,
  useOrganisationSeatsQuery,
} from "~/store/organisation.redux";
import React, { useCallback, useMemo, useState } from "react";

import { AccountCircle } from "@material-ui/icons";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import Panel from "~/components/panel/panel";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { debounce } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const InlineBlockDiv = styled.div`
  display: inline-block;
`;

const AccountImage = styled.img`
  display: flex;
  margin: 0 0.5rem 0 0;
  max-height: 35px;
  border-radius: 50%;
`;

const AccountIcon = styled(AccountCircle)`
  display: flex;
  margin: 0 0.5rem 0 0;
  max-height: 35px;
  fill: ${({ theme }) => theme.palette.text.primary};
`;

const PAGE_SIZE = 30;

const OrganisationSeats = () => {
  const { t } = useTranslation();

  const contentRef = React.useRef<HTMLHeadingElement>(null);
  const [filters, setFilters] = useState({
    search: "",
    roleFilter: "",
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const debouncedSetFilters = debounce(setFilters, 300);

  const { seats, total, isFetching } = useOrganisationSeatsQuery(
    {
      pageIndex: filters.pageIndex,
      pageSize: filters.pageSize,
      search: filters.search,
      roleFilter: filters.roleFilter,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        isFetching,
        seats: data?.seats || [],
        total: data?.total || 0,
      }),
    }
  );

  const fetchData = useCallback(({ pageIndex }) => {
    setFilters({
      ...filters,
      pageIndex,
    });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        id: "Avatar",
        Header: "Avatar",
        accessor: (row: OrganisationSeat) => row.imgUrl || "",
        Cell: ({ cell }: { cell: { value: string } }) => {
          return cell.value !== "" ? (
            <AccountImage src={cell.value} id="avatar" />
          ) : (
            <AccountIcon id="avatar" />
          );
        },
        disableSortBy: true,
        isVisible: true,
      },
      {
        id: "email",
        Header: t("settings.seatsEmail"),
        accessor: (row: OrganisationSeat) => row.email,
        Cell: TextCell,
        isVisible: true,
      },
      {
        id: "firstName",
        Header: t("settings.seatsFirstname"),
        accessor: (row: OrganisationSeat) => row.firstName,
        Cell: TextCell,
        isVisible: true,
      },
      {
        id: "lastName",
        Header: t("settings.seatsLastname"),
        accessor: (row: OrganisationSeat) => row.lastName,
        Cell: TextCell,
        isVisible: true,
      },
      {
        id: "role",
        Header: t("settings.seatsRole"),
        accessor: (row: OrganisationSeat) => row.role,
        Cell: TextCell,
        isVisible: true,
      },
      {
        id: "numberOfChannelsConnected",
        Header: t("settings.seatsConnectedChannels"),
        accessor: (row: OrganisationSeat) =>
          row.numberOfChannelsConnected?.toString() || 0,
        Cell: TextCell,
        isVisible: true,
      },
      {
        id: "registrationTime",
        Header: t("settings.seatsCreatedAt"),
        accessor: (row: OrganisationSeat) =>
          moment(row.registrationTime).format("DD MMM YYYY"),
        Cell: TextCell,
        isVisible: true,
      },
    ];
  }, []);

  return (
    <Grid container>
      <Panel
        id="organisation-seats-table"
        title={`${t("settings.seats")} | Total: ${total}`}
        content={
          <>
            <InlineBlockDiv ref={contentRef} />
            <Table
              {...{
                columns,
                data: seats,
                fetchData: fetchData,
                pagination: true,
                pageSize: filters.pageSize,
                pageCount: Math.ceil(total / filters.pageSize),
                loading: isFetching,
              }}
            />
          </>
        }
        actions={
          <>
            <Box marginRight={2} height="60px">
              <TextField
                label={t("settings.seatsSearch")}
                onChange={(e) => {
                  debouncedSetFilters({
                    ...filters,
                    pageIndex: 0,
                    search: e.target.value,
                  });
                }}
              />
            </Box>
            <Box marginRight={2}>
              <NativeSelect
                name="role"
                placeholder="Role" // Keep this, so the default is an empty string
                options={[
                  {
                    value: "owner",
                    label: "Owner",
                  },
                  {
                    value: "administrator",
                    label: "Administrator",
                  },
                  {
                    value: "internal",
                    label: "Internal",
                  },
                  {
                    value: "external",
                    label: "External",
                  },
                ]}
                value={filters.roleFilter}
                onChange={(e) => {
                  debouncedSetFilters({
                    ...filters,
                    pageIndex: 0,
                    roleFilter:
                      e.target.value === "placeholder"
                        ? ""
                        : (e.target.value as string),
                  });
                }}
              />
            </Box>
            <DownloadCsv
              mid={"all"}
              reportType="accountSeats"
              path="/api/user/organisationSeats"
              params={filters}
            />
          </>
        }
      />
    </Grid>
  );
};

export default OrganisationSeats;
