import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import { GrowthBlock } from "./valueAndGrowthCell";
import StatusText from "../../typography/status";
import styled from "styled-components";

export const getGrowthStatus = (
  growth?: number | string,
  reverseFormatting?: boolean
): undefined | "error" | "success" => {
  if (!growth || growth === "N/A" || growth === "-") {
    return undefined;
  }
  const value = typeof growth === "string" ? parseInt(growth) : growth;
  if (reverseFormatting) {
    return value > 0 ? "error" : "success";
  } else {
    return value < 0 ? "error" : "success";
  }
};

export const Cell = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

interface CellProps {
  cell: {
    value?: {
      value?: string;
      growth?: number;
      conditionalFormatting?: boolean;
      reverseFormatting?: boolean;
    };
  };
}

const PercentageAndGrowthCell = memo<CellProps>(
  function PercentageAndGrowthCell({ cell }) {
    const value = cell.value?.value;
    const growth = cell.value?.growth;
    const conditionalFormatting = cell.value?.conditionalFormatting;
    const reverseFormatting = cell.value?.reverseFormatting;
    const growthStatus = getGrowthStatus(growth, reverseFormatting);

    return (
      <Cell>
        <StatusText align="right" justify="flex-end" variant="body2">
          {value === "N/A" || value === "-" ? value : `${value}%`}
        </StatusText>
        {growth && (
          <GrowthBlock>
            <StatusText
              status={
                conditionalFormatting === false ? undefined : growthStatus
              }
              variant="body2"
              align="right"
              paragraph={false}
            >
              {growth > 0 && (
                <UpArrow fontSize="inherit" status={growthStatus} />
              )}
              {growth < 0 && (
                <DownArrow fontSize="inherit" status={growthStatus} />
              )}
              {isNaN(growth) ? growth : Math.abs(growth)}
              {!isNaN(growth) && "ppt"}
            </StatusText>
          </GrowthBlock>
        )}
      </Cell>
    );
  }
);

export default PercentageAndGrowthCell;
