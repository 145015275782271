import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  Toolbar,
} from "@material-ui/core";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
} from "@material-ui/icons";
import React, { memo } from "react";
import { drawerWidthClosed, drawerWidthOpen } from "./sideNavConstants";

import { CurrentStore } from "~/typedef/store";
import { DrawerMenuItem } from "./drawerMenuItem";
import { StoreLink } from "../myStoresToolbar/storeLink";
import { isUndefined } from "lodash";
import styled from "styled-components";
import { useSideNavConfig } from "~/hooks/useSideNavConfig";

export const TopLevelItem = styled(({ hasChildren, isMini, ...props }) => (
  <ListItem {...props} />
))`
  color: ${({ theme }) =>
    !isUndefined(theme.palette.sideToolbar)
      ? theme.palette.sideToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background-color: ${({ theme, selected }) =>
    selected
      ? `${
          !isUndefined(theme.palette.sideToolbar)
            ? theme.palette.sideToolbar.light
            : theme.palette.secondary.light
        }!important`
      : null};
`;

const CloseIcon = styled(IconButton)`
  margin-left: auto;
  padding: 0;
`;

const DesktopMenuToggle = styled(IconButton)<{ open?: boolean }>`
  z-index: 1201;
  position: absolute;
  background: ${({ theme }) =>
    !isUndefined(theme.palette.sideToolbar)
      ? theme.palette.sideToolbar.main
      : theme.palette.secondary.main};
  border: 2px solid
    ${({ theme }) =>
      !isUndefined(theme.palette.sideToolbar)
        ? theme.palette.sideToolbar.contrastText
        : theme.palette.secondary.contrastText};
  color: ${({ theme }) =>
    !isUndefined(theme.palette.sideToolbar)
      ? theme.palette.sideToolbar.contrastText
      : theme.palette.secondary.contrastText};
  padding: 0;
  height: 30px;
  width: 30px;
  left: ${({ open }) =>
    `calc(${open ? `${drawerWidthOpen}px` : `${drawerWidthClosed}px`} - 15px)`};
  top: 80px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  transition: margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  &:hover {
    background-color: ${({ theme }) =>
      !isUndefined(theme.palette.sideToolbar)
        ? theme.palette.sideToolbar.light
        : theme.palette.secondary.light};
  }
  & svg {
    fill: ${({ theme }) =>
      !isUndefined(theme.palette.sideToolbar)
        ? theme.palette.sideToolbar.contrastText
        : theme.palette.secondary.contrastText};
  }
`;

export const SubItem = styled(ListItem)``;

const SlidingDrawer = styled(Drawer)<{ open?: boolean }>`
  width: ${drawerWidthOpen}px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;
  color: ${({ theme }) =>
    !isUndefined(theme.palette.sideToolbar)
      ? theme.palette.sideToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    !isUndefined(theme.palette.sideToolbar)
      ? theme.palette.sideToolbar.main
      : theme.palette.secondary.main};
  ${({ open, theme }) =>
    open
      ? {
          width: `${drawerWidthOpen}px`,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [theme.breakpoints.down("xs")]: {
            width: "100vw",
          },
        }
      : {
          width: `${drawerWidthClosed}px`,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.down("sm")]: {
            width: 0,
          },
        }}
  & svg {
    fill: ${({ theme }) =>
      !isUndefined(theme.palette.sideToolbar)
        ? theme.palette.sideToolbar.contrastText
        : theme.palette.secondary.contrastText};
  }
`;

interface SideNavDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentStore?: CurrentStore;
}

export const SideNavDrawer = memo<SideNavDrawerProps>(function SideNavDrawer({
  open,
  setOpen,
  currentStore,
}) {
  const menuItems = useSideNavConfig();

  return (
    <>
      <SlidingDrawer variant="permanent" open={open} id="side-navigation">
        <Toolbar />
        <Hidden mdUp>
          <Toolbar />
        </Hidden>
        <List>
          <ListItem>
            <Box p={open ? 1 : 2} display="flex" flex="1 1 100%">
              {open && (
                <>
                  {currentStore && (
                    <Hidden xsDown>
                      <StoreLink
                        {...{
                          activeLink: false,
                          market: currentStore.marketplace,
                          marketplaceSubtype: currentStore.marketplaceSubtype,
                          countryCode: currentStore.marketplaceCountry,
                          mid: currentStore.merchantId,
                          shopName: currentStore.storeName,
                          fontSize: "large",
                        }}
                      />
                    </Hidden>
                  )}
                  <Hidden mdUp>
                    <CloseIcon
                      edge="end"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setOpen(false)}
                    >
                      <CloseRounded />
                    </CloseIcon>
                  </Hidden>
                </>
              )}
            </Box>
          </ListItem>
          {open && <Divider light />}
          {menuItems.map((menuItem) => (
            <DrawerMenuItem
              menuItem={menuItem}
              key={menuItem.id}
              setOpen={setOpen}
              open={open}
              currentStore={currentStore}
            />
          ))}
        </List>
      </SlidingDrawer>
      <Hidden smDown>
        <DesktopMenuToggle
          open={open}
          edge="end"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ChevronLeftRounded fontSize="small" />
          ) : (
            <ChevronRightRounded fontSize="small" />
          )}
        </DesktopMenuToggle>
      </Hidden>
    </>
  );
});
