import {
  DELETE_CONNECTION_STATUS,
  DELETE_CONNECTION_STATUS_ERROR,
} from "./connections.redux";

import axios from "axios";
import { baseUrl } from "../../configs";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const deleteConnectionStatus =
  ({ user, mid, sellerId }) =>
  async (dispatch) => {
    try {
      const res = await axios
        .post(`${baseUrl}/api/connection/deleteStatus`, {
          user: { _id: user._id },
          mid,
          sellerId,
        })
        .catch((e) => {
          throw e;
        });

      if (res && isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: DELETE_CONNECTION_STATUS,
          payload: data,
        });
      } else {
        throw new Error(
          `${
            res.status
          } deleteConnectionStatus failed with error ${JSON.stringify(
            res.data
          )}`
        );
      }
    } catch (e) {
      return dispatch({
        type: DELETE_CONNECTION_STATUS_ERROR,
        payload: e,
      });
    }
  };
