import { AmazonCountry, AmazonRegion } from "mm-amazon-common/dist/typedef/mws";
import { Box, IconButton } from "@material-ui/core";
import React, { ChangeEvent } from "react";

import AmazonAuthorise from "~/modules/login/amazonAuthorise";
import AmazonAuthoriseVendor from "~/modules/login/amazonAuthoriseVendor";
import { ArrowBack } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Medium from "~/components/typography/medium";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import Typography from "@material-ui/core/Typography";
import { getCountryCodeISO3ToName } from "~/utils/countryUtils";
import { marketplace as getMarketplace } from "mm-amazon-common/dist/mwsUtils";
import isEmpty from "lodash/isEmpty";
import { loadInfo } from "~/store/user.redux";
import { regionalCountryCodes } from "mm-amazon-common/dist/mwsConstants";
import { storeTempSellerId } from "~/store/connections/connectAmazon.redux";
import { strcmp } from "@merchantspring/common";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { v4 as uuidv4 } from "uuid";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin-left: -1.5rem;
`;

const BackButton = styled(IconButton)`
  margin: -15px 0 0 -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const topCountries = [
  {
    value: "USA",
    label: "United States",
  },
  {
    value: "GBR",
    label: "United Kingdom",
  },
  {
    value: "DEU",
    label: "Germany",
  },
  {
    value: "JPN",
    label: "Japan",
  },
];

const NORTH_AMERICA = "USA";

const countries = Object.keys(regionalCountryCodes)
  .reduce<
    {
      value: string;
      label: string;
    }[]
  >((acc, regionKey) => {
    const regionCountries = regionalCountryCodes[regionKey as AmazonRegion];

    const countryOptions = regionCountries.map((countryCode) => ({
      value: countryCode,
      label: getCountryCodeISO3ToName(countryCode),
    }));

    return acc.concat(countryOptions);
  }, [])
  .filter((a) => !topCountries.find((b) => b.value === a.value))
  .sort((a, b) => strcmp(a.label, b.label));

const AmazonConnectPage = ({
  setIsSubmitting,
  onSuccess,
}: {
  setIsSubmitting?: (bool: boolean) => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlSearchParams = useQueryParams();
  const marketplace = urlSearchParams.get("marketplace");
  const isAmazonVendor = marketplace === "amazon_vendor";
  const user = useTypedSelector((state) => state.user);

  const [countryCode, setCountryCode] = React.useState("");
  const errMsg = React.useRef("");

  const generateAmazonAuthorization = (selectedCountryCode: AmazonCountry) => {
    const hostname = isAmazonVendor
      ? getMarketplace(selectedCountryCode).vendorHost
      : `sellercentral.${getMarketplace(selectedCountryCode).retailHost}`;
    const url = new URL(`https://${hostname}/apps/authorize/consent`);

    const urlParams = new URLSearchParams(
      process.env.TARGET_ENV === "production"
        ? {
            // eslint-disable-next-line no-undef
            application_id: AMAZON_APP_ID,
            state: `${uuidv4()}_${selectedCountryCode}`,
          }
        : {
            // eslint-disable-next-line no-undef
            application_id: AMAZON_APP_ID,
            state: `${uuidv4()}_${selectedCountryCode}`,
            version: "beta", // needed in staging, since app is not published
          }
    );
    url.search = urlParams.toString();

    return url.toString();
  };

  const redirectNextStep = (selectedCountryCode: string) => {
    urlSearchParams.set("step", "1");
    urlSearchParams.set(
      "marketplace",
      isAmazonVendor ? "amazonVendorUpdateStoreName" : "amazonUpdateStoreName"
    );
    urlSearchParams.set("selectedCountry", selectedCountryCode);
    history.push(`/connectStore/select?${urlSearchParams.toString()}`);
  };

  const onAuthSuccess = async (sellerId: string, state: string) => {
    setIsSubmitting && setIsSubmitting(false);
    // store sellerId to use in succeeding steps
    await dispatch(storeTempSellerId(sellerId));

    //refresh user info before redirecting
    await dispatch(loadInfo());

    const stateData = state.split("_");
    const selectedCountryCode = stateData[1];

    if (isEmpty(selectedCountryCode)) {
      errMsg.current = "Error encountered, country is missing";
    } else {
      onSuccess();
      redirectNextStep(selectedCountryCode);
    }
  };

  const onAuthError = () => {
    setIsSubmitting && setIsSubmitting(false);
    errMsg.current = t("connectWizard.connectionError");
  };

  return (
    <GridWrapper id="amazon-connect-wrapper">
      <Grid container spacing={5}>
        <Grid item xs={2}>
          <BackButton onClick={history.goBack} id="back-button">
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item xs={10}>
          <TitleWrapper>
            <Medium color="textPrimary">
              {t(
                marketplace === "amazon_vendor"
                  ? "connectWizard.amazonVendorLinkTitle"
                  : "connectWizard.amazonLinkTitle"
              )}
            </Medium>
          </TitleWrapper>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start">
          <Medium color="textSecondary">
            {t("connectWizard.amazonCountryLabel")}
          </Medium>
          <Box pt={1} pb={1} width={1}>
            <NativeSelect
              name={"countryCode"}
              value={countryCode}
              topOptions={topCountries}
              options={countries}
              onChange={(
                e: ChangeEvent<{ name?: string; value: unknown }>,
                _child
              ) => {
                const selectedCountry = topCountries
                  .concat(countries)
                  .find((country) => country.value === e.target.value);
                if (selectedCountry) {
                  setCountryCode(selectedCountry.value);
                }
              }}
              autoFocus={true}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            {isAmazonVendor ? (
              <AmazonAuthoriseVendor
                {...{
                  disabled: !countryCode,
                  url: generateAmazonAuthorization(
                    (countryCode || NORTH_AMERICA) as AmazonCountry
                  ),
                  onSuccess: onAuthSuccess,
                  onError: onAuthError,
                }}
              >
                {t("connectWizard.authorizeButton")}
              </AmazonAuthoriseVendor>
            ) : (
              <AmazonAuthorise
                {...{
                  disabled: !countryCode,
                  url: generateAmazonAuthorization(
                    (countryCode || NORTH_AMERICA) as AmazonCountry
                  ),
                  onSuccess: onAuthSuccess,
                  onError: onAuthError,
                  setIsSubmitting,
                }}
              >
                {t("connectWizard.authorizeButton")}
              </AmazonAuthorise>
            )}
          </Grid>
        </Grid>
        <>
          {errMsg && errMsg.current && (
            <Grid container item xs={12}>
              <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default AmazonConnectPage;
