import React, { memo } from "react";

import PanelSelect from "~/components/select/panelSelect";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FilterActionsComponent = ({ currentFilter, switchFilter }) => {
  const { t } = useTranslation();
  const SPACER = "\u00A0\u00A0";
  const FILTER_OPTIONS = [
    {
      value: "losing",
      label: t("myStoresWidget.buyBoxPerformance.losingLabel"),
    },
    {
      value: "losingToAmazon",
      label: `${SPACER} ${t(
        "myStoresWidget.buyBoxSummary.losingToAmazonLabel"
      )}`,
    },
    {
      value: "losingToOthers",
      label: `${SPACER} ${t(
        "myStoresWidget.buyBoxSummary.losingToOthersLabel"
      )}`,
    },
    {
      value: "losingToSelf",
      label: `${SPACER} ${t("myStoresWidget.buyBoxSummary.losingToSelfLabel")}`,
    },
    {
      value: "noWinner",
      label: `${SPACER} ${t("myStoresWidget.buyBoxSummary.noWinnerLabel")}`,
    },
    {
      value: "winning",
      label: t("myStoresWidget.buyBoxPerformance.winningLabel"),
    },
    {
      value: "notParticipating",
      label: t("myStoresWidget.buyBoxPerformance.inactiveListingLabel"),
    },
    {
      value: "noOffers",
      label: t("myStoresWidget.buyBoxSummary.gatheringDataLabel"),
    },
  ];

  const handleClick = (value) => {
    switchFilter(value);
  };

  return (
    <PanelSelect
      currentValue={
        FILTER_OPTIONS.find((option) => option.value === currentFilter).label
      }
      handleChange={handleClick}
      options={FILTER_OPTIONS}
    />
  );
};

FilterActionsComponent.propTypes = {
  switchFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired,
};

export const FilterActions = memo(FilterActionsComponent);
export default FilterActions;
