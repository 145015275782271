import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";

const SyncResultCell = memo(({ cell }) => {
  if (!cell.value || cell.value.length === 0) {
    return <></>;
  }

  return (
    <Grid container>
      <Typography variant="body2" noWrap>
        <ul>
          {cell.value.map((e) => (
            <li key={e.MessageID}>
              {e.ResultCode}: {e.AdditionalInfo.SKU}
            </li>
          ))}
        </ul>
      </Typography>
    </Grid>
  );
});

SyncResultCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default SyncResultCell;
