import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import React from "react";
import ReportTemplateTable from "~/modules/widgets/reportTemplateTable";
import { memo } from "react";

const ReportTemplateList = memo(function ReportTemplateList() {
  return (
    <PageBlock>
      <Grid container>
        <ReportTemplateTable />
      </Grid>
    </PageBlock>
  );
});

export default ReportTemplateList;
