import { ArrowDownward, ArrowForward } from "@material-ui/icons";
import { Box, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReportOption, ReportOptionSection } from "~/pages/reports/reportUtils";

import { DraggableCard } from "./draggableCard";
import { DroppableContainer } from "./droppableContainer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DragContainer = styled(Paper)`
  border: 1px solid ${({ theme }) => theme.palette.border.dark};
  overflow: hidden;
`;

const DragWrapper = styled(Paper)`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 85%;
  max-height: 300px;
  overflow-y: auto;
`;

const DropWrapper = styled(DragWrapper)`
  background-color: ${({ theme }) => theme.palette.border.main};
`;

const ClickableBox = styled(Box)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    background: ${({ theme }) => theme.palette.border.main};
  }
`;

export type Option = Omit<ReportOption, "navId"> & {
  selectedConfiguration?: any;
};

interface DragAndDropProps {
  allOptions: ReportOptionSection[];
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
}

const DragAndDrop = ({
  allOptions,
  selectedOptions,
  setSelectedOptions,
}: DragAndDropProps) => {
  const { t } = useTranslation();

  const getOptionsLeft = () => {
    return allOptions.map((sectionOptions) => {
      const section = {
        ...sectionOptions,
        items: sectionOptions.items.filter(
          (item) =>
            !Boolean(
              selectedOptions.find(
                (selectedOption: Option) => item.id === selectedOption.id
              )
            )
        ),
      };
      return section;
    });
  };
  const [localOptions, setLocalOptions] = useState<ReportOptionSection[]>(
    getOptionsLeft()
  );

  useEffect(() => {
    if (!selectedOptions.length) {
      setLocalOptions(allOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    setLocalOptions(getOptionsLeft());
  }, [selectedOptions]);

  const selectAll = () => {
    const options: Option[] = allOptions.reduce((acc, section) => {
      const sectionOptions = section.items.map((item) => {
        return {
          ...item,
          position: item.position - 1,
          dropped: true,
        };
      });
      return [...acc, ...sectionOptions];
    }, [] as Option[]);
    setSelectedOptions(options);
    setLocalOptions([]);
  };

  const moveCard = useCallback(
    (item: Option) => {
      if (!item.dropped) {
        const newLocalOptions = localOptions.map((sectionOptions) => {
          const section = {
            ...sectionOptions,
            items: sectionOptions.items.filter(
              (localOption) => localOption.id !== item.id
            ),
          };
          return section;
        });
        setLocalOptions(newLocalOptions);
        setSelectedOptions([
          ...selectedOptions,
          {
            ...item,
            position: selectedOptions.length,
            dropped: true,
          },
        ]);
      }
    },
    [selectedOptions]
  );

  const onRemove = (id: string) => {
    const newLocalOptions = allOptions.map((sectionOptions) => {
      const section = {
        ...sectionOptions,
        items: sectionOptions.items.filter(
          (sectionOption) =>
            Boolean(sectionOption.id === id) ||
            !selectedOptions.some(
              (selectedOption: Option) => selectedOption.id === sectionOption.id
            )
        ),
      };
      return section;
    });
    const newSelectedOptions = selectedOptions
      .filter((localOption) => localOption.id !== id)
      .map((option, i) => ({ ...option, position: i }));
    setSelectedOptions(newSelectedOptions);
    setLocalOptions(newLocalOptions);
  };

  const sortCard = useCallback(
    (dragId: string, hoverId: string) => {
      const dragCard = selectedOptions.find(
        (selectedOption) => selectedOption.id === dragId
      );
      const hoverCard = selectedOptions.find(
        (selectedOption) => selectedOption.id === hoverId
      );
      if (dragCard && hoverCard) {
        const newSelectedOptions = selectedOptions.map((oldSelectedOption) => {
          if (oldSelectedOption.id === dragCard.id) {
            return { ...oldSelectedOption, position: hoverCard.position };
          }
          if (
            // Moving up
            hoverCard.position < dragCard.position &&
            oldSelectedOption.position >= hoverCard.position &&
            oldSelectedOption.position < dragCard.position
          ) {
            return {
              ...oldSelectedOption,
              position: oldSelectedOption.position + 1,
            };
          }
          if (
            // Moving down
            hoverCard.position > dragCard.position &&
            oldSelectedOption.position <= hoverCard.position &&
            oldSelectedOption.position > dragCard.position
          ) {
            return {
              ...oldSelectedOption,
              position: oldSelectedOption.position - 1,
            };
          }
          return oldSelectedOption;
        });
        setSelectedOptions(newSelectedOptions);
      }
    },
    [selectedOptions]
  );

  const renderOption = (position: number) => {
    const matchingOption = selectedOptions.find(
      (selectedOption) => selectedOption.position === position
    );
    if (!matchingOption) {
      return null;
    }
    return (
      <DraggableCard
        {...matchingOption}
        index={matchingOption.position}
        sortCard={sortCard}
        onRemove={onRemove}
      />
    );
  };

  const renderOptions = () => {
    let optionNumber = 0;
    return allOptions.map((option, i) =>
      selectedOptions.length <= 0 && i === 3 ? (
        <Box key={i} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" color="textSecondary" align="center">
            {t("createReport.reportElementsPlaceholder")}
          </Typography>
        </Box>
      ) : (
        option.items.map(() => {
          optionNumber++;
          return (
            <DroppableContainer key={optionNumber} moveCard={moveCard}>
              {renderOption(optionNumber - 1)}
            </DroppableContainer>
          );
        })
      )
    );
  };

  return (
    <Box display="flex" alignItems="stretch" width="100%">
      <Grid container alignItems="stretch">
        <Grid item xs={12} md={5}>
          <Typography variant="body1">
            {t("createReport.widgetsList")}
          </Typography>
          <DragContainer>
            <DragWrapper elevation={0}>
              {localOptions.map((option, i) => (
                <Fragment key={i}>
                  <Box pt={1} pb={1}>
                    <Typography variant="body2" color="textSecondary">
                      {t(option.title)}
                    </Typography>
                  </Box>
                  {option.items.map((item) => (
                    <DraggableCard
                      key={item.id}
                      {...item}
                      index={item.position}
                      sortCard={sortCard}
                    />
                  ))}
                </Fragment>
              ))}
            </DragWrapper>
          </DragContainer>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <ClickableBox onClick={selectAll}>
              <Hidden smDown>
                <ArrowForward />
              </Hidden>
              <Hidden mdUp>
                <ArrowDownward />
              </Hidden>
            </ClickableBox>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="body1">
            {t("createReport.reportElements")}
          </Typography>
          <DragContainer>
            <DropWrapper elevation={0}>{renderOptions()}</DropWrapper>
          </DragContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DragAndDrop;
