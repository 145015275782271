import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { FORECAST_TYPE, REFERENCE_LEVEL } from "./types";
import React, { useEffect, useState } from "react";
import {
  StyledCloseIcon,
  StyledDoneIcon,
  StyledEditIcon,
} from "~/modules/buybox/editablePriceCell";

import { Moment } from "moment";
import { intFormatter } from "~/utils/currencyUtils";
import styled from "styled-components";

interface EditableChipProps {
  item: {
    amount: number;
    currency: string;
  };
  type: FORECAST_TYPE;
  level: REFERENCE_LEVEL;
  onEdit?: (
    item: {
      type: FORECAST_TYPE;
      referenceLevel: REFERENCE_LEVEL;
      amount: number;
    },
    date: Moment
  ) => void;

  colors: Record<FORECAST_TYPE, string>;
  setEditable: (editable: boolean) => void;
  editable: boolean;
  date: Moment;
  setSupportDialogOpen: (supportDialogOpen: boolean) => void;
}

const Indicator = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  height: 12px;
  width: 12px;
  margin: 1px;
  border-radius: 50%;
  margin-top: 2px;
`;

const StyledGrid = styled(Grid)<{ $edit: boolean }>`
  width: ${({ $edit }) => ($edit ? "80%" : "50%")};
`;

const StyledIndicatorGrid = styled(Grid)`
  width: 13%;
`;

const TextFieldWithPadding = styled(TextField)`
  padding: 1px 0 1px 0;
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: -5px;
`;

export const EditableChip: React.FC<EditableChipProps> = ({
  item,
  colors,
  type,
  level,
  onEdit,
  setEditable,
  editable,
  setSupportDialogOpen,
  date,
}) => {
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [amount, setAmount] = useState(intFormatter.format(item.amount));
  useEffect(() => {
    setAmount(intFormatter.format(item.amount));
  }, [item]);
  return (
    <Box
      display="flex"
      width="100%"
      data-testid="editable-chip"
      alignItems="space-between"
      justifyContent="flex-start"
    >
      <StyledIndicatorGrid item container alignItems="center">
        <Indicator
          data-testid="indicator-editable-chip"
          $color={colors[type]}
        />
      </StyledIndicatorGrid>

      <StyledGrid item container $edit={edit}>
        <TextFieldWithPadding
          size="small"
          data-testid="editable-chip"
          autoFocus
          type="text"
          disabled={edit === false}
          variant="outlined"
          color="primary"
          value={amount}
          inputProps={{
            "data-testid": "chip-input",
            style: {
              ...theme.typography.body2,
              width: "auto",
              height: "3px",
              borderRadius: "12px",
              paddingLeft: "4px",
              paddingRight: "4px",
              textAlign: "right",
            },
          }}
          InputProps={{
            endAdornment: !edit ? (
              <StyledInputAdornment position="end">
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    aria-label="Edit"
                    onClick={() => {
                      if (editable == true) {
                        setSupportDialogOpen(true);
                      } else {
                        setEdit(!edit);
                        setEditable(true);
                      }
                    }}
                    edge="end"
                  >
                    <StyledEditIcon data-testid="edit-icon" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </StyledInputAdornment>
            ) : (
              <>
                <Tooltip title="Save">
                  <IconButton
                    data-testid="done-icon"
                    size="small"
                    aria-label="Save"
                    onClick={() => {
                      setEdit(false);
                      setEditable(false);
                      const amountInt = Number(amount.replace(/,/g, ""));
                      if (amountInt !== item.amount && onEdit) {
                        const newItem = {
                          referenceLevel: level,
                          type,
                          amount: amountInt,
                        };
                        onEdit(newItem, date);
                      }
                    }}
                  >
                    <StyledDoneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    size="small"
                    aria-label="Cancel"
                    onClick={() => {
                      setEdit(false);
                      setEditable(false);
                      setAmount(intFormatter.format(item.amount));
                    }}
                  >
                    <StyledCloseIcon
                      data-testid="close-icon"
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </StyledGrid>
    </Box>
  );
};
