import {
  Bar,
  ComposedChart,
  Label,
  LabelProps,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import React, { memo, useMemo } from "react";
import { getCurrencySymbol, intFormatterRounded } from "~/utils/currencyUtils";
import {
  getEvenTicks,
  getEvenTicksFromZero,
  useLayoutProps,
} from "../chartUtils/chartComponents";
import {
  getNearestHundredOrTen,
  getNearestHundredOrTenFloor,
  getNearestPowerOfTenFloor,
} from "../chartUtils/chartUtils";

import { AxisInterval } from "recharts/types/util/types";
import BarChartNoData from "~/components/placeholders/barChartNoData";
import ComboChartLegend from "../comboChart/ComboChartLegend";
import ComboChartTooltip from "../comboChart/ComboChartTooltip";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

interface PlotProps {
  key: string;
  colour: string;
  toFixed?: number;
}

interface StackedBarAndLineChartProps {
  title: string;
  currentPeriod: DATETIME_PERIODS;
  interval: INTERVAL;
  currentCurrency: string;
  chartData: any[];
  isLoading: boolean;
  bottomBar: PlotProps;
  topBar?: PlotProps;
  line?: PlotProps;
  totalsKey?: string;
  xKey: string;
  axisLabels?: { x?: LabelProps; y?: LabelProps };
  view?: string;
  report?: boolean;
  barCanContainNegativeValues?: boolean;
  timezone: string;
  lineUnit?: string;
}

const StackedBarAndLineChart = memo<StackedBarAndLineChartProps>(
  function StackedBarAndLineChart({
    title,
    chartData = [],
    currentPeriod,
    interval,
    currentCurrency,
    isLoading,
    bottomBar,
    topBar,
    totalsKey,
    line,
    xKey,
    view,
    report,
    barCanContainNegativeValues,
    timezone,
    lineUnit,
    axisLabels,
  }) {
    const {
      key: lineKey,
      colour: lineColor,
      toFixed: lineToFixed,
    } = line || {};
    const {
      key: topBarKey,
      colour: topBarColor,
      toFixed: topBarToFixed,
    } = topBar || {};
    const {
      key: bottomBarKey,
      colour: bottomBarColor,
      toFixed: bottomBarToFixed,
    } = bottomBar;

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { height, margin } = useLayoutProps("comboChart", report);

    const { dataMinBar, dataMaxBar, dataMinLine, dataMaxLine } = useMemo(() => {
      const dataMinBar = barCanContainNegativeValues
        ? chartData &&
          getNearestHundredOrTenFloor(
            Math.min.apply(
              Math,
              chartData
                .map((o) => o[bottomBarKey])
                .concat(topBarKey ? chartData.map((o) => o[topBarKey]) : [])
            )
          )
        : 0;
      const dataMaxBar =
        chartData &&
        getNearestHundredOrTen(
          Math.max.apply(
            Math,
            chartData
              .map((o) => o[bottomBarKey])
              .concat(
                topBarKey
                  ? chartData.map((o) => o[topBarKey] + o[bottomBarKey])
                  : []
              )
          )
        );

      const dataMinLine =
        chartData && lineUnit === "%" && barCanContainNegativeValues
          ? -100
          : lineKey && barCanContainNegativeValues
          ? getNearestHundredOrTen(
              Math.min.apply(
                Math,
                chartData.map((o) => o[lineKey])
              )
            )
          : 0;

      const dataMaxLine =
        chartData && lineUnit === "%" && barCanContainNegativeValues
          ? 100
          : lineKey
          ? getNearestHundredOrTen(
              Math.max.apply(
                Math,
                chartData.map((o) => o[lineKey])
              )
            )
          : 0;

      return {
        dataMinBar,
        dataMaxBar,
        dataMinLine,
        dataMaxLine,
      };
    }, [
      chartData,
      barCanContainNegativeValues,
      bottomBarKey,
      topBarKey,
      lineKey,
      lineUnit,
    ]);

    const rightTicks = useMemo(() => {
      return dataMinLine < 0
        ? getEvenTicks(dataMinLine, dataMaxLine, 5)
        : getEvenTicksFromZero(
            getNearestHundredOrTen(dataMaxLine),
            5,
            Math.max(10, getNearestPowerOfTenFloor(dataMaxLine / 100))
          );
    }, [dataMinLine, dataMaxLine]);

    const leftTicks = useMemo(() => {
      return dataMinBar < 0
        ? getEvenTicks(dataMinBar, dataMaxBar, 5)
        : getEvenTicksFromZero(
            getNearestHundredOrTen(dataMaxBar),
            5,
            Math.max(10, getNearestPowerOfTenFloor(dataMaxBar / 100))
          );
    }, [dataMinBar, dataMaxBar]);

    const getXAxisProps = () => {
      if (
        [DATETIME_PERIODS.WEEK, DATETIME_PERIODS.WEEKMTS].includes(
          currentPeriod
        )
      ) {
        const tickFormatter = (tick: number) =>
          moment.unix(tick).tz(timezone).format("ddd");
        return {
          dataKey: xKey,
          dy: 10,
          tickLine: false,
          tick: { fontSize: 14 },
          tickFormatter,
        };
      } else {
        let tickFormatter;
        if (interval === INTERVAL.MONTHS) {
          tickFormatter = (tick: number) =>
            moment
              .unix(tick)
              .tz(timezone)
              .add(12, "h")
              .startOf("day")
              .format("MMM");
        } else if (interval === INTERVAL.HOURS) {
          tickFormatter = (tick: number) =>
            moment.unix(tick).tz(timezone).format("HH");
        } else {
          tickFormatter = (tick: number) =>
            moment.unix(tick).tz(timezone).format("D MMM");
        }
        const tickCount = interval === INTERVAL.HOURS ? 13 : 3;
        return {
          dataKey: xKey,
          dy: 10,
          type: "category",
          interval: "preserveStartEnd" as AxisInterval,
          tickLine: false,
          tickCount: tickCount,
          domain: ["dataMin", "dataMax"],
          padding: "gap",
          tick: { fontSize: 14 },
          tickFormatter,
        } as XAxisProps;
      }
    };

    const legendData = [
      {
        name: `chartKey.${bottomBarKey}`,
        fillColor: bottomBarColor,
        shape: "bar",
        unit: view?.toLowerCase().includes("units")
          ? ""
          : getCurrencySymbol[
              currentCurrency as keyof typeof getCurrencySymbol
            ],
        isInEndUnit: false,
        ...(bottomBarToFixed ? { toFixed: bottomBarToFixed } : {}),
      },
      ...(topBarKey && topBarColor
        ? [
            {
              name: `chartKey.${topBarKey}`,
              fillColor: topBarColor,
              shape: "bar",
              unit: view?.toLowerCase().includes("units")
                ? ""
                : getCurrencySymbol[
                    currentCurrency as keyof typeof getCurrencySymbol
                  ],
              isInEndUnit: false,
              ...(topBarToFixed ? { toFixed: topBarToFixed } : {}),
            },
          ]
        : []),
      ...(lineKey && lineColor
        ? [
            {
              name: `chartKey.${lineKey}`,
              fillColor: lineColor,
              shape: "line",
              unit:
                lineUnit === "currency"
                  ? getCurrencySymbol[
                      currentCurrency as keyof typeof getCurrencySymbol
                    ]
                  : lineUnit ?? "",
              isInEndUnit: lineUnit === "currency" ? false : true,
              ...(lineToFixed ? { toFixed: lineToFixed } : {}),
            },
          ]
        : []),
    ];

    const LegendComponent = () => <ComboChartLegend legendItems={legendData} />;

    const tooltipProps = topBarKey
      ? {
          barTooltipProps: legendData[0],
          barTooltipProps2: legendData[1],
          lineTooltipProps: legendData[2],
        }
      : {
          barTooltipProps: legendData[0],
          lineTooltipProps: legendData[1],
        };
    const totalsTooltipProps = totalsKey
      ? {
          name: `chartKey.${totalsKey}`,
          fillColor: "black", // Same as tooltip backgorund color
          shape: "bar",
          unit: view?.toLowerCase().includes("units")
            ? ""
            : getCurrencySymbol[
                currentCurrency as keyof typeof getCurrencySymbol
              ],
          isInEndUnit: false,
          ...(bottomBarToFixed ? { toFixed: bottomBarToFixed } : {}),
        }
      : undefined;

    const yAxisLeftTickFormatter = (tick: number) => {
      if (view?.toLowerCase().includes("units")) {
        return intFormatterRounded.format(tick);
      } else {
        return (
          getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol] +
          intFormatterRounded.format(tick)
        );
      }
    };

    const yAxisRightTickFormatter = (tick: number) => {
      if (lineUnit === "currency") {
        return (
          getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol] +
          intFormatterRounded.format(tick)
        );
      } else {
        return intFormatterRounded.format(tick);
      }
    };

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box pb={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isEmpty(chartData) && !isLoading ? (
            <BarChartNoData {...{ currentPeriod }} />
          ) : (
            <ResponsiveContainer width="100%" height={height}>
              <ComposedChart
                data={chartData}
                margin={{
                  top: margin.top,
                  bottom:
                    axisLabels?.x && axisLabels.x.offset
                      ? margin.bottom + axisLabels.x.offset * 2 // we want the label to be centered in the padding
                      : margin.bottom,
                  left:
                    axisLabels?.y && axisLabels.y.offset
                      ? margin.left + axisLabels.y.offset * 2 // we want the label to be centered in the padding
                      : margin.left,
                  right: margin.right,
                }}
                stackOffset="sign"
              >
                <XAxis {...getXAxisProps()}>
                  {axisLabels?.x && <Label {...axisLabels.x} />}
                </XAxis>
                <YAxis
                  yAxisId="left"
                  {...{
                    tickFormatter: yAxisLeftTickFormatter,
                    dataKey: topBarKey,
                    tick: { fontSize: 14 },
                    domain: [dataMinBar, dataMaxBar],
                    ticks: leftTicks,
                    width: 80,
                  }}
                >
                  {axisLabels?.y && <Label {...axisLabels.y} />}
                </YAxis>
                {lineKey && dataMaxLine && (
                  <YAxis
                    yAxisId="right"
                    {...{
                      tickFormatter: yAxisRightTickFormatter,
                      dataKey: lineKey,
                      orientation: "right",
                      tick: { fontSize: 14 },
                      domain: [dataMinLine, dataMaxLine],
                      ticks: rightTicks,
                    }}
                  >
                    {axisLabels?.y && <Label {...axisLabels.y} />}
                  </YAxis>
                )}
                <ReferenceLine yAxisId="left" y={0} strokeWidth={2} />
                <Tooltip
                  content={
                    <ComboChartTooltip
                      currentPeriod={currentPeriod}
                      barTooltipProps={tooltipProps.barTooltipProps}
                      barTooltipProps2={tooltipProps.barTooltipProps2}
                      lineTooltipProps={tooltipProps.lineTooltipProps}
                      totalsTooltipProps={totalsTooltipProps}
                      lineKey={lineKey}
                      barKey={bottomBarKey}
                      barKey2={topBarKey}
                      headerKey={xKey}
                      totalsKey={totalsKey}
                      timezone={timezone}
                      interval={interval}
                    />
                  }
                />
                <Legend
                  content={LegendComponent}
                  {...{
                    verticalAlign: "top",
                    align: "left",
                    wrapperStyle: { top: -16, fontSize: "12px" },
                  }}
                />
                <Bar
                  yAxisId="left"
                  stackId="a"
                  {...{
                    dataKey: bottomBarKey,
                    fill: bottomBarColor,
                  }}
                />
                {topBarKey && (
                  <Bar
                    yAxisId="left"
                    stackId="a"
                    {...{
                      dataKey: topBarKey,
                      fill: topBarColor,
                    }}
                  />
                )}
                {lineKey && dataMaxLine && (
                  <Line
                    yAxisId="right"
                    {...{
                      dataKey: lineKey,
                      stroke: lineColor,
                      dot: false,
                      strokeWidth: smDown ? 1 : 2,
                    }}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default StackedBarAndLineChart;
