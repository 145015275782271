import { Avatar, Box, Grid, Paper, Typography } from "@material-ui/core";
import { GTranslateRounded, WarningRounded } from "@material-ui/icons";
import React, { useState } from "react";

import Bold from "../../components/typography/bold";
import styled from "styled-components";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const IconWrapper = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.error.dark};
  height: 35px;
  padding-bottom: 5px;
  margin-right: 1rem;
`;

const ERROR_TYPES = {
  DEFAULT: "default",
  TRANSLATE: "gt",
};

const errorMessagesByType: Record<
  string,
  { title: string; subtitle: string; message: string }
> = {
  default: {
    title: "errorPage.error.title",
    subtitle: "errorPage.error.subtitle",
    message: "errorPage.error.message",
  },
  gt: {
    title: "errorPage.gtError.title",
    subtitle: "errorPage.gtError.subtitle",
    message: "errorPage.gtError.message",
  },
  maxAge: {
    title: "errorPage.maxAgeError.title",
    subtitle: "errorPage.maxAgeError.subtitle",
    message: "errorPage.maxAgeError.message",
  },
};

interface ErroredWidgetContentProps {
  error: null | Error;
}

export const ErroredWidgetContent: React.FC<ErroredWidgetContentProps> = ({
  error,
}) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const errorType = queryParams.get("errorType");
  const [type, setType] = useState(
    errorType && errorMessagesByType[errorType]
      ? errorType
      : ERROR_TYPES.DEFAULT
  );

  if (error && error.name && error.message) {
    const { name, message } = error;
    if (
      type === ERROR_TYPES.DEFAULT &&
      (name === "NotFoundError" || message.includes("'Node'"))
    ) {
      setType(ERROR_TYPES.TRANSLATE);
    }
  }

  return (
    <Paper>
      <Box width="100%" maxWidth={540} mx="auto" py={4} px={2}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item container alignItems="center" justifyContent="center">
            <IconWrapper>
              <WarningRounded color="primary" />
            </IconWrapper>
            <Bold variant="h1">{t(errorMessagesByType[type].title)}</Bold>
          </Grid>
          <Grid item>
            {type === ERROR_TYPES.TRANSLATE && (
              <GTranslateRounded fontSize="large" />
            )}
            <Typography variant="body1" align="center">
              {t(errorMessagesByType[type].subtitle)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t(errorMessagesByType[type].message)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
