const _ = require("lodash");
const { getCategoryName } = require("../../utils");

/*
compareResult is result for the items in one category:
[
{itemId: "332568753031", compareDetails: Array, missing: 16, invalid: 1, correct: 14},
{itemId: "332705830157", compareDetails: Array, missing: 14, invalid: 4, correct: 13},
{itemId: "332802355593", compareDetails: Array, missing: 14, invalid: 3, correct: 14},
]
validationRules is Array:
 ["Required", "Optional", "Recommended"]
 */
// Given an compareResult, return an object of the form
// {
//     missing: <int>
//     invalid: <int>
//     correct: <int>
//      total: <int>
//      missingPercentage: <Float>
//      invalidPercentage: <Float>
//      correctPercentage: <Float>
// },
// where each int represents the total number of fields in the item in each
// bucket.
const itemSpecificTotals = (compareResult, validationRules) => {
  if (!compareResult || !compareResult.length) {
    return {
      missing: 0,
      invalid: 0,
      correct: 0,
    };
  }

  let _compareResult = compareResult.map((item) => {
    let _compareDetails = item.compareDetails.filter((i) =>
      validationRules.includes(i.validationRules)
    );
    let missing = _compareDetails.filter((i) => i.flag === "missing").length;
    let invalid = _compareDetails.filter((i) => i.flag === "invalid").length;
    let correct = _compareDetails.filter((i) => i.flag === "correct").length;

    return {
      ...item,
      missing,
      invalid,
      correct,
    };
  });

  const missing = _compareResult.reduce(
    (acc, i) => acc + parseInt(_.get(i, "missing", 0)),
    0
  );
  const invalid = _compareResult.reduce(
    (acc, i) => acc + parseInt(_.get(i, "invalid", 0)),
    0
  );
  const correct = _compareResult.reduce(
    (acc, i) => acc + parseInt(_.get(i, "correct", 0)),
    0
  );

  let total = missing + invalid + correct;
  let missingPercentage =
    total !== 0 ? parseFloat(Math.floor(100 * missing) / total / 100.0) : 0;
  let invalidPercentage =
    total !== 0 ? parseFloat(Math.floor(100 * invalid) / total / 100.0) : 0;
  let correctPercentage =
    total !== 0 ? parseFloat(Math.floor(100 * correct) / total / 100.0) : 0;

  return {
    missing,
    invalid,
    correct,
    total,
    missingPercentage,
    invalidPercentage,
    correctPercentage,
  };
};

/** Some fields are allowed to be missing, because of business logic
considerations */
const allowMissingField = (fieldName, otherFields) => {
  /* Some fields are dependent on others - this object maps field names to
   the fields and values that they are dependent on */
  const dependentFields = {
    ["Bundle Description"]: {
      dependsOn: "Custom Bundle",
      value: "Yes",
    },
    ["Modification Description"]: {
      dependsOn: "Modified Item",
      value: "Yes",
    },
  };
  const dependentField = dependentFields[fieldName];
  // If the value of the dependency in the item that we have isn't equal
  // to the required value, then we don't to check the dependent
  if (dependentField) {
    return !(
      (otherFields.find((f) => f.name === dependentField.dependsOn) || {})
        .value || []
    ).includes(dependentField.value);
  }

  const whitelistedFields = ["MPN", "Brand"];
  return whitelistedFields.includes(fieldName);
};

/** Return true iff the given value is in the list of accepted values */
const isFieldValid = (field, acceptedValues) => {
  return (
    acceptedValues === null ||
    _.intersection(acceptedValues, field.value).length === field.value.length
  );
};

/** A flag indiciating the correctness of a particular item specifics field
 * @typedef {("correct" | "invalid" | "missing")} FieldStatus */

/** @typedef {Object} AspectConstraint
 * @property {("SINGLE"|"MULTI")} itemToAspectCardinality
 */

/** @typedef {Object} CategorySpecific
  @property {string} name
  @property {AspectConstraint} aspectConstraint
  @property {(string[]|null)} valueRecommendation
  @property {string} validationRules
 * */

/** @typedef {Object} ItemSpecific
 * @property {string} name
 * @property {string[]} value */

/** @typedef {('select'|'mSelect'|'text')} InputType - an input type for
 * item specifics fields; used when rendering input fields for users to edit
 * item specifics */

/** Given the list of item specifics for an item, and the recommended values
 * for a particular item specific in that list, return a value indicating if
 * that field is correctly filled in for this item (or invalid, or missing).
 *
 * @param {ItemSpecific[]} itemSpecifics - the list of item specifics for an item
 * @param {CategorySpecific} categorySpecific - an object representing
 *  a field name and the list of accepted values for that field
 * @return {FieldStatus} whether this field is correct according to eBay's
 * guidelines. */
const calculateFieldStatus = (itemSpecifics, categorySpecific) => {
  const itemField = (itemSpecifics || []).find(
    (field) => field.name === categorySpecific.name
  );
  if (!itemField) {
    // If the field is allowed to be missing, just mark it as correct
    if (allowMissingField(categorySpecific.name, itemSpecifics || [])) {
      return "correct";
    } else {
      return "missing";
    }
  }

  if (isFieldValid(itemField, categorySpecific.valueRecommendation)) {
    return "correct";
  } else {
    return "invalid";
  }
};

/** Given a category specific, return the type of input field that we should
 * display to the user
 * @param {CategorySpecific} categorySpecific
 * @return {InputType} */
const getInputType = (categorySpecific) => {
  if (
    !categorySpecific.valueRecommendation ||
    categorySpecific.valueRecommendation.length === 0
  ) {
    return "text";
  }
  if (
    _.get(categorySpecific, "aspectConstraint.itemToAspectCardinality") ===
    "MULTI"
  ) {
    return "mSelect";
  }
  return "select";
};

/** @param {CategorySpecific} recomm
 * @param {ItemSpecific[]} itemSpecifics
 * @param {InputType} inputType
 * @return {(string|string[])} */
const getCompareValue = (recomm, itemSpecifics, inputType) => {
  let found = itemSpecifics.find((i) => i.name === recomm.name);
  let matched = itemSpecifics.find(
    (i) =>
      i.name === recomm.name &&
      (recomm.valueRecommendation || []).indexOf(i.value) !== -1
  );
  let selectedValue;

  if (found || matched) {
    if (inputType === "mSelect") {
      selectedValue =
        found.value instanceof Array ? [...found.value] : [found.value];
    } else {
      selectedValue = found.value;
    }
  } else {
    if (inputType === "mSelect") {
      selectedValue = [];
    } else {
      selectedValue = "";
    }
  }

  return selectedValue;
};

// Compare value of item specifics and recommended value
// validationRules: 'Required'| 'Optional'| 'Recommended'
// itemSpecifics should be array like:
/*    itemSpecifics: [
                        { name: "Expiration Date", value: "2020-07-02" },
                        { name: "Model", value: "None" },
                        { name: "Modified Item", value: "No" },
                        { name: "MPN", value: "Does Not Apply" },
                        { name: "Custom Bundle", value: "Yes" },
                        { name: "Brand", value: "Gioia Casa" },
                        { name: "Bundle Description", value: "Description" },

                    ] */
/* recommendedSpecifics should be array like: [
                    { name: "Brand", valueRecommendation: [] },
                    { name: "MPN", valueRecommendation: ["Does Not Apply"], validationRules: "Optional" },
                    { name: "Custom Bundle", valueRecommendation: ["No", "Yes"], validationRules: "Optional" },
                    { name: "Bundle Description", valueRecommendation: [], validationRules: "Optional" },
                    { name: "Expiration Date", valueRecommendation: [], validationRules: "Optional" },
                    { name: "Modified Item", valueRecommendation: ["No", "Yes"], validationRules: "Optional" },
                    { name: "Modification Description", valueRecommendation: [], validationRules: "Optional" },
                    { name: "Model", valueRecommendation: [], validationRules: "Optional" },
                ], */
const compareActualAndRecommended = (item, recommendedSpecifics) => {
  let itemSpecifics = _.get(item, "itemSpecifics");

  if (
    !(itemSpecifics instanceof Array && recommendedSpecifics instanceof Array)
  ) {
    return null;
  }

  /* An array which represents the results of comparing the
   * fields in an item listing against eBay's recommended values */
  const compareDetails = [];

  /* We iterate over the category requirements instead of the item's fields
     in the outer loop to allow us to more easily detect missing fields */
  recommendedSpecifics.forEach((categorySpecific) => {
    const flag = calculateFieldStatus(itemSpecifics, categorySpecific);
    const inputType = getInputType(categorySpecific);
    const selectedValue = getCompareValue(
      categorySpecific,
      itemSpecifics,
      inputType
    );

    compareDetails.push({
      name: categorySpecific.name,
      flag,
      inputType,
      selectedValue,
      validationRules: _.get(categorySpecific, "validationRules", "Optional"),
    });
  });

  const correct = compareDetails.filter((i) => i.flag === "correct");
  const invalid = compareDetails.filter((i) => i.flag === "invalid");
  const missing = compareDetails.filter((i) => i.flag === "missing");

  return {
    itemId: item.itemId,
    itemTitle: item.itemTitle,
    itemImg: item.itemImg,
    compareDetails,
    missing: missing.length,
    invalid: invalid.length,
    correct: correct.length,
  };
};

const getItemSpecificsResults = (categoryList, validationRules) => {
  let _validationRules = validationRules || [
    "Required",
    "Optional",
    "Recommended",
  ];

  let _categoryList = categoryList
    .map((category) => {
      if (category && category.itemInfo) {
        let itemInfo = _.get(category, "itemInfo", []);
        let recommendedSpecifics = _.get(category, "recommendedSpecifics", []);

        let compareResult = itemInfo.map((item) => {
          return compareActualAndRecommended(item, recommendedSpecifics);
        });

        const {
          missingPercentage,
          invalidPercentage,
          correctPercentage,
          correct,
          total,
        } = itemSpecificTotals(compareResult, _validationRules);

        let recommendedSpecificsIsNull =
          category.recommendedSpecifics && category.recommendedSpecifics.length
            ? false
            : true;

        return {
          categoryId: category.categoryId,
          category: getCategoryName("ebay", category),
          listings: _.get(category, "itemInfo", []).length,
          compareResult,
          correctPercentage,
          invalidPercentage,
          missingPercentage,
          recommendedSpecificsIsNull,
          total,
          correct,
          isPercentageAllZero:
            !missingPercentage && !invalidPercentage && !correctPercentage,
        };
      }
    })
    .sort((a, b) => b.listings - a.listings);

  let total = _categoryList.reduce((acc, i) => i.total + acc, 0);
  let totalCorrect = _categoryList.reduce((acc, i) => i.correct + acc, 0);

  let summaryPercent = total
    ? parseFloat((100 * totalCorrect) / total).toFixed(0)
    : 0;

  return { categoryList: _categoryList, percentage: summaryPercent };
};

module.exports = {
  itemSpecificTotals,
  compareActualAndRecommended,
  getItemSpecificsResults,
};
