import {
  Box,
  ButtonBase,
  Checkbox,
  Grid,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { ArrowDropDown } from "@material-ui/icons";
import { LinkAndImageComponent } from "../table/cells/linkAndImageCell";
import PanelLoading from "../loadingIndicator/panelLoadingIndicator";
import SearchFilter from "../adTable/searchFilter";
import { User } from "~/typedef/user";
import isEmpty from "lodash/isEmpty";
import { searchProducts } from "~/store/customGroups/reducer.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MAX_PRODUCTS = 25;

export const DropDownBase = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !["maxWidth"].includes(prop),
})`
  width: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

const PAGE_SIZE = 100;

export const OptionText = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  padding-left: 8px;
`;

interface ProductType {
  productSku: string;
  productId?: string;
}

interface ProductDropDownProps {
  title: string;
  handleOptionChange: (
    notificationType: ProductNotificationType | StoreNotificationType,
    products: string[]
  ) => void;
  mid: string;
  marketplace: string;
  userInfo: User;
  initProducts: ProductType[];
  notificationType: ProductNotificationType | StoreNotificationType;
  setNotificationType: (
    notificationType: ProductNotificationType | StoreNotificationType
  ) => void;
}

const ProductDropDown = memo<ProductDropDownProps>(function ProductDropDown({
  title,
  handleOptionChange,
  mid,
  marketplace,
  userInfo,
  initProducts,
  notificationType,
  setNotificationType,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [selectionInProgress, setSelectionInProgress] =
    useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] =
    useState<ProductType[]>(initProducts);

  const dispatch = useDispatch();
  const dispatchSearchProducts = useCallback(() => {
    dispatch(
      searchProducts({
        mid,
        pageSize: PAGE_SIZE,
        searchText: searchText,
        products: selectedOptions.map((option) => option.productSku),
        excludeInactive: true,
      })
    );
  }, [userInfo, marketplace, mid, searchText, selectedOptions]);

  const products = useTypedSelector(
    (state) => state?.customGroups?.products?.data || []
  );

  useEffect(() => {
    if (mid) {
      dispatchSearchProducts();
    }
  }, [searchText]);

  useEffect(() => {
    if (!selectionInProgress) {
      setSelectedOptions(initProducts);
    }
  }, [selectedOptions, initProducts]);

  const productsSearching = useTypedSelector(
    (state) => state?.customGroups?.products?.fetching || false
  );
  const data = useMemo(
    () =>
      products?.map((product: any) => ({
        productId: product.productId,
        productSku: product.productSku,
        value: product.title,
        secondRowValue: `${marketplace === "amazon" ? "ASIN" : "LISTING ID"}: ${
          product.productSku
        }   |    SKU: ${product.sku}`,
        link: product.link,
        image: product.image,
        target: "_blank",
      })),
    [products, userInfo, marketplace, mid, searchText]
  );

  useEffect(() => {
    if (data.length) {
      const productOptions = data.map((product: any) => {
        const isSelected = selectedOptions.find(
          (selectedOption: ProductType) =>
            selectedOption.productSku === product.productSku
        );
        return {
          ...product,
          selected: Boolean(isSelected),
        };
      });
      setOptions(productOptions);
    } else if (options.length) {
      setOptions([]);
    }
  }, [data, selectedOptions, searchText, userInfo]);

  const changeSelectOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: any
  ) => {
    setSelectionInProgress(true);
    const checked = e.target.checked;
    if (checked) {
      if (
        !(
          selectedOptions.length > MAX_PRODUCTS - 1 ||
          selectedOptions.length < 0
        )
      ) {
        setSelectedOptions([option, ...selectedOptions]);
      }
    } else {
      setSelectedOptions(
        selectedOptions.filter(
          (selectedOption: ProductType) =>
            selectedOption.productSku !== option.productSku
        )
      );
    }
  };

  const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    dispatchSearchProducts();
  };

  const closeMenu = () => {
    handleOptionChange(
      notificationType,
      selectedOptions.map((productOption: any) => productOption.productSku)
    );
    setNotificationType(notificationType);
    setAnchorEl(null);
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <DropDownBase
        onClick={openMenu}
        id="product-select"
        data-cy="product-select"
        theme={theme}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid container alignItems="flex-end" item xs={8}>
            {isEmpty(selectedOptions) ? (
              <OptionText theme={theme} variant="body2">
                {title}
              </OptionText>
            ) : selectedOptions.length === 1 &&
              selectedOptions[0].productSku === "ALL" ? (
              <OptionText variant="body2" noWrap>
                {t("notifications.all")}
              </OptionText>
            ) : (
              <OptionText variant="body2" noWrap>
                {t("generic.selectedProductsCount", {
                  count: selectedOptions.length,
                })}
              </OptionText>
            )}
          </Grid>
          <Grid item xs={2}>
            <ArrowDropDown />
          </Grid>
        </Grid>
      </DropDownBase>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        marginThreshold={10}
        onClose={closeMenu}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box p={1}>
              <SearchFilter setSearchText={setSearchText} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {productsSearching ? (
              <PanelLoading />
            ) : isEmpty(options) ? (
              <Typography align="center">
                {t("generic.noDataAvailableMessage")}
              </Typography>
            ) : (
              options?.map((option) => (
                <Box display="flex" alignItems="center" p={1}>
                  <Checkbox
                    checked={option.selected}
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      option.selected = e.target.checked;
                      changeSelectOption(e, option);
                    }}
                  />
                  <LinkAndImageComponent {...option} />
                </Box>
              ))
            )}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
});

export default ProductDropDown;
