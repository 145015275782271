import React, { useEffect, useState } from "react";
import { clearError, setWarningMessage } from "~/store/globalToast.redux";

import { StoreState } from "@typedef/store";
import Toast from "@components/alert/toast";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const ApplicationToast = () => {
  const globalToast = useTypedSelector(
    (state: StoreState) => state.globalToast
  );
  const [isTranslated, setIsTranslated] = useState(false);
  const dispatch = useDispatch();

  const closeError = () => {
    dispatch(clearError());
  };

  const setMessage = (msg: string, sticky: boolean) => {
    setWarningMessage(dispatch, msg, sticky);
  };

  useEffect(() => {
    const checkGoogleTranslate = () => {
      const htmlElement = document.getElementsByTagName("html")[0];
      const htmlClasses = htmlElement && [...htmlElement.classList];
      const translated = htmlClasses.includes("translated-ltr");
      if (isTranslated !== translated && !get(globalToast, "showToast")) {
        setIsTranslated(translated);
        setMessage(
          `It looks like you are using Google Translate.
              This has been known to cause issues on our site.
              If you experience site errors please try turning off Google Translate.`,
          true
        );
      }
    };
    const intervalId = setInterval(checkGoogleTranslate, 3000);

    checkGoogleTranslate();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {get(globalToast, "showToast") && (
        <Toast
          type={get(globalToast, "status") || "error"}
          onClose={closeError}
          message={get(globalToast, "msg")}
          sticky={get(globalToast, "sticky")}
          location={get(globalToast, "location")}
        />
      )}
    </>
  );
};
