import React, { memo, useState } from "react";

import DownloadCsvIcon from "~/icons/downloadCsvIcon";
import DownloadDialog from "./downloadDialog";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";

const SmallIcon = styled(DownloadCsvIcon)`
  height: 18px;
  width: 18px;
  margin: -5px;
`;

export type DownloadCSVProps = {
  mid: string | undefined;
  reportType: string;
  path: string;
  params: Record<string, any>;
};

const DownloadCsv = memo<DownloadCSVProps>(function DownloadCsv({
  mid,
  reportType,
  path,
  params,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <DownloadDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        mid={mid}
        reportType={reportType}
        path={path}
        params={params}
      />
      <IconButton
        onClick={() => {
          setDialogOpen(true);
        }}
        title={"Download as CSV"}
      >
        <SmallIcon />
      </IconButton>
    </>
  );
});

export default DownloadCsv;
