import { Grid, Tooltip } from "@material-ui/core";
import React, { memo } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import HelpIcon from "@material-ui/icons/Help";
import PrimeIcon from "~/img/amazon_prime.png";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const RedCancelIcon = styled(CancelIcon)`
  fill: ${({ theme }) => theme.palette.error.dark};
  width: 14px;
  height: 14px;
  padding: 2px 0 0 14px;
`;

const QuestionIcon = styled(HelpIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding: 2px 0 0 14px;
`;

const SmallIcon = styled.img`
  width: 14px;
  height: 14px;
  padding: 2px 0 0 14px;
`;

const PrimeCell = memo(({ cell }) => {
  const { t } = useTranslation();

  return (
    <Grid item>
      {cell.value === "true" || cell.value === true ? (
        <Tooltip title={t("generic.fulfilledByAmazon")}>
          <SmallIcon src={PrimeIcon} />
        </Tooltip>
      ) : cell.value === "false" || cell.value === false ? (
        <RedCancelIcon />
      ) : (
        <Tooltip title={t("generic.noPrimeInfo")}>
          <QuestionIcon />
        </Tooltip>
      )}
    </Grid>
  );
});

PrimeCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default PrimeCell;
