import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { RESPONSE_CODE } from "./utils/httpsResponseCodes";
import { api } from "./apiSlice";
import { configureStore } from "@reduxjs/toolkit";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import localForage from "localforage";
import rootReducer from "./reducers";
import { setupListeners } from "@reduxjs/toolkit/query/react";

localForage.config({
  name: "Marketplace Manager",
  storeName: "marketplace_manager",
});

const rootPersistConfig = {
  key: "root",
  version: RESPONSE_CODE.SUCCESS,
  storage: localForage,
  stateReconciler: hardSet,
  transforms: [],
  // If the api slice reducer is not blacklisted, the api cache will be automatically
  // persisted and restored which could leave you with phantom subscriptions from components that do not exist any more.
  blacklist: [api.reducerPath],
};

export type RootReducer = ReturnType<typeof rootReducer>;
const persistedReducer = persistReducer<RootReducer>(
  rootPersistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  // @ts-expect-error: The inferred types are clashing due to our rootReducer & many slices being in js and not ts
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // we should specifically ignore all the action types redux persist dispatches
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
  devTools: process.env.TARGET_ENV !== "production",
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
// We cannot use "useAppSelector" until we have gotten ride of rootReducer
// it prevents proper type inference
// use "useTypedSelector" for now
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
