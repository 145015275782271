import { Box, Typography } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";

import CountryDropdown from "../toolbarComponents/countryDropdown";
import FilterTagDropdown from "../toolbarComponents/filterTagDropdown";
import MarketPlaceDropdown from "../toolbarComponents/marketPlaceDropdown";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import usePrevious from "../../../hooks/usePrevious";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const OptionText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
`;

const FiltersBox = styled(Box)<{ $rightAlign?: boolean }>`
  width: 700px;
  ${({ $rightAlign }) => ($rightAlign ? "margin-right: -1rem;" : "")}
`;

interface FilterToolbarProps {
  filterWidth?: number;
  disabled?: boolean;
  isDemoMode?: boolean;
  rightAlign?: boolean;
}

const FilterToolbar = memo<FilterToolbarProps>(
  ({ filterWidth, disabled, isDemoMode, rightAlign }) => {
    const user = useTypedSelector((state) => state.user);
    const defaultFilters = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );
    const dispatch = useDispatch();
    const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
    const prevSelectedFilters = usePrevious(selectedFilters);

    const updateFilters = useCallback(() => {
      const dispatchCurrentFilter = () =>
        dispatch(
          upsertPersistentAppSettings(
            {
              organisationId: user.organisationId,
              userId: user._id,
              settings: {
                currentFilter: selectedFilters,
              },
            },
            isDemoMode ? false : true
          )
        );

      const dispatchFetchFilteredStores = () =>
        dispatch(fetchFilteredStores(selectedFilters));
      if (!isEqual(prevSelectedFilters, selectedFilters)) {
        dispatchCurrentFilter();
        dispatchFetchFilteredStores();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSelectedFilters, selectedFilters]);

    useEffect(() => {
      updateFilters();
    }, [updateFilters, selectedFilters]);

    return (
      <FiltersBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        $rightAlign={rightAlign}
      >
        <MarketPlaceDropdown
          filterWidth={filterWidth}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disabled}
        />
        <CountryDropdown
          filterWidth={filterWidth}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disabled}
        />
        <FilterTagDropdown
          filterWidth={filterWidth}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disabled}
        />
      </FiltersBox>
    );
  }
);

export default FilterToolbar;
