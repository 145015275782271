import React, { useCallback, useEffect, useState } from "react";
import {
  fetchCustomTags,
  fetchFilteredStores,
} from "~/store/overview/customTags.redux";
import { flatMap, get, uniq } from "lodash";

import FilterTagSelect from "~/components/select/filterTagSelect";
import { FormControl } from "@material-ui/core";
import { Store } from "~/typedef/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SelectStoreByTagProps {
  existingTags?: string[];
  selectedTags: string[];
  setSelectedTags: (tag: string[]) => void;
  setStores: (stores: Store[]) => void;
}
export const SelectStoreByTag = ({
  selectedTags,
  setSelectedTags,
  setStores,
}: SelectStoreByTagProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isTagsLoading = useTypedSelector((state) =>
    get(state, "overview.customTags.fetching")
  );
  const filteredStoresFetching = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.fetching")
  );

  const customTags = useTypedSelector((state) =>
    get(state, "overview.customTags.tags")
  );

  const tags = uniq(flatMap(customTags, (val: any) => val.tags));

  const handleOptionChange = (newTags: string[]) => {
    setSelectedTags(newTags);
  };

  const dispatchFetchFilteredStores = () => {
    dispatch(fetchFilteredStores({ tags: selectedTags }));
  };

  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores")
  );

  useEffect(() => {
    if (!isTagsLoading && !filteredStoresFetching && selectedTags.length) {
      dispatchFetchFilteredStores();
    }
  }, [selectedTags]);

  useEffect(() => {
    if (selectedTags.length && !filteredStoresFetching) {
      setStores(filteredStores);
    }
    if (!selectedTags.length && !filteredStoresFetching) {
      setStores([]);
    }
  }, [filteredStores, selectedTags]);

  const handleOpenMenu = useCallback(() => {
    dispatch(fetchCustomTags());
  }, []);

  return (
    <FormControl fullWidth>
      <FilterTagSelect
        {...{
          title: t(`filterTitle.tags`),
          isFullWidth: true,
          minWidth: 120,
          maxWidth: 360,
          additionalMenuWidth: 50,
          isLoading: isTagsLoading,
          isWithSuggestion: true,
          options: tags,
          selectedOptions: selectedTags,
          handleOpenMenu: handleOpenMenu,
          filterTagTitle: t("filters.allCustomTags"),
          handleOptionChange: handleOptionChange,
        }}
      />
    </FormControl>
  );
};
