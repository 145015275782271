import { Box, Grid, Hidden, Toolbar, Typography } from "@material-ui/core";
import {
  DEFAULT_CURRENCY,
  upsertPersistentAppSettings,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../breadcrumbs";
import CombinedLink from "../../links/link";
import CountryDropdown from "../toolbarComponents/countryDropdown";
import CurrencyDropdown from "../toolbarComponents/currencyDropdown";
import { Filter } from "@typedef/store";
import FilterTagDropdown from "../toolbarComponents/filterTagDropdown";
import FilterToolBar from "../filterToolbar/filterToolbar";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { Location } from "history";
import MarketPlaceDropdown from "../toolbarComponents/marketPlaceDropdown";
import SyncButton from "~/components/buttons/syncButton";
import TimePeriodDropdown from "../toolbarComponents/timePeriodDropdown";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { useDashboardFetching } from "~/hooks/useDashboardFetching";
import { useLocation } from "react-router-dom";
import usePrevious from "../../../hooks/usePrevious";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SelectedItem {
  currentPage: string;
  breadcrumb: Breadcrumb[];
}
interface Breadcrumb {
  text: string;
  link?: string;
}

interface ToolbarProps {
  changeCurrency: (currency: string, isDemoMode?: boolean) => void;
  currentCurrency: string;
  location: Location<unknown>;
  selectedItem: SelectedItem;
  disableFilters: boolean;
  isDemoMode?: boolean;
}

const StyledToolbar = styled(Toolbar)`
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  align-self: flex-end;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      padding-bottom: 0.5rem;
    }`}
`;

const FlexLink = styled(CombinedLink)`
  display: flex;
  align-items: center;
`;

const BackButtonWrapper = styled(Grid)`
  margin-bottom: 8px;
`;

const BreadcrumbContainer = styled(Grid)`
  height: 21px;
`;

const DesktopToolbar = memo<ToolbarProps>(function DesktopToolbar({
  currentCurrency,
  changeCurrency,
  selectedItem,
  location,
  disableFilters,
  isDemoMode,
}) {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid container item xs={12} lg={9} xl={10}>
          <FilterToolBar
            {...{
              containerWidth: 700,
              filterWidth: 220,
              disabled: disableFilters,
              isDemoMode: isDemoMode,
            }}
          />
        </Grid>
        <Grid container item xs={12} lg={3} xl={2} justifyContent="flex-end">
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box pr={1} display="flex" alignItems="flex-end" height="100%">
              <SyncButton />
            </Box>
            <TimePeriodDropdown isDisabled={disableFilters} />
            <Box pl={1}>
              <CurrencyDropdown
                {...{
                  currentCurrency,
                  switchCurrency: changeCurrency,
                  disabled: disableFilters,
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Box pt={2}>
          <BreadcrumbContainer container xl={10}>
            {location.pathname !== "/overview" && (
              <Grid container item xs={12} alignItems="center">
                <FlexLink
                  {...{
                    variant: "body2",
                    color: "textSecondary",
                    to: "/overview",
                  }}
                >
                  <KeyboardArrowLeftRounded fontSize="small" />{" "}
                  {t("generic.backLink")}
                </FlexLink>
              </Grid>
            )}
            <Grid container item xs={12} alignItems="center">
              <Breadcrumbs selectedItem={selectedItem} isOverviewToolbarPage />
            </Grid>
          </BreadcrumbContainer>
          <Typography variant="h1" noWrap color="textPrimary">
            {selectedItem.currentPage}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
});

const MobileToolbar = memo<ToolbarProps>(function MobileToolbar({
  currentCurrency,
  changeCurrency,
  selectedItem,
  disableFilters,
  isDemoMode,
}) {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.user);
  const defaultFilters: Filter | undefined = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
  const prevSelectedFilters = usePrevious(selectedFilters);

  const updateFilters = useCallback(() => {
    const dispatchCurrentFilter = () =>
      dispatch(
        upsertPersistentAppSettings(
          {
            organisationId: user.organisationId,
            userId: user._id,
            settings: {
              currentFilter: selectedFilters,
            },
          },
          isDemoMode ? false : true
        )
      );

    const dispatchFetchFilteredStores = () =>
      dispatch(fetchFilteredStores(selectedFilters));
    if (!isEqual(prevSelectedFilters, selectedFilters)) {
      dispatchCurrentFilter();
      dispatchFetchFilteredStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedFilters, selectedFilters]);

  useEffect(() => {
    updateFilters();
  }, [updateFilters]);

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        {location.pathname !== "/overview" && (
          <BackButtonWrapper container item alignItems="center">
            <FlexLink
              {...{
                variant: "body1",
                color: "textSecondary",
                to: "/overview",
              }}
            >
              <KeyboardArrowLeftRounded fontSize="small" />{" "}
              {t("generic.backLink")}
            </FlexLink>
          </BackButtonWrapper>
        )}
        <Grid item>
          <Breadcrumbs selectedItem={selectedItem} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <MarketPlaceDropdown
          fullWidth
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disableFilters}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CountryDropdown
          fullWidth
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disableFilters}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FilterTagDropdown
          fullWidth
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          disabled={disableFilters}
        />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        wrap="wrap"
        xs={12}
        sm={8}
      >
        <SyncButton />
        <Grid item xs>
          <TimePeriodDropdown
            minWidth={300}
            fullWidth
            isDisabled={disableFilters}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CurrencyDropdown
          {...{
            fullWidth: true,
            currentCurrency,
            switchCurrency: changeCurrency,
            disabled: disableFilters,
          }}
        />
      </Grid>
    </Grid>
  );
});

interface OverviewToolbarProps {
  selectedItem: SelectedItem;
}

export const OverviewToolbar = memo<OverviewToolbarProps>(
  function OverviewToolbar({ selectedItem }) {
    const user = useTypedSelector((state) => get(state, "user"));
    const isDemoMode: boolean | undefined = get(user, "isDemoMode");
    const dispatch = useDispatch();
    const dispatchSwitchCurrency = (currency: string) =>
      dispatch(
        upsertPersistentAppSettings(
          {
            organisationId: user.organisationId,
            userId: user._id,
            settings: {
              currentCurrency: currency,
            },
          },
          isDemoMode ? false : true
        )
      );

    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const location = useLocation();

    const dashboardFetching = useDashboardFetching();
    const [beforeTimeout, setBeforeTimeout] = useState(true);

    useEffect(() => {
      setBeforeTimeout(dashboardFetching);
      const timeout = setTimeout(() => {
        setBeforeTimeout(false);
      }, 60000);

      return () => clearTimeout(timeout);
    }, [dashboardFetching]);

    return (
      <StyledToolbar id="overview-toolbar">
        <Hidden smDown>
          <DesktopToolbar
            currentCurrency={currentCurrency}
            changeCurrency={dispatchSwitchCurrency}
            selectedItem={selectedItem}
            location={location}
            disableFilters={dashboardFetching && beforeTimeout}
            isDemoMode={isDemoMode}
          />
        </Hidden>
        <Hidden mdUp>
          <MobileToolbar
            currentCurrency={currentCurrency}
            changeCurrency={dispatchSwitchCurrency}
            selectedItem={selectedItem}
            location={location}
            disableFilters={dashboardFetching && beforeTimeout}
            isDemoMode={isDemoMode}
          />
        </Hidden>
      </StyledToolbar>
    );
  }
);
