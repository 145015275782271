import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { CurrencyRate, Filter, Range } from "~/typedef/store";
import React, { useMemo } from "react";

import CombinedLink from "~/components/links/link";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { EnterBudgetBox } from "./adSpendInvestmentVsBudget";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import PieChartWithNeedle from "~/components/charts/pieChartWithNeedle/pieChartWithNeedle";
import RaisedButton from "~/components/buttons/raisedButton";
import { User } from "~/typedef/user";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { memo } from "react";
import styled from "styled-components";
import { useFetchPerformanceAgainstForecastDataQuery } from "~/store/mystore/forecasting.redux";
import { useSalesPerformanceQuery } from "@store/overview/salesPerformance.redux";
import { useTranslation } from "react-i18next";

const ForecastBox = styled(Box)`
  min-width: 80%;
  max-width: 100%;
`;

const TitleTypography = styled(Typography)`
  margin: 0.5rem 0;
`;

const MetricGrid = styled(Grid)`
  padding: 5px 0;
`;

type MetricTypographyProps = {
  diff?: number;
};

const MetricTypography = styled(Typography)<MetricTypographyProps>`
  color: ${({ diff, theme }) => {
    return isNaN(diff as number)
      ? theme.palette.text.primary
      : diff && diff >= 0
      ? theme.palette.chart.green
      : theme.palette.chart.red;
  }} !important;
`;

interface SalesPerformanceAgainstForecastParams {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  marketplaceType: string;
  includeTax: boolean;
  currentFilter?: Filter;
  userInfo: User;
  currencyRates: CurrencyRate[];
  report?: boolean;
}

const SalesPerformanceAgainstForecastWidget =
  memo<SalesPerformanceAgainstForecastParams>(
    function SalesPerformanceAgainstForecastWidget({
      mid,
      currentCurrency,
      currentPeriod,
      currentRange,
      currentFilter,
      includeTax,
      userInfo,
      currencyRates,
      report,
      marketplaceType,
    }) {
      const { t } = useTranslation();
      const theme = useTheme();

      const { salesPerformance, salesLoading } = useSalesPerformanceQuery(
        {
          mid,
          filter: currentFilter,
          currentPeriod,
          currentRange,
          includeTax,
          currency: currentCurrency,
          includeVendor: true,
        },
        {
          selectFromResult: ({ data, isFetching }) => {
            return {
              salesPerformance: data,
              salesLoading: isFetching,
            };
          },
        }
      );

      const { performanceData, performanceDataFetching, forecastCurrency } =
        useFetchPerformanceAgainstForecastDataQuery(
          {
            user: userInfo,
            mid,
            currentRange,
          },
          {
            selectFromResult: ({ data, isFetching }) => ({
              performanceData: data,
              performanceDataFetching: isFetching,
              forecastCurrency: data?.currency,
            }),
          }
        );

      const result = useMemo(() => {
        if (performanceData && !performanceDataFetching && !salesLoading) {
          const forecast = performanceData.forecast.forecast ?? 0;
          const sales = salesPerformance?.current.sales ?? 0;

          const forecastTargetValueMax = Math.max(forecast, sales);
          const forecastPieChartMax = forecastTargetValueMax * 2;

          const forecastDiff = sales - forecast;
          const forecastData = {
            chartData: [
              ...(forecast > 0
                ? [
                    {
                      name: "$0",
                      value: forecast * 0.8,
                      color: theme.palette.chart.red,
                    },
                    {
                      value: forecast * 0.2,
                      color: theme.palette.chart.orange,
                    },
                    {
                      name: formatCurrencyRounded(
                        forecast,
                        currencyRates,
                        forecastCurrency,
                        currentCurrency
                      ),
                      value: 1,
                      color: theme.palette.chart.lightBlue,
                    },
                    {
                      name: formatCurrencyRounded(
                        forecastPieChartMax,
                        currencyRates,
                        forecastCurrency,
                        currentCurrency
                      ),
                      value: forecastPieChartMax - forecast,
                      color: theme.palette.chart.green,
                    },
                  ]
                : [
                    {
                      value: forecastPieChartMax - forecast,
                      color: theme.palette.chart.green,
                    },
                  ]),
            ],
            name: "N/A",
            value: forecast === 0 ? 0 : ((sales / forecast) * 100).toFixed(2),
          };

          return {
            forecastData,
            forecastDiff,
          };
        }
        return {
          forecastData: { chartData: [], value: 0 },
          forecastDiff: 0,
        };
      }, [performanceData, salesPerformance]);
      const forecast = performanceData?.forecast.forecast ?? 0;
      const sales = salesPerformance?.current.sales ?? 0;
      return (
        <Panel
          id="widget-performance-against-forecast"
          title={t("dashboardWidget.salesPerformanceAgainstForecast.mainTitle")}
          content={
            performanceDataFetching || salesLoading || !result ? (
              <Box p={2}>
                <LoadingIndicator />
              </Box>
            ) : forecast === 0 && sales === 0 ? (
              <EnterBudgetBox
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <RaisedButton variant="contained" color="info">
                  <CombinedLink
                    {...{
                      to: marketplaceLink(
                        marketplaceType,
                        mid,
                        "forecastsandcosts"
                      ),
                      color: "inherit",
                      variant: "body1",
                      underline: "none",
                    }}
                  >
                    {t("forecasting.enterBudget")}
                  </CombinedLink>
                </RaisedButton>
              </EnterBudgetBox>
            ) : (
              <Box p={3}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    container
                    xs={8}
                    md={6}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box mt="25px" pr="10px" maxWidth={250}>
                      <PieChartWithNeedle
                        data={result?.forecastData.chartData}
                        circleX={125}
                        circleY={125}
                        innerRadius={65}
                        outerRadius={100}
                        value={
                          performanceData?.forecast.forecast &&
                          salesPerformance?.current.sales
                            ? salesPerformance?.current.sales
                            : 0
                        }
                        needleColor={theme.palette.text.primary}
                        width={250}
                        height={175}
                      />
                      <Typography variant="h2" align="center">
                        {`${result?.forecastData.value}%`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    md={6}
                    alignItems="center"
                    justifyContent={report ? "flex-start" : "flex-end"}
                  >
                    <ForecastBox>
                      <TitleTypography variant="h3">
                        {t("customGroupsChart.salesOption")}
                      </TitleTypography>
                      <MetricGrid container justifyContent="space-between">
                        <Typography variant="h6">
                          {t(
                            "dashboardWidget.performanceAgainstForecast.forecast"
                          )}
                          :
                        </Typography>
                        <Typography variant="h6">
                          {performanceData?.forecast?.forecast
                            ? formatCurrencyRounded(
                                performanceData?.forecast?.forecast,
                                currencyRates,
                                forecastCurrency,
                                currentCurrency
                              )
                            : "N/A"}
                        </Typography>
                      </MetricGrid>
                      <MetricGrid container justifyContent="space-between">
                        <Typography variant="h6">
                          {t(
                            "dashboardWidget.performanceAgainstForecast.actual"
                          )}
                          :
                        </Typography>
                        <Typography variant="h6">
                          {salesPerformance?.current.sales
                            ? formatCurrencyRounded(
                                salesPerformance?.current.sales,
                                currencyRates,
                                forecastCurrency,
                                currentCurrency
                              )
                            : "N/A"}
                        </Typography>
                      </MetricGrid>
                      <MetricGrid container justifyContent="space-between">
                        <Typography variant="h6">
                          {t("dashboardWidget.performanceAgainstForecast.diff")}
                          :
                        </Typography>
                        <MetricTypography
                          variant="h6"
                          diff={result.forecastDiff}
                        >
                          {result.forecastDiff
                            ? formatCurrencyRounded(
                                result.forecastDiff,
                                currencyRates,
                                forecastCurrency,
                                currentCurrency
                              )
                            : "N/A"}
                        </MetricTypography>
                      </MetricGrid>
                    </ForecastBox>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        />
      );
    }
  );

export default SalesPerformanceAgainstForecastWidget;
