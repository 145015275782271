import { Box, Grid, ListItemText, Tooltip } from "@material-ui/core";
import { MenuItem, SubMenuItem as SubMenuItemType } from "./sideNavConstants";
import React, { memo, useEffect, useState } from "react";

import { CustomBadge } from "./drawerMenuItem";
import { Location } from "history";
import { LockRounded } from "@material-ui/icons";
import { SubItem } from "./sideNavDrawer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LockIcon = styled.div`
  display: flex;
  font-size: 1rem;
  margin-left: 5px;
`;

const TooltipLabel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  opacity: 0.5;

  & .coming-soon-text {
    flex-grow: 0;
  }
`;

const StyledListItemText = styled(ListItemText)`
  display: inline-block;
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
`;

export const isSubItemSelected = (
  location: Location,
  subItem: SubMenuItemType
) => {
  if (subItem.link && location.pathname.includes(subItem.link)) {
    return true;
  }
  if (subItem.subMenus) {
    return subItem.subMenus.some(
      (subSubItem) =>
        subSubItem.link && location.pathname.includes(subSubItem.link)
    );
  }
  return false;
};

interface SubMenuItemProps {
  id?: string;
  location: Location<unknown>;
  subItem: SubMenuItemType;
  changeLocation: (item: MenuItem | SubMenuItemType) => void;
  comingSoon?: boolean;
  notInPlan?: boolean;
}

export const SubMenuItem = memo<SubMenuItemProps>(function SubMenuItem({
  subItem,
  location,
  changeLocation,
  comingSoon,
  notInPlan,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(
    isSubItemSelected(location, subItem)
  );
  useEffect(() => {
    setSelected(isSubItemSelected(location, subItem));
  }, [location, subItem]);
  const handleClick = () => {
    changeLocation(subItem);
  };

  if (comingSoon || notInPlan) {
    return (
      <Box width="100%" pl={4}>
        <Tooltip
          title={t(`generic.${comingSoon ? "comingSoon" : "notInPlan"}`)}
          placement="bottom-end"
        >
          <TooltipLabel>
            <StyledListItemText
              secondary={t(subItem.title)}
              className="coming-soon-text"
            />
            <LockIcon>
              <LockRounded fontSize="inherit" />
            </LockIcon>
          </TooltipLabel>
        </Tooltip>
      </Box>
    );
  }

  return (
    <SubItem
      id={subItem.id}
      selected={selected}
      button
      disabled={comingSoon}
      disableGutters
      onClick={handleClick}
    >
      <Box width="100%" pl={4}>
        <StyledListItemText secondary={t(subItem.title)} />
        {subItem.isNew && <CustomBadge>{t("generic.new")}</CustomBadge>}
        {subItem.isBeta && <CustomBadge>{t("generic.beta")}</CustomBadge>}
      </Box>
    </SubItem>
  );
});
