import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Grid, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import NoData from "../../loadingIndicator/noData";
import PropTypes from "prop-types";
import { StepChartLegend } from "./StepChartLegend";
import { StepChartTooltip } from "./StepChartTooltip";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useLayoutProps } from "../chartUtils/chartComponents";
import { useTranslation } from "react-i18next";

const getXAxisProps = (compact, timezone) => {
  const tickFormatter = (tick) => moment.unix(tick).tz(timezone).format("ddd");
  return {
    dataKey: "timestamp",
    tickLine: false,
    tick: { fontSize: compact ? 10 : 12 },
    tickFormatter,
  };
};

const OPACITY = 0.7;
const Y_AXIS_WIDTH = 80;

const StepChart = memo(({ data, currentCurrency, compact, timezone }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { height, margin } = useLayoutProps("stepChart");
  const XAxisProps = data && getXAxisProps(compact, timezone);

  if (data && data.length && !data.some((datum) => datum.average !== 0)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <NoData />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box pt={4}>
          <ResponsiveContainer width="100%" height={compact ? 150 : height}>
            <ComposedChart
              data={data}
              margin={
                compact
                  ? {
                      top: 20,
                      right: 10,
                      left: 0,
                      bottom: -5,
                    }
                  : margin
              }
              barCategoryGap={-1}
              barGap={0}
            >
              <CartesianGrid
                strokeDasharray="2 2"
                stroke={theme.palette.grey["400"]}
                vertical={false}
              />
              <XAxis {...XAxisProps} xAxisId={0} />
              <XAxis {...XAxisProps} xAxisId={1} hide />
              <YAxis
                label={{
                  value: `${t("chartLegends.sales")} (${
                    getCurrencySymbol[currentCurrency]
                  })`,
                  position: "top",
                  offset: 20,
                  fontSize: compact ? 10 : 12,
                }}
                tick={{ fontSize: compact ? 10 : 12 }}
                tickFormatter={(tick) => numberWithCommas(parseInt(tick))}
                width={Y_AXIS_WIDTH}
              />
              <Tooltip content={<StepChartTooltip timezone={timezone} />} />
              <Legend
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  top: compact ? -20 : -25,
                  fontSize: compact ? "10px" : "12px",
                }}
                content={<StepChartLegend />}
              />
              <Bar
                dataKey="average"
                xAxisId={1}
                fill={theme.palette.secondary.light}
              />
              <Bar
                dataKey="sales"
                xAxisId={0}
                barSize={25}
                fill={alpha(theme.palette.secondary.dark, OPACITY)}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
    </Grid>
  );
});

StepChart.propTypes = {
  data: PropTypes.array,
  currentCurrency: PropTypes.string,
  compact: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
};

export default StepChart;
