import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { TableBaseProps } from "~/components/adTable/table";
import { numberWithCommas } from "~/utils/utils";

interface ValueCellProps {
  cell: {
    value: string;
    column?: TableBaseProps["columns"][number];
  };
}

export const ValueCell = memo<ValueCellProps>(function ValueCell({ cell }) {
  const value = cell.value;
  const cellJustify = cell.column?.cellJustify;

  return (
    <Grid container justifyContent={cellJustify ?? "flex-end"}>
      <Typography variant="body2">{numberWithCommas(value)}</Typography>
    </Grid>
  );
});
