import {
  FETCH_SALES_BY_BRAND,
  FETCH_SALES_BY_BRAND_FETCHING,
} from "./mystore.redux";

import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

async function setFetching(dispatch) {
  await dispatch({
    type: FETCH_SALES_BY_BRAND_FETCHING,
  });
}

export const fetchSalesByBrand =
  ({ mid, currentPeriod, currentRange, includeTax, timezone }) =>
  async (dispatch) => {
    await setFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/salesByBrand`, {
        timezone,
        currentPeriod,
        ...currentRange,
        mid,
        includeTax,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(dispatch, msg || statusText, get(err, "status"));
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_SALES_BY_BRAND,
          payload: data,
        });
      } else {
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    return setError(dispatch);
  };
