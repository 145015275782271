import { RawDraftContentState } from "draft-js";
import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export enum COMMENTARY_FILTERS {
  OVERVIEW = "OVERVIEW",
  CUSTOMER = "CUSTOMER",
  SALES = "SALES",
  MARKETING = "MARKETING",
  OPERATIONS = "OPERATIONS",
  PROFITABILITY = "PROFITABILITY",
  OTHER = "OTHER",
  INTERNAL_NOTES = "INTERNAL NOTES",
}

export type Note = SingleChannelNote | MultiChannelNote;

interface SingleChannelNote {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  _id: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  filter: COMMENTARY_FILTERS;
  note: RawDraftContentState;
}

interface MultiChannelNote {
  tag: string;
  _id: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  note: RawDraftContentState;
}

interface SaveNotesSingleChannelArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  note: RawDraftContentState;
  filter: COMMENTARY_FILTERS;
}

interface SaveNotesMultiChannelArgs {
  tag: string;
  note: RawDraftContentState;
}

type SaveNotesArgs = SaveNotesSingleChannelArgs | SaveNotesMultiChannelArgs;

interface FetchSingleChannelNotesArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  filters: COMMENTARY_FILTERS[];
}

interface FetchMultiChannelNotesArgs {
  tags: string[];
}

type FetchNotesArgs = FetchSingleChannelNotesArgs | FetchMultiChannelNotesArgs;

interface FetchNotesResponse {
  notes: Note[];
}

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    saveNotes: build.mutation<string, SaveNotesArgs>({
      query: (body) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/notes`,
          method: "POST",
          data: body,
        };
      },
      onQueryStarted: globalQueryErrorHandler("SaveNotes"),
    }),
    fetchNotes: build.query<FetchNotesResponse, FetchNotesArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/notes`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("GetNotes"),
    }),
  }),
});

export const { useSaveNotesMutation, useFetchNotesQuery } = extendedApiSlice;
