import { Box, Input } from "@material-ui/core";

import LoadingButton from "~/components/buttons/loadingButton";
import React from "react";
import { setError } from "../../store/globalToast.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUploadLogoMutation } from "~/store/customTheme.redux";

interface FileUploadProps {
  type: "light" | "dark" | "favicon";
  fileWidth: number;
  fileHeight: number;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}

const UploadButton = styled(LoadingButton)`
  min-width: 100px;
  min-height: 30px;
  max-width: fit-content;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.warning.contrastText};
  background-color: ${({ theme }) => theme.palette.warning.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.warning.dark};
  }
`;

const CustomInput = styled(Input)`
  width: 300px;
  margin-right: 30px;
`;

const FileUpload = ({
  type,
  fileWidth,
  fileHeight,
  selectedFile,
  setSelectedFile,
}: FileUploadProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploadLogoFile, { isLoading }] = useUploadLogoMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const { height, width } = image;
        if (height !== fileHeight || width !== fileWidth) {
          setError(
            dispatch,
            `${type} must be ${fileHeight}x${fileWidth} pixels.`,
            "CUSTOM",
            "setting/mediaUpload"
          );
        } else {
          setSelectedFile(file);
        }
        URL.revokeObjectURL(image.src);
      };
    }
  };

  const uploadFile = async () => {
    if (selectedFile) {
      await uploadLogoFile({
        type,
        file: selectedFile,
      });
      setSelectedFile(null);
    }
  };

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <CustomInput
        id={`${type}-file-input`}
        type="file"
        onChange={handleFileChange}
      />
      <UploadButton
        size="small"
        onClick={uploadFile}
        loading={isLoading}
        disabled={!selectedFile}
      >
        {t("settings.media.uploadBtn")}
      </UploadButton>
    </Box>
  );
};

export default FileUpload;
