import { Cell } from "./cell";
import { ProfitAndLossColumn } from "./profitAndLossTable";
import React from "react";
import { Row } from "./tableData";

interface HeaderProps {
  width?: string;
  columns: ProfitAndLossColumn[];
}

export const Header = ({ width, columns }: HeaderProps) => {
  return (
    <Row $width={width}>
      {columns.map(({ header, ...rest }) => {
        return <Cell value={header} {...rest} />;
      })}
    </Row>
  );
};
