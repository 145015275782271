import * as Sentry from "@sentry/browser";

import { clearStore, partnerWidgetRegex } from "~/store/user.redux";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import LoadingIndicator from "../components/loadingIndicator/loadingIndicator";
import React from "react";
import cookies from "browser-cookies";
import { handleLoginSucceed } from "../modules/login/login";
import { useHistory } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";

export const TokenLoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const user = useSelector((state) => state.user);

  const [isLoginSuccessful, setIsLoginSuccessful] = React.useState(undefined);
  const destinationRoute = queryParams.get("redirect") || "/overview";

  const handleSuccessfulLogin = () => {
    setIsLoginSuccessful(true);
  };

  const handleFailLogin = () => {
    setIsLoginSuccessful(false);
    history.push("/");
  };

  const dispatchLogin = async () => {
    try {
      const authSource = cookies.get("authSource");
      if (authSource) {
        handleSuccessfulLogin();
      } else if (destinationRoute.match(partnerWidgetRegex)) {
        // show partner widget even if user is not logged in
        dispatch(clearStore());
        history.push(destinationRoute);
      } else if (!authSource) {
        if (!user) {
          handleFailLogin();
        } else {
          handleSuccessfulLogin();
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      handleFailLogin();
    }
  };

  React.useEffect(() => {
    dispatchLogin();
  }, [user]);

  React.useEffect(() => {
    if (isLoginSuccessful && user && user._id) {
      handleLoginSucceed(user, history, destinationRoute, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccessful, user, history, destinationRoute]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <LoadingIndicator />
    </Box>
  );
};

export default TokenLoginPage;
