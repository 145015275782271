import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import GenericBuyboxTrend from "~/modules/genericBuybox/genericBuyboxTrend";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface GenericBuyboxProps {
  singleWidget?: "trend";
  condensed?: boolean;
}

const GenericBuybox = memo(
  ({ singleWidget, condensed }: GenericBuyboxProps) => {
    const store = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    if (singleWidget && store) {
      switch (singleWidget) {
        case "trend":
          return (
            <GenericBuyboxTrend
              condensed={condensed}
              mid={store.merchantId}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              marketplace={store.marketplace}
              timezone={selectedTimezone}
            />
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  }
);

export default GenericBuybox;
