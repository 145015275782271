import { Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import RaisedButton from "~/components/buttons/raisedButton";
import React from "react";
import connectImage from "~/img/connectImage.jpg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LandingImage = styled.img`
  display: flex;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  margin: auto;
  margin-top: 2rem;
`;

const LandingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const onNextStep = () => {
    const nextPath = location.pathname + "/select";
    history.push(nextPath);
  };

  return (
    <Grid
      id="progress-wizard"
      container
      direction="column"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12}>
        <LandingImage src={connectImage} />
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <Typography variant="h1" color="primary" align="center">
          {t("connect.welcomeMessage")}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        justifyContent="center"
      >
        <RaisedButton
          id="wizard-next-button"
          color="primary"
          onClick={onNextStep}
        >
          {t("generic.nextButton")}
        </RaisedButton>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
