import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { LayoutProps, useLayoutProps } from "../chartUtils/chartComponents";

import React from "react";
import { percentColour } from "~/theming/utils";

export enum Variant {
  Percent = "PERCENT",
  Segments = "SEGMENTS",
}

type ChartLayoutProps = Partial<LayoutProps> & {
  title: string;
  donutWidth?: number;
  donutOuterRadius?: number;
};

type PercentType = {
  // any other variant must have a discriminant called 'variant'
  variant: Variant.Percent;
  percent: number;
} & ChartLayoutProps;

type SegmentType = {
  // any other variant must have a discriminant called 'variant'
  variant: Variant.Segments;
  data: { name: string; value: number }[];
} & ChartLayoutProps;

const isPercent = (props: PercentType | SegmentType): props is PercentType => {
  return (props as PercentType).variant === Variant.Percent;
};

export type DonutChartProps = PercentType | SegmentType;

export const DEFAULT_DONUT_WIDTH = 14; // based on Walmart listing quality donut

interface DonutData {
  value: number;
  name?: string;
  colour?: string;
}
const DonutChart: React.FC<DonutChartProps> = ({
  donutWidth = DEFAULT_DONUT_WIDTH,
  ...props
}) => {
  const { title } = props;
  // grab any of the layoutprops here, note if LayoutProps were passed as props to this component directly, we use those
  const {
    height,
    margin: { top, bottom, left, right },
  } = { ...useLayoutProps("donutChart"), ...props };
  const theme = useTheme();

  const DEFAULT_COLOURS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.error.main,
  ];

  let intPercent = isPercent(props) ? Math.round(props.percent) : 0; // intPercent only used when isPercent
  const data: DonutData[] = [];
  if (props.variant === Variant.Percent) {
    data.push({
      value: 100 - intPercent,
      colour: theme.palette.border.dark,
    });
    const colour = percentColour(intPercent, theme);
    data.push({ value: intPercent, colour });
  } else {
    data.push(...props.data);
  }

  const LABEL_SPACE = 20; // To add sufficient space for the external labels
  let donutOuterRadius = height / 2 - top;
  if (!isPercent(props)) donutOuterRadius = donutOuterRadius - LABEL_SPACE;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={height}>
          <PieChart margin={{ top, bottom, left, right }}>
            <Pie
              data={data}
              blendStroke
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={donutOuterRadius - donutWidth}
              outerRadius={donutOuterRadius}
              labelLine={!isPercent(props)}
              label={!isPercent(props)}
              startAngle={isPercent(props) ? 90 : 0} // 90 = 12 o clock according to rechart! Necassary for the Walmart listing quality design
              endAngle={isPercent(props) ? 450 : 360} // the angle increments counter clockwise
            >
              {data.map((e, index) => (
                <Cell
                  key={`${e.value}-${index}`}
                  fill={
                    e.colour ?? DEFAULT_COLOURS[index % DEFAULT_COLOURS.length]
                  }
                />
              ))}
              {isPercent(props) && (
                <Label
                  value={`${intPercent}%`}
                  style={{
                    fontWeight: theme.typography.chartSummary.fontWeight,
                    fontSize: theme.typography.chartSummary.fontSize,
                    fontFamily: (theme.typography as any).fontFamily,
                    fill: percentColour(intPercent, theme),
                  }}
                  position="center"
                />
              )}
            </Pie>
            {!isPercent(props) && <Tooltip />}
            {!isPercent(props) && (
              <Legend layout="vertical" verticalAlign="middle" align="right" />
            )}
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default React.memo(DonutChart);
