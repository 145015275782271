import { Box, Grid } from "@material-ui/core";
import {
  DEFAULT_SORT_KEY,
  DEFAULT_SORT_ORDER,
} from "./cohortPurchaseBehaviour";
import React, { ReactChild, memo, useMemo } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import { sortBy } from "lodash";
import { useCohortTrendQuery } from "~/store/mystore/customerLtv.redux";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const CHART_TYPE = {
  Customer: "customer",
  Revenue: "revenue",
};

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

interface MetricChartConfig {
  topBar: {
    key: string;
    colour: string;
    isCurrency: boolean;
    axis: "1" | "2";
  };
  bottomBar: {
    key: string;
    colour: string;
    isCurrency: boolean;
    axis: "1" | "2";
  };
  totalsKey: string;
}

interface CLVCohortTrendChartProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  timezone: string;
  chartType: ChartOption;
  actions?: ReactChild;
}

const CLVCohortTrendChart = memo<CLVCohortTrendChartProps>(
  function CLVCohortTrendChart({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentPeriod,
    currentRange,
    timezone,
    chartType,
    actions,
  }) {
    const { t } = useTranslation();

    const theme = useTheme();

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { isLoadingCohortTrend, chartData } = useCohortTrendQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
        sortKey: DEFAULT_SORT_KEY,
        sortOrder: DEFAULT_SORT_ORDER,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isLoadingCohortTrend: isFetching,
            chartData: data?.data ? sortBy(data.data, "startTime") : [],
          };
        },
      }
    );

    const convertedChartData = useMemo(() => {
      return chartData.map((data) => {
        return {
          ...data,
          totalRevenueNotRepeatCustomers: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            data.totalRevenueNotRepeatCustomers,
            2
          ),
          totalRevenueRepeatCustomers: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            data.totalRevenueRepeatCustomers,
            2
          ),
          totalRevenue: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            data.totalRevenue,
            2
          ),
          averageLTV: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            data.averageLTV,
            2
          ),
          averageRepeatLTV: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            data.averageRepeatLTV,
            2
          ),
        };
      });
    }, [currentCurrency, chartData, currencyRates]);

    const metricOptions: Record<
      (typeof CHART_TYPE)[keyof typeof CHART_TYPE],
      MetricChartConfig
    > = {
      [CHART_TYPE.Customer]: {
        topBar: {
          key: "distinctNonRepeatCustomersCount",
          colour: theme.palette.secondary.main,
          isCurrency: false,
          axis: "1",
        },
        bottomBar: {
          key: "distinctRepeatCustomersCount",
          colour: theme.palette.primary.main,
          isCurrency: false,
          axis: "1",
        },
        totalsKey: "distinctCustomersCount",
      },
      [CHART_TYPE.Revenue]: {
        bottomBar: {
          key: "totalRevenueRepeatCustomers",
          colour: theme.palette.primary.main,
          isCurrency: true,
          axis: "1",
        },
        topBar: {
          key: "totalRevenueNotRepeatCustomers",
          colour: theme.palette.secondary.main,
          isCurrency: true,
          axis: "1",
        },
        totalsKey: "totalRevenue",
      },
    };
    const { topBar, bottomBar, totalsKey } = metricOptions[chartType.value];

    return (
      <Panel
        id="clv-cohort-trend"
        title={t("chartTitle.cohortTrendTitle")}
        subtitle={t("chartTitle.cohortTrendSubtitle")}
        titleGridSizeMd={9}
        actions={actions}
        content={
          <Box p={3}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                {chartData.length > 0 && bottomBar && (
                  <StackedBarAndLineChart
                    bottomBar={bottomBar}
                    topBar={topBar}
                    line={{
                      key: "averageLTV",
                      colour: theme.palette.primary.dark,
                      toFixed: 2,
                    }}
                    view={
                      chartType.value === CHART_TYPE.Customer
                        ? "units"
                        : "currency"
                    }
                    lineUnit="currency"
                    chartData={convertedChartData}
                    currentPeriod={currentPeriod}
                    isLoading={isLoadingCohortTrend}
                    timezone={timezone}
                    title={t("chartTitle.cohortTrendTitle")}
                    xKey="startTime"
                    currentCurrency={currentCurrency}
                    interval={currentRange.interval}
                    axisLabels={{
                      x: {
                        value: t("chartTitle.cohortTrendTitleXAxisLabel"),
                        position: "bottom",
                        fontSize: "12px",
                        offset: 10,
                      },
                    }}
                    totalsKey={totalsKey}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default CLVCohortTrendChart;
