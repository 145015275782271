import { Box, Paper, Switch, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { CurrentStore } from "~/typedef/store";
import { DateRange } from "~/typedef/date";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { ProfitAndLossTable } from "./profitAndLossTable";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useFormattedTableData } from "./hooks/useFormattedTableData";
import { useMarketplace } from "~/utils/navigationUtils";
import { useProfitAndLossColumns } from "./hooks/useProfitAndLossColumns";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorChannelProfitAndLossQuery } from "~/store/mystore/vendorProfitability.redux";

interface VendorProfitAndLossProps {
  title: string;
  store: CurrentStore;
  currentRange: DateRange;
  currentCurrency: string;
  isComparison?: boolean;
  sellType: SellType;
  setChargebackUploadDialogOpen: (open: boolean) => void;
  setDeductionUpdateDialogOpen: (open: boolean) => void;
}

const PanelWrapper = styled(Paper)`
  overflow: hidden;
`;

const PanelHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 200px;
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VendorProfitAndLoss = memo<VendorProfitAndLossProps>(
  function VendorProfitAndLoss({
    title,
    store,
    currentRange,
    currentCurrency,
    isComparison,
    sellType,
    setChargebackUploadDialogOpen,
    setDeductionUpdateDialogOpen,
  }) {
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [showBreakdown, setShowBreakdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);

    const canUploadData =
      !store.isDemoMode && !hasFilteredSuffix(store.marketplace);

    const {
      income,
      expense,
      metrics,
      isFetchingVendorProfitAndLoss,
      currency,
      snsFromDate,
      snsToDate,
    } = useVendorChannelProfitAndLossQuery(
      {
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        countryCode: store.marketplaceCountry,
        currentRange,
        isComparison,
        sellType,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            ...data,
            currency: data?.currency || currentCurrency,
            isFetchingVendorProfitAndLoss: isFetching,
          };
        },
      }
    );

    useEffect(() => {
      // force artificial loading state to show loading indicator
      // when user toggles the detailed P&L, loading indicator makes the transition smoother
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // 0.5 seconds
    }, [showBreakdown]);

    const { formattedTableData } = useFormattedTableData({
      income,
      expense,
      metrics,
      snsFromDate,
      snsToDate,
      showBreakdown,
      currencyRates,
      sellType,
      currentCurrency,
      currency,
      setChargebackUploadDialogOpen,
      setDeductionUpdateDialogOpen,
      canUploadData,
    });

    const columns = useProfitAndLossColumns({
      isComparison,
      currentCurrency,
    });

    return (
      <PanelWrapper elevation={2} className="break-before">
        <PanelHeader>
          <Typography variant="h3">{title}</Typography>
          <FlexBox>
            <DownloadCsv
              {...{
                reportType: "vendorChannelProfitability",
                path: "/api/generic/vendorProfitability/channel",
                mid: store?.merchantId,
                params: {
                  customerId: userInfo._id,
                  currentRange: {
                    ...currentRange,
                    fromDate: isComparison
                      ? currentRange.priorFromDate
                      : currentRange.fromDate,
                    toDate: isComparison
                      ? currentRange.priorToDate
                      : currentRange.toDate,
                  },
                  currentCurrency,
                  shopName: store?.storeName,
                  marketplaceName: useMarketplace(),
                  countryCode: store?.marketplaceCountry,
                  marketplaceType: store?.marketplace,
                  sellType,
                  marketplaceSubtype:
                    store?.marketplaceSubtype || store?.marketplace,
                  exchangeRate: getExchangeRate(
                    currencyRates,
                    getCurrencyByCountryCode[store?.marketplaceCountry || ""],
                    currentCurrency
                  ),
                  showBreakdown,
                },
              }}
            />
            <Typography variant="subtitle1" color="textPrimary" noWrap>
              {t("profitability.expandProfitAndLoss")}
            </Typography>
            <Switch
              size="small"
              checked={showBreakdown}
              onClick={() => {
                setShowBreakdown(!showBreakdown);
              }}
            />
          </FlexBox>
        </PanelHeader>
        <Box>
          {loading || isFetchingVendorProfitAndLoss ? (
            <LoadingWrapper>
              <PanelLoading />
            </LoadingWrapper>
          ) : (
            <ProfitAndLossTable {...formattedTableData} columns={columns} />
          )}
        </Box>
      </PanelWrapper>
    );
  }
);

export default VendorProfitAndLoss;
