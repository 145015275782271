// Min width of a table at each breakpoint
const columnBreakpoints = {
  xs: 300,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const MARKETPLACE_CELL_WIDTH = 80;
const CHANNEL_CELL_WIDTH = 160;

/**
 * This function dynamically calculates the desired column width
 * based off the current screen size, number of columns and column
 * content.
 *
 * Changing this code will affect every table in the application so
 * proceed with caution.
 *
 * @param {*} column current column
 * @param {*} rows table data
 * @param {*} maxWidth max size of the column according to screen size
 */
export const getColumnWidth = (column, rows, maxWidth) => {
  const { Header, accessor } = column;
  const letterSpacing = 11;
  //average width of cell components in non text based cells
  const composedItemWidth = 45;
  const iconWidth = 20;

  if (column.customWidth) {
    return {
      width: column.customWidth,
      disableFlexGrow: true,
      oversized: false,
      oversizedAmount: 0,
    };
  }

  if (column.Header === "Market") {
    return {
      width: MARKETPLACE_CELL_WIDTH,
      disableFlexGrow: true,
      oversized: false,
      oversizedAmount: 0,
    };
  }

  if (column.Header === "Channel") {
    return {
      width: CHANNEL_CELL_WIDTH,
      disableFlexGrow: false,
      oversized: false,
      oversizedAmount: 0,
    };
  }

  if (column.useColSpan) {
    return {
      width: maxWidth * column.colSpan,
      oversized: false,
      oversizedAmount: 0,
    };
  }

  const cellLength = Math.max(
    ...rows.map((row) => {
      if (row) {
        const rowValue =
          typeof accessor === "function" ? accessor(row) : row[accessor];

        if (typeof rowValue === "object" && rowValue !== null) {
          // Custom table cells with an image (assuming you use the image key as a naming convention)
          if (rowValue.image) {
            return (
              `${rowValue.value}`.length * letterSpacing + composedItemWidth
            );
          }
          // Custom table cells with a link
          if (rowValue.link) {
            return `${rowValue.value}`.length * letterSpacing;
          }
          // Custom table cells with an icon
          if (rowValue.icon) {
            return `${rowValue.value}`.length * letterSpacing + iconWidth;
          }
          if (rowValue.status) {
            return `${rowValue.value}`.length * letterSpacing + iconWidth;
          }
          // Value and growth cells
          if (rowValue.growth) {
            return (
              `${rowValue.value}`.length * letterSpacing +
              `${rowValue.growth}`.length * letterSpacing +
              iconWidth
            );
          }
          // Add more logic for custom cells here

          // Default for where the custom cell logic has not been defined, most are roughly this size
          // For example the flag and the marketplace icon
          return composedItemWidth + composedItemWidth;
        }
        // Cells that are just text
        return (`${rowValue}` || "").length * letterSpacing;
      }
      return letterSpacing;
    }),
    Header.length * letterSpacing
  );

  return {
    width: Math.min(maxWidth * (column.colSpan || 1), cellLength),
    oversized: !column.alwaysSmall && Boolean(maxWidth < cellLength),
    oversizedAmount: cellLength - maxWidth,
  };
};

/**
 * This function gets the width for each column
 *
 * It also takes "spare" width from undersized columns and gives it
 * to large ones.
 *
 * Changing this code will affect every table in the application so
 * proceed with caution.
 *
 * @param {*} columns all columns in the table
 * @param {*} data table data
 * @param {*} size size of the screen (see breakpoints above)
 * @param {*} gridLayoutColumns number of grid layout columns this table takes up (12 = full width)
 */
export const generateResponsiveColumns = (
  columns,
  data,
  size,
  gridLayoutColumns
) => {
  if (!data) {
    return columns;
  }
  const FULL_WIDTH_GRID_LAYOUT_COLUMNS = 12;
  const numOfColumns = columns.reduce(
    (acc, column) => acc + (column.colSpan || 1),
    0
  );
  const screenProportion = gridLayoutColumns / FULL_WIDTH_GRID_LAYOUT_COLUMNS;
  let availableWidth = screenProportion * columnBreakpoints[size];

  const responsiveColumns = columns.map((column) => {
    const maxWidth = column.alwaysSmall
      ? (screenProportion * columnBreakpoints["xs"]) / numOfColumns
      : (screenProportion * columnBreakpoints[size]) / numOfColumns;
    const columnWidth = getColumnWidth(column, data, maxWidth);
    availableWidth = availableWidth - columnWidth.width;
    return {
      ...column,
      ...columnWidth,
    };
  });

  const numOfOversizedColumns = responsiveColumns.filter(
    (column) => column.oversized
  ).length;

  return responsiveColumns.map((column) => {
    if (column.oversized) {
      const leftoverWidthPortion = availableWidth / numOfOversizedColumns;
      const allocatedWidth =
        leftoverWidthPortion > column.oversizedAmount
          ? column.oversizedAmount
          : leftoverWidthPortion;
      column.width = column.width + allocatedWidth;
    }

    return column;
  });
};
