import { Box } from "@material-ui/core";
import RaisedButton from "~/components/buttons/raisedButton";
import React from "react";

const GenericConnectButton = ({
  children,
  disabled,
  onSuccess,
}: {
  children: React.ReactElement;
  disabled: boolean;
  onSuccess: () => void;
}) => {
  return (
    <>
      <RaisedButton color="primary" onClick={onSuccess} disabled={disabled}>
        <Box width="100%" display="flex">
          <Box m="auto">{children}</Box>
        </Box>
      </RaisedButton>
    </>
  );
};

export default GenericConnectButton;
