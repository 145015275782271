import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import { Grid, Tooltip, Typography, useTheme } from "@material-ui/core";
import React, { memo } from "react";
import {
  formatCurrency,
  formatCurrencyRounded,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import { CampaignsTotals } from "~/typedef/store";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { OptionalStatusProps } from "~/typedef/status";
import StatusText from "~/components/typography/status";
import get from "lodash/get";
import { getPercentageDifference } from "~/utils/salesUtils";
import styled from "styled-components";
import { useAdvertisingOnly } from "~/hooks/useAdvertisingOnly";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GridContainer = styled(Grid)`
  ${({ theme }) => `
    align-items: center;
    justify-content: flex-start;
    ${theme.breakpoints.down("sm")} {
      justify-content: center;
    }
  `}
`;

const FlexGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const InheritGrid = styled(Grid)`
  display: inherit;
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: -2px;
`;

const InlineTooltip = styled(Tooltip)`
  margin-bottom: -4px;
`;

const AcosGridContainer = styled(Grid)`
  ${({ theme }) => `
    align-items: flex-start;
    ${theme.breakpoints.down("sm")} {
      justify-content: center;
      align-items: center;
    }
  `}
`;
const TotalGridContainer = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      justify-content: center;
      align-items: center;
    }
  `}
`;

export enum AdvertisingType {
  ACOS = "acos",
  ROAS = "roas",
  TACOS = "tacos",
  TROAS = "troas",
}

export enum SuccessDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

const LargeNumber = styled(Typography)`
  ${({ theme }) => `
    font-size: 30px;
    display: inline;
    ${theme.breakpoints.down("lg")} {
      font-size: 26px;
    }
    ${theme.breakpoints.down("md")} {
      font-size: 24px;
    }
    ${theme.breakpoints.down("sm")} {
      font-size: 22px;
    }
  `}
`;

const LightH = styled(Typography)<OptionalStatusProps>`
  font-weight: 300;
  display: inline;
  color: ${({ theme, $status }) =>
    $status ? theme.palette[$status].main : theme.palette.text.secondary};
`;

const Currency = styled(Typography)`
  margin-left: 5px;
  font-weight: 300;
  display: inline;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

interface PerformanceSummaryProps {
  data: {
    current: CampaignsTotals;
    prior: CampaignsTotals;
  } | null;
  homeCurrency: string;
  currentCurrency: string;
  isOverview?: boolean;
  conditionalFormatting?: boolean;
}

export const getStatus = (
  successDirection: SuccessDirection,
  arrowDirection: SuccessDirection,
  conditionalFormatting?: boolean
): undefined | "success" | "error" => {
  if (conditionalFormatting === false) {
    return undefined;
  } else if (successDirection === arrowDirection) {
    return "success";
  } else {
    return "error";
  }
};
export const getDifference = (
  current: number,
  prior: number,
  isPptChange: boolean,
  successDirection: SuccessDirection,
  conditionalFormatting?: boolean,
  toFixedDigits?: number,
  noUnit?: boolean
): React.ReactElement => {
  const diff: number = isPptChange
    ? current - prior
    : Number(getPercentageDifference(current, prior));

  if (isNaN(diff)) {
    return (
      <StatusText variant="body2" align="right" paragraph={false}>
        -
      </StatusText>
    );
  }

  let arrow: React.ReactElement, status: undefined | "success" | "error";
  if (diff >= 0) {
    status = getStatus(
      successDirection,
      SuccessDirection.ASCENDING,
      conditionalFormatting
    );
    arrow = <UpArrow fontSize="inherit" status={status} />;
  } else {
    status = getStatus(
      successDirection,
      SuccessDirection.DESCENDING,
      conditionalFormatting
    );
    arrow = <DownArrow fontSize="inherit" status={status} />;
  }
  return (
    <StatusText variant="body2" align="right" paragraph={false} status={status}>
      {arrow}
      {isNaN(diff)
        ? diff.toString()
        : Math.abs(diff).toFixed(toFixedDigits ?? 0)}
      {!isNaN(diff) && (isPptChange ? (noUnit ? "" : "ppt") : "%")}
    </StatusText>
  );
};

const PerformanceSummary = memo<PerformanceSummaryProps>(
  function PerformanceSummary({
    data,
    homeCurrency,
    currentCurrency,
    isOverview,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();
    const currencyRates = useSelector((state) =>
      get(state, "globalVar.currencyRates", [])
    );
    const isAdvertisingOnly = useAdvertisingOnly();

    if (data === null) {
      // TODO: Display Error Message
      return null;
    }

    const { current, prior } = data;
    const {
      totalAdvertisingSales,
      totalCost,
      impressions,
      clicks,
      orders,
      units,
      acos,
      roas,
      tacos,
      troas,
      costPerClick,
      conversionRate,
      ntbUnits,
      ntbUnitsPercentage,
    } = current;
    const {
      totalAdvertisingSales: priorTotalAdvertisingSales,
      totalCost: priorTotalCost,
      impressions: priorImpressions,
      clicks: priorClicks,
      orders: priorOrders,
      units: priorUnits,
      acos: priorAcos,
      roas: priorRoas,
      tacos: priorTacos,
      troas: priorTroas,
      costPerClick: priorCostPerClick,
      conversionRate: priorConversionRate,
      ntbUnits: priorNtbUnits,
      ntbUnitsPercentage: priorNtbUnitsPercentage,
    } = prior;

    return (
      <GridContainer container spacing={2}>
        {/* START TOTAL SALES */}
        <TotalGridContainer container item spacing={1} xs={6}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("dashboardWidget.advertisingPerformance.totalSales")}
            </Typography>
          </Grid>
          <FlexGrid item md={12}>
            <LargeNumber variant="h1">
              {formatCurrencyRounded(
                totalAdvertisingSales,
                currencyRates,
                homeCurrency,
                currentCurrency
              )}
            </LargeNumber>
            <Currency variant="h4" color="textSecondary">
              {currentCurrency}
            </Currency>
          </FlexGrid>
          <Grid item md={12}>
            {totalAdvertisingSales !== 0
              ? getDifference(
                  totalAdvertisingSales,
                  priorTotalAdvertisingSales,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </TotalGridContainer>
        {/* END TOTAL SALES

              START TOTAL SPEND */}
        <TotalGridContainer container item spacing={1} xs={6}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("dashboardWidget.advertisingPerformance.totalSpend")}
            </Typography>
          </Grid>
          <FlexGrid item md={12}>
            <LargeNumber variant="h1">
              {formatCurrencyRounded(
                totalCost,
                currencyRates,
                homeCurrency,
                currentCurrency
              )}
            </LargeNumber>
            <Currency variant="h4" color="textSecondary">
              {currentCurrency}
            </Currency>
          </FlexGrid>
          <Grid item md={12}>
            {totalCost !== 0
              ? getDifference(
                  totalCost,
                  priorTotalCost,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </TotalGridContainer>
        {/* END TOTAL SPEND

              START ACOS/TACOS */}
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.acosColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">{acos.toFixed(1)}%</LightH>
            {acos !== 0
              ? getDifference(
                  acos,
                  priorAcos,
                  true,
                  SuccessDirection.DESCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={isOverview ? 3 : 8}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.roasColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">{roas.toFixed(1)}</LightH>
            {getDifference(
              roas,
              priorRoas,
              true,
              SuccessDirection.ASCENDING,
              conditionalFormatting,
              1,
              true
            )}
          </Grid>
        </AcosGridContainer>
        {isOverview && !isAdvertisingOnly && (
          <>
            <AcosGridContainer container item spacing={1} xs={3}>
              <InheritGrid item md={12}>
                <Typography variant="h6">
                  {t("advertisingDashboardWidget.adTable.tacosColumn")}
                </Typography>
                <StyledTooltip
                  title={t("advertisingDashboardWidget.adTable.taxTooltip")}
                >
                  <InlineIconButton />
                </StyledTooltip>
              </InheritGrid>
              <Grid item md={12}>
                <LightH variant="h5">
                  {tacos ? `${tacos.toFixed(1)}%` : "-"}
                </LightH>
                {tacos && priorTacos && tacos !== 0 && !isNaN(tacos)
                  ? getDifference(
                      tacos,
                      priorTacos,
                      true,
                      SuccessDirection.DESCENDING,
                      conditionalFormatting
                    )
                  : null}
              </Grid>
            </AcosGridContainer>
            <AcosGridContainer container item spacing={1} xs={3}>
              <InheritGrid item md={12}>
                <Typography variant="h6">
                  {t("advertisingDashboardWidget.adTable.troasColumn")}
                </Typography>
                <StyledTooltip
                  title={t("advertisingDashboardWidget.adTable.taxTooltip")}
                >
                  <InlineIconButton />
                </StyledTooltip>
              </InheritGrid>
              <Grid item md={12}>
                <LightH variant="h5">
                  {troas ? `${troas.toFixed(1)}` : "-"}
                </LightH>
                {troas && priorTroas && troas !== 0 && !isNaN(troas)
                  ? getDifference(
                      troas,
                      priorTroas,
                      true,
                      SuccessDirection.ASCENDING,
                      conditionalFormatting,
                      1,
                      true
                    )
                  : null}
              </Grid>
            </AcosGridContainer>
          </>
        )}
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.impressionsColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">
              {intFormatterRounded.format(impressions)}
            </LightH>
            {impressions !== 0 && !isNaN(impressions)
              ? getDifference(
                  impressions,
                  priorImpressions,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.clicksColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">{intFormatterRounded.format(clicks)}</LightH>
            {clicks !== 0 && !isNaN(clicks)
              ? getDifference(
                  clicks,
                  priorClicks,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.ordersColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">{intFormatterRounded.format(orders)}</LightH>
            {orders !== 0 && !isNaN(orders)
              ? getDifference(
                  orders,
                  priorOrders,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <InheritGrid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.unitsColumn")}
            </Typography>
            <StyledTooltip
              title={t("advertisingDashboardWidget.adTable.unitsColumnTooltip")}
            >
              <InlineIconButton />
            </StyledTooltip>
          </InheritGrid>
          <Grid item md={12}>
            <LightH variant="h5">{intFormatterRounded.format(units)}</LightH>
            {units !== 0 && !isNaN(units)
              ? getDifference(
                  units,
                  priorUnits,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.costPerClickColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">
              {formatCurrency(
                costPerClick,
                currencyRates,
                homeCurrency,
                currentCurrency
              )}
            </LightH>
            {costPerClick !== 0
              ? getDifference(
                  costPerClick,
                  priorCostPerClick,
                  false,
                  SuccessDirection.DESCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.conversionColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">{conversionRate.toFixed(1)}%</LightH>
            {conversionRate !== 0
              ? getDifference(
                  conversionRate,
                  priorConversionRate,
                  true,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <InheritGrid item md={12}>
              <Typography variant="h6">
                {t(
                  "advertisingDashboardWidget.adTable.ntbUnitsPercentageColumn"
                )}
                <InlineTooltip
                  title={t(
                    "advertisingDashboardWidget.adTable.ntbUnitsPercentageColumnTooltip"
                  )}
                >
                  <InlineIconButton />
                </InlineTooltip>
              </Typography>
            </InheritGrid>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">-</LightH>
          </Grid>
        </AcosGridContainer>
        <AcosGridContainer container item spacing={1} xs={3}>
          <Grid item md={12}>
            <Typography variant="h6">
              {t("advertisingDashboardWidget.adTable.ntbUnitsColumn")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <LightH variant="h5">
              {ntbUnits !== 0 ? intFormatterRounded.format(ntbUnits) : "-"}
            </LightH>
            {ntbUnits !== 0
              ? getDifference(
                  ntbUnits,
                  priorNtbUnits,
                  false,
                  SuccessDirection.ASCENDING,
                  conditionalFormatting
                )
              : null}
          </Grid>
        </AcosGridContainer>
      </GridContainer>
    );
  }
);

export default PerformanceSummary;
