import RaisedButton from "./raisedButton";
import styled from "styled-components";

const SmallButton = styled(RaisedButton)`
  min-width: fit-content;
  min-height: 1.625rem;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 500;
  padding: 4px 20px;
  word-break: keep-all;
`;
export default SmallButton;
