import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import { DotPoint } from "./specificsRecommendations";
import InfoBox from "~/components/info/infoBox";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Panel from "~/components/panel/panel.tsx";
import PercentageBar from "~/components/percentageBar";
import PropTypes from "prop-types";
import React from "react";
import SmallButton from "~/components/buttons/smallButton";
import get from "lodash/get";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dot = styled.div`
  background-color: red;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

const Headings = ({
  categoryName,
  correctPercentage,
  invalidPercentage,
  missingPercentage,
  requiredToggle,
  toggleSwitch,
  handleExportCSV,
  syncToEbay,
  totalUnsyncedItems,
  isDemoMode,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isPercentageAllZero =
    !missingPercentage && !invalidPercentage && !correctPercentage;
  const categoryArray = categoryName ? categoryName.split(">") : null;
  const mainCategory = categoryArray ? categoryArray.pop() : "[Discontinued]";
  const parentCategory = categoryArray ? `${categoryArray.pop()} >` : "";
  return (
    <Panel
      id="widget-item-specifics-listings"
      title={t("myStoresWidget.itemSpecifics.listingsTitle")}
      content={
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={4}
              lg={3}
              justifyContent="center"
              direction="column"
            >
              <Typography variant="body2" noWrap>
                {parentCategory}
              </Typography>
              <Typography noWrap>{mainCategory}</Typography>
              <Box pt={4} maxWidth={235}>
                <SmallButton color="secondary" onClick={() => history.goBack()}>
                  <KeyboardArrowLeft fontSize="small" />
                  {t("myStoresWidget.itemSpecifics.backButton")}
                </SmallButton>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={8}
              lg={5}
              justifyContent="center"
              direction="column"
            >
              {isPercentageAllZero ? (
                <Typography variant="body1">
                  {t(
                    "myStoresWidget.itemSpecifics.noRequiredForCategoryMessage"
                  )}
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  alignItems="center"
                >
                  <Typography variant="body1">
                    {t("myStoresWidget.itemSpecifics.pageCompletenessLabel")}
                  </Typography>
                  <PercentageBar
                    data={[
                      {
                        percentage: correctPercentage,
                        color: get(theme, "palette.success.main"),
                        des: t("myStoresWidget.itemSpecifics.legend.correct"),
                      },
                      {
                        percentage: invalidPercentage,
                        color: get(theme, "palette.warning.main"),
                        des: t("myStoresWidget.itemSpecifics.legend.custom"),
                      },
                      {
                        percentage: missingPercentage,
                        color: get(theme, "palette.disabled.light"),
                        des: t("myStoresWidget.itemSpecifics.legend.missing"),
                      },
                    ]}
                  />
                </Box>
              )}
              <Box
                pt={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <SmallButton
                  id="item-specifics-export"
                  name="exportCSVBtn"
                  color="info"
                  onClick={handleExportCSV}
                >
                  {t("generic.exportCsvButton")}
                </SmallButton>
                <Box pl={1}>
                  {isDemoMode ? (
                    <DemoTooltip
                      arrow
                      placement="top"
                      open
                      title={t("generic.notAvailableDemoMode")}
                    >
                      <span>
                        <SmallButton
                          id="item-specifics-sync"
                          disabled={true}
                          name="syncEbayBtn"
                          color="info"
                        >
                          {t("myStoresWidget.itemSpecifics.syncButton")}
                        </SmallButton>
                      </span>
                    </DemoTooltip>
                  ) : (
                    <SmallButton
                      id="item-specifics-sync"
                      name="syncEbayBtn"
                      color="info"
                      onClick={syncToEbay}
                    >
                      {t("myStoresWidget.itemSpecifics.syncButton")}
                    </SmallButton>
                  )}
                </Box>
                {totalUnsyncedItems ? (
                  <Box pl={2} display="flex" alignItems="center">
                    <Dot />
                    <Typography variant="body2">
                      {t("myStoresWidget.itemSpecifics.itemsNotSyncedMessage", {
                        count: totalUnsyncedItems,
                      })}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid container item xs={12} lg={4} justifyContent="center">
              <InfoBox>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.listings.bulletA")}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.listings.bulletB")}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.listings.bulletC")}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.listings.bulletD")}
                    </Typography>
                  </Box>
                </Box>
              </InfoBox>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <FormControlLabel
          id="item-specifics-required"
          labelPlacement="end"
          control={
            <Switch
              size="small"
              checked={requiredToggle}
              onChange={toggleSwitch}
              value="switch"
              color="secondary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={
            <Typography variant="body2">
              {t("myStoresWidget.itemSpecifics.requiredOnlyLabel")}
            </Typography>
          }
        />
      }
    />
  );
};

Headings.propTypes = {
  categoryName: PropTypes.string.isRequired,
  correctPercentage: PropTypes.number.isRequired,
  invalidPercentage: PropTypes.number.isRequired,
  missingPercentage: PropTypes.number.isRequired,
  requiredToggle: PropTypes.bool.isRequired,
  toggleSwitch: PropTypes.func.isRequired,
  handleExportCSV: PropTypes.func.isRequired,
  syncToEbay: PropTypes.func.isRequired,
  totalUnsyncedItems: PropTypes.number.isRequired,
  isDemoMode: PropTypes.bool,
};

export default Headings;
