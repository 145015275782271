import axios from "axios";
import { baseUrl } from "../../configs";
import cookies from "browser-cookies";

let interceptorId: number | undefined = undefined;

export function jwtInterceptor(reportToken: string | null, path: string) {
  if (interceptorId !== undefined) {
    axios.interceptors.request.eject(interceptorId);
  }

  interceptorId = axios.interceptors.request.use((request) => {
    const isInternalUrl = request?.url?.startsWith(baseUrl) || false;
    if (isInternalUrl && request.headers) {
      if (path === "/report" && reportToken) {
        request.headers.authorization = `Bearer ${reportToken}`;
      } else if (cookies.get("accessToken")) {
        request.headers.authorization = `Bearer ${cookies.get("accessToken")}`;
      }
    }
    return request;
  });
}
