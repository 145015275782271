import React, { memo } from "react";

import BannerWithLink from "~/components/alert/bannerWithLink";
import { Grid } from "@material-ui/core";
import MarketingOverviewPanel from "~/modules/marketing/overview";
import OverviewChartWrapper from "~/modules/marketing/charts/overviewChartWrapper";
import OverviewTotalsWrapper from "~/modules/marketing/charts/overviewTotalsWrapper";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MarketingOverview = memo(function MarketingOverview() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const { isAdvertisingAuthorised } = useAdvertisingConnected(store);
  const { t } = useTranslation();
  return (
    <PageBlock>
      {!isAdvertisingAuthorised ? (
        <BannerWithLink
          type="info"
          isOpen={true}
          link="/connectStore/select"
          linkText={t("generic.linkButton")}
          message={t("advertising.linkAdvertisingMessage")}
        />
      ) : store ? (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={8}>
            <OverviewChartWrapper
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              marketplaceType={store.marketplace}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <OverviewTotalsWrapper
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              marketplaceType={store.marketplace}
            />
          </Grid>
          <Grid item xs={12}>
            <MarketingOverviewPanel
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              marketplace={store.marketplace}
            />
          </Grid>
        </Grid>
      ) : null}
    </PageBlock>
  );
});

export default MarketingOverview;
