import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import ErrorButton from "~/components/buttons/errorButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

interface SyncDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (fromDate: string, file: File) => void;
  isLoading?: boolean;
}

const SyncDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: SyncDialogProps) => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const selectFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setSelectedFile(event.target.files[0]);
  };

  const fromDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const submitHandler = () => {
    if (fromDate && selectedFile) {
      onConfirm(fromDate, selectedFile);
    }
  };

  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={onClose}>
        {t("profitability.uploadButtonLabel")}
      </BaseDialogTitle>
      <DialogContent>
        <DialogContentGrid container spacing={2}>
          <Grid container item xs={6} alignItems="center">
            <Typography variant="body1">
              {t("profitability.effectiveDateFromLabel")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Input
              type="date"
              name="fromDate"
              value={fromDate}
              onChange={fromDateHandler}
            />
          </Grid>
          <Grid container item xs={6} alignItems="center">
            <Typography variant="body1">
              {t("profitability.directCostFileLabel")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Input type="file" name="file" onChange={selectFileHandler} />
          </Grid>
        </DialogContentGrid>
      </DialogContent>
      <DialogActions>
        <Box mb={8} width="100%">
          <Grid spacing={2} container item xs={12} direction="row-reverse">
            <Grid container justifyContent="center" item xs={12} sm={6}>
              <RaisedButton
                disabled={!fromDate || !selectedFile}
                onClick={submitHandler}
                variant="contained"
                color="primary"
              >
                {isLoading ? (
                  <LoadingIndicator size={20} />
                ) : (
                  t("generic.confirmButton")
                )}
              </RaisedButton>
            </Grid>
            <Grid container justifyContent="center" item xs={12} sm={6}>
              <ErrorButton onClick={onClose} variant="outlined">
                {t("generic.cancelButton")}
              </ErrorButton>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </BaseDialog>
  );
};

export default SyncDialog;
