import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";
import { setInfoMessage } from "./globalToast.redux";

interface UploadLogoResponse {
  message: string;
  error?: string;
}

interface UploadLogoParams {
  type: "light" | "dark" | "favicon";
  file: File;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    uploadLogo: build.mutation<UploadLogoResponse, UploadLogoParams>({
      query: (params) => {
        const { type, file } = params;
        const formData = new FormData();

        formData.append("type", type);
        formData.append("image", file);

        return {
          url: `${baseUrl}/api/custom-theme-service/api/logo/upload`,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "uploadLogo",
        false,
        (data: UploadLogoResponse, dispatch, _args) => {
          setInfoMessage(dispatch, data.message);
        }
      ),
    }),
  }),
});

export const { useUploadLogoMutation } = extendedApiSlice;
