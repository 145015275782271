import { FilteredStore } from "~/typedef/store";
import { get } from "lodash";
import { getMatchingStore } from "./marketplaceUtils";
import { useLocation } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import { useTypedSelector } from "../hooks/useTypedSelector";

export const pathNavMap = (path: any) => {
  switch (true) {
    case path.includes("mystores"):
      return "marketplaces";
    case path.includes("overview"):
      return "overview";
    case path.includes("connect"):
      return "connect";
    case /^(\/my)/.test(path):
      return "marketplaces";
    case path.includes("reports"):
      return "reports";
    case path.includes("notifications"):
      return "notifications";
    default:
      return "";
  }
};

export const useMarketplace = <T extends Marketplace>(): T => {
  const marketplaceIndex = 2;
  const location = useLocation();
  const marketplace = location.pathname.split("/")[marketplaceIndex];
  return marketplace as T; // This will hold as long as the type Marketplace is kept up to date.
};

export const useStore = (): FilteredStore | undefined => {
  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const location = useLocation();
  const urlSearchParams = useQueryParams();
  const storeName =
    urlSearchParams.get("store") || location.pathname.split("=")[1];
  const marketplace = useMarketplace();
  const matchingStore = getMatchingStore(
    filteredStores,
    marketplace,
    storeName
  );

  return matchingStore;
};

export const isFrontPage = (location: any) => {
  const INDEX_NOT_FOUND = -1;
  return (
    location.pathname.search(/signin/) !== INDEX_NOT_FOUND ||
    location.pathname.search(/signup/) !== INDEX_NOT_FOUND ||
    location.pathname === "/"
  );
};
