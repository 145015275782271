import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { CountryLogo } from "../../../img/flags/country_logos";
import styled from "styled-components";

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(FlexGrid)`
  margin-left: 5px;
`;

interface CurrencyDisplayProps {
  activeLink?: boolean;
  changeCurrency?: (currency: string) => void;
  currencyCode: string;
  countryCode: string;
}
export const CurrencyDisplay = memo(
  ({
    countryCode,
    currencyCode,
    changeCurrency,
    activeLink,
  }: CurrencyDisplayProps) => {
    const selectCurrency = () => {
      if (activeLink && changeCurrency) {
        changeCurrency(currencyCode);
      }
    };

    return (
      <Grid
        container
        id="currency-display"
        spacing={1}
        alignItems="stretch"
        onClick={selectCurrency}
      >
        <IconWrapper item>
          <CountryLogo code={countryCode} currencyCode={currencyCode} />
        </IconWrapper>
        <FlexGrid item>
          <Typography variant="body2" color="textPrimary">
            {currencyCode}
          </Typography>
        </FlexGrid>
      </Grid>
    );
  }
);

export default CurrencyDisplay;
