import { ArrowDownwardRounded, ArrowUpwardRounded } from "@material-ui/icons";

import { Theme } from "@material-ui/core";
import styled from "styled-components";

interface ArrowProps {
  theme: Theme;
  status?: "error" | "success";
}
export const UpArrow = styled(ArrowUpwardRounded)`
  fill: ${({ theme, status }: ArrowProps) =>
    status ? theme.palette[status].main : theme.palette.text.secondary};
`;

export const DownArrow = styled(ArrowDownwardRounded)`
  fill: ${({ theme, status }: ArrowProps) =>
    status ? theme.palette[status].main : theme.palette.text.secondary};
`;
