import { Typography, alpha } from "@material-ui/core";

import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const InfoIcon = styled(InfoOutlined)`
  height: 24px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.palette.warning.main};
`;

const MessageBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: ${({ theme }) => `1px solid ${theme.palette.warning.main}`};
  background-color: ${({ theme }) =>
    alpha(theme.palette.warning.light, OPACITY)};
`;

const OPACITY = 0.3;

const ConnectAdvertisingInfo = () => {
  const { t } = useTranslation();

  return (
    <MessageBox>
      <InfoIcon />
      <Typography variant="body2">
        {t("connection.connectAdvertisingMessage")}
      </Typography>
    </MessageBox>
  );
};

export default ConnectAdvertisingInfo;
