import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import AdvertisingConnectionPrompt from "./advertisingConnectionPrompt";
import RaisedButton from "~/components/buttons/raisedButton";
import StoreConnectionPrompt from "./storeConnectionPrompt";
import { fetchAllStores } from "~/store/overview/customTags.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PanelWrapper = styled(Grid)`
  align-self: stretch;
`;

const Panel = styled(Grid)`
  padding: 2rem;
  margin: 0;
`;

const FirstPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const SecondPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.palette.border.main};
  border-left: ${({ theme }) => `2px solid ${theme.palette.border.dark}`};
`;

const ConnectionTypeToggleButton = styled(ToggleButton)`
  margin-top: 1rem;
  padding: 15px 30px;
  border-radius: 30px;
`;

enum CONNECTION_TYPE {
  ECOMMERCE = "store",
  ADVERTISING = "adStore",
}

const ConnectionSelector = () => {
  const { t } = useTranslation();
  const urlSearchParams = useQueryParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const allStoresFetching = useTypedSelector(
    (state) => state.mystore.allStores.fetching
  );

  const [storeType, setStoreType] = useState<CONNECTION_TYPE | null>(
    urlSearchParams.get("connectionType")
      ? (urlSearchParams.get("connectionType") as CONNECTION_TYPE)
      : null
  );
  const [isDone, setIsDone] = useState<boolean>(false);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    nextView: CONNECTION_TYPE
  ) => {
    startAgain();
    setStoreType(nextView);
  };

  const onSuccess = () => {
    setIsDone(true);
    if (!allStoresFetching) {
      // Fetch all stores to update the store list in the overview page
      // otherwise a new user will not see the store they just added
      // and will repeatedly be prompted to add a store
      dispatch(fetchAllStores());
    }
  };

  const clearQueryParams = () => {
    urlSearchParams.delete("step");
    urlSearchParams.delete("store");
    urlSearchParams.delete("countryCode");
    urlSearchParams.delete("state");
    urlSearchParams.delete("marketplace");
    urlSearchParams.delete("name");
    urlSearchParams.delete("country");
    urlSearchParams.delete("submarketplace");
  };

  const startAgain = () => {
    setIsDone(false);
    setStoreType(null);
    clearQueryParams();
    history.push({
      search: "?" + urlSearchParams.toString(),
    });
  };

  const goToDashboard = () => {
    setIsDone(false);
    setStoreType(null);
    clearQueryParams();
    history.push("/overview");
  };

  return (
    <PanelWrapper id="progress-wizard" container item xs={12}>
      <FirstPanel
        container
        direction="column"
        alignItems="center"
        item
        xs={12}
        md={6}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2" color="textSecondary" noWrap>
            {t("connect.selectType")}
          </Typography>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            orientation="vertical"
            value={storeType}
            exclusive
            onChange={handleChange}
          >
            <ConnectionTypeToggleButton
              value={CONNECTION_TYPE.ECOMMERCE}
              aria-label="ecommerce"
            >
              {t("connect.ecommerce")}
            </ConnectionTypeToggleButton>
            <ConnectionTypeToggleButton
              value={CONNECTION_TYPE.ADVERTISING}
              aria-label="advertising"
            >
              {t("connect.advertising")}
            </ConnectionTypeToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </FirstPanel>
      <SecondPanel
        container
        spacing={2}
        direction="column"
        alignItems="center"
        item
        xs={12}
        md={6}
      >
        {storeType === CONNECTION_TYPE.ECOMMERCE && (
          <Grid item>
            <StoreConnectionPrompt onSuccess={onSuccess} />
          </Grid>
        )}
        {storeType === CONNECTION_TYPE.ADVERTISING && (
          <Grid item>
            <AdvertisingConnectionPrompt
              onSuccess={onSuccess}
              defaultStore={urlSearchParams.get("store")}
            />
          </Grid>
        )}
        {!storeType && (
          <Grid item>
            <Typography variant="h5" color="textSecondary" noWrap>
              {t("connect.selectTypePrompt")}
            </Typography>
          </Grid>
        )}
        {isDone && (
          <>
            <Box pt={2} />
            <Grid item>
              <RaisedButton
                variant="contained"
                color="primary"
                onClick={startAgain}
              >
                {t("connect.addAnotherStore")}
              </RaisedButton>
            </Grid>
            <Grid item>
              <RaisedButton
                variant="contained"
                color="secondary"
                onClick={goToDashboard}
              >
                {t("connect.viewDashboard")}
              </RaisedButton>
            </Grid>
          </>
        )}
      </SecondPanel>
    </PanelWrapper>
  );
};

export default ConnectionSelector;
