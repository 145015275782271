import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { CountryLogo } from "../../../img/flags/country_logos";
import { CurrencyCollection } from "~/typedef/currency";
import { MarketplaceIcon } from "../../../img/marketplaces/icons/marketplaceIcon";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import styled from "styled-components";

const LegendRowElement = styled.div`
  margin-right: 4px;
  align-items: center;
  display: flex;
`;

const StoreNameContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

const intFormatter = new Intl.NumberFormat(void 0, {
  useGrouping: true, // separating thousands etc
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface SalesMixPieChartLegendItemProps {
  storeName: string;
  market?: string;
  countryCode?: string;
  salesValue: number;
  piePercentage: number;
  currentCurrency: string;
  noMargin?: boolean;
  compact?: boolean;
}

const SalesMixPieChartLegendItem = memo(
  ({
    storeName,
    market,
    countryCode,
    salesValue,
    piePercentage,
    currentCurrency,
    noMargin = false,
    compact = false,
  }: SalesMixPieChartLegendItemProps) => {
    const piePercentageText = Number.isNaN(piePercentage)
      ? "N/A"
      : `${piePercentage}%`;
    return (
      <Box
        display="flex"
        flexDirection="column"
        // eslint-disable-next-line no-magic-numbers
        mb={noMargin ? 0 : compact ? 0.5 : 2}
        mr={1}
        mt={compact ? "3px" : 0}
        minWidth={0}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          overflow="hidden"
        >
          {market && countryCode ? (
            <>
              <LegendRowElement>
                <MarketplaceIcon market={market} />
              </LegendRowElement>
              <LegendRowElement>
                <CountryLogo code={countryCode} currencyCode={undefined} />
              </LegendRowElement>
            </>
          ) : null}
          <StoreNameContainer>
            <Typography
              variant={compact ? "subtitle2" : "body1"}
              noWrap
              style={{ minWidth: 0 }}
            >
              {storeName}
            </Typography>
          </StoreNameContainer>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
        >
          <LegendRowElement>
            <Typography
              variant={compact ? "subtitle2" : "body2"}
              style={{ fontWeight: compact ? 600 : 400 }}
            >
              {getCurrencySymbol[currentCurrency as keyof CurrencyCollection]}
              {intFormatter.format(salesValue)}
            </Typography>
          </LegendRowElement>
          <LegendRowElement>
            <Typography
              variant={compact ? "subtitle2" : "body2"}
              color="textSecondary"
              style={{ fontWeight: 300 }}
            >
              ({piePercentageText})
            </Typography>
          </LegendRowElement>
        </Box>
      </Box>
    );
  }
);

export default SalesMixPieChartLegendItem;
