import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { Detail } from "~/components/typography/detail";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { ListingQualityStatus } from "~/modules/marketplaceListings/listingQuality/listingQualityStatus";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import ProductTable from "~/modules/marketplaceListings/listingQuality/productTable";
import SearchFilter from "~/components/adTable/searchFilter";
import { StoreState } from "~/typedef/store";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DetailContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const ComingSoon = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.body2.fontSize};
  font-style: italic;
`;

// TODO remove the partial - just set it temporarily as we are
// displaying 'coming soon' for now so not passing any props in
// TODO move this component into a module when removing the 'coming soon'
// That module could use the Component LabelledData
interface UnpublishedProps {
  totalUnpublishedProducts: number;
  unpublishedInventory: number;
  unpublishedValue: {
    amount: number;
    currency: string;
  };
  appealedProducts: number;
}
export const UnpublishedProductsStatus: React.FC<Partial<UnpublishedProps>> = (
  props
) => {
  const {
    totalUnpublishedProducts,
    unpublishedInventory,
    // unpublishedValue: { amount, currency },
    appealedProducts,
  } = props;
  // const currencyRates = useSelector<any>((state) =>
  //   state.globalVar ? state.globalVar.currencyRates : []
  // );
  // const formattedValue = formatCurrencyRounded(
  //   amount,
  //   currencyRates,
  //   currency,
  //   currentCurrency as string
  // );

  const { t } = useTranslation();
  return (
    <Box p={2}>
      <Grid container>
        <ItemGrid item xs={12}>
          <DetailContainer>
            <Detail display="inline">
              {t(
                "myStoresWidget.listingQuality.walmart.unpublished.totalUnpublishedProducts"
              )}
            </Detail>
            <Tooltip
              title={`${t(
                "myStoresWidget.listingQuality.walmart.unpublished.totalUnpublishedProductsTooltip"
              )}`}
            >
              <InlineIconButton />
            </Tooltip>
          </DetailContainer>
          <ComingSoon>Coming soon</ComingSoon>
          {/* <ChartSummary >
            {intFormatterRounded.format(totalUnpublishedProducts)}

          </ChartSummary> */}
        </ItemGrid>
        <ItemGrid item xs={12}>
          <DetailContainer>
            <Detail>
              {t(
                "myStoresWidget.listingQuality.walmart.unpublished.unpublishedInventory"
              )}
            </Detail>
            <Tooltip
              title={`${t(
                "myStoresWidget.listingQuality.walmart.unpublished.unpublishedInventoryTooltip"
              )}`}
            >
              <InlineIconButton />
            </Tooltip>
          </DetailContainer>
          <ComingSoon>Coming soon</ComingSoon>
          {/* <ChartSummary >
            {intFormatterRounded.format(unpublishedInventory)}
          </ChartSummary> */}
        </ItemGrid>
        <ItemGrid item xs={12}>
          <DetailContainer>
            <Detail>
              {t(
                "myStoresWidget.listingQuality.walmart.unpublished.unpublishedValue"
              )}
            </Detail>
            <Tooltip
              title={`${t(
                "myStoresWidget.listingQuality.walmart.unpublished.unpublishedValueTooltip"
              )}`}
            >
              <InlineIconButton />
            </Tooltip>
          </DetailContainer>
          <ComingSoon>Coming soon</ComingSoon>
          {/* <ChartSummary >{formattedValue}</ChartSummary> */}
        </ItemGrid>
        <ItemGrid item xs={12}>
          <DetailContainer>
            <Detail>
              {t(
                "myStoresWidget.listingQuality.walmart.unpublished.appealedProducts"
              )}
            </Detail>
            <Tooltip
              title={`${t(
                "myStoresWidget.listingQuality.walmart.unpublished.appealedProductsTooltip"
              )}`}
            >
              <InlineIconButton />
            </Tooltip>
          </DetailContainer>
          <ComingSoon>Coming soon</ComingSoon>
          {/* <ChartSummary >
            {intFormatterRounded.format(appealedProducts)}
          </ChartSummary> */}
        </ItemGrid>
      </Grid>
    </Box>
  );
};

const WalmartListingQuality: React.FC = () => {
  const { t } = useTranslation();
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const [searchText, setSearchText] = useState("");

  const FILTER_OPTIONS = [
    {
      value: null,
      label: t("myStoresWidget.listingQuality.allProductsLabel"),
    },
    {
      value: "ACTIVE",
      label: t("myStoresWidget.listingQuality.activeProductsLabel"),
    },
    {
      value: "ARCHIVED",
      label: t("myStoresWidget.listingQuality.archivedProductsLabel"),
    },
  ];

  const [statusFilter, setStatusFilter] = useState(FILTER_OPTIONS[0]);

  const switchType = (value: string | null) => {
    const option = FILTER_OPTIONS.find(
      (filterOption) => filterOption.value === value
    );
    if (option) {
      setStatusFilter(option);
    }
  };

  const unpublishedProps = {};
  return store ? (
    <>
      <Grid item xs={12} md={8}>
        <ListingQualityStatus />
      </Grid>
      <Grid item xs={12} md={4}>
        <Panel
          id="widget-walmart-unpublished-product-status"
          title={t("myStoresWidget.listingQuality.walmart.unpublished.title")}
          tooltip={t(
            "myStoresWidget.listingQuality.walmart.unpublished.tooltip"
          )}
          content={<UnpublishedProductsStatus {...unpublishedProps} />}
        />
      </Grid>
      <Grid item xs={12}>
        <Panel
          id="widget-walmart-unpublished-product-table"
          title={t("myStoresWidget.listingQuality.walmart.table.title")}
          content={
            <ProductTable
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              searchText={searchText}
              statusFilter={statusFilter.value}
            />
          }
          actions={
            <>
              <SearchFilter {...{ setSearchText }} />
              <Box pl={2}>
                <TableFilter
                  {...{
                    width: "auto",
                    options: FILTER_OPTIONS,
                    currentValue: statusFilter.label,
                    handleChange: switchType,
                  }}
                />
              </Box>
              <DownloadCsv
                {...{
                  mid: store.merchantId,
                  reportType: "walmartListingQuality",
                  path: "/api/walmart/listingQuality/products",
                  params: {
                    statusFilter: statusFilter.value,
                    searchText,
                    shopName: store.storeName,
                    countryCode: store.marketplaceCountry,
                  },
                }}
              />
            </>
          }
        ></Panel>
      </Grid>
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default WalmartListingQuality;
