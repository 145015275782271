import {
  FrontendNotification,
  NotificationStatus,
  ProductNotificationType,
  UserNotificationSetting,
} from "mm-utils-frontend";
import {
  NotificationPieChartData,
  NotificationsRow,
  NotificationsState,
  Store,
} from "~/typedef/store";

import { Dispatch } from "redux";
import { FrequencyValue } from "~/pages/notifications/notificationSettings";
import axios from "axios";
import { baseUrl } from "~/configs";
import { get } from "lodash";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";
import { shouldUseCache } from "./utils/shouldUseCache";

export const INITIAL_STATE = "INITIAL_STATE";

export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS =
  "FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS";
export const FETCH_NOTIFICATIONS_PIE_CHART_SUCCESS =
  "FETCH_NOTIFICATIONS_PIE_CHART_SUCCESS";
export const FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_SUCCESS =
  "FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_SUCCESS";
export const FETCH_NOTIFICATIONS_FETCHING = "FETCH_NOTIFICATIONS_FETCHING";
export const FETCH_NOTIFICATIONS_PIE_CHART_FETCHING =
  "FETCH_NOTIFICATIONS_PIE_CHART_FETCHING";
export const FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_FETCHING =
  "FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_FETCHING";
export const FETCH_NOTIFICATION_SETTING_SUCCESS =
  "FETCH_NOTIFICATION_SETTING_SUCCESS";
export const FETCH_NOTIFICATION_SETTING_FETCHING =
  "FETCH_NOTIFICATION_SETTING_FETCHING";
export const SAVE_NOTIFICATION_SETTING_SUCCESS =
  "SAVE_NOTIFICATION_SETTING_SUCCESS";
export const NOTIFICATION_SETTING_SAVING = "NOTIFICATION_SETTING_SAVING";
export const SAVE_ALL_NOTIFICATION_SETTING_SUCCESS =
  "SAVE_ALL_NOTIFICATION_SETTING_SUCCESS";
export const ALL_NOTIFICATION_SETTING_SAVING =
  "ALL_NOTIFICATION_SETTING_SAVING";
export const UPDATE_NOTIFICATION_STATUS_SUCCESS =
  "UPDATE_NOTIFICATION_STATUS_SUCCESS";
export const UPDATE_NOTIFICATION_STATUS_FETCHING =
  "UPDATE_NOTIFICATION_STATUS_FETCHING";
export const FETCH_DAYS_COVER_LIMIT_SUCCESS = "FETCH_DAYS_COVER_LIMIT_SUCCESS";
export const FETCH_DAYS_COVER_LIMIT_FETCHING =
  "FETCH_DAYS_COVER_LIMIT_FETCHING";

const initState: NotificationsState = {
  all: { fetching: false, triggerRefetch: false },
  pieChart: { fetching: false },
  pieChartByStore: { fetching: false },
  setting: { fetching: false },
  daysCoverLimits: { fetching: false },
};

type FetchNotificationsFetchingAction = {
  type: typeof FETCH_NOTIFICATIONS_FETCHING;
};

type FetchNotificationsPieChartFetchingAction = {
  type: typeof FETCH_NOTIFICATIONS_PIE_CHART_FETCHING;
};

type FetchNotificationsPieChartByStoreFetchingAction = {
  type: typeof FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_FETCHING;
};

type FetchNotificationsSuccessfulAction = {
  type: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: {
    data: NotificationsRow[];
    count: number;
    params?: FetchNotificationsParams;
  };
};

type FetchNewNotificationsCountSuccessfulAction = {
  type: typeof FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS;
  payload: number;
};

type FetchNotificationsPieChartSuccessfulAction = {
  type: typeof FETCH_NOTIFICATIONS_PIE_CHART_SUCCESS;
  payload: {
    data: NotificationPieChartData[];
  };
};

type FetchNotificationsPieChartByStoreSuccessfulAction = {
  type: typeof FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_SUCCESS;
  payload: {
    data: NotificationPieChartData[];
  };
};

type FetchNotificationSettingFetchingAction = {
  type: typeof FETCH_NOTIFICATION_SETTING_FETCHING;
};

type FetchNotificationSettingSuccessfulAction = {
  type: typeof FETCH_NOTIFICATION_SETTING_SUCCESS;
  payload: {
    userSettings: UserNotificationSetting;
  };
};

type SaveNotificationSettingSavingAction = {
  type: typeof NOTIFICATION_SETTING_SAVING;
};

type SaveNotificationSettingSuccessfulAction = {
  type: typeof SAVE_NOTIFICATION_SETTING_SUCCESS;
  payload: {
    data: UserNotificationSetting;
  };
};

type SaveAllNotificationSettingSavingAction = {
  type: typeof ALL_NOTIFICATION_SETTING_SAVING;
};

type SaveAllNotificationSettingSuccessfulAction = {
  type: typeof SAVE_ALL_NOTIFICATION_SETTING_SUCCESS;
};

type ChangeNotificationStatusFetchingAction = {
  type: typeof UPDATE_NOTIFICATION_STATUS_FETCHING;
  payload: {
    messageId: string;
  };
};

type ChangeNotificationStatusSuccessfulAction = {
  type: typeof UPDATE_NOTIFICATION_STATUS_SUCCESS;
  payload: {
    messageId: string;
    status: NotificationStatus;
  };
};

type FetchDaysCoverLimitFetchingAction = {
  type: typeof FETCH_DAYS_COVER_LIMIT_FETCHING;
};

type FetchDaysCoverLimitSuccessfulAction = {
  type: typeof FETCH_DAYS_COVER_LIMIT_SUCCESS;
  payload: {
    data: { mid: string; daysCoverLimits?: number }[];
  };
};

type NotificationsAction =
  | SaveAllNotificationSettingSavingAction
  | SaveAllNotificationSettingSuccessfulAction
  | ChangeNotificationStatusFetchingAction
  | ChangeNotificationStatusSuccessfulAction
  | FetchNotificationsFetchingAction
  | FetchNotificationsPieChartFetchingAction
  | FetchNotificationsPieChartByStoreFetchingAction
  | FetchNotificationsSuccessfulAction
  | FetchNewNotificationsCountSuccessfulAction
  | FetchNotificationsPieChartSuccessfulAction
  | FetchNotificationsPieChartByStoreSuccessfulAction
  | FetchNotificationSettingFetchingAction
  | SaveNotificationSettingSavingAction
  | FetchNotificationSettingSuccessfulAction
  | SaveNotificationSettingSuccessfulAction
  | FetchDaysCoverLimitFetchingAction
  | FetchDaysCoverLimitSuccessfulAction;

export const notifications = (
  state: NotificationsState = initState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_FETCHING:
      return {
        ...state,
        all: { ...state.all, fetching: true, triggerRefetch: false },
      };
    case FETCH_NOTIFICATIONS_PIE_CHART_FETCHING:
      return {
        ...state,
        pieChart: { ...state.pieChart, fetching: true },
      };
    case FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_FETCHING:
      return {
        ...state,
        pieChartByStore: { ...state.pieChartByStore, fetching: true },
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        all: {
          ...action.payload,
          fetching: false,
          triggerRefetch: false,
        },
      };
    case FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unresolvedCount: action.payload,
      };
    case FETCH_NOTIFICATIONS_PIE_CHART_SUCCESS:
      return {
        ...state,
        pieChart: {
          ...state.pieChart,
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_SUCCESS:
      return {
        ...state,
        pieChartByStore: {
          ...state.pieChartByStore,
          ...action.payload,
          fetching: false,
        },
      };
    case FETCH_NOTIFICATION_SETTING_FETCHING:
      return {
        ...state,
        setting: { ...state.setting, fetching: true },
      };
    case FETCH_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          data: action.payload.userSettings,
          fetching: false,
        },
      };
    case NOTIFICATION_SETTING_SAVING:
      return {
        ...state,
        setting: { ...state.setting, fetching: true },
      };
    case SAVE_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          ...state.setting,
          data: {
            ...(state.setting.data as UserNotificationSetting),
            updatedAt: new Date(),
          },
          fetching: false,
        },
      };
    case ALL_NOTIFICATION_SETTING_SAVING:
      return {
        ...state,
        setting: { ...state.setting, fetching: true },
      };
    case SAVE_ALL_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        setting: { ...state.setting, fetching: false },
      };
    case UPDATE_NOTIFICATION_STATUS_FETCHING:
      const idx = state.all.data?.findIndex(
        (n) => n.messageId === action.payload.messageId
      );
      if (!state.all.data || !action.payload.messageId || idx === undefined) {
        return state;
      }

      const beforeSlice = state.all.data.slice(0, idx);
      const afterSlice = state.all.data.slice(idx + 1, state.all.data.length);
      const elem = state.all.data[idx];
      const newElem = {
        ...elem,
        fetching: true,
      };

      return {
        ...state,
        all: {
          ...state.all,
          data: [...beforeSlice, newElem, ...afterSlice],
          triggerRefetch: false,
        },
      };
    case UPDATE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          triggerRefetch: true,
        },
      };
    case FETCH_DAYS_COVER_LIMIT_FETCHING:
      return {
        ...state,
        daysCoverLimits: {
          ...state.daysCoverLimits,
          fetching: true,
        },
      };
    case FETCH_DAYS_COVER_LIMIT_SUCCESS:
      return {
        ...state,
        daysCoverLimits: {
          ...action.payload,
          fetching: false,
        },
      };
    default:
      return state;
  }
};

export interface FetchNotificationsParams {
  searchText?: string;
  status: string | null;
  notificationType: string | null;
  messageIdFilter: string | null;
  pageIndex: number;
  pageSize: number;
  mids: string[];
}

export interface BackendNotification extends FrontendNotification {
  _id: string;
}

export const fetchNotifications =
  (
    params: FetchNotificationsParams,
    oldParams?: FetchNotificationsParams,
    triggerRefetch?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    if (!shouldUseCache(params, oldParams) || triggerRefetch) {
      dispatch({ type: FETCH_NOTIFICATIONS_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/mm-notification-management-service/api/notification`,
          params
        );

        if (isHttpResponseValid(status) && data) {
          const notificationRows = data.data?.map(
            (notification: BackendNotification): NotificationsRow => ({
              isProduct: Object.values(ProductNotificationType).includes(
                notification.notificationType as ProductNotificationType
              ),
              ...notification,
              imageUrl: notification.payload.imageUrl as string | undefined,
              linkUrl: notification.payload.linkUrl as string | undefined,
            })
          );

          return dispatch({
            type: FETCH_NOTIFICATIONS_SUCCESS,
            payload: { data: notificationRows, count: data.count, params },
          });
        } else {
          return setError(dispatch, data, status, "fetchNotifications");
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export const fetchNewNotificationsCount =
  (mids: string[]) => async (dispatch: Dispatch) => {
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/mm-notification-management-service/api/notification/unresolved`,
        {
          mids,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_NEW_NOTIFICATIONS_COUNT_SUCCESS,
          payload: data.count,
        });
      } else {
        return setError(dispatch, data, status, "fetchNewNotificationsCount");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

export const getNotificationsChartData =
  (mids: string[]) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_PIE_CHART_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/mm-notification-management-service/api/notification/unresolvedByType`,
        {
          mids,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_NOTIFICATIONS_PIE_CHART_SUCCESS,
          payload: { data: data.data, total: data.total },
        });
      } else {
        return setError(dispatch, data, status, "getNotificationsChartData");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

export interface GetNotificationsPieChartParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
}

export const getNotificationsChartDataByStore =
  (params: GetNotificationsPieChartParams) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/mm-notification-management-service/api/notification/unresolvedByStore`,
        params
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_NOTIFICATIONS_PIE_CHART_BY_STORE_SUCCESS,
          payload: { data: data.data, total: data.total },
        });
      } else {
        return setError(
          dispatch,
          data,
          status,
          "getNotificationsChartDataByStore"
        );
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

export interface SaveNotificationSettingsParams {
  marketplaceType: string | undefined;
  mid: string;
  userId: string;
  notificationType: string;
  isEnabled?: boolean;
  email: string | undefined;
  emailFrequency: FrequencyValue;
  advancedConfig?: {
    daysCover: string;
  };
}

export const saveNotificationSettings =
  (params: SaveNotificationSettingsParams) => async (dispatch: Dispatch) => {
    dispatch({ type: NOTIFICATION_SETTING_SAVING });
    try {
      const url =
        params.notificationType === "ALL"
          ? `/api/mm-notification-management-service/api/settings/toggleAll`
          : `/api/mm-notification-management-service/api/settings`;
      const { status, data } = await axios.post(`${baseUrl}${url}`, params);
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: SAVE_NOTIFICATION_SETTING_SUCCESS,
          payload: { data },
        });
      } else {
        return setError(dispatch, data, status, "saveNotificationSettings");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "saveNotificationSettings"
      );
    }
  };

export interface ApplySettingToAllStoresParams {
  typeMap: {
    notificationType: string;
    type: "isEnabled" | "emailFrequency";
    value: any;
  }[];
}

export const applySettingToAllStores =
  (params: ApplySettingToAllStoresParams) => async (dispatch: Dispatch) => {
    dispatch({ type: ALL_NOTIFICATION_SETTING_SAVING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/mm-notification-management-service/api/settings/applyToAll`,
        params
      );
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: SAVE_ALL_NOTIFICATION_SETTING_SUCCESS,
          payload: { data, count: 10 },
        });
      } else {
        return setError(dispatch, data, status, "applyToAll");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "applyToAll"
      );
    }
  };

interface FetchNotificationParams {
  mid: string;
  marketplaceType: string;
}

export interface NotificationSetting {
  marketplaceType: string;
  mid: string;
  userId: string;
  settings: {
    LOST_BUYBOX?: {
      isEnabled: boolean;
      emailFrequency: string | number;
      products: string[];
    };
    SUPPRESSED_LISTING?: {
      isEnabled: boolean;
      emailFrequency: string | number;
      products: string[];
    };
    LOW_INVENTORY?: {
      isEnabled: boolean;
      emailFrequency: string | number;
      advancedConfig: {
        daysCover: string;
      };
      products: string[];
    };
    STORE_MISSING_PERMISSIONS?: {
      isEnabled: boolean;
      emailFrequency: string | number;
    };
  };
}

export const fetchNotificationSettings =
  ({ mid, marketplaceType }: FetchNotificationParams) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION_SETTING_FETCHING });
    try {
      const { status, data } = await axios.get(
        `${baseUrl}/api/mm-notification-management-service/api/settings`,
        {
          params: {
            mid,
            marketplaceType,
          },
        }
      );
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_NOTIFICATION_SETTING_SUCCESS,
          payload: data,
        });
      } else {
        return setError(dispatch, data, status, "fetchNotificationSettings");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "fetchNotificationSettings"
      );
    }
  };

interface UpdateNotificationStatusParams {
  messageId: string;
  status: NotificationStatus;
  email?: string;
}

interface MarkNotificationsAsReadParams {
  ids: string[];
  email?: string;
}

export const updateNotificationStatus =
  (params: UpdateNotificationStatusParams) => async (dispatch: Dispatch) => {
    const { messageId } = params;
    try {
      dispatch({
        type: UPDATE_NOTIFICATION_STATUS_FETCHING,
        payload: { messageId },
      });
      const { status, data } = await axios.put(
        `${baseUrl}/api/mm-notification-management-service/api/notification/status`,
        params
      );
      if (isHttpResponseValid(status)) {
        return dispatch({
          type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
          payload: { messageId, status: data.status },
        });
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

export const markNotificationsAsRead =
  (params: MarkNotificationsAsReadParams) => async (dispatch: Dispatch) => {
    try {
      await axios.put(
        `${baseUrl}/api/mm-notification-management-service/api/notification/read`,
        params
      );
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

interface SaveWatchList {
  marketplaceType: string;
  mid: string;
  userId: string;
  email: string | undefined;
  notificationType: string;
  newWatchlist: string[];
}
export const saveWatchList =
  (params: SaveWatchList) => async (dispatch: Dispatch) => {
    dispatch({ type: NOTIFICATION_SETTING_SAVING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/mm-notification-management-service/api/settings/watchedProducts`,
        params
      );
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: SAVE_NOTIFICATION_SETTING_SUCCESS,
          payload: { data },
        });
      } else {
        return setError(dispatch, data, status, "saveWatchList");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

export const fetchAllStoreDaysCoverLimit = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_DAYS_COVER_LIMIT_FETCHING });
  try {
    const { status, data } = await axios.post(
      `${baseUrl}/api/mm-notification-management-service/api/settings/allStoresDaysCoverLimit`
    );
    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: FETCH_DAYS_COVER_LIMIT_SUCCESS,
        payload: data,
      });
    } else {
      return setError(dispatch, data, status, "allStoresDaysCoverLimit");
    }
  } catch (err) {
    return setError(
      dispatch,
      get(err, "response.data"),
      get(err, "response.status")
    );
  }
};
