import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Grid, useTheme } from "@material-ui/core";

import Bold from "../../typography/bold";
import { LegendIndicator } from "../barChart/barChartLegend";
import Medium from "../../typography/medium";
import React from "react";
import get from "lodash/get";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const StepChartTooltip = ({ active, payload, label, timezone }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  if (active) {
    const date = formatTooltipLabel(label, "WTD", undefined, timezone);
    return (
      <BlackTooltip>
        <Box p={1}>
          <Bold variant="body2">{date}</Bold>
          <Box
            display="flex"
            mt={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container direction="row">
              <LegendIndicator
                theme={theme}
                fill={theme.palette.secondary.dark}
              />
              <Medium variant="body2">
                {t("chartLegends.salesToDate")}:&nbsp;
              </Medium>
            </Grid>

            <Medium>
              {getCurrencySymbol[currentCurrency]}
              {numberWithCommas(payload[1].value.toFixed(2))}
            </Medium>
          </Box>
          <Box display="flex" mt={0.5} alignItems="center">
            <Grid container direction="row">
              <LegendIndicator
                theme={theme}
                fill={theme.palette.secondary.light}
              />
              <Medium variant="body2">
                {t("chartLegends.expectedSales")}:&nbsp;
              </Medium>
            </Grid>
            <Medium>
              {getCurrencySymbol[currentCurrency]}
              {numberWithCommas(payload[0].value.toFixed(2))}
            </Medium>
          </Box>
        </Box>
      </BlackTooltip>
    );
  }
  return null;
};
