import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
  withTheme,
} from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { getConnectionStatus, getSyncStatus } from "~/utils/marketplaceUtils";
import { isNaN, omit } from "lodash";
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { DisconnectedStoreRow } from "./rows/disconnectedStore";
import { IndeterminateCheckbox } from "../adTable/indeterminateCheckbox";
import Pagination from "./tablePagination";
import PropTypes from "prop-types";
import { SyncingStoreRow } from "./rows/syncingStore";
import { generateResponsiveColumns } from "./tableUtils";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DownIcon = styled(ArrowDropDownIcon)`
  height: 20px;
`;

const UpIcon = styled(ArrowDropUpIcon)`
  height: 20px;
`;

const Styles = withTheme(styled.div`
  display: block;
  width: 100%;
  height: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      height: auto;
    }
  `}
  table {
    display: flex;
    flex-direction: column;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    font-size: ${({ compact }) => (compact ? "0.625rem" : "0.75rem")};
    font-weight: 400;
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
    overflow: hidden;
    tbody {
      height: 100%;
    }
    thead {
      background-color: ${({ theme }) => theme.palette.table.header};
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.palette.table.text.header};
      ${({ theme, compact }) => `
        ${theme.breakpoints.down("xs")} {
          font-size: ${compact ? "0.625rem" : "0.75rem"}
        }
     `}
    }
    tr {
      border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
      &:last-of-type {
        border-bottom: none;
      }
    }
    th,
    td {
      display: flex;
      padding: ${({ compact }) => (compact ? "0.25rem 0.5rem" : "0.5rem 1rem")};
      height: ${({ compact, tdHeight }) =>
        tdHeight ? tdHeight : compact ? "30px" : "45px"};
      align-items: center;
      position: relative;
      width: 100%;
      max-width: ${({ isMarketplaceSettingPage }) =>
        isMarketplaceSettingPage ? "300px" : "100%"};
    }
  }
  .pagination {
    border-top: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
    padding: 0.5rem;
  }
`);

const Header = styled.th`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  word-break: keep-all;
  flex: ${({ width, disableFlexGrow }) =>
    disableFlexGrow
      ? `0 1 ${width}px`
      : `1 1 ${width}px`} !important; //overrides default react-table
  border-right: ${({ divideRight }) =>
    divideRight ? `1px solid #BDBDBD` : `none`};
  cursor: ${({ onClick }) => (onClick ? `pointer` : `auto`)};
`;

const Cell = styled.td`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  word-break: keep-all;
  flex: ${({ width, disableFlexGrow }) =>
    disableFlexGrow
      ? `0 1 ${width}px`
      : `1 1 ${width}px`} !important; //overrides default react-table
  border-right: ${({ divideRight }) =>
    divideRight ? `1px solid #BDBDBD` : `none`};
`;

const NoDataRow = styled.tr`
  display: flex;
  height: 100%;
`;

const NoData = styled.td`
  min-height: 50px;
  height: calc(100% - 2rem) !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getHiddenColumns = (breakpoints, columns) =>
  columns
    .filter((column) => breakpoints[column.hiddenDown])
    .map((column) => column.id || column.accessor);

const getVisibleColumns = (breakpoints, columns) =>
  columns.filter((column) => !breakpoints[column.hiddenDown]);

const TableBase = memo(
  ({
    columns,
    filter,
    data,
    fetchData,
    loading,
    sorting,
    sortBy: sort,
    isManualSort,
    pagination,
    pageCount: count,
    pageSize,
    pageIndex: index,
    renderRowSubComponent,
    getRowProps,
    getCellProps,
    gridLayoutColumns,
    dataVersion,
    isMarketplaceSettingPage,
    hasCheckboxSelect,
    onRowSelect,
    CustomRow,
    // is this table being rendered as part of a client report?
    // allows us to adjust some of the layout for better display/printing
    isReport,
  }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const breakpoints = {
      xs: useMediaQuery(theme.breakpoints.down("xs")),
      sm: useMediaQuery(theme.breakpoints.down("sm")),
      md: useMediaQuery(theme.breakpoints.down("md")),
      lg: useMediaQuery(theme.breakpoints.down("lg")),
      xl: useMediaQuery(theme.breakpoints.down("xl")),
    };

    const size = isReport
      ? "xs"
      : Object.keys(breakpoints).find(
          (breakpoint) => breakpoints[breakpoint] === true
        );
    const hiddenColumns = getHiddenColumns(breakpoints, columns);
    const responsiveColumns = React.useMemo(
      () =>
        generateResponsiveColumns(
          getVisibleColumns(breakpoints, columns),
          data,
          size,
          gridLayoutColumns
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [columns, data, size]
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setHiddenColumns,
      selectedFlatRows,
      state: { pageIndex, selectedRowIds, sortBy },
    } = useTable(
      {
        columns: responsiveColumns,
        data,
        manualSortBy: Boolean(fetchData) || isManualSort,
        disableSortRemove: true,
        manualPagination: Boolean(fetchData),
        pageCount: count,
        initialState: {
          sortBy: sort || [],
          pageIndex: index || 0,
          pageSize,
          hiddenColumns,
        },
      },
      useFlexLayout,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
      (hooks) => {
        // eslint-disable-next-line no-unused-expressions
        hasCheckboxSelect &&
          hooks.visibleColumns.push((column) => [
            {
              id: "selection",
              // eslint-disable-next-line react/prop-types
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              // eslint-disable-next-line react/prop-types
              Cell: ({ row }) => (
                <IndeterminateCheckbox
                  {
                    // eslint-disable-next-line react/prop-types
                    ...row.getToggleRowSelectedProps()
                  }
                />
              ),
            },
            ...column,
          ]);
      }
    );

    useEffect(
      () => setHiddenColumns(getHiddenColumns(breakpoints, columns)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        breakpoints.xs,
        breakpoints.sm,
        breakpoints.md,
        breakpoints.lg,
        breakpoints.xl,
        columns,
      ]
    );

    useEffect(() => {
      if (fetchData && !loading) {
        fetchData({ pageIndex, pageSize, sortBy });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, pageIndex, pageSize, sortBy, dataVersion]);

    useEffect(() => {
      gotoPage(0);
    }, [filter, gotoPage]);

    useEffect(() => {
      if (hasCheckboxSelect && onRowSelect) {
        onRowSelect(selectedFlatRows.map((d) => d.original));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowIds]);

    const noConnections = [];
    const connections = useSelector(
      (state) =>
        get(state, "connections.connectionStatus.connections", noConnections),
      isEqual
    );
    const disconnectedStores = useSelector(
      (state) => get(state, "connections.disconnectedStores.stores", []),
      isEqual
    );

    // Render the UI for your table
    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                {headerGroup.headers.map((column) => (
                  <Header
                    key={column}
                    align={column.align}
                    width={column.customWidth ?? (column.width || null)}
                    disableFlexGrow={column.disableFlexGrow}
                    divideRight={column.divideRight}
                    onClick={column.onHeaderClick}
                    {...column.getHeaderProps(
                      sorting && column.getSortByToggleProps()
                    )}
                  >
                    {column.isSorted &&
                      (column.getSortDesc && column.getSortDesc() ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      ))}
                    {column.id === "selection" ? (
                      column.render("Header")
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: column.render("Header"),
                        }}
                      />
                    )}
                  </Header>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <NoDataRow>
                <NoData p={2}>
                  <CircularProgress />
                </NoData>
              </NoDataRow>
            ) : page.length ? (
              page.map((row, i) => {
                prepareRow(row);
                const renderCustomRow = row.original.renderCustomRow;
                const store = row.original.store;
                const cells = row.allCells;
                const marketplaceInfo = cells.find(
                  (cell) =>
                    cell.column.id === "market" ||
                    cell.column.id === "marketplace"
                );
                const { shopStatus } = marketplaceInfo
                  ? getConnectionStatus(
                      disconnectedStores,
                      get(marketplaceInfo, "value.market"),
                      store
                    )
                  : { shopStatus: true };
                const disconnected = !shopStatus;
                const syncing =
                  store && connections
                    ? getSyncStatus(connections, store)
                    : false;

                if (!isMarketplaceSettingPage && disconnected) {
                  return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                      <DisconnectedStoreRow
                        row={row}
                        getCellProps={getCellProps}
                      />
                    </tr>
                  );
                }
                if (!isMarketplaceSettingPage && syncing) {
                  return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                      <SyncingStoreRow row={row} getCellProps={getCellProps} />
                    </tr>
                  );
                }
                if (renderCustomRow) {
                  return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                      <CustomRow row={row} getCellProps={getCellProps} />
                    </tr>
                  );
                }
                return (
                  <React.Fragment key={i}>
                    <tr {...row.getRowProps(getRowProps(row))}>
                      {row.cells.map((cell) => (
                        <Cell
                          key={cell}
                          align={cell.column.align}
                          width={
                            isNaN(cell.column.width)
                              ? undefined
                              : cell.column.width
                          }
                          disableFlexGrow={cell.column.disableFlexGrow}
                          divideRight={cell.column.divideRight}
                          {...cell.getCellProps(getCellProps(cell))}
                        >
                          {cell.render("Cell")}
                        </Cell>
                      ))}
                    </tr>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                    {row.isExpanded ? renderRowSubComponent({ row }) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <NoDataRow>
                <NoData p={2}>
                  <Typography align="center">
                    {t("generic.noDataAvailableMessage")}
                  </Typography>
                </NoData>
              </NoDataRow>
            )}
          </tbody>
        </table>
        {pagination && pageCount > 1 && (
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
          />
        )}
      </>
    );
  }
);

TableBase.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          Header: PropTypes.string,
          accessor: PropTypes.string,
          hiddenDown: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  compact: PropTypes.bool,
  filter: PropTypes.string,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  sorting: PropTypes.bool,
  isManualSort: PropTypes.bool,
  sortBy: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  pagination: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  renderRowSubComponent: PropTypes.func,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  gridLayoutColumns: PropTypes.number,
  dataVersion: PropTypes.number,
  isMarketplaceSettingPage: PropTypes.bool,
  hasCheckboxSelect: PropTypes.bool,
  onRowSelect: PropTypes.func,
  CustomRow: PropTypes.func,
};

const TableComponent = (props) => {
  return (
    <Styles {...omit(props, "loading")}>
      <TableBase {...props} />
    </Styles>
  );
};

TableComponent.defaultProps = {
  compact: false,
  pagination: false,
  pageSize: 10,
  getRowProps: () => ({}),
  getCellProps: () => ({}),
  gridLayoutColumns: 12,
};

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          Header: PropTypes.string,
          accessor: PropTypes.string,
          hiddenDown: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  compact: PropTypes.bool,
  filter: PropTypes.string,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  sorting: PropTypes.bool,
  isManualSort: PropTypes.bool,
  sortBy: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.string,
    })
  ),
  pagination: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  renderRowSubComponent: PropTypes.func,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  gridLayoutColumns: PropTypes.number,
  // a bit of a hack: increment dataVersion to reload the contents of table by
  // forcing fetchData to fire
  dataVersion: PropTypes.number,
  isMarketplaceSettingPage: PropTypes.bool,
  hasCheckboxSelect: PropTypes.bool,
  onRowSelect: PropTypes.func,
  CustomRow: PropTypes.func,
  tdHeight: PropTypes.string,
};

const Table = memo(TableComponent);

export default Table;
