import { Grid, Hidden, Typography } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const NoOverflowTypography = styled(Typography)`
  width: 100%;
`;

export const CategoryCell = memo(({ row, cell, report }) => {
  const category = row.original.category || cell.value || {};

  // special formatting rules for Walmart
  if (category.current === "UNNAV") {
    category.current = "Unknown";
  }

  return (
    <Grid container direction="column">
      {report ? (
        <NoOverflowTypography variant="caption" noWrap>
          {category.parent || category.firstCategory}
        </NoOverflowTypography>
      ) : (
        <Hidden smDown>
          <NoOverflowTypography variant="caption" noWrap>
            {category.parent || category.firstCategory}
          </NoOverflowTypography>
        </Hidden>
      )}
      <NoOverflowTypography noWrap>
        {category.current || category.secondCategory}
      </NoOverflowTypography>
    </Grid>
  );
});

CategoryCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({ category: PropTypes.any }),
  }).isRequired,
  cell: PropTypes.shape({
    value: PropTypes.shape({ category: PropTypes.any }),
  }).isRequired,
  report: PropTypes.bool,
};
