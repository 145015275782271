import { Box, Grid, Typography } from "@material-ui/core";
/* eslint-disable no-magic-numbers */
import { FieldValue, FormButton } from "../subscriptionStyledComponents";
import React, { useState } from "react";
import {
  handleSubscriptionError,
  handleTokenisationError,
} from "../validationUtils";

import BraintreeCheckoutForm from "~/modules/subscription/braintreeCheckoutForm";
import PaymentSelect from "~/components/select/paymentSelect";
import PaypalCheckoutForm from "~/modules/subscription/paypalCheckoutForm";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CardImage = styled.img`
  vertical-align: middle;
  margin-right: 8px;
`;

const formatMaskedNumber = (last4CardDigits) =>
  `**** **** **** ${last4CardDigits | "****"}`;

const PaymentMethodPanel = ({ subscription, handleSubmit, plan }) => {
  const { t } = useTranslation();
  const paymentMethod = get(subscription, "paymentMethod", {});
  const { imageUrl, last4, email } = paymentMethod;
  const subscriptionErrors = useSelector((state) =>
    get(state, "subscriptions.error")
  );
  // A dirty hack to set the view mode correctly if attempting to
  // update the payment method throws an error. Needs to be fixed
  // by changed the way the redux store updates.
  const [mode, setMode] = useState(subscriptionErrors ? "edit" : "view");
  const [submitting, setSubmitting] = React.useState(false);
  const [tokenize, setTokenizeFunc] = React.useState();
  const [error, setError] = React.useState(null);
  const [paymentMethodType, setPaymentMethodType] = React.useState(
    get(subscription, "paymentMethodType", "CreditCard")
  );

  const handleError = (err) => {
    setSubmitting(false);
    if (err instanceof Response) {
      handleSubscriptionError(err, setError);
    } else {
      handleTokenisationError(err, setError);
    }
  };

  const submitToken = async (token) => {
    setError(null);
    setSubmitting(true);
    await handleSubmit(token);
  };

  const getToken = () => {
    tokenize().then(submitToken).catch(handleError);
  };

  const onBack = () => setMode("view");

  return (
    <>
      {mode === "view" && (
        <Grid container spacing={2}>
          {paymentMethodType === "CreditCard" && (
            <Grid item xs={12}>
              <FieldValue gutterBottom={true}>
                {t("settings.billing.creditCardLabel")}
              </FieldValue>
              <FieldValue gutterBottom={true}>
                {imageUrl && <CardImage src={imageUrl} />}
                {formatMaskedNumber(last4)}
              </FieldValue>
            </Grid>
          )}
          {paymentMethodType === "PayPalAccount" && (
            <Grid item xs={12}>
              <FieldValue gutterBottom={true}>
                {t("settings.billing.paypalLabel")}
              </FieldValue>
              <FieldValue gutterBottom={true}>
                {imageUrl && <CardImage src={imageUrl} />}
                {email}
              </FieldValue>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormButton
              type="button"
              name="editBtn"
              color="primary"
              onClick={() => {
                setMode("edit");
              }}
            >
              {t("generic.editButton")}
            </FormButton>
          </Grid>
        </Grid>
      )}

      {mode === "edit" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={6}>
            <Typography variant="h6">
              {t("settings.billing.paymentMethodLabel")}
            </Typography>
            <Box pt={2} pb={4}>
              <PaymentSelect
                currentValue={paymentMethodType}
                handleChange={setPaymentMethodType}
              />
            </Box>
            {paymentMethodType === "CreditCard" && (
              <BraintreeCheckoutForm
                summaryProps={{ plan }}
                error={error}
                setError={setError}
                handleError={handleError}
                setTokenizeFunc={setTokenizeFunc}
                editMode={true}
              />
            )}
            {paymentMethodType === "PayPalAccount" && (
              <>
                <PaypalCheckoutForm
                  summaryProps={{ plan }}
                  error={error}
                  setError={setError}
                  handleError={handleError}
                  submitToken={submitToken}
                  editMode={true}
                />
                <Box maxWidth="150px">
                  <div id="paypal-button"></div>
                </Box>
              </>
            )}
          </Grid>
          <Grid container item xs={12}>
            <FormButton
              type="button"
              name="backBtn"
              color="secondary"
              onClick={onBack}
              disabled={submitting}
            >
              {submitting
                ? t("generic.processingMessage")
                : t("generic.cancelButton")}
            </FormButton>
            {paymentMethodType === "CreditCard" && (
              <FormButton
                type="button"
                name="updateBtn"
                color="primary"
                onClick={getToken}
                disabled={submitting}
              >
                {submitting
                  ? t("generic.processingMessage")
                  : t("generic.updateButton")}
              </FormButton>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

PaymentMethodPanel.propTypes = {
  plan: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PaymentMethodPanel;
