import { Box, Grid, useTheme } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useMemo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LineAndBarChart from "~/components/charts/comboChart/lineAndBarChart";
import { Range } from "~/typedef/store";
import { fetchSalesByProductTrend } from "~/store/mystore/salesByProduct.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByProductTableChartProps {
  mid: string;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  productId: number;
  currentCurrency: string;
  currencyRates: any[];
  timezone?: string;
}

export interface SalesByProductTrend {
  startTime: number;
  totalSales: number;
  avgPrice: number;
}

const formatSalesByProductProductData = (
  salesByProductData: SalesByProductTrend[] | undefined,
  currency: string,
  currencyRates: any[],
  currentCurrency: string
) => {
  if (!salesByProductData) {
    return [];
  }
  return salesByProductData.map((data) => ({
    startTime: data.startTime,
    avgPrice: data.avgPrice
      ? getConvertedValue(
          currencyRates,
          currency,
          currentCurrency,
          data.avgPrice
        )
      : 0.0,
    totalSales: getConvertedValue(
      currencyRates,
      currency,
      currentCurrency,
      data.totalSales
    ),
  }));
};

const SalesByProductTableChart = memo<SalesByProductTableChartProps>(
  function SalesByProductTableChart({
    mid,
    currentRange,
    currentPeriod,
    productId,
    timezone,
    currentCurrency,
    currencyRates,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const productTrend = useTypedSelector(
      (state) => state?.mystore?.productChart?.data || []
    );

    const currency = useTypedSelector(
      (state) => state?.mystore?.productChart?.currency || []
    );

    const loading = useTypedSelector(
      (state) => state?.mystore?.productChart?.fetching || false
    );

    const dispatch = useDispatch();

    const dispatchFetchSalesByProductTrend = useCallback(() => {
      dispatch(
        fetchSalesByProductTrend({
          currentRange,
          mid,
          productId,
        })
      );
    }, [currentRange, mid, productId]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchSalesByProductTrend();
      };
      fetchData();
    }, [currentRange, mid, productId]);

    const data = useMemo(
      () =>
        formatSalesByProductProductData(
          productTrend,
          currency,
          currencyRates,
          currentCurrency
        ),
      [productTrend, currentRange, mid, productId, currentCurrency]
    );

    return (
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <LineAndBarChart
              title={t(CHART_TITLES[currentPeriod])}
              currentPeriod={currentPeriod}
              chartData={data}
              currentCurrency={currentCurrency}
              isLoading={loading}
              line={{
                key: "avgPrice",
                colour: theme.palette.secondary.main,
                toFixed: 2,
                isInEndUnit: false,
                type: "step",
              }}
              bar={{
                key: "totalSales",
                colour: theme.palette.primary.main,
                toFixed: 0,
              }}
              xKey="startTime"
              report={true} // to make chart shorter
              timezone={timezone ?? ""}
              interval={currentRange.interval}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default SalesByProductTableChart;
