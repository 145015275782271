import BraintreeBadgeImage from "../../../img/creditCard/braintree-badge-light.png";
import React from "react";
import styled from "styled-components";

const BraintreeLink = styled.a`
  display: flex;
  padding-left: 1rem;
`;

const BraintreeBadge = () => {
  const merchantId = process.env["BRAINTREE_MERCHANT_ID"];

  return (
    <BraintreeLink
      href={`https://www.braintreegateway.com/merchants/${merchantId}/verified`}
      target="_blank"
      rel="noreferrer"
    >
      <img src={BraintreeBadgeImage} width="92px" height="25px" border="0" />
    </BraintreeLink>
  );
};

export default BraintreeBadge;
