import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import { connectTrademe } from "~/store/connections/connectTrademe.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useTrademeAuthcodeAuth from "~/hooks/useTrademeAuthcodeAuth";

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

const TrademeAuthorise = (props) => {
  const { children, onSuccess, onError } = props;
  const dispatch = useDispatch();
  const onCode = React.useCallback(
    (oauth_token, oauth_verifier, oauth_token_secret) => {
      dispatch(connectTrademe(oauth_token, oauth_verifier, oauth_token_secret))
        .then(() => {
          onSuccess();
        })
        .catch((e) => {
          if (onError) {
            onError(e);
          } else {
            throw e;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const triggerSignin = useTrademeAuthcodeAuth(onCode);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <RaisedButton color="primary" onClick={handleSignin} {...props}>
      <StyledWrapper>
        <StyledChildren>{children}</StyledChildren>
      </StyledWrapper>
    </RaisedButton>
  );
};

TrademeAuthorise.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.any,
};

export default TrademeAuthorise;
