const _ = require("lodash");
const {
  getItemSpecificsResults,
  itemSpecificTotals,
  compareActualAndRecommended,
} = require("./itemSpecific");

// Contains pure JS functions (as opposed to functions that depend
// on some nodejs functionality), that can be included in frontend code running
// in a browser.

// given a scores object of the form
// { totalListings: number
//   shortTitles: [ itemids:string ],
//   missingProductCodes: [ itemids:string ],
//   ... etc
//  }
// and a key in string form e.g. shortTitles,
// return an integer between 0 and 100 inclusive representing the
// percentage of entries with *good* scores (i.e. the complement of the bad
// entries given)
const extractScore = (scores, key) => {
  const badEntries = _.get(scores, key, []).length;
  const totalEntries = _.get(scores, "totalListings", 0);
  return Math.round(((totalEntries - badEntries) * 100) / totalEntries);
};

const getListingQualityMetrics = (scores, itemSpecificsScores) => {
  const totalCorrect = itemSpecificsScores.reduce(
    (total, categoryScore) => (total += categoryScore.correct),
    0
  );
  const total = itemSpecificsScores.reduce(
    (total, categoryScore) =>
      (total +=
        categoryScore.correct + categoryScore.invalid + categoryScore.missing),
    0
  );
  const itemSpecificsSummaryPercent = Math.round((totalCorrect / total) * 100);

  const metrics = [
    {
      description: "myStoresWidget.listingQuality.metrics.titleLength",
      value: extractScore(scores, "shortTitles"),
    },
    {
      description:
        "myStoresWidget.listingQuality.metrics.validProductIdentifier",
      value: extractScore(scores, "missingProductCodes"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.qualityDescription",
      value: extractScore(scores, "shortDescriptions"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.itemSpecifics",
      value: itemSpecificsSummaryPercent,
    },
    {
      description: "myStoresWidget.listingQuality.metrics.multipleImages",
      value: extractScore(scores, "insufficientImages"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.freeShipping",
      value: extractScore(scores, "noFreeShipping"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.guaranteedDelivery",
      value: extractScore(scores, "noGuaranteedDelivery"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.clickAndCollect",
      value: extractScore(scores, "noClickCollect"),
    },
    {
      description: "myStoresWidget.listingQuality.metrics.httpsCompliant",
      value: extractScore(scores, "notHttpsCompliant"),
    },
  ];

  return metrics;
};

const getListingQualitySummaryPercent = (metrics) => {
  const summaryPercent = Math.round(
    metrics.reduce((result, metric) => result + metric.value, 0) /
      metrics.length
  );

  return summaryPercent;
};

const ebaySiteByCountryCode = {
  AUS: "http://www.ebay.com.au/",
  GBR: "http://www.ebay.co.uk/",
  USA: "http://www.ebay.com/",
  CAN: "http://www.ebay.ca/",
  AUT: "http://www.ebay.at/",
  BEL: "http://www.ebay.be/",
  FRA: "http://www.ebay.fr/",
  DEU: "http://www.ebay.de/",
  ITA: "http://www.ebay.it/",
  SXM: "",
  NLD: "http://www.ebay.nl/",
  ESP: "http://www.ebay.es/",
  CHE: "http://www.ebay.ch/",
  HKG: "http://www.ebay.com.hk/",
  IND: "http://www.ebay.in/",
  IRL: "http://www.ebay.ie/",
  MYS: "http://www.ebay.com.my/",
  PHL: "http://www.ebay.ph/",
  POL: "http://www.ebay.pl/",
  SGP: "http://www.ebay.com.sg/",
};

module.exports = {
  ebaySiteByCountryCode,
  extractScore,
  getListingQualityMetrics,
  getListingQualitySummaryPercent,
  itemSpecificTotals,
  compareActualAndRecommended,
  getItemSpecificsResults,
};
