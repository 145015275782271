import React, { memo } from "react";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";

const Container = styled.div`
  position: relative;
`;

const FilledBar = styled.div`
  display: inline-block;
  width: ${({ percentage }) => `calc(100% * ${percentage})`};
  height: ${({ height }) => height || "10px"};
  margin: ${({ margin }) => margin || "0"};
  background-color: ${({ summaryStatus, theme }) =>
    theme.palette[summaryStatus].main};
`;

const UnfilledBar = styled.div`
  display: inline-block;
  width: ${({ percentage }) => `calc(100% * ${1 - percentage})`};
  height: ${({ height }) => height || "10px"};
  margin: ${({ margin }) => margin || "0"};
  background-color: ${({ theme }) => theme.palette.disabled.light};
`;

const LabelContainer = styled.div`
  position: absolute;
  top: -14px;
  right: 0px;
`;

// A horizontal score bar that changes colour and size depending on how high its
// "score" is. The higher the score, the larger the percentage of the container
// the bar will occupy.
const HorizontalScoreBar = memo(({ value, maxValue, unit, height, margin }) => {
  const percentage = (value / maxValue).toFixed(2);
  const theme = useTheme();
  const summaryStatus =
    percentage > 0.8
      ? "success"
      : percentage > 0.5
      ? "warning"
      : percentage > 0
      ? "error"
      : "disabled";
  return (
    <Container>
      <FilledBar
        summaryStatus={summaryStatus}
        theme={theme}
        percentage={percentage}
        height={height}
        margin={margin}
      />
      <UnfilledBar
        theme={theme}
        percentage={percentage}
        height={height}
        margin={margin}
      />
      <LabelContainer>
        <Typography variant="caption">{`${value}` + unit}</Typography>
      </LabelContainer>
    </Container>
  );
});

HorizontalScoreBar.defaultProps = {
  unit: "",
  height: "7px",
  margin: "0",
};

HorizontalScoreBar.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  unit: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

export default HorizontalScoreBar;
