import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SalesByProduct from "~/modules/overview/salesByProduct";
import get from "lodash/get";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const TopProductsByBSR = memo(function TopProductsByBSR() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      {store ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SalesByProduct
              mid={store.merchantId}
              marketplace={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
              countryCode={store.marketplaceCountry}
              sellerId={store.sourceSystemId}
              type="topBSR"
            />
          </Grid>
        </Grid>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default TopProductsByBSR;
