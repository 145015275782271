import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { Dispatch } from "redux";
import { FORBIDDEN } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../../configs";
import { setError } from "../globalToast.redux";

axios.defaults.withCredentials = true;

export const connectOtto =
  (authCode: string, shopName: string) => async (dispatch: Dispatch) => {
    try {
      const options = {
        method: "POST",
        url: `${baseUrl}/auth/otto/connect`,
        data: { authCode, shopName },
      };
      const res = await axios(options);

      if (
        res &&
        isHttpResponseValid(res.status) &&
        res.data &&
        res.data.code === RESPONSE_CODE.SUCCESS
      ) {
        return "Success";
      } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
        setError(dispatch, res.data.message);
        throw new Error("TokenInValid");
      } else {
        setError(dispatch, res.data.message);
        throw new Error("Failed");
      }
    } catch (err: any) {
      if (err.response && err.response.status === FORBIDDEN) {
        throw new Error("Forbidden");
      } else if (err.response && err.response.data.message) {
        setError(dispatch, err, err.response.status);
        throw new Error("Error");
      } else {
        throw new Error("Failed");
      }
    }
  };
