import { Grid, Link } from "@material-ui/core";
import React, { useMemo } from "react";

import { LinkCell } from "~/components/table/cells/linkCell";
import { NOTIFICATION_TYPE_MAP } from "~/components/table/cells/notificationTypeCell";
import { NotificationsRow } from "@typedef/store";
import { ReactTableCell } from "~/components/table/cells/actionCell";
import { Link as RouterLink } from "react-router-dom";
import SidePanel from "~/components/drawerPanel/sidePanel";
import Table from "~/components/table/table";
import { WrappedTextCell } from "~/components/table/cells/textCell";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface NotificationDetailsProps {
  notification: NotificationsRow | null;
  setDrawerOpen: (open: boolean) => void;
}

const NotificationDetails = ({
  notification,
  setDrawerOpen,
}: NotificationDetailsProps) => {
  const { t } = useTranslation();

  /** These columns are used for the detailed pop-out when clicking on a
   * purchase order i.e. this displays data for a single purchase order. */
  const columns = useMemo(
    () => [
      {
        Header: t("notifications.problem"),
        id: "Problem",
        accessor: "Problem",
        Cell: WrappedTextCell,
        customWidth: 200,
      },
      {
        Header: t("notifications.solution"),
        id: "Solution",
        accessor: "Solution",
        Cell: WrappedTextCell,
        customWidth: 200,
      },
      {
        id: "helpLink",
        Header: t("notifications.helpLink"),
        accessor: (row: any) => ({
          value: row.helpUrl ? t("notifications.viewHelpLink") : "-",
          link: row.helpUrl,
          target: "_blank",
        }),
        Cell: (props: any) => <LinkCell {...props} colorVariant="external" />,
      },
      {
        Header: t("notifications.viewIssue"),
        id: "viewIssue",
        accessor: (row: NotificationsRow) => row,
        Cell: (cell: ReactTableCell) => {
          const { notificationType: type }: NotificationsRow = cell.value;
          return NOTIFICATION_TYPE_MAP[type]?.viewIssueT !== "" ? (
            <Link
              component={RouterLink}
              to={NOTIFICATION_TYPE_MAP[type]?.viewIssueLink(cell.value) || ""}
              underline={"always"}
            >
              {t(NOTIFICATION_TYPE_MAP[type]?.viewIssueT)}
            </Link>
          ) : (
            "-"
          );
        },
      },
    ],
    [notification]
  );

  return notification ? (
    <SidePanel
      title={notification.subject}
      displayImage={notification.isProduct}
      image={notification.imageUrl}
      content={
        <Table
          {...{
            columns,
            data: [notification],
            tdHeight: "auto",
          }}
        />
      }
      handleClose={() => setDrawerOpen(false)}
    />
  ) : (
    <Grid />
  );
};

export default NotificationDetails;
