import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FulfilledByCell = memo(({ cell }) => {
  const { t } = useTranslation();
  return (
    <Grid item>
      {cell.value === "MARKETPLACE" ? (
        <Tooltip title={t("generic.fulfilledByAmazon")}>
          <Typography variant="body2">FBA</Typography>
        </Tooltip>
      ) : cell.value === "MERCHANT" ? (
        <Tooltip title={t("generic.fulfilledByMerchant")}>
          <Typography variant="body2">FBM</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2">{cell.value}</Typography>
      )}
    </Grid>
  );
});

FulfilledByCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default FulfilledByCell;
