import { Box, Link, Typography } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";

const HintBox = styled(Box)`
  background-color: #f0f5ff;
  color: ${({ theme }) => theme.palette.table.text.header};
  padding: 0.5em;
  text-align: center;
`;

interface FilterAppliedHintProps {
  text: string;
  actionText: string;
  action: () => void;
}

const FilterAppliedHint = memo(
  ({ text, actionText, action }: FilterAppliedHintProps) => {
    return (
      <HintBox>
        <Typography>
          {text}&nbsp;
          <Link onClick={action}>
            <b>{actionText}</b>
          </Link>
        </Typography>
      </HintBox>
    );
  }
);

export default FilterAppliedHint;
