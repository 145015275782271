import { setError, setInfoMessage } from "../globalToast.redux";

import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

const DATE_RANGE_TOO_LARGE = 218;

export const FETCH_REVIEW_ORDERS = "FETCH_REVIEW_ORDERS";
export const FETCH_REVIEW_ORDERS_FETCHING = "FETCH_REVIEW_ORDERS_FETCHING";

export const FETCH_REVIEW_SUMMARY = "FETCH_REVIEW_SUMMARY";
export const FETCH_REVIEW_SUMMARY_FETCHING = "FETCH_REVIEW_SUMMARY_FETCHING";

export const ORDER_STATUS = {
  Shipped: "shipped",
  Refunded: "refunded",
  Cancelled: "cancelled",
  Domestic: "domestic",
  International: "international",
};

export const FETCH_REVIEW_SETTINGS = "FETCH_REVIEW_SETTINGS";
export const UPDATE_REVIEW_SETTINGS = "UPDATE_REVIEW_SETTINGS";
export const SEND_MANUAL_REQUEST = "SEND_MANUAL_REQUEST";
export const FETCH_REVIEW_SETTINGS_FETCHING = "FETCH_REVIEW_SETTINGS_FETCHING";

const initState = {
  summary: {
    fetching: false,
  },
  orders: {
    fetching: false,
  },
  settings: {
    fetching: false,
  },
};

export const review = (state = initState, action) => {
  switch (action.type) {
    case FETCH_REVIEW_SUMMARY:
      return {
        ...state,
        summary: {
          ...action.payload,
          fetching: false,
        },
      };

    case FETCH_REVIEW_SUMMARY_FETCHING:
      return {
        ...state,
        summary: {
          ...state.summary,
          fetching: true,
        },
      };

    case FETCH_REVIEW_ORDERS:
      return {
        ...state,
        triggerRefetch: false,
        orders: {
          ...action.payload,
          fetching: false,
        },
      };

    case FETCH_REVIEW_ORDERS_FETCHING:
      return {
        ...state,
        orders: {
          ...state.orders,
          fetching: true,
        },
      };

    case UPDATE_REVIEW_SETTINGS:
      return {
        ...state,
        triggerRefetch: true,
        settings: {
          ...action.payload,
          fetching: false,
        },
      };

    case SEND_MANUAL_REQUEST:
      return {
        ...state,
        triggerRefetch: true,
      };

    case FETCH_REVIEW_SETTINGS:
      return {
        ...state,
        settings: {
          ...action.payload,
          fetching: false,
        },
      };

    case FETCH_REVIEW_SETTINGS_FETCHING:
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: true,
        },
      };

    default:
      return state;
  }
};

export const fetchReviewOrders =
  ({
    user,
    currentRange,
    mid,
    pageSize,
    pageIndex,
    sortKey,
    sortOrder,
    searchText,
    format,
    orderStatus,
    reviewStatusFilter,
  }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_REVIEW_ORDERS_FETCHING });

    try {
      let isShipped = false;
      let isRefunded = false;
      let isCancelled = false;
      let isDomestic = false;
      let isInternational = false;

      orderStatus.forEach((status) => {
        if (status.toLowerCase() === ORDER_STATUS.Shipped) {
          isShipped = true;
        } else if (status.toLowerCase() === ORDER_STATUS.Refunded) {
          isRefunded = true;
        } else if (status.toLowerCase() === ORDER_STATUS.Cancelled) {
          isCancelled = true;
        } else if (status.toLowerCase() === ORDER_STATUS.Domestic) {
          isDomestic = true;
        } else if (status.toLowerCase() === ORDER_STATUS.International) {
          isInternational = true;
        }
      });

      const requestData = {
        user: { _id: user._id },
        mid,
        ...currentRange,
        pageIndex,
        pageSize,
        sortKey,
        sortOrder,
        searchText,
        format,
        isShipped,
        isRefunded,
        isCancelled,
        isDomestic,
        isInternational,
        ...(reviewStatusFilter ? { reviewStatusFilter } : null),
      };

      const { data, status } = await axios.request({
        method: format === "csv" ? "GET" : "POST",
        url:
          format === "csv"
            ? `${baseUrl}/api/myStores/review/orders`
            : `${baseUrl}/api/generic-mws-service/api/review/orders`,
        params: requestData,
        data: requestData,
      });

      if (status === DATE_RANGE_TOO_LARGE) {
        return dispatch({
          type: FETCH_REVIEW_ORDERS,
          payload: { fetchError: "generic.dateRangeTooLarge" },
        });
      }

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_REVIEW_ORDERS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const fetchReviewSummary =
  ({ user, currentRange, mid }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_REVIEW_SUMMARY_FETCHING });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/myStores/review/summary`,
        {
          params: {
            user: { _id: user._id },
            mid,
            ...currentRange,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_REVIEW_SUMMARY,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const fetchReviewSettings =
  ({ user, mid }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_REVIEW_SETTINGS_FETCHING });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/myStores/review/settings`,
        {
          params: {
            user: { _id: user._id },
            mid,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_REVIEW_SETTINGS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const updateReviewSettings =
  ({ user, mid, settings, t }) =>
  async (dispatch) => {
    const {
      automationOn,
      excludeRefunds,
      excludeFBM,
      excludeFBA,
      excludeInternational,
      minDays,
    } = settings;
    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/myStores/review/settings/update`,
        {
          user: { _id: user._id },
          mid,
          settings: {
            automationOn,
            excludeRefunds,
            excludeFBM,
            excludeFBA,
            excludeInternational,
            minDays,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("reviewManagement.updateSuccessMessage"));
        return dispatch({
          type: UPDATE_REVIEW_SETTINGS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const sendManualReviewRequest =
  ({ user, amazonSellerId, countryCode, orders, t }) =>
  async (dispatch) => {
    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/seller-partner-refresh-service/api/solicitations/requestReview`,
        {
          customerId: user._id,
          amazonSellerId,
          countryCode,
          orders,
        }
      );

      if (isHttpResponseValid(status)) {
        setInfoMessage(
          dispatch,
          t("reviewManagement.reviewSentSuccessMessage")
        );
        return dispatch({
          type: SEND_MANUAL_REQUEST,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };
