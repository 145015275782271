import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "../../../store/utils/dateTimeUtils";
import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ReviewSummary from "~/modules/widgets/reviews/reviewSummary";
import ReviewsAutomation from "~/modules/widgets/reviews/reviewAutomation";
import ReviewsTable from "~/modules/widgets/reviews/reviewTable";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Reviews = memo(function Reviews() {
  const user = useTypedSelector((state) => get(state, "user"));
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <ReviewsAutomation mid={store?.merchantId} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReviewSummary
            userInfo={user}
            mid={store?.merchantId}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
          />
        </Grid>
        <Grid item xs={12}>
          <ReviewsTable mid={store?.merchantId} />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default Reviews;
