import { isEqual } from "lodash";

/** Dev utlity */
const getObjectDiff = (obj1: Object, obj2: Object) => {
  const diff = (Object.keys(obj1) as Array<keyof typeof obj1>).reduce(
    (result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    },
    Object.keys(obj2)
  );

  return diff;
};
/** */

export const shouldUseCache = (
  newParams: Object,
  oldParams?: Object
): boolean => {
  if (!oldParams) {
    return false;
  }
  return isEqual(oldParams, newParams);
};
