import { Box, Divider, MuiThemeProvider, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import MarketplaceOverview from "~/modules/widgets/partner/marketplaceOverview";
import PageBlock from "~/components/containers/pageBlock";
import { StoreState } from "@typedef/store";
import cookies from "browser-cookies";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import { hasOnlyDemoStores } from "~/utils/marketplaceUtils";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import theme from "~/theming/omnivoreRectangularTheme";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledPageBlock = styled(PageBlock)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: #fdfdfe;
`;

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.grey["400"]};
`;

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const RectangularWidgetPage = memo(function RectangularWidgetPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StoreState) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );
  const userCheck = user && user._id;
  const [showingDemoData, setShowingDemoData] = useState(true);
  const accessToken = cookies.get("accessToken");

  useEffect(() => {
    dispatch(fetchFilteredStores({}));
  }, [userCheck]);

  useEffect(() => {
    if (userCheck) {
      const isShowingDemoData = hasOnlyDemoStores(filteredStores);
      setShowingDemoData(isShowingDemoData);
    } else {
      setShowingDemoData(true);
    }

    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, [userCheck, filteredStores]);

  useEffect(() => {
    if (accessToken && userCheck) {
      jwtInterceptor(null, location.pathname);
    }
  }, [userCheck, accessToken, location.pathname]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StyledPageBlock disableGutters>
          <Box>
            <MarketplaceOverview
              currentCurrency="AUD"
              showingDemoData={showingDemoData}
              partnerName={t("partnerWidget.omnivore")}
            />
          </Box>
          <StyledDivider />
          <Box p={1} fontStyle="italic">
            <Typography variant="body2" align="right">
              <PrimaryText>{t("partnerWidget.omnivore")}</PrimaryText>
              &nbsp;{t("partnerWidget.poweredBy")}
            </Typography>
          </Box>
        </StyledPageBlock>
      </ThemeProvider>
    </MuiThemeProvider>
  );
});

export default RectangularWidgetPage;
