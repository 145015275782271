import { Box, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "../../../store/utils/dateTimeUtils";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingIndicator from "../../../components/loadingIndicator/loadingIndicator";
import TotalOrders from "~/components/totals/totalOrders";
import TotalSales from "~/components/totals/totalSales";
import TotalStores from "../../../components/totals/totalStores";
import { fetchMarketplaceOverviewTotals } from "../../../store/overview/marketplaceOverview.redux";
import get from "lodash/get";
import moment from "moment-timezone";

const MarketplaceOverviewTotals = memo(function MarketplaceOverviewTotals() {
  const userInfo = useSelector((state) => state.user);
  const includeTax = useSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currentPeriod = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCompare = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const selectedTimezone = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentFilter = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );

  const marketplaceOverviewTotals = useSelector(
    (state) => state.overview.marketplaceOverviewTotals
  );

  const dispatch = useDispatch();

  const dispatchFetchMarketplaceOverviewTotals = useCallback(
    (args) => {
      dispatch(
        fetchMarketplaceOverviewTotals(
          args,
          marketplaceOverviewTotals && marketplaceOverviewTotals.params
        )
      );
    },
    [dispatch, marketplaceOverviewTotals?.params]
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchMarketplaceOverviewTotals({
        user: userInfo,
        filter: currentFilter,
        currentPeriod,
        currentRange,
        includeTax,
      });
    };
    fetchData();
  }, [currentPeriod, currentRange, currentFilter, userInfo, includeTax, dispatchFetchMarketplaceOverviewTotals]);

  return marketplaceOverviewTotals.fetching ? (
    <Box p={2}>
      <LoadingIndicator />
    </Box>
  ) : (
    <Box p={2}>
      <Grid container item xs={12} alignItems="center">
        <Grid container item xs={12} md={3} alignItems="center">
          <TotalStores salesPerformance={marketplaceOverviewTotals} />
        </Grid>
        <Grid container item xs={12} md={3} alignItems="center">
          <TotalOrders
            current={marketplaceOverviewTotals.currentOrders}
            prior={marketplaceOverviewTotals.priorOrders}
            currentPeriod={currentPeriod}
          />
        </Grid>
        <Grid container item xs={12} md={3} alignItems="center">
          <TotalSales
            current={get(marketplaceOverviewTotals, "currentSales.sales")}
            prior={get(marketplaceOverviewTotals, "priorSales.sales")}
            currency={marketplaceOverviewTotals.currency}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default MarketplaceOverviewTotals;
