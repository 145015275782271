import { PlanPropTypes, formatBillingPeriod, getPlanName } from "./plan/plan";

import PropTypes from "prop-types";
import React from "react";
import { SummaryItem } from "./subscriptionStyledComponents";
import get from "lodash/get";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

/** A small reusable component for displaying the total value of a subscription
 * checkout transaction */
const CheckoutSummary = ({ plan, discountAmount, discount, newUser }) => {
  const { t } = useTranslation();
  const numTrialDays = get(discount, "numTrialDays");
  const planName = t(getPlanName(plan.planId));
  const billingFrequency =
    plan.billingFrequencyMonths === 1
      ? t("subscription.plan.monthlyLabel")
      : t("subscription.plan.yearlyLabel");
  const finalAmount = plan.cost - (discountAmount || 0);
  const paymentString = `${getSymbolFromCurrency(plan.currency)}${plan.cost} ${
    plan.currency
  } /${t(formatBillingPeriod(plan))}`;
  const paymentStringWithDiscount = `${getSymbolFromCurrency(
    plan.currency
  )}${finalAmount} ${plan.currency} /${t(formatBillingPeriod(plan))}`;
  const trialExpiry = moment().startOf("day").add(plan.trialPeriod, "days");
  const today = moment();
  const promoStart = newUser && !numTrialDays ? trialExpiry : today;
  const billingCycle = numTrialDays
    ? "day"
    : t(formatBillingPeriod(plan, false));

  const discountCycles = numTrialDays
    ? numTrialDays
    : discount
    ? discount.numberOfBillingCycles
    : 0;
  const endOfDiscount = moment(promoStart)
    .add(discountCycles, billingCycle)
    .format("LL");

  return (
    <>
      {discountAmount > 0 || numTrialDays ? (
        <SummaryItem
          leftText={`After PROMO period`}
          leftSubtitle={`Starting ${endOfDiscount}`}
          rightText={paymentString}
          rightSubtitle={t("subscription.create.incSalesTaxLabel")}
        />
      ) : newUser ? (
        <SummaryItem
          leftText={`After trial period`}
          leftSubtitle={`Starting ${endOfDiscount}`}
          rightText={paymentStringWithDiscount}
          rightSubtitle={t("subscription.create.incSalesTaxLabel")}
        />
      ) : (
        <SummaryItem
          leftText={`${planName} ${t(
            "subscription.create.subscriptionSuffix"
          )} (${billingFrequency})`}
          rightText={paymentString}
          rightSubtitle={t("subscription.create.incSalesTaxLabel")}
        />
      )}
    </>
  );
};

CheckoutSummary.propTypes = {
  plan: PlanPropTypes,
  newUser: PropTypes.bool,
  discountAmount: PropTypes.number,
  discount: PropTypes.shape({
    amount: PropTypes.number,
    numTrialDays: PropTypes.number,
    numberOfBillingCycles: PropTypes.number,
  }),
};

export default CheckoutSummary;
