import { FormControl, TextField, Typography } from "@material-ui/core";
import { getHomePath, isEmailValid } from "@merchantspring/common";
import {
  getMarketingContact,
  getMarketingContactId,
  updateMarketingContact,
} from "~/utils/intercomUtils";
import { useDispatch, useSelector } from "react-redux";

import AuthPageBlock from "~/components/containers/authPageBlock";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import WeChatQrCode from "~/img/wechat_qrcode.png";
import { isNewUser } from "~/utils/userUtils";
import styled from "styled-components";
import { updatePersonalInfo } from "~/store/user.redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// phone numbers in HK are 8-digits, Taiwan (9), China (10-11)
const MIN_PHONE_NUMBER_LENGTH = 8;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
`;

function WeChatPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  const user = useSelector((state) => state.user);
  const destination = useSelector((state) => {
    if (state.globalVar && state.globalVar.destinationRoute) {
      if (state.globalVar.destinationRoute !== "/") {
        return state.globalVar.destinationRoute;
      } else {
        return getHomePath(state.user);
      }
    }
    return getHomePath(state.user);
  });
  const bypassConnect = destination.includes("direct=true");

  const handleConnectBtn = async (e) => {
    e.preventDefault();
    setIsError(false);

    try {
      await dispatch(
        updatePersonalInfo({
          userInfo: {
            email,
            phone,
          },
        })
      );

      const contact = await getMarketingContact(user._id);
      const contactId = getMarketingContactId(contact);
      await updateMarketingContact(user._id, contactId, null, email, phone);

      if ((!user.isNewUser || bypassConnect) && destination !== null) {
        history.push(destination);
      } else {
        history.push("/connect");
      }
    } catch (err) {
      setIsError(true);
    }
  };

  return (
    <AuthPageBlock>
      <Form method="POST" onSubmit={(event) => handleConnectBtn(event)}>
        <Typography
          variant="h1"
          display="inline"
          color="textSecondary"
          align="center"
        >
          {t("register.contactDetailsLabel")}
        </Typography>
        <FormControl>
          <TextField
            fullWidth
            label={t("signin.emailLabel")}
            margin="normal"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        {isError && (
          <Typography color="error" variant="body1" align="center">
            {t("register.duplicateEmailError")}
          </Typography>
        )}
        <FormControl>
          <TextField
            fullWidth
            label={t("signin.phoneNumberLabel")}
            margin="normal"
            required
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </FormControl>
        <ButtonWrapper>
          <RaisedButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !isEmailValid(email) || phone.length < MIN_PHONE_NUMBER_LENGTH
            }
          >
            {t("generic.nextButton")}
          </RaisedButton>
        </ButtonWrapper>
        <ImageWrapper>
          <img src={WeChatQrCode} style={{ maxWidth: "100%" }}></img>
        </ImageWrapper>
      </Form>
    </AuthPageBlock>
  );
}

export default WeChatPage;
