import { Grid, useTheme } from "@material-ui/core";

import React from "react";
import styled from "styled-components";

export const LegendIndicator = styled.div<{ fill: string }>`
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: ${({ fill }) => fill};
`;

export const LegendWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

interface BarChartLegendNewProps {
  dataKeys: string[];
  colours: string[];
}

export const BarChartLegendNew = ({
  dataKeys,
  colours,
}: BarChartLegendNewProps) => {
  const theme = useTheme();

  return (
    <Grid container>
      {dataKeys.map((dataKey, i) => (
        <LegendWrapper item key={dataKey}>
          <LegendIndicator
            theme={theme}
            fill={colours[i] ?? theme.palette.primary.main}
          />
          {dataKey}
        </LegendWrapper>
      ))}
    </Grid>
  );
};
