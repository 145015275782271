import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import FailedPaymentAlert from "./failedPaymentAlert";
import PlanUpgradeAlert from "./planUpgradeAlert";
import React from "react";
import SuccessfulSubscriptionAlert from "./successfulSubscriptionAlert";
import TrialExpiryAlert from "./trialExpiryAlert";
import { fetchSubscription } from "../../../store/subscriptions.redux";
import get from "lodash/get";
import styled from "styled-components";

const AlertContainer = styled(Box)`
  margin-top: 1px;
  margin-bottom: 1px;
`;

const SubscriptionAlert = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptions);
  const user = useSelector((state) => state.user);
  const planId = get(subscriptions, "currentSubscription.planId");

  React.useEffect(() => {
    if (user && user._id) {
      dispatch(fetchSubscription());
    }
  }, []);
  return (
    <AlertContainer>
      <FailedPaymentAlert
        currentSubscription={get(subscriptions, "currentSubscription")}
      />
      <SuccessfulSubscriptionAlert planId={planId} />
      <PlanUpgradeAlert />
      <TrialExpiryAlert
        currentSubscription={get(subscriptions, "currentSubscription")}
      />
    </AlertContainer>
  );
};

export default SubscriptionAlert;
