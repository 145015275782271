import {
  FETCH_DISPATCH_STATUS_OVERVIEW,
  FETCH_DISPATCH_STATUS_OVERVIEW_ERROR,
  FETCH_DISPATCH_STATUS_OVERVIEW_FETCHING,
} from "./overview.redux";
import {
  FETCH_STORE_DISPATCH_STATUS,
  FETCH_STORE_DISPATCH_STATUS_FETCHING,
} from "../mystore/mystore.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_DISPATCH_STATUS_OVERVIEW_FETCHING,
  });
}

async function setStoreFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_STORE_DISPATCH_STATUS_FETCHING,
  });
}

interface DispatchStatusParams {
  user: User;
  filter: Filter;
  filteredStores: any[];
  timezone: string;
}

export const fetchDispatchStatus = (
  params: DispatchStatusParams,
  oldParams?: DispatchStatusParams
) => async (dispatch: Dispatch) => {
  const { user, filter, timezone } = params;
  if (!shouldUseCache(params, oldParams)) {
    await setFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/dispatchStatus`, {
        timezone,
        filter,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_DISPATCH_STATUS_OVERVIEW_ERROR);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });
    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_DISPATCH_STATUS_OVERVIEW,
          payload: { ...data, params },
        });
      } else {
        await dispatchError(dispatch, FETCH_DISPATCH_STATUS_OVERVIEW_ERROR);
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    return setError(dispatch);
  }
};

export const fetchStoreDispatchStatus = (
  user: User,
  timezone: string,
  mid?: string,
  filter?: Filter
) => async (dispatch: Dispatch) => {
  await setStoreFetching(dispatch);
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/dispatchStatus`, {
      timezone,
      mid,
      filter,
    })
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(dispatch, msg || statusText, get(err, "status"));
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: FETCH_STORE_DISPATCH_STATUS,
        payload: data,
      });
    } else {
      return setError(dispatch, res.data.errMsg, res.status);
    }
  }
  return setError(dispatch);
};
