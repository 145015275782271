import {
  FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY,
  FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR,
  FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING,
  FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY,
  FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR,
  FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING,
} from "../overview/overview.redux";
import {
  FETCH_STORE_AVERAGE_SALES_BY_DAY,
  FETCH_STORE_AVERAGE_SALES_BY_DAY_FETCHING,
} from "./mystore.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch, mid?: string) {
  await dispatch({
    type: mid
      ? FETCH_STORE_AVERAGE_SALES_BY_DAY_FETCHING
      : FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING,
  });
}

async function setMarketsFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_FETCHING,
  });
}

interface FetchSalesByDayParams {
  mid?: string;
  market?: string;
  timezone: string;
  filter: Filter;
  filteredStores: any[];
  includeTax: boolean;
}

export const fetchAverageSalesByDay =
  (params: FetchSalesByDayParams, oldParams?: FetchSalesByDayParams) =>
  async (dispatch: Dispatch) => {
    const { mid, market, timezone, filter, includeTax } = params;
    if (!shouldUseCache(params, oldParams)) {
      await setFetching(dispatch, mid);
      const res = await axios
        .post(`${baseUrl}/api/generic-mws-service/api/weeklySales/average`, {
          mid,
          market,
          timezone,
          filter,
          includeTax,
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(dispatch, FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR);
          return setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "weeklySales/average"
          );
        });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          /** For stepchart */
          return await dispatch({
            type: mid
              ? FETCH_STORE_AVERAGE_SALES_BY_DAY
              : FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY,
            payload: {
              data: res.data.data,
              params,
            },
          });
        } else {
          await dispatchError(
            dispatch,
            FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR
          );
          setError(
            dispatch,
            res.data.errMsg,
            res.status,
            "weeklySales/average"
          );
          throw `fetchAverageSalesByDay failed with error ${res}`;
        }
      }
      await dispatchError(dispatch, FETCH_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR);
      return setError(dispatch);
    }
  };

interface FetchHeatmapParams {
  user: User;
  timezone: string;
  pageIndex: number;
  pageSize: number;
  filter: Filter;
  filteredStores: any[];
  includeTax: boolean;
}

export const fetchWeeklySalesHeatmap =
  (params: FetchHeatmapParams, oldParams?: FetchHeatmapParams) =>
  async (dispatch: Dispatch) => {
    const { timezone, pageIndex, pageSize, filter, includeTax } = params;
    if (!shouldUseCache(params, oldParams)) {
      await setMarketsFetching(dispatch);
      const res = await axios
        .post(`${baseUrl}/api/generic-mws-service/api/weeklySales/heatmap`, {
          timezone,
          pageIndex,
          pageSize,
          filter,
          includeTax,
        })
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(
            dispatch,
            FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR
          );
          return setError(
            dispatch,
            msg || statusText,
            get(err, "status"),
            "weeklySales/heatmap"
          );
        });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          /** For Heatmap */
          return await dispatch({
            type: FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY,
            payload: { ...res.data, params },
          });
        } else {
          await dispatchError(
            dispatch,
            FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR
          );
          setError(
            dispatch,
            res.data.errMsg,
            res.status,
            "weeklySales/heatmap"
          );
          throw `fetchWeeklySalesHeatmap failed with error ${res}`;
        }
      }
      await dispatchError(
        dispatch,
        FETCH_MARKET_OVERVIEW_AVERAGE_SALES_BY_DAY_ERROR
      );
      return setError(dispatch);
    }
  };
