import InventorySummaryWidget from "~/modules/widgets/overview/inventorySummary";
import React from "react";
import { get } from "lodash";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const InventorySummary = memo(function InventorySummary() {
  const userInfo = useTypedSelector((state) => state.user);
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const PAGE_SIZE = 5;

  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );

  return (
    <InventorySummaryWidget
      {...{
        currentFilter: currentFilter,
        pageSize: PAGE_SIZE,
        userInfo: userInfo,
        filteredStores: filteredStores,
        timezone: selectedTimezone,
      }}
    />
  );
});

export default InventorySummary;
