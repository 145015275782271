import {
  hasAnyAdvertisingStores,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import AccountHealth from "~/modules/overview/accountHealth";
import AdSpendInvestmentVsBudget from "~/modules/overview/adSpendInvestmentVsBudget";
/** An alternate version of the store dashboard page which allows
widgets to be arranged in an arbitrary order, and for only select
widgets to be displayed. Currently used for Seller Society,
with plans to expand this by allowing each user to choose their
dashboard layout. */
import { Box } from "@material-ui/core";
import BuyBoxSummary from "~/modules/widgets/buyboxSummary";
import CampaignPerformance from "~/modules/marketing/campaignPerformance";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DispatchStatus from "~/modules/widgets/overview/dispatchStatus";
import GenericBuybox from "../pricing/genericBuyboxPerformance";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import ItemSpecifics from "~/modules/widgets/itemSpecifics/itemSpecifics";
import ListingQuality from "~/modules/widgets/listingQuality/listingQuality";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import NotificationsPieChart from "~/components/charts/pieChart/notificationsPieChart";
import OverviewChartWrapper from "~/modules/marketing/charts/overviewChartWrapper";
import { Panel } from "~/components/panel/panel";
import ProfitAndLossStatement from "~/pages/singleChannel/profitability/profitAndLossStatement";
import ProfitabilitySplit from "~/modules/widgets/profitability/profitabilitySplit";
import React from "react";
import SalesByBrandTable from "~/modules/overview/salesByBrand";
import SalesByCategoryTable from "~/modules/overview/salesByCategory";
import SalesByDay from "~/modules/widgets/salesByDay";
import SalesByProduct from "~/modules/overview/salesByProduct";
import SalesPerformance from "~/modules/overview/salesPerformance";
import SalesPerformanceAgainstBudget from "~/modules/overview/salesPerformanceAgainstBudget";
import SalesPerformanceAgainstForecast from "~/modules/overview/salesPerformanceAgainstForecast";
import TrafficAndConversionTrend from "~/modules/trafficAndConversion/trafficAndConversionTrend";
import { WidgetId } from "~/store/customLayout.redux";
import get from "lodash/get";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import styled from "styled-components";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const FullHeightBox = styled(Box)`
  height: 100%;
`;

interface CustomStoreDashboardProps {
  isAdvertisingAuthorised: boolean;
  isReconnectAdvertising: boolean;
}

const CustomStoreDashboard = ({
  isAdvertisingAuthorised,
  isReconnectAdvertising,
}: CustomStoreDashboardProps) => {
  const { t } = useTranslation();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const widgets = useTypedSelector(
    (state) => state.customLayout.layoutConfig?.myStores?.widgets
  );
  const { currentPeriod, selectedTimezone, currentRange } =
    useDateRangeFilters();
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  if (!widgets || !store) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <LoadingIndicator />
      </Box>
    );
  }

  const isAdvertising = hasAnyAdvertisingStores([store]);

  return (
    <>
      {[...widgets]
        .sort((a, b) => a.position - b.position)
        .map((widget) => {
          switch (widget.id) {
            case WidgetId.SALES_PERFORMANCE:
              return (
                <GridWithErrorBoundary item xs={12} md={8}>
                  <SalesPerformance market={store.marketplace} store={store} />
                </GridWithErrorBoundary>
              );
            case WidgetId.ACCOUNT_HEALTH:
              return (
                <GridWithErrorBoundary container item xs={12} md={4}>
                  <AccountHealth
                    market={store.marketplace as keyof typeof healthDataConfig}
                    mid={store.merchantId}
                    condensed
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.TOP_SELLERS:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <SalesByProduct
                    mid={store.merchantId}
                    marketplace={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    countryCode={store.marketplaceCountry}
                    sellerId={store.sourceSystemId}
                    type="topSellers"
                    condensed={true}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.SALES_BY_DAY:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <SalesByDay
                    mid={store.merchantId}
                    market={store.marketplace}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.ADVERTISING_PERFORMANCE:
              if (isAdvertisingAuthorised) {
                return (
                  <>
                    <GridWithErrorBoundary item xs={12} md={6}>
                      <OverviewChartWrapper
                        countryCode={store.marketplaceCountry}
                        marketplaceType={store.marketplace}
                        mid={store.merchantId}
                      />
                    </GridWithErrorBoundary>
                    <GridWithErrorBoundary item xs={12} md={6}>
                      <CampaignPerformance
                        market={store.marketplace}
                        mid={store.merchantId}
                        countryCode={store.marketplaceCountry}
                      />
                    </GridWithErrorBoundary>
                  </>
                );
              } else if (store.marketplace === "amazon") {
                return (
                  <GridWithErrorBoundary item xs={12} md={6}>
                    <Panel
                      id="widget-marketing-campaign-chart"
                      title={t(
                        "advertisingDashboardWidget.authorise.performanceTrendCampaignPerformance"
                      )}
                      content={
                        <MarketingAuthoriseUser
                          condensed
                          isReconnect={isReconnectAdvertising}
                        />
                      }
                    />
                  </GridWithErrorBoundary>
                );
              } else {
                return null;
              }
            case WidgetId.SALES_BY_CATEGORY:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <SalesByCategoryTable
                    market={store.marketplace}
                    mid={store.merchantId}
                    condensed
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.SALES_BY_BRAND:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <SalesByBrandTable
                    market={store.marketplace}
                    mid={store.merchantId}
                    condensed
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.WORST_SELLERS:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <SalesByProduct
                    mid={store.merchantId}
                    marketplace={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    countryCode={store.marketplaceCountry}
                    sellerId={store.sourceSystemId}
                    type="worstSellers"
                    condensed={true}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.BUYBOX_PERFORMANCE:
              if (store.marketplace === "amazon") {
                return (
                  <>
                    <GridWithErrorBoundary item xs={12} md={6}>
                      <FullHeightBox>
                        <GenericBuybox singleWidget={"trend"} />
                      </FullHeightBox>
                    </GridWithErrorBoundary>
                    <GridWithErrorBoundary item xs={12} md={6}>
                      <FullHeightBox>
                        <BuyBoxSummary
                          {...{
                            store: {
                              sourceSystemId: store.sourceSystemId,
                              marketplaceCountry: store.marketplaceCountry,
                              merchantId: store.merchantId,
                            },
                          }}
                        />
                      </FullHeightBox>
                    </GridWithErrorBoundary>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.DISPATCH_STATUS:
              return (
                <GridWithErrorBoundary item xs={12} md={6}>
                  <DispatchStatus
                    market={store.marketplace}
                    mid={store.merchantId}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.ITEM_SPECIFICS:
              if (store.marketplace === "ebay") {
                return (
                  <>
                    <GridWithErrorBoundary item xs={12} md={8}>
                      <ItemSpecifics
                        {...{
                          market: store.marketplace,
                          mid: store.merchantId,
                        }}
                      />
                    </GridWithErrorBoundary>
                    <GridWithErrorBoundary item xs={12} md={4}>
                      <ListingQuality
                        {...{
                          market: store.marketplace,
                          mid: store.merchantId,
                        }}
                      />
                    </GridWithErrorBoundary>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.AD_SPEND_AGAINST_BUDGET:
              if (isAdvertising) {
                return (
                  <GridWithErrorBoundary item xs={12} md={4}>
                    <AdSpendInvestmentVsBudget
                      mid={store.merchantId}
                      marketplaceType={store.marketplace}
                      countryCode={store.marketplaceCountry}
                      marketplaceSubtype={store.marketplaceSubtype}
                    />
                  </GridWithErrorBoundary>
                );
              } else {
                return null;
              }
            case WidgetId.SALES_AGAINST_BUDGET:
              return (
                <GridWithErrorBoundary item xs={12} md={4}>
                  <SalesPerformanceAgainstBudget
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.SALES_AGAINST_FORECAST:
              return (
                <GridWithErrorBoundary item xs={12} md={4}>
                  <SalesPerformanceAgainstForecast
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.TRAFFIC_AND_CONVERSION:
              if (stripFilteredSuffix(store.marketplace) === "amazon") {
                return (
                  <GridWithErrorBoundary item xs={12} md={8}>
                    <TrafficAndConversionTrend
                      mid={store.merchantId}
                      marketplaceType={store.marketplace}
                      marketplaceSubtype={
                        store.marketplaceSubtype ?? store.marketplace
                      }
                      currentRange={currentRange}
                      currentPeriod={currentPeriod}
                      timezone={selectedTimezone}
                      currentCurrency={currentCurrency}
                      condensed={true}
                    />
                  </GridWithErrorBoundary>
                );
              } else {
                return null;
              }
            case WidgetId.UNRESOLVED_NOTIFICATIONS:
              return (
                <GridWithErrorBoundary item xs={12} md={4}>
                  <NotificationsPieChart
                    title={t("notificationsWidget.unresolvedNotifications")}
                    inPanel={true}
                    store={store}
                    flexDirection="column"
                  />
                </GridWithErrorBoundary>
              );
            case WidgetId.STORE_PROFITABILITY:
              if (
                ["amazon", "walmart"].includes(
                  stripFilteredSuffix(store.marketplace) as string
                )
              ) {
                return (
                  <>
                    <GridWithErrorBoundary item xs={12} md={8}>
                      <ProfitAndLossStatement
                        title={t("nav.storeProfit")}
                        showComparison={true}
                        condensed={true}
                      />
                    </GridWithErrorBoundary>
                    <GridWithErrorBoundary item xs={12} md={4}>
                      <ProfitabilitySplit singleStore={true} condensed={true} />
                    </GridWithErrorBoundary>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.TOP_SELLERS_BSR:
              if (store.marketplace === "amazon") {
                return (
                  <GridWithErrorBoundary item xs={12} md={6}>
                    <SalesByProduct
                      mid={store.merchantId}
                      marketplace={store.marketplace}
                      marketplaceSubtype={
                        store.marketplaceSubtype ?? store.marketplace
                      }
                      countryCode={store.marketplaceCountry}
                      sellerId={store.sourceSystemId}
                      type="topBSR"
                      condensed={true}
                    />
                  </GridWithErrorBoundary>
                );
              } else {
                return null;
              }
          }
        })}
    </>
  );
};

export default CustomStoreDashboard;
