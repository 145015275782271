import React from "react";
import { SvgIcon } from "@material-ui/core";

const WarningIcon = (props: any) => (
  <SvgIcon {...props}>
    <g transform="translate(-1.8589619,-1.7520192)">
      <path d="M 12,2 C 6.48,2 2,6.48 2,12 2,17.52 6.48,22 12,22 17.52,22 22,17.52 22,12 22,6.48 17.52,2 12,2 Z" />
      <rect x="11" y="16" fill="#ffffff" width="2" height="2" />
      <path d="m 11,6 h 2 v 7 h -2 z" fill="#ffffff" />
    </g>
  </SvgIcon>
);

export default WarningIcon;
