import { DATETIME_PERIODS } from "../../../store/utils/dateTimeUtils";

export const CHART_TITLES = {
  [DATETIME_PERIODS.DAY]: "chartTitles.day",
  [DATETIME_PERIODS.YESTERDAY]: "chartTitles.yesterday",
  [DATETIME_PERIODS.WEEK]: "chartTitles.week",
  [DATETIME_PERIODS.WEEKMTS]: "chartTitles.week",
  [DATETIME_PERIODS.LAST30]: "chartTitles.last30",
  [DATETIME_PERIODS.MTD]: "chartTitles.currentMonth",
  [DATETIME_PERIODS.CURRENTMONTH]: "chartTitles.currentMonth",
  [DATETIME_PERIODS.LASTMONTH]: "chartTitles.lastMonth",
  [DATETIME_PERIODS.CURRENTQ]: "chartTitles.currentQuarter",
  [DATETIME_PERIODS.LASTQ]: "chartTitles.lastQuarter",
  [DATETIME_PERIODS.LAST12]: "chartTitles.last12",
  [DATETIME_PERIODS.YEAR]: "chartTitles.year",
  [DATETIME_PERIODS.CURRENTYEAR]: "chartTitles.currentYear",
  [DATETIME_PERIODS.CUSTOM]: "chartTitles.custom",
};

export const CHART_KEYS = {
  [DATETIME_PERIODS.DAY]: {
    current: "chartKeys.day.current",
  },
  [DATETIME_PERIODS.YESTERDAY]: {
    current: "chartKeys.yesterday.current",
  },
  [DATETIME_PERIODS.WEEK]: {
    past: "chartKeys.week.past",
    current: "chartKeys.week.current",
  },
  [DATETIME_PERIODS.WEEKMTS]: {
    past: "chartKeys.week.past",
    current: "chartKeys.week.current",
  },
  [DATETIME_PERIODS.LAST30]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.MTD]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.CURRENTMONTH]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.LASTMONTH]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.CURRENTQ]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.LASTQ]: {
    past: "chartKeys.last30.past",
    current: "chartKeys.last30.current",
  },
  [DATETIME_PERIODS.LAST12]: {
    current: "chartKeys.last12.current",
  },
  [DATETIME_PERIODS.YEAR]: {
    current: "chartKeys.year.current",
  },
  [DATETIME_PERIODS.CURRENTYEAR]: {
    past: "chartKeys.currentYear.past",
    current: "chartKeys.currentYear.current",
  },
  [DATETIME_PERIODS.CUSTOM]: {
    past: "chartKeys.custom.past",
    current: "chartKeys.custom.current",
  },
  CUSTOM_NEW: {
    current: "chartKeys.current",
    past: "chartKeys.past",
  },
};

export const removeZeroValues = (value: number | string) => {
  if (!value) return null;
  return value;
};

export const getNearestHundredOrTen = (num: number): number => {
  const mult = num > 50 ? 100 : 10;
  return Math.ceil(num / mult) * mult;
};

export const getNearestHundredOrTenFloor = (num: number): number => {
  const mult = num > 50 ? 100 : 10;
  return Math.floor(num / mult) * mult;
};

// Given a number, round it down to the closest power of 10
// e.g. f(9) = 1, f(101) = 100, f(9001) = 1000, etc
export const getNearestPowerOfTenFloor = (num: number): number => {
  return Math.pow(10, Math.floor(Math.log10(num)));
};
