import { Grid, Typography, alpha } from "@material-ui/core";
import React, { memo } from "react";
import { Status, StatusProps } from "~/typedef/status";

import CombinedLink from "../links/link";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const OPACITY = 0.3;

interface CompactProps {
  $compact?: boolean;
}

const StatusRow = styled(Grid)<StatusProps & CompactProps>`
  ${({ theme, $status, $compact }) => `
    display: flex;
    background-color: ${
      $status
        ? alpha(theme.palette[$status].light, OPACITY)
        : alpha(theme.palette.disabled.light, OPACITY)
    };
    color: white;
    align-items: center;
    justify-content: flex-start;
    height: ${$compact ? "37px" : "74px"};
    border-radius: ${$compact ? "20px" : "40px"};
    box-shadow: ${theme.shadows[1]};
    `}
`;

const Count = styled(Grid)<StatusProps & CompactProps>`
  ${({ theme, $status, $compact }) => `
    display: flex;
    background-color: ${
      $status ? theme.palette[$status].main : theme.palette.disabled.main
    };
    color: white;
    font-size: ${$compact ? "0.75rem" : "1rem"};
    align-items: center;
    justify-content: center;
    width: ${$compact ? "50px" : "80px"};
    height: 100%;
    border-radius: 40px 0 0 40px;
  `}
`;

const FlexRow = styled.div<CompactProps>`
  ${({ $compact }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${$compact ? "0.5rem" : "1rem"};
    width: ${
      $compact ? "calc(100% - 50px - 1rem)" : "calc(100% - 80px - 2rem)"
    };
    /** 100% minus count box width, minus padding */
  `}
`;

interface DispatchStatusProps {
  count: number;
  title?: string;
  status?: Status;
  link?: string | { pathname: string; search: string } | null;
  compact?: boolean;
}

const DispatchStatus = memo(
  ({
    count,
    title,
    status = "disabled",
    link,
    compact,
  }: DispatchStatusProps) => {
    const { t } = useTranslation();
    return (
      <StatusRow
        container
        item
        spacing={1}
        alignItems="center"
        $status={status}
        $compact={compact}
      >
        <Count item $status={status} $compact={compact}>
          {count}
        </Count>
        <FlexRow $compact={compact}>
          {title && (
            <Typography
              variant={compact ? "subtitle2" : "body2"}
              color="textPrimary"
            >
              {title}
            </Typography>
          )}
          {link && (
            <CombinedLink
              {...{
                to: link,
                variant: "subtitle2",
                color: "textSecondary",
                target: compact ? "_blank" : "_self",
              }}
            >
              {t("generic.viewAllLink")}
            </CombinedLink>
          )}
        </FlexRow>
      </StatusRow>
    );
  }
);

export default DispatchStatus;
