import { Button } from "@material-ui/core";
import styled from "styled-components";

const ActionButton = styled(Button)`
  padding: 2px;
  font-size: 0.75rem;
  font-weight: 400;
  border: 1px solid;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.secondary.main : "transparent"};
  border-color: ${({ theme, selected }) =>
    selected ? theme.palette.secondary.main : theme.palette.secondary.light};
  color: ${({ theme, selected }) =>
    selected
      ? theme.palette.secondary.contrastText
      : theme.palette.secondary.main};
  box-shadow: ${({ theme, selected }) =>
    selected ? theme.shadows[1] : "none"};
  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : "transparent"};
  }
`;
export default ActionButton;
