import { IssueGroup, amazonFieldNameToIssueGroup } from "mm-utils-frontend";

import { Dispatch } from "redux";
import { SuppressedProductListingResponse } from "mm-utils-frontend";
import { SuppressedProductsMarketplace } from "~/pages/singleChannel/marketplaceListings/suppressedProducts";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const FETCH_SUPPRESSED_PRODUCTS_SUMMARY =
  "FETCH_SUPPRESSED_PRODUCTS_SUMMARY";
export const FETCH_SUPPRESSED_PRODUCTS_SUMMARY_FETCHING =
  "FETCH_SUPPRESSED_PRODUCTS_SUMMARY_FETCHING";

export const FETCH_SUPPRESSED_PRODUCTS = "FETCH_SUPPRESSED_PRODUCTS";
export const FETCH_SUPPRESSED_PRODUCTS_FETCHING =
  "FETCH_SUPPRESSED_PRODUCTS_FETCHING";

export const FETCH_SUPPRESSED_PRODUCT_DETAIL =
  "FETCH_SUPPRESSED_PRODUCT_DETAIL";
export const FETCH_SUPPRESSED_PRODUCT_DETAIL_FETCHING =
  "FETCH_SUPPRESSED_PRODUCT_DETAIL_FETCHING";

const getFieldNameToIssueGroup = (
  marketplace: SuppressedProductsMarketplace
): ((fn: string) => IssueGroup) | null => {
  switch (marketplace) {
    case "amazon":
    case "amazon_filtered":
      return amazonFieldNameToIssueGroup;
    default:
      return null; // unsupported marketplace
  }
};

interface SuppressedProductsSummaryDTO {
  productCount: number;
  suppressedProductCount: number;
  availableQuantity: number;
  lastUpdatedAt: null | string;
  lostValue: number;
  fieldNames: Array<{
    fieldName: string;
    defectCount: number;
  }>;
}

export type RowsWithCount<T> = { rows: T[]; count: number };

export interface IssueDTO {
  issueId: string;
  fieldName: string;
  issueType: string; // this is not the 'issue type' in the UI, that is IssueGroup
  issueDescription: string;
  currentValue: string; // currently coming back blank from api
  suggestedValue: string; // currently blank
}

type SuppressedProductDetailDTO = {
  rows: IssueDTO[];
  count: number;
};

type PreFetch = { fetching: false; fetched: false };
type Fetching<T> = Partial<T> & { fetching: true; fetched: false };
export type Fetched<T> = T & { fetching: false; fetched: true };

type PossibleStates<T> = PreFetch | Fetching<T> | Fetched<T>;

interface IssueGroupWithDefectCount {
  issueGroup: IssueGroup;
  defectCount: number;
}
export type Summary = Omit<SuppressedProductsSummaryDTO, "fieldNames"> & {
  issueGroups: Array<IssueGroupWithDefectCount>;
};

export interface SuppressedProductsState {
  summary: PossibleStates<Summary>;
  products: PossibleStates<RowsWithCount<SuppressedProductListingResponse>>;
  /** re the keys of SuppressedProductListingResponse:
  issueTypes: string[]; // These are not the high level 'issue types' in the UI/design
  productSku: string; // maps to amazon ASIN, walmart Item ID etc
 */
  detail: PossibleStates<SuppressedProductDetailDTO>;
}

const initState: SuppressedProductsState = {
  summary: {
    fetching: false,
    fetched: false,
  },
  products: {
    fetching: false,
    fetched: false,
  },
  detail: {
    fetching: false,
    fetched: false,
  },
};

type FetchSummaryFetchingAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCTS_SUMMARY_FETCHING;
};
type FetchSummaryAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCTS_SUMMARY;
  payload: SuppressedProductsSummaryDTO;
  marketplace: SuppressedProductsMarketplace;
};

type FetchProductsFetchingAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCTS_FETCHING;
};
type FetchProductsAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCTS;
  payload: RowsWithCount<SuppressedProductListingResponse>;
};

type FetchProductDetailFetchingAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCT_DETAIL_FETCHING;
};

type FetchProductDetailAction = {
  type: typeof FETCH_SUPPRESSED_PRODUCT_DETAIL;
  payload: SuppressedProductDetailDTO;
};

type SuppressedProductAction =
  | FetchSummaryFetchingAction
  | FetchSummaryAction
  | FetchProductsAction
  | FetchProductsFetchingAction
  | FetchProductDetailFetchingAction
  | FetchProductDetailAction;

export const suppressedProducts = (
  state: SuppressedProductsState = initState,
  action: SuppressedProductAction
): SuppressedProductsState => {
  switch (action.type) {
    case FETCH_SUPPRESSED_PRODUCT_DETAIL_FETCHING:
      return {
        ...state,
        detail: {
          ...state.detail,
          fetched: false,
          fetching: true,
        },
      };
    case FETCH_SUPPRESSED_PRODUCT_DETAIL:
      return {
        ...state,
        detail: {
          fetched: true,
          fetching: false,
          ...action.payload,
        },
      };
    case FETCH_SUPPRESSED_PRODUCTS_FETCHING:
      return {
        ...state,
        products: {
          ...state.products,
          fetched: false,
          fetching: true,
        },
      };

    case FETCH_SUPPRESSED_PRODUCTS:
      return {
        ...state,
        products: {
          ...action.payload,
          fetched: true,
          fetching: false,
        },
      };

    case FETCH_SUPPRESSED_PRODUCTS_SUMMARY_FETCHING:
      return {
        ...state,
        summary: {
          ...state.summary,
          fetched: false,
          fetching: true,
        },
      };
    case FETCH_SUPPRESSED_PRODUCTS_SUMMARY:
      const fieldNameToIssueGroup = getFieldNameToIssueGroup(
        action.marketplace
      );

      // skip further processing for unsupported marketplaces (e.g., amazon_vendor)
      if (!fieldNameToIssueGroup) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        summary: {
          ...action.payload,
          fetching: false,
          fetched: true,
          issueGroups: action.payload.fieldNames.reduce((acc, cur) => {
            const issueGroup = fieldNameToIssueGroup(cur.fieldName);
            const existing = acc.find((i) => i.issueGroup === issueGroup);
            let issueGroupWithDefectCount: IssueGroupWithDefectCount;
            if (existing) {
              issueGroupWithDefectCount = existing;
              issueGroupWithDefectCount.defectCount += cur.defectCount;
            } else {
              issueGroupWithDefectCount = {
                issueGroup,
                defectCount: cur.defectCount,
              };
              acc.push(issueGroupWithDefectCount);
            }
            return acc;
          }, [] as Array<IssueGroupWithDefectCount>),
        },
      };
    default:
      return state;
  }
};

interface FetchSuppressedDetails {
  suppressedProductId: string;
  mid: string;
}
export const fetchSuppressedProductDetail =
  ({ suppressedProductId, mid }: FetchSuppressedDetails) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SUPPRESSED_PRODUCT_DETAIL_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/suppressedProducts/listingIssue`,
        {
          mid,
          suppressedProductId,
        }
      );
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_SUPPRESSED_PRODUCT_DETAIL,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

interface FetchSummary {
  marketplace: SuppressedProductsMarketplace;
  mid: string;
}
export const fetchSuppressedProductsSummary =
  ({ marketplace, mid }: FetchSummary) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SUPPRESSED_PRODUCTS_SUMMARY_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/suppressedProducts/summary`,
        {
          mid,
        }
      );
      if (isHttpResponseValid(status) && data) {
        const dto = data as SuppressedProductsSummaryDTO;
        const action: FetchSummaryAction = {
          type: FETCH_SUPPRESSED_PRODUCTS_SUMMARY,
          payload: {
            ...dto,
            lastUpdatedAt: dto.lastUpdatedAt,
          },
          marketplace,
        };
        return dispatch(action);
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export interface FetchSuppressedProductsArgs {
  mid: string;
  pageIndex: number;
  pageSize: number;
  sortKey: string;
  sortOrder: "desc" | "asc";
}
export const fetchSuppressedProducts =
  ({
    mid,
    pageIndex,
    pageSize,
    sortKey,
    sortOrder,
  }: FetchSuppressedProductsArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SUPPRESSED_PRODUCTS_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/suppressedProducts/listing`,
        {
          mid,
          sortKey,
          sortOrder,
          pageIndex,
          pageSize,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_SUPPRESSED_PRODUCTS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };
