import { Route, Switch } from "react-router-dom";

import AuthCallbackPage from "../modules/login/authCallbackPage";
import { ClientReport } from "../pages/reports/clientReport";
import ConditionsPage from "../pages/staticContent/conditionsPage";
import { ConnectionRoutes } from "~/routes/connectionRoutes";
import Dashboard from "../pages/multiChannel/overview";
import DemoPage from "../pages/demoPage";
import ErrorBoundary from "../components/errorBoundary";
import ErrorPage from "../pages/errorPage";
import FrontPage from "../pages/auth/frontPage";
import MaintenanceBoundary from "../components/maintenanceBoundary";
import MaintenancePage from "../pages/maintenancePage";
import MarketingOverviewExpanded from "../modules/widgets/overview/marketingCampaignExpanded";
import MarketplaceOverviewExpanded from "../modules/widgets/overview/marketplaceOverviewExpanded";
import MyStoresRoutes from "@routes/myStoresRoutes";
import NewUserSubscriptionPage from "../pages/subscription/newUserSubscriptionPage";
import NotificationRoutes from "./notificationRoutes";
import OverdueOrdersList from "../modules/widgets/overview/overdueOrdersList";
import PartnerOmnivoreRectangularWidgetPage from "../pages/partner/omnivore/rectangularWidget";
import PartnerOmnivoreSquareWidgetPage from "../pages/partner/omnivore/squareWidget";
import PolicyPage from "../pages/staticContent/policyPage";
import PrivateRoute from "@routes/privateRoute";
import ProfitAndLossStatement from "~/pages/multiChannel/profitAndLossStatement";
import ProfitSummaryExpanded from "../modules/widgets/profitability/profitabilitySummaryExpanded";
import React from "react";
import RegistrationPage from "@pages/auth/registrationPage";
import ReportRoutes from "./reportRoutes";
import ResetPwdPage from "../pages/auth/resetPwdPage";
import SettingPage from "../pages/account/settingPage";
import SigninPage from "@pages/auth/signinPage";
import { Store } from "redux";
import { StoreState } from "~/typedef/store";
import SubscriptionCreatePage from "../pages/subscription/subscriptionCreatePage";
import SubscriptionPricingPage from "../pages/subscription/plans";
import TokenLoginPage from "../pages/tokenLoginPage";
import WeChatPage from "../pages/auth/wechatPage";
import { useAgencySpecificConfig } from "~/hooks/useAgencySpecificConfig";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const renderRoutes = (store: Store) => {
  const user = useTypedSelector((state: StoreState) => state.user);

  const { hideSignUp, signupPath } = useAgencySpecificConfig();

  return (
    <Switch>
      <MaintenanceBoundary>
        <Switch>
          <Route exact path="/" component={FrontPage} />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/maintenance" component={MaintenancePage} />
          <Route exact path="/demo" component={DemoPage} />
          {hideSignUp ? (
            <Route exact path={signupPath} component={RegistrationPage} />
          ) : (
            <Route exact path="/signup" component={RegistrationPage} />
          )}
          <Route exact path="/signin" component={SigninPage} />
          {!hideSignUp && (
            <Route exact path="/register" component={RegistrationPage} />
          )}
          <Route exact path="/policy" component={PolicyPage} />
          <Route exact path="/conditions" component={ConditionsPage} />
          <Route exact path="/resetpwd/:token" component={ResetPwdPage} />
          <Route
            exact
            path="/callback/:authProvider"
            component={AuthCallbackPage}
          />
          <Route exact path="/wechat" component={WeChatPage} />
          <Route exact path="/tokenLogin" component={TokenLoginPage} />
          <Route
            exact
            path="/partner/omnivore/rectangularWidget"
            component={PartnerOmnivoreRectangularWidgetPage}
          />
          <Route
            exact
            path="/partner/omnivore/squareWidget"
            component={PartnerOmnivoreSquareWidgetPage}
          />
          <Route exact path="/report" component={ClientReport} />
          <ErrorBoundary>
            <PrivateRoute
              fullNav={true}
              path="/notifications"
              component={NotificationRoutes}
            />
            <PrivateRoute
              fullNav={true}
              path="/reports"
              component={ReportRoutes}
            />
            <PrivateRoute
              fullNav={true}
              path="/mystores"
              component={MyStoresRoutes}
            />
            {/** For first connection (no nav) */}
            <PrivateRoute
              path="/connect"
              fullNav={false}
              component={ConnectionRoutes}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview"
              component={Dashboard}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview/orderStatus"
              component={OverdueOrdersList}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview/marketplaceOverview"
              component={MarketplaceOverviewExpanded}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview/marketingOverview"
              component={MarketingOverviewExpanded}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview/profitAndLoss"
              component={ProfitAndLossStatement}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/overview/profitOverview"
              component={ProfitSummaryExpanded}
            />
            {/** For inside app */}
            <PrivateRoute
              fullNav={true}
              path="/connectStore"
              showFullButtons={true}
              component={ConnectionRoutes}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/setting"
              component={SettingPage}
            />
            <PrivateRoute
              fullNav={true}
              exact
              path="/subscription/plans"
              component={SubscriptionPricingPage}
            />
            <PrivateRoute
              exact
              path="/subscription/newUser"
              fullNav={false}
              component={NewUserSubscriptionPage}
            />
            <PrivateRoute
              exact
              path="/subscription/create"
              fullNav={false}
              component={SubscriptionCreatePage}
            />
          </ErrorBoundary>
        </Switch>
      </MaintenanceBoundary>
    </Switch>
  );
};
