import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import { CSVLink } from "react-csv";
import CategoryValue from "./categoryValue";
import { DateRange } from "~/typedef/date";
import DownloadCsvIcon from "~/icons/downloadCsvIcon";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { intFormatterRounded } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const HeadingWrapper = styled(Grid)`
  width: 100%;
  border-bottom: 2px ${({ theme }) => theme.palette.border.dark} solid;
  display: flex;
  padding: 0.5rem 0rem;
`;

const SmallIcon = styled(DownloadCsvIcon)`
  height: 18px;
  width: 18px;
  margin: -5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

function arrayOfObjectsToArrayOfArrays(
  objectArray: Record<string, any>[],
  description: string
) {
  // Hacky: csvData is an array of objects with keys matching the column headers
  // Convert it to an array of arrays where each inner array is just each row as text entries
  // in order to be able to prepend an arbitrary title/description row that doesn't match the actual format
  // (CSVLink from react-csv accepts both types of array)
  const descriptionRow = [description];

  const headers = new Set<string>();
  for (const obj of objectArray) {
    for (const key of Object.keys(obj)) {
      headers.add(key);
    }
  }

  // headers.values() returns members in insertion order
  const csvDataArrayOfArrays = [descriptionRow, Array.from(headers.values())];

  for (const obj of objectArray) {
    const newRow = [];
    for (const header of headers) {
      newRow.push(obj[header]);
    }
    csvDataArrayOfArrays.push(newRow);
  }

  return csvDataArrayOfArrays;
}

interface MetaColumnHeadingsProps {
  currentRange: DateRange;
  csvData: Record<string, any>[];
  report?: boolean;
  isProductReport?: boolean;
  countryCode: string;
  shopName: string;
  marketplaceName: string;
  sellerSku: string;
  currentCurrency: string;
}

const MetaColumnHeadings = memo<MetaColumnHeadingsProps>(
  function MetaColumnHeadings({
    currentRange,
    csvData,
    report,
    isProductReport,
    countryCode,
    shopName,
    marketplaceName,
    sellerSku,
    currentCurrency,
  }) {
    const { t } = useTranslation();

    const reportType = isProductReport ? "product" : "store";
    const sku = sellerSku || "";
    const skuDesc = isProductReport ? `SKU: ${sku} ` : "";
    const skuFilename = isProductReport ? `${sku}-` : "";

    const fromDateUnix = Number(currentRange.fromDate);
    const toDateUnix = Number(currentRange.toDate);
    const fromDateStr = isNaN(fromDateUnix)
      ? ""
      : moment.unix(fromDateUnix).format("YYYY_MM_DD");
    const toDateStr = isNaN(toDateUnix)
      ? ""
      : moment.unix(toDateUnix).format("YYYY_MM_DD");

    const description = `${skuDesc}${reportType} report | ${shopName} - ${marketplaceName} ${countryCode} - ${currentCurrency} | ${fromDateStr} - ${toDateStr}`;

    const csvDataArrayOfArrays = arrayOfObjectsToArrayOfArrays(
      csvData,
      description
    );

    return (
      <HeadingWrapper container item xs={12}>
        <Grid item xs={10}>
          <Box
            width="100%"
            p={2}
            display="flex"
            flexDirection="column"
            className="profit-headings"
          >
            <Bold variant="h3">
              {report
                ? t("profitability.storeProfitReport")
                : t("profitability.currentPeriodLabel")}
            </Bold>
          </Box>
        </Grid>
        <Grid item xs={2} className="no-print">
          <Box
            pr={3}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <CSVLink
              data={csvDataArrayOfArrays}
              filename={`${reportType}-profit-report-${skuFilename}${shopName}-${marketplaceName}-${countryCode}-(${currentCurrency})-${fromDateStr}-${toDateStr}.csv`}
            >
              <SmallIcon />
            </CSVLink>
          </Box>
        </Grid>
      </HeadingWrapper>
    );
  }
);

interface MetaTableProps {
  data: {
    meta: {
      sellerSku: string;
      orderCount: number;
      unitCount: number;
      refundedUnitCount: number;
    };
    events: {
      label: string;
      value: number;
    }[];
  };
  currentRange: DateRange;
  csvData: Record<string, any>[];
  report?: boolean;
  isProductReport?: boolean;
  countryCode: string;
  shopName: string;
  marketplaceName: string;
  currentCurrency: string;
}

const MetaTable = memo<MetaTableProps>(function MetaTable({
  data,
  currentRange,
  csvData,
  report,
  isProductReport,
  countryCode,
  shopName,
  marketplaceName,
  currentCurrency,
}) {
  const { t } = useTranslation();

  const orderCount = data.meta.orderCount;

  const unitCount = data.meta.unitCount;

  const refundedUnitCount = data.meta.refundedUnitCount;

  const sellerSku = isProductReport ? data.meta.sellerSku : "";

  return (
    <>
      <MetaColumnHeadings
        currentRange={currentRange}
        csvData={csvData}
        report={report}
        isProductReport={isProductReport}
        countryCode={countryCode}
        shopName={shopName}
        marketplaceName={marketplaceName}
        sellerSku={sellerSku}
        currentCurrency={currentCurrency}
      />
      <Grid item xs={5}>
        <Box p={1} pl={2} display="flex" alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {t("profitability.ordersLabel")}
          </Typography>
          {!report && (
            <Tooltip title={t("profitability.ordersTooltip")}>
              <InlineIconButton />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <CategoryValue
        level={1}
        colSpan={2}
        value={orderCount > 0 ? intFormatterRounded.format(orderCount) : "-"}
      />
      <Grid item xs={5} />
      <Grid item xs={5}>
        <Box p={1} pl={2} display="flex" alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {t("profitability.unitsLabel")}
          </Typography>
          {!report && (
            <Tooltip title={t("profitability.unitsTooltip")}>
              <InlineIconButton />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <CategoryValue
        level={1}
        colSpan={2}
        value={unitCount > 0 ? intFormatterRounded.format(unitCount) : "-"}
      />
      <Grid item xs={5} />
      <Grid item xs={5}>
        <Box p={1} pl={2} display="flex" alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {t("profitability.unitsRefundedLabel")}
          </Typography>
          {!report && (
            <Tooltip title={t("profitability.unitsRefundedTooltip")}>
              <InlineIconButton />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <CategoryValue
        level={1}
        colSpan={2}
        value={
          refundedUnitCount > 0
            ? intFormatterRounded.format(refundedUnitCount)
            : "-"
        }
      />
      <Grid item xs={5} />
    </>
  );
});

export default MetaTable;
