import {
  FETCH_OVERDUE_ORDER_LIST,
  FETCH_OVERDUE_ORDER_LIST_FETCHING,
} from "../mystore/mystore.redux";
import {
  FETCH_OVERDUE_ORDER_LIST_OVERVIEW,
  FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR,
  FETCH_OVERDUE_ORDER_LIST_OVERVIEW_FETCHING,
  FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW,
  FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR,
  FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_FETCHING,
} from "./overview.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_FETCHING,
  });
}

async function setFetchingList(dispatch: Dispatch, mid?: string) {
  await dispatch({
    type: mid
      ? FETCH_OVERDUE_ORDER_LIST_FETCHING
      : FETCH_OVERDUE_ORDER_LIST_OVERVIEW_FETCHING,
  });
}

interface OverdueSummaryParams {
  user: User;
  pageIndex: number;
  pageSize: number;
  filter: Filter;
  filteredStores: any[];
  timezone: string;
}
export const fetchOverdueOrderSummary = (
  params: OverdueSummaryParams,
  oldParams?: OverdueSummaryParams
) => async (dispatch: Dispatch) => {
  const { pageIndex, pageSize, filter, timezone } = params;
  if (!shouldUseCache(params, oldParams)) {
    await setFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/overdueOrderSummary`, {
        timezone,
        pageIndex,
        pageSize,
        filter,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW,
          payload: { ...data, params },
        });
      } else {
        await dispatchError(
          dispatch,
          FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR
        );
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    await dispatchError(dispatch, FETCH_OVERDUE_ORDER_SUMMARY_OVERVIEW_ERROR);
    return setError(dispatch);
  }
};

interface OverdueListParams {
  user: User;
  mid?: string;
  pageIndex: number;
  pageSize: number;
  filter: Filter;
  timezone: string;
}
export const fetchOverdueOrdersList = ({
  user,
  mid,
  pageIndex,
  pageSize,
  filter,
  timezone,
}: OverdueListParams) => async (dispatch: Dispatch) => {
  await setFetchingList(dispatch, mid);
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/overdueOrders`, {
      mid,
      timezone,
      pageIndex,
      pageSize,
      filter,
    })
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      dispatchError(dispatch, FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR);
      return setError(dispatch, msg || statusText, get(err, "status"));
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: mid
          ? FETCH_OVERDUE_ORDER_LIST
          : FETCH_OVERDUE_ORDER_LIST_OVERVIEW,
        payload: data,
      });
    } else {
      await dispatchError(dispatch, FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR);
      return setError(dispatch, res.data.errMsg, res.status);
    }
  }
  await dispatchError(dispatch, FETCH_OVERDUE_ORDER_LIST_OVERVIEW_ERROR);
  return setError(dispatch);
};
