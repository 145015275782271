import { Box, Typography, useTheme } from "@material-ui/core";

import React from "react";
import styled from "styled-components";

interface LegendIndicatorProps {
  fill: string;
}

export const LegendIndicator = styled.div`
  height: 3px;
  width: 15px;
  margin-right: 10px;
  background-color: ${({ fill }: LegendIndicatorProps) => fill};
`;

const LegendWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

interface MultiLineChartLegendProps {
  dataKeys: string[];
}

const MultiLineChartLegend = ({ dataKeys }: MultiLineChartLegendProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" width="100%" pt={2} pl="60px">
      {dataKeys.map((dataKey, i) => (
        <LegendWrapper key={`${dataKey}`}>
          <LegendIndicator
            fill={Object.values(theme.palette.chart)[i] as string}
          />
          <Typography variant="body2" color="textSecondary">
            {dataKey}
          </Typography>
        </LegendWrapper>
      ))}
    </Box>
  );
};

export default MultiLineChartLegend;
