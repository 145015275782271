import { Grid, Link, Typography } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const CellLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
  `}
`;

const SellerLinkCell = memo(({ cell }) => {
  const { handleListingSelect, justify } = cell.column.getProps();

  return (
    <Grid container justifyContent={justify || "flex-end"}>
      {cell.value > 0 ? (
        <CellLink
          onClick={() => handleListingSelect(cell.row.original)}
          variant="body2"
        >
          {cell.value}
        </CellLink>
      ) : (
        <Typography variant="body2">{cell.value}</Typography>
      )}
    </Grid>
  );
});

SellerLinkCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    column: PropTypes.any,
    value: PropTypes.number,
  }).isRequired,
};

export default SellerLinkCell;
