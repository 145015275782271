import AmazonAdvertisingStandaloneConnectPage from "~/pages/marketplaceConnectors/amazonAdvertisingSelectCountry";
import CriteoAdvertisingConnectPage from "~/pages/marketplaceConnectors/criteoAdvertisingConnectPage";
import GenericStandaloneAdvertisingConnectPage from "~/pages/marketplaceConnectors/genericStandaloneAdvertisingConnectPage";
import GoogleAdvertisingConnectPage from "~/pages/marketplaceConnectors/googleAdvertisingConnectPage";
import InstacartAdvertisingConnectPage from "~/pages/marketplaceConnectors/instacartAdvertisingConnectPage";
import React from "react";
import TikTokAdvertisingConnectPage from "~/pages/marketplaceConnectors/tikTokAdvertisingConnectPage";

type StandaloneAdvertisingConnectPageProps = {
  marketplace: string;
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
  selectCountry: (countryCode: string) => void;
};

const StandaloneAdvertisingConnectPage = ({
  marketplace,
  onSuccess,
  setIsSubmitting,
  selectCountry,
}: StandaloneAdvertisingConnectPageProps) => {
  switch (marketplace) {
    case "amazon":
    case "amazon_vendor":
    case "dsp":
      return (
        <AmazonAdvertisingStandaloneConnectPage
          marketplace={marketplace}
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
          selectCountry={selectCountry}
        />
      );
    case "walmart":
      return (
        <GenericStandaloneAdvertisingConnectPage
          marketplaceCountry={"USA"}
          marketplaceType={"walmart"}
          marketplaceSubtype={"advertising"}
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
        />
      );
    case "criteo":
      return (
        <CriteoAdvertisingConnectPage
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
        />
      );
    case "tiktok":
      return (
        <TikTokAdvertisingConnectPage
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
        />
      );
    case "google":
      return (
        <GoogleAdvertisingConnectPage
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
        />
      );

    case "instacart":
      return (
        <InstacartAdvertisingConnectPage
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
        />
      );
    default:
      return <></>;
  }
};

export default StandaloneAdvertisingConnectPage;
