import {
  formatCurrencyRounded,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import { Grid } from "@material-ui/core";
import { LabelledData } from "~/components/labelledData/labelledData";
import React from "react";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { percentageFormatterRounded } from "~/modules/profitLossTable/categoryUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export interface SuppressedProductsStatusProps {
  totalProducts: number;
  totalProductsPercent: number;
  salesValue: { amount: number; currency: string };
  inventory: number;
}

export const SuppressedProductsStatus: React.FC<SuppressedProductsStatusProps> =
  ({
    totalProducts,
    totalProductsPercent,
    salesValue: { amount, currency },
    inventory,
  }) => {
    const { t } = useTranslation();
    const currencyRates = useTypedSelector<any>((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );
    const currentCurrency = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency")
    );
    const salesValue = formatCurrencyRounded(
      amount,
      currencyRates,
      currency,
      currentCurrency as string
    );
    return (
      <Grid container>
        <Grid item xs={12} sm={4} md={6}>
          <LabelledData
            label={t(
              "myStoresWidget-suppressedProducts-issueTypes-totalProductsTitle"
            )}
            tooltip={t(
              "myStoresWidget-suppressedProducts-issueTypes-totalProductsTooltip"
            )}
            data={intFormatterRounded.format(totalProducts)}
            secondaryData={`(${percentageFormatterRounded.format(
              totalProductsPercent
            )}%)`}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <LabelledData
            label={t(
              "myStoresWidget-suppressedProducts-issueTypes-salesValueTitle"
            )}
            tooltip={t(
              "myStoresWidget-suppressedProducts-issueTypes-salesValueTooltip"
            )}
            data={salesValue}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <LabelledData
            label={t(
              "myStoresWidget-suppressedProducts-issueTypes-inventoryTitle"
            )}
            tooltip={t(
              "myStoresWidget-suppressedProducts-issueTypes-inventoryTooltip"
            )}
            data={intFormatterRounded.format(inventory)}
          />
        </Grid>
      </Grid>
    );
  };
