import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { memo } from "react";

import ComboChartTooltip from "./ComboChartTooltip";
import { INTERVAL } from "~/store/utils/dateTimeUtils";
import { useLayoutProps } from "../chartUtils/chartComponents";

interface ComboChartProps {
  chartData: any[];
  barChartProps: any;
  lineChartProps: any;
  lineChart2Props?: any;
  xAxisProps: any;
  yAxisLeftProps: any;
  yAxisRightProps: any;
  legendProps?: any;
  legendContent: () => JSX.Element;
  tooltipProps: any;
  currentPeriod: string;
  xKey?: string;
  xKey2?: string;
  barKey?: string;
  lineKey?: string;
  line2Key?: string;
  report?: boolean;
  timezone: string;
  interval?: INTERVAL;
}

const ComboChart = memo<ComboChartProps>(function ComboChart({
  chartData = [],
  barChartProps = {},
  lineChartProps = {},
  lineChart2Props = {},
  xAxisProps = {},
  yAxisLeftProps = {},
  yAxisRightProps = {},
  legendContent,
  legendProps = {
    verticalAlign: "top",
    align: "left",
    wrapperStyle: { top: -16, fontSize: "12px" },
  },
  tooltipProps = {},
  currentPeriod,
  barKey,
  lineKey,
  line2Key,
  report,
  timezone,
  interval,
  xKey,
  xKey2,
}) {
  const { height, margin } = useLayoutProps("comboChart", report);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={chartData} margin={margin}>
        <XAxis {...xAxisProps} />
        <YAxis yAxisId="left" {...yAxisLeftProps} />
        <YAxis yAxisId="right" orientation="right" {...yAxisRightProps} />
        <ReferenceLine yAxisId="left" y={0} strokeWidth={2} />
        <Tooltip
          content={
            <ComboChartTooltip
              currentPeriod={currentPeriod}
              barTooltipProps={tooltipProps.barTooltipProps}
              lineTooltipProps={tooltipProps.lineTooltipProps}
              line2TooltipProps={tooltipProps.line2TooltipProps}
              lineKey={lineKey}
              line2Key={line2Key}
              barKey={barKey}
              timezone={timezone}
              interval={interval}
              headerKey={xKey}
              headerKey2={xKey2}
            />
          }
        />
        <Legend content={legendContent} {...legendProps} />
        <Bar yAxisId="left" {...barChartProps} />
        <Line yAxisId="right" dot={false} {...lineChartProps} />
        {line2Key && <Line yAxisId="right" dot={false} {...lineChart2Props} />}
      </ComposedChart>
    </ResponsiveContainer>
  );
});

export default ComboChart;
