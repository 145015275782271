import { Grid, Link, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "~/components/typography/bold";
import PropTypes from "prop-types";
import TrophyIcon from "~/img/trophy.png";
import styled from "styled-components";

const SmallIcon = styled.img`
  padding: 1px 0.5rem 1px 0.25rem;
  height: 14px;
  float: left;
`;

const MyOffer = styled(Bold)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const CellLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
  `}
`;

const SellerCell = memo(({ cell }) => {
  const { title, isWinner, linkUrl } = cell.value;
  const { isAmazon, isMyOffer } = cell.row.original;

  return (
    <Grid container alignItems="flex-start">
      {isWinner && (
        <Tooltip title="Buy Box winner">
          <SmallIcon src={TrophyIcon} />
        </Tooltip>
      )}
      {isAmazon ? (
        <Bold variant="body2">Amazon</Bold>
      ) : isMyOffer ? (
        <MyOffer variant="body2" noWrap>
          {title}
        </MyOffer>
      ) : (
        <CellLink href={linkUrl} target="_blank">
          <Typography variant="body2" noWrap>
            {title}
          </Typography>
        </CellLink>
      )}
    </Grid>
  );
});

SellerCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    value: PropTypes.shape({
      sellerId: PropTypes.string,
      title: PropTypes.string,
      isWinner: PropTypes.bool,
      linkUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default SellerCell;
