import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import React, { FormEvent, useState } from "react";
import { Status, StatusProps } from "~/typedef/status";
import { loginWithCredentials, resetPwdRequest } from "@store/user.redux";

import CombinedLink from "~/components/links/link";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "@components/buttons/raisedButton";
import VerificationInput from "react-verification-input";
import { get } from "lodash";
import { isEmailValid } from "@merchantspring/common";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const LoginFormContainer = styled.form`
  width: 100%;
  padding-top: 2rem;

  .container {
    width: 285px;
    margin: 20px 0;
  }

  .character {
    border-radius: 5px;
  }
`;

const LoginFormField = styled(TextField)`
  padding-bottom: 1rem;
`;

export const Icon = styled(KeyboardArrowRight)`
  fill: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const StatusText = styled(Typography)<StatusProps>`
  ${({ theme, $status }) => `
    color: ${get(theme, `palette.${$status}.main`)};
  `}
`;

interface LoginFormProps {
  onSuccess: () => void;
  onError: (err: any) => void;
  hideSignup?: boolean;
}

const defaultState = {
  isLoading: false,
  isMfaRequired: false,
  email: "",
  password: "",
  authCode: "",
  showPassword: false,
  status: "info" as Status,
  message: "",
};

const LoginForm = ({
  onSuccess,
  onError,
  hideSignup = false,
}: LoginFormProps) => {
  const [state, setState] = useState(defaultState);
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const dispatchLogin = ({
    email,
    password,
    authCode,
  }: {
    email: string;
    password: string;
    authCode?: string;
  }) => dispatch(loginWithCredentials({ email, password, authCode }));

  const dispatchResetPwdRequest = (email: string) =>
    dispatch(resetPwdRequest(email));

  const handleResetPwd = async () => {
    setState({ ...state, message: "" });
    if (!state.email) {
      setState({
        ...state,
        status: "error",
        message: t("signin.emailIsEmptyError"),
      });
      return;
    }
    if (!isEmailValid(state.email)) {
      setState({
        ...state,
        status: "error",
        message: t("signin.invalidEmailError"),
      });
      return;
    }
    try {
      setState({ ...state, isLoading: true, message: "" });
      await dispatchResetPwdRequest(state.email.toLowerCase());
      setState({
        ...state,
        isLoading: false,
        status: "info",
        message: t("signin.resetEmailSentMessage"),
      });
    } catch {
      setState({
        ...state,
        isLoading: false,
        status: "error",
        message: t("signin.serverUnavailableError"),
      });
    }
  };

  const handleSubmitLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, isLoading: true, message: "" });

    try {
      await dispatchLogin({
        email: state.email.toLowerCase(),
        password: state.password,
      });
      onSuccess();
    } catch (err) {
      if (err === "MFA_REQUIRED") {
        setState({
          ...state,
          isLoading: false,
          isMfaRequired: true,
          status: "info",
          message: "",
        });
      } else {
        setState({
          ...state,
          isLoading: false,
          status: "error",
          message: err as string,
        });
      }
    }
  };

  const handleSubmitAuthCode = async (authCode: string) => {
    setState({ ...state, isLoading: true, message: "" });

    try {
      await dispatchLogin({
        email: state.email.toLowerCase(),
        password: state.password,
        authCode,
      });
      onSuccess();
    } catch (err) {
      if (err === "MFA_AUTH_CODE_ERROR") {
        setState({
          ...state,
          isLoading: false,
          status: "error",
          message: t("signin.invalidVerificationCodeError"),
        });
      } else {
        setState({
          ...state,
          isLoading: false,
          isMfaRequired: false,
          status: "error",
          message: err as string,
        });
      }
    }
  };

  if (state.isLoading) {
    return (
      <LoginFormContainer>
        <LoadingIndicator />
      </LoginFormContainer>
    );
  }

  if (state.isMfaRequired) {
    return (
      <LoginFormContainer>
        <Box>
          <Typography>{t("signin.verificationCodeSent")}</Typography>
        </Box>
        <VerificationInput
          autoFocus={true}
          onComplete={handleSubmitAuthCode}
          classNames={{
            container: "container",
            character: "character",
          }}
        />
        {state.message && (
          <Box>
            <StatusText $status={state.status}>{state.message}</StatusText>
          </Box>
        )}
      </LoginFormContainer>
    );
  }

  return (
    <LoginFormContainer
      id="signinForm"
      method="POST"
      onSubmit={handleSubmitLogin}
    >
      <LoginFormField
        fullWidth
        label={t("signin.emailLabel")}
        value={state.email}
        onChange={(e) =>
          setState({
            ...state,
            email: e.target.value.toLowerCase(),
          })
        }
      />
      <LoginFormField
        fullWidth
        type={state.showPassword ? "text" : "password"}
        label={t("signin.passwordLabel")}
        onChange={(e) =>
          setState({
            ...state,
            password: e.target.value,
          })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment variant="filled" position="end">
              <IconButton
                aria-label={t("signin.togglePasswordVisibilityTooltip")}
                onClick={() => {
                  setState({
                    ...state,
                    showPassword: !state.showPassword,
                  });
                }}
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Link
          component="button"
          type="button"
          color="primary"
          onClick={handleResetPwd}
        >
          {t("signin.resetPasswordLink")}
        </Link>
      </Box>
      <Box pt={2} pb={2}>
        <RaisedButton type="submit" color="primary">
          {t("signin.signinButton")}
          <Icon fontSize="small" />
        </RaisedButton>
      </Box>
      {state.message && (
        <Box>
          <StatusText $status={state.status}>{state.message}</StatusText>
        </Box>
      )}
      {!hideSignup && (
        <Box display="flex" flexWrap="wrap" flexDirection="row" mt={1}>
          <Typography>
            {t("signin.noAccountText")}
            {t("generic.space")}
            <CombinedLink
              to={{
                pathname: "/signup",
                search: window.location.search,
              }}
            >
              {t("signin.signupLink")}
            </CombinedLink>
          </Typography>
        </Box>
      )}
    </LoginFormContainer>
  );
};
export default LoginForm;
