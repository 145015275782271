import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo, useEffect, useRef, useState } from "react";

import LargeNumber from "./largeNumber";
import StatusText from "../typography/status";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface TotalOrdersProps {
  current: number;
  prior?: number;
  currentPeriod: string;
  omitGrowth?: boolean;
  comparisonSubtitle?: string;
  currentSubtitle?: string;
}

export const TotalOrders = memo<TotalOrdersProps>(function TotalOrders({
  current,
  prior,
  currentPeriod,
  omitGrowth,
  comparisonSubtitle,
  currentSubtitle,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [totalOrders, setTotalOrders] = useState<string | number>("-");
  const [lastPeriodOrders, setLastPeriodOrders] = useState<string | number>(
    "-"
  );
  const [growth, setGrowth] = useState<string | number>("N/A");
  /** If totalOrders is a large number, our default font size can overflow the container.
    LargeNumber will recalculate the font size dynamically to prevent this from happening */
  const headingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const orders: number | string = current || "-";

    const lastPeriodOrders = prior || "-";

    const growthValue = getPercentageDifference(orders, lastPeriodOrders);

    setTotalOrders(orders);
    setLastPeriodOrders(lastPeriodOrders);
    setGrowth(growthValue);
  }, [currentPeriod, current, prior]);

  const status = isNaN(growth as number)
    ? "disabled"
    : (growth as number) < 0
    ? "error"
    : "success";
  return (
    <Grid
      container
      alignItems="center"
      justifyContent={smDown ? "center" : "flex-start"}
      spacing={2}
      id="total-sales-wrapper"
    >
      <Grid item xs={6} md={12}>
        <Typography variant="h3">
          {t("dashboardWidget.salesPerformance.totalOrders")}
        </Typography>
      </Grid>
      <Grid item xs={6} md={12} ref={headingContainerRef}>
        <LargeNumber
          variant="h1"
          headingContainerRef={headingContainerRef}
          value={numberWithCommas(totalOrders)}
        />
        {currentSubtitle && (
          <Typography variant="body1" color="textSecondary">
            {currentSubtitle}
          </Typography>
        )}
      </Grid>
      {!omitGrowth && (
        <>
          <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
            <Box>
              <Typography variant="h3" color="textSecondary">
                {t("dashboardWidget.salesPerformance.priorPeriod")}
              </Typography>
              {comparisonSubtitle && (
                <Typography variant="body1" color="textSecondary">
                  {comparisonSubtitle}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid container item wrap="wrap" alignItems="center" xs={6} md={12}>
            <LargeNumber
              variant="h2"
              headingContainerRef={headingContainerRef}
              value={numberWithCommas(lastPeriodOrders)}
            />
            <Tooltip title="Percentage change compared to the equivalent prior period">
              <StatusText variant="h5" status={status}>
                {status === "success" && (
                  <UpArrow fontSize="inherit" status="success" />
                )}
                {status === "error" && (
                  <DownArrow fontSize="inherit" status="error" />
                )}
                {isNaN(growth as number) ? growth : Math.abs(growth as number)}
                {!isNaN(growth as number) && "%"}
              </StatusText>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default TotalOrders;
