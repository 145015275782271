import {
  CHART_TYPE,
  ChartOption,
} from "~/modules/widgets/customGroups/customGroupsChart";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { CustomGroup } from "@typedef/customGroups";
import CustomGroups from "~/modules/widgets/customGroups/customGroups";
import CustomGroupsSelect from "~/modules/widgets/customGroups/customGroupsSelect";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { Store } from "@typedef/store";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import moment from "moment-timezone";
import { useCustomGroupsQuery } from "~/store/customGroups/reducer.redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SalesByGroup = memo(function SalesByGroup() {
  const user = useTypedSelector((state) => state.user);
  const marketplace = useMarketplace();

  const { t } = useTranslation();

  /** Selectors */
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.Sales,
        label: t("customGroupsChart.salesOption"),
      },
      {
        value: CHART_TYPE.AvgPrice,
        label: t("customGroupsChart.avgPriceOption"),
      },
      {
        value: CHART_TYPE.UnitsSold,
        label: t("customGroupsChart.unitsSoldOption"),
      },
    ],
    []
  );

  const { customGroups, customGroupsFetching } = useCustomGroupsQuery(
    {
      mid: store?.merchantId || "",
      userId: user?._id,
    },
    {
      skip: !store?.merchantId || !user?._id,
      selectFromResult: ({ data, isFetching }) => ({
        customGroups: data?.groups || [],
        customGroupsFetching: isFetching,
      }),
    }
  );

  /** Local State */
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [selectedGroups, setSelectedGroups] = useState<CustomGroup[]>([]);

  useEffect(() => {
    if (!customGroupsFetching && customGroups.length > 0) {
      setSelectedGroups(customGroups);
    }
  }, [customGroups]);

  /** Utilities */
  const switchType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const actions = useMemo(() => {
    return (
      <>
        <Box pl={2} width={200}>
          <CustomGroupsSelect
            setSelected={setSelectedGroups}
            selected={selectedGroups}
            store={filteredStores?.stores?.find(
              (filteredStore: Store) =>
                filteredStore.merchantId === store?.merchantId
            )}
          />
        </Box>
        <Box pl={2}>
          <TableFilter
            {...{
              width: "105px",
              options: CHART_OPTIONS,
              currentValue: chartType.label,
              handleChange: switchType,
            }}
          />
        </Box>
      </>
    );
  }, [selectedGroups, customGroups, chartType.label]);

  return (
    <PageBlock>
      {store ? (
        <CustomGroups
          userInfo={user}
          actions={actions}
          selectedGroups={selectedGroups}
          chartType={chartType}
          mid={store.merchantId}
          currentPeriod={currentPeriod}
          currentRange={currentRange}
          countryCode={store.marketplaceCountry}
          marketplace={marketplace}
          shopName={store.storeName}
          currentCurrency={currentCurrency}
          timezone={selectedTimezone}
          includeTax={includeTax}
        />
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default SalesByGroup;
