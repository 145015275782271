import PropTypes from "prop-types";
import React from "react";
import { ebaySiteByCountryCode } from "@merchantspring/common";
import { getCountryCode } from "~/utils/countryUtils";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";

const StyledLink = withTheme(styled.a`
  &:active, :visited: {
    color: ${({ theme }) => `${theme.palette.link.secondary}`};
  }
  color: ${({ theme }) => `${theme.palette.link.secondary}`};
`);

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DotWrapper = styled.div`
  flex: 1;
  margin-right: 2px;
`;

const Dot = styled.div`
  background-color: ${({ color }) => color};
  height: 16px;
  width: 16px;
  border-radius: 50%;
`;

const TitleWrapper = styled.div`
  flex: 7;
  margin-right: 2px;
  font-size: 12px;
`;

const ImageWrapper = styled.div`
  flex: 2;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  vertical-align: middle;
`;

const DEFAULT_EBAY_ITEM_LINK = "https://www.ebay.com.au/";

const StickyContent = (props) => {
  const { title, image, dotColor, countryCode, itemId, index } = props;

  const itemLink =
    (ebaySiteByCountryCode[countryCode]
      ? ebaySiteByCountryCode[countryCode]
      : DEFAULT_EBAY_ITEM_LINK) + "itm/";

  return (
    <StyledLink href={`${itemLink}${itemId}`} target="_blank">
      <ItemWrapper>
        <DotWrapper id={`item-specifics-indicator-${index}`}>
          <Dot color={dotColor} />
        </DotWrapper>
        <TitleWrapper>{title}</TitleWrapper>
        <ImageWrapper>
          <Image src={image} alt="" />
        </ImageWrapper>
      </ItemWrapper>
    </StyledLink>
  );
};

StickyContent.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  dotColor: PropTypes.oneOf(["red", "gray", "green"]).isRequired,
  countryCode: PropTypes.oneOf(getCountryCode),
  itemId: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default StickyContent;
