import { Connections, PersistentAppSettingState, Store } from "~/typedef/store";
import { useCallback, useEffect, useState } from "react";

import { FieldWithPossiblyUndefined } from "lodash";
import { hasDemoStore } from "~/utils/marketplaceUtils";

export const useStoreConnection = (
  connections: Connections["connectionStatus"],
  currentStore: FieldWithPossiblyUndefined<
    PersistentAppSettingState,
    "setting.data.currentStore"
  >,
  filteredStores: Store[]
) => {
  const determineConnectionInProgress = useCallback(() => {
    if (
      connections &&
      connections.connections &&
      currentStore &&
      currentStore.sourceSystemId
    ) {
      return connections.connections.find(
        (storeConnection) =>
          (storeConnection.mid === currentStore.merchantId &&
            Boolean(currentStore.merchantId)) ||
          (storeConnection.sellerId === currentStore.sourceSystemId &&
            Boolean(currentStore.sourceSystemId))
      );
    }
    return null;
  }, [connections, currentStore]);

  const [connection, setConnection] = useState(determineConnectionInProgress());

  useEffect(
    () => setConnection(determineConnectionInProgress()),
    [determineConnectionInProgress]
  );

  const [firstConnectionIncomplete, setFirstConnectionIncomplete] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (connection) {
      const inProgress = connection.percentage !== 100;
      const hasDemoStores = hasDemoStore(filteredStores);
      setFirstConnectionIncomplete(inProgress && hasDemoStores);
    }
  }, [connection, filteredStores]);

  return {
    firstConnectionIncomplete,
    connection,
    showingDemoData: currentStore?.isDemoMode,
  };
};
