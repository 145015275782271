import { Box, Grid, Typography } from "@material-ui/core";
import { PlanPropTypes, formatBillingPeriod } from "./plan/plan";
import {
  addDiscountOverride,
  fetchBraintreeToken,
  removeDiscountOverride,
} from "../../store/subscriptions.redux";
import { useDispatch, useSelector } from "react-redux";

import AlertCard from "~/components/alert/alertCard";
import CheckoutSummary from "./checkoutSummary";
import { FieldLabel } from "./subscriptionStyledComponents";
import PromoCodeField from "./promoCodeField";
import PropTypes from "prop-types";
import React from "react";
import ReactGA from "react-ga";
import braintree from "braintree-web";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PAYPAL_CLIENT_ID = process.env.PAYPAL_CLIENT_ID;

const StyledAlertCard = styled(AlertCard)`
  padding: 1rem 0;
`;

// Container for all the checkout fields
const FieldContainer = (props) => <Grid container spacing={2} {...props} />;

// A wrapper around individual fields
const FieldWrapper = styled(Grid)`
  ${({ theme }) => `
    padding-top: 24px;
    ${theme.breakpoints.up("md")} {
      padding-right: 50px;
    }
  `}
`;

const PaypalCheckoutForm = ({
  displaySummary,
  summaryProps,
  promoCode,
  setPromoCode,
  error,
  setError,
  handleError,
  submitToken,
  discount,
  discountAmount,
  setDiscountAmount,
  newUser,
  editMode,
}) => {
  const { t } = useTranslation();
  const clientToken = useSelector((state) =>
    get(state, "subscriptions.clientToken")
  );

  const [paypalCheckoutInstance, setPaypalCheckoutInstance] = React.useState();
  const dispatch = useDispatch();

  const trialExpiry = moment()
    .startOf("day")
    .add(summaryProps.plan.trialPeriod, "days");
  const numTrialDays = discount ? discount.numTrialDays : 0;

  const today = moment();
  const promoStart = newUser && !numTrialDays ? trialExpiry : today;
  const billingCycle = numTrialDays
    ? "day"
    : t(formatBillingPeriod(summaryProps.plan, false));
  const discountCycles = numTrialDays ? numTrialDays : 0;
  const firstChargeDate = moment(promoStart)
    .add(discountCycles, billingCycle)
    .format("LL");

  React.useEffect(() => {
    ReactGA.event({
      category: "Subscription",
      action: "Payment Method - Started",
    });
  }, []);

  React.useEffect(() => {
    dispatch(fetchBraintreeToken());
    dispatch(removeDiscountOverride());
  }, [dispatch]);

  React.useEffect(() => {
    const initPaypalCheckoutInstance = async () => {
      if (clientToken) {
        const clientInstance = await braintree.client.create({
          authorization: clientToken,
        });

        const paypalInstance = await braintree.paypalCheckout.create({
          client: clientInstance,
        });

        setPaypalCheckoutInstance(paypalInstance);
      }
    };
    initPaypalCheckoutInstance();
  }, [clientToken]);

  React.useEffect(() => {
    const renderPayPalButtons = async () => {
      if (paypalCheckoutInstance) {
        const paypalSDK = await paypalCheckoutInstance.loadPayPalSDK({
          "client-id": PAYPAL_CLIENT_ID,
          vault: true,
        });

        try {
          await window.paypal
            .Buttons({
              fundingSource: window.paypal.FUNDING.PAYPAL,
              style: {
                height: 55,
                label: "paypal",
              },
              createBillingAgreement: () =>
                paypalSDK.createPayment({
                  flow: "vault",
                }),
              onApprove: (data) =>
                paypalSDK.tokenizePayment(data).then((payload) => {
                  submitToken(payload).catch(handleError);
                }),
            })
            .render("#paypal-button");
        } catch (err) {
          // do nothing
        }
      }
    };
    renderPayPalButtons();
  }, [paypalCheckoutInstance, submitToken, handleError, editMode]);

  const onPromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const onPromoCodeSuccess = (amount, trialDays, numberOfBillingCycles) => {
    setError(null);
    setDiscountAmount(amount);
    dispatch(
      addDiscountOverride({
        amount,
        numTrialDays: trialDays,
        numberOfBillingCycles,
      })
    );
  };

  const onPromoCodeError = (errorMessage) => {
    setError(errorMessage);
  };

  return (
    <Box py={1}>
      {error && (
        <StyledAlertCard
          type={"error"}
          isOpen={true}
          onClose={() => {
            setError(null);
          }}
        >
          {error}
        </StyledAlertCard>
      )}

      <FieldContainer>
        {summaryProps && !editMode && (
          <FieldWrapper item xs={12}>
            <FieldLabel>{t("settings.billing.promoCodeLabel")}</FieldLabel>
            <PromoCodeField
              value={promoCode}
              onChange={onPromoCodeChange}
              plan={summaryProps.plan}
              planId={summaryProps.plan.planId}
              onPromoCodeSuccess={onPromoCodeSuccess}
              onPromoCodeError={onPromoCodeError}
              newUser={newUser}
            />
          </FieldWrapper>
        )}
      </FieldContainer>

      <Box pt={2}>
        <Grid container spacing={1}>
          {displaySummary && summaryProps && (
            <CheckoutSummary
              {...summaryProps}
              discount={discount}
              discountAmount={discountAmount}
              newUser={newUser}
            />
          )}
        </Grid>
      </Box>

      {(newUser || Boolean(numTrialDays)) && (
        <Box pt={2}>
          <Typography variant="body2">
            {t("subscription.create.firstChargeMessage", { firstChargeDate })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

PaypalCheckoutForm.propTypes = {
  newUser: PropTypes.bool,
  editMode: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  submitToken: PropTypes.func.isRequired,
  displaySummary: PropTypes.bool,
  summaryProps: PropTypes.shape({
    plan: PlanPropTypes,
  }),
  promoCode: PropTypes.string,
  setPromoCode: PropTypes.func,
  error: PropTypes.string,
  discountAmount: PropTypes.number,
  setDiscountAmount: PropTypes.func,
  discount: PropTypes.shape({
    amount: PropTypes.number,
    numTrialDays: PropTypes.number,
    numberOfBillingCycles: PropTypes.number,
  }),
};

export default PaypalCheckoutForm;
