import { Box, Grid } from "@material-ui/core";
/* eslint-disable no-undefined */
import React, { memo, useCallback, useEffect } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { User } from "~/typedef/user";
import { fetchOrderedTotals } from "~/store/mystore/vendor.redux";
import { formatNumber } from "~/utils/salesUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OrderedRevenueTotalsProps {
  userInfo: User;
  mid: string;
  currentRange: Range;
  currentCurrency: string;
  currentCompare: COMPARISON_PERIOD;
  conditionalFormatting?: boolean;
  report?: boolean;
}

const OrderedRevenueTotals = memo(
  ({
    userInfo,
    mid,
    currentRange,
    currentCurrency,
    currentCompare,
    conditionalFormatting,
    report,
  }: OrderedRevenueTotalsProps) => {
    const { t } = useTranslation();

    const orderedRevenueTotals = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.data
    );

    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.data?.lastReportDate
    );

    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.data?.lastUpdatedAt
    );

    const homeCurrency = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.data?.currency
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.totals?.fetching || false
    );

    const dispatch = useDispatch();

    const dispatchFetchOrderedTotals = useCallback(() => {
      dispatch(
        fetchOrderedTotals(
          {
            user: userInfo,
            currentRange,
            mid,
          },
          params
        )
      );
    }, [currentRange, mid, userInfo]);

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchOrderedTotals();
      };
      fetchData();
    }, [userInfo, currentRange, mid]);

    return (
      <Panel
        id="widget-ordered-totals"
        title={t(`myStoresWidget.orderedRevenueSummary`)}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.manufacturingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.manufacturingView")}`
        }
        tooltip={undefined}
        content={
          <Box p={2}>
            {loading || !orderedRevenueTotals ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={orderedRevenueTotals?.glanceViews.current}
                    comparison={{
                      prior: orderedRevenueTotals?.glanceViews.prior,
                    }}
                    title={t(`vendor.glanceViews`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...orderedRevenueTotals?.orderedRevenue}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`vendor.orderedRevenue`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>

                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={orderedRevenueTotals?.orderedUnits.current}
                    comparison={{
                      prior: orderedRevenueTotals?.orderedUnits.prior,
                    }}
                    title={t(`vendor.orderedUnits`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...orderedRevenueTotals?.avgPrice}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`vendor.avgPrice`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={orderedRevenueTotals?.unitsPerGlanceView.current}
                    comparison={{
                      prior: orderedRevenueTotals?.unitsPerGlanceView.prior,
                    }}
                    title={t(`vendor.unitsPerGlanceView`)}
                    tooltip={t(`vendor.unitsPerGlanceViewTooltip`)}
                    condensedComparison={true}
                    condensed={true}
                    percentage={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={orderedRevenueTotals?.customerReturns.current}
                    comparison={{
                      prior: orderedRevenueTotals?.customerReturns.prior,
                    }}
                    title={t(`vendor.customerReturns`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                    reverseFormatting={true}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default OrderedRevenueTotals;
