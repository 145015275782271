import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface LegendIndicatorProps {
  shape: string;
  fill: string;
}
export const LegendIndicator = styled.div<LegendIndicatorProps>`
  height: ${({ shape }) => (shape === "line" ? "3px" : "10px")};
  width: 10px;
  margin-right: 5px;
  background-color: ${({ fill }) => fill};
`;

export const LegendWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

interface ComboChartLegendProps {
  legendItems: {
    shape: string;
    fillColor: string;
    name: string;
  }[];
}
const ComboChartLegend = memo<ComboChartLegendProps>(({ legendItems }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      {legendItems.map((item, i) => (
        <LegendWrapper key={`${item}-${i}`}>
          <LegendIndicator shape={item.shape} fill={item.fillColor} />
          {t(item.name)}
        </LegendWrapper>
      ))}
    </Grid>
  );
});

export default ComboChartLegend;
