import {
  MenuItem,
  SubMenuItem,
  amazonVendorMenuItems,
  getAdvertisingMenuItems,
  getMenuItems,
  notificationMenuItems,
  reportMenuItems,
} from "~/components/toolbars/sideNavigation/sideNavConstants";

import { InsertChartRounded } from "@material-ui/icons";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "./useTypedSelector";

export const useSideNavConfig = (marketplace?: string) => {
  const customLayoutEnabled =
    useTypedSelector((state) => state.customLayout?.layoutConfig?.enabled) ??
    false;
  const customSideNavLinks = useTypedSelector(
    (state) => state.customLayout?.layoutConfig?.sideNav?.pages
  );

  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const location = useLocation();

  if (location.pathname.includes("/reports/") && !marketplace) {
    return reportMenuItems;
  }

  if (location.pathname.includes("/notifications/") && !marketplace) {
    return notificationMenuItems;
  }

  const market =
    marketplace ||
    currentStore?.marketplaceSubtype ||
    currentStore?.marketplace ||
    "";

  const isVendorStore = stripFilteredSuffix(market) === "amazon_vendor";

  const isAdvertisingStore = stripFilteredSuffix(market) === "advertising";

  const flatMenuItems: SubMenuItem[] = [];

  getMenuItems().forEach((menuItem) => {
    if (menuItem.link && menuItem.link !== "storeoverview") {
      flatMenuItems.push({ ...menuItem, subMenus: undefined });
    }
    if (menuItem.subMenus) {
      flatMenuItems.push(...menuItem.subMenus);
    }
  });

  const customSubMenuItemsNew = flatMenuItems.reduce((acc, item) => {
    if (item.link && customSideNavLinks?.includes(item.link)) {
      acc[item.link] = item;
    }
    return acc;
  }, {} as { [key: string]: SubMenuItem });

  const customMenuItems: MenuItem[] = [
    {
      title: "nav.marketplaceOverview",
      link: "storeoverview",
      icon: InsertChartRounded,
      id: "marketplace-overview",
      subMenus: Object.values(customSubMenuItemsNew),
    },
  ];

  return isVendorStore
    ? amazonVendorMenuItems
    : isAdvertisingStore
    ? getAdvertisingMenuItems()
    : customLayoutEnabled && customMenuItems[0]?.subMenus?.length
    ? customMenuItems
    : getMenuItems();
};
