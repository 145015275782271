import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface ValidateAllegroAuthArgs {
  authCode: string;
  shopName: string;
  countryCode: string;
}

interface ValidateAllegroAuthResponse {
  errMsg?: string;
  code?: number;
}
const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    validateAllegroAuth: build.mutation<
      ValidateAllegroAuthResponse,
      ValidateAllegroAuthArgs
    >({
      query: ({ authCode, shopName, countryCode }) => {
        return {
          url: `${baseUrl}/auth/allegro/connect`,
          method: "POST",
          data: { authCode, shopName, countryCode },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Allegro connect", true),
    }),
  }),
});

export const { useValidateAllegroAuthMutation } = extendedApiSlice;
