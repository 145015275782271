import PieChart from "./pieChart";
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
function BuyBoxPieChart(props) {
  const { data } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    numberOfProductsWinning,
    numberOfProductsLosingToAmazon,
    numberOfProductsLosing,
    numberOfNoWinners,
    losingToSelfCount,
    unknownCount,
  } = data;
  const pieChartData = [
    {
      name: t("myStoresWidget.buyBoxSummary.winningLabel"),
      value: numberOfProductsWinning,
      color: theme.palette.chart.green,
    },
    {
      name: t("myStoresWidget.buyBoxSummary.losingToSelfLabel"),
      value: losingToSelfCount,
      color: theme.palette.chart.lightGreen,
    },
    {
      name: t("myStoresWidget.buyBoxSummary.losingToAmazonLabel"),
      value: numberOfProductsLosingToAmazon,
      color: theme.palette.chart.orange,
    },
    {
      name: t("myStoresWidget.buyBoxSummary.losingToOthersLabel"),
      value:
        numberOfProductsLosing -
        numberOfProductsLosingToAmazon -
        losingToSelfCount -
        numberOfNoWinners,
      color: theme.palette.chart.red,
    },
    {
      name: t("myStoresWidget.buyBoxSummary.noWinnerLabel"),
      value: numberOfNoWinners,
      color: theme.palette.chart.gray,
    },
    {
      name: t("myStoresWidget.buyBoxSummary.gatheringDataLabel"),
      value: unknownCount,
      color: theme.palette.chart.cyan,
    },
  ].filter((e) => e.value > 0);
  const totalCount = pieChartData.reduce((acc, curr) => acc + curr.value, 0);
  pieChartData.forEach((pieData) => {
    const piePercentage = Math.round((pieData.value / totalCount) * 100);
    pieData.tooltipContent = `${pieData.name}: ${pieData.value} (${piePercentage}%)`;
  });
  return (
    <PieChart
      data={pieChartData}
      title={t("myStoresWidget.buyBoxSummary.chartTitle")}
    />
  );
}

BuyBoxPieChart.propTypes = {
  data: PropTypes.shape({
    numberOfProductsWinning: PropTypes.number.isRequired,
    numberOfProductsLosingToAmazon: PropTypes.number.isRequired,
    numberOfProductsLosing: PropTypes.number.isRequired,
    numberOfNoWinners: PropTypes.number.isRequired,
    losingToSelfCount: PropTypes.number.isRequired,
    losingToUnknownCount: PropTypes.number.isRequired,
    unknownCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default BuyBoxPieChart;
