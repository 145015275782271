import { Button as ButtonBase, Theme } from "@material-ui/core";

import { ButtonProps } from "@material-ui/core";
import React from "react";
import ReactGA from "react-ga";
import { alpha } from "@material-ui/core/styles";
import styled from "styled-components";

interface ButtonStyleProps extends ButtonProps {
  theme: Theme;
  $customColor:
    | "primary"
    | "secondary"
    | "error"
    | "warning"
    | "info"
    | "success";
}

const Button = styled(ButtonBase)`
  ${({ theme, $customColor, variant }: ButtonStyleProps) =>
    $customColor && {
      backgroundColor:
        variant === "outlined" || variant === "text"
          ? "transparent"
          : theme.palette[$customColor].main,
      color:
        variant === "outlined" || variant === "text"
          ? theme.palette[$customColor].main
          : theme.palette[$customColor].contrastText,
      borderColor:
        variant === "text" ? "transparent" : theme.palette[$customColor].main,
      "&:hover": {
        backgroundColor:
          variant === "outlined" || variant === "text"
            ? // eslint-disable-next-line no-magic-numbers
              alpha(theme.palette[$customColor].main, 0.15)
            : theme.palette[$customColor].dark,
      },
    }}
`;

const supportedColorVariants = ["inherit", "primary", "secondary", "default"];

export interface RaisedButtonProps extends ButtonProps {
  analytics?: {
    action: string;
    category: string;
  };
  color?: any;
  component?: any;
  target?: string;
  to?: string;
}

const RaisedButton = ({
  analytics,
  onClick,
  variant = "contained",
  color,
  ...props
}: RaisedButtonProps) => {
  const augmentedOnClick = (event: any) => {
    if (analytics) {
      ReactGA.event({
        category: analytics.category,
        action: analytics.action,
      });
    }
    // Some buttons are submit buttons, so they don't have a natural onClick.
    // Just skip in that case.
    if (onClick) onClick(event);
  };

  return (
    <Button
      variant={variant}
      onClick={augmentedOnClick}
      {...props}
      $customColor={
        color && !supportedColorVariants.includes(color) ? color : null
      }
      color={
        color && supportedColorVariants.includes(color) ? color : undefined
      }
    >
      {props.children}
    </Button>
  );
};

export default RaisedButton;
