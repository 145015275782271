import "regenerator-runtime/runtime";

import * as Sentry from "@sentry/browser";

import { ExtraErrorData, RewriteFrames } from "@sentry/integrations";
import React, { ComponentType, Suspense } from "react";

import { App } from "./App";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LoadingPage from "@modules/loadingPage";
import Locize from "i18next-locize-backend";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import smartlookClient from "smartlook-client";
import { store } from "@store/configureStore";

interface SentrySamplingContext {
  transactionContext: {
    name: string; // human-readable identifier, like "GET /users"
    op: string; // short description of transaction type, like "pageload"
  };
  parentSampled: boolean; // if this transaction has a parent, its sampling decision
  request: object; // in server contexts, information about the incoming request
}

if (process.env.TARGET_ENV === "production") {
  Sentry.init({
    dsn: "https://a46ffed842b9408f81ed079ab01175c4@o409516.ingest.sentry.io/5282236",
    integrations: [new RewriteFrames(), new ExtraErrorData({ depth: 4 })],
    normalizeDepth: 5,
    environment: process.env.TARGET_ENV,
    beforeSend(event, hint) {
      const error = hint?.originalException;

      if (event) {
        /** Session expired */
        if (
          event.message?.toLowerCase().includes("session") ||
          event.message?.toLowerCase().includes("expired") ||
          (typeof error === "string" && error?.includes("401")) ||
          (typeof error === "object" &&
            error &&
            "message" in error &&
            (error as Error).message?.includes("401"))
        ) {
          return null;
        }
      }

      /**
       * Custom fingerprinting
       *
       * if (
          error &&
          error.message &&
          error.message.match(/database unavailable/i)
        ) {
          event.fingerprint = ["database-unavailable"];
        }
       */

      // Modify the event here
      return event;
    },
    // sampleRate: 0.25,
    // tracesSampleRate: 0.25,
    tracesSampler: (samplingContext) => {
      // Examine provided context data (including parent decision, if any) along
      // with anything in the global namespace to compute the sample rate or
      // sampling decision for this transaction

      // Inherit parent sampling to avoid partial traces
      if (samplingContext.parentSampled !== undefined) {
        return samplingContext.parentSampled;
      }

      // if ("...") {
      //   // These are important - take a big sample
      //   return 0.5;
      // } else if ("...") {
      //   // These are less important or happen much more frequently - only take 1%
      //   return 0.01;
      // } else if ("...") {
      //   // These aren't something worth tracking - drop all transactions like this
      //   return 0;
      // } else {

      // Default sample rate
      return 0.25;
      // }
    },
  });
}

smartlookClient.init("609731b1e943aecf3c83cb5736e92966127abba4");

export const ReactGgEnabled = true;

// initialise i18n framework with Locize backend
i18n
  .use(Locize)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lang",
    },
    fallbackLng: process.env.TARGET_ENV === "production" ? "en" : "dev",
    supportedLngs: ["dev", "en", "zh", "de", "fr", "es", "it", "ja"],
    load: "languageOnly",
    interpolation: {
      escapeValue: false, // not needed for React
    },
    backend: {
      referenceLng: "en",
      projectId: process.env.LOCIZE_PROJECT_ID,
      version:
        process.env.TARGET_ENV === "development" ? "latest" : "production",
    },
    debug: false,
  });

TagManager.initialize({
  gtmId: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID || "",
  dataLayerName: "PageDataLayer",
});

export const WithLazyLoad = (WrappedComponent: ComponentType) =>
  class HOC extends React.Component {
    render() {
      return (
        <Suspense fallback={<LoadingPage />}>
          <WrappedComponent {...this.props} />
        </Suspense>
      );
    }
  };

const AppWithProvider = () => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <App />
    </Provider>
  </DndProvider>
);

ReactDOM.render(<AppWithProvider />, document.getElementById("root"));
