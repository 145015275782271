export enum FORECAST_TYPE {
  BUDGET = "budget",
  FORECAST = "forecast",
  ADSPEND = "adspend",
}

export enum REFERENCE_LEVEL {
  TARGET = "target",
  LIMIT = "limit",
}

export type DayCellData = {
  [FORECAST_TYPE.BUDGET]: {
    [REFERENCE_LEVEL.TARGET]: {
      amount: number;
      currency: string;
    };
  };
  [FORECAST_TYPE.FORECAST]: {
    [REFERENCE_LEVEL.TARGET]: {
      amount: number;
      currency: string;
    };
  };
  [FORECAST_TYPE.ADSPEND]: {
    [REFERENCE_LEVEL.LIMIT]: {
      amount: number;
      currency: string;
    };
  };
};

export interface ForecastValueItem {
  [date: number]: DayCellData;
}

export interface ForecastDataItem {
  forecastValue: ForecastValueItem;
  totals: {
    forecast: {
      budget: number;
      forecast: number;
    };
    adSpend: {
      target: number;
    };
    currency: string;
  };
}
