/* eslint-disable no-magic-numbers */
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { memo } from "react";
import { getCurrencySymbol, intFormatterRounded } from "~/utils/currencyUtils";
import {
  getDataMax,
  getEvenTicksFromZero,
  getTimeseriesXAxisProps,
  useLayoutProps,
} from "~/components/charts/chartUtils/chartComponents";

import BarChartNoData from "~/components/placeholders/barChartNoData";
import ComboChartLegend from "~/components/charts/comboChart/ComboChartLegend";
import ComboChartTooltip from "~/components/charts/comboChart/ComboChartTooltip";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { isEmpty } from "lodash";

interface PlotProps {
  key: string;
  colour: string;
}

export interface LineChartProps {
  title: string;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency?: string;
  chartData: any[];
  isLoading: boolean;
  line: PlotProps;
  xKey: string;
  report?: boolean;
  timezone: string;
}

const Y_AXIS_WIDTH = 80;

const LineChart = memo(
  ({
    title,
    currentPeriod,
    currentCurrency,
    chartData,
    isLoading,
    line,
    xKey,
    report,
    timezone,
  }: LineChartProps) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { height, margin } = useLayoutProps("comboChart", report);

    const { key: lineKey, colour: lineColor } = line;

    const xAxisProps = getTimeseriesXAxisProps(
      currentPeriod,
      chartData,
      xKey,
      timezone
    );

    const dataMax = getDataMax(
      chartData,
      lineKey,
      10 // roundToClosest
    );
    const yTicks = getEvenTicksFromZero(
      dataMax,
      5, // numTicks
      10 // roundToClosest
    );
    const yAxisLeftTickFormatter = (tick: number) => {
      return (
        (currentCurrency
          ? getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol]
          : "") + intFormatterRounded.format(tick)
      );
    };

    const legendData = [
      {
        name: `chartKey.${lineKey}`,
        fillColor: lineColor,
        shape: "line",
        unit: "",
        isInEndUnit: true,
      },
    ];
    const LegendComponent = () => <ComboChartLegend legendItems={legendData} />;
    const tooltipProps = {
      lineTooltipProps: legendData[0],
    };

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box pb={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isEmpty(chartData) && !isLoading ? (
            <BarChartNoData {...{ currentPeriod }} />
          ) : (
            <ResponsiveContainer width="100%" height={height}>
              <ComposedChart data={chartData} margin={margin}>
                <XAxis {...xAxisProps} />
                <YAxis
                  yAxisId="left"
                  {...{
                    tickFormatter: yAxisLeftTickFormatter,
                    dataKey: lineKey,
                    tick: { fontSize: 14 },
                    domain: [0, dataMax],
                    ticks: yTicks,
                    width: Y_AXIS_WIDTH,
                  }}
                />
                <ReferenceLine yAxisId="left" y={0} strokeWidth={2} />
                <Tooltip
                  content={
                    <ComboChartTooltip
                      currentPeriod={currentPeriod}
                      lineTooltipProps={tooltipProps.lineTooltipProps}
                      lineKey={lineKey}
                      timezone={timezone}
                      headerKey={xKey}
                    />
                  }
                />
                <Legend
                  content={LegendComponent}
                  {...{
                    verticalAlign: "top",
                    align: "left",
                    wrapperStyle: { top: -16, fontSize: "12px" },
                  }}
                />
                <Line
                  yAxisId="left"
                  {...{
                    dataKey: lineKey,
                    stroke: lineColor,
                    dot: false,
                    strokeWidth: smDown ? 1 : 2,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default LineChart;
