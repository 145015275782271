import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { InlineIconButton } from "~/icons/inlineIconButton";
import styled from "styled-components";

const CustomGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledInlineIconButton = styled(InlineIconButton)`
  font-size: 1rem;
`;

interface ValueAndTooltipCellProps {
  cell: {
    value: {
      value: number | string;
      tooltip?: string;
      tooltipPosition?: "top" | "bottom" | "left" | "right";
      customIcon?: React.ReactElement;
      hideValue?: boolean;
    };
  };
}

const ValueAndTooltipCell = memo(({ cell }: ValueAndTooltipCellProps) => {
  const { value, tooltip, tooltipPosition, hideValue, customIcon } = cell.value;
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <CustomGrid item>
        {!hideValue && <Typography variant="body2">{value}</Typography>}
      </CustomGrid>
      <CustomGrid item>
        {tooltip && (
          <Tooltip title={tooltip} placement={tooltipPosition || "top"}>
            {customIcon || <StyledInlineIconButton />}
          </Tooltip>
        )}
      </CustomGrid>
    </Grid>
  );
});

export default ValueAndTooltipCell;
