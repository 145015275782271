import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import {
  SnsProductPerformanceRow,
  useSubscribeAndProductPerformanceQuery,
} from "~/store/mystore/subscribeAndSave.redux";
import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import FlatTable from "~/components/table/table";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SubscribeAndSaveTableProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentRange: Range;
  currentCurrency: string;
  pageSize: number;
  actions?: ReactChild;
  searchText?: string;
  conditionalFormatting?: boolean;
  report?: boolean;
}

const SubscribeAndSaveTable = memo<SubscribeAndSaveTableProps>(
  function SubscribeAndSaveTable({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentRange,
    currentCurrency,
    actions,
    searchText,
    pageSize,
    conditionalFormatting,
    report,
  }) {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "subscriberShippedRevenue",
      sortOrder: "desc",
    });

    const { isFetching, rows, count, currency, startDate, endDate } =
      useSubscribeAndProductPerformanceQuery(
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          countryCode,
          currentRange,
          searchText,
          ...paginationParams,
        },
        {
          selectFromResult: ({ data, isFetching }) => {
            return {
              isFetching,
              rows: data?.productPerformance || [],
              currency: data?.currency,
              count: data?.count || 0,
              startDate: data?.startDate,
              endDate: data?.endDate,
            };
          },
        }
      );

    const homeCurrency = useMemo(
      () => currency || getCurrencyByCountryCode[countryCode],
      [countryCode, currency]
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "subscriberShippedRevenue",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const columns = useMemo(
      () => [
        {
          Header: t("snsProductTable.productColumn"),
          id: "title",
          accessor: (row: SnsProductPerformanceRow) => ({
            value: row.title,
            secondRowValue: `ASIN: ${row.asin}${
              row.sku ? ` | SKU: ${row.sku}` : ""
            }`,
            image: row.imageUrl,
            link: row.link,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          divideRight: true,
          sticky: "left",
          colSpan: 1,
          disableSortBy: true,
          customWidth: 200,
        },
        {
          Header: t("snsProductTable.shippedRevenueColumn"),
          id: "subscriberShippedRevenue",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: SnsProductPerformanceRow) => {
            return {
              value: row.subscriberShippedRevenue.current
                ? formatCurrencyRounded(
                    row.subscriberShippedRevenue.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: row.subscriberShippedRevenue.prior
                ? getPercentageDifference(
                    row.subscriberShippedRevenue.current,
                    row.subscriberShippedRevenue.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("snsProductTable.subscribersColumn"),
          id: "subscribers",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: SnsProductPerformanceRow) => {
            return {
              value: numberWithCommas(row.subscribers.current),
              growth: row.subscribers.prior
                ? getPercentageDifference(
                    row.subscribers.current,
                    row.subscribers.prior
                  )
                : "N/A",
              conditionalFormatting,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("snsProductTable.revenuePerSubscriberColumn"),
          id: "revenuePerSubscriber",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: SnsProductPerformanceRow) => {
            return {
              value: row.revenuePerSubscriber.current
                ? formatCurrencyRounded(
                    row.revenuePerSubscriber.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: row.revenuePerSubscriber.prior
                ? getPercentageDifference(
                    row.revenuePerSubscriber.current,
                    row.revenuePerSubscriber.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("snsProductTable.revenuePenetrationPercentageColumn"),
          id: "revenuePenetrationPercentage",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: SnsProductPerformanceRow) => {
            return {
              value:
                row.revenuePenetration.current !== null
                  ? `${row.revenuePenetration.current.toFixed(1)}%`
                  : "-",
              growth: getPercentageDifference(
                row.revenuePenetration.current,
                row.revenuePenetration.prior,
                true
              ),
              conditionalFormatting,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("snsProductTable.notDeliveredDueToOOSPercentageColumn"),
          id: "notDeliveredDueToOOSPercent",
          align: "center",
          cellJustify: "center" as const,
          accessor: ({
            notDeliveredDueToOOSPercent,
          }: SnsProductPerformanceRow) => `${notDeliveredDueToOOSPercent}%`,
          Cell: TextCell,
          isVisible: true,
          hiddenDown: "xl",
        },
        {
          Header: t("snsProductTable.baseDiscountColumn"),
          id: "baseDiscount",
          align: "center",
          cellJustify: "center" as const,
          accessor: ({ baseDiscount }: SnsProductPerformanceRow) =>
            baseDiscount ?? 0,
          Cell: TextCell,
          isVisible: true,
          hiddenDown: "xl",
          disableSortBy: true,
        },
        {
          Header: t("snsProductTable.tierDiscountColumn"),
          id: "tierDiscount",
          align: "center",
          cellJustify: "center" as const,
          accessor: ({ tierDiscount }: SnsProductPerformanceRow) =>
            tierDiscount ?? 0,
          Cell: TextCell,
          isVisible: true,
          hiddenDown: "xl",
          disableSortBy: true,
        },
        ...(marketplaceType === "amazon"
          ? [
              {
                Header: t("snsProductTable.enrollmentColumn"),
                id: "enrollment",
                align: "center",
                cellJustify: "center" as const,
                accessor: ({ enrollment }: SnsProductPerformanceRow) =>
                  enrollment,
                Cell: TextCell,
                isVisible: true,
                hiddenDown: "xl",
                disableSortBy: true,
              },
            ]
          : []),
        {
          Header: t("snsProductTable.eligibilityColumn"),
          id: "eligibility",
          align: "center",
          cellJustify: "center" as const,
          accessor: ({ eligibility }: SnsProductPerformanceRow) => eligibility,
          Cell: TextCell,
          isVisible: true,
          hiddenDown: "xl",
          disableSortBy: true,
        },
      ],
      [currentCurrency]
    );

    return (
      <Panel
        id="widget-sns-product-performance-table"
        title={t("myStoresWidget.snsProductTable.mainTitle")}
        subtitle={
          startDate && endDate
            ? t("myStoresWidget.snsProductTable.timePeriod", {
                fromDate: moment.unix(startDate).format("DD MMM YY"),
                toDate: moment
                  .tz(moment.unix(endDate), currentRange.timezone)
                  .format("DD MMM YY"),
              })
            : undefined
        }
        content={
          report ? (
            <FlatTable
              {...{
                columns,
                data: rows,
                fetchData,
                loading: isFetching,
                sorting: false,
                pagination: false,
                pageSize,
                isReport: report,
              }}
            />
          ) : (
            <Table
              columns={columns}
              loading={isFetching}
              data={rows}
              fetchData={fetchData}
              pageSize={pageSize}
              pageCount={Math.ceil(count / pageSize)}
              sorting
              pagination
            />
          )
        }
        actions={actions}
      />
    );
  }
);

export default SubscribeAndSaveTable;
