import get from "lodash/get";
import { useTypedSelector } from "./useTypedSelector";

const DASHBOARD_RTK_ENDPOINT_NAMES = [
  "salesPerformance",
  "marketingOverviewTotals",
  "marketingOverviewChart",
  "marketingCampaignSummary",
  "marketplaceOverview",
  "marketplaceOverviewExpanded",
];

export const useDashboardFetching = () => {
  // TODO: as we migrate the other slices to RTK, we should remove the old slices from here
  // the dashboard reducer should have matchers for the relevant endpoints which will be used on the dashboard
  const dashboardFetching = useTypedSelector(
    (state) => state.dashboard?.isFetching
  );
  const marketplaceOverviewTotalsFetching = useTypedSelector((state) =>
    get(state, "overview.marketplaceOverviewTotals.fetching", false)
  );
  const salesMixFetching = useTypedSelector((state) =>
    get(state, "overview.salesMix.fetching", false)
  );
  const dispatchStatusFetching = useTypedSelector((state) =>
    get(state, "overview.dispatchStatus.fetching", false)
  );
  const overdueOrdersFetching = useTypedSelector((state) =>
    get(state, "overview.overdueOrders.fetching", false)
  );
  const overdueOrdersListFetching = useTypedSelector((state) =>
    get(state, "overview.overdueOrdersList.fetching", false)
  );
  const topProductsFetching = useTypedSelector((state) =>
    get(state, "overview.topProducts.fetching", false)
  );
  const inventoryStatusFetching = useTypedSelector((state) =>
    get(state, "overview.inventoryStatus.fetching", false)
  );
  const accountHealthFetching = useTypedSelector((state) =>
    get(state, "overview.accountHealth.fetching", false)
  );
  const salesByDayFetching = useTypedSelector((state) =>
    get(state, "overview.salesByDay.fetching", false)
  );
  const averageSalesByDayFetching = useTypedSelector((state) =>
    get(state, "overview.averageSalesByDay.fetching", false)
  );
  const averageSalesByDayByMarketFetching = useTypedSelector((state) =>
    get(state, "overview.averageSalesByDayByMarket.fetching", false)
  );
  const profitabilityFetching = useTypedSelector((state) =>
    get(state, "profitability.overview.fetching", false)
  );
  const profitabilityExpandedFetching = useTypedSelector((state) =>
    get(state, "profitability.overviewExpanded.fetching", false)
  );
  const marketingExpandedFetching = useTypedSelector((state) =>
    get(state, "overview.marketing.summaryExpanded.loading")
  );

  // We need to use any until we have properly defined types for all our slices
  const isRTKQueryPending = useTypedSelector((state: any) =>
    state?.api?.queries
      ? Object.values(state.api.queries).some(
          (query: any) =>
            DASHBOARD_RTK_ENDPOINT_NAMES.includes(query.endpointName) &&
            query.status === "pending"
        )
      : false
  );

  return (
    dashboardFetching ||
    marketplaceOverviewTotalsFetching ||
    salesMixFetching ||
    dispatchStatusFetching ||
    overdueOrdersFetching ||
    overdueOrdersListFetching ||
    topProductsFetching ||
    inventoryStatusFetching ||
    accountHealthFetching ||
    salesByDayFetching ||
    averageSalesByDayFetching ||
    averageSalesByDayByMarketFetching ||
    profitabilityFetching ||
    profitabilityExpandedFetching ||
    marketingExpandedFetching ||
    isRTKQueryPending
  );
};
