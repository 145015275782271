import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/pageBlock";
import React from "react";
import SalesByIntervalWidget from "~/modules/overview/salesByInterval";
import { get } from "lodash";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SalesByInterval = () => {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SalesByIntervalWidget store={store} />
        </Grid>
      </Grid>
    </PageBlock>
  );
};

export default SalesByInterval;
