import moment, { Moment } from "moment-timezone";

import React from "react";
import { StoreState } from "~/typedef/store";
import { Typography } from "@material-ui/core";
import get from "lodash/get";
import { notNullish } from "~/utils/typeUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Footer = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  font-weight: ${(props) => props.theme.typography.faintDetail.fontWeight};
  font-size: ${(props) => props.theme.typography.faintDetail.fontSize};
  color: ${(props) => props.theme.palette.grey[600]};
`;

type AddParams = Parameters<Moment["add"]>;
export const DataDate: React.FC<{
  savedAt: string;
  nextUpdateAmount?: number;
  nextUpdateUnit?: AddParams[0];
}> = ({ savedAt, nextUpdateAmount = 1, nextUpdateUnit = "d" }) => {
  const { t } = useTranslation();
  const selectedTimezone = useTypedSelector(
    (state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const lastUpdate = moment
    .tz(savedAt, selectedTimezone)
    .format("HH:mm z DD MMMM, YYYY");
  const nextUpdate = moment
    .tz(moment(savedAt).add(nextUpdateAmount, nextUpdateUnit), selectedTimezone)
    .format("HH:mm z DD MMMM, YYYY");
  const footerText =
    `${t("myStoresWidget.dataDate.lastUpdated")} ${lastUpdate}.` +
    ` ${t("myStoresWidget.dataDate.nextUpdate")} ${nextUpdate}.`;

  return <Footer>{notNullish(savedAt) && footerText}</Footer>;
};

export default DataDate;
