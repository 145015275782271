import { Box, Grid, Link, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { useCallback, useEffect, useState } from "react";
import {
  loadBuyBoxProducts,
  loadSyncStatus,
  resetAllBuyBoxPrices,
  submitSyncPrice,
} from "~/store/buyBox.redux";
import { useDispatch } from "react-redux";

import BuyBoxSummary from "~/modules/widgets/buyboxSummary";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import { FilterActions } from "~/modules/buybox/filterActions";
import FloatingAlertCard from "~/components/alert/floatingAlertCard";
import GenericBuyboxTrend from "~/modules/genericBuybox/genericBuyboxTrend";
import NavigationPrompt from "react-router-navigation-prompt";
import PageBlock from "~/components/containers/sideNavPageBlock";
import Panel from "~/components/panel/panel";
import ProductTable from "~/modules/buybox/productTable";
import SellerSummary from "~/modules/buybox/sellerSummary";
import SellerTable from "~/modules/buybox/sellerTable";
import SidePanel from "~/components/drawerPanel/sidePanel";
import SmallButton from "~/components/buttons/smallButton";
import SyncDialog from "~/modules/buybox/syncDialog";
import SyncStatusDialog from "~/modules/buybox/syncStatusDialog";
import { get } from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { AmazonOffer } from "~/typedef/amazon-offer";
import { AmazonProductData } from "~/typedef/amazon-product";
import { AmazonFeed } from "~/typedef/amazon-feed";

const PAGE_SIZE = 10;

const SyncStatusLink = styled(Link)<{ disabled: boolean }>`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
    padding: 0 1rem;
  `}
`;

const SyncButton = styled(SmallButton)`
  height: 2rem;
`;

type BuyBoxPerformanceSellerType = {
  sellerSku: string;
  title: string;
  imageUrl: string;
  isFulfilledByAmazon: boolean;
  currencyCode: string;
  originalPrice: number;
  updatedPrice: number;
};

const BuyBoxPerformance = () => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => get(state, "user"));

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const productsLoading = useTypedSelector((state) =>
    get(state, "buyBox.productsLoading")
  );
  const products: { data: AmazonProductData[]; count: number } =
    useTypedSelector((state) => get(state, "buyBox.products"));
  const syncPending: BuyBoxPerformanceSellerType[] = useTypedSelector((state) =>
    get(state, "buyBox.syncPending")
  );
  const syncLoading: boolean = useTypedSelector((state) =>
    get(state, "buyBox.syncLoading")
  );
  const syncStatus: AmazonFeed[] = useTypedSelector((state) =>
    get(state, "buyBox.syncStatus")
  );
  const syncStatusLoading: boolean = useTypedSelector((state) =>
    get(state, "buyBox.syncStatusLoading")
  );

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const [filter, setFilter] = useState("losing");
  const [selectedListing, setSelectedListing] = useState<AmazonProductData>();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [toastOpen, setToastOpen] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [dataVersion, setDataVersion] = React.useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && store) {
      dispatch(resetAllBuyBoxPrices());
      dispatch(
        loadSyncStatus(user._id, store.sourceSystemId, store.marketplaceCountry)
      );
    }
  }, [store?.sourceSystemId, store?.marketplaceCountry, user?._id]);

  useEffect(() => {
    if (statusDialogOpen && store && user) {
      dispatch(
        loadSyncStatus(user._id, store.sourceSystemId, store.marketplaceCountry)
      );
    }
  }, [
    store?.sourceSystemId,
    store?.marketplaceCountry,
    statusDialogOpen,
    user?._id,
  ]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      if (store && user) {
        dispatch(
          loadBuyBoxProducts(
            user._id,
            store.sourceSystemId,
            store.marketplaceCountry,
            filter,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                }
          )
        );
      }
    },
    [user?._id, store?.sourceSystemId, store?.marketplaceCountry, filter]
  );

  const handleListingSelect = useCallback(
    (listing: AmazonProductData) => {
      setSelectedListing(listing);
      setDrawerOpen(true);
    },
    [setDrawerOpen]
  );

  return (
    <PageBlock>
      <NavigationPrompt when={syncPending && syncPending.length > 0}>
        {({ onConfirm, onCancel }) => (
          <ConfirmDialog
            open={true}
            title={t("myStoresWidget.buyBoxPerformance.unsavedChangesTitle")}
            onClose={onCancel}
            onConfirm={onConfirm}
            content={
              <>
                <Typography variant="body1">
                  {t("myStoresWidget.buyBoxPerformance.unsavedChangesMessage")}
                </Typography>
                <Typography variant="body1">
                  {t(
                    "myStoresWidget.buyBoxPerformance.unsavedChangesConfirmMessage"
                  )}
                </Typography>
              </>
            }
          />
        )}
      </NavigationPrompt>

      <SyncDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        onConfirm={() => {
          const submitAndRefresh = async () => {
            setDialogOpen(false);
            if (store) {
              await dispatch(
                submitSyncPrice(
                  user._id,
                  store.sourceSystemId,
                  store.marketplaceCountry,
                  syncPending.map((p) => ({
                    sellerSku: p.sellerSku,
                    price: {
                      CurrencyCode: p.currencyCode,
                      Amount: p.updatedPrice,
                    },
                  }))
                )
              );
            }
            setToastOpen(true);
            dispatch(resetAllBuyBoxPrices());
            setDataVersion((prevState) => prevState + 1);
          };
          submitAndRefresh();
        }}
        data={syncPending}
      />

      <SyncStatusDialog
        open={statusDialogOpen}
        onClose={() => {
          setStatusDialogOpen(false);
        }}
        data={syncStatus}
      />

      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          {store && <BuyBoxSummary store={store} />}
        </Grid>

        <Grid item lg={6} xs={12}>
          {store && (
            <GenericBuyboxTrend
              mid={store.merchantId}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              marketplace={store.marketplace}
              timezone={selectedTimezone}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <FloatingAlertCard
            onClose={() => {
              setToastOpen(false);
            }}
            isOpen={toastOpen}
            type="success"
          >
            <Typography align="left">
              {t("myStoresWidget.buyBoxPerformance.syncingMessage")}
            </Typography>
          </FloatingAlertCard>

          <DrawerPanel
            open={drawerOpen}
            setOpen={setDrawerOpen}
            containerId={"drawer-container"}
            sidePanel={
              selectedListing ? (
                <SidePanel
                  displayImage={true}
                  image={selectedListing.imageUrl}
                  title={selectedListing.title || ""}
                  content={
                    <Grid item>
                      <SellerSummary
                        data={selectedListing}
                        marketplaceCountry={store?.marketplaceCountry}
                      />
                      {store && (
                        <SellerTable
                          listing={selectedListing}
                          data={selectedListing.sellers}
                          sellerId={store.sourceSystemId}
                          shopName={store.storeName}
                          marketplaceCountry={store?.marketplaceCountry}
                        />
                      )}
                    </Grid>
                  }
                  handleClose={() => setDrawerOpen(false)}
                />
              ) : (
                <Grid />
              )
            }
            mainPanel={
              <Panel
                id="widget-products"
                title={t("myStoresWidget.buyBoxPerformance.mainTitle")}
                content={
                  <Box style={{ height: "36.5em" }}>
                    <ProductTable
                      filter={filter}
                      data={products.data}
                      fetchData={fetchData}
                      loading={productsLoading}
                      pageCount={Math.ceil(products.count / PAGE_SIZE)}
                      pageSize={PAGE_SIZE}
                      handleListingSelect={handleListingSelect}
                      dataVersion={dataVersion}
                    />
                  </Box>
                }
                actions={
                  <Box width="100%" px={1}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <SyncStatusLink
                          disabled={syncStatusLoading}
                          onClick={() => {
                            setStatusDialogOpen(true);
                          }}
                        >
                          <Typography variant="body1">
                            {t(
                              "myStoresWidget.buyBoxPerformance.syncStatusLink"
                            )}
                          </Typography>
                        </SyncStatusLink>
                        {store && store.isDemoMode ? (
                          <DemoTooltip
                            arrow
                            placement="top"
                            open={!drawerOpen}
                            title={t("generic.notAvailableDemoMode")}
                          >
                            <span>
                              <SyncButton
                                disabled={true}
                                variant="contained"
                                color="info"
                              >
                                {t(
                                  "myStoresWidget.buyBoxPerformance.syncButton"
                                )}
                              </SyncButton>
                            </span>
                          </DemoTooltip>
                        ) : (
                          <SyncButton
                            disabled={
                              !syncPending ||
                              syncPending.length === 0 ||
                              syncLoading
                            }
                            variant="contained"
                            color="info"
                            onClick={() => {
                              setDialogOpen(true);
                            }}
                          >
                            {t("myStoresWidget.buyBoxPerformance.syncButton")}
                          </SyncButton>
                        )}
                      </Grid>
                      <Grid container item xs={12} md={6} alignItems="center">
                        <Grid item xs={11} md={10}>
                          <FilterActions
                            switchFilter={(value) => setFilter(value)}
                            currentFilter={filter}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={1}
                          md={2}
                          justifyContent="flex-end"
                        >
                          {store && (
                            <DownloadCsv
                              mid={store.merchantId}
                              reportType={"buyboxProducts"}
                              path={"/api/amazon/buybox/products"}
                              params={{
                                ...store,
                                amazonSellerId: store.sourceSystemId,
                                filter,
                                countryCode: store.marketplaceCountry,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
            }
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
};

export default BuyBoxPerformance;
