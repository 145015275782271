import "draft-js/dist/Draft.css";

import { EditorState, RawDraftContentState, convertFromRaw } from "draft-js";

import { Box } from "@material-ui/core";
import { COMMENTARY_FILTERS } from "~/store/mystore/notes.redux";
import Panel from "~/components/panel/panel";
import React from "react";
import RichTextEditor from "~/components/richTextEditor/richTextEditor";
import { useTranslation } from "react-i18next";

type CommentsDisplayProps =
  | CommentsSingleChannelProps
  | CommentsMultiChannelProps;

interface CommentsSingleChannelProps {
  note: RawDraftContentState;
  filter: COMMENTARY_FILTERS;
}

interface CommentsMultiChannelProps {
  note: RawDraftContentState;
  tag: string;
}

const CommentsDisplay = (data: CommentsDisplayProps) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(convertFromRaw(data.note))
  );

  return (
    <Panel
      id="widget-external-comments"
      title={
        "filter" in data
          ? `COMMENT - ${t(`reportComments.commentType.${data.filter}`)}`
          : `COMMENT - ${data.tag}`
      }
      content={
        <Box>
          <RichTextEditor
            editorState={editorState}
            setEditorState={setEditorState}
            editMode={false}
            includeControls={false}
          />
        </Box>
      }
    />
  );
};

export default CommentsDisplay;
