import axios from "axios";
import { baseUrl } from "../../configs";

export const connectYahooJapan = async (authCode: string) => {
  await axios({
    method: "POST",
    url: `${baseUrl}/auth/yahooJapan/connect`,
    data: { authCode },
  });
};
