import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  DATETIME_PERIODS_KEY,
  getDatesFromPeriod,
} from "../../../store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";
import {
  shouldDisableDateFilter,
  shouldDisableTimezone,
} from "../sideNavigation/sideNavUtils";

import { DateRange } from "~/typedef/date";
import DateRangeDropdown from "~/components/dateRangePicker/fullDateTimeDropdown";
import { StoreState } from "~/typedef/store";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { getNewRange } from "~/components/buttons/syncButton";
import moment from "moment-timezone";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TimePeriodDropdownProps {
  maxWidth?: number;
  minWidth?: number;
  fullWidth?: boolean;
  isDisabled?: boolean;
}

const TimePeriodDropdown = memo<TimePeriodDropdownProps>(
  function TimePeriodDropdown({ maxWidth, minWidth, fullWidth, isDisabled }) {
    const { t } = useTranslation();
    const location = useLocation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [disableTimezone, setDisableTimezone] = useState<boolean>(false);
    const dispatch = useDispatch();

    const user: User = useTypedSelector((state: StoreState) => state.user);

    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const preferredTimezone: string | undefined = useTypedSelector(
      (state: StoreState) =>
        state?.persistentAppSettings?.setting?.data?.preferredTimezone
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    useEffect(() => {
      // Our routes are set up so that the path segment corresponding to a
      // menu item in the sidebar is always in the same position in the URL
      // If you're at a path deeper than that, you get taken back to the
      // "main screen" for your particular menu item instead
      const MENU_ITEM_PATH_DEPTH = 3;
      const pathSegments = location.pathname.split("/");
      const page = pathSegments[MENU_ITEM_PATH_DEPTH];
      if (shouldDisableDateFilter(page, user)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      if (shouldDisableTimezone(page, user)) {
        setDisableTimezone(true);
      } else {
        setDisableTimezone(false);
      }
    }, [location]);

    const dispatchSetDates = (
      range: DateRange,
      period: DATETIME_PERIODS,
      comparison: COMPARISON_PERIOD,
      timezone: string
    ) => {
      const newRange = getNewRange({
        currentPeriod: period,
        currentCompare: comparison,
        selectedTimezone: timezone,
        currentRange: range,
      });

      dispatch(
        upsertPersistentAppSettings(
          {
            organisationId: user.organisationId,
            userId: user._id,
            settings: {
              currentPeriod: period,
              timezone,
              // save a "preferred timezone" that we can switch
              // to when users swap away from monthly, quarterly,
              // yearly etc intervals
              ...(timezone !== "UTC" ? { preferredTimezone: timezone } : {}),
              currentRange: newRange,
              currentCompare: comparison,
            },
          },
          user?.isDemoMode ? false : true
        )
      );
    };

    const getPeriod = (): DATETIME_PERIODS => {
      return (Object.values(DATETIME_PERIODS).find(
        (value) => value === currentPeriod
      ) || [""]) as DATETIME_PERIODS;
    };

    return (
      <DateRangeDropdown
        title={t("dashboard.selectDateRangeTitle")}
        tooltip={t("dashboard.selectDateRangeTooltip")}
        maxWidth={maxWidth}
        minWidth={minWidth}
        fullWidth={fullWidth}
        disabled={isDisabled || disabled}
        disableTimezone={disableTimezone}
        setDates={dispatchSetDates}
        period={getPeriod()}
        range={currentRange}
        compare={currentCompare}
        timezone={selectedTimezone}
        preferredTimezone={preferredTimezone}
        options={Object.keys(DATETIME_PERIODS) as DATETIME_PERIODS_KEY[]}
      />
    );
  }
);

export default TimePeriodDropdown;
