import { Filter, Range } from "~/typedef/store";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import OverviewChartPanel from "./overviewChartPanel";
import OverviewTotalsPanel from "./overviewTotalsPanel";
import React from "react";
import { User } from "~/typedef/user";
import { memo } from "react";

interface OverviewTotalsAndChartProps {
  userInfo: User;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter: Filter;
  includeTax: boolean;
  report?: boolean;
  timezone: string;
  showChart?: boolean;
  showTotals?: boolean;
  conditionalFormatting?: boolean;
  selectedMetric?: string;
}

const OverviewTotalsAndChart = memo<OverviewTotalsAndChartProps>(
  function OverviewTotalsAndChart({
    userInfo,
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentPeriod,
    currentRange,
    currentCurrency,
    currentFilter,
    report,
    timezone,
    showChart,
    showTotals,
    conditionalFormatting,
    includeTax,
    selectedMetric,
  }) {
    return (
      <>
        {showChart && (
          <OverviewChartPanel
            mid={mid}
            countryCode={countryCode}
            marketplaceType={marketplaceType}
            marketplaceSubtype={marketplaceSubtype}
            report={report}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            currentFilter={currentFilter}
            timezone={timezone}
            includeTax={includeTax}
            selectedMetric={selectedMetric}
          />
        )}
        {showTotals && (
          <OverviewTotalsPanel
            mid={mid}
            timezone={timezone}
            userInfo={userInfo}
            currentRange={currentRange}
            countryCode={countryCode}
            marketplaceType={marketplaceType}
            marketplaceSubtype={marketplaceSubtype}
            currentCurrency={currentCurrency}
            conditionalFormatting={conditionalFormatting}
            includeTax={includeTax}
          />
        )}
      </>
    );
  }
);

export default OverviewTotalsAndChart;
