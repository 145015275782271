import {
  Code,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@material-ui/icons";

import { EditorState } from "draft-js";
import React from "react";
import StyleButton from "./styleButton";

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: <FormatBold fontSize="small" /> },
  { label: "Italic", style: "ITALIC", icon: <FormatItalic fontSize="small" /> },
  {
    label: "Underline",
    style: "UNDERLINE",
    icon: <FormatUnderlined fontSize="small" />,
  },
];

interface InlineStyleControlsProps {
  editorState: EditorState;
  onToggle: (style: string) => void;
  editMode: boolean;
}

const InlineStyleControls = ({
  editorState,
  onToggle,
  editMode,
}: InlineStyleControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
          editMode={editMode}
        />
      ))}
    </>
  );
};

export default InlineStyleControls;
