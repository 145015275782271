import * as Sentry from "@sentry/browser";

import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import LoadingIndicator from "../components/loadingIndicator/loadingIndicator";
import React from "react";
import { getHomePath } from "@merchantspring/common";
import { handleLoginSucceed } from "../modules/login/login";
import { loginDemoAccount } from "~/store/user.redux";
import { useHistory } from "react-router-dom";

export const DemoPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [isLoginSuccessful, setIsLoginSuccessful] = React.useState(false);

  const dispatchLoginDemoAccount = React.useCallback(async () => {
    try {
      await dispatch(loginDemoAccount());
      setIsLoginSuccessful(true);
    } catch (e) {
      Sentry.captureException(e);
      setIsLoginSuccessful(false);
      history.push("/");
    }
  }, [dispatch, history]);

  const destinationRoute = useSelector((state) => {
    if (state.globalVar && state.globalVar.destinationRoute) {
      if (
        !["/", "/signin", "/signup"].includes(state.globalVar.destinationRoute)
      ) {
        return state.globalVar.destinationRoute;
      } else {
        return getHomePath(state.user);
      }
    }
    return getHomePath(state.user);
  });

  React.useEffect(() => {
    dispatchLoginDemoAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isLoginSuccessful && user && user._id) {
      handleLoginSucceed(user, history, destinationRoute, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccessful, user, history, destinationRoute]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <LoadingIndicator />
    </Box>
  );
};

export default DemoPage;
