import {
  ENTITY_TYPE,
  formatMatchType,
  getPerformanceColumns,
} from "./commonColumns";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";
import { useReportDateFrom, useReportDateTo } from "./useReportDate";

import ColumnSelect from "~/components/adTable/columnSelect";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkCell } from "~/components/table/cells/linkCell";
import Panel from "~/components/panel/panel.tsx";
import PropTypes from "prop-types";
import SearchFilter from "~/components/adTable/searchFilter";
import StatusCell from "./statusCell";
import Table from "~/components/adTable/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchMarketingKeywords } from "../../store/mystore/marketing.redux";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

const KeywordSummaryPanel = memo(({ mid, countryCode, adGroupId }) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user);
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const marketplaceType = get(store, "marketplace");
  const marketplaceSubtype = get(store, "marketplaceSubtype");
  const homeCurrency = getCurrencyByCountryCode[countryCode];
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useSelector((state) =>
    get(state, "globalVar.currencyRates")
  );
  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store.marketplaceCountry],
    currentCurrency
  );
  const marketing = useSelector((state) => get(state, "marketing"));
  const loading = useSelector((state) =>
    get(state, "marketing.keywords.loading")
  );

  const reportDateFrom = useReportDateFrom();
  const reportDateTo = useReportDateTo();

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      ...(!adGroupId
        ? [
            {
              Header: t("advertisingDashboardWidget.adTable.campaignColumn"),
              id: "campaign_name",
              accessor: "campaign_name",
              isVisible: true,
            },
            {
              Header: t("advertisingDashboardWidget.adTable.adGroupColumn"),
              id: "ad_group_name",
              accessor: "ad_group_name",
              isVisible: true,
            },
          ]
        : []),
      {
        Header: t("advertisingDashboardWidget.adTable.keywordColumn"),
        id: "keyword_text",
        accessor: (row) => ({
          value: row.keyword_text,
          subtitle: formatMatchType(row.match_type),
        }),
        Cell: (props) => <LinkCell {...props} />,
        isVisible: true,
        isLocked: true,
      },
      {
        Header: t("advertisingDashboardWidget.adTable.statusColumn"),
        id: "keyword_status",
        accessor: (row) => ({
          value: row.keyword_status,
        }),
        Cell: (props) => <StatusCell {...props} />,
        isVisible: true,
      },
      {
        Header: t("advertisingDashboardWidget.adTable.bidColumn"),
        id: "bid",
        accessor: (row) =>
          row.bid
            ? formatCurrency(
                row.bid,
                currencyRates,
                homeCurrency,
                currentCurrency
              )
            : "-",
        align: "right",
        Cell: ValueCell,
        isVisible: true,
      },
      ...getPerformanceColumns(
        ENTITY_TYPE.keyword,
        currencyRates,
        homeCurrency,
        currentCurrency,
        t
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencyRates, currentCurrency, homeCurrency, adGroupId]
  );

  const [myColumns, setMyColumns] = useState(columns);
  const [searchText, setSearchText] = useState("");

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      dispatch(
        fetchMarketingKeywords(
          userInfo._id,
          mid,
          countryCode,
          marketplaceType,
          marketplaceSubtype,
          searchText,
          reportDateFrom,
          reportDateTo,
          adGroupId,
          sortBy.length > 0
            ? {
                pageSize,
                pageIndex,
                sortKey: sortBy[0].id,
                sortDesc: sortBy[0].desc,
              }
            : {
                pageSize,
                pageIndex,
                sortKey: "attributed_sales",
                sortDesc: true,
              }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userInfo._id,
      mid,
      countryCode,
      store.marketplaceType,
      searchText,
      reportDateFrom,
      reportDateTo,
      adGroupId,
    ]
  );

  return (
    <Panel
      id="widget-marketing-keyword-summary"
      title={t("advertisingDashboardWidget.keywords.mainTitle")}
      tooltip={t("advertisingDashboardWidget.keywords.mainTooltip")}
      content={
        <Table
          columns={myColumns}
          data={get(marketing, "keywords.data", [])}
          fetchData={fetchData}
          loading={loading}
          sorting={true}
          pagination={true}
          pageCount={Math.ceil(get(marketing, "keywords.count", 0) / PAGE_SIZE)}
          pageSize={PAGE_SIZE}
        />
      }
      actions={
        <>
          <SearchFilter setSearchText={setSearchText} />
          <ColumnSelect columns={myColumns} setColumns={setMyColumns} />
          <DownloadCsv
            mid={store.merchantId}
            reportType={"keyword"}
            path={"/api/amazon/advertising/keyword"}
            params={{
              adGroupId,
              mid,
              countryCode,
              marketplaceType,
              marketplaceSubtype,
              searchText,
              fromDate: reportDateFrom,
              toDate: reportDateTo,
              shopName: store.storeName,
              currentCurrency,
              exchangeRate,
            }}
          />
        </>
      }
    />
  );
});

KeywordSummaryPanel.propTypes = {
  mid: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  adGroupId: PropTypes.string,
};
export default KeywordSummaryPanel;
