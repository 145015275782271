import { CurrentStore, FilteredStore } from "~/typedef/store";
import { useEffect, useState } from "react";

import { useTypedSelector } from "./useTypedSelector";

export const useAdvertisingConnected = (store?: CurrentStore) => {
  const [isAdvertisingAuthorised, setAdvertisingAuthorized] = useState(false);
  const [isReconnectAdvertising, setReconnectAdvertising] = useState(false);

  const filteredStores = useTypedSelector(
    (state) => state.mystore?.filteredStores?.stores || []
  );

  const disconnectedStores = useTypedSelector(
    (state) => state.connections?.disconnectedStores?.stores || []
  );

  const advertisingValid =
    filteredStores.find(
      (fs: FilteredStore) => fs.merchantId === store?.merchantId
    )?.advertisingStatus || store?.marketplaceSubtype === "advertising";

  const disconnectedStore = disconnectedStores?.find(
    (ds) =>
      ds.mid === store?.merchantId &&
      ds.credentials.some((cred) => cred.type === "advertising")
  );

  const isDemoMode = store?.isDemoMode;

  useEffect(() => {
    setAdvertisingAuthorized(false);

    if (isDemoMode) {
      setAdvertisingAuthorized(true);
      setReconnectAdvertising(false);
    } else if (advertisingValid && !disconnectedStore) {
      setAdvertisingAuthorized(true);
      setReconnectAdvertising(false);
    } else if (!advertisingValid && disconnectedStore) {
      setAdvertisingAuthorized(false);
      setReconnectAdvertising(true);
    } else {
      setAdvertisingAuthorized(false);
      setReconnectAdvertising(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return {
    isAdvertisingAuthorised,
    isReconnectAdvertising,
  };
};
