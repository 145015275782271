import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";

import MarketingOverviewChart from "./marketingCampaignSummaryChart";
import React from "react";
import get from "lodash/get";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CampaignSummaryChart = memo(function CampaignSummaryChart() {
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  return (
    <MarketingOverviewChart
      {...{
        currentFilter,
        currentPeriod,
        includeTax,
        currentCurrency,
        currentRange,
        timezone: selectedTimezone,
      }}
    />
  );
});

export default CampaignSummaryChart;
