import Button from "@material-ui/core/Button";
import React from "react";
import Thehtml from "./MSToolTermsandConditions.html";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: theme.typography.fontFamily,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    marginTop: "50px",
    paddingLeft: "300px",
    paddingRight: "300px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },

  btnFrame: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    width: "150px",
    margin: "0 auto",
    marginBottom: "30px",
    color: theme.palette.secondary.main,
    border: "1px solid #FDB813",
    fontWeight: 500,
    display: "inline-block",
  },
});

class ConditionPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const locale = this.props.match.params.locale;
    return (
      <div>
        <div dangerouslySetInnerHTML={createMarkup()} />
        <div className={classes.btnFrame}>
          <Button
            className={classes.btn}
            onClick={() => this.props.history.push("/register")}
          >
            Back
          </Button>
        </div>
      </div>
    );
  }
}

const createMarkup = () => ({ __html: Thehtml });

export default withStyles(styles)(ConditionPage);
