import { Box, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { LaunchRounded, TrendingUp } from "@material-ui/icons";
import React, { ReactChild, memo } from "react";

import { Variant } from "@material-ui/core/styles/createTypography";
import picMissing from "../../../img/picMissing.jpg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const GridWrapper = styled(Grid)<{ maxwidth?: string; width?: string }>`
  max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "31.25rem")};
  ${({ width }) => (width ? `width: ${width};` : "")}
`;

const Typography11 = styled(Typography)`
  font-size: ${({ theme }) => `${theme.typography.linkAndImageCell.fontSize}`};
  font-weight: ${({ theme }) =>
    `${theme.typography.linkAndImageCell.fontWeight}`};
`;

const FlexStartGrid = styled(Grid)<{ $thirdRowComponent: boolean }>`
  align-self: ${({ $thirdRowComponent }) =>
    $thirdRowComponent === true ? "flex-start" : "default"};
`;

const NoWrapGrid = styled(Grid)<{ nowrapflexgrow?: string }>`
  overflow: hidden;
  ${({ nowrapflexgrow }) =>
    nowrapflexgrow ? `flex-grow: ${nowrapflexgrow};` : ""}
`;

const Image = styled.img`
  display: flex;
  height: calc(45px - 1rem);
  width: calc(45px - 1rem);
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  object-fit: cover;
`;

const CellLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
  `}
`;

const ExternalCellLinkIcon = styled(LaunchRounded)`
  font-size: 1rem;
  margin-left: 5px;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ClickableFlexContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledExpandIcon = styled(TrendingUp)`
  height: 1rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Divider = styled.div`
  height: 10px;
  width: 1px;
  margin-left: 5px;
  background-color: ${({ theme }) => theme.palette.text.secondary};
`;

const handleClick = (row: any, toggleAllRowsExpanded: (a: boolean) => void) => {
  const { onClick } = row.getToggleRowExpandedProps();
  toggleAllRowsExpanded(false);
  onClick();
};

interface LinkAndImageComponentProps {
  colorVariant?: "internal" | "external";
  maxColumnWidth?: string;
  image?: string;
  icon?: ReactChild;
  value: string;
  link?: string;
  target?: string;
  secondRowValue?: string;
  row?: any; // used for React table's expanding row feature
  toggleAllRowsExpanded?: (a: boolean) => void;
  expandable?: boolean;
  thirdRowComponent?: ReactChild;
  width?: string;
  noWrapCell?: boolean;
  noWrapFlexGrow?: string;
  fontVariant?: Variant;
  secondRowFontSize?: string;
}

export const LinkAndImageComponent: React.FC<LinkAndImageComponentProps> = ({
  maxColumnWidth,
  image,
  icon,
  value,
  noWrapCell,
  link,
  target,
  colorVariant,
  secondRowValue,
  expandable,
  row,
  toggleAllRowsExpanded,
  thirdRowComponent,
  width,
  noWrapFlexGrow,
  fontVariant,
}) => {
  const { t } = useTranslation();
  return (
    <GridWrapper
      container
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      maxwidth={maxColumnWidth}
      width={width}
    >
      {/* Had to turn the boolean into a string to silence a console warning */}
      <FlexStartGrid item $thirdRowComponent={Boolean(thirdRowComponent)}>
        {image ? <Image src={image} /> : <Image src={picMissing} />}
        {icon}
      </FlexStartGrid>
      <NoWrapGrid nowrapflexgrow={noWrapFlexGrow} item>
        <Tooltip title={value}>
          {link ? (
            <>
              <CellLink href={link} target={target}>
                <FlexContainer>
                  <Typography
                    variant={fontVariant ?? "body2"}
                    noWrap={noWrapCell}
                  >
                    {value}
                  </Typography>
                  {colorVariant === "external" && <ExternalCellLinkIcon />}
                </FlexContainer>
              </CellLink>
              {secondRowValue && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography11 align="left" color="textPrimary" noWrap>
                    {secondRowValue}
                  </Typography11>
                  {expandable && <Divider />}
                  {expandable && row && toggleAllRowsExpanded && (
                    <ClickableFlexContainer
                      onClick={() => handleClick(row, toggleAllRowsExpanded)}
                    >
                      <StyledExpandIcon />
                      <Typography
                        align="left"
                        variant="subtitle2"
                        color="primary"
                        noWrap
                      >
                        {t("genericLabel.viewChart")}
                      </Typography>
                    </ClickableFlexContainer>
                  )}
                </Box>
              )}
              {thirdRowComponent && thirdRowComponent}
            </>
          ) : (
            <>
              <Typography variant="body2" noWrap>
                {value}
              </Typography>
              {secondRowValue && (
                <Box display="flex">
                  <Typography
                    align="left"
                    variant="subtitle2"
                    color="textSecondary"
                    noWrap
                  >
                    {secondRowValue}
                  </Typography>
                  {expandable && <Divider />}
                  {expandable && row && toggleAllRowsExpanded && (
                    <ClickableFlexContainer
                      onClick={() => handleClick(row, toggleAllRowsExpanded)}
                    >
                      <StyledExpandIcon />
                      <Typography
                        align="left"
                        variant="subtitle2"
                        color="primary"
                        noWrap
                      >
                        {t("genericLabel.viewChart")}
                      </Typography>
                    </ClickableFlexContainer>
                  )}
                </Box>
              )}
              {thirdRowComponent && thirdRowComponent}
            </>
          )}
        </Tooltip>
      </NoWrapGrid>
    </GridWrapper>
  );
};

interface LinkAndImageCellProps {
  cell: {
    value: {
      value: string;
      secondRowValue?: string;
      image: string;
      link: string;
      icon?: ReactChild;
      target?: string;
    };
  };
  colorVariant?: "internal" | "external";
  maxWidth?: string;
  row?: any; // used for React table's expanding row feature
  toggleAllRowsExpanded?: (a: boolean) => void;
  expandable?: boolean;
  // Note: If you use a thirdRowComponent, you need to ensure the {td: height: ??px;} rule on the enclosing table is sufficient
  // For example, the default 45px in the adTable is too small for a thirdRowComponent of StarRatingCell
  // If you are using adTable, you can pass a tdHeight prop in.
  thirdRowComponent?: ReactChild;
  width?: string;
  noWrapCell?: boolean;
  noWrapFlexGrow?: string;
}

export const LinkAndImageCell = memo<LinkAndImageCellProps>(
  function LinkAndImageCell({
    cell,
    colorVariant,
    maxWidth,
    width,
    noWrapFlexGrow,
    row,
    expandable,
    toggleAllRowsExpanded,
    thirdRowComponent,
    noWrapCell = true,
  }) {
    if (!cell.value) {
      return null;
    }
    const value = cell.value.value;
    const secondRowValue = cell.value.secondRowValue;
    const image = cell.value.image;
    const link = cell.value.link;
    const icon = cell.value.icon;
    const target = cell.value.target || "_self";
    const maxColumnWidth = maxWidth;

    return (
      <LinkAndImageComponent
        maxColumnWidth={maxColumnWidth}
        image={image}
        icon={icon}
        value={value}
        link={link}
        target={target}
        colorVariant={colorVariant}
        secondRowValue={secondRowValue}
        expandable={expandable}
        row={row}
        toggleAllRowsExpanded={toggleAllRowsExpanded}
        thirdRowComponent={thirdRowComponent}
        width={width}
        noWrapFlexGrow={noWrapFlexGrow}
        noWrapCell={noWrapCell}
      />
    );
  }
);
