import { Box, FormControlLabel, Switch } from "@material-ui/core";
import React, { memo, useState } from "react";

import Heatmap from "../../../components/charts/heatmap/heatmap";
import { Panel } from "../../../components/panel/panel.tsx";
import get from "lodash/get";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DISPLAY_TYPE = {
  percentage: false,
  sales: true,
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const SalesByDay = memo(() => {
  const { t } = useTranslation();
  const [displayType, setDisplayType] = useState(DISPLAY_TYPE.percentage);
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );

  return (
    <Panel
      id="widget-average-sales-per-day"
      title={t("dashboardWidget.averageSales.mainTitle")}
      tooltip={t("dashboardWidget.averageSales.mainTooltip")}
      actions={
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={displayType}
              onChange={() => setDisplayType(!displayType)}
            />
          }
          label={t("dashboardWidget.averageSales.showLabel", {
            label: displayType ? "%" : getCurrencySymbol[currentCurrency],
          })}
        />
      }
      content={
        <Box p={2} overflow="hidden" height="100%">
          <Heatmap displayType={getKeyByValue(DISPLAY_TYPE, displayType)} />
        </Box>
      }
    />
  );
});

export default SalesByDay;
