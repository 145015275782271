import { Grid, Link, Paper, Theme, Typography } from "@material-ui/core";

import React from "react";
import { User } from "~/typedef/user";
import { isUndefined } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core";

const FooterBase = styled(Paper)`
  padding: 1rem;
  color: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.main
      : theme.palette.secondary.main};
`;

interface FooterProps {
  user?: User;
}
const Footer = ({ user }: FooterProps) => {
  const { t } = useTranslation();
  const now = new Date();
  const isExternalUser = user?.role === "external";
  return (
    <FooterBase id="app-footer" elevation={0} square>
      <Grid
        container
        justifyContent={isExternalUser ? "center" : "space-between"}
        alignItems="center"
      >
        {Boolean(user) ? (
          isExternalUser ? (
            <Typography variant="subtitle1" align="center" color="inherit">
              Build&nbsp;
              {(
                process.env.CODEBUILD_RESOLVED_SOURCE_VERSION || "local"
              ).substring(0, 7)}
              .
            </Typography>
          ) : (
            <Grid item>
              <Typography variant="subtitle1" align="center" color="inherit">
                {!isExternalUser &&
                  `© Copyright, MerchantSpring ${now.getFullYear()}. All Rights
            Reserved.`}{" "}
                Build&nbsp;
                {(
                  process.env.CODEBUILD_RESOLVED_SOURCE_VERSION || "local"
                ).substring(0, 7)}
                .
              </Typography>
            </Grid>
          )
        ) : null}
        {!isExternalUser && (
          <>
            <Grid item>
              <Typography variant="subtitle1" align="center" color="inherit">
                <Link
                  color="inherit"
                  href={t("generic.termsConditionsLinkUrl")}
                  target="_blank"
                >
                  {t("generic.termsConditionsLink")}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center" color="inherit">
                <Link
                  color="inherit"
                  href={t("generic.policyLinkUrl")}
                  target="_blank"
                >
                  {t("generic.policyLink")}
                </Link>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </FooterBase>
  );
};

export default Footer;
