import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import {
  IPieChartData,
  ShippedUnitsSummaryLegendItem,
} from "../vendor/shippedUnitsSummary";
import React, { memo } from "react";

import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import PieChart from "~/components/charts/pieChart/pieChart";
import { Range } from "~/typedef/store";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTrafficAndConversionNormalVsB2BSummaryQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TrafficAndConversionNormalVsB2BTotalsProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  currentCurrency: string;
  report?: boolean;
}

const TrafficAndConversionNormalVsB2BTotals =
  memo<TrafficAndConversionNormalVsB2BTotalsProps>(
    function TrafficAndConversionNormalVsB2BTotals({
      mid,
      marketplaceType,
      marketplaceSubtype,
      currentRange,
      currentCurrency,
      report,
    }) {
      const { t } = useTranslation();

      const { normalVsB2BSummary, normalVsB2BSummaryCurrency, loading } =
        useTrafficAndConversionNormalVsB2BSummaryQuery(
          {
            mid,
            marketplaceFilters: {
              marketplaceType,
              marketplaceSubtype,
            },
            currentRange,
          },
          {
            selectFromResult: ({ data, isFetching }) => ({
              normalVsB2BSummary: data?.data || {
                orderedUnits: 0,
                sales: 0,
                otherOrderedUnits: 0,
                otherSales: 0,
                orderedUnitsTotal: 0,
                salesTotal: 0,
              },
              normalVsB2BSummaryCurrency: data?.currency || "",
              loading: isFetching,
            }),
          }
        );

      const currencyRates = useTypedSelector(
        (state) => state?.globalVar?.currencyRates
      );

      const theme = useTheme();

      const {
        orderedUnits,
        sales,
        otherOrderedUnits,
        otherSales,
        orderedUnitsTotal,
        salesTotal,
      } = normalVsB2BSummary;
      const pieChartData: IPieChartData[] = [
        {
          name: t("trafficAndConversion.b2cSales"),
          value: orderedUnits,
          color: theme.palette.secondary.main,
          type: "normalSales",
          cost: sales,
        },
        {
          name: t("trafficAndConversion.b2bSales"),
          value: otherOrderedUnits ?? 0,
          color: theme.palette.primary.main,
          type: "b2bSales",
          cost: otherSales ?? 0,
        },
      ];
      pieChartData.forEach((pieData: IPieChartData) => {
        const piePercentage = Math.round(
          (pieData.value / orderedUnitsTotal) * 100
        );
        pieData.tooltipContent = `${pieData.name}: ${numberWithCommas(
          pieData.value
        )} (${piePercentage}%)`;
        pieData.legendContent = (
          <ShippedUnitsSummaryLegendItem
            name={pieData.name}
            value={numberWithCommas(pieData.value)}
            piePercentage={piePercentage}
            type={pieData.type}
            cost={formatCurrencyRounded(
              pieData.cost,
              currencyRates,
              normalVsB2BSummaryCurrency,
              currentCurrency
            )}
          />
        );
      });

      return (
        <Panel
          id="widget-mobile-vs-browser-totals"
          title={t("myStoresWidget.salesMixByCustomer")}
          tooltip={t("myStoresWidget.salesMixByCustomer")}
          content={
            loading || !normalVsB2BSummary ? (
              <Box
                p={2}
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <LoadingIndicator />
              </Box>
            ) : (
              <Grid container item xs={12} alignItems="center">
                <PieChart
                  {...{
                    report,
                    data: pieChartData,
                    legendHeader: (
                      <>
                        <GenericTotalMoney
                          current={salesTotal}
                          currency={normalVsB2BSummaryCurrency}
                          currentCurrency={currentCurrency}
                          title={t("trafficAndConversion.totalSales")}
                          condensed={true}
                          lockLayout={true}
                          rounded
                        />

                        <Typography variant="body1" noWrap>
                          {`${numberWithCommas(orderedUnitsTotal)} ${t(
                            "trafficAndConversion.totalUnits"
                          )}`}
                        </Typography>
                      </>
                    ),
                  }}
                />
              </Grid>
            )
          }
        />
      );
    }
  );

export default TrafficAndConversionNormalVsB2BTotals;
