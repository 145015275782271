import CLVCohortTrendChart, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/customerLtv/cohortTrendChart";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import CLVCohortPurchaseBehaviour from "~/modules/customerLtv/cohortPurchaseBehaviour";
import CLVCohortSummaryPanel from "~/modules/customerLtv/cohortSummaryPanel";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CustomerLTV = memo(function CustomerLTV() {
  const { t } = useTranslation();

  const userInfo = useTypedSelector((state) => state.user);

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );

  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );

  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );

  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );

  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store?.marketplaceCountry || ""],
    currentCurrency
  );

  const CHART_OPTIONS: ChartOption[] = [
    {
      value: CHART_TYPE.Customer,
      label: t("customerLifetimeValueChart.cohortTrendCustomers"),
    },
    {
      value: CHART_TYPE.Revenue,
      label: t("customerLifetimeValueChart.cohortTrendRevenue"),
    },
  ];
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const csvDownload = (
    <DownloadCsv
      reportType="cohortTrend"
      path="/api/generic/clvCohortTrend"
      mid={store.merchantId}
      params={{
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        currentRange,
        currency: currentCurrency,
        exchangeRate,
      }}
    />
  );

  const chartActions = (
    <>
      <TableFilter
        width="125px"
        options={CHART_OPTIONS}
        currentValue={chartType.label}
        handleChange={switchChartType}
      />
      {csvDownload}
    </>
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={8}>
          <CLVCohortTrendChart
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            timezone={selectedTimezone}
            chartType={chartType}
            actions={chartActions}
          />
        </Grid>
        <Grid container item xs={12} md={4}>
          <CLVCohortSummaryPanel
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentCompare={currentCompare}
            currentRange={currentRange}
          />
        </Grid>
        <Grid container item xs={12}>
          <CLVCohortPurchaseBehaviour
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            timezone={selectedTimezone}
            actions={csvDownload}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default CustomerLTV;
