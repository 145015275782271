import { Grid, Link } from "@material-ui/core";
import React, { memo } from "react";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledAssignmentIcon = styled(AssignmentOutlinedIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 19px;
  height: 19px;
  padding-top: 3px;
`;

const ProductLinkCell = memo(({ cell }) => {
  const { handleListingSelect } = cell.column.getProps();

  return (
    <Grid container justifyContent="flex-end">
      <Link
        onClick={() => handleListingSelect(cell.row.original)}
        variant="body2"
      >
        <StyledAssignmentIcon />
      </Link>
    </Grid>
  );
});

ProductLinkCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    column: PropTypes.any,
  }).isRequired,
};

export default ProductLinkCell;
