import { CircularProgress, Grid, Hidden, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import PageBlock from "~/components/containers/pageBlock";
import React from "react";
import SubscriptionPlans from "~/modules/subscription/plan/subscriptionPlans";
import { fetchPlans } from "~/store/subscriptions.redux";
import get from "lodash/get";
import size from "lodash/size";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SubscriptionPageBlock = styled(PageBlock)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const MobileSectionHeading = styled(Typography).attrs(() => ({
  variant: "h3",
  color: "textSecondary",
}))`
  padding-top: 1rem;
  text-align: center;
`;

const DesktopSectionHeading = styled(Typography).attrs(() => ({
  variant: "h2",
  component: "div",
}))`
  text-align: center;
  line-height: 30px;
`;

const NewUserSubscriptionPage = () => {
  const { t } = useTranslation();
  const loading = useSelector((state) => get(state, "subscriptions.loading"));
  const plans = useSelector((state) => get(state, "subscriptions.plans"));

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  return loading || size(plans) === 0 ? (
    <CircularProgress />
  ) : (
    <SubscriptionPageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Hidden smDown>
            <DesktopSectionHeading>
              {t("subscription.plan.sellSmarterTitle")}
            </DesktopSectionHeading>
          </Hidden>
          <Hidden mdUp>
            <MobileSectionHeading>
              {t("subscription.plan.getStartedTitle")}
            </MobileSectionHeading>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <SubscriptionPlans
            newUser={true}
            plans={plans}
            title={t("subscription.plan.getStartedTitle")}
          />
        </Grid>
      </Grid>
    </SubscriptionPageBlock>
  );
};

export default NewUserSubscriptionPage;
