import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface AdAccount {
  id: string;
  name: string;
  currencyCode: string;
}

interface ValidateGoogleAdvertisingRequest {
  authCode: string;
}

export interface ValidateGoogleAdvertisingResponse {
  errMsg?: string;
  code?: number;
  adAccounts: AdAccount[] | null;
}

interface UpdateGoogleAdAccountRequest {
  adAccountId: AdAccount["id"];
  countryCode: string;
}

export interface UpdateGoogleAdAccountResponse {
  errMsg?: string;
  code?: number;
  store?: {
    marketplaceType: string;
    marketplaceSubtype: string;
    mid: string;
    countryCode: string;
    shopName: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    validateGoogleAdvertisingAuth: build.mutation<
      ValidateGoogleAdvertisingResponse,
      ValidateGoogleAdvertisingRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/google/advertising/connect`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "Google Advertising connect",
        true
      ),
    }),
    updateGoogleAdAccount: build.mutation<
      UpdateGoogleAdAccountResponse,
      UpdateGoogleAdAccountRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/google/advertising/connect/profile`,
          method: "POST",
          data: params,
        };
      },
    }),
  }),
});

export const {
  useValidateGoogleAdvertisingAuthMutation,
  useUpdateGoogleAdAccountMutation,
} = extendedApiSlice;
