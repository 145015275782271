const { decode: htmlDecode } = require("he");
const memoize = require("memoizee");
const get = require("lodash/get");

const RESPONSE_CODE = {
  SUCCESS: 0,
  REFRESH_CONNECTION_ERROR: -1,
  INVALID_TOKEN: -2,
  GENERAL_ERROR: -3,
};

const isHttpResponseValid = (statusCode) =>
  // eslint-disable-next-line no-magic-numbers
  statusCode >= 200 && statusCode < 400;

const stripHtmlTags = (string) => {
  return htmlDecode(string.replace(/<[^>]*>/g, ""));
};

// C strcmp
const strcmp = (a, b) => {
  return a < b ? -1 : +(a > b); // abusing the unary '+' to get a number
};

const mergeSameMids = (arr) => {
  let afterMergeArr = arr.reduce(function (o, cur) {
    // Get the index of the key-value pair.
    var occurs = o.reduce(function (n, item, i) {
      return item.mid === cur.mid ? i : n;
    }, -1);

    // If the name is found,
    if (occurs >= 0) {
      // append the current value to its list of values.
      o[occurs].customerIds = cur.customerIds.concat(o[occurs].customerIds);
      o[occurs].mid = cur.mid;
      o[occurs].midInfo = cur.midInfo;
      // Otherwise,
    } else {
      // add the current item to o (but make sure the value is an array).
      var obj = {
        customerIds: cur.customerIds,
        mid: cur.mid,
        midInfo: cur.midInfo,
      };
      o = o.concat(obj);
    }

    return o;
  }, []);

  return afterMergeArr;
};

const mergeSameMidsMemo = memoize(mergeSameMids, {
  promise: true,
  maxAge: 3600000,
  max: 1000,
}); // 10 minutes

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// Use in function declarations to force a parameter to be passed
// e.g. Usage:
// function doFoo(foo, bar = requiredParam('bar')) {}
// -> doFoo(1, 2) // OK
// -> doFoo(1) // Error: bar is a required parameter
const requiredParam = (paramName) => {
  throw new Error(`${paramName} is a required parameter`);
};

// Return true iff we're running in a browser
const inBrowser = () => {
  return typeof window !== "undefined";
};

// Get formatted category.
const getCategoryName = (market, categoryData) => {
  let category = null;
  switch (market) {
    case "ebay":
      category =
        categoryData &&
        (categoryData.categoryLabel || categoryData.categoryName);
      break;
    case "catch":
      category = get(categoryData, "category", null);
      break;
    case "amazon":
      category = get(categoryData, "categoryLabel", null);
      break;
    case "iconic":
      const mainCategory = categoryData.mainCategory || "";
      category = mainCategory
        .concat(">")
        .concat(get(categoryData, "category", null));
      break;
    case "shopify":
      category = get(categoryData, "category", null);
      break;
  }
  let firstCategory = "";
  let secondCategory = "";
  let categoryArr = [];

  if (!category) {
    return { styleCode: 1, categoryLabel: "" };
  }

  if (market === "ebay") {
    categoryArr = category.split(":");
  } else {
    categoryArr = category.split(">");
  }

  if (categoryArr.length < 2) {
    return { styleCode: 1, categoryLabel: category };
  } else {
    firstCategory = categoryArr[categoryArr.length - 2].trim();
    secondCategory = categoryArr[categoryArr.length - 1].trim();
    return { styleCode: 2, firstCategory, secondCategory };
  }
};

// Given an instance of a user and a string representing a merchant ID,
// return a string representing the "home" URL of the user.
// Used to redirect particular types of users to different screens.
// @param {Object} user - a plain object representing an instance of a user
// @param {string} mid - a "merchant" or "store" ID
// @return {string} - the URL of the user's "home" page within the application
const getHomePath = (user, mid) => {
  if (!user) {
    return "/";
  }
  const userType = get(user, "userType");
  const market = get(userType, "market", "");
  const interest = get(userType, "interest", "");
  let path = "/overview";

  // default to the overview page
  if (!market || !interest) {
    return path;
  }

  if (market === "ebay" && interest === "itemSpecifics") {
    // For eBay users, if no merchant ID is specified, return a path corresponding
    // to the first store that they connected.
    if (mid) {
      path = `/mystores/ebay/itemspecifics?store=${mid}`;
    } else {
      const firstMid = get(user, "ebayAcc[0].mid", "");
      if (firstMid) {
        path = `/mystores/ebay/itemspecifics?store=${firstMid}`;
      }
    }
  }
  return path;
};

const isPasswordValid = (password) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[0-9])[^-\s]{6,20}$/;
  return reg.test(password);
};

const isEmailValid = (email) => {
  const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  return reg.test(email);
};

module.exports = {
  inBrowser,
  isHttpResponseValid,
  getHomePath,
  mergeSameMidsMemo,
  requiredParam,
  sleep,
  strcmp,
  stripHtmlTags,
  getCategoryName,
  RESPONSE_CODE,
  isPasswordValid,
  isEmailValid,
};
