import React, { useEffect } from "react";

import CriteoAdvertisingProfilePage from "~/pages/marketplaceConnectors/criteoAdvertisingProfilePage";
import GoogleAdvertisingProfilePage from "~/pages/marketplaceConnectors/googleAdvertisingProfilePage";
import StandaloneSelectProfile from "~/pages/marketplaceConnectors/standaloneSelectProfile";
import TikTokAdvertisingProfilePage from "~/pages/marketplaceConnectors/tikTokAdvertisingProfilePage";

type StandaloneAdvertisingProfilePageProps = {
  marketplace: string;
  countryCode?: string;
  selectProfile: (
    mid: string,
    profileId?: string,
    entityId?: string,
    countryCode?: string
  ) => void;
  onSuccess: () => void;
};

const StandaloneAdvertisingProfilePage = ({
  marketplace,
  countryCode,
  selectProfile,
  onSuccess,
}: StandaloneAdvertisingProfilePageProps) => {
  switch (marketplace) {
    case "amazon":
    case "amazon_vendor":
    case "dsp":
      return countryCode ? (
        <StandaloneSelectProfile
          marketplaceType={marketplace}
          countryCode={countryCode}
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      ) : (
        <></>
      );
    case "criteo":
      return (
        <CriteoAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    case "tiktok":
      return (
        <TikTokAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    case "google":
      return (
        <GoogleAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    default:
      return <DummyProfilePage onSuccess={onSuccess} />;
  }
};

type DummyProfilePageProps = {
  onSuccess: () => void;
};
const DummyProfilePage = ({ onSuccess }: DummyProfilePageProps) => {
  useEffect(() => {
    onSuccess();
  }, []);
  return <></>;
};

export default StandaloneAdvertisingProfilePage;
