import React, { memo } from "react";
import { useMarketplace } from "~/utils/navigationUtils";
import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import EbayListingQuality from "./ebayListingQuality";
import WalmartListingQuality from "./walmartListingQuality";
import { Subset } from "~/utils/typeUtils";
import { ListingQualityMarketplaces } from "~/components/toolbars/sideNavigation/sideNavConstants";

type ListingQualityMarketplace = Subset<
  Marketplace,
  typeof ListingQualityMarketplaces[number]
>;

function getComponentsForMarketplace(
  marketplace: ListingQualityMarketplace
): React.ReactNode {
  switch (marketplace) {
    case "ebay":
      return <EbayListingQuality />;
    case "walmart":
      return <WalmartListingQuality />;
    default:
      const _exhaustiveCheck: never = marketplace;
      return _exhaustiveCheck;
  }
}

const ListingQuality = memo(() => {
  const marketplace = useMarketplace<ListingQualityMarketplace>();

  return (
    <PageBlock>
      <Grid container spacing={3}>
        {getComponentsForMarketplace(marketplace)}
      </Grid>
    </PageBlock>
  );
});

export default ListingQuality;
