import { DayCellData, FORECAST_TYPE, REFERENCE_LEVEL } from "./types";
import React, { useCallback } from "react";

import Bold from "../typography/bold";
import { EditableChip } from "./editableChip";
import { HoverCell } from "./hoverCell";
import { Moment } from "moment";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const CellGrid = styled.div<{ $disabled?: boolean }>`
  min-height: 50px;
  width: 90%;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.palette.border.light : theme.palette.background.default};
  padding: 0.25rem 0.25rem 0.75rem 0.75rem;
`;

const DatePart = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
`;

const ContentsPart = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

type DayCellProps = {
  date: Moment;
  printDay: boolean;
  printMonth: boolean;
  editView: boolean;
  data?: DayCellData;
  onEdit?: (
    item: {
      type: string;
      amount: number;
      referenceLevel: string;
    },
    date: Moment
  ) => void;
  disabled?: boolean;
  editable: boolean;
  setEditable: (editable: boolean) => void;
  setSupportDialogOpen: (supportDialogOpen: boolean) => void;
  colors: Record<FORECAST_TYPE, string>;
};

export const DayCell: React.FC<DayCellProps> = ({
  date,
  printDay,
  printMonth,
  editable,
  editView,
  data,
  onEdit,
  disabled,
  setEditable,
  colors,
  setSupportDialogOpen,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const renderEditableChips = useCallback(() => {
    if (!data) return null;
    const typeEntries = Object.entries(data);
    return typeEntries.map(([type, typeItem]) => {
      const levelEntries = Object.entries(typeItem);
      return levelEntries.map(([referenceLevel, item]) => (
        <EditableChip
          key={`${type}-${referenceLevel}`}
          item={item}
          type={type as FORECAST_TYPE}
          level={referenceLevel as REFERENCE_LEVEL}
          onEdit={onEdit}
          date={date}
          editable={editable}
          setEditable={setEditable}
          setSupportDialogOpen={setSupportDialogOpen}
          colors={colors}
        />
      ));
    });
  }, [data]);

  return (
    <CellGrid
      data-testid={disabled ? "disabled-day-cell" : "day-cell"}
      $disabled={disabled || isHovered}
      key={date.format("YYYYMMDD")}
    >
      <DatePart>
        {printMonth ? (
          <Bold variant="body2" color="textSecondary">
            {date.format("D MMM")}
          </Bold>
        ) : printDay ? (
          <Typography variant="body2" color="textSecondary">
            {date.format("D")}
          </Typography>
        ) : null}
      </DatePart>
      {!disabled && data && (
        <ContentsPart data-testid="contents">
          {editView ? (
            renderEditableChips()
          ) : (
            <HoverCell
              items={data}
              colors={colors}
              setIsHovered={setIsHovered}
            />
          )}
        </ContentsPart>
      )}
    </CellGrid>
  );
};
