import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import React, { Suspense, useEffect } from "react";
import { persistor, store } from "@store/configureStore";

import { ApplicationToast } from "./modules/toast";
import { Box } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import GA from "./pages/staticContent/googleAnalytics";
import { Helmet } from "react-helmet";
import LoadingPage from "@modules/loadingPage";
import LoginContext from "./modules/login/loginContext";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "./components/scrollTop";
import { ThemeProvider } from "styled-components";
import cookies from "browser-cookies";
import { fetchCustomOverridesConfig } from "./store/customOverrides.redux";
import { getPreferredColorScheme } from "~/utils/browserUtils";
import { renderRoutes } from "./routes/renderRoutes";
import { useCustomFavicon } from "./hooks/useCustomLogo";
import { useCustomTheme } from "./hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import { useTitleOverride } from "./hooks/useTitleOverride";
import { useTypedSelector } from "./hooks/useTypedSelector";

export const App = () => {
  return (
    <PersistGate
      persistor={persistor}
      loading={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <LoadingPage />
        </Box>
      }
    >
      <BrowserRouter>
        <ThemeApplication />
      </BrowserRouter>
    </PersistGate>
  );
};

const ThemeApplication = () => {
  const user = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();
  const originalDomain = cookies.get("originalDomain");
  const authSource = cookies.get("authSource");
  const colorScheme = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.colorScheme ||
      getPreferredColorScheme()
  );

  const theme = useCustomTheme();

  const fetchOverrides = (originalDomain: string | null) => {
    dispatch(
      fetchCustomOverridesConfig({ originalDomain, authSource, colorScheme })
    );
  };

  useEffect(() => {
    if (user?._id || originalDomain) {
      fetchOverrides(originalDomain);
    }
  }, [user, originalDomain]);

  return (
    <>
      <ScrollToTop />
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <Helmet defaultTitle={useTitleOverride()}>
              {/* Google Optimizer Anti-flicker snippet */}
              {/* https://support.google.com/optimize/answer/7100284 */}
              {/* <style>{`.async-hide { opacity: 0 !important}`}</style>
    <script>
      {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})(window,document.documentElement,'async-hide','dataLayer',4000,{'${GOOGLE_TAG_MANAGER_CONTAINER_ID}':true});`}
    </script> */}

              {/* Intercom Script - APP_ID is t6uqtrtt */}
              {/* https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app */}
              <script>
                {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/t6uqtrtt';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
              </script>

              {/* FirstPromoter Script */}
              {/* https://merchantspring.firstpromoter.com/setup_instructions?a=56ds6vdb */}
              <script>
                {`(function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("56ds6vdb",".merchantspring.io")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();`}
              </script>

              {/* ProfitWell Script */}
              {/* https://learn.profitwell.com/article/s3ew8syaas */}
              <script
                id="profitwell-js"
                data-pw-auth="ec11bfc57fd023c3349ed97890434d10"
              >
                {`(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
                a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
                s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
                })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`}
              </script>
              <link
                rel="shortcut icon"
                type="image/png"
                href={useCustomFavicon()}
              />
            </Helmet>
            <ApplicationToast />
            <LoginContext>
              {/* <Auth /> */}
              {GA.init() && <GA.RouteTracker />}
              <Suspense fallback={<LoadingPage />}>
                {renderRoutes(store)}
              </Suspense>
            </LoginContext>
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
};
