import { Typography, alpha } from "@material-ui/core";

import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const InfoIcon = styled(InfoOutlined)`
  height: 24px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const MessageBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  background-color: ${({ theme }) =>
    alpha(theme.palette.secondary.light, OPACITY)};
`;

const WarningBox = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  border: ${({ theme }) => `1px solid ${theme.palette.warning.main}`};
  background-color: ${({ theme }) =>
    alpha(theme.palette.warning.light, OPACITY)};
`;

const OPACITY = 0.3;

interface PortfolioConnectInfoProps {
  portfolioNames?: string[];
}
const PortfolioConnectInfo = ({
  portfolioNames,
}: PortfolioConnectInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MessageBox>
        <InfoIcon />
        <Typography variant="body2">
          {t("connection.portfolioConnectInfo")}
        </Typography>
      </MessageBox>
      {portfolioNames?.length ? (
        <WarningBox>
          <Typography variant="body2">
            {t("connection.existingPortfolio", {
              portfolioNames: portfolioNames.join(", "),
            })}
          </Typography>
        </WarningBox>
      ) : null}
    </>
  );
};

export default PortfolioConnectInfo;
