import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CheckCircleOutline, PriorityHighRounded } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  hasFilteredSuffix,
  marketplaceLink,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import AmazonAuthoriseAdvertising from "~/modules/login/amazonAuthoriseAdvertising";
import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import BrandFilterAdConnectBanner from "./brandFilterAdConnectBanner";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import RaisedButton from "~/components/buttons/raisedButton";
import SiteBackground from "~/img/background_sites.png";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { loadInfo } from "~/store/user.redux";
import { regionalCountryCodes } from "mm-amazon-common/dist/mwsConstants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMarketplace } from "~/utils/navigationUtils";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PaperWrapper = styled(Paper)`
  padding-bottom: 1rem;
`;

const HeaderWrapper = styled.div`
  padding-left: 1rem;
`;

interface HasMobile {
  isMobile: boolean;
}

const ContentWrapper = styled.div<HasMobile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ isMobile }) => (isMobile ? "1rem" : "none")};
  height: 100%;
`;

const FooterWrapper = styled.div<HasMobile>`
  text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
  padding-left: 1rem;
`;

const IncludedIcon = styled(CheckCircleOutline)`
  color: ${({ theme }) => theme.palette.success.main};
`;

const SiteImage = styled.img`
  width: 40vw;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const ErrorIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error.light};
  margin-right: 1rem;
`;

const AlertIcon = styled(PriorityHighRounded)`
  font-size: 1rem;
  color: white;
`;

const NORTH_AMERICA = "NA";
const EUROPE = "EU";
const ASIA_PACIFIC = "AP";

interface MarketingAuthoriseUserParams {
  condensed?: boolean;
  isReconnect?: boolean;
  isStandalone?: boolean;
  isVendorNewConnection?: boolean;
  selectedCountry?: string; // used only for new vendor connections
}

const MarketingAuthoriseUser = ({
  condensed,
  isReconnect,
  isStandalone,
  isVendorNewConnection = false,
  selectedCountry,
}: MarketingAuthoriseUserParams) => {
  const [region, setRegion] = useState("");
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const store =
    isVendorNewConnection || isStandalone
      ? useTypedSelector((state) => state.amazonNewStore)
      : useTypedSelector((state: StoreState) =>
          get(state, "persistentAppSettings.setting.data.currentStore")
        );
  const countryCode =
    isVendorNewConnection || isStandalone
      ? selectedCountry
      : get(store, "marketplaceCountry");
  const marketplace = useMarketplace();
  const errMsg = useRef("");
  const theme = useTheme();
  const onMobile = condensed || useMediaQuery(theme.breakpoints.down("xs"));
  const urlSearchParams = useQueryParams();
  const mid = isVendorNewConnection
    ? store.newMid
    : urlSearchParams.get("store");
  const { t } = useTranslation();
  const history = useHistory();

  // Amazon Vendor stores do not have Seller IDs, so passing null into
  // AmazonAuthoriseAdvertising is the expected behaviour
  const sellerId =
    marketplace === "amazon" ? get(store, "sourceSystemId") : null;

  const getRegion = (countryCode: AmazonCountry) => {
    if (regionalCountryCodes[NORTH_AMERICA].includes(countryCode)) {
      return NORTH_AMERICA;
    }

    if (regionalCountryCodes[EUROPE].includes(countryCode)) {
      return EUROPE;
    }
    if (regionalCountryCodes[ASIA_PACIFIC].includes(countryCode)) {
      return ASIA_PACIFIC;
    }

    return NORTH_AMERICA;
  };

  useEffect(() => {
    setRegion(getRegion(countryCode));
  }, [store]);

  // Checking if the marketplace is not Amazon, Amazon Vendor, Walmart, or a new vendor connection
  if (
    marketplace !== "amazon" &&
    marketplace !== "amazon_vendor" &&
    marketplace !== "walmart" &&
    !isVendorNewConnection
  ) {
    return null;
  }

  return (
    <>
      <PaperWrapper {...{ xs: 12 }}>
        <Grid {...{ container: true, display: "flex", spacing: 0 }}>
          {!onMobile && (
            <Grid item xs={onMobile ? 12 : 6}>
              <SiteImage src={SiteBackground} />
            </Grid>
          )}

          <Grid item xs={onMobile ? 12 : 6}>
            <ContentWrapper isMobile={onMobile}>
              {isReconnect ? (
                <>
                  <HeaderWrapper>
                    <Typography variant="h3">
                      {t("advertisingConnectLabel.genericHeaderReconnect")}
                    </Typography>
                  </HeaderWrapper>
                  <List>
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <Box display="flex" alignContent="center">
                          <ErrorIcon>
                            <AlertIcon />
                          </ErrorIcon>
                        </Box>
                      </ListItemIcon>
                      <Typography variant="h6">
                        {t("advertisingConnectLabel.bulletAReconnect")}
                      </Typography>
                    </ListItem>
                  </List>
                </>
              ) : (
                <>
                  <HeaderWrapper>
                    <Typography variant="h3">
                      {t("advertisingConnectLabel.genericHeader")}
                    </Typography>
                  </HeaderWrapper>
                  <List>
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <Box display="flex" alignContent="center">
                          <IncludedIcon />
                        </Box>
                      </ListItemIcon>
                      <Typography variant="h6">
                        {t("advertisingConnectLabel.bulletA")}
                      </Typography>
                    </ListItem>
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <Box display="flex" alignContent="center">
                          <IncludedIcon />
                        </Box>
                      </ListItemIcon>
                      <Typography variant="h6">
                        {t("advertisingConnectLabel.bulletB")}
                      </Typography>
                    </ListItem>
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <Box display="flex" alignContent="center">
                          <IncludedIcon />
                        </Box>
                      </ListItemIcon>
                      <Typography variant="h6">
                        {t("advertisingConnectLabel.bulletC")}
                      </Typography>
                    </ListItem>
                  </List>
                </>
              )}
              <FooterWrapper isMobile={onMobile}>
                {user.isDemoMode ? (
                  <DemoTooltip
                    {...{
                      arrow: true,
                      placement: "bottom",
                      open: true,
                      title: t<string>("generic.notAvailableDemoMode"),
                    }}
                  >
                    <span>
                      <RaisedButton id="amazon-advertising-link">
                        {t("advertisingConnectLabel.connectButton")}
                      </RaisedButton>
                    </span>
                  </DemoTooltip>
                ) : (
                  <RaisedButton
                    href={`/connectStore/select?connectionType=adStore&store=${encodeURIComponent(
                      mid
                    )}`}
                    color="primary"
                  >
                    Connect
                  </RaisedButton>
                )}
                {errMsg && errMsg.current && (
                  <Grid container item xs={12}>
                    <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
                  </Grid>
                )}
              </FooterWrapper>
            </ContentWrapper>
          </Grid>
        </Grid>
      </PaperWrapper>
    </>
  );
};

const Wrapper = (props: MarketingAuthoriseUserParams) => {
  const marketplace = useMarketplace();

  if (hasFilteredSuffix(marketplace)) {
    return <BrandFilterAdConnectBanner {...props} />;
  } else {
    return <MarketingAuthoriseUser {...props} />;
  }
};

export default Wrapper;
