import { Box, Theme, Typography } from "@material-ui/core";

import AuthPageBlock from "~/components/containers/authPageBlock";
import { LoginProviders } from "../../modules/login/loginProviders";
import React from "react";
import Register from "@modules/login/register";
import { SignInPaper } from "./signinPage";
import SimpleToolbar from "@components/toolbars/simpleToolbar";
import { StoreState } from "@typedef/store";
import get from "lodash/get";
import { getHomePath } from "@merchantspring/common";
import { handleLoginSucceed } from "../../modules/login/login";
import isEmpty from "lodash/isEmpty";
import { updateMarketingInfo } from "@store/user.redux";
import { useAgencySpecificConfig } from "~/hooks/useAgencySpecificConfig";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import useQueryParams from "../../hooks/useQueryParams";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

function RegistrationPage() {
  const { t } = useTranslation();
  const user = useTypedSelector((state: StoreState) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasErrored, setHasErrored] = React.useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = React.useState(false);
  const destinationRoute = useTypedSelector((state: StoreState) => {
    if (state.globalVar?.destinationRoute) {
      if (
        !["/", "/signin", "/signup"].includes(state.globalVar.destinationRoute)
      ) {
        return state.globalVar.destinationRoute;
      } else {
        return getHomePath(state.user);
      }
    }
    return getHomePath(state.user);
  });
  const queryParams = useQueryParams();
  const emailInitValue = queryParams.get("email");
  const errMsgInitValue = queryParams.get("errMsg");

  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { hideLoginProviders } = useAgencySpecificConfig();

  const marketingInfo = {
    marketingFunnel: document.referrer,
    utmSource: queryParams.get("utm_source"),
    utmCampaign: queryParams.get("utm_campaign"),
    utmContent: queryParams.get("utm_content"),
    utmMedium: queryParams.get("utm_medium"),
    utmTerm: queryParams.get("utm_term"),
    gclid: queryParams.get("gclid"),
    partnerCode: queryParams.get("partner_code"),
  };

  React.useEffect(() => {
    if (isLoginSuccessful && user?._id) {
      handleLoginSucceed(user, history, destinationRoute, dispatch, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccessful, user, history, destinationRoute]);

  const onRegistrationSuccess = () => {
    setHasErrored(false);
    dispatch(updateMarketingInfo(marketingInfo));
    setIsLoginSuccessful(true);
  };

  return (
    <AuthPageBlock>
      <SimpleToolbar fullWidth />
      <Box
        minHeight="calc(100vh - 300px)"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SignInPaper elevation={2}>
          <Box
            pt={2}
            pl={isMobile ? 2 : 8}
            pb={8}
            pr={isMobile ? 2 : 8}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box pt={8} pb={2}>
              <Typography
                variant="h1"
                display="inline"
                color="textSecondary"
                noWrap
              >
                {t("register.signupTitle")}
              </Typography>
            </Box>
            {isEmpty(emailInitValue) && !hideLoginProviders && (
              <LoginProviders
                variant="register"
                onSuccess={() => onRegistrationSuccess()}
                onError={() => setHasErrored(true)}
              />
            )}
            <Register
              onSuccess={() => onRegistrationSuccess()}
              onError={() => setHasErrored(true)}
              emailInitValue={emailInitValue}
              errMsgInitValue={errMsgInitValue}
            />
            {hasErrored && (
              <Typography color="error">
                {get(user, "error.errMsg", t("generic.generalError"))}
              </Typography>
            )}
          </Box>
        </SignInPaper>
      </Box>
    </AuthPageBlock>
  );
}

export default RegistrationPage;
