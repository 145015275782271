import { Box, Typography } from "@material-ui/core";
import {
  IssueDTO,
  fetchSuppressedProductDetail,
} from "~/store/mystore/suppressedProducts.redux";
import {
  IssueGroup,
  IssueGroups,
  amazonFieldNameToIssueGroup,
} from "mm-utils-frontend";
import React, { useEffect } from "react";

import Bold from "~/components/typography/bold";
import InfoIcon from "@material-ui/icons/Info";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { SuppressedProductsMarketplace } from "~/pages/singleChannel/marketplaceListings/suppressedProducts";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledInfoIcon = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-bottom: -6px;
`;

const IssuesContainer = styled("div")`
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

const SubIssuesContainer = styled("div")`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

function mapFieldNameForMarketplace(
  fieldName: string,
  marketplace: SuppressedProductsMarketplace
): IssueGroup {
  switch (stripFilteredSuffix(marketplace)) {
    case "amazon":
      return amazonFieldNameToIssueGroup(fieldName);
    default:
      return "Other";
  }
}

interface Props {
  marketplace: SuppressedProductsMarketplace;
  suppressedProductId: string;
  mid: string;
}

const SuppressedProductDetails: React.FC<Props> = ({
  marketplace,
  suppressedProductId,
  mid,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchSuppressedProductDetail({ suppressedProductId, mid }));
  }, [suppressedProductId, mid]);

  const detail = useTypedSelector((state) => state.suppressedProducts.detail);

  type IssueGroupMap = Record<IssueGroup, IssueDTO[]>;
  const groupedIssues: IssueGroupMap = detail.fetched
    ? detail.rows.reduce((acc, cur) => {
        const group = mapFieldNameForMarketplace(cur.fieldName, marketplace);
        let issues: IssueDTO[];
        if (group in acc) {
          issues = acc[group];
        } else {
          issues = [];
          acc[group] = issues;
        }
        issues.push(cur);
        return acc;
      }, {} as IssueGroupMap)
    : ({} as IssueGroupMap);

  return (
    <IssuesContainer>
      {detail.fetched ? (
        IssueGroups.map((issueGroup, i) => {
          if (issueGroup in groupedIssues) {
            return (
              <Box key={issueGroup + i}>
                <Box pb={1.5}>
                  <Typography
                    display="block"
                    variant="button"
                  >{`${issueGroup.toUpperCase()} ISSUES`}</Typography>
                </Box>
                {groupedIssues[issueGroup].map((issue, i) => {
                  return (
                    <SubIssuesContainer key={issue.issueId}>
                      <div>
                        <StyledInfoIcon color="error"></StyledInfoIcon>
                      </div>
                      <div>
                        <div>
                          <Bold display="inline">{`${t(
                            "myStoresWidget.suppressedProducts.detail.fieldName"
                          )}: `}</Bold>
                          <Typography display="inline">
                            {issue.fieldName}
                          </Typography>
                        </div>
                        <div>
                          <Bold display="inline">{`${t(
                            "myStoresWidget.suppressedProducts.detail.issue"
                          )}: `}</Bold>
                          <Typography display="inline">
                            {issue.issueType}
                          </Typography>
                        </div>
                        <div>
                          <Bold display="inline">{`${t(
                            "myStoresWidget.suppressedProducts.detail.description"
                          )}: `}</Bold>
                          <Typography display="inline">
                            {issue.issueDescription}
                          </Typography>
                        </div>
                      </div>
                    </SubIssuesContainer>
                  );
                })}
              </Box>
            );
          } else {
            return undefined;
          }
        })
      ) : (
        <LoadingIndicator />
      )}
    </IssuesContainer>
  );
};

export default SuppressedProductDetails;
