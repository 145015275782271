import Cell from "./cell";
import styled from "styled-components";

const SubcategoryCell = styled(Cell)<{
  $startAlign?: boolean;
  $heavyBorder?: boolean;
  $visible?: boolean;
}>`
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  border-bottom: ${({ $heavyBorder, theme }) =>
    $heavyBorder
      ? `2px solid ${theme.palette.border.dark}`
      : `1px solid ${theme.palette.border.main}`};
`;

export default SubcategoryCell;
