import { Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";

export const IndexCell = memo(({ cell }) => {
  const value = cell.value;
  return (
    <Grid container alignItems="flex-start">
      <Typography variant="body2">{value + 1}</Typography>
    </Grid>
  );
});

IndexCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};

export default IndexCell;
