import React, { useEffect } from "react";

import ReactGA from "react-ga";
import { ReactGgEnabled } from "~/index";
import { Route } from "react-router-dom";

interface GoogleAnalyticsProps {
  location: Location;
  options: Object;
}
const GoogleAnalytics = ({ location, options }: GoogleAnalyticsProps) => {
  const logPageChange = (pathname: string, search = "") => {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options,
    });
    ReactGA.pageview(page);
  };
  useEffect(() => {
    logPageChange(location?.pathname, location?.search);
  }, [location?.pathname, location?.search]);

  return null;
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = () => {
  if (ReactGgEnabled) {
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID as string, {
      gaOptions: { allowLinker: true },
    });
  }

  return ReactGgEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
