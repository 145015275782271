import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import VendorProfitAndLossByVendorCodes from "~/modules/widgets/vendorProfitability/profitAndLossByVendorCodes";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityVendorByVendorCodes = memo(
  function ProfitabilityVendorMonthly() {
    const currentStore = useTypedSelector(
      (state) => state.persistentAppSettings?.setting?.data?.currentStore
    );
    const { t } = useTranslation();

    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    return (
      <PageBlock>
        {currentStore ? (
          <Grid container>
            <Grid item xs={12}>
              <VendorProfitAndLossByVendorCodes
                title={t("profitability.vendorCodeProfitTitle")}
                currentCurrency={currentCurrency}
                store={currentStore}
                currentRange={currentRange}
              />
            </Grid>
          </Grid>
        ) : (
          <LoadingIndicator />
        )}
      </PageBlock>
    );
  }
);

export default ProfitabilityVendorByVendorCodes;
