import { Box, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { CardDivider } from "./personalSetting";
import FileUpload from "~/modules/account/fileUpload";
import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledPaper = styled(Paper)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const InfoIcon = styled(InfoOutlined)<{ $isFaviconInfo: boolean }>`
  height: 24px;
  margin-left: ${({ $isFaviconInfo }) => ($isFaviconInfo ? "1.9rem" : "1rem")};
`;

interface LogoUploadProps {
  title: string;
  toolTip: string;
  type: "light" | "dark" | "favicon";
  fileWidth: number;
  fileHeight: number;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}

const LogoUpload = ({
  title,
  toolTip,
  type,
  fileHeight,
  fileWidth,
  selectedFile,
  setSelectedFile,
}: LogoUploadProps) => {
  return (
    <Grid container direction="row">
      <Grid item xs={2} md={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h3" color="textPrimary">
            {title}
          </Typography>
          <Tooltip title={toolTip}>
            <InfoIcon $isFaviconInfo={type === "favicon"} />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={6} justifyContent="flex-start" alignItems="center">
        <FileUpload
          type={type}
          fileHeight={fileHeight}
          fileWidth={fileWidth}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </Grid>
    </Grid>
  );
};

const MediaUpload = () => {
  const { t } = useTranslation();
  const [selectedLightFile, setSelectedLightFile] = useState<File | null>(null);
  const [selectedDarkFile, setSelectedDarkFile] = useState<File | null>(null);
  const [selectedFaviconFile, setSelectedFaviconFile] = useState<File | null>(
    null
  );

  return (
    <StyledPaper>
      <Box
        p={2}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" color="textPrimary">
          {t("settings.media.uploadTitle")}
        </Typography>
      </Box>
      <CardDivider />
      <Box p={4}>
        <Box p={1} pt={4}>
          <LogoUpload
            title={t("settings.media.lightLogoTitle")}
            toolTip={t("settings.media.lightLogoToolTip")}
            type="light"
            fileHeight={250}
            fileWidth={1000}
            selectedFile={selectedLightFile}
            setSelectedFile={setSelectedLightFile}
          />
        </Box>
        <Box p={1}>
          <LogoUpload
            title={t("settings.media.darkLogoTitle")}
            toolTip={t("settings.media.darkLogoToolTip")}
            type="dark"
            fileHeight={250}
            fileWidth={1000}
            selectedFile={selectedDarkFile}
            setSelectedFile={setSelectedDarkFile}
          />
        </Box>
        <Box p={1}>
          <LogoUpload
            title={t("settings.media.faviconTitle")}
            toolTip={t("settings.media.faviconToolTip")}
            type="favicon"
            fileHeight={64}
            fileWidth={64}
            selectedFile={selectedFaviconFile}
            setSelectedFile={setSelectedFaviconFile}
          />
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default MediaUpload;
