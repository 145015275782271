import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Input,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  useGetChargebackMetadataQuery,
  useUploadChargebackFileMutation,
} from "~/store/mystore/vendorProfitability.redux";

import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import { ArrowDropDown } from "@material-ui/icons";
import Bold from "@components/typography/bold";
import { DateRange } from "~/typedef/date";
import ErrorButton from "~/components/buttons/errorButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ModalPanel from "~/components/panel/modalPanel";
import RaisedButton from "~/components/buttons/raisedButton";
import { marketplace as getAmazonMarketplace } from "mm-amazon-common/dist/mwsUtils";
import moment from "moment-timezone";
import { setError } from "~/store/globalToast.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Form = styled.form`
  margin: 2rem;
`;
const CellLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    text-decoration: underline;
  `}
`;

const OrderedList = styled.ol`
  margin: revert;
  padding: revert;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.25;
`;

function generateChargebacksUrl(countryCode: AmazonCountry) {
  try {
    // fetch chargebacks for last 2 years
    const fromDateMoment = moment().subtract(2, "years").add(1, "day");
    const toDateMoment = moment();

    const fromDate = `fromDate_m=${fromDateMoment.format(
      "M"
    )}&fromDate_d=${fromDateMoment.format(
      "D"
    )}&fromDate_y=${fromDateMoment.format("YYYY")}`;

    const toDate = `toDate_m=${toDateMoment.format(
      "M"
    )}&toDate_d=${toDateMoment.format("D")}&toDate_y=${toDateMoment.format(
      "YYYY"
    )}`;
    return `https://${
      getAmazonMarketplace(countryCode)?.vendorHost ?? ""
    }/hz/vendor/members/chargebacks/ui?searchText=&${fromDate}&${toDate}`;
  } catch (err) {
    // do nothing
    return "";
  }
}

const FailedUploadSummary = styled(AccordionSummary)`
  background-color: ${({ theme }) => theme.palette.info.main};
  border-radius: 0px;
`;
const FailedUploadDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.info.contrastText};
`;

const FailedUploadArrow = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.palette.info.contrastText};
  fill: ${({ theme }) => theme.palette.info.contrastText};
`;

interface ChargebackUploadDialogProps {
  mid: string;
  marketplaceType?: string;
  marketplaceSubtype?: string;
  countryCode: string;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  currentRange: DateRange;
}

function ChargebackUploadDialog({
  mid,
  marketplaceType,
  marketplaceSubtype,
  countryCode,
  openModal,
  setOpenModal,
  currentRange,
}: ChargebackUploadDialogProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [responseExpanded, setResponseExpanded] = useState<boolean>(false);

  const selectFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    // Required file type and size (10 MB)
    if (file?.type === "text/csv" && file?.size < 10000000) {
      setSelectedFile(file);
    } else {
      setError(
        dispatch,
        t("vendorProfitability.chargebackInvalidFileError"),
        undefined,
        "UploadChargebackFile"
      );
    }
  };

  const [
    uploadChargebackFile,
    { isLoading, isSuccess, skippedChargebackCount, skippedVendorCodes },
  ] = useUploadChargebackFileMutation({
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      isLoading,
      isSuccess,
      skippedChargebackCount: data?.skippedChargebackCount || 0,
      skippedVendorCodes: data?.skippedVendorCodes,
    }),
  });

  const uploadFile = () => {
    if (selectedFile && mid && marketplaceType) {
      uploadChargebackFile({
        mid,
        marketplaceType,
        marketplaceSubtype: marketplaceSubtype ?? marketplaceType,
        countryCode,
        file: selectedFile,
        successMessage: t("vendorProfitability.chargebackUploadSuccessMsg"),
      });
    }
  };

  const { lastChargebackUpdatedAt } = useGetChargebackMetadataQuery(
    {
      mid,
      marketplaceFilters: {
        marketplaceType: marketplaceType || "",
        marketplaceSubtype: marketplaceSubtype || "",
      },
      currentRange,
    },
    {
      skip: !marketplaceType || !mid || !marketplaceSubtype,
      selectFromResult: ({ data }) => {
        return {
          lastChargebackUpdatedAt: data?.lastChargebackUpdatedAt || null,
        };
      },
    }
  );

  const close = () => {
    setSelectedFile(undefined);
    setOpenModal(false);
  };

  const expandAccordion = (_event: ChangeEvent<{}>, expanded: boolean) => {
    setResponseExpanded(expanded);
  };

  useEffect(() => {
    if (isSuccess && skippedChargebackCount === 0) {
      close();
    }
  }, [isSuccess]);

  const chargebackUrl = generateChargebacksUrl(countryCode as AmazonCountry);

  return (
    <ModalPanel
      open={openModal}
      setOpen={setOpenModal}
      onClose={close}
      title={t("vendorProfitability.chargebackUploadTitle")}
      subtitle={
        lastChargebackUpdatedAt
          ? t("vendorProfitability.lastChargebackUpdated", {
              updatedAt: moment(lastChargebackUpdatedAt).format("lll"),
            })
          : undefined
      }
      content={
        <Form>
          <Grid container spacing={4}>
            {isSuccess && skippedChargebackCount > 0 && (
              <Grid item xs={12} justifyContent="center" alignItems="center">
                <Accordion
                  expanded={responseExpanded}
                  onChange={expandAccordion}
                  square
                >
                  <FailedUploadSummary
                    expandIcon={
                      <Tooltip
                        title={
                          responseExpanded
                            ? t("vendorProfitability.hideVendorCodesTooltip")
                            : t("vendorProfitability.showVendorCodesTooltip")
                        }
                      >
                        <FailedUploadArrow />
                      </Tooltip>
                    }
                  >
                    <Bold
                      variant="body1"
                      $color={theme.palette.info.contrastText}
                    >
                      {t("vendorProfitability.chargebackUploadAlert", {
                        skippedChargebacks: `${skippedChargebackCount}`,
                        count: skippedChargebackCount,
                      })}
                    </Bold>
                  </FailedUploadSummary>
                  <FailedUploadDetails>
                    <Typography variant="body1">
                      {skippedVendorCodes?.join(", ")}
                    </Typography>
                  </FailedUploadDetails>
                </Accordion>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h3">
                {t("vendorProfitability.chargebackUploadInstructions")}
              </Typography>
              <Typography variant="body2">
                <OrderedList>
                  <ListItem>
                    <span>
                      {t(
                        "vendorProfitability.chargebackUploadInstructionsBullet1"
                      )}
                    </span>
                    <CellLink href={chargebackUrl} target={"_blank"}>
                      {t("vendorProfitability.chargebackUploadClickHereButton")}
                    </CellLink>
                    <span>
                      {t(
                        "vendorProfitability.chargebackUploadInstructionsBullet2"
                      )}
                    </span>
                    <CellLink
                      href="https://help.merchantspring.io/en/how-to-download-chargeback-information-from-vendor-central"
                      target={"_blank"}
                    >
                      {t("vendorProfitability.chargebackUploadHowToButton")}
                    </CellLink>
                  </ListItem>
                  <ListItem>
                    {t(
                      "vendorProfitability.chargebackUploadInstructionsBullet3"
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      "vendorProfitability.chargebackUploadInstructionsBullet4"
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      "vendorProfitability.chargebackUploadInstructionsBullet5"
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      "vendorProfitability.chargebackUploadInstructionsBullet6"
                    )}
                  </ListItem>
                </OrderedList>
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1">
                {t("vendorProfitability.chargebackUploadLabel")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent="center"
              alignItems="center"
            >
              <Input type="file" name="file" onChange={selectFileHandler} />
            </Grid>
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid item container xs={12} sm={6} justifyContent="center">
                <ErrorButton
                  disabled={isLoading}
                  onClick={close}
                  variant="outlined"
                >
                  {t("generic.cancelButton")}
                </ErrorButton>
              </Grid>
              <Grid item container xs={12} sm={6} justifyContent="center">
                <RaisedButton
                  type="submit"
                  color="primary"
                  onClick={uploadFile}
                  disabled={isLoading || !selectedFile}
                >
                  {isLoading ? (
                    <LoadingIndicator size={20}></LoadingIndicator>
                  ) : (
                    t("vendorProfitability.uploadBtnLabel")
                  )}
                </RaisedButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      }
    ></ModalPanel>
  );
}

export default ChargebackUploadDialog;
