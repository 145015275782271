import AmexImage from "~/img/creditCard/american-express.svg";
import { types as CardType } from "credit-card-type";
import { Grid } from "@material-ui/core";
import MasterCardImage from "~/img/creditCard/master-card.svg";
import PropTypes from "prop-types";
import React from "react";
import VisaImage from "~/img/creditCard/visa.svg";
import styled from "styled-components";

const CardImage = styled.img`
  vertical-align: middle;
  margin-left: 2px;
  filter: ${({ active }) => (active ? "none" : "grayscale(1)")};
  width: 30px;
`;

const CardBadgeContainer = styled(Grid).attrs(() => ({
  container: true,
}))`
  width: fit-content;
  padding: 0.2rem 0;
`;

const CreditCardBadge = ({ cardType }) => (
  <CardBadgeContainer>
    <Grid item xs={4}>
      <CardImage src={VisaImage} active={cardType === CardType.VISA} />
    </Grid>
    <Grid item xs={4}>
      <CardImage
        src={MasterCardImage}
        active={cardType === CardType.MASTERCARD || cardType === "master-card"}
      />
    </Grid>
    <Grid item xs={4}>
      <CardImage
        src={AmexImage}
        active={cardType === CardType.AMERICAN_EXPRESS}
      />
    </Grid>
  </CardBadgeContainer>
);

CreditCardBadge.propTypes = {
  cardType: PropTypes.string,
};
export default CreditCardBadge;
