import { Grid, Hidden, Typography } from "@material-ui/core";
import Bold from "~/components/typography/bold";
import PropTypes from "prop-types";
import React from "react";

const SubscriptionDetails = ({ id, description, value }) => (
  <Grid id={id} item xs={12} sm={6}>
    <Hidden xsDown>
      <Bold>{description}: </Bold>
      <Typography>{value}</Typography>
    </Hidden>
    <Hidden smUp>
      <Typography>
        {description}: {value}
      </Typography>
    </Hidden>
  </Grid>
);

SubscriptionDetails.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default SubscriptionDetails;
