/* eslint-disable no-magic-numbers */
import Plan from "../modules/subscription/plan/plan";
import ReactGA from "react-ga";
import { UNAUTHORIZED } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../configs";
import concat from "lodash/concat";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { isLegacyPlan } from "@merchantspring/common";
import { setError } from "./globalToast.redux";

const moment = require("moment-timezone");

export const SELECT_PLAN = "SELECT_PLAN";

export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_ERROR = "FETCH_PLANS_ERROR";

export const FETCH_SUBSCRIPTION_REQUEST = "FETCH_SUBSCRIPTION_REQUEST";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_ERROR = "FETCH_SUBSCRIPTION_ERROR";

export const FETCH_BRAINTREE_TOKEN_REQUEST = "FETCH_BRAINTREE_TOKEN_REQUEST";
export const FETCH_BRAINTREE_TOKEN_SUCCESS = "FETCH_BRAINTREE_TOKEN_SUCCESS";
export const FETCH_BRAINTREE_TOKEN_ERROR = "FETCH_BRAINTREE_TOKEN_ERROR";

export const REGISTER_INTEREST_REQUEST = "REGISTER_INTEREST_REQUEST";
export const REGISTER_INTEREST_SUCCESS = "REGISTER_INTEREST_SUCCESS";
export const REGISTER_INTEREST_ERROR = "REGISTER_INTEREST_ERROR";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const UPDATE_BILLING_ADDRESS_REQUEST = "UPDATE_BILLING_ADDRESS_REQUEST";
export const UPDATE_BILLING_ADDRESS_SUCCESS = "UPDATE_BILLING_ADDRESS_SUCCESS";
export const UPDATE_BILLING_ADDRESS_FAILURE = "UPDATE_BILLING_ADDRESS_FAILURE";

export const UPDATE_PAYMENT_METHOD_REQUEST = "UPDATE_PAYMENT_METHOD_REQUEST";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILURE = "UPDATE_PAYMENT_METHOD_FAILURE";

export const UPDATE_FAILED_PAYMENT_DISPLAY = "UPDATE_FAILED_PAYMENT_DISPLAY";

export const HIDE_TRIAL_WARNING = "HIDE_TRIAL_WARNING";

export const ADD_DISCOUNT_OVERRIDE = "ADD_DISCOUNT_OVERRIDE";
export const REMOVE_DISCOUNT_OVERRIDE = "REMOVE_DISCOUNT_OVERRIDE";

const entitlements = {
  basic: {
    marketplaces: 2,
    syncPeriodMonths: 2,
    dataRetentionMonths: 12,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
    ],
  },
  standard: {
    marketplaces: 5,
    syncPeriodMonths: 2,
    dataRetentionMonths: 12,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
    ],
  },
  professional: {
    marketplaces: 10,
    syncPeriodMonths: 3,
    dataRetentionMonths: 24,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
    ],
  },
  vendor: {
    marketplaces: 15,
    syncPeriodMonths: 15,
    dataRetentionMonths: 24,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
      "vendorAnalytics",
      "clientReporting",
    ],
  },
  agency_global: {
    marketplaces: 300,
    syncPeriodMonths: 15,
    dataRetentionMonths: 36,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
      "vendorAnalytics",
      "clientReporting",
      "inhouseBranding",
      "apiGateway",
    ],
  },
  agency_sml: {
    marketplaces: 50,
    syncPeriodMonths: 15,
    dataRetentionMonths: 36,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
      "clientReporting",
      "customLogo",
    ],
  },
  agency: {
    marketplaces: 100,
    syncPeriodMonths: 27,
    dataRetentionMonths: 60,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
      "vendorAnalytics",
      "clientReporting",
      "customLogo",
      "inhouseBranding",
      "customDomain",
    ],
  },
  agency1p_sml: {
    marketplaces: 50,
    syncPeriodMonths: 15,
    dataRetentionMonths: 36,
    features: [
      "salesTrends",
      "operations",
      "health",
      "listingQuality",
      "advertising",
      "profitability",
      "reviewAutomation",
      "vendorAnalytics",
      "clientReporting",
      "customLogo",
    ],
  },
};

const getPlansByLevel = (braintreePlans, level, billingFrequency) =>
  braintreePlans
    .filter(
      (p) =>
        !isLegacyPlan(p) &&
        p.billingFrequency === billingFrequency &&
        (p.id.indexOf(`${level}_mth`) >= 0 || p.id.indexOf(`${level}_yr`) >= 0)
    )
    .map((plan) =>
      new Plan(
        plan.id,
        parseFloat(plan.price),
        plan.currencyIsoCode,
        plan.trialDuration,
        entitlements[level],
        plan.billingFrequency,
        plan.name,
        plan.description
      ).toObject()
    );

const getPlans = (braintreePlans) => ({
  monthly: concat(
    getPlansByLevel(braintreePlans, "basic", 1),
    getPlansByLevel(braintreePlans, "standard", 1),
    getPlansByLevel(braintreePlans, "professional", 1),
    getPlansByLevel(braintreePlans, "vendor", 1),
    getPlansByLevel(braintreePlans, "agency_global", 1),
    getPlansByLevel(braintreePlans, "agency_sml", 1),
    getPlansByLevel(braintreePlans, "agency", 1),
    getPlansByLevel(braintreePlans, "agency1p_sml", 1)
  ),
  yearly: concat(
    getPlansByLevel(braintreePlans, "basic", 12),
    getPlansByLevel(braintreePlans, "standard", 12),
    getPlansByLevel(braintreePlans, "professional", 12),
    getPlansByLevel(braintreePlans, "vendor", 12),
    getPlansByLevel(braintreePlans, "agency_global", 12),
    getPlansByLevel(braintreePlans, "agency_sml", 12),
    getPlansByLevel(braintreePlans, "agency", 12),
    getPlansByLevel(braintreePlans, "agency1p_sml", 12)
  ),
});

const initState = {
  loading: false,
  sendingInterest: false,
  hideFailedPaymentUntil: Date.now(),
  hideTrialWarning: false,
  currentSubscription: {},
  currentPlan: null,
  discount: null,
  plans: [],
  clientToken: "",
};

export const subscriptions = (state = initState, action) => {
  switch (action.type) {
    case SELECT_PLAN:
      return { ...state, selectedPlan: action.payload };

    case FETCH_BRAINTREE_TOKEN_REQUEST:
      return state;

    case FETCH_PLANS_REQUEST:
    case FETCH_SUBSCRIPTION_REQUEST:
    case UPDATE_CUSTOMER_REQUEST:
    case UPDATE_BILLING_ADDRESS_REQUEST:
    case UPDATE_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_PLANS_SUCCESS:
      // do not clear loading yet
      return { ...state, loading: true, plans: action.payload };
    case FETCH_PLANS_ERROR: {
      const newState = {
        ...state,
        loading: false,
        plans: {},
      };
      if (action.payload) {
        return { ...newState, error: action.payload };
      }
      return newState;
    }

    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubscription: action.payload.subscription,
        currentPlan: action.payload.plan,
      };
    case FETCH_SUBSCRIPTION_ERROR: {
      const newState = {
        ...state,
        loading: false,
      };
      if (action.payload) {
        return { ...newState, error: action.payload };
      }
      return newState;
    }

    case FETCH_BRAINTREE_TOKEN_SUCCESS:
      // do not modify loading
      return { ...state, clientToken: action.payload };
    case FETCH_BRAINTREE_TOKEN_ERROR: {
      const newState = {
        ...state,
        clientToken: "",
      };
      if (action.payload) {
        return { ...newState, error: action.payload };
      }
      return newState;
    }

    case UPDATE_CUSTOMER_SUCCESS:
    case UPDATE_BILLING_ADDRESS_SUCCESS:
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubscription: action.payload,
      };

    case UPDATE_CUSTOMER_FAILURE:
    case UPDATE_BILLING_ADDRESS_FAILURE:
    case UPDATE_PAYMENT_METHOD_FAILURE: {
      // If an update fails, we should be able to assume the current
      // subscription information is unchanged, so don't touch it.
      const newState = {
        ...state,
        loading: false,
      };
      if (action.payload) {
        return { ...newState, error: action.payload };
      }
      return newState;
    }

    case REGISTER_INTEREST_REQUEST:
      return { ...state, sendingInterest: true, error: null };
    case REGISTER_INTEREST_SUCCESS:
      return { ...state, sendingInterest: false };
    case REGISTER_INTEREST_ERROR:
      return { ...state, sendingInterest: false, error: action.payload };

    case UPDATE_FAILED_PAYMENT_DISPLAY:
      return { ...state, hideFailedPaymentUntil: action.payload };
    case HIDE_TRIAL_WARNING:
      return { ...state, hideTrialWarning: true };
    case ADD_DISCOUNT_OVERRIDE:
      return { ...state, discount: action.payload };
    case REMOVE_DISCOUNT_OVERRIDE:
      return { ...state, discount: null };
    default:
      return state;
  }
};

export const updateCustomer =
  (subscriptionId, email, firstName, lastName, company) => async (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_REQUEST,
    });

    try {
      const { data, status } = await axios.put(
        `/api/subscription-service/api/subscription/${subscriptionId}/customer`,
        { email, firstName, lastName, company }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: UPDATE_CUSTOMER_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      const status = get(err, "response.status");
      setError(
        dispatch,
        get(err, "response.data") || err.message,
        status === UNAUTHORIZED ? status : "CUSTOM"
      );
      return dispatch({
        type: UPDATE_CUSTOMER_FAILURE,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: UPDATE_CUSTOMER_FAILURE,
    });
  };

export const updatePaymentMethod =
  (subscriptionId, paymentMethodNonce) => async (dispatch) => {
    dispatch({
      type: UPDATE_PAYMENT_METHOD_REQUEST,
    });

    try {
      const { data, status } = await axios.put(
        `/api/subscription-service/api/subscription/${subscriptionId}/paymentMethod`,
        { paymentMethodNonce }
      );

      if (isHttpResponseValid(status) && data) {
        ReactGA.event({
          category: "Subscription",
          action: "Payment Method - Updated",
        });

        return dispatch({
          type: UPDATE_PAYMENT_METHOD_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      const status = get(err, "response.status");
      setError(
        dispatch,
        get(err, "response.data") || err.message,
        status === UNAUTHORIZED ? status : "CUSTOM"
      );
      return dispatch({
        type: UPDATE_PAYMENT_METHOD_FAILURE,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: UPDATE_PAYMENT_METHOD_FAILURE,
    });
  };

/* Return a user's active subscriptions */
export const fetchSubscription = () => async (dispatch) => {
  dispatch({ type: FETCH_SUBSCRIPTION_REQUEST });

  try {
    const { data, status } = await axios.post(
      `${baseUrl}/api/subscription-service/api/user/subscriptions`
    );

    if (isHttpResponseValid(status)) {
      const subscription = get(data, "0");
      const statusHistory = get(subscription, "statusHistory.0");

      if (!subscription || !subscription.status || !statusHistory) {
        // user has no prior subscriptions
        return dispatch({
          type: FETCH_SUBSCRIPTION_SUCCESS,
          payload: {
            subscription: {},
            plan: null,
          },
        });
      }

      if (
        (subscription.status === "Canceled" ||
          subscription.status === "Expired") &&
        moment(subscription.nextBillingDate).isBefore(new Date())
      ) {
        // user has a past subscription (planStatus = Canceled or Expired), which has already lapsed
        return dispatch({
          type: FETCH_SUBSCRIPTION_SUCCESS,
          payload: {
            subscription,
            plan: null,
          },
        });
      }

      // either user has an active plan, or
      // user has cancelled plan, but current plan is still valid until billing date
      const { planId, currencyIsoCode } = statusHistory;
      const price = statusHistory.price ? parseFloat(statusHistory.price) : 0;

      return dispatch({
        type: FETCH_SUBSCRIPTION_SUCCESS,
        payload: {
          subscription,
          plan: new Plan(
            planId,
            price,
            currencyIsoCode,
            0,
            {},
            planId.includes("yr") ? 12 : 1
          ).toObject(), // serializable
        },
      });
    }
  } catch (err) {
    setError(dispatch, err.message, status, "fetchSubscription");
    return dispatch({
      type: FETCH_SUBSCRIPTION_ERROR,
      payload: get(err, "response.data"),
    });
  }

  return dispatch({
    type: FETCH_SUBSCRIPTION_ERROR,
  });
};

export const fetchPlans = () => async (dispatch) => {
  dispatch({ type: FETCH_PLANS_REQUEST });
  try {
    const { data, status } = await axios.get(
      `${baseUrl}/api/subscription-service/api/subscription/plan`
    );

    if (isHttpResponseValid(status) && data) {
      dispatch({
        type: FETCH_PLANS_SUCCESS,
        payload: getPlans(data),
      });

      return dispatch(fetchSubscription());
    }
  } catch (err) {
    setError(dispatch, err.message, status, "fetchPlans");
    return dispatch({
      type: FETCH_PLANS_ERROR,
      payload: get(err, "response.data"),
    });
  }

  return dispatch({
    type: FETCH_PLANS_ERROR,
  });
};

export const fetchBraintreeToken = () => async (dispatch) => {
  dispatch({ type: FETCH_BRAINTREE_TOKEN_REQUEST });
  try {
    const { data, status } = await axios.get(
      `${baseUrl}/api/subscription-service/api/subscription/clientToken`
    );

    if (isHttpResponseValid(status) && data) {
      return dispatch({
        type: FETCH_BRAINTREE_TOKEN_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    setError(dispatch, err.message, status, "fetchBraintreeToken");
    return dispatch({
      type: FETCH_BRAINTREE_TOKEN_ERROR,
      payload: get(err, "response.data"),
    });
  }

  return dispatch({
    type: FETCH_BRAINTREE_TOKEN_ERROR,
  });
};

/* Hide the failed payment banner for a day */
export const hideFailedPayment = () => (dispatch) =>
  dispatch({
    type: UPDATE_FAILED_PAYMENT_DISPLAY,
    payload: moment().add(1, "day").valueOf(),
  });

export const hideTrialWarning = () => (dispatch) =>
  dispatch({
    type: HIDE_TRIAL_WARNING,
  });

/* Add discount */
export const addDiscountOverride = (discount) => (dispatch) =>
  dispatch({
    type: ADD_DISCOUNT_OVERRIDE,
    payload: discount,
  });

export const removeDiscountOverride = () => (dispatch) =>
  dispatch({
    type: REMOVE_DISCOUNT_OVERRIDE,
  });
