import { Box, Grid, Typography } from "@material-ui/core";
import {
  CHART_TITLES,
  getNearestHundredOrTen,
  getNearestPowerOfTenFloor,
} from "../chartUtils/chartUtils";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import React, { memo } from "react";
import {
  getEvenTicksFromZero,
  useLayoutProps,
} from "../chartUtils/chartComponents";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import MultiLineChartLegendGeneric from "./multiLineChartLegendGeneric";
import { MultiLineChartTooltipGeneric } from "./multiLineChartTooltipGeneric";
import { SuccessDirection } from "../percentageChange";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import get from "lodash/get";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface MultiLineChartProps {
  chartData: {
    startTime?: number;
    endTime?: number;
    current?: number | null;
    prior?: number | null;
  }[];
  currentPeriod: DATETIME_PERIODS;
  currency: string;
  fromDate: number;
  toDate: number;
  interval: INTERVAL;
  report?: boolean;
  timezone: string;
  keys?: [string, string];
  keysForLegend?: [string, string];
  colours?: [string, string];
  notCurrency?: boolean;
  valueTooltipKey: string;
  valuePrefix?: string;
  valueSuffix?: string;
  toFixed?: number;
  displayPercentChange?: boolean;
  percentageChangeFormatting?: {
    isPptChange?: boolean;
    successDirection?: SuccessDirection;
    conditionalFormatting?: boolean;
    toFixedDigits?: number;
    noUnit?: boolean;
  };
}

const Y_AXIS_WIDTH = 80;

const getXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  interval: INTERVAL,
  isCustomPeriodSameDay = false,
  timezone: string
): XAxisProps => {
  let tickFormatter;
  if (interval === INTERVAL.MONTHS) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).add(12, "h").startOf("day").format("MMM");
  } else if (currentPeriod === DATETIME_PERIODS.DAY || isCustomPeriodSameDay) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("HH");
  } else if (
    currentPeriod === DATETIME_PERIODS.WEEK ||
    currentPeriod === DATETIME_PERIODS.WEEKMTS
  ) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("ddd");
  } else {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("D MMM");
  }

  return {
    dataKey: "startTime",
    type: "category",
    interval: "preserveStartEnd",
    domain: ["dataMin", "dataMax"],
    tickLine: false,
    tick: { fontSize: 13 },
    minTickGap: 30,
    tickCount: 6,
    tickFormatter,
  };
};

const MultiLineChartGeneric = memo<MultiLineChartProps>(
  function MultiLineChartGeneric({
    chartData = [],
    currentPeriod,
    currency,
    fromDate,
    toDate,
    interval,
    report,
    timezone,
    keys,
    keysForLegend,
    colours,
    notCurrency,
    valueTooltipKey,
    valueSuffix,
    valuePrefix,
    toFixed,
    displayPercentChange = true,
    percentageChangeFormatting,
  }) {
    const { height, margin } = useLayoutProps("lineChart", report);
    const { t } = useTranslation();
    const theme = useTheme();

    const isCustomPeriodSameDay =
      Boolean(fromDate) &&
      Boolean(toDate) &&
      moment.unix(fromDate).isSame(moment.unix(toDate), "day");

    const XAxisProps =
      chartData &&
      getXAxisProps(currentPeriod, interval, isCustomPeriodSameDay, timezone);

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const currentCurrency =
      currency ||
      useTypedSelector(
        (state) =>
          get(state, "persistentAppSettings.setting.data.currentCurrency") ||
          DEFAULT_CURRENCY
      );

    const dataMaxThisWeek =
      chartData &&
      Math.max.apply(
        Math,
        chartData.map((o) => o.current ?? 0)
      );

    const dataMaxLastWeek =
      chartData &&
      Math.max.apply(
        Math,
        chartData.map((o) => o.prior ?? 0)
      );

    const dataMax = Math.max(dataMaxThisWeek, dataMaxLastWeek);

    const dataKeys = keys ?? ["current", "prior"];
    const dataKeysForLegend = keysForLegend ?? [
      t("chartKeys.current"),
      t("chartKeys.past"),
    ];
    const dataColours = colours ?? [
      theme.palette.primary.main,
      theme.palette.secondary.main,
    ];

    /* Calculate a 'rounding ceiling' - the minimum tick size for the Y axis 
    The one exception is if the dataMax is less than 10, in which case we round to 1, so that the Y axis
    ticks look better for small numbers.
  */
    const roundingCeiling = dataMax < 10 ? 1 : valueSuffix === "%" ? 10 : 100;

    const yAxisTicks = getEvenTicksFromZero(
      getNearestHundredOrTen(dataMax),
      5,
      // take the closest hundred, or thousand, etc, to 1% of the overall data value
      // Used to make the rounding on the tick marks more intuitive
      // for small currency denominations like JPY or DKK
      Math.max(roundingCeiling, getNearestPowerOfTenFloor(dataMax / 100))
    );

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box pb={2}>
            <Typography variant="h6">
              {currentPeriod ? t(CHART_TITLES[currentPeriod]) : ""}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={height}>
            <LineChart data={chartData} margin={margin}>
              <XAxis {...XAxisProps} />
              <YAxis
                type="number"
                ticks={yAxisTicks}
                domain={[0, "dataMax"]}
                allowDataOverflow
                tick={{ fontSize: 13 }}
                tickFormatter={(tick) =>
                  notCurrency
                    ? `${valuePrefix ?? ""}${numberWithCommas(parseInt(tick))}${
                        valueSuffix ?? ""
                      }`
                    : formatCurrencyRounded(
                        tick,
                        currencyRates,
                        currentCurrency,
                        currentCurrency
                      )
                }
                width={Y_AXIS_WIDTH}
              />
              <Legend
                verticalAlign="top"
                align="left"
                wrapperStyle={{ top: -16, fontSize: "12px" }}
                content={
                  <MultiLineChartLegendGeneric
                    dataKeys={dataKeysForLegend}
                    colours={dataColours}
                  />
                }
              />
              <Tooltip
                content={
                  <MultiLineChartTooltipGeneric
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    currencyRates={currencyRates}
                    interval={interval}
                    timezone={timezone}
                    dataKeys={dataKeysForLegend}
                    colours={dataColours}
                    notCurrency={notCurrency}
                    valueTooltipKey={valueTooltipKey}
                    valuePrefix={valuePrefix}
                    valueSuffix={valueSuffix}
                    toFixed={toFixed}
                    displayPercentChange={displayPercentChange}
                    percentageChangeFormatting={percentageChangeFormatting}
                  />
                }
              />
              {dataKeys.map((dataKey, i) => {
                return (
                  <Line
                    key={dataKey}
                    dataKey={dataKey}
                    dot={false}
                    strokeWidth={2}
                    stroke={dataColours[i] ?? theme.palette.primary.main}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    );
  }
);

export default MultiLineChartGeneric;
