import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { Paper, useMediaQuery } from "@material-ui/core";

import { PaletteColor } from "@material-ui/core/styles/createPalette";
import i18n from "i18next";
import moment from "moment-timezone";
import styled from "styled-components";
import { useCallback } from "react";
import { useTheme } from "@material-ui/core";

// TODO - some functions in this file have unknown parameters (which are cast to any to be used).
// Track down how these functions are being used and update their parameter types, then remove the any casts.
// Some variables with unknown types were cast to boolean or any to enable their pre TS use to continue.
// Work out how to provide their correct types.

export const formatTooltipLabel = (
  label: unknown,
  currentPeriod: unknown,
  interval: INTERVAL | undefined,
  timezone: string
) => {
  if (currentPeriod === DATETIME_PERIODS.DAY) {
    return moment
      .unix(label as any)
      .tz(timezone)
      .format("ddd, MMM Do YYYY HH:mm");
  }
  if (currentPeriod === "WTD") {
    moment
      .unix(label as any)
      .tz(timezone)
      .format("ddd HH:mm");
  }
  if (interval === INTERVAL.WEEKS) {
    return moment
      .unix(label as any)
      .tz(timezone)
      .format("ddd, MMM Do YYYY z HH:mm");
  }
  if (interval === INTERVAL.MONTHS) {
    return moment
      .unix(label as any)
      .tz(timezone)
      .add(12, "h")
      .startOf("day")
      .format("MMM YYYY");
  }
  return moment
    .unix(label as any)
    .tz(timezone)
    .format("ddd, MMM Do YYYY HH:mm");
};

export const formatTooltipLegend = (
  label: unknown,
  currentPeriod: unknown,
  t: (s: string) => string,
  timezone: string
): { label: string; shade: keyof PaletteColor } => {
  if (currentPeriod === DATETIME_PERIODS.DAY) {
    const tooltipLabel = moment.unix(label as any).calendar({
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
    });
    return {
      label: moment
        .unix(label as any)
        .tz(timezone)
        .format("hh A"),
      shade: (tooltipLabel === "Today"
        ? "main"
        : "light") as keyof PaletteColor,
    };
  }
  if (currentPeriod === "WTD") {
    moment
      .unix(label as any)
      .tz(timezone)
      .locale(i18n.language)
      .format("dddd");
  }
  const dayNumber = moment
    .unix(label as any)
    .tz(timezone)
    .weekday();
  const tooltipLabel =
    dayNumber === 6 || dayNumber === 0
      ? t("chartKeys.custom.past")
      : t("chartKeys.custom.current");
  return {
    label: tooltipLabel,
    shade: (tooltipLabel === t("chartKeys.custom.current")
      ? "main"
      : "light") as keyof PaletteColor,
  };
};

export const BlackTooltip = styled(Paper)`
  background-color: black;
  color: white;
`;

function useBarChartProps(onMobile: boolean) {
  const DESKTOP_HEIGHT = 260;
  const MOBILE_HEIGHT = 150;
  const DESKTOP_MARGIN = {
    top: 25,
    right: 20,
    left: 5,
    bottom: 5,
  };
  const MOBILE_MARGIN = {
    top: 5,
    right: 5,
    left: 5,
    bottom: 5,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 2,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function useStepChartProps(onMobile: boolean) {
  const DESKTOP_HEIGHT = 260;
  const MOBILE_HEIGHT = 200;
  const DESKTOP_MARGIN = {
    top: 20,
    right: 20,
    left: 5,
    bottom: 5,
  };
  const MOBILE_MARGIN = {
    top: 20,
    right: 20,
    left: 5,
    bottom: 5,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: "10px",
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: "0px",
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function useComboChartProps(onMobile: boolean) {
  const DESKTOP_HEIGHT = 260;
  const MOBILE_HEIGHT = 200;
  const DESKTOP_MARGIN = {
    top: 25,
    right: 20,
    left: 5,
    bottom: 5,
  };
  const MOBILE_MARGIN = {
    top: 25,
    right: 20,
    left: 5,
    bottom: 5,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 2,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function useHorizontalChartProps(onMobile: boolean) {
  // These Heights may not be sufficient if your use case has several bars, or really long labels
  // They were designed for the suppressed listings use case where there is a non dynamic (4) number
  // of bars
  const DESKTOP_HEIGHT = 170;
  const MOBILE_HEIGHT = 150;
  // Only the HEIGHT values are being used in the component.
  const DESKTOP_MARGIN = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  };
  const MOBILE_MARGIN = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 0,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function useTableChartProps(onMobile: boolean) {
  const DESKTOP_HEIGHT = 150;
  const MOBILE_HEIGHT = 100;
  const DESKTOP_MARGIN = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 20,
  };
  const MOBILE_MARGIN = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 10,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 0,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function useDonutChartProps(onMobile: boolean) {
  // based on the invision for Walmart Listing quality donut chart - an overall height of 160 gets us a 70 radius donut
  const DESKTOP_HEIGHT = 160;

  const MOBILE_HEIGHT = 100;
  const DESKTOP_MARGIN = {
    top: 10,
    right: 10,
    left: 10,
    bottom: 10,
  };
  const MOBILE_MARGIN = {
    top: 10,
    right: 10,
    left: 10,
    bottom: 10,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 0,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

function usePieChartProps(onMobile: boolean) {
  // We calculate height differently for pie charts
  const DESKTOP_HEIGHT = 0;
  const MOBILE_HEIGHT = 0;
  const DESKTOP_MARGIN = {
    top: 10,
    right: 10,
    left: 10,
    bottom: 10,
  };
  const MOBILE_MARGIN = {
    top: 10,
    right: 10,
    left: 10,
    bottom: 10,
  };
  const desktopProps = {
    height: DESKTOP_HEIGHT,
    margin: DESKTOP_MARGIN,
    padding: 0,
    onMobile,
  };
  const mobileProps = {
    height: MOBILE_HEIGHT,
    margin: MOBILE_MARGIN,
    padding: 0,
    onMobile,
  };
  return { mobileProps, desktopProps };
}

export type chartTypes =
  | "horizontalChart"
  | "stepChart"
  | "lineChart"
  | "comboChart"
  | "tableChart"
  | "donutChart"
  | "pieChart";

export interface LayoutProps {
  height: number;
  margin: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  padding: string | number; // the pre TS code sometimes returns a string, sometimes a number. It would be nice to change this to one or the other
  onMobile: boolean;
}

export const useLayoutProps = (
  type?: chartTypes,
  report?: boolean
): LayoutProps => {
  const theme = useTheme();
  const onMobile = report
    ? true
    : ((theme.breakpoints &&
        useMediaQuery(theme.breakpoints.down("xs"))) as boolean);
  const { mobileProps, desktopProps } =
    type === "stepChart"
      ? useStepChartProps(onMobile)
      : type === "comboChart" || type === "lineChart"
      ? useComboChartProps(onMobile)
      : type === "tableChart"
      ? useTableChartProps(onMobile)
      : type === "donutChart"
      ? useDonutChartProps(onMobile)
      : type === "pieChart"
      ? usePieChartProps(onMobile)
      : type === "horizontalChart"
      ? useHorizontalChartProps(onMobile)
      : useBarChartProps(onMobile);

  const getLayout = useCallback((onMobile) => {
    return onMobile ? mobileProps : desktopProps;
  }, []);

  return getLayout(onMobile);
};

export const getTimeseriesXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  chartData: Record<string, any>[],
  xKey: string,
  timezone: string,
  interval?: INTERVAL
) => {
  if (currentPeriod === DATETIME_PERIODS.WEEK) {
    const tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("ddd");
    return {
      dataKey: xKey,
      dy: 10,
      tickLine: false,
      tick: { fontSize: 14 },
      tickFormatter,
    };
  } else {
    const tickFormatter =
      interval === INTERVAL.MONTHS
        ? (tick: number) =>
            moment
              .unix(tick)
              .tz(timezone)
              .add(12, "h")
              .startOf("day")
              .format("MMM")
        : currentPeriod === DATETIME_PERIODS.DAY
        ? (tick: number) => moment.unix(tick).tz(timezone).format("HH")
        : (tick: number) => moment.unix(tick).tz(timezone).format("MMMM Do");
    const tickCount = currentPeriod === DATETIME_PERIODS.DAY ? 13 : 3;
    return {
      dataKey: xKey,
      dy: 10,
      type: "category" as "category",
      interval: "preserveStartEnd" as "preserveStartEnd",
      tickLine: false,
      tickCount: tickCount,
      domain: ["dataMin", "dataMax"],
      padding: "gap" as "gap",
      tick: { fontSize: 14 },
      tickFormatter,
      ticks: [chartData[0]?.[xKey], chartData[chartData.length - 1]?.[xKey]],
    };
  }
};

export const roundTo = (input: number, closest: number) => {
  return Math.sign(input) * (Math.ceil(Math.abs(input) / closest) * closest);
};

export const getEvenTicksFromZero = (
  dataMax: number,
  numTicks: number,
  roundToClosest: number
) => {
  const tickSize = roundTo(dataMax / numTicks, roundToClosest);
  const ticks = [0];
  for (let i = 1; i < numTicks + 1; i++) {
    ticks.push(tickSize * i);
  }
  return [...ticks];
};

export const getEvenTicks = (
  dataMin: number,
  dataMax: number,
  numTicks: number
) => {
  const dataRange = dataMax - dataMin;
  const calcNumTicks =
    dataRange < 100 ? Math.ceil(dataRange / 10) + 1 : numTicks;
  const diff = dataRange < 100 ? 10 : roundTo(dataRange / (numTicks - 1), 10);
  const ticks = [dataMin];
  for (let i = 1; i < calcNumTicks; i++) {
    ticks.push(dataMin + diff * i);
  }
  return [...ticks];
};

export const getDataMax = (
  chartData: Record<string, any>[],
  dataKey: string,
  roundToClosest: number
) => {
  return chartData
    ? roundTo(
        Math.max.apply(
          Math,
          chartData.map((o) => o[dataKey])
        ),
        roundToClosest
      )
    : 0;
};
