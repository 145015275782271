import { Box, Grid, useTheme } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { Filter, Range } from "~/typedef/store";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  computeTotalValue,
  getCategory,
} from "../../profitLossTable/categoryUtils";
import {
  fetchProfitabilityCategories,
  useProfitabilityChartQuery,
} from "@store/mystore/profitability.redux";
import {
  getCurrencySymbol,
  roundFractionalDigits,
} from "~/utils/currencyUtils";
import moment, { DurationInputArg2 } from "moment-timezone";

import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import MultiComposedChart from "@components/charts/multiComposedChart/multiComposedChart";
import Panel from "@components/panel/panel";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const LABELS = {
  SALES: "productSalesLabel",
  ADVERTISING: "advertisingLabel",
  SHIPPING: "fulfilmentAndShippingLabel",
  REFUNDS: "refundedSalesLabel",
};

/** Group data into buckets/intervals, where each bucket
is a single bar on the chart */
const formatChartData = (
  range: Range,
  events:
    | [
        {
          label: string;
          value: number;
          date: string;
          storeId?: number | undefined;
        }
      ]
    | undefined,
  categories: any[],
  timezone: string
) => {
  const { fromDate, interval, toDate } = range;
  const formatCurrencyValue = (value: number) => {
    if (isNaN(value)) return "-";
    // defend against weird accidental string values
    if (typeof value === "number") return roundFractionalDigits(value, 2);
    return value;
  };

  const startDate = moment.unix(fromDate).tz(timezone);
  const endDate = moment.unix(toDate).tz(timezone);
  const data = [];
  if (events && categories) {
    while (startDate.isBefore(endDate)) {
      const bucketEvents = events.filter((event) =>
        startDate.isSame(moment(event.date).tz(timezone), interval)
      );

      const revenueCategories = categories.filter(
        (category: { type: string }) => category.type === "revenue"
      );

      const expensesCategories = categories.filter(
        (category: { type: string }) => category.type === "expense"
      );

      const totalRevenue = computeTotalValue(
        {
          type: "revenue",
          label: "incomeLabel",
          children: revenueCategories.reduce((acc: any[], category: any) => {
            return [...acc, ...category.children];
          }, []),
        },
        bucketEvents
      );
      const totalExpenses = computeTotalValue(
        {
          type: "expense",
          label: "expenseLabel",
          children: expensesCategories.reduce((acc: any[], category: any) => {
            return [...acc, ...category.children];
          }, []),
        },
        bucketEvents
      );

      const salesCategory = categories.reduce(getCategory(LABELS.SALES), []);
      const totalSales = computeTotalValue(salesCategory, bucketEvents);

      const shippingCategory = categories.reduce(
        getCategory(LABELS.SHIPPING),
        []
      );
      const totalShipping = computeTotalValue(shippingCategory, bucketEvents);

      const advertisingCategory = categories.reduce(
        getCategory(LABELS.ADVERTISING),
        []
      );
      const totalAdvertising = computeTotalValue(
        advertisingCategory,
        bucketEvents
      );

      const refundsCategory = categories.reduce(
        getCategory(LABELS.REFUNDS),
        []
      );
      const totalRefunds = computeTotalValue(
        refundsCategory,
        bucketEvents,
        true
      );

      const intervalData = {
        startTime: startDate.clone().startOf(interval).unix(),
        endTime: startDate.clone().endOf(interval).unix(),
        sales: formatCurrencyValue(totalSales),
        shipping: formatCurrencyValue(totalShipping),
        advertising: formatCurrencyValue(totalAdvertising),
        refunds: formatCurrencyValue(totalRefunds),
        profit: formatCurrencyValue(totalRevenue - totalExpenses),
      };
      data.push(intervalData);
      startDate.add(1, interval as DurationInputArg2);
    }
  }

  if (moment.normalizeUnits(interval as any) === "week" && data.length) {
    data[0].startTime = fromDate;
    data[data.length - 1].endTime = toDate;
  }

  return data;
};

interface ProfitabilityChartProps {
  mid?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
  report?: boolean;
  includeTax: boolean;
  currentFilter: Filter;
}

const ProfitabilityChart = memo<ProfitabilityChartProps>(
  function ProfitabilityChart({
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    timezone,
    report,
    includeTax,
    currentFilter,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [totalProfit, setTotalProfit] = useState(0);

    const categoriesData = useTypedSelector((state) =>
      get(state.profitability, "categories.data")
    );

    const { chartData, chartDataLoading, chartTotalsData } =
      useProfitabilityChartQuery(
        {
          mid,
          currentRange,
          filter: currentFilter,
          currency: currentCurrency,
          includeTax,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            chartData: data?.events?.events,
            chartDataLoading: isFetching,
            chartTotalsData: data?.events?.totalWithSku,
          }),
        }
      );

    const formattedChartData = useMemo(() => {
      return formatChartData(currentRange, chartData, categoriesData, timezone);
    }, [chartData, categoriesData]);

    useEffect(() => {
      dispatch(
        fetchProfitabilityCategories({
          mid,
          includeTax,
        })
      );
    }, [mid, includeTax]);

    useEffect(() => {
      if (categoriesData && categoriesData.length && chartData) {
        const revenueCategories = categoriesData.filter(
          (category: { type: string }) => category.type === "revenue"
        );

        const expensesCategories = categoriesData.filter(
          (category: { type: string }) => category.type === "expense"
        );

        const totalRevenue = computeTotalValue(
          {
            type: "revenue",
            label: "incomeLabel",
            children: revenueCategories.reduce((acc: any[], category: any) => {
              return [...acc, ...category.children];
            }, []),
          },
          chartData
        );
        const totalExpenses = computeTotalValue(
          {
            type: "expense",
            label: "expenseLabel",
            children: expensesCategories.reduce((acc: any[], category: any) => {
              return [...acc, ...category.children];
            }, []),
          },
          chartData
        );

        setTotalProfit(totalRevenue - totalExpenses);
      }
    }, [categoriesData, chartData]);

    const dataDefinition = [
      { dataKey: "sales", type: "line", color: theme.palette.chart.darkBlue },
      {
        dataKey: "shipping",
        type: "line",
        color: theme.palette.chart.lightBlue,
      },
      {
        dataKey: "advertising",
        type: "line",
        color: theme.palette.chart.orange,
      },
      { dataKey: "refunds", type: "line", color: theme.palette.chart.red },
      { dataKey: "profit", type: "bar", color: theme.palette.success.main },
    ];

    return (
      <Panel
        id="product-profitability-chart"
        title={t("myStoresWidget.productProfit.chartTitle")}
        content={
          chartDataLoading ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Grid container spacing={1}>
              <Grid container item xs={12} md={9} lg={10}>
                <MultiComposedChart
                  {...{
                    chartData: formattedChartData,
                    dataDefinition: dataDefinition,
                    currentPeriod,
                    toDate: currentRange.toDate,
                    fromDate: currentRange.fromDate,
                    currency:
                      getCurrencySymbol[
                        currentCurrency as keyof typeof getCurrencySymbol
                      ],
                    interval: currentRange.interval as INTERVAL,
                    report,
                    timezone,
                  }}
                />
              </Grid>
              <Grid container item xs={12} md={3} lg={2} alignItems="center">
                <Box width="100%" pl={report ? 2 : 0} pb={report ? 2 : 0}>
                  <GenericTotalMoney
                    current={totalProfit}
                    currency={currentCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`profitability.totalProfit`)}
                    rounded
                  />
                </Box>
                {chartTotalsData && (
                  <Box width="100%" pl={report ? 2 : 0} pb={report ? 2 : 0}>
                    <GenericTotalMoney
                      current={chartTotalsData}
                      currency={currentCurrency}
                      currentCurrency={currentCurrency}
                      title={t(`profitability.productProfit`)}
                      tooltip={t(`profitability.productProfitTooltip`)}
                      rounded
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          )
        }
      />
    );
  }
);

export default ProfitabilityChart;
