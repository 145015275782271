import React, { useMemo } from "react";

import TableFilter from "~/components/panel/panelActions/tableFilter";

const MetricSelect = ({
  setSelected,
  selected,
}: {
  setSelected: (value: any) => void;
  selected: any;
}) => {
  const metricOptions = useMemo(
    () => [
      {
        value: "acos",
        label: "ACOS",
      },
      {
        value: "tacos",
        label: "TACOS",
      },
      {
        value: "adSpend",
        label: "AD SPEND",
      },
      {
        value: "roas",
        label: "ROAS",
      },
    ],
    []
  );

  return (
    <TableFilter
      currentValue={selected}
      handleChange={setSelected}
      options={metricOptions}
      width="200px"
    />
  );
};

export default MetricSelect;
