import { Box, Grid, Typography } from "@material-ui/core";
import { PlanPropTypes, formatBillingPeriod, getPlanName } from "./plan/plan";
import {
  SubscriptionPanelContent,
  SummaryItem,
} from "./subscriptionStyledComponents";

import Panel from "~/components/panel/panel.tsx";
import PlanFeatureSummary from "./plan/planFeatureSummary";
import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FooterContainer = styled(Box).attrs(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
}))`
  width: 100%;
`;

const PaymentSubscriptionSummary = ({ newUser, plan, discount }) => {
  const { t } = useTranslation();
  const planName = t(getPlanName(plan.planId));
  const trialPeriod = get(discount, "numTrialDays") || plan.trialPeriod;
  const finalAmount = plan.cost - (get(discount, "amount") || 0);
  const billingFrequency =
    plan.billingFrequencyMonths === 1
      ? t("subscription.plan.monthlyLabel")
      : t("subscription.plan.yearlyLabel");

  const panelContent = (
    <Box pr={1}>
      <SubscriptionPanelContent container>
        {newUser && (
          <SummaryItem
            leftText={`${planName} ${t(
              "subscription.create.subscriptionSuffix"
            )} (${billingFrequency})`}
            rightText={"FREE"}
            rightSubtitle={`${trialPeriod} day trial`}
          />
        )}
        {!newUser && (
          <SummaryItem
            leftText={`${planName} ${t(
              "subscription.create.subscriptionSuffix"
            )} (${billingFrequency})`}
            rightText={`${getCurrencySymbol[plan.currency]}${finalAmount} ${
              plan.currency
            } /${t(formatBillingPeriod(plan))}`}
            rightSubtitle={t("subscription.create.incSalesTaxLabel")}
          />
        )}
      </SubscriptionPanelContent>
    </Box>
  );

  const panelFooter = (
    <FooterContainer>
      <Typography variant="h6">
        {t("subscription.create.planFeaturesTitle")}
      </Typography>
      <PlanFeatureSummary plan={plan} />
    </FooterContainer>
  );

  return (
    <Grid item xs={12} md={6}>
      <Panel
        id="widget-plan-overview"
        title={t("subscription.create.planOverviewTitle")}
        content={panelContent}
        footer={panelFooter}
      />
    </Grid>
  );
};
PaymentSubscriptionSummary.propTypes = {
  plan: PlanPropTypes.isRequired,
  newUser: PropTypes.bool.isRequired,
  discount: PropTypes.shape({
    amount: PropTypes.number,
    numTrialDays: PropTypes.number,
  }),
};
export default PaymentSubscriptionSummary;
