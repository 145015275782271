import { Box, Grid, Typography } from "@material-ui/core";
import {
  ClientReportConfigTemplate,
  ScheduledReport,
  useDeleteScheduledReportMutation,
  useGetScheduledReportsQuery,
} from "~/store/mystore/clientReport.redux";
import CreateReport, {
  FREQUENCY_OPTIONS,
  FrequencyOption,
} from "~/pages/reports/createReport";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { BaseDialog } from "~/components/dialogs/baseDialog";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DATETIME_PERIOD_LABELS } from "~/store/persistentAppSettings.redux";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import ReportCell from "~/components/table/cells/reportCell";
import { ReportSentTableProps } from "./reportSentTable";
import SearchFilter from "~/components/adTable/searchFilter";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { getScheduledDescription } from "../../pages/reports/reportUtils";
import i18next from "i18next";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 25;

export interface ScheduledReportType {
  countryCode: string;
  createdAt: string;
  frequency: string;
  reportingDelay?: number;
  marketplace: string;
  mid?: string;
  period: DATETIME_PERIODS;
  reportDay: string;
  reportName: string;
  reportUrl: string;
  storeName: string;
  type: string;
  userId: string;
  timezone: string;
  _id: string;
  reportId?: string;
  reportRecipients: string[];
  user: {
    _id: string;
    firstName: string;
    lastName: string;
  };
}

export const getFrequency = (
  row: ClientReportConfigTemplate | ScheduledReportType,
  t: TFunction<"translation">
) => {
  const frequency = FREQUENCY_OPTIONS.find(
    (frequency: FrequencyOption) => frequency?.value === row?.frequency
  );
  return frequency ? t(frequency?.text) : "";
};

const ReportSentTable = memo(({ mid }: ReportSentTableProps) => {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);

  const [markedForDelete, setMarkedForDelete] = useState<{
    reportId: string;
    reportName: string;
  } | null>(null);

  const [markedForEdit, setMarkedForEdit] = useState<{
    reportId: string;
    reportName: string;
    report: ScheduledReportType;
  } | null>(null);

  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize: PAGE_SIZE,
    pageIndex: 0,
    sortKey: "createdAt",
    sortOrder: "desc",
  });

  const [search, setSearch] = useState<string | null>(null);
  const { isLoading, data, count } = useGetScheduledReportsQuery(
    {
      search,
      ...paginationParams,
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          data: data?.sentReports?.reportRows || [],
          count: data?.sentReports?.count || 0,
          isLoading: isFetching,
        };
      },
    }
  );

  const [deleteScheduledReport, {}] = useDeleteScheduledReportMutation();

  const handleOnFetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setPaginationParams({
        pageSize,
        pageIndex,
        sortKey: paginationParams.sortKey,
        sortOrder: paginationParams.sortOrder,
      });
    },
    []
  );

  const deleteReport = async () => {
    if (markedForDelete?.reportId) {
      await deleteScheduledReport({
        reportId: markedForDelete?.reportId,
        successMessage: t("scheduledReports.deleteSuccessMessage"),
      });
      setMarkedForDelete(null);
    }
  };

  const onMarkDeleted = ({
    reportId,
    reportName,
  }: {
    reportId: string;
    reportName: string;
  }) => {
    setMarkedForDelete({ reportId, reportName });
  };

  const onMarkEdited = ({
    reportId,
    reportName,
    report,
  }: {
    reportId: string;
    reportName: string;
    report: ScheduledReportType;
  }) => {
    setMarkedForEdit({ reportId, reportName, report });
  };

  const columns = useMemo(
    () => [
      {
        Header: t("createReport.formHeader"),
        accessor: (row: ScheduledReport) => ({
          reportId: row?.reportId,
          reportName: row?.reportName,
          storeCount: row?.stores?.length,
          reportLink: row?.latestReportUrl
            ? row?.latestReportUrl
            : row?.reportUrl,
        }),
        Cell: (props: any) => (
          <ReportCell
            {...props}
            report={props?.row?.original}
            onEdit={onMarkEdited}
            onDelete={onMarkDeleted}
          />
        ),
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.type"),
        accessor: "reportType",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.scheduledBy"),
        accessor: (row: ScheduledReportType) =>
          `${row.user.firstName} ${row.user.lastName}`,
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.createdAt"),
        id: "createdAt",
        accessor: "dateAndTime",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.selectDateRangeTitle"),
        id: "value",
        accessor: (row: ScheduledReportType) =>
          t(
            DATETIME_PERIOD_LABELS[
              row.period as unknown as keyof typeof DATETIME_PERIOD_LABELS
            ]
          ),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.reportingFrequency"),
        accessor: (row: ScheduledReportType) => getFrequency(row, t),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.selectReportingDelayTitle"),
        accessor: (row: ScheduledReportType) =>
          row?.reportingDelay ? `${row?.reportingDelay}d` : "-",
        Cell: TextCell,
        isVisible: false,
        align: "left",
      },
      {
        Header: t("createReport.reportingDay"),
        accessor: (row: ScheduledReportType) =>
          getScheduledDescription(
            row?.frequency,
            t,
            row?.timezone,
            row?.reportingDelay
          ),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.recipientEmail"),
        accessor: "recipientEmail",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
    ],
    []
  );

  const tableData = data
    ? data.map((report) => {
        const recipients = report.reportRecipients
          ? typeof report.reportRecipients === "string"
            ? report.reportRecipients
            : report.reportRecipients.join(",")
          : null;
        return {
          sentBy: `${userInfo?.firstName}  ${userInfo?.lastName}`,
          dateAndTime: moment(report?.createdAt)
            .locale(i18next.language)
            .format("ll LT"),
          recipientEmail: report.reportRecipients ? recipients : userInfo.email,
          ...report,
        };
      })
    : [];

  return (
    <Grid item xs={12}>
      <Panel
        id="scheduled-report-table"
        title={t("createReport.scheduledReports")}
        tooltip={t("createReport.scheduledReportsTooltip")}
        footerLink={undefined}
        content={
          <Box>
            <Table
              {...{
                columns: columns,
                data: tableData,
                fetchData: handleOnFetchData,
                pagination: true,
                pageSize: PAGE_SIZE,
                pageCount: Math.ceil(count / PAGE_SIZE),
                loading: isLoading,
              }}
            />
          </Box>
        }
        actions={
          <>
            <SearchFilter setSearchText={setSearch} />
            <ConfirmDialog
              open={Boolean(markedForDelete)}
              title={t("generic.confirmTitle")}
              onClose={() => setMarkedForDelete(null)}
              onConfirm={deleteReport}
              content={
                <>
                  <Typography variant="body1">
                    {t("createReport.removeScheduledReportMessage", {
                      reportName: markedForDelete?.reportName,
                    })}
                  </Typography>
                </>
              }
            />
            <Box>
              <BaseDialog
                open={Boolean(markedForEdit)}
                onClose={() => setMarkedForEdit(null)}
              >
                <CreateReport
                  isEdit={true}
                  editReport={markedForEdit ? markedForEdit?.report : undefined}
                  onClose={() => setMarkedForEdit(null)}
                />
              </BaseDialog>
            </Box>
          </>
        }
      />
    </Grid>
  );
});

export default ReportSentTable;
