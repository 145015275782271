import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";
import SyncResultCell from "./syncResultCell";
import SyncStatusCell from "./syncStatusCell";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const formatDate = (date) => moment(date).format("MMM D LTS");

const SyncStatusTable = memo(({ data }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        id: "SubmittedDate",
        Header: t("myStoresWidget.buyBoxSync.submittedColumn"),
        accessor: (row) => formatDate(row.SubmittedDate),
        Cell: TextCell,
        customWidth: 150,
      },
      {
        id: "FeedSubmissionId",
        Header: t("myStoresWidget.buyBoxSync.requestIdColumn"),
        accessor: "FeedSubmissionId",
        Cell: TextCell,
        hiddenDown: "sm",
        customWidth: 150,
      },
      {
        id: "processingSummary",
        Header: t("myStoresWidget.buyBoxSync.statusColumn"),
        accessor: "processingSummary",
        Cell: SyncStatusCell,
        customWidth: 150,
      },
      {
        id: "processingResult",
        Header: "",
        accessor: "processingResult",
        Cell: SyncResultCell,
        hiddenDown: "sm",
        customWidth: 200,
        colSpan: 2,
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      pageSize={10}
      getCellProps={() => ({
        style: {
          height: "auto",
        },
      })}
    />
  );
});

SyncStatusTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SyncStatusTable;
