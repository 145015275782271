const moment = require("moment");
const _ = require("lodash");

const isLegacySubscription = (subscription) => {
  return _.get(subscription, "planId", "").includes("legacy");
};

const isLegacyPlan = (plan) => {
  return _.get(plan, "id", "").includes("legacy");
};

/** Check that the user has a status that entitles them to access features.
 * e.g. 'Active'
 * @param {Object} subscription - an object representing the user's subscription information
 * @return {boolean} - true iff the user has an appropriate status. */
const checkUserStatus = (subscription) => {
  if (!subscription || !subscription.status) return false;
  if (
    (subscription.status === "Canceled" || subscription.status === "Expired") &&
    moment(subscription.nextBillingDate).isBefore(new Date())
  )
    return false;
  if (subscription.status === "PastDue") {
    const now = moment();
    const failureDate = subscription.statusHistory
      .filter((val) => val.status === "PastDue")
      .map((val) => moment(val.timestamp))
      .sort()
      .pop();
    if (now.isAfter(failureDate)) {
      return false;
    }
  }
  return true;
};

module.exports = {
  checkUserStatus,
  isLegacyPlan,
  isLegacySubscription,
};
