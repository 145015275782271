import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SalesByCountryTable from "~/modules/widgets/salesInsights/salesByCountryTable";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 10;

const SalesByCountry = memo(function SalesByCountry() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state?.persistentAppSettings?.setting?.data?.includeTax)
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  return (
    <PageBlock>
      {store ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SalesByCountryTable
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              marketplaceCountry={store.marketplaceCountry}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              pageSize={PAGE_SIZE}
              includeTax={includeTax}
            />
          </Grid>
        </Grid>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default SalesByCountry;
