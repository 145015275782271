import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ColumnSelect from "~/components/adTable/columnSelect";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import Panel from "~/components/panel/panel.tsx";
import PropTypes from "prop-types";
import SearchFilter from "~/components/adTable/searchFilter";
import StatusCell from "./statusCell";
import Table from "~/components/adTable/table";
import { fetchMarketingCampaignNegativeKeywords } from "../../store/mystore/marketing.redux";
import { formatAdType } from "./commonColumns";
import get from "lodash/get";
import { useReportDateTo } from "./useReportDate";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

const CampaignNegativeKeywordSummaryPanel = memo(
  ({ campaignId, countryCode, mid }) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state.user);
    const store = useSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const marketplaceType = get(store, "marketplace");
    const marketplaceSubtype = get(store, "marketplaceSubtype");
    const marketing = useSelector((state) => get(state, "marketing"));
    const loading = useSelector((state) =>
      get(state, "marketing.campaignNegativeKeywords.loading")
    );
    const reportDate = useReportDateTo();
    const dispatch = useDispatch();

    const columns = useMemo(
      () => [
        {
          Header: t("advertisingDashboardWidget.adTable.typeColumn"),
          id: "ad_type",
          accessor: (row) => formatAdType(row.ad_type, t),
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.keywordTextColumn"),
          id: "keyword_text",
          accessor: "keyword_text",
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.matchTypeColumn"),
          id: "match_type",
          accessor: "match_type",
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.statusColumn"),
          id: "keyword_status",
          accessor: (row) => ({
            value: row.keyword_status,
          }),
          Cell: (props) => <StatusCell {...props} />,
          isVisible: true,
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const [myColumns, setMyColumns] = useState(columns);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchMarketingCampaignNegativeKeywords(
            userInfo._id,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            reportDate,
            campaignId,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        campaignId,
        userInfo._id,
        mid,
        countryCode,
        marketplaceType,
        marketplaceSubtype,
        reportDate,
        searchText,
      ]
    );

    return (
      <Panel
        id="widget-marketing-negative-keyword-summary"
        title={t(
          "advertisingDashboardWidget.campaignNegativeKeywords.mainTitle"
        )}
        content={
          <Table
            columns={myColumns}
            data={get(marketing, "campaignNegativeKeywords.data", [])}
            fetchData={fetchData}
            loading={loading}
            sorting={true}
            pagination={true}
            pageCount={Math.ceil(
              get(marketing, "campaignNegativeKeywords.count", 0) / PAGE_SIZE
            )}
            pageSize={PAGE_SIZE}
          />
        }
        actions={
          <>
            <SearchFilter setSearchText={setSearchText} />
            <ColumnSelect columns={myColumns} setColumns={setMyColumns} />
            <DownloadCsv
              mid={store.merchantId}
              reportType={"negativeKeyword"}
              path={"/api/amazon/advertising/campaignNegativeKeyword"}
              params={{
                campaignId,
                mid,
                countryCode,
                marketplaceType,
                marketplaceSubtype,
                searchText,
                toDate: reportDate,
                shopName: store.storeName,
              }}
            />
          </>
        }
      />
    );
  }
);

CampaignNegativeKeywordSummaryPanel.propTypes = {
  campaignId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  mid: PropTypes.string.isRequired,
};
export default CampaignNegativeKeywordSummaryPanel;
