import React, { memo } from "react";

import Medium from "../../typography/medium";
import { PaletteColor } from "@material-ui/core/styles/createPalette";
import { StatusProps } from "~/typedef/status";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";

export const NO_DATA = Number.NEGATIVE_INFINITY;

const NoDataCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  padding: 7px;

  @media print {
    min-width: 40px;
  }
`;

const Cell = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  padding: 7px;
  border-radius: 40px;
  background-color: ${({ theme, $status }) => theme.palette[$status].main};
  color: ${({ theme, $status }) =>
    (theme.palette[$status] as PaletteColor).contrastText};

  @media print {
    min-width: 40px;
  }
`;

interface AvailabilityCellProps {
  cell: {
    value: {
      availableValue: number;
      compareValue?: number;
      conditionalFormatting?: boolean;
      threshold?: number;
      hideUnits?: boolean;
    };
  };
}

export const AvailabilityCell = memo<AvailabilityCellProps>(
  function AvailabilityCell({ cell }) {
    const {
      availableValue,
      compareValue,
      conditionalFormatting,
      threshold,
      hideUnits,
    } = cell.value;

    const noData = availableValue === NO_DATA;

    const status =
      availableValue === 0
        ? "error"
        : conditionalFormatting === false ||
          !Number.isFinite(Number(compareValue)) ||
          !threshold
        ? "success"
        : Number(compareValue) < Number(threshold) / 2
        ? "error"
        : Number(compareValue) < Number(threshold)
        ? "warning"
        : "success";

    return noData ? (
      <NoDataCell>
        <Medium variant="body2" noWrap color="inherit">
          -
        </Medium>
      </NoDataCell>
    ) : (
      <Cell $status={status}>
        <Medium variant="body2" noWrap color="inherit">
          {numberWithCommas(availableValue)} {hideUnits ? null : "in stock"}
        </Medium>
      </Cell>
    );
  }
);
