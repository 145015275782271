import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  formatCurrency,
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import { InfoOutlined } from "@material-ui/icons";
import LargeNumber from "./largeNumber";
import { LightHeading } from "./styledTypography";
import StatusText from "../typography/status";
import { getGrowthStatus } from "./genericTotal";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const InlineIconButton = styled(InfoOutlined)`
  cursor: pointer;
  font-size: 1rem;
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface TotalSalesProps {
  title: string;
  tooltip?: string;
  comparisonPeriod?: COMPARISON_PERIOD;
  current?: number | null;
  prior?: number | null;
  currency?: string;
  currentCurrency: string;
  condensedComparison?: boolean;
  condensed?: boolean;
  rounded?: boolean;
  conditionalFormatting?: boolean;
  lockLayout?: boolean;
  reverseFormatting?: boolean;
}

export const GenericTotalMoney = memo<TotalSalesProps>(
  function GenericTotalMoney({
    title,
    tooltip,
    current,
    comparisonPeriod,
    prior,
    currency = "AUD",
    currentCurrency,
    condensedComparison,
    condensed,
    rounded,
    conditionalFormatting,
    lockLayout,
    reverseFormatting,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const [totalSales, setTotalSales] = useState("-");
    const [growth, setGrowth] = useState<string | number>("N/A");
    const headingContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const sales = current || "-";

      const lastPeriodSales = prior || "-";

      const totalSalesValue = getConvertedValue(
        currencyRates,
        currency,
        currentCurrency,
        sales
      );

      const lastPeriodSalesValue = getConvertedValue(
        currencyRates,
        currency,
        currentCurrency,
        lastPeriodSales
      );

      const convertedTotalSales = rounded
        ? formatCurrencyRounded(sales, currencyRates, currency, currentCurrency)
        : formatCurrency(sales, currencyRates, currency, currentCurrency);

      const growthValue = getPercentageDifference(
        totalSalesValue,
        lastPeriodSalesValue
      );

      setTotalSales(convertedTotalSales);
      setGrowth(growthValue);
    }, [currentCurrency, currencyRates, current, prior, currency]);

    const status = getGrowthStatus(growth, reverseFormatting);

    return (
      <Grid
        container
        alignItems="center"
        justifyContent={smDown ? "center" : "flex-start"}
        spacing={condensed ? 1 : 2}
        id="total-generic-money-wrapper"
      >
        <Grid
          container
          item
          wrap="wrap"
          alignItems="center"
          xs={lockLayout ? 12 : 6}
          md={12}
        >
          <Box mr="5px">
            <Typography variant="h3">{title}</Typography>
          </Box>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InlineIconButton aria-label="info" />
            </Tooltip>
          )}
        </Grid>
        <Grid
          container
          item
          wrap="wrap"
          alignItems="center"
          xs={lockLayout ? 12 : 6}
          md={12}
          ref={headingContainerRef}
        >
          <LargeNumber
            value={numberWithCommas(totalSales)}
            headingContainerRef={headingContainerRef}
          />
          <LightHeading variant="h3" color="textSecondary">
            {currentCurrency}
          </LightHeading>
        </Grid>
        {comparisonPeriod && (
          <Grid
            container
            item
            wrap="wrap"
            alignItems="center"
            xs={lockLayout ? 12 : 6}
            md={12}
          >
            {!condensedComparison && (
              <Box mr="5px">
                <Typography variant="h3" color="textSecondary">
                  {t("dashboardWidget.salesPerformance.priorPeriod")}
                </Typography>
              </Box>
            )}
            <Tooltip title="Percentage change compared to the equivalent prior period">
              <StatusText
                variant="h5"
                status={conditionalFormatting === false ? undefined : status}
              >
                {!isNaN(growth as number) && (growth as number) > 0 && (
                  <UpArrow fontSize="inherit" status={status} />
                )}
                {!isNaN(growth as number) && (growth as number) < 0 && (
                  <DownArrow fontSize="inherit" status={status} />
                )}
                {isNaN(growth as number) ? growth : Math.abs(growth as number)}
                {!isNaN(growth as number) && "%"}
              </StatusText>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default GenericTotalMoney;
