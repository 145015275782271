import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";

import CategoryValue from "./categoryValue";
import { InlineIconButton } from "~/icons/inlineIconButton";
import Light from "~/components/typography/light";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { percentageFormatter } from "./categoryUtils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RowGrid = styled(Grid)`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
`;

export const CategoryRow = memo(
  ({
    countryCode,
    category,
    data,
    level,
    totalRevenue,
    tooltip,
    currentCurrency,
    report,
  }) => {
    const { t } = useTranslation();

    const currencyRates = useSelector((state) => state.globalVar.currencyRates);
    const sourceCurrency = getCurrencyByCountryCode[countryCode] || "AUD";
    const total = category.children.reduce((acc, child) => {
      const matchingEvent = data.events.find((e) => e.label === child.label);
      return (
        acc +
        // add value to accumulator if type is the same, subtract otherwise
        (category.type === child.type ? 1 : -1) * get(matchingEvent, "value", 0)
      );
    }, 0);

    const formatCurrencyValue = (value) =>
      isNaN(value) || value === 0.0
        ? "-"
        : isEmpty(currencyRates)
        ? `$${intFormatter.format(value)}` // only used in storybook
        : formatCurrency(value, currencyRates, sourceCurrency, currentCurrency);

    return (
      <RowGrid container item xs={12}>
        <Grid container item xs={5}>
          <Box p={1} pl={2} display="flex" alignItems="center">
            {level === 1 ? (
              <>
                <Typography variant="body1" color="textSecondary">
                  {t(`profitability.${category.label}`)}
                </Typography>
                {!report && (
                  <Tooltip title={t(tooltip)}>
                    <InlineIconButton />
                  </Tooltip>
                )}
              </>
            ) : (
              <Light variant="body2" color="textSecondary">
                {t(`profitability.${category.label}`)}
              </Light>
            )}
          </Box>
        </Grid>
        <CategoryValue
          level={level}
          value={formatCurrencyValue(total)}
          colSpan={2}
        />
        <CategoryValue
          level={level}
          value={
            total !== 0 && totalRevenue > 0
              ? `${percentageFormatter.format((100 * total) / totalRevenue)}%`
              : "-"
          }
          colSpan={2}
        />
        <CategoryValue
          level={level}
          value={formatCurrencyValue(total / data.meta.unitCount)}
          colSpan={3}
        />
      </RowGrid>
    );
  }
);

CategoryRow.propTypes = {
  countryCode: PropTypes.string,
  category: PropTypes.object,
  data: PropTypes.shape({
    meta: PropTypes.shape({
      orderCount: PropTypes.number,
      unitCount: PropTypes.number,
      refundedUnitCount: PropTypes.number,
    }),
    events: PropTypes.array,
  }),
  level: PropTypes.number,
  totalRevenue: PropTypes.number,
  tooltip: PropTypes.string,
  currentCurrency: PropTypes.string,
  report: PropTypes.bool,
};

export default CategoryRow;
