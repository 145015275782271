/* eslint-disable no-undef */
// Note: marketplace character count should not exceed 13

import { allMarketplaces as miraklMarketplaces } from "mm-mirakl-common/dist/shared/marketplaceConstants";
import sortBy from "lodash/sortBy";

interface Marketplace {
  group: string;
  country: string;
  name: string;
  marketplace: string;
  parentMarketplace: string;
  featureFlag?: string;
}

// Marketplace connectors turned off on production
const featureFlaggedMarketPlaces: Marketplace[] = [];

const globalMarketplaces = [
  ...miraklMarketplaces.filter((item) => item.group === "Global"),
  {
    group: "Global",
    country: "Global",
    name: "WooCommerce",
    marketplace: "woocommerce",
    parentMarketplace: "woocommerce",
  },
  {
    group: "Global",
    country: "Global",
    name: "Amazon Seller Central",
    marketplace: "amazon",
    parentMarketplace: "amazon",
  },
  {
    group: "Global",
    country: "Global",
    name: "Amazon Vendor Central",
    marketplace: "amazon_vendor",
    parentMarketplace: "amazon_vendor",
  },
  {
    group: "Global",
    country: "Global",
    name: "eBay",
    marketplace: "ebay",
    parentMarketplace: "ebay",
  },
  {
    group: "Global",
    country: "Global",
    name: "Mercado Libre",
    marketplace: "mercadolibre",
    parentMarketplace: "mercadolibre",
  },
  {
    group: "Global",
    country: "Global",
    name: "Shopify",
    marketplace: "shopify",
    parentMarketplace: "shopify",
  },
  {
    group: "Global",
    country: "Global",
    name: "Magento",
    marketplace: "magento",
    parentMarketplace: "magento",
  },
  {
    group: "Global",
    country: "Global",
    name: "BigCommerce",
    marketplace: "bigcommerce",
    parentMarketplace: "bigcommerce",
  },
  {
    group: "Global",
    country: "Global",
    name: "Wayfair (BETA)",
    marketplace: "wayfair",
    parentMarketplace: "wayfair",
  },
];

const asiaPacificMarketplaces = [
  ...miraklMarketplaces.filter((item) => item.group === "Asia Pacific"),
  {
    group: "Asia Pacific",
    country: "Australia",
    name: "Iconic",
    marketplace: "iconic",
    parentMarketplace: "iconic",
  },
  {
    group: "Asia Pacific",
    country: "Australia",
    name: "Kogan",
    marketplace: "kogan",
    parentMarketplace: "kogan",
  },
  {
    group: "Asia Pacific",
    country: "Australia",
    name: "MyDeal",
    marketplace: "mydeal",
    parentMarketplace: "mydeal",
  },
  // note: hide TheMarket from UI options - connection facade is still present,
  // and should work again once this is uncommented
  // {
  //   group: "Asia Pacific",
  //   country: "New Zealand",
  //   name: "TheMarket (BETA)",
  //   marketplace: "themarket",
  //   parentMarketplace: "themarket",
  // },
  {
    group: "Asia Pacific",
    country: "New Zealand",
    name: "Trade Me",
    marketplace: "trademe",
    parentMarketplace: "trademe",
  },
  {
    group: "Asia Pacific",
    country: "Japan",
    name: "Yahoo",
    marketplace: "yahoojapan",
    parentMarketplace: "yahoojapan",
    featureFlag: "yahooJapanOn",
  },
  // note: excluded
  // {
  //   group: "Asia Pacific",
  //   country: "Thailand",
  //   name: "Central Group",
  //   marketplace: "centralgroup",
  // },
];

const europeMarketplaces = [
  ...miraklMarketplaces.filter((item) => item.group === "Europe"),
  {
    group: "Europe",
    country: "Poland",
    name: "Allegro (BETA)",
    marketplace: "allegro",
    parentMarketplace: "allegro",
    featureFlag: "allegroConnectorOn",
  },
  {
    group: "Europe",
    country: "Netherlands",
    name: "bol.com",
    marketplace: "bol",
    parentMarketplace: "bol",
  },
  {
    group: "Europe",
    country: "France",
    name: "Cdiscount",
    marketplace: "cdiscount",
    parentMarketplace: "cdiscount",
  },
  {
    group: "Europe",
    country: "Germany",
    name: "Kaufland (BETA)",
    marketplace: "kaufland",
    parentMarketplace: "kaufland",
  },
  {
    group: "Europe",
    country: "France",
    name: "ManoMano",
    marketplace: "manomano",
    parentMarketplace: "manomano",
  },
  {
    group: "Europe",
    country: "Germany",
    name: "Otto (BETA)",
    marketplace: "otto",
    parentMarketplace: "otto",
    featureFlag: "ottoConnectorOn",
  },
  {
    group: "Europe",
    country: "Italy",
    name: "Privalia (BETA)",
    marketplace: "privalia",
    parentMarketplace: "pinkConnect",
  },
  {
    group: "Europe",
    country: "Italy",
    name: "Veepee (BETA)",
    marketplace: "veepee",
    parentMarketplace: "pinkConnect",
  },
  {
    group: "Europe",
    country: "Germany",
    name: "Zalando",
    marketplace: "zalando",
    parentMarketplace: "zalando",
  },
  // note: excluded
  // {
  //   group: "Europe",
  //   country: "France",
  //   name: "EMMA",
  //   marketplace: "emma",
  // },
  // {
  //   group: "Europe",
  //   country: "Netherlands",
  //   name: "Voelkner – Anmelden",
  //   marketplace: "voelkneranmelden",
  // },
];

const northAmericaMarketplaces = [
  ...miraklMarketplaces.filter((item) => item.group === "North America"),
  {
    group: "North America",
    country: "United States",
    name: "Sears Marketplace (BETA)",
    marketplace: "sears",
    parentMarketplace: "sears",
  },
  {
    group: "North America",
    country: "United States",
    name: "Walmart USA",
    marketplace: "walmart",
    parentMarketplace: "walmart",
  },
  // note: excluded
  // {
  //   group: "North America",
  //   country: "United States",
  //   name: "Kroger",
  //   marketplace: "kroger",
  // },
];

const filteredChannels = [
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Amazon Seller Central Filtered",
    marketplace: "amazon_filtered",
    parentMarketplace: "amazon_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Amazon Vendor Central Filtered",
    marketplace: "amazon_vendor_filtered",
    parentMarketplace: "amazon_vendor_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "bol.com Filtered",
    marketplace: "bol_filtered",
    parentMarketplace: "bol_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "eBay Filtered",
    marketplace: "ebay_filtered",
    parentMarketplace: "ebay_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Mercado Libre Filtered",
    marketplace: "mercadolibre_filtered",
    parentMarketplace: "mercadolibre_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Mirakl Filtered",
    marketplace: "mirakl_filtered",
    parentMarketplace: "mirakl_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Walmart Filtered",
    marketplace: "walmart_filtered",
    parentMarketplace: "walmart_filtered",
  },
  {
    group: "Filters (via custom groups)",
    country: "Filtered Channel",
    name: "Zalando Filtered",
    marketplace: "zalando_filtered",
    parentMarketplace: "zalando_filtered",
  },
  {
    group: "Filtered Channel (based on Custom Groups)",
    country: "Filtered Channel",
    name: "Zalando Filtered (Global)",
    marketplace: "zalando_all_filtered",
    parentMarketplace: "zalando_all_filtered",
  },
];
export const miraklMarketplaceArray = [
  ...miraklMarketplaces.map((item) => item.marketplace),
];

const southAmericanMarketplaces = [
  ...miraklMarketplaces.filter((item) => item.group === "South America"),
];

const sortedGlobalMarketplaces = sortBy(globalMarketplaces, (market) =>
  market.name.toLowerCase()
);
const sortedAPMarketplaces = sortBy(asiaPacificMarketplaces, (market) =>
  market.name.toLowerCase()
);
const sortedEuroMarketplaces = sortBy(europeMarketplaces, (market) =>
  market.name.toLowerCase()
);
const sortedNAMarketplaces = sortBy(northAmericaMarketplaces, (market) =>
  market.name.toLowerCase()
);
const sortedSAMarketplaces = sortBy(southAmericanMarketplaces, (market) =>
  market.name.toLowerCase()
);
const sortedFilteredChannels = sortBy(filteredChannels, (market) =>
  market.name.toLowerCase()
);

const combinedMarketplaces = [
  ...sortedFilteredChannels,
  ...sortedGlobalMarketplaces,
  ...sortedAPMarketplaces,
  ...sortedEuroMarketplaces,
  ...sortedNAMarketplaces,
  ...sortedSAMarketplaces,
];

export const marketplaceOnMultipleRegions = ["carrefour", "leroymerlin"];

export const otherMarketplaces: Marketplace[] = [
  ...combinedMarketplaces,
  ...(process.env.TARGET_ENV !== "production"
    ? featureFlaggedMarketPlaces
    : []),
];
