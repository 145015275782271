import { InputAdornment, TextField } from "@material-ui/core";
import React, { memo } from "react";

import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import styled from "styled-components";

const SearchContainer = styled.div`
  margin-right: 5px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  height: 20px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
interface SearchFilterProps {
  setSearchText: (searchText: string) => void;
}
const SearchFilter = memo(({ setSearchText }: SearchFilterProps) => {
  const DEBOUNCE_DELAY_MSEC = 1000;
  const debouncedSetSearchText = debounce(setSearchText, DEBOUNCE_DELAY_MSEC);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSetSearchText(event.target.value.trim());
  };

  const onKeyDown = (e: any) => {
    e.stopPropagation();
  };

  return (
    <SearchContainer>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        autoFocus={true}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
    </SearchContainer>
  );
});

export default SearchFilter;
