import { Box, Grid, useTheme } from "@material-ui/core";
import React, { memo } from "react";
import {
  TrafficAndConversionProductTrend,
  useTrafficAndConversionProductTrendQuery,
} from "~/store/mystore/trafficAndConversion.redux";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import MultiBarLineChart from "~/components/charts/multiBarLineChart/multiBarLineChart";
import { Range } from "~/typedef/store";
import { TrafficAndConversionTableView } from "./trafficAndConversionTable";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface TrafficAndConversionTableChartProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  view: TrafficAndConversionTableView;
  productId: number;
  currentCurrency: string;
}

const formatTrafficAndConversionProductData = (
  trafficAndConversionProductData:
    | TrafficAndConversionProductTrend[]
    | undefined
) => {
  if (!trafficAndConversionProductData) {
    return [];
  }
  return trafficAndConversionProductData.map((data) => ({
    startTime: data.startTime,
    pageViews: Number.isFinite(data.pageViews)
      ? Math.round(data.pageViews)
      : data.pageViews,
    unitsPerView: Number.isFinite(data.unitsPerView)
      ? Math.min(Math.round(data.unitsPerView), 100.0)
      : data.unitsPerView,
    totalSessions: Number.isFinite(data.totalSessions)
      ? Math.round(data.totalSessions)
      : data.totalSessions,
    unitsPerSession: Number.isFinite(data.unitsPerSession)
      ? Math.min(Math.round(data.unitsPerSession), 100.0)
      : data.unitsPerSession,
    buyboxWinPercentage: Number.isFinite(data.buyboxWinPercentage)
      ? Math.round(data.buyboxWinPercentage)
      : data.buyboxWinPercentage,
  }));
};

const TrafficAndConversionTableChart =
  memo<TrafficAndConversionTableChartProps>(
    function TrafficAndConversionTableChart({
      mid,
      marketplaceType,
      marketplaceSubtype,
      currentRange,
      currentPeriod,
      view,
      productId,
      currentCurrency,
    }) {
      const { t } = useTranslation();
      const theme = useTheme();

      const currencyRates = useTypedSelector(
        (state) => state.globalVar.currencyRates
      );

      const { data, loading } = useTrafficAndConversionProductTrendQuery(
        {
          mid,
          marketplaceFilters: {
            marketplaceType,
            marketplaceSubtype,
          },
          currentRange,
          view,
          productId,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            data: data?.data
              ? formatTrafficAndConversionProductData(data.data)
              : [],
            loading: isFetching,
          }),
        }
      );

      return (
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              <MultiBarLineChart
                title={t(CHART_TITLES[currentPeriod])}
                currentPeriod={currentPeriod}
                currencyRates={currencyRates}
                currentCurrency={currentCurrency}
                chartData={data}
                isLoading={loading}
                bars={[
                  {
                    key: "pageViews",
                    colour: theme.palette.primary.main,
                    axis: "1",
                  },
                ]}
                lines={[
                  {
                    key: "unitsPerView",
                    colour: theme.palette.secondary.main,
                    axis: "2",
                    toFixed: 1,
                  },
                  {
                    key: "unitsPerSession",
                    colour: theme.palette.success.dark,
                    axis: "2",
                    toFixed: 1,
                  },
                  {
                    key: "buyboxWinPercentage",
                    colour: theme.palette.chart.red,
                    axis: "2",
                    toFixed: 1,
                  },
                ]}
                xKey="startTime"
                report={true}
                timezone={currentRange.timezone}
                interval={currentRange.interval}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }
  );

export default TrafficAndConversionTableChart;
