import { GridJustification, Typography, useTheme } from "@material-ui/core";
import React, { memo, useCallback, useMemo } from "react";
import { StoreState, WithFetching } from "~/typedef/store";
import { useDispatch, useSelector } from "react-redux";

import ColouredPercentCell from "~/components/table/cells/colouredPercentCell";
import { Column } from "~/components/adTable/columnSelect";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { StarRatingCell } from "~/components/table/cells/starRatingCell";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { fetchListingQualityProducts } from "~/store/mystore/listingQuality.redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 25;

// This interface was copied over from mm-walmart-service/mm-walmart-refresh-service - it could be out of date
export interface ListingQualityProduct {
  mid: string;
  productName: string;
  imageUrl: string;
  productId: string;
  sku: string;
  itemId: string;
  itemPageUrl: string;
  starRating: number | null;
  averageRating: number;
  listingQualityScore: number;
  contentDiscoveryScore: number;
  offerScore: number;
  reviewRatingScore: number;
  pageViews: number;
  issueCount: number;
  titleLength: number;
  descriptionLength: number;
  imagesCount: number;
  reviewsCount: number;
  lifecycleStatus: string;
  savedAt: string; // This gets added to the data as it is saved into mongo in mm-walmart-refresh-service
}
// This is the shape of the data formatted as the frontend needs it
type ProductDataRow = Omit<
  ListingQualityProduct,
  | "itemPageUrl"
  | "starRating"
  | "averageRating"
  | "imageUrl"
  | "starRatingPercent"
  | "mid"
  | "productName"
  | "productId"
  | "sku"
  | "itemId"
  | "savedAt"
> & {
  productName: {
    value: string;
    secondRowValue: string;
    link: string;
    image: string;
    target: "_blank";
  } & Pick<ListingQualityProduct, "starRating" | "averageRating">;
};

function mapToProductDataRow(data: ListingQualityProduct): ProductDataRow {
  return {
    productName: {
      value: data.productName,
      secondRowValue: `SKU: ${data.sku}  |  ITEM ID: ${data.itemId}`,
      link: data.itemPageUrl,
      image: data.imageUrl,
      target: "_blank",
      starRating: data.starRating,
      averageRating: data.averageRating,
    },
    listingQualityScore: data.listingQualityScore,
    contentDiscoveryScore: data.contentDiscoveryScore,
    offerScore: data.offerScore,
    reviewRatingScore: data.reviewRatingScore,
    pageViews: data.pageViews,
    issueCount: data.issueCount,
    titleLength: data.titleLength,
    descriptionLength: data.descriptionLength,
    imagesCount: data.imagesCount,
    reviewsCount: data.reviewsCount,
    lifecycleStatus: data.lifecycleStatus,
  };
}

interface ListingQualityProductTable {
  searchText: string;
  statusFilter: string | null;
  mid: string;
  countryCode: string;
}

const ListingQualityProductTable: React.FC<ListingQualityProductTable> = ({
  searchText,
  statusFilter,
  mid,
  countryCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const columns: Array<Column> = useMemo(
    () => [
      {
        Header: t("myStoresWidget.listingQuality.productsTable.product"),
        accessor: "productName",
        Cell: (props: TableCellProp<ProductDataRow["productName"]>) => {
          const { averageRating, starRating } = props.value;
          let thirdRowComponent;
          // if starRating is null, dont render a StarRatingCell, render message
          // 'No reviews'
          if (starRating === null || starRating === undefined) {
            thirdRowComponent = (
              <Typography
                align="left"
                variant="subtitle2"
                color="textSecondary"
              >
                No reviews
              </Typography>
            );
          } else {
            const starProps = {
              cell: {
                value: averageRating,
                starRating: starRating,
                justify: "flex-start" as GridJustification,
              },
            };
            thirdRowComponent = <StarRatingCell {...starProps} />;
          }
          return (
            <LinkAndImageCell
              {...{
                ...props,
                maxWidth: "250px",
                thirdRowComponent,
                colorVariant: "internal",
              }}
            />
          );
        },
        isVisible: true,
        sticky: "left",
      },
      {
        Header: t(
          "myStoresWidget.listingQuality.productsTable.listingQualityScore"
        ),
        wrapHeaderText: true,
        accessor: "listingQualityScore",
        Cell: ColouredPercentCell,
        isVisible: true,
        align: "center",
      },
      {
        Header: t(
          "myStoresWidget.listingQuality.productsTable.contentDiscoveryScore"
        ),
        wrapHeaderText: true,
        accessor: "contentDiscoveryScore",
        isVisible: true,
        Cell: (
          props: TableCellProp<ProductDataRow["contentDiscoveryScore"]>
        ) => (
          <ColouredPercentCell
            {...props}
            color={theme.palette.text?.primary}
            fontWeight={theme.typography.body1?.fontWeight}
          />
        ),
        align: "center",
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.offerScore"),
        wrapHeaderText: true,
        accessor: "offerScore",
        isVisible: true,
        align: "center",
        Cell: (props: TableCellProp<ProductDataRow["offerScore"]>) => (
          <ColouredPercentCell
            {...props}
            color={theme.palette.text?.primary}
            fontWeight={theme.typography.body1?.fontWeight}
          />
        ),
      },
      {
        Header: t(
          "myStoresWidget.listingQuality.productsTable.reviewRatingScore"
        ),
        wrapHeaderText: true,
        accessor: "reviewRatingScore",
        isVisible: true,
        align: "center",
        Cell: (props: TableCellProp<ProductDataRow["reviewRatingScore"]>) => (
          <ColouredPercentCell
            {...props}
            color={theme.palette.text?.primary}
            fontWeight={theme.typography.body1?.fontWeight}
          />
        ),
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.pageViews"),
        wrapHeaderText: true,
        accessor: "pageViews",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.issueCount"),
        accessor: "issueCount",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.titleLength"),
        wrapHeaderText: true,
        accessor: "titleLength",
        isVisible: true,
        align: "center",
      },
      {
        Header: t(
          "myStoresWidget.listingQuality.productsTable.descriptionLength"
        ),
        wrapHeaderText: true,
        accessor: "descriptionLength",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.imagesCount"),
        wrapHeaderText: true,
        accessor: "imagesCount",
        isVisible: true,
        align: "center",
      },
      {
        Header: t("myStoresWidget.listingQuality.productsTable.reviewsCount"),
        wrapHeaderText: true,
        accessor: "reviewsCount",
        isVisible: true,
        align: "center",
      },
      {
        Header: t(
          "myStoresWidget.listingQuality.productsTable.lifecycleStatus"
        ),
        accessor: "lifecycleStatus",
        isVisible: true,
        align: "center",
      },
    ],
    []
  );

  const dispatchFetchListingQualityProducts = useCallback<
    (args: {
      pageIndex: number;
      pageSize: number;
      sortBy: Array<{ id: string; desc: boolean }>;
    }) => void
  >(
    ({ pageIndex, pageSize, sortBy }) => {
      dispatch(
        fetchListingQualityProducts({
          mid,
          countryCode,
          searchText,
          statusFilter,
          sortKey: sortBy && sortBy.length && sortBy[0].id,
          sortOrder:
            sortBy && sortBy.length && (sortBy[0].desc ? "desc" : "asc"),
          pageIndex,
          pageSize,
        })
      );
    },
    [mid, searchText, statusFilter]
  );

  // rows and count are undefined until the query resolved hence Partial
  interface ListingQualityGenericProduct {
    rows: ListingQualityProduct[];
    count: number;
  }
  const products: WithFetching<Partial<ListingQualityGenericProduct>> =
    useSelector(
      (state: StoreState) =>
        get(state, "listingQualityGeneric.products") || {
          rows: [],
          fetching: false,
          count: 0,
        }
    );

  const { rows, fetching, count } = products;

  const data = useMemo(() => {
    if (rows) {
      return rows.map(mapToProductDataRow);
    } else {
      return [];
    }
  }, [rows]);

  const tableProps = {
    loading: fetching && !rows,
    columns,
    data,
    fetchData: dispatchFetchListingQualityProducts,
    sorting: true,
    pagination: true,
    pageSize: PAGE_SIZE,
    pageCount: Math.ceil((count || 0) / PAGE_SIZE),
    tdHeight: 60,
  };
  return <Table {...tableProps}></Table>;
};

export default memo(ListingQualityProductTable);
