import { CircularProgress, Icon, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import { ArrowBack } from "@material-ui/icons";
import { ConnectionProps } from "../connectWizard/new/storeConnectionPrompt";
import GenericConnectPageAuthForm from "./genericConnectPageAuthForm";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import RaisedButton from "~/components/buttons/raisedButton";
import { connectionStatus } from "~/utils/marketplaceUtils";
import crossLogo from "~/img/cross_large.png";
import { getMarketplaceConfig } from "./genericConnectPageConfig";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { CONNECTION_INIT, CONNECTION_FAILED, CONNECTION_SUCCEED } =
  connectionStatus;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const BackButton = styled(IconButton)`
  margin: -1rem;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const GenericConnectPage = memo<ConnectionProps>(function GenericConnectPage({
  onSuccess,
  setIsSubmitting,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [marketplaceConfig, setMarketplaceConfig] =
    useState<Record<string, any>>();
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [shopName, setShopName] = useState("");

  const [transaction, setTransaction] = useState({
    status: connectionStatus.CONNECTION_INIT,
    errMsg: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const marketplace = urlParams.get("marketplace");
    const submarketplace = urlParams.get("submarketplace");
    const name = urlParams.get("name");
    const country = urlParams.get("country");

    setMarketplaceConfig(
      getMarketplaceConfig(marketplace, submarketplace, name, country, t)
    );
  }, []);

  const onHandleSuccess = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const marketplace = urlParams.get("marketplace");

    /* Note: For Cdiscount connection, stop auto redirecting to Congratulations page (step=2).
      This allows users to read the info text that they have only sent a request to connect their stores.
    */
    if (marketplace !== "cdiscount") {
      onSuccess();
    }
  };

  return !marketplaceConfig ? (
    <CircularProgress />
  ) : (
    <Grid container>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item>
            {marketplaceConfig.logoLinkUrl ? (
              <a
                href={marketplaceConfig.logoLinkUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {marketplaceConfig.logo}
              </a>
            ) : (
              <>{marketplaceConfig.logo}</>
            )}
          </Grid>
          {transaction.status === CONNECTION_INIT && (
            <Grid container direction="column" alignItems="center">
              <Grid
                container
                item
                xs={11}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={1}>
                  <BackButton onClick={history.goBack} id="back-button">
                    <ArrowBack />
                  </BackButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                  >
                    {marketplaceConfig.messages.title}
                  </Typography>
                </Grid>
              </Grid>
              <GenericConnectPageAuthForm
                {...{
                  marketplaceConfig,
                  input1,
                  input2,
                  input3,
                  input4,
                  shopName,
                  setTransaction,
                  setInput1,
                  setInput2,
                  setInput3,
                  setInput4,
                  setShopName,
                  onSuccess: onHandleSuccess,
                  setIsSubmitting,
                }}
              />
            </Grid>
          )}
          {transaction.status === CONNECTION_FAILED && (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <Icon>
                    <img src={crossLogo} />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography color="error">{transaction.errMsg}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                <Typography color="error" variant="body1" align="center">
                  {marketplaceConfig.messages.retryMessage}
                </Typography>
              </Grid>
              <GenericConnectPageAuthForm
                {...{
                  marketplaceConfig,
                  input1,
                  input2,
                  input3,
                  input4,
                  shopName,
                  setTransaction,
                  setInput1,
                  setInput2,
                  setInput3,
                  setInput4,
                  setShopName,
                  onSuccess: onHandleSuccess,
                  setIsSubmitting,
                }}
              />
            </Grid>
          )}
          {transaction.status === CONNECTION_SUCCEED && (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <Icon>
                    <img src={tickLogo} />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                  >
                    {marketplaceConfig.messages.successMessage}
                  </Typography>
                </Grid>
              </Grid>
              <ButtonWrapper>
                <RaisedButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/overview")}
                >
                  {t("nav.dashboard")}
                </RaisedButton>
              </ButtonWrapper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default GenericConnectPage;
