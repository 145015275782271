import React, { useCallback, useEffect, useState } from "react";
import { Store, StoreState } from "~/typedef/store";
import {
  fetchParentStore,
  fetchPortfolios,
  useConnectBrandFilterAdvertisingMutation,
} from "~/store/connections/connectBrandFilter.redux";

import { AdvertisingPortfolio } from "~/typedef/brandFilterConnection";
import { Box } from "@material-ui/core";
import GenericConnectButton from "~/modules/login/genericConnectButton";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import PortfolioConnectInfo from "~/components/info/portfolioInfo";
import PortfolioMenu from "~/components/toolbars/toolbarComponents/portfolioDropdown";
import RaisedButton from "~/components/buttons/raisedButton";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import Typography from "@material-ui/core/Typography";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import uniq from "lodash/uniq";
import { useDispatch } from "react-redux";
import usePrevious from "~/hooks/usePrevious";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const ButtonWrapper = styled(Grid)`
  margin: 0 auto;
`;

interface AdvertisingProps {
  store: Store;
  onSuccess: (params?: any) => void;
  setIsSubmitting?: (bool: boolean) => void;
}

const BrandFilterAdvertisingConnectPage = ({
  onSuccess,
  store,
  setIsSubmitting,
}: AdvertisingProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const urlSearchParams = useQueryParams();
  const filteredStores = useTypedSelector(
    (state: StoreState) => state.mystore.filteredStores
  );
  const allStores = useTypedSelector(
    (state: StoreState) => state.mystore.allStores?.stores
  );
  const portfolios = useTypedSelector(
    (state: StoreState) => state.newBrandFilterStore.portfolios
  );
  const parentStore = useTypedSelector(
    (state: StoreState) => state.newBrandFilterStore.parentStore?.parentStore
  );
  // ID of connected portfolio, if it exists
  // i.e. this is a re-connection/change
  const connectedPortfolioIds = useTypedSelector(
    (state: StoreState) =>
      state.newBrandFilterStore.parentStore?.currentConnectedPortfolios
  );
  const portfoliosLoading = useTypedSelector(
    (state: StoreState) => state.newBrandFilterStore.loading
  );
  const parentStoreLoading = useTypedSelector(
    (state: StoreState) => state.newBrandFilterStore.parentStoreLoading
  );
  const [currentStore, setCurrentStore] = useState<Store | undefined>(store);

  const [selectedPortfolios, setSelectedPortfolios] = useState<
    AdvertisingPortfolio[]
  >([]);
  const previousStore: Store | undefined = usePrevious(currentStore);

  const errMsg = React.useRef("");

  const [
    connectBrandFilterAdvertisingStore,
    {
      connectingBrandFilterAdvertisingStore,
      brandFilterAdvertisingStoreConnected,
    },
  ] = useConnectBrandFilterAdvertisingMutation({
    selectFromResult: ({ isLoading, isSuccess }) => ({
      connectingBrandFilterAdvertisingStore: isLoading,
      brandFilterAdvertisingStoreConnected: isSuccess,
    }),
  });

  useEffect(() => {
    if (brandFilterAdvertisingStoreConnected) {
      fetchFilteredStores({}, true)(dispatch);
      onSuccess();
    }
  }, [brandFilterAdvertisingStoreConnected]);

  const changeStore = useCallback(
    (_market: string, mid: string = currentStore?.merchantId || ""): void => {
      const newStore: Store | undefined = filteredStores?.stores?.find(
        (store: Store) => store.merchantId === mid
      );

      if (newStore) {
        setCurrentStore(newStore);
      }
    },
    [filteredStores]
  );

  const changePortfolio = useCallback(
    (portfolioNames: string[]): void => {
      const filteredPortfolios = portfolios?.filter((p) =>
        portfolioNames.includes(p.name)
      );

      if (filteredPortfolios) {
        setSelectedPortfolios(filteredPortfolios);
      }
    },
    [portfolios]
  );

  const redirectNextStep = useCallback(() => {
    onSuccess();
  }, []);

  const handleSuccess = useCallback(async () => {
    if (currentStore && selectedPortfolios.length && parentStore != null) {
      setIsSubmitting && setIsSubmitting(true);
      connectBrandFilterAdvertisingStore({
        storeParams: {
          mid: currentStore.merchantId,
          marketplaceType: currentStore.marketplaceType,
          marketplaceSubtype: currentStore.marketplaceSubtype,
          marketplaceCountry: currentStore.marketplaceCountry,
        },
        portfolioIds: selectedPortfolios.map((p) => p.portfolioId),
        parentStoreParams: {
          mid: parentStore.mid,
          marketplaceType: parentStore.marketplaceType,
          marketplaceSubtype: parentStore.marketplaceSubtype,
          marketplaceCountry: parentStore.countryCode,
        },
      });
    }
  }, [currentStore, selectedPortfolios]);

  useEffect(() => {
    setCurrentStore(
      filteredStores?.stores.find(
        (store) => store.merchantId === urlSearchParams.get("mid")
      ) ??
        filteredStores?.stores.find(
          (fc) => fc.merchantId === store?.merchantId
        ) ??
        filteredStores?.stores.filter((store) =>
          store.marketplaceType.includes("filtered")
        )[0]
    );
    // deliberately don't include urlSearchParams in
    // dependency array - we don't want this to fire
    // as we're leaving the page.
  }, [filteredStores]);

  useEffect(() => {
    if (!isEqual(previousStore, currentStore) && currentStore?.merchantId) {
      dispatch(
        fetchPortfolios({
          mid: currentStore.merchantId,
          marketplaceType: currentStore.marketplaceType,
          marketplaceSubtype: currentStore.marketplaceSubtype,
          marketplaceCountry: currentStore.marketplaceCountry,
        })
      );
    }
  }, [previousStore, currentStore]);

  useEffect(() => {
    if (!isEqual(previousStore, currentStore) && currentStore?.merchantId) {
      dispatch(
        fetchParentStore({
          mid: currentStore.merchantId,
          marketplaceType: currentStore.marketplaceType,
          marketplaceSubtype: currentStore.marketplaceSubtype,
          marketplaceCountry: currentStore.marketplaceCountry,
        })
      );
    }
  }, [previousStore, currentStore]);

  const connectedPortfolioNames = portfolios
    ?.filter((p) => connectedPortfolioIds?.includes(p.portfolioId))
    ?.map((p) => p.name);

  return (
    <GridWrapper id="amazon-connect-wrapper">
      <Grid container spacing={5}>
        <Grid container item xs={12}>
          <PortfolioConnectInfo portfolioNames={connectedPortfolioNames} />
        </Grid>
        <Grid container item xs={12}>
          <Box pt={1} pb={1} width={1}>
            <StoreSelector
              changeStore={changeStore}
              currentStore={
                currentStore
                  ? {
                      marketplace: currentStore?.marketplaceType,
                      merchantId: currentStore?.merchantId,
                      sourceSystemId: currentStore?.merchantId.split(" @")[0],
                      marketplaceCountry: currentStore?.marketplaceCountry,
                      storeName: currentStore?.storeName,
                    }
                  : undefined
              }
              currentFilter={{
                marketplaces: uniq(
                  (allStores ?? [])
                    .map((s) => s.marketplaceType)
                    .filter((s) => hasFilteredSuffix(s))
                ),
                countries: [],
                tags: [],
              }}
              maxWidth={900}
              fullWidth={true}
              rightAlign={true}
            />
          </Box>
        </Grid>
        {currentStore && (
          <Grid container item xs={12}>
            <Box pt={0} pb={1} width={1}>
              {portfoliosLoading ||
              parentStoreLoading ||
              filteredStores.fetching ? (
                <LoadingIndicator size={30} />
              ) : !portfolios?.length || !parentStore ? (
                <Box display="flex" flexDirection="column">
                  <Medium align="center">{"No portfolios found"}</Medium>
                </Box>
              ) : (
                <PortfolioMenu
                  changePortfolios={changePortfolio}
                  currentPortfolioNames={selectedPortfolios.map((p) => p.name)}
                  portfolioNames={portfolios?.map((p) => p.name) || []}
                  maxWidth={350}
                  fullWidth={true}
                />
              )}
            </Box>
          </Grid>
        )}
        <ButtonWrapper container spacing={2} item xs={12}>
          <Grid container item xs={12} justifyContent="center">
            <GenericConnectButton
              disabled={!!isEmpty(selectedPortfolios)}
              onSuccess={handleSuccess}
            >
              {portfoliosLoading || connectingBrandFilterAdvertisingStore ? (
                <LoadingIndicator />
              ) : (
                t("connectWizard.createFilteredStore")
              )}
            </GenericConnectButton>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <RaisedButton
              id="wizard-skip-button"
              color="secondary"
              onClick={redirectNextStep}
              variant="outlined"
            >
              {t("generic.skipButton")}
            </RaisedButton>
          </Grid>
        </ButtonWrapper>
        <>
          {errMsg && errMsg.current && (
            <Grid container item xs={12}>
              <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default BrandFilterAdvertisingConnectPage;
