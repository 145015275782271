import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import { GrowthBlock } from "./valueAndGrowthCell";
import StatusText from "../../typography/status";
import styled from "styled-components";

export const getStatusFromACOS = (acos: any, conditionalFormatting?: boolean) =>
  conditionalFormatting === false
    ? "disabled"
    : acos
    ? acos === "-"
      ? "disabled"
      : acos <= 15
      ? "success"
      : acos <= 25
      ? "warning"
      : "error"
    : "disabled";

const getGrowthStatus = (
  growth: string | number,
  conditionalFormatting?: boolean
) => {
  if (growth === "N/A" || growth === "-" || conditionalFormatting === false) {
    return "disabled";
  }
  return Number(growth) > 0 ? "error" : "success";
};

export const Cell = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

interface ACOSCellProps {
  row: { original: any };
  cell: { value: any };
}

const ACOSCell = memo<ACOSCellProps>(function ACOSCell({ cell, row }) {
  const growth = cell.value.growth;
  const { value } = cell.value;
  const conditionalFormatting = cell.value.conditionalFormatting;
  const acos = growth ? cell.value.raw : row.original.acos;
  const status = getStatusFromACOS(acos, conditionalFormatting);
  const growthStatus = getGrowthStatus(growth);
  return (
    <Cell>
      <StatusText
        align="right"
        variant="body2"
        status={conditionalFormatting === false ? undefined : status}
      >
        {value}
      </StatusText>
      {growth && (
        <GrowthBlock>
          <StatusText variant="body2" align="right" paragraph={false}>
            {growthStatus === "success" && (
              <UpArrow fontSize="inherit" status="success" />
            )}
            {growthStatus === "error" && (
              <DownArrow fontSize="inherit" status="error" />
            )}
            {isNaN(growth) ? growth : Math.abs(growth)}
            {!isNaN(growth) && "ppt"}
          </StatusText>
        </GrowthBlock>
      )}
    </Cell>
  );
});

export default ACOSCell;
