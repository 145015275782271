import { Tooltip } from "@material-ui/core";
import React, { memo, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

type SortingChip = Omit<ChipItemProps, "updateWidth" | "order"> & {
  originalIndex: number;
  width: number;
};
type SortingChips = SortingChip[];
/**
 * If you have a container with width 10, and the following width chips: [6,7,2,3],
 * the job of this function is to return the chips in this order [7,2,3,6] so the chips can be displayed
 * on the minimum amount of rows, ie 2 rows, with rows alternating as to whether longest or shortest content is
 * displayed first (per the design).
 */
function sortChips(chips: SortingChips, width: number): SortingChips {
  // sortedByWidth[0] has the greatest width
  const sortedByWidth = chips.sort((a, b) => b.width - a.width);

  const sorted: SortingChips = [];
  let rowIndex = 0;
  while (sortedByWidth[0]) {
    const row: SortingChips = [];
    const longest = sortedByWidth.shift() as SortingChip; // because of the while condition we know this cast is safe
    row.push(longest);
    let rowWidth = longest.width;
    let candidateIndex = 0;
    while (candidateIndex < sortedByWidth.length && rowWidth < width) {
      if (rowWidth + sortedByWidth[candidateIndex].width <= width) {
        const chip = sortedByWidth[candidateIndex];
        rowWidth += chip.width;
        row.push(chip);
        sortedByWidth.splice(candidateIndex, 1);
      } else {
        candidateIndex += 1;
      }
    }
    if (rowIndex % 2 === 1) {
      row.reverse();
    }
    sorted.push(...row);
    rowIndex++;
  }
  return sorted;
}

type ChipProps = Pick<
  ChipItemProps,
  "color" | "order" | "padding" | "fontWeight"
>;
const Chip = styled.span<ChipProps>`
  background-color: ${({ color }) => color};
  border-radius: 40px;
  text-align: center;
  height: 17px;
  padding: ${({ padding }) => padding ?? "0 0.5rem"};
  margin: 0.125rem;
  line-height: 1rem;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ?? theme.typography.body2.fontWeight};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  order: ${({ order }) => (order !== undefined ? order : 0)};
  min-width: 0.4rem;
`;

const ChipContainer = styled.div<{
  width: number;
  justifyContent?: string;
  margin?: string;
}>`
  width: ${(props) => props.width}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
  ${({ margin }) => (margin ? `margin: ${margin};` : "")};
`;

export interface ChipItemProps {
  title: string | number;
  details?: string;
  color: string;
  updateWidth: (width: number) => void; // function used to pass width length back up
  order: number | undefined;
  padding?: string;
  fontWeight?: number;
}
const ChipItem: React.FC<ChipItemProps> = (props) => {
  const {
    title,
    details,
    color,
    updateWidth,
    order,
    padding,
    fontWeight,
  } = props;
  const targetRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (targetRef.current) {
      const style = window.getComputedStyle(targetRef.current);
      const leftMargin = parseInt(style.marginLeft);
      const rightMargin = parseInt(style.marginRight);
      const offsetWidth = targetRef.current.offsetWidth;
      updateWidth(leftMargin + rightMargin + offsetWidth);
    }
  }, [targetRef.current, title]);

  const chip = (
    <Chip
      ref={targetRef}
      order={order}
      color={color}
      padding={padding}
      fontWeight={fontWeight}
    >
      {title}
    </Chip>
  );
  return details ? <Tooltip title={details}>{chip}</Tooltip> : chip;
};

export interface ChipCellProps {
  cell: {
    value: {
      chips: Omit<ChipItemProps, "updateWidth" | "order">[];
      width?: number; // determines width in pixels of this component
      justifyContent?: string;
      margin?: string;
    };
  };
}
export const ChipCell: React.FC<ChipCellProps> = memo((props) => {
  const { chips, width = 135, justifyContent, margin } = props.cell.value;

  // first number indexes into the chips array, second is the width of that chip
  const [chipWidths, setChipWidth] = useState<Record<number, number>>({});
  let sorted: SortingChips;
  if (Object.getOwnPropertyNames(chipWidths).length) {
    const chipsWithLength = chips.map((c, i) => ({
      ...c,
      width: chipWidths[i],
      originalIndex: i,
    }));
    sorted = sortChips(chipsWithLength, width);
  }

  return (
    <ChipContainer
      width={width}
      justifyContent={justifyContent}
      margin={margin}
    >
      {chips.map((c, i) => {
        let sortedChipIndex: number | undefined;
        if (sorted) {
          sortedChipIndex = sorted.findIndex((c) => c.originalIndex === i);
        }
        const order: number | undefined = sorted ? sortedChipIndex : undefined;
        return (
          <ChipItem
            order={order}
            {...c}
            key={`${c.title}-${i}`}
            updateWidth={(width: number) =>
              setChipWidth((prev) => ({ ...prev, [i]: width }))
            }
          />
        );
      })}
    </ChipContainer>
  );
});
