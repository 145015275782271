import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { Filter, Range } from "@typedef/store";
import React, { memo, useEffect } from "react";

import Bold from "@components/typography/bold";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import Panel from "@components/panel/panel";
import PieChart from "@components/charts/pieChart/pieChart";
import { ReactChild } from "react";
import { User } from "~/typedef/user";
import { fetchShippedUnitsSummary } from "~/store/mystore/vendor.redux";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment";
import { numberWithCommas } from "@utils/utils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export interface IPieChartData {
  name: string;
  value: number;
  color: string;
  type: string;
  tooltipContent?: string;
  legendContent?: ReactChild;
  cost: number;
}

interface ShippedUnitsSummaryProps {
  currentRange: Range;
  currentFilter: Filter;
  user: User;
  mid: string;
  currentCurrency: string;
  report?: boolean;
  condensed?: boolean;
  country: string;
}

interface IShippedUnitsSummaryLegendItem {
  name: string;
  value: number;
  piePercentage?: number;
  type?: string;
  cost?: string;
}

const MarginedGrid = styled(Grid)`
  margin-left: 8px;
  margin-bottom: 36px;
`;

export function ShippedUnitsSummaryLegendItem({
  name,
  value,
  cost,
  piePercentage,
  type,
}: IShippedUnitsSummaryLegendItem) {
  const { t } = useTranslation();
  const piePercentageText = Number.isNaN(piePercentage)
    ? "N/A"
    : `${piePercentage}%`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      // eslint-disable-next-line no-magic-numbers
      minWidth={0}
      pb={1}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        overflow="hidden"
      >
        <Tooltip title={t(`pieChartTooltip.${type}`) || ""}>
          <Typography variant="body1" noWrap style={{ minWidth: 0 }}>
            {name}
          </Typography>
        </Tooltip>
        <Box display="flex" ml={1}>
          <Bold variant="body2">{cost}</Bold>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box display="flex">
          <Bold variant="body2">{value}</Bold>
        </Box>
        <Box display="flex" ml={1}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            ({piePercentageText})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const ShippedUnitsSummary = memo<ShippedUnitsSummaryProps>(
  function ShippedUnitsSummary({
    currentRange,
    currentFilter,
    user,
    mid,
    currentCurrency,
    report,
    condensed,
    country,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const sourcingShareData = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.data
    ) || {
      yourShipments: 0,
      otherVendors: 0,
      total: 0,
      exports: 0,
      exportedCogs: 0,
      yourShipmentsCogs: 0,
      otherVendorCogs: 0,
      totalCogs: 0,
    };

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const currency = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.currency
    );

    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.lastReportDate
    );

    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.lastUpdatedAt
    );

    const chartDataLoading = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.fetching || false
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.summary?.params || {}
    );

    useEffect(() => {
      dispatch(
        fetchShippedUnitsSummary(
          {
            user,
            currentRange,
            mid,
            currentFilter,
          },
          params
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, currentRange, mid]);

    const {
      yourShipments,
      otherVendors,
      total,
      yourShipmentsCogs,
      otherVendorCogs,
      totalCogs,
      exports,
      exportedCogs,
    } = sourcingShareData;
    const pieTotal = yourShipments + otherVendors + exports;
    const pieChartData: IPieChartData[] = [
      {
        name: t("sourcingShare.yourShipmentsCountry", { country }),
        value: yourShipments,
        color: theme.palette.secondary.light,
        type: "yourShipments",
        cost: yourShipmentsCogs,
      },
      {
        name: t("sourcingShare.otherVendors", { country }),
        value: otherVendors,
        color: theme.palette.primary.main,
        type: "otherVendors",
        cost: otherVendorCogs,
      },
      {
        name: t("sourcingShare.exports"),
        value: exports,
        color: theme.palette.secondary.dark,
        type: "exports",
        cost: exportedCogs,
      },
    ];

    pieChartData.forEach((pieData: IPieChartData) => {
      const piePercentage = Math.round((pieData.value / pieTotal) * 100);
      pieData.tooltipContent = `${pieData.name}: ${numberWithCommas(
        pieData.value
      )} (${piePercentage}%)`;
      pieData.legendContent = (
        <ShippedUnitsSummaryLegendItem
          name={pieData.name}
          value={numberWithCommas(pieData.value)}
          piePercentage={piePercentage}
          type={pieData.type}
          cost={formatCurrencyRounded(
            pieData.cost,
            currencyRates,
            currency,
            currentCurrency
          )}
        />
      );
    });

    const exportPercentage = Math.round(
      (exports / (yourShipments + exports)) * 100
    );

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "sourcingshare"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="shipped-units-summary-chart"
        title={t("sourcingShare.summaryTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.updated", {
                lastReportDate: moment(lastReportDate).format("ll"),
                lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
              })}`
            : ""
        }
        tooltip={t("sourcingShare.tooltip")}
        footerLink={footerLink}
        content={
          chartDataLoading || !sourcingShareData ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Grid container item xs={12} alignItems="center">
              <PieChart
                {...{
                  report,
                  data: pieChartData,
                  legendHeader: (
                    <>
                      <GenericTotalMoney
                        current={totalCogs}
                        currency={currency}
                        currentCurrency={currentCurrency}
                        title={t("sourcingShare.totalShippedCogs", { country })}
                        condensed={true}
                        lockLayout={true}
                        rounded
                      />

                      <Typography variant="body1" noWrap>
                        {`${numberWithCommas(total)} ${t(
                          "sourcingShare.units"
                        )}`}
                      </Typography>
                    </>
                  ),
                }}
              />
              {exportPercentage > 0 && (
                <MarginedGrid container justifyContent="flex-end">
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotal
                      tooltip={t("exportShare.tooltip")}
                      percentage={true}
                      number={exportPercentage}
                      title={t("sourcingShare.exportShare")}
                    />
                  </Grid>
                </MarginedGrid>
              )}
            </Grid>
          )
        }
      />
    );
  }
);

export default ShippedUnitsSummary;
