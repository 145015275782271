import { Box, Grid, Typography } from "@material-ui/core";
import { ErrorIcon, FloatRight } from "~/components/alert/disconnectedStores";

import AlertCard from "~/components/alert/alertCard";
import CombinedLink from "~/components/links/link";
import DisconnectedStoresAlert from "~/components/alert/disconnectedStores";
import { PriorityHighRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const DisconnectedStoresList = ({ disconnected, hideDisconnected }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="stretch"
      flexDirection="column"
      mb={disconnected && disconnected.length ? 2 : 0}
    >
      {disconnected && disconnected.length === 1 ? (
        <Box>
          <DisconnectedStoresAlert
            store={disconnected[0]}
            onClose={hideDisconnected}
          />
        </Box>
      ) : (
        <Box>
          <AlertCard isOpen={true} type="error" onClose={hideDisconnected}>
            <ErrorIcon>
              <PriorityHighRounded fontSize="small" />
            </ErrorIcon>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography variant="body1">
                &nbsp;{t("generic.multipleStoreDisconnected")}
              </Typography>
            </Box>
            <FloatRight>
              <CombinedLink
                to={{
                  pathname: "/setting",
                  search: "?showDisconnected",
                }}
                color="inherit"
                variant="body1"
                underline="always"
              >
                {t("generic.manageDisconnectedLink")}
              </CombinedLink>
            </FloatRight>
          </AlertCard>
        </Box>
      )}
    </Box>
  );
};

DisconnectedStoresList.propTypes = {
  disconnected: PropTypes.array,
  hideDisconnected: PropTypes.func.isRequired,
};

export default DisconnectedStoresList;
