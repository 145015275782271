import { Route, Switch } from "react-router-dom";

import NotificationList from "~/pages/notifications/notificationOverview";
import NotificationSettings from "~/pages/notifications/notificationSettings";
import React from "react";

const NotificationRoutes = () => {
  return (
    <Switch>
      <Route path="/notifications/list" component={NotificationList} />
      <Route path="/notifications/settings" component={NotificationSettings} />
    </Switch>
  );
};

export default NotificationRoutes;
