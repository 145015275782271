import { BaseDialog, BaseDialogTitle } from "./baseDialog";
import { Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import React, { FormEvent } from "react";

import ErrorButton from "../buttons/errorButton";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  content: React.ReactElement;
  onClose: () => void;
  onConfirm: (e: FormEvent) => void;
  open: boolean;
  title: string;
  disableConfirm?: boolean;
}
const ConfirmDialog = ({
  content,
  onClose,
  onConfirm,
  open,
  title,
  disableConfirm,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  return (
    <BaseDialog open={open} onClose={onClose}>
      <Grid container spacing={2} alignItems="center">
        <Grid container item xs={12}>
          <BaseDialogTitle data-testid="confirm-dialog-title" onClose={onClose}>
            {title}
          </BaseDialogTitle>
        </Grid>
        <Grid item xs={12}>
          <DialogContent data-testid="confirm-dialog-content">
            {content}
          </DialogContent>
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Grid container justifyContent="center" item xs={12} sm={6}>
              <ErrorButton
                data-testid="confirm-dialog-cancel"
                onClick={onClose}
                variant="outlined"
              >
                {t("generic.cancelButton")}
              </ErrorButton>
            </Grid>
            <Grid container justifyContent="center" item xs={12} sm={6}>
              <Button
                data-testid="confirm-dialog-ok"
                onClick={onConfirm}
                variant="contained"
                color="primary"
                disabled={Boolean(disableConfirm)}
              >
                {t("generic.confirmButton")}
              </Button>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default ConfirmDialog;
