import { TAG_TYPES, api } from "@store/apiSlice";

import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";
import { setInfoMessage } from "../globalToast.redux";

interface UploadCampaignFileResponse {
  cutoffDate: number | null;
}

interface UploadCampaignFileParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  successMessage: (cutoff: number | null) => string;
  file: File;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    uploadCampaignFile: build.mutation<
      UploadCampaignFileResponse,
      UploadCampaignFileParams
    >({
      query: (params) => {
        const { mid, marketplaceType, marketplaceSubtype, countryCode, file } =
          params;
        const formData = new FormData();

        formData.append("mid", mid);
        formData.append("marketplaceType", marketplaceType);
        formData.append("marketplaceSubtype", marketplaceSubtype);
        formData.append("countryCode", countryCode);
        formData.append("file", file);

        return {
          url: `${baseUrl}/api/advertising-runtime-service/api/advertising/amazon/uploads`,
          method: "POST",
          data: formData,
        };
      },
      invalidatesTags: [TAG_TYPES.MarketingCampaigns],
      onQueryStarted: globalQueryErrorHandler(
        "uploadCampaignFile",
        false,
        (data: UploadCampaignFileResponse, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage(data?.cutoffDate));
        }
      ),
    }),
  }),
});

export const { useUploadCampaignFileMutation } = extendedApiSlice;
