import { Box, Grid } from "@material-ui/core";
import {
  CHART_TYPE,
  CHART_TYPE_LEGENDS_MAPPING,
  ChartOption,
} from "./salesPerformanceLineChart";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  INTERVAL,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import BarChartNoData from "~/components/placeholders/barChartNoData";
import { Filter } from "~/typedef/store";
import MultiLineChartGeneric from "~/components/charts/multiLineChart/multiLineChartGeneric";
import { Panel } from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import TotalSales from "~/components/totals/totalSales";
import { User } from "~/typedef/user";
import { fetchSalesPerformanceYtd } from "~/store/overview/salesPerformanceYTD.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

interface SalesPerformanceYTDProps {
  userInfo: User;
  mid?: string;
  currentCurrency: string;
  currentFilter?: Filter;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  actions?: ReactChild;
  overview?: boolean;
  chartType: ChartOption;
  report?: boolean;
  timezone: string;
  includeTax: boolean;
  conditionalFormatting?: boolean;
  isLineChart?: boolean;
  interval: INTERVAL;
}

const SalesPerformanceLineChartYTD = ({
  userInfo,
  mid,
  currentCurrency,
  currentFilter = {
    marketplaces: [],
    countries: [],
    tags: [],
  },
  footerLink,
  timezone,
  overview,
  actions,
  chartType,
  report,
  includeTax,
  conditionalFormatting,
  interval,
}: SalesPerformanceYTDProps) => {
  const currentPeriod = DATETIME_PERIODS.YEAR;
  const currentRange = useMemo(
    () =>
      getDatesFromPeriod(
        currentPeriod,
        COMPARISON_PERIOD.LASTYEAR,
        timezone || moment.tz.guess(),
        undefined,
        undefined,
        undefined,
        undefined,
        interval
      ),
    [timezone]
  );

  const { t } = useTranslation();
  const isVendorStore: boolean = useVendorStore(mid);

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const { fromDate, toDate, interval: calculatedInterval } = currentRange;

  const salesPerformanceYTD = useTypedSelector((state) =>
    overview
      ? state.overview.salesPerformanceYTD
      : state.mystore.salesPerformanceYTD
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchSalesPerformanceYtd(
        {
          user: userInfo,
          mid,
          filter: currentFilter,
          currentRange,
          includeTax,
          includeVendor: true,
        },
        salesPerformanceYTD?.params
      )
    );
  }, [userInfo, mid, currentFilter, includeTax, salesPerformanceYTD?.params]);

  const hasData = useCallback((): boolean => {
    const hasChartData: boolean = salesPerformanceYTD?.chartData.some(
      (data: any) => data[chartType?.value || "sales"] !== 0
    );
    const hasCurrentSales: boolean = salesPerformanceYTD?.current.sales !== 0;
    const hasPriorSales: boolean = salesPerformanceYTD?.prior.sales !== 0;
    return hasChartData || hasCurrentSales || hasPriorSales;
  }, [salesPerformanceYTD]);

  // Format data for chart display
  const convertSalesValues = useCallback(() => {
    const type = chartType?.value || "sales";
    return (
      salesPerformanceYTD?.chartData?.map((unitData: any) => {
        const current =
          type === CHART_TYPE.Sales || type === CHART_TYPE.AvgOrderValue
            ? getConvertedValue(
                currencyRates,
                salesPerformanceYTD.currency,
                currentCurrency,
                unitData.current?.[type]
              )
            : unitData.current?.[type];
        const prior =
          type === CHART_TYPE.Sales || type === CHART_TYPE.AvgOrderValue
            ? getConvertedValue(
                currencyRates,
                salesPerformanceYTD.currency,
                currentCurrency,
                unitData.prior?.[type]
              )
            : unitData.prior?.[type];
        return {
          startTime: unitData.current.startTime,
          endTime: unitData.current.endTime,
          current,
          prior,
        };
      }) || []
    );
  }, [
    currencyRates,
    currentCurrency,
    salesPerformanceYTD?.chartData,
    salesPerformanceYTD?.currency,
    chartType?.value,
  ]);

  return (
    <Panel
      id="widget-sales-performance"
      title={t("dashboardWidget.salesPerformanceYTD.mainTitle")}
      tooltip={undefined}
      footerLink={footerLink}
      actions={actions}
      content={
        salesPerformanceYTD?.fetching ? (
          <PanelLoading />
        ) : !hasData() ? (
          <BarChartNoData {...{ currentPeriod: currentPeriod, report }} />
        ) : (
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid container item xs={12} md={9} lg={10}>
                <MultiLineChartGeneric
                  {...{
                    chartData: convertSalesValues(),
                    currentPeriod: currentPeriod,
                    currency: currentCurrency,
                    fromDate: fromDate,
                    toDate: toDate,
                    interval: calculatedInterval,
                    report,
                    timezone,
                    valueTooltipKey: t(
                      CHART_TYPE_LEGENDS_MAPPING[chartType?.value || "sales"]
                    ),
                    notCurrency: [
                      CHART_TYPE.Orders,
                      CHART_TYPE.UnitsSold,
                      CHART_TYPE.OrderItems,
                    ].includes(chartType?.value),
                  }}
                />
              </Grid>

              <Grid container item xs={12} md={3} lg={2} alignItems="center">
                <TotalSales
                  {...{
                    current: salesPerformanceYTD.current.sales,
                    prior: salesPerformanceYTD.prior.sales,
                    isVendorStore,
                    currentCurrency,
                    currency: salesPerformanceYTD.currency,
                    currentPeriod,
                    conditionalFormatting: conditionalFormatting,
                    customTitle: t(
                      "dashboardWidget.salesPerformanceYTD.ytdSales"
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )
      }
    />
  );
};

export default memo(SalesPerformanceLineChartYTD);
