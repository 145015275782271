import { Box, Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { DayCellData, FORECAST_TYPE } from "./types";
import React, { useCallback } from "react";
import { camelCase, startCase } from "lodash";

import { getCurrencySymbol } from "~/utils/currencyUtils";
import styled from "styled-components";

interface HoverCellProps {
  items?: DayCellData;
  colors: Record<FORECAST_TYPE, string>;
  setIsHovered: (isHovered: boolean) => void;
}

const Indicator = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  height: 6px;
  width: 6px;
  margin: 1px;
  border-radius: 50%;
`;

const StyledGrid = styled(Grid)`
  margin-right: 12px;
`;
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const HoverCell: React.FC<HoverCellProps> = ({
  items,
  colors,
  setIsHovered,
}) => {
  const renderItems = useCallback(() => {
    if (!items) return "";
    const typeEntries = Object.entries(items);
    return typeEntries.map(([type, typeItem]) => {
      const levelEntries = Object.entries(typeItem);
      return levelEntries.map(([referenceLevel, item]) => (
        <Box
          key={`${type}-${referenceLevel}`}
          width="200px"
          display="flex"
          mt={0.5}
          mb={1}
          data-testid="tool-tip-box"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <StyledGrid>
              <Indicator
                data-testid="indicator-one"
                $color={colors[type as FORECAST_TYPE]}
              />
            </StyledGrid>
            <Typography variant="body2">
              {startCase(camelCase(type)) +
                " " +
                startCase(camelCase(referenceLevel))}
              :
            </Typography>
          </Box>
          <Typography variant="body2">
            {getCurrencySymbol[
              item.currency as keyof typeof getCurrencySymbol
            ] + item.amount}
          </Typography>
        </Box>
      ));
    });
  }, [items]);

  return (
    <Tooltip
      data-testid="hover-cell-tooltip"
      classes={useStylesBootstrap()}
      role="tooltip"
      arrow
      title={items ? renderItems() : ""}
      placement="right"
    >
      <Grid
        data-testid="grid-hover-cell"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {items &&
          Object.keys(items).map((key) => (
            <Indicator
              data-testid="indicator"
              key={key}
              $color={colors[key as keyof typeof colors]}
            />
          ))}
      </Grid>
    </Tooltip>
  );
};
