import { CurrentStore, Range } from "~/typedef/store";
import {
  ProductProfitabilityRow,
  VendorChannelProfitAndLossResponse,
} from "~/store/mystore/vendorProfitability.redux";

import { CurrencyRate } from "~/typedef/store";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { getExchangeRate } from "~/utils/currencyUtils";
import moment from "moment";

const percentageFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

interface GetProductCsvDataProps {
  currentRange: Omit<Range, "priorToDate" | "priorFromDate">;
  selectedListing: ProductProfitabilityRow | undefined;
  store: CurrentStore;
  currentCurrency: string;
  formattedDataForProductPopOut:
    | Omit<
        VendorChannelProfitAndLossResponse,
        "snsFromDate" | "snsToDate" | "currency"
      >
    | undefined;
  sellType: SellType;
  currencyRates: CurrencyRate[];
  homeCurrency: string;
}

export function getProductCsvData({
  currentRange,
  selectedListing,
  store,
  currentCurrency,
  formattedDataForProductPopOut,
  sellType,
  currencyRates,
  homeCurrency,
}: GetProductCsvDataProps) {
  const exchangeRate = getExchangeRate(
    currencyRates,
    homeCurrency,
    currentCurrency
  );

  const csvData = generateCsv(
    formattedDataForProductPopOut,
    sellType,
    exchangeRate
  );

  const fromDateUnix = Number(currentRange.fromDate);
  const toDateUnix = Number(currentRange.toDate);

  const fromDateStr = isNaN(fromDateUnix)
    ? ""
    : moment.unix(fromDateUnix).format("YYYY_MM_DD");
  const toDateStr = isNaN(toDateUnix)
    ? ""
    : moment.unix(toDateUnix).format("YYYY_MM_DD");

  const description = `${selectedListing?.asin} report | ${store.storeName} - ${store.marketplace} ${store.marketplaceCountry} - ${currentCurrency} | ${fromDateStr} - ${toDateStr}`;

  return { productCsvData: csvData, productCsvDescription: description };
}

const generateCsv = (
  data:
    | Omit<
        VendorChannelProfitAndLossResponse,
        "snsFromDate" | "snsToDate" | "currency"
      >
    | undefined,
  sellType: SellType,
  exchangeRate = 1
) => {
  if (!data) return [];
  const { income, expense, metrics } = data;

  const totalRevenue =
    Object.keys(income).reduce((acc, key) => acc + income[key], 0) *
    exchangeRate;
  const totalDeductions =
    Object.values(expense?.deduction || {}).reduce((acc, val) => acc + val, 0) *
    exchangeRate;
  const totalChargebacks =
    Object.values(expense?.chargeback || {}).reduce(
      (acc, val) => acc + val,
      0
    ) * exchangeRate;
  const adCost = (expense?.adCost || 0) * exchangeRate;
  const cogs =
    Object.values(expense?.cogs || {}).reduce((acc, val) => acc + val, 0) *
    exchangeRate;

  const totalExpenses = adCost + cogs + totalDeductions + totalChargebacks;
  const unitsShipped = metrics?.shippedUnits || 0;

  // Sell-in metrics
  const netReceipts = Number(income?.poValue || 0);
  const cancelledPO = Number(income?.cancelledValue || 0);
  const shortages = Number(income?.shortageValue || 0);
  const requestedPO = netReceipts + cancelledPO + shortages;
  const unitsReceived = metrics?.receivedUnits || 0;

  const isSellOut = sellType === SellType.SELL_OUT;

  // Common metrics
  const units = isSellOut ? unitsShipped : unitsReceived;
  const netReceiptsOrRevenue = isSellOut ? totalRevenue : netReceipts;

  const profit = netReceiptsOrRevenue - totalExpenses;

  const tacosPercentage =
    netReceiptsOrRevenue > 0 ? (adCost / netReceiptsOrRevenue) * 100 : 0;
  const totalFeesPercentage =
    netReceiptsOrRevenue > 0
      ? ((adCost + totalDeductions) / netReceiptsOrRevenue) * 100
      : 0;
  const profitPercentage =
    netReceiptsOrRevenue > 0 ? (profit / netReceiptsOrRevenue) * 100 : 0;
  const snsPenetration =
    metrics.snsUnits > 0 ? (metrics.snsUnits / metrics.shippedUnits) * 100 : 0;
  const actualPO = requestedPO - cancelledPO;

  const confirmationRate = requestedPO > 0 ? (actualPO / requestedPO) * 100 : 0;
  const deliveryRate = actualPO > 0 ? (netReceipts / actualPO) * 100 : 0;

  const calculatePercentageIncome = (
    value: number,
    totalRevenue: number,
    rate: number
  ): string => {
    if (totalRevenue <= 0 || value === 0 || isNaN(value)) return "-";
    const percentage = (100 * value * rate) / totalRevenue;
    return `${percentageFormatter.format(percentage)}%`;
  };

  const calculateValuePerUnit = (
    value: number,
    totalUnits: number,
    rate: number
  ): string => {
    if (totalUnits > 0 && !isNaN(value) && value !== 0) {
      return `${((value * rate) / totalUnits).toFixed(2)}`;
    }
    return "-";
  };

  const convertValues = (obj: Record<string, number>, excludeUnit?: string[]) =>
    Object.entries(obj).map(([key, value]) => ({
      key,
      value: (value * exchangeRate).toFixed(2),
      percentageIncome: calculatePercentageIncome(
        value,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit:
        excludeUnit && excludeUnit.includes(key)
          ? undefined
          : calculateValuePerUnit(value, units, exchangeRate),
    }));

  return [
    ...(isSellOut
      ? [
          ...convertValues(income),
          {
            key: "totalRevenue",
            value: netReceiptsOrRevenue.toFixed(2),
            percentageIncome: calculatePercentageIncome(
              netReceiptsOrRevenue,
              netReceiptsOrRevenue,
              exchangeRate
            ),
            valuePerUnit: calculateValuePerUnit(
              totalRevenue,
              units,
              exchangeRate
            ),
          },
        ]
      : [
          ...convertValues(
            {
              requestedPO,
              cancelledPO,
              shortages: Math.abs(shortages),
            },
            ["requestedPO", "cancelledPO", "shortages"]
          ),
          {
            key: "netReceipts",
            value: netReceipts.toFixed(2),
            percentageIncome: calculatePercentageIncome(
              netReceipts,
              netReceiptsOrRevenue,
              exchangeRate
            ),
            valuePerUnit: calculateValuePerUnit(
              netReceipts,
              metrics.receivedUnits,
              exchangeRate
            ),
          },
        ]),
    {
      key: "adCost",
      value: adCost.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        adCost,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(adCost, units, exchangeRate),
    },
    {
      key: "deductions",
      value: totalDeductions.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        totalDeductions,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(totalDeductions, units, exchangeRate),
    },
    {
      key: "cogs",
      value: cogs.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        cogs,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(cogs, units, exchangeRate),
    },
    {
      key: "chargebacks",
      value: totalChargebacks.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        totalChargebacks,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(
        totalChargebacks,
        units,
        exchangeRate
      ),
    },
    {
      key: "totalExpenses",
      value: totalExpenses.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        totalExpenses,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(
        totalExpenses,
        metrics.shippedUnits,
        exchangeRate
      ),
    },
    {
      key: "profit",
      value: profit.toFixed(2),
      percentageIncome: calculatePercentageIncome(
        profit,
        netReceiptsOrRevenue,
        exchangeRate
      ),
      valuePerUnit: calculateValuePerUnit(profit, units, exchangeRate),
    },
    {
      key: "profitPercentage",
      value: `${percentageFormatter.format(profitPercentage)}%`,
    },
    {
      key: "tacos",
      value: `${percentageFormatter.format(tacosPercentage)}%`,
    },
    {
      key: "totalFees",
      value: `${percentageFormatter.format(totalFeesPercentage)}%`,
    },
    ...(isSellOut
      ? [
          ...convertValues(metrics, ["shippedUnits"]),
          {
            key: "snsPenetration",
            value: `${percentageFormatter.format(snsPenetration)}%`,
          },
        ]
      : [
          {
            key: "unitsReceived",
            value: unitsReceived.toString(),
          },
          {
            key: "confirmationRate",
            value: `${confirmationRate.toFixed(2)}%`,
          },
          {
            key: "deliveryRate",
            value: `${deliveryRate.toFixed(2)}%`,
          },
        ]),
  ];
};

export type CsvData = ReturnType<typeof generateCsv>;
