import { Box, Grid, Tooltip, Typography } from "@material-ui/core";

import Bold from "~/components/typography/bold";
import ErrorIcon from "@material-ui/icons/Error";
import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SummaryTitleTypography = styled(Typography)`
  line-height: 1.5;
  text-transform: uppercase;
`;

const RedErrorIcon = styled(ErrorIcon)`
  fill: ${({ theme }) => theme.palette.error.dark};
  padding-right: 0.25rem;
`;

const FlexGrid = styled(Grid)`
  width: ${({ $isMobileView }) => ($isMobileView ? "100%" : "fit-content")};
  display: flex;
  flex-direction: ${({ $isMobileView }) => ($isMobileView ? "row" : "column")};
`;

const SummaryItemGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const FlexHelpIcon = styled(HelpIcon)`
  flex-grow: 0;
  margin-left: 0.25rem;
`;

const SummaryTitle = ({ tooltip, isMobileView, children }) => {
  const basicContent = (
    <SummaryTitleTypography
      variant="h6"
      align={isMobileView ? "center" : "left"}
    >
      {children}
    </SummaryTitleTypography>
  );
  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="top">
        <Box display="flex" flexDirection="row" alignItems="center">
          {basicContent}
          <FlexHelpIcon fontSize="inherit" />
        </Box>
      </Tooltip>
    );
  }
  return basicContent;
};

SummaryTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isMobileView: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const SummaryItem = ({ title, content, icon, isMobileView, tooltip }) => (
  // eslint-disable-next-line no-magic-numbers
  <SummaryItemGrid xs={isMobileView ? 4 : false} item>
    <SummaryTitle isMobileView={isMobileView} tooltip={tooltip}>
      {title}
    </SummaryTitle>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={isMobileView ? "center" : "start"}
      alignItems="center"
    >
      {icon}
      <Bold variant="h1" component="span">
        {content}
      </Bold>
    </Box>
  </SummaryItemGrid>
);

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
  isMobileView: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const SummaryBox = ({ winnerCount, losingCount, isMobileView }) => {
  const { t } = useTranslation();
  return (
    <FlexGrid $isMobileView={isMobileView} container>
      <SummaryItem
        title={t("myStoresWidget.buyBoxSummary.activeProductsLabel")}
        content={winnerCount + losingCount}
        isMobileView={isMobileView}
      />
      <SummaryItem
        title={t("myStoresWidget.buyBoxSummary.losersLabel")}
        content={losingCount}
        icon={<RedErrorIcon />}
        isMobileView={isMobileView}
      />
      <SummaryItem
        title={t("myStoresWidget.buyBoxSummary.winRateLabel")}
        content={`${
          winnerCount
            ? Math.round((100 * winnerCount) / (winnerCount + losingCount))
            : 0
        }%`}
        isMobileView={isMobileView}
      />
    </FlexGrid>
  );
};

SummaryBox.defaultProps = {
  winnerCount: 0,
  losingCount: 0,
  isMobileView: false,
};

SummaryBox.propTypes = {
  winnerCount: PropTypes.number.isRequired,
  losingCount: PropTypes.number.isRequired,
  isMobileView: PropTypes.bool.isRequired,
};
export default SummaryBox;
