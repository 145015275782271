import { CurrentStore, IssueState } from "~/typedef/store";

import { Dispatch } from "redux";
import axios from "axios";
import { baseUrl } from "../../configs";

export const FETCH_STORE_ISSUES = "FETCH_STORE_ISSUES";
export const FETCH_STORE_ISSUES_FETCHING = "FETCH_STORE_ISSUES_FETCHING";

const initState: IssueState = {
  fetching: false,
};

type FetchIssuesFetchingAction = {
  type: typeof FETCH_STORE_ISSUES_FETCHING;
};
type FetchIssuesAction = {
  type: typeof FETCH_STORE_ISSUES;
  payload: string[];
};

type IssueAction = FetchIssuesFetchingAction | FetchIssuesAction;

export const issues = (
  state: IssueState = initState,
  action: IssueAction
): IssueState => {
  switch (action.type) {
    case FETCH_STORE_ISSUES_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_STORE_ISSUES:
      return {
        data: action.payload,
        fetching: false,
      };

    default:
      return state;
  }
};

function setFetching(dispatch: Dispatch) {
  dispatch({
    type: FETCH_STORE_ISSUES_FETCHING,
  });
}

export const fetchStoreIssues =
  (currentStore: CurrentStore) => async (dispatch: Dispatch) => {
    setFetching(dispatch);
    const { marketplace, marketplaceSubtype, marketplaceCountry, merchantId } =
      currentStore;
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/user-management-service/api/store/issues`,
        {
          params: {
            marketplaceType: marketplace,
            marketplaceSubtype,
            countryCode: marketplaceCountry,
            mid: merchantId,
          },
        }
      );

      return dispatch({
        type: FETCH_STORE_ISSUES,
        payload: data,
      });
    } catch (e) {
      //do nothing
    }
  };
