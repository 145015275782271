import { Card, Switch, Tooltip } from "@material-ui/core";

import { Detail } from "../typography/detail";
import { InlineIconButton } from "~/icons/inlineIconButton";
import React from "react";
import styled from "styled-components";

interface RowProps {
  isLastRow: boolean;
}
const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
  ${({ isLastRow, theme }) =>
    isLastRow ? "" : `border-bottom: solid 1px ${theme.palette.border.main};`}
`;

const MenuCard = styled(Card)`
  width: max-content;
`;

export interface SettingsMenuItem {
  title: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  tooltip: string;
  colour?: "default" | "primary" | "secondary" | undefined;
}

export interface SettingsMenuProps {
  menuItems: SettingsMenuItem[];
}

export const SettingsMenu: React.FC<SettingsMenuProps> = ({ menuItems }) => {
  return (
    <MenuCard>
      {menuItems.map((item, i) => {
        return (
          <Row isLastRow={i + 1 === menuItems.length} key={item.title}>
            {includeTaxSwitch(item)}
            <Detail>{item.title}</Detail>
            <Tooltip title={item.tooltip}>
              <InlineIconButton />
            </Tooltip>
          </Row>
        );
      })}
    </MenuCard>
  );
};

export const includeTaxSwitch = (item: SettingsMenuItem) => {
  return (
    <>
      <Switch
        size="small"
        color={item.colour ?? "secondary"}
        checked={item.checked}
        onClick={() => item.setChecked(!item.checked)}
      />
    </>
  );
};
