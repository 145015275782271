/* eslint-disable no-magic-numbers */
const ebayOdrMax = 2;
const ebayLateShipmentRateMax = 5;
const ebayClosedMax = 0.3;

export const ebayHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    url: "https://sellerstandards.ebay.com.au/dashboard",
    items: [
      {
        key: "projectedStandard",
        title: "dashboardWidget.accountHealth.projectedSellerRating",
        // should we display when this metric was computed?
        useTimestamp: true,
      },
      {
        key: "standard",
        title: "dashboardWidget.accountHealth.currentSellerRating",
        useTimestamp: true,
      },
    ],
  },
  shippingPerformance: {
    colspan: 4,
    items: [
      {
        key: "odr",
        title: "dashboardWidget.accountHealth.transactionDefectRate", //Transaction defect rate",
        tooltip: "dashboardWidget.accountHealth.tooltip.ebay.odr",
        target: `${ebayOdrMax.toFixed(0)}`,
        targetCondition: "<",
        suffix: "%",
        displayTimePeriod: true,
        /* Should this item also display absolute values as well as
         * proportions/percentages? */
        displayTotals: true,
      },
      {
        key: "lateShipment",
        title: "dashboardWidget.accountHealth.lateShipment", //"Late shipment rate",
        tooltip: "dashboardWidget.accountHealth.tooltip.ebay.lateShipment",
        target: `${ebayLateShipmentRateMax.toFixed(0)}`,
        targetCondition: "<",
        suffix: "%",
        displayTimePeriod: true,
        /* Should this item also display absolute values as well as
         * proportions/percentages? */
        displayTotals: true,
      },
      {
        key: "returnRequests",
        title: "dashboardWidget.accountHealth.returnRequests",
        tooltip: "",
        url: "https://www.ebay.com.au/sh/ord/return",
      },
    ],
  },
  resolutionCentre: {
    colspan: 4,
    url: "https://res.ebay.com.au/ws/eBayISAPI.dll?ResolutionCenter",
    items: [
      {
        key: "closedNoSolution",
        title: "dashboardWidget.accountHealth.closedNoSolution", //"Cases closed without seller resolution",
        tooltip: "dashboardWidget.accountHealth.tooltip.ebay.closedNoSolution",
        target: `${ebayClosedMax.toFixed(1)}`,
        targetCondition: "<",
        displayTimePeriod: true,
        /* Should this item also display absolute values as well as
         * proportions/percentages? */
        displayTotals: true,
      },
      {
        key: "cases",
        title: "dashboardWidget.accountHealth.resolutionCentreCases", //"Resolution centre cases",
        tooltip:
          "dashboardWidget.accountHealth.tooltip.ebay.resolutionCentreCases",
        url: "https://res.ebay.com.au/ws/eBayISAPI.dll?ResolutionCenter",
      },
      {
        key: "negativeFeedback",
        title: "dashboardWidget.accountHealth.negativeFeedback", //"Negative feedback",
        tooltip: "",
      },
      {
        key: "newMessages",
        title: "dashboardWidget.accountHealth.newMessages", //"New Messages",
        url: "https://mesg.ebay.com.au/mesgweb/ViewMessages/0/m2m",
        tooltip: "",
      },
    ],
  },
};
