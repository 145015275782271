import { Box, Grid } from "@material-ui/core";
import React, { memo } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";

import Bold from "~/components/typography/bold";
import CategoryRow from "./categoryRow";
import CategoryValue from "./categoryValue";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { percentageFormatter } from "./categoryUtils";
import { useSelector } from "react-redux";

const CategoryLabel = memo(
  ({
    countryCode,
    category,
    data,
    level,
    totalLabel,
    totalValue,
    totalRevenue,
    currentCurrency,
    report,
  }) => {
    const currencyRates = useSelector((state) => state.globalVar.currencyRates);
    const sourceCurrency = getCurrencyByCountryCode[countryCode] || "AUD";

    const formatCurrencyValue = (value) =>
      isEmpty(currencyRates)
        ? `$${intFormatter.format(value)}` // only used in storybook
        : formatCurrency(value, currencyRates, sourceCurrency, currentCurrency);

    return (
      <>
        {category.children.map((child) =>
          isEmpty(child.children[0]?.children) ? (
            <CategoryRow
              key={child.label}
              countryCode={countryCode}
              category={child}
              data={data}
              level={level + 1}
              tooltip={`profitability.${child.label}Tooltip`}
              totalRevenue={totalRevenue}
              currentCurrency={currentCurrency}
              report={report}
            />
          ) : (
            <CategoryLabel
              key={child.label}
              countryCode={countryCode}
              category={child}
              data={data}
              level={level + 1}
              totalRevenue={totalRevenue}
              currentCurrency={currentCurrency}
            />
          )
        )}
        {level === 0 && (
          <>
            <Grid item xs={5}>
              <Box p={1} pl={2}>
                <Bold>{totalLabel}</Bold>
              </Box>
            </Grid>
            <CategoryValue
              value={formatCurrencyValue(totalValue)}
              colSpan={2}
              isTotalRow={true}
            />
            <CategoryValue
              value={
                totalRevenue > 0
                  ? `${percentageFormatter.format(
                      (100 * totalValue) / totalRevenue
                    )}%`
                  : "-"
              }
              colSpan={2}
              isTotalRow={true}
            />
            <CategoryValue
              value={formatCurrencyValue(totalValue / data.meta.unitCount)}
              colSpan={3}
              isTotalRow={true}
            />
          </>
        )}
      </>
    );
  }
);

CategoryLabel.propTypes = {
  countryCode: PropTypes.string,
  category: PropTypes.object,
  data: PropTypes.shape({
    meta: PropTypes.shape({
      orderCount: PropTypes.number,
      unitCount: PropTypes.number,
      refundedUnitCount: PropTypes.number,
    }),
    events: PropTypes.array,
  }),
  level: PropTypes.number,
  totalLabel: PropTypes.string,
  totalValue: PropTypes.number,
  totalRevenue: PropTypes.number,
  currentCurrency: PropTypes.string,
  report: PropTypes.bool,
};

export default CategoryLabel;
