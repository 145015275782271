import {
  ADD_CUSTOM_TAGS_STORE,
  FETCH_ALL_STORES,
  FETCH_ALL_STORES_FETCHING,
  FETCH_CUSTOM_TAGS_STORE,
  FETCH_CUSTOM_TAGS_STORE_FETCHING,
  FETCH_FILTERED_STORES,
  FETCH_FILTERED_STORES_FETCHING,
} from "../mystore/mystore.redux";
import {
  FETCH_CUSTOM_TAGS_OVERVIEW,
  FETCH_CUSTOM_TAGS_OVERVIEW_FETCHING,
} from "./overview.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";

export const setCustomTagsOverviewFetching = async (dispatch: Dispatch) => {
  await dispatch({
    type: FETCH_CUSTOM_TAGS_OVERVIEW_FETCHING,
  });
};

export const setCustomTagsStoreFetching = async (dispatch: Dispatch) => {
  await dispatch({
    type: FETCH_CUSTOM_TAGS_STORE_FETCHING,
  });
};

export const fetchCustomTags = () => async (dispatch: Dispatch) => {
  await setCustomTagsOverviewFetching(dispatch);
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/customTags`)
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(
        dispatch,
        msg || statusText,
        get(err, "status"),
        "fetchCustomTags"
      );
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: FETCH_CUSTOM_TAGS_OVERVIEW,
        payload: data,
      });
    } else {
      return setError(dispatch, res.data.errMsg, res.status, "fetchCustomTags");
    }
  }
  return setError(dispatch);
};

export const fetchStoreCustomTags =
  (mid?: string) => async (dispatch: Dispatch) => {
    await setCustomTagsStoreFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/customTags`, {
        mid,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "fetchStoreCustomTags"
        );
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_CUSTOM_TAGS_STORE,
          payload: data,
        });
      } else {
        return setError(
          dispatch,
          res.data.errMsg,
          res.status,
          "fetchStoreCustomTags"
        );
      }
    }
    return setError(dispatch);
  };

export const addStoreCustomTags =
  (mid: string, tags: string[]) => async (dispatch: Dispatch) => {
    const res = await axios
      .put(`${baseUrl}/api/generic-mws-service/api/customTags`, {
        mid,
        tags,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "addStoreCustomTags"
        );
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: ADD_CUSTOM_TAGS_STORE,
          payload: data,
        });
      } else {
        return setError(
          dispatch,
          res.data.errMsg,
          res.status,
          "addStoreCustomTags"
        );
      }
    }
    return setError(dispatch);
  };

export const fetchFilteredStores =
  (filter?: Partial<Filter>, forceConnectionStatusRefetch?: boolean) =>
  async (dispatch: Dispatch) => {
    await dispatch({
      type: FETCH_FILTERED_STORES_FETCHING,
    });
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/filteredStores`, {
        filter,
        includeVendor: true,
        includeAdvertising: true,
        forceConnectionStatusRefetch,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "fetchFilteredStores"
        );
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_FILTERED_STORES,
          payload: data,
        });
      } else {
        return setError(
          dispatch,
          res.data.errMsg,
          res.status,
          "fetchFilteredStores"
        );
      }
    }
    return null;
  };

export const fetchAllStores = () => async (dispatch: Dispatch) => {
  await dispatch({
    type: FETCH_ALL_STORES_FETCHING,
  });
  const res = await axios
    .post(`${baseUrl}/api/generic-mws-service/api/filteredStores`, {
      includeVendor: true,
      includeAdvertising: true,
    })
    .catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(
        dispatch,
        msg || statusText,
        get(err, "status"),
        "fetchAllStores"
      );
    });

  if (res) {
    if (isHttpResponseValid(res.status)) {
      const data = res.data;
      return await dispatch({
        type: FETCH_ALL_STORES,
        payload: data,
      });
    } else {
      return setError(dispatch, res.data.errMsg, res.status, "fetchAllStores");
    }
  }
  return setError(dispatch);
};
