import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import SalesByCategoryWidget from "../widgets/salesByCategory";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByCategoryProps {
  market: string;
  mid: string;
  condensed?: boolean;
}

const SalesByCategory = memo(
  ({ market, mid, condensed }: SalesByCategoryProps) => {
    const { t } = useTranslation();

    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const includeTax: boolean = useTypedSelector((state: StoreState) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    const footerLink = condensed
      ? {
          url: marketplaceLink(market, mid, "salesbycategory"),
          label: t("generic.viewAllLink"),
        }
      : undefined;

    return (
      <SalesByCategoryWidget
        mid={mid}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        footerLink={footerLink}
        condensed={condensed}
        timezone={selectedTimezone}
        includeTax={includeTax}
      />
    );
  }
);

export default SalesByCategory;
