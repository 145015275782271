import { Grid, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

interface ValueAndTextCellProps {
  cell: {
    value: {
      value: string;
      text: string;
    };
  };
}

export const ValueAndTextCell = memo<ValueAndTextCellProps>(
  function ValueAndTextCell({ cell }) {
    const value = cell.value.value;
    const text = cell.value.text;
    return (
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Typography variant="body2">{value}</Typography>
          <Typography variant="subtitle2">{text}</Typography>
        </Grid>
      </Grid>
    );
  }
);
