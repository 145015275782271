import { marketplaceConstants } from "mm-mercado-libre-common/dist/shared/marketplaceConstants";

export const mercadoLibreHealthDataConfig = {
  healthStatus: {
    colspan: 4,
    url: `<mercadoLibreUrl>/reputacion`,
    items: [
      {
        key: "powerSeller",
        title: "dashboardWidget.accountHealth.powerSeller",
        suffix: "",
      },
      {
        key: "realLevel",
        title: "dashboardWidget.accountHealth.realLevel",
        suffix: "",
      },
      {
        key: "protectionEndDate",
        title: "dashboardWidget.accountHealth.protectionEndDate",
        suffix: "",
      },
      {
        key: "sales",
        title: "dashboardWidget.accountHealth.sales",
        suffix: "",
      },
    ],
  },
  metrics: {
    colspan: 4,
    url: `<mercadoLibreUrl>/reputacion`,
    items: [
      {
        key: "claims",
        title: "dashboardWidget.accountHealth.claims",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "cancellations",
        title: "dashboardWidget.accountHealth.cancellations",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
      {
        key: "delayedHandlingTime",
        title: "dashboardWidget.accountHealth.delayedHandlingTime",
        suffix: "%",
        displayTimePeriod: true,
        displayTotals: true,
      },
    ],
  },
  ratings: {
    colspan: 4,
    url: `<mercadoLibreUrl>/reputacion`,
    items: [
      {
        key: "negativeRatings",
        title: "dashboardWidget.accountHealth.negativeRatings",
        suffix: "%",
      },
      {
        key: "neutralRatings",
        title: "dashboardWidget.accountHealth.neutralRatings",
        suffix: "%",
      },
      {
        key: "positiveRatings",
        title: "dashboardWidget.accountHealth.positiveRatings",
        suffix: "%",
      },
    ],
  },
};
