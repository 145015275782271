import ReactGA from "react-ga";

export const handleTokenisationError = (err, setError) => {
  switch (err.code) {
    case "HOSTED_FIELDS_FIELDS_EMPTY":
      setError("All fields are empty! Please fill out the form.");
      break;
    case "HOSTED_FIELDS_FIELDS_INVALID":
      // the credit card field is called "number" in Braintree parlance;
      // convert to a friendlier name
      const invalidFields = err.details.invalidFieldKeys.map((key) =>
        key === "number" ? "card number" : key
      );
      setError(`Some payment input fields are invalid: (${invalidFields})`);
      break;
    case "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE":
      setError("This payment method already exists in your vault.");
      break;
    case "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED":
      setError("CVV did not pass verification.");
      break;
    case "HOSTED_FIELDS_FAILED_TOKENIZATION":
      setError(
        "Tokenization failed server side. Please check that the card is valid and try again."
      );
    case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
      setError("A network error occurred when tokenizing. Please try again.");
      break;
    default:
      setError("An unexpected error has occurred: " + err.message);
  }
  ReactGA.event({
    category: "Subscription",
    action: "Payment Method - Tokenization Error",
  });
};

export const handleSubscriptionError = async (err, setError) => {
  if (err.text) {
    setError(await err.text());
  } else {
    setError(
      "An error has occurred while processing your payment. " +
        "Please check that you have entered in your payment details correctly, including Expiration Date and CVV, and try again. " +
        "If this issue persists, please contact support. "
    );
  }
  ReactGA.event({
    category: "Subscription",
    action: "Payment Method - Gateway Error",
  });
};
