import { ChipCell, ChipCellProps } from "~/components/table/cells/chipCell";
import {
  DrawerContents,
  SuppressedProductsMarketplace,
  getIssueGroupMap,
} from "~/pages/singleChannel/marketplaceListings/suppressedProducts";
import {
  FetchSuppressedProductsArgs,
  Fetched,
  RowsWithCount,
  fetchSuppressedProducts,
} from "~/store/mystore/suppressedProducts.redux";
import { GridJustification, Theme, Typography } from "@material-ui/core";
import { IssueGroup, amazonFieldNameToIssueGroup } from "mm-utils-frontend";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import Bold from "~/components/typography/bold";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaletteColor } from "@material-ui/core/styles/createPalette";
import { Row } from "react-table";
import SmallButton from "~/components/buttons/smallButton";
import { StarRatingCell } from "~/components/table/cells/starRatingCell";
import { StyledIcon } from "~/components/table/rows/disconnectedStore";
import { SuppressedProductListingResponse } from "mm-utils-frontend";
import Table from "~/components/adTable/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import get from "lodash/get";
import moment from "moment-timezone";
import { snakeCase } from "lodash";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

type SortableColumns = keyof Omit<
  CommonTableData,
  "issueGroups" | "suppressedProductId"
>;
// function to get us the keys for the set of sortable column:
function getSortKey(
  tableAccessor: SortableColumns
): keyof SuppressedProductListingResponse {
  switch (tableAccessor) {
    case "product":
      return "title";
    case "inventory":
      return "availableQuantity";
    case "dateSuppressed":
    case "daysSinceSuppressed":
      return "createdAt";
    case "lostValue":
      return "lostValue";
    case "status":
      return "productStatus";
    case "price":
      return "price";
    case "issueCount":
      return "issueCount";
    default:
      const _exhaustiveCheck: never = tableAccessor;
      return _exhaustiveCheck;
  }
}

function getSnakeSortKey(tableAccessor: SortableColumns): string {
  return snakeCase(getSortKey(tableAccessor));
}

// Amazon, unlike other stores(?) doesn't have star ratings
export const AmazonProductCell = (props: {
  cell: { value: AmazonTableData["product"] };
}) => {
  return (
    <LinkAndImageCell
      {...{
        ...props,
        maxWidth: "250px",
        width: "250px",
        noWrapFlexGrow: "1",
        colorVariant: "external",
      }}
    />
  );
};

export const ProductCell = (props: {
  cell: { value: CommonTableData["product"] };
}) => {
  const { starRating, percentRating } = props.cell.value;
  let thirdRowComponent;

  // if starRatingPercentage is null, dont render a StarRatingCell, render message
  // 'No reviews'
  if (starRating === null || starRating === undefined) {
    thirdRowComponent = (
      <Typography align="left" variant="subtitle2" color="textSecondary">
        No reviews
      </Typography>
    );
  } else {
    const starProps = {
      cell: {
        value: percentRating,
        starRating,
        justify: "flex-start" as GridJustification,
      },
    };
    thirdRowComponent = <StarRatingCell {...starProps} />;
  }
  return (
    <LinkAndImageCell
      {...{
        ...props,
        maxWidth: "250px",
        width: "250px",
        noWrapFlexGrow: "1",
        thirdRowComponent,
        colorVariant: "external",
      }}
    />
  );
};

const issuesCountCell = (props: { value: CommonTableData["issueCount"] }) => {
  const theme = useCustomTheme();
  const oneChipCellProps: ChipCellProps = {
    cell: {
      value: {
        chips: [
          {
            title: props.value,
            details: `${props.value}`,
            color: (theme.palette.error as PaletteColor)?.main,
            padding: "0 .3rem",
            fontWeight: 700,
          },
        ],
        width: 63,
        justifyContent: "center",
        margin: "0 auto",
      },
    },
  };
  return <ChipCell {...oneChipCellProps} />;
};

const mapToAmazonTableData =
  (currency: Currency) =>
  (row: SuppressedProductListingResponse): AmazonTableData => {
    const suppressedMoment = moment(row.createdAt);
    const dateSuppressed = suppressedMoment.format("YYYY-MM-DD");
    const daysSinceSuppressed = moment().diff(moment(row.createdAt), "days");

    return {
      dateSuppressed,
      daysSinceSuppressed,
      inventory: row.availableQuantity,
      lostValue: formatCurrency(
        row.lostValue,
        currency.currencyRates,
        currency.sourceCurrency,
        currency.currentCurrency
      ),
      status: row.productStatus,
      price: formatCurrency(
        row.price,
        currency.currencyRates,
        currency.sourceCurrency,
        currency.currentCurrency
      ),
      issueCount: row.issueCount,
      // Note, just like we need to do this here, we have to do something similar in
      // mm-report-download-service - if you are adding another marketplace, don't forget to update that code
      issueGroups: Array.from(
        new Set(row.fieldNames.map(amazonFieldNameToIssueGroup))
      ),
      suppressedProductId: row.suppressedProductId,
      product: {
        value: row.title,
        secondRowValue: `ASIN: ${row.productSku}  |  SKU: ${row.sellerSku}`,
        image: row.imageUrl,
        link: row.linkUrl,
        target: "_blank",
        fixLink: row.fixUrl,
      },
    };
  };

interface Currency {
  sourceCurrency: any;
  currentCurrency: any;
  currencyRates: any;
}

function mapToTableData(
  rows: SuppressedProductListingResponse[],
  marketplace: SuppressedProductsMarketplace,
  currency: Currency
): (CommonTableData | AmazonTableData)[] {
  switch (stripFilteredSuffix(marketplace)) {
    case "amazon":
      return rows.map(mapToAmazonTableData(currency));
    default:
      return [];
  }
}

function useColumnsForMarketplace(
  marketplace: SuppressedProductsMarketplace,
  t: TFunction<"translation">,
  setDrawerOpen: CommonSPTProps["setDrawerOpen"],
  setDrawerContents: CommonSPTProps["setDrawerContents"],
  theme: Theme
) {
  const issuesCell = (props: { value: CommonTableData["issueGroups"] }) => {
    const issueTypesProps: ChipCellProps = {
      cell: {
        value: {
          chips: props.value?.map((i) => ({
            title: i,
            color: getIssueGroupMap(theme)[i].color,
            details: t(getIssueGroupMap(theme)[i].details),
          })),
          width: 135, // 135 gets us the width indicated in the design
          justifyContent: "center",
          margin: "0 auto",
        },
      },
    };
    return <ChipCell {...issueTypesProps} />;
  };

  const commonColumns = [
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.product"),
      accessor: "product",
      Cell: ProductCell,
      isVisible: true,
      sticky: "left",
      id: getSnakeSortKey("product"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.inventory"),
      accessor: "inventory",
      isVisible: true,
      align: "center",
      id: getSnakeSortKey("inventory"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.dateSuppressed"),
      wrapHeaderText: true,
      accessor: "dateSuppressed",
      isVisible: true,
      align: "center",
      id: getSnakeSortKey("dateSuppressed"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.daysSuppressed"),
      wrapHeaderText: true,
      accessor: "daysSinceSuppressed",
      isVisible: true,
      align: "center",
      id: getSnakeSortKey("daysSinceSuppressed") + "2", // ids need to be unique, and dateSuppressed & daysSinceSuppressed are just views of created_at...
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.salesValue"),
      accessor: "lostValue",
      isVisible: true,
      Cell: ValueCell,
      align: "right",
      id: getSnakeSortKey("lostValue"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.status"),
      accessor: "status",
      isVisible: true,
      align: "center",
      id: getSnakeSortKey("status"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.price"),
      accessor: "price",
      isVisible: true,
      align: "right",
      Cell: ValueCell,
      id: getSnakeSortKey("price"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.issuesCount"),
      accessor: "issueCount",
      isVisible: true,
      align: "center",
      wrapHeaderText: true,
      Cell: issuesCountCell,
      id: getSnakeSortKey("issueCount"),
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.issueGroups"),
      accessor: "issueGroups",
      isVisible: true,
      align: "center",
      Cell: issuesCell,
      disableSortBy: true,
    },
    {
      Header: t("myStoresWidget.suppressedProducts.tableHeader.viewIssues"),
      accessor: "suppressedProductId", // we don't directly use the value picked out by this accessor, just setting it because we need to set one
      isVisible: true,
      align: "center",
      disableSortBy: true,
      Cell: (props: any) => {
        const row: Row = props.row;
        return (
          <SmallButton
            onClick={() => {
              setDrawerContents(row.original as unknown as DrawerContents);
              setDrawerOpen(true);
            }}
            color="primary"
          >
            <Bold variant="body2">
              {t("myStoresWidget.suppressedProducts.view")}
            </Bold>
            <StyledIcon fontSize="small" />
          </SmallButton>
        );
      },
    },
  ];

  switch (stripFilteredSuffix(marketplace)) {
    case "amazon":
      const amazonProductCell = {
        Header: t("myStoresWidget.suppressedProducts.tableHeader.product"),
        accessor: "product",
        Cell: AmazonProductCell,
        isVisible: true,
        sticky: "left",
        id: getSnakeSortKey("product"),
      };
      const amazonColumns = [amazonProductCell, ...commonColumns.slice(1)];
      amazonColumns.splice(5, 1); // Remove the status column for amazon, since all will have the same status: active
      return amazonColumns;
    default:
      return [];
  }
}

type ProductCellData = {
  value: SuppressedProductListingResponse["title"];
  secondRowValue: string; // this should be 'ASIN: xxxxx | SKU: xxxxxx' for amazon, not sure if different for others
  image: SuppressedProductListingResponse["imageUrl"];
  link: SuppressedProductListingResponse["linkUrl"];
  starRating: SuppressedProductListingResponse["starRating"];
  percentRating: SuppressedProductListingResponse["percentageRating"];
  fixLink: SuppressedProductListingResponse["fixUrl"];
  target: string;
};

// The CommonSuppressedDTO data mapped as the table needs it.
export type CommonTableData = {
  dateSuppressed: string; // from createdAt
  daysSinceSuppressed: number; // from createdAt
  lostValue: string;
  price: string;
  status: SuppressedProductListingResponse["productStatus"];
  issueGroups: IssueGroup[];
  inventory: SuppressedProductListingResponse["availableQuantity"];
} & Pick<
  SuppressedProductListingResponse,
  "issueCount" | "suppressedProductId"
> & {
    product: ProductCellData;
  };

type AmazonTableData = Omit<CommonTableData, "product"> & {
  product: Omit<ProductCellData, "starRating" | "percentRating">;
};

interface CommonSPTProps {
  marketplace: SuppressedProductsMarketplace;
  setDrawerOpen: (open: boolean) => void;
  setDrawerContents: (contents: DrawerContents) => void;
  setFetching: (loading: boolean) => void;
  setSortState: (state: { sortKey: string; sortOrder: string }) => void;
}

interface AmazonProps extends CommonSPTProps {
  marketplace: "amazon" | "amazon_filtered";
}

function hasRows(
  o: unknown
): o is Fetched<RowsWithCount<SuppressedProductListingResponse>> {
  return !!(o as Fetched<RowsWithCount<SuppressedProductListingResponse>>).rows;
}

export type SuppressedProductsTableProps = AmazonProps; // To add other marketplace, create [MarketPlace]Props as above and include here

const SuppressedProductsTable: React.FC<SuppressedProductsTableProps> = ({
  marketplace,
  setDrawerOpen,
  setDrawerContents,
  setFetching,
  setSortState,
}) => {
  const { t } = useTranslation();
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const columns = useMemo(
    () =>
      useColumnsForMarketplace(
        marketplace,
        t,
        setDrawerOpen,
        setDrawerContents,
        theme
      ),
    [useColumnsForMarketplace, marketplace, setDrawerOpen, setDrawerContents]
  );
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const sourceCurrency = store
    ? getCurrencyByCountryCode[store.marketplaceCountry]
    : DEFAULT_CURRENCY;

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const products = useTypedSelector(
    (state) => state.suppressedProducts.products
  );

  useEffect(() => setFetching(products.fetching), [products.fetching]);

  const dispatchFetchSuppressedProducts = useCallback<
    (args: {
      pageIndex: number;
      pageSize: number;
      sortBy: Array<{ id: SortableColumns; desc: boolean }>;
    }) => void
  >(
    ({ pageIndex, pageSize, sortBy }) => {
      let sortKey = sortBy && sortBy.length ? sortBy[0].id : "lost_value";
      if (sortKey === "created_at2") sortKey = "created_at"; // see column definition for why
      const sortOrder =
        sortBy && sortBy.length ? (sortBy[0].desc ? "desc" : "asc") : "desc";
      // Set the sort state for the use of csv download
      setSortState({ sortKey, sortOrder });

      if (store) {
        const args: FetchSuppressedProductsArgs = {
          mid: store.merchantId,
          pageIndex,
          pageSize,
          sortKey,
          sortOrder,
        };
        dispatch(fetchSuppressedProducts(args));
      }
    },
    [store?.merchantId]
  );

  const data = useMemo(
    () =>
      hasRows(products)
        ? mapToTableData(products.rows, marketplace, {
            sourceCurrency,
            currentCurrency,
            currencyRates,
          })
        : [],
    [
      products,
      (products as Fetched<RowsWithCount<SuppressedProductListingResponse>>)
        ?.rows,
      marketplace,
      sourceCurrency,
      currentCurrency,
      currencyRates,
    ]
  );
  const tableProps = {
    loading: products.fetching && !hasRows(products),
    columns,
    data,
    fetchData: dispatchFetchSuppressedProducts,
    sorting: true,
    pagination: true,
    pageSize: PAGE_SIZE,
    pageCount: products.fetched
      ? Math.ceil((products.count || 0) / PAGE_SIZE)
      : 0,
    tdHeight: 45,
    noDataLocizeKey: "myStoresWidget.suppressedProducts.table.noDataMessage",
  };
  return <Table {...tableProps} />;
};

export default memo(SuppressedProductsTable);
