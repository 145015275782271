import React, { memo } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useAppStatusQuery } from "@store/appStatus.redux";

interface MaintenanceBoundaryProps {
  children: React.ReactElement;
}

const MaintenanceBoundary = memo<MaintenanceBoundaryProps>(({ children }) => {
  const location = useLocation();

  const { appDown } = useAppStatusQuery(
    {},
    {
      pollingInterval: 60000, // refetch every minute
      selectFromResult: ({ data }) => ({
        appDown: data?.down ?? false,
      }),
    }
  );

  if (appDown && location.pathname !== "/maintenance") {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
        }}
      />
    );
  }
  if (!appDown && location.pathname === "/maintenance") {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return children;
});

export default MaintenanceBoundary;
