import { Grid, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import PercentageBar from "~/components/percentageBar";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

export const PercentageBarCell = memo(({ cell }) => {
  const theme = useTheme();
  const {
    correctPercentage,
    invalidPercentage,
    missingPercentage,
  } = cell.value;

  return (
    <Container>
      <PercentageBar
        data={[
          {
            percentage: correctPercentage,
            color: theme.palette.success.main,
          },
          {
            percentage: invalidPercentage,
            color: theme.palette.warning.main,
          },
          {
            percentage: missingPercentage,
            color: theme.palette.grey["300"],
          },
        ]}
      />
    </Container>
  );
});

PercentageBarCell.propTypes = {
  cell: PropTypes.shape({ value: PropTypes.any }).isRequired,
};
