import { Box, Grid, Link, Typography } from "@material-ui/core";

import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TitleContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 22px;
`;

interface MarketingBreadcrumbsProps {
  marketplace: string;
  mid: string;
  parentPage: string;
  parentTitle: string;
  title?: string;
  state: unknown;
}

const MarketingBreadcrumbs: React.FC<MarketingBreadcrumbsProps> = ({
  marketplace,
  mid,
  parentPage,
  parentTitle,
  title,
  state,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} sm={5} md={4} lg={3} alignItems="center">
      <Grid container item xs={12}>
        <KeyboardArrowLeftRounded fontSize="small" color="secondary" />
        <Box pt={"2px"}>
          <Link
            to={{
              pathname: `/mystores/${marketplace}/${parentPage}`,
              search: `?store=${encodeURI(mid)}`,
              state,
            }}
            component={RouterLink}
            underline={"none"}
            variant="body2"
            color="textSecondary"
          >
            {t("generic.backLink")} {parentTitle}
          </Link>
        </Box>
      </Grid>
      {title && (
        <Grid container item xs={12}>
          <TitleContainer>
            <Typography variant="h5" color="textPrimary" noWrap>
              {title}
            </Typography>
          </TitleContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default MarketingBreadcrumbs;
