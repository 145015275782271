/* eslint-disable no-magic-numbers */
import {
  PaletteColor,
  PaletteOptions,
} from "@material-ui/core/styles/createPalette";
import {
  palette as muiThemePalette,
  typography as muiThemeTypography,
  overrides,
  shadows,
} from "./muiTheme";

import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { createTheme } from "@material-ui/core/styles";

const primary: PaletteColor = {
  light: "#50AF5F",
  main: "#3A9E46",
  dark: "#278633",
  contrastText: "#000F36",
};

export const palette: PaletteOptions = {
  ...muiThemePalette,
  primary,
};

const typography: TypographyOptions = {
  ...muiThemeTypography,
  fontFamily: "Marine",
  body2: {
    fontSize: "0.75rem",
    fontWeight: 300,
  },
  h3: {
    fontSize: "0.75rem",
    fontWeight: 600,
  },
};

const shape = {
  borderRadius: 0,
};

/** Material UI Theme */
const theme = createTheme({
  palette,
  typography,
  shadows,
  shape,
  spacing: 8,
  overrides,
});

export default theme;

// /*
// Exporting for use in styled.d.ts so we can get typed theme inside styled components ie:
//     font-size: ${({theme})=>theme.typography.body2.fontSize};
//     color: ${({theme})=>theme.palette.chart.darkGray};
// */
export type ThemeType = typeof theme;
