import {
  CurrencyRate,
  Filter,
  Range,
  Store,
  VendorOverview,
} from "~/typedef/store";
/* A table displaying multi-channel Amazon vendor metrics */
import React, { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  formatCurrency,
  formatCurrencyRounded,
  getConvertedValue,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";
import {
  formatNumber,
  getComparisonValue,
  getConvertedComparisonValue,
  getPercentageDifference,
} from "~/utils/salesUtils";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { fetchVendorOverview } from "~/store/overview/vendorOverview.redux";
import { Panel } from "../../../components/panel/panel";
import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { ValueAndGrowthCell } from "../../../components/table/cells/valueAndGrowthCell";
import Table from "../../../components/table/table";

const formatMarketplaceData = (
  filteredStores: Store[],
  rowData: VendorOverview["rows"],
  currencyRates: CurrencyRate[],
  conditionalFormatting: boolean,
  currentCurrency?: string
) => {
  const rows = rowData
    .map((r) => {
      const store = filteredStores.find(
        (s) =>
          s.merchantId === r.mid &&
          s.marketplaceType === r.marketplaceType &&
          s.marketplaceSubtype === r.marketplaceSubtype
      );
      if (!store) return null;
      const sourceCurrency =
        getCurrencyByCountryCode[store?.marketplaceCountry];
      // currency conversions
      const convertedSalesValue = getConvertedValue(
        currencyRates,
        sourceCurrency,
        currentCurrency,
        r.poSales.value
      );
      const convertedSalesPriorValue = getConvertedValue(
        currencyRates,
        sourceCurrency,
        currentCurrency,
        r.poSales.priorPeriodValue
      );
      const convertedOrderValue =
        r.avgOrderValue?.value != null
          ? getConvertedValue(
              currencyRates,
              sourceCurrency,
              currentCurrency,
              r.avgOrderValue.value
            )
          : null;
      const convertedOrderPriorValue =
        r.avgOrderValue?.value != null
          ? getConvertedValue(
              currencyRates,
              sourceCurrency,
              currentCurrency,
              r.avgOrderValue.priorPeriodValue
            )
          : null;
      return {
        ...r,
        countryCode: store.marketplaceCountry,
        convertedSalesValue,
        convertedSalesPriorValue,
        convertedOrderValue,
        convertedOrderPriorValue,
      };
    })
    // workaround for typescript weirdness that doesn't put the correct type guards on Array.filter
    .flatMap((r) => (r !== null ? [r] : []));
  return rows
    .map((row) => {
      const shopName = {
        value: getShopName(filteredStores, row.marketplaceSubtype, row.mid),
        link: marketplaceLink(row.marketplaceSubtype, row.mid),
      };
      const orders = {
        value: row.orders.value ?? "-",
        growth: getPercentageDifference(
          row.orders.value,
          getComparisonValue(row.orders)
        ),
        conditionalFormatting: conditionalFormatting,
      };
      const sales = {
        sortValue: row.convertedSalesValue,
        value: formatCurrencyRounded(
          row.convertedSalesValue,
          currencyRates,
          currentCurrency,
          currentCurrency
        ),
        growth: getPercentageDifference(
          row.convertedSalesValue,
          getConvertedComparisonValue(row)
        ),
        conditionalFormatting: conditionalFormatting,
      };

      const avgOrderValue = {
        value:
          row.convertedOrderValue != null
            ? formatCurrency(
                row.convertedOrderValue,
                currencyRates,
                currentCurrency,
                currentCurrency
              )
            : "-",
        growth: getPercentageDifference(
          row.convertedOrderValue,
          getConvertedComparisonValue(row, "orders")
        ),
        conditionalFormatting: conditionalFormatting,
      };

      const avgOrderSize = {
        value:
          row.avgOrderSize?.value != null
            ? formatNumber(row.avgOrderSize.value)
            : "-",
        growth: getPercentageDifference(
          row.avgOrderSize.value,
          getComparisonValue(row.avgOrderSize)
        ),
        conditionalFormatting: conditionalFormatting,
      };

      return {
        ...row,
        shopName,
        orders,
        sales,
        avgOrderValue,
        avgOrderSize,
      };
    })
    .sort((a, b) => (a.sales.sortValue > b.sales.sortValue ? -1 : 1));
};

interface VendorOverviewProps {
  conditionalFormatting: boolean;
  currentCurrency?: string;
  currentFilter?: Filter;
  currentRange: Range;
  filteredStores: Store[];
  includeTax: boolean;
  pageSize: number;
  report: boolean;
}

const VendorOverview = memo(
  ({
    conditionalFormatting,
    currentCurrency,
    currentFilter,
    currentRange,
    filteredStores,
    includeTax,
    pageSize,
    report,
  }: VendorOverviewProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const dispatch = useDispatch();
    const vendorOverview = useTypedSelector(
      (state) => state.overview?.vendorOverview
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const fetchVendorData = useCallback(
      ({ pageIndex, pageSize }) => {
        dispatch(
          fetchVendorOverview(
            {
              currentRange,
              pageIndex,
              pageSize,
              filter: currentFilter,
              includeTax,
            },
            vendorOverview?.params
          )
        );
      },
      [
        dispatch,
        currentRange,
        currentFilter,
        filteredStores,
        includeTax,
        vendorOverview?.params,
      ]
    );

    const data = useMemo(
      () =>
        formatMarketplaceData(
          filteredStores,
          vendorOverview.rows,
          currencyRates,
          conditionalFormatting,
          currentCurrency
        ),
      [filteredStores, vendorOverview, currencyRates, currentCurrency]
    );

    const columns = useMemo(
      () => [
        {
          id: "marketplace",
          Header: t("dashboardWidget.marketplaceOverview.marketplaceColumn"),
          accessor: (
            row: ReturnType<typeof formatMarketplaceData>[number]
          ) => ({
            market: row.marketplaceType,
            countryCode: row.countryCode,
          }),
          Cell: MarketplaceAndCountryCell,
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.marketplaceOverview.storeColumn"),
          accessor: "shopName",
          Cell: LinkCell,
        },
        {
          id: "sales",
          Header: t("dashboardWidget.marketplaceOverview.poSalesColumn"),
          accessor: "sales",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "orders",
          Header: t("dashboardWidget.marketplaceOverview.ordersColumn"),
          accessor: "orders",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "xl" : "sm",
        },
        {
          id: "avgOrderSize",
          Header: t("dashboardWidget.marketplaceOverview.avgOrderSizeColumn"),
          accessor: "avgOrderSize",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
        {
          id: "avgOrderValue",
          Header: t("dashboardWidget.marketplaceOverview.avgOrderValueColumn"),
          accessor: "avgOrderValue",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: "md",
        },
      ],
      [report, t]
    );

    return (
      <Panel
        id="widget-marketplace-overview"
        title={t(`dashboardWidget.vendorOverview.mainTitle`)}
        tooltip={t("dashboardWidget.vendorOverview.mainTooltip")}
        content={
          <Box mb={isMobile || data.length < pageSize + 1 ? 0 : "-53px"}>
            <Table
              {...{
                columns,
                data,
                loading: vendorOverview.fetching,
                fetchData: fetchVendorData,
                pageSize,
                pagination: !report,
                pageCount: Math.ceil(vendorOverview.count / pageSize),
                gridLayoutColumns: 6,
              }}
            />
          </Box>
        }
      />
    );
  }
);

export default VendorOverview;
