import axios from "axios";
import { baseUrl } from "../configs";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";

export const FETCH_CATEGORY_SCORES_REQUEST = "FETCH_CATEGORY_SCORES_REQUEST";
export const FETCH_CATEGORY_SCORES_SUCCESS = "FETCH_CATEGORY_SCORES_SUCCESS";
export const FETCH_CATEGORY_SCORES_ERROR = "FETCH_CATEGORY_SCORES_ERROR";

const initState = {
  loading: false,
  categoryScores: [],
};

export const itemSpecifics = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_SCORES_REQUEST:
      return { ...state, loading: true };

    case FETCH_CATEGORY_SCORES_SUCCESS:
      return { ...state, loading: false, categoryScores: action.payload };
    case FETCH_CATEGORY_SCORES_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const fetchCategoryScores =
  (mid, countryCode, categoryId, siteId) => async (dispatch) => {
    dispatch({ type: FETCH_CATEGORY_SCORES_REQUEST });
    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/itemSpecifics/categoryScores`,
        {
          params: {
            mid,
            countryCode,
            categoryId,
            siteId,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_CATEGORY_SCORES_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      return dispatch({
        type: FETCH_CATEGORY_SCORES_ERROR,
      });
    }

    return dispatch({
      type: FETCH_CATEGORY_SCORES_ERROR,
    });
  };
