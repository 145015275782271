import {
  FETCH_ACCOUNT_HEALTH,
  FETCH_ACCOUNT_HEALTH_FETCHING,
} from "../mystore/mystore.redux";
import {
  FETCH_ACCOUNT_HEALTH_OVERVIEW,
  FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR,
  FETCH_ACCOUNT_HEALTH_OVERVIEW_FETCHING,
} from "./overview.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch, mid?: string) {
  await dispatch({
    type: mid
      ? FETCH_ACCOUNT_HEALTH_FETCHING
      : FETCH_ACCOUNT_HEALTH_OVERVIEW_FETCHING,
  });
}

interface FetchAccountHealthProps {
  user: User;
  mid?: string;
  pageNum?: number;
  pageSize?: number;
  filter: Filter;
  filteredStores?: any[];
  timezone: string;
}
export const fetchAccountHealth = (
  params: FetchAccountHealthProps,
  oldParams?: FetchAccountHealthProps
) => async (dispatch: Dispatch) => {
  const { user, mid, pageNum, pageSize, filter, timezone } = params;
  if (!shouldUseCache(params, oldParams)) {
    await setFetching(dispatch, mid);

    const res = await axios
      .post(`${baseUrl}/api/overview/accountHealth`, {
        user: { _id: user._id },
        timezone,
        mid,
        pageNum,
        pageSize,
        filter,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        const accountHealth = res.data;
        return await dispatch({
          type: mid ? FETCH_ACCOUNT_HEALTH : FETCH_ACCOUNT_HEALTH_OVERVIEW,
          payload: { ...accountHealth, params },
        });
      } else {
        await dispatchError(dispatch, FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR);
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    await dispatchError(dispatch, FETCH_ACCOUNT_HEALTH_OVERVIEW_ERROR);
    return setError(dispatch);
  }
};
