import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useMemo } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
  roundFractionalDigits,
} from "~/utils/currencyUtils";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import Panel from "~/components/panel/panel";
import PerformanceChart from "../../widgets/advertising/performanceChart";
import { StoreState } from "~/typedef/store";
import { fetchMarketingCampaignChart } from "~/store/mystore/marketing.redux";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CampaignChartPanelProps {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  campaignId: string;
}

const CampaignChartPanel = memo(
  ({
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    campaignId,
  }: CampaignChartPanelProps) => {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const loading = useTypedSelector((state) =>
      get(state, "marketing.chartLoading")
    );

    const data = useTypedSelector((state) => get(state, "marketing.chartData"));

    const chartData = useMemo(
      () =>
        data.map((entry: any) => ({
          reportDate: moment(get(entry, "current.report_date")).unix(),
          sales: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.attributed_sales", 0)
          ),
          acos: roundFractionalDigits(get(entry, "current.acos") || 0),
          cost: getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.cost", 0)
          ),
        })),
      [data, currentCurrency]
    );

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        fetchMarketingCampaignChart(
          userInfo._id,
          mid,
          countryCode,
          marketplaceType,
          marketplaceSubtype,
          currentRange.fromDate,
          currentRange.toDate,
          currentRange.priorFromDate,
          currentRange.priorToDate,
          campaignId,
          selectedTimezone
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      userInfo._id,
      mid,
      countryCode,
      marketplaceType,
      currentRange,
      campaignId,
      selectedTimezone,
    ]);

    return (
      <Panel
        id="widget-marketing-campaign-chart"
        title={t("advertisingDashboardWidget.campaignPerformance")}
        content={
          <Box p={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <PerformanceChart
                    title={t(CHART_TITLES[currentPeriod])}
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    chartData={chartData}
                    isLoading={loading}
                    timezone={selectedTimezone}
                    interval={currentRange.interval}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default CampaignChartPanel;
