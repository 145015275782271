import { Box, Grid, useTheme } from "@material-ui/core";
import {
  FetchSnSTrendData,
  useSubscribeAndSaveTrendQuery,
} from "~/store/mystore/subscribeAndSave.redux";
import MultiBarLineChart, {
  MultiBarLineChartProps,
} from "~/components/charts/multiBarLineChart/multiBarLineChart";
import React, { ReactChild, memo, useMemo } from "react";

import BarChartNoData from "~/components/placeholders/barChartNoData";
import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { Panel } from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { getConvertedValue } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export enum CHART_TYPE {
  Revenue = "Revenue",
  Subscribers = "Subscribers",
  AvgRevenue = "AvgRevenue",
}

export const CHART_TYPE_LEGENDS_MAPPING = {
  [CHART_TYPE.Revenue]: "chartLegends.revenue",
  [CHART_TYPE.Subscribers]: "chartLegends.subscribers",
  [CHART_TYPE.AvgRevenue]: "chartLegends.avgRevenue",
};

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

interface SubscribeAndSaveTrendChartProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubType: string;
  countryCode: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  actions?: ReactChild;
  chartOption: ChartOption;
}

type ChartDataFormatter = (
  item: FetchSnSTrendData
) => Record<string, number | string>;

const SubscribeAndSaveTrendChart = memo<SubscribeAndSaveTrendChartProps>(
  function SubscribeAndSaveTrendChart({
    mid,
    marketplaceType,
    marketplaceSubType,
    countryCode,
    currentPeriod,
    currentRange,
    currentCurrency,
    actions,
    chartOption,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const { interval } = currentRange;

    const { data, isTrendChartFetching } = useSubscribeAndSaveTrendQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubType,
        countryCode,
        currentRange,
      },
      {
        selectFromResult: ({ isFetching, data }) => ({
          isTrendChartFetching: isFetching,
          data: data?.chartData ?? [],
        }),
      }
    );

    const trendChartData = useMemo(() => {
      const getValue = (value: number, currency: string): number =>
        Number.isFinite(value)
          ? getConvertedValue(currencyRates, currency, currentCurrency, value)
          : value;

      const getRevenue = (
        item: FetchSnSTrendData
      ): { current: number; prior: number } => ({
        current: getValue(item.current.revenue, item.currency),
        prior: getValue(item.prior.revenue, item.currency),
      });

      const getSubscribers = (
        item: FetchSnSTrendData
      ): { current: number; prior: number } => ({
        current: item.current.subscribers,
        prior: item.prior.subscribers,
      });

      const getAvgRevenue = (
        item: FetchSnSTrendData
      ): { current: number; prior: number } => {
        const currentAvg = item.current.subscribers
          ? item.current.revenue / item.current.subscribers
          : 0;
        const priorAvg = item.prior.subscribers
          ? item.prior.revenue / item.prior.subscribers
          : 0;
        return {
          current: getValue(currentAvg, item.currency),
          prior: getValue(priorAvg, item.currency),
        };
      };

      const chartDataFormatter: { [K in CHART_TYPE]: ChartDataFormatter } = {
        [CHART_TYPE.Revenue]: (item: FetchSnSTrendData) => ({
          startTime: item.startDate,
          endTime: item.endDate,
          ...getRevenue(item),
        }),
        [CHART_TYPE.Subscribers]: (item: FetchSnSTrendData) => ({
          startTime: item.startDate,
          endTime: item.endDate,
          ...getSubscribers(item),
        }),
        [CHART_TYPE.AvgRevenue]: (item: FetchSnSTrendData) => ({
          startTime: item.startDate,
          endTime: item.endDate,
          ...getAvgRevenue(item),
        }),
      };

      return data.map((item) => {
        return chartDataFormatter[chartOption.value](item);
      });
    }, [data, chartOption, currencyRates, currentCurrency]);

    const chartProps = useMemo(() => {
      const isCurrency = [CHART_TYPE.Revenue, CHART_TYPE.AvgRevenue].includes(
        chartOption?.value
      );
      const currentAndPrior = [
        {
          key: "current",
          colour: theme.palette.primary.main,
          toFixed: isCurrency ? 2 : 0,
          isCurrency,
          axis: "1" as const,
        },
        {
          key: "prior",
          colour: theme.palette.secondary.main,
          toFixed: isCurrency ? 2 : 0,
          isCurrency,
          axis: "1" as const,
        },
      ];

      const chartProps: MultiBarLineChartProps = {
        title: t(CHART_TITLES[currentPeriod]),
        currentPeriod,
        currentCurrency,
        currencyRates,
        chartData: trendChartData,
        isLoading: false, // We have a loading check in our content
        lines: [],
        bars: [],
        xKey: "startTime",
        // hardcoding timezone to UTC as we don't receive daily/hourly data
        timezone: "UTC",
        interval,
        tooltipPercentageChange: [
          {
            dataKey1: "current",
            dataKey2: "prior",
            toFixedDigits: 0,
          },
        ],
        tooltipAttainment: [],
      };
      chartProps.bars.push(...currentAndPrior);

      return chartProps;
    }, [theme, t, trendChartData]);

    return (
      <Panel
        id="widget-sns-trend-chart"
        title={t("myStoresWidget.snsTrendChart.mainTitle")}
        tooltip={t("subscribeAndSave.chartIntervalRestriction")}
        subtitle={
          trendChartData.length
            ? t("myStoresWidget.snsTrendChart.timePeriod", {
                fromDate: moment
                  .unix(Number(trendChartData[0].startTime))
                  .format("DD MMM YY"),
                toDate: moment
                  .tz(
                    moment.unix(
                      Number(trendChartData[trendChartData.length - 1].endTime)
                    ),
                    currentRange.timezone
                  )
                  .format("DD MMM YY"),
              })
            : undefined
        }
        actions={actions}
        content={
          isTrendChartFetching ? (
            <PanelLoading />
          ) : !data.length ? (
            <BarChartNoData {...{ currentPeriod: currentPeriod }} />
          ) : (
            <Box p={2}>
              <Grid container>
                <Grid container item xs={12}>
                  <MultiBarLineChart {...chartProps} />
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default SubscribeAndSaveTrendChart;
