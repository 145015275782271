import {
  Card,
  Collapse,
  CollapseProps,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  PaletteColor,
  PaletteOptions,
} from "@material-ui/core/styles/createPalette";
import React, { memo } from "react";
import styled, { DefaultTheme } from "styled-components";

import CloseIcon from "@material-ui/icons/Close";

interface NotificationCardProps {
  theme: DefaultTheme;
  type: keyof PaletteOptions | "plain";
}
const NotificationCard = styled(Card)`
  ${({ theme, type }: NotificationCardProps) => `
    background-color: ${
      type === "plain"
        ? theme.palette.background.paper
        : (theme.palette[type] as PaletteColor).main
    };
    color: ${
      type === "plain"
        ? theme.palette.text.primary
        : (theme.palette[type] as PaletteColor).contrastText
    };
    text-align: left;
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

const RightIconButton = styled(IconButton)`
  float: right;
  margin-top: auto;
  margin-bottom: auto;
`;

const FullWidthCollapse = styled(Collapse)`
  width: 100%;
  ${(props: CollapseProps) =>
    props.in ? "min-height: fit-content !important;" : ""}
`;

const WhiteCloseIcon = styled(CloseIcon)`
  ${({ theme, type }: NotificationCardProps) => `
    max-width: 16px;
    fill: ${
      type === "plain"
        ? theme.palette.text.primary
        : (theme.palette[type] as PaletteColor).contrastText
    };
    stroke: ${
      type === "plain"
        ? theme.palette.text.primary
        : (theme.palette[type] as PaletteColor).contrastText
    };
    stroke-width: 2;
  `}
`;

const NotificationCardContent = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  padding: 0.75rem 1rem;
`;

interface AlertCardProps {
  children: any;
  isOpen: boolean;
  onClose?: () => void;
  type?: keyof PaletteOptions | "plain";
}

const AlertCard = memo((props: AlertCardProps) => {
  const { children, isOpen, onClose, type = "success", ...others } = props;
  return (
    <FullWidthCollapse in={isOpen} timeout="auto" {...others}>
      <NotificationCard type={type}>
        <NotificationCardContent>
          {typeof children === "string" && (
            <Typography variant="body1">{children}</Typography>
          )}
          {typeof children !== "string" && children}
        </NotificationCardContent>
        {onClose && (
          <RightIconButton
            aria-label="close notification"
            onClick={() => onClose()}
          >
            <WhiteCloseIcon type={type} fontSize="small" />
          </RightIconButton>
        )}
      </NotificationCard>
    </FullWidthCollapse>
  );
});

export default AlertCard;
