import React, { memo } from "react";

import StopIcon from "@material-ui/icons/Stop";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LegendContainer = styled.dl`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
`;

const LegendDotContainer = styled.dt``;

const LegendTextContainer = styled.dd`
  padding: 0 16px 8px 4px;
`;

const CorrectIcon = styled(StopIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.success.main};
`;

const CustomIcon = styled(StopIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.warning.main};
`;

const MissingIcon = styled(StopIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.grey["300"]};
`;

const ItemLegend = memo(() => {
  const { t } = useTranslation();
  return (
    <LegendContainer>
      <LegendDotContainer>
        <CorrectIcon />
      </LegendDotContainer>
      <LegendTextContainer>
        <Typography variant="body2" component="span">
          {t("myStoresWidget.itemSpecifics.legend.correct")}
        </Typography>
      </LegendTextContainer>
      <LegendDotContainer>
        <CustomIcon />
      </LegendDotContainer>
      <LegendTextContainer>
        <Typography variant="body2" component="span">
          {t("myStoresWidget.itemSpecifics.legend.custom")}
        </Typography>
      </LegendTextContainer>
      <LegendDotContainer>
        <MissingIcon />
      </LegendDotContainer>
      <LegendTextContainer>
        <Typography variant="body2" component="span">
          {t("myStoresWidget.itemSpecifics.legend.missing")}
        </Typography>
      </LegendTextContainer>
    </LegendContainer>
  );
});

export default ItemLegend;
