import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import PurchaseOrderStatus from "~/modules/widgets/amazonVendor/purchaseOrderStatus";
import PurchaseOrdersList from "~/modules/widgets/amazonVendor/purchaseOrders";
import PurchaseOrdersPerformance from "~/modules/widgets/amazonVendor/purchaseOrdersPerformance";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PurchaseOrders = memo(() => {
  const marketplace = useMarketplace();
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return store ? (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <PurchaseOrdersPerformance market={marketplace} store={store} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PurchaseOrderStatus />
        </Grid>
        <Grid item xs={12}>
          <PurchaseOrdersList marketplace={marketplace} store={store} />
        </Grid>
      </Grid>
    </PageBlock>
  ) : (
    <PageBlock>
      <LoadingIndicator />
    </PageBlock>
  );
});

export default PurchaseOrders;
