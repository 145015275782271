import { Box, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useMemo, useState } from "react";
import SubscribeAndSaveTrendChart, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/widgets/subscribeAndSave/subscribeAndSaveChart";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import AlertCard from "~/components/alert/alertCard";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { Info } from "@material-ui/icons";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import SubscribeAndSaveSummary from "~/modules/widgets/subscribeAndSave/subscribeAndSaveSummary";
import SubscribeAndSaveTable from "~/modules/widgets/subscribeAndSave/subscribeAndSaveTable";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { isValidSnSCountry } from "mm-amazon-common/dist/shared/countryUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const AlertIcon = styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 1rem;
`;

const PAGE_SIZE = 10;

const SubscriberAndSave = memo(function SubscriberAndSave() {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store.marketplaceCountry],
    currentCurrency
  );

  if (!isValidSnSCountry(store.marketplace, store.marketplaceCountry)) {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AlertCard isOpen={true} type="warning">
              {t("subscribeAndSave.unsupportedCountry")}
            </AlertCard>
          </Grid>
        </Grid>
      </PageBlock>
    );
  }

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.Revenue,
        label: t("subscribeAndSave.chartSelect.revenue"),
      },
      {
        value: CHART_TYPE.Subscribers,
        label: t("subscribeAndSave.chartSelect.subscribers"),
      },
      {
        value: CHART_TYPE.AvgRevenue,
        label: t("subscribeAndSave.chartSelect.avgRevenue"),
      },
    ],
    []
  );

  const [chartOption, setChartOption] = useState<ChartOption>(CHART_OPTIONS[0]);

  const switchChartOption = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartOption(option);
    }
  };

  const actions = (
    <>
      <TableFilter
        {...{
          width: "180px",
          options: CHART_OPTIONS,
          currentValue: chartOption.label,
          handleChange: switchChartOption,
        }}
      />
    </>
  );

  const tableActions = (
    <>
      <Box mr={2}>
        <SearchFilter {...{ setSearchText }} />
      </Box>
      <DownloadCsv
        reportType="snsProductPerformance"
        path="/api/amazon/subscribeAndSave/productPerformance"
        mid={store?.merchantId}
        params={{
          mid: store.merchantId,
          shopName: store.storeName,
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
          countryCode: store.marketplaceCountry,
          currency: currentCurrency,
          exchangeRate,
          ...currentRange,
        }}
      />
    </>
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SubscribeAndSaveTrendChart
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubType={store.marketplaceSubtype || ""}
            countryCode={store.marketplaceCountry}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            chartOption={chartOption}
            actions={actions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SubscribeAndSaveSummary
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubType={store.marketplaceSubtype}
            countryCode={store.marketplaceCountry}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentCompare={currentCompare}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <SubscribeAndSaveTable
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
              countryCode={store.marketplaceCountry}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              pageSize={PAGE_SIZE}
              searchText={searchText}
              actions={tableActions}
            />
          </Box>
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default SubscriberAndSave;
