import { CurrencyRate, Store } from "~/typedef/store";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";

import { FetchShippedRevenueByStoresResponse } from "~/store/mystore/vendor.redux";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";

// the monetary values are in USD, as received from the API
const DATA_CURRENCY = "USD";

/**
 * Generates rows for the shipped revenue by stores table based on the provided data.
 *  formatCurrencyRounded is used to format the currency values, it also converts the DATA_CURRENCY to the display currency.
 */
export const generateShippedRevenueByStoresTableRows = (
  shippedRevenueByStores: FetchShippedRevenueByStoresResponse["shippedRevenueByStores"],
  filteredStores: Store[],
  currentCurrency: string,
  currencyRates: CurrencyRate[],
  conditionalFormatting?: boolean
) => {
  return shippedRevenueByStores.map((shippedRevenueByStore) => {
    const { current, prior } = shippedRevenueByStore;

    const channel = {
      value: getShopName(
        filteredStores,
        current.marketplaceType,
        current.storeName
      ),
      link: marketplaceLink(current.marketplaceType, current.mid),
    };

    const shippedRevenue = {
      value: formatCurrencyRounded(
        current.shippedRevenue,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(
        current.shippedRevenue,
        prior?.shippedRevenue
      ),
      conditionalFormatting,
    };

    const shippedCogs = {
      value: formatCurrencyRounded(
        current.shippedCogs,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(current.shippedCogs, prior?.shippedCogs),
      conditionalFormatting,
    };

    const shippedUnits = {
      value: numberWithCommas(current.shippedUnits) ?? "-",
      growth: getPercentageDifference(
        current.shippedUnits,
        prior?.shippedUnits
      ),
      conditionalFormatting,
    };

    const avgCogs = {
      value: formatCurrency(
        current.avgCogs,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(current.avgCogs, prior?.avgCogs),
      conditionalFormatting,
    };

    const shippedRevenueManufacturing = {
      value: formatCurrencyRounded(
        current.shippedRevenueManufacturing,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(
        current.shippedRevenueManufacturing,
        prior?.shippedRevenueManufacturing
      ),
      conditionalFormatting,
    };

    const shippedCogsManufacturing = {
      value: formatCurrencyRounded(
        current.shippedCogsManufacturing,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(
        current.shippedCogsManufacturing,
        prior?.shippedCogsManufacturing
      ),
      conditionalFormatting,
    };

    const shippedUnitsManufacturing = {
      value: numberWithCommas(current.shippedUnitsManufacturing) ?? "-",
      growth: getPercentageDifference(
        current.shippedUnitsManufacturing,
        prior?.shippedUnitsManufacturing
      ),
      conditionalFormatting,
    };

    const avgCogsManufacturing = {
      value: formatCurrencyRounded(
        current.avgCogsManufacturing,
        currencyRates,
        DATA_CURRENCY,
        currentCurrency
      ),
      growth: getPercentageDifference(
        current.avgCogsManufacturing,
        prior?.avgCogsManufacturing
      ),
      conditionalFormatting,
    };

    return {
      marketplace: {
        market: current.marketplaceType,
        countryCode: current.marketplaceCountry,
      },
      channel,
      shippedRevenue,
      shippedCogs,
      shippedUnits,
      avgCogs,
      shippedRevenueManufacturing,
      shippedCogsManufacturing,
      shippedUnitsManufacturing,
      avgCogsManufacturing,
    };
  });
};
