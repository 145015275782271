import { Box } from "@material-ui/core";
import styled from "styled-components";

const Cell = styled(Box)<{
  $startAlign?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: ${({ $startAlign }) =>
    $startAlign ? "flex-start" : "flex-end"};
  min-height: 20px;
  max-height: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

export default Cell;
