import { Paper, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const InfoBoxContainer = styled(Paper)`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-right: 8px;
  border-left: 5px solid ${({ theme }) => theme.palette.info.main};
  background: ${({ theme }) => theme.palette.border.main};
`;

const InfoBox = memo(({ children }) => {
  const theme = useTheme();
  return <InfoBoxContainer theme={theme}>{children}</InfoBoxContainer>;
});

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoBox;
