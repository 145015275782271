import { Box, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useMemo, useState } from "react";
import SourcingShareTrend, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/vendor/sourcingShareTrend";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import ShippedUnitsSummary from "~/modules/vendor/shippedUnitsSummary";
import SourcingShareTable from "~/modules/vendor/sourcingShareTable";
import { StoreState } from "~/typedef/store";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface SourcingShareProps {
  singleWidget?: "table" | "summary" | "trend";
}

const SourcingShare = memo<SourcingShareProps>(function SourcingShare({
  singleWidget,
}) {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.user);

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.LostCogs,
        label: t("sourcingShare.lostCogs"),
      },
      {
        value: CHART_TYPE.LostUnits,
        label: t("sourcingShare.lostUnits"),
      },
    ],
    [user]
  );

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );

  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const [searchText, setSearchText] = useState("");
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const chartActions = (
    <TableFilter
      {...{
        width: "165px",
        options: CHART_OPTIONS,
        currentValue: chartType.label,
        handleChange: switchChartType,
      }}
    />
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const tableActions = (
    <>
      <SearchFilter setSearchText={setSearchText} />
      <DownloadCsv
        {...{
          reportType: "sourcingShare",
          path: "/api/generic/vendor/sourcingShare",
          mid: store.merchantId,
          params: {
            fromDate: currentRange.fromDate,
            priorFromDate: currentRange.priorFromDate,
            toDate: currentRange.toDate,
            priorToDate: currentRange.priorToDate,
            timezone: currentRange.timezone,
            sortKey: "lost_cogs",
            sortOrder: "desc",
            searchText,
            currentCurrency,
            currencyRates: currencyRates,
          },
        }}
      />
    </>
  );
  if (singleWidget) {
    switch (singleWidget) {
      case "trend":
        return (
          <SourcingShareTrend
            userInfo={user}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            chartType={chartType}
            actions={chartActions}
            timezone={selectedTimezone}
          />
        );
      case "summary":
        return (
          <ShippedUnitsSummary
            currentFilter={currentFilter}
            currentRange={currentRange}
            mid={store.merchantId}
            user={user}
            currentCurrency={currentCurrency}
            condensed={true}
            country={store.marketplaceCountry}
          />
        );
      case "table":
        return (
          <SourcingShareTable
            user={user}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            pageSize={5}
            condensed={true}
            country={store.marketplaceCountry}
          />
        );
      default:
        return (
          <SourcingShareTable
            user={user}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            pageSize={5}
            condensed={true}
            country={store.marketplaceCountry}
          />
        );
    }
  } else {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <SourcingShareTrend
              userInfo={user}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              chartType={chartType}
              actions={chartActions}
              timezone={selectedTimezone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ShippedUnitsSummary
              currentFilter={currentFilter}
              currentRange={currentRange}
              mid={store.merchantId}
              user={user}
              currentCurrency={currentCurrency}
              country={store.marketplaceCountry}
            />
          </Grid>
          <Grid item xs={12}>
            <SourcingShareTable
              user={user}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              searchText={searchText}
              actions={tableActions}
              pageSize={PAGE_SIZE}
              country={store.marketplaceCountry}
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
});

export default SourcingShare;
