/** The default layout of the store dashboard page.
Contrast with customStoreDashboard.tsx */
import { Box, Grid } from "@material-ui/core";
import {
  hasAnyAdvertisingStores,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import AccountHealth from "~/modules/overview/accountHealth";
import AdSpendInvestmentVsBudget from "~/modules/overview/adSpendInvestmentVsBudget";
import BuyBoxSummary from "~/modules/widgets/buyboxSummary";
import CampaignPerformance from "~//modules/marketing/campaignPerformance";
import CampaignTypes from "~/modules/marketing/campaignTypePieChart";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DispatchStatus from "~/modules/widgets/overview/dispatchStatus";
import GenericBuybox from "../pricing/genericBuyboxPerformance";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import ItemSpecifics from "~/modules/widgets/itemSpecifics/itemSpecifics";
import ListingQuality from "~/modules/widgets/listingQuality/listingQuality";
import { ListingQualityStatus } from "~/modules/marketplaceListings/listingQuality/listingQualityStatus";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import NotificationsPieChart from "~/components/charts/pieChart/notificationsPieChart";
import OverviewChartWrapper from "~/modules/marketing/charts/overviewChartWrapper";
import OverviewTotalsWrapper from "~/modules/marketing/charts/overviewTotalsWrapper";
import { Panel } from "~/components/panel/panel";
import ProfitAndLossStatement from "../profitability/profitAndLossStatement";
import ProfitabilitySplit from "~/modules/widgets/profitability/profitabilitySplit";
import React from "react";
import SalesByBrandTable from "~/modules/overview/salesByBrand";
import SalesByCategoryTable from "~/modules/overview/salesByCategory";
import SalesByDay from "../../../modules/widgets/salesByDay";
import SalesByProduct from "../../../modules/overview/salesByProduct";
import SalesPerformance from "~/modules/overview/salesPerformance";
import SalesPerformanceAgainstBudget from "~/modules/overview/salesPerformanceAgainstBudget";
import SalesPerformanceAgainstForecast from "~/modules/overview/salesPerformanceAgainstForecast";
import SuppressedProducts from "../marketplaceListings/suppressedProducts";
import TrafficAndConversionTrend from "~/modules/trafficAndConversion/trafficAndConversionTrend";
import { get } from "lodash";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import styled from "styled-components";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

const FullHeightBox = styled(Box)`
  height: 100%;
`;

interface DefaultStoreDashboardProps {
  isAdvertisingAuthorised: boolean;
  isReconnectAdvertising: boolean;
}

const DefaultStoreDashboard = ({
  isAdvertisingAuthorised,
  isReconnectAdvertising,
}: DefaultStoreDashboardProps) => {
  const { t } = useTranslation();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const { currentPeriod, selectedTimezone, currentRange } =
    useDateRangeFilters();
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  if (store) {
    const isVendor = useVendorStore(store.merchantId);
    const isAdvertising = hasAnyAdvertisingStores([store]);

    return (
      <>
        <GridWithErrorBoundary item xs={12} md={8}>
          <SalesPerformance market={store.marketplace} store={store} />
        </GridWithErrorBoundary>
        <GridWithErrorBoundary container item xs={12} md={4}>
          <AccountHealth
            market={store.marketplace as keyof typeof healthDataConfig}
            mid={store.merchantId}
            condensed
          />
        </GridWithErrorBoundary>
        <Grid container item spacing={2}>
          <GridWithErrorBoundary item xs={12} md={isAdvertising ? 4 : 6}>
            <SalesPerformanceAgainstBudget
              mid={store.merchantId}
              marketplaceType={store.marketplace}
            />
          </GridWithErrorBoundary>
          <GridWithErrorBoundary item xs={12} md={isAdvertising ? 4 : 6}>
            <SalesPerformanceAgainstForecast
              mid={store.merchantId}
              marketplaceType={store.marketplace}
            />
          </GridWithErrorBoundary>
          {isAdvertising && (
            <GridWithErrorBoundary item xs={12} md={4}>
              <AdSpendInvestmentVsBudget
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                countryCode={store.marketplaceCountry}
                marketplaceSubtype={store.marketplaceSubtype}
              />
            </GridWithErrorBoundary>
          )}
        </Grid>
        {stripFilteredSuffix(store.marketplace) === "ebay" && (
          <>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByProduct
                mid={store.merchantId}
                marketplace={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                countryCode={store.marketplaceCountry}
                sellerId={store.sourceSystemId}
                type="topSellers"
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByDay mid={store.merchantId} market={store.marketplace} />
            </GridWithErrorBoundary>
          </>
        )}
        {stripFilteredSuffix(store.marketplace) === "amazon" && (
          <>
            <GridWithErrorBoundary item xs={12} md={8}>
              <TrafficAndConversionTrend
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                timezone={selectedTimezone}
                currentCurrency={currentCurrency}
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <NotificationsPieChart
                title={t("notificationsWidget.unresolvedNotifications")}
                inPanel={true}
                store={store}
                flexDirection="column"
              />
            </GridWithErrorBoundary>
          </>
        )}
        {stripFilteredSuffix(store.marketplace) === "amazon" && (
          <>
            <GridWithErrorBoundary item xs={12} md={8}>
              <ProfitAndLossStatement
                title={t("nav.storeProfit")}
                showComparison={true}
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <ProfitabilitySplit singleStore={true} condensed={true} />
            </GridWithErrorBoundary>
          </>
        )}
        {isAdvertisingAuthorised && (
          <>
            <GridWithErrorBoundary item xs={12} md={8}>
              <OverviewChartWrapper
                countryCode={store.marketplaceCountry}
                marketplaceType={store.marketplace}
                mid={store.merchantId}
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <OverviewTotalsWrapper
                countryCode={store.marketplaceCountry}
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <CampaignPerformance
                market={store.marketplace}
                mid={store.merchantId}
                countryCode={store.marketplaceCountry}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <CampaignTypes
                {...{
                  mid: store.merchantId,
                  countryCode: store.marketplaceCountry,
                  marketplaceType: store.marketplace,
                }}
              />
            </GridWithErrorBoundary>
          </>
        )}
        {stripFilteredSuffix(store.marketplace) === "amazon" && (
          <>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByCategoryTable
                market={store.marketplace}
                mid={store.merchantId}
                condensed
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByBrandTable
                market={store.marketplace}
                mid={store.merchantId}
                condensed
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByProduct
                mid={store.merchantId}
                marketplace={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                countryCode={store.marketplaceCountry}
                sellerId={store.sourceSystemId}
                type="topSellers"
                condensed={true}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByProduct
                mid={store.merchantId}
                marketplace={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                countryCode={store.marketplaceCountry}
                sellerId={store.sourceSystemId}
                type="worstSellers"
                condensed={true}
              />
            </GridWithErrorBoundary>
            {store.marketplace === "amazon" && (
              <>
                <GridWithErrorBoundary item xs={12} md={6}>
                  <FullHeightBox>
                    <GenericBuybox singleWidget={"trend"} condensed={true} />
                  </FullHeightBox>
                </GridWithErrorBoundary>
                <GridWithErrorBoundary item xs={12} md={6}>
                  <FullHeightBox>
                    <BuyBoxSummary
                      {...{
                        store: {
                          sourceSystemId: store.sourceSystemId,
                          marketplaceCountry: store.marketplaceCountry,
                          merchantId: store.merchantId,
                        },
                        showFooterLink: true,
                      }}
                    />
                  </FullHeightBox>
                </GridWithErrorBoundary>
              </>
            )}
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByDay mid={store.merchantId} market={store.marketplace} />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <DispatchStatus
                market={store.marketplace}
                mid={store.merchantId}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12}>
              <SuppressedProducts fromDashboard={true} showFooterLink={true} />
            </GridWithErrorBoundary>
          </>
        )}
        {(stripFilteredSuffix(store.marketplace) === "amazon" ||
          store.marketplace === "walmart") &&
          !isAdvertisingAuthorised && (
            <GridWithErrorBoundary item xs={12} md={6}>
              <Panel
                id="widget-marketing-campaign-chart"
                title={t(
                  "advertisingDashboardWidget.authorise.performanceTrendCampaignPerformance"
                )}
                content={
                  <MarketingAuthoriseUser
                    condensed
                    isReconnect={isReconnectAdvertising}
                  />
                }
              />
            </GridWithErrorBoundary>
          )}
        {store.marketplace === "amazon" && !isVendor && (
          <GridWithErrorBoundary item xs={12} md={6}>
            <SalesByProduct
              mid={store.merchantId}
              marketplace={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
              countryCode={store.marketplaceCountry}
              sellerId={store.sourceSystemId}
              type="topBSR"
              condensed={true}
            />
          </GridWithErrorBoundary>
        )}
        {stripFilteredSuffix(store.marketplace) !== "amazon" &&
          stripFilteredSuffix(store.marketplace) !== "ebay" && (
            <>
              <GridWithErrorBoundary item xs={12} md={6}>
                <SalesByProduct
                  mid={store.merchantId}
                  marketplace={store.marketplace}
                  marketplaceSubtype={
                    store.marketplaceSubtype ?? store.marketplace
                  }
                  countryCode={store.marketplaceCountry}
                  sellerId={store.sourceSystemId}
                  type="topSellers"
                  condensed={true}
                />
              </GridWithErrorBoundary>
              <GridWithErrorBoundary item xs={12} md={6}>
                <SalesByProduct
                  mid={store.merchantId}
                  marketplace={store.marketplace}
                  marketplaceSubtype={
                    store.marketplaceSubtype ?? store.marketplace
                  }
                  countryCode={store.marketplaceCountry}
                  sellerId={store.sourceSystemId}
                  type="worstSellers"
                  condensed={true}
                />
              </GridWithErrorBoundary>
            </>
          )}
        {stripFilteredSuffix(store.marketplace) !== "amazon" && (
          <>
            <GridWithErrorBoundary item xs={12} md={6}>
              <SalesByCategoryTable
                market={store.marketplace}
                mid={store.merchantId}
                condensed
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={6}>
              <DispatchStatus
                market={store.marketplace}
                mid={store.merchantId}
              />
            </GridWithErrorBoundary>
          </>
        )}
        {store.marketplace === "ebay" && (
          <>
            <GridWithErrorBoundary item xs={12} md={8}>
              <ItemSpecifics
                {...{
                  market: store.marketplace,
                  mid: store.merchantId,
                  countryCode: store.marketplaceCountry,
                }}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <ListingQuality
                {...{
                  market: store.marketplace,
                  mid: store.merchantId,
                  countryCode: store.marketplaceCountry,
                }}
              />
            </GridWithErrorBoundary>
          </>
        )}
        {store.marketplace === "walmart" && (
          <GridWithErrorBoundary item xs={12}>
            <ListingQualityStatus showFooterLink={true} />
          </GridWithErrorBoundary>
        )}
      </>
    );
  }
  return <LoadingIndicator />;
};

export default DefaultStoreDashboard;
