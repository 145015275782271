import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import RaisedButton from "~/components/buttons/raisedButton";
import { baseUrl } from "../../configs";
import { getCountryCodeISO3ToName } from "~/utils/countryUtils";
import styled from "styled-components";
import useAllegroAuthcodeAuth from "~/hooks/useAllegroAuthcodeAuth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useValidateAllegroAuthMutation } from "~/store/connections/connectAllegro.redux";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

const countries = ["POL", "CZE", "SVK"].map((countryCode) => ({
  value: countryCode,
  label: getCountryCodeISO3ToName(countryCode),
}));

interface AllegroConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const AllegroConnectPage: React.FC<AllegroConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [shopName, setShopName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isAdded, setIsAdded] = useState(false);

  const onAuthError = useCallback((error: string) => {
    setError(t("connectWizard.connectionError"));
    console.error(error);
  }, []);

  const [validateAllegroAuth] = useValidateAllegroAuthMutation({
    fixedCacheKey: "allegroAuth",
  });

  const onAuthCode = useCallback(
    async (code: string) => {
      validateAllegroAuth({
        authCode: code,
        shopName,
        countryCode,
      })
        .unwrap()
        .then(({ code, errMsg }) => {
          if (errMsg) {
            onAuthError(errMsg);
          } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
            onAuthError("connectWizard.connectionError");
          } else {
            setIsAdded(true);
            onSuccess();
          }
        })
        .catch((error) => {
          const errorMessage = error.message || "connectWizard.connectionError";
          onAuthError(errorMessage);
        });
    },
    [shopName, countryCode]
  );

  const triggerSignin = useAllegroAuthcodeAuth(onAuthCode, onAuthError);
  const handleSignin = () => {
    triggerSignin(countryCode);
  };

  return (
    <GridWrapper id="allegro-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Medium color="textPrimary" align="center">
            {t("connectWizard.allegroLinkTitle")}
          </Medium>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/allegro.jpg`} />
        </Grid>
        {!isAdded && (
          <>
            <Grid container item xs={12}>
              <Box pt={3}>
                <Medium>{t("connectWizard.allegroCountryLabel")}</Medium>
              </Box>
              <Box pt={1} pb={1} width={1}>
                <NativeSelect
                  name={"countryCode"}
                  value={countryCode}
                  options={countries}
                  onChange={(e) => setCountryCode(e.target.value as string)}
                  autoFocus={true}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <AccountNameWrapper
                fullWidth
                label={t("connectWizard.shopNameTitle")}
                margin="normal"
                required
                onChange={(e) => {
                  setShopName(e.target.value);
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              item
              xs={12}
            >
              <Grid item>
                <RaisedButton color="primary" onClick={handleSignin}>
                  <StyledWrapper>
                    <StyledChildren>
                      {t("connectWizard.connectButton")}
                    </StyledChildren>
                  </StyledWrapper>
                </RaisedButton>
              </Grid>
            </Grid>
          </>
        )}
        {error && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessage color="error">{error}</ErrorMessage>
          </Grid>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default AllegroConnectPage;
