import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";

import React from "react";
import ShippedRevenueByStoresTable from "~/modules/vendor/shippedRevenueByStoresTable/shippedRevenueByStoresTable";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

const ShippedRevenueByStores = () => {
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );

  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentFilter = useTypedSelector(
    (state) =>
      state?.persistentAppSettings?.setting?.data?.currentFilter ||
      DEFAULT_FILTER
  );

  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currentViewPreference = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.viewPreference") ||
      "sourcing"
  );

  return (
    <ShippedRevenueByStoresTable
      currentCurrency={currentCurrency}
      currentRange={currentRange}
      pageSize={PAGE_SIZE}
      currentFilter={currentFilter}
      view={currentViewPreference}
      filteredStores={filteredStores}
    />
  );
};

export default ShippedRevenueByStores;
