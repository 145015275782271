import { Box, Grid, Typography, withTheme } from "@material-ui/core";
import { ExpandLessOutlined, ExpandMoreRounded } from "@material-ui/icons";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";

import { LinkCell } from "~/components/table/cells/linkCell";
import Medium from "~/components/typography/medium";
import Panel from "~/components/panel/panel.tsx";
import PropTypes from "prop-types";
import Table from "~/components/table/table";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { fetchCancelledOrdersList } from "../../store/overview/cancelledOrders.redux";
import { fetchOverdueOrdersList } from "../../store/overview/overdueOrders.redux";
import { get } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

const ORDER_TYPE = {
  ToSend: "ordersToSend",
  Overdue: "overdueOrders",
  AtRisk: "ordersDueToday",
  Cancelled: "cancelledOrders",
};

const AlignEnd = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SubRow = withTheme(styled(Box)`
  border-bottom: 1px solid #ededf0;
  padding: 1rem;
  ${({ theme }) => `
    ${theme.breakpoints.down("xs")} {
      padding: 1rem;
    }
 `}
`);

const EqualWidthItems = styled(Grid)`
  display: flex;
  flex: ${({ width }) => width} 1 0;
`;

const OverdueOrdersTable = memo(({ mid, market }) => {
  const { t } = useTranslation();
  const ORDER_OPTIONS = [
    {
      value: ORDER_TYPE.Overdue,
      label: t("dashboardWidget.dispatchStatus.overdueOrdersLabel"),
    },
    {
      value: ORDER_TYPE.AtRisk,
      label: t("dashboardWidget.dispatchStatus.ordersDueTodayLabel"),
    },
    {
      value: ORDER_TYPE.ToSend,
      label: t("dashboardWidget.dispatchStatus.ordersToSendLabel"),
    },
    {
      value: ORDER_TYPE.Cancelled,
      label: t("dashboardWidget.dispatchStatus.cancelledOrders"),
    },
  ];

  const userInfo = useSelector((state) => state.user);
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useSelector((state) => state.globalVar.currencyRates);

  const overdueOrders = useSelector((state) => state.mystore.overdueOrdersList);

  const selectedTimezone = useSelector((state) =>
    get(state, "currentRange.timezone", moment.tz.guess())
  );

  const urlSearchParams = useQueryParams();
  const urlOrderType = urlSearchParams.get("type");
  const [orderType, setOrderType] = useState(
    ORDER_OPTIONS.find((orderOption) => orderOption.value === urlOrderType) ||
      ORDER_OPTIONS[0]
  );

  const [orders, setOrders] = useState(overdueOrders[orderType.value] || null);

  const history = useHistory();
  const dispatch = useDispatch();

  const dispatchFetchOverdueOrders = useCallback(
    ({ pageIndex, pageSize }) => {
      if (orderType.value === ORDER_TYPE.Cancelled) {
        dispatch(
          fetchCancelledOrdersList({
            mid,
            pageIndex,
            pageSize,
            timezone: selectedTimezone,
          })
        );
      } else {
        dispatch(
          fetchOverdueOrdersList({
            user: userInfo,
            mid,
            pageIndex,
            pageSize,
            timezone: selectedTimezone,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mid, userInfo, orderType.value, selectedTimezone]
  );

  useEffect(() => {
    setOrders(overdueOrders[orderType.value]);
  }, [orderType.value, overdueOrders]);

  const data = useMemo(
    () =>
      orders && orders.rows
        ? orders.rows
            .map((order) => ({
              id: order.orderId,
              linkUrl: order.linkUrl,
              orderValue: formatCurrency(
                order.orderValue,
                currencyRates,
                getCurrencyByCountryCode[order.store.countryCode],
                currentCurrency
              ),
              itemCount: order.items,
              destination: `${order.shippingStateOrProvince || ""} ${
                order.shippingCountry || ""
              }${
                order.shippingPostalCode ? `, ${order.shippingPostalCode}` : ""
              }`,
              daysOverdue: moment().diff(
                moment(order.commitTime).startOf("day"),
                "days"
              ),
              items: order.orderDetails.map((orderItem) => ({
                sku: orderItem.sku,
                item: orderItem.title,
                qty: orderItem.quantity,
                price: formatCurrency(
                  orderItem.price,
                  currencyRates,
                  getCurrencyByCountryCode[order.store.countryCode],
                  currentCurrency
                ),
              })),
            }))
            .sort((a, b) => (a.daysOverdue > b.daysOverdue ? -1 : 1))
        : [],
    [orders, currencyRates, currentCurrency]
  );

  const DaysOverdue = ({ row }) => (
    <Medium variant="body2" color="error">
      {row.original.daysOverdue}
    </Medium>
  );

  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: t("dashboardWidget.dispatchStatus.orderIdColumn"),
        accessor: (row) => ({
          value: row.id,
          link: row.linkUrl,
        }),
        Cell: (props) => <LinkCell {...props} colorVariant="external" />,
      },
      {
        id: "orderValue",
        Header: t("dashboardWidget.dispatchStatus.orderValueColumn"),
        accessor: "orderValue",
        hiddenDown: "sm",
      },
      {
        id: "itemCount",
        Header: t("dashboardWidget.dispatchStatus.itemsColumn"),
        accessor: "itemCount",
        hiddenDown: "sm",
      },
      {
        id: "destination",
        Header: t("dashboardWidget.dispatchStatus.destinationColumn"),
        accessor: "destination",
        hiddenDown: "sm",
      },
      ...(orderType.value !== ORDER_TYPE.Cancelled
        ? [
            {
              id: "daysOverdue",
              Header: t("dashboardWidget.dispatchStatus.daysOverdueColumn"),
              accessor: "daysOverdue",
              Cell: DaysOverdue,
            },
          ]
        : []),
      {
        id: "items",
        Header: t("dashboardWidget.dispatchStatus.detailsColumn"),
        accessor: "items",
        customWidth: 50,
        align: "center",
        Cell: ({ row }) => (
          <AlignEnd {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandLessOutlined /> : <ExpandMoreRounded />}
          </AlignEnd>
        ),
      },
    ],
    [orderType.value]
  );

  const switchType = (value) => {
    const option = ORDER_OPTIONS.find(
      (orderOption) => orderOption.value === value
    );
    setOrderType(option);
    history.replace(
      marketplaceLink(market, mid, "overdueOrders", { type: value })
    );
  };

  const renderRowSubComponent = ({ row: { values } }) =>
    values.items &&
    values.items.map((item) => (
      <SubRow key={item.title}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-around"
        >
          <EqualWidthItems item direction="column" width="20%">
            <Medium color="textSecondary" variant="body2">
              SKU
            </Medium>
            <Typography color="textSecondary" variant="body2">
              {item.sku}
            </Typography>
          </EqualWidthItems>
          <EqualWidthItems item direction="column" width="50%">
            <Medium color="textSecondary" variant="body2">
              ITEM
            </Medium>
            <Typography color="textSecondary" variant="body2">
              {item.item}
            </Typography>
          </EqualWidthItems>
          <EqualWidthItems item direction="column" width="15%">
            <Medium color="textSecondary" variant="body2">
              QTY
            </Medium>
            <Typography color="textSecondary" variant="body2">
              {item.qty}
            </Typography>
          </EqualWidthItems>
          <EqualWidthItems item direction="column" width="15%">
            <Medium color="textSecondary" variant="body2">
              PRICE
            </Medium>
            <Typography color="textSecondary" variant="body2">
              {item.price}
            </Typography>
          </EqualWidthItems>
        </Grid>
      </SubRow>
    ));

  return (
    <Panel
      id="widget-overdue-orders"
      title={t(`myStoresWidget.${orderType.value}.mainTitle`)}
      tooltip={undefined}
      actions={
        <TableFilter
          options={ORDER_OPTIONS}
          currentValue={orderType.label}
          handleChange={switchType}
        />
      }
      content={
        <Table
          columns={columns}
          data={data}
          fetchData={dispatchFetchOverdueOrders}
          pageSize={PAGE_SIZE}
          pagination
          pageCount={orders ? Math.ceil(orders.count / PAGE_SIZE) : 0}
          renderRowSubComponent={renderRowSubComponent}
          loading={overdueOrders.fetching}
        />
      }
    />
  );
});

OverdueOrdersTable.propTypes = {
  mid: PropTypes.string.isRequired,
  market: PropTypes.string,
};

export default OverdueOrdersTable;
