import Bold from "../typography/bold";
import React from "react";
import styled from "styled-components";

const DayGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
`;

export const DayHeader = ({ isoWeekday }: { isoWeekday: string }) => {
  return (
    <DayGrid>
      <Bold variant="body2" color="textSecondary">
        {isoWeekday}
      </Bold>
    </DayGrid>
  );
};
