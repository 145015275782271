import { Box, Grid } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ItemLegend from "~/modules/widgets/itemSpecifics/itemLegend";
import ItemTable from "~/modules/widgets/itemSpecifics/itemTable";
import NoData from "~/components/loadingIndicator/noData";
import Panel from "~/components/panel/panel.tsx";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import PropTypes from "prop-types";
import StatusIndicator from "../../../components/statusIndicator/statusIndicator";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { fetchCategoryScores } from "~/store/itemSpecifics.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ItemSpecifics = memo(({ market, mid, countryCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => _.get(state, "itemSpecifics.loading"));
  const scores = useSelector((state) =>
    _.get(state, "itemSpecifics.categoryScores", [])
  ).map((score) => {
    const categoryNames = score.categoryName.split(">");
    return {
      ...score,
      category: {
        parent: (categoryNames[0] || "").trim(),
        current: (categoryNames[1] || "").trim(),
      },
    };
  });

  const footerLink = {
    url: marketplaceLink(market, mid, "itemspecifics"),
    label: t("generic.viewAllLink"),
  };

  const totalCorrectFields = scores.reduce(
    (total, categoryScore) => total + categoryScore.correct,
    0
  );
  const totalFields = scores.reduce(
    (total, categoryScore) =>
      total +
      categoryScore.correct +
      categoryScore.invalid +
      categoryScore.missing,
    0
  );
  const totalItems = scores.reduce(
    (totalItemCount, categoryScore) => totalItemCount + categoryScore.itemCount,
    0
  );

  const summaryPercent = parseInt(
    Math.round((totalCorrectFields / totalFields) * 100)
  );
  const updateTime = scores
    ? _.get(
        cloneDeep(scores).sort((a, b) => a.savedAt - b.savedAt)[0],
        "savedAt"
      )
    : undefined;

  const selectedTimezone = useSelector((state) =>
    _.get(state, "currentRange.timezone", moment.tz.guess())
  );

  const time = updateTime
    ? moment.tz(updateTime, selectedTimezone).calendar()
    : "Unknown";

  const summaryStatus =
    summaryPercent > 80
      ? "success"
      : summaryPercent > 50
      ? "warning"
      : summaryPercent > 0
      ? "error"
      : "disabled";

  useEffect(() => {
    dispatch(fetchCategoryScores(mid, countryCode));
  }, [dispatch, mid, countryCode]);

  return (
    <Panel
      id="widget-item-specifics"
      title={t("myStoresWidget.itemSpecifics.mainTitle")}
      footerLink={footerLink}
      content={
        loading ? (
          <PanelLoading />
        ) : _.isEmpty(scores) ? (
          <NoData />
        ) : (
          <ContentWrapper>
            <Grid container>
              <Grid container item xs={12} sm={6} md={12} lg={6}>
                <Box p={2}>
                  <StatusIndicator
                    status={summaryStatus}
                    statusScore={
                      isFinite(summaryPercent) ? `${summaryPercent}%` : ""
                    }
                    title={t(
                      "myStoresWidget.listingQuality.statusIndicator.mainTitle"
                    )}
                    subtitle={t(
                      "myStoresWidget.listingQuality.statusIndicator.subTitle",
                      { time }
                    )}
                    footnote={t(
                      "myStoresWidget.listingQuality.statusIndicator.footnote",
                      { numListings: numberWithCommas(totalItems) }
                    )}
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} sm={6} md={12} lg={6}>
                <ItemLegend />
              </Grid>
              <Grid container item xs={12}>
                <ItemTable market={market} data={scores} />
              </Grid>
            </Grid>
          </ContentWrapper>
        )
      }
    />
  );
});

ItemSpecifics.propTypes = {
  market: PropTypes.string.isRequired,
  mid: PropTypes.string.isRequired,
};

export default ItemSpecifics;
