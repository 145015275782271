import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SalesByCountryRow,
  useSalesByCountryQuery,
} from "~/store/mystore/salesByCountry.redux";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import CountryCell from "~/components/table/cells/countryCell";
import { InlineBlockDiv } from "../salesByProduct";
import NoScrollTable from "~/components/table/table";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import Table from "~/components/adTable/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { isNil } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByCountryTableProps {
  mid: string;
  marketplaceType: string;
  marketplaceCountry: string;
  currentRange: Range;
  currentCurrency: string;
  pageSize: number;
  includeTax: boolean;
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  conditionalFormatting?: boolean;
}

const SalesByCountryTable = memo<SalesByCountryTableProps>(
  function SalesByCountryTable({
    mid,
    marketplaceType,
    marketplaceCountry,
    currentRange,
    currentCurrency,
    pageSize,
    includeTax,
    actions,
    condensed,
    report,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();
    const sourceCurrency =
      getCurrencyByCountryCode[marketplaceCountry] || "AUD";

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "totalSales",
      sortOrder: "desc",
    });
    const { salesByCountry, salesByCountryCount, salesByCountryFetching } =
      useSalesByCountryQuery(
        {
          mid,
          currentRange,
          includeTax,
          ...paginationParams,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            salesByCountry: data?.rows || [],
            salesByCountryCount: data?.count || 0,
            salesByCountryFetching: isFetching,
          }),
        }
      );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "totalSales",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const contentRef = React.useRef<HTMLHeadingElement>(null);

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByCountry.country"),
          accessor: (row: SalesByCountryRow) => ({
            value: row.current.country,
          }),
          id: "country",
          Cell: (props: any) => <CountryCell {...props} />,
          colSpan: 2,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.salesByCategory.priorPeriodColumn"),
          accessor: (row: SalesByCountryRow) => {
            return !isNil(row.prior.totalSales)
              ? formatCurrencyRounded(
                  row.prior.totalSales,
                  currencyRates,
                  sourceCurrency,
                  currentCurrency
                )
              : "-";
          },
          id: "comparisonPeriod",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.salesByCategory.thisPeriodColumn"),
          accessor: (row: SalesByCountryRow) => {
            return {
              value: !isNil(row.current.totalSales)
                ? formatCurrencyRounded(
                    row.current.totalSales,
                    currencyRates,
                    sourceCurrency,
                    currentCurrency
                  )
                : "-",
              growth:
                !isNil(row.prior.totalSales) && !isNil(row.current.totalSales)
                  ? getPercentageDifference(
                      row.current.totalSales,
                      row.prior.totalSales
                    )
                  : "N/A",
              conditionalFormatting,
            };
          },
          id: "totalSales",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.salesByCategory.unitsColumn"),
          accessor: (row: SalesByCountryRow) => {
            return {
              value: !isNil(row.current.quantity)
                ? numberWithCommas(row.current.quantity)
                : "-",
              growth:
                !isNil(row.prior.quantity) && !isNil(row.current.quantity)
                  ? getPercentageDifference(
                      row.current.quantity,
                      row.prior.quantity
                    )
                  : "N/A",
              conditionalFormatting,
            };
          },
          id: "quantity",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("myStoresWidget.salesByCategory.avgUnitColumn"),
                accessor: (row: SalesByCountryRow) => {
                  const currentAvgUnitPrice =
                    !isNil(row.current.totalSales) &&
                    !isNil(row.current.quantity) &&
                    row.current.quantity !== 0
                      ? row.current.totalSales / row.current.quantity
                      : null;
                  const priorAvgUnitPrice =
                    !isNil(row.prior.totalSales) &&
                    !isNil(row.prior.quantity) &&
                    row.prior.quantity !== 0
                      ? row.prior.totalSales / row.prior.quantity
                      : null;

                  return {
                    value: !isNil(currentAvgUnitPrice)
                      ? formatCurrency(
                          currentAvgUnitPrice,
                          currencyRates,
                          sourceCurrency,
                          currentCurrency
                        )
                      : "-",
                    growth:
                      !isNil(currentAvgUnitPrice) && !isNil(priorAvgUnitPrice)
                        ? getPercentageDifference(
                            currentAvgUnitPrice,
                            priorAvgUnitPrice
                          )
                        : "N/A",
                    conditionalFormatting,
                  };
                },
                id: "avgUnitPrice",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("myStoresWidget.salesByCategory.avgOrderColumn"),
                accessor: (row: SalesByCountryRow) => {
                  const currentAvgOrderSize =
                    !isNil(row.current.quantity) &&
                    !isNil(row.current.orderCount) &&
                    row.current.orderCount !== 0
                      ? row.current.quantity / row.current.orderCount
                      : null;
                  const priorAvgOrderSize =
                    !isNil(row.prior.quantity) &&
                    !isNil(row.prior.orderCount) &&
                    row.prior.orderCount !== 0
                      ? row.prior.quantity / row.prior.orderCount
                      : null;

                  return {
                    value: !isNil(currentAvgOrderSize)
                      ? numberWithCommas(currentAvgOrderSize.toFixed(2))
                      : "-",
                    growth:
                      !isNil(priorAvgOrderSize) && !isNil(currentAvgOrderSize)
                        ? getPercentageDifference(
                            currentAvgOrderSize,
                            priorAvgOrderSize
                          )
                        : "N/A",
                    conditionalFormatting,
                  };
                },
                id: "avgOrderSize",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]),
      ],
      [mid, currentRange, currentCurrency, currencyRates, sourceCurrency]
    );

    const [myColumns, setMyColumns] = useState<Column[]>(columns);

    useEffect(() => {
      setMyColumns(
        columns.map((column, idx) => {
          if (
            myColumns.find((myColumn) => myColumn.id === column.id)
              ?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            } as any;
          } else {
            return column;
          }
        })
      );
    }, [columns]);

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink(marketplaceType, mid, "salesbycountry"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="sales-by-country-table"
        title={`${t("salesbyCountryTable.mainTitle")}`}
        footerLink={footerLink}
        content={
          condensed ? (
            <>
              {/* Empty div to get the rendered width of the table by ref */}
              <InlineBlockDiv ref={contentRef} />
              <NoScrollTable
                {...{
                  columns,
                  data: salesByCountry,
                  pageSize,
                  loading: salesByCountryFetching,
                  isReport: report,
                }}
              />
            </>
          ) : (
            <>
              {/* Empty div to get the rendered width of the table by ref */}
              <InlineBlockDiv ref={contentRef} />
              <Table
                {...{
                  columns: myColumns,
                  data: salesByCountry,
                  fetchData,
                  loading: salesByCountryFetching,
                  pagination: true,
                  pageCount: Math.ceil((salesByCountryCount || 0) / pageSize),
                  pageSize,
                }}
              />
            </>
          )
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: myColumns, setColumns: setMyColumns }}
              />
            )}
          </>
        }
      />
    );
  }
);

export default SalesByCountryTable;
