import { Box, Grid, Typography } from "@material-ui/core";
import { PlanPropTypes, formatBillingPeriod } from "./plan/plan";
import {
  SubscriptionPanelContent,
  SummaryItem,
} from "./subscriptionStyledComponents";
import {
  handleSubscriptionError,
  handleTokenisationError,
} from "./validationUtils";
import { useDispatch, useSelector } from "react-redux";

import BraintreeBadge from "./assets/braintreeBadge";
import BraintreeCheckoutForm from "./braintreeCheckoutForm";
import Panel from "~/components/panel/panel.tsx";
import PaymentSelect from "~/components/select/paymentSelect";
import PaypalCheckoutForm from "./paypalCheckoutForm";
import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import SecurePaymentBadge from "../../img/creditCard/secure-payment.png";
import get from "lodash/get";
import { getDiscount } from "./promoCodeField";
import getSymbolFromCurrency from "currency-symbol-map";
import { handleSubmit } from "./formSubmission";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SecurePaymentImage = styled.img`
  height: 21px;
  display: flex;
  align-self: center;
`;

const FooterContainer = styled(Box).attrs(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
}))`
  width: 100%;
`;

const PaymentDetails = ({ newUser, plan, discount, promoCode }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [tokenize, setTokenizeFunc] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [promoCodeValue, setPromoCode] = React.useState(
    promoCode || get(user, "marketingInfo.partnerCode") || ""
  );
  const [paymentMethodType, setPaymentMethodType] = React.useState(
    "CreditCard"
  );

  const formState = {
    userId: user._id,
    planId: plan.planId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };

  const reduxError = useSelector((state) => get(state, "subscriptions.error"));

  const getPromoCodeDescription = async (promoCodeVal) => {
    const promoCodeDiscount = await getDiscount(promoCodeVal, plan.planId);
    return get(promoCodeDiscount, "shortName", "");
  };

  React.useEffect(() => {
    setError(reduxError);
    if (reduxError) {
      setSubmitting(false);
    }
  }, [reduxError]);

  const handleError = (err) => {
    setSubmitting(false);
    if (err instanceof Response) {
      handleSubscriptionError(err, setError);
    } else {
      handleTokenisationError(err, setError);
    }
  };

  const submitToken = async (token) => {
    setError(null);
    setSubmitting(true);
    const promoCodeDescription = await getPromoCodeDescription(promoCodeValue);
    await handleSubmit(
      formState,
      true,
      user,
      history,
      dispatch
    )(token, promoCodeValue, promoCodeDescription);
  };

  const getToken = () => {
    tokenize().then(submitToken).catch(handleError);
  };

  const panelContent = (
    <SubscriptionPanelContent container>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("settings.billing.paymentMethodLabel")}
        </Typography>
        <Box pt={2} pb={4}>
          <PaymentSelect
            currentValue={paymentMethodType}
            handleChange={setPaymentMethodType}
          />
        </Box>
        {paymentMethodType === "CreditCard" && (
          <BraintreeCheckoutForm
            displaySummary={true}
            summaryProps={{ plan }}
            newUser={newUser}
            error={error}
            setError={setError}
            promoCode={promoCodeValue}
            setPromoCode={setPromoCode}
            handleError={handleError}
            setTokenizeFunc={setTokenizeFunc}
            discount={discount}
            discountAmount={discountAmount}
            setDiscountAmount={setDiscountAmount}
          />
        )}
        {paymentMethodType === "PayPalAccount" && (
          <PaypalCheckoutForm
            displaySummary={true}
            summaryProps={{ plan }}
            newUser={newUser}
            error={error}
            setError={setError}
            promoCode={promoCodeValue}
            setPromoCode={setPromoCode}
            handleError={handleError}
            submitToken={submitToken}
            discount={discount}
            discountAmount={discountAmount}
            setDiscountAmount={setDiscountAmount}
          />
        )}
      </Grid>
    </SubscriptionPanelContent>
  );

  const finalAmount = plan.cost - (discountAmount || 0);
  const paymentStringWithDiscount = `${getSymbolFromCurrency(
    plan.currency
  )}${finalAmount} ${plan.currency} /${t(formatBillingPeriod(plan))}`;
  const paymentString =
    newUser || (discount && discount.numTrialDays)
      ? `${getSymbolFromCurrency(plan.currency)}0 /${t(
          formatBillingPeriod(plan)
        )}`
      : paymentStringWithDiscount;

  const panelFooter = (
    <FooterContainer pl={1} pr={1}>
      <Grid container>
        <SummaryItem
          leftText={t("subscription.create.todayPaymentLabel")}
          rightText={paymentString}
          rightSubtitle={t("subscription.create.incSalesTaxLabel")}
        />
      </Grid>
      <Box pt={2}>
        {paymentMethodType === "CreditCard" && (
          <RaisedButton
            type="button"
            name="registerBtn"
            color="primary"
            fullWidth
            onClick={getToken}
            disabled={submitting}
          >
            {submitting
              ? t("generic.processingMessage")
              : newUser
              ? t("subscription.create.startTrialButton")
              : t("subscription.create.activatePlanButton")}
          </RaisedButton>
        )}
        {paymentMethodType === "PayPalAccount" && (
          <div id="paypal-button"></div>
        )}
      </Box>
      <Box pt={2} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">
          {t("subscription.create.securePaymentLabel")}
        </Typography>
        <BraintreeBadge />
      </Box>
    </FooterContainer>
  );

  return (
    <Grid item xs={12} md={6}>
      <Panel
        id="widget-payment-details"
        title={t("subscription.create.paymentDetailsTitle")}
        actions={<SecurePaymentImage src={SecurePaymentBadge} />}
        content={panelContent}
        footer={panelFooter}
      />
    </Grid>
  );
};

PaymentDetails.propTypes = {
  plan: PlanPropTypes,
  newUser: PropTypes.bool.isRequired,
  discount: PropTypes.shape({
    amount: PropTypes.number,
    numTrialDays: PropTypes.number,
  }),
  promoCode: PropTypes.string,
};
export default PaymentDetails;
