import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import StatusText from "~/components/typography/status";

interface DaysCoverCellProps {
  cell: {
    value: {
      displayValue: number;
      conditionalFormatting?: boolean;
      compareValue?: number;
      lowThreshold?: number;
      highThreshold?: number;
      midThreshold?: number;
    };
  };
}

/**
 * Table cell to show traffic lighted values based on supplied thresholds
 * Higher values are "good", lower values are "bad".
 */
export const DaysCoverCell = memo(({ cell }: DaysCoverCellProps) => {
  const displayValue = cell.value.displayValue;

  const getStatus = () => {
    if (cell.value.conditionalFormatting === false) {
      return "";
    }

    if (Number(cell.value.compareValue) < Number(cell.value.lowThreshold)) {
      return "error";
    }

    if (
      Number(cell.value.compareValue) < Number(cell.value.midThreshold) &&
      Number(cell.value.compareValue) > Number(cell.value.lowThreshold)
    ) {
      return "warning";
    }

    if (
      Number(cell.value.compareValue) < Number(cell.value.highThreshold) &&
      Number(cell.value.compareValue) > Number(cell.value.midThreshold)
    ) {
      return "borderline";
    }

    if (Number(cell.value.compareValue) > Number(cell.value.highThreshold)) {
      return "success";
    }

    return "";
  };

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <StatusText variant="body2" status={getStatus()}>
          {displayValue}
        </StatusText>
      </Grid>
    </Grid>
  );
});
