import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import ProductCogsSyncDialog, {
  SingleProductCogs,
} from "@modules/profitLossProductTable/productCogsSyncDialog";
import React, { memo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import {
  uploadDirectCosts,
  useUpdateDirectCostsByProductMutation,
} from "~/store/mystore/profitability.redux";

import Alert from "~/components/alert/alertCard";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MessageAlert from "~/components/alert/messageAlert";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { ProductEventRow } from "~/modules/profitLossProductTable/productTable";
import ProfitabilityChart from "~/modules/profitability/profitabilityChart";
import ProfitabilityProductWidget from "~/modules/widgets/profitability/profitabilityProduct";
import ProfitabilitySplit from "~/modules/widgets/profitability/profitabilitySplit";
import SyncDialog from "~/modules/profitLossProductTable/syncDialog";
import TemplateDialog from "~/modules/profitLossProductTable/templateDialog";
import get from "lodash/get";
import moment from "moment-timezone";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityProduct = memo(function ProfitabilityProduct() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings.setting.data.includeTax)
  );
  const user = useTypedSelector((state) => get(state, "user"));

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const exchangeRate = currentStore
    ? getExchangeRate(
        currencyRates,
        getCurrencyByCountryCode[currentStore.marketplaceCountry],
        currentCurrency
      )
    : 1;
  const storeCurrency =
    (currentStore
      ? getCurrencyByCountryCode[currentStore.marketplaceCountry]
      : null) ?? currentCurrency;

  const { isAdvertisingAuthorised } = useAdvertisingConnected(currentStore);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);
  const [productCogsDialogOpen, setProductCogsDialogOpen] = useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [missingCogs, setMissingCogs] = useState(false);
  const [product, setProduct] = useState<ProductEventRow>();

  const [updateProductDirectCosts] = useUpdateDirectCostsByProductMutation();

  const handleCogsEdit = (product: ProductEventRow) => {
    setProduct(product);
    setProductCogsDialogOpen(true);
  };

  const handleCogsConfirm = (fromDate: string, file: File) => {
    if (currentStore && fromDate && file) {
      dispatch(
        uploadDirectCosts(
          currentStore.merchantId,
          currentStore.marketplace,
          currentStore.marketplaceSubtype || currentStore.marketplace,
          moment(fromDate).unix(),
          file,
          t
        )
      );
      setSyncDialogOpen(false);
    }
  };

  const handleProductCogsConfirm = (
    {
      mid,
      marketplaceType,
      marketplaceSubtype,
    }: { mid: string; marketplaceType: string; marketplaceSubtype: string },
    fromDate: string | undefined,
    cogs: SingleProductCogs
  ) => {
    if (currentStore && fromDate && cogs) {
      updateProductDirectCosts({
        mid,
        marketplaceType,
        marketplaceSubtype,
        fromDate: moment(fromDate).unix(),
        directCosts: {
          sellerSku: cogs.sku,
          currency: cogs.currency,
          value: cogs.value,
        },
        successMessage: t("profitability.productUploadSuccessMessage", {
          sku: cogs.sku,
        }),
      });
      setProductCogsDialogOpen(false);
    }
  };

  return currentStore ? (
    <>
      <SyncDialog
        open={syncDialogOpen}
        onClose={() => {
          setSyncDialogOpen(false);
        }}
        onConfirm={handleCogsConfirm}
      />
      <ProductCogsSyncDialog
        store={currentStore}
        storeCurrency={storeCurrency}
        timezone={currentRange.timezone}
        open={productCogsDialogOpen}
        product={product}
        onClose={() => {
          setProductCogsDialogOpen(false);
        }}
        onConfirm={handleProductCogsConfirm}
      />
      <TemplateDialog
        mid={currentStore.merchantId}
        open={templateDialogOpen}
        onClose={() => {
          setTemplateDialogOpen(false);
        }}
      />
      <PageBlock>
        <Grid container spacing={2}>
          {get(currentStore, "marketplace") === "amazon" &&
            !isAdvertisingAuthorised && (
              <Grid container item xs={12}>
                <Alert isOpen={true} type="info">
                  {t("profitability.linkAdvertisingMessage")}
                </Alert>
              </Grid>
            )}
          {missingCogs && (
            <Grid container item xs={12}>
              <MessageAlert message="generic.missingCogsMessage" />
            </Grid>
          )}

          <Grid container item xs={12} md={8} alignItems="center">
            <ProfitabilityChart
              market={currentStore.marketplace}
              mid={currentStore.merchantId}
            />
          </Grid>
          <Grid container item xs={12} md={4} alignItems="center">
            <ProfitabilitySplit singleStore={true} />
          </Grid>

          <Grid item xs={12}>
            <ProfitabilityProductWidget
              currentCurrency={currentCurrency}
              currentRange={currentRange}
              includeTax={includeTax}
              exchangeRate={exchangeRate}
              store={currentStore}
              user={user}
              report={false}
              setSyncDialogOpen={setSyncDialogOpen}
              handleCogsEdit={handleCogsEdit}
              missingCogs={missingCogs}
              setMissingCogs={setMissingCogs}
            />
          </Grid>
        </Grid>
      </PageBlock>{" "}
    </>
  ) : (
    <PageBlock>
      <LoadingIndicator />
    </PageBlock>
  );
});

export default ProfitabilityProduct;
