import { useDispatch, useSelector } from "react-redux";

import AlertCard from "./alertCard";
import { Box } from "@material-ui/core";
import CombinedLink from "../links/link.tsx";
import Medium from "../typography/medium";
import { PriorityHigh } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { setDemoBannerVisible } from "../../store/globalVar.redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core/styles";

const AlertIcon = withTheme(styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.light};
  margin-right: 1rem;
`);

const DemoStoreAlert = ({ linkToDemo }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const demoBannerVisible = useSelector(
    (state) => state.globalVar.demoBannerVisible
  );

  const onClick = () => {
    const location = marketplaceLink(
      "amazon",
      "HomeOzComfort (Demo) @ Amazon.com.au"
    );
    history.push(location);
  };

  const handleClose = () => {
    dispatch(setDemoBannerVisible(false));
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="stretch"
      flexDirection="column"
      mb={demoBannerVisible ? 2 : 0}
    >
      <AlertCard
        isOpen={demoBannerVisible}
        onClose={handleClose}
        type="warning"
      >
        <Box display="flex" alignItems="center" width="100%">
          <AlertIcon>
            <PriorityHigh fontSize="small" />
          </AlertIcon>
          {linkToDemo ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Medium variant="body1">{t("demo.syncMessageWithLink")}</Medium>
              <CombinedLink
                color="inherit"
                variant="body1"
                underline="always"
                onClick={onClick}
              >
                {t("demo.viewDemoStoreLink")}
              </CombinedLink>
            </Box>
          ) : (
            <Medium variant="body1">{t("demo.syncMessage")}</Medium>
          )}
        </Box>
      </AlertCard>
    </Box>
  );
};

DemoStoreAlert.propTypes = {
  linkToDemo: PropTypes.bool,
};

export default DemoStoreAlert;
