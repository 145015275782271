import * as Sentry from "@sentry/browser";

import { Avatar, Box, Typography, withTheme } from "@material-ui/core";
import { GTranslateRounded, WarningRounded } from "@material-ui/icons";
import React, { memo, useState } from "react";

import Bold from "../components/typography/bold";
import CombinedLink from "../components/links/link.tsx";
import { ErrorBoundaryBackground } from "../img/backgrounds/errorBoundaryBackground";
import PropTypes from "prop-types";
import RaisedButton from "../components/buttons/raisedButton.tsx";
import { get } from "lodash";
import styled from "styled-components";
import useQueryParams from "../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const Background = styled(Box)`
  background: ${({ theme }) =>
    theme.palette.backgroundColor
      ? theme.palette.backgroundColor
      : `url(${ErrorBoundaryBackground})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
`;

const IconWrapper = withTheme(styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.error.dark};
  height: 35px;
  padding-bottom: 5px;
  margin-right: 1rem;
`);

const TextWrapper = styled(Box)`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 20%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const ERROR_TYPES = {
  DEFAULT: "default",
  TRANSLATE: "gt",
};

const errorMessagesByType = {
  default: {
    title: "errorPage.error.title",
    subtitle: "errorPage.error.subtitle",
    message: "errorPage.error.message",
  },
  gt: {
    title: "errorPage.gtError.title",
    subtitle: "errorPage.gtError.subtitle",
    message: "errorPage.gtError.message",
  },
  maxAge: {
    title: "errorPage.maxAgeError.title",
    subtitle: "errorPage.maxAgeError.subtitle",
    message: "errorPage.maxAgeError.message",
  },
};

const ErrorPage = memo(({ location }) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const errorType = queryParams.get("errorType");
  const [type, setType] = useState(
    errorMessagesByType[errorType] ? errorType : ERROR_TYPES.DEFAULT
  );
  const error = get(location, "state.error");
  if (error && error.name && error.message) {
    const { name, message } = error;
    if (
      type === ERROR_TYPES.DEFAULT &&
      (name === "NotFoundError" || message.includes("'Node'"))
    ) {
      setType(ERROR_TYPES.TRANSLATE);
    }
  }
  return (
    <Background
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
    >
      <TextWrapper p={4} pb={10} maxWidth={500}>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml="-45px"
        >
          <IconWrapper>
            <WarningRounded color="primary" />
          </IconWrapper>
          <Bold variant="h1">{t(errorMessagesByType[type].title)}</Bold>
        </Box>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {type === ERROR_TYPES.TRANSLATE && (
            <GTranslateRounded fontSize="large" />
          )}
          <Typography variant="body1" align="center">
            {t(errorMessagesByType[type].subtitle)}
          </Typography>
        </Box>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" align="center">
            {t(errorMessagesByType[type].message)}
          </Typography>
        </Box>

        {type !== "maxAge" && (
          <Box
            p={2}
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box p={1}>
              <RaisedButton component={CombinedLink} to="/" color="primary">
                BACK TO LOGIN
              </RaisedButton>
            </Box>
            <Box p={1}>
              <RaisedButton
                color="info"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: location.state.eventId })
                }
              >
                PROVIDE DETAILS
              </RaisedButton>
            </Box>
          </Box>
        )}
      </TextWrapper>
    </Background>
  );
});

ErrorPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ErrorPage;
