import cookies from "browser-cookies";
import { useTypedSelector } from "./useTypedSelector";

const nodeEnv =
  process.env.TARGET_ENV === "production"
    ? "prod"
    : process.env.TARGET_ENV === "staging"
    ? "staging"
    : "dev";

export const useCustomLogo = (dark?: boolean) => {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  const isOmnivoreUser = () => cookies.get("authSource") === "omnivore";
  const originalDomain = cookies.get("originalDomain");
  const agency = isOmnivoreUser()
    ? "omnivore"
    : customOverrides?.agency || originalDomain?.split(".")[0] || "ms";
  if (dark) {
    return `https://logos-dark-${nodeEnv}.merchantspring.io/${agency}_logo_dark.png`;
  } else {
    return `https://logos-light-${nodeEnv}.merchantspring.io/${agency}_logo_light.png`;
  }
};

export function useCustomFavicon() {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  const newNotificationsCount: number | undefined = useTypedSelector(
    (state) => state.notifications?.unresolvedCount
  );

  const isOmnivoreUser = () => cookies.get("authSource") === "omnivore";
  const originalDomain = cookies.get("originalDomain");
  const agency = isOmnivoreUser()
    ? "omnivore"
    : customOverrides?.agency || originalDomain?.split(".")[0] || "ms";

  if (agency === "ms" && newNotificationsCount) {
    return `https://favicons-${nodeEnv}.merchantspring.io/ms_favicon_notifications.png`;
  }

  return `https://favicons-${nodeEnv}.merchantspring.io/${agency}_favicon.png`;
}
