import * as Sentry from "@sentry/browser";

import { FORBIDDEN } from "http-status-codes";
import { PAYWALL_URL } from "~/modules/subscription/paywall";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import { logout } from "~/store/user.redux";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      needSubscription: false,
      errorInfo: null,
      eventId: null,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    if (get(error, "response.status") === FORBIDDEN) {
      return { needSubscription: true };
    }
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      /** Specify user information */
      scope.setUser({
        id: get(this.props.user, "_id"),
        username:
          get(this.props.user, "firstName") + get(this.props.user, "lastName"),
        email: get(this.props.user, "email"),
        ip_address: "{{auto}}",
      });
      /** Specify extra tags used for Slack notification */
      scope.setTag("user.email", get(this.props.user, "email"));
      scope.setTag("path", get(this.props.location, "pathname"));
      /** Specify redux data to attach to the error */
      scope.setContext("Location", this.props.location);
      scope.setContext("Error Information", errorInfo);
      scope.setContext("User (Redux)", {
        id: get(this.props.user, "_id"),
        firstName: get(this.props.user, "firstName"),
        lastName: get(this.props.user, "lastName"),
        email: get(this.props.user, "email"),
        org: get(this.props.user, "org"),
      });
      const { ...globalVar } = this.props.globalVar;
      scope.setContext("Global Variables (Redux)", globalVar);
      scope.setContext("Custom Date Range (Redux)", this.props.currentRange);

      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    if (this.state.needSubscription) {
      return <Redirect to={`${PAYWALL_URL}?needSubscription=true`} />;
    }
    if (this.state.hasError) {
      if (
        this.props.user &&
        this.props.user._id &&
        process.env.TARGET_ENV !== "development"
      ) {
        this.props.logout();
      }
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              eventId: this.state.eventId,
              error: this.state.error,
            },
          }}
        />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    org: PropTypes.string,
  }),
  globalVar: PropTypes.object,
  location: PropTypes.object,
  currentRange: PropTypes.object,
  children: PropTypes.any, // added this to silence a TS error in index.tsx
};

const mapStateToProps = (state) => ({
  user: state.user,
  globalVar: state.globalVar,
  currentRange: state.currentRange,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
