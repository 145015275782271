import React, { memo } from "react";

import OverdueOrderSummaryContents from "./overdueOrderSummaryContents";
import { Panel } from "~/components/panel/panel";
import { fetchOverdueOrderSummary } from "~/store/overview/overdueOrders.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const OverdueOrderSummary = memo(function OverdueOrderSummary() {
  const { t } = useTranslation();
  const overdueOrderSummary = useTypedSelector(
    (state) => state.overview.overdueOrders
  );

  return (
    <Panel
      id="widget-overdue-orders"
      title={t("dashboardWidget.overdueOrders.mainTitle")}
      tooltip={t("dashboardWidget.overdueOrders.mainTooltip")}
      content={
        <OverdueOrderSummaryContents
          overdueOrderSummary={overdueOrderSummary}
          fetchOverdueOrderSummary={fetchOverdueOrderSummary}
        />
      }
    />
  );
});

export default OverdueOrderSummary;
