import styled, { DefaultTheme, ThemeProps } from "styled-components";

import BackgroundImage from "../img/backgrounds/home-background.jpg";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import React from "react";
import { useTypedSelector } from "~/hooks/useTypedSelector";

type BackgroundImageContainerProps = ThemeProps<DefaultTheme> & {
  $backgroundImage: string;
  $useImage: boolean;
};

const BackgroundImageContainer = styled.div`
  ${({ theme }) =>
    theme?.palette?.backgroundColor &&
    `background-color: ${theme.palette.backgroundColor};`};
  ${({ $backgroundImage, $useImage }: BackgroundImageContainerProps) =>
    $useImage &&
    $backgroundImage &&
    `background-image: url(${$backgroundImage});`}
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LoadingPage = () => {
  const customOverrides = useTypedSelector((state) => state.customOverrides);
  return (
    <BackgroundImageContainer
      $backgroundImage={BackgroundImage}
      $useImage={customOverrides?.agency === "ms"}
    >
      <LoadingIndicator id="subscription-loading" />
    </BackgroundImageContainer>
  );
};

export default LoadingPage;
