import { Grid, Link } from "@material-ui/core";

import Medium from "~/components/typography/medium";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";

const FlexItem = withTheme(styled(Grid)`
  padding: 2px 10px;
  margin: 0 10px;
  border-bottom: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : "transparent"};
  text-transform: uppercase;
`);

const MarketingSubNavItem = ({
  title,
  marketplace,
  mid,
  page,
  state,
  selected,
}) => (
  <FlexItem selected={selected} item>
    <Medium variant="body1" color="textPrimary">
      {selected ? (
        <>{title}</>
      ) : (
        <Link
          to={{
            pathname: `/mystores/${marketplace}/${page}`,
            search: `?store=${encodeURI(mid)}`,
            state,
          }}
          component={RouterLink}
          underline={"none"}
          color="textPrimary"
        >
          {title}
        </Link>
      )}
    </Medium>
  </FlexItem>
);

MarketingSubNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  marketplace: PropTypes.string,
  mid: PropTypes.string,
  page: PropTypes.string,
  state: PropTypes.object,
  selected: PropTypes.bool,
};

export default MarketingSubNavItem;
