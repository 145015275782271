import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import AlertCard from "./alertCard";
import CombinedLink from "../links/link";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import styled from "styled-components";

const FloatRight = styled.div`
  display: flex;
  float: right;
  margin-left: auto;
`;

interface BannerWithLinkProps {
  type: keyof PaletteOptions | "plain";
  isOpen: boolean;
  message: string;
  link:
    | string
    | {
        pathname: string;
        search: string;
      }
    | null;
  linkText: string;
  onClose?: () => void;
}

const BannerWithLink = memo<BannerWithLinkProps>(function BannerWithLink({
  type,
  isOpen,
  message,
  link,
  linkText,
  onClose,
}) {
  return (
    <AlertCard {...{ isOpen, type, onClose }}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="body1">{message}</Typography>
      </Box>
      <FloatRight>
        <CombinedLink
          {...{
            to: link,
            color: "inherit",
            variant: "body1",
            underline: "always",
          }}
        >
          {linkText}
        </CombinedLink>
      </FloatRight>
    </AlertCard>
  );
});

export default BannerWithLink;
