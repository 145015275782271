import {
  LEGACY_PLAN_ID,
  getConvertedToPaid,
} from "~/modules/subscription/plan/plan";
import React, { useCallback, useEffect, useState } from "react";
import {
  createIntercomTags,
  createIntercomUserEvents,
  createMarketingContact,
  getMarketingContact,
  getMarketingContactId,
  updateMarketingContact,
} from "~/utils/intercomUtils";
import {
  loadInfo,
  setImpersonationFlag,
  updateRefreshToken,
} from "../../store/user.redux";
import { useHistory, useLocation } from "react-router-dom";

/**
 * component which loads the "user" context (in this case the redux store)
 */
import LoadingPage from "@modules/loadingPage";
import { Store } from "~/typedef/store";
import TagManager from "react-gtm-module";
import cookies from "browser-cookies";
import get from "lodash/get";
import { getFriendlyLongUserName } from "~/store/utils/displayUtils";
import isEmpty from "lodash/isEmpty";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import { loadCurrencyRates } from "@store/globalVar.redux";
import { marketplaceTypesAndCountriesString } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import smartlookClient from "smartlook-client";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CAPTURE_MARKETING_METRICS = process.env.TARGET_ENV !== "development";

type MarketingWindow = Window &
  typeof globalThis & {
    smartlook?: {
      playUrl: string;
    };
    profitwell: (field: string, value: string) => void;
  };

const LoginContext = ({ children }: { children?: any }) => {
  const accessToken = cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);
  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores || []
  );
  const allStoresWithoutDemo = allStores.filter((s: Store) => !s.isDemoMode);
  const advertisingStores = allStores.filter((s: Store) => s.advertisingStatus);
  const subscriptions = useTypedSelector((state) => state.subscriptions);
  const userCheck = user && user._id;
  const subscriptionsCheck =
    subscriptions &&
    !isEmpty(subscriptions.currentPlan) &&
    !isEmpty(subscriptions.currentSubscription);

  const initInterceptor = useCallback(() => {
    if (accessToken) {
      jwtInterceptor(null, location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const initLoadInfo = useCallback(async () => {
    try {
      await dispatch(loadInfo(location?.pathname));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, location?.pathname]);

  const initLoadCurrencyRates = useCallback(async () => {
    await dispatch(loadCurrencyRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeEpochSeconds = (timeMilli: Date) =>
    Math.round(new Date(timeMilli).getTime() / 1000);

  let currentUserDetails = {};
  let userCustomAttrib: Record<string, any> = {};
  let currentUserSubscriptions: Record<string, any> = {};
  let plan: string = "";
  let numMarketplaces: number = 0;
  let ppcConnected = "";

  if (userCheck) {
    const registrationTimeEpochSeconds = timeEpochSeconds(
      user.registrationTime as Date
    );
    numMarketplaces = allStoresWithoutDemo.length;

    ppcConnected = advertisingStores.reduce(
      (acc: string, store: Store) =>
        acc + `${store.marketplaceType}_${store.marketplaceCountry}_`,
      ""
    );

    userCustomAttrib = {
      org: user.org,
      source: user.agency || user.source,
      Marketing_Funnel: get(user, "marketingInfo.marketingFunnel"),
      gclid: get(user, "marketingInfo.gclid"),
      Marketplaces: marketplaceTypesAndCountriesString(allStoresWithoutDemo),
      Marketplaces_connected: numMarketplaces,
      PPC_connected: ppcConnected,
    };

    currentUserDetails = {
      app_id: process.env.INTERCOM_APP_ID,
      user_id: user._id,
      email: user.email,
      phone: user.phone,
      created_at: registrationTimeEpochSeconds,
      ...userCustomAttrib,
      custom_launcher_selector: "#launch-intercom",
      name: getFriendlyLongUserName(user),
    };
  }

  if (subscriptionsCheck) {
    const {
      planId,
      status,
      internalSubscriptionStatus,
      nextBillAmount,
      nextBillingDate,
    } = subscriptions.currentSubscription;

    const billingDate = moment(nextBillingDate).format("D MMMM YYYY");
    const trialExpiryDate =
      planId === LEGACY_PLAN_ID ? billingDate : "N/A - Paid Subscription";
    plan = planId;

    currentUserSubscriptions = {
      Plan: planId,
      Subscription_status: status,
      Converted_to_paid: getConvertedToPaid(internalSubscriptionStatus),
      Next_billing_amount: nextBillAmount,
      Next_billing_date: billingDate,
      Trial_expiry_date: trialExpiryDate,
    };
  }

  const refreshAccessToken = () => {
    const accessTokenExpiry = cookies.get("accessTokenExpiry") || "0";
    const tokenValidityMinutes = moment
      .unix(parseInt(accessTokenExpiry))
      .diff(moment(), "minutes");

    if (userCheck && accessTokenExpiry !== "0" && tokenValidityMinutes <= 59) {
      try {
        dispatch(updateRefreshToken());
      } catch (err) {
        // do nothing - if token cannot be refreshed, allow the session to eventually timeout
      }
    }
  };

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        // tab is now inactive
      } else {
        refreshAccessToken();
      }
    };

    document.addEventListener("visibilitychange", visibilityChangeHandler);

    const timer = setInterval(
      refreshAccessToken,
      moment.duration(1, "minute").asMilliseconds()
    );

    // Remove event listener and clear interval on cleanup
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
      clearInterval(timer);
    };
  }, [refreshAccessToken]);

  useEffect(() => {
    initInterceptor();
  }, [initInterceptor]);

  useEffect(() => {
    initLoadInfo();
  }, [initLoadInfo]);

  useEffect(() => {
    initLoadCurrencyRates();
  }, [initLoadCurrencyRates]);

  useEffect(() => {
    history.listen((locationChange) => {
      TagManager.dataLayer({
        dataLayer: {
          pagePath: `${locationChange.pathname}${locationChange.search}`,
        },
        dataLayerName: "PageDataLayer",
      });
      if ((window as MarketingWindow).Intercom && CAPTURE_MARKETING_METRICS) {
        (window as MarketingWindow).Intercom("update", {
          last_request_at: new Date().getTime() / 1000,
        });
      }
    });

    async function runIntercom() {
      if (CAPTURE_MARKETING_METRICS && userCheck) {
        let contact = await getMarketingContact(user._id);
        let contactId = getMarketingContactId(contact);

        if (!contactId) {
          await createMarketingContact(user);
          contact = await getMarketingContact(user._id);
          contactId = getMarketingContactId(contact);
        }

        if (contactId) {
          await updateMarketingContact(
            user._id,
            contactId,
            {
              ...userCustomAttrib,
              ...currentUserSubscriptions,
            },
            user.email,
            user.phone,
            user.role
          );

          smartlookClient.getData(() => {
            createIntercomUserEvents(
              "Smartlook Session",
              moment().unix(),
              user._id,
              {
                playUrl: (window as MarketingWindow)?.smartlook?.playUrl,
              }
            );
          });

          if (numMarketplaces > 0) {
            createIntercomTags(user._id, "Connected Store");
          }
          if (plan === LEGACY_PLAN_ID) {
            createIntercomTags(user._id, "Started Trial");
          }
        }
      }
    }

    if (userCheck && !user.impersonation && !user.isDemoMode) {
      runIntercom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numMarketplaces, plan, ppcConnected]);

  useEffect(() => {
    const checkImpersonation = () => {
      const authSource = cookies.get("authSource");
      const isImpersonating = authSource === "impersonation";
      if (userCheck) {
        if (user.impersonation !== isImpersonating) {
          try {
            dispatch(setImpersonationFlag(isImpersonating));
          } catch (err) {
            // do nothing
          }
        }
      }
    };

    checkImpersonation();

    const timer = setInterval(() => {
      checkImpersonation();
    }, moment.duration(1, "minute").asMilliseconds());
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCheck, user]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (userCheck && user.impersonation === false) {
    if (
      userCheck &&
      subscriptionsCheck &&
      (window as MarketingWindow).Intercom
    ) {
      (window as MarketingWindow).Intercom("boot", {
        ...currentUserDetails,
        ...currentUserSubscriptions,
      });
    } else if ((window as MarketingWindow).Intercom) {
      (window as MarketingWindow).Intercom("boot", {
        app_id: process.env.INTERCOM_APP_ID,
        custom_launcher_selector: "#launch-intercom",
      });
    }

    // integration for ProfitWell snippet
    if (process.env.TARGET_ENV === "production") {
      if ((window as MarketingWindow).profitwell && user.email) {
        (window as MarketingWindow).profitwell("user_email", user.email);
      }
    }
  }

  return children;
};

export default LoginContext;
