import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const FlexContainer = styled(Grid)`
  display: flex;
  align-content: flex-end;
  justify-content: center;
`;

const MarketingSubNav = ({ children }) => (
  <FlexContainer container item xs={12} sm={7}>
    {children}
  </FlexContainer>
);

MarketingSubNav.propTypes = {
  children: PropTypes.node,
};

export default MarketingSubNav;
