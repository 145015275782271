import { useEffect, useState } from "react";

import { useTypedSelector } from "./useTypedSelector";

export const useAdvertisingOnly = () => {
  const [isAdvertisingOnly, setIsAdvertisingOnly] = useState(false);
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );

  useEffect(() => {
    const nonAdvertisingStores = filteredStores.find(
      (store) => store.marketplaceSubtype !== "advertising"
    );
    // If there are no non-advertising stores, then we are advertising only
    setIsAdvertisingOnly(!nonAdvertisingStores);
  }, [filteredStores]);

  return isAdvertisingOnly;
};
