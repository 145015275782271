import React, { memo } from "react";

import Bold from "../../typography/bold";
import { IconAndTextCell } from "../cells/iconAndTextCell";
import InfoBox from "../../info/infoBox.jsx";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  width: ${({ width }) => width || null}!important;
  flex-grow: ${({ width }) => (width ? "0 !important" : "inherit")};
`;

const ButtonCell = styled.td`
  width: auto !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
`;

export const SyncingStoreRow = memo(({ row, getCellProps }) => {
  const { t } = useTranslation();
  const cells = row.allCells;
  const marketplaceInfo = cells.find(
    (cell) => cell.column.id === "market" || cell.column.id === "marketplace"
  );
  const store = cells.find(
    (cell) => cell.column.id === "shopName" || cell.column.id === "store"
  );

  return (
    <>
      {marketplaceInfo && (
        <Cell
          width="90px"
          align={marketplaceInfo.column.align}
          {...marketplaceInfo.getCellProps(getCellProps(marketplaceInfo))}
          key={`${row.id} - syncing market - ${marketplaceInfo}`}
        >
          {marketplaceInfo.render("Cell")}
        </Cell>
      )}
      <Cell
        align={store.column.align}
        {...store.getCellProps(getCellProps(store))}
        key={`${row.id} - syncing store - ${store}`}
      >
        <IconAndTextCell cell={store} status="sync" />
      </Cell>
      <ButtonCell>
        <InfoBox>
          <Bold variant="body2">
            {t("connectionProgress.syncInProgressMessage")}
          </Bold>
        </InfoBox>
      </ButtonCell>
    </>
  );
});

SyncingStoreRow.propTypes = {
  row: PropTypes.any.isRequired,
  getCellProps: PropTypes.func.isRequired,
};
