import { Box, FormControlLabel, Grid } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";

import PurchaseOrdersLineChart from "./purchaseOrdersLineChart";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const CHART_TYPE = {
  Sales: "sales",
  Orders: "orders",
  AvgOrderValue: "avgOrderValue",
  UnitsSold: "unitsSold",
};

export const QUERY_TYPE = {
  poDate: "order_created_at",
  shippedDate: "shipped_date",
};

export const DISPLAY_CHART = {
  Bar: "Bar Chart",
  Line: "Line Chart",
};

interface PurchaseOrdersPerformanceProps {
  market: string;
  store: { merchantId?: string };
}

interface ChartOption {
  value: string;
  label: string;
}

const PurchaseOrdersPerformance = memo<PurchaseOrdersPerformanceProps>(
  function PurchaseOrdersPerformance({ market, store }) {
    const { t } = useTranslation();
    const overview = Boolean(market === "overview");
    const userInfo = useTypedSelector((state) => state.user);
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );

    const CHART_OPTIONS: ChartOption[] = useMemo(
      () => [
        {
          value: CHART_TYPE.Sales,
          label: t("dashboardWidget.salesPerformance.salesOption"),
        },
        {
          value: CHART_TYPE.Orders,
          label: t("dashboardWidget.salesPerformance.ordersOption"),
        },
        {
          value: CHART_TYPE.AvgOrderValue,
          label: t("dashboardWidget.salesPerformance.avgOrderValueOption"),
        },
        {
          value: CHART_TYPE.UnitsSold,
          label: t("dashboardWidget.salesPerformance.unitsSoldOption"),
        },
      ],
      []
    );

    const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
    const [lineChart, setLineChart] = useState<boolean>(false);
    const [queryByShipDate, setQueryByShipDate] = useState<boolean>(false);
    const footerLink = useMemo(
      () =>
        !overview && store.merchantId
          ? {
              url: marketplaceLink(market, store.merchantId, "salesbycategory"),
              label: t("generic.showFullReportLink"),
            }
          : undefined,
      [overview, store, market]
    );

    const switchType = useCallback((value: string) => {
      const option = CHART_OPTIONS.find(
        (chartOption) => chartOption.value === value
      );
      if (option) {
        setChartType(option);
      }
    }, []);

    const chartToggle: SettingsMenuItem = useMemo(
      () => ({
        title: t("salesPerformance.chartToggle"),
        checked: lineChart,
        setChecked: (newValue: boolean) => setLineChart(newValue),
        tooltip: t("includeTax.tooltip"),
      }),
      [lineChart]
    );

    const queryByToggle: SettingsMenuItem = useMemo(
      () => ({
        title: t("dashboardWidget.poTrend.byOrderDate"),
        checked: queryByShipDate,
        setChecked: (isChecked: boolean) => setQueryByShipDate(isChecked),
        tooltip: t("includeTax.tooltip"),
      }),
      [queryByShipDate]
    );

    const actions = useMemo(
      () => (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <TableFilter
              {...{
                width: "165px",
                options: CHART_OPTIONS,
                currentValue: chartType.label,
                handleChange: switchType,
              }}
            />
          </Grid>
          <Grid item>
            <Box pl="20px">
              <FormControlLabel
                control={includeTaxSwitch(queryByToggle)}
                label={
                  queryByToggle.checked
                    ? t("dashboardWidget.poTrend.byShippedDate")
                    : t("dashboardWidget.poTrend.byOrderDate")
                }
              />
            </Box>
          </Grid>
          <Grid item>
            <Box pl={0}>
              <FormControlLabel
                control={includeTaxSwitch(chartToggle)}
                label={
                  chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar
                }
              />
            </Box>
          </Grid>
        </Grid>
      ),
      [chartType, queryByShipDate, chartToggle]
    );

    return (
      <>
        <PurchaseOrdersLineChart
          userInfo={userInfo}
          mid={store.merchantId}
          currentPeriod={currentPeriod}
          currentRange={currentRange}
          currentCurrency={currentCurrency}
          currentFilter={currentFilter}
          footerLink={footerLink}
          overview={overview}
          actions={actions}
          chartType={chartType}
          queryBy={queryByShipDate ? QUERY_TYPE.shippedDate : QUERY_TYPE.poDate}
          timezone={selectedTimezone}
          isLineChart={lineChart}
        />
      </>
    );
  }
);

export default PurchaseOrdersPerformance;
