import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";
import React, { memo } from "react";
import { getCurrencySymbol, intFormatterRounded } from "~/utils/currencyUtils";
import { isEmpty, maxBy } from "lodash";

import BarChartNoData from "../../../components/placeholders/barChartNoData";
import ComboChart from "~/components/charts/comboChart/comboChart";
import ComboChartLegend from "~/components/charts/comboChart/ComboChartLegend";
import moment from "moment-timezone";

const xAxisProps = {
  dataKey: "reportDate",
  dy: 10,
};

const yAxisLeftProps = {
  dataKey: "sales",
  tick: { fontSize: 14 },
  dx: -10,
};

const yAxisRightProps = {
  dataKey: "acos",
  unit: "%",
  orientation: "right",
  tick: { fontSize: 14 },
  dx: 10,
};

const barChartProps = {
  dataKey: "sales",
  fill: "#0277BD",
};

const lineChartProps = {
  dataKey: "acos",
  stroke: "#FFA726",
  dot: false,
};

const line2ChartProps = {
  dataKey: "tacos",
  stroke: "#66BB6A",
  dot: false,
};

const legendData = [
  {
    name: "advertisingDashboardWidget.adTable.salesColumn",
    fillColor: "#0277BD",
    shape: "bar",
    unit: "$",
    isInEndUnit: false,
  },
  {
    name: "advertisingDashboardWidget.adTable.acosColumn",
    fillColor: "#FFA726",
    shape: "line",
    unit: "%",
    isInEndUnit: true,
  },
];

const tooltipProps = {
  barTooltipProps: legendData[0],
  lineTooltipProps: legendData[1],
};

interface PerformanceChartProps {
  title: string;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  chartData: any[];
  isLoading: boolean;
  timezone: string;
  interval: INTERVAL;
  line2Key?: string;
}

const PerformanceChart = memo<PerformanceChartProps>(function PerformanceChart({
  title,
  currentPeriod,
  currentCurrency,
  chartData,
  isLoading,
  timezone,
  interval,
  line2Key,
}) {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const getXAxisProps = () => {
    if (currentPeriod === DATETIME_PERIODS.WEEK) {
      const tickFormatter = (tick: number) =>
        moment.unix(tick).tz(timezone).format("ddd");
      return {
        tickLine: false,
        tick: { fontSize: 14 },
        tickFormatter,
      };
    } else {
      let tickFormatter;
      if (interval === INTERVAL.MONTHS) {
        tickFormatter = (tick: number) =>
          moment
            .unix(tick)
            .tz(timezone)
            .add(12, "h")
            .startOf("day")
            .format("MMM");
      } else if (currentPeriod === DATETIME_PERIODS.DAY) {
        tickFormatter = (tick: number) =>
          moment.unix(tick).tz(timezone).format("HH");
      } else {
        tickFormatter = (tick: number) =>
          moment.unix(tick).tz(timezone).format("MMMM Do");
      }
      const tickCount = currentPeriod === DATETIME_PERIODS.DAY ? 13 : 3;
      return {
        type: "category",
        interval: "preserveStartEnd",
        tickLine: false,
        tickCount: tickCount,
        domain: ["dataMin", "dataMax"],
        padding: "gap",
        tick: { fontSize: 14 },
        tickFormatter,
      };
    }
  };

  const getYAxisLeftProps = (currency: string) => {
    const tickFormatter = (tick: number) =>
      getCurrencySymbol[currency as keyof typeof getCurrencySymbol] +
      intFormatterRounded.format(tick);

    return {
      tickFormatter,
    };
  };

  const getLineChartProps = () => ({
    strokeWidth: smDown ? 1 : 2,
  });

  const getTooltipProps = (currency: string, line2Key?: string) => ({
    barTooltipProps: Object.assign(tooltipProps.barTooltipProps, {
      unit: getCurrencySymbol[currency as keyof typeof getCurrencySymbol],
    }),
    lineTooltipProps: tooltipProps.lineTooltipProps,
    line2TooltipProps: line2Key
      ? {
          name: "advertisingDashboardWidget.adTable.tacosColumn",
          fillColor: "#66BB6A",
          shape: "line",
          unit: "%",
          isInEndUnit: true,
        }
      : undefined,
  });

  const getLegendData = (line2Key?: string) =>
    line2Key
      ? [
          ...legendData,
          {
            name: "advertisingDashboardWidget.adTable.tacosColumn",
            fillColor: "#66BB6A",
            shape: "line",
            unit: "%",
            isInEndUnit: true,
          },
        ]
      : legendData;

  const getYAxisRightProps = (line2Key?: string) => {
    if (line2Key) {
      const maxAcos = maxBy(chartData, "acos");
      const maxTacos = maxBy(chartData, line2Key);
      return {
        ...yAxisRightProps,
        dataKey: maxAcos?.acos > maxTacos[line2Key] ? "acos" : line2Key,
      };
    }
    return yAxisRightProps;
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {isEmpty(chartData) && !isLoading ? (
          <BarChartNoData {...{ currentPeriod }} />
        ) : (
          <ComboChart
            {...{
              chartData,
              xAxisProps: { ...getXAxisProps(), ...xAxisProps },
              yAxisLeftProps: {
                ...getYAxisLeftProps(currentCurrency),
                ...yAxisLeftProps,
              },
              yAxisRightProps: getYAxisRightProps(line2Key),
              barChartProps,
              lineChartProps: {
                ...lineChartProps,
                ...getLineChartProps(),
              },
              lineChart2Props: {
                ...line2ChartProps,
                ...getLineChartProps(),
              },
              legendContent: () => (
                <ComboChartLegend legendItems={getLegendData(line2Key)} />
              ),
              tooltipProps: {
                ...getTooltipProps(currentCurrency, line2Key),
              },
              currentPeriod,
              timezone,
              xKey: "reportDate",
              lineKey: "acos",
              barKey: "sales",
              line2Key,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
});

export default PerformanceChart;
