import { ExtendButtonBase, Tab, TabTypeMap, Tabs } from "@material-ui/core";
import { LinkProps, Link as RouterLink, useLocation } from "react-router-dom";
import React, { memo } from "react";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import { pathNavMap } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

type CustomTabProps = ExtendButtonBase<TabTypeMap<{}, "div">> &
  LinkProps &
  ThemeProps<DefaultTheme>;

const CustomTab = styled(Tab)`
  &.Mui-selected {
    font-weight: bold;
    color: ${({ theme }: CustomTabProps) =>
      theme.palette.topToolbar
        ? theme.palette.topToolbar.activeText
        : theme.palette.secondary.contrastText};
  }
` as unknown as CustomTabProps;

const CustomBadge = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.topToolbar
      ? theme.palette.topToolbar.activeText
      : theme.palette.primary.main};
  color: ${({ theme }) =>
    theme.palette.topToolbar
      ? theme.palette.topToolbar.contrastText
      : theme.palette.primary.contrastText};
  padding: 4px 6px;
  margin-left: 0.5rem;
  font-size: 12px;
`;

interface NavTabsProps {
  isNewUser?: boolean;
}

export const NavTabs = memo(({ isNewUser }: NavTabsProps) => {
  const user = useTypedSelector((state) => state.user);

  const { t } = useTranslation();
  const location = useLocation();

  const pathTab = pathNavMap(location.pathname);

  return (
    <Tabs
      id="nav-tabs"
      value={
        ["overview", "marketplaces", "reports"].includes(pathTab)
          ? pathTab
          : false
      }
      onChange={(e) => {
        e.stopPropagation();
      }}
      centered
      variant="fullWidth"
      TabIndicatorProps={{ style: { display: "none" } }}
    >
      <CustomTab
        value="overview"
        disabled={isNewUser}
        to="/overview"
        label={t("nav.dashboard")}
        id="dashboard-tab"
        component={RouterLink}
      />
      <CustomTab
        value="marketplaces"
        disabled={isNewUser}
        to="/mystores"
        label={t("nav.myStores")}
        id="my-stores-tab"
        component={RouterLink}
      />
      {user.reportsOn && (
        <CustomTab
          value="reports"
          to="/reports/createReport"
          label={<>{t("nav.reports")}</>}
          id="reports-tab"
          component={RouterLink}
        />
      )}
    </Tabs>
  );
});

export default NavTabs;
