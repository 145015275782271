import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { ChartSummary } from "../typography/chartSummary";
import { Detail } from "../typography/detail";
import { SecondaryData } from "../typography/secondaryData";

const PaddedSecondaryData = styled(SecondaryData)`
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`;

export interface LabelledDataProps {
  label: string;
  data: string;
  secondaryData?: string;
  tooltip?: string;
}

export const LabelledData: React.FC<LabelledDataProps> = ({
  label,
  data,
  tooltip,
  secondaryData,
}) => {
  return (
    <Box p={2}>
      <Box display="flex" alignItems="center">
        <Detail display="inline">{label}</Detail>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InlineIconButton />
          </Tooltip>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <ChartSummary display="inline">{data}</ChartSummary>
        {secondaryData && (
          <PaddedSecondaryData display="inline">{`${secondaryData}`}</PaddedSecondaryData>
        )}
      </Box>
    </Box>
  );
};
