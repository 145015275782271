import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";

import Bold from "~/components/typography/bold";
import { Box } from "@material-ui/core";
import DoubleCell from "./doubleCell";
import { GlobalVar } from "~/typedef/store";
import React from "react";
import StatusText from "~/components/typography/status";
import { Typography } from "@material-ui/core";

export interface ValueAndGrowth {
  value: number;
  growth: string;
  status: "success" | "error" | undefined;
}
// Used to display detailed profitability breakdowns,
// where the event label is not known a priori
export type ChildValueAndGrowth = ValueAndGrowth & {
  eventType: string;
};

interface ProfitAndLossGrowthCellProps {
  value: ValueAndGrowth;
  currencyRates: GlobalVar["currencyRates"];
  currentCurrency: string;
  currency: string;
  condensed?: boolean;
  bold: boolean;
}

export const ProfitAndLossGrowthCell = ({
  value,
  condensed,
  currencyRates,
  currency,
  currentCurrency,
  bold,
}: ProfitAndLossGrowthCellProps) => {
  const currencyFormatter = condensed ? formatCurrencyRounded : formatCurrency;
  const TextVariant = bold ? Bold : Typography;
  return (
    <DoubleCell>
      <TextVariant variant="body1">
        {value.value
          ? currencyFormatter(
              value.value,
              currencyRates,
              currency,
              currentCurrency
            )
          : "-"}
      </TextVariant>
      <Box width="14px" />
      <StatusText status={value.status} variant="body2" color="textSecondary">
        {value.status === "success" && (
          <UpArrow fontSize="inherit" status="success" />
        )}
        {value.status === "error" && (
          <DownArrow fontSize="inherit" status="error" />
        )}
        {value.growth ? `${value.growth}%` : "-"}
      </StatusText>
    </DoubleCell>
  );
};
