import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import TrafficAndConversionTable, {
  DEFAULT_SORT,
  TrafficAndConversionTableSelect,
  TrafficAndConversionTableView,
} from "~/modules/trafficAndConversion/trafficAndConversionTable";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import NeedToReconnectBanner from "~/components/alert/needToReconnect";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import TrafficAndConversionNormalVsB2BTotals from "~/modules/trafficAndConversion/trafficAndConversionNormalVsB2BTotals";
import TrafficAndConversionTotals from "~/modules/trafficAndConversion/trafficAndConversionTotals";
import TrafficAndConversionTrend from "~/modules/trafficAndConversion/trafficAndConversionTrend";
import TrafficMixTrend from "~/modules/trafficAndConversion/trafficMixTrend";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { getExchangeRate } from "~/utils/currencyUtils";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTrafficAndConversionQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface TrafficAndConversionProps {}

const TrafficAndConversion = memo<TrafficAndConversionProps>(
  function TrafficAndConversion() {
    const { t } = useTranslation();

    const store = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const { currentPeriod, currentCompare, selectedTimezone, currentRange } =
      useDateRangeFilters();
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const issues = useTypedSelector((state) => state?.issues?.data);

    const [canShowData, setCanShowData] = useState(true);
    const [searchText, setSearchText] = useState("");

    const [view, setView] = useState<TrafficAndConversionTableView>(
      TrafficAndConversionTableSelect.SKUS
    );

    const { trafficAndConversionCount } = useTrafficAndConversionQuery(
      {
        mid: store?.merchantId || "", // it won't be undefined because of the skip
        marketplaceFilters: {
          marketplaceType: store?.marketplace || "",
          marketplaceSubtype:
            store?.marketplaceSubtype ?? (store?.marketplace || ""),
        },
        currentRange,
        searchText,
        view: TrafficAndConversionTableSelect.SKUS,
        paginationParams: {
          pageSize: PAGE_SIZE,
          pageIndex: 0,
          sortKey: DEFAULT_SORT,
          sortOrder: "desc",
        },
      },
      {
        skip: !store,
        selectFromResult: ({ data }) => ({
          trafficAndConversionCount:
            data?.[TrafficAndConversionTableSelect.SKUS]?.count || 0,
        }),
      }
    );

    useEffect(() => {
      if (
        issues?.includes("noRetailAnalytics") &&
        trafficAndConversionCount > 0
      ) {
        setCanShowData(false);
      }
    }, [issues, trafficAndConversionCount]);

    if (!store) {
      return (
        <PageBlock>
          <LoadingIndicator />
        </PageBlock>
      );
    }

    const tableActions = (
      <>
        {store.marketplace === "amazon" && (
          <Box px={1}>
            <ToggleButtonGroup
              exclusive
              size={"small"}
              onChange={(_, v) => {
                if (v !== null) setView(v);
              }}
              value={view}
            >
              <ToggleButton value={TrafficAndConversionTableSelect.PARENTS}>
                <Typography variant="caption">
                  {t("trafficAndConversion.parentView")}
                </Typography>
              </ToggleButton>
              <ToggleButton value={TrafficAndConversionTableSelect.SKUS}>
                <Typography variant="caption">
                  {t("trafficAndConversion.childView")}
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        <Box px={1}>
          <SearchFilter setSearchText={setSearchText} />
        </Box>
        <DownloadCsv
          {...{
            reportType: "trafficAndConversion",
            path: "/api/generic/trafficAndConversion",
            mid: store.merchantId,
            params: {
              marketplaceFilters: {
                marketplaceType: store.marketplace,
                marketplaceSubtype:
                  store.marketplaceSubtype ?? store.marketplace,
              },
              ...currentRange,
              sortKey: "orderedUnits",
              sortOrder: "desc",
              searchText,
              currentCurrency,
              exchangeRate: getExchangeRate(
                currencyRates,
                getCurrencyByCountryCode[store.marketplaceCountry],
                currentCurrency
              ),
              view,
            },
          }}
        />
      </>
    );

    return (
      <PageBlock>
        {canShowData ? (
          <Grid container spacing={2}>
            <GridWithErrorBoundary item xs={12} md={8}>
              <TrafficAndConversionTrend
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                timezone={selectedTimezone}
                currentCurrency={currentCurrency}
              />
            </GridWithErrorBoundary>
            <GridWithErrorBoundary item xs={12} md={4}>
              <TrafficAndConversionTotals
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                currentRange={currentRange}
                currentCompare={currentCompare}
                currentCurrency={currentCurrency}
              />
            </GridWithErrorBoundary>
            {store.marketplace === "amazon" && (
              <>
                <GridWithErrorBoundary item xs={12} md={8}>
                  <TrafficMixTrend
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    currentRange={currentRange}
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    timezone={selectedTimezone}
                  />
                </GridWithErrorBoundary>
                <GridWithErrorBoundary item xs={12} md={4}>
                  <TrafficAndConversionNormalVsB2BTotals
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    currentRange={currentRange}
                    currentCurrency={currentCurrency}
                  />
                </GridWithErrorBoundary>
              </>
            )}
            <GridWithErrorBoundary item xs={12}>
              <TrafficAndConversionTable
                mid={store.merchantId}
                marketplaceType={store.marketplace}
                marketplaceSubtype={
                  store.marketplaceSubtype ?? store.marketplace
                }
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                searchText={searchText}
                view={view}
                actions={tableActions}
                pageSize={PAGE_SIZE}
                timezone={selectedTimezone}
              />
            </GridWithErrorBoundary>
          </Grid>
        ) : (
          <NeedToReconnectBanner marketplace={store.marketplace} />
        )}
      </PageBlock>
    );
  }
);

export default TrafficAndConversion;
