import { Filter, Range } from "~/typedef/store";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import MarketingOverviewChart from "./marketingCampaignSummaryChart";
import MarketingOverviewTotals from "./marketingCampaignSummaryTotals";
import React from "react";
import { memo } from "react";

interface MarketingCampaignSummaryChartAndTotalsProps {
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
  showChart?: boolean;
  showTotals?: boolean;
  currentFilter: Filter;
  includeTax: boolean;
  conditionalFormatting?: boolean;
}

const MarketingCampaignSummaryChartAndTotals =
  memo<MarketingCampaignSummaryChartAndTotalsProps>(
    function MarketingCampaignSummaryChartAndTotals({
      currentPeriod,
      currentRange,
      currentCurrency,
      currentFilter,
      showChart,
      showTotals,
      includeTax,
      conditionalFormatting,
      timezone,
    }) {
      return (
        <>
          {showChart && (
            <MarketingOverviewChart
              {...{
                currentFilter,
                currentPeriod,
                includeTax,
                currentCurrency,
                currentRange,
                timezone,
              }}
            />
          )}
          {showTotals && (
            <MarketingOverviewTotals
              {...{
                includeTax,
                currentFilter,
                currentCurrency,
                currentRange,
                timezone,
                conditionalFormatting,
              }}
            />
          )}
        </>
      );
    }
  );

export default MarketingCampaignSummaryChartAndTotals;
