/* eslint-disable no-undefined */
import { Box, Divider, LinearProgress, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import AlertCard from "../alert/alertCard";
import Bold from "../typography/bold";
import { FeatureIndicator } from "./featureIndicator";
import PropTypes from "prop-types";
import { StoreDetails } from "./storeDetails";
import SyncComplete from "../../img/connections/sync_complete.png";
import SyncInProgress from "../../img/connections/sync_in_progress.png";
import { deleteConnectionStatus } from "../../store/connections/connectionRemove.redux";
import { fetchConnectionStatus } from "../../store/connections/connectionStatus.redux";
import get from "lodash/get";
import { getMatchingStore } from "~/utils/marketplaceUtils";
import { setError } from "../../store/globalToast.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ImageWrapper = styled.img`
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const CardDivider = styled(Divider)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey["500"]};
  `}
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const DEFAULT_DAYS_TO_SYNC = 90;

const ConnectionProgress = memo(function ConnectionProgress({
  user,
  filteredStores,
  connection,
  percentage,
  breakdown,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = getMatchingStore(
    filteredStores,
    connection.market,
    connection.mid
  );
  const daysToSync = get(user, "daysToSync", DEFAULT_DAYS_TO_SYNC);
  const monthsToSync = Math.floor(daysToSync / 30);
  const [open, setOpen] = useState(true);

  const onClose = () => {
    if (percentage === 100) {
      dispatch(
        deleteConnectionStatus({
          user,
          mid: connection.mid,
          sellerId: connection.sellerId,
        })
      );
    }
    setOpen(!open);
  };

  useEffect(() => {
    const dispatchFetchConnectionStatus = () =>
      dispatch(
        fetchConnectionStatus({
          user,
          mid: connection.mid,
          sellerId: connection.sellerId,
          market: connection.market,
        })
      );
    const fetchData = async () => {
      await dispatchFetchConnectionStatus();
    };

    const fetchConnections = () => {
      fetchData().catch((err) =>
        setError(dispatch, err, err.status, "fetchConnectionStatus")
      );
    };
    fetchConnections();
    // eslint-disable-next-line no-magic-numbers
    const interval = setInterval(fetchConnections, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    store && (
      <Box
        display="flex"
        width="100%"
        alignItems="stretch"
        flexDirection="column"
        mb={open ? 2 : 0}
      >
        <AlertCard
          type="plain"
          id="store-connection-progress"
          isOpen={open}
          onClose={onClose}
          style={{ width: "100%" }}
        >
          <Box p={1} pl={0}>
            <ImageWrapper
              src={Boolean(percentage === 100) ? SyncComplete : SyncInProgress}
            />
          </Box>
          <Box
            p={1}
            pb={0.5}
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Bold variant="h5">
              {Boolean(percentage === 100)
                ? t("connectionProgress.completeTitle")
                : t("connectionProgress.inProgressTitle")}
            </Bold>
            <Typography variant="body2" align="right">
              {percentage === "N/A"
                ? t("connectionProgress.syncingMessage")
                : percentage === 100
                ? t("connectionProgress.doneMessage")
                : t("connectionProgress.percentageCompletedMessage", {
                    percentage,
                  })}
            </Typography>
            <Box mt={0.5} mb={0.5}>
              <LinearProgress
                color="secondary"
                value={percentage}
                variant={percentage === "N/A" ? "indeterminate" : "determinate"}
              />
            </Box>
            <StoreDetails store={store} />
            <CardDivider />
            {breakdown ? (
              <Box display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(breakdown).map((featureKey) => (
                  <FeatureIndicator
                    key={featureKey}
                    label={breakdown[featureKey].label}
                    complete={breakdown[featureKey].complete}
                  />
                ))}
                {get(breakdown, "operations.accounthealth") !== undefined && (
                  <FeatureIndicator
                    label="Account Health"
                    complete={breakdown.operations.accounthealth}
                  />
                )}
                {get(breakdown, "marketplaceListings.listingquality") !==
                  undefined && (
                  <FeatureIndicator
                    label="Listing Quality"
                    complete={breakdown.marketplaceListings.listingquality}
                  />
                )}
              </Box>
            ) : (
              <Typography variant="body2">
                {Boolean(percentage === 100)
                  ? t("connectionProgress.completeMessage")
                  : daysToSync <= DEFAULT_DAYS_TO_SYNC
                  ? t("connectionProgress.inProgressMessage")
                  : t("connectionProgress.inProgressMessageWithMonths", {
                      monthsToSync,
                    })}
              </Typography>
            )}
          </Box>
        </AlertCard>
      </Box>
    )
  );
});

ConnectionProgress.propTypes = {
  user: PropTypes.object.isRequired,
  filteredStores: PropTypes.array.isRequired,
  connection: PropTypes.shape({
    mid: PropTypes.string,
    sellerId: PropTypes.string,
    market: PropTypes.string,
  }).isRequired,
  percentage: PropTypes.number.isRequired,
  breakdown: PropTypes.object,
};

export default ConnectionProgress;
