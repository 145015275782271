import React, { useState } from "react";

import { AdvertisingProfile } from "~/store/mystore/marketing.redux";
import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import Box from "@material-ui/core/Box";
import { CountryLogo } from "../../img/flags/country_logos";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import Medium from "~/components/typography/medium";
import Radio from "@material-ui/core/Radio";
import RaisedButton from "~/components/buttons/raisedButton";
import Typography from "@material-ui/core/Typography";
import { marketplace } from "mm-amazon-common/dist/mwsUtils";
import styled from "styled-components";
import { updateAmazonAdvertisingProfile } from "../../store/connections/connectAmazon.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
  padding-top: 2rem;
`;

export const CountryLogoWrapper = styled.div`
  & > img {
    display: block;
  }
`;

interface StandaloneSelectProfileProps {
  countryCode: string;
  marketplaceType: string;
  onSuccess: () => void;
  selectProfile: (mid: string, profileId?: string, entityId?: string) => void;
}

const StandaloneSelectProfile: React.FC<StandaloneSelectProfileProps> = ({
  marketplaceType,
  countryCode,
  onSuccess,
  selectProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const marketing = useTypedSelector((state) => state.marketing);
  const [loading, setLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] =
    useState<AdvertisingProfile | null>(null);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (selectedProfile?.profileId) {
        const marketplaceId = marketplace(
          countryCode as AmazonCountry
        ).marketplaceId;

        const mid =
          marketplaceType === "dsp"
            ? `${selectedProfile.accountInfo.id}_${selectedProfile.profileId} @ ${marketplaceId}`
            : `${selectedProfile.profileId} @ ${marketplaceId}`;
        await dispatch(
          updateAmazonAdvertisingProfile(
            mid,
            countryCode,
            selectedProfile.profileId,
            selectedProfile.accountInfo.id,
            marketplaceType,
            "advertising",
            true
          )
        );
        selectProfile(
          mid,
          selectedProfile.profileId,
          selectedProfile.accountInfo.id
        );
        setLoading(false);
        onSuccess();
      } else {
        throw new Error(`Invalid advertising profile: ${selectedProfile}`);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  const renderAvailableProfiles = (profiles: AdvertisingProfile[]) =>
    profiles.map((profile, index) => (
      <Grid
        key={index}
        container
        item
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Radio
          checked={`${selectedProfile?.profileId}` === `${profile.profileId}`}
          name={`${profile.profileId}`}
          color="primary"
          onChange={(e) => setSelectedProfile(profile)}
        />
        <Grid item>
          <CountryLogoWrapper>
            <CountryLogo code={profile.countryCode} />
          </CountryLogoWrapper>
        </Grid>
        <Grid item>
          <MarketplaceIcon market={"amazon"} />
        </Grid>
        <Grid item>
          <Typography variant="body2" noWrap>
            {profile.accountInfo.id} (&quot;{profile.accountInfo.name}
            &quot;)
          </Typography>
        </Grid>
      </Grid>
    ));

  return loading ? (
    <LoadingIndicator />
  ) : (
    <GridWrapper id="amazon-selectadvertisingprofile-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <Medium align="center">
            {t("connectWizard.amazonAdvertisingProfileTitle")}
          </Medium>
        </Grid>
        <Grid item xs={12} alignItems="center" justifyContent="flex-start">
          {renderAvailableProfiles(marketing.profiles)}
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          xs={12}
        >
          <Grid item xs={12} alignItems="center" justifyContent="center">
            <Box pt={2}>
              <RaisedButton
                disabled={!selectedProfile?.profileId}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
              >
                {t("generic.continueButton")}
              </RaisedButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </GridWrapper>
  );
};

export default StandaloneSelectProfile;
