import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "../../../store/utils/dateTimeUtils";
import React, { memo, useCallback, useEffect } from "react";

import { DEFAULT_FILTER } from "~/store/persistentAppSettings.redux";
import { Panel } from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import SalesMixPieChartContents from "./salesMixPieChartContents";
import { fetchSalesMix } from "../../../store/overview/salesMix.redux";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SalesMixPieChart = memo(function SalesMixPieChart() {
  const { t } = useTranslation();
  const salesMix = useTypedSelector((state) => state.overview.salesMix);
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const userInfo = useTypedSelector((state) => state.user);
  const userCheck = userInfo && userInfo._id;
  const dispatch = useDispatch();
  const isFetching = get(salesMix, "fetching", true);

  const dispatchFetchSalesMix = useCallback(() => {
    dispatch(
      fetchSalesMix(
        {
          user: {
            _id: userInfo?._id,
            organisationId: userInfo?.organisationId,
          },
          currentPeriod,
          currentRange,
          filter: currentFilter,
          filteredStores,
          includeTax,
        },
        salesMix?.params
      )
    );
  }, [
    userCheck,
    currentPeriod,
    currentRange,
    currentFilter,
    filteredStores,
    includeTax,
    salesMix?.params,
  ]);

  const fetchData = async () => {
    await dispatchFetchSalesMix();
  };

  useEffect(() => {
    fetchData();
  }, [userCheck, currentPeriod, currentRange, currentFilter, filteredStores, includeTax]);

  return (
    <Panel
      id="widget-sales-mix"
      title={t("dashboardWidget.salesMix.mainTitle")}
      tooltip={t("dashboardWidget.salesMix.mainTooltip")}
      content={
        isFetching ? (
          <PanelLoading />
        ) : (
          <SalesMixPieChartContents salesMix={salesMix} />
        )
      }
    />
  );
});

export default SalesMixPieChart;
