import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  upsertPersistentAppSettings,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";
import ShippedRevenueTrend, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/vendor/shippedRevenueTrend";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import ShippedRevenueMetricSelect from "~/modules/vendor/shippedRevenueMetricSelect";
import ShippedRevenueTable from "~/modules/vendor/shippedRevenueTable";
import ShippedRevenueTotals from "~/modules/vendor/shippedRevenueTotals";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface ShippedRevenueProps {
  singleWidget?: "table" | "summary" | "trend";
}

const ShippedRevenue = memo<ShippedRevenueProps>(function ShippedRevenue({
  singleWidget,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const DISPLAY_CHART = {
    Bar: "Bar Chart",
    Line: "Line Chart",
  };

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.Revenue,
        label: t("shippedRevenue.revenueOption"),
      },
      {
        value: CHART_TYPE.COGS,
        label: t("shippedRevenue.cogsOption"),
      },
      {
        value: CHART_TYPE.Units,
        label: t("shippedRevenue.unitsOption"),
      },
      {
        value: CHART_TYPE.NetPureProductMargin,
        label: t("shippedRevenue.netPureProductMarginOption"),
      },
    ],
    []
  );

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const user = useTypedSelector((state) => state.user);

  const marketplaceFilters = store
    ? {
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
      }
    : undefined;

  const currentViewPreference = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.viewPreference") ||
      "sourcing"
  );

  const [isSourcingView, setIsSourcingView] = useState(
    currentViewPreference === "sourcing"
  );
  const [searchText, setSearchText] = useState("");
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [lineChart, setLineChart] = useState<boolean>(false);

  const chartToggle: SettingsMenuItem = {
    title: "",
    checked: lineChart,
    setChecked: (newValue: boolean) => setLineChart(newValue),
    tooltip: "",
  };

  const chartActions = (
    <>
      <ShippedRevenueMetricSelect
        {...{
          width: "165px",
          selected: chartType,
          setSelected: setChartType,
        }}
      />
      <Box pl="12px">
        <FormControlLabel
          control={includeTaxSwitch(chartToggle)}
          label={chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar}
        />
      </Box>
    </>
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const updateViewPreference = useCallback((isSourcingView: boolean) => {
    dispatch(
      upsertPersistentAppSettings({
        organisationId: user.organisationId,
        userId: user._id,
        settings: {
          viewPreference: isSourcingView ? "sourcing" : "manufacturing",
        },
      })
    );
  }, []);

  const tableActions = (
    <>
      <SearchFilter setSearchText={setSearchText} />
      <DownloadCsv
        {...{
          reportType: "shippedRevenue",
          path: "/api/generic/vendor/shippedRevenue",
          mid: store.merchantId,
          params: {
            fromDate: currentRange.fromDate,
            priorFromDate: currentRange.priorFromDate,
            toDate: currentRange.toDate,
            priorToDate: currentRange.priorToDate,
            timezone: currentRange.timezone,
            sortKey: "shipped_revenue",
            sortOrder: "desc",
            searchText,
            currentCurrency,
            currencyRates: currencyRates,
            view: isSourcingView ? "sourcing" : "manufacturing",
          },
        }}
      />
    </>
  );
  if (singleWidget) {
    switch (singleWidget) {
      case "trend":
        return (
          <ShippedRevenueTrend
            mid={store.merchantId}
            marketplaceFilters={marketplaceFilters}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            chartType={chartType}
            actions={chartActions}
            view={isSourcingView ? "sourcing" : "manufacturing"}
            condensed={true}
            timezone={selectedTimezone}
            isLineChart={lineChart}
          />
        );
      case "summary":
        return (
          <ShippedRevenueTotals
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentCompare={currentCompare}
            view={isSourcingView ? "sourcing" : "manufacturing"}
          />
        );
      case "table":
        return (
          <ShippedRevenueTable
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            view={isSourcingView ? "sourcing" : "manufacturing"}
            pageSize={5}
            condensed={true}
          />
        );
      default:
        return (
          <ShippedRevenueTable
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            view={isSourcingView ? "sourcing" : "manufacturing"}
            pageSize={5}
            condensed={true}
          />
        );
    }
  } else {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="subtitle1" color="textPrimary" noWrap>
                {isSourcingView
                  ? t("retailAnalytics.sourcingView")
                  : t("retailAnalytics.manufacturingView")}
              </Typography>
              <Box pr={1}>
                <Switch
                  {...{
                    size: "small",
                    checked: isSourcingView,
                    onClick: () => {
                      updateViewPreference(!isSourcingView);
                      setIsSourcingView(!isSourcingView);
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <ShippedRevenueTrend
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              chartType={chartType}
              actions={chartActions}
              view={isSourcingView ? "sourcing" : "manufacturing"}
              timezone={selectedTimezone}
              isLineChart={lineChart}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ShippedRevenueTotals
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentCompare={currentCompare}
              view={isSourcingView ? "sourcing" : "manufacturing"}
            />
          </Grid>

          <Grid item xs={12}>
            <ShippedRevenueTable
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              searchText={searchText}
              actions={tableActions}
              view={isSourcingView ? "sourcing" : "manufacturing"}
              pageSize={PAGE_SIZE}
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
});

export default ShippedRevenue;
