import {
  Autorenew,
  CancelOutlined,
  CheckCircleOutline,
  Power,
} from "@material-ui/icons";
/* eslint-disable no-magic-numbers */
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { FEATURE_LIST, PlanPropTypes } from "./plan";

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const IncludedIcon = styled(CheckCircleOutline)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const ExcludedIcon = styled(CancelOutlined)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const PlugIcon = styled(Power)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const SyncIcon = styled(Autorenew)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FeatureItem = (props) => {
  const { text, icon } = props;
  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{ variant: "subtitle1" }}
      />
    </ListItem>
  );
};
FeatureItem.defaultProps = {
  icon: <IncludedIcon />,
};
FeatureItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.Element,
};

const PlanFeatures = (props) => {
  const { t } = useTranslation();
  const { plan } = props;
  return (
    <Box paddingY={2}>
      <List>
        <Box paddingBottom={1}>
          <FeatureItem
            text={t("subscription.feature.marketplaceConnections", {
              numConnections: plan?.entitlements?.marketplaces,
            })}
            icon={<PlugIcon />}
          />
        </Box>
        {Object.entries(FEATURE_LIST).map(([featureKey, featureText]) => (
          <FeatureItem
            key={featureKey}
            text={t(featureText)}
            icon={
              plan?.entitlements?.features?.includes(featureKey) ? (
                <IncludedIcon />
              ) : (
                <ExcludedIcon />
              )
            }
          />
        ))}
        <FeatureItem
          text={t("subscription.feature.initialSync", {
            months: plan?.entitlements?.syncPeriodMonths,
          })}
          icon={<SyncIcon />}
        />
        <FeatureItem
          text={t("subscription.feature.retentionPeriod", {
            months: plan?.entitlements?.dataRetentionMonths,
          })}
          icon={<SyncIcon />}
        />
      </List>
    </Box>
  );
};
PlanFeatures.propTypes = {
  plan: PlanPropTypes.isRequired,
};

export default PlanFeatures;
