import { FormControl, Grid, TextField } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import SearchableMultiSelect, {
  Option,
} from "~/components/select/searchableMultiSelect";
import {
  createCampaignCustomGroup,
  fetchAllCampaignCustomGroups,
  fetchCampaignGroupById,
} from "~/store/customGroups/advertising.redux";

import { LinkCell } from "~/components/table/cells/linkCell";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ModalPanel from "~/components/panel/modalPanel";
import RaisedButton from "~/components/buttons/raisedButton";
import { fetchAllMarketingCampaigns } from "~/store/mystore/marketing.redux";
import { formatAdType } from "~/modules/marketing/commonColumns";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
`;

interface CampaignCustomGroupsModalProps {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  countryCode: string;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  groupId?: number;
  onEdit?: () => void;
}

type OptionsType = {
  cell: Record<string, any>;
  campaignId: string;
  selected: boolean;
};

type CurrentGroup = {
  groupId: number;
  groupName: string;
};

const CampaignCustomGroupsModal = memo(
  ({
    mid,
    marketplace,
    marketplaceSubtype,
    countryCode,
    openModal,
    setOpenModal,
    groupId,
  }: CampaignCustomGroupsModalProps) => {
    const { t } = useTranslation();

    const userInfo = useTypedSelector((state) => state.user);

    const campaignsLoading = useTypedSelector(
      (state) => state?.marketing?.loading
    );

    const campaigns = useTypedSelector(
      (state) => state?.marketing?.campaigns?.data
    );

    const campaignCustomGroups = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.groups?.data || []
    );

    const campaignCustomGroupsFetching = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.groups?.fetching
    );

    const currentGroup = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.currentGroup?.group || []
    );

    const [searchText, setSearchText] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [options, setOptions] = useState<OptionsType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<OptionsType[]>([]);
    const [groupNameValidation, setGroupNameValidation] = useState("");

    const dispatch = useDispatch();

    const dispatchFetchAllMarketingCampaigns = useCallback(() => {
      if (openModal) {
        dispatch(
          fetchAllMarketingCampaigns(
            userInfo._id,
            mid,
            countryCode,
            marketplace,
            marketplaceSubtype,
            searchText
          )
        );
      }
    }, [mid, userInfo, searchText, openModal]);

    const fetchCampaignGroups = useCallback(() => {
      dispatch(
        fetchAllCampaignCustomGroups({
          mid,
          marketplaceType: marketplace,
          marketplaceSubtype,
          countryCode,
          searchText,
        })
      );
    }, [
      mid,
      marketplace,
      marketplaceSubtype,
      countryCode,
      searchText,
      openModal,
    ]);

    useEffect(() => {
      fetchCampaignGroups();
    }, [fetchCampaignGroups]);

    const fetchCampaignGroup = useCallback(() => {
      dispatch(
        fetchCampaignGroupById({
          mid,
          marketplaceType: marketplace,
          marketplaceSubtype,
          countryCode,
          searchText,
          groupId,
        })
      );
    }, [mid, marketplace, marketplaceSubtype, countryCode, openModal]);

    useEffect(() => {
      if (groupId) {
        fetchCampaignGroup();
      }
    }, [fetchCampaignGroup, groupId]);

    const dispatchCreateGroup = useCallback(async () => {
      await dispatch(
        createCampaignCustomGroup({
          mid,
          marketplaceType: marketplace,
          marketplaceSubtype,
          countryCode,
          groupId,
          groupName: campaignName,
          campaignNames: selectedOptions.map(
            (option) => option.cell.value.value
          ),
        })
      );
      setOpenModal(false);
      setSearchText("");
      setCampaignName("");
      setOptions([]);
      setSelectedOptions([]);
    }, [mid, userInfo, campaignName, selectedOptions]);

    useEffect(() => {
      dispatchFetchAllMarketingCampaigns();
    }, [dispatchFetchAllMarketingCampaigns]);

    const data = useMemo(() => {
      return campaigns?.length
        ? campaigns?.map((campaign: any) => {
            return {
              cell: {
                value: {
                  value: campaign.campaign_name,
                  link: {
                    pathname: `/mystores/${marketplace}/marketingCampaignAdGroups`,
                    search: `?store=${encodeURI(mid)}`,
                    state: {
                      campaignId: campaign.campaign_id,
                      campaignName: campaign.campaign_name,
                    },
                  },
                  subtitle: formatAdType(campaign.ad_type, t),
                  target: "_blank",
                },
              },
              campaignId: campaign.campaign_id,
              selected: false,
            };
          })
        : [];
    }, [campaigns, userInfo, marketplace, mid]);

    useEffect(() => {
      if (data.length) {
        setOptions(
          data.map((campaign: OptionsType) => {
            const isSelected = selectedOptions.find(
              (selectedOption) =>
                selectedOption.campaignId === campaign.campaignId
            );
            return {
              ...campaign,
              selected: Boolean(isSelected),
            };
          })
        );
      } else if (options.length) {
        setOptions([]);
      }
    }, [data, selectedOptions]);

    const changeSelectOption = (
      e: React.ChangeEvent<HTMLInputElement>,
      option: OptionsType
    ) => {
      const checked = e.target.checked;
      if (checked) {
        setSelectedOptions([option, ...selectedOptions]);
      } else {
        setSelectedOptions(
          selectedOptions.filter(
            (selectedOption) => selectedOption.campaignId !== option.campaignId
          )
        );
      }
    };

    const selectAllOptions = (checked: boolean) => {
      if (checked) {
        setSelectedOptions(options);
      } else {
        setSelectedOptions([]);
      }
    };

    useEffect(() => {
      if (groupId && currentGroup?.length) {
        const options = currentGroup.map((element) => {
          return {
            cell: {
              value: {
                value: element.campaignName,
                link: {
                  pathname: `/mystores/${marketplace}/marketingCampaignAdGroups`,
                  search: `?store=${encodeURI(mid)}`,
                  state: {
                    campaignId: element.campaignId,
                    campaignName: element.campaignName,
                  },
                },
              },
            },
            campaignId: String(element.campaignId),
            selected: true,
          };
        });
        setCampaignName(currentGroup[0] && currentGroup[0].groupName);
        setSelectedOptions(options as any);
      }
    }, [currentGroup]);

    const changeCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
      const campaignName = e.target.value;
      let matchingGroupNameExists = campaignCustomGroups?.find(
        (customCampaignGroup) =>
          customCampaignGroup.groupName.toLowerCase() ===
          campaignName.toLowerCase()
      );

      if (currentGroup && currentGroup[0]?.groupName === campaignName) {
        matchingGroupNameExists = currentGroup[0];
      }

      if (matchingGroupNameExists) {
        setGroupNameValidation("campaignCustomGroups.groupNameValidation");
      } else {
        setGroupNameValidation("");
      }
      setCampaignName(campaignName);
    };

    return (
      <ModalPanel
        open={openModal}
        setOpen={setOpenModal}
        title={
          groupId
            ? t("campaignCustomGroups.editGroup")
            : t("campaignCustomGroups.mainTitle")
        }
        content={
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    error={Boolean(groupNameValidation)}
                    helperText={t(groupNameValidation)}
                    label={t("campaignCustomGroups.campaignNameLabel")}
                    required
                    onChange={changeCampaignName}
                    value={campaignName}
                    disabled={campaignCustomGroupsFetching}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <SearchableMultiSelect
                    title={t("campaignCustomGroups.searchCampaigns")}
                    searchLimitText={t(
                      "campaignCustomGroups.searchLimitMessage",
                      {
                        pageSize: 100,
                      }
                    )}
                    setSearchText={setSearchText}
                    count={selectedOptions.length}
                    options={options as Option[]}
                    searching={campaignsLoading}
                    optionComponent={
                      LinkCell as (props: any) => React.ReactElement
                    }
                    changeSelectOption={changeSelectOption as any}
                    selectAllOptions={selectAllOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <RaisedButton
                  type="button"
                  color="primary"
                  onClick={dispatchCreateGroup}
                  disabled={
                    Boolean(groupNameValidation) ||
                    campaignCustomGroupsFetching ||
                    campaignsLoading ||
                    !selectedOptions.length
                  }
                >
                  {campaignCustomGroupsFetching ? (
                    <LoadingIndicator size={20} />
                  ) : (
                    t("generic.save")
                  )}
                </RaisedButton>
              </Grid>
            </Grid>
          </Form>
        }
      />
    );
  }
);

export default CampaignCustomGroupsModal;
