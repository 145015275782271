import {
  Bar,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { CHART_TITLES } from "../chartUtils/chartUtils";
import { DATETIME_PERIODS } from "../../../store/utils/dateTimeUtils";
import { DataKey } from "recharts/types/util/types";
import MultiChartTooltip from "./multiChartTooltip";
import MutltiChartLegend from "./multiChartLegend";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useLayoutProps } from "../chartUtils/chartComponents";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface ChartDataDefinition {
  type: string;
  dataKey: DataKey<string>;
  color: string;
}

interface MultiComposedChartProps {
  chartData: {
    startTime: number;
    endTime: number;
    sales: string | number;
    shipping: string | number;
    advertising: string | number;
    refunds: string | number;
    profit: string | number;
  }[];
  dataDefinition: ChartDataDefinition[];
  currentPeriod: DATETIME_PERIODS;
  currency: string;
  fromDate: number;
  toDate: number;
  report?: boolean;
  timezone: string;
}

const Y_AXIS_WIDTH = 80;

const getXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  isCustomPeriodSameDay = false,
  ticks: [number, number] | undefined,
  timezone: string
): XAxisProps => {
  const tickFormatter =
    currentPeriod === DATETIME_PERIODS.DAY || isCustomPeriodSameDay
      ? (tick: number) => moment.unix(tick).tz(timezone).format("HH")
      : (tick: number) => moment.unix(tick).tz(timezone).format("MMMM Do");
  return {
    dataKey: "startTime",
    axisLine: false,
    type: "category",
    interval: "preserveStartEnd",
    tickLine: false,
    domain: ["dataMin", "dataMax"],
    padding: { left: 30, right: 30 },
    tick: { fontSize: 14 },
    tickFormatter,
    ...(ticks ? { ticks } : {}),
  };
};

const MultiComposedChart = memo<MultiComposedChartProps>(
  function MultiComposedChart({
    chartData = [],
    dataDefinition = [],
    currentPeriod,
    currency,
    fromDate,
    toDate,
    report,
    timezone,
  }) {
    const { height, margin } = useLayoutProps("comboChart", report);
    const { t } = useTranslation();
    const theme = useTheme();

    const isCustomPeriodSameDay =
      Boolean(fromDate) &&
      Boolean(toDate) &&
      moment
        .unix(fromDate)
        .tz(timezone)
        .isSame(moment.unix(toDate).tz(timezone), "day");

    const XAxisProps =
      chartData &&
      getXAxisProps(
        currentPeriod,
        isCustomPeriodSameDay,
        chartData.length
          ? [chartData[0].startTime, chartData[chartData.length - 1].startTime]
          : undefined,
        timezone
      );

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box pl={3} pt={2}>
            <Typography variant="h6">
              {t(CHART_TITLES[currentPeriod])}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={height}>
            <ComposedChart data={chartData} margin={margin}>
              <XAxis {...XAxisProps} />
              <ReferenceLine y={0} strokeWidth={2} />
              <YAxis
                label={{
                  value: `${t("chartLegend.profit")} (${currency})`,
                  position: "top",
                  offset: 15,
                  dx: 15,
                  fontSize: 12,
                }}
                tickLine={false}
                tick={{ fontSize: 12 }}
                tickFormatter={(tick) => numberWithCommas(parseInt(tick))}
                width={Y_AXIS_WIDTH}
              />
              <Legend
                align="right"
                layout="vertical"
                verticalAlign="middle"
                content={<MutltiChartLegend legendItems={dataDefinition} />}
              />
              <Tooltip
                content={
                  <MultiChartTooltip
                    currentPeriod={currentPeriod}
                    items={dataDefinition}
                    currency={currency}
                    timezone={timezone}
                  />
                }
              />
              {dataDefinition.map((definition, i) => {
                if (definition.type === "line") {
                  return (
                    <Line
                      key={`${definition.dataKey}-${i}`}
                      dataKey={definition.dataKey}
                      dot={false}
                      strokeWidth={2}
                      stroke={definition.color}
                    />
                  );
                } else {
                  return (
                    <Bar
                      key={`${definition.dataKey}-${i}`}
                      dataKey={definition.dataKey}
                      fill={definition.color}
                    >
                      {chartData &&
                        chartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              (entry[
                                definition.dataKey as keyof MultiComposedChartProps["chartData"][number]
                              ] as number) > 0
                                ? theme.palette.success.main
                                : theme.palette.error.main
                            }
                          />
                        ))}
                    </Bar>
                  );
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    );
  }
);

export default MultiComposedChart;
