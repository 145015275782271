import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import React, { ReactChild, useEffect } from "react";
import { drawerWidthClosed, drawerWidthOpen } from "./sideNavConstants";

import { ContentWrapper } from "./drawerContentWrapper";
import { SecondaryToolbar } from "../myStoresToolbar/secondaryToolbar";
import { SelectedItem } from "./sideMenu";
import { SideNavDrawer } from "./sideNavDrawer";
import useWindowSize from "../../../hooks/useWindowSize";

const PAGE_BLOCK_WIDTH = 1600;

interface SimplifiedNavigationProps {
  content: ReactChild;
  selectedItem: SelectedItem;
  includeFilters?: boolean;
}

export const SimplifiedNavigation = ({
  content,
  selectedItem,
  includeFilters,
}: SimplifiedNavigationProps) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!smDown);
  useEffect(() => setOpen(!smDown), [smDown]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  /** Requirement to keep the mystores page in line with the app toolbar */
  const pageWidth = useWindowSize().width;
  const marginRight = (pageWidth! - PAGE_BLOCK_WIDTH) / 2;
  const drawerWidth = open ? drawerWidthOpen : drawerWidthClosed;
  const marginLeft = marginRight - drawerWidth;
  const marginLeftPx = marginLeft < 0 ? `0px` : `${marginLeft}px`;
  const marginRightPx = marginRight < 0 ? `0px` : `${marginRight}px`;

  return (
    <>
      <SideNavDrawer open={open} setOpen={setOpen} />
      <ContentWrapper {...{ container: true, item: true, open }}>
        <SecondaryToolbar
          {...{
            open,
            selectedItem,
            toggleDrawer,
            hideDateSelector: true,
            hideStoreSelector: true,
            hideCurrencySelector: true,
            includeFilters,
            marginLeftPx,
            marginRightPx,
          }}
        />
        <Box
          width={`calc(100% - ${marginRightPx} - ${marginLeftPx})`}
          mr={marginRightPx}
          ml={marginLeftPx}
        >
          {content}
        </Box>
      </ContentWrapper>
    </>
  );
};
