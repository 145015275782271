import Bold from "~/components/typography/bold";
import Cell from "./cell";
import React from "react";
import SubcategoryCell from "./subcategoryCell";
import { Typography } from "@material-ui/core";

interface ProfitAndLossExpandableCellProps {
  cellKey: string;
  breakdown?: (string | number | undefined)[];
  value: number | string;
  bold?: boolean;
  expandRows?: boolean;
}
export const ProfitAndLossExpandableCell = ({
  value,
  breakdown,
  cellKey,
  bold,
  expandRows,
}: ProfitAndLossExpandableCellProps) => {
  const TextVariant = bold ? Bold : Typography;

  return (
    <>
      <Cell key={cellKey}>
        <TextVariant variant="body1">{value}</TextVariant>
      </Cell>
      {breakdown?.length
        ? breakdown?.map((b, index) => (
            <SubcategoryCell
              key={`${cellKey}-${index}`}
              $visible={expandRows}
              $heavyBorder={index >= breakdown.length - 1}
            >
              <TextVariant variant="body1">{b}</TextVariant>
            </SubcategoryCell>
          ))
        : null}
    </>
  );
};
