import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import {
  Box,
  CircularProgress,
  Grid,
  PropTypes,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Cancel, Check, CheckCircle, Undo } from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchNotifications,
  markNotificationsAsRead,
  updateNotificationStatus,
} from "~/store/notifications.redux";

import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import FilterAppliedHint from "~/components/adTable/filterAppliedHint";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { MarketplaceAndCountryCell } from "~/components/table/cells/marketplaceAndCountryCell";
import NotificationDetails from "./notificationDetails";
import { NotificationStatus } from "mm-utils-frontend";
import { NotificationTypeCell } from "~/components/table/cells/notificationTypeCell";
import { NotificationsListProps } from "./notificationOverview";
import { NotificationsRow } from "~/typedef/store";
import Panel from "~/components/panel/panel";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { Variant } from "@material-ui/core/styles/createTypography";
import { getShopName } from "~/utils/marketplaceUtils";
import i18next from "i18next";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { memo } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import usePrevious from "~/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ResolvedIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.palette.success.main};
`;

const UnresolvedIcon = styled(Cancel)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const NotificationsList = memo(
  ({
    user,
    pageSize,
    actions,
    searchText,
    status,
    notificationType,
    messageId,
    autoResolve,
  }: NotificationsListProps) => {
    const { t } = useTranslation();
    const notifications = useTypedSelector(
      (state) => state?.notifications?.all?.data || []
    );
    const params = useTypedSelector(
      (state) => state?.notifications?.all?.params
    );
    const loading = useTypedSelector(
      (state) => state?.notifications?.all?.fetching || false
    );
    const notificationsCount = useTypedSelector(
      (state) => state?.notifications?.all?.count || 0
    );
    const triggerRefetch = useTypedSelector(
      (state) => state?.notifications?.all?.triggerRefetch || false
    );
    const allStores = useTypedSelector(
      (state) => state?.mystore?.allStores?.stores
    );
    const filteredStores = useTypedSelector(
      (state) => state?.mystore?.filteredStores?.stores
    );
    const [messageIdFilter, setMessageIdFilter] = useState(messageId);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [notification, setSelectedNotification] =
      useState<NotificationsRow | null>(null);
    const previousNotifications = usePrevious(notifications);

    const dispatch = useDispatch();

    const fetchData = useCallback(
      ({ pageSize, pageIndex }) => {
        dispatch(
          fetchNotifications(
            {
              searchText,
              status,
              notificationType,
              messageIdFilter,
              pageIndex,
              pageSize,
              mids: filteredStores.map((store) => store.merchantId),
            },
            params,
            triggerRefetch
          )
        );
      },
      [
        user,
        searchText,
        status,
        notificationType,
        messageIdFilter,
        filteredStores,
        params,
        triggerRefetch,
      ]
    );

    const handleListingSelect = useCallback(
      (n) => {
        setSelectedNotification(n);
        setDrawerOpen(true);
      },
      [setDrawerOpen]
    );

    const updateStatus = useCallback(
      ({ messageId, status }) => {
        dispatch(
          updateNotificationStatus({
            messageId,
            status,
            email: user.email,
          })
        );
      },
      [user]
    );

    useEffect(() => {
      if (autoResolve && messageId) {
        updateStatus({ messageId, status: NotificationStatus.RESOLVED });
      }
    }, [autoResolve, messageId]);

    useEffect(() => {
      if (!isEqual(previousNotifications, notifications)) {
        const newNotificationIds: string[] =
          notifications
            ?.filter((n) => n.status === NotificationStatus.NEW)
            ?.map((n) => n._id) || [];

        if (!isEmpty(newNotificationIds)) {
          dispatch(
            markNotificationsAsRead({
              ids: newNotificationIds,
              email: user.email,
            })
          );
        }
      }
    }, [previousNotifications, notifications]);

    const columns = useMemo(
      () => [
        {
          Header: t("notifications.type"),
          id: "notificationType",
          accessor: (
            row: NotificationsRow
          ): { row: NotificationsRow; handleListingSelect: Function } => ({
            row,
            handleListingSelect,
          }),
          Cell: NotificationTypeCell,
          isVisible: true,
        },
        {
          Header: t("notifications.date"),
          id: "createdAt",
          accessor: (row: NotificationsRow) =>
            moment(row.createdAt).locale(i18next.language).format("L"),
          Cell: TextCell,
          isVisible: true,
        },
        {
          Header: t("notifications.store"),
          id: "store",
          accessor: (row: NotificationsRow) => ({
            market: row.marketplaceType,
            marketplaceSubtype: row.marketplaceSubtype,
            countryCode: row.marketplaceCountry,
            mid: row.mid,
          }),
          customWidth: 175,
          Cell: (props: any) => (
            <Grid container spacing={2}>
              <Grid item>
                <MarketplaceAndCountryCell {...props} />
              </Grid>
              <Grid item>
                <Typography variant="body2" noWrap>
                  {getShopName(
                    allStores,
                    props?.row?.original?.marketplaceType,
                    props?.row?.original?.mid
                  )}
                </Typography>
              </Grid>
            </Grid>
          ),
          isVisible: true,
        },
        {
          Header: t("notifications.product"),
          id: "product",
          accessor: (row: NotificationsRow) => ({
            value: row.subject,
            secondRowValue: `${
              row.marketplaceType === "amazon" ? "ASIN" : "LISTING ID"
            }: ${row.productSku}`,
            image: row.imageUrl,
            link: row.linkUrl,
            target: "_blank",
          }),
          customWidth: 250,
          Cell: (props: any) =>
            props?.row?.original?.isProduct ? (
              <LinkAndImageCell {...props} colorVariant="external" />
            ) : (
              <Typography variant="body2" noWrap>
                -
              </Typography>
            ),
          isVisible: true,
        },
        {
          Header: t("notifications.status"),
          id: "status",
          accessor: "status",
          Cell: ({ value }: { value: string }) => (
            <Tooltip
              title={
                (value === NotificationStatus.RESOLVED
                  ? t(`notificationStatus.resolved`)
                  : t(`notificationStatus.read`)) as string
              }
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                {value === NotificationStatus.RESOLVED ? (
                  <ResolvedIcon />
                ) : (
                  <UnresolvedIcon />
                )}
              </Box>
            </Tooltip>
          ),
          isVisible: true,
        },
        {
          Header: t("notifications.actions"),
          accessor: (row: NotificationsRow) => row,
          id: "actions",
          Cell: (cell: ReactTableCell) => {
            const markAsResolvedAction = {
              text: t("notifications.markAsResolved"),
              icon: Check,
              textVariant: "button" as Variant,
              action: () => {
                updateStatus({
                  messageId: cell.value.messageId,
                  status: NotificationStatus.RESOLVED,
                });
              },
            };

            const markAsUnresolvedAction = {
              text: t("notifications.markAsUnresolved"),
              icon: Undo,
              textVariant: "button" as Variant,
              buttonVariant: "text" as "text",
              color: "disabled" as PropTypes.Color,
              action: () => {
                updateStatus({
                  messageId: cell.value.messageId,
                  status: NotificationStatus.READ,
                });
              },
            };

            return cell.value.fetching ? (
              <CircularProgress size="2rem" />
            ) : cell.value.status === NotificationStatus.RESOLVED ? (
              <ActionCell actions={[markAsUnresolvedAction]} />
            ) : (
              <ActionCell actions={[markAsResolvedAction]} />
            );
          },
          isVisible: true,
          disableSortBy: true,
        },
        {
          Header: t("notifications.resolvedBy"),
          id: "resolvedBy",
          accessor: (row: NotificationsRow) => row,
          Cell: (cell: ReactTableCell) => {
            return cell.value.status === NotificationStatus.RESOLVED ? (
              <Grid container spacing={2} alignItems={"center"}>
                <Typography variant="caption">
                  {` ${cell.value.updatedBy}`}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {` ${moment(cell.value.updatedAt)
                    .locale(i18next.language)
                    .format("ll LT")}`}
                </Typography>
              </Grid>
            ) : (
              <Typography variant="body2">-</Typography>
            );
          },
          isVisible: true,
          disableSortBy: true,
        },
      ],
      [user, allStores]
    );

    return (
      <DrawerPanel
        containerId="notifications-drawer-container"
        drawerWidth="900px"
        open={drawerOpen}
        setOpen={setDrawerOpen}
        sidePanel={
          <NotificationDetails
            notification={notification}
            setDrawerOpen={setDrawerOpen}
          />
        }
        mainPanel={
          <Panel
            id="all-notifications-table"
            title={t("notifications.allNotifications")}
            content={
              <>
                {messageIdFilter && (
                  <FilterAppliedHint
                    text={t("notifications.filterHint.text")}
                    actionText={t("notifications.filterHint.actionText")}
                    action={() => setMessageIdFilter(null)}
                  />
                )}
                <Table
                  {...{
                    columns,
                    data: notifications,
                    fetchData,
                    loading,
                    pagination: true,
                    pageCount: Math.ceil((notificationsCount || 0) / pageSize),
                    pageSize,
                  }}
                  // remount the table if one of those filters, passed by the parent, change, so that the pagination params refresh
                  // correcty internally to the Table component.
                  key={`${notificationType}-${status}-${messageIdFilter}`}
                />
              </>
            }
            actions={actions}
          />
        }
      />
    );
  }
);
