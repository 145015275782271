import React, { memo } from "react";

import FilterSelect from "~/components/select/filterSelect";
import { useTranslation } from "react-i18next";

interface PortfolioMenuProps {
  changePortfolios: (portfolios: string[]) => void;
  currentPortfolioNames: string[];
  portfolioNames: string[];
  maxWidth?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}
const PortfolioMenu = memo(
  ({
    changePortfolios,
    currentPortfolioNames,
    portfolioNames,
    maxWidth,
    fullWidth,
    disabled,
  }: PortfolioMenuProps) => {
    const { t } = useTranslation();
    return (
      <FilterSelect
        title={t(`dashboard.selectPortfoliosTitle`)}
        options={portfolioNames}
        selectedOptions={currentPortfolioNames}
        handleSelect={changePortfolios}
        filterTitle={t("dashboard.selectPortfoliosFilterTitle")}
        condensed
        forceLowercase
        isFullWidth={fullWidth || false}
        maxWidth={maxWidth || 150}
        isMarketplace={false}
        disabled={disabled ?? false}
        searchable={false}
        isCountry={false}
        isDisplayIcons={false}
      />
    );
  }
);

export default PortfolioMenu;
