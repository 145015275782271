import React from "react";
import get from "lodash/get";
import { useLocation } from "react-router-dom";

export default function () {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(get(location, "search")),
    [location]
  );
}
