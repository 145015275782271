import {
  Grid,
  GridJustification,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo } from "react";

import StarRatings from "react-star-ratings";
import styled from "styled-components";

const StarRatingsContainer = styled.div`
  display: inline-flex;
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 4px 2px 0;
`;

const PercentageText = styled(Typography)`
  width: 35px;
  font-size: ${({ theme }) => `${theme.typography.linkAndImageCell.fontSize}`};
  font-weight: ${({ theme }) =>
    `${theme.typography.linkAndImageCell.fontWeight}`};
`;

const NUM_STARS = 5;

interface StarRatingCellProps {
  cell: {
    value: any;
    starRating?: number;
    justify?: GridJustification;
    row?: {
      original?: {
        isAmazon?: boolean;
      };
    };
  };
}

/**
 * The normal use case for this component only has the prop cell.value
 * being passed in with a value like 80, standing for 80%.
 * That gets converted to a rating of 4, which is passed into StarRatings,
 * which duly renders 4/5 stars.
 *
 * However, the walmart listing quality api returns us both star ratings (integer out of 5)
 * and average score (out of 100). For this use case, we pass in two props:
 * cell.value and cell.starRating. In this case, we still use cell.value for the PercentageText,
 * but we use cell.starRating directly for the rating prop to the StarRatings component.
 * The starRating prop must be an integer from 1 to 5.
 */
export const StarRatingCell = memo<StarRatingCellProps>(
  function StarRatingCell({ cell }) {
    const theme = useTheme();
    const value = parseFloat(cell.value) || 0;
    let rating;
    if (typeof cell.starRating === "number") {
      rating = cell.starRating;
    } else {
      rating = (NUM_STARS * value) / 100;
    }
    const isAmazon = cell?.row?.original?.isAmazon;

    return (
      <Grid
        container
        alignItems="center"
        justifyContent={cell.justify ? cell.justify : "center"}
      >
        {!isAmazon && (
          <>
            <StarRatingsContainer>
              <StarRatings
                rating={rating}
                starRatedColor={theme.palette.primary.main}
                starDimension="12px"
                starSpacing="0"
                numberOfStars={NUM_STARS}
                name="rating"
              />
            </StarRatingsContainer>
            <PercentageText as="div">{value.toFixed(0)}%</PercentageText>
          </>
        )}
      </Grid>
    );
  }
);
