import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchReviewOrders,
  sendManualReviewRequest,
} from "../../../store/mystore/review.redux";

import { Box } from "@material-ui/core";
import { CountryLogo } from "../../../img/flags/country_logos";
import DownloadCsv from "~/components/adTable/downloadCsv";
import FilterSelect from "~/components/select/filterSelect";
import FulfilledByCell from "~/components/table/cells/fulfilledByCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import MessageAlert from "~/components/alert/messageAlert";
import Panel from "~/components/panel/panel";
import RequestStatusCell from "~/components/table/cells/requestStatusCell";
import ReviewStatusCell from "~/components/table/cells/reviewStatusCell";
import SearchFilter from "~/components/adTable/searchFilter";
import SmallButton from "~/components/buttons/smallButton";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import i18next from "i18next";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;
const MIN_DAYS = 5;

const StyledOrderFilter = styled.span`
  margin-right: 1rem;
  min-width: 150px;
`;

const StyledButton = styled(SmallButton)`
  margin-right: 1rem;
`;

interface ReviewOrder {
  id: string;
  orderId: string;
  sourceSystemId: string;
  orderCountry: string;
  orderDate: Date;
  orderCreatedAt: Date;
  scheduledFor: Date;
  dateToSend: Date;
  quantity: number;
  linkUrl: string;
  requestStatus: string;
  shippingStatus: string;
  eligibility: boolean;
  orderDetails: Record<string, any>;
}

interface ReviewCellProps {
  id: string;
  orderId: string;
  sourceSystemId: string;
  orderCountry: string;
  orderCreatedAt: Date;
  quantity: number;
  link: string;
  canSendReviewRequest: boolean;
  details: Record<string, any>;
}

interface ReviewTableProps {
  mid?: string;
}

const ReviewTable = memo<ReviewTableProps>(function ReviewTable({ mid }) {
  const { t } = useTranslation();

  const ORDER_STATUS_OPTIONS = [
    "Shipped",
    "Refunded",
    "Cancelled",
    "Domestic",
    "International",
  ];

  const REVIEW_STATUS_OPTIONS = [
    {
      value: "",
      label: t("reviewManagement.reviewStatus"),
    },
    {
      value: "Excluded",
      label: t("reviewManagement.excluded"),
    },

    { value: "Scheduled", label: t("reviewManagement.scheduled") },
    { value: "Sent", label: t("reviewManagement.solicited") },
    { value: "Not Sent", label: t("reviewManagement.notSolicited") },
  ];

  const userInfo = useTypedSelector((state) => state.user);
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const marketplaceName = useMarketplace();
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const reviewOrders = useTypedSelector((state) => state.review.orders);
  const reviewSettings = useTypedSelector((state) => state.review.settings);
  const triggerRefetch = useTypedSelector(
    (state) => state.review.triggerRefetch
  );
  const loading = useTypedSelector((state) =>
    get(state.review.orders, "fetching")
  );
  const [orderStatus, setOrderStatus] = useState<string[]>([]);
  const [reviewStatusFilter, setReviewStatusFilter] = useState<string>("");
  const [sortOptions, setSortOptions] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);

  const dispatch = useDispatch();

  const dispatchSendManualReviewRequest = useCallback(
    (orders) => {
      dispatch(
        sendManualReviewRequest({
          user: userInfo,
          amazonSellerId: store?.sourceSystemId,
          countryCode: store?.marketplaceCountry,
          orders,
          t,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store?.sourceSystemId, store?.marketplaceCountry, userInfo]
  );

  const formatDate = (date: Date, orderDate?: Date) => {
    if (date) {
      if (moment(date).startOf("d").isSame(moment().startOf("d"))) {
        if (
          moment(orderDate)
            .startOf("d")
            .isAfter(moment().startOf("d").subtract(MIN_DAYS, "d"))
        ) {
          return moment()
            .startOf("d")
            .add(MIN_DAYS, "d")
            .locale(i18next.language)
            .format("L");
        }
      }
      return moment(date).locale(i18next.language).format("L");
    }
    return "-";
  };

  const columns = useMemo(
    () => [
      {
        Header: t("reviewManagement.status.orderDateColumn"),
        id: "order_date",
        accessor: (row: ReviewOrder): string => formatDate(row.orderDate),
        isVisible: true,
      },
      {
        Header: t("reviewManagement.status.shippingCountryColumn"),
        id: "shipping_country",
        align: "center",
        accessor: "shippingCountry",
        Cell: (props: TableCellProp<string>) => (
          <CountryLogo code={props.cell.value} />
        ),
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.orderNumberColumn"),
        id: "order_id",
        accessor: (row: ReviewOrder): ReviewCellProps => ({
          id: row.id,
          orderId: row.orderId,
          sourceSystemId: row.sourceSystemId,
          orderCountry: row.orderCountry,
          orderCreatedAt: row.orderDate,
          quantity: row.quantity,
          link: row.linkUrl,
          canSendReviewRequest:
            row.requestStatus !== "Sent" &&
            row.requestStatus !== "Expired" &&
            row.shippingStatus !== "Not Sent" &&
            row.eligibility,
          details: row.orderDetails,
        }),
        Cell: (props: TableCellProp<ReviewCellProps>) => (
          <ReviewStatusCell
            {...props}
            onSendReviewRequestClick={dispatchSendManualReviewRequest}
          />
        ),
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.fulfilledByColumn"),
        id: "fulfilled_by",
        align: "center",
        accessor: "fulfilledBy",
        Cell: FulfilledByCell,
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.requestStatusColumn"),
        id: "request_status",
        align: "center",
        accessor: "requestStatus",
        Cell: (props: {
          cell: TableCellProp<string>;
          row: {
            original: {
              isFBM: boolean;
              isFBA: boolean;
              isRefunded: boolean;
              shippingStatus: string;
            };
          };
        }) => <RequestStatusCell {...props} reviewSettings={reviewSettings} />,
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.scheduledForColumn"),
        id: "scheduled_for",
        align: "center",
        accessor: (row: ReviewOrder) =>
          formatDate(row.scheduledFor, row.orderDate),
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.orderStatusColumn"),
        id: "order_status",
        align: "center",
        accessor: "orderStatus",
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.shippingColumn"),
        id: "shipping_status",
        align: "center",
        accessor: "shippingStatus",
        disableSortBy: true,
      },
      {
        Header: t("reviewManagement.status.dateToSendColumn"),
        id: "date_to_send",
        align: "center",
        accessor: (row: ReviewOrder) => formatDate(row.dateToSend),
        isVisible: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mid, currentRange, currentPeriod]
  );

  const selectedOptions = (value: string[]) => {
    setOrderStatus(value);
  };

  const handleReviewStatusFilter = (value: string) => {
    setReviewStatusFilter(value);
  };

  const [myColumns, setMyColumns] = useState<Column[]>(columns);
  const [searchText, setSearchText] = useState("");

  const dispatchFetchReviewOrders = useCallback(
    ({ pageIndex, pageSize, sortBy, format }) => {
      setSortOptions(sortBy);
      dispatch(
        fetchReviewOrders({
          user: userInfo,
          currentRange,
          mid,
          pageSize,
          pageIndex,
          sortKey: sortBy && sortBy.length ? sortBy[0].id : "order_date",
          sortOrder:
            sortBy && sortBy.length
              ? sortBy[0].desc
                ? "desc"
                : "asc"
              : "desc",
          searchText,
          format,
          orderStatus,
          reviewStatusFilter,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRange, mid, searchText, orderStatus, reviewStatusFilter]
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchReviewOrders({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, mid]);

  const updateSelectedRows = (rows: ReviewOrder[]) => {
    if (reviewOrders && reviewOrders.rows) {
      const orderDetails = rows.map((row) => {
        const { orderId, orderDate, sourceSystemId, orderCountry } = row;
        return {
          orderId,
          orderCreatedAt: orderDate,
          sourceSystemId,
          orderCountry,
        };
      });
      setSelectedOrders(orderDetails);
    }
  };

  const sendSelectedRows = () => {
    dispatchSendManualReviewRequest(selectedOrders);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchReviewOrders({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        sortBy: sortOptions,
      });
    };

    if (triggerRefetch && !reviewOrders.fetching) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchFetchReviewOrders, reviewOrders.fetching, triggerRefetch]);

  const renderReviewSubComponent = ({ row: { values } }: any) =>
    values.order_id.details &&
    values.order_id.details.map(
      (item: {
        title: string;
        productSku: string;
        sku: string;
        imageUrl: string;
        linkUrl: string;
      }) => (
        <tr key={item.title}>
          <td />
          <td />
          <td />
          <Box p={1} pl={2}>
            <LinkAndImageCell
              cell={{
                value: {
                  value: item.title,
                  secondRowValue: `ASIN: ${item.productSku} | SKU: ${item.sku}`,
                  image: item.imageUrl,
                  link: item.linkUrl,
                  target: "_blank",
                },
              }}
            />
          </Box>
        </tr>
      )
    );

  return (
    <Panel
      id="widget-review-table"
      title={t(`myStoresWidget.reviewStatus`)}
      content={
        <>
          {reviewOrders.fetchError && (
            <MessageAlert message={reviewOrders.fetchError} />
          )}
          <Table
            columns={myColumns}
            data={get(reviewOrders, "rows", [])}
            fetchData={dispatchFetchReviewOrders}
            loading={loading}
            sorting={true}
            pagination={true}
            pageCount={Math.ceil(get(reviewOrders, "count", 0) / PAGE_SIZE)}
            pageSize={PAGE_SIZE}
            renderRowSubComponent={renderReviewSubComponent}
            hasCheckboxSelect={true}
            onRowSelect={updateSelectedRows}
          />
        </>
      }
      actions={
        <>
          <StyledOrderFilter>
            <TableFilter
              options={REVIEW_STATUS_OPTIONS}
              currentValue={reviewStatusFilter}
              handleChange={handleReviewStatusFilter}
            />
          </StyledOrderFilter>
          <StyledOrderFilter>
            <FilterSelect
              options={ORDER_STATUS_OPTIONS}
              title={t("")}
              filterTitle={t("reviewManagement.status.orderStatusColumn")}
              selectedOptions={orderStatus}
              handleSelect={selectedOptions}
              isDisplayIcons={false}
              condensed
              isFullWidth
              isMarketplace={false}
              isCountry={false}
              disabled={false}
              searchable={false}
            />
          </StyledOrderFilter>
          <StyledButton
            color="info"
            onClick={sendSelectedRows}
            disabled={!(selectedOrders.length > 0)}
          >
            {t("reviewManagement.status.requestReview")}
          </StyledButton>
          <SearchFilter setSearchText={setSearchText} />
          <ColumnSelect columns={myColumns} setColumns={setMyColumns} />
          <DownloadCsv
            entityType=""
            downloadUrl={"api/myStores/review/orders"}
            params={{
              user: {
                _id: userInfo._id,
              },
              mid,
              currentPeriod,
              ...currentRange,
              shopName: store?.storeName,
              marketplaceName,
              countryCode: store?.marketplaceCountry,
            }}
          />
        </>
      }
    />
  );
});

export default ReviewTable;
