import { Range } from "~/typedef/store";
import { api } from "../apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface SalesByCountryArgs {
  mid: string;
  currentRange: Range;
  includeTax: boolean;
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortOrder: string;
}

interface SalesByCountryRecord {
  country: string;
  quantity: number;
  orderCount: number;
  totalSales: number;
}

export interface SalesByCountryRow {
  current: SalesByCountryRecord;
  prior: SalesByCountryRecord;
}

interface SalesByCountryResponse {
  rows: SalesByCountryRow[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesByCountry: build.query<SalesByCountryResponse, SalesByCountryArgs>({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/salesByCountry`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("SalesByCountry"),
    }),
  }),
});

export const { useSalesByCountryQuery } = extendedApiSlice;
