import React, { memo } from "react";
import { connect, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import ItemSpecificsComponent from "~/modules/itemSpecifics/specificsRecommendations";
import PageBlock from "~/components/containers/sideNavPageBlock";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useMarketplace } from "~/utils/navigationUtils";

const ItemSpecifics = memo(({ user }) => {
  const marketplace = useMarketplace();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ItemSpecificsComponent
            market={marketplace}
            mid={store.merchantId}
            countryCode={store.marketplaceCountry}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

ItemSpecifics.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ItemSpecifics);
