import React, { useState } from "react";

import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import Box from "@material-ui/core/Box";
import { CountryLogo } from "../../img/flags/country_logos";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import Medium from "~/components/typography/medium";
import Radio from "@material-ui/core/Radio";
import RaisedButton from "~/components/buttons/raisedButton";
import Typography from "@material-ui/core/Typography";
import { marketplace } from "mm-amazon-common/dist/mwsUtils";
import styled from "styled-components";
import { updateAmazonAdvertisingAdvertiser } from "../../store/connections/connectAmazon.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface Advertiser {
  advertiserId: string;
  name: string;
  currency: string;
  url: string;
  country: string;
  timezone: string;
}

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
  padding-top: 2rem;
`;

const SelectAdvertiser = ({
  mid,
  profileId,
  entityId,
  marketplaceType,
  countryCode,
  onSuccess,
  selectAdvertiser,
}: {
  mid: string;
  profileId: string;
  entityId: string;
  countryCode: string;
  marketplaceType: string;
  onSuccess: () => void;
  selectAdvertiser: (mid: string, advertiserId: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const marketing = useTypedSelector((state) => state.marketing);
  const [loading, setLoading] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser | null>(null);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (selectedAdvertiser?.advertiserId) {
        const marketplaceId = marketplace(
          countryCode as AmazonCountry
        ).marketplaceId;
        const mid = `${entityId}_${selectedAdvertiser.advertiserId} @ ${marketplaceId}`;
        await dispatch(
          updateAmazonAdvertisingAdvertiser(
            mid,
            countryCode,
            selectedAdvertiser.advertiserId,
            profileId,
            entityId,
            marketplaceType,
            "advertising",
            true
          )
        );
        selectAdvertiser(mid, selectedAdvertiser.advertiserId);
        setLoading(false);
        onSuccess();
      } else {
        throw new Error(
          `Invalid advertising advertiser: ${selectedAdvertiser}`
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      throw err;
    }
  };

  const renderAvailableAdvertisers = (advertisers: Advertiser[]) =>
    advertisers.map((advertiser, index) => (
      <Grid
        key={index}
        container
        item
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Radio
          checked={
            `${selectedAdvertiser?.advertiserId}` ===
            `${advertiser.advertiserId}`
          }
          name={`${advertiser.advertiserId}`}
          color="primary"
          onChange={(e) => setSelectedAdvertiser(advertiser)}
        />
        <Grid item>
          <CountryLogo code={advertiser.country} />
        </Grid>
        <Grid item>
          <MarketplaceIcon market={"amazon"} />
        </Grid>
        <Grid item>
          <Typography variant="body2" noWrap>
            {advertiser.advertiserId} (&quot;{advertiser.name}
            &quot;)
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="amazon-selectadvertisingadvertiser-wrapper">
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Medium align="center">
                {t("connectWizard.amazonAdvertisingAdvertiserTitle")}
              </Medium>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="flex-start">
              {renderAvailableAdvertisers(marketing.advertisers || [])}
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12} alignItems="center" justifyContent="center">
                <Box pt={2}>
                  <RaisedButton
                    disabled={!selectedAdvertiser?.advertiserId}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                  >
                    {t("generic.continueButton")}
                  </RaisedButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default SelectAdvertiser;
