import { Route, Switch } from "react-router-dom";

import CreateGlobalReport from "~/pages/reports/createReport";
import React from "react";
import ReportSentList from "~/pages/reports/reportSentList";
import ReportTemplateList from "~/pages/reports/reportTemplateList";
import ScheduledReportsList from "~/pages/reports/scheduledReportList";

const ReportRoutes = () => {
  return (
    <Switch>
      <Route path="/reports/createReport" component={CreateGlobalReport} />
      <Route path="/reports/reportsSent" component={ReportSentList} />
      <Route
        path="/reports/scheduledReports"
        component={ScheduledReportsList}
      />
      <Route path="/reports/reportTemplates" component={ReportTemplateList} />
    </Switch>
  );
};

export default ReportRoutes;
