import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ProductSummaryPanel from "~/modules/marketing/products";
import { get } from "lodash";
import moment from "moment";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MarketingProducts = memo(function MarketingProducts() {
  const userInfo = useTypedSelector((state) => state.user);

  const store = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const { isAdvertisingAuthorised, isReconnectAdvertising } =
    useAdvertisingConnected(store);

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  return (
    <PageBlock>
      {!isAdvertisingAuthorised ? (
        <MarketingAuthoriseUser isReconnect={isReconnectAdvertising} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ProductSummaryPanel
              currentCurrency={currentCurrency}
              userInfo={userInfo}
              mid={store.merchantId}
              countryCode={store.marketplaceCountry}
              marketplaceType={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
              currentRange={currentRange}
              storeName={store.storeName}
              pageSize={10}
            />
          </Grid>
        </Grid>
      )}
    </PageBlock>
  );
});

export default MarketingProducts;
