import { Typography, useTheme } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CorrectIcon = styled(CheckIcon)`
  align-self: center;
  height: 15px;
  margin: 0 5px;
  color: ${({ theme }) => theme.palette.success.main};
`;

const WrongIcon = styled(CloseIcon)`
  align-self: center;
  height: 15px;
  margin: 0 5px;
  color: ${({ theme }) => theme.palette.error.main};
`;

const PasswordHintContainer = styled.div`
  display: flex;
`;

function PasswordHint({ message }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const label = message[0];
  const isValidated = message[1];

  return (
    <PasswordHintContainer>
      {isValidated ? (
        <CorrectIcon theme={theme} />
      ) : (
        <WrongIcon theme={theme} />
      )}
      <Typography variant="subtitle1">{t(`${label}`)}</Typography>
    </PasswordHintContainer>
  );
}

PasswordHint.propTypes = {
  message: PropTypes.array.isRequired,
};

export default PasswordHint;
