import cookies from "browser-cookies";

export function getPreferredColorScheme() {
  const originalDomain = cookies.get("originalDomain");
  const authSource = cookies.get("authSource");
  if (process.env.TARGET_ENV !== "production") {
    // Temporarily disabling this on production until dark mode is all fixed and ready to deploy
    if (window.matchMedia && !authSource && !originalDomain) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      } else {
        return "light";
      }
    }
  }
  return "light";
}
