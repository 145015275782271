import {
  FETCH_INVENTORY_OVERVIEW,
  FETCH_INVENTORY_OVERVIEW_ERROR,
  FETCH_INVENTORY_OVERVIEW_FETCHING,
} from "./overview.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetching(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_INVENTORY_OVERVIEW_FETCHING,
  });
}

interface FetchInventoryParams {
  user: User;
  pageIndex: number;
  pageSize: number;
  filter: Filter;
  filteredStores: any[];
  timezone: string;
}
export const fetchInventoryStatus = (
  params: FetchInventoryParams,
  oldParams?: FetchInventoryParams
) => async (dispatch: Dispatch) => {
  const { user, pageIndex, pageSize, filter, timezone } = params;
  if (!shouldUseCache(params, oldParams)) {
    await setFetching(dispatch);
    const res = await axios
      .post(`${baseUrl}/api/generic-mws-service/api/inventoryStatus`, {
        timezone,
        pageIndex,
        pageSize,
        filter,
      })
      .catch((e) => {
        const err = get(e, "response.data.errMsg");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        dispatchError(dispatch, FETCH_INVENTORY_OVERVIEW_ERROR);
        return setError(dispatch, msg || statusText, get(err, "status"));
      });
    if (res) {
      if (isHttpResponseValid(res.status)) {
        const data = res.data;
        return await dispatch({
          type: FETCH_INVENTORY_OVERVIEW,
          payload: { ...data, params },
        });
      } else {
        await dispatchError(dispatch, FETCH_INVENTORY_OVERVIEW_ERROR);
        return setError(dispatch, res.data.errMsg, res.status);
      }
    }
    await dispatchError(dispatch, FETCH_INVENTORY_OVERVIEW_ERROR);
    return setError(dispatch);
  }
};
