import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined, SaveAlt } from "@material-ui/icons";
import React, { memo } from "react";

import { isUndefined } from "lodash";
import styled from "styled-components";
import { useCustomLogo } from "~/hooks/useCustomLogo";
import { useTranslation } from "react-i18next";

const ToolbarWrapper = styled(Grid)`
  height: 350px;
  color: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.main
      : theme.palette.secondary.main};
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 220px;
`;

const LogoImg = styled.img`
  display: flex;
  width: 90%;
  height: auto;
  max-width: 250px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const DownloadIcon = styled(SaveAlt)`
  height: 45px;
  display: flex;
  margin-left: 10px;
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

const DownloadButton = styled(Button)`
  justify-content: flex-end;
  width: fit-content;
  color: inherit;
  margin-top: 1.4rem;
`;

const InfoIcon = styled(InfoOutlined)`
  height: 16px;
  width: 16px;
  margin: 0 8px;
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

interface HeaderProps {
  reportName: string;
  preparedBy: string;
  fromDisplayDate: string;
  toDisplayDate: string;
  createdAt: string;
}

const ClientReportToolbar = memo<HeaderProps>(function ClientReportToolbar({
  reportName,
  fromDisplayDate,
  toDisplayDate,
  preparedBy,
  createdAt,
}) {
  const logoImgProps = {
    src: useCustomLogo(),
    alt: "MerchantSpring Logo",
  };

  const { t } = useTranslation();

  return (
    <ToolbarWrapper
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      className="no-print"
    >
      <Grid container item xs={6} justifyContent="flex-start">
        <LogoImg {...logoImgProps} />
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        {/* Fragment wrapper is needed for html within the tooltip title */}
        <Tooltip
          title={
            <>
              {t("clientReport.downloadPDFTooltipTitle")}
              <br />
              <b>{t("clientReport.downloadPDFTooltipWindows")}:</b>{" "}
              {t("clientReport.downloadPDFTooltipWindowsSuggestion")}
              <br />
              <b>{t("clientReport.downloadPDFTooltipMac")}:</b>{" "}
              {t("clientReport.downloadPDFTooltipMacSuggestion")}
              <br />
              <i>{t("clientReport.downloadPDFTooltipNote")}</i>
            </>
          }
        >
          <DownloadButton
            aria-label={t("clientReport.download")}
            className="no-print"
            onClick={() => {
              document.title =
                reportName +
                t("report.preparedBy", {
                  preparedBy,
                  createdAt,
                });
              window.print();
            }}
            size="small"
            startIcon={<DownloadIcon />}
          >
            {t("clientReport.download")}
            <InfoIcon />
          </DownloadButton>
        </Tooltip>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="h1">{reportName}</Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        {" "}
        <Typography variant="h6">
          {t("clientReport.timeRange", {
            fromDate: fromDisplayDate,
            toDate: toDisplayDate,
          })}
        </Typography>
      </Grid>
    </ToolbarWrapper>
  );
});

export default ClientReportToolbar;
