import "draft-js/dist/Draft.css";

import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import {
  COMMENTARY_FILTERS,
  useFetchNotesQuery,
  useSaveNotesMutation,
} from "~/store/mystore/notes.redux";
import { Edit, Save } from "@material-ui/icons";
import {
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import React, { useEffect, useState } from "react";
import {
  ReportingCommentEditIcon,
  ReportingCommentHeader,
  ReportingCommentSaveIcon,
} from "./reportingCommentsDrawer";
import styled, { css } from "styled-components";

import ObjectFilterSelect from "~/components/select/objectFilterSelect";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import RichTextEditor from "~/components/richTextEditor/richTextEditor";
import get from "lodash/get";
import { getMenuItems } from "~/components/toolbars/sideNavigation/sideNavConstants";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const mapMenuItemsToFilter = (menuIds: string[], filter: COMMENTARY_FILTERS) =>
  (
    getMenuItems().find((item) => item.id && menuIds.includes(item.id))
      ?.subMenus || []
  ).map((menu) => ({
    href: menu.link || "",
    filter,
  }));

const NAVIGATION_MAPPING: {
  href: string;
  filter: COMMENTARY_FILTERS;
}[] = [
  {
    href: "/storeoverview",
    filter: COMMENTARY_FILTERS.OVERVIEW,
  },
  ...mapMenuItemsToFilter(["customers"], COMMENTARY_FILTERS.CUSTOMER),
  ...mapMenuItemsToFilter(["sales-insights"], COMMENTARY_FILTERS.SALES),
  ...mapMenuItemsToFilter(
    ["profitability", "profitability-walmart"],
    COMMENTARY_FILTERS.PROFITABILITY
  ),
  ...mapMenuItemsToFilter(["operations"], COMMENTARY_FILTERS.OPERATIONS),
  ...mapMenuItemsToFilter(["marketing"], COMMENTARY_FILTERS.MARKETING),
];

const SingleChannelReportingComments = ({
  setShouldShowBadge,
}: {
  setShouldShowBadge: (shouldShow: boolean) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editorState, setEditorState] = React.useState<
    Record<COMMENTARY_FILTERS, EditorState>
  >(
    Object.values(COMMENTARY_FILTERS).reduce((acc, filter) => {
      acc[filter] = EditorState.createEmpty();
      return acc;
    }, {} as Record<COMMENTARY_FILTERS, EditorState>)
  );

  const [editMode, setEditMode] = React.useState<
    Record<COMMENTARY_FILTERS, boolean>
  >(
    Object.values(COMMENTARY_FILTERS).reduce((acc, filter) => {
      acc[filter] = false;
      return acc;
    }, {} as Record<COMMENTARY_FILTERS, boolean>)
  );
  const [internalDirty, setInternalDirty] = React.useState<
    Record<COMMENTARY_FILTERS, boolean>
  >(
    Object.values(COMMENTARY_FILTERS).reduce((acc, filter) => {
      acc[filter] = false;
      return acc;
    }, {} as Record<COMMENTARY_FILTERS, boolean>)
  );

  const [selectedFilters, setSelectedFilters] = React.useState<
    {
      groupId: number;
      groupName: string;
    }[]
  >(
    Object.values(COMMENTARY_FILTERS).map((filter, index) => ({
      groupId: index,
      groupName: filter,
    }))
  );

  const currentHref = window.location.pathname;

  useEffect(() => {
    /* If the URL navigation changes, select the appropriate filter */
    const mapping = NAVIGATION_MAPPING.find((mapping) =>
      currentHref.includes(mapping.href)
    );
    if (mapping) {
      setSelectedFilters([
        {
          groupId: Object.values(COMMENTARY_FILTERS).findIndex(
            (filter) => filter === mapping.filter
          ),
          groupName: mapping.filter,
        },
      ]);
    }
  }, [currentHref]);

  const user = useTypedSelector((state) => get(state, "user"));
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const { isFetching, data: notes } = useFetchNotesQuery(
    {
      mid: store?.merchantId || "",
      marketplaceType: store?.marketplace || "",
      marketplaceSubtype: store?.marketplaceSubtype || store?.marketplace || "",
      filters: Object.values(COMMENTARY_FILTERS),
    },
    {
      skip:
        !store ||
        !user ||
        !store.merchantId ||
        !store.marketplace ||
        !store.marketplaceSubtype,
    }
  );

  const [saveNote] = useSaveNotesMutation();

  useEffect(() => {
    if (notes && notes.notes.length > 0 && !isFetching) {
      const noteMap = notes.notes.reduce((acc, note) => {
        if ("filter" in note) {
          acc[note.filter] = note.note;
        }
        return acc;
      }, {} as Record<COMMENTARY_FILTERS, RawDraftContentState>);
      const newEditorState = Object.values(COMMENTARY_FILTERS).reduce(
        (acc, filter) => {
          acc[filter] = noteMap[filter]
            ? EditorState.createWithContent(convertFromRaw(noteMap[filter]))
            : EditorState.createEmpty();
          return acc;
        },
        {} as Record<COMMENTARY_FILTERS, EditorState>
      );
      setEditorState(newEditorState);
    } else if (!isFetching) {
      /* If we dont have notes after loading, reset the editor state */
      const newEditorState = Object.values(COMMENTARY_FILTERS).reduce(
        (acc, filter) => {
          acc[filter] = EditorState.createEmpty();
          return acc;
        },
        {} as Record<COMMENTARY_FILTERS, EditorState>
      );
      setEditorState(newEditorState);
    }
  }, [notes]);

  const editors = React.useRef<
    Record<COMMENTARY_FILTERS, React.RefObject<any>>
  >(
    Object.values(COMMENTARY_FILTERS).reduce((acc, filter) => {
      acc[filter] = React.createRef();
      return acc;
    }, {} as Record<COMMENTARY_FILTERS, React.RefObject<any>>)
  );

  const focusEditor = (filter: COMMENTARY_FILTERS) => {
    const editor = editors.current[filter];
    if (editor && editor?.current) {
      editor?.current?.focus();
    }
  };

  useEffect(() => {
    const isDirty = Object.values(internalDirty).some((dirty) => dirty);
    setShouldShowBadge(isDirty);
  }, [Object.values(internalDirty)]);

  const handleSaveInternal = async (filter: COMMENTARY_FILTERS) => {
    if (store) {
      const contentState = editorState[filter].getCurrentContent();

      await saveNote({
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        note: convertToRaw(contentState),
        filter,
      });

      setInternalDirty({
        ...internalDirty,
        [filter]: false,
      });

      setEditMode({
        ...editMode,
        [filter]: false,
      });
    }
  };

  const editComment = (filter: COMMENTARY_FILTERS, newState: EditorState) => {
    setInternalDirty({
      ...internalDirty,
      [filter]: true,
    });
    setEditorState({
      ...editorState,
      [filter]: newState,
    });
  };

  const handleButtonClick = async (filter: COMMENTARY_FILTERS) => {
    if (!editMode[filter]) {
      focusEditor(filter);
      setEditMode({
        ...editMode,
        [filter]: true,
      });
    } else {
      await handleSaveInternal(filter);
      setEditMode({
        ...editMode,
        [filter]: false,
      });
    }
  };

  const availableFilters = Object.values(COMMENTARY_FILTERS);
  const selectedFiltersGroupNames = selectedFilters.map(
    (filter) => filter.groupName
  );

  return (
    <>
      <ReportingCommentHeader container>
        <Typography variant="h3" color="textPrimary">
          {t("reportComments.reportCommentaryTitle")}
        </Typography>
        <Box
          display="flex"
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          marginTop={3}
          boxSizing={"border-box"}
        >
          <Box marginRight={3}>
            <Typography variant="h3" color="textPrimary">
              {t("reportComments.filterTitle")}
            </Typography>
          </Box>
          <ObjectFilterSelect
            filterTitle="Show All"
            displayKey={"groupName"}
            groups={availableFilters.map((filter, index) => ({
              groupId: index,
              groupName: filter,
            }))}
            handleSelect={(options) => {
              setSelectedFilters(options.map((option) => option));
            }}
            idKey={"groupName"}
            isFullWidth={false}
            options={availableFilters.map((filter, index) => ({
              groupId: index,
              groupName: filter,
            }))}
            selectedOptions={selectedFilters.map((filter, index) => ({
              groupId: filter.groupId,
              groupName: filter.groupName,
            }))}
          />
        </Box>
      </ReportingCommentHeader>
      <Box>
        {isFetching ? (
          <PanelLoading />
        ) : (
          <Box>
            {availableFilters
              .filter((filter) => {
                if (selectedFilters.length === 0) {
                  return true;
                }

                return selectedFiltersGroupNames.includes(filter);
              })
              .map((filter, index) => (
                <Box
                  key={filter}
                  borderBottom={
                    index < availableFilters.length - 1
                      ? `1px solid ${theme.palette.border.main}`
                      : 0
                  }
                >
                  <Box padding={3}>
                    <Typography variant="h3" color="textPrimary">
                      {`COMMENT - ${t(`reportComments.commentType.${filter}`)}`}
                    </Typography>
                  </Box>
                  <Box>
                    <RichTextEditor
                      editorState={editorState[filter]}
                      setEditorState={(state) => {
                        editComment(filter, state);
                      }}
                      editMode={editMode[filter]}
                      editor={editors.current[filter]}
                      includeControls={true}
                    />
                    <Box position={"relative"}>
                      {editMode[filter] ? (
                        <ReportingCommentSaveIcon
                          onClick={() => handleButtonClick(filter)}
                        />
                      ) : (
                        <ReportingCommentEditIcon
                          onClick={() => handleButtonClick(filter)}
                        />
                      )}
                    </Box>
                  </Box>

                  {internalDirty[filter] && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      height="70px"
                      p={3}
                    >
                      <Typography variant="body1" color="error">
                        {t("reportComments.unsavedComments")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SingleChannelReportingComments;
