import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Grid } from "@material-ui/core";
import i18next from "i18next";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Table from "~/components/adTable/table";
import Panel from "~/components/panel/panel";
import { LinkCell } from "~/components/table/cells/linkCell";
import { TextCell } from "~/components/table/cells/textCell";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import {
  SentReport,
  useGetSentReportsQuery,
} from "~/store/mystore/clientReport.redux";
import { getFriendlyLongUserName } from "~/store/utils/displayUtils";
import { isValidHttpUrl } from "~/utils/utils";
import SearchFilter from "~/components/adTable/searchFilter";
import { PaginationArgs } from "~/typedef/pagination";

const PAGE_SIZE = 25;

export interface ReportSentTableProps {
  mid?: string;
}

export const getReportLink = (reportUrl: string): string => {
  if (isValidHttpUrl(reportUrl)) {
    return reportUrl;
  }

  return "https://" + reportUrl;
};

const ReportSentTable = memo(({ mid }: ReportSentTableProps) => {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);

  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize: PAGE_SIZE,
    pageIndex: 0,
    sortKey: "createdAt",
    sortOrder: "desc",
  });

  const [search, setSearch] = useState<string | null>(null);

  const handleOnFetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setPaginationParams({
        pageSize,
        pageIndex,
        sortKey: paginationParams.sortKey,
        sortOrder: paginationParams.sortOrder,
      });
    },
    []
  );

  const { isLoading, data, count } = useGetSentReportsQuery(
    {
      search,
      ...paginationParams,
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          data: data?.sentReports?.reportRows || [],
          count: data?.sentReports?.count || 0,
          isLoading: isFetching,
        };
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: t("createReport.formHeader"),
        accessor: (row: SentReport) => ({
          value: row.reportName,
          link: getReportLink(row.reportUrl),
          target: "_blank",
        }),
        Cell: (props: any) => <LinkCell {...props} colorVariant="external" />,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.type"),
        accessor: "reportType",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.sentBy"),
        accessor: "sentBy",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.dateAndTime"),
        id: "createdAt",
        accessor: "dateAndTime",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.type"),
        accessor: "type",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.selectDateRangeTitle"),
        accessor: "reportPeriod",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.recipientEmail"),
        accessor: "recipientEmail",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
    ],
    []
  );

  const tableData = data
    ? data.map((report) => {
        const recipients = report.reportRecipients
          ? typeof report.reportRecipients === "string"
            ? report.reportRecipients
            : report.reportRecipients.join(",")
          : null;

        const [reportPeriodFrom, reportPeriodTo] = report.reportPeriod
          ? report.reportPeriod.split(" - ").map((d: string) =>
              d.includes("/")
                ? moment(d, "DD/MM/YYYY") // legacy support for reports created before 1 Feb 2023
                    .locale(i18next.language)
                    .format("ll")
                : moment(d, "DD MMM YYYY").locale(i18next.language).format("ll")
            )
          : [];

        return {
          sentBy: getFriendlyLongUserName(userInfo),
          dateAndTime: moment(report.createdAt)
            .locale(i18next.language)
            .format("ll LT"),
          recipientEmail: report.reportRecipients ? recipients : userInfo.email,
          ...report,
          reportPeriod: `${reportPeriodFrom} - ${reportPeriodTo}`,
        };
      })
    : [];

  return (
    <Grid item xs={12}>
      <Panel
        id="sent-report-table"
        title={t("createReport.reportsSent")}
        tooltip={undefined}
        footerLink={undefined}
        actions={
          <>
            <SearchFilter setSearchText={setSearch} />
          </>
        }
        content={
          <Table
            {...{
              columns: columns,
              data: tableData,
              fetchData: handleOnFetchData,
              pagination: true,
              pageSize: PAGE_SIZE,
              pageCount: Math.ceil(count / PAGE_SIZE),
              loading: isLoading,
            }}
          />
        }
      />
    </Grid>
  );
});

export default ReportSentTable;
