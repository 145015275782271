import React, { useEffect, useState } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import axios from "axios";
import { baseUrl } from "../configs";

const generateAuthUrl = (requestTokens) => {
  const authUrlSearchParams = new URLSearchParams(requestTokens);
  return `${
    process.env.TRADEME_AUTH_ENDPOINT
  }/Oauth/Authorize?${authUrlSearchParams.toString()}`;
};

/**
 * @param {(code: string) => void} onCode
 */
export default function (onCode) {
  const childWindow = React.useRef(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  const [requestTokens, setRequestTokens] = useState(null);

  useEffect(() => {
    axios.get(`${baseUrl}/auth/trademe/requestToken`).then((res) => {
      setRequestTokens(res.data);
    });
  }, []);

  useEffect(() => {
    const messageListener = (event) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "trademe"
      ) {
        const { params } = event.data;
        closeChildWindow();
        if (requestTokens) {
          onCode(
            params.oauth_token,
            params.oauth_verifier,
            requestTokens.oauth_token_secret
          );
        }
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, requestTokens]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateAuthUrl(requestTokens),
        "authPopup-mm-trademe",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
