import { Box, Grid, Typography } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import React, { memo } from "react";

import { CHART_TITLES } from "../chartUtils/chartUtils";
import { CHART_TYPE } from "~/modules/widgets/customGroups/customGroupsChart";
import { CHART_TYPE_VENDOR } from "~/pages/singleChannel/vendorGroup/customVendorGroupsChart";
import MultiLineChartTooltip from "./multiLineChartTooltip";
import MutliLineChartLegend from "./multiLineChartLegend";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useLayoutProps } from "../chartUtils/chartComponents";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface MultiLineChartProps {
  chartData: {
    date: number;
    groups: { name: string; value: number }[];
  }[];
  chartType: string;
  currentPeriod: DATETIME_PERIODS;
  currency: string;
  fromDate: number;
  toDate: number;
  interval: INTERVAL;
  report?: boolean;
  timezone: string;
}

const Y_AXIS_WIDTH = 80;

const getXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  interval: INTERVAL,
  isCustomPeriodSameDay = false,
  ticks: [number, number] | undefined,
  timezone: string
): XAxisProps => {
  let tickFormatter;
  if (interval === INTERVAL.MONTHS) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).add(12, "h").startOf("day").format("MMM");
  } else if (currentPeriod === DATETIME_PERIODS.DAY || isCustomPeriodSameDay) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("HH");
  } else {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("MMMM Do");
  }

  return {
    dataKey: "date",
    axisLine: false,
    type: "category",
    interval: "preserveStartEnd",
    tickLine: false,
    domain: ["dataMin", "dataMax"],
    padding: { left: 30, right: 30 },
    tick: { fontSize: 14 },
    tickFormatter,
    ...(ticks ? { ticks } : {}),
  };
};

const MultiLineChart = memo<MultiLineChartProps>(function MultiLineChart({
  chartData = [],
  chartType,
  currentPeriod,
  currency,
  fromDate,
  toDate,
  interval,
  report,
  timezone,
}) {
  const { height, margin } = useLayoutProps("lineChart", report);
  const { t } = useTranslation();
  const theme = useTheme();

  const isCustomPeriodSameDay =
    Boolean(fromDate) &&
    Boolean(toDate) &&
    moment
      .unix(fromDate)
      .tz(timezone)
      .isSame(moment.unix(toDate).tz(timezone), "day");

  const XAxisProps =
    chartData &&
    getXAxisProps(
      currentPeriod,
      interval,
      isCustomPeriodSameDay,
      chartData.length
        ? [chartData[0].date, chartData[chartData.length - 1].date]
        : undefined,
      timezone
    );

  const data = chartData?.map((dateData) => {
    const { groups, date } = dateData;
    const mappedData: { date: number; [key: string]: number } = {
      date,
    };
    groups?.forEach((group) => {
      const { name, value } = group;
      mappedData[name] = value;
    });
    return mappedData;
  });

  const { date, ...groups } = data[0] || {};
  const dataKeys = groups ? Object.keys(groups) : [];

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Box pl={3} pt={2}>
          <Typography variant="h6">
            {currentPeriod ? t(CHART_TITLES[currentPeriod]) : ""}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={height}>
          <LineChart data={data} margin={margin}>
            <XAxis {...XAxisProps} />
            <ReferenceLine y={0} strokeWidth={2} />
            <YAxis
              label={{
                value: `${t(`chartLegend.${chartType}`)} ${
                  chartType === CHART_TYPE.UnitsSold ||
                  chartType === CHART_TYPE_VENDOR.GlanceView ||
                  chartType === CHART_TYPE_VENDOR.UnitsSold
                    ? ""
                    : ` (${currency})`
                }`,
                position: "top",
                offset: 15,
                dx: 20,
                fontSize: 12,
              }}
              tickLine={false}
              tick={{ fontSize: 12 }}
              tickFormatter={(tick) => numberWithCommas(parseInt(tick))}
              width={Y_AXIS_WIDTH}
            />
            <Legend
              align="center"
              layout="horizontal"
              verticalAlign="bottom"
              content={<MutliLineChartLegend dataKeys={dataKeys} />}
            />
            <Tooltip
              content={
                <MultiLineChartTooltip
                  currentPeriod={currentPeriod}
                  dataKeys={dataKeys}
                  currency={
                    chartType === CHART_TYPE.UnitsSold ||
                    chartType === CHART_TYPE_VENDOR.UnitsSold
                      ? undefined
                      : currency
                  }
                  chartType={chartType}
                  interval={interval}
                  timezone={timezone}
                />
              }
            />
            {dataKeys?.map((dataKey, i) => {
              return (
                <Line
                  key={dataKey}
                  dataKey={dataKey}
                  dot={false}
                  strokeWidth={2}
                  stroke={Object.values(theme.palette.chart)[i]}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
});

export default MultiLineChart;
