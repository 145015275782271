import { History, Location } from "history";
import {
  PAYWALL_URL,
  shouldTriggerPaywall,
} from "../modules/subscription/paywall";
import React, { ReactChild, useCallback } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { get, isEmpty, isEqual } from "lodash";
import {
  logout,
  setAuthSource as setAuthSourceCookie,
} from "../store/user.redux";

import ErrorBoundary from "../components/errorBoundary";
import LoadingPage from "@modules/loadingPage";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import Wrapper from "~/components/wrapper";
import cookies from "browser-cookies";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import { setDestinationRoute } from "~/store/globalVar.redux";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MAX_ROUTES_VISITED = 2;
const isLoggingOut = (history: History) => history.length > MAX_ROUTES_VISITED;

function reAuthorise(
  setDestination: (pathname: string) => void,
  location: Location<unknown>,
  history: History<unknown>
) {
  if (isLoggingOut(history)) {
    setDestination("");
  }
  const destinationUrl =
    location.pathname + (location.search ? location.search : "");
  const redirectToSignup = destinationUrl.includes("signup=true");
  setDestination(destinationUrl);
  return redirectToSignup ? (
    <Redirect to={{ pathname: "/signup", state: { from: location } }} />
  ) : (
    <Redirect to={{ pathname: "/", state: { from: location } }} />
  );
}

interface PrivateRouteProps {
  component: any;
  fullNav: boolean;
  /** Pass down props */
  [x: string]: any;
}

const GlobalToastAuthCheck = ({ children }: { children: ReactChild }) => {
  const dispatch = useDispatch();
  const globalToast = useTypedSelector((state) => state.globalToast);
  const location = useLocation<Location>();
  const history = useHistory();
  const user = useTypedSelector((state) => state.user);
  const logoutOnError = useCallback(() => dispatch(logout()), []);

  const setDestination = useCallback(
    async (route) => await dispatch(setDestinationRoute(route)),
    []
  );

  if (globalToast && globalToast.triggerLogout) {
    if (user && user._id) {
      logoutOnError();
    }
    return reAuthorise(setDestination, location, history);
  }

  return <>{children}</>;
};

const PrivateRoute = ({
  component: Component,
  fullNav = true,
  ...rest
}: PrivateRouteProps) => {
  const user = useTypedSelector((state) => state.user);
  const userCheck = user && user._id;
  const isLoggedIn = user && user._id ? true : false;
  const dispatch = useDispatch();

  const location = useLocation<Location>();
  const history = useHistory();
  const authSource = cookies.get("authSource");
  const userAuthSource = get(user, "authSource");
  const accessToken = cookies.get("accessToken");

  const theme = useCustomTheme();

  const destinationRoute = useTypedSelector(
    (state) => state.globalVar.destinationRoute
  );
  const currentSubscription = useTypedSelector(
    (state) => state.subscriptions.currentSubscription
  );
  const fetchingSubscription = useTypedSelector(
    (state) => state.subscriptions.loading
  );

  const setDestination = useCallback(
    async (route) => await dispatch(setDestinationRoute(route)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    const checkAuthSource = () => {
      if (userCheck) {
        try {
          if (!isEqual(authSource, userAuthSource)) {
            dispatch(setAuthSourceCookie(authSource));
          }
        } catch (err) {
          // do nothing
        }
      }
    };
    checkAuthSource();
  }, [userCheck, dispatch, authSource, userAuthSource]);

  React.useEffect(() => {
    if (accessToken) {
      jwtInterceptor(null, location.pathname);
    }
  }, [userCheck, accessToken, location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (fetchingSubscription && isEmpty(currentSubscription)) {
          return <LoadingPage />;
        }
        if (isLoggedIn) {
          if (shouldTriggerPaywall(currentSubscription, location)) {
            const isExpired = get(currentSubscription, "status") === "Expired";
            const targetUrl =
              PAYWALL_URL + (isExpired ? "?trialExpired=true" : "");
            return <Redirect to={targetUrl} />;
          } else {
            if (destinationRoute?.length) {
              setDestination("");
            }
            return (
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <ErrorBoundary location={location}>
                    <Wrapper fullNav={fullNav}>
                      <GlobalToastAuthCheck>
                        <Component {...props} {...rest} />
                      </GlobalToastAuthCheck>
                    </Wrapper>
                  </ErrorBoundary>
                </ThemeProvider>
              </MuiThemeProvider>
            );
          }
        } else {
          return reAuthorise(setDestination, location, history);
        }
      }}
    />
  );
};

export default PrivateRoute;
