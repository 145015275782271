import { Box } from "@material-ui/core";
import CombinedLink from "../links/link.tsx";
import { CountryLogo } from "../../img/flags/country_logos";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import PropTypes from "prop-types";
import React from "react";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import styled from "styled-components";

const Link = styled(CombinedLink)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
  `}
`;

export const StoreDetails = ({ store }) => (
  <Box display="flex" alignItems="center" height="100%">
    <Box pr={0.5} display="flex" alignItems="center" height="100%">
      <MarketplaceIcon
        marketplaceSubtype={store.marketplaceSubtype}
        market={store.marketplaceType}
        mid={store.merchantId}
      />
    </Box>
    <CountryLogo code={store.marketplaceCountry} />
    <Box pl={1} display="flex" alignItems="center" height="100%">
      <Link
        variant="body2"
        to={marketplaceLink(store.marketplaceType, store.merchantId)}
      >
        {store.storeName}
      </Link>
    </Box>
  </Box>
);

StoreDetails.propTypes = {
  store: PropTypes.shape({
    merchantId: PropTypes.string,
    marketplaceType: PropTypes.string,
    marketplaceSubtype: PropTypes.string,
    marketplaceCountry: PropTypes.string,
  }).isRequired,
};
