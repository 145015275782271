import { Box, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import {
  LanguageOutlined,
  MonetizationOnOutlined,
  ScheduleOutlined,
  TrendingUpOutlined,
} from "@material-ui/icons";
import React, { memo } from "react";

import CurrencyDisplay from "~/components/toolbars/toolbarComponents/currencyDisplay";
import GenericTotal from "~/components/totals/genericTotal";
import { Store } from "~/typedef/store";
import { distinctCurrenciesByCountryCode } from "~/utils/currencyUtils";
import i18next from "i18next";
import moment from "moment-timezone";
import styled from "styled-components";
import { useCustomLogo } from "~/hooks/useCustomLogo";
import { useTranslation } from "react-i18next";

const ReportDivider = styled(Divider)`
  height: 2px;
  background-color: ${({ theme }) => theme.palette.border.main};
  margin-bottom: 2rem;
`;

const LogoImg = styled.img`
  display: flex;
  max-width: 250px;
`;

const TimeIcon = styled(ScheduleOutlined)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const ComparisonIcon = styled(TrendingUpOutlined)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const CurrencyIcon = styled(MonetizationOnOutlined)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const TimezoneIcon = styled(LanguageOutlined)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const InnerGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const OuterGrid = styled(Grid)`
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
`;

interface HeaderProps {
  reportName: string;
  fromDisplayDate: string;
  toDisplayDate: string;
  priorFromDisplayDate: string;
  priorToDisplayDate: string;
  currency: string;
  timezone: string;
  createdAt: string;
  stores: Store[];
}

const ClientReportHeader = memo<HeaderProps>(function ClientReportHeader({
  reportName,
  fromDisplayDate,
  toDisplayDate,
  priorFromDisplayDate,
  priorToDisplayDate,
  currency,
  timezone,
  createdAt,
  stores,
}) {
  const theme = useTheme();
  const currencies = distinctCurrenciesByCountryCode;
  const currencyKeys = Object.keys(currencies) as (keyof typeof currencies)[];
  const logoImgProps = {
    src: useCustomLogo(!theme.darkModeEnabled),
    alt: "MerchantSpring Logo",
  };
  const { t } = useTranslation();

  return (
    <>
      <OuterGrid
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        className="report-header"
      >
        <InnerGrid item container xs={12} md={6} justifyContent="space-between">
          <LogoImg {...logoImgProps} />

          <Box display="flex" flexDirection="column">
            <Typography variant="h1">{reportName}</Typography>
            <Box pt={1} />
            <Typography variant="body2" color="textSecondary">
              {t("createReport.generatedOn")}:{" "}
              {moment(createdAt).locale(i18next.language).format("ll LT ")} (
              {timezone})
            </Typography>
          </Box>
        </InnerGrid>
        <InnerGrid item container xs={12} md={6}>
          {stores.length > 1 && (
            <GenericTotal
              title={t("clientReport.stores")}
              number={stores.length}
              lockLayout={true}
              textAlign="right"
            />
          )}
          <Box pt={1} display="flex" justifyContent="space-between">
            <Box display="flex">
              <TimeIcon />
              <Typography variant="body1">
                {t("createReport.selectDateRangeTitle")}:
              </Typography>
            </Box>
            <Typography variant="body1">
              {t("clientReport.timeRange", {
                fromDate: fromDisplayDate,
                toDate: toDisplayDate,
              })}
            </Typography>
          </Box>
          <Box pt={1} display="flex" justifyContent="space-between">
            <Box display="flex">
              <ComparisonIcon />
              <Typography variant="body1" style={{ paddingRight: "12px" }}>
                {t("clientReport.comparison")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {t("clientReport.comparisonRange", {
                fromDate: priorFromDisplayDate,
                toDate: priorToDisplayDate,
              })}
            </Typography>
          </Box>
          <Box pt={1} display="flex" justifyContent="space-between">
            <Box display="flex">
              <CurrencyIcon />
              <Typography variant="body1">
                {t("dashboard.selectCurrencyTitle")}:
              </Typography>
            </Box>
            <Box display="flex">
              <CurrencyDisplay
                countryCode={
                  currencyKeys.find((key) => currencies[key] === currency) || ""
                }
                currencyCode={currency as string}
              />
            </Box>
          </Box>
          <Box pt={1} display="flex" justifyContent="space-between">
            <Box display="flex">
              <TimezoneIcon />
              <Typography variant="body1">
                {t("createReport.selectTimeZone")}:
              </Typography>
            </Box>
            <Typography variant="body1">{timezone}</Typography>
          </Box>
        </InnerGrid>
      </OuterGrid>
      <ReportDivider className="report-divider" />
    </>
  );
});

export default ClientReportHeader;
