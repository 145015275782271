import { checkUserStatus } from "@merchantspring/common";
import get from "lodash/get";
/** @param {object} currentSubscription - an object representing the
 * user's current subscription
 * @return {boolean} true iff a user has no prior subscriptions (i.e. is a new user for the purposes of subscription)
 */
export const noPriorSubscription = (currentSubscription) => {
  // If a user doesn't have a subscription status, assume that they're new
  const status = get(currentSubscription, "status", null);
  return status === null;
};

const SHOULD_DISABLE_PAYWALL = process.env.SHOULD_DISABLE_PAYWALL === "true";
export const PAYWALL_URL = "/subscription/newUser";

export const shouldTriggerPaywall = (currentSubscription, targetLocation) => {
  if (SHOULD_DISABLE_PAYWALL) {
    return false;
  }
  const invalidSubscription = !checkUserStatus(currentSubscription);
  const targetPath = get(targetLocation, "pathname") || targetLocation;
  return (
    invalidSubscription &&
    ![PAYWALL_URL, "/subscription/create", "/subscription/plans"].includes(
      targetPath
    )
  );
};
