import { useDispatch, useSelector } from "react-redux";

import CombinedLink from "../../../components/links/link.tsx";
import FloatingAlertCard from "../../../components/alert/floatingAlertCard";
import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@material-ui/core";
import findLast from "lodash/findLast";
import get from "lodash/get";
import { hideFailedPayment } from "../../../store/subscriptions.redux";
import { isFrontPage } from "~/utils/navigationUtils";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";
import { useLocation } from "react-router-dom";

const FailedPaymentAlert = ({ currentSubscription }) => {
  const isPastDue = get(currentSubscription, "status") === "PastDue";
  const hideAlertUntil = useSelector((state) =>
    get(state, "subscriptions.hideFailedPaymentUntil")
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(!isFrontPage(location) && isPastDue);

  const subscriptionHistory = get(currentSubscription, "statusHistory", []);
  const failureDate = get(
    findLast(
      sortBy(subscriptionHistory, (val) => moment(val.timestamp)),
      (subscriptionEvent) => subscriptionEvent.status === "PastDue"
    ),
    "timestamp"
  );

  const onClose = () => {
    dispatch(hideFailedPayment());
    setOpen(false);
  };

  return (
    <FloatingAlertCard
      data-testid="failed-payment-alert"
      isOpen={open && hideAlertUntil < Date.now()}
      onClose={onClose}
      type="warning"
    >
      <Typography variant="body1">
        Invoice payment failed on {moment(failureDate).format("D MMM YYYY")}.
        Please click <CombinedLink to="/setting">here</CombinedLink> to update
        your billing details.
      </Typography>
    </FloatingAlertCard>
  );
};

FailedPaymentAlert.propTypes = {
  currentSubscription: PropTypes.object,
};

export default FailedPaymentAlert;
