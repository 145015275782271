import * as _ from "lodash";

/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InfoBox from "~/components/info/infoBox";
import { LegendIndicator } from "~/components/charts/barChart/barChartLegend";
import { LinkCell } from "~/components/table/cells/linkCell";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel.tsx";
import PercentageBar from "~/components/percentageBar";
import StatusIndicator from "~/components/statusIndicator/statusIndicator";
import Switch from "@material-ui/core/Switch";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { fetchCategoryScores } from "../../store/itemSpecifics.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { withTheme } from "@material-ui/core/styles";

export const DotPoint = styled.div`
  min-height: 4px;
  min-width: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.text.primary};
  margin-right: 8px;
`;

const Legend = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = {
    correct: _.get(theme, "palette.success.main"),
    custom: _.get(theme, "palette.warning.main"),
    missing: _.get(theme, "palette.disabled.light"),
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {Object.entries(colors).map(([text, color], i) => (
        // return a React fragment to avoid adding elements to the DOM
        <Box key={i} display="flex" alignItems="center" ml={1}>
          <LegendIndicator fill={color} />
          <Typography variant="body2">
            {t(`myStoresWidget.itemSpecifics.legend.${text}`)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const Heading = ({
  time,
  summaryPercent,
  summaryStatus,
  totalListings,
  requiredToggle,
  toggleSwitch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Panel
      id="widget-item-specifics-overall"
      title={t("myStoresWidget.itemSpecifics.mainTitle")}
      content={
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} sm={6} lg={4} justifyContent="center">
              <StatusIndicator
                status={summaryStatus}
                statusScore={summaryPercent ? `${summaryPercent}%` : ""}
                title={t(
                  "myStoresWidget.listingQuality.statusIndicator.mainTitle"
                )}
                subtitle={t(
                  "myStoresWidget.listingQuality.statusIndicator.subTitle",
                  { time }
                )}
                footnote={t(
                  "myStoresWidget.listingQuality.statusIndicator.footnote",
                  { numListings: numberWithCommas(totalListings) }
                )}
              />
            </Grid>
            <Grid container item xs={12} sm={6} lg={4} justifyContent="center">
              <Legend />
            </Grid>
            <Grid container item xs={12} lg={4} justifyContent="center">
              <InfoBox>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.bulletA")}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <DotPoint theme={theme} />
                    <Typography variant="subtitle2" color="textPrimary">
                      {t("myStoresWidget.itemSpecifics.bulletB")}
                    </Typography>
                  </Box>
                </Box>
              </InfoBox>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <FormControlLabel
          id="item-specifics-required"
          labelPlacement="start"
          control={
            <Switch
              size="small"
              checked={requiredToggle}
              onChange={toggleSwitch}
              value="switch"
              color="secondary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={
            <Typography variant="body2">
              {t("myStoresWidget.itemSpecifics.requiredOnlyLabel")}
            </Typography>
          }
        />
      }
    />
  );
};

const CustomLinkCell = ({ row }) => {
  const { t } = useTranslation();
  const score = row.original;
  const scoreTotal = score.correct + score.missing + score.invalid;
  const query = {
    categoryId: score.categoryId,
    siteId: score.siteId,
    mid: score.mid,
    countryCode: score.countryCode,
    requiredToggle: score.requiredToggle,
  };
  const link = marketplaceLink(
    "ebay",
    score.mid,
    "itemspecifics/detail",
    query
  );
  const value = scoreTotal
    ? t("myStoresWidget.itemSpecifics.viewListings")
    : null;
  return (
    <LinkCell
      cell={{
        value: {
          value,
          link,
        },
      }}
    />
  );
};

const ScoreCell = ({ row }) => {
  const theme = useTheme();
  const score = row.original;
  const scoreTotal = score.correct + score.missing + score.invalid;

  return (
    <Box display="flex" alignItems="center" width="100%">
      {scoreTotal ? (
        <PercentageBar
          data={[
            {
              percentage: score.correct / scoreTotal,
              color: _.get(theme, "palette.success.main"),
            },
            {
              percentage: score.invalid / scoreTotal,
              color: _.get(theme, "palette.warning.main"),
            },
            {
              percentage: score.missing / scoreTotal,
              color: _.get(theme, "palette.disabled.light"),
            },
          ]}
        />
      ) : (
        <Typography variant="body2" noWrap>
          No required item specifics for this category
        </Typography>
      )}
    </Box>
  );
};

export const ItemSpecifics = withTheme(({ mid, countryCode }) => {
  const [requiredToggle, setRequiredToggle] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [categoryScores, setCategoryScores] = useState([]);
  const [summaryPercent, setSummaryPercent] = useState(0);

  const itemSpecifics = useSelector((state) => state.itemSpecifics);

  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = useMemo(
    () => [
      {
        id: "categoryName",
        Header: t("myStoresWidget.itemSpecifics.categoryColumn"),
        accessor: "categoryName",
        Cell: TextCell,
      },
      {
        id: "itemCount",
        Header: t("myStoresWidget.itemSpecifics.listingsColumn"),
        accessor: "itemCount",
        Cell: TextCell,
        colSpan: 0.5,
        hiddenDown: "xs",
      },
      {
        id: "completeness",
        Header: t("myStoresWidget.itemSpecifics.completenessColumn"),
        Cell: ScoreCell,
        colSpan: smDown ? 1 : 3,
        useColSpan: true,
      },
      {
        id: "viewListings",
        Header: t("myStoresWidget.itemSpecifics.viewListings"),
        Cell: CustomLinkCell,
        colSpan: smDown ? 1 : 0.5,
      },
    ],
    [smDown]
  );

  const dispatch = useDispatch();
  const dispatchFetchCategoryScores = useCallback(() => {
    dispatch(fetchCategoryScores(mid, countryCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, countryCode]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchCategoryScores();
      setHasFetched(true);
    };
    fetchData();
  }, [dispatchFetchCategoryScores]);

  useEffect(() => {
    const scores = (
      requiredToggle
        ? itemSpecifics.categoryScores.map((categoryScore) => ({
            ...categoryScore,
            ...categoryScore.required,
            mid,
            countryCode,
            requiredToggle,
          }))
        : itemSpecifics.categoryScores.map((categoryScore) => ({
            ...categoryScore,
            mid,
            countryCode,
            requiredToggle,
          }))
    )
      // item count descending
      .sort((a, b) => _.get(b, "itemCount", 0) - _.get(a, "itemCount", 0));
    setCategoryScores(scores);
  }, [countryCode, itemSpecifics.categoryScores, mid, requiredToggle]);

  useEffect(() => {
    const totalCorrect = categoryScores.reduce(
      (total, categoryScore) => (total += categoryScore.correct),
      0
    );
    const total = categoryScores.reduce(
      (totalAcc, categoryScore) =>
        (totalAcc +=
          categoryScore.correct +
          categoryScore.invalid +
          categoryScore.missing),
      0
    );
    const percent = Math.round((totalCorrect / total) * 100);
    setSummaryPercent(percent);
  }, [categoryScores]);

  const summaryStatus =
    summaryPercent > 80
      ? "success"
      : summaryPercent > 50
      ? "warning"
      : summaryPercent > 0
      ? "error"
      : "disabled";

  if (!hasFetched || itemSpecifics.loading) {
    return <LoadingIndicator />;
  }

  const rawTime = _.get(
    _.cloneDeep(categoryScores).sort((a, b) => a.savedAt - b.savedAt)[0],
    "savedAt"
  );
  const time = rawTime ? moment(rawTime).calendar() : null;

  const totalListings = categoryScores.reduce(
    (totalAcc, categoryScore) => (totalAcc += categoryScore.itemCount),
    0
  );

  return (
    <Grid container spacing={0}>
      <Grid container item xs={12} display="flex" justifyContent="stretch">
        <Heading
          time={time}
          summaryPercent={summaryPercent}
          summaryStatus={summaryStatus}
          totalListings={totalListings}
          requiredToggle={requiredToggle}
          toggleSwitch={() => setRequiredToggle(!requiredToggle)}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Table columns={columns} data={categoryScores} pagination={true} />
        </Paper>
      </Grid>
    </Grid>
  );
});

export default withRouter(ItemSpecifics);
