import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  clearProfitOverviewExpanded,
  fetchOverviewProfitabilityExpanded,
  fetchProfitabilityCategories,
} from "@store/mystore/profitability.redux";
import { get, isEmpty } from "lodash";

import ColumnSelect from "~/components/adTable/columnSelect";
import { CurrencyCollection } from "@typedef/currency";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import IconLinkCell from "~/components/table/cells/iconLinkCell";
import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { OverviewToolbar } from "../../../components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "../../../components/containers/pageBlock";
import Panel from "../../../components/panel/panel";
import ProfitCell from "@components/table/cells/profitCell";
import ProfitabilityChart from "../../profitability/profitabilityChart";
import ProfitabilitySplit from "./profitabilitySplit";
import SearchFilter from "../../../components/adTable/searchFilter";
import { Store } from "@typedef/store";
import Table from "../../../components/adTable/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "@components/table/cells/valueCell";
import { formatData } from "./profitabilityUtils";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DISPLAY_TYPE = {
  percentage: true,
  sales: false,
};

const DEFAULT_SORT = "totalProfit";

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

const PAGE_SIZE = 5;
export interface FormatDataProps {
  profitability: any[];
  categoriesData: any[];
  currencyRates: any[];
  currentCurrency: any;
  allowedLinks?: string[];
  filteredStores?: Store[];
  conditionalFormatting?: boolean;
}

interface ShopNameObject {
  storeId: string;
  shopName: string;
}

const generateColumns = (t: TFunction<"translation">, displayType: boolean) => {
  return [
    {
      id: "market",
      Header: t("dashboardWidget.marketplaceColumn"),
      accessor: (row: any) => ({
        market: row.market,
        countryCode: row.countryCode,
      }),
      Cell: MarketplaceAndCountryCell,
      isLocked: true,
      isVisible: true,
      disableSortBy: true,
      customWidth: 90, // force override width
      // used by react-table-sticky; must be in sync with customWidth
      // if sticky and customWidth are set
      width: 90,
      sticky: "left",
    },
    {
      id: "shopName",
      Header: t("dashboardWidget.storeColumn"),
      accessor: "storeName",
      Cell: LinkCell,
      isVisible: true,
      isLocked: true,
      disableSortBy: true,
      sticky: "left",
    },
    {
      Header: t("dashboardWidget.salesColumn"),
      id: "totalSales",
      accessor: "totalSales",
      align: "right",
      Cell: ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.refundsColumn"),
      id: "totalRefunds",
      accessor: "totalRefunds",
      align: "right",
      Cell: ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.reimbursementsColumn"),
      id: "totalReimbursements",
      accessor: "totalReimbursements",
      align: "right",
      Cell: ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("customGroups.adSpendColumn"),
      id: "totalAdvertising",
      accessor: "totalAdvertising",
      align: "right",
      Cell: ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.promotionsColumn"),
      id: "totalPromotions",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentagePromotions"
          : "totalPromotions",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.otherIncomeColumn"),
      id: "totalOtherIncome",
      accessor: "totalOtherIncome",
      align: "right",
      Cell: ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.sellingFeesColumn"),
      id: "totalSellingFees",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageSellingFees"
          : "totalSellingFees",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.shippingFeesColumn"),
      id: "totalShippingFees",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageShipping"
          : "totalShippingFees",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.refundsAndReturnsColumn"),
      id: "totalRefundsAndReturns",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageRefundsAndReturns"
          : "totalRefundsAndReturns",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.cogsColumn"),
      id: "totalCogs",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageCogs"
          : "totalCogs",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.otherExpensesColumn"),
      id: "totalOtherExpenses",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageOtherExpenses"
          : "totalOtherExpenses",
      align: "right",
      Cell:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? ValueCell
          : ValueAndGrowthCell,
      sortDescFirst: true,
    },
    {
      Header: t("dashboardWidget.profitColumn"),
      id: "totalProfit",
      accessor:
        getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
          ? "percentageProfit"
          : "totalProfit",
      align: "right",
      Cell: ProfitCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t(`profitability.unitsLabel`),
      id: "unitCount",
      accessor: "unitCount",
      align: "right",
      Cell: ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t(`profitability.unitsRefundedLabel`),
      id: "refundedUnitCount",
      accessor: "refundedUnitCount",
      align: "right",
      Cell: ValueAndGrowthCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t(`profitability.refundsLabel`),
      id: "percentageRefund",
      accessor: "percentageRefund",
      align: "right",
      Cell: ValueCell,
      isVisible: true,
      sortDescFirst: true,
    },
    {
      Header: t(`profitability.column.action`),
      id: "action",
      accessor: "storeName",
      Cell: IconLinkCell,
      align: "right",
      isLocked: true,
      isVisible: true,
      disableSortBy: true,
    },
  ];
};

const ProfitabilitySummary = memo(function ProfitabilitySummary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [displayType, setDisplayType] = useState(DISPLAY_TYPE.sales);
  const [searchText, setSearchText] = useState("");
  const [columns, setColumns] = useState([] as any[]);

  const userInfo = useTypedSelector((state) => state.user);
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );

  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const profitability = useTypedSelector(
    (state) => get(state, "profitability.overviewExpanded.data") || []
  );
  const profitabilityParams = useTypedSelector(
    (state) => get(state, "profitability.overviewExpanded.params") || []
  );
  const containsComparisons = useTypedSelector(
    (state) =>
      get(state, "profitability.overviewExpanded.containsComparisons") || []
  );
  const profitabilityCount = useTypedSelector(
    (state) => get(state, "profitability.overviewExpanded.count") || 0
  );
  const loadingData = useTypedSelector((state) =>
    get(state, "profitability.overviewExpanded.fetching")
  );

  const categoriesData = useTypedSelector(
    (state) => get(state.profitability, "categories.data") || []
  );
  const loadingCategories = useTypedSelector((state) =>
    get(state, "categories.fetching")
  );

  const allowedLinks = useTypedSelector(
    (state) => state.customLayout?.layoutConfig?.sideNav?.pages
  );

  const newColumns = useMemo(
    () => (categoriesData ? generateColumns(t, displayType) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayType]
  );

  useEffect(() => {
    setColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newColumns]);

  const fetchCategories = useCallback(
    () => {
      dispatch(
        fetchProfitabilityCategories({
          mid: undefined,
          includeTax,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [includeTax]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      if (pageIndex === 0) {
        dispatch(clearProfitOverviewExpanded());
      }
      dispatch(
        fetchOverviewProfitabilityExpanded({
          includeTax,
          currentRange,
          filter: currentFilter,
          pageSize,
          pageIndex,
          sortKey: sortBy && sortBy.length ? sortBy[0].id : DEFAULT_SORT,
          sortOrder:
            sortBy && sortBy.length
              ? sortBy[0].desc
                ? "desc"
                : "asc"
              : "desc",
          searchText,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [includeTax, searchText, currentFilter, currentRange]
  );

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo._id]);

  useEffect(() => {
    if (!containsComparisons) {
      if (profitabilityParams) {
        const { pageIndex, pageSize, sortKey, sortOrder } = profitabilityParams;
        fetchData({
          pageSize,
          sortBy: [{ id: sortKey, desc: sortOrder === "desc" }],
          pageIndex: pageIndex,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (profitabilityParams) {
      const { pageIndex, pageSize, sortKey, sortOrder } = profitabilityParams;
      if (!loadingData) {
        if (profitabilityCount > pageSize * (pageIndex + 1)) {
          fetchData({
            ...profitabilityParams,
            sortBy: [{ id: sortKey, desc: sortOrder === "desc" }],
            pageIndex: pageIndex + 1,
          });
        }
      }
    }
  }, [fetchData, profitability, loadingData]);

  const data = useMemo(() => {
    return formatData({
      profitability,
      categoriesData,
      filteredStores,
      currencyRates,
      currentCurrency,
      allowedLinks,
    });
  }, [
    loadingData,
    loadingCategories,
    profitability,
    categoriesData,
    filteredStores,
    currencyRates,
    currentCurrency,
  ]);

  const getShopNameArray = (data: ShopNameObject[]) => {
    return data.reduce<ShopNameObject[]>((acc, curr) => {
      const shopName = curr.shopName;
      const storeId = curr.storeId;
      acc.push({
        storeId,
        shopName,
      });
      return acc;
    }, []);
  };
  const shopNameArrayParameter = getShopNameArray(data);

  /** Clear the redux store when navigating away so data is refreshed properly */
  useEffect(
    () => () => {
      dispatch(clearProfitOverviewExpanded());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableProps = {
    columns,
    data,
    fetchData,
    sorting: true,
    loading: (loadingData || loadingCategories) && isEmpty(data),
    pagination: true,
    pageSize: PAGE_SIZE,
    pageCount: Math.ceil(profitabilityCount / PAGE_SIZE),
    autoPagination: true,
    currentPage: get(profitabilityParams, "pageIndex") + 1 || 1,
  };

  return (
    <PageBlock>
      <OverviewToolbar
        selectedItem={{
          currentPage: t("profitSummary.pageTitle"),
          breadcrumb: [],
        }}
      />{" "}
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} alignItems="center">
          <ProfitabilityChart />
        </Grid>
        <Grid container item xs={12} md={4} alignItems="center">
          <ProfitabilitySplit />
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Panel
            id="profit-summary-expanded"
            title={t("profitSummary.title")}
            tooltip={t("profitSummary.tooltip")}
            content={<Table {...tableProps} />}
            actions={
              <>
                <Box mr="10px">
                  <Typography variant="subtitle2" color="textSecondary">
                    {t("profitSummary.subtitle")}
                  </Typography>
                </Box>
                <Box mr="20px">
                  <SearchFilter {...{ setSearchText }} />
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={displayType}
                      onChange={() => setDisplayType(!displayType)}
                    />
                  }
                  label={t("dashboardWidget.averageSales.showLabel", {
                    label: displayType
                      ? getCurrencySymbol[
                          currentCurrency as keyof CurrencyCollection
                        ]
                      : "%",
                  })}
                />
                <ColumnSelect
                  {...{ columns: columns, setColumns: setColumns }}
                />
                <DownloadCsv
                  {...{
                    reportType: "profitOverview",
                    path: "/api/generic/profitability/profitOverview",
                    mid: "all",
                    params: {
                      includeTax,
                      fromDate: currentRange.fromDate,
                      priorFromDate: currentRange.priorFromDate,
                      toDate: currentRange.toDate,
                      priorToDate: currentRange.priorToDate,
                      filter: currentFilter,
                      sortKey: DEFAULT_SORT,
                      sortOrder: "desc",
                      searchText,
                      currentCurrency,
                      currencyRates: currencyRates,
                      shopNameArrayParameter: shopNameArrayParameter,
                    },
                  }}
                />
              </>
            }
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default ProfitabilitySummary;
