import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CurrencyRate, Range } from "~/typedef/store";
import { InlineBlockDiv, StyledTableCell } from "../salesByProduct";
import {
  MappedRefundProduct,
  useRefundAnalyticsProductsQuery,
} from "~/store/mystore/refundAnalytics.redux";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import RefundAnalyticsTableChart from "./refundAnalyticsTableChart";
import SearchFilter from "~/components/adTable/searchFilter";
import { ShowChart } from "@material-ui/icons";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { useTranslation } from "react-i18next";

interface RefundAnalyticsByProduct {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  includeTax: boolean;
  conditionalFormatting?: boolean;
  actions?: ReactChild;
  currencyRates: CurrencyRate[];
  pageSize?: number;
  report?: boolean;
}

const DEFAULT_PAGE_SIZE = 25;

const RefundAnalyticsByProduct = memo<RefundAnalyticsByProduct>(
  function RefundAnalyticsByProduct({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentPeriod,
    currentRange,
    includeTax,
    conditionalFormatting,
    currencyRates,
    actions,
    pageSize,
    report = false,
  }) {
    const { t } = useTranslation();

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );
    const columns = useMemo(
      () => [
        {
          Header: t("refundAnalyticsByProduct.productColumn"),
          id: "title",
          accessor: (row: MappedRefundProduct) => ({
            value: row.title,
            secondRowValue: `${
              marketplaceType === "amazon" ? "ASIN" : "LISTING ID"
            }: ${row.asin} | SKU: ${row.sellerSku}`,
            image: row.imageUrl,
            link: row.linkUrl,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          sticky: "left",
          customWidth: 200,
          isLocked: true,
        },
        {
          Header: t("generic.chart.actionsColumn"),
          accessor: () => {},
          id: "actions",
          Cell: (cell: ReactTableCell) => {
            const actions = [
              {
                icon: ShowChart,
                text: cell.row.isExpanded
                  ? t("generic.hide")
                  : t("generic.show"),
                action: () => {
                  const isExpanded = cell.row.isExpanded;
                  cell.toggleAllRowsExpanded(false);
                  const { onClick } = cell.row.getToggleRowExpandedProps();
                  if (!isExpanded) onClick();
                },
              },
            ];
            return <ActionCell actions={actions} />;
          },
          isVisible: !report,
          disableSortBy: true,
          sticky: "left",
        },
        {
          Header: t("refundAnalyticsByProduct.grossRevenue"),
          id: "grossSales",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.grossSales.current
                ? formatCurrencyRounded(
                    row.grossSales.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: getPercentageDifference(
                row.grossSales.current,
                row.grossSales.prior
              ),
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: !report,
        },
        {
          Header: t("refundAnalyticsByProduct.cancelledSales"),
          id: "cancelledSales",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.cancelledSales.current
                ? formatCurrencyRounded(
                    row.cancelledSales.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",

              growth: getPercentageDifference(
                row.cancelledSales.current,
                row.cancelledSales.prior
              ),
              conditionalFormatting,
              reverseFormatting: true,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("refundAnalyticsByProduct.cancelRate"),
          id: "cancelRate",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.cancelRate.current
                ? `${row.cancelRate.current.toFixed(1)}%`
                : "-",

              growth: getPercentageDifference(
                row.cancelRate.current,
                row.cancelRate.prior,
                true
              ),
              conditionalFormatting,
              reverseFormatting: true,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("refundAnalyticsByProduct.netSales"),
          id: "netSales",
          accessor: (row: MappedRefundProduct) => {
            const netSalesCurrent =
              row.grossSales.current - row.cancelledSales.current;
            const netSalesPrior =
              row.grossSales.prior - row.cancelledSales.prior;
            return {
              value: netSalesCurrent
                ? formatCurrencyRounded(
                    netSalesCurrent,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: getPercentageDifference(netSalesCurrent, netSalesPrior),
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: !report,
          disableSortBy: true,
        },
        {
          Header: t("refundAnalyticsByProduct.refundedRevenue"),
          id: "refundedSales",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.refundedSales.current
                ? formatCurrencyRounded(
                    row.refundedSales.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: getPercentageDifference(
                row.refundedSales.current,
                row.refundedSales.prior
              ),
              conditionalFormatting,
              reverseFormatting: true,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("refundAnalyticsByProduct.refundRate"),
          id: "refundRate",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.refundRate.current
                ? `${row.refundRate.current.toFixed(1)}%`
                : "-",
              growth: getPercentageDifference(
                row.refundRate.current,
                row.refundRate.prior,
                true
              ),
              conditionalFormatting,
              reverseFormatting: true,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("refundAnalyticsByProduct.cancelledUnits"),
          id: "unitsCancelled",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.unitsCancelled.current
                ? row.unitsCancelled.current
                : "-",
              growth: getPercentageDifference(
                row.unitsCancelled.current,
                row.unitsCancelled.prior
              ),

              conditionalFormatting,
              reverseFormatting: true,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: !report,
        },
        {
          Header: t("refundAnalyticsByProduct.refundedUnits"),
          id: "unitsRefunded",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.unitsRefunded.current
                ? row.unitsRefunded.current
                : "-",
              growth: getPercentageDifference(
                row.unitsRefunded.current,
                row.unitsRefunded.prior
              ),
              conditionalFormatting,
              reverseFormatting: true,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: !report,
        },
        {
          Header: t("refundAnalyticsByProduct.refundRateUnits"),
          id: "refundRateUnits",
          accessor: (row: MappedRefundProduct) => {
            return {
              value: row.refundRateUnits.current
                ? `${row.refundRateUnits.current.toFixed(1)}%`
                : "-",
              growth: getPercentageDifference(
                row.refundRateUnits.current,
                row.refundRateUnits.prior,
                true
              ),
              conditionalFormatting,
              reverseFormatting: true,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
      ],
      [currencyRates, homeCurrency, currentCurrency, report]
    );

    const [columnDef, setColumnDef] = useState<Column[]>(columns);

    useEffect(() => {
      setColumnDef(columns);
    }, [columns]);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: pageSize || DEFAULT_PAGE_SIZE,
      pageIndex: 0,
      sortKey: "grossSales",
      sortOrder: "desc",
    });

    const [searchText, setSearchText] = useState("");

    const { isLoading, products, count } = useRefundAnalyticsProductsQuery(
      {
        mid,
        currentRange,
        includeTax,
        marketplaceFilters: {
          marketplaceType,
          marketplaceSubtype,
        },
        paginationParams,
        searchText,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          isLoading: isFetching,
          products: data?.products || [],
          count: data?.count || 0,
        }),
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "grossSales",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const contentRef = React.useRef<HTMLHeadingElement>(null);

    const renderRefundAnalyticsSubComponent = ({ row }: any) => {
      const tableRenderedWidth = Number(contentRef.current?.clientWidth) - 20;

      return (
        <tr>
          <StyledTableCell
            style={{
              maxWidth: "0px",
              overflowX: "visible",
              position: "sticky",
              zIndex: 3,
              left: "0px",
            }}
          >
            <div
              style={{
                width: tableRenderedWidth,
              }}
            >
              <RefundAnalyticsTableChart
                mid={mid}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                productId={row.original?.productId}
                marketplaceFilters={{
                  marketplaceType,
                  marketplaceSubtype,
                }}
                includeTax={includeTax}
                currencyRates={currencyRates}
                homeCurrency={homeCurrency}
              />
            </div>
          </StyledTableCell>
        </tr>
      );
    };

    return (
      <Panel
        id="widget-refund-analytics-product"
        title={t("myStoresWidget.refundAnalyticsByProduct")}
        titleGridSizeMd={9}
        tooltip={t("myStoresWidget.refundAnalyticsByProductTooltip")}
        content={
          report ? (
            <NoScrollTable
              {...{
                columns: columnDef.filter((column) => {
                  return column.isVisible;
                }),
                data: products,
                fetchData,
                loading: isLoading,
                sorting: false,
                pagination: false,
                pageSize,
                isReport: report,
              }}
            />
          ) : (
            <>
              <InlineBlockDiv ref={contentRef} />
              <Table
                columns={columnDef}
                loading={isLoading}
                data={products}
                fetchData={fetchData}
                sorting={true}
                pagination={true}
                pageSize={paginationParams.pageSize}
                pageCount={Math.ceil(count / paginationParams.pageSize)}
                renderRowSubComponent={renderRefundAnalyticsSubComponent}
              />
            </>
          )
        }
        actions={
          <>
            {!report && (
              <>
                <SearchFilter setSearchText={setSearchText} />

                <ColumnSelect
                  columns={columnDef}
                  setColumns={(columns) => setColumnDef(columns)}
                />
              </>
            )}
            {actions}
          </>
        }
      />
    );
  }
);

export default RefundAnalyticsByProduct;
