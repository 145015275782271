import { BaseDialog, BaseDialogTitle } from "./baseDialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";

import PropTypes from "prop-types";
import RaisedButton from "../buttons/raisedButton.tsx";
import React from "react";

// A simple generic message dialog.
// TODO make this more composable, so that we can use most of it for ErrorDialog
class MessageDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, onClose, messages, title } = this.props;

    return (
      <BaseDialog open={open} onClose={onClose}>
        <Grid container direction="column" justifyContent="center">
          <BaseDialogTitle
            data-testid="message-dialog-title"
            id="message-dialog-title"
            onClose={onClose}
          >
            {title}
          </BaseDialogTitle>
        </Grid>
        <Grid item xs={12}>
          <DialogContent data-testid="message-dialog-content">
            {messages.map((message) => (
              <DialogContentText key={message}>{message}</DialogContentText>
            ))}
          </DialogContent>
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          <DialogActions>
            <RaisedButton
              data-testid="message-dialog-ok"
              autoFocus
              onClick={onClose}
              variant="outlined"
            >
              OK
            </RaisedButton>
          </DialogActions>
        </Grid>
      </BaseDialog>
    );
  }
}
MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
export default MessageDialog;
