import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import Alert from "~/components/alert/alertCard";
import BannerWithLink from "~/components/alert/bannerWithLink";
import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ProfitAndLoss from "~/modules/widgets/profitability/profitAndLoss";
import get from "lodash/get";
import { hasFilteredSuffix } from "@utils/marketplaceUtils";
import moment from "moment-timezone";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProfitabilityStore = memo(() => {
  const currentStore = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const { isAdvertisingAuthorised } = useAdvertisingConnected(currentStore);
  const { t } = useTranslation();
  const currentPeriod = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const includeTax = useSelector((state) =>
    Boolean(get(state, "persistentAppSettings.setting.data.includeTax"))
  );

  return (
    <PageBlock>
      <Grid container spacing={4}>
        {get(currentStore, "marketplace") === "amazon" &&
          !isAdvertisingAuthorised && (
            <Grid container item xs={12}>
              <BannerWithLink
                type="info"
                isOpen={true}
                link={"/connectStore/select"}
                linkText={t("generic.linkButton")}
                message={t("profitability.linkAdvertisingMessage")}
              />
            </Grid>
          )}
        {hasFilteredSuffix(get(currentStore, "marketplace")) && (
          <Grid container item xs={12}>
            <Alert type="info" isOpen={true}>
              {t("profitability.filteredChannelWarning")}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <ProfitAndLoss
            title={t("nav.storeProfit")}
            store={currentStore}
            currentRange={currentRange}
            includeTax={includeTax}
            currentCurrency={currentCurrency}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfitAndLoss
            title={t("nav.storeProfit")}
            store={currentStore}
            currentRange={currentRange}
            includeTax={includeTax}
            currentCurrency={currentCurrency}
            isComparison={true}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default ProfitabilityStore;
