import { MarketplaceOverviewExpandedResponse } from "~/store/overview/marketplaceOverview.redux";

const demoData: MarketplaceOverviewExpandedResponse = {
  count: 5,
  rows: [
    {
      marketplace: {
        market: "ebay",
        countryCode: "AUS",
      },
      storeId: 1,
      store: "Shoe Warehouse",
      currency: "AUD",
      sales: {
        value: 15896,
        priorPeriodValue: 15463,
      },
      orders: {
        value: 299,
        priorPeriodValue: 284,
      },
      units: {
        value: 329,
        priorPeriodValue: 311,
      },
      avOrderValue: {
        value: 53.23,
        priorPeriodValue: 54.43,
      },
      avOrderSize: {
        value: 1.1,
        priorPeriodValue: 1.0945,
      },
      salesSplit: 0,
      outOfStockPercentage: 15.0,
      healthStatus: "success",
      buyBoxSnapshot: null,
      forecastTarget: null,
      adspendLimit: null,
      budgetTarget: null,
    },
    {
      marketplace: {
        market: "catch",
        countryCode: "AUS",
      },
      storeId: 2,
      store: "Shoes Direct",
      currency: "AUD",
      sales: {
        value: 9875.0,
        priorPeriodValue: 7824.88,
      },
      orders: {
        value: 189,
        priorPeriodValue: 152,
      },
      units: {
        value: 208,
        priorPeriodValue: 167,
      },
      avOrderValue: {
        value: 52.14,
        priorPeriodValue: 51.52,
      },
      avOrderSize: {
        value: 1.1,
        priorPeriodValue: 1.0967,
      },
      salesSplit: 0,
      outOfStockPercentage: 10.0,
      healthStatus: "success",
      buyBoxSnapshot: null,
      forecastTarget: null,
      adspendLimit: null,
      budgetTarget: null,
    },
    {
      marketplace: {
        market: "iconic",
        countryCode: "AUS",
      },
      storeId: 3,
      store: "Shoes-y",
      currency: "AUD",
      sales: {
        value: 6315.0,
        priorPeriodValue: 6490.24,
      },
      orders: {
        value: 72,
        priorPeriodValue: 76,
      },
      units: {
        value: 94,
        priorPeriodValue: 100,
      },
      avOrderValue: {
        value: 87.3,
        priorPeriodValue: 85.34,
      },
      avOrderSize: {
        value: 1.3,
        priorPeriodValue: 1.3131,
      },
      salesSplit: 0,
      outOfStockPercentage: 43.0,
      healthStatus: "warning",
      buyBoxSnapshot: null,
      forecastTarget: null,
      adspendLimit: null,
      budgetTarget: null,
    },
    {
      marketplace: {
        market: "amazon",
        countryCode: "AUS",
      },
      storeId: 4,
      store: "Shoes Direct",
      currency: "AUD",
      sales: {
        value: 4587.0,
        priorPeriodValue: 3561.34,
      },
      orders: {
        value: 87,
        priorPeriodValue: 67,
      },
      units: {
        value: 96,
        priorPeriodValue: 74,
      },
      avOrderValue: {
        value: 52.5,
        priorPeriodValue: 53.14,
      },
      avOrderSize: {
        value: 1.1,
        priorPeriodValue: 1.0978,
      },
      salesSplit: 0,
      outOfStockPercentage: 9.0,
      healthStatus: "success",
      buyBoxSnapshot: null,
      forecastTarget: null,
      adspendLimit: null,
      budgetTarget: null,
    },
    {
      marketplace: {
        market: "shopify",
        countryCode: "AUS",
      },
      storeId: 5,
      store: "Shoes Direct",
      currency: "AUD",
      sales: {
        value: 2789.0,
        priorPeriodValue: 2656.19,
      },
      orders: {
        value: 48,
        priorPeriodValue: 18,
      },
      units: {
        value: 96,
        priorPeriodValue: 41,
      },
      avOrderValue: {
        value: 134.0,
        priorPeriodValue: 148.89,
      },
      avOrderSize: {
        value: 2.3,
        priorPeriodValue: 2.2927,
      },
      salesSplit: 0,
      outOfStockPercentage: 20.0,
      healthStatus: "noData",
      buyBoxSnapshot: null,
      forecastTarget: null,
      adspendLimit: null,
      budgetTarget: null,
    },
  ],
};

export const salesMixDemoData = {
  data: [
    {
      marketplace: { market: "ebay", countryCode: "AUS" },
      store: "Shoe Warehouse",
      currency: "AUD",
      sales: "15896.0",
      percentage: 40.3,
    },
    {
      marketplace: { market: "catch", countryCode: "AUS" },
      store: "Shoes Direct",
      currency: "AUD",
      sales: "9875.0",
      percentage: 25.0,
    },
    {
      marketplace: { market: "iconic", countryCode: "AUS" },
      store: "Shoes-y",
      currency: "AUD",
      sales: "6315.0",
      percentage: 16.0,
    },
    {
      marketplace: { market: "amazon", countryCode: "AUS" },
      store: "Shoes Direct",
      currency: "AUD",
      sales: "4587.0",
      percentage: 11.6,
    },
    {
      marketplace: { market: "shopify", countryCode: "AUS" },
      store: "Shoes Direct",
      currency: "AUD",
      sales: "2789.0",
      percentage: 7.1,
    },
  ],
};

export const dispatchStatusDemoData = {
  ordersToSend: "311",
  ordersDueToday: "62",
  overdueOrders: "7",
};

export const salesTrendDemoData = [
  { average: 13340.75, sales: 13742.369999999999, timestamp: 1632578400 },
  { average: 26759, sales: 26907.33, timestamp: 1632664800 },
  { average: 39455.64, sales: 40324, timestamp: 1632751200 },
  { average: 53057.81, sales: 56136.979999999996, timestamp: 1632837600 },
  { average: 65614.73, sales: 62656.399999999994, timestamp: 1632924000 },
  { average: 78053.53, sales: 0, timestamp: 1633010400 },
  { average: 90321.14, sales: 0, timestamp: 1633096800 },
];

export const accountHealthDemoData = {
  accountHealth: [
    {
      marketplace: { market: "amazon", countryCode: "AUS" },
      store: "Shoes Direct",
      accountHealth: {
        mid: "Shoes Direct",
        healthStatus: "warning",
      },
    },
    {
      marketplace: { market: "ebay", countryCode: "AUS" },
      store: "Shoe Warehouse",
      accountHealth: {
        mid: "Shoe Warehouse",
        healthStatus: "success",
      },
    },
    {
      marketplace: { market: "catch", countryCode: "AUS" },
      store: "Shoes Direct",
      accountHealth: {
        mid: "Shoes Direct",
        healthStatus: "success",
      },
    },
    {
      marketplace: { market: "iconic", countryCode: "AUS" },
      store: "Shoes-y",
      accountHealth: {
        mid: "Shoes-y",
        healthStatus: "noData",
      },
    },
    {
      marketplace: { market: "shopify", countryCode: "AUS" },
      store: "Shoes Direct",
      accountHealth: {
        mid: "Shoes Direct",
        healthStatus: "noData",
      },
    },
  ],
  count: 5,
};

export const overdueOrdersSummaryDemoData = {
  fetching: false,
  overdueOrdersSummary: {
    rows: [
      {
        marketplace: { market: "amazon", countryCode: "AUS" },
        store: "Shoes Direct",
        overdueOrderCount: "4",
      },
      {
        marketplace: { market: "ebay", countryCode: "AUS" },
        store: "Shoe Warehouse",
        overdueOrderCount: "2",
      },
      {
        marketplace: { market: "catch", countryCode: "AUS" },
        store: "Shoes Direct",
        overdueOrderCount: "1",
      },
      {
        marketplace: { market: "iconic", countryCode: "AUS" },
        store: "Shoes-y",
        overdueOrderCount: "0",
      },
      {
        marketplace: { market: "shopify", countryCode: "AUS" },
        store: "Shoes Direct",
        overdueOrderCount: "0",
      },
    ],
    count: 5,
  },
};

export default demoData;
