import { CHART_TYPE, ChartOption } from "./cohortTrendChart";

import React from "react";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { useTranslation } from "react-i18next";

const CohortTrendTypeSelect = ({
  setSelected,
  selected,
}: {
  setSelected: (value: any) => void;
  selected: any;
}) => {
  const { t } = useTranslation();
  const CHART_OPTIONS: ChartOption[] = [
    {
      value: CHART_TYPE.Customer,
      label: t("customerLifetimeValueChart.cohortTrendCustomers"),
    },
    {
      value: CHART_TYPE.Revenue,
      label: t("customerLifetimeValueChart.cohortTrendRevenue"),
    },
  ];

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setSelected(option);
    }
  };

  return (
    <TableFilter
      currentValue={selected?.value}
      handleChange={switchChartType}
      options={CHART_OPTIONS}
      width="200px"
    />
  );
};

export default CohortTrendTypeSelect;
