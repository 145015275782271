import React, { memo } from "react";

import DownloadCsvIcon from "~/icons/downloadCsvIcon";
import { IconButton } from "@material-ui/core";
import { baseUrl } from "../../configs";
import styled from "styled-components";

const SmallIcon = styled(DownloadCsvIcon)`
  height: 18px;
  width: 18px;
  margin: -5px;
`;

interface DownloadCsvProps {
  entityType: string;
  params: Record<string, any>;
  downloadUrl: string;
}

const DownloadCsv = memo<DownloadCsvProps>(function DownloadCsv({
  entityType,
  params,
  downloadUrl,
}) {
  const paramString = new URLSearchParams({
    ...params,
    pageSize: "9999",
    format: "csv",
  });

  const defaultDownloadUrl =
    `${baseUrl}/api/advertising-runtime-service/api/advertising` +
    (params.campaignId ? `/campaign/${params.campaignId}` : "") +
    (params.adGroupId ? `/adGroup/${params.adGroupId}` : "") +
    `/${entityType}?${paramString.toString()}`;

  const url = downloadUrl
    ? `${baseUrl}/${downloadUrl}?${paramString.toString()}`
    : defaultDownloadUrl;

  return (
    <IconButton href={url} title={"Download as CSV"} download>
      <SmallIcon />
    </IconButton>
  );
});

export default DownloadCsv;
