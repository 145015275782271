import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";

import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import SyncStatusTable from "~/modules/buybox/syncStatusTable";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const SyncStatusDialog = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={onClose}>
        {t("myStoresWidget.buyBoxSync.mainTitle")}
      </BaseDialogTitle>
      <DialogContent>
        <DialogContentText>
          <DialogContentGrid container spacing={2}>
            <Grid item xs={12}>
              <SyncStatusTable data={data} />
            </Grid>
          </DialogContentGrid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mb={8} width="100%">
          <Grid spacing={2} container item xs={12} direction="row-reverse">
            <Grid container justifyContent="center" item xs={12}>
              <RaisedButton onClick={onClose} variant="outlined">
                {t("generic.closeButton")}
              </RaisedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </BaseDialog>
  );
};

SyncStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default SyncStatusDialog;
