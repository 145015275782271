import { Box, Typography } from "@material-ui/core";
import { Done, SyncRounded } from "@material-ui/icons";

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  flex: 0 0 12px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  margin-right: 0.5rem;
  ${({ complete, theme }) => `
    color: ${
      complete ? theme.palette.success.main : theme.palette.background.default
    };
    background-color: ${
      complete ? theme.palette.background.default : theme.palette.grey["500"]
    };
    border-color: ${
      complete ? theme.palette.success.main : theme.palette.grey["500"]
    };
  `}
  & svg {
    font-size: 10px;
    & path {
      stroke-width: 2;
    }
  }
`;

export const FeatureIndicator = ({ label, complete }) => (
  <Box display="flex" mr={3} alignItems="center">
    {complete ? (
      <IconWrapper complete>
        <Done />
      </IconWrapper>
    ) : (
      <IconWrapper>
        <SyncRounded />
      </IconWrapper>
    )}
    <Typography variant="body2">{label}</Typography>
  </Box>
);

FeatureIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  complete: PropTypes.bool.isRequired,
};
