import { Grid, Typography } from "@material-ui/core";
import React, { memo, useEffect, useRef, useState } from "react";

import { CROSS_COUNTRY_MARKETPLACES } from "~/utils/marketplaceUtils";
import { CountryLogo } from "~/img/flags/country_logos";
import { MarketplaceIcon } from "~/img/marketplaces/icons/marketplaceIcon";
import { MenuItem } from "../sideNavigation/sideNavConstants";
import styled from "styled-components";
import { useSideNavConfig } from "~/hooks/useSideNavConfig";
import { useTranslation } from "react-i18next";

const ICONS_WIDTH = 65;

const ExpandableGrid = styled(Grid)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  display: flex;
  width: 3px;
`;

const TextWrapper = styled(Grid)<{ $maxwidth: number }>`
  max-width: ${({ $maxwidth }) => `${$maxwidth}px`};
`;

interface StoreLinkProps {
  market?: string;
  mid?: string;
  countryCode?: string;
  marketplaceSubtype?: string;
  activeLink?: boolean;
  hideDivider?: boolean;
  changeStore?: (market: string, mid: string, menuItems: MenuItem[]) => void;
  shopName?: string;
  fontSize?: string;
}
export const StoreLink = memo<StoreLinkProps>(function StoreLink({
  market,
  mid,
  countryCode,
  marketplaceSubtype,
  activeLink,
  changeStore,
  shopName,
  fontSize,
}) {
  const { t } = useTranslation();
  const defaultMaxWidth = 100;
  const [maxWidth, setMaxWidth] = useState(defaultMaxWidth);
  const ref = useRef<HTMLDivElement | null>(null);
  const name = shopName || (mid || "").split("@")[0];
  const menuItems = useSideNavConfig(market);

  const adjustMaxWidth = () => {
    if (
      ref.current &&
      ref.current.offsetWidth > defaultMaxWidth + ICONS_WIDTH
    ) {
      setMaxWidth(ref.current.offsetWidth - ICONS_WIDTH);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      adjustMaxWidth();
    });
  }, [ref]);

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", adjustMaxWidth);
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", adjustMaxWidth);
  }, []); // Empty array ensures that effect is only run on mount

  const selectStore = () => {
    if (activeLink && changeStore && market && mid) {
      changeStore(market, mid, menuItems);
    }
  };

  return (
    <ExpandableGrid
      id="store-link"
      container
      item
      wrap="nowrap"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      onClick={selectStore}
      ref={ref}
    >
      {!market || !mid ? (
        <TextWrapper item $maxwidth={maxWidth}>
          <Typography
            variant={fontSize === "large" ? "h2" : "body2"}
            noWrap
            color="textPrimary"
          >
            {t("storeLink.selectStore")}
          </Typography>
        </TextWrapper>
      ) : (
        <>
          <FlexGrid item>
            <MarketplaceIcon
              market={market}
              marketplaceSubtype={marketplaceSubtype}
            />
            <Spacer />
            {market in CROSS_COUNTRY_MARKETPLACES ? (
              <CountryLogo currencyCode={CROSS_COUNTRY_MARKETPLACES[market]} />
            ) : (
              <CountryLogo code={countryCode} />
            )}
          </FlexGrid>
          <TextWrapper item $maxwidth={maxWidth}>
            <Typography
              variant={fontSize === "large" ? "h2" : "body2"}
              noWrap
              color={fontSize === "large" ? "inherit" : "textPrimary"}
            >
              {name}
            </Typography>
          </TextWrapper>
        </>
      )}
    </ExpandableGrid>
  );
});

export default StoreLink;
