import React, { memo, useMemo } from "react";

import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import PrimeIcon from "~/img/amazon_prime.png";
import PropTypes from "prop-types";
import Table from "~/components/table/table";
import { Tooltip } from "@material-ui/core";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getCurrencySymbol } from "~/utils/currencyUtils.js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

const formatPrice = (currencyCode, price) =>
  `${getCurrencySymbol[currencyCode]}${price}`;

const SyncTable = memo(({ data }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: "Product",
        id: "title",
        accessor: (row) => ({
          value: row.title,
          image: row.imageUrl,
          icon: row.isFulfilledByAmazon && (
            <Tooltip title={t("generic.fulfilledByAmazon")}>
              <SmallIcon src={PrimeIcon} />
            </Tooltip>
          ),
        }),
        Cell: LinkAndImageCell,
        colSpan: 3,
      },
      {
        Header: "Previous Price",
        id: "originalPrice",
        accessor: (row) => formatPrice(row.currencyCode, row.originalPrice),
        Cell: ValueCell,
        align: "right",
      },
      {
        Header: "Updated Price",
        id: "updatedPrice",
        accessor: (row) => formatPrice(row.currencyCode, row.updatedPrice),
        Cell: ValueCell,
        align: "right",
      },
    ],
    []
  );

  return <Table columns={columns} data={data} pageSize={1000} width="200px" />;
});

SyncTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SyncTable;
