import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";

import ErrorButton from "~/components/buttons/errorButton";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import SyncTable from "~/modules/buybox/syncTable";
import styled from "styled-components";

const StyledInfoIcon = styled(InfoIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const SyncDialog = ({ open, onClose, onConfirm, data }) => (
  <BaseDialog maxWidth="md" open={open} onClose={onClose}>
    <BaseDialogTitle onClose={onClose}>Sync to Amazon</BaseDialogTitle>
    <DialogContent>
      <DialogContentText>
        <DialogContentGrid container spacing={2}>
          <Grid container item xs={12} alignItems="center">
            <StyledInfoIcon />
            {data && data.length === 1 && (
              <Typography variant="body1" style={{ flex: 1 }}>
                1 product price has been modified and will be synced to Amazon
              </Typography>
            )}
            {data && data.length > 1 && (
              <Typography variant="body1" style={{ flex: 1 }}>
                {data.length} product prices have been modified and will be
                synced to Amazon
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <SyncTable data={data} />
          </Grid>
        </DialogContentGrid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Box mb={8} width="100%">
        <Grid spacing={2} container item xs={12} direction="row-reverse">
          <Grid container justifyContent="center" item xs={12} sm={6}>
            <RaisedButton
              onClick={onConfirm}
              variant="contained"
              color="primary"
            >
              {"Confirm"}
            </RaisedButton>
          </Grid>
          <Grid container justifyContent="center" item xs={12} sm={6}>
            <ErrorButton onClick={onClose} variant="outlined">
              {"Cancel"}
            </ErrorButton>
          </Grid>
        </Grid>
      </Box>
    </DialogActions>
  </BaseDialog>
);

SyncDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default SyncDialog;
