import { Cell } from "./cell";
import { ProfitAndLossColumn } from "./profitAndLossTable";
import React from "react";
import { Row } from "./tableData";

interface SectionHeaderProps {
  title: string;
  columns: ProfitAndLossColumn[];
  width?: string;
}

export const SectionHeader = ({
  title,
  columns,
  width,
}: SectionHeaderProps) => {
  return (
    <Row $width={width} $isSectionHeader>
      {columns.map(({ divider, width, stickyLeft }, index) => (
        <Cell
          key={`${title}-${index}`}
          // title in the first column and rest empty
          value={index === 0 ? title : ""}
          startAlign
          bold
          isSectionHeader
          divider={divider}
          width={width}
          uppercase
          stickyLeft={stickyLeft}
        />
      ))}
    </Row>
  );
};
