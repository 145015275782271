import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo } from "react";

import ProfitabilityChartWidget from "../widgets/profitability/profitabilityChart";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ProfitabilityChartProps {
  market?: string;
  mid?: string;
}

const ProfitabilityChart = memo<ProfitabilityChartProps>(
  function ProfitabilityChart({ mid }) {
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );

    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );

    return (
      <ProfitabilityChartWidget
        mid={mid}
        includeTax={includeTax}
        currentPeriod={currentPeriod}
        currentFilter={currentFilter}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        timezone={selectedTimezone}
      />
    );
  }
);

export default ProfitabilityChart;
