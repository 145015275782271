import { Link, Typography } from "@material-ui/core";

import FloatingAlertCard from "../../../components/alert/floatingAlertCard";
import React from "react";
import useQueryParams from "../../../hooks/useQueryParams";

const PlanUpgradeAlert = () => {
  const params = useQueryParams();
  const needsPlanUpgrade = params.get("needSubscription") === "true";
  const [open, setOpen] = React.useState(needsPlanUpgrade);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <FloatingAlertCard
      data-testid="plan-upgrade-alert"
      isOpen={open}
      onClose={onClose}
      type="warning"
    >
      <Typography variant="body1">
        Your current plan does not have access to this feature. <br />
        Please upgrade your plan if you would like to continue, or{" "}
        <Link
          component="a"
          href="https://merchantspring.zendesk.com/hc/en-us"
          target="_blank"
        >
          contact us
        </Link>
        .
      </Typography>
    </FloatingAlertCard>
  );
};

export default PlanUpgradeAlert;
