import FloatingAlertCard from "../../../components/alert/floatingAlertCard";
import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@material-ui/core";
import { getPlanName } from "../plan/plan";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const SuccessfulSubscriptionAlert = ({ planId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useQueryParams();
  const subscriptionSuccess = params.get("subscriptionSuccess");
  const [open, setOpen] = React.useState(Boolean(subscriptionSuccess));

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close the toast automatically when the user changes pages
    const unlisten = history.listen(() => {
      onClose();
    });
    // Unsubscribe
    return unlisten;
  });

  return (
    <FloatingAlertCard
      data-testid="successful-subscription-alert"
      isOpen={Boolean(open && planId)}
      onClose={onClose}
      type="success"
    >
      <Typography variant="body1">
        Subscription successful!
        <br />
        You are now on a {t(getPlanName(planId))} plan.
      </Typography>
    </FloatingAlertCard>
  );
};
SuccessfulSubscriptionAlert.defaultProps = {
  planId: "",
};
SuccessfulSubscriptionAlert.propTypes = {
  planId: PropTypes.string.isRequired,
};

export default SuccessfulSubscriptionAlert;
