import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useState } from "react";
import { editBuyBoxPrice, resetBuyBoxPrice } from "~/store/buyBox.redux";
import { useDispatch, useSelector } from "react-redux";

import Bold from "~/components/typography/bold";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import SyncIcon from "@material-ui/icons/Sync";
import UndoIcon from "@material-ui/icons/Undo";
import { getCurrencySymbol } from "~/utils/currencyUtils.js";
import styled from "styled-components";

const MyOffer = styled(Bold)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledEditIcon = styled(EditIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const StyledUndoIcon = styled(UndoIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const StyledSyncIcon = styled(SyncIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 14px;
  height: 14px;
  padding: 0 3px 2px 5px;
`;

export const StyledDoneIcon = styled(DoneIcon)`
  fill: ${({ theme }) => theme.palette.success.main};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.palette.error.main};
  width: 14px;
  height: 14px;
  padding-bottom: 2px;
`;

const EditablePriceCell = memo(({ cell }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const syncPending = useSelector((state) => state.buyBox.syncPending);
  const [editMode, setEditMode] = useState(false);

  const {
    currencyCode,
    sellerSku,
    title,
    imageUrl,
    isFulfilledByAmazon,
    originalPrice,
    isPriceDirty,
  } = cell.column.getProps(cell.row.original);
  const syncPendingEntry = syncPending.find((p) => p.sellerSku === sellerSku);

  // isAmazon and isMyOffer are only populated in the seller table
  // these values will be undefined in the product table
  const { isAmazon, isMyOffer } = cell.row.original;

  // Price is editable only if isMyOffer is undefined (product table) or
  // explicitly true (seller table). Price is not editable when original
  // price is not defined (e.g., inactive items)
  const isEditable = isMyOffer !== false && originalPrice;

  const originalFieldValue =
    isEditable && syncPendingEntry ? syncPendingEntry.updatedPrice : cell.value;
  const [fieldValue, setFieldValue] = useState(originalFieldValue);

  const displayValue = currencyCode
    ? `${getCurrencySymbol[currencyCode]}${originalFieldValue}`
    : "-";

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid container alignItems="center" justifyContent="flex-end">
        {editMode ? (
          <>
            <TextField
              type="number"
              autoFocus
              defaultValue={fieldValue}
              inputProps={{
                step: "0.01",
                style: {
                  ...theme.typography.body2,
                  textAlign: "right",
                  width: "42px",
                },
              }}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(parseFloat(value).toFixed(2));
              }}
            />
            <Tooltip title="Save">
              <IconButton
                size="small"
                aria-label="Save"
                onClick={() => {
                  setEditMode(false);
                  if (fieldValue !== originalFieldValue) {
                    dispatch(
                      editBuyBoxPrice(
                        sellerSku,
                        title,
                        imageUrl,
                        isFulfilledByAmazon,
                        currencyCode,
                        originalPrice,
                        fieldValue
                      )
                    );
                  }
                }}
              >
                <StyledDoneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                size="small"
                aria-label="Cancel"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                <StyledCloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : isAmazon ? (
          <>
            <Bold variant="body2">{displayValue}</Bold>
          </>
        ) : isMyOffer ? (
          <>
            <MyOffer variant="body2">{displayValue}</MyOffer>
          </>
        ) : (
          <>
            <Typography variant="body2">{displayValue}</Typography>
          </>
        )}
        {isPriceDirty && (
          <Tooltip title="Waiting for price update confirmation">
            <StyledSyncIcon fontSize="small" />
          </Tooltip>
        )}
        {isEditable && !editMode && !isPriceDirty && (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              aria-label="Edit"
              onClick={() => {
                setFieldValue(originalFieldValue);
                setEditMode(true);
              }}
              style={{
                marginLeft: "2px",
              }}
            >
              <StyledEditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {isEditable && !editMode && Boolean(syncPendingEntry) && (
          <Tooltip title="Undo">
            <IconButton
              size="small"
              aria-label="Undo"
              onClick={() => {
                dispatch(resetBuyBoxPrice(sellerSku));
              }}
              style={{
                marginLeft: "2px",
              }}
            >
              <StyledUndoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
});

EditablePriceCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.any,
    column: PropTypes.any,
    value: PropTypes.any,
  }).isRequired,
};

export default EditablePriceCell;
