import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { DATETIME_PERIODS } from "../../../store/utils/dateTimeUtils";
import MultiChartTooltip from "../multiComposedChart/multiChartTooltip";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useLayoutProps } from "../chartUtils/chartComponents";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const CHART_TITLES = {
  [DATETIME_PERIODS.DAY]: "chartTitlesProfit.day",
  [DATETIME_PERIODS.YESTERDAY]: "chartTitlesProfit.yesterday",
  [DATETIME_PERIODS.WEEK]: "chartTitlesProfit.week",
  [DATETIME_PERIODS.LAST30]: "chartTitlesProfit.last30",
  [DATETIME_PERIODS.YEAR]: "chartTitlesProfit.year",
  [DATETIME_PERIODS.CUSTOM]: "chartTitlesProfit.custom",
};

const getXAxisProps = (
  currentPeriod,
  isCustomPeriodSameDay = false,
  ticks = null,
  timezone
) => {
  const tickFormatter =
    currentPeriod === DATETIME_PERIODS.DAY || isCustomPeriodSameDay
      ? (tick) => moment.unix(tick).tz(timezone).format("HH")
      : (tick) => moment.unix(tick).tz(timezone).format("MMMM Do");
  return {
    dataKey: "startTime",
    axisLine: false,
    type: "category",
    interval: "preserveStartEnd",
    tickLine: false,
    domain: ["dataMin", "dataMax"],
    padding: { left: 30, right: 30 },
    tick: { fontSize: 14 },
    tickFormatter,
    ...(ticks ? { ticks } : {}),
  };
};

const TableBarChart = memo(
  ({
    chartData,
    dataDefinition,
    currentPeriod,
    currency,
    fromDate,
    toDate,
    timezone,
  }) => {
    const { height, margin } = useLayoutProps("tableChart");
    const { t } = useTranslation();
    const theme = useTheme();

    const isCustomPeriodSameDay =
      fromDate &&
      toDate &&
      moment
        .unix(fromDate)
        .tz(timezone)
        .isSame(moment.unix(toDate).tz(timezone), "day");

    const XAxisProps =
      chartData &&
      getXAxisProps(
        currentPeriod,
        isCustomPeriodSameDay,
        chartData.length
          ? [chartData[0].startTime, chartData[chartData.length - 1].startTime]
          : undefined,
        timezone
      );

    return (
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box
            pb={1}
            pt="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6">
              {t(CHART_TITLES[currentPeriod])}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={height}>
            <BarChart data={chartData} margin={margin}>
              <XAxis {...XAxisProps} />
              <ReferenceLine y={0} strokeWidth={2} />
              <Tooltip
                content={
                  <MultiChartTooltip
                    currentPeriod={currentPeriod}
                    items={dataDefinition}
                    currency={currency}
                    timezone={timezone}
                  />
                }
              />
              {dataDefinition.map((definition) => (
                <Bar
                  key={definition.dataKey}
                  dataKey={definition.dataKey}
                  fill={definition.color}
                >
                  {chartData &&
                    chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry[definition.dataKey] > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }
                      />
                    ))}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    );
  }
);

TableBarChart.defaultProps = {
  chartData: [],
  dataDefinition: [],
};

TableBarChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  dataDefinition: PropTypes.array.isRequired,
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  currentPeriod: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default TableBarChart;
