import { Box, Grid, TableCell, Typography } from "@material-ui/core";

import Light from "~/components/typography/light";
import Medium from "~/components/typography/medium";
import PageBlock from "~/components/containers/pageBlock";
import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import React from "react";
import StatusText from "~/components/typography/status";
import styled from "styled-components";

export const SectionHeading = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const SubscriptionPageBlock = styled(PageBlock)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const FieldLabel = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  font-weight: 500;
`;

export const FieldValue = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  ${({ theme }) => `
    color: ${theme.palette.grey["600"]};
  `}
`;

export const FormButton = styled(RaisedButton)`
  ${({ theme }) => `
    margin: 24px 16px 24px 0;
    ${theme.breakpoints.only("xs")} {
      min-width: 120px;
    }
    ${theme.breakpoints.up("sm")} {
      min-width: 150px;
    }
  `}
`;

export const ErrorFormButton = styled(FormButton)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const StyledTableCell = styled(TableCell)`
  ${({ theme }) => `
    color: ${theme.palette.grey["600"]};
    font-size: 12px;
  `}
`;

export const SubscriptionPanelContent = styled(Grid).attrs(() => ({
  spacing: 2,
}))`
  padding: 1rem;
`;

const LeftItem = styled(Grid)`
  text-align: left;
`;
const RightItem = styled(Grid)`
  text-align: right;
`;

export const SummaryItem = ({
  leftText,
  leftSubtitle,
  rightText,
  rightSubtitle,
}) => (
  <>
    <LeftItem xs={8} item>
      <Medium>{leftText}</Medium>
      {leftSubtitle && <Light variant="subtitle1">{leftSubtitle}</Light>}
    </LeftItem>
    <RightItem xs={4} item>
      <Medium>{rightText}</Medium>
      {rightSubtitle && <Light variant="subtitle1">{rightSubtitle}</Light>}
    </RightItem>
  </>
);

SummaryItem.propTypes = {
  leftText: PropTypes.node,
  leftSubtitle: PropTypes.node,
  rightText: PropTypes.node,
  rightSubtitle: PropTypes.node,
};

const MediumStatusText = styled(StatusText)`
  font-weight: 500;
`;

const MediumStatusTextEnd = styled(StatusText)`
  justify-content: flex-end;
  font-weight: 500;
`;

const MediumStatusTextStrike = styled(StatusText)`
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 5px;
`;

export const PromoItem = ({
  leftText,
  leftSubtitle,
  rightStrikethrough,
  rightText,
  rightSubtitle,
}) => (
  <Grid container>
    <LeftItem xs={7} item>
      <MediumStatusText status="success">{leftText}</MediumStatusText>
      {leftSubtitle && <Light variant="subtitle1">{leftSubtitle}</Light>}
    </LeftItem>
    <RightItem xs={5} item>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <MediumStatusTextStrike status="error">
          {rightStrikethrough}{" "}
        </MediumStatusTextStrike>
        <MediumStatusTextEnd status="success">{rightText}</MediumStatusTextEnd>
      </Box>
      {rightSubtitle && <Light variant="subtitle1">{rightSubtitle}</Light>}
    </RightItem>
  </Grid>
);

PromoItem.propTypes = {
  leftText: PropTypes.node,
  leftSubtitle: PropTypes.node,
  rightStrikethrough: PropTypes.node,
  rightText: PropTypes.node,
  rightSubtitle: PropTypes.node,
};
