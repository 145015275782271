import { CurrencyRate, GlobalVar } from "../typedef/store";

import { Dispatch } from "redux";
import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";

export const GET_TOKEN = "GET_TOKEN";
export const SWITCH_LOADINGDATA = "SWITCH_LOADINGDATA";
export const LOAD_CURRENCY_RATES = "LOAD_CURRENCY_RATES";
export const SET_DESTINATION_ROUTE = "SET_DESTINATION_ROUTE";
export const SET_DEMO_BANNER_VISIBLE = "SET_DEMO_BANNER_VISIBLE";

// All the unknown/any types are just there because this was originally a .js file.
interface GetTokenAction {
  type: typeof GET_TOKEN;
  payload: string;
}
interface SwitchLoadingDataAction {
  type: typeof SWITCH_LOADINGDATA;
  payload: boolean;
}
interface LoadCurrencyRatesAction {
  type: typeof LOAD_CURRENCY_RATES;
  payload: CurrencyRate[];
}
interface SetDestinationRouteAction {
  type: typeof SET_DESTINATION_ROUTE;
  payload: string;
}
interface SetDemoBannerVisibleAction {
  type: typeof SET_DEMO_BANNER_VISIBLE;
  payload: boolean;
}

type GlobalVarAction =
  | GetTokenAction
  | SwitchLoadingDataAction
  | LoadCurrencyRatesAction
  | SetDestinationRouteAction
  | SetDemoBannerVisibleAction;

axios.defaults.withCredentials = true;

const initialState: GlobalVar = {
  loadingData: false,
  currencyRates: [],
  token: "",
  demoBannerVisible: true,
  destinationRoute: "/",
};

export const token = "rcJYBbB6-ySkfSCbXsznwCMzsLftsYfyXuqY";

export const globalVar = (state = initialState, action: GlobalVarAction) => {
  switch (action.type) {
    case SWITCH_LOADINGDATA:
      return { ...state, loadingData: action.payload };
    case GET_TOKEN:
      return { ...state, token: action.payload };
    case LOAD_CURRENCY_RATES:
      return { ...state, currencyRates: action.payload };
    case SET_DESTINATION_ROUTE:
      return { ...state, destinationRoute: action.payload };
    case SET_DEMO_BANNER_VISIBLE:
      return { ...state, demoBannerVisible: action.payload };
    default:
      return state;
  }
};

export function switchLoadingData(loadingData: any) {
  return (dispatch: any) => {
    dispatch({ type: SWITCH_LOADINGDATA, payload: loadingData });
  };
}

export function loadCurrencyRates() {
  return async (dispatch: any) => {
    const res = await axios.get(`${baseUrl}/api/currencyRates`).catch((e) => {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(
        dispatch,
        msg || statusText,
        get(err, "status"),
        "currencyRates"
      );
    });

    if (res) {
      if (isHttpResponseValid(res.status)) {
        return dispatch({ type: LOAD_CURRENCY_RATES, payload: res.data });
      } else {
        return setError(
          dispatch,
          res.data.message,
          res.status,
          "currencyRates"
        );
      }
    }
    return setError(dispatch, undefined, undefined, "currencyRates");
  };
}

export function setDestinationRoute(pathname: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_DESTINATION_ROUTE, payload: pathname });
  };
}

export function setDemoBannerVisible(isVisible: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_DEMO_BANNER_VISIBLE, payload: isVisible });
  };
}
