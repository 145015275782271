import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import React from "react";

const ebayAuthScopes = [
  "https://api.ebay.com/oauth/api_scope",
  "https://api.ebay.com/oauth/api_scope/sell.marketing.readonly",
  "https://api.ebay.com/oauth/api_scope/sell.marketing",
  "https://api.ebay.com/oauth/api_scope/sell.inventory.readonly",
  "https://api.ebay.com/oauth/api_scope/sell.inventory",
  "https://api.ebay.com/oauth/api_scope/sell.account.readonly",
  "https://api.ebay.com/oauth/api_scope/sell.account",
  "https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly",
  "https://api.ebay.com/oauth/api_scope/sell.fulfillment",
  "https://api.ebay.com/oauth/api_scope/sell.analytics.readonly",
];

const authUrlSearchParams = new URLSearchParams({
  response_type: "code",
  redirect_uri: process.env.EBAY_RU_NAME,
  scope: ebayAuthScopes.join(" "),
  client_id: process.env.EBAY_CLIENT_ID,
  prompt: "login",
});
const authUrl = `https://auth.ebay.com/oauth2/authorize?${authUrlSearchParams.toString()}`;

/**
 * @param {(code: string) => void} onCode
 */
export default function (onCode) {
  const childWindow = React.useRef(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };
  React.useEffect(() => {
    const messageListener = (event) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "ebay"
      ) {
        const { params } = event.data;
        closeChildWindow();
        onCode(params.code);
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode]);
  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        authUrl,
        "authPopup-mm-ebay",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=599,height=600,top=240,left=660.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}
