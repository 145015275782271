import { Box, CircularProgress, Grid } from "@material-ui/core";
import React, { memo, useEffect, useMemo } from "react";

import Panel from "~/components/panel/panel";
import PerformanceSummary from "./performanceSummary";
import { Range } from "~/typedef/store";
import { StoreState } from "~/typedef/store";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useDispatch } from "react-redux";
import { useMarketingAllCampaignsTotalsQuery } from "~/store/mystore/marketing.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OverviewTotalsPanelProps {
  mid: string;
  userInfo: User;
  currentRange: Range;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentCurrency: string;
  timezone: string;
  includeTax: boolean;
  condensed?: boolean;
  report?: boolean;
  conditionalFormatting?: boolean;
}

const OverviewTotalsPanel = memo<OverviewTotalsPanelProps>(
  function OverviewTotalsPanel({
    mid,
    userInfo,
    currentRange,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentCurrency,
    report,
    conditionalFormatting,
    timezone,
    condensed,
    includeTax,
  }) {
    const { t } = useTranslation();
    const homeCurrency: string = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const { data, loading } = useMarketingAllCampaignsTotalsQuery(
      {
        customerId: userInfo._id,
        mid,
        countryCode,
        marketplaceType,
        marketplaceSubtype,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        priorFromDate: currentRange.priorFromDate,
        priorToDate: currentRange.priorToDate,
        timezone,
        includeTax,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            data: data?.summaryData ? data.summaryData : null,
            loading: isFetching,
          };
        },
      }
    );

    const footerLink = {
      url: marketplaceLink(marketplaceType, mid, "marketingOverview"),
      label: t("generic.viewAllLink"),
    };

    return (
      <Panel
        id="widget-marketing-campaign-chart"
        title={t("advertisingDashboardWidget.performanceTotals.mainTitle")}
        tooltip={t("advertisingDashboardWidget.performanceTotals.mainTooltip")}
        footerLink={!report && condensed ? footerLink : undefined}
        content={
          <Box p={2} height="100%" display="flex" alignItems="center">
            {loading || !data ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceSummary
                    data={data}
                    homeCurrency={homeCurrency}
                    currentCurrency={currentCurrency}
                    isOverview={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default OverviewTotalsPanel;
