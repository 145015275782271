import { Box, PropTypes, Typography } from "@material-ui/core";
import React, { memo } from "react";

import AlertCard from "./alertCard";
import { AlertIcon } from "./messageAlert";
import CombinedLink from "../links/link";
import { PriorityHigh } from "@material-ui/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledAlertIcon = styled(AlertIcon)`
  background-color: ${({ theme }) => theme.palette.warning.light};
`;

const FloatRight = styled.div`
  display: flex;
  float: right;
  margin-left: auto;
`;

interface BannerProps {
  marketplace: string;
  flat?: boolean;
}

const NeedToReconnectBanner = memo(({ marketplace, flat }: BannerProps) => {
  const { t } = useTranslation();

  const AlertContent = ({ textAlign }: { textAlign: PropTypes.Alignment }) => (
    <>
      <StyledAlertIcon>
        <PriorityHigh fontSize="small" />
      </StyledAlertIcon>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="body1" color="textSecondary" align={textAlign}>
          {t("generic.oldTokenMessage")}
        </Typography>
      </Box>
    </>
  );

  return flat ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
      minHeight={200}
      width="80%"
    >
      <AlertContent textAlign={"center" as PropTypes.Alignment} />
      <CombinedLink
        {...{
          to: `/connectStore/select`,
          color: "textSecondary",
          variant: "body1",
          underline: "always",
        }}
      >
        {t("generic.reconnectNowLink")}
      </CombinedLink>
    </Box>
  ) : (
    <AlertCard isOpen={true} type="plain">
      <AlertContent textAlign={"left" as PropTypes.Alignment} />
      <FloatRight>
        <CombinedLink
          {...{
            to: `/connectStore/select`,
            color: "textSecondary",
            variant: "body1",
            underline: "always",
          }}
        >
          {t("generic.reconnectNowLink")}
        </CombinedLink>
      </FloatRight>
    </AlertCard>
  );
});

export default NeedToReconnectBanner;
