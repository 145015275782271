import { Typography, TypographyProps } from "@material-ui/core";

import styled from "styled-components";

interface BoldProps extends TypographyProps {
  $color?: string;
}
const Bold = styled(Typography)`
  ${({ $color }: BoldProps) => ($color ? `color: ${$color};` : ``)}
  font-weight: 700;
`;

export default Bold;
