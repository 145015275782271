import { CircularProgress, useTheme } from "@material-ui/core";
import RaisedButton, { RaisedButtonProps } from "./raisedButton";

import React from "react";

interface LoadingButtonProps extends RaisedButtonProps {
  loading?: boolean;
  circularProgressSize?: number | string;
}

const LoadingButton = ({
  loading,
  circularProgressSize = 20,
  children,
  ...props
}: LoadingButtonProps) => {
  const theme = useTheme();
  return (
    <RaisedButton {...props}>
      {loading ? (
        <CircularProgress
          size={circularProgressSize}
          style={{ color: theme.palette.common.white }}
        />
      ) : (
        children
      )}
    </RaisedButton>
  );
};

export default LoadingButton;
