import { Cell, GrowthBlock } from "./valueAndGrowthCell";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import StatusText from "~/components/typography/status";

export const ChangeCell = memo(
  (props: {
    cell: {
      value: {
        value: string | number;
        positiveGrowth: boolean;
        conditionalFormatting?: boolean;
      };
    };
  }) => {
    const { cell } = props;
    const value = cell.value.value;
    const positiveGrowth = cell.value.positiveGrowth;

    let arrow: React.ReactElement, status: "success" | "error";
    if (positiveGrowth) {
      status = "success";
      arrow = <UpArrow fontSize="inherit" {...{ status }} />;
    } else {
      status = "error";
      arrow = <DownArrow fontSize="inherit" {...{ status }} />;
    }

    return (
      <Cell>
        <GrowthBlock>
          {value === "-" ? (
            value
          ) : (
            <StatusText
              variant="body2"
              align="right"
              paragraph={false}
              status={status}
            >
              {arrow}
              {value}
            </StatusText>
          )}
        </GrowthBlock>
      </Cell>
    );
  }
);
