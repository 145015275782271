import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import PanelSelect from "./panelSelect";
import { useTranslation } from "react-i18next";

interface ComparisonPeriodSelectProps {
  setCompare: (compare: COMPARISON_PERIOD) => void;
  currentCompare: COMPARISON_PERIOD;
  width?: string;
}

export const ComparisonPeriodSelect = memo<ComparisonPeriodSelectProps>(
  function ComparisonPeriodSelect({ setCompare, currentCompare, width }) {
    const { t } = useTranslation();

    const COMPARISON_PERIODS = [
      { value: "THISYEAR", label: t("generic.comparisonPeriod.priorPeriod") },
      { value: "LASTYEAR", label: t("generic.comparisonPeriod.lastYear") },
      {
        value: "PREVIOUSFISCALPERIOD",
        label: t("generic.comparisonPeriod.previousFiscalPeriod"),
      },
    ];

    const handleClick = (compare: COMPARISON_PERIOD) => {
      setCompare(compare);
    };

    return (
      <Box>
        <Typography variant="subtitle1" color="textSecondary" noWrap>
          {t("generic.comparisonPeriod.title")}
        </Typography>
        <PanelSelect
          {...{
            width: width || "175px",
            height: "38px",
            currentValue: COMPARISON_PERIODS.find(
              (option) => option.value === currentCompare
            )?.label,
            handleChange: handleClick,
            options: COMPARISON_PERIODS,
          }}
        />
      </Box>
    );
  }
);

export default ComparisonPeriodSelect;
