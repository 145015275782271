import Plan from "./plan";
import get from "lodash/get";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const usePlan = () => {
  const plans = useTypedSelector((state) => get(state, "subscriptions.plans"));
  const currentPlan = useTypedSelector(
    (state) => state.subscriptions.currentPlan
  );

  if (currentPlan && plans) {
    const billingPeriod = currentPlan.planId.includes("mth")
      ? "monthly"
      : "yearly";
    if (plans[billingPeriod]) {
      const customPlanDetails = plans[billingPeriod].find(
        (p: Plan) => p.planId === currentPlan.planId
      );
      return customPlanDetails;
    }
  }
  return null;
};
