import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { CurrencyRate, CurrentStore } from "~/typedef/store";
import { FORECAST_TYPE, ForecastValueItem, REFERENCE_LEVEL } from "./types";
import React, { useState } from "react";
import moment, { Moment } from "moment-timezone";
import {
  useForecastingValuesQuery,
  useSplitValueEvenlyMutation,
} from "@store/mystore/forecasting.redux";

import { Calendar } from "./calendar";
import { DateRange } from "~/typedef/date";
import { INTERVAL } from "~/store/utils/dateTimeUtils";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import Panel from "../panel/panel";
import { SplitInputComponent } from "./splitInput";
import { User } from "~/typedef/user";
import { getConvertedValue } from "~/utils/currencyUtils";
import { partitionRangeByCalendarMonth } from "./utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 14%);
  width: 100%;
`;

const CalendarGrid = styled(Grid)`
  padding: 4px;
`;
const PaddedTypography = styled(Typography)`
  padding: 8px 12px;
`;

const StyledTypography = styled(Typography)`
  margin: 8px 12px;
`;

interface ForecastingCalendarProps {
  currentRange: DateRange;
  currentCurrency: string;
  userInfo: User;
  store: CurrentStore;
  ChartComponent?: React.FC;
}

const getConvertedForecastingValue = (
  forecastData: ForecastValueItem,
  currencyRates: CurrencyRate[],
  currentCurrency: string
) => {
  return Object.keys(forecastData).reduce((acc: ForecastValueItem, date) => {
    const currency =
      forecastData[Number(date)][FORECAST_TYPE.FORECAST][REFERENCE_LEVEL.TARGET]
        ?.currency;
    acc[Number(date)] = {
      [FORECAST_TYPE.BUDGET]: {
        [REFERENCE_LEVEL.TARGET]: {
          amount: getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            forecastData[Number(date)][FORECAST_TYPE.BUDGET][
              REFERENCE_LEVEL.TARGET
            ]?.amount
          ),
          currency: currentCurrency,
        },
      },
      [FORECAST_TYPE.FORECAST]: {
        [REFERENCE_LEVEL.TARGET]: {
          amount: getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            forecastData[Number(date)][FORECAST_TYPE.FORECAST][
              REFERENCE_LEVEL.TARGET
            ]?.amount
          ),
          currency: currentCurrency,
        },
      },
      [FORECAST_TYPE.ADSPEND]: {
        [REFERENCE_LEVEL.LIMIT]: {
          amount: getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            forecastData[Number(date)][FORECAST_TYPE.ADSPEND][
              REFERENCE_LEVEL.LIMIT
            ]?.amount
          ),
          currency: currentCurrency,
        },
      },
    };
    return acc;
  }, {});
};
const ForecastingCalendar: React.FC<ForecastingCalendarProps> = ({
  currentRange,
  currentCurrency,
  userInfo,
  store,
  ChartComponent,
}) => {
  const theme = useTheme();

  const currencyRates = useTypedSelector((state) =>
    state.globalVar ? state.globalVar.currencyRates : []
  );

  // TODO: Move dispatches and queries to the parent of this component
  // components should ideally not be responsible for dispatching actions
  // or querying data - this should be done at page or module level
  const { forecastData, forecastDataLoading } = useForecastingValuesQuery(
    {
      user: userInfo,
      mid: store.merchantId,
      currentRange,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        forecastData: data?.data
          ? getConvertedForecastingValue(
              data.data.forecastValue,
              currencyRates,
              currentCurrency
            )
          : {},
        forecastDataLoading: isFetching,
      }),
    }
  );
  const colors = {
    [FORECAST_TYPE.BUDGET]: theme.palette.info.light,
    [FORECAST_TYPE.FORECAST]: theme.palette.success.dark,
    [FORECAST_TYPE.ADSPEND]: theme.palette.warning.light,
  };
  const [budgetTarget, setBudgetTarget] = useState(0);
  const [forecastTarget, setForecastTarget] = useState(0);
  const [adSpendLimit, setAdSpendLimit] = useState(0);

  const [splitValueEvenly, { isLoading: splitValueLoading }] =
    useSplitValueEvenlyMutation();

  const onEdit = (
    newItem: {
      type: string;
      amount: number;
      referenceLevel: string;
    },
    date: Moment
  ) => {
    const range = {
      ...currentRange,
      fromDate: moment(date).unix(),
      toDate: moment(date).unix(),
    };
    splitValueEvenly({
      type: newItem.type,
      value: newItem.amount,
      currency: currentCurrency,
      level: newItem.referenceLevel,
      currentRange: range,
      user: userInfo,
      mid: store.merchantId,
    });
  };

  const splitValue = ({
    type,
    value,
    level,
  }: {
    type: string;
    value: number;
    level: string;
  }) => {
    splitValueEvenly({
      type,
      value,
      level,
      currency: currentCurrency,
      currentRange: currentRange,
      user: userInfo,
      mid: store.merchantId,
    });
  };

  const from = moment.unix(currentRange.fromDate).tz(currentRange.timezone);
  const to = moment.unix(currentRange.toDate).tz(currentRange.timezone);
  const diff = to.diff(from, INTERVAL.DAYS);
  const { t } = useTranslation();
  const getSplitInputFields = () => {
    return (
      <Grid container>
        <Grid container direction="column">
          <StyledTypography variant="body1">
            {t("forecasting.enterValueCaption")}
          </StyledTypography>
          <StyledTypography variant="body1">
            {`${t("forecasting.dataRangeText")}: ${from.format(
              "DD MMMM YYYY"
            )} - ${to.format("DD MMMM YYYY")}`}
          </StyledTypography>
        </Grid>
        <SplitInputComponent
          type="budget"
          level="target"
          title={t("forecasting.BudgetTarget")}
          disableButton={forecastDataLoading || splitValueLoading}
          value={budgetTarget}
          setStateValue={setBudgetTarget}
          colorValue={colors[FORECAST_TYPE.BUDGET]}
          currentCurrency={currentCurrency}
          splitValue={splitValue}
        />
        <SplitInputComponent
          type="forecast"
          level="target"
          title={t("forecasting.ForecastTarget")}
          value={forecastTarget}
          disableButton={forecastDataLoading || splitValueLoading}
          setStateValue={setForecastTarget}
          colorValue={colors[FORECAST_TYPE.FORECAST]}
          currentCurrency={currentCurrency}
          splitValue={splitValue}
        />
        <SplitInputComponent
          type="adspend"
          level="limit"
          title={t("forecasting.AdSpendLimit")}
          disableButton={forecastDataLoading || splitValueLoading}
          value={adSpendLimit}
          setStateValue={setAdSpendLimit}
          colorValue={colors[FORECAST_TYPE.ADSPEND]}
          currentCurrency={currentCurrency}
          splitValue={splitValue}
        />
      </Grid>
    );
  };

  if (diff >= 90) {
    const partitionedDates = partitionRangeByCalendarMonth(from, to);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {ChartComponent && <ChartComponent />}
        </Grid>
        <Grid item xs={12} md={4}>
          <Panel
            id="forecasting-panel"
            title={t("forecasting.title")}
            content={
              <Box p={1}>
                {getSplitInputFields()}
                <Grid container item>
                  <PaddedTypography variant="body1">
                    {t("forecasting.selectSmallTimeRange")}
                  </PaddedTypography>
                </Grid>
              </Box>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Panel
            id="forecasting-panel"
            title={t("forecasting.calendar")}
            content={
              <Grid item xs={12}>
                {forecastDataLoading || splitValueLoading ? (
                  <LoadingIndicator />
                ) : (
                  <CalendarGrid container spacing={2}>
                    {partitionedDates.map(
                      ([partitionedFrom, partitionedTo]) => (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={partitionedFrom.format("YYYYMMDD")}
                        >
                          <ContainerGrid data-testid="calendar">
                            <Calendar
                              from={partitionedFrom}
                              to={partitionedTo}
                              data={forecastData}
                              dataTimezone={currentRange.timezone}
                              labels="month"
                              onEdit={onEdit}
                              colors={colors}
                            />
                          </ContainerGrid>
                        </Grid>
                      )
                    )}
                  </CalendarGrid>
                )}
              </Grid>
            }
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {ChartComponent && <ChartComponent />}
        </Grid>
        <Grid item xs={12} md={6}>
          <Panel
            id="forecasting-panel"
            title={t("forecasting.title")}
            content={
              <Box p={1}>
                {getSplitInputFields()}
                <PaddedTypography variant="body1">
                  {t("forecasting.editIndividualValues")}
                </PaddedTypography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Panel
            id="forecasting-panel"
            title={t("forecasting.calendar")}
            content={
              <Box p={1}>
                {forecastDataLoading || splitValueLoading ? (
                  <LoadingIndicator />
                ) : (
                  <ContainerGrid data-testid="calendar">
                    <Calendar
                      from={from}
                      to={to}
                      data={forecastData}
                      dataTimezone={currentRange.timezone}
                      labels="day"
                      colors={colors}
                      onEdit={onEdit}
                    />
                  </ContainerGrid>
                )}
              </Box>
            }
          />
        </Grid>
      </Grid>
    );
  }
};

export default ForecastingCalendar;
