import React, { memo, useEffect } from "react";

import AccountHealthSummaryContents from "./accountHealthSummaryContents";
import { DEFAULT_FILTER } from "~/store/persistentAppSettings.redux";
import { Panel } from "../../../components/panel/panel";
import { fetchAccountHealth } from "../../../store/overview/accountHealth.redux";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

const AccountHealthSummary = memo(function AccountHealthSummary() {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);
  const accountHealth = useTypedSelector(
    (state) => state.overview?.accountHealth
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        fetchAccountHealth(
          {
            user: {
              _id: userInfo._id,
              organisationId: userInfo.organisationId,
            },
            pageNum: 0,
            pageSize: PAGE_SIZE,
            filter: currentFilter,
            filteredStores,
            timezone: selectedTimezone,
          },
          accountHealth?.params
        )
      );
    };
    fetchData();
  }, [currentFilter, filteredStores, userInfo._id]);

  return (
    <Panel
      id="widget-account-health"
      title={t("dashboardWidget.accountHealth.mainTitle")}
      tooltip={t("dashboardWidget.accountHealth.mainTooltip")}
      content={
        <AccountHealthSummaryContents
          accountHealth={accountHealth}
          fetchAccountHealth={fetchAccountHealth}
        />
      }
    />
  );
});

export default AccountHealthSummary;
