import { Box, Grid, useTheme } from "@material-ui/core";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import { CurrencyRate, Range, SourcingShareTrend } from "~/typedef/store";
import { User } from "~/typedef/user";
import { fetchSourcingTrend } from "~/store/mystore/vendor.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { getConvertedValue } from "~/utils/currencyUtils";
import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import moment from "moment";

export interface ChartOption {
  value: string;
  label: string;
}

export interface ChartDataRevenue {
  revenue: number;
  startTime: number; //unix timestamp
  glanceViews: number;
  units: number;
  [x: string]: number;
}

export type ChartDataForSourcingShare = ChartDataRevenue[];

export const CHART_TYPE = {
  LostCogs: "lostCogs",
  LostUnits: "lostUnits",
  ExportedUnits: "exportedUnits",
  ExportedCogs: "exportedCogs",
};

interface SourcingShareParams {
  userInfo: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  chartType: ChartOption;
  actions?: ReactChild;
  report?: boolean;
  timezone: string;
}

const formatSourcingShareData = (
  sourcingShareData: SourcingShareTrend[] | undefined,
  currentCurrency: string,
  currency = "AUD",
  currencyRates: CurrencyRate[]
) => {
  if (!sourcingShareData) {
    return [];
  }
  return sourcingShareData.map((data: SourcingShareTrend) => {
    return {
      startTime: data.startTime,
      endTime: data.endTime,
      lostCogs: getConvertedValue(
        currencyRates,
        currency,
        currentCurrency,
        data.lostCogs
      ),
      exportedCogs: getConvertedValue(
        currencyRates,
        currency,
        currentCurrency,
        data.exportedCogs
      ),
      lostUnits: data.lostUnits,
      exportedUnits: data.exportedUnits,
      sourcingShare: data.sourcingShare,
    };
  });
};

const SourcingShareTrend = memo<SourcingShareParams>(
  function SourcingShareTrend({
    userInfo,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    chartType,
    actions,
    report,
    timezone,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const sourcingShareTrend = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart.data
    );

    const currency = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart.currency
    );

    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart.lastReportDate
    );

    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart.lastUpdatedAt
    );

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.vendor?.sourcingShare?.chart?.fetching || false
    );

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchSourcingTrend();
      };
      fetchData();
    }, [currentRange, mid, userInfo]);

    const dispatchFetchSourcingTrend = useCallback(() => {
      dispatch(
        fetchSourcingTrend(
          {
            user: userInfo,
            currentRange,
            mid,
          },
          params
        )
      );
    }, [currentRange, mid, userInfo, currentCurrency]);

    const data = useMemo(() => {
      return formatSourcingShareData(
        sourcingShareTrend,
        currentCurrency,
        currency,
        currencyRates
      );
    }, [
      currentRange,
      mid,
      userInfo,
      sourcingShareTrend,
      currentCurrency,
      chartType,
    ]);
    return (
      <Panel
        id="sourcing-share-trend"
        title={t("chartTitle.sourcingShareTrend")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.updated", {
                lastReportDate: moment(lastReportDate).format("ll"),
                lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
              })}`
            : ""
        }
        tooltip={t("sourcingShareTrend.mainTooltip")}
        actions={actions}
        content={
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <StackedBarAndLineChart
                  title={t(CHART_TITLES[currentPeriod])}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  interval={currentRange.interval}
                  chartData={data}
                  isLoading={loading}
                  line={{
                    key: "sourcingShare",
                    colour: theme.palette.primary.main,
                  }}
                  topBar={{
                    key:
                      chartType.value === CHART_TYPE.LostCogs
                        ? "exportedCogs"
                        : "exportedUnits",
                    colour: theme.palette.secondary.main,
                  }}
                  bottomBar={{
                    key:
                      chartType.value === CHART_TYPE.LostCogs
                        ? "lostCogs"
                        : "lostUnits",
                    colour: theme.palette.disabled.main,
                  }}
                  xKey="startTime"
                  report={report}
                  timezone={timezone}
                  view={chartType.value}
                  barCanContainNegativeValues={true}
                  lineUnit="%"
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default SourcingShareTrend;
