import { CustomGroup, CustomGroupWithProducts } from "~/typedef/customGroups";
import { FormControl, Grid, TextField } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import SearchableMultiSelect, {
  Option,
} from "~/components/select/searchableMultiSelect";
import {
  searchProducts,
  useCreateOrEditCustomGroupByProductsMutation,
} from "~/store/customGroups/reducer.redux";

import { LinkAndImageComponent } from "~/components/table/cells/linkAndImageCell";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 100;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
`;

interface CustomGroupsFormProductProps {
  mid: string;
  marketplace: string;
  customGroups: CustomGroup[];
  currentGroup?: CustomGroupWithProducts;
  setOpenModal: (open: boolean) => void;
  onEdit?: () => void;
}

const CustomGroupsFormProduct = memo<CustomGroupsFormProductProps>(
  function CustomGroupsFormProduct({
    mid,
    marketplace,
    customGroups,
    currentGroup,
    setOpenModal,
    onEdit,
  }) {
    const { t } = useTranslation();

    const products = useTypedSelector(
      (state) => state?.customGroups?.products?.data || []
    );
    const productsSearching = useTypedSelector(
      (state) => state?.customGroups?.products?.fetching || false
    );

    const [searchText, setSearchText] = useState("");
    const [groupName, setGroupName] = useState("");
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
    const [groupNameValidation, setGroupNameValidation] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
      if (currentGroup) {
        setGroupName(currentGroup.groupName);
        setSelectedOptions(currentGroup.products);
      }
    }, [currentGroup]);

    const dispatchSearchProducts = useCallback(() => {
      dispatch(
        searchProducts({
          mid,
          pageSize: PAGE_SIZE,
          searchText,
        })
      );
    }, [mid, searchText]);

    const [createOrEditCustomGroup, { isLoading: customGroupSaving }] =
      useCreateOrEditCustomGroupByProductsMutation();

    const dispatchCreateGroup = useCallback(async () => {
      try {
        await createOrEditCustomGroup({
          mid,
          groupName,
          products: selectedOptions,
          groupId: currentGroup?.groupId,
          successMessage: currentGroup?.groupId
            ? t("customGroups.editSuccessMessage")
            : t("customGroups.createSuccessMessage"),
        }).unwrap();
        setOpenModal(false);
        setSearchText("");
        setGroupName("");
        setOptions([]);
        setSelectedOptions([]);
        if (currentGroup && onEdit) {
          onEdit();
        }
      } catch (error) {
        // Do nothing
        // The error is handled using the global error handler
        // in the mutation definition
      }
    }, [mid, currentGroup, groupName, selectedOptions, onEdit]);

    useEffect(() => {
      dispatchSearchProducts();
    }, [dispatchSearchProducts]);

    const data = useMemo(
      () =>
        products.map((product) => ({
          productId: product.productId,
          value: product.title,
          secondRowValue: `${
            marketplace === "amazon" ? "ASIN" : "LISTING ID"
          }: ${product.productSku} | SKU: ${product.sku}`,
          link: product.link,
          image: product.image,
          target: "_blank",
        })),
      [products, marketplace, mid, searchText, currentGroup?.products]
    );

    useEffect(() => {
      if (data.length) {
        const productOptions = data.map((product) => {
          const isSelected = selectedOptions.find(
            (selectedOption) => selectedOption.productId === product.productId
          );
          return {
            ...product,
            selected: Boolean(isSelected),
          };
        });
        setOptions(productOptions);
      } else if (options.length) {
        setOptions([]);
      }
    }, [data, selectedOptions]);

    const changeSelectOption = (
      e: React.ChangeEvent<HTMLInputElement>,
      option: Option
    ) => {
      const checked = e.target.checked;
      if (checked) {
        setSelectedOptions([option, ...selectedOptions]);
      } else {
        setSelectedOptions(
          selectedOptions.filter(
            (selectedOption) => selectedOption.productId !== option.productId
          )
        );
      }
    };

    const selectAllOptions = (checked: boolean) => {
      if (checked) {
        setSelectedOptions(data);
      } else {
        setSelectedOptions([]);
      }
    };

    const changeGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
      const groupName = e.target.value;
      if (e.target.value !== currentGroup?.groupName) {
        const matchingGroupName = customGroups?.find(
          (customGroup) =>
            customGroup.groupName.toLowerCase() === groupName.toLowerCase()
        );
        if (matchingGroupName) {
          setGroupNameValidation("customGroups.groupNameValidation");
        } else {
          setGroupNameValidation("");
        }
      }
      setGroupName(groupName);
    };

    return (
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                error={Boolean(groupNameValidation)}
                helperText={t(groupNameValidation)}
                label={t("customGroups.groupNameLabel")}
                required
                onChange={changeGroupName}
                value={groupName}
                disabled={customGroupSaving}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SearchableMultiSelect
                title={t("customGroups.searchProducts")}
                searchLimitText={t("customGroups.searchLimitMessage", {
                  pageSize: PAGE_SIZE,
                })}
                setSearchText={setSearchText}
                count={selectedOptions.length}
                options={options}
                searching={productsSearching}
                optionComponent={(props) => (
                  <LinkAndImageComponent {...props} />
                )}
                changeSelectOption={changeSelectOption}
                selectAllOptions={selectAllOptions}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RaisedButton
              type="submit"
              color="primary"
              onClick={dispatchCreateGroup}
              disabled={
                Boolean(groupNameValidation) ||
                customGroupSaving ||
                productsSearching ||
                !selectedOptions.length
              }
            >
              {customGroupSaving ? (
                <LoadingIndicator size={20} />
              ) : (
                t("generic.save")
              )}
            </RaisedButton>
          </Grid>
        </Grid>
      </Form>
    );
  }
);

export default CustomGroupsFormProduct;
