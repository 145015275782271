import React, { memo, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";
import { Header } from "./header";
import { SectionHeader } from "./sectionHeader";
import { TableData } from "./tableData";
import { useTranslation } from "react-i18next";

export interface ProfitAndLossData {
  key: string;
  bold?: boolean;
  startAlign?: boolean;
  tooltip?: string;
  colorCode?: boolean;
  actions?: React.ReactNode;
  [key: string]: string | boolean | React.ReactNode;
}

export interface ProfitAndLossColumn {
  header: string;
  key: string;
  width?: string;
  bold?: boolean;
  startAlign?: boolean;
  uppercase?: boolean;
  tooltip?: string;
  divider?: string;
  stickyLeft?: string;
}

interface ProfitAndLossStatementProps {
  monthsCount?: number;
  income: ProfitAndLossData[];
  profit: ProfitAndLossData[];
  expense: ProfitAndLossData[];
  metrics: ProfitAndLossData[];
  columns: ProfitAndLossColumn[];
}

interface RowDefinition {
  header?: boolean;
  sectionHeader?: string;
  dataSection?: ProfitAndLossData[];
  key: string;
}

export const ProfitAndLossTable = memo<ProfitAndLossStatementProps>(
  function ProfitAndLossTable({
    monthsCount,
    income,
    profit,
    expense,
    metrics,
    columns,
  }) {
    const { t } = useTranslation();

    const width = (monthsCount || 0) > 7 ? "max-content" : undefined;

    const rows = useMemo<RowDefinition[]>(() => {
      return [
        { header: true, key: "header" },
        { sectionHeader: t("profitability.incomeLabel"), key: "incomeHeader" },
        { dataSection: income, key: "income" },
        {
          sectionHeader: t("profitability.expensesLabel"),
          key: "expenseHeader",
        },
        { dataSection: expense, key: "expense" },
        { dataSection: profit, key: "profit" },
        {
          sectionHeader: t("profitability.metricsLabel"),
          key: "metricsHeader",
        },
        { dataSection: metrics, key: "metrics" },
      ];
    }, [income, profit, expense, metrics]);

    const getRowComponent = useCallback((row: RowDefinition) => {
      if (row.header) {
        return <Header key={row.key} columns={columns} width={width} />;
      }
      if (row.sectionHeader) {
        return (
          <SectionHeader
            title={row.sectionHeader}
            key={row.key}
            columns={columns}
            width={width}
          />
        );
      }
      if (row.dataSection) {
        return (
          <TableData
            data={row.dataSection}
            columns={columns}
            key={row.key}
            width={width}
          />
        );
      }

      return null;
    }, []);

    return <Box>{rows.map((row) => getRowComponent(row))}</Box>;
  }
);
