import React, { useCallback, useEffect, useRef, useState } from "react";

import { loginGoogle } from "~/store/user.redux";
import styled from "styled-components";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";

declare const GOOGLE_SIGNIN_CLIENT_ID: string | undefined;
declare const google: any;

const SignInWrapper = styled.div`
  text-align: "center";
`;

interface GoogleSignInButtonProps {
  onSuccess: () => {};
  onError: () => {};
}

const GoogleSignInButton = ({
  onSuccess,
  onError,
}: GoogleSignInButtonProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const dispatch = useDispatch();
  const theme = useCustomTheme();

  const handleCredentialResponse = useCallback(
    async (res: { credential: string }) => {
      try {
        await dispatch(loginGoogle(res.credential));
        onSuccess();
      } catch {
        onError();
      }
    },
    [loginGoogle, onSuccess]
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      const googleAuth = google; // google variable from external script
      googleAuth.accounts.id.initialize({
        client_id: GOOGLE_SIGNIN_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      setScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
      setScriptLoaded(false);
    };
  }, []);

  const googleButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scriptLoaded) {
      const googleAuth = google; // google variable from external script
      googleAuth.accounts.id.renderButton(googleButtonRef.current, {
        theme: "outline",
        size: "large",
        width: googleButtonRef.current?.clientWidth || 250,
        logo_alignment: "left",
      });
    }
  }, [scriptLoaded]);

  return <SignInWrapper ref={googleButtonRef} />;
};

export default GoogleSignInButton;
