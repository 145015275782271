import { Box, Snackbar, Typography } from "@material-ui/core";
import React, { memo } from "react";

import AlertCard from "./alertCard";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { SnackbarOrigin } from "@material-ui/core";
import styled from "styled-components";

const ToastAlert = styled(Snackbar)`
  position: fixed;
  width: 100%;
  top: 0;
  & .MuiCollapse-container {
    width: 100%;
  }
`;

interface ToastProps {
  message: string;
  sticky?: boolean;
  onClose: () => void;
  type?: keyof PaletteOptions | "plain";
  location?: string;
}

const Toast = memo((props: ToastProps) => {
  const {
    message,
    onClose,
    type = "success",
    sticky = false,
    location,
  } = props;

  const toastAlertProps = {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    } as SnackbarOrigin,
    open: true,
    autoHideDuration: sticky ? null : 6000,
    onClose: sticky ? undefined : onClose,
  };

  const alertCardProps = {
    isOpen: true,
    type,
    onClose,
  };

  return (
    <ToastAlert {...toastAlertProps}>
      <AlertCard {...alertCardProps}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">{message}</Typography>
          <Typography variant="body2">({location})</Typography>
        </Box>
      </AlertCard>
    </ToastAlert>
  );
});

export default Toast;
