import { Box, useTheme } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
  getCurrencySymbol,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";

import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PropTypes from "prop-types";
import TableBarChart from "~/components/charts/tableBarChart/tableBarChart";
import { fetchProductChartProfitability } from "~/store/mystore/profitability.redux";
import get from "lodash/get";
import moment from "moment-timezone";

const formatChartData = (
  range,
  interval,
  events,
  categories,
  currencyRates,
  currentCurrency,
  sourceCurrency
) => {
  const { fromDate, toDate } = range;
  const formatCurrencyValue = (value) =>
    isNaN(value)
      ? "-"
      : getConvertedValue(
          currencyRates,
          sourceCurrency,
          currentCurrency,
          value
        );

  const startDate = moment.unix(fromDate);
  const endDate = moment.unix(toDate);
  const data = [];
  if (events && categories) {
    while (startDate.isBefore(endDate)) {
      const bucketEvents = events.find((event) =>
        startDate.isSame(
          moment(event.date),
          interval
        )
      );

      if (bucketEvents) {
        const { totalProfit } = bucketEvents;

        const intervalData = {
          startTime: startDate
            .clone()
            .startOf(interval)
            .unix(),
          endTime: startDate
            .clone()
            .endOf(interval)
            .unix(),
          profit: formatCurrencyValue(totalProfit),
        };
        data.push(intervalData);
      } else {
        const intervalData = {
          startTime: startDate
            .clone()
            .startOf(interval)
            .unix(),
          endTime: startDate
            .clone()
            .endOf(interval)
            .unix(),
          profit: formatCurrencyValue(0),
        };
        data.push(intervalData);
      }
      startDate.add(1, interval);
    }
  }

  if (moment.normalizeUnits(interval) === "week" && data.length) {
    data[0].startTime = fromDate;
    data[data.length - 1].endTime = toDate;
  }
  return data;
};

const ProductTableChart = memo(({ sellerSku }) => {
  const dispatch = useDispatch();
  const includeTax = useSelector((state) =>
    Boolean(state.persistentAppSettings.setting.data.includeTax)
  );
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const theme = useTheme();
  const [data, setData] = useState([]);

  const currencyRates = useSelector((state) => state.globalVar.currencyRates);
  const currentCurrency = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const sourceCurrency =
    getCurrencyByCountryCode[store.marketplaceCountry] || "AUD";

  const currentPeriod = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const categoriesData = useSelector((state) =>
    get(state.profitability, "categories.data")
  );
  const chartData = useSelector((state) =>
    get(state.profitability, "productByDay.data", [])
  );
  const chartDataLoading = useSelector((state) =>
    get(state.profitability, "productByDay.fetching")
  );

  useEffect(() => {
    dispatch(
      fetchProductChartProfitability({
        includeTax,
        mid: store.merchantId,
        currentRange,
        sellerSku,
        timezone: selectedTimezone,
        currency: currentCurrency,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.merchantId,
    includeTax,
    currentRange,
    selectedTimezone,
    currentCurrency,
  ]);

  useEffect(() => {
    setData(
      formatChartData(
        currentRange,
        chartData.interval ?? "d",
        chartData.events,
        categoriesData,
        currencyRates,
        currentCurrency,
        sourceCurrency
      )
    );
  }, [
    store.merchantId,
    currentRange,
    chartData.interval,
    chartData.events,
    categoriesData,
    currencyRates,
    currentCurrency,
    sourceCurrency,
  ]);

  const dataDefinition = [
    { dataKey: "profit", type: "bar", color: theme.palette.chart.green },
  ];

  return chartDataLoading ? (
    <Box
      p={2}
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingIndicator />
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="50vw"
      margin="auto"
      pb={1}
    >
      <TableBarChart
        chartData={data}
        dataDefinition={dataDefinition}
        currentPeriod={currentPeriod}
        toDate={currentRange.toDate}
        fromDate={currentRange.fromDate}
        currency={getCurrencySymbol[currentCurrency]}
        timezone={selectedTimezone}
      />
    </Box>
  );
});

ProductTableChart.propTypes = {
  sellerSku: PropTypes.string.isRequired,
};

export default ProductTableChart;
