import React, { ReactChild } from "react";

import { ItemTypes } from "./itemTypes";
import styled from "styled-components";
import { useDrop } from "react-dnd";

export interface Item {
  id: number;
  text: string;
}

export interface DroppableContainerProps {
  children: ReactChild[] | ReactChild | null;
  moveCard: (item: any) => void;
}

const DropZone = styled.div`
  min-height: 28px;
  display: flex;
  align-self: stretch;
  width: 100%;
`;

export const DroppableContainer = ({
  moveCard,
  children,
}: DroppableContainerProps) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => moveCard(item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [children, moveCard]
  );
  return <DropZone ref={drop}>{children}</DropZone>;
};
